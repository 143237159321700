<app-titlebar *ngIf="!isMobile"  [title]="'login.policies' | translate" class="mb-3"></app-titlebar>
<app-header *ngIf="isMobile" goback="1" [title]="'login.policies' | translate" [isReloadVisible]="false"></app-header>


<mat-card class = "mt-2"  [class.mt-5]="isMobile" [class.mx-2]="!isMobile">
    <mat-card-content>
      <div class="nofilter  p-0 stickychips">
          <mat-chip-list>
              <mat-chip class = "ml-0" [class.imactive]="selectedLabel === 'privacyPolicy'" (click) = "onTabChange('privacyPolicy')" translate>login.privacy_policy</mat-chip>
              <mat-chip [class.imactive]="selectedLabel === 'infoPolicy'" (click) = "onTabChange('infoPolicy')" translate>login.info_policy</mat-chip>
              <mat-chip [class.imactive]="selectedLabel === 'pricingPolicy'" (click) = "onTabChange('pricingPolicy')" translate>login.pricing_policy</mat-chip>
          </mat-chip-list>
      </div>

      <div *ngIf="selectedLabel === 'privacyPolicy'">
          <div class = "webpanelheight">
            <div class="textdetails-popup">
                    <div>
                        <h4>Background</h4>
                        <p>This Privacy Policy addresses the collection, storage, processing and use, of your personal and non-personal information pursuant to your usage of services of the BROKER.</p>
                    </div>
                    <div>
                        <h4>Scope</h4>
                        <p>The BROKER with whom you have an account and who has provided you the access to this software, has engaged FinSpot Technology Solutions Pvt Ltd (FINSPOT) for provision of multiple technology services. This policy covers the services rendered by BROKER, FINSPOT, and other third-party organizations engaged by FINSPOT for provision of services.</p>
                        <p>In addition to this Policy, the BROKER may have other additional policies in place. This document does NOT address those policies or any possible conflicts with this policy. It is your sole responsibility to ensure you are aware of all the privacy implications.</p>
                    </div>
                    <div>
                        <h4>Consent by Opening Account</h4>
                        <p>By virtue of opening an account you agree to these Privacy Policies as they exist at the time of your opening of the account. These may be changed from time to time.</p>
                    </div>
                    <div>
                        <h4>Consent by Continued Maintenance of Account</h4>
                        <p>This privacy policy may be changed at any time, at the discretion of FINSPOT. By continuing to maintain your account with BROKER, you agree to these Privacy Policies, including any modifications made to these policies from time to time. We shall inform you by an alert at the time of your first login subsequent to any change in these policies. Some of our services may be used without login – for instance by reading emails sent by us. Just continuing to maintain an account with us shall constitute acceptance of the changed policy, and explicit acceptance of every change of these policies is not necessary.</p>
                        <p>If you do not consent to any changes to this policy, when requested at the time of login, you must stop using our services.</p>
                    </div>
                    <div>
                        <h4>Denial of Consent</h4>
                        <p>At any point of time, if you do not agree to the terms of this Privacy Policy, your only recourse shall be to discontinue using our services. This Privacy Policy is to be treated as a whole, and your usage of ANY of our services implies your continued acceptance of the entire Privacy Policy. This means that a complete cessation of ALL of our services is your only recourse in case of denial of your consent.
                        <br>Data Handling Post Account Closure
                        <br>On closure of your account, we will still need to retain some personal and non-personal data of yours, that we are legally obligated to retain – including daily transaction records, log files, etc, as per regulatory requirements.
                        <br>This data is typically consolidated across our entire operations and is generally not maintained or available on a per-user basis. Our maintenance of records for regulatory reasons does not imply that these records will be available to you for your requirements – including for tax filing, or any other purposes.
                        <br>Please ensure that you have downloaded all data you require from us PRIOR to closing your account with us. We will not be able to provide you your data from our records.
                        </p>
                    </div>
                    <div>
                        <h4>Cookie Policy</h4>
                        <p>Some of the services of FinSpot maintain the state of the interaction in a small piece of content on your computer, called a cookie. This cookie can be used for multiple purposes, including maintaining session credentials. Cookies created by one service of FinSpot may be used by other services provided by FinSpot or its associates.</p>
                        <p>If you set your browser settings to prevent storage or retrieval of these cookies, it may impact your ability to use our services. Some of our services may be completely unusable without cookies.</p>
                    </div>
                    <div>
                        <h4>How we collect data</h4>
                        <ul>
                            <li>Data that you have explicitly submitted to BROKER, as part of the account opening process. This includes Name, Address, PAN Number, Phone Number, your Bank Account details. In addition, we may also have your Aadhaar number/Driving License/Passport details, whichever you have used for verifying your ID/Address. </li>
                            <li>Data that we are aware of, by virtue of you connecting to our servers for any reason. This includes IP Address, Network details, Location details, Login Times, Login History, device and usage related details, etc.</li>
                            <li>Data that we have from your usage of our services, what you have traded, your holdings and positions, your trading style, the size of your portfolio, the specific instruments/sectors you are monitoring/trading, your margin levels, which widgets you have added to our trading application, how much time you have spent on each page/widget, etc.</li>
                            <li>Data that we collect from your usage of our Content Services like which stocks you have searched for, which news articles you have read, which technical indicators you have added in your charts, etc.</li>
                            <li>Data that we collect by profiling your usage of our systems. This includes trading style, sectors/stocks of interest, etc.</li>
                            <li>Data that you explicitly share while using our Social Platform services – like your opinions, etc.</li>
                            <li>Data that you may share with us, as part of resolving any issue you may face in using our services. This may include Device version, OS version, other apps installed in your device, your network service provider, any other details of your network, etc.</li>
                            <li>Additionally, any other data that you provide, or we may come to possess, by virtue of your usage of services offered by us.</li>
                        </ul>
                    </div>
                    <div>
                        <h4>How we don’t collect data</h4>
                        We will not
                        <ul>
                            <li>
                                Collect data about you by buying it from third parties, irrespective of how they have come into possession of your data.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4>How we use your data</h4>
                        In general, we use your data
                        <ul>
                            <li>To provide services most useful to you.</li>
                            <li>To determine how you use our services and decide priority for our future feature road map</li>
                            <li>Technical Management and research of services useful to the financial markets</li>
                            <li>We may also use some portions of your data, whether in aggregated or, consolidated form, for our monetization purposes, to the extent permitted by current law</li>
                            <li>User Information on the services and products provided by FinSpot provided the user has given consent for this. This consent is assumed as opt-in for use of the services and products.</li>
                            <li>The User also explicitly authorizes FinSpot to transmit Personal Data to employees, agents or other third parties within or outside the country where the User lives in order to provide services and for the purposes indicated above. Employees, agents and other third parties which have access to Personal Data are required by FinSpot to ensure compliance with all the applicable data protection provisions. Any person who authorizes FinSpot to access their Personal Data as defined in this Privacy Statement will be made aware of the data security and data protection implications. Please see Information Security Policy</li>
                            <li>FinSpot also reserves the right to disclose Personal Data to regulatory and supervisory authorities, as well as, pursuant to this Privacy Statement, to Analysis Service Providers and in response to court orders or requests from regulatory bodies. </li>
                            <li>Users who use the FinSpot platform by default opt-in to the privacy policy. However, they have the right to opt-out. Opt-out may diminish their service levels and the features sets available to them.</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Can you expect privacy?</h4>
                        <p>All of us are interested in protecting our information, especially sensitive information. But at the same time, there are certain realities that we need to deal with.
                            <br>We do not wish to give you a false guarantee of privacy. We would rather be upfront with you about the reality of privacy, as it stands in current context.
                            <br>If you own a vehicle, you would have seen how you get multiple calls from multiple insurance companies/brokers, who obviously have access to your name, your phone number, your vehicle details, etc. Everyone claims to keep your information private, but clearly, your information is not very private.
                            <br>The reality is that if any human being can see your data, then it is impossible to guarantee your privacy.
                            <br>At the cost structures at which we operate, it is simply not possible for us to ensure that that no human being can see any information about you, from any database. We have mechanisms in place to prevent and detect tampering or hacking, etc, but it is far harder to ensure that no human being can ever see any of your information.
                            <br>The other reality is that you really cannot expect privacy in an environment where even statutory bodies and brokers send you plain text SMS messages about your trading activity – and there could be apps on your phone that have permission to read those SMS messages. Similarly, there could be apps that can listen to your push notifications, and even emails.</p>
                    </div>
                    <div>
                        <h4> Payment Details</h4>
                        <p>All payments are processed through a third-party payment processing company. We do not collect or store any credentials needed to make payments, and such credentials are taken directly by either the payment processor or your bank. Please make sure that you check the site details on your browser, and verify that you are entering the details in legitimate sites only.</p>
                    </div>
                </div>
            </div>
      </div>

      <div *ngIf="selectedLabel === 'infoPolicy'" class = "webpanelheight">
        <div class="textdetails-popup">
            <div>
                <h4>Scope</h4>
                <p>This document describes the measures in place to protect your data from loss, and unauthorized access.</p>
            </div>
            <div>
                <h4>Physical Security</h4>
                <p>Our servers are hosted in Tier 4 data centres, with stringent physical access controls. These data centres are designed to withstand fire, flood, earthquakes and other exigencies. They are also equipped to deal with disruption in power supply and network bandwidth, with multiple backup options.</p>
            </div>
            <div>
                <h4>Network Security</h4>
                <p>Our servers are behind enterprise grade firewalls, and all services hosted on these servers are hardened to prevent unauthorized access, etc. All access related to SSH, FTP, etc, is allowed only from designated static IP addresses.</p>
            </div>
            <div>
                <h4>Personnel Security</h4>
                <p>Staff with access to these servers go through background verification checks, and are experienced in managing these services.</p>
            </div>
            <div>
                <h4>User Security</h4>
                <p>Access to your data is only allowed after your credentials are verified. Any change in credentials must necessarily be validated with OTP send on registered mobile number and/or email address. Please ensure that you keep strong passwords, and do not write down or share your password with anyone. Do not use the same password on multiple services. Please change your password frequently.</p>
            </div>
            <div>
                <h4>Backup Policy</h4>
                <p>We maintain backups of all data, and ensure that these backups are encrypted and stored securely in a different location. We also have processes to regularly verify these backups.</p>
            </div>
            
            
            
            
            
            
            
            
            
            
            
            
            </div>
      </div>

      <div *ngIf="selectedLabel === 'pricingPolicy'" class = "webpanelheight">
        <div class="textdetails-popup">
            <div>
                <h4>Pricing Policy</h4>
                <p>Broker shall deduct money from your trading account towards Demat charges, brokerage, exchange charges, statutory fees, amounts for purchase of securities, initial margin and top up margin payments for your intraday and derivatives positions. In case of sale of any holdings, sale proceeds will be credited to your trading account after deducting charges for the same.</p>
            </div>
            <div>
                <h4>Refund Policy</h4>
                <p>You may request a Payout of partial or complete balance, and such request shall be processed by the broker at the end of the business day, after ensuring that all pending charges have been debited from your account.</p>
            </div>
            <div>
        
        
                <h4>Funds Transfer Charges</h4>
                <p>Transfer through RazorPay Payment Gateway:<br/>
                    Net Banking - Rs.18.00 (ICICI/HDFC/Axis/SBI/Kotak), Rs.14.00 (Other Banks) <br/>
                    UPI - Rs. 6.00 (Currently not Support)<br/>
                    FinSpot Transaction Fee - Rs.5.00- per transaction.<br/>
                    GST @ 18% will be added on the RazorPay Payment Gateway charges and FinSpot Transaction Fee.
              </p>
            </div>
        </div>
      </div>
    
    </mat-card-content>
  </mat-card>
  