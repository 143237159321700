import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Url } from '../url';
import { Keys } from '../keys';
import { ChannelType, Utility } from '../utility';
import { ScripModel } from '../../model/scrip.model';
import { TradingRestService } from '../tradingrest.service';
import { ScripContentDataModel } from 'src/app/model/scripcontentdata.model';
import { OptionChainModel } from 'src/app/model/optionchain.model';
import { Preferences } from '../Preferences';
declare const moment: any;
let tradingRest: TradingRestService;

@Injectable()
export class DerivativeService {
    constructor(private tradingRestService: TradingRestService, private apiService: ApiService) {
        tradingRest = tradingRestService;
    }

    getOptionChain(data: any, callback: Function, precallback: Function) {
        let message = '';
        let code;
        let spotprice;
        let presentdate: any;
        let exDate: any;
        const optionChaninArray = [];
        const scripTempList = [];
        const key = Url.OPTION_CHAIN + '_' + data;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        const obj = { 'option_series': data['option_series'], 'expiry_date': data['expiry_date'], 'exchange_seg': data['exchange_seg'], 'underlying_symbol': data['underlying_symbol'], 'underlying_token': data['underlying_token'], 'limit': data['limit'] };
        tradingRest.marketDataFeeder.unsubscribe(ChannelType.Optionchain);
        return this.apiService.ptpluspost(Url.OPTION_CHAIN, obj).subscribe((result) => {
            let maxOI = 0;
            let spot = {};
            Utility.SyntheticFuturePrice = 0;
            if (result.code === 200 && Object.keys(result.data).length === 4 && result.data.call.length > 0 ) {
                spot = result.data.spot;
                spotprice = Math.round((result.data.future.length > 0)?result.data.future[0]['last']:spot['last']);
                let diff = 0;
                if(result.data.call.length>2){
                    diff = result.data.call[1]['strikprc'] - result.data.call[0]['strikprc'];
                }
                let min = 999999 ;
                let rownum = 0;
                Utility.atmStrike = 0;
                Utility.ocDiff = 0;
                if(result.data.call.length > 1){
                    Utility.ocDiff = result.data.call[1]['strikprc']-result.data.call[0]['strikprc']
                }
                let f;
                let ot;
                if (result.data.future.length > 0 ) {
                    f = Utility.getScripModel(result.data.future[0]['omtkn'], result.data.future[0]['exseg'], tradingRest, true, ChannelType.Optionchain);
                    f.TradingSymbol = result.data.future[0]['tsym'];
                    f.LastTradePrice = (result.data.future[0]['last'] === '') ? 0 : result.data.future[0]['last'];
                    f.StrikePrice = result.data.future[0]['strikprc'];
                    f.OptionType = result.data.future[0]['optyp'];
                    f.ExpiryDate = result.data.future[0]['expry'];
                    ot = (result.data.future[0]['expry'] == data['expiry_date'])?1:2;
                    f.Series = result.data.future[0]['seris'];
                    f.DecimalPrecision = 2;
                    f.Change = result.data.future[0]['chng'];
                    f.PercentageChange = result.data.future[0]['pchng'];
                    f.Openinterest = result.data.future[0]['oi'];
                    f.BestBuyPrice = result.data.future[0]['bid'];
                    f.BestBuySize = result.data.future[0]['bsiz'];
                    f.BestSellPrice = result.data.future[0]['ask'];
                    f.BestSellSize = result.data.future[0]['asiz'];
                    scripTempList.push(result.data.future[0]['exseg'] + '|' + result.data.future[0]['omtkn']);                
                }
                for (let i = 0; i < result.data.call.length; i++) {
                    const option = new OptionChainModel();
                    option.strikeprice = result.data.call[i]['strikprc'];
                    option.atm = ((spotprice - option.strikeprice) >0)?-1:2;
                    let abs = Math.abs(spotprice - option.strikeprice)/2
                    if(abs <= min){
                        min = abs;
                        rownum = i;
                    }
                    option.callscrip = Utility.getScripModel(result.data.call[i]['omtkn'], result.data.call[i]['exseg'], tradingRest, true, ChannelType.Optionchain);
                    option.putscrip = Utility.getScripModel(result.data.put[i]['omtkn'], result.data.put[i]['exseg'], tradingRest, true, ChannelType.Optionchain);
                    option.optionType = ot;
                    if (result.data.future.length > 0 ) {
                        option.futurescrip = Utility.getScripModel(result.data.future[0]['omtkn'], result.data.future[0]['exseg'], tradingRest, true, ChannelType.Optionchain);
                    }
                    option.callscrip.TradingSymbol = result.data.call[i]['tsym'];
                    option.callscrip.LastTradePrice = (result.data.call[i]['last'] === '') ? 0 : result.data.call[i]['last'];
                    option.callscrip.StrikePrice = result.data.call[i]['strikprc'];
                    option.callscrip.DecimalPrecision = 2;
                    option.callscrip.OptionType = result.data.call[i]['optyp'];
                    option.callscrip.ExpiryDate = result.data.call[i]['expry'];
                    option.callscrip.Series = result.data.call[i]['seris'];
                    option.callscrip.Change = result.data.call[i]['chng'];
                    option.callscrip.PercentageChange = result.data.call[i]['pchng'];
                    option.callscrip.Openinterest = result.data.call[i]['oi'];
                    option.callscrip.BestBuyPrice = result.data.call[i]['bid'];
                    option.calloi = result.data.call[i]['oi'];
                    option.callscrip.BestBuySize = result.data.call[i]['bsiz'];
                    option.callscrip.BestSellPrice = result.data.call[i]['ask'];
                    option.callscrip.BestSellSize = result.data.call[i]['asiz'];
                    if(maxOI <= option.calloi) {
                        maxOI = option.calloi;
                    }
                    option.putscrip.TradingSymbol = result.data.put[i]['tsym'];
                    option.putscrip.LastTradePrice = (result.data.put[i]['last'] === '') ? 0 : result.data.put[i]['last'];
                    option.putscrip.StrikePrice = result.data.put[i]['strikprc'];
                    option.putscrip.OptionType = result.data.put[i]['optyp'];
                    option.putscrip.DecimalPrecision = 2;
                    option.putscrip.ExpiryDate = result.data.put[i]['expry'];
                    option.putscrip.Series = result.data.put[i]['seris'];
                    option.putscrip.Change = result.data.put[i]['chng'];
                    option.putscrip.PercentageChange = result.data.put[i]['pchng'];
                    option.putscrip.Openinterest = result.data.put[i]['oi'];
                    option.putoi = result.data.put[i]['oi'];
                    option.putscrip.BestBuyPrice = result.data.put[i]['bid'];
                    option.putscrip.BestBuySize = result.data.put[i]['bsiz'];
                    option.putscrip.BestSellPrice = result.data.put[i]['ask'];
                    option.putscrip.BestSellSize = result.data.put[i]['asiz'];
                    if(maxOI <= option.putoi){
                        maxOI = option.putoi;
                    }
                    exDate = Utility.toDateTime(option.putscrip.ExpiryDate);
                    presentdate = new Date();
                    let ed = moment(exDate).format('YYYY-MM-DD')+'T23:59:59';
                    let pd = moment(presentdate).format('YYYY-MM-DD')+'T00:00:00';
                    let pds = moment(presentdate).format('YYYY-MM-DD')+'T09:15:00';
                    let edd = Date.parse(ed);
                    let pdd = Date.parse(pd);
                    option.pdsd = Date.parse(pds);
                    
                    let dtime = edd - pdd;
                    option.tmpday = Math.ceil(dtime/(1000*60*60*24));
                    
                    option.days = option.getDays();
                    option.callscrip.Series = result.data.call[i]['seris'];
                    option.putscrip.Series = result.data.put[i]['seris'];
                    option.spotCng = spot['chng'];
                    option.spotTsym = spot['tsym'];
                    option.spotpchng = spot['pchng'];
                    option.spotLTP = spot['last'];
                   /* if (option.callscrip.LastTradePrice !== 0 && option.putscrip.LastTradePrice !== 0 && option.futurescrip.LastTradePrice !== 0){
                        option.calculatevalues();
                    }*/
                    scripTempList.push(result.data.call[i]['exseg'] + '|' + result.data.call[i]['omtkn']);
                    scripTempList.push(result.data.put[i]['exseg'] + '|' + result.data.put[i]['omtkn']);
                    optionChaninArray.push(option);
                }
                Utility.atmStrike = optionChaninArray[rownum].strikeprice;
                optionChaninArray[rownum].atm = 1;
                Utility.SyntheticFuturePrice = optionChaninArray[rownum].strikeprice+optionChaninArray[rownum].callscrip.LastTradePrice-optionChaninArray[rownum].putscrip.LastTradePrice;
                
                for(let i=0;i<optionChaninArray.length;i++){
                    optionChaninArray[i].updatemodel();
                }
                Utility.CACHE_STORE.setCacheData(key, optionChaninArray);
                code = Keys.RES_CODE_SUCCESS;
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = 'NO DATA';
            }
            callback(code, optionChaninArray, spotprice, spot, message);
            const count = scripTempList.length;
            if (count > 0) {
                const scripListObj = {
                    'uid': Preferences.getPreference('userid'),
                    'tomcatCount': Preferences.getPreference('tomcatcount'),
                    'scripList': scripTempList.toString()
                };
            tradingRest.getScripService().getMultipleScrips(scripListObj, (resultdata, resCode, resMessage) => { });
            }
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback(code, optionChaninArray, undefined, undefined, message);
        });
    }

    getOptionChainData(exch: any, callback: Function, precallback: Function) {
        let message = '';
        let code;
        const key = Url.OPTION_CHAIN_DATA + '_' + exch;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        return this.apiService.ptpluspost(Url.OPTION_CHAIN_DATA, { 'exchange_seg': exch }).subscribe((result) => {
            if (result.code === 200 && result.data !== null && Object.keys(result.data).length > 0) {
                Utility.CACHE_STORE.setCacheData(key, result.data);
                code = Keys.RES_CODE_SUCCESS;
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = 'NO DATA';
            }
            callback(code, result, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback(code, '', message);
        });
    }

    getPutCallRatioData(data, callback: Function, precallback: Function) {
        let message = '';
        let code;
        const key = Url.PUT_CALL_RATIO + '_' + data;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        return this.apiService.ptpluspost(Url.PUT_CALL_RATIO, data).subscribe((result) => {
            if (result.code === 200 && Object.keys(result.data).length > 0) {
                code = Keys.RES_CODE_SUCCESS;
                message = Keys.RES_MSG_OK;
                Utility.CACHE_STORE.setCacheData(key, result.data);
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = 'NO DATA';
            }
            callback(code, result.data, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback(code, '', message);
        });
    }

    getMarketTodayData(callback: Function, precallback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const scripModelArr = new Array<ScripModel>();
        const scripTempList = [];
        const key = Url.MARKET_TODAY_DERIVATIVE + '_' + 'derivative';
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        return this.apiService.ptpluspost(Url.MARKET_TODAY_DERIVATIVE).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res['code'] === Keys.RES_CODE_SUCCESS && Object.keys(res.data).length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    const value = res['data'][i];
                    const bean = Utility.getScripModel(value['omtkn'], value['exseg'], tradingRest, true, ChannelType.Market);
                    bean.TradingSymbol = value['tsym'];
                    bean.ExchangeSegment = value['exseg'];
                    bean.Token = value['omtkn'];
                    bean.scripContentDataModel = new ScripContentDataModel();
                    bean.scripContentDataModel.SevenDayReturn = value['7drt'];
                    bean.scripContentDataModel.OneMonthReturn = value['1mrt'];
                    bean.scripContentDataModel.ThreeMonthReturn = value['3mrt'];
                    bean.scripContentDataModel.SixMonthReturn = value['6mrt'];
                    bean.scripContentDataModel.OneYearReturn = value['1yrt'];
                    bean.scripContentDataModel.SevenDayVolume = value['7avol'];
                    bean.scripContentDataModel.OneMonthVolume = value['mavol'];
                    bean.scripContentDataModel.ThreeMonthVolume = value['3mav'];
                    bean.scripContentDataModel.SixMonthVolume = value['6mav'];
                    bean.scripContentDataModel.OneYearVolume = value['yavol'];
                    bean.scripContentDataModel.VolumeOne = value['vol_1'];
                    bean.scripContentDataModel.VolumeTwo = value['vol_2'];
                    bean.scripContentDataModel.SevenDayReturnVolume = value['7drtv'];
                    bean.scripContentDataModel.OneMonthReturnVolume = value['1mrtv'];
                    bean.scripContentDataModel.ThreeMonthReturnVolume = value['3mrtv'];
                    bean.scripContentDataModel.SixMonthReturnVolume = value['6mrtv'];
                    bean.scripContentDataModel.OneYearReturnVolume = value['1yrtv'];
                    bean.scripContentDataModel.Sma20 = value['sma20'];
                    bean.scripContentDataModel.Sma50 = value['sma50'];
                    bean.scripContentDataModel.Sma100 = value['sma100'];
                    bean.scripContentDataModel.Sma200 = value['sma200'];
                    bean.scripContentDataModel.Ema20 = value['ema20'];
                    bean.scripContentDataModel.Ema50 = value['ema50'];
                    bean.scripContentDataModel.Ema100 = value['ema100'];
                    bean.scripContentDataModel.Ema200 = value['ema200'];
                    bean.scripContentDataModel.UpperBand = value['uprband'];
                    bean.scripContentDataModel.MiddleBand = value['mdlband'];
                    bean.scripContentDataModel.LowerBand = value['lwrband'];
                    scripTempList.push(value['exseg'] + '|' + value['omtkn']);
                    scripModelArr.push(bean);
                }
                Utility.CACHE_STORE.setCacheData(key, scripModelArr);
                callback(scripModelArr, code, message);
                const count = scripTempList.length;
                if (count > 0) {
                    const scripListObj = {
                        'uid': Preferences.getPreference('userid'),
                        'tomcatCount': Preferences.getPreference('tomcatcount'),
                        'scripList': scripTempList.toString()
                    };
                    tradingRest.getScripService().getMultipleScrips(scripListObj, (data, resCode, resMessage) => {});
                }
            }
            else {
                message = res.message;
                code = Keys.RES_CODE_FAILURE;
                callback([], code, message);
            }
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback([], code, message);
        });
    }

    getIndexFutureRollOverDataNQ(exchange_segment: string, type: string, callback: Function, precallback: Function) {
        let message;
        let code = Keys.RES_CODE_SUCCESS;
        const rolloverModelData = new Array<ScripModel>();
        const scripTempList = [];
        const params = { exchange_segment: exchange_segment };
        const url = ((type === 'Index') ? Url.INDEXFUTUREROLLOVERNQ : Url.FUTUREROLLOVERNQ);
        const key = Url + '_' + params;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.ptpluspost(url, params).subscribe((response) => {
            response = JSON.parse(JSON.stringify(response));
            if (response['code'] === Keys.RES_CODE_SUCCESS && Object.keys(response.data).length > 0) {
                for (let i = 0; i < response.data.length; i++) {
                    const value = response['data'][i];
                    const bean = Utility.getScripModel(value['omtkn'], value['exseg'], tradingRest, true, ChannelType.Market);
                    bean.TradingSymbol = value['tsym'];
                    bean.Token = value['omtkn'];
                    bean.ExchangeSegment = value['exseg'];
                    bean.scripContentDataModel = new ScripContentDataModel();
                    bean.scripContentDataModel.SevenDayReturn = value['7drt'];
                    bean.scripContentDataModel.OneMonthReturn = value['1mrt'];
                    bean.scripContentDataModel.ThreeMonthReturn = value['3mrt'];
                    bean.scripContentDataModel.SixMonthReturn = value['6mrt'];
                    bean.scripContentDataModel.OneYearReturn = value['1yrt'];
                    bean.scripContentDataModel.SevenDayVolume = value['7avol'];
                    bean.scripContentDataModel.OneMonthVolume = value['mavol'];
                    bean.scripContentDataModel.ThreeMonthVolume = value['3mav'];
                    bean.scripContentDataModel.SixMonthVolume = value['6mav'];
                    bean.scripContentDataModel.OneYearVolume = value['yavol'];
                    bean.scripContentDataModel.VolumeOne = value['vol_1'];
                    bean.scripContentDataModel.VolumeTwo = value['vol_2'];
                    bean.scripContentDataModel.SevenDayReturnVolume = value['7drtv'];
                    bean.scripContentDataModel.OneMonthReturnVolume = value['1mrtv'];
                    bean.scripContentDataModel.ThreeMonthReturnVolume = value['3mrtv'];
                    bean.scripContentDataModel.SixMonthReturnVolume = value['6mrtv'];
                    bean.scripContentDataModel.OneYearReturnVolume = value['1yrtv'];
                    bean.scripContentDataModel.Sma20 = value['sma20'];
                    bean.scripContentDataModel.Sma50 = value['sma50'];
                    bean.scripContentDataModel.Sma100 = value['sma100'];
                    bean.scripContentDataModel.Sma200 = value['sma200'];
                    bean.scripContentDataModel.Ema20 = value['ema20'];
                    bean.scripContentDataModel.Ema50 = value['ema50'];
                    bean.scripContentDataModel.Ema100 = value['ema100'];
                    bean.scripContentDataModel.Ema200 = value['ema200'];
                    bean.scripContentDataModel.UpperBand = value['uprband'];
                    bean.scripContentDataModel.MiddleBand = value['mdlband'];
                    bean.scripContentDataModel.LowerBand = value['lwrband'];
                    scripTempList.push(value['exseg'] + '|' + value['omtkn']);
                    bean.RollScrip = value['rllcstpr'];
                    bean.RollOver = value['rollover'];
                    rolloverModelData.push(bean);
                }
                Utility.CACHE_STORE.setCacheData(key, rolloverModelData);
                callback(rolloverModelData, code, message);
                const count = scripTempList.length;
                if (count > 0) {
                    const scripListObj = {
                        'uid': Preferences.getPreference('userid'),
                        'tomcatCount': Preferences.getPreference('tomcatcount'),
                        'scripList': scripTempList.toString()
                    };
                    tradingRest.getScripService().getMultipleScrips(scripListObj, (data, resCode, resMessage) => {
                    });
                }
            }
            else {
                message = response.message;
                code = Keys.RES_CODE_FAILURE;
                callback([], code, message);
            }
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback([], code, message);
        });
    }
}
