import { createChart } from 'lightweight-charts';
import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Utility } from '../../../services/utility';
import { TradingRestService } from '../../../services/tradingrest.service';
import { environment } from 'src/environments/environment';
import { Preferences, ReturnType } from 'src/app/services/Preferences';

let lwc: LightWeightChartComponent;

@Component({
    selector: 'app-lightweightchart',
    templateUrl: './lightweightchart.component.html',
    styleUrls: ['./lightweightchart.component.scss']
})
export class LightWeightChartComponent implements OnInit {
    @ViewChild('lightweightchart', { static: true }) lightweight;
    @Output() outputEvent = new EventEmitter();
    @Input() chartWidth;
    @Input() chartHeight;
    @Input() isLegend = false;
    chartDataArr = [];
    volumeDataArr = [];
    lastBarGlobal;
    isLoader: boolean;
    isNoData: boolean;
    isAreaChart: boolean;
    isCandleChart: boolean;
    volumeSeries;
    areaSeries;
    candlestickSeries;
    chartType;
    style;
    exchSeg;
    symbol;
    chart;

    constructor(private tradingRestService: TradingRestService) {
        lwc = this;
        lwc.chartType = 'area'; // Change it to "area" if Chart should be drawn as "Area" by default
    }

    ngOnInit() {
        setTimeout(() => {
            lwc.outputEvent.emit('event emitted');
        }, 100);
    }

    initChart() {
        lwc.chart = createChart(lwc.lightweight.nativeElement, {
            width: lwc.chartWidth,
            height: lwc.chartHeight
        });

        lwc.volumeSeries = lwc.chart.addHistogramSeries({
            lineWidth: 3,
            priceFormat: {
                type: 'volume',
            },
            overlay: true,
            scaleMargins: {
                top: 0.8,
                bottom: 0,
            },
        });
        let bgColor;
        const element = document.querySelector('.chart-color6');
        lwc.style = getComputedStyle(element);
        bgColor = lwc.rgb2hex(lwc.style.background);
        if (lwc.chartType == 'area') {
            lwc.isAreaChart = true;
            lwc.areaSeries = lwc.chart.addAreaSeries({
                topColor: lwc.rgb2rgba(lwc.style.lightingColor, '0.1'),
                bottomColor: lwc.rgb2rgba(lwc.style.fill, '0.1'),
                lineColor: lwc.rgb2rgba(lwc.style.fill, '0.8'),
                lineWidth: 2,
                crosshairMarkerVisible: true,
                crosshairMarkerRadius: 2,
            });
        }
        else if (lwc.chartType == 'candle') {
            lwc.isCandleChart = true;
            lwc.candlestickSeries = lwc.chart.addCandlestickSeries({
				upColor: lwc.rgb2hex(lwc.style.fill),
				downColor: lwc.rgb2hex(lwc.style.borderColor),
				borderVisible: false,
				wickVisible: true,
				borderColor: lwc.rgb2hex(lwc.style.background),
				wickColor: lwc.rgb2hex(lwc.style.columnRuleColor),
				borderUpColor: lwc.rgb2hex(lwc.style.fill),
				borderDownColor: lwc.rgb2hex(lwc.style.borderColor),
				wickUpColor: lwc.rgb2hex(lwc.style.fill),
				wickDownColor: lwc.rgb2hex(lwc.style.borderColor),
			});
        }
        
        lwc.chart.applyOptions({
            timeScale: {
                borderVisible: true,
                visible: true,
                timeVisible: true,
            },
            priceScale: {
                borderVisible: true,
                visible: true,
                scaleMargins: {
                    top: 0.30,
                    bottom: 0.25,
                }
            },
            layout: {
                backgroundColor: bgColor,
                textColor: lwc.rgb2hex(lwc.style.color),
            },
            grid: {
                vertLines: {
                    color: lwc.rgb2hex(lwc.style.borderInlineEndColor),
                },
                horzLines: {
                    color: lwc.rgb2hex(lwc.style.borderInlineEndColor),
                },
            },
        });
    }

    rgb2hex(rgb) {
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? '#' +
            ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
    }

    rgb2rgba(rgb, alpha) {
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? 'rgba(' + rgb[1] + ',' + rgb[2] + ',' + rgb[3] + ',' + alpha + ')' : '';
    }

    plotChart(symbol, exch) {
        lwc.symbol = symbol;
        lwc.exchSeg = exch;
        lwc.isLoader = true;
        const data = { 'symbol': lwc.symbol, 'exch': lwc.exchSeg, 'days': '1', 'tokentype': 'omnisys', 'type': 'stock' }
        lwc.tradingRestService.getTRKDChartService().getTRKDChartData(data, lwc.setOneDayTRKDData);
    }


    setOneDayTRKDData(code, result, message) {
        lwc.initChart();
        lwc.isLoader = false;
        
        if (code === 200 && result.data && Object.keys(result.data).length > 0) {
            lwc.volumeDataArr = [];
            lwc.chartDataArr = [];
            let data = result.data;
            let areaChartDataObj = {};
            let cumulativeVolume = 0;
            let volumeColor;
            for (let i = 0; i < data['timestamparray'].length; i++) {
                let timeArray = new Date(data['timestamparray'][i]).getTime();
                timeArray = (timeArray + (330 * 60 * 1000));
                if (data['closepricearray'][i - 1] < data['closepricearray'][i]) {
                    volumeColor = lwc.rgb2rgba('rgb(76, 175, 80)', 0.4);
                }
                else {
                    volumeColor = lwc.rgb2rgba('rgb(244, 67, 54)', 0.4);
                }
                const volumeChartDataObj =
                {
                    time: timeArray / 1000,
                    value: data['volumearray'][i],
                    color: volumeColor,
                };
                lwc.volumeDataArr.push(volumeChartDataObj);
                if (lwc.isAreaChart) {
                    areaChartDataObj = {
                        time: timeArray / 1000,
                        value: data['closepricearray'][i],
                    };
                }
                else if (lwc.isCandleChart) {
                    areaChartDataObj = {
                        time: timeArray/1000,
                        open: data['openpricearray'][i],
                        high: data['highpricearray'][i],
                        low: data['lowpricearray'][i],
                        close: data['closepricearray'][i]
                    }
                }
                lwc.chartDataArr.push(areaChartDataObj);
                cumulativeVolume += parseInt(data['volumearray'][i], 10);
            }
            if (lwc.chartType == 'area')
                lwc.areaSeries.setData(lwc.chartDataArr);
            else if (lwc.chartType == 'candle')
                lwc.candlestickSeries.setData(lwc.chartDataArr);
            lwc.volumeSeries.setData(lwc.volumeDataArr);
            if (lwc.chartDataArr.length > 0 && lwc.volumeDataArr.length > 0) {
                lwc.lastBarGlobal = lwc.chartDataArr[lwc.chartDataArr.length - 1];
                lwc.lastBarGlobal.cumulativeVolume = cumulativeVolume;
            }
        }
        else {
            lwc.removeChartFromUI();
            lwc.isNoData = true;
        }
        
        lwc.chart.timeScale().fitContent();

    }

    

    chartResized(width) {
        lwc.chart.resize(width, lwc.chartHeight);
        lwc.chart.timeScale().fitContent();
    }

    removeChartFromUI() {
        const child = document.getElementsByClassName('tv-lightweight-charts');
        const chartElement = document.getElementById('lwchart');
        if (child[0])
            chartElement.removeChild(child[0]);
    }
}
