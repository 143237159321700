<app-titlebar *ngIf = '!isMobile' [title]="'all.gtt' | translate" [isLoading]="isLoading"></app-titlebar>
<app-stockheader *ngIf = 'isMobile' [scripModel] = "scrpModel" [isLoading]="isLoading"></app-stockheader>
<div [ngClass]="{'data-container pb-5': isMobile}">
    <mat-card *ngIf = 'isMobile' class="stockdetailstop">  
        <div class="row btn-margin">
            <div class="col-6 m-0" [class.buy]="transType=='B'" [class.sell]="transType=='S'" >
                <div class="mt-1" ><small class="primetext">{{'all.tgr_typ' | translate}}</small></div>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="trgType" (change) = "onTriggerTypeChange($event)" [disabled] = "isEdit || isDisableForm"> 
                        <mat-radio-button [class.imactive]="trgType =='GTT_SINGLE'" value="GTT_SINGLE" class = "pr-1">{{'all.single' | translate}}</mat-radio-button>
                        <mat-radio-button [class.imactive]="trgType =='GTT_OCO'" value="GTT_OCO" class = "pr-1" [disabled]="FlagOCO">{{'all.oco' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-6 text-right">
                <div class="mt-1 text-right ml-4"><small class="primetext landscape-primetext-mr">{{'all.type' | translate}}</small></div>
                <button  mat-flat-button [class.btn-positive]="transType=='B'" (click) = "changeTransectionType('B')" [disabled] = "(isEdit && trgType =='GTT_OCO') || isDisableForm"><span>{{'all.buy' | translate}}</span></button>
                <button  mat-flat-button [class.btn-negative]="transType=='S'" (click) = "changeTransectionType('S')" [disabled] = "(isEdit && trgType =='GTT_OCO') || isDisableForm"><span>{{'all.sell' | translate}}</span></button>
            </div>
        </div>
    </mat-card>
    <div class="buy placeorderwrapper" [ngClass]="{'placeorderheight narrowpadding': !isMobile}" [class.buy]="transType=='B'" [class.sell]="transType=='S'">
        <div *ngIf="!isMobile" class="row">
            <div class="col-12">
                <div class="row stockdetailstop pb-2">
                    <div class="col-xl-6 col-sm-4">
                        <div class="firstline"><span class="symbolname">{{((scrpModel.ExchangeSegment == 'nse_cm' || scrpModel.ExchangeSegment == 'bsc_cm') ? scrpModel.TradingSymbol : scrpModel.Symbolname) | symbolpipe}}&nbsp;<span class="ts-2" *ngIf = "scrpModel.ExchangeName === 'BSE'">({{scrpModel.InstrumentName}})</span><small>{{scrpModel.ExchangeName}}</small></span></div>
                        <div class="line2">{{scrpModel.DisplaySymbolDescription}}</div>
                    </div>
                    <div class="col-xl-3 col-sm-4 text-right" [class.imnegative]="scrpModel.Change<0" [class.impositive]="scrpModel.Change>0" >
                        <div [class.imnegativebg]="scrpModel.TickDirection === -1" [class.impositivebg]="scrpModel.TickDirection === 1">
                            <div class="firstline">{{scrpModel.LastTradePrice | pricepipe: scrpModel.DecimalPrecision}}</div>
                            <div class="line2">{{scrpModel.Change | pricepipe: scrpModel.DecimalPrecision}} ({{scrpModel.PercentageChange | percentagepipe}})</div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-4 text-right">
                        <button mat-flat-button tabindex="-1" [class.custom-btn]="transType!=='B'" [class.btn-positive]="transType=='B'" (click) = "changeTransectionType('B')" [disabled] = "(isEdit && trgType =='GTT_OCO') || isDisableForm"><span>{{'all.buy' | translate}}</span></button> &nbsp;
                        <button mat-flat-button tabindex="-1" [class.custom-btn]="transType!=='S'" [class.btn-negative]="transType=='S'" (click) = "changeTransectionType('S')" [disabled] = "(isEdit && trgType =='GTT_OCO') || isDisableForm"><span>{{'all.sell' | translate}}</span></button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isMobile" class="row myrow" [class.buy]="transType=='B'" [class.sell]="transType=='S'">
            <div class="col-12 col-md-6">
                <div class="mt-1"><small class="primetext">{{'all.tgr_typ' | translate}}</small></div>
                <mat-radio-group  [(ngModel)]="trgType" aria-label="Select an option" (change) = "onTriggerTypeChange($event)" [disabled] = "isEdit || isDisableForm">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <mat-radio-button [class.imactive]="trgType =='GTT_SINGLE'" value="GTT_SINGLE" class = "pr-1">{{'all.single' | translate}}</mat-radio-button>
                            <mat-radio-button [class.imactive]="trgType =='GTT_OCO'" value="GTT_OCO" class = "pr-1" [disabled]="FlagOCO">{{'all.oco' | translate}}</mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <div class="row">   
            <div class="mt-3" [class.col-6]="!isMobile" *ngIf="trgType =='GTT_OCO'" [class.px-2]="isMobile"><h3 [class.ml-2]="!isMobile">{{'all.stoploss' | translate}}</h3>
                <div class="row mt-2" [class.mx-1]="!isMobile">
                    <div [class.col-6]="!isMobile" [class.col-12]="isMobile">
                        <mat-form-field>
                            <mat-label>{{'all.trig_price' | translate}}</mat-label>
                            <input id="stpTrgPrice" matInput type="tel" [placeholder]="'all.trig_price' | translate" [(ngModel)]="stp_trgPrice" autocomplete="off" inputmode="decimal" (keyup)="calStopTriggerPrcPercent(inputStopTriggerPrice.value)" (input)="validatePrice($event,'inputStpTrgPrice')"  #inputStopTriggerPrice [disabled]="isDisableForm">
                            <p class="insideinput" [class.impositive]="transType=='B'" [class.imnegative]="transType=='S'">{{stpTrgPrcPrcnt | pricepipe: 2}} % of {{'all.ltp' | translate}}</p>
                        </mat-form-field>
                    </div>
                    <div [class.col-3]="!isMobile" [ngClass]="{'col-6 pr-0': isMobile}">
                        <div class="my-1"><small class="primetext">{{'all.producttype' | translate}}</small></div>
                        <div><label>{{prdctType}}</label></div>
                    </div>
                    <div [class.col-3]="!isMobile" [class.col-6]="isMobile">
                        <div class="my-1"><small class="primetext">{{'all.ordertype' | translate}}</small></div>
                        <div><label>LIMIT</label></div>
                    </div>
                </div>
                <div class="row mt-3" [class.mx-1]="!isMobile">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label><span>{{'all.basket_qty' | translate}}</span></mat-label>
                            <input id="stpQty" matInput type="tel" [placeholder]="'all.basket_qty' | translate" [(ngModel)]="stp_qty" (input)="validateQty($event,'inputStpQty')" maxlength="8" autocomplete="off" [disabled]="isDisableForm">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>{{'all.prc' | translate}}</mat-label>
                            <input id="stpPrice" matInput type="tel" [placeholder]="'all.prc' | translate" [(ngModel)]="stp_price" (input)="validatePrice($event,'inputStpPrice')" autocomplete="off" inputmode="decimal" [disabled]="isDisableForm">
                        </mat-form-field>
                    </div>
                </div> 
                <!-- <div class="row" [class.mrow]="!isMobile">
                    <div class="col-12">
                        <div class="mt-1"><small class="primetext" translate>Trigger Condition</small></div>
                        <mat-radio-group  [(ngModel)]="selectedStpTrgCond" aria-label="Select an option" disabled>    
                            <mat-radio-button *ngFor="let val of stpTrgCondition" [value]="val" class = "pr-1">{{val}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>  -->
            </div>
            <div [class.col-6]="!isMobile" [class.col-12]="!isMobile && trgType =='GTT_SINGLE'" [class.px-2]="isMobile"><h3  class="mt-3" [class.ml-2]="!isMobile" *ngIf="trgType =='GTT_OCO'">{{'all.target' | translate}}</h3>
                <div class="row mt-2" [class.mx-1]="!isMobile">
                    <div [class.col-6]="!isMobile" [class.col-12]="isMobile">
                        <mat-form-field>
                            <mat-label>{{'all.trig_price' | translate}}</mat-label>
                            <input id="trgPrice" matInput type="tel" [placeholder]="'all.trig_price' | translate" autocomplete="off" inputmode="decimal" [(ngModel)]="trgPrice" (input)="validatePrice($event,'inputTrgPrice')" (keyup)="calTriggerPrcPercent(inputTriggerPrice.value)" #inputTriggerPrice [disabled]="isDisableForm">
                            <p class="insideinput" [class.impositive]="transType=='B'" [class.imnegative]="transType=='S'">{{trgPrcPrcnt | pricepipe: 2}} % of {{'all.ltp' | translate}}</p>
                        </mat-form-field>
                    </div>
                    <div [class.col-3]="!isMobile" [ngClass]="{'col-6 pr-0': isMobile}">
                        <div class="my-1"><small class="primetext">{{'all.producttype' | translate}}</small></div> 
                        <div><label>{{prdctType}}</label></div>
                    </div>
                    <div [class.col-3]="!isMobile" [class.col-6]="isMobile">
                        <div class="my-1" ><small class="primetext">{{'all.ordertype' | translate}}</small></div>
                        <div><label>LIMIT</label></div>
                    </div>
                </div>
                <div class="row mt-3" [class.mx-1]="!isMobile">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label><span>{{'all.basket_qty' | translate}}</span></mat-label>
                            <input id="qty" matInput type="tel" [placeholder]="'all.basket_qty' | translate" [(ngModel)]="qty" (input)="validateQty($event,'inputQty')" maxlength="8" autocomplete="off" [disabled]="isDisableForm">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>{{'all.prc' | translate}}</mat-label>
                            <input id="price" matInput type="tel" [placeholder]="'all.prc' | translate" [(ngModel)]="price" (input)="validatePrice($event,'inputPrice')" (keyup.enter) = "checkValidation()" autocomplete="off" inputmode="decimal" [disabled]="isDisableForm">
                        </mat-form-field>
                    </div>
                </div> 
                <!-- <div *ngIf="trgType =='GTT_OCO'" class="row" [class.mrow]="!isMobile">
                    <div class="col-12">
                        <div class="mt-1"><small class="primetext" translate>Trigger Condition</small></div>
                        <mat-radio-group  [(ngModel)]="selectedTrgCond" aria-label="Select an option" disabled>    
                            <mat-radio-button *ngFor="let val of trgCondition" [value]="val" class = "pr-1">{{val}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>  -->
            </div> 
        </div>            
    </div>
    <div class="text-right" [class.footerbtn]="isMobile">
        <button *ngIf="!isEdit && !isDisableForm" class="btn-positive"  [class.w-100]="isMobile" [ngClass]="{'mb-2 mr-3': !isMobile}" [class.btn-negative]="transType=='S'" mat-flat-button (click) = 'checkValidation()' [disabled]="confrmFlag"><span>{{'all.create' | translate}} {{'all.gtt' | translate}}</span></button>
        <button *ngIf="isEdit && !isDisableForm" class="btn-positive"  [class.w-100]="isMobile" [ngClass]="{'mb-2 mr-3': !isMobile}" [class.btn-negative]="transType=='S'" mat-flat-button (click) = 'checkValidation()'><span>{{'all.edit' | translate}} {{'all.gtt' | translate}}</span></button>
        <button *ngIf="isDisableForm" class="btn-primary"  [class.w-25]="isMobile" [ngClass]="{'mb-2 mr-3': !isMobile}" mat-flat-button (click) = 'onEdit()' [disabled]="confrmFlag"><span>{{'all.edit' | translate}}</span></button>
        <button *ngIf="isDisableForm" class="btn-positive"  [class.w-75]="isMobile" [ngClass]="{'mb-2 mr-3': !isMobile}" [class.btn-negative]="transType=='S'" mat-flat-button (click) = 'onConfirm()' [disabled]="confrmFlag"><span>{{'all.confirm_btn' | translate}}</span></button>        
    </div>    
</div>