<app-header (onTitleClick)="onTitleClick()" *ngIf="isMobile" goback="1" [title]="scannerTypes[dataType]" [isLoading]="isLoading" (reloadPage) = "reloadData()"></app-header>
<mat-card class="webpanelnew" >
    <mat-card-content>
        <div #sectionNeedToScroll class = "pt-3">
            <div class="row" [class.data-container]="isMobile">
                <div class="col-12">
                    <div class="panelsubheader">
                        <div class="row">
                            <div class="col-5">
                                <mat-form-field *ngIf='dropDown'>
                                    <mat-label translate>scanners.scanner_type</mat-label>
                                    <mat-select [(value)]="selected" (selectionChange)="onSelection($event.value)">
                                        <mat-option *ngFor="let data of dropDownValue" [value]="data.value">
                                            {{data.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field *ngIf='indexDropDown'>
                                    <mat-label translate>dashboard.index</mat-label>
                                    <mat-select [(value)]="selectedIndex" (selectionChange)="onIndexDropDownSelection($event.value)">
                                        <mat-option *ngFor="let data of indexArray" [value]="data.ptps_idx">
                                            {{data.displayname}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-5">
                                <mat-form-field *ngIf='typeDrownDown'>
                                    <mat-label translate>scanners.scanner_type</mat-label>
                                    <mat-select [(value)]="selected" (selectionChange)="onTypeDropDownSelection($event.value)">
                                        <mat-option *ngFor="let data of dropDownValue" [value]="data.viewValue">
                                            {{data.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2 text-right p-2">
                                <i class="icon-help infoicon2 helpicon" [matTooltip]="selectedTool | translate" ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ngx-pull-to-refresh
            spinnerColor="#ff0000"
            distanceForRefresh="80"
            [isEnable]="isMobile"
            [targetElement]="targetElement"
            (refresh)="myRefreshEvent($event, 'refresh')"
            (loadMore)="alert('loadmore')"
            class = "loader">
            <div *ngIf="(isMobile || cellview) && !isLoading && !isNodata"  [ngStyle]="Height">
                <div *ngFor="let option of multipleScripData; let i = index" [attr.id]="'ob_cell' + i" [class.wlweb]="cellview" [class.pl-2]="cellview" [class.pr-2]="cellview" (click)="highlight(option, i)" tabindex="999" >
                    <div class="row">
                        <div class="col-12" [ngClass]="{'highlight': selectedRowIndex == i}">
                            <div class="watchlistwrapper" [ngClass]="{'d-block d-sm-none' : isMobile}">
                                <div class="row">
                                    <div class="col-7">
                                        <div class="firstline">{{option.TradingSymbol | symbolpipe}} <small>{{option.Exchange}}</small></div>
                                        <div class="line2 subcolor">{{option.CompanyName === '' ? '-' : option.CompanyName}}</div>
                                    </div>
                                    <div [class.imnegative]="option.Change<0" [class.impositive]="option.Change>0" class="col-5 text-right">
                                        <div>
                                            <div class="firstline"><span [class.imnegativebg]="option.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="option.TickDirection === 1 && flashType !== 'noFlash'">{{option.LastTradePrice | pricepipe:option.DecimalPrecision}}</span><br>
                                                <span class="line2" [class.imnegativebg]="option.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="option.TickDirection === 1 && flashType === 'defaultFlash'">{{option.Change | pricepipe:option.DecimalPrecision}} ({{option.PercentageChange | percentagepipe}})</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="line2 subcolor" *ngIf="selected.includes('50DMA')">50 DMA: {{option.dmaFifty | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="selected.includes('200DMA')">200 DMA: {{option.dmaTwoHundred | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="selected.includes('macd')">MACD: {{option.macd | pricepipe}} Signal Line: {{option.signalLine | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="selected.includes('rsi')">RSI: {{option.rsi | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="selected == 'openhigh'">Open: {{option.OpenPrice | pricepipe}} High: {{option.High | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="selected == 'openlow'">Open: {{option.OpenPrice | pricepipe}} Low: {{option.Low | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="selected == 'ltplow' || selected == 'ltphigh'">Low: {{option.Low | pricepipe}} High: {{option.High | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="selected == '52wkhigh' || selected == '52wklow'">52 Week Low: {{option.wklow | pricepipe}} 52 Week High: {{option.wkhigh | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="selected == 'ltpupckt' || selected == 'ltplowckt'"> Lower Circuit: {{option.LCircuitLimit | pricepipe}} Upper Circuit: {{option.HCircuitLimit | pricepipe}}</div>
                                        <div class="line2 subcolor" *ngIf="dataType == 'Volume Shocker'">Prv LTP1: {{option.pltp1 | pricepipe}} Prv LTP2: {{option.pltp2 | pricepipe}} <br/>Prv Vol1: {{option.pvol1 | numberpipe}} Prv Vol2: {{option.pvol2 | numberpipe}}</div>
                                        <div class="line2 subcolor" *ngIf="dataType == 'Resistance & Support'">R1: {{option.ResistanceOne | pricepipe}} R2: {{option.ResistanceTwo | pricepipe}} R3: {{option.ResistanceThree | pricepipe}}  PP: {{option.Pivot | pricepipe}}
                                            <br/>S1: {{option.SupportOne | pricepipe}} S2: {{option.SupportTwo | pricepipe}} S3: {{option.SupportThree | pricepipe}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wl-btn" *ngIf="cellview" [class.d-none]="!M.IS_MOUSE">
                            <button (click)='openPlaceOrder("B", option)' class="ml-2 btn-positive" matTooltip="{{'books.buy' | translate}}" mat-icon-button><i class="icon-bb"></i></button> 
                            <button (click)='openPlaceOrder("S", option)' class="ml-2 btn-negative" matTooltip="{{'books.sell' | translate}}" mat-icon-button ><i class="icon-sb"></i></button>
                            <button *ngIf="createGttFlag" matTooltip="{{'all.create_gtt' | translate}}" (click)='openTrigger(option)' class="btn-primary ml-2" mat-icon-button><i class="icon-aim"></i></button>
                            <button mat-icon-button (click) = "addWLdata(option)" matTooltip="{{'search.addto_watchlist' | translate}}"  class="btn-primary ml-2" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                            <mat-menu #menu="matMenu">
                            <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                            <ng-container *ngFor = "let data of WlArr">
                                <button mat-menu-item (click) = "addStock(data.wlName, option)" [disabled] = "data.isDisable">
                                    <span>{{data.wlName}}</span>
                                </button>
                            </ng-container>
                            </mat-menu>
                            <button matTooltip="{{'tooltip.stock_detail' | translate}}" (click)='openStockDetail(option)' class="ml-2 btn-primary" mat-icon-button><i class="icon-ib"></i></button> 
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!cellview && !isLoading && !isNodata">
                <div class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'" [class.tabletscroll]="utility.IS_TABLET">
                <table mat-table [dataSource]="multipleScripData">

                    <ng-container matColumnDef="TradingSymbol" sticky>
                    <th mat-header-cell *matHeaderCellDef translate> stock_detail.symbol </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="button-row">
                            <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true" [class.d-none]="!M.IS_MOUSE">
                                <button (click)='openPlaceOrder("B", element)' matTooltip="{{'books.buy' | translate}}" class="btn-positive" mat-icon-button><i class="icon-bb"></i></button> 
                                <button (click)='openPlaceOrder("S", element)' matTooltip="{{'books.sell' | translate}}" class="btn-negative" mat-icon-button ><i class="icon-sb"></i></button>
                                <button *ngIf="createGttFlag" matTooltip="{{'all.create_gtt' | translate}}" (click)='openTrigger(element)' class="btn-primary" mat-icon-button><i class="icon-aim"></i></button>
                                <button mat-icon-button (click) = "addWLdata(element)" matTooltip="{{'search.addto_watchlist' | translate}}"  class="btn-primary" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                <mat-menu #menu="matMenu">
                                <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                <ng-container *ngFor = "let data of WlArr">
                                    <button mat-menu-item (click) = "addStock(data.wlName, element)" [disabled] = "data.isDisable">
                                        <span>{{data.wlName}}</span>
                                    </button>
                                </ng-container>
                                </mat-menu>
                                <button matTooltip="{{'tooltip.stock_detail' | translate}}" (click)='openStockDetail(element)' class="btn-primary" mat-icon-button><i class="icon-ib"></i></button> 
                            </div>
                        </span>
                        {{element.TradingSymbol | symbolpipe}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="CompanyName">
                    <th mat-header-cell *matHeaderCellDef translate>stock_detail.company_name</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.CompanyName === '' ? '-' : element.CompanyName}} </span></td>
                    </ng-container>
                    <ng-container matColumnDef="LastTradePrice">
                        <th mat-header-cell *matHeaderCellDef translate class="text-right"> stock_detail.ltp </th>
                        <td mat-cell *matCellDef="let element" class="text-right" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0"> 
                            <span [class.imnegativebg]="element.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType !== 'noFlash'">
                                {{element.LastTradePrice | pricepipe:element.DecimalPrecision}} 
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Change">
                        <th mat-header-cell *matHeaderCellDef translate class="text-right"> stock_detail.change </th>
                        <td mat-cell *matCellDef="let element" class="text-right" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">
                            <span [class.imnegativebg]="element.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType === 'defaultFlash'">
                                {{element.Change | pricepipe:element.DecimalPrecision}} 
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="PercentageChange">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> % {{'stock_detail.change'| translate}} </th>
                        <td mat-cell *matCellDef="let element" class="text-right" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0"> 
                            <span [class.imnegativebg]="element.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType === 'defaultFlash'">
                                {{element.PercentageChange | percentagepipe}} 
                            </span>
                        </td>
                    </ng-container>


                    <ng-container *ngIf="dataType == 'Resistance & Support'" matColumnDef="R1">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> R1 </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.ResistanceOne | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Resistance & Support'" matColumnDef="R2">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> R2 </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.ResistanceTwo | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Resistance & Support'" matColumnDef="R3">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> R3 </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.ResistanceThree | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Resistance & Support'" matColumnDef="S1">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> S1 </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.SupportOne | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Resistance & Support'" matColumnDef="S2">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> S2 </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.SupportTwo | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Resistance & Support'" matColumnDef="S3">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> S3 </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.SupportThree | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Resistance & Support'" matColumnDef="PIVOT">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> PP </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.Pivot | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
 
                    <ng-container *ngIf="dataType == 'High/Low Breakers'" matColumnDef="52 WEEK HIGH">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> 52 Week High </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.wkhigh | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'High/Low Breakers'" matColumnDef="52 WEEK LOW">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> 52 Week Low </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.wklow | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>

                    <ng-container *ngIf="dataType == 'Technical Scanners' " matColumnDef="50DMA">
                        <th mat-header-cell *matHeaderCellDef class="text-right">50 DMA</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.dmaFifty | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>

                    <ng-container *ngIf="dataType == 'Technical Scanners' " matColumnDef="200DMA">
                        <th mat-header-cell *matHeaderCellDef class="text-right">200 DMA</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.dmaTwoHundred | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>

                    <ng-container *ngIf="dataType == 'Technical Scanners'" matColumnDef="RSI">
                        <th mat-header-cell *matHeaderCellDef class="text-right">RSI</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.rsi | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>

                    <ng-container *ngIf="dataType == 'Technical Scanners'" matColumnDef="MACD">
                        <th mat-header-cell *matHeaderCellDef class="text-right">MACD</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.macd | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Technical Scanners'" matColumnDef="SIGNAL LINE">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Signal Line</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.signalLine | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Circuit Breakers'" matColumnDef="Low Circuit Limit">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Lower Circuit</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.LCircuitLimit | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Circuit Breakers'" matColumnDef="High Circuit Limit">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Upper Circuit</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.HCircuitLimit | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Open High/Low'" matColumnDef="Open">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Open </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.OpenPrice | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>

                    <ng-container *ngIf="dataType == 'Volume Shocker'" matColumnDef="pltp1">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Prv LTP 1</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.pltp1 | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Volume Shocker'" matColumnDef="pltp2">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Prv LTP 2</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.pltp2 | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Volume Shocker'" matColumnDef="vol">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Volume</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.TradeVolume | numberpipe}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Volume Shocker'" matColumnDef="pvol1">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Prv Vol 1</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.pvol1 | numberpipe}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Volume Shocker'" matColumnDef="pvol2">
                        <th mat-header-cell *matHeaderCellDef class="text-right">Prv Vol 2</th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.pvol2 | numberpipe}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Open High/Low' || dataType == 'High/Low Breakers'" matColumnDef="High">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> High </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.High | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container *ngIf="dataType == 'Open High/Low' || dataType == 'High/Low Breakers'" matColumnDef="Low">
                        <th mat-header-cell *matHeaderCellDef class="text-right"> Low </th>
                        <td mat-cell *matCellDef="let element" class="text-right"> {{element.Low | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" [ngClass]="{'highlight': selectedRowIndex == i}" (click)="highlight(row, i)" tabindex="999" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)" [attr.id]="'ob_tbl' + i"></tr>
                </table>
                </div>
            </ng-container>

            <ng-container *ngIf='!isLoading && isNodata' class='nodata'>
                <app-nodata (refreshEvent) = "reloadData()" message = "N0 DATA" [btnTitle]="'utility.reload' | translate"></app-nodata>
            </ng-container>
            </ngx-pull-to-refresh>
            
        </div>
    </mat-card-content>
</mat-card>