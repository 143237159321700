import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility, ChannelType } from '../../../../services/utility';
import { ScripModel } from '../../../../model/scrip.model';
import { TradingRestService } from '../../../../services/tradingrest.service';
import { IScripService } from '../../../../services/scrip/iscrip.service';
import { PlaceOrderModel } from '../../../../model/place.order.model';
import { Keys } from '../../../../services/keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { Subject } from '../../../../../../node_modules/rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from '../../../../../../src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MyAlertDialogComponent } from '../../home/watchlist/watchlist.component';
import { element } from 'protractor';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Preferences } from 'src/app/services/Preferences';
@Component({
  selector: 'app-spreadplaceorder',
  templateUrl: './spreadplaceorder.component.html',
  styleUrls: ['./spreadplaceorder.component.scss'],
  animations: [
    trigger('slideInOut', [
        state('', style({

        })),
        transition('initial=>final', [
          style({ height: '0px', opacity: '0' }),
          animate('400ms ease-in', style({ height: '200px', opacity: '0.2' })),
        ]),
        transition('final=>initial', [
          animate('400ms ease-in', style({ height: '0px', opacity: '0' }))
        ]),
    ])
  ]
})
export class SpreadPlaceorderComponent implements OnChanges, OnInit, OnDestroy {
  constructor(private activatedRoute: ActivatedRoute, private tradingRestService: TradingRestService,
    private snackBar: MatSnackBar, private location: Location, private _bottomSheet: MatBottomSheet, private translate: TranslateService, public dialog: MatDialog, private router: Router) {
    this.isMobile = Utility.IS_MOBILE;
    this.isPopup = Utility.IS_POPUP;
    this.scripService = tradingRestService.getScripService();
    this.userdata = JSON.parse(Preferences.getPreference('userParameter'));
    if (environment.isQuickRest) {
      this.orarr = this.userdata['ordArr'];
    }
    else {
      this.orarr = this.userdata['orarr'];
    }
    this.GTDMindate = new Date();
    this.pom = new PlaceOrderModel();

    Utility.changeTranctionType.subscribe(ele =>{
      this.pom.Ttranstype = ele;
    })

    document.addEventListener('keypress', this.onSubmitForm.bind(this))
  }
  //static ORDER_PLACED_EVENT = new Subject<string>();
  scrpModel: ScripModel;
  transectionType: string;
  pom: PlaceOrderModel;
  productArr = [];
  advancedProductArray = [];
  orderTypeArray = ['SP'];
  basicOrderTypeArray = ['L', 'MKT'];
  stopLossOrderTypeArray = ['SL', 'SL-M'];
  BOOrderTypeArray = ['L', 'SL'];
  orarr = [];
  scripService: IScripService;
  showAdvanced = false;
  allRetentionTypeArray = [];
  retentionTypeArray = [];
  isSL = false;
  isPlaceorder = true;
  isLot = false;
  ticksize;
  isDiscQty = true;
  GTDMindate;
  GTDVal;
  GTTval;
  GTDayaVal;
  daysArr = ['1', '2', '3', '4', '5', '6', '7'];
  discQtyValidationMsg;
  isEdit = false;
  orderData;
  isReverseOrder = false;
  isLTPCoverageCallInProgress = false;
  isCoveragePercentageCalled = false;
  isOrderType = true;
  isQtyDisable = false;
  isEnablePrice = true;
  isSquareOff = false;
  holdingData;
  reqCount = 0;
  isMktPro = false;
  userdata;
  isMobile = true;
  isAddRemove = false;
  @ViewChild('inputQty', { static: false }) inputQty: ElementRef;
  @ViewChild('inputPrice', { static: false }) inputPrice: ElementRef;
  @ViewChild('inputTriggerPrice', { static: false }) inputTriggerPrice: ElementRef;
  @ViewChild('discQty', { static: false }) discQty: ElementRef;
  @ViewChild('inputSquareOffvalue', { static: false }) inputSquareOffvalue: ElementRef;
  @ViewChild('inputStoplossValue', { static: false }) inputStoplossValue: ElementRef;
  @ViewChild('inputMktPro', { static: false }) inputMktPro: ElementRef;
  isPopup;
  verityType = 'RGLR';
  isProductType = true;
  orderObjArr = [];
  isOrderSummary = false;
  @Input() nodepth = false;
  @Input() omtkn: string;
  @Input() exseg: string;
  @Input() action: string;
  @Input() fromMultiView = false;
  isShowDepth = false;
  depthAnimation = 'initial'
  isDisableForm = false;
  submitType;
  squareOffvalue;
  stoplossValue;
  lotError = true;
  isRemove = false;
  filledQuantity = 0;
  ngOnChanges() {
    if (this.fromMultiView) {
      this.isPopup = true;
      const obj = { action: "B", exseg: this.exseg, omtkn: this.omtkn };
      this.initPlaceorder(obj);
    }
  }

  initPlaceorder(params) {
    this.isDisableForm = false;
    if (params.isEdit) {
      this.isEdit = true;
      this.showAdvanced = true;
      this.orderData = Utility.spreadOrderDetail;
      this.pom.Ttranstype = this.orderData.Action;
      this.pom.exch = this.orderData.Exchange;
      this.pom.Exchangeseg = this.orderData.ExchangeSegment;
      this.pom.Token = this.orderData.Token;
      this.scrpModel = Utility.getScripModel(this.pom.Token, this.pom.Exchangeseg, this.tradingRestService);
      this.updateOrderData();
      if (this.orderData.ProductCode == 'CO') {
        this.filterCOEditCondtions();
      }
      this.filledQuantity = this.orderData.FilledShares;
    }
    // else if (params.isSquareOff) {
    //   this.isSquareOff = true;
    //   this.holdingData = Utility.holdingDetails;
    //   this.pom.Ttranstype = params['action'];
    //   this.pom.exch = Keys.key_map[params['exseg']];
    //   this.pom.Exchangeseg = params['exseg'];
    //   this.pom.Token = this.holdingData.Token;
    //   this.scrpModel = Utility.getScripModel(this.pom.Token, this.pom.Exchangeseg);
    //   this.updateHoldingData();
    // }
    // else if (params.add || params.remove) {
    //   this.isAddRemove = true;
    //   if (params.remove)
    //     this.isRemove = true;
    //   this.pom.Ttranstype = params.add ? 'B' : 'S';
    //   this.pom.exch = params.Exchange;
    //   this.pom.Exchangeseg = Utility.exchangeToSegment(this.pom.exch);
    //   this.pom.Token = params.Token;
    //   this.pom.prctyp = params.OrderType;
    //   this.pom.Pcode = params.ProductCode;
    //   this.pom.qty = params.qty;
    //   this.scrpModel = Utility.getScripModel(this.pom.Token, this.pom.Exchangeseg);
    // }
    else {
      // this.pom.prctyp = Utility.PLACEORDERSETTINGSMODEL.orderType;
      this.pom.Ttranstype = params['action'];
      this.pom.exch = Keys.key_map[params['exseg']];
      this.pom.Exchangeseg = params['exseg'];
      this.pom.Token = params['omtkn'];
      this.scrpModel = Utility.getScripModel(this.pom.Token, this.pom.Exchangeseg, this.tradingRestService);
    }
      this.scripService.showQuote(this.scrpModel, true, this.showQuoteResponse.bind(this));
      this.filterProducts(this.userdata, this.pom.Exchangeseg);
      this.scripService.getRetention(this.pom.exch, this.getRetentionRes.bind(this));
      this.scripService.getLotWeight(this.getLotWeightRes.bind(this), this.setPrdefinedLotWeight.bind(this));
      this.scripService.getSecInfo({ 'exch': this.pom.exch, 'tk': this.pom.Token }, this.getSecInfoRes.bind(this));
      this.filterExchDepencies();
      this.filterOrderTypeArray();  
      if (this.scrpModel.Token == 'asdfg') {
        this.isDisableForm = true;
      }
      if (!this.isEdit && !this.isSquareOff && !this.isAddRemove)
      {
        if (this.productArr.length === 0)
          this.isPlaceorder = false;
        else
        this.isPlaceorder = true;
        this.pom.Pcode = this.productArr[0];
      }
  }

  getRetentionRes(data, code, message) {
    if (code === Keys.RES_CODE_SUCCESS) {
      this.allRetentionTypeArray = data;
      this.filterRetentionData();
    }
    else {
    }
  }
  priceFormDepth(data) {
    if (this.pom.prctyp === 'L') {
      this.pom.Price = data;
    }
  }
  getLotWeightRes(data, code, message) {
    if (code === Keys.RES_CODE_SUCCESS && Object.keys(data).length) {
      if (Object.keys(data).includes(this.pom.exch) && data[this.pom.exch] === 'Lots')
        this.isLot = true;
      else
      {
        if (!this.isEdit && !this.isSquareOff && !this.isRemove)
          setTimeout(function(){
            this.pom.qty = this.scrpModel.BodLotQuantity;
          }.bind(this), 1000);
      }
    } else {
      if (!this.isEdit && !this.isSquareOff && !this.isRemove)
        this.pom.qty = this.scrpModel.BodLotQuantity;
    }
  }

  setPrdefinedLotWeight(data) {
    if (Object.keys(data).includes(this.pom.exch) && data[this.pom.exch] === 'Lots') {
      this.isLot = true;
    }
  }

  getSecInfoRes(data, code, message) {
    if (code === Keys.RES_CODE_SUCCESS) {
      // this.LCL = data.LcircuitLimit;
      // this.HCL = data.HcircuitLimit;
      this.scrpModel.BodLotQuantity = data.BodLotQuantity;
    } else {
    }
  }
  showQuoteResponse(sm, code, msg) {
    // this.pom.exch = this.scrpModel.ExchangeName;
    if (!this.isEdit && !this.isSquareOff && !this.isAddRemove) {
      this.pom.Tsym = sm.TradingSymbol;
      if (this.pom.Ttranstype === 'B')
        this.pom.Price = this.scrpModel.BestSellPrice;
      else
        this.pom.Price = this.scrpModel.BestBuyPrice;
    }

    if (this.scrpModel.NoMarketProtectionFlag) {
      this.isMktPro = false;
      if (this.pom.prctyp === 'MKT' || this.pom.prctyp === 'SL-M')
        this.pom.MktPro = '0';
      else
        this.pom.MktPro = 'NA';
    }

    if (this.scrpModel.MarketProtection) {
      if (this.pom.prctyp === 'MKT' || this.pom.prctyp === 'SL-M') {
        this.isMktPro = true;
        this.isEnablePrice = false;
        this.pom.MktPro = this.scrpModel.DefaultMarketProtectionValue;
      }
    }

    this.ticksize = Utility.getTickSize(this.scrpModel.TickSize, this.scrpModel.Multiplier, this.scrpModel.DecimalPrecision);
  }
  ngOnInit() {
    if (!this.fromMultiView) {
      if (Utility.IS_MOBILE) {
        this.activatedRoute.queryParams.subscribe(params => {
          this.initPlaceorder(params);
        });
      }
      else {
        this.initPlaceorder(Utility.dialogData);
      }
    }
    this.onAppearing();
  }
  changeTransectionType(trtype) {
    this.pom.Ttranstype = trtype;
    if (this.pom.Pcode === 'CO')
      this.calculateCOTriggerRabge();
  }
  placeOrder() {
    this.isPlaceorder = false;
    if (!this.isEdit)
      this.submitType = 'placeorder';
    else
    {
      this.pom.qty = Utility.getNumber(this.pom.qty) + Utility.getNumber(this.filledQuantity);
      this.submitType = 'modify';
    }
    this.showAlert();
  }

  filterProducts(userdata, exch) {
    this.productArr = [];
    this.advancedProductArray = [];
    let exchDeatilObj;
    if (environment.isQuickRest) {
      exchDeatilObj = userdata.exDtl;
    }
    else {
      exchDeatilObj = userdata.exchDeatil;
    }
    Object.keys(exchDeatilObj).forEach(el => {
      const arr = exchDeatilObj[el];
      let products;
      for (let i = 0; i < arr.length; i++) {
        if (environment.isQuickRest) {
          if (arr[i].exSeg === exch) {
            products = arr[i].prod;
            for (let j = 0; j < userdata['prodArr'].length; j++) {
              const ele = userdata['prodArr'][j];
              if (products.indexOf(ele) !== -1) {
                if (ele === 'NRML' || ele === 'I')
                  this.productArr.push(ele);
              }
            }
            break;
          }
        }
        else {
          if (arr[i].exchseg === exch) {
            products = arr[i].product;
            for (let j = 0; j < userdata['prarr'].length; j++) {
              const ele = userdata['prarr'][j];
              if (products.indexOf(ele) !== -1) {
                if (ele === 'BO' || ele === 'CO')
                  this.productArr.push(ele);
              }
            }
            break;
          }
        }
      }
    });

    // if (!this.isMobile)
    //   this.productArr = this.productArr.concat(this.advancedProductArray);

    if (!this.isEdit && !this.isSquareOff && !this.isAddRemove)
    {
      if (this.productArr.length === 0)
        this.isPlaceorder = false;
      this.pom.Pcode = this.productArr[0];
    }
  }

  onAdvanced() {
    if (!this.showAdvanced)
      this.showAdvanced = true;
    else
      this.showAdvanced = false;
  }

  filterRetentionData() {
    this.retentionTypeArray = [];
    if (this.pom.Pcode === Keys.PRODUCT_CO) {
      this.retentionTypeArray.push(Keys.RETENTION_DAY);
    }
    else {
      Array.prototype.push.apply(this.retentionTypeArray, this.allRetentionTypeArray);

      if (this.isSL && this.retentionTypeArray.includes(Keys.RETENTION_IOC)) {
        this.retentionTypeArray.splice(this.retentionTypeArray.indexOf(Keys.RETENTION_IOC), 1);
      }
    }

    if (this.pom.Pcode === Keys.PRODUCT_BO && this.pom.exch === Keys.EXCHANGE_BSE && this.retentionTypeArray.includes(Keys.RETENTION_EOS))
    {
      this.retentionTypeArray.splice(this.retentionTypeArray.indexOf(Keys.RETENTION_EOS), 1);
    }

    if (this.retentionTypeArray.length) {
      this.pom.Ret = this.retentionTypeArray[0];
    }
    else
      this.isPlaceorder = false;
  }

  onAMO(ev) {
    if (ev.checked)
      this.pom.AMO = 'YES';
    else
      this.pom.AMO = 'NO';
  }

  onOrderTypeChange(ev) {
    this.orderTypeDependencies(ev.value)

    this.filterRetentionData();
    // this.filterOrderTypeArray();
  }

  orderTypeDependencies(value)
  {
    if (value === 'MKT' || value === 'SL-M') {
      this.pom.Price = '0';
      this.isEnablePrice = false;
      this.isMktPro = true;
      if (this.isMktPro)
        this.pom.MktPro = this.scrpModel.DefaultMarketProtectionValue;
      else
        this.pom.MktPro = '0';
    }
    else {
      if (!this.isEdit && !this.isSquareOff && !this.isAddRemove) {
        if (this.pom.Ttranstype === 'B')
          this.pom.Price = this.scrpModel.BestSellPrice;
        else
          this.pom.Price = this.scrpModel.BestBuyPrice;
      }
      this.isEnablePrice = true;
      this.isMktPro = false;
      this.pom.MktPro = 'NA';
    }

    if (value === 'SL' || value === 'SL-M')
    {
      this.isSL = true;
    }
    else
      this.isSL = false;
  }

  onStopLoss(ev) {
    if (ev.checked) {
      this.isSL = true;
      // if (this.pom.prctyp === 'L')
      //   this.pom.prctyp = 'SL';
      // else if (this.pom.prctyp === 'MKT')
      //   this.pom.prctyp = 'SL-M';
    }
    else {
      this.isSL = false;
      // if (this.pom.prctyp === 'SL')
      //   this.pom.prctyp = 'L';
      // else if (this.pom.prctyp === 'SL-M')
      //   this.pom.prctyp = 'MKT';
    }

    this.filterRetentionData();
    this.filterOrderTypeArray();
  }

  validateQty(ev) {
    const regex = new RegExp('^[0-9]{0,8}$|^[0-9]{1,' + 2 + '}$');
    const val = this.inputQty.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.key, 'qty')
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputQty.nativeElement.dispatchEvent(new Event('input'));
      this.inputQty.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  validatePrice(ev) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputPrice.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.key, 'price');
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputPrice.nativeElement.dispatchEvent(new Event('input'));
      this.inputPrice.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  validateKey(key, str)
  {
    if (/[0-9]/i.test(key))
    {
      if (str === 'price')
        this.snackBar.open(this.translate.instant('limits.max_bfr_digits') + this.translate.instant('limits.max_aft_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      else if (str === 'mkt')
        this.snackBar.open(this.translate.instant('placeorder.max_two_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      else
        this.snackBar.open(this.translate.instant('placeorder.max_sevn_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (/[a-zA-Z]/i.test(key))
      this.snackBar.open(this.translate.instant('limits.only_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
  }

  validateTriggerPrice(ev) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputTriggerPrice.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.key, 'price');
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputTriggerPrice.nativeElement.dispatchEvent(new Event('input'));
      this.inputTriggerPrice.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  validateMKTPro(ev)
  {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputMktPro.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex) || Utility.getNumber(val) > 99) {
      this.validateKey(ev.key, 'mkt')
      if (Utility.getNumber(val) > 99)
        this.snackBar.open(this.translate.instant('placeorder.max_mrktprotect'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputQty.nativeElement.dispatchEvent(new Event('input'));
      this.inputQty.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  validateDiscQty(ev) {
    const regex = new RegExp('^[0-9]{0,8}$|^[0-9]{1,' + 2 + '}$');
    const val = this.discQty.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.key, 'qty')
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.discQty.nativeElement.dispatchEvent(new Event('input'));
      this.discQty.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  validateSquareOffvalue(ev) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputSquareOffvalue.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.keyCode, 'price');
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputSquareOffvalue.nativeElement.dispatchEvent(new Event('input'));
      this.inputSquareOffvalue.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  validateStoplossValue(ev) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputStoplossValue.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.keyCode, 'price');
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputStoplossValue.nativeElement.dispatchEvent(new Event('input'));
      this.inputStoplossValue.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  placeOrderRes(data, code, message) {
    this.isPlaceorder = true;
    if (code === Keys.RES_CODE_SUCCESS) {
      this.isOrderSummary = true;
      //SpreadPlaceorderComponent.ORDER_PLACED_EVENT.next('true');
      this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
        if (resCode === Keys.RES_CODE_SUCCESS) {
          if (environment.isQuickRest) {
            this.orderObjArr = list.filter(el => (el.NestOrd === data.nOrdNo || el.SyomOrderId.split('|')[0] === data.nOrdNo));
          }
          else {
            this.orderObjArr = list.filter(el => (el.NestOrd === data.NOrdNo || el.SyomOrderId.split('|')[0] === data.NOrdNo));
          }
        }
      }.bind(this), this.setPreDefinedOrders.bind(this));
    }
    else {
      this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  setPreDefinedOrders() { }

  floatSafeModulus(val, step) {
    if (val && step)
    {
      const valDecCount = (val.toString().split('.')[1] || '').length;
      const stepDecCount = (step.toString().split('.')[1] || '').length;
      const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
      let valInt;
      if (val !== undefined && val !== null && val !== NaN)
        valInt = parseInt(Utility.getNumber(val).toFixed(decCount).replace(/\./g, ''), 10);
      const stepInt = parseInt(Utility.getNumber(step).toFixed(decCount).replace(/\./g, ''), 10);
      return (valInt % stepInt) / Math.pow(10, decCount);
    }
    else
      return 0;
  }

  onRetentionChange(ev) {
    this.filterExchDepencies();
  }

  filterExchDepencies() {
    if ((this.pom.exch === Keys.EXCHANGE_NFO || this.pom.exch === Keys.EXCHANGE_BFO) || (this.pom.Pcode === Keys.PRODUCT_CO || this.pom.Pcode === Keys.PRODUCT_BO) ||
      this.pom.Ret === 'IOC') {
      this.pom.discqty = '0';
      this.isDiscQty = false;
    }
    else
      this.isDiscQty = true;
  }

  onSubmit() {
    if (!this.pom.qty || this.pom.qty.length > 7)
    {
      this.snackBar.open(this.translate.instant('placeorder.invalid_qty'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isEnablePrice && (!this.pom.Price || !this.checkPriceLength(this.pom.Price)))
    {
      this.snackBar.open(this.translate.instant('placeorder.invalid_price'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isMktPro && (!this.pom.MktPro || Utility.getNumber(this.pom.MktPro) > 99))
    {
      this.snackBar.open(this.translate.instant('placeorder.invalid_mktpro'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (!this.isLot && Utility.getNumber(this.scrpModel.BodLotQuantity) > 1 &&
      this.floatSafeModulus(Utility.getNumber(this.pom.qty), Utility.getNumber(this.scrpModel.BodLotQuantity)) !== 0) {
      this.snackBar.open(this.translate.instant('placeorder.qtymultiple') + this.scrpModel.BodLotQuantity + '.', this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    // else if (this.isEnablePrice && (this.scrpModel.HcircuitLimit && this.scrpModel.LcircuitLimit) && (Utility.getNumber(this.pom.Price) < Utility.getNumber(this.scrpModel.LcircuitLimit) ||
    //   Utility.getNumber(this.pom.Price) > Utility.getNumber(this.scrpModel.HcircuitLimit))) {
    //   this.snackBar.open(this.translate.instant('placeorder.pricebtn') + this.scrpModel.LcircuitLimit + ' & ' + this.scrpModel.HcircuitLimit + '.', 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
    // }
    else if (this.isEnablePrice && (this.floatSafeModulus(this.pom.Price, this.ticksize) !== 0)) {
      this.snackBar.open(this.translate.instant('placeorder.pricemultiple') + this.ticksize + '.', this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (Utility.getNumber(this.pom.qty) <= 0) {
      this.snackBar.open(this.translate.instant('placeorder.qtyequal'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isEnablePrice && Utility.getNumber(this.pom.Price) <= 0) {
      this.snackBar.open(this.translate.instant('placeorder.priceequal'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isSquareOff && this.pom.qty > this.holdingData.AvailableQty) {
      this.snackBar.open(this.translate.instant('placeorder.qtyless') + this.holdingData.AvailableQty, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if ((this.isSL || this.pom.Pcode === 'CO') && (!Utility.getNumber(this.pom.TrigPrice) || !this.checkPriceLength(this.pom.TrigPrice))) {
      this.snackBar.open(this.translate.instant('placeorder.invalidtrigger'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.pom.Pcode === 'CO' && (this.pom.TrigPrice > this.scrpModel.TriggerRangeEnd || this.pom.TrigPrice < this.scrpModel.TriggerRangeStart)) {
      this.snackBar.open(this.translate.instant('placeorder.triggerRange'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if ((this.isSL || this.pom.Pcode === 'CO') && this.pom.Ttranstype === 'B' && this.isEnablePrice && this.pom.TrigPrice > this.pom.Price) {
      this.snackBar.open(this.translate.instant('placeorder.triggerbuy'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if ((this.isSL || this.pom.Pcode === 'CO') && this.pom.Ttranstype === 'S' && this.isEnablePrice && this.pom.TrigPrice < this.pom.Price) {
      this.snackBar.open(this.translate.instant('placeorder.triggersell'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isDiscQty && (this.discQtyValidationMsg = Utility.validateDiscQuantity(this.pom.qty, this.pom.discqty, this.pom.exch)) !== '') {
      this.snackBar.open(this.translate.instant(this.discQtyValidationMsg), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.pom.Pcode === 'BO' && !this.isEdit) {
      this.placeBOOrder();
    }
    // else if (this.pom.Ret === 'GTD' || this.pom.Ret === 'GTT' || this.pom.Ret === 'GTDys' || this.pom.prctyp === 'SL-M') {
    //   if (this.pom.prctyp === 'SL-M') {
    //     this.validateSLM();
    //   }
    //   else if (this.pom.Ret === 'GTD' || this.pom.Ret === 'GTT' || this.pom.Ret === 'GTDys') {
    //     this.validateGTD();
    //   }
    // }
    else {
      this.placeOrder();
    }
  }

  validateGTD() {
    let tempGTDVal;
    if (this.pom.Ret === 'GTD') {
      if (!this.GTDVal) {
        this.snackBar.open(this.translate.instant('placeorder.invaliddate'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        return 0;
      }
      else {
        this.GTDVal = new Date(this.GTDVal);
        const day = this.GTDVal.getDate() < '10' ? '0' + this.GTDVal.getDate() : this.GTDVal.getDate();
        const month = (Utility.getNumber(this.GTDVal.getMonth()) + 1) < '10' ? '0' + (Utility.getNumber(this.GTDVal.getMonth()) + 1) : (Utility.getNumber(this.GTDVal.getMonth()) + 1);
        const year = this.GTDVal.getFullYear();
        tempGTDVal = month + '/' + day + '/' + year;
      }
    }
    else if (this.pom.Ret === 'GTDys') {
      tempGTDVal = this.GTDayaVal;
    }
    else if (this.pom.Ret === 'GTT') {
      if (!this.GTTval) {
        this.snackBar.open(this.translate.instant('placeorder.invalidtime'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        return 0;
      }
      else {
        tempGTDVal = this.GTTval + ':00';
      }
    }

    this.reqCount++;
    const data = { 'Exchange': this.pom.exch, 'Expdate': this.scrpModel.ExpiryDate, 'Retention': this.pom.Ret, 'Gtdval': tempGTDVal }
    this.scripService.validateGTD(data, this.validateGTDRes.bind(this))
  }

  validateGTDRes(data, code, message) {
    this.reqCount--;
    if (code === Keys.RES_CODE_SUCCESS) {
      this.pom.DateDays = data;
      if (this.reqCount === 0)
        this.placeOrder();
    }
    else {
      this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  updateOrderData() {
    this.pom.Price = this.orderData.FillPrice;
    this.pom.qty = this.orderData.PendingQty;
    this.pom.Pcode = this.orderData.ProductCode;
    if (this.pom.Pcode === 'BO') {
      this.applyBOModifyConditions();
    }
    this.pom.prctyp = this.orderData.PriceType;
    if (this.pom.prctyp === 'SL' || this.pom.prctyp === 'SL-M')
      this.isSL = true;
    if (this.orderData.DiscQty)
      this.pom.discqty = this.orderData.DiscQty.toString();
    this.pom.Ret = this.orderData.Validity;
    this.pom.Nstordno = this.orderData.NestOrd;
    this.pom.Filledqty = this.orderData.FilledShares.toString();
    this.pom.Scripname = this.orderData.ScripName;
    this.pom.Symbol = this.orderData.SymbolName;
    this.pom.Status = this.orderData.Status;
    this.pom.Token = this.orderData.Token;
    this.pom.RequestID = this.orderData.RequestID;
    this.pom.Tsym = this.orderData.TSym;
    // this.pom.TrigPrice = this.orderData.TriggerPrice;
  }

  modifyOrderRes(data, code, message) {
    this.isPlaceorder = true;
    if (code === Keys.RES_CODE_SUCCESS) {
      this.isOrderSummary = true;
      //SpreadPlaceorderComponent.ORDER_PLACED_EVENT.next('true');
      this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
        if (resCode === Keys.RES_CODE_SUCCESS) {
          if (environment.isQuickRest) {
            this.orderObjArr = list.filter(el => (el.NestOrd === data.nOrdNo || el.SyomOrderId.split('|')[0] === data.nOrdNo));
          }
          else {
            this.orderObjArr = list.filter(el => (el.NestOrd === data.NOrdNo || el.SyomOrderId.split('|')[0] === data.NOrdNo));
          }
        }
      }.bind(this), this.setPreDefinedOrders.bind(this));
    }
    else {
      this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  onProductTypeChange(ev) {
    this.filterRetentionData();
    if (this.pom.Pcode === 'CO')
      this.calculateCOTriggerRabge();
    else if (this.pom.Pcode === 'BO') {
      this.calculateBOValues();
    }
    this.filterOrderTypeArray();
    this.filterExchDepencies();
  }

  calculateBOValues() {
    const value = this.scrpModel.LastTradePrice;
    const sqrOffPer = (Utility.getNumber(Utility.PLACEORDERSETTINGSMODEL.SqrOffPercentage) / 100) * Utility.getNumber(value);
    const stopLossPer = (Utility.getNumber(Utility.PLACEORDERSETTINGSMODEL.stoplossPercentage) / 100) * Utility.getNumber(value);
    let sqrOffvalue: any;
    let slvalue: any;
    if (this.pom.Ttranstype === 'B') {
      sqrOffvalue = (Utility.getNumber(value) + sqrOffPer).toFixed(this.scrpModel.DecimalPrecision);
      slvalue = (Utility.getNumber(value) - stopLossPer).toFixed(this.scrpModel.DecimalPrecision);
    }
    else {
      sqrOffvalue = (Utility.getNumber(value) - sqrOffPer).toFixed(this.scrpModel.DecimalPrecision);
      slvalue = (Utility.getNumber(value) + stopLossPer).toFixed(this.scrpModel.DecimalPrecision);
    }
    const squreOffMod = this.floatSafeModulus(sqrOffvalue, this.ticksize);
    const slMod = this.floatSafeModulus(slvalue, this.ticksize);
    sqrOffvalue = squreOffMod === 0 ? Utility.getNumber(sqrOffvalue).toFixed(this.scrpModel.DecimalPrecision) : (sqrOffvalue - squreOffMod).toFixed(this.scrpModel.DecimalPrecision);
    slvalue = slMod === 0 ? Utility.getNumber(slvalue).toFixed(this.scrpModel.DecimalPrecision) : (slvalue - slMod).toFixed(this.scrpModel.DecimalPrecision);
    // this.squareOffvalue = this.scrpModel.HcircuitLimit ? (sqrOffvalue < this.scrpModel.HcircuitLimit ? sqrOffvalue.toString() : this.scrpModel.HcircuitLimit.toString()): sqrOffvalue.toString();
    // this.stoplossValue = this.scrpModel.LcircuitLimit ? (slvalue > this.scrpModel.LcircuitLimit ? slvalue.toString() : this.scrpModel.LcircuitLimit.toString()): slvalue.toString();
  }

  onVerityTypeChange(ev) {
    this.pom.AMO = 'NO';
    this.isProductType = true;
    document.getElementById('I').classList.remove('mat-radio-checked');
    if (ev.value === 'RGLR') {
      if (this.pom.Pcode === 'BO' || this.pom.Pcode === 'CO')
        this.pom.Pcode = this.productArr[0];
    }
    else if (ev.value === 'BO' || ev.value === 'CO') {
      if (ev.value === 'CO')
        setTimeout(function(){document.getElementById('I').classList.add('mat-radio-checked')}, 100);
      this.pom.Pcode = ev.value;
      this.isProductType = false;
    }
    else {
      if (this.pom.Pcode === 'BO' || this.pom.Pcode === 'CO')
        this.pom.Pcode = this.productArr[0];
      this.pom.AMO = 'YES';
    }
    this.onProductTypeChange(ev);
  }

  calculateCOTriggerRabge() {
    const dataObj = { 'Exchange': this.pom.exch, 'Symbol': this.pom.Tsym, 'Transtype': this.pom.Ttranstype };
    if (this.isEdit && this.orderData.Status.toLowerCase() === 'trigger pending') {
      this.isReverseOrder = true;
      this.scrpModel.COLTP = Utility.getNumber(this.orderData.RefLmtPrice)
    }
    this.scripService.LTPCoverPrctge(dataObj, this.calculateCOTriggerRabgeRes.bind(this));
    this.isLTPCoverageCallInProgress = true;
  }

  calculateCOTriggerRabgeRes(data, code, message) {
    this.isLTPCoverageCallInProgress = false;
    this.isCoveragePercentageCalled = true;
    if (code === Keys.RES_CODE_SUCCESS) {
      this.scrpModel.CoverPercentage = data;
      if (this.isReverseOrder) {
        this.getCOPriceFlag();
      }
      else {
        this.calculateTriggerRange();
        if (!this.isEdit) {
          this.scrpModel.tracsactionType = this.pom.Ttranstype;
          this.scrpModel.isUpdateTriggerRange = true;
        }
      }
    }
    else {

    }
  }

  getCOPriceFlag() {
    this.scripService.coverOrderPriceFlag(this.coverOrderPriceFlagRes.bind(this))
  }

  coverOrderPriceFlagRes(data, code, message) {
    this.pom.IsCOPriceFlagEnabled = data;
    this.calculateTriggerRange();
  }

  calculateTriggerRange() {
    this.scrpModel.CalculateTriggerPriceRange(this.pom.Ttranstype, this.pom.IsCOPriceFlagEnabled);
    if (!this.isEdit)
      this.pom.TrigPrice = this.pom.Ttranstype === 'B' ? this.scrpModel.TriggerRangeStart : this.scrpModel.TriggerRangeEnd;
  }

  updateTriggerRange() {
    this.scrpModel.UpdateTriggerPriceRange(this.pom.Ttranstype);
  }

  filterCOEditCondtions() {
    this.showAdvanced = true;
    this.verityType = 'CO';
    if (this.pom.Status.toLowerCase() === 'trigger pending') {
      this.calculateCOTriggerRabge();
      this.isOrderType = false;
      this.isQtyDisable = true;
      this.isEnablePrice = false;
    }
    else if (this.pom.Status.toLowerCase() === 'open') {
      this.isOrderType = true;
      this.isQtyDisable = true;
    }
  }

  updateHoldingData() {
    this.pom.prctyp = 'MKT';
    this.isEnablePrice = false;
    this.pom.Pcode = this.holdingData.Pcode;
    this.pom.qty = this.holdingData.AvailableQty.toString();
  }

  validateSLM() {
    this.reqCount++;
    const data = { 'sExchange': this.pom.exch, 'sToken': this.pom.Token, 'sSLPrice': this.pom.TrigPrice.toString(), 'sOrderType': this.pom.Ttranstype, 'sMarketProtection': this.pom.MktPro };
    this.scripService.validateSLM(data, this.validateSLMRes.bind(this));
  }

  validateSLMRes(data, code, message) {
    this.reqCount--;
    if (code === Keys.RES_CODE_SUCCESS) {
      if (this.reqCount === 0)
        this.placeOrder();
    }
    else {
      this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  filterOrderTypeArray() {
    // this.orderTypeArray = [];
    // // this.orderTypeArray = this.pom.Pcode == 'BO'? this.BOOrderTypeArray : ['L', 'MKT', 'SL', 'SL-M'];
    // if (this.pom.Pcode === 'BO')
    //   this.orderTypeArray = this.BOOrderTypeArray;
    // else if (this.pom.Pcode === 'CO' || this.isSquareOff)
    //   this.orderTypeArray = this.basicOrderTypeArray;
    // else
    //   this.orderTypeArray = ['L', 'MKT', 'SL', 'SL-M'];

    if (!this.isEdit && !this.isSquareOff && !this.isAddRemove) {
      // if (this.orderTypeArray.includes(Utility.PLACEORDERSETTINGSMODEL.orderType))
      //   this.pom.prctyp = Utility.PLACEORDERSETTINGSMODEL.orderType;
      // else
        this.pom.prctyp = this.orderTypeArray[0];
    }
    if (this.orderTypeArray.length === 0)
      this.isPlaceorder = false;
    this.orderTypeDependencies(this.pom.prctyp);
  }

  placeBOOrder() {
    if (!this.squareOffvalue || Utility.getNumber(this.squareOffvalue) <= 0) {
      this.snackBar.open(this.translate.instant('placeorder.invalid_squareoff'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.pom.SqrOffAbsOrticks === 'Absolute' && this.floatSafeModulus(this.squareOffvalue, this.ticksize) !== 0) {
      this.snackBar.open(this.translate.instant('placeorder.squareoffmult') + this.ticksize + '.', this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (!this.stoplossValue || Utility.getNumber(this.stoplossValue) <= 0) {
      this.snackBar.open(this.translate.instant('placeorder.invalidSL'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.pom.SLAbsOrticks === 'Absolute' && this.floatSafeModulus(this.stoplossValue, this.ticksize) !== 0) {
      this.snackBar.open(this.translate.instant('placeorder.slmultiple') + this.ticksize + '.', this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else {
      this.isPlaceorder = false;
      this.pom.SqrOffvalue = (Utility.getNumber(this.squareOffvalue) - Utility.getNumber(this.pom.Price)).toString();
      this.pom.SLvalue = (Utility.getNumber(this.pom.Price) - Utility.getNumber(this.stoplossValue)).toString();
      this.submitType = 'bo';
      this.showAlert();

    }
  }

  placeBOOrderRes(data, code, message) {
    this.isPlaceorder = true;
    if (code === Keys.RES_CODE_SUCCESS) {
      this.isOrderSummary = true;
      //SpreadPlaceorderComponent.ORDER_PLACED_EVENT.next('true');
      this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
        if (resCode === Keys.RES_CODE_SUCCESS) {
          this.orderObjArr = list.filter(el => (el.NestOrd === data.nestOrderNumber || el.SyomOrderId.split('|')[0] === data.nestOrderNumber));
        }
      }.bind(this), this.setPreDefinedOrders.bind(this));
    }
    else {
      this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  applyBOModifyConditions() {
    this.verityType = 'BO';
    if (this.orderData.Status.toLowerCase() === 'trigger pending') {
      this.isQtyDisable = true;
      this.isEnablePrice = false;
    }
    else {
      if (this.orderData.IsCancel) {
        this.isQtyDisable = false;
        this.isEnablePrice = true;
      }
      else {
        this.isQtyDisable = true;
        this.isEnablePrice = true;
      }
    }
  }

  onPlaceAnother() {
    this.isOrderSummary = false;
    if (this.pom.Ttranstype === 'B')
      this.pom.Price = this.scrpModel.BestSellPrice;
    else
      this.pom.Price = this.scrpModel.BestBuyPrice;
    this.pom.qty = this.scrpModel.BodLotQuantity;
    this.pom.Pcode = this.productArr[0];
    if (this.pom.Pcode === 'BO') {
      this.applyBOModifyConditions();
    }
    this.pom.prctyp = this.orderTypeArray[0];
    this.pom.discqty = '0';
    this.pom.Ret = this.retentionTypeArray[0];
    this.pom.TrigPrice = '0';
    this.pom.AMO = 'NO';
    this.isEdit = false;
    this.isSquareOff = false;
  }

  openDepth() {
    if (this.isShowDepth)
    {
      this.depthAnimation = 'initial';
      setTimeout(function(){this.isShowDepth = false}.bind(this), 400);
    }
    else
    {
      this.isShowDepth = true;
      this.depthAnimation = 'final'
    }
  }

  onModify(obj) {
    this.isOrderSummary = false;
    Utility.detailedOrder = obj;
    this.initPlaceorder({ 'isEdit': true })
  }

  onCancel(obj) {
    const alertMessage = this.translate.instant('books.cancel_order')
     Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { message: alertMessage } });
    Utility.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.tradingRestService.getBookService().CancelOrder(obj, function(responsedata, code, message){
          if (responsedata['stat'] === 'Ok') {
            this.isOrderSummary = true;
            this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
              if (resCode === Keys.RES_CODE_SUCCESS) {
                this.orderObjArr = list.filter(el => el.NestOrd === responsedata.NOrdNo)[0];
              }
            }.bind(this), this.setPreDefinedOrders.bind(this));
            this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['successSnackBar'] });
          }
          else
            this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }.bind(this));
      }
    });
  }

  showAlert() {
    this.isDisableForm = true;
  }

  onEdit() {
    this.isDisableForm = false;
    this.isPlaceorder = true;
  }

  onConfirm() {
    this.isDisableForm = false;
    switch (this.submitType) {
      case 'placeorder':
        console.log('==============')
        this.scripService.spreadPlaceOrder(this.pom, this.placeOrderRes.bind(this));
        break;
      case 'modify':
        this.scripService.spreadModifyOrder(this.pom, this.modifyOrderRes.bind(this));
        break;
      case 'bo':
        this.scripService.placeBOOrder(this.pom, this.placeBOOrderRes.bind(this));
        break;
    }
  }

  onExit(obj) {
    const str = this.translate.instant('books.exit_order')
     Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { message: str } });
     Utility.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.tradingRestService.getBookService().ExitOrder(obj, function(responsedata, code, message){
          if (responsedata['stat'] === 'Ok') {
            this.isOrderSummary = true;
            this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
              if (resCode === Keys.RES_CODE_SUCCESS) {
                this.orderObjArr = list.filter(el => el.NestOrd === responsedata.NOrdNo)[0];
              }
            }.bind(this), this.setPreDefinedOrders.bind(this));
            this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['successSnackBar'] });
          }
          else
            this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }.bind(this));
      }
    });
  }

  onEnter(id) {
    if (Utility.IS_MOBILE) {
      document.getElementById(id).focus();
    }
}

  onOpenOrderBook()
  {
    this.router.navigate(['/home'], { replaceUrl: true });
    Utility.openOrderBook.next();
  }

  calculateLots(qty, lotSize)
  {
    this.lotError = false;
    if (qty && lotSize && this.floatSafeModulus(Utility.getNumber(qty), Utility.getNumber(lotSize)) === 0)
      return Math.floor(Utility.getNumber(qty)/Utility.getNumber(lotSize));
    this.lotError = true;
    return 'NA';
  }

  close()
  {
    Utility.dialogRef.close();
  }

  selectData(value) {
    if (value.omtkn && value.exseg)
      Utility.addRecentlySearchedScrips(value.omtkn, value.exseg, value.tsym, value.cnam);
    
    this.initPlaceorder({'action': this.pom.Ttranstype, 'omtkn': value.omtkn, 'exseg': value.exseg})
  }

  onSubmitForm(ev)
  {
    if (ev.keyCode === 13)
    {
      if (this.isDisableForm)
        this.onConfirm();
      else
        this.onSubmit();
    }
  }
  
  checkSL()
  {
    return this.isEdit && (this.orderData? (Utility.getNumber(this.orderData.TriggerPrice) !== 0) : false);
  }

  checkPriceLength(str)
  {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    return regex.test(str);
  }

  setBaseVisibility(visible: boolean){
    if(visible){
      setTimeout(() => {
        this.onAppearing();
      }, 50);
    }
    else {
      this.onDisappearing();
    }
  }

  onAppearing() {
    Utility.resumeChannel(this.tradingRestService, ChannelType.Shared);
  }

  onDisappearing() {
    Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
  }

  ngOnDestroy()
  {
    this.onDisappearing();
  }
}
