import { Injectable, ɵConsole } from '@angular/core';
import { IAlertService } from './ialert.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import { Utility } from '../utility';
import { Preferences, ReturnType } from '../Preferences';


let al: AlertService;

@Injectable()
export class AlertService implements IAlertService {
    apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
        al = this;
    }
    GetAlertIndex(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = {};
        const jsonSendObj = {'exarry': JSON.stringify(JSON.parse(Preferences.getPreference('userParameter'))['exarr'])};        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj),  Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.AlertIndex, param).subscribe(res => {
        const stat = res['stat'];
        if (stat === 'Ok') {
            returndata = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(returndata, code, message);
        });
    }
    CreateSecTradeAlert(Exchange: string, Source: string, Value: string, Direction: string, SendOn: string, Symbol1: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = {};
        const jsonSendObj = {'uid': Preferences.getPreference('userid'), 'Exchange': Exchange, 'Source': Source, 'Value': Value, 'Direction': Direction,
            'SendOn': SendOn, 'Symbol': Symbol1, 'actid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId']};
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj),  Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.SecTradeAlert, param).subscribe(res => {
        const stat = res['stat'];
        if (stat === 'Ok') {
            returndata = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(returndata, code, message);
        });
    }
    GetOpenOrders(Exchange: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = [];
        const jsonSendObj = {'uid': Preferences.getPreference('userid'), 'accid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'], 'Exchange': Exchange,
                             's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali']};
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj),  Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.OpenOrders, param).subscribe(res => {
        const stat = res[0]['stat'];
        if (stat === 'Ok') {
            returndata = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(returndata, code, message);
        });
    }
    CreateOrderTradeAlert(Exchange: string, Token: string, Comment: string, Target: string, Senton: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = {};
        const jsonSendObj = {'uid': Preferences.getPreference('userid'), 'actid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'], 'Exchange': Exchange, 'Token': Token,
                             'Comment': Comment, 'Target': Target, 'Senton': Senton};
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj),  Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.PerTradeAlert, param).subscribe(res => {
        const stat = res['stat'];
        if (stat === 'Ok') {
            returndata = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(returndata, code, message);
        });
    }
    GetAlertHistory(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = [];
        const jsonSendObj = {'uid': Preferences.getPreference('userid'), 's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali']};
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj),  Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.AlertHistory, param).subscribe(res => {
        const stat = res[0]['stat'];
        if (stat === 'Ok') {
            returndata = res;
                message = 'OK';
            } else {
                message = res[0]['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(returndata, code, message);
        });
    }
    CancelAlert(AlertId: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = {};
        const jsonSendObj = {'AlertId': AlertId, 'uid': Preferences.getPreference('userid')};
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj),  Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.CancelAlert, param).subscribe(res => {
        const stat = res['stat'];
        if (stat === 'Ok') {
            returndata = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(returndata, code, message);
        });
    }
    ModifyPerTradeAlert(ATMNumbr: string, Target: string, Senton: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = {};
        const jsonSendObj = {'uid': Preferences.getPreference('userid'), 'ATMNumbr': ATMNumbr, 's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali'],
                             'Target': Target, 'Senton': Senton,
                             'actid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId']};        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj),  Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.ModifyPerTradeAlert, param).subscribe(res => {
        const stat = res['stat'];
        if (stat === 'Ok') {
            returndata = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(returndata, code, message);
        });
    }
    ModifySecTradeAlert(ATMNumbr: string, Target: string, Operation: string, Senton: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = {};
        const jsonSendObj = {'uid': Preferences.getPreference('userid'), 'ATMNumbr': ATMNumbr, 's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali'],
                             'Target': Target, 'Operation': Operation, 'actid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'], 'Senton': Senton};
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj),  Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.ModifySecTradeAlert, param).subscribe(res => {
        const stat = res['stat'];
        if (stat === 'Ok') {
            returndata = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(returndata, code, message);
        });
    }
}
