<div class="col-12 mt-2 cont" *ngIf='!isNoData' [ngStyle]="Height">
  <div class="col-12 mb-md-0 mb-3">
  <div class="row">
      <div class="col-md-5 col-12 pb-3 panelsubheader">
        <mat-form-field>
          <mat-select  (selectionChange)="onSelection($event)" [(value)]="selectedVal">
              <mat-option *ngFor="let val of finFilters" [value]="val">{{val}}</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div class="col-md-5 col-6 text-left my-auto">
        <mat-radio-group (change)="finChange($event)">
          <mat-radio-button value="1" checked><span translate>stock_detail.annual</span> &nbsp;&nbsp;</mat-radio-button>
          <mat-radio-button value="2"><span translate>stock_detail.interim</span></mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-md-2 col-6 text-right my-auto">
        <button class="ibutton" *ngIf="isChart" (click)="toggle()">
          <button> insert_chart_outlined</button>
      </button>
      <button class="ibutton" *ngIf="!isChart" (click)="toggle()">
        <i class="icon-insert-chart"></i>
      </button>
      
    </div>
  </div>
</div>
        <div [class.ng-hide]="!isProfitLoss" class="col-12">
          <div *ngIf = "plshow" class="row pt-2">
            
            <div class="col-md-4 col-12 pr-md-2" *ngIf = "rchartData.length>0">
              <mat-card class= "finchart-height">
                  <canvas  class = "chart-color2" *ngIf = "ispl || ispli" baseChart [plugins]="barChartPlugins" [datasets]="rchartData" [labels]="chartLabels"
                      [options]="plChartOptions"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
              </mat-card>
            </div>
            <div class="col-md-4 col-12 pl-md-2 mt-md-0 mt-3" *ngIf = "opchartData.length>0">
              <mat-card class= "finchart-height">
                  <canvas  class = "chart-color2" *ngIf = "ispl || ispli" baseChart [plugins]="barChartPlugins" [datasets]="opchartData" [labels]="chartLabels"
                  [options]="plChartOptions1"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
              </mat-card>
            </div>
            <div class="col-md-4 mt-md-0 pr-md-2" *ngIf="ichartData.length>0">
              <mat-card class= "finchart-height">
                  <canvas  class = "chart-color2" *ngIf = "ispl || ispli" baseChart [plugins]="barChartPlugins" [datasets]="ichartData" [labels]="chartLabels"
                  [options]="plChartOptions2"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
              </mat-card>
            </div>
          </div>
          <div class=" col-12 cont" [class.ng-hide]="plshow">
            <mat-card class="webpanelnew">
              <div class="table-wrapper fin-tab-container">
            <table mat-table [dataSource]="pldatasource" class="w-100">
              <ng-container matColumnDef="first" sticky>
                <td mat-cell *matCellDef="let element" class="tdfirst size14 ">{{element.first}} </td>
              </ng-container>
              <ng-container matColumnDef="second">
                <td mat-cell *matCellDef="let element" class="pl-3 tdfirst text-right size14"> {{element.second}}</td>
              </ng-container>
              <ng-container matColumnDef="third">
                <td mat-cell *matCellDef="let element" class="pl-3 tdfirst text-right size14"> {{element.third}} </td>
              </ng-container>
              <ng-container matColumnDef="fourth">
                <td mat-cell *matCellDef="let element" class="pl-3 tdfirst text-right size14"> {{element.fourth}} </td>
              </ng-container>
              <ng-container matColumnDef="fifth">
                <td mat-cell *matCellDef="let element" class="pl-3 tdfirst text-right size14"> {{element.fifth}} </td>
              </ng-container>
              <ng-container matColumnDef="sixth">
                <td mat-cell *matCellDef="let element" class="pl-3 tdfirst text-right size14"> {{element.sixth}} </td>
              </ng-container>
              <ng-container matColumnDef="seventh">
                <td mat-cell *matCellDef="let element" class="pl-3 tdfirst text-right size14"> {{element.seventh}} </td>
              </ng-container>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
            </mat-card>
          </div>
        </div>
         
  <div [class.ng-hide] = "!isBalanceSheet" class="col-12">
    <div *ngIf = "bsshow" class="row pt-2">
          <div class="col-md-4 col-12 pr-md-2" *ngIf="bachartData.length>0">
            <mat-card class= "finchart-height">
                <canvas  class = "chart-color2" *ngIf = "isbs || isbsi" baseChart [plugins]="barChartPlugins" [datasets]="bachartData" [labels]="chartLabels"
                [options]="bsChartOptions"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
            </mat-card>
          </div>
          <div class="col-md-4 pl-md-2 mt-md-0 mt-3" *ngIf="bnchartData.length>0">
            <mat-card class= "finchart-height">
                <canvas  class = "chart-color2" *ngIf = "isbs || isbsi" baseChart [plugins]="barChartPlugins" [datasets]="bnchartData" [labels]="chartLabels"
                [options]="bsChartOptions1"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
            </mat-card>
          </div>
          <div class="col-md-4 col-12 pr-md-2" *ngIf="blchartData.length>0">
            <mat-card class= "finchart-height">
                <canvas  class = "chart-color2" *ngIf = "isbs || isbsi" baseChart [plugins]="barChartPlugins" [datasets]="blchartData" [labels]="chartLabels"
                [options]="bsChartOptions2"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
            </mat-card>
          </div>
    </div>
    <div class="col-12 cont" [class.ng-hide]="bsshow">
      <mat-card class="webpanelnew">
        <div class="table-wrapper fin-tab-container">
          <table mat-table [dataSource]="bsdatasource" class="w-100">
            <ng-container matColumnDef="first" sticky>
              <td mat-cell *matCellDef="let element" class="tdfirst size14 ">{{element.first}} </td>
            </ng-container> 
            <ng-container matColumnDef="second">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.second}}</td>
            </ng-container>
            <ng-container matColumnDef="third">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.third}} </td>
            </ng-container>
            <ng-container matColumnDef="fourth">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.fourth}} </td>
            </ng-container>
            <ng-container matColumnDef="fifth">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.fifth}} </td>
            </ng-container>
            <ng-container matColumnDef="sixth">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.sixth}} </td>
            </ng-container>
            <ng-container matColumnDef="seventh">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.seventh}} </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </mat-card>
    </div>
  </div>
   
  
  
  
  <div [class.ng-hide] = "!isCashFlow" class="col-12">
    <div *ngIf = "cfshow" class="row pt-2">
          <div class="col-md-4 col-12 pr-md-2" *ngIf="cochartData.length>0">
            <mat-card class= "finchart-height">
                <canvas  class = "chart-color2" *ngIf = "iscf || iscfi" baseChart [plugins]="barChartPlugins" [datasets]="cochartData" [labels]="chartLabels"
                  [options]="cfChartOptions"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
            </mat-card>
          </div>
          <div class="col-md-4 col-12 pl-md-2 mt-md-0 mt-3" *ngIf="cichartData.length>0">
            <mat-card class= "finchart-height">
                <canvas  class = "chart-color2" *ngIf = "iscf || iscfi" baseChart [plugins]="barChartPlugins" [datasets]="cichartData" [labels]="chartLabels"
                [options]="cfChartOptions1"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
            </mat-card> 
          </div>
          <div class="col-md-4 col-12 pr-md-2" *ngIf="cfchartData.length>0">
            <mat-card class= "finchart-height">
                <canvas  class = "chart-color2" *ngIf = "iscf || iscfi" baseChart [plugins]="barChartPlugins" [datasets]="cfchartData" [labels]="chartLabels"
                [options]="cfChartOptions2"  [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
            </mat-card>
          </div>
    </div>
    <div  class="col-12 cont" [class.ng-hide]="cfshow">
      <mat-card class="webpanelnew">
        <div class="table-wrapper fin-tab-container ">
          <table mat-table [dataSource]="cfdatasource" class="w-100">
            <ng-container matColumnDef="first" sticky >
              <td mat-cell *matCellDef="let element" class="tdfirst size14 ">{{element.first}} </td>
            </ng-container> 
            <ng-container matColumnDef="second">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.second}}</td>
            </ng-container>
            <ng-container matColumnDef="third">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.third}} </td>
            </ng-container>
            <ng-container matColumnDef="fourth">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.fourth}} </td>
            </ng-container>
            <ng-container matColumnDef="fifth">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.fifth}} </td>
            </ng-container>
            <ng-container matColumnDef="sixth">
              <td mat-cell *matCellDef="let element" class="pl-3 tdfirst size14 "> {{element.sixth}} </td>
            </ng-container>
            <ng-container matColumnDef="seventh">
              <td mat-cell *matCellDef="let element" class=" pl-3 tdfirst size14 "> {{element.seventh}} </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        </mat-card>
    </div>
  </div>
 
</div>
<div style="display: none;" class="chart-color2"></div>
<div *ngIf='isNoData'>
  <app-nodata message = "No Data"></app-nodata>
</div>