import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { fingerprintDecision, Utility } from 'src/app/services/utility';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { TableUtility } from 'src/app/services/tableutility';
import { environment } from 'src/environments/environment';
import { MetaColorService } from '../../meta-color.service';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { ServerMockService } from '../Fingerprint/server-mock.service';
import { WebAuthnService } from '../Fingerprint/web-authn.service';
import { PlaceorderSettingsModel } from 'src/app/model/placeordersettings.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
let genset: GeneralSettingsComponent;
@Component({
  selector: 'app-generalsettings',
  templateUrl: '../../'+environment.baseviewpath+'/generalsettings/generalsettings.component.html',
  styleUrls: ['./generalsettings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  static themeChange = new Subject<string>();
  theme_no = '1';
  selectedLang = 'en';
  isMobile = true;
  selectedTheme = '1';
  selectedMode = 'M';
  profile = 'B';
  utility;
  userdata;
  orarr = [];
  isboorder = false;
  orderTypeArray = ['L', 'MKT', 'SL', 'SL-M'];
  orderArray =  ['L', 'MKT'];
  prodArray = [];
  selectedFlashType = 'defaultFlash';
  static MODE = new Subject<boolean>();
  selectFingerprint = '';
  isFingerprintSupport = true;
  isFingerPrintEnable;
  tabIndex = 0;
  stockValue;
  indexValue;
  optionValueLow;
  optionValueHigh;
  currValueLow;
  currValueMedium;
  currValueHigh;
  eurValueLow;
  eurValueMedium;
  eurValueHigh;
  totalBuyAmt;
  isMobileForce = 'false';
  istablet;
  placeOrderSettings: PlaceorderSettingsModel;
  placeOrdersettng;
  preTradeVal;

  constructor(@Inject(DOCUMENT) private document: Document, private translate: TranslateService, private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar, private location: Location,
  private router: Router, private dialog: MatDialog, private _metaColorService: MetaColorService, private serverMockService: ServerMockService, private webAuthnService: WebAuthnService,private tradingRestService: TradingRestService) {
    genset = this;
    var deviceInfo = Preferences.getPreference('deviceInfoObj');
    var valueFromJson = JSON.parse(deviceInfo);
    var browser = valueFromJson['browser'];
    var version = parseFloat(valueFromJson['browser_version']);
    var operatingSystem = valueFromJson['os'];
    this.istablet = Utility.IS_TABLET;
    genset.isMobile = Utility.IS_MOBILE;
    if (genset.isMobile) {
      genset.activatedRoute.queryParams.subscribe(params => {
        genset.tabIndex = Number(params['tabIndex']);
      });
    } else {
      genset.tabIndex = (Utility.dialogData && Utility.dialogData['tabIndex']) ?? 0;
    }
    if(genset.tabIndex == 1) {
      Utility.isFromSettingPO = true;
    }
    genset.isFingerprintSupport = Number(Preferences.getPreference(Utility.FINGERPRINT_STATUS)) === fingerprintDecision.NotSupport ? false : true;
    if(browser === 'Chrome' && operatingSystem === 'Android' && version >= 88)
      genset.isFingerPrintEnable = true;
    else{
      genset.isFingerPrintEnable = false;
    }
    if(genset.isFingerprintSupport)
      genset.selectFingerprint = Number(Preferences.getPreference(Utility.FINGERPRINT_STATUS)) === fingerprintDecision.Enabled ? 'E' : 'D';
    if (Preferences.getItem('language', ReturnType.Undefined))
      genset.selectedLang = Preferences.getItem('language');
    if (!genset.isMobile && Preferences.getItem('w_touch', ReturnType.Undefined) !== undefined && Preferences.getItem('w_touch', ReturnType.Undefined) !== null){
      Utility.TOUCH_TYPE = Preferences.getItem('w_touch');
    }
    if (genset.isMobile && Preferences.getItem('m_touch', ReturnType.Undefined) !== undefined && Preferences.getItem('m_touch', ReturnType.Undefined) !== null){
      Utility.TOUCH_TYPE = Preferences.getItem('m_touch');
    }
    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      genset.selectedFlashType = Preferences.getItem('flashType');
    }
    genset.profile = Utility.PROFILE;
    genset.selectedMode = Utility.TOUCH_TYPE;
    if (Preferences.getItem('theme', ReturnType.Undefined) !== undefined && Preferences.getItem('theme', ReturnType.Undefined) !== null) {
      genset.theme_no = Preferences.getItem('theme');
    }
    else {
      genset.theme_no = '1';
    }
    genset.selectedTheme = genset.theme_no;
    genset.userdata = JSON.parse(Preferences.getPreference('userParameter'));
    if(environment.isQuickRest) {
      genset.orarr = genset.userdata['ordArr'];
    }
    else {
      genset.orarr = genset.userdata['orarr'];
    }
    if(this.istablet) {

      genset.isMobileForce = (Preferences.getPreference('isMobileForce') === 'true')? 'true' :'false';
    }

    Utility.PLACEORDERSETTINGSMODEL = (Preferences.getItem('placeordersettings', ReturnType.Undefined)) ? 
    Preferences.getItem('placeordersettings') : new PlaceorderSettingsModel();
    genset.placeOrderSettings =  JSON.parse(JSON.stringify(Utility.PLACEORDERSETTINGSMODEL));
    genset.placeOrderSettings.onetouch = (genset.placeOrderSettings.onetouch)?genset.placeOrderSettings.onetouch: 'false';
    genset.placeOrderSettings.productType = (genset.placeOrderSettings.productType)?genset.placeOrderSettings.productType:'CNC';
    if (Object.keys(Preferences.getItem('placeordersettings')).length <= 0) {
      Preferences.setItem('placeordersettings',new PlaceorderSettingsModel());
    }
    let preTradeValues;
    if (Preferences.getItem('preTradeValues', ReturnType.Undefined)) {
      preTradeValues = Preferences.getItem('preTradeValues');
    }
    else{
      Utility.fillPreTradeValues();
      preTradeValues = Preferences.getItem('preTradeValues');
    }
    genset.stockValue = preTradeValues['stockValue'];
    genset.indexValue = preTradeValues['indexValue'];
    genset.optionValueLow = preTradeValues['optionValueLow'];
    genset.optionValueHigh = preTradeValues['optionValueHigh'];
    genset.currValueLow = preTradeValues['currValueLow'];
    genset.currValueMedium = preTradeValues['currValueMedium'];
    genset.currValueHigh = preTradeValues['currValueHigh'];
    genset.eurValueLow = preTradeValues['eurValueLow'];
    genset.eurValueMedium = preTradeValues['eurValueMedium'];
    genset.eurValueHigh = preTradeValues['eurValueHigh'];
    genset.totalBuyAmt = preTradeValues['totalBuyAmt'];
  }

  ngOnInit(): void {
    genset.prodArray =  genset.userdata['prodArr'];
    if (genset.prodArray.length > 0 && genset.prodArray.includes('BO')){
      genset.isboorder = true;
    }
    genset.utility = Utility;
  }

  toggleFingerprint(fingerprintMode)
  {
    genset.selectFingerprint = fingerprintMode;
    if(fingerprintMode === 'D')
    {
      Preferences.setPreference(Utility.FINGERPRINT_STATUS, fingerprintDecision.Disabled);
    }
    else
    {
       Utility.showFingerprintDialog(genset.translate, genset.dialog, genset.serverMockService, genset.webAuthnService);
    }
  }

  static fingerprintStatus(fingerprintResult)
  {
    if(genset)
      genset.selectFingerprint = fingerprintResult;
  }

  toggleTheme(no) {
    genset.trans();
    genset.theme_no = no;
    switch (genset.theme_no) {
      case '1':
        this.document.documentElement.setAttribute('data-theme', 'light');
        this._metaColorService.changeThemeColor(this._metaColorService.light);
        break;
      case '2':
        this.document.documentElement.setAttribute('data-theme', 'dark');
        this._metaColorService.changeThemeColor(this._metaColorService.dark);
        break;
      case '3':
        this.document.documentElement.setAttribute('data-theme', 'blue');
        this._metaColorService.changeThemeColor(this._metaColorService.blue);
        break;
    }
    genset.selectedTheme = genset.theme_no;
    Preferences.setItem('theme', genset.theme_no);
    GeneralSettingsComponent.themeChange.next(genset.theme_no);
  }
  trans() {
    genset.document.documentElement.classList.add('transition');
    setTimeout(() => {
      genset.document.documentElement.classList.remove('transition');
    }, 1000);
  }

  resetPreTradeValues() {
    Utility.PLACEORDERSETTINGSMODEL = new PlaceorderSettingsModel();
    Preferences.setItem('placeordersettings', JSON.parse(JSON.stringify(Utility.PLACEORDERSETTINGSMODEL)));
    Utility.fillPreTradeValues();
    (this.isMobile) ? this.location.back() : Utility.dialogRef.close();
    this.snackBar.open(this.translate.instant('all.reset_success'), 'Close', { duration: 2000, panelClass: ['successSnackBar'] });
  }

  savePreTradeValues() {
    
    if (genset.stockValue <= 0.0) {
      this.snackBar.open(this.translate.instant('settings.pretrade_stockerror'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
    } else if(genset.indexValue <= 0.0) {
      this.snackBar.open(this.translate.instant('settings.pretrade_indexerror'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
    } else if(genset.optionValueHigh <= 0.0 || genset.optionValueLow <= 0.0) {
      this.snackBar.open(this.translate.instant('settings.pretrade_optionerror'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
    } else if(genset.currValueLow <= 0.0 || genset.currValueMedium <= 0.0 || genset.currValueHigh <= 0.0 || genset.eurValueLow <= 0.0 || genset.eurValueMedium <= 0.0 || genset.eurValueHigh <= 0.0) {
      this.snackBar.open(this.translate.instant('settings.pretrade_currencyerror'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
    } else if(genset.totalBuyAmt <= 0.0) {
      this.snackBar.open(this.translate.instant('settings.pretrade_amounterror'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
    } else {
      genset.save();
      Preferences.setItem("preTradeValues", {
        "stockValue": genset.stockValue ?? 5,
        "indexValue": genset.indexValue ?? 3,
        "optionValueLow": genset.optionValueLow ?? 20,
        "optionValueHigh": genset.optionValueHigh ?? 40,
        "currValueLow": genset.currValueLow ?? 0.05,
        "currValueMedium": genset.currValueMedium ?? 0.1,
        "currValueHigh": genset.currValueHigh ?? 20,
        "eurValueLow": genset.eurValueLow ?? 0.002,
        "eurValueMedium": genset.eurValueMedium ?? 0.004,
        "eurValueHigh": genset.eurValueHigh ?? 20,
        "totalBuyAmt": genset.totalBuyAmt ?? 1000000,
      });
      (this.isMobile) ? this.location.back() : Utility.dialogRef.close();
    }
  }
  
  _keyPress(e: any) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
     return false;
    }
    if (e.which === 0) {
     return true;
    }
    if (e.which < 33) {
      return true;
    }
    if(e.which  === 46) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  openTouchType(val) {
    Utility.TOUCH_TYPE = val;
    TableUtility.TOUCH_TYPE = Utility.TOUCH_TYPE;
    TableUtility.setTuchType();
    genset.selectedMode = Utility.TOUCH_TYPE;
    if (genset.isMobile)
      Preferences.setItem('m_touch', Utility.TOUCH_TYPE);
    else
      Preferences.setItem('w_touch', Utility.TOUCH_TYPE);

    this.setAppMode();
    if (!genset.isMobile)
      GeneralSettingsComponent.MODE.next(true);
  }
  setAppMode()
  {
    switch (Utility.TOUCH_TYPE) {
      case 'T':
        this.document.documentElement.setAttribute('app-mode', 'touch');
        break;
      case 'M':
        this.document.documentElement.setAttribute('app-mode', 'mouse');
        break;
      case 'C':
        this.document.documentElement.setAttribute('app-mode', 'power');
        break;
    }
  }
  toggleFlash(flashType) {
    Utility.flashType.next(flashType);
    Preferences.setItem('flashType', flashType);
  }

  toggleProfile(p) {
    Utility.PROFILE = p;
    genset.profile = p;
    Preferences.setItem('profile', p);
  }
  
  onTabChanged(index) {
    genset.tabIndex = index;
      if(genset.tabIndex == 1) {
        Utility.isFromSettingPO = true;
      }
   }
 
  changeLanguage() {
    if (genset.selectedLang === 'en')
      genset.selectedLang = 'hi';
    else
    genset.selectedLang = 'en';
    Preferences.setItem('language', genset.selectedLang);
    genset.translate.use(genset.selectedLang);
  }

  save() {
    if ( this.placeOrderSettings['SqrOffPercentage'] !== null && this.placeOrderSettings['stoplossPercentage'] !== null) {
      const obj = {'orderType': this.placeOrderSettings['orderType'],
                  'productType': this.placeOrderSettings['productType'],
                  'SqrOffPercentage': this.placeOrderSettings['SqrOffPercentage'],
                  'stoplossPercentage': this.placeOrderSettings['stoplossPercentage'],
                  'onetouch': this.placeOrderSettings['onetouch']};
      Utility.PLACEORDERSETTINGSMODEL = this.placeOrderSettings;
      Preferences.setItem('placeordersettings', obj);
      Preferences.serverSync(this.tradingRestService);
    }
}
reset() {
  Preferences.clearPrefs(this.tradingRestService, true);
  if (Utility.IS_MOBILE) {
    genset.router.navigate(['/home']);
  } else {
    Utility.dialogRef.close();
  }
}

selectedIndexChange(selectedTab : any)
{
  this.onTabChanged(selectedTab);
}


  close() {
    this.preTradeVal = {
      "stockValue": genset.stockValue ?? 5,
      "indexValue": genset.indexValue ?? 3,
      "optionValueLow": genset.optionValueLow ?? 20,
      "optionValueHigh": genset.optionValueHigh ?? 40,
      "currValueLow": genset.currValueLow ?? 0.05,
      "currValueMedium": genset.currValueMedium ?? 0.1,
      "currValueHigh": genset.currValueHigh ?? 20,
      "eurValueLow": genset.eurValueLow ?? 0.002,
      "eurValueMedium": genset.eurValueMedium ?? 0.004,
      "eurValueHigh": genset.eurValueHigh ?? 20,
      "totalBuyAmt": genset.totalBuyAmt ?? 1000000,
    };
    this.placeOrdersettng = genset.placeOrderSettings;
    
    const plcOrdrSetting = JSON.stringify(this.placeOrdersettng) === JSON.stringify(Preferences.getItem("placeordersettings"));
    const tradeVal = JSON.stringify(this.preTradeVal) === JSON.stringify(Preferences.getItem("preTradeValues"));
    if(!tradeVal || !plcOrdrSetting) {
      const dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.confirmation'), message: this.translate.instant('all.changes_unsaved')}});
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') {
          this.isMobile ? this.location.back() : Utility.dialogRef.close();      
        }
      });
    } else {
      this.isMobile ? this.location.back() : Utility.dialogRef.close();  
    }
  }

  ngOnDestroy() {
    Utility.isFromSettingPO = false;
  }

  enableMobileForce() {
    Utility.dialogRef.close();
    Utility.IS_MOBILE = true;
    Preferences.setPreference('isMobileForce',true);
    this.router.navigate(['/home'], { state: {'isReload': true}});
  }

  disableMobileForce() {
    Utility.IS_MOBILE = true;
    Preferences.setPreference('isMobileForce',false);
    this.router.navigate(['/workbook'], { state: {'isReload': true}});
  }
}
