<div class="screen-rotate-icon" *ngIf="utility.IS_TABLET && isVisible && !utility.IS_MOBILE_LAND">
    <svg viewBox="0 0 24 24">
        <path d="M18.04,3.53c0-.83-.66-1.51-1.49-1.51l-8.99-.04c-.83,0-1.51,.66-1.51,1.49l-.08,17c0,.83,.66,1.51,1.49,1.51l8.99,.04c.83,0,1.51-.66,1.51-1.49l.08-17ZM7.48,19.48l.07-14.99c0-.55,.45-1,1-.99l7,.03c.55,0,1,.45,.99,1l-.07,15c0,.55-.45,1-1,.99l-7-.03c-.54,0-.99-.46-.99-1.02ZM23.49,8.53c-.52-1.7-1.48-3.53-3.1-5.09-.13-.13-.34-.03-.35,.15l-.02,4.92h1.88c1.14,3.23,.66,6.88-1.44,9.72-.25,.33-.18,.8,.17,1.05,.33,.25,.81,.17,1.05-.17,2.28-3.1,2.89-7.03,1.82-10.58ZM3.99,15.51h-1.88c-1.14-3.23-.66-6.88,1.44-9.72,.25-.33,.18-.8-.17-1.05-.33-.25-.81-.17-1.05,.17C.05,8.01-.56,11.94,.51,15.49c.52,1.7,1.48,3.53,3.1,5.09,.13,.13,.34,.03,.35-.15,0,0,.03-4.93,.03-4.93Z"/>
    </svg>
</div>
<div class="row stickychips nomargin-row">
    <div class="col-12 pr-0"><div class="row nomargin-row">
        <div class="col-10">
            <div class="row nomargin-row">
                <div class="col-12" *ngIf = "positionList?.length > 0">
                    <mat-chip-list *ngIf = "!(!isLoading && isNoData) || isFilterNoData">
                        <mat-chip *ngIf = "!isLoading" (click) = "getEventData(netTab.data)" id = "netChip" [class.imactive]= "netTab.visibility === true">{{netTab.data}}</mat-chip>
                        <mat-chip *ngIf = "!isLoading" (click) = "getEventData(dayTab.data)" id = "dayChip"  [class.imactive]= "dayTab.visibility === true">{{dayTab.data}}</mat-chip>
                        <div *ngIf = "combineDataArr.length > 0 && interopsPositionList?.length > 1" class = "verticalline"></div>
                        <mat-chip *ngIf = "!isLoading && combineDataArr.length > 0 && interopsPositionList?.length > 1" [class.imactive]="selectedInteropsLabel === 'ALL'" id="tab-header" (click) = "onCombineTabChange('ALL','All')">ALL</mat-chip>
                        <mat-chip *ngFor="let obj of combineDataArr | slice:0: combineDataArrLen;  let i = index" (click)="onCombineTabChange(obj.data, obj.type, i)" [class.imactive]="obj.visibility === true" >
                                {{obj.data}}</mat-chip>
                         <mat-chip  id="tab-header" (click) = "onCombineExpanClick()" *ngIf="!isMobile && interopsPositionList?.length > 1" title="Expand">{{expandText}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div #sortMenu class="col-2 text-right my-auto" *ngIf="combinePositionModelList?.length > 0">
            <button disabled="false" #menuTrigger="matMenuTrigger" class="btn-icon" mat-icon-button [matMenuTriggerFor]="sortnav" (menuClosed)="matMenuClosed()">
                <i class="icon-menu"></i>
                <em *ngIf="(selectedSortedItem !== sortItemList[0] || (selectedSortedItem === sortItemList[0] && chipSelected === matChipItems[1]))  && (cellView || isMobile)" class="wl-bubblesmall"></em>
            </button>
            <mat-menu #sortnav="matMenu" xPosition="before" [class.col-12] = "true" class="panelnav">
                <div *ngIf="cellView || isMobile" [ngClass]="{'d-sm-none' : isMobile}" class="primetext p-2 ts-2">{{'all.sorting' | translate}}</div>
                <div *ngIf="cellView || isMobile" [ngClass]="{'d-sm-none' : isMobile}" (click)="$event.stopPropagation();$event.preventDefault();"><button (click)="sortingItemSelected(sortingItem)" mat-menu-item *ngFor="let sortingItem of sortItemList" >{{sortingItem}} <span class="rightitem impositive"><i [class.icon-godown]= "chipSelected === 'Asc' && selectedSortedItem === sortingItem" [class.icon-goup]= "chipSelected === 'Desc' && selectedSortedItem === sortingItem"></i></span></button></div>
                <div class="border-t pt-2">
                    <button *ngIf = "!isLoading && !isNoData" mat-menu-item matTooltip="{{'all.export' | translate}}" (click)="exportCSV()">{{'all.d_all' | translate}}<span class="rightitem"><i class="icon-refresh"></i></span></button>
                    <button *ngIf = "!isLoading && !isNoData" mat-menu-item matTooltip="{{'all.export' | translate}}" (click)="exportCombineCSV()">{{'all.d_combine' | translate}}<span class="rightitem"><i class="icon-refresh"></i></span></button>
                    <button *ngIf = "!isLoading && !isNoData && ((!cellView && !isMobile) || isMobileLand)" matTooltip="{{'all.manage_columns' | translate}}" mat-menu-item  (click)="openManageBook()">{{'all.manage_columns' | translate}}<span class="rightitem"><i class="icon-setting"></i></span></button>
                    <button *ngIf = "isPositionSquaerOff && !cellView && !isLoading && !isNoData && checkedPositionCount > 0" matTooltip="{{'placeorder.squareoff' | translate}}" mat-menu-item  (click)="allSquareOff()">{{'placeorder.squareoff' | translate}}<span class="rightitem">({{checkedPositionCount}})</span></button>
                    <button *ngIf = "isPositionSquaerOff && (cellView || isMobile) && !isLoading && !isNoData" matTooltip="{{'placeorder.squareoff' | translate}}" mat-menu-item  (click)="allSquareOffCellView()">{{'placeorder.squareoff' | translate}}</button>
                </div>
            </mat-menu>        
        </div>
        </div>
    </div>
</div>

<div #pnlHeader *ngIf='!isLoading && !isNoData' class="row hightlight-box ts-1 nomargin-row" [class.p-50]="!isMobile">
    <div class="col-6" [class.imnegative]="positions.totalMtoM<0" [class.impositive]="positions.totalMtoM>0">
        <div class="booksheader-lp row">
            <div class="col-12"><small class="subcolor" translate>{{'all.total' | translate}} {{'all.mtom' | translate}}</small></div>
            <div class="col-12 ts-4 text-sm-left">{{positions.totalMtoM | pricepipe:2}}</div>
        </div>
    </div>
    
    <div class="col-6 text-right">
        <div class="booksheader-lp row">
            <div class="col-12"><small class="subcolor" translate>all.net_amt</small></div>
            <div class="col-12 ts-4 text-right">{{positions.NetValue | pricepipe:2}}</div>
        </div>
    </div>
</div>
<div class="row nomargin-row">
    <div class="col-12">
        <div *ngIf='!isLoading && isNoData' class="">
            <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'all.reload' | translate"></app-nodata>
        </div>
        <ngx-pull-to-refresh
        spinnerColor="#ff0000"
        distanceForRefresh="80"
        [isEnable]="isMobile"
        [targetElement]="targetElement"
        (refresh)="myRefreshEvent($event, 'refresh')"
        (loadMore)="alert('loadmore')"
        class = "loader">
        <div *ngIf='!isLoading && !isNoData'>
            <div #p2 *ngIf="isMobile || cellView" [ngStyle]="Height">
                <div *ngFor="let data of combinePositionModelList; let i = index" [attr.id]="'pb_cell' + i" [class.wlweb]="cellView" [class.pl-2]="cellView" [class.pr-2]="cellView" (click)="highlight(data, i)" tabindex="999">
                    <div class="row">
                        <div class="col-12" [ngClass]="{'highlight': selectedRowIndex == i}">
                            <div class="list-view border-b" [ngClass]="{'d-block d-sm-none' : isMobile}">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="subcolor ts-2">
                                            <div>{{'all.qty' | translate}}: <span class="tc-1">{{data.Netqty | numberpipe}}</span> | {{'all.avg' | translate}}:<span class="tc-1"> {{((data.list.length==1)?data.list[0].AvgPrc:data.AvgPrc) | pricepipe:data.list[0].scripModel.DecimalPrecision}}</span></div>
                                            <span [ngClass]="{'tc-1' : true, 'ts-4' : true, 'textclip' : true, 'fnotext' : data?.list[0].Exchange === 'NFO'}" matTooltip="{{data.Tsym | symbolpipe}}">{{data.Tsym | symbolpipe}}</span> <small class="exchangename" *ngIf = "data?.list[0].Exchange !== 'NSE' && data?.list[0].Exchange !== 'BSE'">{{data?.list[0].Exchange}}</small><br>
                                            {{data.Pcode | valuepipe}}
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="subcolor ts-2 text-right">
                                            <div *ngIf="data.list.length==1" [class.imnegative]="data.list[0].PNLPercentageChg<0" [class.impositive]="data.list[0].PNLPercentageChg>0"><span class="subcolor">{{'all.return_change' | translate}}% </span> {{data.list[0].PNLPercentageChg  | percentagepipe}}</div>
                                            <div *ngIf="data.list.length==1" class="ts-4" [class.imnegative]="data.list[0].MtoM<0" [class.impositive]="data.list[0].MtoM>0">{{data.list[0].MtoM | pricepipe:data.list[0].scripModel.DecimalPrecision}}</div>
                                            <div *ngIf="data.list.length==2 " [class.imnegative]="(((data.list[0].PNLPercentageChg*data.list[0].Netqty)+(data.list[1].PNLPercentageChg*data.list[1].Netqty)))<0" [class.impositive]="(((data.list[0].PNLPercentageChg*data.list[0].Netqty)+(data.list[1].PNLPercentageChg*data.list[1].Netqty)))>0">
                                                <span class="subcolor">{{'books.return_change' | translate}}% </span>
                                                <span *ngIf="data.Netqty === 0">{{0 | percentagepipe}}</span>
                                                <span *ngIf="data.Netqty !== 0"> {{(((data.list[0].PNLPercentageChg*data.list[0].Netqty)+(data.list[1].PNLPercentageChg*data.list[1].Netqty))/data.Netqty)  | percentagepipe}}</span></div>
                                            <div *ngIf="data.list.length==2" [class.imnegative]="(data.list[0].MtoM+data.list[1].MtoM)<0" [class.impositive]="(data.list[0]+data.list[1].MtoM)>0">
                                                <span>{{(data.list[0].MtoM+data.list[1].MtoM) | pricepipe:data.list[0].scripModel.DecimalPrecision}}</span>
                                            </div>

                                            <div [class.imnegative]="data.list[0].scripModel.Change<0" [class.impositive]="data.list[0].scripModel.Change>0">
                                                <div [class.imnegativebg]="data.list[0].scripModel.TickDirection === -1" [class.impositivebg]="data.list[0].scripModel.TickDirection === 1">
                                                    {{data.list[0].scripModel.LastTradePrice | pricepipe:data.list[0].scripModel.DecimalPrecision}} <small class="ts-1">({{data.list[0].scripModel.PercentageChange | percentagepipe}})</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wl-btn" *ngxCacheIf="!isMobile && cellView" [class.d-none]="!M.IS_MOUSE">
                                    <button mat-button matTooltip="{{'all.add' | translate}}"  [class.btn-positive]="data.Netqty>=0" class="text-uppercase" [class.btn-negative]="data.Netqty<0" (click)="handleButton('ADD', data.list[0], data)" >{{'all.add' | translate}}</button>
                                    <button mat-button matTooltip="{{'all.exit' | translate}}"  [class.btn-positive]="data.Netqty<0" class="ml-2 text-uppercase" *ngIf = "data.Pcode !== 'CO' && data.Netqty !== 0" [class.btn-negative]="data.Netqty>=0" (click)="handleButton('REMOVE', data.list[0], data)">{{'all.exit' | translate}}</button>
                                    <button mat-icon-button matTooltip="{{'all.product_convert' | translate}}" class="btn-primary ml-2" [disabled] = "data.Netqty == 0 || (data.Pcode == 'CO' || data.Pcode == 'BO') " (click)="handleButton('PPC', data)"><i class="icon-pcb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.position_detail' | translate}}" class="btn-primary ml-2"  (click)="handleButton('breakup', data)"><i class="icon-summary"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.stock_detail' | translate}}" class="btn-primary ml-2"  (click)="handleButton('sd', data.list[0])"><i class="icon-ib"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!cellView">
                <div #p1 class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                    <table mat-table [dataSource]="combinePositionModelList" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matSort (matSortChange)="sortCombinePositionBook($event)">
                        <ng-container matColumnDef="Tsym" sticky>
                        <th mat-header-cell *matHeaderCellDef cdkDrag [cdkDragDisabled]="true" mat-sort-header>
                            <mat-checkbox *ngIf="!isMobile && isPositionSquaerOff" class = "p-2" [checked] = "positionCount>0" (change) = "onCheckBoxClicked('All', $event, '')"></mat-checkbox>&nbsp;
                            <span translate>all.instrument</span>
                        </th>
                        <td mat-cell *matCellDef="let element; let i =index">
                            <mat-checkbox *ngIf="!isMobile && isPositionSquaerOff" class = "p-2" [checked] = "element.CheckBoxValue" (change) = "onCheckBoxClicked(element, $event, i)"></mat-checkbox>&nbsp;
                            <span *ngIf = "!isMobile" class="button-row">
                                <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true" [class.d-none]="!M.IS_MOUSE">
                                    <button mat-icon-button matTooltip="{{'all.add' | translate}}" [class.btn-positive]="element.Netqty>=0" class="normalbtn text-uppercase" [class.btn-negative]="element.Netqty<0" (click)="handleButton('ADD', element.list[0], element)">{{'all.add' | translate}}</button>
                                    <button mat-icon-button matTooltip="{{'all.exit' | translate}}" [class.btn-positive]="element.Netqty<0" class="normalbtn text-uppercase" [disabled] = "element.Netqty == 0 || (element.Pcode == 'CO' || element.Pcode == 'BO') " [class.btn-negative]="element.Netqty>=0" (click)="handleButton('REMOVE', element.list[0], element)">{{'all.exit' | translate}}</button>
                                    <button mat-icon-button matTooltip="{{'all.product_convert' | translate}}" class="btn-primary" [disabled] = "element.Netqty == 0 || (element.Pcode == 'CO' || element.Pcode == 'BO') " (click)="handleButton('PPC', element)"><i class="icon-pcb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.position_detail' | translate}}" class="btn-primary"  (click)="handleButton('breakup', element)"><i class="icon-summary"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.stock_detail' | translate}}" class="btn-primary"  (click)="handleButton('sd', element.list[0])"><i class="icon-ib"></i></button>
                                </div>
                            </span>
                            <span [matTooltip]="element.list[0].scripModel.CompanyName" class="txtsmall">{{element.Tsym | symbolpipe}} <small *ngIf = "element?.list[0].Exchange !== 'NSE' && element?.list[0].Exchange !== 'BSE'">{{element.list[0].Exchange}}</small> </span>
                        </td>
                        </ng-container>
                        <ng-container matColumnDef="Pcode">
                            <th class="text-left" mat-header-cell mat-sort-header *matHeaderCellDef cdkDrag><span translate>all.product</span> </th>
                            <td class="text-left" mat-cell *matCellDef="let element"> {{element.Pcode | valuepipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Netqty">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag>{{'all.net_qty' | translate}}</th>
                            <td mat-cell *matCellDef="let element" class="text-right">{{element.Netqty | numberpipe}}</td>
                        </ng-container>
                        <ng-container matColumnDef="AvgPrc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"  cdkDrag>{{'all.avgprice' | translate}}</th>
                            <td mat-cell *matCellDef="let element" class="text-right">{{((element.list.length==1)?element.list[0].AvgPrc:element.AvgPrc) | pricepipe:element.list[0].scripModel.DecimalPrecision}}</td>
                        </ng-container>
                        <ng-container matColumnDef="LastTradePrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag><span translate>all.ltp</span></th>
                            <td mat-cell *matCellDef="let element" class="text-right" [class.imnegative]="element.list[0].scripModel.Change<0" [class.impositive]="element.list[0].scripModel.Change>0">
                                <div [class.imnegativebg]="element.list[0].scripModel.TickDirection === -1" [class.impositivebg]="element.list[0].scripModel.TickDirection === 1">
                                    {{element.list[0].scripModel.LastTradePrice | pricepipe:element.list[0].scripModel.DecimalPrecision}} 
                                </div>
                            </td>
                        </ng-container>             
                        <ng-container matColumnDef="MtoM">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag>{{'all.mtom' | translate}}</th>
                            <td mat-cell *matCellDef="let element" [class.imnegative]="(element.list.length==1 && element.list[0].MtoM<0) || (element.list.length==2 && (element.list[0].MtoM+element.list[1].MtoM)<0)" [class.impositive]="(element.list.length==1 && element.list[0].MtoM>0) || (element.list.length==2 && (element.list[0].MtoM+element.list[1].MtoM)>0)" class="text-right">
                                <span *ngIf="element.list.length==1">{{element.list[0].MtoM | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                                <span *ngIf="element.list.length==2">{{(element.list[0].MtoM+element.list[1].MtoM) | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="PNLPercentageChg">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag>{{'all.return_change' | translate}}%</th>
                            <td mat-cell *matCellDef="let element" class="text-right">
                                <span *ngIf="element.list.length==1" [class.imnegative]="element.list[0].PNLPercentageChg<0"  [class.impositive]="element.list[0].PNLPercentageChg>0">{{element.list[0].PNLPercentageChg | percentagepipe}}</span>
                                <span *ngIf="element.list.length==2 && element.Netqty === 0"> {{0 | percentagepipe}} </span>
                                <span *ngIf="element.list.length==2 && element.Netqty !== 0" [class.imnegative]="(((element.list[0].PNLPercentageChg*element.list[0].Netqty)+(element.list[1].PNLPercentageChg*element.list[1].Netqty))/element.Netqty)<0"  [class.impositive]="(((element.list[0].PNLPercentageChg*element.list[0].Netqty)+(element.list[1].PNLPercentageChg*element.list[1].Netqty))/element.Netqty)>0">{{(((element.list[0].PNLPercentageChg*element.list[0].Netqty)+(element.list[1].PNLPercentageChg*element.list[1].Netqty))/element.Netqty) | percentagepipe}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Netbuyamt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag>{{'all.net_buy_val' | translate}}</th>
                            <td mat-cell *matCellDef="let element" class="text-right">
                                <span *ngIf="element.list.length==1">{{element.list[0].NetBuyAmt | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                                <span *ngIf="element.list.length==2">{{(element.list[0].NetBuyAmt+element.list[1].NetBuyAmt) | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Netsellamt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag>{{'all.net_sell_val' | translate}}</th>
                            <td mat-cell *matCellDef="let element" class="text-right">
                                <span *ngIf="element.list.length==1">{{element.list[0].NetSellAmt | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                                <span *ngIf="element.list.length==2">{{(element.list[0].NetSellAmt+element.list[1].NetSellAmt) | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Netamt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag>{{'all.net_amt' | translate}}</th>
                            <td mat-cell *matCellDef="let element" class="text-right">
                                <span *ngIf="element.list.length==1">{{element.list[0].Netamt | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                                <span *ngIf="element.list.length==2">{{(element.list[0].Netamt+element.list[1].Netamt) | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="AvgBuyPrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"  cdkDrag>{{'all.avg_buy_price' | translate}}</th>
                            <td mat-cell *matCellDef="let element" class="text-right">
                                <span *ngIf="element.list.length==1">{{element.list[0].NetBuyAvgPrc | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                                <span *ngIf="element.list.length==2 && (element.list[0].NetBQty != 0 || element.list[1].NetBQty != 0)">{{((element.list[0].NetBuyAmt + element.list[0].NetBuyAmt)/(element.list[0].NetBQty + element.list[0].NetBQty)) | pricepipe:element.list[0].scripModel.DecimalPrecision}} </span>
                                <span *ngIf="element.list.length==2 && element.list[0].NetBQty == 0 && element.list[1].NetBQty == 0">{{0 | pricepipe:element.list[0].scripModel.DecimalPrecision}} </span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="AvgSellPrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"  cdkDrag>{{'all.avg_sell_price' | translate}}</th>
                            <td mat-cell *matCellDef="let element" class="text-right">
                                <span *ngIf="element.list.length==1">{{element.list[0].NetSellAvgPrc | pricepipe:element.list[0].scripModel.DecimalPrecision}}</span>
                                <span *ngIf="element.list.length==2 && (element.list[0].NetSQty != 0 || element.list[1].NetSQty != 0)">{{((element.list[0].NetSellAmt + element.list[0].NetSellAmt)/(element.list[0].NetSQty + element.list[0].NetSQty)) | pricepipe:element.list[0].scripModel.DecimalPrecision}} </span>
                                <span *ngIf="element.list.length==2 && element.list[0].NetSQty == 0 && element.list[1].NetSQty == 0">{{0 | pricepipe:element.list[0].scripModel.DecimalPrecision}} </span>                            
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" [ngClass]="{'highlight': selectedRowIndex == i}" (click)="highlight(row, i)" tabindex="999" (mouseover)="handleMouseOver(row.list[0])" (mouseleave)="handleMouseLeave(row.list[0])" [attr.id]="'pb_tbl' + i"></tr>
                    </table>
                    </div>
            </ng-container>     
        </div>
        </ngx-pull-to-refresh>
    </div>
</div>