<div class="row">
    <div class="col-12">
        <div class="p-2">
            <ngx-pull-to-refresh
            spinnerColor="#ff0000"
            distanceForRefresh="80"
            [isEnable]="isMobile"
            [targetElement]="targetElement"
            (refresh)="myRefreshEvent($event, 'refresh')"
            (loadMore)="alert('loadmore')"
            class = "loader">
                <div *ngIf="technicalsData" [ngStyle]="Height" class="row">
                    <div class = "col-12"><p translate>stock_detail.tech_desc</p></div>
                    <div class = "col-6 border-b mb-2 pt-2 pb-2" translate>stock_detail.resistance</div>
                    <div class = "col-6 border-b text-right pt-2 pb-2 mb-2" translate>stock_detail.support</div>
                    <div class = "col-6 mb-3">
                        <span class = "pr-2"> R1 </span>
                        <span >{{technicalsData.r1 | pricepipe}}</span>
                    </div>
                    <div class = "col-6 text-right mb-3">
                        <span class = "pr-2"> S1 </span>
                        <span >{{technicalsData.s1 | pricepipe}}</span>
                    </div>
                    <div class = "col-4 mb-3">
                        <span class = "pr-2"> R2 </span>
                        <span >{{technicalsData.r2 | pricepipe}}</span>
                    </div>
                    <div class = "col-4 mb-3 text-center">
                        <span class = "pr-2" translate> stock_detail.pivot </span>
                        <span >{{technicalsData.pp | pricepipe}}</span>
                    </div>
                    <div class = "col-4 text-right mb-3">
                        <span class = "pr-2"> S2 </span>
                        <span >{{technicalsData.s2 | pricepipe}}</span>
                    </div>
                    <div class = "col-6">
                        <span class = "pr-2"> R3 </span>
                        <span >{{technicalsData.r3 | pricepipe}}</span>
                    </div>
                    <div class = "col-6 text-right">
                        <span class = "pr-2"> S3 </span>
                        <span >{{technicalsData.s3 | pricepipe}}</span>
                    </div>
                </div>
                <div *ngIf='isNoData'>
                    <app-nodata message = "No Data"></app-nodata>
                </div>
            </ngx-pull-to-refresh>
        </div>
    </div>
</div>