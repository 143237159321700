export class PlaceOrderModel {
    Tsym: string;
    exch: string;
    Ttranstype: string;
    Ret: string;
    qty = '1';
    discqty = '0';
    MktPro = 'NA';
    Price: string = '0';
    TrigPrice = '0';
    Pcode: string;
    DateDays = 'NA';
    AMO = 'NO';
    PosSquareFlg = 'N';
    MinQty = '0';
    BrokerClient = 'Local-local';
    naicCode = '';
    orderSource = 'PtLite';
    userTag = 'PtLite';
    Exch_Algo_Id = '';
    Exch_Algo_Category = '';
    remarks = '';
    criteriaAttribute = '';
    prctyp: string;
    Exchangeseg: string;
    Nstordno: string;
    Filledqty: string;
    Scripname: string;
    Symbol: string;
    Status: string;
    Token: string;
    RequestID: string;
    IsCOPriceFlagEnabled = true;
    ltpOratp: string = 'LTP';
    SqrOffAbsOrticks: string = 'Absolute';
    SqrOffvalue: string;
    SLAbsOrticks: string = 'Absolute';
    SLvalue: string;
    trailingSL: string = 'N';
    tSLticks: string = 'NA';
}