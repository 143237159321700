<app-header *ngIf="isMobile" goback="1" [isSearch] = "false" [title]="'all.search' | translate" [isLoading]="isLoader" [freetrial] =  "isfreetrial" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="!isMobile && showheader" [title]="'all.search' | translate"></app-titlebar>
<mat-card class="webpanelnew">
  <mat-card-title class="m-0 topfix">
    <div class="pb-2 toplandscape m-notche" [class.container] = "!isMobile">
      <div class="row" [class.data-container]="isMobile">
        <div [class.col-11]="!isMobile" [class.col-12]="isMobile" >
          <div class="">
            <div class="searchmob px-2">
              <input autofocus (input)="onSearchChange($event.target.value)" [(ngModel)]="inputValue" #search type="search" aria-label="Search here..." (keypress)="validation($event)" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" id="dynamic-placeholder">
              <a class="searchclearicon" *ngIf="inputValue"  (click)="onClearSearch()">
                <i class="icon-close"></i>
              </a>
            </div>
          </div>
        </div>
         <div *ngIf = "!isMobile" class="col-1">
          <button class="mt-12" mat-button  matSuffix mat-icon-button aria-label="Clear" (click)="close()">
            <i class="icon-close"></i>
          </button>
        </div> 
      </div>
      <div class="row sameer m-0">
        <div class="col-12" *ngIf = "!isfromOC">
          <div class="scrollchips">
            <mat-radio-group [disabled]="isDisable" [(ngModel)]="selectedOption">
              <mat-radio-button value="All" (change) = "selectTab('All')"><span translate>all.all</span></mat-radio-button>
              <mat-radio-button [disabled]="isfromSpOrder" *ngIf = "exTypeArr.includes('Cash')" value="Cash" (change) = "selectTab('Cash')"><span translate>all.cash</span></mat-radio-button>
              <mat-radio-button *ngIf = "exTypeArr.includes('F&O')"  value="F&O" (change) = "selectTab('F&O')"><span translate>all.f&o</span></mat-radio-button>
              <mat-radio-button *ngIf = "exTypeArr.includes('Currency')"  value="Currency" (change) = "selectTab('Currency')"> <span translate>all.currency</span></mat-radio-button>
              <mat-radio-button [disabled]="isfromSpOrder" *ngIf = "exTypeArr.includes('Commodity')"  value="Commodity" (change) = "selectTab('Commodity')"><span translate>all.commodity</span></mat-radio-button>
              <mat-radio-button [disabled]="isfromSpOrder" *ngIf = "defaultFeature && showNews"  value="News" (change) = "selectTab('News')"><span translate>all.news</span></mat-radio-button>
          </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="row" *ngIf = '!isfromnews && (searchResult | async)?.length==0'>
        <div class="col-12 text-center">
          <app-nodata  *ngIf="keyword?.length > 2"></app-nodata>
        </div>
      </div>
      <div class="row" *ngIf = 'isfromnews && filteredOptions.length==0'>
        <div class="col-12 text-center">
          <app-nodata *ngIf="keyword?.length > 2"></app-nodata>
          <span *ngIf="keyword?.length == ''" class="subcolor2 p-3 ts-3 center-holding">{{'all.type_search' | translate}}</span>
        </div>
      </div>
      <div class="row subheaderbg" [class.container] = "isMobile" *ngIf="!isfromnews && recentSearchValues?.length > 0 && (searchResult | async)?.length==0 && keyword == ''">
        <div class="col-11">
          <span class="searchkeywords" *ngFor="let obj of recentSearchValues" (click)="onRecentKeyword(obj.keyword)">{{obj.keyword}}</span>
        </div>
        <div class="col-1 text-right" (click) = "onSugggestionClear()">
          <a (click) = "onSugggestionClear()" class="closetab subcolor2 ts-3 mouse-p"><i class="icon-close"></i></a>
        </div>
      </div>
    </div>
  </mat-card-title>

  <mat-card-content  *ngIf="((searchResult | async)?.length > 0 || filteredOptions.length > 0) && keyword != ''" class="narrowpadding" [class.moretopmargin] = "!isfromOC" [class.pb-45] = "isMobile" [class.topmargin-oc] = "isfromOC">
    <div [class.container] = "!isMobile" #p2>
    <ng-container *ngIf = "!isfromnews">
      <div *ngFor="let option of (searchResult | async ) | slice:0:10; let i = index" tabindex="999" (keydown.arrowdown)="arrowDownEvent(option, selectedRowIndex,$event,p2)" (keydown.arrowup)="arrowUpEvent(option, selectedRowIndex,$event,p2)" [attr.id]="'search_list' + i">
        

          <div class="wlweb watchlistwrapper" [ngClass]="{'highlight': selectedRowIndex == i}">
            <div class="row">
              <div class = "col-6" (click)="result(option, false)">
                <div [ngClass]="{'firstline' : true, 'fnotext' : segmentToExchange(option.exseg) === 'NFO'}"><span class="texttrim">{{option.tsym | symbolpipe}}&nbsp;<span class="ts-2" *ngIf = "option.exseg === 'bse_cm'">({{option.seris}})</span></span> <small>{{segmentToExchange(option.exseg)}}</small></div>
                <div [ngClass]="{'line2' : true, 'texttrim' : true, 'subcolor' : true, 'fnotext' : segmentToExchange(option.exseg) === 'NFO'}">{{option.symdes}}</div>
              </div>
              <div class="col-6 text-right">
                <div [class.imnegative]="option.chng<0" [class.impositive]="option.chng>0" class="valuetext">
                  <div class="firstline">{{option.last | pricepipe:getDecimal(option.exseg)}}</div>
                  <div class="line2">{{option.chng | pricepipe:getDecimal(option.exseg)}} ({{option.pchng | percentagepipe}})</div>
                </div>
                <div class="addbtn" *ngIf = "isfromWL || isfromMWL">
                  <button mat-icon-button (click) = "addStock(option)" class="borderbutton" [disabled]="isAddRemoveDisabled">
                    <i class="icon-minus" matTooltip="{{'all.remove_stock' | translate}}" *ngIf = '(isfromWL && wltokens.includes(option.omtkn+"_"+option.exseg)) || (isfromMWL && wltokens.includes(option.omtkn+"_"+option.exseg))'></i>
                    <i class="icon-plus" matTooltip="{{'all.add_stock' | translate}}" *ngIf ='(isfromWL && !wltokens.includes(option.omtkn+"_"+option.exseg)) || (isfromMWL && !wltokens.includes(option.omtkn+"_"+option.exseg))'></i>
                  </button>
                </div>
                <div *ngIf="isMobile && !isfromMWL && !isfromWL" class="text-right addbtn">
                  <button mat-icon-button (click)="addRecentSearchData(option)"  [matMenuTriggerFor]="menu"><i class="icon-plus"></i></button>
                  <mat-menu #menu="matMenu">
                      <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                      <ng-container *ngFor = "let data of WlArr">
                          <button mat-menu-item (click) = "addStockPopup(data.wlName, option)" [disabled] = "data.isDisable">
                              <span>{{data.wlName}}</span>
                          </button>
                      </ng-container>
                  </mat-menu>
                </div>
              </div>
              <div class="wl-btn" *ngxCacheIf="!isMobile && !(isfromWL || isfromMWL)" [class.d-none]="!M.IS_MOUSE">
                <button (click)='openPlaceOrder("B", option)' class="btn-positive" mat-icon-button matTooltip="{{'all.buy' | translate}}" ><i class="icon-bb"></i></button> 
                <button (click)='openPlaceOrder("S", option)' class="ml-2 btn-negative" mat-icon-button matTooltip="{{'all.sell' | translate}}" ><i class="icon-sb"></i></button>
                <button *ngIf="createGttFlag" matTooltip="{{'all.create_gtt' | translate}}" (click)='openTrigger(option)' class="ml-2 btn-primary" mat-icon-button><i class="icon-aim"></i></button>
                <button (click)="addRecentSearchData(option)" *ngIf = "!isfromWL || !isfromMWL" mat-icon-button matTooltip="{{'all.addto_watchlist' | translate}}"  class="ml-2 btn-primary" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                <mat-menu #menu="matMenu">
                    <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                    <ng-container *ngFor = "let data of WlArr">
                        <button mat-menu-item (click) = "addStockPopup(data.wlName, option)" [disabled] = "data.isDisable">
                            <span>{{data.wlName}}</span>
                        </button>
                    </ng-container>
                </mat-menu>
              </div>
            </div>
          </div>
      </div>


      <ng-container *ngIf = "isLoadMore">
        <div *ngFor="let option of (searchResult | async ) | slice:10:(searchResult | async)?.length; let i = index" tabindex="999" (keydown.arrowdown)="arrowDownEvent(option, selectedRowIndex,$event,p2)" (keydown.arrowup)="arrowUpEvent(option, selectedRowIndex,$event,p2)" [attr.id]="'search_list' + (i + 10)">
          
          <div class="wlweb watchlistwrapper" [ngClass]="{'highlight': selectedRowIndex == i + 10}">
          <div class="row">
            <div class = "col-6" (click)="result(option, false)">
              <div [ngClass]="{'firstline' : true, 'fnotext' : segmentToExchange(option.exseg) === 'NFO'}"><span class="texttrim">{{option.tsym | symbolpipe}}</span>&nbsp;<span class="ts-2" *ngIf = "option.exseg === 'bse_cm'">({{option.seris}})</span> <small>{{segmentToExchange(option.exseg)}}</small></div>
              <div [ngClass]="{'line2' : true, 'texttrim' : true, 'subcolor' : true, 'fnotext' : segmentToExchange(option.exseg) === 'NFO'}">{{option.symdes}}</div>
            </div>
            <div class="col-6 text-right"> 
              <div [class.imnegative]="option.chng<0" [class.impositive]="option.chng>0" class="valuetext">
                <div class="firstline">{{option.last | pricepipe:getDecimal(option.exseg)}}</div>
                <div class="line2">{{option.chng | pricepipe:getDecimal(option.exseg)}} ({{option.pchng | percentagepipe}})</div>
              </div>
              <div class="addbtn" *ngIf = "isfromWL || isfromMWL">
                <button mat-icon-button class="borderbutton" (click) = "addStock(option)" [disabled]="isAddRemoveDisabled">
                  <i class="icon-minus" matTooltip="{{'all.remove_stock' | translate}}" *ngIf = '(isfromWL && wltokens.includes(option.omtkn+"_"+option.exseg)) || (isfromMWL && wltokens.includes(option.omtkn+"_"+option.exseg))' ></i>
                  <i class="icon-plus" matTooltip="{{'all.add_stock' | translate}}" *ngIf = '(isfromWL && !wltokens.includes(option.omtkn+"_"+option.exseg)) || (isfromMWL && !wltokens.includes(option.omtkn+"_"+option.exseg))'></i>
                </button>
              </div>
              <div *ngIf="isMobile && !isfromMWL && !isfromWL" class="text-right addbtn">
                <button mat-icon-button (click)="addRecentSearchData(option)"   [matMenuTriggerFor]="menu"><i class="icon-plus"></i></button>
                <mat-menu #menu="matMenu">
                    <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                    <ng-container *ngFor = "let data of WlArr">
                        <button mat-menu-item (click) = "addStockPopup(data.wlName, option)" [disabled] = "data.isDisable">
                            <span>{{data.wlName}}</span>
                        </button>
                    </ng-container>
                </mat-menu>
              </div>
            </div>
            <div class="wl-btn" *ngxCacheIf="!isMobile && !(isfromWL || isfromMWL)" [class.d-none]="!M.IS_MOUSE"> 
              <button (click)='openPlaceOrder("B", option)' class="btn-positive" mat-icon-button matTooltip="{{'all.buy' | translate}}"><i class="icon-bb"></i></button> 
              <button (click)='openPlaceOrder("S", option)' class="ml-2 btn-negative" mat-icon-button matTooltip="{{'all.sell' | translate}}"><i class="icon-sb"></i></button>
              <button *ngIf="createGttFlag" matTooltip="{{'all.create_gtt' | translate}}" (click)='openTrigger(option)' class="ml-2 btn-primary" mat-icon-button><i class="icon-aim"></i></button>
              <button (click)="addRecentSearchData(option)" *ngIf = "!isfromWL || !isfromMWL" mat-icon-button matTooltip="{{'all.addto_watchlist' | translate}}"  class="ml-2 btn-primary" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
              <mat-menu #menu="matMenu">
                  <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                  <ng-container *ngFor = "let data of WlArr">
                      <button mat-menu-item (click) = "addStockPopup(data.wlName, option)" [disabled] = "data.isDisable">
                          <span>{{data.wlName}}</span>
                      </button>
                  </ng-container>
              </mat-menu>
            </div>
          </div>
        </div>
      
        </div>
      </ng-container>
    </ng-container>


    
    <ng-container *ngIf = "isfromnews">
      <ng-container *ngFor="let option of filteredOptions">
        <div (click)="onSelect(option)" class="watchlistwrapper">
          <div class="row">
            <div class="col-12"># {{option.tag}}</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  </mat-card-content>
  <!-- <mat-card-actions>
    <div class="row">
      <div class="col-12">
        <button mat-flat-button class = "w-100" class = "btn-primary" (click) = "close()"><span translate>watchlist.close</span></button>
      </div>
    </div>
  </mat-card-actions> -->
</mat-card>
<app-recentlyview [isOpen] = "isOpen" [currentPageName]="currentPageName" [fromWatchlist]="isMobile === true ? (isfromMWL || isfromWL) : formWL" [selWLName]="isMobile === true ? wlname : wlnameSearch"
 (openStockDetailPage)='openStockDetail($event)' (itemClicked)="result($event)" *ngIf="!isfromnews && (searchResult | async)?.length==0 && keyword == ''"></app-recentlyview>
 <div *ngIf = "isfromWL || isfromMWL "[class.mx-2]="!isMobile">
  <div [class.btn-pos] = "!isMobile" [class.footerbtn] = "isMobile">
    <button mat-flat-button  class = "btn-primary w100" (click) = "close()"><span translate>all.done</span></button>
  </div>
</div>
 