<mat-progress-bar *ngIf="isLoading" class = "progressbarposition" mode="indeterminate"></mat-progress-bar>
<app-banner [isBannerClose] = "true"></app-banner>
<div class="header" [class.absheader]="!isMobileLand && goback!=1" [class.header-unpin]="hideshow==2" [class.header-pin]="hideshow==1" [class.header-relative]="isMobileLand && goback==0">
    <div class="row">
      <div class="d-flex mr-0 pr-0" [class.col-12] = "!(isOC || isEventFilter || isSearch || isDisplayMenu || balanceValue || isFunds)" [class.col-9] = "goback!=1 && (isOC || isEventFilter || isSearch || isDisplayMenu || isFunds)" [class.col-7] = "goback==1 && (isOC || isEventFilter || isSearch || isDisplayMenu || isFunds)" [class.col-6]="balanceValue">
          <button *ngIf="goback==1" mat-icon-button [class.float-left]="!balanceValue" (click)="back()"><i class="icon-goback"></i></button>
          <img *ngIf="goback!=1" class="logo" src="assets/img/app-icon.svg" alt=""/>
          <span class="title" *ngIf="goback==1" [class.float-left]="!balanceValue" (click)="titleClicked()">{{title}} <em *ngIf = "freetrial" class="freetrial"> Trial</em></span>

          <ng-container *ngIf="goback!=1">
            <div class="index-pagetop mt-1" *ngFor="let index of indexList; let i = index;">
              <div (click)="openIndexSpot(i)">
                <div class="primetext textuppercase ts-2 texttrim" matTooltip="{{index.Name}}">{{index.Name | uppercase}}</div>
                <div class="ts-2" [class.imnegative]="index.Change<0" [class.impositive]="index.Change>0">{{index.IndexValue | pricepipe:2}} <small>{{index.PerCng | percentagepipe}}</small></div>
              </div>
            </div>                
          </ng-container>
        </div>
      <div *ngIf="isOC || isEventFilter || isSearch || isDisplayMenu || balanceValue || isFunds" class="ml-0 pl-0 text-right" [class.col-3] = "goback!=1 && (isOC || isEventFilter || isSearch || isDisplayMenu || isFunds)" [class.col-5] = "goback==1 && (isOC || isEventFilter || isSearch || isDisplayMenu || isFunds)" [class.col-6]="balanceValue">
         <div *ngIf="balanceValue != ''" class="pt-2 pr-1 primetext"><small class="subcolor ts-2 ">{{ ((isWithdrawFunds == true) ? 'limits.avl_cash' : 'limits.avl_margin') | translate }}:</small>{{balanceValue | pricepipe}}</div>
         <button *ngIf="isFunds"  mat-icon-button (click) = "openTransaction()"><i class="icon-history primetext"></i></button>
          <button *ngIf="isOC" [disabled] = "utility.disableResetButton" mat-icon-button (click) = "gotoOptionChain()"><i class="icon-insert-chart"></i></button>
          <button *ngIf="isOC" [disabled] = "utility.disableResetButton"  mat-icon-button (click) = "resetOptionChain()"><i class="icon-reset"></i></button>
          <button *ngIf="isEventFilter" mat-icon-button (click) = "gotoEvent()"><i class="icon-filter"></i></button>
          <button *ngIf="isSearch" mat-icon-button (click) = "gotoSearch()"><i class="icon-search"></i></button>
          <button *ngIf="isBetaFeature && !isEventFilter" mat-icon-button (click) = "gotoAlert()"><i class="icon-noti"></i></button>
          <button *ngIf="false" class="btn-icon" (click) = "reloadData()" mat-icon-button><i class="icon-autorenew"></i></button>
          <button *ngIf="false" disabled="false" #menuTrigger="matMenuTrigger" class="btn-icon" mat-icon-button [matMenuTriggerFor]="sortnav" (menuClosed)="matMenuClosed()"><i class="icon-sort"></i></button>
          <mat-menu #sortnav="matMenu" [class.col-12] = "true">
            <div class="primetext pt-2 px-2">{{'header.sorting' | translate}}</div>
            <button (click)="sortingItemSelected(sortingItem)" mat-menu-item *ngFor="let sortingItem of sortItemList" >{{sortingItem}} <i [class.icon-godown]= "chipSelected === 'Desc' && selectedSortedItem === sortingItem" [class.icon-goup]= "chipSelected === 'Asc' && selectedSortedItem === sortingItem"></i></button>
          </mat-menu>
          <span *ngIf="contactList.length > 0"><a href="tel:+91{{contactList[0]}}"><button mat-icon-button><i class="icon-callus"></i></button></a></span>
          <button *ngIf="!utility.hideNotification && goback!=1" class="btn-icon" (click)="notificationClicked()" mat-icon-button><i class="icon-noti"></i></button>
          <button *ngIf = "isDisplayMenu" class="btn-icon" mat-icon-button [matMenuTriggerFor]="menu"><i class="icon-nav"></i></button>
          <mat-menu #menu="matMenu">
              <button mat-menu-item (click) = "profile()" translate>header.my_profile</button>
              <button mat-menu-item (click) = "changePwd()" translate>header.change_password</button>
              <!-- <button *ngIf = "isIAM" mat-menu-item (click) = "changeMpin()" translate>header.change_mpin</button> -->
              <button *ngIf = "false" mat-menu-item (click) = "userLogs()" translate>header.user_logs</button>
              <button mat-menu-item (click) = "lotWeight()" translate>header.lot_weight</button>
              <button mat-menu-item (click) = "openGenSetting()" translate>header.settings <i class="icon-setting right-navicon"></i></button>
              <button mat-menu-item (click) = "openAbout()" translate>login.about</button>
              <button mat-menu-item (click) = "openPrivacy()" translate>login.policies</button>
              <button mat-menu-item (click) = "openTerms()" translate>login.t_c</button>
              <button mat-menu-item (click) = "openContact()" translate>login.contact_us</button>
              <!-- <button mat-menu-item (click) = "openFeedback()" translate>header.feedback</button> -->
              <button *ngIf = "backOfficeType != 0" mat-menu-item (click) = "openBackOffice()">Back Office</button>
              <button *ngIf = "ticketSupport" mat-menu-item (click) = "openTicket()" translate>header.raise_ticket</button>
              <button mat-menu-item (click) = "logout()" translate>header.logout</button>
              <div class="row" *ngIf="contactList.length > 0">
                <div class="col-12 callme">
                  <span translate>header.call_us</span>
                  <a *ngFor="let contact of contactList" href="tel:+91{{contact}}">+91 {{contact}}</a>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                    <div class="versionnav pr-1 pb-3">{{'header.version' | translate}} {{version}} <button class="btn-positive float-right" *ngIf = "isUpdateAvailable" (click)="checkUpdate()" translate>portfolio.update</button></div>
                </div>
              </div>
              <div class="row" *ngIf="Pwa.promptEvent">
                <div class="col-12">
                    <div class="versionnav">
                        {{'header.install_msz' | translate}}
                        <button class="btn-plain" (click)="installApp()" translate><i class="icon-refresh primetext"></i> <span class="primetext" translate> header.install</span></button>
                    </div>
               </div>
            </div>              
          </mat-menu>      
      </div>  
    </div>  
</div>