import CryptoJS from 'crypto-js';
import { OrderModel } from '../model/order.model';
import { Keys } from './keys';
import { ScripModel } from '../model/scrip.model';
import { Router } from '@angular/router';
import { TradeModel } from '../model/trade.model';
import { ScripMarketPictureModel } from '../model/scripMarketPictureModel';
import { IndexModel } from '../model/index.model';
import { RollOverModel } from '../model/rollover.model';
import { PlaceorderComponent } from '../view/protradelite/placeorder/placeorder.component';
import { NewsComponent } from '../view/protradelite/news/news.component';
import { PersistenceStorage } from './storage';
import { PlaceorderSettingsModel } from '../model/placeordersettings.model';
import { environment } from 'src/environments/environment';
import { MyAlertDialogComponent } from '../view/protradelite/home/watchlist/watchlist.component';
import { Subject } from 'rxjs';
import { Preferences, ReturnType } from './Preferences';
import { PositionModel } from '../model/position.model';
import { HoldingModel } from '../model/holding.model';
import { SpreadPlaceorderComponent } from '../view/protradelite/spreadorder/spreadplaceorder/spreadplaceorder.component';
import { SpreadOrderModel } from '../model/spreadorder.model';
import { User } from '../view/protradelite/Fingerprint/user';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerMockService } from '../view/protradelite/Fingerprint/server-mock.service';
import { WebAuthnService } from '../view/protradelite/Fingerprint/web-authn.service';
import { GeneralSettingsComponent } from '../view/protradelite/generalsettings/generalsettings.component';
import { CreateTriggerComponent } from '../view/protradelite/createTrigger/createTrigger.component';

declare let JSEncrypt: any;
declare let app;
declare const moment: any;
export enum fingerprintDecision{Disabled, Enabled, NotSupport}
export enum ColumnState { Stable, Modified }
export enum ChannelType { None, Watchlist, Order, Position, Holding, Trade, Shared, Scanner, Market, Index, Dashboard, Optionchain, GTT}
export class Utility {
    static SyntheticFuturePrice;
    static lastPageSelected = -1;
    static atmStrike;
    static ocDiff;
    static MySub = {'oc' : []};
    static SYNC = 0; // 0 sync done , 1 sync required , 2 syncing
    static SELECTED_BASKET_TAB = 0;
    static MinWidth = 500;
    static SELECTED_SCRIP:ScripModel;
    static SELECTED_ORDER:OrderModel;
    static SELECTED_POSITION:PositionModel;
    static SELECTED_TRADE_ORDER:TradeModel;
    static SELECTED_HOLDING:HoldingModel;
    static OCWebAssambly;
    static MOBILE = false;
    static IsFingerPrint = 0;
    static LocalDivId =0;
    static FINGERPRINT = 'FINGERPRINT';
    static FINGERPRINT_RES = 'FINGERPRINT_RES';
    static FINGERPRINT_STATUS = 'FINGERPRINT_STATUS';
    static UA;
    static saveLayoutArr = [{},{},{},{},{},{},{},{},{},{},{}];                           
    static ocData;
    static newsComponent: NewsComponent;
    static SELECTION = 0;
    static TOUCH_TYPE;
    static PROFILE = 'B';
    static CACHE = {'trade':[], 'position':[]};
    static CACHE_STORE: PersistenceStorage;
    static scripDetails;
    static OrderModelMap: any;
    static ScripModelMap: any;
    static ScripModelMapForUnSub: any;
    static ChannelScripMap: any;
    static ChannelIndexMap: any;
    static IndexModelMap: any;
    static SpreadOrderModelMap: any;
    static RolloverModelMap: any;
    static TradeModelMap: any;
    static SegmentDetails = {};
    static RETENTION_MAP = new Map();
    static NavigationData: any;
    static MarketPictureModelMap: any;
    static LOT_WEIGHT_MAP;
    static SEC_INFO_MAP = new Map();
    static exchangeToProductMap: any;
    static IS_POPUP;
    static LTPCoverPercgeMap = new Map();
    static COVER_ORDER_PRICE_FLAG;
    static IS_MOBILE;
    static IS_MOBILE_SOURCE;
    static IS_MOBILE_LAND;
    static IS_TABLET;
    static dialogRef;
    static olddialogRef;
    static dialogData;
    static Action = { 'B': 'BUY', 'S': 'SELL' };
    static PRODUCT_CNC = 'CNC';
    static WorkBook1 = [];
    static WorkBook2 = [];
    static WorkBook3 = [];
    static WorkBook4 = [];
    static WorkBook5 = [];
    static basketOrderSummary;
    static isLogin: boolean;
    static PLACEORDERSETTINGSMODEL = new PlaceorderSettingsModel();
    static LOGGING = true;
    static CONSOLEMODE = 'debug';
    static recentlySearchedScrips = [];
    static flashType = new Subject<any>();
    static orderBookData = new Subject<any>();
    static loginSubject = new Subject<any>();
    static ORDER_PLACED_EVENT = new Subject<string>();
    static selectWLTab = new Subject<any>();
    static ChannelMap =  new Map<number, boolean >();
    static checkfunds : boolean;
    static installApp = new Subject<any>();
    static isKeyboardHidden = new Subject<any>();
    static preferencesUpdated = new Subject<any>();
    static orientationChanged = new Subject<any>();
    static holdingAvgPriceChanged =  new Subject<any>();
    static tradeBookData = new Subject<any>();
    static positionBookData = new Subject<any>();
    static watchlistData = new Subject<any>();
    static watchlistDataFromMWL = new Subject<any>();
    static callOptionCalculator = new Subject<any>();
    static isBannerHidden : boolean;
    static hideNotification;
    static showBannerMessage = new Subject<any>();
    static getColInWL = new Subject<any>();
    static optionchaindata = new Subject<any>();
    static newsSearchReq = new Subject<any>();
    static selectedBookTab = 0;
    static selectedPortfolioTab = 0;
    static reloadLimit = new Subject();
    static transactionRefresh = new Subject();
    static watchlistAction = new Subject<any>();
    static addFundsMainPage = new Subject();
    static openWithDraw = new Subject();
    static positionModelMap;
    static orderDataSubscription;
    static openOrderBook = new Subject<any>();
    static candeactivate = true;
    static watchlistDataMap = new Map();
    static userId = undefined;
    static detailedOrder;
    static stockDetailIndex = 0;
    static disableResetButton = true;
    static closeAddOverLay = new Subject();
    static tradeDetail;
    static isDialogOpened = false;
    static hideBellIcon : boolean;
    static holdingDetails;
    static holdings;
    static positionDetail;
    static positionBreakUpDetail;
    static LTPCOVERPER_MAP = new Map();
    static positionBook = new  Subject<any>();
    static closeWithdraw = new Subject();
    static portfolioReloadPage = new Subject<any>();
    static bookReloadPage = new Subject<any>();
    static orderBookReloadPage = new Subject<any>();
    static tradeBookReloadPage = new Subject<any>();
    static watchlistSortIconClicked = new Subject<string>();
    static orderBookSortIconClicked = new Subject<string>();
    static tradeBookSortIconClicked = new Subject<string>();
    static positionBookSortIconClicked = new Subject<string>();
    static holdingBookSortIconClicked = new Subject<string>();
    static watchlistSettingOpen = new Subject<any>();
    static InputsDataGet  = new Subject<any>();
    static webReloadComponent = new Subject<any>();
    static getTriggers = new Subject<any>();
    static indexCall = new Subject();
    static optionChainDataMap = new Map();
    static isOptionChainReloadData = true;
    static selectedTab = -1;
    static modifyWithdrawalData = [];
    static fillWithdrawalData = new Subject();
    static isPlaceorderOpen = false;
    static changeTranctionType = new Subject<any>();
    static spreadOrderDetail;
    static webAuthnAvailable = !!navigator.credentials && !!navigator.credentials.create;
    static isFingerprintDeviceAvailable = window.PublicKeyCredential && PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    static fcmNotificationCollection = new Map<string, any>();
    static fcmNotificationCount = new Subject<any>();
    static isLogout = false;
    static timeId;
    static hidden = false;
    static limitBalance;
    static avl_margin;
    static scannerName="";
    static scannerTypeValue=""
    static stockFlag = false;
    private static encryptionKey = '123456$#@$^@1ERF';
    static fromNewWorkspace: boolean;
    static BufferMap = {'0':true,'1':false,'2':false,'3':false,'4':false,'5':false, 
                        '6':false,'7':false,'8':false,'9':false,'10':false }  
    static WorkspaceArr = [0];
    static StockDetailArrs = [];
    static StockKeyMap = {6:{},7:{},8:{},9:{},10:{}};       
    static StockListArr = [];
    static selectedWorkspace = 0;
    static workspaceMap = {0: ['WatchList', 'Order Book', 'Trade Book', 'Position Book', 'Holding Book', 'Funds', 'Investment Boxes'], 1: [], 2: [], 3: [], 4: [], 5:[]};
    static basketPositionData;
    static basketName;
    static callInvestorBaskets = new Subject<any>();
    static watchlistToastCnt = 0;
    static selectedRowIndex;
    static inputproplist ={1: {}, 2: {}, 3: {}, 4: {}, 5: {}};
    static chartFlag : boolean;
    static setSize = {};
    static todaysSipData = [];
    static selectedSipData;
    static isTodaySipOpen = true;
    static callBasketSip = new Subject<any>();
    static fromTodaySip = false;
    static triggerData = {};
    static selectedTriggerData = {};
    static selectedTriggertyp = 'GTT_SINGLE';
    static getQProfileData = new Subject<any>();
    static fromBasket = false;
    static placedSipData = {};
    static isFromSettingPO = false;
    static iscombineQProfileClose = true;
    static payoutamt;
    static requestCode;
    static shortcutsMap = {
    'stock_detail.markets': {
       'dashboard.watchlist': 'F4',
       'watchlist.add_watchlist_stock': 'insert or ctrl + i',
       'watchlist.watchlist_upward': 'shift + up',
       'watchlist.watchlist_downward': 'shift + down',
       'watchlist.delete_watchlist_stock': 'del or ctrl + e',
       'watchlist.manage_watchlist' : 'ctrl + shift + F6',
       'watchlist.Advances_Declines': 'alt + shift + a',
       'equity.most_active_val_most_active_vol': 'alt + shift + v',
       'more.market_status': 'alt + shift + s',
       'derivative.option_chain': 'alt + shift + o',
       'more.top_indices': 'alt + shift + i',
       'equity.gainers_losers': 'alt + shift + t'
     },
     'books.report': {
       'more.order_book': 'F3',
       'more.position_book': 'alt + F6',
       'more.holding_book': 'alt + F9',
       'watchlist.cancel_order': 'shift + F1',
       'basket.exit_order': 'shift + F11',
       'watchlist.modify_order': 'shift + F2',
       'books.order_detail': 'shift + F12',
       'books.position_detail': 'shift + F7',
       'tooltip.product_convert': 'shift + F5'
     },
     'books.global': {
       'header.logout': 'F10',
       'header.change_password': 'ctrl + shift + F9',
       'header.change_mpin': 'ctrl + shift + F8',
       'header.my_profile': 'ctrl + shift + F7',
       'more.general_settings': 'ctrl + shift + F5',
       'search.search': 'ctrl + shift + s',
       'tooltip.stock_detail': 'F6'
     },
     'basket.trade': {
       'spread.buyorder': 'F1 or +',
       'spread.sellorder': 'F2 or -',
       'stock_detail.chart': 'shift + F9',
       'stock_detail.depth': 'shift + d',
       'all.amo_buy_ord': 'ctrl + f1',
       'all.amo_sell_ord': 'ctrl + f2'
     },
     'more.funds': {
       'more.limits': 'ctrl + shift + u'
     },
     'more.news_events': {
       'more.corporate_events': 'alt + shift + e',
       'more.derivative_news': 'alt + shift + d',
       'more.equity_news': 'alt + shift + n',
       'more.exchange_message': 'alt + shift + m',
       'more.general_news': 'alt + shift + g'
     },
     "derivative.stk": {
       'stock_detail.technicals': 'shift + t',
       'stock_detail.stock_news': 'shift + s'
     },
     'scanners.scanners': {
       'scanners.circuit_break': 'alt + 3',
       'scanners.HLBreaker': 'alt + 4',
       'scanners.openHL': 'alt + 5',
       'scanners.res_sup': 'alt + 1',
       'scanners.tech_scanner': 'alt + 6',
       'scanners.vol_shocker': 'alt + 2',
     },
     'more.tools_calculator': {
       'more.option_calc': 'alt + shift + c'
     },
     'dashboard.workspace': {
       'dashboard.next_workspace': 'shift + tab',
       'dashboard.delete_workspace': 'ctrl + q',
       'dashboard.add_workspace': 'alt + n',
     },
   };

    static getFreeBUffer(isStock){
        if(!isStock){
            for(let i=1;i<6;i++){
                if(!this.BufferMap[i+'']) {
                    this.BufferMap[i] = true;
                    return i;
                }
            }
        } else {
            for(let i=6;i<11;i++){
                if(!this.BufferMap[i+'']) {
                    this.BufferMap[i] = true;
                    return i;
                }
            }
        }
        return -1
    }

   static arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
   }

    static addWorkSpace(isStock) {
      let f = this.getFreeBUffer(isStock);
      if (f != -1) {
        Utility.selectedWorkspace = f;
        (isStock)?this.StockDetailArrs.push(f):this.WorkspaceArr.push(f);
     }
     return f;
  }
  static loadWorkSpace(n, isStock) {
    this.selectedWorkspace = n;
  }
  static deleteWorkSpace(l, isStock) {
    this.BufferMap[l] = false;
    let arr =[];
    arr.push(...this.WorkspaceArr);
    arr.push(...this.StockDetailArrs);
    if( l === this.selectedWorkspace ) {
       let n = arr.indexOf(l);
       this.selectedWorkspace = arr[n-1];
    } 
    if (isStock) {
      this.StockDetailArrs = this.arrayRemove(this.StockDetailArrs, l);
      this.StockKeyMap[l] = {};
    } else {
      this.WorkspaceArr = this.arrayRemove(this.WorkspaceArr, l);
    }
    return this.selectedWorkspace;
  }
  static saveWorkspace(){
    Preferences.setItem('w_save',Utility.saveLayoutArr);
    Preferences.setItem('w_sel',Utility.selectedWorkspace);
    Preferences.setItem('w_map',Utility.workspaceMap);
    Preferences.setItem('w_iplist',Utility.inputproplist);
    Preferences.setItem('w_warr',Utility.WorkspaceArr);
    Preferences.setItem('w_sarr',Utility.StockDetailArrs);
    Preferences.setItem('w_skmap',Utility.StockKeyMap);
    Preferences.setItem('w_slarr',Utility.StockListArr);
  }
    static getTradingSegments() {
        return JSON.parse(Preferences.getPreference('userParameter'))['exchDeatil'];
    }
    static getTradingExchanges() {
        if (environment.isQuickRest) {
            return JSON.parse(Preferences.getPreference('userParameter'))['exArr'];
        }
        else {
            return JSON.parse(Preferences.getPreference('userParameter'))['exarr'];
        }
    }
    static setIsLoggedIn() {
        this.isLogin = (Preferences.getPreference('userLogIn') === '0') ? false : true;
    }
    static getOmniScripModel(omnitoken, exchangesegment): ScripModel {
        return this.getScripModel(omnitoken, exchangesegment);
    }

    static getErrorMessage(code: number,msg) {
        switch (code) {
            case 49:
                return 'all.invalid_credentials';
            case 101:
                return 'all.user_blocked';
            case 200:
                return 'all.success';
            case 204:
                return 'all.no_content_code';
            case 400:
                return 'all.invalid_user_detail';
            case 401:
                return 'all.session_expired';
            case 404:
                return 'all.not_found';
            case 408:
                return 'all.request_timeout';
            case 409:
                return 'all.entry_exist';
            case 422:
                return 'all.setmpin_error';
            case 423:
                return 'all.modify_mpin_error';
            case 425:
                return 'all.change_status_error';
            case 426:
                return 'all.change_pwd_error';
            case 427:
                return 'all.set_pwd_error';
            case 428:
                return 'all.unblock_user_error';
            case 429:
                return 'all.block_user_error';
            case 430:
                return 'all.invalid_mpin';
            case 431:
                return 'delete_user_err_code';
            case 432:
                return 'all.change_mpin_pwd_error';
            case 432:
                    return 'all.resend_otp_error';
            case 438:
                    return 'all.user_disable';
            case 500:
                return 'all.internal_server_rror';
            case 502:
                return 'all.authenticatio_failed';
            case 509:
                return 'all.invalid_data';
            case 510:
                return 'all.token_expire';
            case 511:
                return 'all.invalid_token';
            case 521:
                return 'all.failure';
            case 435:
                return 'all.invalid_otp';
            case 441:
                return 'all.password_expired';
            case 0:
                return 'all.try_sometime';
            default:
                return msg;
        }
    }

    static GetScripMarketPictureModel(token: String, exchangesegment: string, restclient = null, subscribe = true) {
        if (Utility.MarketPictureModelMap === undefined)
            this.MarketPictureModelMap = new Map();
        
        if(!Utility.isExchangeSegAllowed(exchangesegment)) {
            const depthModel = new ScripMarketPictureModel();
            depthModel.Token = token;
            depthModel.ExchangeSegment = exchangesegment;
            return depthModel;
        }
        
        if (this.MarketPictureModelMap.get(exchangesegment + '_' + token) === undefined) {
            const depthModel = new ScripMarketPictureModel();
            depthModel.Token = token;
            depthModel.ExchangeSegment = exchangesegment;
            this.MarketPictureModelMap.set(exchangesegment + '_' + token, depthModel);
            
            if (subscribe && restclient != null) {
                depthModel.IsSubscribe = false;
                if (environment.isQuickRest)
                    restclient.marketDataFeeder.subscribeDepthScripSnapshot(token, exchangesegment);
                else
                    restclient.marketDataFeeder.subscribeDepthScrip();
            }
        }
        return this.MarketPictureModelMap.get(exchangesegment + '_' + token);
    }

    static IsLot(Exchange: string) {
        let flag = false;
        try {
            if (this.LOT_WEIGHT_MAP !== null && this.LOT_WEIGHT_MAP !== undefined) {
                if (Object.keys(this.LOT_WEIGHT_MAP).includes(Exchange) && this.LOT_WEIGHT_MAP[Exchange] === 'Lots') {
                    flag = true;
                }
            }
        }
        catch (e) {
            Utility.print(e);
            flag = false;
        }
        return flag;
    }

    static isExchangeSegAllowed(exseg){
        const allowedExchanges = Utility.getTradingExchanges();
        const exch = Utility.segmentToExchange(exseg);
        if(allowedExchanges.includes(exch)) {
            return true;
        }
        return false;
    }

    static getScripModel(scriptoken, exchangesegment, restclient = null, subscribe = true, channel = ChannelType.Shared): ScripModel {
        scriptoken = scriptoken.replace(' ', '|');
        if (Utility.ScripModelMap === undefined) {
            this.ScripModelMap = new Map();
            this.ChannelScripMap = new Map();
            this.ScripModelMapForUnSub = new Map();
        }
        if(!Utility.isExchangeSegAllowed(exchangesegment)) {
           if(this.ScripModelMapForUnSub.get(exchangesegment + '_' + scriptoken) === undefined) {
                const sm = new ScripModel();
                sm.Token = scriptoken;
                sm.ExchangeSegment = exchangesegment;
                sm.ExchangeName = Utility.segmentToExchange(exchangesegment);
                sm.DecimalPrecision = Utility.getDecimal(exchangesegment);
                this.ScripModelMapForUnSub.set(exchangesegment + '_' + scriptoken, sm);
                return sm;
            } else {
                return this.ScripModelMapForUnSub.get(exchangesegment + '_' + scriptoken);
           }
        }
        if(scriptoken === 'asdfg'){
            const sm = new ScripModel();
            sm.Token = scriptoken;
            sm.ExchangeSegment = exchangesegment;
            sm.ExchangeName = Utility.segmentToExchange(exchangesegment);
            return sm;
        }

        if (this.ScripModelMap.get(exchangesegment + '_' + scriptoken) === undefined) {
            const sm = new ScripModel();
            sm.Token = scriptoken;
            sm.ExchangeSegment = exchangesegment;
            sm.ExchangeName = Utility.segmentToExchange(exchangesegment);
            this.ScripModelMap.set(exchangesegment + '_' + scriptoken, sm);
            sm.DecimalPrecision = Utility.getDecimal(exchangesegment);
        }

        const model = this.ScripModelMap.get(exchangesegment + '_' + scriptoken)

        if (channel !== ChannelType.None) {
            if (this.ChannelScripMap.get(channel) === undefined) {
                const scripMap = new Map();
                scripMap.set(exchangesegment + '_' + scriptoken, model);
                this.ChannelScripMap.set(channel, scripMap);
            } else {
                this.ChannelScripMap.get(channel).set(exchangesegment + '_' + scriptoken, model);
            }
        }
        if(this.ChannelMap.get(channel) !== undefined && !this.ChannelMap.get(channel)){
            this.resumeChannel(restclient, channel);
        }        
        
        if (subscribe && restclient != null) {
            (environment.isQuickRest) ? restclient.marketDataFeeder.SubscribeSnapshot([channel]) : restclient.marketDataFeeder.SubscribeNow([channel]);
        }
        return model;
    }

    static resumeChannel(restclient, channel) {
        if(typeof(channel) === 'object')
            restclient?.marketDataFeeder.resumeChannel(channel);
        else if (typeof(channel) === 'number' && channel !== ChannelType.None && channel !== ChannelType.Shared){
            restclient?.marketDataFeeder.resumeChannel([channel]);
            this.ChannelMap.set(channel,true);
        }
    }

    static pauseChannel(restclient, channel) {
        if(typeof(channel) === 'object')
            restclient?.marketDataFeeder.pauseChannel(channel);
        else if (typeof(channel) === 'number' && channel === ChannelType.Shared){
            restclient?.marketDataFeeder.unsubscribe(channel)
            this.ChannelScripMap?.delete(channel);
        }
        else if (typeof(channel) === 'number' && channel !== ChannelType.None){
            restclient?.marketDataFeeder.pauseChannel([channel]);
            this.ChannelMap.set(channel,false);
        }
    }
    
    static getUserId() {
        if (this.userId === undefined || this.userId === null) {
            this.userId = Preferences.getPreference('userid');
        }
        return this.userId;
    }
    static openPage(router: Router, currentPageName, openPageName, componentName, dialog, openPageMode?, data_obj?, h = '580px', w = '500px', currentComponentName?) {
        Preferences.setItem('currentpage', currentPageName);
        Preferences.setItem('pagetitle', openPageName);
        if (openPageMode === undefined) {
            openPageMode = 'link';
        }
        if( currentPageName === 'placeorder')
            this.isPlaceorderOpen = true;
        if (openPageMode === 'popup') {
            this.IS_POPUP = true;
            this.dialogData = data_obj;
            this.isDialogOpened = true;
            this.dialogRef = (this.IS_TABLET && this.isPlaceorderOpen) ?  dialog.open(componentName, { disableClose: false, autoFocus: false, width: w, panelClass: 'custom-placeorder' }) : dialog.open(componentName, { disableClose: false, autoFocus: false, width: w });
            this.dialogRef.afterClosed().subscribe(result => {
                this.isDialogOpened = false;
                if (currentPageName === 'managewl' && openPageName === 'search') {
                    currentComponentName.setWatchList();
                    this.dialogRef = this.olddialogRef;
                } else if (currentPageName === 'watchList' && openPageName === 'search') {
                    currentComponentName.getWatchlistData();
                } else if (currentPageName === 'watchList' && openPageName === 'managewatchlist') {
                    currentComponentName.getAllWatchlistData();
                    this.dialogRef = this.olddialogRef;
                    if (result)
                        currentComponentName.getCols(result.hideColArr);
                } else if (currentPageName === 'addalert') {
                    currentComponentName.setData();
                    this.dialogRef = this.olddialogRef;
                } else if (currentPageName === 'optioncalculator') {
                    currentComponentName.setData();
                    this.dialogRef = this.olddialogRef;
                } else if (currentPageName === 'watchList' && openPageName === 'watchListPopup') {
                    this.dialogRef = this.olddialogRef;
                    if (result)
                        currentComponentName.getCols(result.hideColArr);
                }
                else if( currentPageName === 'placeorder')
                    this.isPlaceorderOpen = false;
                if (this.SELECTION === 7) {
                    this.newsComponent.update();
                }

                // this.animal = result;
            });
        } else {
            this.IS_POPUP = false;
            router.navigate([openPageName]);
        }
    }
    static getIndexModel(name, exchange, restclient = null, subscribe = true, channel = ChannelType.Index): IndexModel {
        if (Utility.IndexModelMap === undefined) {
            this.IndexModelMap = new Map();
            this.ChannelIndexMap = new Map();
        }

        if (this.IndexModelMap.get(name) === undefined) {
            const im = new IndexModel();
            im.Exchange = exchange;
            im.Token = name;
            im.Name = name;
            im.ExchangeSegment = Utility.exchangeToSegment(exchange);
            this.IndexModelMap.set(name, im);
        }

        const model = this.IndexModelMap.get(name);

        if (channel !== ChannelType.None) {
            if (this.ChannelIndexMap.get(channel) === undefined) {
                const indexMap = new Map();
                indexMap.set(name, model);
                this.ChannelIndexMap.set(channel, indexMap);
            } else {
                this.ChannelIndexMap.get(channel).set(name, model);
            }
        }                    

        if (subscribe && restclient != null) {
            (environment.isQuickRest) ? restclient.marketDataFeeder.SubscribeIndexSnap(channel) : restclient.marketDataFeeder.SubscribeIndexNow(channel);
        }
        if(this.ChannelMap.get(channel) !== undefined && !this.ChannelMap.get(channel)){
            this.resumeChannel(restclient, channel);
        }
        return this.IndexModelMap.get(name);
    }

    static getRolloverModel(name, exchange, restclient = null, subscribe = true): RollOverModel {
        if (Utility.RolloverModelMap === undefined)
            this.RolloverModelMap = new Map();

        if (this.RolloverModelMap.get(name) === undefined) {
            const rm = new RollOverModel();
            rm.TSymbol = name;
            rm.Exchange = Utility.segmentToExchange(exchange);
            this.RolloverModelMap.set(name, rm);
            if (subscribe) {
                if (restclient != null) {
                    restclient.marketDataFeeder.SubscribeIndexNow(ChannelType.Index);
                }
            }
        }
        return this.RolloverModelMap.get(name);
    }

    public static checkPriceLength(str, decimalPrecision) {
        const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + decimalPrecision + '}$');
        return regex.test(str);
    }

    public static checkQty(str, decimalPrecision) {
        const regex = new RegExp('^[0-9]{0,8}$|^[0-9]{1,' + decimalPrecision + '}$');
        return regex.test(str);
    }

    public static mergeArrays(arr1, arr2) {
        return arr1.map(function (item, index) {
            return {
                yterm: arr1[index],
                xterm: arr2[index]
            };
        });
    }

    public static getDecimal(sm) {
        switch (sm) {
            case 'cde_fo': return 4;
            case 'nse_cm': return 2;
            default: return 2;
        }
    }

    static GetOrderModels(nestOrderId: string, exchSeg: string): OrderModel {
        if (Utility.OrderModelMap === undefined) {
            this.OrderModelMap = new Map();
        }
        if (this.OrderModelMap.get(nestOrderId) === undefined) {
            const om = new OrderModel();
            om.NestOrd = nestOrderId;
            om.Exseg = exchSeg;
            om.Exchange = Utility.segmentToExchange(exchSeg);
            this.OrderModelMap.set(om.NestOrd, om);
        }
        return this.OrderModelMap.get(nestOrderId);
    }

    static GetSpreadOrderModels(nestOrderId: string, exchSeg: string): SpreadOrderModel {
        if (Utility.SpreadOrderModelMap === undefined) {
            this.SpreadOrderModelMap = new Map();
        }
        if (this.SpreadOrderModelMap.get(nestOrderId) === undefined) {
            const om = new SpreadOrderModel();
            om.nestOrderNumber = nestOrderId;
            om.exchSeg = exchSeg;
            om.exchange = Utility.segmentToExchange(exchSeg);
            this.SpreadOrderModelMap.set(om.nestOrderNumber, om);
        }
        return this.SpreadOrderModelMap.get(nestOrderId);
    }


    static isOrderExists(nestOrderId) {
        if (Utility.OrderModelMap === undefined) {
            this.OrderModelMap = new Map();
        }
        return (this.OrderModelMap.get(nestOrderId) === undefined) ? false : true;
    }

    static getProductKey(productAlias: string) {
        const map = new Map();
        const productsStr = Preferences.getPreference('productAlias');
        const products = productsStr.split('||');
        products.forEach(element => {
            const values = element.split(':');
            map.set(values[1], values[0]);
        });
        let product = map.get(productAlias);
        if (!product) {
            product = productAlias;
        }
        return product;
    }

    static getDisplayStatus(Status) {
        if (Keys.key_map[Status] !== undefined) {
            return Keys.key_map[Status];
        } else {
            return Keys.INPROGRESS;
        }
    }

    static getChipLetterWidth(text) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.font = '0.75rem sans-serif';
        const width = ctx.measureText(text).width;
        return width + 16 + 2;
    }

    static getSHAEncryptedData(data) {
        if (environment.isencryptionneeded) {
            let encryptedData: any = CryptoJS.SHA256(data);
            for (let i = 0; i < 999; i++) {
                encryptedData = CryptoJS.SHA256(encryptedData);
            }
            return encryptedData.toString();
        }
        else {
            return data.toString();
        }
    }

    static logoutPopup(router: Router, translate, tradingRestService, dialog, currentPageName?, snackBar?) {
        if (Preferences.getPreference('LoggedIn') === 'true') {
            if (typeof app !== 'undefined')
                app.setValueToMobile('LOGOUTPOPUP', '1');
                Utility.candeactivate = true;
            Utility.isDialogOpened = true;
            if(environment.baseviewpath !== 'investor')
                Preferences.serverSync(tradingRestService);
            const dialogRef = dialog.open(MyAlertDialogComponent,
                { data: { heading: translate.instant('alert.leaving_alert'), message: translate.instant('header.logout_msg')}, disableClose: true });
            dialogRef.afterClosed().subscribe(result => {
                Utility.candeactivate = false;
                Utility.isDialogOpened = false;
                if (result === 'confirm') {
                    if (typeof app !== 'undefined')
                        app.setValueToMobile('LOGOUTPOPUP', '0');
                    tradingRestService.getUserService().Logout((res, code, message) => {
                        Preferences.setPreference('LoggedIn', 'false');
                        Preferences.setPreference('finallogin', 'false');
                        tradingRestService.marketDataFeeder.DisconnectWS();
                        if (environment.isQuickRest) {
                        //    tradingRestService.tradeFeeder.closeSocketConnection();
                        }
                        if (code === 200 || message.toLowerCase().includes('session expired')) {
                            Utility.isLogout = true;
                            this.ScripModelMap = new Map();
                            this.ChannelScripMap = new Map();
                            this.MarketPictureModelMap = new Map();
                            this.IndexModelMap = new Map();
                            this.ChannelIndexMap = new Map();
                            Preferences.setPreference('userSessionId', null);
                            if (Utility.orderDataSubscription)
                                Utility.orderDataSubscription.unsubscribe();
                                this.userId = undefined;
                                router.navigate(['/login'], { replaceUrl: true });
                        }
                    });
                }
                else {
                    if (typeof app !== 'undefined')
                        app.setValueToMobile('LOGOUTPOPUP', '0');
                    Preferences.setPreference('LoggedIn', 'false');
                    Preferences.setPreference('checkSession', 'false');
                    setTimeout(function () {
                      Preferences.setPreference('LoggedIn', 'true');
                    }, 20);
                    setTimeout(function () {
                      Preferences.setPreference('checkSession', 'true');
                    }, 500);
                }
            });
        }
        else{
            const loginUrl = '';
            Utility.isLogin = false;
            Preferences.setPreference('userLogIn', '0');
            Preferences.setPreference('checkSession', 'false');
            Preferences.setPreference('finallogin', 'false');
            Preferences.setPreference('LoggedIn', 'false');
            Utility.candeactivate = false;
            tradingRestService.marketDataFeeder.DisconnectWS();
            if (Utility.dialogRef){
            Utility.dialogRef.close();
            }
            router.navigate([loginUrl]);
            snackBar.open(translate.instant('all.session_expired'), '', { duration: 3000 }); 
        }
    }

    static showFingerprintDialog(translate : TranslateService, dialog : MatDialog, serverMockService : ServerMockService, webAuthnService : WebAuthnService)
    {
        if(environment.baseviewpath !== 'investor'){
            if (Utility.webAuthnAvailable && Utility.isFingerprintDeviceAvailable)
            {
                const dialogRef = dialog.open(MyAlertDialogComponent,
                    { data: { heading: translate.instant('alert.fingerprint_hed'), message: translate.instant('header.fingerprintMessage')}, disableClose: true });
        
                dialogRef.afterClosed().subscribe(result => {
                    var user: User = serverMockService.getUser(Preferences.getPreference('userid'));
                    if(user === undefined || user === null)
                        user = serverMockService.addUser({ userId: Preferences.getPreference('userid'), mpin: Utility.encryptUserMessage(Utility.decryptUserMessage(Preferences.getPreference('ht_key'))), credentials: [] });
                    if(result === 'confirm')
                    {
                        webAuthnService.webAuthnSignup(user)
                          .then((credential: PublicKeyCredential) => {
                            const valid = serverMockService.registerCredential(user, credential);
                            if(valid)
                            {
                                Preferences.setPreference(Utility.FINGERPRINT_STATUS, fingerprintDecision.Enabled);
                                GeneralSettingsComponent.fingerprintStatus('E');
                            }
                          }).catch((error) => {
                            console.log('credentials.create ERROR', error);
                          });
                    }
                    else
                    {
                        GeneralSettingsComponent.fingerprintStatus('D');
                        Preferences.setPreference(Utility.FINGERPRINT_STATUS, fingerprintDecision.Disabled);
                    }
                });
            }
            else
            {
                Preferences.setPreference(Utility.FINGERPRINT_STATUS, fingerprintDecision.NotSupport);
            }
        }
    }

    static encryptUserMessage(textValue)
    {
        var key = CryptoJS.enc.Utf8.parse(Utility.encryptionKey);
        var iv = CryptoJS.enc.Utf8.parse(Utility.encryptionKey);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(textValue.toString()), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        return encrypted.toString();
    }

    static decryptUserMessage(encryptValue)
    {
        var key = CryptoJS.enc.Utf8.parse(Utility.encryptionKey);
        var iv = CryptoJS.enc.Utf8.parse(Utility.encryptionKey);
        var decrypted = CryptoJS.AES.decrypt(encryptValue, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    public static segmentToExchange(ExchangeSeg): string {
        const data = {
            'ncx_fo': 'NCDEX',
            'nse_fo': 'NFO',
            'nse_cm': 'NSE',
            'mcx_fo': 'MCX',
            'cde_fo': 'CDS',
            'nse_mf': 'MFSS',
            'icx_fo': 'ICEX',
            'nmc_fo': 'NMCE',
            'dgx_fo': 'DGCX',
            'mcx_sx': 'MCXSX',
            'bse_fo': 'BFO',
            'nsel_sm': 'NSEL',
            'mcx_cm': 'MCXSXCM',
            'mcx_cmfo': 'MCXSXFO',
            'nse_dm': 'NDM',
            'bcs_fo': 'BCD',
            'bse_cm': 'BSE',
            'cse_cm': 'CSE',
            'bmv_cm': 'BMV',
            'ysx_cm': 'YSX',
            'bse_mf': 'BSEMF',
            'nse_ipo': 'NSE IPO',
            'bse_ipo': 'BSE IPO',
            'nse_nie': 'NIE',
            'bse_bix': 'BIX'

        };
        if (data[ExchangeSeg] != null) {
            return data[ExchangeSeg];
        } else {
            return ExchangeSeg;
        }
    }

    public static exchangeToSegment(exchange: string) {
        const data = {
            'NCDEX': 'ncx_fo',
            'NFO': 'nse_fo',
            'NSE': 'nse_cm',
            'MCX': 'mcx_fo',
            'CDS': 'cde_fo',
            'MFSS': 'nse_mf',
            'ICEX': 'icx_fo',
            'NMCE': 'nmc_fo',
            'DGCX': 'dgx_fo',
            'MCXSX': 'mcx_sx',
            'BFO': 'bse_fo',
            'NSEL': 'nsel_sm',
            'MCXSXCM': 'mcx_cm',
            'MCXSXFO': 'mcx_cmfo',
            'NDM': 'nse_dm',
            'BCD': 'bcs_fo',
            'BSE': 'bse_cm',
            'CSE': 'cse_cm',
            'BMV': 'bmv_cm',
            'YSX': 'ysx_cm',
            'BSEMF': 'bse_mf',
            'NIE': 'nse_nie',
            'BIX': 'bse_bix'
        };
        if (data[exchange] != null) {
            return data[exchange];
        } else {
            return exchange;
        }
    }

    public static displayOrderSource(ordersource) {
        switch (ordersource) {
            case 'TWS':
                return 'Nest Trader';
            case 'NEST_REST_MOB':
                return 'MOBILE';
            case 'NEST_REST_WEB':
                return 'WEB';
            default:
                return ordersource;
        }
    }

    static prepareParameters(data, publicKeyStr) {
        Utility.print('Encr:<' + data + '>');
        let encrypted = '';
        let buffer = '';
        if (environment.isencryptionneeded) {
            try {
                encrypted = this.encryptBlock(data, publicKeyStr);
                if (Preferences.getPreference('tomcatcount')) {
                    buffer = 'jData=' + encrypted + '&jKey=' +
                        CryptoJS.SHA256(publicKeyStr) + '&jsessionid=' + 'Mobile' + '.' + Preferences.getPreference('tomcatcount');
                } else {
                    buffer = 'jData=' + encrypted + '&jKey=' + CryptoJS.SHA256(publicKeyStr);
                }
            } catch (e) {
                Utility.print(e);
            }
        }
        else {
            buffer = 'jData=' + data;
        }

        return buffer;
    }

    static encryptBlock(src: string, publicKey: string) {
        Utility.print('Encr:<' + src + '>');
        const crypt = new JSEncrypt();
        crypt.setPublicKey(publicKey);
        const numberOfBytes = Keys.RSA_KEY_SIZE / 8 - 11;
        let start = 0;
        let end = numberOfBytes;
        let encryptedString = '';
        if (numberOfBytes > src.length) {
            end = src.length;
        }
        do {
            const bytesstr = src.substring(start, end);
            encryptedString += crypt.encrypt(bytesstr);
            encryptedString += '\n';
            start = end;
            end = end + numberOfBytes;
            if (end > src.length) {
                end = src.length;
            }
        } while (end < src.length);
        if ((end - start) > 0) {
            const bytesstr = src.substring(start, end);
            encryptedString += crypt.encrypt(bytesstr);
            encryptedString += '\n';
        }
        return btoa(encryptedString);
    }

    static decrypt(privateKey, src) {
        let plainText = '';
        const crypt = new JSEncrypt();
        crypt.setPublicKey(privateKey);
        const encryptedText = atob(src);
        const encryptArray = encryptedText.split('\n');
        for (let i = 0; i < encryptArray.length; i = i + 1) {
            const block = encryptArray[i];
            if (block !== '') {
                plainText += crypt.decrypt(block);
            }
        }
        return plainText;
    }

    static print(log) {
        if(!environment.production) {
            console.log(log);
        }
    }

    static openSpreadPlaceOrder(router: Router, omtkn, exseg, buttontype, dialog, clearHistory = false) {
        const data_obj = { 'omtkn': omtkn, 'exseg': exseg, 'action': buttontype };
        if (this.IS_MOBILE) {
            router.navigate(['spreadplaceorder'], { queryParams: data_obj, replaceUrl: clearHistory  });
        } else {
            this.openPage(router, 'placeorder', 'spreadplaceorder', SpreadPlaceorderComponent, dialog, 'popup', data_obj, '500px', '1250px');
        }

    }

    static openPlaceOrder(router: Router, omtkn, exseg, buttontype, dialog, clearHistory = false) {
        const data_obj = { 'omtkn': omtkn, 'exseg': exseg, 'action': buttontype };
        if (this.IS_MOBILE) {
            router.navigate(['placeorder'], { queryParams: data_obj, replaceUrl: clearHistory  });
        } else {
            this.openPage(router, 'placeorder', 'placeorder', PlaceorderComponent, dialog, 'popup', data_obj, '500px', (environment.baseviewpath === 'investor')?'600px':'1250px');
        }

    }
    static openStockDetail(router: Router, omtkn, exseg, clearHistory = false) {
        const data_obj = { 'omtkn': omtkn, 'exseg': exseg };
        router.navigate(['stockdetail'], { queryParams: data_obj, replaceUrl: clearHistory  });
    }

    static openCreateTrigger(router: Router, omtkn, exseg, dialog, clearHistory = false) {
        const data_obj = { 'omtkn': omtkn, 'exseg': exseg };
        if (this.IS_MOBILE) {
            router.navigate(['createtrigger'], { queryParams: data_obj, replaceUrl: clearHistory  });
        } else {
            this.openPage(router, 'createTrigger', 'createTrigger', CreateTriggerComponent, dialog, 'popup', data_obj, '500px','850px');
        }
    }

    static GetTradeModels(nestOrderId: string, exchSeg: string, fillId: string): TradeModel {
        if (Utility.TradeModelMap === undefined) {
            this.TradeModelMap = new Map();
        }
        if (this.TradeModelMap.get(nestOrderId + '_' + fillId) === undefined) {
            const tradeModel = new TradeModel();
            tradeModel.NestOrd = nestOrderId;
            tradeModel.FillId = fillId;
            tradeModel.Exseg = exchSeg;
            tradeModel.Exchange = Utility.segmentToExchange(exchSeg);
            this.TradeModelMap.set(tradeModel.NestOrd + '_' + tradeModel.FillId, tradeModel);
        }
        return this.TradeModelMap.get(nestOrderId + '_' + fillId);
    }


    static isTradeExists(nestOrderId, fillId) {
        if (Utility.TradeModelMap === undefined) {
            this.TradeModelMap = new Map();
        }
        return this.TradeModelMap.get(nestOrderId + '_' + fillId);
    }
    static setNavigationData(data: any) {
        this.NavigationData = data;
    }
    static getNavigationData() {
        return this.NavigationData;
    }

    static fillPreTradeValues() {
        Preferences.setItem("preTradeValues", {
            "stockValue": 5,
            "indexValue": 3,
            "optionValueLow": 20,
            "optionValueHigh": 40,
            "currValueLow": 0.05,
            "currValueMedium": 0.1,
            "currValueHigh": 20,
            "eurValueLow": 0.002,
            "eurValueMedium": 0.004,
            "eurValueHigh": 20,
            "totalBuyAmt": 1000000,
        });
    }

    static getSegment(exchange) {
        switch(exchange) {
            case 'nse_cm' || 'bse_cm':
                return 'EQT';
            case 'nse_fo' || 'bse_fo':
                return 'DER';
            case 'cde_fo' || 'bcs_fo':
                return 'FX';
            default:
                return '';
        }
    }

    static isCompInCurrentWorkspace(cmpName: any, cmpWorkspaceIndex: any) {
        const userID = Utility.getUserId();
        let cmplist = [];

        const wlselected = parseInt(((Preferences.getItem(userID + '_wl-select', ReturnType.Undefined) !== undefined && 
        Preferences.getItem(userID + '_wl-select', ReturnType.Undefined) !== null) ? Preferences.getItem(userID + '_wl-select') : '0'), 10);

        const workspaceName =  Preferences.getItem(userID + '_workspace-list', ReturnType.Undefined);

        if (wlselected === cmpWorkspaceIndex) {
            if (wlselected !== 0) {
                cmplist = Preferences.getItem(userID + '_workspace-componentslist-' + workspaceName[wlselected], ReturnType.Array);
            } else {
                cmplist = ['Watchlist', 'Order Book', 'Trade Book', 'Position Book', 'Holding Book'];
            }
        }
        return cmplist.includes(cmpName);
    }

    static getNumber(str) {
        if (str)
            return Number(str.toString().replace(/\,/g, ''));
        else
            return str;
    }

    static formatNumberToDecimalPrecision(value, dp) {
        if (value) {
            return Number((value.toString().replace(/\,/g, ''))).toFixed(dp);
        }
        else {
            return value;
        }
    }

    static getMaskedValue(value, size = 3) {
        if (value && value !== '-' && value.length >= 3) {
            const maskedValue = new Array(value.length - (size - 1)).join('X') + value.substr(value.length - size, size);
            return maskedValue;
        }
        else
            return value;
    }

    public static getTickSize(tk, mul, dp) {
        if (tk && mul && dp)
            return (tk / (mul * Math.pow(10, dp)));
        else
            return 0;
    }

    public static validateDiscQuantity(qty, discQty, exchange) {
        let message = '';
        const regex = new RegExp('^[0-9]{0,8}$|^[0-9]{1,' + 2 + '}$');
        if (!discQty || Utility.getNumber(discQty) === NaN || !regex.test(discQty)) {
            message = 'placeorder.enterdisc';
        }
        else if (Utility.getNumber(discQty) > 0) {
            if (Utility.getNumber(discQty) > Utility.getNumber(qty)) {
                message = 'placeorder.lessqty';
            }
            else if (exchange === 'NSE' || exchange === 'BSE' || exchange === 'CDS' || exchange === 'BCD' || exchange === 'MCXSX' || exchange === 'NCDEX') {
                if (Utility.getNumber(discQty) < Utility.getNumber(qty) / 10) {
                    message = 'placeorder.min_disc_ten';
                }
            }
            else if (exchange === 'MCXSXCM') {
                if (Utility.getNumber(discQty) < Utility.getNumber(qty) / 5) {
                    message ='placeorder.min_disc_twenty';
                }
            }
            else if (exchange === 'MCX') {
                if (Utility.getNumber(discQty) < Utility.getNumber(qty) / 4) {
                    message = 'placeorder.min_disc';
                }
            }
        }
        return message;
    }
    public static GetAllProductForExchange(exchange) {
        Utility.exchangeToProductMap = new Map();
        const exchangeDetail = JSON.parse(Preferences.getPreference('userParameter'))['exchDeatil'];
        const exchangeType = Utility.GetParentSegment(exchange);
        //const exchTypeArr = exchangeDetail[exchangeType];
        return Utility.FilterProducts(exchange);
    }

    public static GetParentSegment(exchange) {
        switch (exchange) {
            case 'NSE': case 'BSE':
                return 'EQUITY';
            case 'NFO': case 'BFO':
                return 'DERIVATIVE';
            case 'BCD': case 'CDS':
                return 'CURRENCY';
            case 'MCX': case 'NCDEX':
                return 'COMMODITY';
        }
    }

    public static FilterProducts(exchange) {
        let userdata = JSON.parse(Preferences.getPreference('userParameter'));
        let array = [];
        let exchDeatilObj;
        if (environment.isQuickRest) {
          exchDeatilObj = userdata.exDtl;
        }
        else {
          exchDeatilObj = userdata.exchDeatil;
        }
        Object.keys(exchDeatilObj).forEach(el => {
          const arr = exchDeatilObj[el];
          let products;
          for (let i = 0; i < arr.length; i++) {
            if (environment.isQuickRest) {
              if (arr[i].exch === exchange) {
                products = arr[i].prod;
                for (let j = 0; j < userdata['prodArr'].length; j++) {
                  const ele = userdata['prodArr'][j];
                  if (products.indexOf(ele) !== -1) {
                      array.push(ele);
                  }
                }
                break;
              }
            }
            else {
              if (arr[i].exch === exchange) {
                products = arr[i].product;
                for (let j = 0; j < userdata['prarr'].length; j++) {
                  const ele = userdata['prarr'][j];
                  if (products.indexOf(ele) !== -1) {
                      array.push(ele);
                  }
                }
                break;
              }
            }
          }
        });
        
        return array;
    }

    public static GetProductArray(exchange, array) {
        switch (exchange) {
            case 'NSE': case 'NFO': case 'BCD':
                return array[0]['product'];
            case 'BSE': case 'BFO': case 'CDS':
                return array[1]['product'];
        }
    }

    static ChangeOrderOfExchange() {
        const list = Utility.getEnabledExchanges();
        const exchList = [];
        if (list !== undefined) {
            if (list.indexOf('NSE') > -1)
                exchList.push('NSE');
            if (list.indexOf('BSE') > -1)
                exchList.push('BSE');
            if (list.indexOf('MCX') > -1)
                exchList.push('MCX');
            if (list.indexOf('NCDEX') > -1)
                exchList.push('NCDEX');
            if (list.indexOf('NFO') > -1)
                exchList.push('NFO');
            if (list.indexOf('CDS') > -1)
                exchList.push('CDS');
        }
        return exchList;
    }

    static getEnabledExchanges() {
        const exchanges = [];
        const userExchEnabled = Preferences.getPreference('userExchEnabled');
        if (userExchEnabled) {
            const exchangeSegs = userExchEnabled.split(',');
            exchangeSegs.forEach(element => {
                if (element) {
                    const exchange = Utility.segmentToExchange(element);
                    exchanges.push(exchange);
                }
            });
        }
        return exchanges;
    }

    static groupByObj(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    static toDateTime(secs) {
        const t = new Date(1980, 0, 1);
        t.setSeconds(secs);
        return t;
    }

    static getScripExchObj(obj) {
        let scripExch = '';
        obj.forEach(element => {
            scripExch += element.exseg + '|' + element.omtkn + ',';
        });
        return scripExch;
    }

    static setDevice(ismob) {
        if (environment.baseviewpath === 'fabits'){
           this.IS_MOBILE = true;
        }
        else{
            this.IS_MOBILE = ismob;
        }
        this.IS_MOBILE_SOURCE = ismob;
    }
    static getDevice() {
        return this.IS_MOBILE;
    }

    static setTabDevice(istab) {
            this.IS_TABLET = istab;
    }
    static getTabDevice() {
        return this.IS_TABLET;
    } 

    static clearSearchData() {
        Preferences.setItem('tsym', '');
        Preferences.setItem('exg', '');
        Preferences.setItem('token', '');
    }

    static printlog(message, level: string) {
        const mode = Utility.CONSOLEMODE;
        if (Utility.LOGGING) {
            if (mode === 'release') {
                if (level === 'release')
                    console.log(message);
            }
            else if (mode === 'debug') {
                if (level === 'release' || level === 'debug')
                    console.log(message);
            }
        }
    }

    static addRecentlySearchedScrips(token, exseg, tsym, cname) {
        if (cname === null || cname === undefined) {
            cname = '-';
        }
        const obj = { 'token': token, 'exseg': exseg, 'tsym': tsym, 'cname': cname };
        Utility.recentlySearchedScrips = Preferences.getItem('recentlySearchedScrips', ReturnType.Array);
        Utility.recentlySearchedScrips = Utility.recentlySearchedScrips.filter(ele => !(ele.token === token && ele.exseg === exseg && ele.tsym === tsym && ele.cname === cname));
        Utility.recentlySearchedScrips.push(obj);
        Preferences.setItem('recentlySearchedScrips', Utility.recentlySearchedScrips);
    }

    static addRecentlyViewScrips(keyword, tsym) {
        keyword = keyword.trim();
        tsym = tsym.trim();

        if (keyword === null || keyword === undefined || tsym === null || tsym === undefined) {
            return;
        }
        const recentViewScrips = Preferences.getItem('recentlyViewScrips', ReturnType.Array);

        if (recentViewScrips.length === 0) {
            recentViewScrips.push({'Symbol': tsym, 'keyword': keyword});
        }
        else {
            for (let i = 0; i < recentViewScrips.length; i++) {
                if (recentViewScrips[i].Symbol.includes(keyword.toUpperCase())) {
                    recentViewScrips[i].keyword = keyword;
                    recentViewScrips[i].Symbol = tsym;
                    break;
                } else if (i === recentViewScrips.length - 1) {
                    recentViewScrips.splice(0, 0, {'Symbol': tsym, 'keyword': keyword});
                    if (recentViewScrips.length > 5)
                        recentViewScrips.splice(recentViewScrips.length - 1, 1);
                    break;
                }
            }
        }
        Preferences.setItem('recentlyViewScrips', recentViewScrips);
    }

    static GetPositionModels(token: string, exchSeg: string, pcode: string): PositionModel {
        if (Utility.positionModelMap === undefined) {
            this.positionModelMap = new Map();
        }
        if (!this.positionModelMap.get(token + '_' + exchSeg + '_' + pcode)) {
            const positionModel = new PositionModel();

            this.positionModelMap.set(token + '_' + exchSeg + '_' + pcode, positionModel);
        }
        return this.positionModelMap.get(token + '_' + exchSeg + '_' + pcode);
    }

    static isPositionExists(token: string, exchSeg: string, pcode: string) {
        if (Utility.positionModelMap === undefined) {
            this.positionModelMap = new Map();
        }
        return this.positionModelMap.get(token + '_' + exchSeg + '_' + pcode);
    }

    static getMD5EncryptedData(data) {
        return CryptoJS.MD5(data).toString();
    }
    static dateFormatter(str) {
        const date = new Date(str.split('/')[1]+"/"+str.split('/')[0]+"/"+str.split('/')[2]);
        const dateTime = date.toString().slice(16,18);
        const t = Utility.getNumber(dateTime) > 12 ? (Utility.getNumber(dateTime) - 12): (dateTime);
        return date.toString().slice(4,10)+", "+date.toString().slice(11, 15)+" "+ t + date.toString().slice(18, 24)+(date.getHours() > 11 ? " PM" : " AM");
    }

    static getExchangeTypesForSearch() {
        const arr = ['All'];
        const allowedExchanges = Utility.getTradingExchanges();
        if (allowedExchanges.includes('NSE') || allowedExchanges.includes('BSE'))
            arr.push('Cash');
        if (allowedExchanges.includes('CDS') || allowedExchanges.includes('BCD'))
            arr.push('Currency');
        if (allowedExchanges.includes('MCX') || allowedExchanges.includes('NCDEX'))
            arr.push('Commodity');
        if (allowedExchanges.includes('NFO') || allowedExchanges.includes('BFO'))
            arr.push('F&O');
        return arr;
    }

    static getSource() {
        return (Utility.IS_MOBILE_SOURCE ? 'Mobile' : 'Web');
    }

    static clearSession() {
        const arr = ['userLogIn', 'checkSession', 'finallogin',
        'LoggedIn', 'userSessionId', 'publicKey4',
        'userKey', 'accountId', 'userParameter',
        'productAlias', 'brkName', 'userExchEnabled', 'tomcatcount',
        'decryptionKey', 'publicKey3', 'accessToken', 'publicKey1',
        'LoginMode', 'dragingElement'];
        for (let i = 0; i < arr.length; i++){
            Preferences.removePreference(arr[i]);
        }
    }
     static getSymDes(exdate, company_name, precisionValue?, optype?) {
        // precisionValue = stk value
        // optype = XX/ CE/ PE
        let symdes = '';
        if (exdate != null && exdate !== '' && exdate !== '--' && exdate !== undefined && exdate !== 'NA') {
            const t = new Date(1980, 0, 1);
            t.setSeconds(exdate);            
            symdes = moment(t).format('DDMMMYY').toUpperCase();
            if (optype !== 'XX')
                symdes = symdes + ' ' + precisionValue + ' ' + optype;
            else
                symdes = symdes + ' FUT';
        } else
            symdes = (company_name === undefined) ? '-' : company_name;
        return symdes;
    }
    
    static  edisAuthorizationCheck(productType, hldqty, transType, scripToken, exseg, usedqty){
        if (productType !== 'CNC' || transType === 'B'){
            return true;
        }
        else {
            if( hldqty > 0) {
                return true;
            } else {
                const edisresp = JSON.parse(Preferences.getPreference(Utility.userId + '_edisresp'));
                const currentDate = moment().format('DDMMYYYY'); 
                let map = new Map(JSON.parse(Preferences.getPreference(Utility.userId + '_authorizedScripMap')));
                if (edisresp !== null && currentDate === edisresp['dt'] && edisresp['status'] === 'true' && (hldqty-usedqty) <= map.get(scripToken + '_' + exseg)){
                    return true;
                }
                return false;
            }
        }        
    }

    static edisAuthorize(holdingData, hostName, tradingRestService) {
        let user = JSON.parse(Preferences.getPreference('userParameter'));
        const edisTransArray = [];
        const authorizedScripMap = new Map();
        const boId = user['dpId'];
      //  const dpId = user['dpId'];
        const token = Preferences.getPreference('userSessionId');
        const cmId = environment.cmId;
        const tmId = environment.tmId;
        const dpId = environment.dpId;
        const exId = '12';
        let i=0;
        let EdisTidArr=[];
        const d = new Date();
          let time = d.getTime();

        holdingData.forEach(arrData => {
            let a = i++;
            if(arrData['NonPOAQty']!=0){
                const jsonObj = {'TxnReqId' : time+''+a, 'ISIN': arrData['isin'], 'Quantity': arrData['NonPOAQty'], 'exch':arrData.scripModel.ExchangeName, 'tkn':arrData.scripModel.Token};
                const jd = {'exch':arrData.scripModel.ExchangeName, 'tkn':arrData.scripModel.Token, 'qty':arrData['NonPOAQty'],'isin':arrData['isin']};
                EdisTidArr.push(jd);
                edisTransArray.push(jsonObj);
                authorizedScripMap.set(arrData.scripModel.Token + '_' + arrData.scripModel.ExchangeSegment, arrData['NonPOAQty']);
            }
        });
        Preferences.setPreference(Utility.userId + '_authorizedScripMap', JSON.stringify(Array.from(authorizedScripMap.entries())));        
        const winURL= 'https://' + hostName + '/assets/edis/index.php';
        //const winURL= 'https://test.hypertrade.in/assets/edis/index.php';
    
      //  Preferences.setItem('hld',JSON.stringify(edisTransArray));
       // Preferences.serverSync(tradingRestService);
        tradingRestService.getUserService().getEdisTransectionID((data, code, status) => {
            if(data['code']==200){
                let earr = data['data']['resData'];
                let a =[];
                for(let i=0;i<earr.length;i++){
                    let obj = {};
                    obj["TxnReqId"]=earr[i]["TxnReqId"];
                    obj["ISIN"] = earr[i]["isin"];
                    obj["Quantity"] = earr[i]["qty"];
                    a.push(obj);
                }
                const str = {'boid':boId, 'dpid': dpId,'cmid':cmId,'tmid':tmId,'exid':exId, 'token':token, 'user': this.userId,'bid':environment.brokerId, 'tsessionid': data['data']['txn_id'] };       
                const windowoption='resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
                const form = document.createElement('form');
                form.setAttribute('name', 'frmDIS' );
                form.setAttribute('method', 'post');
                form.setAttribute('action', winURL);
                form.setAttribute('target','EDIS');        
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'dtl';
                input.value = JSON.stringify(str);
                form.appendChild(input);
                const input1 = document.createElement('input');
                input1.type = 'hidden';
                input1.name = 'hld';
                input1.value = JSON.stringify(a);
                form.appendChild(input1);
                document.body.appendChild(form);
                window.open(winURL,'EDIS', windowoption);
                form.target = 'EDIS';
                form.submit();
                document.body.removeChild(form);    
            }
        }, {'jData':EdisTidArr});
    }

    static openEmandate(obj, hostName){

        const winURL= 'https://' + hostName + '/assets/em/index.php';
        //const winURL= 'https://demo.hypertrade.in/assets/em/index.php';

        const windowoption='resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
        const form = document.createElement('form');
        form.setAttribute('name', 'frmMandate' );
        form.setAttribute('method', 'post');
        form.setAttribute('action', winURL);
        form.setAttribute('target','EMANDATE');        
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'dtl';
        input.value = JSON.stringify(obj);
        form.appendChild(input);
        document.body.appendChild(form);
        window.open(winURL,'EMANDATE', windowoption);
        form.target = 'EMANDATE';
        form.submit();
        document.body.removeChild(form); 
    }

    

    onUpdateOrderBookData(obj) {
        Utility.orderBookData.next(obj);
    }

    onUpdateTradeBookData(obj) {
        Utility.tradeBookData.next(obj);
    }

    onUpdatePositionBookData(obj) {
        Utility.positionBookData.next(obj);
    }
    static canCancel(orderModel:OrderModel) {
        let retval = false;
        if (orderModel.Status.toLowerCase() === 'open' || orderModel.Status.toLowerCase() === 'trigger pending'
          || orderModel.Status.toLowerCase() === 'after market order req received'
          || orderModel.Status.toLowerCase() === 'modify after market order req received') {
          if (orderModel.ProductCode !== 'CO') {
            if (orderModel.ProductCode !== 'BO' || orderModel.SyomOrderId === '') {
              retval = true;
            }
          }
        }
        return retval;
    }
}
