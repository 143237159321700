import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Preferences } from '../Preferences';
import { Url } from '../url';
import { Utility } from '../utility';
import { IAuthenticationService } from './iauthentication.service';

@Injectable()
export class AuthenticationService implements IAuthenticationService {
    apiService: ApiService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    GetAccessToken(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let data = '';
        const uid = Preferences.getPreference('userid');
        const accountId = Preferences.getPreference('accountId');
        const params = { userid: uid, accountid: accountId };
        this.apiService.ptpluspost(Url.GetAccessToken, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res['code'] === Keys.RES_CODE_SUCCESS) {
                if (Object.keys(res.data).length > 0)
                    data = res.data;
            }
            else {
                message = res.message;
                code = Keys.RES_CODE_FAILURE;
            }
            callback(data, code, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }
}
