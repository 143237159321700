
<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row list-view border-b ts-4" (swipedown)="onSwipeDown()">
    <div class="col-4 primetext"><span class="subcolor ts-1" translate>derivative.strike_price</span><br/>{{data.strikeprice | pricepipe:data.putscrip.DecimalPrecision}}</div>
    <div class="col-4 primetext text-center"><span class="subcolor ts-1" translate>derivative.future</span><br/>{{data.futurescrip === undefined ? data.spotLTP : data.futurescrip.LastTradePrice | pricepipe:data.callscrip.DecimalPrecision}}</div>
    <div class="col-4 ts-2 text-right"><span class="subcolor ts-1" translate>optioncalc.expiry</span><br/>{{expiryDate | datepipe: 'date'}}</div>
</div>

<div class="sheetscrollarea optionchain-area">
    <div class="row">
        <div class="col-12">
            <table class="table-bottomsheet">
                <tr class="withbutton">
                    <td class="border-r w50">
                        <p class="d-inline-block primetext ts-3" translate>derivative.call</p>
                        <button mat-icon-button (click) = "addWLdata(data.callscrip)" [matMenuTriggerFor]="menu" class="float-right primetext"><i class="icon-plus"></i></button>
                            <mat-menu #menu="matMenu">
                                <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                <ng-container *ngFor = "let item of WlArr">
                                    <button mat-menu-item (click) = "addStock(item.wlName, data.callscrip)" [disabled] = "item.isDisable">
                                       {{item.wlName}}
                                    </button>
                                </ng-container>
                            </mat-menu>
                    </td>
                    <td>
                        <p class="d-inline-block primetext ts-3" translate>derivative.put</p>
                        <button mat-icon-button (click) = "addWLdata(data.putscrip)" [matMenuTriggerFor]="scripMenu" class="float-right primetext"><i class="icon-plus"></i></button>
                            <mat-menu #scripMenu="matMenu">
                                <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                <ng-container *ngFor = "let item of WlArr">
                                    <button mat-menu-item (click) = "addStock(item.wlName, data.putscrip)" [disabled] = "item.isDisable">
                                        {{item.wlName}}
                                    </button>
                                </ng-container>
                            </mat-menu>
                    </td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>stock_detail.ltp</span>{{data.callscrip.LastTradePrice | pricepipe:data.callscrip.DecimalPrecision}}</td>
                    <td><span translate>stock_detail.ltp</span>{{data.putscrip.LastTradePrice | pricepipe:data.putscrip.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>IV</span>{{(data.calliv == '-Infinity') ? ('--') : (data.calliv)}}</td>
                    <td><span translate>IV</span>{{(data.putiv == '-Infinity') ? ('--') : (data.putiv)}}</td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>OI</span>{{data.callscrip.Openinterest | numberpipe}}</td>
                    <td><span translate>OI</span>{{data.putscrip.Openinterest | numberpipe}}</td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>{{'stock_detail.change' | translate}}(%)</span>{{data.callscrip.Change | numberpipe}} ({{data.callscrip.PercentageChange | percentagepipe}})</td>
                    <td><span translate>{{'stock_detail.change' | translate}}(%)</span>{{data.putscrip.Change | numberpipe}} ({{data.putscrip.PercentageChange | percentagepipe}})</td>
                <tr>
                
                <tr>
                    <td class="border-r"><span>{{'stock_detail.bid' | translate }} / {{'portfolio.qty' | translate }}</span> {{data.callscrip.BestBuyPrice | pricepipe:data.callscrip.DecimalPrecision}} / {{data.callscrip.BestBuySize | numberpipe}}</td>
                    <td><span>{{'stock_detail.bid' | translate }} / {{'portfolio.qty' | translate }}</span> {{data.putscrip.BestBuyPrice | pricepipe:data.putscrip.DecimalPrecision}} / {{data.putscrip.BestBuySize | numberpipe}}</td>
                <tr>
                <tr>
                    <td class="border-r"><span>{{'stock_detail.ask' | translate }} / {{'portfolio.qty' | translate }}</span>  {{data.callscrip.BestSellPrice | pricepipe:data.callscrip.DecimalPrecision}} / {{data.callscrip.BestSellSize | numberpipe}}</td>
                    <td><span>{{'stock_detail.ask' | translate }} / {{'portfolio.qty' | translate }}</span>  {{data.putscrip.BestSellPrice | pricepipe:data.putscrip.DecimalPrecision}} / {{data.putscrip.BestSellSize | numberpipe}}</td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>optioncalc.delta</span> {{data.calldelta}}</td>
                    <td><span translate>optioncalc.delta</span> {{data.putdelta}}</td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>optioncalc.theta</span> {{data.calltheta}} </td>
                    <td><span translate>optioncalc.theta</span> {{data.puttheta}} </td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>optioncalc.vega</span> {{data.callvega}}</td>
                    <td><span translate>optioncalc.vega</span> {{data.putvega}}</td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>optioncalc.gamma</span>{{data.callgamma}}</td>
                    <td><span translate>optioncalc.gamma</span>{{data.putgamma}}</td>
                </tr>
                <tr>
                    <td class="border-r"><span translate>stock_detail.volume</span>{{data.callscrip.TradeVolume | numberpipe}}</td>
                    <td><span translate>stock_detail.volume</span>{{data.putscrip.TradeVolume | numberpipe}}</td>
                </tr>
                <tr class="border-tb plainbg">
                    <td colspan="2">1 {{'portfolio.lot' | translate }}={{data.callscrip.BodLotQuantity | numberpipe}} {{'portfolio.qty' | translate }}</td>
                </tr>
            </table>
        </div>
        
    </div>
    
</div>



<div class="row border-t">
    <div class="bottomsheet-buttons col-6 py-2 border-r">
        <button (click)='onCount("S", data, "call")' class="btn-negative" mat-icon-button>-</button>
        <span class="op-qty">{{data.callqtycount}}</span>
        <button (click)='onCount("B", data, "call")' class="btn-positive" mat-icon-button>+</button>
    </div>
    <div class="bottomsheet-buttons col-6 py-2">
        <button (click)='onCount("S", data, "put")' class="btn-negative" mat-icon-button>-</button>
        <span class="op-qty">{{data.putqtycount}}</span>
        <button (click)='onCount("B", data, "put")' class="btn-positive" mat-icon-button>+</button>
    </div>
</div>