<app-header *ngIf = "isMobile" goback="1" [title]="'alert.alert' | translate" [isLoading]="isLoading"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'alert.alert' | translate" ></app-titlebar>
<mat-dialog-content class="narrowpadding placeorderfield">
  <div class="row" [class.data-container]="isMobile">
    <div class="col-12" [class.p-53] = "!isMobile">
      <div class="row myrow2">
      <div class="col-12 sameer">
          <mat-radio-group (change)="onTypeSelection($event)">
            <mat-radio-button class="mr-1" value="Stock" [checked] = "type == 'Stock'" [disabled] = "isEdit"><span translate>stock_detail.stock</span></mat-radio-button>
            <mat-radio-button class="mr-1" value="Index" [checked] = "type == 'Index'" [disabled] = "isEdit"><span translate>dashboard.index</span></mat-radio-button>
            <mat-radio-button class="mr-1" value="Order" [checked] = "type == 'Order'" [disabled] = "isEdit"><span translate>stock_detail.order</span> </mat-radio-button>
            </mat-radio-group>
        </div>
      </div>  
        <div class="row myrow2" *ngIf = "type == 'Stock' && !isEdit">
          <div class="col-12" (click) = "openSearch()">
            <div class="searchdummy"> {{SymbolName}}</div>
          </div>
        </div>
        <div class="row myrow2" *ngIf = "type == 'Stock' && isEdit">
          <div class="col-4" translate>search.search</div>
          <div class="col-8">{{SymbolName}}</div>
        </div>
        <div class="row myrow2" *ngIf = "type == 'Order' && isEdit">
          <div class="col-4" translate>alert.exchange</div>
          <div class="col-8" >{{Exchange}}</div>
        </div>
        <div class="row myrow2" *ngIf = "type == 'Order' && isEdit">
          <div class="col-4" translate>stock_detail.order</div>
          <div class="col-8">{{SymbolName}} | {{Comment}}</div>
        </div>
        <div class="row myrow2" *ngIf = "type == 'Order' && !isEdit">
            <div class="col-12"> 
              <mat-form-field class="w-100">
                <mat-label translate>alert.exchange</mat-label>
                <mat-select (selectionChange)="onExchangeSelection($event)" [(value)]="Source">
                  <mat-option *ngFor="let data of exarr" [value]="data">
                    {{data}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <div class="row myrow2" *ngIf = "type == 'Order' && !isEdit">
            <div class="col-12"> 
              <mat-form-field class="w-100">
                <mat-label translate>stock_detail.order</mat-label>
                <mat-select (selectionChange)="onOrderSelection($event)" [(value)]="Order">
                  <mat-option *ngFor="let data of orderarr" [value]="data">
                  {{data.Trsym}} | {{data.Nstordno}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <div class="row myrow2" *ngIf = "type == 'Order'">
            <div class="col-12"> 
              <mat-form-field class="w-100">
                <mat-label translate>alert.alert_if</mat-label>
                <mat-select (selectionChange)="onAlertSelection($event)" [(value)]="Target">
                  <mat-option *ngFor="let data of alertarr" [value]="data">
                    {{data}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <div class="row myrow2" *ngIf = "type == 'Stock'">
            <div class="col-12"> 
              <mat-form-field class="w-100">
                <mat-label translate>alert.based_on</mat-label>
                <mat-select (selectionChange)="onSourceSelection($event)" [disabled]="basedOnDisable" [(value)]="Source">
                  <mat-option *ngFor="let data of basedarr" [value]="data.value">
                    {{data.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <div class="row myrow2" *ngIf = "type == 'Index' && !isEdit">
            <div class="col-12"> 
              <mat-form-field  class="w-100">
                <mat-label translate>dashboard.index</mat-label>
                <mat-select (selectionChange)="onIndexSelection($event)" [(value)]="Index">
                  <mat-option *ngFor="let data of indexarr" [value]="data">
                    {{data.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <div class="row myrow2"  *ngIf = "type == 'Index' && isEdit">
            <div class="col-4 mb-3" translate>dashboard.index</div>
            <div class="col-8 mb-3">{{Symbol}}</div>
        </div>
        <div class="row myrow2" *ngIf = "type != 'Order'">
          <div class="col-12">
              <div class="mb-1"><small class="textcolor5" translate>alert.alert_if</small></div>
              <div class="sameer">
                <mat-radio-group (change)="onDirSelection($event)">
                  <mat-radio-button class="" value="l=" [checked]="Direction == 'l='"><=</mat-radio-button>
                  <mat-radio-button class="" value="g=" [checked]="Direction == 'g='">>=</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
        </div>
        <div class="row myrow2" *ngIf = "type != 'Order'">
            <div class="col-12">
              <mat-form-field class="w-100">
                <mat-label translate>alert.value</mat-label>
                <input matInput type="text" placeholder="00.00"   oncopy="return false" onpaste="return false" inputmode="decimal"  autocomplete="off"  appNumberDir dp="2" pricesize=7 (keydown)="validatePrice($event, Value)"
              [(ngModel)]="Value">
              </mat-form-field>
            </div>
        </div>
        <div class="row myrow2">
            <div class="col-12"> 
              <mat-form-field class="w-100">
                <mat-label translate>alert.send_on</mat-label>
                <mat-select (selectionChange)="onSendSelection($event)" [(value)]="SendOn">
                  <mat-option *ngFor="let data of sendonArr" [value]="data.value">
                    {{data.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions d-block">
	<div class="row">
		<div *ngIf="!isMobile" class="col-12 text-right">
			<button class="m-2 custom-btn" mat-flat-button (click) = "cancel()">{{isEdit ? ('alert.delete' | translate) : ('alert.cancel' | translate)}}</button>
			<button class="m-2 custom-btn btn-primary" mat-flat-button (click) = "setAlert()">{{isEdit ? ('alert.save' | translate) : ('alert.setalert' | translate)}}</button>
		</div>
		<div *ngIf="isMobile" class="col-6 p-action-l">
			<button class="custom-btn w-100" mat-flat-button (click) = "cancel()">{{isEdit ? ('alert.delete' | translate) : ('alert.cancel' | translate)}}</button>
    </div>
		<div *ngIf="isMobile" class="col-6 p-action-r">
			<button class="custom-btn btn-primary w-100" mat-flat-button (click) = "setAlert()">{{isEdit ? ('alert.save' | translate) : ('alert.setalert' | translate)}}</button>
    </div>
	</div>
</mat-dialog-actions>
