<app-header *ngIf="isMobile" goback="1" [title]="'alert.alert' | translate" [isLoading]="isLoading"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'alert.alert' | translate"></app-titlebar>
<mat-card class="webpanelnew">
<mat-card-content>
    <div [class.pt-2]="!isMobile" [class.data-container]="isMobile">
        <ng-container >
            <div *ngFor="let alert of alertList">
                <div class="row" (click)="result(alert)">
                    <div class="col-12">
                        <div class="watchlistwrapper">
                            <div class="row" [class.px-3]="!isMobile">
                                <div class="col-7">
                                    <div class="firstline">{{alert.Tsym}} <small>{{alert.Exchange}}</small></div>
                                    <div class="line2">{{alert.Field}}</div>
                                </div>
                                <div class="col-5 text-right">
                                    <div>
                                        <div class="firstline">{{alert.Operation}} &nbsp; {{alert.Target}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf = "isnodata">
        <app-nodata (refreshEvent) = "addAlert()" [btnTitle]="'alert.add_alert' | translate"></app-nodata>
    </div>
</mat-card-content>
</mat-card>
<mat-dialog-actions *ngIf = "!isLoading && !isnodata" class="mat-dialog-actions d-block">
    <div class="row" [class.mx-2]="!isMobile">
        <div class="col-12 text-right" [class.button-footer]="isMobile">
            <button mat-flat-button [class.w-100] = "isMobile" class = "btn-primary" (click) = "addAlert()"><span translate>alert.add_alert</span></button>
        </div>
    </div>
</mat-dialog-actions>

