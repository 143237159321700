import { Preferences, ReturnType } from './Preferences';
import { Utility } from './utility';
export class WatchListUtility {
    static addStockInWatchList(wl, seltctedStock, service, callback: Function) {
        if (Utility.watchlistDataMap.get(wl))
        var scripList = Utility.watchlistDataMap.get(wl).split(',');
        if (scripList.includes(seltctedStock)) {
            callback(false, 'Fail');
        } else {
            service.getWatchListService().addScripToWL(seltctedStock, wl, function(list, code, msg) {
                if (code === 200) {
                    Utility.watchlistData.next(true);
                    callback(true, 'Success');
                } else {
                    callback(true, 'Fail');
                }
            }.bind(this));
        }
    }

    static checkScripInWl(seltctedStock){
        const WLstr = Preferences.getItem('watchlistname', ReturnType.Undefined);
        const watchlistMap = JSON.parse(Preferences.getPreference('watchlistDataMap'));
        if (WLstr !== null && WLstr !== undefined) {
            let WlArr = [...WLstr];
            let returnArr = [];
            for(let i=0; i< WlArr.length; i++) {
                if(Utility.watchlistDataMap.get(WlArr[i]) === undefined)
                    Utility.watchlistDataMap.set(WlArr[i], watchlistMap[WlArr[i]]);

                if (Utility.watchlistDataMap.get(WlArr[i]))
                var scripList = Utility.watchlistDataMap.get(WlArr[i]).split(',');
                let isDisable = (scripList.includes(seltctedStock)) ? true : false;
                returnArr.push({'wlName': WlArr[i], 'isDisable': isDisable});
             }
             return returnArr;
        }
    }
}
