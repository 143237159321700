import { Component, EventEmitter, Output } from '@angular/core';
import { BasketModel } from 'src/app/model/basket.model';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Router } from '@angular/router';
import * as crosshair from 'chartjs-plugin-crosshair';
import { Preferences, ReturnType } from 'src/app/services/Preferences';

let basketCompare: BasketCompareComponent;
@Component({
    selector: 'app-basketcompare',
    templateUrl: './basketcompare.component.html',
    styleUrls: ['./basketcompare.component.scss']
})

export class BasketCompareComponent {
    @Output() load = new EventEmitter<boolean>();
    id: any;
    basketList: Array<BasketModel>;
    basketData = [];
    selectedBasket = [];
    basketId = '';
    basketDetailData = [];
    isNodata: boolean;
    requestObj = {};
    multipleBenchMarkData = [];
    basketIndexArray = [];
    basketBenchmarkData;
    width;
    element;
    style;
    total = 0;
    symbol = '';
    displayColumns: string[] = ['symbol', 'weight'];
    selectedIndex = 'NIFTY';
    graphData = {};
    chartLoaded: boolean;
    isLoading: boolean;
    investmentPeriodMap = {};
    riskMap = {};
    showChart = true;
    closeButtonHide: boolean;
    isClosedClick: boolean;
    clickCount = 0;
    itemArray = [];
    chartData = [];
    chartLabels = [];
    chartOptions = {};
    chartType;
    chartPlugins;
    borderColor;
    constructor(private PS: PtPlusRestService, private router: Router) {
        basketCompare = this;
        basketCompare.createMap();
        basketCompare.id = Preferences.getItem('selectedBasket', ReturnType.Array);
        basketCompare.basketList = Preferences.getItem('basketList', ReturnType.Array);
        basketCompare.getComparisonData();
    }

    createMap() {
        basketCompare.investmentPeriodMap['s'] = 'Short';
        basketCompare.investmentPeriodMap['m'] = 'Medium';
        basketCompare.investmentPeriodMap['l'] = 'Long';
        basketCompare.riskMap['h'] = 'High';
        basketCompare.riskMap['m'] = 'Medium';
        basketCompare.riskMap['l'] = 'Low';
    }

    getComparisonData() {
        basketCompare.id.map(element => {
            for (let i = 0; i < basketCompare.basketList.length; i++) {
                if (element === basketCompare.basketList[i]['id']) {
                    basketCompare.basketData.push(basketCompare.basketList[i]);
                }
            }
        });
        basketCompare.basketData.forEach(element => {
            basketCompare.basketId = basketCompare.basketId + element['id'] + ',';
        });
        basketCompare.basketId = basketCompare.basketId.slice(0, -1);
        basketCompare.isLoading = true;
        basketCompare.PS.getThematicBasketService().getMultipleBasketDetailData(basketCompare.basketId, basketCompare.getMultipleBasketData);
        basketCompare.requestObj = { 'basket_or_index': basketCompare.basketId, 'type': 'basket,basket', 'date': '2019-03-24' };
        basketCompare.getBenchMarkData(basketCompare.requestObj);
    }

    getMultipleBasketData(code, result, message) {
        basketCompare.isLoading = false;
        basketCompare.load.emit(basketCompare.isLoading);
        if (message === 'SUCCESS' && code === 200) {
            basketCompare.basketDetailData = result;
        }
        else {
            basketCompare.isNodata = true;
        }
    }

    getBenchMarkData(obj) {
        basketCompare.isLoading = true;
        basketCompare.PS.getThematicBasketService().getMultipleBenchmarkData(obj, basketCompare.getMultipleBenchmarkData);
    }

    getMultipleBenchmarkData(result, code, message) {
        basketCompare.isLoading = false;
        basketCompare.load.emit(basketCompare.isLoading);
        if (code === 200 && message === 'SUCCESS') {
            basketCompare.multipleBenchMarkData = result;
            basketCompare.drawComparisonChart();
        }
        else {
            basketCompare.isNodata = true;
        }
    }

    drawComparisonChart() {
        basketCompare.chartData = [];
        const plottingValue = 35;
        const valueArr = [];
        const valueArr1 = [];
        basketCompare.graphData = {};
        basketCompare.chartLoaded = true;
        const tempArr = [];
        let keys = ['data', 'label', 'fill', 'borderColor'];
        let obj = {};
        let obj1 = {};
        basketCompare.loadChart();
        if (basketCompare.basketData[0]) {
            for (let i = 0; i < basketCompare.multipleBenchMarkData[basketCompare.basketData[0].id].length;) {
                tempArr.push(basketCompare.multipleBenchMarkData[basketCompare.basketData[0].id][i]['date']);
                valueArr.push(Number(basketCompare.multipleBenchMarkData[basketCompare.basketData[0].id][i]['value'].toFixed(2)));
                i = i + Math.ceil(basketCompare.multipleBenchMarkData[basketCompare.basketData[0].id].length / plottingValue);
            }
            obj[keys[0]] = valueArr;
            obj[keys[1]] = basketCompare.basketData[0].name;
            obj[keys[2]] = false;
            obj[keys[3]] = basketCompare.getColor(basketCompare.basketData[0].name);
            basketCompare.chartData.push(obj);
        }
        if (basketCompare.basketData[1]) {
            for (let i = 0; i < basketCompare.multipleBenchMarkData[basketCompare.basketData[1].id].length;) {
                tempArr.push(basketCompare.multipleBenchMarkData[basketCompare.basketData[1].id][i]['date']);
                valueArr1.push(Number(basketCompare.multipleBenchMarkData[basketCompare.basketData[1].id][i]['value'].toFixed(2)));
                i = i + Math.ceil(basketCompare.multipleBenchMarkData[basketCompare.basketData[1].id].length / plottingValue);
            }
            obj1[keys[0]] = valueArr1;
            obj1[keys[1]] = basketCompare.basketData[1].name;
            obj1[keys[2]] = false
            obj1[keys[3]] = basketCompare.getColor(basketCompare.basketData[1].name);
            basketCompare.chartData.push(obj1);
        }
        basketCompare.chartLabels = tempArr;
    }

    getColor(name) {
        if (name === 'Prime Basket 1')
            basketCompare.borderColor = basketCompare.style.outlineColor;
        else
            basketCompare.borderColor = basketCompare.style.borderColor;
        return basketCompare.borderColor;
    }

    onClose(data) {
        basketCompare.closeButtonHide = true;
        basketCompare.showChart = false;
        setTimeout(() => {
            basketCompare.showChart = true;
        });
        basketCompare.selectedBasket = Preferences.getItem('selectedBasket', ReturnType.Array);
        const index = basketCompare.selectedBasket.indexOf(data.id);
        if (index > -1) {
            basketCompare.selectedBasket.splice(index, 1);
            basketCompare.id.splice(index, 1);
        }
        if (basketCompare.id.length >= 1) {
            basketCompare.requestObj = { 'basket_or_index': JSON.stringify(basketCompare.id[0]), 'type': 'basket,basket', 'date': '2019-03-24' };
        }
        else {
            basketCompare.requestObj = { 'basket_or_index': '', 'type': '', 'date': '2019-03-24' };
        }
        Preferences.setItem('selectedBasket', basketCompare.selectedBasket);
        basketCompare.isClosedClick = true;
        basketCompare.clickCount = basketCompare.clickCount + 1;
        basketCompare.itemArray.push(basketCompare.clickCount);
        basketCompare.basketData.splice(basketCompare.basketData.indexOf(data), 1);
        basketCompare.drawComparisonChart();
    }

    addBasket() {
        basketCompare.router.navigate(['/basket']);
    }

    loadChart() {
        basketCompare.element = document.querySelector('.chart-color5');
        basketCompare.style = getComputedStyle(basketCompare.element);
        basketCompare.chartType = 'line';
        basketCompare.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                titleAlign: 'center',
                titleSpacing: 10,
                titleMarginBottom: 14,
                bodySpacing: 10,
                intersect: false,
                mode: 'index',
                callbacks: {
                    title: function (tooltipItems, data) {
                        return new Date(tooltipItems[0].xLabel).toLocaleDateString('en-DE', { month: 'short', year: 'numeric' });
                    },
                    label: function (tooltipItem, data) {
                        return (' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel.toFixed(2) + '%');
                    }
                }
            },
            elements: { point: { radius: 0 } },
            layout: { padding: { left: 8, right: 8, top: 16 } },
            legend: { position: 'bottom', labels: { boxWidth: 25, fontColor: basketCompare.style.color } },
            plugins: {
                datalabels: { display: false },
                crosshair: {
                    line: { color: basketCompare.style.columnRuleColor, width: 3 },
                },
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontColor: basketCompare.style.color,
                        callback: function (label, index, dataset) {
                            return label + '%';
                        }
                    },
                    gridLines: { display: false },
                }],
                xAxes: [{
                    type: 'time',
                    time: { unit: 'quarter', displayFormats: { quarter: 'MMM YYYY' } },
                    ticks: { fontColor: basketCompare.style.color },
                    gridLines: { lineWidth: 0, zeroLineColor: basketCompare.style.color }
                }]

            },
        };
        basketCompare.chartPlugins = [crosshair];
    }
}
