<app-stockheader [isStock]='true' [isLand]='isLand' [scripModel] = "scrpModel" [isLoading]="isLoading"></app-stockheader>
<mat-chip-list class="fixwithheader  nofilter" >
    <mat-chip (click)="onTabChanged(0)" [class.imactive] = "tabIndex ===  0" translate>stock_detail.overview</mat-chip>
    <mat-chip (click)="onTabChanged(1)" *ngIf = "isHideTab && advanceFeature" [class.imactive] = "tabIndex ===  1" translate>stock_detail.analysis</mat-chip>
    <mat-chip (click)= "onTabChanged(2)" *ngIf = "isHideTab && defaultFeature" [class.imactive] = "tabIndex ===  2" translate>stock_detail.technicals</mat-chip>
    <mat-chip (click)= "onTabChanged(3)" *ngIf = "isHideTab && betaFeature" [class.imactive] = "tabIndex ===  3" translate>stock_detail.peer</mat-chip>
    <mat-chip (click)= "onTabChanged(4)" *ngIf = "isHideTab && betaFeature" [class.imactive] = "tabIndex ===  4" translate>stock_detail.key_ratio</mat-chip>
    <mat-chip (click)= "onTabChanged(5)" *ngIf = "isHideTab && betaFeature" [class.imactive] = "tabIndex ===  5" translate >stock_detail.financials</mat-chip>
    <mat-chip (click)= "onTabChanged(7)" *ngIf = "defaultFeature" [class.imactive] = "tabIndex ===  7" translate>stock_detail.news<em class="freetrial chipbubble">Trial</em></mat-chip>
    <mat-chip (click)= "onTabChanged(8)" *ngIf = "isHideTab" [class.imactive] = "tabIndex ===  8" translate>stock_detail.events<em class="freetrial chipbubble">Trial</em></mat-chip>
    <mat-chip (click)= "onTabChanged(6)" *ngIf = "isHideTab && betaFeature" [class.imactive] = "tabIndex ===  6" translate>stock_detail.company_info</mat-chip>  
    <mat-chip (click)= "onTabChanged(9)" *ngIf = "scrpModel.ExchangeSegment === 'nse_cm'" [class.imactive] = "tabIndex ===  9" translate>stock_detail.option_chain<em class="freetrial chipbubble">Trial</em></mat-chip>             
</mat-chip-list>

    <div *ngIf="!isLand" class="footerbtn">
        <button mat-flat-button class="btn-positive" (click)='openPlaceOrder("B")'><span translate>stock_detail.buy</span></button>
        <button mat-flat-button class="btn-negative" (click)='openPlaceOrder("S")'><span translate>stock_detail.sell</span></button>
    </div>

<div class="data-container-stockd">
    <div class="row">
        <div class="col-12">
            <ng-container *ngxCacheIf = "tabIndex === 0">
               <div class="row chartcontent">
                    <div class="col-12">
                        <div id="lite-weight">
                            <button mat-icon-button class="graph-enlarge" (click)="openTradingViewChart()"><i class="icon-expand"></i></button>
                            <app-lightweightchart (outputEvent)="chartEvent($event)" #lightweightchart [chartWidth]='graphWidth' [chartHeight]='graphHeight'></app-lightweightchart>
                        </div>
                    </div>
                </div>
                <mat-card>
                    <div class="h4new" translate>stock_detail.depth</div>
                    <mat-card-content><app-depth [omtkn] = "omtkn" [exseg] = "exseg" (loaderEvent)="loaderEvent($event)"></app-depth></mat-card-content>
                </mat-card>
            <mat-card>
                <div class="h4new" translate>stock_detail.quotes</div>
                <mat-card-content>
                    <app-qoutes [omtkn] = "omtkn" [exseg] = "exseg"></app-qoutes>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 8">
            <mat-card class="webpanelnew">
                <mat-card-content>
                    <app-event [IsStockSpecific]="true" (loaderEvent)="loaderEvent($event)" [ScripToken]="scrpModel.Token" [ExchangeSegment]="scrpModel.ExchangeSegment"></app-event>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 1">
            <mat-card class="webpanelnew">
                <mat-card-content>
                    <app-ibes [omtkn] = "omtkn" [exseg] = "exseg"></app-ibes>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 2">
            <mat-card *ngIf = "defaultFeature" class="webpanelnew">
                <mat-card-content>
                    <app-technicals [omtkn] = "omtkn" [exseg] = "exseg"  (loaderEvent)="loaderEvent($event)"></app-technicals>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 3">
            <mat-card *ngIf = "betaFeature" class="webpanelnew">
                <mat-card-content>
                    <app-peer (loaderEvent)="loaderEvent($event)" [omtkn] = "omtkn" [exseg] = "exseg"></app-peer>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 4">
            <mat-card *ngIf = "betaFeature" class="webpanelnew">
                <mat-card-content>
                    <app-keyratio  [omtkn] = "omtkn" [exseg] = "exseg"></app-keyratio>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 5">
            <mat-card *ngIf = "betaFeature" class="webpanelnew">
                <mat-card-content>
                    <app-financial (loaderEvent)="loaderEvent($event)" [omtkn] = "omtkn" [exseg] = "exseg"></app-financial>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 6">
            <mat-card *ngIf = "betaFeature" class="webpanelnew">
                <mat-card-content>
                    <app-about [omtkn] = "omtkn" [exseg] = "exseg"></app-about>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 7">
            <mat-card *ngIf = "defaultFeature" class="webpanelnew">
                <mat-card-content>
                    <app-tagspecificnewsweb [IsStockSpecific]="false" [tagtype]="scrpModel.Symbolname" (loaderEvent)="loaderEvent($event)"></app-tagspecificnewsweb>
                </mat-card-content>
            </mat-card>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 9">
            <mat-card *ngIf = "defaultFeature" class="webpanelnew sd-optionchain">
                <mat-card-content>
                    <app-option [IsStockSpecific]="true" [token] = "scrpModel.Token" [tagtype]="scrpModel.Symbolname" (loaderEvent)="loaderEvent($event)"></app-option>
                </mat-card-content>
            </mat-card>
        </ng-container>

        </div>
    </div>
</div>
