import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StockMoreOptionComponent } from '../../stockmoreoption/stockmoreoption.component';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { TableUtility } from '../../../../services/tableutility';
import { Mouse } from 'src/app/services/mouse';
@Component({
  selector: 'app-recentlyview',
  templateUrl: '../../../'+environment.baseviewpath+'/home/recentlyview/recentlyview.component.html'
})
export class RecentlyViewComponent implements OnInit {

  @Output() openStockDetailPage = new EventEmitter<any>();
  @Output() itemClicked = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Input() fromWatchlist: boolean;
  @Input() currentPageName = '';
  @Input() selWLName = '';
  scripResult = [];
  tokenList = [];
  tokenExsgList = [];
  WlArr = [];
  wltokens = [];
  scripObj;
  isMobile: boolean;
  selectedRowIndex = 0;
  M;
  @Input() isOpen;
  flashType = 'defaultFlash';

  constructor(private tradingRestService: TradingRestService, private router: Router, public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet, private snackBar: MatSnackBar, private translate: TranslateService) {

    this.isMobile = Utility.IS_MOBILE;
    this.M = Mouse;
    this.scripObj = Preferences.getItem('recentlySearchedScrips', ReturnType.Array);
    if (this.scripObj !== null && this.scripObj !== undefined && this.scripObj.length > 0) {
      for (let index = this.scripObj.length - 1; index >= 0 && this.scripResult.length < 5; index--) {
          if(this.tokenList.includes(this.scripObj[index].token)) {
            continue;
          }
          this.tokenList.push(this.scripObj[index].token);
          this.tokenExsgList.push(this.scripObj[index].exseg +"|"+this.scripObj[index].token)
          this.scripResult.push(Utility.getScripModel(this.scripObj[index].token, this.scripObj[index].exseg, this.tradingRestService));
      }
      const sendJSON = { 'scripList': this.tokenExsgList.toString() };
      this.tradingRestService.getScripService().getMultipleScrips(sendJSON, this.getMultipleScripsData);
    }
    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      this.flashType = Preferences.getItem('flashType');
    }
    Utility.flashType.subscribe(obj => {
      this.flashType = obj;
    });
  }

  initializeRowSelection()
  {
    setTimeout(() => {
      // if(this.scripResult && this.scripResult.length > 0)
      // {
      //   let element = document.getElementById("recent_search_list0") as HTMLElement;
      //   element.focus();
      // }
    }, 1000);
  }

  ngOnInit() 
  {
    this.initializeRowSelection();
  }

  ngOnChanges() 
  {
    this.wltokens = Preferences.getItem('stocks', ReturnType.Array);
    this.selectedRowIndex = 0;
    this.initializeRowSelection();
  }

  result(item) {
      const obj = {omtkn: item.Token, exseg: item.ExchangeSegment, tsym: item.TradingSymbol, symdes: item.SymbolDescription, expry: '-'};
      this.itemClicked.emit(obj);
  }

  openPlaceOrder(buttontype, data) {
    Utility.openPlaceOrder(this.router, data.Token, data.ExchangeSegment, buttontype, this.dialog);
    if(!Utility.IS_MOBILE){
      this.close.emit();
    }
  }

  openStockDetail(data) {
    const obj = { 'omtkn': data.Token, 'exseg': data.ExchangeSegment, 'tsym': data.TradingSymbol };
    this.openStockDetailPage.emit(obj);
  }
  addWLdata(stockdata, event = undefined) {
    event?.stopPropagation();
    const seltctedStock = (stockdata.ExchangeSeg === undefined) ?  stockdata.ExchangeSegment + '|' + stockdata.Token : stockdata.ExchangeSeg + '|' + stockdata.Token;
    this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
  }

  addStockPopup(wl, stockdata) {
    const seltctedStock = (stockdata.ExchangeSeg === undefined) ?  stockdata.ExchangeSegment + '|' + stockdata.Token : stockdata.ExchangeSeg + '|' + stockdata.Token;
    WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
      if (result && msg === 'Success') {
        this.snackBar.open(this.translate.instant('books.add_stock'), '', { duration: 3000 });
      } else if (result && msg === 'Fail') {
        this.snackBar.open(this.translate.instant('books.server_fail'), '', { duration: 3000 });
      } else {
        this.snackBar.open(this.translate.instant('books.cancel_stock'), '', { duration: 3000 });
      }
    }.bind(this));
  }

  highlight(row: any, i: number){
    Mouse.IS_MOUSE = false;
    if(this.selectedRowIndex !== i) {
      let element:HTMLElement;
        element = document.getElementById("recent_search_list"+i) as HTMLElement;
        element.focus();
    }
    this.selectedRowIndex = i;
    Utility.SELECTED_SCRIP = row;
    Utility.selectedTab = TableUtility.recentSearch;
  }

  @HostListener('window:keydown.arrowup', ['$event'])  
  arrowUpEvent(event: KeyboardEvent){
    event.preventDefault();
    if(((this.selectedRowIndex - 1) >= 0))
    {
        var nextrow = this.scripResult[this.selectedRowIndex - 1];
        this.highlight(nextrow, this.selectedRowIndex - 1);
    }
    else
    {
      this.highlight(this.scripResult[this.scripResult.length - 1], this.scripResult.length - 1);
    }
  }

  @HostListener('window:keydown.arrowdown', ['$event'])
  arrowDownEvent(event: KeyboardEvent){
    event.preventDefault();
    if(((this.selectedRowIndex + 1) < this.scripResult.length))
    {
      var nextrow = this.scripResult[this.selectedRowIndex + 1];
      this.highlight(nextrow, this.selectedRowIndex + 1);
    }
    else {
      this.highlight(this.scripResult[0], 0);
    } 
  }

  addStockToSelWL(data) {
    if (this.wltokens.includes(data.Token + '_' + data.ExchangeSegment)) {
      let mWString = '';
      if (environment.isHypersync)
        mWString = data.ExchangeSegment + '|' + data.Token ;
      else
        mWString = Utility.segmentToExchange(data.ExchangeSegment) + '|' + data.Token + '#';
      this.tradingRestService.getWatchListService().deleteScripToWL(mWString, this.selWLName, (list, code, msg) => {
        if (code === 200) {
          Preferences.removeItem('stocks');
          const index = this.wltokens.indexOf(data.Token + '_' + data.ExchangeSegment);
          this.wltokens.splice(index, 1);
          Preferences.setItem('stocks', this.wltokens);
        }
      });
    } else{
      if (environment.DefaultFeature ? this.wltokens.length < 50 : true)
      {
        let mWString = '';
        Preferences.removeItem('stocks');
        this.wltokens.push(data.Token + '_' + data.ExchangeSegment);
        Preferences.setItem('stocks', this.wltokens);
        mWString = Utility.segmentToExchange(data.ExchangeSegment) + '|' + data.Token;
        this.tradingRestService.getWatchListService().addScripToWL(mWString, this.selWLName, (list, code, msg) => {
          if (code === 200) {
            Preferences.setItem('defaultMW', this.selWLName);
          }
        });
      }
      else
        this.snackBar.open(this.translate.instant('watchlist.max_scrips'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  getMultipleScripsData(data, code, message) {
  }
}
