<app-header *ngIf="isMobile" goback="1" [title]="'header.logs' | translate" [isLoading]="isLoading" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'header.logs' | translate" [isLoading]="isLoading"></app-titlebar>
<mat-card class="webpanelnew">
    <mat-card-content>
        <div [class.pt-0]="!isMobile" [class.data-container]="isMobile">
            <ng-container *ngIf="!isLoading && !isNoData">
                <div *ngFor="let log of logs">
                    <div class="watchlistwrapper">
                        <div class="row" [class.px-3]="!isMobile">
                            <div class="col-12">
                                {{log.replace(' ::','').replace('User','').replace('::','')}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf='!isLoading && isNoData' class='nodata'>
                <app-nodata message = "N0 DATA"></app-nodata>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>