import { Component, Input, OnInit } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-tradesummary',
    templateUrl: '../../../'+environment.baseviewpath+'/tradebook/tradesummary/tradesummary.component.html',
})

export class TradesummaryComponent implements OnInit{
    @Input() nOrderNumber: boolean;
    list = [];
    displayedColumns: string[] = ['FillId', 'FilledQty', 'AvgPrice', 'ExchTime'];
    isNoData = false;
  
    constructor(private tradingRestService: TradingRestService) {
    }
    ngOnInit(): void {
        this.callTradeBookService();
    }
    callTradeBookService(){
        this.tradingRestService.getBookService().getAllTradeList(false, function(data, code, message) {
            if(code === 200){
                this.list = data.filter((currVal)=>{
                    if (currVal.NestOrd == this.nOrderNumber) {
                        return currVal;
                    }
                });
            }
            this.isNoData = (this.list.length === 0) ? true: false;
        }.bind(this));
    }
}
