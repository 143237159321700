import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import { MyAlertDialogComponent } from '../../home/watchlist/watchlist.component';
import { BasketPlaceOrderComponent } from '../basketplaceorder/basketplaceorder.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location} from '@angular/common';

let basketConstCmp: BasketConstituentsComponent;
@Component({
    selector: 'app-basketconstituents',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketconstituents/basketconstituents.component.html',
    styleUrls: ['./basketconstituents.component.scss']
})

export class BasketConstituentsComponent implements OnInit, OnChanges {
    @Output() load = new EventEmitter<boolean>();
    @Input() isOpen;
    
    isLoading;
    isMobile;
    isNoData;
    rebalanceAvl: boolean;
    basketPositionData;
    @Output() closeBasket = new EventEmitter<any>();
    constructor(private PS: PtPlusRestService, private router: Router, public dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar, private location: Location) {
        basketConstCmp = this;
      
    }

    ngOnInit() {
        if (this.isMobile)
            this.setData();
    }

    ngOnChanges(){
        if (!this.isMobile)
            this.setData();
    }

    setData(){
        basketConstCmp.isMobile = Utility.IS_MOBILE;
        let data = Utility.basketPositionData;
        if(data !== undefined)
        {
            this.basketPositionData = data.positionData;
            this.rebalanceAvl = data.isRebalance;
            Utility.basketName = data.basket_name;
        }
        else this.isNoData = true;
    }

    rebalanceBasket()
    {
        if(basketConstCmp.isMobile){
            this.router.navigate(['/basketplaceorder'], { queryParams: { 'basketOrderType': 'RebalancePurchase' } });
        } else {
            this.closeBasket.emit();
            Utility.openPage(this.router, 'basketcontituents', 'basketplaceorder', BasketPlaceOrderComponent, this.dialog, 'popup', { 'basketOrderType': 'RebalancePurchase' });
        }
    }
    
    exitBasket() {        
        if(basketConstCmp.isMobile){
            this.router.navigate(['/basketplaceorder'], { queryParams: { 'basketOrderType': 'ExitPurchase' } });
        }
        else{
            this.closeBasket.emit();
            Utility.openPage(this.router, 'basketcontituents', 'basketplaceorder', BasketPlaceOrderComponent, this.dialog, 'popup', { 'basketOrderType': 'ExitPurchase' });
        }
    
    }

}

