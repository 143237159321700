import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-svg',
  templateUrl: '../../'+environment.baseviewpath+'/svg/svg.component.html'
})
export class SvgComponent implements OnInit {
  ARROW = 1;
  WATCHLIST = 2;
  GOBACK = 3;
  LOGOICON = 4;
  STOCKICON = 5;
  INFO = 6;
  BTNLOADER = 7;
  LOGOFULL = 8;
  PULLDOWNARROW = 9;
  WALLET = 10;
  SUCCESS = 11;
  WARN = 12;
  ERROR = 13;
  NO_COMPONENT = 99;
  @Input() type: number;
  @Input() myClass: string;

  constructor() { }

  ngOnInit(): void {
  }

}
