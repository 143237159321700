import { Pipe, PipeTransform } from '@angular/core';
declare const moment: any;
@Pipe({ name: 'datepipe' })

export class DatePipe implements PipeTransform {

  transform(date: string, Formate?: string): any {
    if (date === undefined || date === '' || date === '-') {
      return '-';
    } else {
      return this.getDateFormate(date, Formate);
    }
  }
  getDateFormate(dateString: string, Formate?: string) {
    if (Formate !== undefined && Formate === 'date') {
      return moment(dateString).format('MMM DD, YYYY');
    } else if (Formate !== undefined && Formate === 'datetime') {
      if (moment(dateString).format('DD, MMM YYYY HH:mm:ss') === 'Invalid date') {
        return moment(dateString, 'DD/MM/YYYY HH:mm:ss').format('MMM DD, YYYY hh:mm:ss A');
      } else if(moment(dateString, 'DD/MM/YYYY HH:mm:ss', true).isValid()){
        return moment(dateString, 'DD/MM/YYYY HH:mm:ss').format('MMM DD, YYYY hh:mm:ss A');
      } else {
        return moment(dateString).format('MMM DD, YYYY hh:mm:ss A');
      }
    }else if (Formate !== undefined && Formate === 'datetime-13') {
      return moment(dateString, 'DD/MM/YYYY HH:mm:ss').format('DDMMMYY HH:mm:ss');
    } else if (Formate !== undefined && Formate === 'ISTFromNow') {
      return moment(dateString).fromNow();
    } else if (Formate !== undefined && Formate === 'mediumtime') {
      return moment(dateString).format('hh:mm:ss A');
    } else if (Formate !== undefined && Formate === 'timeformat') {
      return moment(dateString, 'DD-MM-YYYY HH:mm:ss').format('hh:mm:ss A');
    }
    else if (moment(dateString).format('DD-MM-YYYY') && Formate === 'datetimeFormat' ){
      return moment(dateString, 'DD-MM-YYYY').format('MMM DD, YYYY hh:mm:ss A');
    } else if (Formate !== undefined && Formate === 'datetimeFormat2') {
      return moment(dateString, 'DD-MM-YYYY HH:mm:ss').format('MMM DD, YYYY hh:mm:ss A');
    }
    else if (Formate !== undefined && Formate === 'dateIST') {
      if (moment(dateString).format('DD, MMM YYYY HH:mm:ss') === 'Invalid date') {
        return moment(dateString, 'DD/MM/YYYY HH:mm:ss').add(330, 'minutes').format('MMM DD, YYYY hh:mm:ss A');
      } else if(moment(dateString, 'DD/MM/YYYY HH:mm:ss', true).isValid()){
        return moment(dateString, 'DD/MM/YYYY HH:mm:ss').add(330, 'minutes').format('MMM DD, YYYY hh:mm:ss A');
      } else {
        return moment(dateString).add(330, 'minutes').format('MMM DD, YYYY hh:mm:ss A');
      }
    }
    else {
      return moment.parseZone(dateString).fromNow();
    }
  }
}
