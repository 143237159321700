import { Component, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { ScripModel } from "src/app/model/scrip.model";
import { TradingRestService } from "src/app/services/tradingrest.service";
import { Utility } from "src/app/services/utility";
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from "src/environments/environment";
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { IScripService } from "src/app/services/scrip/iscrip.service";

@Component({
    selector: 'app-createtrigger',
    templateUrl: '../../'+environment.baseviewpath+'/createTrigger/createTrigger.component.html',
})
export class CreateTriggerComponent{ 
    scrpModel: ScripModel;
    isNoData = false;
    isMobile: boolean;
    isLoading: boolean;
    @Output() load = new EventEmitter<boolean>();
    transType = "B";
    qty = 1;
    price: number;
    trgPrice: number;
    prdctType = "CNC";
    ordType = "L";
    stp_qty = 1;
    stp_price: number;
    stp_trgPrice: number;
    trgType = "GTT_SINGLE";
    trgCondition = ['gt','lt','eq','gte','lte'];
    stpTrgCondition = ['gt','lt','eq','gte','lte'];
    selectedTrgCond = 'gte';
    selectedStpTrgCond = 'lte';
    token: string;
    exchsegment: string;
    tradingSymbol: string;
    ltp: number;
    expiryTime: any;
    createdData = {};
    isEdit = false;
    FlagOCO = true;
    trgPrcPrcnt: number;
    stpTrgPrcPrcnt: number;
    isError: boolean;
    oid: number;
    stp_oid: number;
    ticksize;
    scripService: IScripService;
    triggerTypeChange = {};
    isDisableForm = false;
    confrmFlag = true;

    constructor(private tradingRestService: TradingRestService, private activatedRoute: ActivatedRoute,  private snackBar: MatSnackBar,  private translate: TranslateService, private location: Location) {
        this.isMobile = Utility.IS_MOBILE;
        this.scripService = tradingRestService.getScripService();
        if (Utility.IS_MOBILE) {
            this.activatedRoute.queryParams.subscribe(params => {
                this.isEdit = params.isEdit;          
                this.stockData(params);
            });
        } else {
            this.isEdit = Utility.dialogData.isEdit;
            this.stockData(Utility.dialogData);
        }
        this.initTrigger();
    }

    initTrigger() {
        if(this.isEdit) {
            if(Object.keys(Utility.triggerData).length !== 0) {
                const val = Utility.triggerData;
                this.transType = val['ord'][0].ttyp;
                this.trgType = val['typ'];
                this.qty = val['ord'][0].qty;
                this.price = val['ord'][0].px.toFixed(2);
                this.ordType = val['ord'][0].otyp;
                this.prdctType = val['ord'][0].prod;
                this.trgPrice =  val['ord'][0].tv.toFixed(2);
                this.oid = val['ord'][0].oid;
                this.expiryTime =  moment(val['exAt']).format('YYYY-MM-DD HH:mm:ss');
                this.calTriggerPrcPercent(this.trgPrice);
                if(val['typ'] === 'GTT_OCO') {
                    this.stp_oid = val['ord'][1].oid;
                    this.stp_qty = val['ord'][1].qty;
                    this.stp_price = val['ord'][1].px.toFixed(2);
                    this.stp_trgPrice =  val['ord'][1].tv.toFixed(2);   
                    this.calStopTriggerPrcPercent(this.stp_trgPrice);
                }
                this.changeTransectionType(this.transType);
            } else {
                this.location.back();
            }
        } else {
            const currentTime = new Date();
            this.expiryTime = currentTime.setFullYear(currentTime.getFullYear() + 1);
            this.expiryTime = moment(this.expiryTime).format('YYYY-MM-DD HH:mm:ss');
        }
    }

    getPriceMultiple(price, ticksize) {
        price = Math.ceil(price / ticksize) * ticksize;
        return price.toFixed(2);
    }
    
    stockData(qParams) {
        this.token = qParams.omtkn;
        this.exchsegment = qParams.exseg;
        this.scrpModel = Utility.getScripModel(this.token, this.exchsegment, this.tradingRestService, true); 
        this.scripService.getSecInfo({ 'exch': this.exchsegment, 'tk': this.token }, this.getSecInfoRes.bind(this));
    }

    getSecInfoRes(data, code, message){
        this.scrpModel = Utility.getScripModel(this.token, this.exchsegment, this.tradingRestService, true); 
        setTimeout(() => {
            this.ticksize = Utility.getTickSize(this.scrpModel.TickSize, this.scrpModel.Multiplier, this.scrpModel.DecimalPrecision);
            this.tradingSymbol = this.scrpModel.TradingSymbol;
            this.ltp = parseFloat(this.scrpModel.LastTradePrice);
            if  (!this.isEdit) {
                this.trgPrice = this.getPriceMultiple(Number((this.ltp - (this.ltp * 0.05)).toFixed(2)), this.ticksize);
                this.stp_trgPrice = this.getPriceMultiple(Number((this.ltp - (this.ltp * 0.05)).toFixed(2)), this.ticksize);
                this.price = this.getPriceMultiple(Number((this.ltp - (this.ltp * 0.05)).toFixed(2)), this.ticksize);
                this.stp_price = this.getPriceMultiple(Number((this.ltp - (this.ltp * 0.05)).toFixed(2)), this.ticksize);
                this.triggerTypeChange = {'trgPrice': this.trgPrice, 'price': this.price, 'stp_trgPrice': this.stp_trgPrice, 'stp_price': this.stp_price, 'qty': this.qty, 'stp_qty': this.stp_qty};
            }
            let data = Utility.FilterProducts(this.scrpModel.ExchangeName);
            this.prdctType = this.sort(data);
            this.calTriggerPrcPercent(this.trgPrice);
            this.calStopTriggerPrcPercent(this.stp_trgPrice);
            this.confrmFlag = false;
        }, 1000);
    }

    createTrigger() {
        this.isLoading = true;
        this.confrmFlag = true;
        const cond = this.getCondition();
        let obj = {"exchange":this.exchsegment,"tradingsymbol":this.tradingSymbol,"scrip_tkn":this.token,"ltp":Number(this.scrpModel.LastTradePrice)};
        let obj2 = [];
        if(this.prdctType !== ''){
            if(this.trgType === 'GTT_SINGLE') {
                obj2 = [{"transaction_type":this.transType,"quantity":Number(this.qty),"price":Number(this.price),"order_type":this.ordType,"product":this.prdctType,"trigger_value":Number(this.trgPrice),"trigger_value_type":"ltp","trigger_condition":cond}];
            } else {
                obj2 = [{"transaction_type":this.transType,"quantity":Number(this.qty),"price":Number(this.price),"order_type":this.ordType,"product":this.prdctType,"trigger_value":Number(this.trgPrice),"trigger_value_type":"ltp","trigger_condition":this.selectedTrgCond},
                {"transaction_type":this.transType,"quantity":Number(this.stp_qty),"price":Number(this.stp_price),"order_type":this.ordType,"product":this.prdctType,"trigger_value":Number(this.stp_trgPrice),"trigger_value_type":"ltp","trigger_condition":this.selectedStpTrgCond}];
            }
        } else {
            this.snackBar.open(this.translate.instant('all.gtt_not'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        }
       
        let jdata =  {
            trigger: JSON.stringify(obj),
            executions: JSON.stringify(obj2),
            type: this.trgType,
            expires_at: this.expiryTime,
        }
        this.tradingRestService.getTriggerService().createTrigger(jdata, function(data, code, msg) {
            if(code === 200 && msg === 'SUCCESS') {
                this.createdData = data;
                this.snackBar.open(this.translate.instant('all.trigger_created'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['successSnackBar']});
                this.isNoData = false;
                if(this.isMobile) {
                    this.location.back();
                } else {
                    Utility.dialogRef.close();
                    Utility.getTriggers.next();
                } 
            }
            else {
                this.isNoData = true;
                this.snackBar.open(msg, this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            }
            this.isLoading = false;
            this.confrmFlag = false;
        }.bind(this));
    }

    sort(data) {
        if (this.scrpModel.ExchangeName === "NSE" || this.scrpModel.ExchangeName === "BSE") {
          if(data.indexOf('CNC') != -1){
              return 'CNC';
          } 
        }
        else {
          if(data.indexOf('NRML') != -1){
              return 'NRML';
          }
        }
        return '';  
    }

    editTrigger() {
        this.confrmFlag = true;
        this.isLoading = true;
        let obj2 = [];
        const tid = Utility.triggerData['tid'];
        const cond = this.getCondition();
        const obj = {"exchange":this.exchsegment,"tradingsymbol":this.tradingSymbol,"scrip_tkn":this.token,"ltp":Number(this.scrpModel.LastTradePrice)};
        if(this.trgType === 'GTT_SINGLE') {
            obj2 = [{"id":this.oid,"transaction_type":this.transType,"quantity":Number(this.qty),"price":Number(this.price),"order_type":this.ordType,"product":this.prdctType,"trigger_value":Number(this.trgPrice),"trigger_value_type":"ltp","trigger_condition":cond}];
        } else {
            obj2 = [{"id":this.oid,"transaction_type":this.transType,"quantity":Number(this.qty),"price":Number(this.price),"order_type":this.ordType,"product":this.prdctType,"trigger_value":Number(this.trgPrice),"trigger_value_type":"ltp","trigger_condition":this.selectedTrgCond},
            {"id":this.stp_oid,"transaction_type":this.transType,"quantity":Number(this.stp_qty),"price":Number(this.stp_price),"order_type":this.ordType,"product":this.prdctType,"trigger_value":Number(this.stp_trgPrice),"trigger_value_type":"ltp","trigger_condition":this.selectedStpTrgCond}];
        }
        const jdata = {
            'trigger': JSON.stringify(obj),
            'executions': JSON.stringify(obj2),
            'expires_at': this.expiryTime   
        };

        this.tradingRestService.getTriggerService().editTrigger(tid, jdata, function(data, code, msg) {
            if(code === 200 && msg === 'SUCCESS') {
                this.snackBar.open(this.translate.instant('all.trigger_edited'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['successSnackBar']});
                this.isMobile ? this.location.back() : Utility.dialogRef.close(); Utility.getTriggers.next();
            }
            else if(code === 0 && msg === "Please verify your request.") {
                this.snackBar.open(msg, this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
                this.isMobile ? this.location.back() : Utility.dialogRef.close(); Utility.getTriggers.next();
            }
            else {
                this.snackBar.open(msg, this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            }
            this.isLoading = false;
            this.confrmFlag = false;
        }.bind(this));

    }

    changeTransectionType(trtype) {
        this.ltp = Number(this.scrpModel.LastTradePrice);
        this.transType = trtype;
        if(this.transType === 'S') {
            this.FlagOCO = false;
            if(!this.isEdit) {
                this.trgPrice = this.getPriceMultiple(Number((this.ltp + (this.ltp * 0.05)).toFixed(2)), this.ticksize);
                this.price = this.getPriceMultiple(Number((this.ltp + (this.ltp * 0.05)).toFixed(2)), this.ticksize);
            }
        } else {    
            this.trgType = 'GTT_SINGLE';
            this.FlagOCO = true;
            if(!this.isEdit) {
                this.trgPrice = this.getPriceMultiple(Number((this.ltp - (this.ltp * 0.05)).toFixed(2)), this.ticksize);
                this.price = this.getPriceMultiple(Number((this.ltp - (this.ltp * 0.05)).toFixed(2)), this.ticksize);
            }
        }
        this.calTriggerPrcPercent(this.trgPrice);
    }

    onTriggerTypeChange(ev) {
        this.ltp = Number(this.scrpModel.LastTradePrice);
        this.trgType = ev.value;
        this.trgPrice = this.getPriceMultiple(Number((this.ltp + (this.ltp * 0.05)).toFixed(2)), this.ticksize);
        this.price = this.getPriceMultiple(Number((this.ltp + (this.ltp * 0.05)).toFixed(2)), this.ticksize);
        this.stp_trgPrice = this.getPriceMultiple(Number((this.ltp - (this.ltp * 0.05)).toFixed(2)), this.ticksize);
        this.stp_price = this.getPriceMultiple(Number((this.ltp - (this.ltp * 0.05)).toFixed(2)), this.ticksize);
        this.qty = this.triggerTypeChange['qty'];
        this.stp_qty = this.triggerTypeChange['stp_qty'];
        this.calTriggerPrcPercent(this.trgPrice);
        this.calStopTriggerPrcPercent(this.stp_trgPrice);
    }

    close() {
        Utility.dialogRef.close();
    }

    calTriggerPrcPercent(val) {
        this.ltp = Number(this.scrpModel.LastTradePrice);
        this.trgPrcPrcnt = Number((val - this.ltp) * 100) / this.ltp;
    }

    calStopTriggerPrcPercent(val) {
        this.ltp = Number(this.scrpModel.LastTradePrice);
        this.stpTrgPrcPrcnt = Number((val - this.ltp) * 100) / this.ltp;
    }

    getCondition() {
        if(this.transType === 'B') {
            return 'lte';
        }
        else if(this.transType === 'S') {
            return (this.trgPrice >= this.ltp) ? 'gte' : 'lte';
        }
    }

    validateQty(ev, str) {
        const regex = new RegExp('^[0-9]{0,7}$|^[0-9]{1,' + 2 + '}$');
        const index = ev.target.selectionStart;
        let val = (str === 'inputQty') ? this.qty : this.stp_qty;
        const value = val.toString();
        if (!value.match(regex)) {
            this.validateKey(ev.data, str)
            ev.srcElement.value = value.substring(0, index - 1) + value.substring(index, value.length);
            ev.path[0].dispatchEvent(new Event('input'));
            ev.path[0].setSelectionRange(index - 1, index - 1);
        }
    }

    validatePrice(ev, str) {
        const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
        let val;
        if(str === 'inputPrice' || str === 'inputStpPrice') {
            val = (str === 'inputPrice') ? this.price : this.stp_price;
        } else {
            val = (str === 'inputTrgPrice') ? this.trgPrice : this.stp_trgPrice;
        }
        const index = ev.target.selectionStart;
        const value = val.toString();
        if (!value.match(regex)) {
            this.validateKey(ev.data, 'price');
            ev.srcElement.value = value.substring(0, index - 1) + value.substring(index, value.length);
            ev.path[0].dispatchEvent(new Event('input'));
            ev.path[0].setSelectionRange(index - 1, index - 1);
        }
    }

    validateKey(key, str) {
        if (/[0-9]/i.test(key)) {
            if (str === 'price')
                this.snackBar.open(this.translate.instant('all.max_bfr_digits') +this.scrpModel.DecimalPrecision + this.translate.instant('all.max_aft_digits'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            else
                this.snackBar.open(this.translate.instant('all.max_sevn_digits'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        }
        else if (/[a-zA-Z]/i.test(key)) {
            this.snackBar.open(this.translate.instant('all.only_digits'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        }
    }

    onEnter(id) {
        document.getElementById(id).focus();
    }

    checkValidation() {
        if (this.trgType === "GTT_OCO" && (Utility.getNumber(this.stp_trgPrice).length == 0)) {
            this.snackBar.open(this.translate.instant('all.trgprice_empty'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stpTrgPrice');
        }
        else if (this.trgType === "GTT_OCO" && (Utility.getNumber(this.stp_trgPrice) === 0)) {
            this.snackBar.open(this.translate.instant('all.invalid_trgprice'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stpTrgPrice');
        }
        // else if (this.trgType === "GTT_OCO" && (!Utility.getNumber(this.stp_trgPrice) || !this.checkPriceLength(this.stp_trgPrice))) {
        //     this.snackBar.open(this.translate.instant('all.invalidtrigger'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('stpTrgPrice');
        // }
        else if (this.trgType === "GTT_OCO" && (this.floatSafeModulus(this.stp_trgPrice, this.ticksize) !== 0)) {
            this.snackBar.open(this.translate.instant('all.triggerpricemultiple') + this.ticksize + '.', this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stpTrgPrice'); 
        }
        else if (this.trgType === "GTT_OCO" && !(this.stp_trgPrice < Utility.getNumber(this.scrpModel.LastTradePrice))) {
            this.snackBar.open(this.translate.instant('all.trgprc_lt_ltp'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stpTrgPrice');
        }
        else if (this.trgType === "GTT_OCO" && (Utility.getNumber(this.stp_qty).length == 0)) {
            this.snackBar.open(this.translate.instant('all.qty_empty'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stpQty');
        }
        // else if (this.trgType === "GTT_OCO" && (!this.stp_qty || !this.checkQty(this.stp_qty))) {
        //     this.snackBar.open(this.translate.instant('all.invalid_qty'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('stpQty');
        // }
        else if (this.trgType === "GTT_OCO" && (Utility.getNumber(this.stp_qty) === 0))  {
            this.snackBar.open(this.translate.instant('all.invalid_qty'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stpQty');
        }
        else if (this.trgType === "GTT_OCO" && (Utility.getNumber(this.stp_price).length == 0)) {
            this.snackBar.open(this.translate.instant('all.price_empty'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stpPrice');
        }
        else if (this.trgType === "GTT_OCO" && (Utility.getNumber(this.stp_price) === 0)) {
            this.snackBar.open(this.translate.instant('all.invalid_price'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stpPrice');
        }
        // else if (this.trgType === "GTT_OCO" && (!this.stp_price || !this.checkPriceLength(this.stp_price))) {
        //     this.snackBar.open(this.translate.instant('all.invalid_price'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('stpPrice');
        // } 
        else if (this.trgType === "GTT_OCO" && (this.floatSafeModulus(this.stp_price, this.ticksize) !== 0)) {
            this.snackBar.open(this.translate.instant('all.pricemultiple') + this.ticksize + '.', this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('stPrice');
        }
        // else if (this.trgType === "GTT_OCO" && !(this.stp_price <= this.stp_trgPrice)) {
        //     this.snackBar.open(this.translate.instant('price_le_trgprc'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('stpPrice');
        // }
        else if (Utility.getNumber(this.trgPrice).length == 0) {
            this.snackBar.open(this.translate.instant('all.trgprice_empty'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('trgPrice');
        }
        else if (Utility.getNumber(this.trgPrice) === 0) {
            this.snackBar.open(this.translate.instant('all.invalid_trgprice'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('trgPrice');
        }
        // else if (!Utility.getNumber(this.trgPrice) || !this.checkPriceLength(this.trgPrice)) {
        //     this.snackBar.open(this.translate.instant('all.invalidtrigger'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('trgPrice');
        // }
        else if (this.trgType === "GTT_SINGLE" && this.transType === "B" && (this.trgPrice > Utility.getNumber(this.scrpModel.LastTradePrice))) {
            this.snackBar.open(this.translate.instant('all.trgprc_le_ltp'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('trgPrice');
        }
        // else if (this.trgType === "GTT_SINGLE" && this.transType === "S" && this.trgPrice < Utility.getNumber(this.scrpModel.LastTradePrice)) {
        //     this.snackBar.open(this.translate.instant('all.trgprc_ge_ltp'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('trgPrice');
        // }
        else if (this.trgType === "GTT_OCO" && this.transType === "S" && this.trgPrice <= Utility.getNumber(this.scrpModel.LastTradePrice)) {
            this.snackBar.open(this.translate.instant('all.trgprc_gt_ltp'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('trgPrice');
        }
        else if (this.floatSafeModulus(this.trgPrice, this.ticksize) !== 0) {
            this.snackBar.open(this.translate.instant('all.triggerpricemultiple') + this.ticksize + '.', this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('trgPrice'); 
        }
        else if (Utility.getNumber(this.qty).length == 0) {
            this.snackBar.open(this.translate.instant('all.qty_empty'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('qty');
        }
        else if (Utility.getNumber(this.qty) === 0) {
            this.snackBar.open(this.translate.instant('all.invalid_qty'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('qty');
        }
        // else if (!this.qty || !this.checkQty(this.qty)) {
        //     this.snackBar.open(this.translate.instant('all.invalid_qty'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('qty');
        // }
        else if (Utility.getNumber(this.price).length == 0) {
            this.snackBar.open(this.translate.instant('all.price_empty'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('price');
        }
        else if (Utility.getNumber(this.price) === 0) {
            this.snackBar.open(this.translate.instant('all.invalid_price'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('price');
        }
        // else if (!Utility.getNumber(this.price) || !this.checkPriceLength(this.price)){
        //     this.snackBar.open(this.translate.instant('all.invalid_price'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('price');
        // }
        else if ((this.floatSafeModulus(this.price, this.ticksize) !== 0)) {
            this.snackBar.open(this.translate.instant('all.pricemultiple') + this.ticksize + '.', this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            this.isError = true;
            this.onEnter('price');
        }
        // else if (this.trgType === "GTT_OCO" && !(this.price >= this.trgPrice)) {
        //     this.snackBar.open(this.translate.instant('prc_ge_trgprc'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
        //     this.isError = true;
        //     this.onEnter('price');
        // }
        else {
            this.isError = false;
        }

        if(!this.isError) {
            this.isDisableForm = true;
        }
    }

    onEdit() {
        this.isDisableForm = false;
    }

    onConfirm(){
        this.isEdit ? this.editTrigger() : this.createTrigger();
    }

    // checkQty(str) {
    //     const regex = new RegExp('^[0-9]{0,8}$|^[0-9]{1,' + 2 + '}$');
    //     return regex.test(str);
    // }

    // checkPriceLength(str) {
    //     const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    //     return regex.test(str);
    // }

    floatSafeModulus(val, step) {
        if (val && step)
        {
          const valDecCount = (val.toString().split('.')[1] || '').length;
          const stepDecCount = (step.toString().split('.')[1] || '').length;
          const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
          let valInt;
          if (val !== undefined && val !== null && val !== NaN)
            valInt = parseInt(Utility.getNumber(val).toFixed(decCount).replace(/\./g, ''), 10);
          const stepInt = parseInt(Utility.getNumber(step).toFixed(decCount).replace(/\./g, ''), 10);
          return (valInt % stepInt) / Math.pow(10, decCount);
        }
        else
          return 0;
    }
}