<app-titlebar *ngIf="!isMobile"  [title]="'login.contact_us' | translate"></app-titlebar>
<app-header *ngIf="isMobile" goback="1" [title]="'login.contact_us' | translate" [isReloadVisible]="false"></app-header>

<mat-card [class.mx-2]="isMobile">
    <mat-card-content>
        <!--div [class.mt-5]="isMobile" [class.p-0]="isMobile">
            <div class = "row ml-2">
                <div class="col-md-6  mt-4 text-center mb-2">
                    <i class="primetext icon-callus2 icon-large"></i><br/>
                    +91 98309 87777
                </div>
                <div class="col-md-6  mt-4 text-center mb-2">
                    <i class="primetext  icon-email icon-large"></i><br/> 
                    reach@finspot.in
                </div>
                <div class="col-md-12 mt-4 text-center">
                    <i class="primetext  icon-location icon-large"></i><br/>
                    <span class="fw-4">FinSpot Technology Solutions Pvt. Ltd.</span><br/><span>No. 16, 2nd 'A' Cross, SBI Colony 3rd Block
                          Koramangala,<br/> Bengaluru - 560 034</span>
                </div>
            </div>
        </div-->
        <div [class.mt-5]="isMobile" [class.p-0]="isMobile">
            <div class="row">
                <div class="col-sm-6">
                    <div class="card-contact">
                        <div class="iconabout">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.46 512"><path d="M232.48,390.32a10,10,0,1,0,14.14,0A10,10,0,0,0,232.48,390.32Z" transform="translate(0 0)"/><path d="M488.92,373,413,308.4a12.19,12.19,0,0,0-1.11-.82,49.69,49.69,0,0,0-62.19,6.83L308.3,355.8A10,10,0,0,1,297.41,358c-46.75-19.34-85.14-48.45-114.13-86.51-22.11-29-30.08-52.1-31.13-55.35a9.84,9.84,0,0,0-.6-2.06,10,10,0,0,1,2.17-10.89l41.39-41.4a49.69,49.69,0,0,0,6.82-62.19c-.1-.16-.2-.32-.31-.47L146.79,22.91a50,50,0,0,0-77.38-8.26l-8.29,8.29s0,0,0,0L31.51,52.55C-3.78,87.84-9.55,140.88,14.82,205.92c20.88,55.72,62.66,116.7,117.67,171.7,56.3,56.31,118.93,98.87,176.35,119.85C365.66,518.23,426.11,520.79,468.9,478l27.91-27.9A50,50,0,0,0,488.92,373Zm-88.31-48.85,75.85,64.5a9.93,9.93,0,0,0,1.11.82A30,30,0,0,1,482.66,436L480.59,438,351.21,341.16l12.63-12.61A29.8,29.8,0,0,1,400.61,324.11ZM130.08,33.89c.1.16.21.32.32.48l54.8,76.16A29.8,29.8,0,0,1,181,147.62l-12.23,12.24L81.21,31.14l2.34-2.35A30,30,0,0,1,130.08,33.89ZM315.7,478.69c-54.74-20-114.78-60.92-169.07-115.21-53-53-93.19-111.47-113.09-164.57C18.61,159.05,8.59,103.75,45.65,66.7L66.81,45.53l87.53,128.72L139.58,189a29.89,29.89,0,0,0-6.85,31.84c3.16,12.95,17.68,39.84,32.34,59.65,21.56,29.15,60.7,69.46,124.69,95.94a29.91,29.91,0,0,0,32.68-6.51l14.49-14.49,129.38,96.87-11.55,11.54C415,503.6,357.21,493.86,315.7,478.69Z" transform="translate(0 0)"/><path d="M213.72,366.45c-10.56-9.26-28.83-25.19-38.45-35.09-23.6-24.25-48.35-53.64-63.07-74.87a10,10,0,1,0-16.44,11.39c18.56,26.78,45.84,57.55,65.17,77.42,10.16,10.45,28.58,26.52,39.6,36.18a10,10,0,0,0,13.19-15Z" transform="translate(0 0)"/></svg>
                        </div>
                        <h3>Calling Support</h3>
                        <p><small>MON-FRI</small><br><a href="tel:9830987777">+91 98309 87777</a></p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card-contact">
                        <div class="iconabout">
                            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 390"><path d="M467,61H45A45.08,45.08,0,0,0,0,106V406a45.07,45.07,0,0,0,45,45H467a45.07,45.07,0,0,0,45-45V106A45.07,45.07,0,0,0,467,61Zm-6.21,30L257,294.83,51.36,91ZM30,399.79V112.07L174.48,255.31ZM51.21,421,195.78,276.43l50.66,50.22a15,15,0,0,0,21.17,0L317,277.21,460.79,421ZM482,399.79,338.21,256,482,112.21Z" transform="translate(0 -61)"/></svg>
                        </div>
                        <h3>Email Information</h3>
                        <p><small>24X7 (reply in 12hr)</small><br><a href="mailto:reach@finspot.in">reach@finspot.in</a></p>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="card-contact">
                        <div class="iconabout">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.14 512">
                                <path d="M494.43,216.16a10,10,0,0,0-8.39-2L333.43,246.81l-17.12-3.66,45.16-90.46a4.54,4.54,0,0,0,.21-.45,108.59,108.59,0,1,0-198.9,0c.06.15.13.3.2.45l32.24,64.55-14.42-3.08c-.13,0-.26,0-.4-.06l-.58-.1-.53,0-.58,0-.55,0-.56,0-.56.09-.42.07L21.91,247.26A10,10,0,0,0,14,257v82.48a10,10,0,0,0,20,0v-74.4L168.71,236.3V460.81L34,489.63V419.52a10,10,0,0,0-20,0V502a10,10,0,0,0,10,10,9.83,9.83,0,0,0,2.09-.22l152.62-32.66,152.62,32.66.17,0c.22.05.44.08.66.11l.3,0c.32,0,.64.05,1,.05s.64,0,.95-.05l.3,0c.23,0,.45-.06.67-.11l.17,0,154.71-33.1a10,10,0,0,0,7.91-9.78V386.42a10,10,0,1,0-20,0v74.39L343.43,489.63V265.12L478.14,236.3v70.12a10,10,0,0,0,20,0V223.94A10,10,0,0,0,494.43,216.16ZM173.64,108.59A88.59,88.59,0,1,1,343.47,144L262.23,306.69,181,144A88,88,0,0,1,173.64,108.59ZM188.71,236.3l17.94,3.84,46.64,93.4a10,10,0,0,0,17.89,0l35.9-71.91,16.35,3.49V489.63L188.71,460.81V236.3Z" transform="translate(-14)"/>
                                <path d="M326.53,108.59a64.3,64.3,0,1,0-64.3,64.3A64.37,64.37,0,0,0,326.53,108.59Zm-108.6,0a44.31,44.31,0,1,1,44.3,44.3A44.35,44.35,0,0,1,217.93,108.59Z" transform="translate(-14)"/>
                                <path d="M24,389.52a10,10,0,1,0-10-10A10,10,0,0,0,24,389.52Z" transform="translate(-14)"/>
                                <path d="M488.14,336.42a10,10,0,1,0,10,10A10,10,0,0,0,488.14,336.42Z" transform="translate(-14)"/></svg>
                        </div>
                        <h3>Office location</h3>
                        <p>FinSpot Technology Solutions Pvt. Ltd.<br>
                            No. 16, 2nd 'A' Cross, SBI Colony 3rd Block
                          Koramangala, Bengaluru - 560 034</p>
                    </div>
                </div>
            </div>
        <!----></div>
    </mat-card-content>
</mat-card>
