import { Component, OnInit } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { OptioPayoffChartHelper } from './optionpayoffchart.helper';
import * as crosshair from 'chartjs-plugin-crosshair';

let optionchainanalysis: OptionChainAnalysisComponent;
@Component({
    selector: 'app-optionchainanalysis',
    templateUrl: './optionchainanalysis.component.html'
})
export class OptionChainAnalysisComponent implements OnInit {
    optiondata = [];
    chartData;
    chartLabels = [];
    chartType = 'line';
    style;
    chartOptions;
    label: number;
    isMobile: boolean;
    constructor() {
        optionchainanalysis = this;
        optionchainanalysis.optiondata = Utility.dialogData;
        optionchainanalysis.isMobile = Utility.IS_MOBILE;
        // optionchainanalysis.chartLabels = OptioPayoffChartHelper.getStrikePriceArr();
    }

    ngOnInit()
    {
        const element = document.querySelector('.chart-color7');
        this.style = getComputedStyle(element);
        this.chartOptions = {responsive: true,
            scales: {
                yAxes: [{
                    display: true,
                    type: 'linear',
                    id: 'y-axis-0',
                    ticks: {
                        fontColor: this.rgb2hex(this.style.floodColor),
                        callback: function (label, index, dataset) {
                            return Number(label);
                        }
                    },
                    gridLines: { lineWidth: 1, color: this.rgb2hex(this.style.fill), zeroLineColor: this.rgb2hex(this.style.borderColor) },
                }],
                xAxes: [{
                    display: true,
                    type: 'linear',
                    id: 'x-axis-0',
                    ticks: {
                        fontColor: this.rgb2hex(this.style.floodColor),
                        callback: function (label, index, dataset) {
                            const lbl = Number(label).toFixed(2).toString();
                            this.label = lbl.substring(lbl.length - 2);
                            if (Number(this.label / 10) > 0){

                                return Number(label).toFixed(2);
                            }
                            else{
                                return Number(label);
                            }
                        }
                    },
                    gridLines: { lineWidth: 1,  color: this.rgb2hex(this.style.fill), zeroLineColor: this.rgb2hex(this.style.borderColor) }
                }]
    
            },
            plugins: {
                crosshair: {
                    line: {lightingColor: this.rgb2hex(this.style.columnRuleColor)},
                    sync: {enabled: false},
                    zoom: {enabled: false}
                }, 
                datalabels: false 
            },
            legend: {
                display: false
            },
            tooltips: {
                mode: 'nearest',
                intersect: false,
                backgroundColor: this.rgb2hex(this.style.borderColor),
                titleFontColor: this.rgb2hex(this.style.fill),
                callbacks: {
                    title: function (a, d) {
                        // return a[0].xLabel.toFixed(2);
                        return 'Strike Price: ' + Number(a[0].xLabel).toFixed(2);
                    },
                    label: function (i, d) {
                        return (
                            'PNL: ' + Number(i.yLabel).toFixed(2)
                        );
                    },
                    labelColor: function(tooltipItem, chart) {
                        return {
                            borderColor: this.rgb2hex(this.style.lightingColor),
                            backgroundColor: this.rgb2hex(this.style.outlineColor)
                        };
                    }.bind(this),
                    labelTextColor: function(tooltipItem, chart) {
                        return this.rgb2hex(this.style.fill);
                    }.bind(this)
                }
            },
            elements : { line : { tension : 0 }, point: { radius: 0} }
        };
        this.chartData = [{'data': [], 'label': 'yAxis', 'fill': false, 'borderColor': this.rgb2hex(this.style.outlineColor), 'lineTension': 0}]

        optionchainanalysis.chartData[0]['data'] = OptioPayoffChartHelper.calculatePayOff();
        optionchainanalysis.chartOptions.scales.xAxes[0].ticks['min'] = OptioPayoffChartHelper.min;
        optionchainanalysis.chartOptions.scales.xAxes[0].ticks['max'] = OptioPayoffChartHelper.max;
        optionchainanalysis.chartOptions.scales.xAxes[0].ticks['stepSize'] = OptioPayoffChartHelper.difference;
    }

    rgb2hex(rgb) {
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? '#' +
            ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
    }
}
