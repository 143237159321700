import { Utility } from "../../../../../services/utility";

export class OptioPayoffChartHelper
{
    static min;
    static max;
    static difference;
    constructor()
    {
        
    }

    static calculatePayOff()
    {
        const optionsArr = Utility.dialogData;
        const strikePriceArr = OptioPayoffChartHelper.getStrikePriceArr();
        const payOffArr = [];
        //optionType, premium, lot, qty, ltp, 
        for (let i = 0; i < optionsArr.length; i++)
        {
            const obj = optionsArr[i];
            if (obj.callqtycount > 0)
            {
                const effectivePrice = Utility.getNumber(obj.strikeprice) + Utility.getNumber(obj.callscrip.LastTradePrice);
                for (let j = 0; j < strikePriceArr.length; j++)
                {
                    const sp = strikePriceArr[j];
                    const pnl = (Utility.getNumber(sp) - Utility.getNumber(effectivePrice)).toFixed(obj.callscrip.DecimalPrecision);
                    const cb = Utility.getNumber(pnl) >= Utility.getNumber((-1 *Utility.getNumber(obj.callscrip.LastTradePrice))) ? Utility.getNumber(pnl): (-1 * Utility.getNumber(obj.callscrip.LastTradePrice));
                    const cbv = cb * obj.callqtycount;
                    if (payOffArr[j])
                        payOffArr[j] += cbv;
                    else
                        payOffArr[j] = cbv;
                }
            }
            else if (obj.callqtycount < 0)
            {
                const effectivePrice = Utility.getNumber(obj.strikeprice) - Utility.getNumber(obj.callscrip.LastTradePrice);
                for (let j = 0; j < strikePriceArr.length; j++)
                {
                    const sp = strikePriceArr[j];
                    const pnl = (Utility.getNumber(effectivePrice) - Utility.getNumber(sp)).toFixed(obj.callscrip.DecimalPrecision);
                    const cs = Utility.getNumber(pnl) <= (Utility.getNumber(obj.callscrip.LastTradePrice)) ? Utility.getNumber(pnl): (Utility.getNumber(obj.callscrip.LastTradePrice));
                    const csv = Utility.getNumber(cs) * (-1 * Utility.getNumber(obj.callqtycount));
                    if (payOffArr[j])
                        payOffArr[j] += csv;
                    else
                        payOffArr[j] = csv;
                }
            }

            if (obj.putqtycount > 0)
            {
                const effectivePrice = Utility.getNumber(obj.strikeprice) - Utility.getNumber(obj.putscrip.LastTradePrice);
                for (let j = 0; j < strikePriceArr.length; j++)
                {
                    const sp = strikePriceArr[j];
                    const pnl = (Utility.getNumber(effectivePrice) - Utility.getNumber(sp)).toFixed(obj.callscrip.DecimalPrecision);
                    const pb = Utility.getNumber(pnl) >= (-1 *Utility.getNumber(obj.putscrip.LastTradePrice)) ? Utility.getNumber(pnl): (-1 * Utility.getNumber(obj.putscrip.LastTradePrice));
                    const pbv = Utility.getNumber(pb) * (Utility.getNumber(obj.putqtycount));
                    if (payOffArr[j])
                        payOffArr[j] += pbv;
                    else
                        payOffArr[j] = pbv;
                }
            }
            else if (obj.putqtycount < 0)
            {
                const effectivePrice = Utility.getNumber(obj.strikeprice) + Utility.getNumber(obj.putscrip.LastTradePrice);
                for (let j = 0; j < strikePriceArr.length; j++)
                {
                    const sp = strikePriceArr[j];
                    const pnl = (Utility.getNumber(sp) - Utility.getNumber(effectivePrice)).toFixed(obj.callscrip.DecimalPrecision);
                    const ps = Utility.getNumber(pnl) <= (Utility.getNumber(obj.putscrip.LastTradePrice)) ? Utility.getNumber(pnl): (Utility.getNumber(obj.putscrip.LastTradePrice));
                    const psv = Utility.getNumber(ps) * (-1 * Utility.getNumber(obj.putqtycount));
                    if (payOffArr[j])
                        payOffArr[j] += psv;
                    else
                        payOffArr[j] = psv;
                }
            }
        }

        let arr = [];
        for (var i = 0; i < strikePriceArr.length; i++)
        {
            arr.push({'x': strikePriceArr[i], "y": payOffArr[i]});
        }
        return arr;
    }

    static getStrikePriceArr()
    {
        const optionsArr = Utility.dialogData;
        let arr = [];
        for (let i = 0; i < optionsArr.length; i++)
        {
            arr.push(optionsArr[i].strikeprice)
        }
        let diff = arr[1] - arr[0];
        OptioPayoffChartHelper.difference = diff;
        let minValue = Utility.getNumber((Utility.getNumber(arr[0]) - (0.2 * Utility.getNumber(arr[0]))).toFixed(2));
        let maxValue = Utility.getNumber((Utility.getNumber(arr[arr.length - 1]) + (0.2 * Utility.getNumber(arr[arr.length - 1]))).toFixed(2));
        let tempMin = Utility.getNumber(arr[0]) - diff;
        let tempMax = Utility.getNumber(arr[arr.length - 1]) + diff;
        let minArr = [];
        while(tempMin > minValue)
        {
            minArr.push(tempMin.toFixed(2));
            tempMin -= diff;
        }
        minArr = minArr.reverse();
        arr = minArr.concat(arr);
        while(tempMax < maxValue)
        {
            arr.push(tempMax.toFixed(2));
            tempMax += diff;
        }
        OptioPayoffChartHelper.min = arr[0];
        OptioPayoffChartHelper.max = arr[arr.length - 1];
        return arr;
    }
}