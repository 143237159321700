<div class="row" [ngStyle]="Height">
    <div class="col-12">
        <div class="p-2">
            <div class="row subheader-menu" *ngIf='!isNoData'>
                <div class="col-12 panelsubheader">
                    <mat-form-field>
                        <mat-select (selectionChange)="onSelection($event)" [(value)]="selectedVal">
                            <mat-option *ngFor="let val of  keys" [value]="val">
                                {{val}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <div *ngFor = "let data of dataSource">
                    <div class="row watchlistwrapper">
                        <div class="col-7 line2">{{data.DisplayName}}</div>
                        <div class="col-5 text-right">{{data.Value | numberpipe}}</div>
                    </div>
                </div>
                <div *ngFor = "let data of dataSource1">
                    <div class="row watchlistwrapper">
                        <div class="col-7 line2">{{data.DisplayName}}</div>
                        <div class="col-5 text-right">{{data.Value | numberpipe}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf='isNoData'>
        <app-nodata message = "No Data"></app-nodata>
</div>