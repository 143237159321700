import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
let updateuserinfoComponent: UpdateuserinfoComponent;
@Component({
  selector: 'app-updateuserinfo',
  templateUrl: './updateuserinfo.component.html'
})
export class UpdateuserinfoComponent implements OnInit {
  email = '';
  mob = '';
  uid = '';
  pwd = '';
  constructor(public dialogRef: MatDialogRef<UpdateuserinfoComponent>, private snackBar: MatSnackBar, private translate: TranslateService, private tradingRestService: TradingRestService) {
    updateuserinfoComponent = this;
   }

  ngOnInit(): void {
  }

  close() {
    updateuserinfoComponent.dialogRef.close();
  }
  submit() {
      if (updateuserinfoComponent.email === ''){
        updateuserinfoComponent.snackBar.open(updateuserinfoComponent.translate.instant('login.enter_email'), '', { duration: 3000 });
      } else if (updateuserinfoComponent.mob === '') {
        updateuserinfoComponent.snackBar.open(updateuserinfoComponent.translate.instant('login.valid_number'), '', { duration: 3000 });
      } else {
        updateuserinfoComponent.tradingRestService.getLoginService().updateUserInfo(updateuserinfoComponent.uid, updateuserinfoComponent.pwd,
          updateuserinfoComponent.email, updateuserinfoComponent.mob, updateuserinfoComponent.updateUserInfoResponse);
      }
  }

  updateUserInfoResponse(data, code, message) {
    updateuserinfoComponent.snackBar.open(updateuserinfoComponent.translate.instant(message), '', { duration: 3000 });
    updateuserinfoComponent.close();
  }

}
