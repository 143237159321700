import { ChartData, ChartMetaInfo, IExternalSaveLoadAdapter, StudyTemplateData, StudyTemplateMetaInfo } from "src/assets/charting_library/charting_library";

let saveloadadapter: SaveLoadAdapterClass;
export class SaveLoadAdapterClass implements IExternalSaveLoadAdapter
{
    studylist = [];
    loadPrefFlag: false;
    studycallback;
    study_promise_failure;
    constructor()
    { 
        saveloadadapter = this;
    }
    getAllCharts(): Promise<ChartMetaInfo[]> {
        throw new Error("Method not implemented.");
    }
    removeChart<T extends string | number>(id: T): Promise<void> {
        throw new Error("Method not implemented.");
    }
    saveChart(chartData: ChartData): Promise<number> {
        throw new Error("Method not implemented.");
    }
    getChartContent(chartId: number): Promise<string> {
        throw new Error("Method not implemented.");
    }

    getAllStudyTemplates(): Promise<StudyTemplateMetaInfo[]> {
        saveloadadapter.studylist = saveloadadapter.getStudyList();
        console.log('studylist: ' + saveloadadapter.studylist);
        if(saveloadadapter.studylist.length > 0)
        {
            console.log('call with resolve');
            return Promise.resolve(saveloadadapter.studylist);
        }
        else
        {
            return Promise.resolve([]);
        }
    }
    removeStudyTemplate(studyTemplateInfo: StudyTemplateMetaInfo): Promise<void> {
        saveloadadapter.studylist = saveloadadapter.getStudyList();
        for(let i=0; i < saveloadadapter.studylist.length; i++)
        {
            if(saveloadadapter.studylist[i]['name'] == studyTemplateInfo['name'])
            {
                saveloadadapter.studylist.splice(i, 1);
                break;
            }
        }
        saveloadadapter.setStudyList(saveloadadapter.studylist);
        return Promise.resolve();
    }
    saveStudyTemplate(studyTemplateData: StudyTemplateData): Promise<void> {
        saveloadadapter.studylist = saveloadadapter.getStudyList();
        let isEdit = false;
        for(let i=0; i < saveloadadapter.studylist.length; i++)
        {
            if(saveloadadapter.studylist[i]['name'] == studyTemplateData['name'])
            {
                saveloadadapter.studylist[i]['content'] = studyTemplateData['content'];    
                isEdit = true;
                break;
            }
        }
        if(!isEdit)
            saveloadadapter.studylist.push(studyTemplateData);

        saveloadadapter.setStudyList(saveloadadapter.studylist);
        return Promise.resolve();
    }

    getStudyTemplateContent(studyTemplateInfo: StudyTemplateMetaInfo): Promise<string> {
        saveloadadapter.studylist = saveloadadapter.getStudyList();
        console.log('get template: ' + studyTemplateInfo['name']);
        for(let i=0; i < saveloadadapter.studylist.length; i++)
        {
            if(saveloadadapter.studylist[i]['name'] == studyTemplateInfo['name'])
            {
                console.log('apply template');
                return Promise.resolve(saveloadadapter.studylist[i]['content']);    
            }
        }
        return Promise.resolve("");
    }

    getDrawingTemplates(toolName: string): Promise<string[]> {
        throw new Error("Method not implemented.");
    }
    loadDrawingTemplate(toolName: string, templateName: string): Promise<string> {
        throw new Error("Method not implemented.");
    }
    removeDrawingTemplate(toolName: string, templateName: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
    saveDrawingTemplate(toolName: string, templateName: string, content: string): Promise<void> {
        throw new Error("Method not implemented.");
    }


    getStudyList()
    {
        let chart_data = localStorage.getItem("StudyTemplate");
        if(chart_data)
        {
            chart_data = JSON.parse(chart_data);
            console.log(chart_data);
            if(chart_data['templates'])
                saveloadadapter.studylist = chart_data["templates"];
            else
                saveloadadapter.studylist = [];
        }
        else
        {
            saveloadadapter.studylist = [];
        }

        console.log(saveloadadapter.studylist);
        return saveloadadapter.studylist;
    }

    setStudyList(studylist)
    {
        let chart_data: any = localStorage.getItem("StudyTemplate");
        if(chart_data)
        {
            chart_data = JSON.parse(chart_data);
        }
        else
        {
            chart_data = {};
        }
        chart_data['templates'] = studylist;
        localStorage.setItem("StudyTemplate", JSON.stringify(chart_data))  
    }

}
