import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Preferences } from "src/app/services/Preferences";
import { TradingRestService } from "src/app/services/tradingrest.service";
import { Url } from "src/app/services/url";
import { Utility } from "src/app/services/utility";
import { environment } from "src/environments/environment";
import { Location } from '@angular/common';
import { ActivatedRoute } from "@angular/router";

declare const moment: any;
@Component({
    selector: 'app-createemandate',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/createemandate/createemandate.component.html',
})

export class CreateEmandateComponent implements OnInit {

    isMobile: boolean;
    name: string;
    email: string;
    mobile: number;
    startDate;
    frequencyMap = {'daily':'DAIL', 'Weekly':'WEEK', 'Monthly':'MNTH', 'Bi-Monthly':'BINM', 'Quarterly':'QURT', 'Half Yearly':'MIAN', 'Yearly':'YEAR'};
    frequency: string;
    amount = 0.00;
    bankDetail = [];
    selectedActNo: number;
    accountTyp = {'Saving':'S', 'Current':'C', 'Other':'O'};
    selectedActTyp = 'Saving';
    ifscCode: string;
    objectKeys = Object.keys;
    resData: any;
    sipData = {};
    emandateFrom: string;
    bnkName = '';
    isLoading: boolean;
    hostName;

    constructor(private tradingRestService: TradingRestService, private snackBar: MatSnackBar,  private translate: TranslateService, private location: Location, private activatedRoute: ActivatedRoute) {
        this.isMobile = Utility.IS_MOBILE;
        if (Utility.IS_MOBILE) {
            this.activatedRoute.queryParams.subscribe(params => {
                this.emandateFrom = params.emandateFrm;
            });
        } else {
            this.emandateFrom = Utility.dialogData.emandateFrm;
        }
        this.hostName = window.location.hostname;

    }

    ngOnInit() {
        if(this.emandateFrom === 'basketSip') {
            this.sipData = Utility.selectedSipData;
        } else if(this.emandateFrom === 'sipBsktOrdersum') {
            this.sipData = Utility.placedSipData['data'];
        }
        if(this.sipData === undefined) {
            this.location.back();
        } else {
            let userData = Utility.CACHE_STORE.getCacheData(Url.HS_USER_DETAILS + '_' + Preferences.getPreference('userid'));
            if(userData === undefined) {
                this.tradingRestService.getUserService().getUserProfile(function(data, code, msg) {
                    if (code === 200 && msg === 'OK') {
                        userData = data;
                        this.getData(userData);
                    }
                }.bind(this));
            } else {
                this.getData(userData);
            } 
        }
    }

    getData(data) {
        this.name = data['accountName'];
        this.email = data['email'];
        this.mobile = data['cellAddr'];
        this.bankDetail = data['bnkDtl'];
        this.selectedActNo = data['bnkActNo'];
        this.ifscCode = data['bnkIfscCode'];
        this.bnkName = data['bnkName'];
        this.frequency = this.sipData['sip_frequency'];
        this.amount = Math.ceil(this.sipData['sip_amount'] * 2);
        this.setDate();
    }

    setDate() {
        this.startDate = moment(this.sipData['next_trigger_date']).subtract(1, 'day').format('Y-MM-DD');
        if(moment().isSame(this.startDate) || moment().isAfter(this.startDate)) {
            if(this.sipData['sip_frequency'] === 'Monthly') {
                this.startDate = moment(this.sipData['next_trigger_date']).add(1, 'M').subtract(1, 'day').format('Y-MM-DD');
            } 
            else if(this.sipData['sip_frequency'] === 'Quarterly') {
                this.startDate = moment(this.sipData['next_trigger_date']).add(4, 'M').subtract(1, 'day').format('Y-MM-DD');
            } 
            else if(this.sipData['sip_frequency'] === 'Weekly') {
                this.startDate = moment(this.sipData['next_trigger_date']).add(6, 'day').format('Y-MM-DD');
            }
        }
    }

    updateStartDate(startvalue) {
        this.startDate =  moment(new Date(startvalue)).format('Y-MM-DD');
    }

    selectIfscCode() {
        this.bankDetail.forEach((element) => {
            if(element['bnkActNo'] === this.selectedActNo) {
                this.ifscCode = element['bnkIfscCode'];
            }
        });
    }

    onEnter(id) {
        document.getElementById(id).focus();
    }

    validatePrice(ev) {
        const regex = new RegExp('^[0-9]{0,}$');
        const index = ev.target.selectionStart;
        const value = this.amount.toString();
        if (!value.match(regex)) {
            this.validateKey(ev.data, 'price');
            ev.srcElement.value = value.substring(0, index - 1) + value.substring(index, value.length);
            ev.path[0].dispatchEvent(new Event('input'));
            ev.path[0].setSelectionRange(index - 1, index - 1);
        }
    }

    validateKey(key, str) {
        if (/[a-zA-Z]/i.test(key)) {
            this.snackBar.open(this.translate.instant('all.only_digits'), this.translate.instant('all.close'), { duration: 2000});
        }
    }

    checkValidation() {
        if(this.ifscCode === '' || this.ifscCode === 'NA') {
            this.snackBar.open(this.translate.instant('all.add_details'), '', {duration: 2000});
        }
        else if(Utility.getNumber(this.amount).length == 0) {
            this.snackBar.open(this.translate.instant('all.amount_empty'), this.translate.instant('all.close'), { duration: 2000});
            this.onEnter('amount');
        } 
        else if(this.amount == 0) {
            this.snackBar.open(this.translate.instant('all.valid_amount'), this.translate.instant('all.close'), { duration: 2000});
            this.onEnter('amount');
        } 
        else {
            this.emandateMsgId();
        }
    }

    emandateMsgId() {
        const obj = {
            "name": this.name,
            "email": this.email,
            "mobile": this.mobile,
            "account_number": this.selectedActNo,
            "debit_amount": Number(this.amount).toFixed(2),
            "debit_freq": this.frequencyMap[this.frequency],
            "cust_sequence": "RCUR",
            "remark": this.sipData['basket_name'],
            "start_date": this.startDate,
            "prod_id": this.sipData['basket_id'],
            "prod_type": "INVESTMENT BOX",
            "bank": this.bnkName,
            "ifsc": this.ifscCode
        }
        this.isLoading = true;
        this.tradingRestService.getUserService().getEmandateMsgId(obj, function(data, code, msg) {
            if(code === 200) {
                this.authorizeEmandate(data.msg_id);
            } else {
                this.snackBar.open(msg, '', {duration: 2000});
            }
        this.isLoading = false;
        }.bind(this));
    }

    authorizeEmandate(msg_id) {
        let jsonObj ;
        jsonObj = {  
            'msgId' : msg_id, 
            'cust_name' : this.name,
            'mob' : this.mobile,
            'email' : this.email,
            'actno' : this.selectedActNo,
            's_date' : this.startDate,
            'cust_Damt' : Number(this.amount).toFixed(2),
            'cust_Dfreq' : this.frequencyMap[this.frequency],
            'ifsc' : this.ifscCode,
            'shortCode' : environment.emandate.shortCode,
            'custSeqType' : 'RCUR',
            'merchantCategoryCode' : environment.emandate.merchantCategoryCode,
            'channel' :  environment.emandate.channel,
            'utilCode' : environment.emandate.utilCode,
            'acc_type' : this.accountTyp[this.selectedActTyp],
            'aesKey': environment.emandate.aesKey,
            'url': environment.emandate.url
        }
        Utility.openEmandate(jsonObj, this.hostName);
    }
}