<app-header (onTitleClick)="onTitleClick()" [goback]="goback" [title]="'more.equity_news' | translate" [isLoading]="isLoading" [isSearch] = "isSearch" [freetrial] =  "true" (reloadPage) = "reloadData()"></app-header>
<div #sectionNeedToScroll class="data-container">
	<!-- <div class="row">
		<div class="col-12 pl-0">
			<mat-chip-list>
				<mat-chip *ngIf = "betaFeature" (click)= "onTabChanged(1)" [class.imactive] = "tabIndex ===  1" translate>equity.topmarket</mat-chip>
                <mat-chip *ngIf = "betaFeature" (click)= "onTabChanged(2)" [class.imactive] = "tabIndex ===  2" translate>equity.indices</mat-chip>
                <mat-chip  *ngIf = "betaFeature" (click)= "onTabChanged(3)" [class.imactive] = "tabIndex ===  3" translate>equity.marketmood</mat-chip>
                <mat-chip *ngIf = "defaultFeature" (click)= "onTabChanged(4)" [class.imactive] = "tabIndex ===  4" translate>dashboard.news_updates</mat-chip>
			</mat-chip-list>
		</div>
	</div> -->
	<div class="row">
		<div class="col-12">
			<ng-container *ngxCacheIf = "tabIndex === 1">
				<app-top-market (loaderEvent)="loaderEvent($event)" [reloadData] = "reloadEquityNews"></app-top-market>
			</ng-container>
			<!-- <ng-container *ngxCacheIf = "tabIndex === 2">
				<app-indices-market (loaderEvent)="loaderEvent($event)"></app-indices-market>
			</ng-container> -->
			<ng-container *ngxCacheIf = "tabIndex === 3">
				<app-market-mood (loaderEvent)="loaderEvent($event)"></app-market-mood>
			</ng-container>
			<ng-container *ngxCacheIf = "tabIndex === 4">
				<app-tagspecificnews tagtype="Equity" (loaderEvent)="loaderEvent($event)" [reloadData] = "reloadEquityNews"></app-tagspecificnews>
			</ng-container>			
		</div>
	</div>
</div>