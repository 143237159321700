import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { IScannerService } from './iscanner.service';
import { Url } from '../url';
import { Keys } from '../keys';
import { CacheContainer } from '../../cachecontainer/cachecontainer';
import { ScripModel } from 'src/app/model/scrip.model';
import { ChannelType, Utility } from '../utility';
import { TradingRestService } from '../tradingrest.service';
import { Preferences } from '../Preferences';

let scannerSerivce: ScannerService;
let tradingRest: TradingRestService;
@Injectable()

export class ScannerService implements IScannerService {
    constructor(private tradingRestService: TradingRestService, private apiService: ApiService) {
        scannerSerivce = this;
        tradingRest = tradingRestService;
    }
    getNQDerivativeScanner(dataObj, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const scripModelArr = new Array<ScripModel>();
        const scripTempList = [];
        return scannerSerivce.apiService.ptpluspost(Url.DERIVATIVE_SCANNER, dataObj).subscribe((res) => {
            res = JSON.parse(JSON.stringify(res));
            if (res['code'] === Keys.RES_CODE_SUCCESS && Object.keys(res.data).length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    const value = res['data'][i];
                    const bean = Utility.getScripModel(value['omtkn'], value['exseg'], tradingRest, true, ChannelType.Scanner);
                    if (dataObj.data_type === 'neutral' && dataObj.scanner_type === 'CALL') {
                        bean.TradingSymbol = value['tsym_call'];
                        bean.Token = value['omtkn_call'];
                    } else if (dataObj.data_type === 'neutral' && dataObj.scanner_type === 'PUT') {
                        bean.TradingSymbol = value['tsym_put'];
                        bean.Token = value['omtkn_put'];
                    } else {
                        bean.TradingSymbol = value['tsym'];
                        bean.Token = value['omtkn'];
                    }
                    bean.ExchangeSegment = value['exseg'];
                    bean.CompanyName = value['tcnam'];
                    bean.ExpiryDate = value['expry'];
                    scripTempList.push(bean.ExchangeSegment + '|' + bean.Token);
                    scripModelArr.push(bean);
                }
                callback(scripModelArr, code, message);
                const count = scripTempList.length;
                if (count > 0) {
                    const scripListObj = {
                        'uid': Preferences.getPreference('userid'),
                        'tomcatCount': Preferences.getPreference('tomcatcount'),
                        'scripList': scripTempList.toString()
                    };
                    tradingRest.getScripService().getMultipleScrips(scripListObj, (data, resCode, resMessage) => {});
                }
            }
            else {
                message = res.message;
                code = Keys.RES_CODE_FAILURE;
                callback([], code, message);
            }
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback([], code, message);
        });
    }

    getAllIndexName(callback: Function) {
        let code;
        let message = '';
        const data = { 'exchange_seg': 'nse_cm' };
        const cachedData = CacheContainer.IndexNames['indexName'];
        if (!cachedData) {
            return scannerSerivce.apiService.ptpluspost(Url.NSE_BSE_INDEX, data).subscribe((result) => {
                if (result.status === 'SUCCESS' && result.code === 200) {
                    message = Keys.RES_MSG_OK;
                    code = Keys.RES_CODE_SUCCESS;
                    if (CacheContainer.caching) {
                        CacheContainer.IndexNames['indexName'] = result.data;
                    }
                }
                else {
                    code = Keys.RES_CODE_FAILURE;
                }
                callback(code, result.data, message);
            });
        }
        else {
            callback(200, cachedData, 'OK');
        }
    }

    getScannersDataByIndexName(data, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const scripModelArr = new Array<ScripModel>();
        const scripTempList = [];
        return scannerSerivce.apiService.ptpluspost(Url.SCANNER_INDEX_URL, data).subscribe((res) => {
            res = JSON.parse(JSON.stringify(res));
            if (res['code'] === Keys.RES_CODE_SUCCESS && Object.keys(res.data).length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    const value = res['data'][i];
                    const bean = Utility.getScripModel(value['omnitkn'], value['exseg'], tradingRest, true, ChannelType.Scanner);
                    bean.TradingSymbol = value['tsym'];
                    bean.ExchangeSegment = value['exseg'];
                    bean.Token = value['omnitkn'];
                    bean.CompanyName = value['cname'];
                    bean.PercentageChange = value['pctchg'];
                    bean.YearlyHighPrice = value['yrhi'];
                    bean.YearlyLowPrice = value['yrlo'];
                    bean.OpenPrice = value['open'];
                    bean.High = value['high'];
                    bean.Low = value['low'];
                    bean.TradeVolume = value['vol'];
                    bean.Change = value['netchng'];
                    bean.LastTradePrice = value['lst'];
                    bean.PrvClose  = value['cls'];
                    bean.HCircuitLimit = value['uplimit'];
                    bean.LCircuitLimit = value['lolimit'];
                    bean.ResistanceOne=value['r1'];
                    bean.ResistanceTwo=value['r2'];
                    bean.ResistanceThree=value['r3'];
                    bean.SupportOne=value['s1'];
                    bean.SupportTwo=value['s2'];
                    bean.SupportThree=value['s3'];
                    bean.Pivot=value['pp'];
                    bean.wkhigh=value['yrhi'];
                    bean.wklow=value['yrlo'];
                    bean.dmaFifty=value['close50'];
                    bean.dmaTwoHundred=value['close200'];
                    bean.macd=value['macd'];
                    bean.signalLine=value['slin'];
                    bean.rsi=value['rsi'];
                    bean.pltp1=value['pltp1'];
                    bean.pltp2=value['pltp2'];
                    bean.pvol1=value['pvol1'];
                    bean.pvol2=value['pvol2'];
                    bean.ExpiryDate = value['datetym'];
                    scripTempList.push(value['exseg'] + '|' + value['omnitkn']);
                    scripModelArr.push(bean);
                }
                callback(scripModelArr, code, message);
                const count = scripTempList.length;
                if (count > 0) {
                    const scripListObj = {
                        'uid': Preferences.getPreference('userid'),
                        'tomcatCount': Preferences.getPreference('tomcatcount'),
                        'scripList': scripTempList.toString()
                    };
                    tradingRest.getScripService().getMultipleScrips(scripListObj, (result, resCode, resMessage) => {});
                }
            }
            else {
                message = res.message;
                code = Keys.RES_CODE_FAILURE;
                callback([], code, message);
            }
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback([], code, message);
        });
    }
}
