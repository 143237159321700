import { Component, ElementRef, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseComponentDirective } from "src/app/goldenlayout/base-component.directive";
import { PtPlusRestService } from "src/app/services/ptplusrest.service";
import { TradingRestService } from "src/app/services/tradingrest.service";
import { Utility } from "src/app/services/utility";
import { environment } from "src/environments/environment";
import { Location } from '@angular/common';


let sipupdate: SipupdateComponent;
@Component({
    selector: 'app-sipupdate',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/sipupdate/sipupdate.component.html'
})
export class SipupdateComponent extends BaseComponentDirective implements OnInit{
  isMobile: boolean;
  isLoading = false;
  updateDisable = false;
  sipdata;
  sip_day = [];
  sip_frequency = [];
  selectedFrequency;
  selectedDay;
  
    constructor(elRef: ElementRef, private PS: PtPlusRestService, private snackBar: MatSnackBar, private location: Location) {
        super(elRef.nativeElement);
        sipupdate = this;
        sipupdate.isMobile = Utility.IS_MOBILE;
        sipupdate.sipdata = Utility.selectedSipData;
        if(sipupdate.sipdata === undefined){
            sipupdate.location.back();
        } else {
            sipupdate.selectedFrequency =  sipupdate.sipdata.sip_frequency;
            sipupdate.selectedDay = sipupdate.sipdata.sip_day;
            sipupdate.SelectSipData();
        }
    }
    ngOnInit(): void {
    }

    updateSip(){
        sipupdate.updateDisable = true;
        sipupdate.isLoading = true;
        if(sipupdate.selectedFrequency === 'Weekly'){
            sipupdate.selectedDay = sipupdate.sip_day.indexOf(sipupdate.selectedDay) + 1;
        }
        let obj = { 'id': sipupdate.sipdata.id, 'sip_status': sipupdate.sipdata.sip_status, 'sip_amount': sipupdate.sipdata.sip_amount, 'sip_frequency': sipupdate.selectedFrequency, 'sip_day': sipupdate.selectedDay};
        sipupdate.PS.getThematicBasketService().UpdateSip(obj, sipupdate.updateSipResponse);
    }

    updateSipResponse(code, status, message){
        if(code === 200 && status === 'SUCCESS'){
            if(sipupdate.isMobile){
                sipupdate.location.back();
            } else {
                Utility.dialogRef.close();
            }
            Utility.callBasketSip.next();
            sipupdate.snackBar.open(message, 'Close', { duration: 3000, panelClass: ['sucessSnackBar'] });
        } else {
            sipupdate.snackBar.open(message, 'Close', { duration: 3000, panelClass: ['errorSnackBar'] });
        }
    }
    
    SelectSipDay() {
        this.SelectSipData();
        this.selectedDay = this.sip_day[0];
    }

    SelectSipData(){
        sipupdate.sip_day = [];
        sipupdate.sip_frequency = [];
        const sip_data = JSON.parse(sipupdate.sipdata.sip_types);
        sip_data.forEach(element=>{
            sipupdate.sip_frequency.push(element.type);
            if(element.type === sipupdate.selectedFrequency) {
                if(element.day !== "any") {
                    sipupdate.sip_day = element.day.split(",").map(function(item) {
                        return parseInt(item, 10);
                    });;
                } else {
                    if(sipupdate.selectedFrequency === 'Weekly'){
                        sipupdate.sip_day = [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
                        sipupdate.selectedDay = sipupdate.sip_day[sipupdate.selectedDay - 1];
                    } else {
                        for(let i=1; i<=28; i++) {
                            sipupdate.sip_day.push(i);
                        }
                    }
                }
            }
        });  

    }

}
