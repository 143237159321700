import { Component, OnInit, Input, OnChanges, Injector, ChangeDetectorRef, OnDestroy, ElementRef } from '@angular/core';
import { ScripMarketPictureModel } from 'src/app/model/scripMarketPictureModel';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { ActivatedRoute } from '@angular/router';
import { ChannelType, Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';

let ob: QuotesComponent;
@Component({
    selector: 'app-qoutes',
    templateUrl: './quotes.component.html',
    styleUrls: ['./quotes.component.scss']

})
export class QuotesComponent extends BaseComponentDirective implements OnChanges, OnInit, OnDestroy {
    isNoData;
    circuitData = { year_high_low_percent: '', circuit_high_low_percent: '', year_high_low_percent_right: '', circuit_high_low_percent_right: '' };
    lowHighData = { high_low_percent: '', circuit_high_low_percent: '', high_low_percent_right: '', circuit_high_low_percent_right: '' };
    scrpModel;
    @Input() omtkn: string;
    @Input() exseg: string;
    @Input() isCustomDashboard: boolean;
    Height = {};
    container;
    modesub: Subscription;
    isDerivativeScrip = false;

    constructor(elRef: ElementRef, private activatedRoute: ActivatedRoute, private tradingRestService: TradingRestService, private injector: Injector, private detector: ChangeDetectorRef) {
        super(elRef.nativeElement);
        ob = this;
        ob.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            ob.changeMode();
        });
    }

    changeMode(){
         ob.Height = {'height': (ob.container.height) + 'px',
             'overflow-x':'hidden',
             'overflow-y': 'auto'};
        ob.detector.detectChanges();
    }
    ngOnInit() {
        if(this.inputs !== undefined){
            this.exseg = this.inputs.exch;
            this.isCustomDashboard = this.inputs.isCustomDashboard;
            this.omtkn = this.inputs.token;
        }
        if (ob.isCustomDashboard) {
            // ob.container = <ComponentContainer>ob.injector.get('Container');
        }
        if (ob.container) {
          /*  ob.container.on('resize', _ => {
                ob.changeMode();
            });*/
        }
        if (ob.isCustomDashboard) {
            ob.loadQuote();
        }
        if (ob.isCustomDashboard) {
            ob.changeMode();
        }
    }
    ngOnChanges() {
        ob.loadQuote();
    }

    loadQuote() {
        ob.scrpModel = Utility.getScripModel(ob.omtkn, ob.exseg,ob.tradingRestService);
        const data = {};
        data['exch'] = ob.exseg;
        data['scripToken'] = ob.omtkn;
        data['restClient'] = ob.tradingRestService;
        this.isDerivativeScrip = Utility.GetParentSegment(ob.scrpModel.ExchangeName) === 'DERIVATIVE';
        ob.tradingRestService.getScripService().showQuote(ob.scrpModel, true, (sm, code, message) => {
            if (code === 200 && message === 'OK') {
                ob.isNoData = false;
                ob.circuitData.circuit_high_low_percent = ((sm.LastTradePrice - sm.YearlyLowPrice) / (sm.YearlyHighPrice - sm.YearlyLowPrice) * 100) + '%';
                ob.circuitData.circuit_high_low_percent_right = (((sm.LastTradePrice - sm.YearlyLowPrice) / (sm.YearlyHighPrice - sm.YearlyLowPrice) * 100) > 75) ? '-100%' : '';
                ob.lowHighData.circuit_high_low_percent = ((sm.LastTradePrice - sm.Low) / (sm.High - sm.Low) * 100) + '%';
                ob.lowHighData.circuit_high_low_percent_right = (((sm.LastTradePrice - sm.Low) / (sm.High - sm.Low) * 100) > 75) ? '-100%' : '';
            }
            else {
                ob.isNoData = true;
            }
        });
    }
    ngOnDestroy() {
        Utility.pauseChannel(ob.tradingRestService,ChannelType.Shared);
    }
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        this.changeMode();
      }
}
