import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, Injector, HostListener, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { PtPlusRestService } from '../../../../services/ptplusrest.service';
import { ChannelType, Utility } from '../../../../services/utility';
import { Keys } from '../../../../services/keys';
import { TradingRestService } from '../../../../services/tradingrest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StockMoreOptionComponent } from '../../stockmoreoption/stockmoreoption.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from '../../../../services/tableutility';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';
import { Subscription, Subject } from 'rxjs';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { Mouse } from 'src/app/services/mouse';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-top-market',
    templateUrl: '../../../'+environment.baseviewpath+'/equitymarket/topmarket/topmarket.component.html',
    styleUrls: ['./topmarket.component.scss']
})
export class TopMarketComponent extends BaseComponentDirective implements OnInit, OnDestroy {
    @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
    targetElement: Element;
    displayedColumns;
    isNoData;
    selectedType;
    selectedExch = 'NSE';
    selectedIndex;
    selectedDay;
    exchArr = ['NSE', 'BSE'];
    indexArr = [];
    daysArr = [{ 'displayName': '1 Day', 'value': 1 }, { 'displayName': '1 Week', 'value': 7 }, { 'displayName': '1 Month', 'value': 30 },
    { 'displayName': '3 Months', 'value': 90 }, { 'displayName': '6 Months', 'value': 180 }, { 'displayName': '1 Year', 'value': 365 }];
    marketMoversData = [];
    scripList;
    isLoading;
    isPopup;
    indexData;
    isMobile = true;
    utility: any;
    showHeader = false;
    goback = '1';
    flashType = 'defaultFlash';
    @Output() loaderEvent = new EventEmitter<boolean>();
    @Output() load = new EventEmitter<boolean>();
    @Output() openStockDetailPage = new EventEmitter<string>();
    @Input() isCustomDashboard = false;
    @Input() workspaceName: string;
    @Input() dataType: string;
    Height: any;
    container;
    left: any;
    dataArray;
    modesub: Subscription;
    cellview;
    type = 'G';
    indexMap = {};
    @Input() webType = 'G';
    WlArr = [];
    @Input() reloadEquityNewsData: Subject<any>;
    isReload = false;
    M;
    selectedRowIndex : any;
    DHeight: {};
    isDHeight = true;
    createGttFlag = environment.isGTTEnable;
    readonly HEADER_HEIGHT = 48;
    constructor(elRef : ElementRef, private injector: Injector, private detector: ChangeDetectorRef, private tradingRestService: TradingRestService,
        private ptPlusRestService: PtPlusRestService, private router: Router, private dialog: MatDialog, private _bottomSheet: MatBottomSheet,
        private activatedRoute: ActivatedRoute, private snackBar: MatSnackBar, private translate: TranslateService) {
        super(elRef.nativeElement);
        if (!Utility.IS_MOBILE){
            // this.container = <ComponentContainer>this.injector.get('Container');
        }
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
          this.flashType = Preferences.getItem('flashType');
        }
        Utility.flashType.subscribe(obj => {
          this.flashType = obj;
        });
        this.createMap();
        this.M = Mouse;
        this.utility = Utility;
        this.isMobile = Utility.IS_MOBILE;
        this.isLoading = true;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        this.isPopup = Utility.IS_POPUP;
        if(environment.baseviewpath === 'investor'){
          setTimeout(() => {
            this.indexData = (JSON.parse(Preferences.getPreference('accessToken')));
            if(this.indexData !== null) {
              this.indexArr = this.indexData.index.NSE;
              this.selectedIndex = this.indexArr[0].TSymbol;
              this.selectedDay = this.daysArr[0].value;
            }
          }, 500);
        } else {
          this.indexData = (JSON.parse(Preferences.getPreference('accessToken')));
          if(this.indexData !== null) {
            this.indexArr = this.indexData.index.NSE;
            this.selectedIndex = this.indexArr[0].TSymbol;
            this.selectedDay = this.daysArr[0].value;
          }
        }
        this.displayedColumns = TableUtility.marketConfig;
        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });

        Utility.webReloadComponent.subscribe(data =>
        {
            if(this.isReload === false && data === this.dataType)
            {
                this.isReload = true;
                this.reloadData();
            }
        });
    }
    createMap() {
        this.indexMap['Nifty 50'] = 'NIFTY50';
        this.indexMap['Nifty Next 50'] = 'NIFTYNEXT50';
        this.indexMap['Nifty 100'] = 'NIFTY100';
        this.indexMap['Nifty 200'] = 'NIFTY200';
        this.indexMap['Nifty 500'] = 'NIFTY500';
        this.indexMap['Nifty Midcap 50'] = 'NIFTYMIDCAP50';
        this.indexMap['Nifty Midcap 100'] = 'NIFMDCP100';
        this.indexMap['Nifty Smallcap 100'] = 'NIFSMCP100';
        this.indexMap['Nifty Bank'] = 'NIFTYBANK';
        this.indexMap['Nifty Auto'] = 'NIFTYAUTO';
        this.indexMap['Nifty Financial Services'] = 'NIFTYFINANCE';
        this.indexMap['Nifty FMCG'] = 'NIFTYFMCG';
        this.indexMap['Nifty IT'] = 'NIFTYIT';
        this.indexMap['Nifty Media'] = 'NIFTYMEDIA';
        this.indexMap['Nifty Metal'] = 'NIFTYMET';
        this.indexMap['Nifty Pharma'] = 'NIFTYPHARM';
        this.indexMap['Nifty PSU Bank'] = 'NIFTYPSU';
        this.indexMap['Nifty Private Bank'] = 'NIFTYPVTBNK';
        this.indexMap['Nifty Realty'] = 'NIFTYREALI';
        this.indexMap['Nifty Commodities'] = 'NIFTYCOMMO';
        this.indexMap['Nifty India Consumption'] = 'NIFTYCONSU';
        this.indexMap['Nifty Energy'] = 'NIFTYENERGY';
        this.indexMap['Nifty Infrastructure'] = 'NIFTYINFRA';
        this.indexMap['Nifty PSE'] = 'NIFTYPSE';
        this.indexMap['Nifty CPSE'] = 'NICPSE';
        this.indexMap['Nifty Midcap Liquid 15'] = 'NIFML15';
        this.indexMap['Nifty MNC'] = 'NIFTYMNC';
        this.indexMap['Nifty Services Sector'] = 'NIFTYSER';
    }
    changeMode(){
        this.Height = {
            'height': (this.container?.height - TableUtility.TMHeight) + 'px',
            'overflow': 'auto'
        };
        this.left = {
            'left': (this.container?.width - TableUtility.TMLeft) + 'px'
        };
        if (this.container?.width < Utility.MinWidth) {
            this.cellview = true;
        }
        else {
          this.cellview = false;
          this.marketMoversData.map((data: any) => {
            data.show = false;
          });
        }
        this.detector.detectChanges();
    }

    ngOnInit() {
      if(this.inputs !== undefined){
        this.isCustomDashboard  = this.inputs.isCustomDashboard;
        this.webType = this.inputs.webType;
      }
      this.targetElement = document.querySelector('html');
        if (this.isMobile) {
          if(environment.baseviewpath !== 'investor') {
            this.activatedRoute.queryParams.subscribe(params => {
                this.showHeader = params['h'];
                this.type = params['type'];
            });
          }
        } else {
            this.type = this.webType;
        }
        this.selectedType = this.type === 'G' ? 'gainer' : 'mostactivebyvalue';
        if (this.container) {
            /* this.container.on('resize', _ => {
                this.changeMode();
            });
            this.container.on('show', _ => {
                setTimeout(() => {
                    this.changeMode();
                    if (this.marketMoversData.length > 0)
                      this.highlight(this.marketMoversData[0], 0);
                }, 50);
              });*/
        }
        if(this.isMobile){
          this.getMarketMoversData();
        }
        if (this.isCustomDashboard) {
            this.changeMode();
          }

        this.reloadEquityNewsData?.subscribe(data =>
        {
            this.reloadData();
        })
      //  this.onAppearing();
    }
    
    ngOnDestroy() {
      this.onDisappearing();
    }

    onAppearing() {
      Utility.resumeChannel(this.tradingRestService, ChannelType.Market);
    }

    onDisappearing() {
      Utility.pauseChannel(this.tradingRestService, ChannelType.Market);
    }
  
    addWLdata(stock) {
      const seltctedStock = stock.ExchangeSegment + '|' + stock.Token;
      this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
    }
    
    addStock(wl, stockdata) {
        const seltctedStock = stockdata.ExchangeSegment + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
          if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('books.add_stock'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('books.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('books.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
      }
    sortTopMarket(event) {
        const data = Array.from(this.dataArray);
        if (event.active === 'TradingSymbol' || event.active === 'CompanyName') {
          if (event.direction === 'asc')
            this.marketMoversData = data.sort((a, b) => (a[event.active] > b[event.active]) ? 1 : ((b[event.active] > a[event.active]) ? -1 : 0));
          else
            this.marketMoversData = data.sort((a, b) => (a[event.active] < b[event.active]) ? 1 : ((b[event.active] < a[event.active]) ? -1 : 0));
        }
        else {
          if (event.direction === 'asc')
            this.marketMoversData = data.sort((a, b) =>
             (Utility.getNumber(a[event.active]) > Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) > Utility.getNumber(a[event.active])) ? -1 : 0));
          else
            this.marketMoversData = data.sort((a, b) =>
             (Utility.getNumber(a[event.active]) < Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) < Utility.getNumber(a[event.active])) ? -1 : 0));
        }
    }
    gainersLosers(type) {
        this.selectedType = type;
        this.getMarketMoversData();
    }

    selectedIndexChange(e){
      this.selectedType = (e===0) ? 'gainer' : 'loser';
      this.getMarketMoversData();
    }

    onDaysSelection(days) {
        this.selectedDay = days;
        this.getMarketMoversData();
    }

    onIndexSelection(index) {
        this.selectedIndex = index;
        this.getMarketMoversData();
    }

    onExchangeSelection(exch) {
        this.selectedExch = exch;
        this.indexArr = [];
        if (this.selectedExch === 'NSE')
            this.indexArr = this.indexData.index.NSE;
        else
            this.indexArr = this.indexData.index.BSE;
        this.selectedIndex = this.indexArr[0].symbol;
        this.getMarketMoversData();
    }

    reloadData()
    {
        this.getMarketMoversData();
    }

    getMarketMoversData() {
        this.isLoading = true;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        if(environment.baseviewpath === 'investor'){
          this.selectedIndex = 'Nifty 100';
        }
        this.ptPlusRestService.getEquityMarketService().getMarketMovers(Utility.exchangeToSegment(this.selectedExch), this.selectedType,
            this.indexMap[this.selectedIndex], this.selectedDay, this.getMarketMoversResponse.bind(this), function(data) {
                this.marketMoversData = data;
                setTimeout(() => {
                  if (!Utility.IS_MOBILE)
                    this.highlight(this.marketMoversData[0], data);
                }, 500);
            }.bind(this));
    }

    getMarketMoversResponse(data, code, message) {
        this.marketMoversData = [];
        if (code === Keys.RES_CODE_SUCCESS && data.length > 0) {
            this.isNoData = false;
            this.marketMoversData = data;
            setTimeout(() => {
              if (!Utility.IS_MOBILE)
                this.highlight(this.marketMoversData[0], 0);
            }, 500);
        } else {
            this.isNoData = true;
        }
        this.isLoading = false;
        this.isReload = false;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        this.dataArray = Array.from(this.marketMoversData);
    }

    openPlaceOrder(buttontype, data) {
        Utility.openPlaceOrder(this.router, data.Token, data.ExchangeSegment, buttontype, this.dialog);
    }

    openScripModel(option) {
        if (this.isMobile) {
            this._bottomSheet.open(StockMoreOptionComponent, {
                data: option, restoreFocus: false
            });
        }

    }

    openStockDetail(data) {
      this.marketMoversData.map((data: any) => {
          data.show = false;
      })
        const obj = {'isCustomDashboard': true,'fromMultiView': true,'fromStockDetail': true,'isHeader': true,'MVtype':'stock','backbtnEnable':false, 'IsStockSpecific': false, 'c_name': 'topmarket', 'token': data.Token, 'exch': data.ExchangeSegment, 'symbol': data.TradingSymbol, 'series': data.series};
        Utility.SELECTED_SCRIP = Utility.getScripModel(data.Token,data.ExchangeSegment);
        this.outputs.emit(obj);
    }

    handleMouseOver(row) {
    const TradingSymbol = row.TradingSymbol;
    this.marketMoversData.map((data: any) => {
      if (TradingSymbol && data.TradingSymbol && data.TradingSymbol === TradingSymbol) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const TradingSymbol = row.TradingSymbol;
    this.marketMoversData.map((data: any) => {
      if (data.TradingSymbol === TradingSymbol) {
        data.show = false;
      }
    });
  }
  highlight(row: any, i: number){
    Mouse.IS_MOUSE = false;
    if(this.selectedRowIndex !== i && !Utility.IS_MOBILE) {
      let element:HTMLElement;
      if(this.cellview) {
        element = document.getElementById("ob_cell"+i) as HTMLElement;
      }
      else {
        element = document.getElementById("ob_tbl"+i) as HTMLElement;
      }
      element?.focus();
    }
    this.selectedRowIndex = i;
    Utility.SELECTED_SCRIP = row;
    Utility.selectedTab = TableUtility.topmarket;
    if(this.isMobile) {
      this.openScripModel(this.marketMoversData[i]);
    }
  }

 @HostListener('window:keydown.arrowdown', ['$event'])
 keyDownEvent(event: KeyboardEvent) {
     //if (Utility.selectedTab === TableUtility.topmarket && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace(this.dataType, this.workspaceName)) {
      if (Utility.selectedTab === TableUtility.topmarket && !Utility.isDialogOpened && !this.isMobile) {     
        event.preventDefault();
         if(((this.selectedRowIndex + 1) < this.marketMoversData.length)) {
             var nextrow = this.marketMoversData[this.selectedRowIndex + 1];
             this.highlight(nextrow, this.selectedRowIndex + 1);
         } else {
             this.highlight(this.marketMoversData[0], 0);
         }
     }
 }

 @HostListener('window:keydown.arrowup', ['$event'])
 keyUpEvent(event: KeyboardEvent) {
     //if (Utility.selectedTab === TableUtility.topmarket && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace(this.dataType, this.workspaceName)) {
    if (Utility.selectedTab === TableUtility.topmarket && !Utility.isDialogOpened && !this.isMobile) {   
        event.preventDefault();
         if(((this.selectedRowIndex - 1) >= 0)) {
             var nextrow = this.marketMoversData[this.selectedRowIndex - 1];
             this.highlight(nextrow, this.selectedRowIndex - 1); 
         } else {
             this.highlight(this.marketMoversData[this.marketMoversData.length - 1], this.marketMoversData.length - 1);
         }
     }
 }

 @HostListener('window:scroll', ['$event'])
 onWindowScroll(e) {
   if (Utility.IS_MOBILE_LAND) {
     if(window.scrollY > this.HEADER_HEIGHT && this.isDHeight){
       this.DHeight = {
        'height': 'calc(100vh - 48px)',
         'overflow': 'auto',
         'scroll-behavior': 'smooth'
       };
       let element:HTMLElement;
       element = document.getElementById("ob_tbl1") as HTMLElement;
       element?.focus();
       this.isDHeight = false;
     }
     else if(window.scrollY < this.HEADER_HEIGHT){
       this.DHeight = {
         'height': '100%',
         'overflow': 'auto',
         'scroll-behavior': 'smooth'
       };
       this.isDHeight = true;
     }
   }
 }

 myRefreshEvent(event: Subject<any>, message: string) {
  setTimeout(() => {
    this.reloadData();
    event.next();
  }, 1000);
 }

 alert(message: string) {
  
 }

 onTitleClick() {
  this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
}
setPositionAndSize(left: number, top: number, width: number, height: number) {
  this.container={'width': width, 'height':height}
  this.setBasePositionAndSize(left, top, width, height);
  this.cellview = (width < Utility.MinWidth) ? true : false;
  this.changeMode();
}
setBaseVisibility(visible: boolean){
  if(visible){
    this.getMarketMoversData();
    setTimeout(() => {
      this.onAppearing();
      this.changeMode();
    }, 50);
  }
  else{
    this.onDisappearing();
  }
}
  openTrigger(data) {
    Utility.openCreateTrigger(this.router, data.Token, data.ExchangeSegment, this.dialog);
  }
}
