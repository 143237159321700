<app-header (onTitleClick)="onTitleClick()" *ngIf = "!IsStockSpecific && !isCustomDashboard" [goback]="goback" [title]="'stock_detail.events' | translate" [isLoading]="isLoading" [isSearch] = "isSearch" [isEventFilter] = "true" (goToEvent) = "onEventFilter()" [freetrial] =  "true" (reloadPage) = "reloadData()"></app-header>
<div  #sectionNeedToScroll [class.data-container]="!IsStockSpecific && !isCustomDashboard" id="events">
    <ngx-pull-to-refresh
    spinnerColor="#ff0000"
    distanceForRefresh="80"
    [isEnable]="isMobile"
    [targetElement]="targetElement"
    (refresh)="myRefreshEvent($event, 'refresh')"
    (loadMore)="alert('loadmore')"
    class = "loader">
        <ng-container *ngIf = "(utility.IS_MOBILE || cellView) && !isNoData && !isFirstNoData">
            <div class="row"  [ngClass]="{'d-block d-sm-none' : isMobile}" [ngStyle]="Height">
                <div div class="col-12" *ngFor="let data of corporateActionData; let i = index;">
                    <mat-card>
                        <div class="heading-panel">
                            <h3>{{data.company_name}}</h3>
                            <p>{{data.purpose}}</p>
                            <div class="row date">
                                <div class="col-6"><span translate>limits.record_date</span> {{data.record_date | datepipe:'date'}}</div>
                                <div class="col-6 text-right"><span translate>limits.ex_date</span> {{data.ex_date | datepipe:'date'}}</div>
                            </div>

                        </div>
                    </mat-card>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!cellView">
            <div *ngIf = "!isFirstNoData ">
                <div #header class="row" *ngIf="!isMobile">
                    <div class="col-11 p-2">
                        <span class="pl-2" translate>events.action</span>
                    </div>
                    <div *ngIf = "!IsStockSpecific" class="col-1 text-right">
                        <button (click) = "onEventFilter()" mat-icon-button><i class="icon-filter"></i></button>
                    </div>
                </div>
                <div *ngIf  = "!isNoData" class="table-global" [ngStyle]="isMobile ? DHeight: Height" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                    <table mat-table [dataSource]="corporateActionData" matSort>
            
                    <ng-container matColumnDef="CompanyName" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <span translate>stock_detail.company_name</span> </th>
                    <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="Purpose">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left"> <span translate>limits.purpose</span> </th>
                        <td mat-cell *matCellDef="let element" class="text-left">{{element.purpose}}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="Record Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> <span translate>limits.record_date</span> </th>
                        <td mat-cell *matCellDef="let element">{{element.record_date | datepipe:'date'}}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="Ex Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"> <span translate>limits.ex_date</span> </th>
                        <td mat-cell *matCellDef="let element">{{element.ex_date | datepipe:'date'}}</td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf='!isLoading && (isNoData || isFirstNoData)' >
            <app-nodata  message="NO DATA"></app-nodata>
        </ng-container>
    </ngx-pull-to-refresh>
</div>
