<app-titlebar  [title]="'all.order_detail' | translate"></app-titlebar>
<mat-card class="webpanelnew mx-2">
  <mat-card-content>
    <div class="container  nofilter">
        <mat-chip-list>
            <mat-chip  [class.imactive]="selectedLabel === 'orderdetail'" id="tab-header" (click) = "onTabChange('orderdetail')" translate>all.order_detail</mat-chip>
            <mat-chip  [class.imactive]="selectedLabel === 'orderhistory'" id="tab-header" (click) = "onTabChange('orderhistory')" translate>all.order_history</mat-chip>
            <mat-chip  [class.imactive]="selectedLabel === 'trades'" id="tab-header" (click) = "onTabChange('trades')" translate>all.trades</mat-chip>
          </mat-chip-list>
    </div>
    
    <div *ngIf = "selectedLabel === 'orderdetail'">
      <div class="container myrow smalltext">
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>all.trade_symbol</span><span class="graytext">:</span>
              <span class="float-right">{{orderDetailData.TradeSymbol}} ({{orderDetailData.Exchange}})</span>
            </div>
          </div>
          <div class="col-6 text-right">
            <div class="line-height">
              <span class="graytext float-left" translate>all.ordertype</span><span class="graytext float-left">:</span>
              {{orderDetailData.OrderType | valuepipe}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" matTooltip={{orderDetailData.RejectionReason}} translate>all.status</span><span class="graytext">:</span>
              <span [class.in-progress-status] = "orderDetailData.DisplayStatus=='In Progress'" [class.trigger-pend-status] = "orderDetailData.DisplayStatus=='Trigger pending'" [class.amo-req-status] = "orderDetailData.DisplayStatus=='after market order req received'"  [class.traded-status] = "orderDetailData.DisplayStatus=='Traded'"   
              [class.Cancelled-status] = "orderDetailData.DisplayStatus=='Cancelled'" [class.rejected-status] = "orderDetailData.DisplayStatus=='Rejected'" [class.cancelled-amo-status] = "orderDetailData.DisplayStatus=='cancelled after market order'" [class.open-status] = "orderDetailData.DisplayStatus=='Open'" [class.modified-status] = "orderDetailData.DisplayStatus=='Modified'" class="float-right">{{orderDetailData.DisplayStatus}}</span>
            </div>
          </div>
          <div class="col-6 text-right">
            <div class="line-height">
              <span class="graytext float-left" translate>all.product</span><span class="graytext float-left">:</span>
              {{orderDetailData.ProductCode | valuepipe}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>all.action_type</span><span class="graytext">:</span>
              <span class="float-right">{{orderDetailData.ActionDisplay}}</span>
            </div>
          </div>
          <div class="col-6 text-right">
            <div class="line-height">
              <span class="graytext float-left"  translate>all.ordervalidity</span><span class="graytext float-left">:</span>
              {{orderDetailData.Validity}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>all.lasttradeprice</span><span class="graytext">:</span>
              <span class="float-right" [class.imnegative]="orderDetailData.scripModel.Change<0" [class.impositive]="orderDetailData.scripModel.Change>0">
                <span [class.imnegativebg]="orderDetailData.scripModel.TickDirection === -1" [class.impositivebg]="orderDetailData.scripModel.TickDirection === 1">
                  {{orderDetailData.scripModel.LastTradePrice | pricepipe: orderDetailData.scripModel.DecimalPrecision }}
                </span>
              </span>
            </div>
          </div>
          <div class="col-6 text-right">
            <div class="line-height">
              <span class="graytext float-left" translate>all.orderid</span>
              {{orderDetailData.NestOrd}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>all.change</span><span class="graytext">:</span>
              <span class="float-right" [class.imnegative]="orderDetailData.scripModel.Change<0" [class.impositive]="orderDetailData.scripModel.Change>0">{{orderDetailData.scripModel.Change | pricepipe: orderDetailData.scripModel.DecimalPrecision }}({{orderDetailData.scripModel.PercentageChange | percentagepipe}})</span>
            </div>
          </div>
          <div class="col-6 text-right">
            <div class="line-height">
              <span class="graytext float-left" translate>all.exordid</span><span class="graytext float-left">:</span>
            {{orderDetailData.ExchangeOrderNo}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>all.basket_qty</span><span class="graytext">:</span>
              <span class="float-right">{{orderDetailData.TotalQty}}</span>
            </div>
          </div>
          <div class="col-6 text-right">
            <div class="line-height">
              <span class="graytext float-left" translate>all.ordertime</span><span class="graytext float-left">:</span>
              {{orderDetailData.OrderedTime | datepipe:'mediumtime'}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>all.avgprice</span><span class="graytext">:</span>
              <span class="float-right">{{orderDetailData.AvgPrice | pricepipe: orderDetailData.scripModel.DecimalPrecision }}</span>
            </div>
          </div>
          <div class="col-6 text-right">
            <div class="line-height">
              <span class="graytext float-left" translate>all.extime</span><span class="graytext float-left">:</span>
              {{orderDetailData.ExchangeTimeStamp === "NA" ? orderDetailData.ExchangeTimeStamp : orderDetailData.ExchangeTimeStamp | datepipe:'datetime'}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>all.trig_price</span><span class="graytext">:</span>
              <span class="float-right">{{orderDetailData.TriggerPrice | pricepipe: orderDetailData.scripModel.DecimalPrecision }}</span>
            </div>
          </div>
          <div class="col-6 text-right">
            <div class="line-height">
              <span class="graytext float-left" translate>all.discqty</span><span class="graytext float-left">:</span>
              {{orderDetailData.DiscQty}}
            </div>
          </div>
        </div>
        <div class = "row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>all.placeby</span><span class="graytext">:</span>
              <span class="float-right">{{orderDetailData.User}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="orderDetailData.DisplayStatus=='Rejected'" class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>all.rejection_reason</span><span class="graytext mr-1">:</span><br/>
              {{orderDetailData.RejectionReason}}
            </div>
          </div>
        </div>
      </div>
    </div>
        
    <div *ngIf = "selectedLabel === 'orderhistory'">
      <app-orderhistory [formOrder] = 'true'></app-orderhistory>
    </div>

    <div *ngIf = "selectedLabel === 'trades'">
      <app-tradesummary [nOrderNumber] = "orderDetailData.NestOrd"></app-tradesummary>
    </div>

  </mat-card-content>
</mat-card>
