import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberpipe' })
export class NumberPipe implements PipeTransform {
  transform(number, arg1?) {
    if (number === undefined || number === '') {
      return '-';
    }
    else if (parseInt(number, 10) >= 10000000 && arg1 === 'short') {
      return this.numberToFigures(number);
    }
    else if (parseInt(number, 10) >= 100000 && arg1) {
      return this.formatNumber(number, arg1);
    }
    else if (parseInt(number, 10)) {
      return this.convertNumeric(number);
    }
    else {
      return number;
    }
  }
  convertNumeric(x) {
    let neg = false;
    x = parseInt(x, 10);
    if (x < 0) {
      neg = true;
      x = x * -1;
    }
    x = x.toString();
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') {
      lastThree = ',' + lastThree;
    }
    const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
    if (res === 'NaN') {
      return '-';
    }
    return (neg ? '-' : '') + res;
  }

  transformNumber(number) {
    if (number !== undefined && isNaN(number) && number.toString().indexOf(',') > -1) {
      number = number.replace(/\,/g, '');
    }
    if (number === undefined || number === null || number === 'NA')
      return '-';
    else if (parseInt(number, 10))
      return this.convertNumeric(number);
    else
      return number;
  }

  numberToFigures(num) {
    const result = Math.sign(num) * parseInt((Math.abs(num) / 10000000).toFixed(2), 10);
    return this.convertNumeric(result) + ' cr';
  }

  formatNumber(num, arg1) {
    let res;
    if (arg1 === 'lacs')
      res = Math.sign(num) * parseInt((Math.abs(num) / 100000).toFixed(2), 10);
    else if (arg1 === 'cr')
      res = Math.sign(num) * parseInt((Math.abs(num) / 10000000).toFixed(2), 10);
    return this.convertNumeric(res);
  }
}
