
<div class="top">
  <app-goldencontrols [isLoad]="isLoader" [stockInput] = "stockInput" [chartInputs] = chartInputs #controls></app-goldencontrols>
</div>
<div class="middle" #middle>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==0">
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost0></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==1">
        <div class="dragdrop" [class.d-none] = "utility.workspaceMap[utility.selectedWorkspace]?.length !== 0">
          <app-svg [type]="99"></app-svg>
          <div><button mat-flat-button class="btn-primary" (click)="opeSideMenu()"><i class="icon-leftnav"></i></button></div>
        </div>
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost1></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==2">
        <div class="dragdrop" [class.d-none] = "utility.workspaceMap[utility.selectedWorkspace]?.length !== 0">
          <app-svg [type]="99"></app-svg>
          <div><button mat-flat-button class="btn-primary" (click)="opeSideMenu()"><i class="icon-leftnav"></i></button></div>
        </div>
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost2></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==3">
        <div class="dragdrop" [class.d-none] = "utility.workspaceMap[utility.selectedWorkspace]?.length !== 0">
          <app-svg [type]="99"></app-svg>
          <div><button mat-flat-button class="btn-primary" (click)="opeSideMenu()"><i class="icon-leftnav"></i></button></div>
        </div>
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost3></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==4">
        <div class="dragdrop" [class.d-none] = "utility.workspaceMap[utility.selectedWorkspace]?.length !== 0">
          <app-svg [type]="99"></app-svg>
          <div><button mat-flat-button class="btn-primary" (click)="opeSideMenu()"><i class="icon-leftnav"></i></button></div>
        </div>
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost4></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==5">
        <div class="dragdrop" [class.d-none] = "utility.workspaceMap[utility.selectedWorkspace]?.length !== 0">
          <app-svg [type]="99"></app-svg>
          <div><button mat-flat-button class="btn-primary" (click)="opeSideMenu()"><i class="icon-leftnav"></i></button></div>
        </div>
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost5></app-golden-layout-host>
      </div>

      <div class="layout" [class.d-none]="utility.selectedWorkspace!==6">
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost6></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==7">
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost7></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==8">
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost8></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==9">
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost9></app-golden-layout-host>
      </div>
      <div class="layout" [class.d-none]="utility.selectedWorkspace!==10">
        <app-golden-layout-host (load)="onLoading($event)" (setStockInputs) = "setStockInputs($event)" (addstockInputs) = "addstockInputs($event)" (manageWLInputs) = "manageWLInputs($event)" (optionCalInputs) = "optionCalInputs($event)" (newsInputs) = "newsInputs($event)"  (setChartInputs) = "setChartInputs($event)" (addOpenManageBooksInputs) = "addOpenManageBooksInputs($event)" (addFundsInputs) = "addFundsInputs($event)" #goldenLayoutHost10></app-golden-layout-host>
      </div>
</div>
<div class="bottom d-flex">
  <div class="mt-1 ml-2" matTooltip="{{'all.click_pinned' | translate}}">
    <span><i class="icon-pin ts-4"></i></span>
  </div>
  <div class="indextoday cursor-pointer" *ngFor="let index of indexList; let i = index;">
      <div (click)="openIndexSpot(i)"><small class="indexname">{{index.Name}}:</small> <span [class.imnegative]="index.Change<0" [class.impositive]="index.Change>0">{{index.IndexValue | pricepipe:2}} <small>{{index.Change | pricepipe:2}} ({{index.PerCng | percentagepipe}})</small></span></div>
  </div>
</div>