import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener, ViewEncapsulation, Input, OnChanges, OnDestroy } from '@angular/core';
import { DragDrop, transferArrayItem, CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utility } from 'src/app/services/utility';
import { ScripModel } from 'src/app/model/scrip.model';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { ScripMarketPictureModel } from 'src/app/model/scripMarketPictureModel';
import { OrderModel } from 'src/app/model/order.model';
import { IScripService } from 'src/app/services/scrip/iscrip.service';
import { PlaceOrderModel } from 'src/app/model/place.order.model';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { Preferences } from 'src/app/services/Preferences';
let ob: VisualTradeComponent;
declare let jsBridgeGraph;
declare let window;
declare let callbackObj;

@Component({
    selector: 'app-visualtrade',
    templateUrl: './visualtrading.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./visualtrading.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('initial', style({

            })),
            transition('*=>buyfinal', [
                style({ transform: 'translateX(-100%)' }),
                animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
            ]),
            transition('*=>buyinitial', [
                animate('180ms ease-in', style({ transform: 'translateX(-100%)' }))
            ]),
            transition('*=>sellfinal', [
                style({ transform: 'translateX(200%)' }),
                animate('180ms ease-in', style({ transform: 'translateX(0%)' }))
            ]),
            transition('*=>sellinitial', [
                animate('180ms ease-in', style({ transform: 'translateX(200%)' }))
            ])
        ])
    ]
})

export class VisualTradeComponent implements OnChanges, OnInit, OnDestroy {
    @ViewChild('container', { static: true }) container: ElementRef;
    @ViewChild('inputtext', { static: false }) inputtext: ElementRef;
    @ViewChild('inputQty', { static: false }) inputQty: ElementRef;
    @Input() multiomtkn: string;
    @Input() isCustomDashboard: boolean;
    @Input() multiexseg: string;
    @Input() fromMultiView = false;
    @Input() windowWidth;
    customrows = [];
    centerPrice;
    ticksize;
    SizeEnum = {
        'buy': 25,
        'bid': 15,
        'price': 20,
        'ask': 15,
        'sell': 25,
    };

    // Border of each column
    border = 1;
    buyMap = new Map();
    sellMap = new Map();
    decimalprecision = 2;
    startPrice;
    endPrice;
    updatedLtp;
    bid = 0;
    ask = 0;
    high;
    low;
    bidprice;
    askprice;
    wait = false;
    middle;
    datasetMid;
    dataset;
    orderBookData = new Map();
    priceBeforeEdit;
    ObjIndex;
    displayMode = 'Normal';
    depthData;
    orderData = new Map();
    noData = false;
    themeObj = {};

    currentY;
    initialY;
    previousY;
    xOffset = 0;
    yOffset = 0;
    buyPrice;
    buyQuantity;
    sellPrice;
    sellQuantity;
    rowHeight;
    containerHeight;
    rowCount;
    buyWidth;
    bidWidth;
    priceWidth;
    askWidth;
    sellWidth;
    cancelArray = [];
    isLoader = true;
    isBottomDiv = true;
    isBuySellDiv = true;
    isModifyCancelDiv = false;
    isBuyButton = true;
    isSellButton = true;
    displayPrice;
    displayQuantity;
    tickSizeArray;
    animate = 'initial';
    cancelDropListEntered = false;
    isDraging = false;
    scrollDown = false;
    scrollUp = false;
    action;
    isAlertDiv = false;
    isPlaceorder = true;
    displayAction;
    dropObj;
    temp;
    uploadDetails;
    omnefeeder;
    fadeDivHeight;
    alertPrice;
    alertQty;
    waitObj;
    showSettings = false;
    tempElement;
    scripService: IScripService;
    pom: PlaceOrderModel;
    om: OrderModel;
    omtkn: any;
    exseg: any;
    scrpModel: ScripModel;
    quotesData;
    loadBaiKaiDetail;
    orderList: Array<OrderModel>;
    filterOrderList: Array<OrderModel>;
    sendJSonObj = {};
    placeOrderModel = new PlaceOrderModel();
    tradingSymbol = '';
    marketModel;
    scripUpdataSubscription;
    marketUpdateSubscription;

    @HostListener('window:callFromParent', ['$event.detail'])
    callFromParent(data)
    {
        data = JSON.parse(JSON.stringify(data));
        Utility.printlog('******callFromParent********', 'release');
        Utility.printlog(JSON.stringify(data), 'release');
        // e = JSON.parse(JSON.stringify(e));
        const str = data.mode;
        if (str === 'create')
        {
            this.isLoader = false;
            this.updateTheme(data['colors']);
            // this.createWidget(Number(data["args"]["ltp"]), Number(data["args"].ticksize));
            // drawNewUI(data["args"]);
            this.middle = this.getUiMiddle();
            this.drawUI(data['args']);
            Utility.GetScripMarketPictureModel(data['args'].Token, 'nse_cm');
            // this.omnefeeder = new OmneFeeder(this.http, this.mservice);
            // this.omnefeeder.createConnection();
        }
        else if (str === 'update')
        {
            this.onUpdate(data['args']);
        }
        else if (str === 'order')
        {
            this.orderUpdate(data['args']);
        }
        else if (str === 'neworder')
        {
            this.onBuyModifyRes(data['args']);
        }
        else if (str === 'cancelorder')
        {
            this.onCancelRes(data['args']);
        }
    }

    constructor(private router: Router, private dragDrop: DragDrop, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private tradingRestService: TradingRestService, private translate: TranslateService) {
        this.scripService = tradingRestService.getScripService();
    }

    showQuoteResponse(sm, code, message) {
        if (code === 200 && message === 'OK') {
            this.quotesData = sm;
            this.tradingSymbol = this.quotesData['TradingSymbol'];
            this.sendJSonObj = {
                'Tsym': this.quotesData['TradingSymbol'],
                'exch': this.quotesData['ExchangeName'],
                'Ttranstype': '',
                'Ret': 'DAY',
                'prctyp': 'L',
                'qty': '',
                'discqty': '0',
                'MktPro': 'NA',
                'Price': '',
                'TrigPrice': '0',
                'Pcode': 'CNC',
                'DateDays': 'NA',
                'AMO': 'NO',
                'PosSquareFlg': 'N',
                'MinQty': '0',
                'BrokerClient': '1',
                'naicCode': '',
                'remarks': ''
            };
            this.placeOrderModel = new PlaceOrderModel();
            this.placeOrderModel.Tsym = this.sendJSonObj['Tsym'];
            this.placeOrderModel.exch = this.sendJSonObj['exch'];
            this.placeOrderModel.Ttranstype = '';
            this.placeOrderModel.Ret = this.sendJSonObj['Ret'];
            this.placeOrderModel.prctyp = this.sendJSonObj['prctyp'];
            this.placeOrderModel.qty = '';
            this.placeOrderModel.discqty = this.sendJSonObj['discqty'];
            this.placeOrderModel.MktPro = this.sendJSonObj['MktPro'];
            this.placeOrderModel.Price = '';
            this.placeOrderModel.TrigPrice = this.sendJSonObj['TrigPrice'];
            this.placeOrderModel.Pcode = this.sendJSonObj['Pcode'];
            this.placeOrderModel.DateDays = this.sendJSonObj['DateDays'];
            this.placeOrderModel.AMO = this.sendJSonObj['AMO'];
            this.placeOrderModel.MinQty = this.sendJSonObj['MinQty'];
            this.placeOrderModel.BrokerClient = this.sendJSonObj['BrokerClient'];
            this.placeOrderModel.naicCode = this.sendJSonObj['naicCode'];
            this.placeOrderModel.Token = this.quotesData.Token;
            this.placeOrderModel.Exchangeseg = this.quotesData.ExchangeSegment;
            this.getDepthData();
        }
    }

    getDepthData() {
        const data = {};
        data['exch'] = this.exseg;
        data['scripToken'] = this.omtkn;
        this.tradingRestService.getScripService().ShowMarketPicture(data, this.getMarketPictureCallback.bind(this));
    }

    getMarketPictureCallback(data, code, message) {
        if (code === 200 && message === 'Ok') {
            this.tradingRestService.getBookService().getAllOrders(this.GetOrderBookResponse.bind(this), this.preResOrderData.bind(this));
            this.loadBaiKaiDetail = data;
            const sendJson = this.loadBaiKaiDetail;
            const obj = {
                'args': {
                    'BestBuyQuantity': sendJson['BestBuyQuantity'],
                    'BestBuyPrice': sendJson['BestBuyPrice'],
                    'BestBuyOrders': sendJson['BestBuyOrders'],
                    'BestSellQuantity': sendJson['BestSellQuantity'],
                    'BestSellPrice': sendJson['BestSellPrice'],
                    'BestSellOrder': sendJson['BestSellOrder'],
                    'Token': sendJson['Token'],
                    'ExchangeSegment': sendJson['ExchangeSegment'],
                    'Symbol': sendJson['Symbol'],
                    'Exchange': sendJson['Exchange'],
                    'TradingSymbol': sendJson['TradingSymbol'],
                    'HCircuitLimit': sendJson['HCircuitLimit'],
                    'LCircuitLimit': sendJson['LCircuitLimit'],
                    'ltp': Utility.getNumber(this.quotesData['LastTradePrice']),
                    'LPrecision': this.quotesData['DecimalPrecision'].toString(),
                    'ticksize': Utility.getTickSize(this.quotesData['TickSize'], this.quotesData['Multiplier'], this.quotesData['DecimalPrecision'])
                },
                'mode': 'create',
                'colors': null,
                'theme': 2
            };
            this.callFromParent(obj);
            this.marketModel = Utility.GetScripMarketPictureModel(sendJson['Token'], sendJson['ExchangeSegment']);
            this.scripUpdataSubscription = this.marketModel.depthUpdate.subscribe(obj => {
                this.onDataUpdate();
            });
            this.marketUpdateSubscription = this.scrpModel.scripUpdate.subscribe(obj =>{
                this.onDataUpdate();
            })
        }
        else {
            this.noData = true;
        }
    }

    GetOrderBookResponse(list, code, msg) {
        if (code === 200 && msg === 'OK') {
            this.orderList = list;
            this.filterOrderList = this.orderList.filter(
                element => element.Token === this.omtkn && element.Exseg === this.exseg && element.Status === 'open');
            if (this.filterOrderList.length)
            {
                const arr = [];
                for (var i = 0; i<this.filterOrderList.length; i++)
                {
                    const data = this.filterOrderList[i];
                    const obj = {
                        'NestOrd': data.NestOrd,
                        'Exseg': data.Exseg,
                        'Exchange': data.Exchange,
                        'TradeSymbol': data.TradeSymbol,
                        'Action': data.Action,
                        'DiscQty': data.DiscQty,
                        'CanceledQty': data.CanceledQty,
                        'Price': data.Price,
                        'Status': data.Status,
                        'DisplayStatus': data.DisplayStatus,
                        'TotalQty': data.TotalQty,
                        'PendingQty': data.PendingQty,
                        'Bqty': data.Bqty,
                        'Stat': data.Stat,
                        'DiscQtyPerc': data.DiscQtyPerc,
                        'ExchangeSegment': data.Exseg
                    };
                    arr.push(obj);
                }
                const dataObj = {'args': arr, 'mode': 'order'};
                this.callFromParent(dataObj);
            }
        }
    }

    preResOrderData(data)
    {
        
    }

    onDataUpdate()
    {
        const sendJson = this.loadBaiKaiDetail;
        const obj = {
            'args': {
                'BestBuyQuantity': sendJson['BestBuyQuantity'],
                'BestBuyPrice': sendJson['BestBuyPrice'],
                'BestBuyOrders': sendJson['BestBuyOrders'],
                'BestSellQuantity': sendJson['BestSellQuantity'],
                'BestSellPrice': sendJson['BestSellPrice'],
                'BestSellOrder': sendJson['BestSellOrder'],
                'Token': sendJson['Token'],
                'ExchangeSegment': sendJson['ExchangeSegment'],
                'Symbol': sendJson['Symbol'],
                'Exchange': sendJson['Exchange'],
                'TradingSymbol': sendJson['TradingSymbol'],
                'HCircuitLimit': sendJson['HCircuitLimit'],
                'LCircuitLimit': sendJson['LCircuitLimit'],
                'ltp': Utility.getNumber(this.quotesData['LastTradePrice']),
                'LPrecision': this.quotesData['DecimalPrecision'].toString(),
                'ticksize': Utility.getTickSize(this.quotesData['TickSize'], this.quotesData['Multiplier'], this.quotesData['DecimalPrecision'])
            },
            'mode': 'update'
        };
        // this.callFromParent(obj);
    }
    ngOnChanges() {
        if (this.fromMultiView) {
            this.activatedRoute.queryParams.subscribe(params => {
                this.omtkn = this.multiomtkn;
                this.exseg = this.multiexseg;
            });
        }
        this.createData();
    }
    ngOnInit() {
        if (!this.fromMultiView) {
            this.activatedRoute.queryParams.subscribe(params => {
                this.omtkn = params['omtkn'];
                this.exseg = params['exseg'];
            });
        }
        this.createData();
    }
    createData() {
        this.scrpModel = Utility.getScripModel(this.omtkn, this.exseg);
        this.loadBaiKaiDetail = Utility.GetScripMarketPictureModel(this.omtkn, this.exseg);
        this.tradingRestService.getScripService().showQuote(this.scrpModel, true, this.showQuoteResponse.bind(this));
        this.calulateInitialHeightWidth();
        this.createRows();
    }

    calulateInitialHeightWidth()
    {
        // Minus border space from height
        this.rowHeight = 30 - (this.border * 2);
        // var priceladderModel1 = new PriceLadderModel();
        if(this.isCustomDashboard)
            this.containerHeight = window.innerHeight - (287 + 110);
        else
            this.containerHeight = window.innerHeight - 287;

        this.fadeDivHeight = this.containerHeight + 120;
        this.rowCount = this.getRowCount(this.containerHeight, this.rowHeight);
        Utility.printlog('height: ' + this.containerHeight + ', rowHeight: ' + this.rowHeight, 'release');
        Utility.printlog('rowCount: ' + this.rowCount, 'release');
        const windowWidth = this.isCustomDashboard ? this.windowWidth: window.innerWidth;
        this.buyWidth = this.getWidth(windowWidth, this.SizeEnum['buy']);
        this.bidWidth = this.getWidth(windowWidth, this.SizeEnum['bid']);
        this.priceWidth = this.getWidth(windowWidth, this.SizeEnum['price']);
        this.askWidth = this.getWidth(windowWidth, this.SizeEnum['ask']);
        this.sellWidth = this.getWidth(windowWidth, this.SizeEnum['sell']);
    }

    createRows()
    {
        this.customrows = [];
        for (let i = 0; i < this.rowCount; i++)
        {
            const rowObj = {
                'buy': { 'orders': [], 'isMore': false, 'showPopup': false, 'moreOrders': [] }, bid: {}, price: ' ',
                'ask': {}, 'sell': { 'orders': [], 'isMore': false, 'showPopup': false, 'moreOrders': [] }
            };
            this.customrows.push(rowObj);
        }
    }

    getWidth(width, per)
    {
        // Calculate the width of column and minus the border
        return (width * per / 100) - (this.border * 2);
    }

    scrollEvent(event)
    {
        Utility.printlog('************ Scrolling ***************', 'release');
        if (this.displayMode === 'Normal')
        {
            this.bid = 0;
            this.ask = 0;
            this.wait = true;
            clearTimeout(this.waitObj);
            this.waitObj = setTimeout(function ()
            {
                this.wait = false;
                this.middle = this.getUiMiddle();
                this.drawUI(this.depthData);
            }, 2000);
            // if deltay is grether than 0 then increase the center price by tick size
            if (event.deltaY > 0)
            {
                this.updateData(Utility.getNumber(this.middle) - 1);
            }
            else
                this.updateData(Utility.getNumber(this.middle) + 1);
        }
        else if (this.displayMode === 'Optimized')
        {
            this.wait = true;
            clearTimeout(this.waitObj);
            this.waitObj = setTimeout(function ()
            {
                this.wait = false;
                this.middle = this.getUiMiddle();
                this.drawUI(this.depthData);
            }, 2000);
            if (isNaN(this.centerPrice))
                this.centerPrice = this.updatedLtp;
            if (event.deltaY > 0)
                this.centerPrice = (Utility.getNumber(this.centerPrice) - Utility.getNumber(this.ticksize)).toFixed(this.decimalprecision);
            else
                this.centerPrice = Utility.getNumber(this.centerPrice) + this.ticksize;
            this.drawUiForOptimizedMode(this.centerPrice);
        }
    }


    // updates all buy quantity and ask quanity in respective ltp
    updateBidAskCol(i, price)
    {
        price = Utility.getNumber(price);
        if (price && this.buyMap.has(price))
        {
            this.customrows[i].bid['qty'] = this.buyMap.get(price);
            this.customrows[i].bid['id'] = 'B' + '-' + price + '-' + this.buyMap.get(price);
        }
        else
        {
            this.customrows[i].bid['qty'] = ' ';
            this.customrows[i].bid['id'] = 'B' + '-' + price + '-' + 1;
        }
        if (price && this.sellMap.has(price))
        {
            this.customrows[i].ask['qty'] = this.sellMap.get(price);
            this.customrows[i].ask['id'] = 'S' + '-' + price + '-' + this.sellMap.get(price);
        }
        else
        {
            this.customrows[i].ask['qty'] = ' ';
            this.customrows[i].ask['id'] = 'S' + '-' + price + '-' + 1;
        }
    }



    getRowCount(windowHeight, rowHeight)
    {
        Utility.printlog('************ getRowCount ***************', 'release');
        try
        {
            return Utility.getNumber(windowHeight / rowHeight);
        }
        catch (error)
        {
            return 0;
        }
    }


    updateMap(dataobj)
    {
        Utility.printlog('*********** In updateMap() *********', 'release');
        Utility.printlog('depth object:', 'debug');
        Utility.printlog(dataobj, 'debug');
        this.noData = false;
        if (!dataobj['ltp'])
            this.noData = true;
        else
        {
            this.updatedLtp = Utility.getNumber(dataobj['ltp']);
            Utility.printlog('updatedLtp = ' + this.updatedLtp, 'debug');
            // this.centerPrice = this.updatedLtp;
            this.buyPrice = dataobj['BestBuyPrice'];
            this.buyQuantity = dataobj['BestBuyQuantity'];
            this.sellPrice = dataobj['BestSellPrice'];
            this.sellQuantity = dataobj['BestSellQuantity'];
            this.decimalprecision = Utility.getNumber(dataobj['LPrecision']) ? Utility.getNumber(dataobj['LPrecision']) : 2;
            this.tickSizeArray = this.decimalprecision === 2 ? [0.05, 0.25, 0.50, 1.00, 5.00, 10.00, 50.00, 100.00] : [0.0025, 0.0050, 0.0100, 0.0500, 0.2500, 0.5000, 1.0000, 5.0000, 10.0000, 50.0000, 100.0000];

            this.high = Utility.getNumber((dataobj['HCircuitLimit'])) > 0 ? Utility.getNumber(dataobj['HCircuitLimit']) : (Utility.getNumber(this.updatedLtp) +
                (Utility.getNumber(this.updatedLtp) * 0.1)).toFixed(this.decimalprecision);
            Utility.printlog('Higher Circuite limit = ' + this.high, 'debug');
            this.low = Utility.getNumber(dataobj['LCircuitLimit']) > 0 ? Utility.getNumber(dataobj['LCircuitLimit']) : (Utility.getNumber(this.updatedLtp) -
                (Utility.getNumber(this.updatedLtp) * 0.1)).toFixed(this.decimalprecision);
            Utility.printlog('Lower Circuite limit = ' + this.low, 'debug');
            this.buyMap.clear();
            this.sellMap.clear();
            this.bid = 0;
            this.ask = 0;
            if (this.displayMode === 'Normal')
            {
                for (let i = 0; i < this.buyPrice.length; i++)
                {
                    if (Utility.getNumber(this.buyPrice[i]) > this.updatedLtp || Utility.getNumber(this.buyPrice[i]) === 0)
                        this.bid++;
                    this.buyMap.set(Utility.getNumber(this.buyPrice[i]), Utility.getNumber(this.buyQuantity[i]));
                }
                for (let j = 0; j < this.sellPrice.length; j++)
                {
                    if (Utility.getNumber(this.sellPrice[j]) < this.updatedLtp || Utility.getNumber(this.sellPrice[j]) === 0)
                        this.ask++;
                    this.sellMap.set(Utility.getNumber(this.sellPrice[j]), Utility.getNumber(this.sellQuantity[j]));
                }
            }
            else if (this.displayMode === 'Optimized')
            {
                for (let i = 0; i < this.buyPrice.length; i++)
                {
                    if (Utility.getNumber(this.buyPrice[i]) !== 0)
                    {
                        const price = this.getNearestPrice(Utility.getNumber(this.buyPrice[i]));
                        if (this.buyMap.has(Utility.getNumber(price)))
                        {
                            let val = this.buyMap.get(Utility.getNumber(price));
                            val = Utility.getNumber(val) + Utility.getNumber(this.buyQuantity[i]);
                            this.buyMap.set(Utility.getNumber(price), val);
                        }
                        else
                            this.buyMap.set(Utility.getNumber(price), Utility.getNumber(this.buyQuantity[i]));
                    }
                }
                for (let j = 0; j < this.sellPrice.length; j++)
                {
                    if (Utility.getNumber(this.sellPrice[j]) !== 0)
                    {
                        const price = this.getNearestPrice(Utility.getNumber(this.sellPrice[j]));
                        if (this.sellMap.has(Utility.getNumber(price)))
                        {
                            let val = this.sellMap.get(Utility.getNumber(price));
                            val = Utility.getNumber(val) + Utility.getNumber(this.sellQuantity[j]);
                            this.sellMap.set(Utility.getNumber(price), val);
                        }
                        else
                            this.sellMap.set(Utility.getNumber(price), Utility.getNumber(this.sellQuantity[j]));
                    }
                }
            }
            Utility.printlog('*************Buy Map************', 'debug');
            Utility.printlog(this.buyMap, 'debug');
            Utility.printlog('*************Sell Map************', 'debug');
            Utility.printlog(this.sellMap, 'debug');
            // this.updateDateset();
        }
    }

    getNearestPrice(price)
    {
        Utility.printlog('********* In getNearestPrice() ******************', 'release');
        // var rem = (Utility.getNumber(price) - Utility.getNumber(this.updatedLtp)).toFixed(this.decimalprecision);
        // var mul = rem / this.ticksize;
        // mul = Math.ceil(mul);
        // var finalValue = ((mul * this.ticksize) + this.updatedLtp).toFixed(this.decimalprecision)
        // return finalValue;
        const rem = this.floatSafeModulus(price, this.ticksize);
        if (rem === 0)
        {
            Utility.printlog('price = ' + price + ' NearestPrice = ' + price, 'debug');
            return price;
        }
        else
        {
            const diff = (Utility.getNumber(this.ticksize) - rem).toFixed(2);
            const result = Utility.getNumber(price) + Utility.getNumber(diff);
            Utility.printlog('price = ' + price + ' NearestPrice = ' + result, 'debug');
            return result;
        }
    }

    updateDateset()
    {
        Utility.printlog('*********** In updateDateset() *********', 'release');
        this.dataset = [];
        for (let i = 0; i < this.buyPrice.length; i++)
        {
            if (this.dataset.indexOf(Utility.getNumber(this.buyPrice[i])) === -1 && Utility.getNumber(this.buyPrice[i]) !== 0)
            {
                this.dataset.push(Utility.getNumber(this.buyPrice[i]));
            }
        }
        for (let i = 0; i < this.sellPrice.length; i++)
        {
            if (this.dataset.indexOf(Utility.getNumber(this.sellPrice[i])) === -1 && Utility.getNumber(this.sellPrice[i]) !== 0)
            {
                this.dataset.push(Utility.getNumber(this.sellPrice[i]));
            }
        }
        if (this.orderBookData !== undefined)
        {
            this.orderData.forEach(function b(value, key, map)
            {
                if (this.dataset.indexOf(Utility.getNumber(key)) === -1)
                    this.dataset.push(Utility.getNumber(key));
            });
        }
        // this.dataset = (this.dataset.sort(function (a, b) { return a - b; })).reverse();
        if (this.dataset.indexOf(this.updatedLtp) === -1)
        {
            // if (this.updatedLtp < this.dataset[0] && this.updatedLtp > this.dataset[this.dataset.length - 1])
            // {
                this.dataset.push(Utility.getNumber(this.updatedLtp));
            // }
        }
        this.dataset = (this.dataset.sort(function (a, b) { return a - b; })).reverse();
        this.dataset = this.dataset.filter(ltp => (Utility.getNumber(ltp) >= Utility.getNumber(this.low) && Utility.getNumber(ltp) <= Utility.getNumber(this.high)));
        if (this.dataset.length === 0)
        {
            this.dataset.push(Utility.getNumber(this.updatedLtp));
        }
        Utility.printlog('dataset = ' + this.dataset, 'debug');
        this.datasetMid = Math.round((Utility.getNumber(this.dataset.length) / 2));
        Utility.printlog('datasetMid = ' + this.datasetMid, 'debug');
        // updateData(this.middle)
    }

    drawrow(index, ltp)
    {
        Utility.printlog('************** In drawrow() ***************', 'release');
        Utility.printlog(index, 'debug');
        if (index >= 0 && index < this.customrows.length)
        {
            if (Utility.getNumber(ltp) >= Utility.getNumber(this.low) && Utility.getNumber(ltp) <= Utility.getNumber(this.high))
                this.customrows[index].price = Utility.getNumber(ltp).toFixed(this.decimalprecision);
            else
                this.customrows[index].price = ' ';
            this.updateBidAskCol(index, this.customrows[index].price);
        }
    }

    drawUI(dataobj)
    {
        this.depthData = dataobj;
        if (!dataobj['ltp'])
            this.noData = true;
        else
        {
            this.updateMap(dataobj);
            if (this.displayMode === 'Normal')
            {
                this.ticksize = dataobj['ticksize'];
                this.updateDateset();
            }
            this.updateData(this.middle);
        }
    }

    onUpdate(dataobj)
    {
        this.depthData = dataobj;
        if (!this.wait && !this.isDraging && !this.isAlertDiv)
        {
            for (let i = 0; i < this.customrows.length; i++)
            {
                const price = this.customrows[i].price;
                if (Utility.getNumber(price) === (this.displayMode === 'Normal' ? Utility.getNumber(dataobj['ltp']) : this.getNearestPrice(Utility.getNumber(dataobj['ltp']))))
                {
                    if (this.displayMode === 'Optimized')
                    {
                        this.middle = i;
                    }
                    else
                        this.middle = this.getUiMiddle();
                    this.drawUI(dataobj);
                    return;
                }
            }
            this.wait = true;
            clearTimeout(this.waitObj);
            this.waitObj = setTimeout(function ()
            {
                this.wait = false;
                this.middle = this.getUiMiddle();
                this.drawUI(dataobj);
            }, 2000);
        }
    }

    updateData(mid)
    {
        Utility.printlog('********* In updateData() ************', 'release');
        Utility.printlog('middle = ' + mid, 'release');
        if (this.displayMode === 'Normal')
        {
            this.middle = mid;
            let dataseti = this.datasetMid - 1;
            let datasetj = Utility.getNumber(this.datasetMid);
            let customrowi = mid;
            let customrowj = Utility.getNumber(mid) + 1;
            let icount = 1;
            let jcount = 1;
            while (customrowi >= 0)
            {
                if (dataseti >= 0)
                {
                    this.drawrow(customrowi, this.dataset[dataseti]);
                    dataseti--;
                }
                else
                {
                    const price = (Utility.getNumber(this.dataset[0]) + (this.ticksize * icount)).toFixed(this.decimalprecision);
                    this.drawrow(customrowi, price);
                    icount++;
                }

                customrowi--;
            }
            while (customrowj < this.customrows.length)
            {
                if (datasetj < this.dataset.length)
                {
                    this.drawrow(customrowj, this.dataset[datasetj]);
                    datasetj++;
                }
                else
                {
                    const price = (Utility.getNumber(this.dataset[this.dataset.length - 1]) - (this.ticksize * jcount)).toFixed(this.decimalprecision);
                    this.drawrow(customrowj, price);
                    jcount++;
                }
                customrowj++;
            }
        }
        else if (this.displayMode === 'Optimized')
        {

            // for(var i = 0; i < this.customrows.length; i++)
            // {
            //     var pricevalue = this.customrows[i].price;
            //     this.drawrow(i, pricevalue)
            // }
            const rowCount = this.customrows.length;
            let tempPrice;
            // Get Starting Price of table
            const center = this.getNearestPrice(this.updatedLtp);
            this.centerPrice = center;
            this.startPrice = Utility.getNumber(center) + (this.middle * this.ticksize);
            for (let i = 0; i < rowCount; i++)
            {
                tempPrice = (this.startPrice - (this.ticksize * i)).toFixed(this.decimalprecision);
                this.drawrow(i, tempPrice);

            }
        }
        if (this.orderData.size !== 0)
            this.updateOrderBookData();
    }

    orderUpdate(data)
    {
        Utility.printlog('************ In orderUpdate() ***************', 'release');
        Utility.printlog('************ order data ***************', 'debug');
        Utility.printlog(data, 'debug');
        this.orderData.clear();
        this.orderBookData.clear();
        for (let i = 0; i < data.length; i++)
        {
            const price = Utility.getNumber(data[i].Price);
            if (this.orderData.has(price))
            {
                this.orderData.get(price).push(data[i]);
            }
            else
            {
                const arr = [data[i]];
                this.orderData.set(price, arr);
            }
            if (this.orderBookData.has(price))
            {
                this.orderBookData.get(price).push(data[i]);
            }
            else
            {
                const arr = [data[i]];
                this.orderBookData.set(price, arr);
            }
        }
        // this.isLoader = true;
        this.isBottomDiv = true;
        this.isBuyButton = true;
        this.isSellButton = true;
        this.isBuySellDiv = true;
        this.isModifyCancelDiv = false;


        this.updateDateset();
        this.updateData(this.middle);

        // this.updateOrderBookData();
    }

    updateOrderBookData()
    {
        Utility.printlog('************ updating order data in UI***************', 'debug');
        Utility.printlog('orderData = ', 'debug');
        Utility.printlog(this.orderData, 'debug');
        Utility.printlog('orderBookData = ', 'debug');
        Utility.printlog(this.orderBookData, 'debug');
        if (this.displayMode === 'Optimized')
            this.updateOrderDataToOptimizedMode();
        else
            this.updateOrderDataToNormalMode();
        const width = Utility.getNumber(this.buyWidth) - 45;
        console.log(width)
        for (let i = 0; i < this.customrows.length; i++)
        {
            let price = this.customrows[i].price;
            price = Utility.getNumber(price);
            this.customrows[i].buy.orders = [];
            this.customrows[i].sell.orders = [];
            this.customrows[i].buy.moreOrders = [];
            this.customrows[i].sell.moreOrders = [];
            this.customrows[i].buy.isMore = false;
            this.customrows[i].sell.isMore = false;
            this.customrows[i].buy.showPopup = false;
            this.customrows[i].sell.showPopup = false;
            if (this.orderBookData.has(price))
            {
                let arr = this.orderBookData.get(price);
                arr = arr.reverse();
                let availbaleWidthForBuy = width;
                let availbaleWidthForSell = width;
                const moreBuy = false;
                const moreSell = false;
                for (let j = 0; j < arr.length; j++)
                {
                    const obj = arr[j];
                    Utility.printlog(obj, 'debug');
                    if (obj.Action === 'B')
                    {
                        if (availbaleWidthForBuy >= 29 && this.customrows[i].buy.orders.length < 4)
                        {
                            obj['id'] = 'buy-' + i + '-' + obj.Price + '-' + obj.PendingQty + '-' + j;
                            this.customrows[i].buy.orders.push(obj);
                            availbaleWidthForBuy = Utility.getNumber(availbaleWidthForBuy) - 29;
                        }
                        else
                        {
                            // moreBuy = true;
                            obj['id'] = 'buy-' + i + '-' + obj.Price + '-' + obj.PendingQty + '-' + j + '-' + 'more';
                            this.customrows[i].buy.moreOrders.push(obj);
                            this.customrows[i].buy.isMore = true;
                            // break;
                        }
                    }
                    else if (obj.Action === 'S')
                    {
                        if (availbaleWidthForSell >= 29 && this.customrows[i].sell.orders.length < 4)
                        {
                            obj['id'] = 'sell-' + i + '-' + obj.Price + '-' + obj.PendingQty + '-' + j;
                            this.customrows[i].sell.orders.push(obj);
                            availbaleWidthForSell = Utility.getNumber(availbaleWidthForSell) - 29;
                        }
                        else
                        {
                            // moreSell = true;
                            obj['id'] = 'sell-' + i + '-' + obj.Price + '-' + obj.PendingQty + '-' + j + '-' + 'more';
                            this.customrows[i].sell.moreOrders.push(obj);
                            this.customrows[i].sell.isMore = true;
                            // break;
                        }
                    }

                }
            }
        }
        Utility.printlog(this.customrows, 'debug');
    }


    drag(ev)
    {
        Utility.printlog('************ In drag() *********', 'release');
        Utility.printlog(ev, 'release');
        clearTimeout(this.waitObj);
        this.wait = false;
        this.isDraging = true;
        const data = ev.source.element.nativeElement.id;
        const idArray = data.split('-');
        if (this.customrows[idArray[1]][idArray[0]].isMore && !idArray[5])
        {
            this.tempElement = this.customrows[idArray[1]][idArray[0]].moreOrders[0];
            this.customrows[idArray[1]][idArray[0]].orders.push(this.customrows[idArray[1]][idArray[0]].moreOrders[0]);
        }
        Preferences.setPreference('dragingElement', data);
        document.getElementById('deleteArea').style.display = 'block';
        document.getElementById('deleteArea').style.width = this.buyWidth + 'px';
        if (data.split('-')[0] === 'sell')
        {
            document.getElementById('deleteArea').style.right = '0px';
            document.getElementById('deleteArea').style.borderRadius = '5px 0px 0px 5px';
            document.getElementById('deleteArea').style.transformOrigin = 'right top';
            document.getElementById('deleteArea').style.left = null;
            this.animate = 'sellfinal';
        }
        else
        {
            document.getElementById('deleteArea').style.left = '0px';
            document.getElementById('deleteArea').style.borderRadius = '0px 5px 5px 0px';
            document.getElementById('deleteArea').style.transformOrigin = 'left top';
            document.getElementById('deleteArea').style.right = null;
            this.animate = 'buyfinal';
        }
        this.isBottomDiv = false;
    }

    onDrop(ev)
    {
        Utility.printlog('************ In onDrop() *********', 'release');
        Utility.printlog(ev, 'debug');
        // ev.item.element.nativeElement.parentElement.removeChild(ev.item.element.nativeElement);
        const previousContainerId = ev.previousContainer.id.split('-');
        const containerId = ev.container.id.split('-');
        if (this.customrows[containerId[1]].price !== ' ' && previousContainerId[0] === containerId[0] && this.customrows[previousContainerId[1]].price !== this.customrows[containerId[1]].price)
        {
            const data = ev.item.element.nativeElement.id;
            Utility.printlog(data, 'debug');
            const initialPrice = data.split('-')[2];
            Utility.printlog('initialPrice = ' + initialPrice, 'debug');
            const obj = this.orderData.get(Utility.getNumber(initialPrice))[data.split('-')[4]];
            obj['disable'] = true;
            if (this.orderData.get(Utility.getNumber(initialPrice)).length === 1)
                this.orderData.delete(Utility.getNumber(initialPrice));
            else
                this.orderData.get(Utility.getNumber(initialPrice)).splice(data.split('-')[4], 1);
            this.customrows[data.split('-')[1]][data.split('-')[0]].orders.splice(data.split('-')[4], 1);
            const tragetIndex = ev.container.id.split('-')[1];
            const price = this.customrows[Utility.getNumber(tragetIndex)].price;
            // obj.Price = Utility.getNumber(price);
            if (this.orderData.has(Utility.getNumber(price)))
            {
                this.orderData.get(Utility.getNumber(price)).push(obj);
            }
            else
            {
                const arr = [obj];
                this.orderData.set(Utility.getNumber(price), arr);
            }

            this.updateData(this.middle);
            this.dropObj = { obj: obj, Price: price, Quantity: obj.PendingQty };
            // this.callParent({obj: obj, Price: price, Quantity: obj.PendingQty}, 'modify');

        }
    }

    onPriceClick(ev)
    {
        Utility.printlog('************ price clicked *********', 'release');
        // this.callParent(ev.target.id, 'buy');
        const price = Utility.getNumber(ev.target.id);
        Utility.printlog(price, 'debug');
        this.displayPrice = price.toFixed(this.decimalprecision).toString();
        // if(this.buyMap.has(price))
        // {
        //   this.displayQuantity = this.buyMap.get(price);
        // }
        // else if(this.sellMap.has(price))
        // {
        //   this.displayQuantity = this.sellMap.get(price);
        // }
        // else
        this.displayQuantity = '1';
        this.isBuyButton = true;
        this.isSellButton = true;
        this.isBuySellDiv = true;
        this.isModifyCancelDiv = false;
    }

    placeorder(str)
    {
        Utility.printlog('************ place order called ************', 'release');
        if (this.checkPriceAndQtyField())
        {
            const data = { Price: this.displayPrice, Quantity: this.displayQuantity, action: str };
            // this.isLoader = true;
            this.callParent(data, str);
        }
    }

    onUpClick()
    {
        Utility.printlog('************ up clicked ************', 'release');
        if (this.displayMode === 'Normal')
        {
            this.updateData(Utility.getNumber(this.middle) + 1);
            this.wait = true;
            clearTimeout(this.waitObj);
            this.waitObj = setTimeout(function ()
            {
                this.wait = false;
                this.middle = this.getUiMiddle();
                this.drawUI(this.depthData);
            }, 2000);
        }
        else if (this.displayMode === 'Optimized')
        {
            this.centerPrice = Utility.getNumber(this.centerPrice) + Utility.getNumber(this.ticksize);
            this.drawUiForOptimizedMode(this.centerPrice);
            this.wait = true;
            clearTimeout(this.waitObj);
            this.waitObj = setTimeout(function ()
            {
                this.wait = false;
                this.centerPrice = Utility.getNumber(this.updatedLtp);
                this.drawUiForOptimizedMode(this.centerPrice);
            }, 2000);
        }
    }

    onRecenter()
    {
        Utility.printlog('************ recenter clicked ************', 'release');
        this.wait = false;
        if (this.displayMode === 'Normal')
            this.updateData(this.getUiMiddle());
        else if (this.displayMode === 'Optimized')
        {
            this.centerPrice = this.updatedLtp;
            this.drawUiForOptimizedMode(this.centerPrice);
        }
    }

    onDownClick()
    {
        Utility.printlog('************ down clicked ************', 'release');
        if (this.displayMode === 'Normal')
        {
            this.updateData(Utility.getNumber(this.middle) - 1);
            this.wait = true;
            clearTimeout(this.waitObj);
            this.waitObj = setTimeout(function ()
            {
                this.wait = false;
                this.middle = this.getUiMiddle();
                this.drawUI(this.depthData);
            }, 2000);
        }
        else if (this.displayMode === 'Optimized')
        {
            this.centerPrice = (Utility.getNumber(this.centerPrice) - Utility.getNumber(this.ticksize)).toFixed(this.decimalprecision);
            this.drawUiForOptimizedMode(this.centerPrice);
            this.wait = true;
            clearTimeout(this.waitObj);
            this.waitObj = setTimeout(function ()
            {
                this.wait = false;
                this.centerPrice = Utility.getNumber(this.updatedLtp);
                this.drawUiForOptimizedMode(this.centerPrice);
            }, 2000);
        }
    }

    onEditOrder(ev)
    {
        Utility.printlog('************ In onEditOrder() ************', 'release');
        Preferences.setPreference('dragingElement', ev.target.id);
        const data = ev.target.id.split('-');
        this.priceBeforeEdit = data[2];
        this.ObjIndex = data[4];
        this.displayPrice = Number(data[2]).toFixed(this.decimalprecision);
        this.displayQuantity = data[3];
        const obj = this.orderData.get(Utility.getNumber(this.priceBeforeEdit))[this.ObjIndex];
        obj['selected'] = true;
        Utility.printlog(obj, 'debug');
        this.isModifyCancelDiv = true;
        this.isBuySellDiv = false;
    }

    onModify()
    {
        Utility.printlog('************ In onModify() ************', 'release');
        if (this.checkPriceAndQtyField())
        {
            const obj = this.orderData.get(Utility.getNumber(this.priceBeforeEdit))[this.ObjIndex];
            obj['disable'] = true;
            // this.isLoader = true;
            this.callParent({ obj: obj, Price: this.displayPrice, Quantity: this.displayQuantity }, 'modify');
        }
    }

    dragEnd()
    {
        Utility.printlog('************ Drag end ************', 'release');
        // this.isDraging = false;
        const data = Preferences.getPreference('dragingElement');
        const idArray = data.split('-');
        if (this.customrows[idArray[1]][idArray[0]].isMore && !idArray[5])
        {
            this.customrows[idArray[1]][idArray[0]].orders.splice(this.customrows[idArray[1]][idArray[0]].orders.indexOf(this.tempElement), 1);
        }
        if (data.split('-')[0] === 'sell')
        {
            this.animate = 'sellinitial';
        }
        else
        {
            this.animate = 'buyinitial';
        }
        setTimeout(function ()
        {
            document.getElementById('deleteArea').style.display = 'none';
        }, 160);
        this.isBottomDiv = true;
        this.isDraging = false;
    }

    onDelete()
    {
        Utility.printlog('************ onDelete() ************', 'release');
        Utility.printlog(this.priceBeforeEdit, 'debug');
        // ev.item.element.nativeElement.parentElement.removeChild(ev.item.element.nativeElement);
        const data = Preferences.getPreference('dragingElement');
        const price = this.displayMode === 'Normal' ? Utility.getNumber(data.split('-')[2]) : this.getNearestPrice(Utility.getNumber(data.split('-')[2]));

        const obj = this.orderBookData.get(price)[data.split('-')[4]];
        obj['disable'] = true;
        this.updateDateset();
        this.updateData(this.middle);
        this.callParent(obj, 'cancel');
    }

    onBuyModifyRes(data)
    {
        Utility.printlog('************ Buy or Modify Response ************', 'release');
        Utility.printlog(data, 'release');
        if (data.Status === 'rejected')
        {
            const message = 'Order with ' + data.TotalQty + '@' + data.Price + ' is rejected.';
            this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['rejectSnackBar'] });
        }
        else if (data.Status === 'complete')
        {
            const message = 'Order with ' + data.TotalQty + '@' + data.Price + ' is completed successfully.';
            this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['completeSnackBar'] });
        }
        else if (data.Status === 'open')
        {
            const message = 'Order with ' + data.PendingQty + '@' + data.Price + ' is open.';
            this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['openSnackBar'] });
        }
        this.replaceOrder(data);
    }

    replaceOrder(data)
    {
        const price = Utility.getNumber(data.Price);
        this.orderData.forEach(function b(value, key, map)
        {
            const arr = this.orderData.get(Utility.getNumber(key));
            for (let i = 0; i < arr.length; i++)
            {
                const obj = arr[i];
                if (obj.NestOrd === data.NestOrd)
                {
                    if (arr.length === 1)
                        this.orderData.delete(Utility.getNumber(key));
                    else
                        arr.splice(i, 1);
                }
            }
        });
        data['disable'] = false;
        if (this.orderData.has(price))
        {
            const arr = this.orderData.get(price);
            if (data.Status === 'open')
                arr.push(data);
        }
        else
        {
            if (data.Status === 'open')
            {
                const arr1 = [data];
                this.orderData.set(price, arr1);
            }
        }
        Utility.printlog('order data after buy or modify', 'debug');
        Utility.printlog(this.orderData, 'debug');
        // updateOrderBookData();
        this.updateDateset();
        if (!this.wait && !this.isDraging)
        {
            this.updateData(this.middle);
        }
        this.isBuySellDiv = true;
    }

    onCancelRes(data)
    {
        Utility.printlog('************ Cancel Responce ************', 'release');
        Utility.printlog(data, 'release');
        const price = Utility.getNumber(data.Price);
        if (this.orderData.has(price))
        {
            const arr = this.orderData.get(price);
            for (let i = 0; i < arr.length; i++)
            {
                const obj = arr[i];
                if (obj.NestOrd === data.NestOrd)
                {
                    arr.splice(i, 1);
                }
            }
            this.orderData.set(price, arr);
        }
        Utility.printlog('order data after cancel', 'debug');
        Utility.printlog(this.orderData, 'debug');
        this.updateData(this.middle);
        this.isBuySellDiv = true;
    }

    onBestBuySell(ev)
    {
        Utility.printlog('********* In onBestBuySell() ************', 'release');
        Utility.printlog(ev.target.id, 'release');
        const data = ev.target.id.split('-');
        this.displayPrice = data[1];
        this.displayQuantity = '1';
        this.isBuySellDiv = true;
        this.isModifyCancelDiv = false;
        if (data[0] === 'B')
        {
            this.isBuyButton = true;
            this.isSellButton = false;
        }
        else if (data[0] = 'S')
        {
            this.isBuyButton = false;
            this.isSellButton = true;
        }
    }

    onChangeMode(ev)
    {
        Utility.printlog('****************** Mode Changed to ' + ev.value + '******************', 'release');

        if (!ev.checked)
        {
            this.displayMode = 'Optimized';
            this.drawUiForOptimizedMode(this.updatedLtp);
        }
        else
        {
            this.displayMode = 'Normal';
            this.ticksize = this.depthData.ticksize;
            this.middle = this.getUiMiddle();
            this.drawUI(this.depthData);
        }
    }

    updateOrderDataToOptimizedMode()
    {
        Utility.printlog('************ updating orderBookData for optimized mode ***************', 'release');
        this.orderBookData.clear();
        this.orderData.forEach(function b(value, price, map)
        {
            const nearestPrice = this.getNearestPrice(price);
            let arr;
            if (!this.orderBookData.has(Utility.getNumber(nearestPrice)))
            {
                arr = [];
                this.orderBookData.set(Utility.getNumber(nearestPrice), arr);
            }
            arr = this.orderBookData.get(Utility.getNumber(nearestPrice));
            for (let j = 0; j < value.length; j++)
            {
                arr.push(value[j]);
            }
        });
        Utility.printlog(this.orderBookData, 'debug');
    }

    updateOrderDataToNormalMode()
    {
        Utility.printlog('************ updating orderBookData for Noemal mode ***************', 'release');
        this.orderBookData.clear();
        this.orderData.forEach(function b(value, key, map)
        {
            this.orderBookData.set(Utility.getNumber(key), this.orderData.get(key));
        });
        Utility.printlog(this.orderBookData, 'debug');
    }

    onTickSizeChange()
    {
        Utility.printlog('Ticksize changed', 'released');
        Utility.printlog('ticksize = ' + this.ticksize, 'debug');
        if (this.ticksize !== 0)
        {
            this.updateMap(this.depthData);
            this.drawUiForOptimizedMode(this.updatedLtp);
        }
    }

    updateTheme(data)
    {
        Utility.printlog('*************** theme data ***************', 'release');
        if (data)
        {
            Utility.printlog(data, 'debug');
            this.themeObj = data;
        }
        else
        {
            const element = document.querySelector('.colors');
            const styles = getComputedStyle(element);
            this.themeObj['Accent'] = styles.floodColor;
            this.themeObj['Primary_text'] = styles.color;
            this.themeObj['Divider'] = styles.borderColor;
            this.themeObj['PositiveTrand'] = styles.lightingColor;
            this.themeObj['NegativeTrand'] = styles.outlineColor;
        }
        document.body.style.background = this.themeObj['Accent'];
    }

    onMore(ev)
    {
        Utility.printlog('************ More clicked ******************', 'release');
        Utility.printlog(ev, 'debug');
        const index = Utility.getNumber(ev.target.id.split('-')[2]);
        if (!this.customrows[index][ev.target.id.split('-')[0]].showPopup)
        {
            this.wait = true;
            this.customrows[index][ev.target.id.split('-')[0]].showPopup = true;
            clearTimeout(this.waitObj);
            this.waitObj = setTimeout(function ()
            {
                this.wait = false;
            }, 2000);
        }
        else
        {
            this.wait = false;
            this.customrows[index][ev.target.id.split('-')[0]].showPopup = false;
        }
    }


    validateQty(ev)
    {
        const regex = new RegExp('^[0-9]{0,8}$|^[0-9]{1,' + this.decimalprecision + '}$');
        const val = this.inputQty.nativeElement.value;
        const index = ev.target.selectionStart;
        if (!val.match(regex))
        {
            ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
            this.inputQty.nativeElement.dispatchEvent(new Event('input'));
            this.inputQty.nativeElement.setSelectionRange(index - 1, index - 1);
        }
        // this.inputQty.nativeElement.value = val;
    }


    floatSafeModulus(val, step)
    {
        const valDecCount = (val.toString().split('.')[1] || '').length;
        const stepDecCount = (step.toString().split('.')[1] || '').length;
        const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
        let valInt;
        if (val !== undefined && val !== null && val !== NaN)
            valInt = parseInt(Utility.getNumber(val).toFixed(decCount).replace(/\./g, ''), 10);
        const stepInt = parseInt(step.toFixed(decCount).replace(/\./g, ''), 10);
        return (valInt % stepInt) / Math.pow(10, decCount);
    }

    validatePrice(ev)
    {
        // this.temp = this.displayPrice;
        // if (this.displayPrice && this.displayPrice.toString().includes('.') && ev.key === 'Unidentified')
        // {
        //     // this.displayPrice = this.temp;
        //     this.displayPrice = null;
        //     return false;
        // }
        // const key = ev.key;
        // const position = Number(ev.selectionStart);
        // const output = [ev.target.value.toString().slice(0, position), key, ev.target.value.toString().slice(position)].join('');
        // const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{0,' + 7 + '}[.]{1,1}[0-9]{0,' + this.decimalprecision + '}$');
        // if (!regex.test(output) && key !== 'Backspace')
        //     ev.preventDefault();
        if (!Utility.IS_MOBILE)
        {
            if (ev.key === 'ArrowUp' && this.displayPrice)
            {
                ev.preventDefault();
                this.displayPrice = (Utility.getNumber(this.displayPrice) + Utility.getNumber(this.depthData.ticksize)).toFixed(this.decimalprecision);
            }
            else if (ev.key === 'ArrowDown' && this.displayPrice && Utility.getNumber(this.displayPrice) !== 0)
            {
                ev.preventDefault();
                this.displayPrice = (Utility.getNumber(this.displayPrice) - Utility.getNumber(this.depthData.ticksize)).toFixed(this.decimalprecision);
            }
        }
    }

    qtyUpDown(ev)
    {
        if (!Utility.IS_MOBILE)
        {
            if (ev.key === 'ArrowUp' && this.displayQuantity)
            {
                ev.preventDefault();
                this.displayQuantity = Utility.getNumber(this.displayQuantity) + 1;
            }
            else if (ev.key === 'ArrowDown' && this.displayQuantity && Utility.getNumber(this.displayQuantity) !== 1)
            {
                ev.preventDefault();
                this.displayQuantity = Utility.getNumber(this.displayQuantity) - 1;
            }
        }
    }

    keyup(ev)
    {
        // if (!this.displayPrice && ev.key !== 'Backspace')
        // {
        //     this.displayPrice = this.temp;
        // }
        // else if (!this.displayPrice && ev.key === 'Backspace')
        //     this.displayPrice = undefined;
        const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.decimalprecision + '}$');
        const val = this.inputtext.nativeElement.value;
        const index = ev.target.selectionStart;
        if (!val.match(regex))
        {
            ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
            this.inputtext.nativeElement.dispatchEvent(new Event('input'));
            this.inputtext.nativeElement.setSelectionRange(index - 1, index - 1);
        }
        // this.inputtext.nativeElement.value = val;
        // this.displayPrice = val;
    }

    drawUiForOptimizedMode(center)
    {
        if (!this.isDraging && !this.isAlertDiv)
        {
            Utility.printlog('************ updating depth data in UI ***************', 'release');
            const rowCount = this.customrows.length;
            let price;
            // Get Starting Price of table
            center = this.getNearestPrice(center);
            this.centerPrice = center;
            this.startPrice = Utility.getNumber(center) + (Math.round(rowCount / 2) * this.ticksize);
            for (let i = 0; i < rowCount; i++)
            {
                price = (this.startPrice - (this.ticksize * i)).toFixed(this.decimalprecision);
                this.drawrow(i, price);

            }
            this.updateOrderBookData();
        }
    }

    ontouchStart(ev)
    {
        this.initialY = ev.touches[0].clientY;
        this.currentY = ev.touches[0].clientY;
    }

    scrollAuto(ev)
    {
        const top = this.container.nativeElement.offsetTop;
        const contnrHt = this.container.nativeElement.offsetHeight;
        const deleteAreaTop = document.getElementById('deleteArea').offsetTop;
        const x = ev.pointerPosition.x;
        const y = ev.pointerPosition.y;
        if (y > 0 && top > y)
        {
            if (this.displayMode === 'Normal')
            {
                this.updateData(Utility.getNumber(this.middle) + 1);
            }
            else
            {
                this.centerPrice = Utility.getNumber(this.centerPrice) + this.ticksize;
                this.drawUiForOptimizedMode(this.centerPrice);
            }
        }
        if ((Utility.getNumber(contnrHt) + Utility.getNumber(top)) < y && deleteAreaTop > y)
        {
            if (this.displayMode === 'Normal')
            {
                this.updateData(Utility.getNumber(this.middle) - 1);
            }
            else
            {
                this.centerPrice = (Utility.getNumber(this.centerPrice) - Utility.getNumber(this.ticksize)).toFixed(this.decimalprecision);
                this.drawUiForOptimizedMode(this.centerPrice);
            }
        }
    }

    ontouchMove(ev)
    {
        Utility.printlog('************ Scrolling with touch event ***************', 'debug');
        this.previousY = this.currentY;
        this.currentY = ev.touches[0].clientY;
        const dif = Math.ceil(this.currentY) - Math.ceil(this.previousY);
        if (this.floatSafeModulus(dif, 4) === 0)
        {
            if (this.displayMode === 'Normal')
            {
                this.bid = 0;
                this.ask = 0;
                this.wait = true;
                clearTimeout(this.waitObj);
                this.waitObj = setTimeout(function ()
                {
                    this.wait = false;
                    this.middle = this.getUiMiddle();
                    this.drawUI(this.depthData);
                }, 2000);
                // if deltay is grether than 0 then increase the center price by tick size
                if (this.currentY > this.previousY)
                    this.updateData(Utility.getNumber(this.middle) + 1);
                else
                    this.updateData(Utility.getNumber(this.middle) - 1);
            }
            else if (this.displayMode === 'Optimized')
            {
                this.wait = true;
                clearTimeout(this.waitObj);
                this.waitObj = setTimeout(function ()
                {
                    this.wait = false;
                    this.middle = this.getUiMiddle();
                    this.drawUI(this.depthData);
                }, 2000);
                if (isNaN(this.centerPrice))
                    this.centerPrice = this.updatedLtp;
                if (this.currentY > this.previousY)
                    this.centerPrice = Utility.getNumber(this.centerPrice) + this.ticksize;
                else
                    this.centerPrice = (Utility.getNumber(this.centerPrice) - Utility.getNumber(this.ticksize)).toFixed(this.decimalprecision);
                this.drawUiForOptimizedMode(this.centerPrice);
            }
        }
    }

    // callParent(data, mode)
    // {
    //     Utility.printlog(mode, 'release');
    //     Utility.printlog(data, 'release');
    //     const dataobj = { 'data': data, 'mode': mode };
    //     // window.parent.postMessage(dataobj);
    //     if (typeof jsBridgeGraph !== 'undefined') // For Android
    //         jsBridgeGraph.invokeAction(JSON.stringify(dataobj));
    //     else if (window.webkit !== undefined) // For iOS
    //         window.webkit.messageHandlers.invokeAction.postMessage(JSON.stringify(dataobj));
    //     else if (callbackObj !== undefined) // For WPF
    //         callbackObj.invokeFromJavascript(JSON.stringify(dataobj));
    //     else // for web
    //         window.parent.postMessage(dataobj);
    // }

    entered(event: CdkDragEnter<string[]>)
    {
        this.cancelDropListEntered = true;
        document.getElementById('deleteArea').style.transform = 'scale(1.2)';
        document.getElementsByClassName('cdk-drag-placeholder')[0].classList.add('zeroOpacity');
        document.getElementsByClassName('cdk-drag-preview')[0].classList.add('oneOpacity');
    }
    exited(event: CdkDragExit<string[]>)
    {
        this.cancelDropListEntered = false;
        document.getElementById('deleteArea').style.transform = null;
        document.getElementsByClassName('cdk-drag-placeholder')[0].classList.remove('zeroOpacity');
        document.getElementsByClassName('cdk-drag-preview')[0].classList.remove('oneOpacity');
    }

    onLongPress(str)
    {
        if (str === 'u')
        {
            this.scrollUp = true;
        }
        else if (str === 'd')
        {
            this.scrollDown = true;
        }
        setTimeout(function () { this.callScroll(); }, 200);
    }

    onLongPressRelease()
    {
        this.scrollUp = false;
        this.scrollDown = false;
    }

    callScroll()
    {
        if (this.scrollUp)
        {
            this.onUpClick();
            setTimeout(function () { this.callScroll(); }, 300);
        }
        else if (this.scrollDown)
        {
            this.onDownClick();
            setTimeout(function () { this.callScroll(); }, 300);
        }
    }


    showAlert(str, event = null)
    {
        this.action = str;
        // this.wait = true;
        if (this.checkPriceField() || this.checkQtyField())
        {
            const message = 'Invalid Price or Quantity.';
            this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['rejectSnackBar'] });
            return false;
        }
        else
        {
            if (str === 'drop')
            {
                const previousContainerId = event.previousContainer.id.split('-');
                const containerId = event.container.id.split('-');
                if (this.customrows[containerId[1]].price !== ' ' && previousContainerId[0] === containerId[0] && this.customrows[previousContainerId[1]].price !== this.customrows[containerId[1]].price)
                {
                    this.displayAction = 'modify';
                    this.onDrop(event);
                    this.isAlertDiv = true;
                    this.isPlaceorder = false;
                    this.alertPrice = this.customrows[containerId[1]].price;
                    this.alertQty = event.item.element.nativeElement.id.split('-')[3];
                }
            }
            else if (str === 'dropCancel')
            {
                const previousContainerId = event.previousContainer.id.split('-');
                this.displayAction = 'cancel';
                this.alertPrice = event.item.element.nativeElement.id.split('-')[2];
                this.alertQty = event.item.element.nativeElement.id.split('-')[3];
                this.isAlertDiv = true;
                this.isPlaceorder = false;
            }
            else
            {
                this.alertPrice = this.displayPrice;
                this.alertQty = this.displayQuantity;
                this.displayAction = str;
                this.isAlertDiv = true;
                this.isPlaceorder = false;
            }
        }
    }

    onAlertYes()
    {
        // this.wait = false;
        this.isAlertDiv = false;
        this.isPlaceorder = true;
        this.isBuyButton = true;
        this.isSellButton = true;
        this.isModifyCancelDiv = false;
        this.isBuySellDiv = true;
        if (this.action === 'buy')
            this.placeorder('B');
        else if (this.action === 'sell')
            this.placeorder('S');
        else if (this.action === 'modify')
            this.onModify();
        else if (this.action === 'cancel')
            this.onDelete();
        else if (this.action === 'dropCancel')
            this.onDelete();
        else if (this.action === 'drop')
            this.callParent(this.dropObj, 'modify');
    }

    onAlertNo()
    {
        // this.wait = false;
        this.isAlertDiv = false;
        this.isPlaceorder = true;
        this.isBuyButton = true;
        this.isSellButton = true;
        this.isModifyCancelDiv = false;
        this.isBuySellDiv = true;
        if (this.action === 'drop')
        {
            // var data = this.dropObj.obj['disable'] = false
            this.replaceOrder(this.dropObj.obj);
        }
    }

    getUiMiddle()
    {
        console.log(this.customrows.length);
        return Math.round((this.customrows.length) / 2) - 1;
    }

    onShowSettingsToggle()
    {
        if (this.showSettings)
            this.showSettings = false;
        else
            this.showSettings = true;
    }

    checkPriceAndQtyField()
    {
        if (Utility.getNumber(this.displayPrice) < Utility.getNumber(this.low) || Utility.getNumber(this.displayPrice) > Utility.getNumber(this.high))
        {
            const message = 'Price should between ' + this.low + ' and ' + this.high + '.';
            this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['rejectSnackBar'] });
            return false;
        }
        else if (this.floatSafeModulus(this.displayPrice, this.depthData['ticksize']) !== 0)
        {
            const message = 'Price should be multiple of ' + this.depthData.ticksize + '.';
            this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['rejectSnackBar'] });
            return false;
        }
        else if (Utility.getNumber(this.displayQuantity) === 0)
        {
            const message = 'Quantity cannot be 0.';
            this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['rejectSnackBar'] });
            return false;
        }
        else
            return true;
    }

    dragListEntered(i, str)
    {
        const price = this.displayMode === 'Normal' ? Utility.getNumber(Preferences.getPreference('dragingElement').split('-')[2]) :
            this.getNearestPrice(Utility.getNumber(Preferences.getPreference('dragingElement').split('-')[2]));
        if (this.customrows[i][str].orders.length && price !== Utility.getNumber(this.customrows[i].price))
        {
            this.customrows[i][str].moreOrders.push(this.customrows[i][str].orders.pop());
        }
    }
    dragListExited(i, str)
    {
        const price = this.displayMode === 'Normal' ? Utility.getNumber(Preferences.getPreference('dragingElement').split('-')[2]) :
            this.getNearestPrice(Utility.getNumber(Preferences.getPreference('dragingElement').split('-')[2]));
        if (this.customrows[i][str].moreOrders.length && price !== Utility.getNumber(this.customrows[i].price))
        {
            this.customrows[i][str].orders.push(this.customrows[i][str].moreOrders.pop());
        }
        // if (this.customrows[i][str].isMore && price === Utility.getNumber(this.customrows[i].price) && this.customrows[i][str].orders.length == 1)
        // {
        //     this.customrows[i][str].orders.push(this.customrows[i][str].moreOrders.pop());
        // }
    }

    checkPriceField()
    {
        const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.decimalprecision + '}$');
        if (this.displayPrice && !this.displayPrice.match(regex))
            return true;
        else
            return false;
    }

    checkQtyField()
    {
        const regex = new RegExp('^[0-9]{0,' + 8 + '}$');
        if (this.displayQuantity && !this.displayQuantity.match(regex))
            return true;
        else
            return false;
    }

    callParent(data, mode) {
        switch (mode) {
            case 'B':
                this.getBuyOrder(data);
                break;
            case 'S':
                this.getSellOrder(data);
                break;
            case 'modify':
                this.getModifyOrder(data);
                break;
            case 'cancel':
                this.getCancelOrder(data);
                break;
        }
    }

    getBuyOrder(data) {
        this.placeOrderModel.Ttranstype = data.action;
        this.placeOrderModel.qty = data.Quantity.toString();
        this.placeOrderModel.Price = data.Price.toString();
        this.scripService.placeOrder(this.placeOrderModel, this.placeOrderResponse.bind(this));
    }

    getSellOrder(data) {
        this.placeOrderModel.Ttranstype = data.action;
        this.placeOrderModel.qty = data.Quantity.toString();
        this.placeOrderModel.Price = data.Price.toString();
        this.scripService.placeOrder(this.placeOrderModel, this.placeOrderResponse.bind(this))
    }

    placeOrderResponse(data, code, message) {
        if (code === 200) {
            // this.snackBar.open(this.translate.instant('stock_detail.place_msg'), 'Close', { duration: 2000, panelClass: ['successSnackBar'] });
            this.tradingRestService.getBookService().getAllOrders(function(list, code, msg){
                if (code === 200 && msg === 'OK') {
                    console.log(list)
                    this.orderList = list.filter(
                        element => element.NestOrd === data.NOrdNo || element.NestOrd === data.nOrdNo);
                    const obj = {
                        'args': {
                            'NestOrd': this.orderList[0]['NestOrd'],
                            'Exseg': this.orderList[0]['Exseg'],
                            'Exchange': this.orderList[0]['Exchange'],
                            'TradeSymbol': this.orderList[0]['TradeSymbol'],
                            'Action': this.orderList[0]['Action'],
                            'DiscQty': this.orderList[0]['DiscQty'],
                            'CanceledQty': this.orderList[0]['CanceledQty'],
                            'Price': this.orderList[0]['Price'],
                            'Status': this.orderList[0]['Status'],
                            'DisplayStatus': this.orderList[0]['DisplayStatus'],
                            'TotalQty': this.orderList[0]['TotalQty'],
                            'PendingQty': this.orderList[0]['PendingQty'],
                            'Bqty': this.orderList[0]['Bqty'],
                            'Stat': this.orderList[0]['Stat'],
                            'DiscQtyPerc': this.orderList[0]['DiscQtyPerc'],
                            'ExchangeSegment': this.orderList[0]['ExchangeSegment']
                        },
                        'mode': 'neworder'
                    }
                    this.callFromParent(obj);
                }
            }.bind(this), this.preResOrderData.bind(this));
        }

        else {
            this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
        }
    }


    getModifyOrder(data) {
        const filterObj = this.orderList.filter(
            element => element.NestOrd === data.obj.NestOrd);
        this.placeOrderModel.Scripname = filterObj[0]['ScripName'];
        this.placeOrderModel.RequestID = filterObj[0]['RequestID'];
        this.placeOrderModel.Symbol = filterObj[0]['SymbolName'];
        this.placeOrderModel.Filledqty = filterObj[0]['FilledShares'].toString();
        this.placeOrderModel.Ttranstype = data.obj.Action;
        this.placeOrderModel.Nstordno = data.obj.NestOrd;
        this.placeOrderModel.qty = data.Quantity.toString();
        this.placeOrderModel.Price = data.Price.toString();
        this.placeOrderModel.Status = data.obj.Status;
        this.placeOrderModel.RequestID = data.obj.id;
        this.scripService.modifyOrder(this.placeOrderModel, function(response, code, msg){
            if (code === 200 && msg === 'OK') {
                // this.snackBar.open(this.translate.instant('stock_detail.modify_msg'), 'Close', { duration: 2000, panelClass: ['successSnackBar'] });
                this.tradingRestService.getBookService().getAllOrders(function(list, code, msg){
                    if (code === 200 && msg === 'OK') {
                        this.orderList = list.filter(
                            element => element.NestOrd === filterObj[0]['NestOrd']);
                        const obj = {
                            'args': {
                                'NestOrd': this.orderList[0]['NestOrd'],
                                'Exseg': this.orderList[0]['Exseg'],
                                'Exchange': this.orderList[0]['Exchange'],
                                'TradeSymbol': this.orderList[0]['TradeSymbol'],
                                'Action': this.orderList[0]['Action'],
                                'DiscQty': this.orderList[0]['DiscQty'],
                                'CanceledQty': this.orderList[0]['CanceledQty'],
                                'Price': this.orderList[0]['Price'],
                                'Status': this.orderList[0]['Status'],
                                'DisplayStatus': this.orderList[0]['DisplayStatus'],
                                'TotalQty': this.orderList[0]['TotalQty'],
                                'PendingQty': this.orderList[0]['PendingQty'],
                                'Bqty': this.orderList[0]['Bqty'],
                                'Stat': this.orderList[0]['Stat'],
                                'DiscQtyPerc': this.orderList[0]['DiscQtyPerc'],
                                'ExchangeSegment': this.orderList[0]['ExchangeSegment']
                            },
                            'mode': 'neworder'
                        }
                        this.callFromParent(obj);
                    }
                }.bind(this), this.preResOrderData.bind(this));
            }

        }.bind(this))
    }

    getCancelOrder(data) {
        this.om = new OrderModel();
        this.om['NestOrd'] = data.NestOrd;
        this.om['TradeSymbol'] = this.sendJSonObj['Tsym'];
        this.om['Exchange'] = this.sendJSonObj['exch'];
        this.om['orderSource'] = 'RESTAPI-TPPtLite';
        this.tradingRestService.getBookService().CancelOrder(this.om, function(response, code, msg){
            if (code === 200 && msg === 'OK') {
                this.tradingRestService.getBookService().getAllOrders(function(list, code, msg){
                    if (code === 200 && msg === 'OK') {
                        this.orderList = list.filter(
                            element => element.NestOrd === this.filterOrderList[0]['NestOrd']);
                        const obj = {
                            'args': {
                                'NestOrd': this.orderList[0]['NestOrd'],
                                'Exseg': this.orderList[0]['Exseg'],
                                'Exchange': this.orderList[0]['Exchange'],
                                'TradeSymbol': this.orderList[0]['TradeSymbol'],
                                'Action': this.orderList[0]['Action'],
                                'DiscQty': this.orderList[0]['DiscQty'],
                                'CanceledQty': this.orderList[0]['CanceledQty'],
                                'Price': this.orderList[0]['Price'],
                                'Status': this.orderList[0]['Status'],
                                'DisplayStatus': this.orderList[0]['DisplayStatus'],
                                'TotalQty': this.orderList[0]['TotalQty'],
                                'PendingQty': this.orderList[0]['PendingQty'],
                                'Bqty': this.orderList[0]['Bqty'],
                                'Stat': this.orderList[0]['Stat'],
                                'DiscQtyPerc': this.orderList[0]['DiscQtyPerc'],
                                'ExchangeSegment': this.orderList[0]['ExchangeSegment']
                            },
                            'mode': 'cancelorder'
                        }
                        this.callFromParent(obj);
                    }
                }.bind(this), this.preResOrderData);
            }
        }.bind(this));
    }

    ngOnDestroy()
    {
        this.marketUpdateSubscription.unsubscribe();
        this.scripUpdataSubscription.unsubscribe();
    }
}
