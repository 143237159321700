import { TradesocketService } from './tradesocket.service';
import { inflate } from 'pako';
import { Utility } from '../../utility';
import { environment } from 'src/environments/environment';
import { Preferences } from '../../Preferences';

let tradeFeeder: TradeFeeder;
let tradeSocket: TradesocketService;
const CN = 'cn';
const HB = 'hb';
const SOB = 'sob';
const STB = 'stb';
const SPB = 'spb';
let channelData = '';
let isConnected = false;
const min_sec = 10, max_sec = 15;
let hbTimeInterval;
let timeout: any;
const actid = environment.isQuickRest === true ? 'actId' : 'sAccountId';


export class TradeFeeder {
    constructor() {
        tradeFeeder = this;
    }

    createConnection() {
        isConnected = false;
        tradeSocket = new TradesocketService();
        tradeSocket.connect(tradeFeeder.ConnectionCallback);
    }

    closeSocketConnection() {
        if (tradeSocket !== undefined) {
            tradeSocket.closeSocketConnection();
        }
    }

    ConnectionCallback(status, response = null) {
        if (status === 'open') {
            const obj = tradeFeeder.getJsonObj(CN);
            tradeSocket.sendMessage(obj);
        }
        else if (status === 'error') {
            isConnected = false;
            const millsec = (Math.floor(Math.random() * (max_sec - min_sec)) + min_sec) * 1000;
            setTimeout(function () {
                tradeSocket.connect(tradeFeeder.ConnectionCallback);
            }, millsec);
        }
        else if (status === 'message') {
            const decoded = window.atob(response.data);
            const data = tradeFeeder.ctos(inflate(decoded));
            const obj = JSON.parse(data);
            if (isConnected) {
                if (obj.hasOwnProperty('pf') && obj.hasOwnProperty('eoi')) {
                    // trade update
                    tradeFeeder.updateTrade(obj);
                } else if (obj.hasOwnProperty('pf')) {
                    // position update
                    tradeFeeder.updatePosition(obj);
                }
                if (obj.hasOwnProperty('os')) {
                    // Order Update
                    tradeFeeder.updateOrder(obj);
                }

            } else if (obj[0] && obj[0].hasOwnProperty('task') && obj[0].task === CN && obj[0].hasOwnProperty('msg') && obj[0].msg === 'connected') {
                tradeFeeder.ConnectionCallback('connected');
            }
        }
        else if (status === 'connected') {
            isConnected = true;
            clearInterval(hbTimeInterval);
            hbTimeInterval = setInterval(tradeFeeder.StartHB, 30000);
            //tradeFeeder.SubscribeOrderNow();
            //tradeFeeder.SubscribePositionNow();
            //tradeFeeder.SubscribeTradeNow();
        }

    }

    getJsonObj(task) {
        const accountid = JSON.parse(Preferences.getPreference('userParameter'))[actid];
        const token = Preferences.getPreference('publicKey4');
        if (task === CN) {
            // return { actId: accountid, typ: task, token: token };
            return {'token': token, 'user': '', 'task': task, 'channel': ''}
        } else {
            return { actId: accountid, typ: task };
        }
    }

    ctos(array) {
        const newarray = [];
        for (let i = 0; i < array.length; i++) {
            newarray.push(String.fromCharCode(array[i]));
        }
        return newarray.join('');
    }

    StartHB() {
        const obj = tradeFeeder.getJsonObj(HB);
        tradeSocket.sendMessage(obj);
    }

    SubscribeOrderNow() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const obj = tradeFeeder.getJsonObj(SOB);
            if (tradeSocket === undefined) {
                tradeFeeder.createConnection();
            }
            tradeSocket.sendMessage(obj);
        }, 50);
    }

    SubscribePositionNow() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const obj = tradeFeeder.getJsonObj(STB);
            if (tradeSocket === undefined) {
                tradeFeeder.createConnection();
            }
            tradeSocket.sendMessage(obj);
        }, 50);
    }

    SubscribeTradeNow() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            const obj = tradeFeeder.getJsonObj(SPB);
            if (tradeSocket === undefined) {
                tradeFeeder.createConnection();
            }
            tradeSocket.sendMessage(obj);
        }, 50);
    }
    updateTrade(obj) {
/*        "acc": "PRAVEEN",
        "ac": "NA",
        "aid": "NA",
        "ap": "32.85",
        "bq": 1,
        "bc": "--",
        "cf": "C",
        "eoi": "200319000000098",
        "es": "nse_cm",
        "et": "19-Mar-2020 14:28:17",
        "ed": "NA",
        "ep": "NA",
        "fd": "19-Mar-2020",
        "fq": 1,
        "fid": "40",
        "fl": 1,
        "fiq": 1,
        "ft": "14:28:17",
        "boe": 1584608297,
        "mq": 0,
        "nri": "1",
        "non": "200319000004520",
        "ot": "XX",
        "od": "DAY",
        "og": "--",
        "ou": "",
        "pc": "NRML",
        "pf": "true",
        "pt": "L",
        "prc": "32.85",
        "qy": 1,
        "re": "--",
        "rt": "fill",
        "se": "EQ",
        "st": "Ok",
        "sp": "00.00",
        "sy": "16921",
        "ti": "19/03/2020 14:28:17",
        "tt": "B",
        "ts": "20MICRONS-EQ",
        "us": "649962",
        "usr": "PRAVEEN"*/

        if (Utility.isTradeExists(obj['non'], obj['fid']))
        {
            const data = this.updateTradeData(obj);
        }
        else
        {
            const data = this.updateTradeData(obj);
            Utility.tradeBookData.next(data);
        }
    }
    updatePosition(obj) {
    /*    "acc": "PRAVEEN",
        "blq": 0,
        "cbq": "100",
        "csq": "100",
        "dq": "0",
        "es": "nse_cm",
        "fbq": "0",
        "fsq": "1",
        "ot": "NA",
        "pc": "NRML",
        "pf": "true",
        "nqp": "0.00",
        "se": "EQ",
        "sf": "NA",
        "st": "Ok",
        "sp": "0.00",
        "tk": "16921",
        "ts": "20MICRONS-EQ",
        "ty": "NA"*/

        if (Utility.isPositionExists(obj['tk'], obj['es'], obj['ot']))
        {
            const data = this.updatePositionData(obj);
        }
        else
        {
            const data = this.updatePositionData(obj);
            Utility.positionBookData.next(data);
        }
    }
    updateOrder(obj) {
    /*    "acc": "PRAVEEN",
        "dq": 0,
        "eoi": "200319000000098",
        "et": "19-Mar-2020 14:28:17",
        "fid": "",
        "fpc": "3285",
        "fs": "Filled quantity",
        "nm": "osf",
        "non": "200319000004520",
        "od": "DAY",
        "ogt": "--",
        "os": "complete",
        "pc": "NRML",
        "pt": "L",
        "ptf": "3285",
        "qtf": 1,
        "rid": "1",
        "rpt": "fill",
        "sid": "",
        "tk": "16921",
        "tp": "00.00",
        "ts": "20MICRONS",
        "tt": "B",
        "ui": "PRAVEEN",
        "uf": 0,
        "vd": "NA"*/
        if (Utility.isOrderExists(obj['non']))
        {
            const data = this.updateOrderData(obj);
        }
        else
        {
            const data = this.updateOrderData(obj);
            Utility.orderBookData.next(data);
        }
    }

    updateOrderData(obj)
    {
        const objData = Utility.GetOrderModels(obj['non'], '');
        /*objData.AccountId = obj['acc'];
        objData.DiscQty = obj['dq'];
        objData.ExchangeOrderNo = obj['eoi'];
        objData.ExchangeTimeStamp = obj['et'];
        objData.FilledShares = obj['fs'];
        objData.NestOrd = obj['non'];
        objData.OrderValidityDate = obj['vd'];
        objData.ordergenerationtype = obj['ogt'];
        objData.Status = obj['os'];
        objData.ProductCode = obj['pc'];
        objData.OrderType = obj['pt'];
        objData.Price = obj['ptf'];
        objData.TotalQty = obj['qtf'];
        objData.RequestID = obj['rid'];
        objData.SyomOrderId = obj['sid'];
        objData.Token = obj['tk'];
        objData.TradeSymbol = obj['ts'];
        objData.Action = obj['tt'];
        objData.User = obj['ui'];
        objData.PendingQty = Utility.getNumber(obj['qtf']) - Utility.getNumber(obj['fs']);
*/

        objData.AccountId = obj['a'];
        objData.User = obj['u'];
        objData.Action = obj['tt'];
        objData.TotalQty = obj['qtf'];
        objData.ProductCode = obj['prod'];
        objData.Status = obj['os'];
        objData.DisplayStatus = obj['os'];
        objData.OrderType = obj['pt'];
        objData.DiscQty = obj['discqty'];
        objData.ExchangeOrderNo = obj['eoi'];
        objData.NestOrd = obj['non'];
        objData.TradeSymbol = obj['ts'];
        objData.Token = obj['tk'];
        objData.ExchangeTimeStamp = obj['exchtim'];
        objData.FilledShares = obj['fs'];
        objData.OrderValidityDate = obj['valdate'];
        objData.ordergenerationtype = obj['ordgentype'];
        objData.Price = obj['ptf'];
        objData.RequestID = obj['reqid'];
        objData.Exchange = 'NSE';
        objData.ActionDisplay = obj['tt'];
        return objData;
    }

    updateTradeData(obj)
    {
        const objData = Utility.GetTradeModels(obj['non'], obj['es'], obj['fid']);
        objData.AccountId = obj['acc'];
        objData.AvgPrice = obj['ap'];
        objData.BrokerClient = obj['bc'];
        objData.CustOFrm = obj['cf'];
        objData.ExpiryDate = obj['ed'];
        objData.NestOrd = obj['non'];
        objData.ExchordID = obj['eoi'];
        objData.ExpiryDate = obj['ep'];
        objData.Exchange = obj['es'];
        objData.ExchTime = obj['et'];
        objData.FillDate = obj['fd'];
        objData.Price = obj['ptf'];
        objData.FillId = obj['fid'];
        objData.FillQty = obj['fiq'];
        objData.FillLeg = obj['fl'];
        objData.FilledQty = obj['fq'];
        objData.FillTime = obj['ft'];
        objData.Minqty = obj['mq'];
        objData.NOReqID = obj['nri'];
        objData.OrdDuration = obj['od'];
        objData.Pcode = obj['pc'];
        objData.PosFlag = obj['pf'];
        objData.Price = obj['prc'];
        objData.PrcType = obj['pt'];
        objData.Symbol = obj['qy'];
        objData.Time = obj['ti'];
        objData.TSym = obj['ts'];
        objData.TranType = obj['tt'];
        objData.User = obj['usr'];
        return objData;
    }

    updatePositionData(obj)
    {
        let objData = Utility.GetPositionModels(obj['tk'], obj['es'], obj['ot']);
        objData.CFBuyQty = obj['cbq'];
        objData.CFSellQty = obj['csq'];
        objData.discQty = obj['dq'];
        objData.Exchangeseg = obj['es'];
        objData.FillBuyQty = obj['fbq'];
        objData.FillSellQty = obj['fbq'];
        objData.Pcode = obj['ot'];
        objData.sSqrflg = obj['se'];
        objData.Series = obj['sf'];
        objData.Stikeprc = obj['st'];
        objData.Token = obj['tk'];
        objData.Tsym = obj['ts'];
        objData.Type = obj['ty'];
        objData.Exchange = Utility.segmentToExchange(obj['es']);
        return objData;
    }
}
