import { Pipe, PipeTransform } from '@angular/core';
import { Keys } from '../../services/keys';

@Pipe({ name: 'valuepipe' })
export class ValuePipe implements PipeTransform 
{
    constructor() {}
    transform(key) 
    {
        if(Keys.key_map[key] == undefined || Keys.key_map[key] == '')
        {
            return key;
        }
        else
        {
            return Keys.key_map[key];
        }
    }
} 