import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import { WatchListUtility } from 'src/app/services/watchlistUtility';

@Component({
  selector: 'app-optionchainmore',
  templateUrl: './optionchainmore.component.html',
  styleUrls: ['./optionchainmore.component.scss']
})
export class OptionchainmoreComponent implements OnInit {
  data;
  utility: any;
  isLot: boolean;
  isLotQty: number;
  expiryDate;
  WlArr = [];
  constructor( private dialog: MatDialog, private _bottomSheetRef: MatBottomSheetRef<OptionchainmoreComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public bottomData: any, private tradingRestService: TradingRestService,
    private translate: TranslateService, private snackBar: MatSnackBar) {
      this.data = bottomData;
      this.expiryDate = Utility.toDateTime(this.data.expirydate);
      this.utility = Utility;
      this.isLot = Utility.IsLot(this.data.callscrip.ExchangeName);
      this.isLotQty = parseInt(this.data.callscrip.BodLotQuantity, 10);
     }

  ngOnInit(): void {
  }

  onCount(buttontype, data, datatype) {
    if (buttontype === 'B') {
      if (datatype === 'call') {
          data.callqtycount = this.isLot? data.callqtycount + 1 : data.callqtycount + Utility.getNumber(this.data.callscrip.BodLotQuantity);
      } else {
          data.putqtycount = this.isLot? data.putqtycount + 1 : data.putqtycount + Utility.getNumber(this.data.putscrip.BodLotQuantity);
      }
     } else {
      if (datatype === 'call') {
          data.callqtycount = this.isLot? data.callqtycount - 1 : data.callqtycount - Utility.getNumber(this.data.callscrip.BodLotQuantity);
      } else {
          data.putqtycount = this.isLot? data.putqtycount - 1 : data.putqtycount - Utility.getNumber(this.data.putscrip.BodLotQuantity);
      }
     }
 }

addWLdata(stock) {
  const seltctedStock = stock.ExchangeSegment + '|' + stock.Token;
  this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
}

addStock(wl, stockdata) {
  const seltctedStock = stockdata.ExchangeSegment + '|' + stockdata.Token;
  WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
    if (result && msg === 'Success') {
      this.snackBar.open(this.translate.instant('books.add_stock'), '', { duration: 3000 });
    } else if (result && msg === 'Fail') {
      this.snackBar.open(this.translate.instant('books.server_fail'), '', { duration: 3000 });
    } else {
      this.snackBar.open(this.translate.instant('books.cancel_stock'), '', { duration: 3000 });
    }
  }.bind(this));
}

onSwipeDown() {
  this._bottomSheetRef.dismiss();
}
}
