import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class MetaColorService {

  light = '#eeeeee';
  dark = '#191919';
  blue = '#161625';

  constructor(private meta: Meta) { }

  changeThemeColor(color: string) {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    const metaThemeColor1 = document.querySelector('meta[name=apple-mobile-web-app-status-bar-style]');
    const metaThemeColor2 = document.querySelector('meta[name=msapplication-navbutton-color]');
    metaThemeColor.setAttribute('content', color);
    metaThemeColor1.setAttribute('content', color);
    metaThemeColor2.setAttribute('content', color);
  }

  changeThemeColorUsingMeta(color: string) {
    this.meta.updateTag({ content: color }, 'name=theme-color');
    this.meta.updateTag({ content: color }, 'name=apple-mobile-web-app-status-bar-style');
    this.meta.updateTag({ content: color }, 'name=msapplication-navbutton-color');
  }
}
