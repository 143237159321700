import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ColumnState, Utility } from 'src/app/services/utility';
import { Subscription } from 'rxjs';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { TableUtility } from 'src/app/services/tableutility';

@Component({
    selector: 'app-watchlist-popup',
    templateUrl: './watchlistpopup.component.html',
})
export class WatchlistPopupComponent implements OnInit, OnChanges, OnDestroy {
    columns = [];
    isReset;
    isSave;
    isMobile;
    orientationSubscription: Subscription;
    colStateMap = new Map<String, ColumnState>(); 
    @Input() formCustomDB;
    @Output() closeWatchlistPopup = new EventEmitter();
    @Input() isPopupOpen;
    isBack;

    constructor(private activatedRoute: ActivatedRoute, private location: Location) {
        this.isMobile = Utility.IS_MOBILE;
        this.isBack = true;
        this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
            if (!Utility.IS_MOBILE_LAND && this.isBack) {
                this.isBack = false;
                this.location.back();
            }
        });
    }

    ngOnDestroy(): void {
        this.orientationSubscription.unsubscribe();
    }

    ngOnChanges() {
        this.getCols();
        this.userSelectClick();
    }

    ngOnInit()
    {
        this.getCols();
        this.userSelectClick();
    }

    userSelectClick() {
        const defaultColKeys = this.getDefaultCols();
        let count =  0;
        this.columns.forEach(item => {
            if ((item.isChecked && defaultColKeys.includes(item.key)) || item.isChecked) {
                count ++;
            } 
        });
        this.isReset = (count === defaultColKeys.length) ? false : true;
    }

    getCols() {
        const tempColumns = Preferences.getItem('watchlistColumns', ReturnType.Undefined);
        this.columns = tempColumns? JSON.parse(JSON.stringify(tempColumns)) : JSON.parse(JSON.stringify(TableUtility.watchlistPopupConfig));
        this.getColumnState();
    }

    getColumnState(){
        this.isSave = false;
        this.colStateMap.clear();
        this.columns.forEach((element)=>{
            this.colStateMap.set(element.key, ColumnState.Stable);
        });
        
    }

    save() {
        Preferences.setItem('watchlistColumns', this.columns);
        Utility.getColInWL.next();

        if (this.isMobile) {
            this.location.back()
        } else {
            this.closeWatchlistPopup.emit();
        }
    }
   
    resetCol() {
        this.isReset = false;
        this.columns = [];
        this.columns = JSON.parse(JSON.stringify(TableUtility.watchlistPopupConfig));
        this.save();
    }

    checkReset(ev, data)
    {
        this.userSelectClick();
        (this.colStateMap.get(data.key) === ColumnState.Stable) ? this.colStateMap.set(data.key, ColumnState.Modified) : this.colStateMap.set(data.key, ColumnState.Stable);
        this.isSave =false;
        for (const [key, value] of this.colStateMap.entries()) {
            if(value === ColumnState.Modified){
                this.isSave = true;
                break;
            }
        }
    }


    getDefaultCols() {
        let columns;
        let defaultCols = [];
        columns = TableUtility.watchlistPopupConfig;
        columns.forEach(element => { 
            if (element.checked)
                defaultCols.push(element.key);
        });
        return defaultCols;
    }
}
