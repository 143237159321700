import { Keys } from '../services/keys';
import { ScripModel } from './scrip.model';

export class OrderModel {
    Usercomments: string;
    ordersource: string;
    displayOrderSource: string;
    modifiedBy: string;
    AlgoID: string;
    AlgoCategory: string;
    panNo: string;
    Exchange: string;
    DisplayExchange: string;
    TradeSymbol: string;
    ExchangeOrderNo: string;
    Action: string;
    ActionDisplay: string;
    OrderType: string;
    DisplayOrderType: string;
    DiscQty: number;
    CanceledQty: number;
    Price: number;
    TriggerPrice: number;
    Validity: string;
    ScripName: string;
    SymbolName: string;
    Status: string;
    DisplayStatus: string;
    FilledShares: number;
    ProductCode: string;
    ProductCodeDisplay: string;
    AvgPrice: number;
    TotalQty: number;
    PendingQty: number;
    ExchangeTimeStamp: string;
    RejectionReason: string;
    Remarks: string;
    OrderValidityDate: string;
    MarketProtectionPercentage: string;
    AccountId: string;
    RefLmtPrice: number;
    BrokerClient: string;
    ExpSsbDate: string;
    COPercentage: number;
    RequestID: string;
    Token: string;
    Ticksize: number;
    NoMktPro: number;
    Bqty: number;
    MarketProtectionFlag: number;
    Multiplier: number;
    Stat: string;
    DiscQtyPerc: number;
    SyomOrderId: string;
    Defmktproval: number;
    ExpDate: string;
    Minqty: number;
    Exseg: string;
    ExchangeSegment: string;
    User: string;
    Decprec: number;
    TicksizeToDisplay: number;
    InstName: string;
    PriceNumerator: string;
    GeneralNumerator: string;
    PriceDenomenator: string;
    GeneralDenomenator: string;
    series: string;
    orderentrytime: string;
    ordergenerationtype: string;
    sipindicator: string;
    ModifiedBy: string;
    DisplaySymbol: string;
    SymbolDetail: string;
    NestOrd: string;
    IsEdit = false;
    IsCancel = false;
    IsExit = false;
    isalertset = false;
    PendQtyDispl: string;
    DateTimeDispl: string;
    ExcelAction = '-';
    OrderedTime: string;
    GuiOrderId : string;
    CheckBoxValue = false;
    scripModel: ScripModel;
    TSym: string;
    DisplaySymbolDetail: string;
    opType: string;
    stkPrc: string;
    expDtSsb: string;
    isModified: boolean;


    applyStatusConditions()
    {
        if(this.Status.toLowerCase() === 'complete' || this.Status.toLowerCase() === 'cancelled' || this.Status.toLowerCase() === 'rejected' || this.Status.toLowerCase() === 'cancelled after market order' || this.Status.toLowerCase() === 'validation pending' || this.Status.toLowerCase() === 'amo cancelled')
        {
            this.IsEdit = false;
            this.IsCancel = false;
            this.IsExit = false;
        }
        else if(this.Status.toLowerCase() === 'open' || this.Status.toLowerCase() === 'trigger pending' || this.Status.toLowerCase() === 'after market order req received' || this.Status.toLowerCase() === 'modify after market order req received' || this.Status.toLowerCase() === 'amo new')
        {
            if (!(this.ProductCode === "CO" && this.SyomOrderId !== 'NA' && this.Status.toLowerCase() === "open"))
                this.IsEdit = true;
            else
                this.IsEdit = false;
            
            if (this.ProductCode !== "CO" && (this.ProductCode !== "BO" || this.SyomOrderId !== 'NA'))
                this.IsCancel = true;
            else
                this.IsCancel = false;

            
            if (this.ProductCode === "BO" || this.ProductCode === "CO" && !(this.SyomOrderId !== 'NA' && this.Status.toLowerCase() === "open"))
                this.IsExit = true;
            else
                this.IsExit = false;
        }
    }
}
