import { Component, Input, OnInit } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { BasketOrderBookModel } from 'src/app/model/basketorderbook.model';
import { Utility } from 'src/app/services/utility';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Preferences } from 'src/app/services/Preferences';

let basketOrderBook: BasketOrderBookComponent;
@Component({
    selector: 'app-basketorderbook',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketorderbook/basketorderbook.component.html',
    styleUrls: ['./basketorderbook.component.scss']
})

export class BasketOrderBookComponent implements OnInit {
    isMobile: boolean;
    isNoData: boolean;
    basketList: Array<BasketOrderBookModel>;
    totalPNL;

    currentValue = 0;
    currentInvestment = 0;
    currentReturn = 0;
    currentReturnPer = 0;
    @Input() isOpen;
    @Input() Height;
    constructor(private PS: PtPlusRestService,  private router: Router) {
        basketOrderBook = this;
        basketOrderBook.isMobile = Utility.IS_MOBILE;
        Utility.callInvestorBaskets.subscribe(data=> {
            this.getData();
        });
        
    }
    ngOnInit() {
        this.getData();
    }
    getData(){
        this.PS.getThematicBasketService().getPositions( Preferences.getPreference('userid'), this.getPositionsResponse);
    }
    getPositionsResponse(data, totalPNL, code, message)
    {
        basketOrderBook.isNoData = false;
        if (code === 200 && message === 'OK')
        {
            basketOrderBook.basketList = data;
            basketOrderBook.totalPNL = totalPNL;
            basketOrderBook.calculateInvestAmt();
        }
        else
            basketOrderBook.isNoData = true;
    }

    calculateInvestAmt()
    {
        let totalBasketPnl = 0;
        for(let i = 0; i < basketOrderBook.basketList.length; i++)
        {
            this.currentInvestment += basketOrderBook.basketList[i].total_investment - basketOrderBook.basketList[i].total_withdrawl;
            this.currentValue = this.currentValue + basketOrderBook.basketList[i].marketVal;
        }
        this.currentReturn = this.currentValue > this.currentInvestment ? this.currentValue - this.currentInvestment : -(this.currentInvestment - this.currentValue) ;
        if( this.currentInvestment !== 0)
            this.currentReturnPer = (this.currentReturn / this.currentInvestment) * 100;
    }

    onClickBasket(data) {
        Utility.basketPositionData = data;
        if(this.isMobile) {
            this.router.navigate(['/userbaskethome']);
        }
        else {
            var obj = {"name":"userbaskethome"}
            Utility.InputsDataGet.next(obj);
        }
    }

}