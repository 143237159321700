<app-header *ngIf="!isPopup && !isCustomDashboard" goback="1" [title]="'more.option_calc' | translate" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="isPopup && !isCustomDashboard" [title]="'more.option_calc' | translate"></app-titlebar>
<mat-dialog-content [class.mh-none] = "isMobile">
    <div [class.data-container]="!isPopup && !isCustomDashboard" class="placeorderfield row pb-lg-0 pb-5" [class.maxwidthsmall] = "isMobile" [class.p-2] = "!isMobile">
        <div [class.col-8]="!isMobile" [class.col-12]="isMobile">
            <div class="row">
                <div class="col-9 primetext linebutton"><span class="subcolor2" translate>optioncalc.scripname</span> {{scrip}}</div>
                <div class="col-3 text-right my-auto"><button mat-icon-button (click) = "openSearch()"><i class="icon-search"></i></button></div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <mat-label><span translate>derivative.strike_price</span></mat-label>
                        <input [formControl]="strikeControl" (keyup.enter)="onEnter('spotprc')" required matInput type = "text" oncopy="return false" onpaste="return false" inputmode="decimal" autocomplete="off" (keydown)="validatePrice($event)" name="strickprice" [(ngModel)] = "strickprice">
                        <mat-error *ngIf="strikeControl.errors?.required"><span translate>login.required</span></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <mat-label><span translate>optioncalc.spotprice</span></mat-label>
                        <input [formControl]="spotControl" id = "spotprc" (keyup.enter)="onEnter('vol')" required matInput type = "text" oncopy="return false" onpaste="return false" inputmode="decimal" autocomplete="off" (keydown)="validatePrice($event)" name="spotprice" [(ngModel)] = "spotprice"/>
                        <mat-error *ngIf="spotControl.errors?.required"><span translate>login.required</span></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <mat-label><span translate>optioncalc.volatility</span> (0-100)</mat-label>
                        <input [formControl]="volatilityControl" id = "vol" (keyup.enter)="onEnter('interest')" required matInput type = "text" oncopy="return false" onpaste="return false" inputmode="decimal" autocomplete="off" (keydown)="validatePrice($event)" name="volatility" [(ngModel)] = "volatility"/>
                        <mat-error *ngIf="volatilityControl.errors?.required"><span translate>login.required</span></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <mat-label><span translate>optioncalc.interest</span> (0-100)</mat-label>
                        <input [formControl]="interestControl" id = "interest" (keyup.enter)="onEnter('dividend')" required matInput type = "text" oncopy="return false" onpaste="return false" inputmode="decimal" autocomplete="off" (keydown)="validatePrice($event)" name="interest" [(ngModel)] = "interest"/>
                        <mat-error *ngIf="interestControl.errors?.required"><span translate>login.required</span></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <mat-label><span translate>optioncalc.dividend</span></mat-label>
                        <input [formControl]="dividendControl" id = "dividend" (keyup.enter)="onEnter('expiry')" required matInput type = "text" oncopy="return false" onpaste="return false" inputmode="decimal" autocomplete="off" (keydown)="validatePrice($event)" name="dividend" [(ngModel)] = "dividend"/>
                        <mat-error *ngIf="dividendControl.errors?.required"><span translate>login.required</span></mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <mat-label><span translate>optioncalc.expiry</span></mat-label>
                        <input  [formControl]="expiryControl" required id = "expiry" (keyup.enter)="onEnter('days')" matInput [matDatepicker]="picker1" [min]="minDate" [max]="maxDate" [(ngModel)]="expiryDate" (dateChange)="onDatePickerSelected()">
                        <mat-error *ngIf="expiryControl.errors?.required"><span translate>login.required</span></mat-error>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field >
                        <mat-label><span translate>optioncalc.days</span></mat-label>
                        <input [formControl]="daysControl" id = "days" (keyup.enter)="SubmitButtonClick()" required matInput type = "text" oncopy="return false" onpaste="return false" inputmode="numeric" autocomplete="off" (keydown)="validateQty($event)" name="days" [(ngModel)] = "days"/>
                        <mat-error *ngIf="daysControl.errors?.required"><span translate>login.required</span></mat-error>
                    </mat-form-field>
                </div>
                
            </div>
            <div class="row">
                <div class="col-md-12 mt-2"><small class="primetext" translate>optioncalc.optiontype</small>{{optiontype}}</div>
                <div class="col-12 sameer m-0">
                    <mat-radio-group (change)="onTypeSelection($event)">
                        <mat-radio-button  value="black" [checked] = "selectedType == 'black'"><span translate>optioncalc.bspm</span></mat-radio-button>
                        <mat-radio-button value="coxross" [checked] = "selectedType == 'coxross'"><span translate>optioncalc.crrbm</span></mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div [ngClass]="{'footerbtn' : isMobile}">
                <button [ngClass]="{'w-100' : isMobile, 'btn-positive' : true}" mat-flat-button (click) = "SubmitButtonClick()"><span translate>optioncalc.calculate</span></button>
            </div>
        </div>
        <div *ngIf = "showTable" [class.col-4]="!isMobile" [class.col-12]="isMobile">
                <table class="table-overview w-100">
                    <tr class="border-b">
                        <td class="py-3 text-left"> <span>{{optionTableHeader[0]}}</span></td>
                        <td class="py-3 text-right"><span>{{optionTableHeader[1]}}</span></td>
                        <td class="py-3 text-right"><span>{{optionTableHeader[2]}}</span></td>
                        <td class="py-3 text-right"><span>{{optionTableHeader[3]}}</span></td>
                    </tr>
                    <tbody *ngIf="calculateData != undefined">
                        <tr *ngFor="let item of calculateData; let i = index" class="border-b">
                            <td class="py-3 text-left"><span>{{item.Name}}</span></td>
                            <td class="py-3 text-right"><span>{{item.call | pricepipe: 3}}</span></td>
                            <td class="py-3 text-right"><span>{{item.put | pricepipe: 3}}</span></td>
                            <td class="py-3 text-right"><span>{{item.straddle | pricepipe: 3}}</span></td>
                        </tr>
                    </tbody>
                </table>
        </div>   
    </div>
</mat-dialog-content>
