<app-header *ngIf="isMobile" goback="1" [title]="'header.change_password' | translate" [isLoading]="isLoading" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'header.change_password' | translate" [isLoading]="isLoading"></app-titlebar>
<mat-dialog-content [class.mh-none] = "isMobile"  class="narrowpadding placeorderfield toplandscape">
   <div [class.data-container]="isMobile" [class.mt-2]="isMobile">
   <div [class.p-0]="!isMobile">
      <div class="row">
         <div class="col-md-6">
            <div class="row">
               <div class="col-12">
                  <div class="row">
                     <div class="col-12">
                       <div class="float-left mr-2"><img src="assets/img/user.svg" class="img-responsive img-circle user-dash" width="50px" alt="user" /></div>
                       <h5 class="mt-2 float-left tc-1"><small class="tc-4" translate>settings.user_id</small><br>{{uid}}</h5>
                     </div>
                   </div>
                  <div class="row">
                     <div class="col-12">
                        <mat-form-field>
                           <input (keyup.enter)="onEnter('newpass')" [type]="hideOldPwd ? 'password' : 'text'" matInput [(ngModel)]="oldPassword" [placeholder]="'settings.old_pwd' | translate" required>
                           <span (click)="hideOldPwd = !hideOldPwd" class="overlap-icons"><i [class.icon-visibility-off]="hideOldPwd" [class.icon-visibility-on]="!hideOldPwd"></i></span>
                           <mat-error *ngIf="oldPwdError"></mat-error>
                        </mat-form-field>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-12">
                        <mat-form-field>
                           <input  id = "newpass" (keyup.enter)="onEnter('conpass')" [type]="hideNewPwd ? 'password' : 'text'" matInput [(ngModel)]= "newPassword" [placeholder]="'settings.new_pwd' | translate" required>
                           <span (click)="hideNewPwd = !hideNewPwd" class="overlap-icons"><i [class.icon-visibility-off]="hideNewPwd" [class.icon-visibility-on]="!hideNewPwd"></i></span>
                           <mat-error *ngIf="newPwdError"></mat-error>
                        </mat-form-field>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-12">
                        <mat-form-field>
                           <input id = "conpass" (keyup.enter)="passwordSubmit()" [type]="hideConfirmPwd ? 'password' : 'text'" matInput  [(ngModel)]= "confirmPassword" [placeholder]="'settings.confirm_pwd' | translate" required>
                           <span (click)="hideConfirmPwd = !hideConfirmPwd" class="overlap-icons"><i [class.icon-visibility-off]="hideConfirmPwd" [class.icon-visibility-on]="!hideConfirmPwd"></i></span>
                           <mat-error *ngIf="confirmPwdError"></mat-error>
                        </mat-form-field>
                     </div>
                  </div>
                  <div [class.popup-footer]="!isMobile" class="row mb-2 mt-2">
                     <div [class.col-6]="!isMobile" [class.offset-6]="!isMobile" [class.col-12]="isMobile" class="text-right">
                        <button [class.w-100]="isMobile" class="btn-primary" mat-flat-button type="submit" (click) = "passwordSubmit()"><span translate>settings.submit</span></button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-6">
            <div class="passwordpolicywrapper">
               <p translate>settings.pwd_policy</p>
               <ul>
                  <li *ngFor= "let item of PasswordPolicy">{{item}}</li>
               </ul>
            </div>
         </div>
      </div>
      
     
   </div>
   </div>
</mat-dialog-content>
