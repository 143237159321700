<app-header (onTitleClick)="onTitleClick()" *ngIf = "showHeader" [goback]="goback" [title]="'equity.advance_decline' | translate" [isLoading]="isLoading" (reloadPage) = "reloadData()"></app-header>

	<div #sectionNeedToScroll class="row">
		<div class="col-12 padding8">
			<mat-card [ngStyle]="Height">
				<mat-card-content>
					
					<div [class.pt-0]="!isMobile" [class.data-container] = "showHeader">
						<ngx-pull-to-refresh
						spinnerColor="#ff0000"
						distanceForRefresh="80"
						[isEnable]="isMobile"
						[targetElement]="targetElement"
						(refresh)="myRefreshEvent($event, 'refresh')"
						(loadMore)="alert('loadmore')"
						class = "loader">
							<div *ngIf = "!noData" class="row">
								<div [class.col-12]="numberofcol==1" [class.col-6]="numberofcol==2" [class.col-4]="numberofcol==3" [class.col-3]="numberofcol==4" *ngFor="let data of indicesArr;">
									<div class="list-view border-b">
										<div class="row">
											<div class="col-12">
												<div class="row ts-4">
													<div class="col-7 primetext">{{data.OmToken}}</div>
													<div class="col-5 text-right">
														<span [class.imnegative]="data.Change<0" [class.impositive]="data.Change>0" class="boldme">{{data.IndexValue | pricepipe:2}} <small>{{data.Change | pricepipe:2}}</small></span>
													</div>
												</div>
												<div class="row ts-1 tc-2">
													<div class="col-12">
														<table class="border-spacing: 0px; w-100">
															<tr>
																<td *ngIf="data.Advance>0" [style.width] = "data.AdvancePer" translate>equity.advance</td>
																<td *ngIf="data.Neutral>0" [style.width] = "data.NeutralPer" [class.text-left]="data.Advance <= 0 && data.Decline > 0" [class.text-center]="data.Advance > 0 && data.Decline > 0" [class.text-right]="data.Advance > 0 && data.Decline <= 0" translate>equity.neutral</td>
																<td *ngIf="data.Decline>0" [style.width] = "data.DeclinePer" class="text-right" translate>equity.decline</td>
															</tr>
															<tr>
																<td *ngIf="data.Advance>0" class="a"></td>
																<td *ngIf="data.Neutral>0" class="n"></td>
																<td *ngIf="data.Decline>0" class="d"></td>
															</tr>
															<tr>
																<td *ngIf="data.Advance>0">{{data.Advance}}</td>
																<td *ngIf="data.Neutral>0" [class.text-left]="data.Advance <= 0 && data.Decline > 0" [class.text-center]="data.Advance > 0 && data.Decline > 0" [class.text-right]="data.Advance > 0 && data.Decline <= 0">{{data.Neutral}}</td>
																<td *ngIf="data.Decline>0" class="text-right">{{data.Decline}}</td>
															</tr>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf = "noData">
								<app-nodata [btnTitle]="'utility.reload' | translate" (refreshEvent) = "getIndicesData()"></app-nodata>
							</div>
						</ngx-pull-to-refresh>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
</div>