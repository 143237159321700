import { Component, Output, EventEmitter, OnInit, Input, OnChanges, Inject, Injector, ChangeDetectorRef, HostListener, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StockMoreOptionComponent } from '../../stockmoreoption/stockmoreoption.component';
import { Keys } from 'src/app/services/keys';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from '../../../../services/tableutility';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { Mouse } from 'src/app/services/mouse';
import { Subject } from 'rxjs';
import { ChannelType } from '../../../../services/utility';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-scannerdetail',
    templateUrl: './scannerdetail.component.html',
    styleUrls: ['./scannerdetail.component.scss']
})

export class ScannerDetailComponent extends BaseComponentDirective implements OnInit, OnDestroy, OnChanges {
    @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
    readonly HEADER_HEIGHT = 60;
    displayedColumns = TableUtility.headerConfig;
    @Output() load = new EventEmitter<boolean>();
    @Output() openStockDetailPage = new EventEmitter<string>();
    @Output() setLoading = new EventEmitter<boolean>();
    @Input() scannerValue: string;
    @Input() workspaceName: string;
    @Input() isCustomDashboard: string;
    targetElement: Element;
    isLoading = true;
    isNodata: boolean;
    dataType = '';
    sendJsonObj = {};
    selected = '';
    multipleScripData = [];
    dropDown: boolean;
    indexDropDown: boolean;
    typeDrownDown: boolean;
    dropDownValue = [];
    indexArray = [];
    isDHeight = true;
    DHeight = {};
    selectedIndex = '';
    ObjData = {};
    isMobile = true;
    utility: any;
    Height: any;
    container;
    left: any;
    modesub: Subscription;
    WlArr = [];
    scannerTypes = {};
    cellview = false;
    isReload = false;
    M;
    selectedRowIndex : any;
    flashType = 'defaultFlash';
    selectedTool = "my tool tip";
    createGttFlag = environment.isGTTEnable;
    toolMap = {
        'openhigh' : this.translate.instant('all.openhigh_tool'),
        'openlow' : this.translate.instant('all.openlow_tool'),
        'r1' : this.translate.instant('all.r1_tool'),
        'r2' : this.translate.instant('all.r2_tool'),
        'r3' : this.translate.instant('all.r3_tool'),
        'pp' : this.translate.instant('all.pp_tool'),
        's1' : this.translate.instant('all.s1_tool'),
        's2' : this.translate.instant('all.s2_tool'),
        's3' : this.translate.instant('all.s3_tool'),
        'ltphigh50DMA' : this.translate.instant('all.ltphigh50DMA_tool'),
        'ltplow50DMA' : this.translate.instant('all.ltplow50DMA_tool'),
        'ltphigh200DMA' : this.translate.instant('all.ltphigh200DMA_tool'),
        'ltplow200DMA' : this.translate.instant('all.ltplow200DMA_tool'),
        'macdhigh' : this.translate.instant('all.macdhigh_tool'),
        'macdlow' : this.translate.instant('all.macdlow_tool'),
        'rsi20' : this.translate.instant('all.rsi20_tool'),
        'rsi2030' : this.translate.instant('all.rsi2030_tool'),
        'rsi3070' : this.translate.instant('all.rsi3070_tool'),
        'rsi7080' : this.translate.instant('all.rsi7080_tool'),
        'rsihigh80' : this.translate.instant('all.rsihigh80_tool'), 
        'priceupvolup' : this.translate.instant('all.priceupvolup_tool'),
        'pricedownvolup' : this.translate.instant('all.pricedownvolup_tool'),
        'priceupvoldown' : this.translate.instant('all.priceupvoldown_tool'),
        'pricedownvoldown' : this.translate.instant('all.pricedownvoldown_tool'),
        'ltpupckt' : this.translate.instant('all.ltpupckt_tool'),
        'ltplowckt' : this.translate.instant('all.ltplowckt_tool'),
        'ltphigh' : this.translate.instant('all.ltphigh_tool'),
        'ltplow' : this.translate.instant('all.ltplow_tool'),
        '52wkhigh' : this.translate.instant('all.52wkhigh_tool'),
        '52wklow' : this.translate.instant('all.52wklow_tool')       
    };

    scannerCondetion = {'r1':'','r2':'','r3':'','pp':'','s1':'','s2':'','s3':'',
                        'priceupvolup':'','pricedownvolup':'','priceupvoldown':'','pricedownvoldown':'',
                        'openhigh':'','openlow':'',
                        'ltpupckt':'','ltplowckt':'',
                        'ltphigh':'','ltplow':'','52wkhigh':'','52wklow':'',
                        'ltphigh50DMA':'','ltplow50DMA':'','ltphigh200DMA':'','ltplow200DMA':'','macdhigh':'','macdlow':'',
                        'rsi20':'','rsi2030':'','rsi3070':'','rsi7080':'','rsihigh80':''
                    }          
    constructor(elRef: ElementRef, private injector: Injector, private PS: PtPlusRestService, private router: Router, 
    private tradingRestService: TradingRestService, private dialog: MatDialog, private _bottomSheet: MatBottomSheet, private detector: ChangeDetectorRef, private translate: TranslateService, private snackBar: MatSnackBar) {
        super(elRef.nativeElement);
        if (!Utility.IS_MOBILE){
            // this.container = <ComponentContainer>this.injector.get('Container');
        }
        this.M = Mouse;
        this.isMobile = Utility.IS_MOBILE;
        this.utility = Utility;
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
            this.flashType = Preferences.getItem('flashType');
          }
        if (Utility.IS_MOBILE) {
            this.dataType = Preferences.getItem('datatype', ReturnType.String);
            this.setScannerDetail();
            Utility.scannerName = this.dataType;
        }
        Utility.flashType.subscribe(obj => {
            this.flashType = obj;
          });
        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });
        
        this.scannerTypes =
            {  'Resistance & Support': this.translate.instant('scanners.res_sup'),
              'Volume Shocker': this.translate.instant('scanners.vol_shocker'),
              'Circuit Breakers': this.translate.instant('scanners.circuit_break'),
              'High/Low Breakers': this.translate.instant('scanners.HLBreaker'),
              'Open High/Low': this.translate.instant('scanners.openHL'),
              'Technical Scanners': this.translate.instant('scanners.tech_scanner'),
              'bullish': this.translate.instant('scanners.bullish_covered_call'),
              'neutral': this.translate.instant('scanners.neutral_long'),
              'bearish': this.translate.instant('derivative.bearish'),
              'IV': this.translate.instant('scanners.IV-scanner') };
    }

    setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.onAppearing();
            this.changeMode();
          }, 50);
        }
        else {
          this.onDisappearing();
        }
    }

    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Scanner);
    }

    onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Scanner);
    }
    

    changeMode(){
        this.container = this.container === undefined ? Utility.setSize : this.container;
        this.Height = {
            'height': (this.container.height - TableUtility.SHeight) + 'px',
            'overflow': 'auto'
          };
          this.left = {
            'left': (this.container.width - TableUtility.SLeft) + 'px'
        };

        this.detector.detectChanges();
        if (this.container.width < Utility.MinWidth)
            this.cellview = true;
        else {
            this.cellview = false;
            this.multipleScripData.map((data: any) => {
                data.show = false;
            })
        }
    }
    ngOnInit() {
        this.targetElement = document.querySelector('html');
        if(this.inputs !== undefined) {
            this.isCustomDashboard = this.inputs.isCustomDashboard;
            this.scannerValue = this.inputs.scannerValue;
            this.workspaceName = this.inputs.workspaceName;
        }
        if (!Utility.IS_MOBILE && this.isCustomDashboard) {
            this.dropDown = false;
            this.indexDropDown = false;
            this.dataType = this.scannerValue;
        }
        if (this.isCustomDashboard) {
            this.changeMode();

            /* this.container.on('resize', _ => {
                this.changeMode();
            });
            this.container.on('show', _ => {
                if (this.multipleScripData.length > 0)
                    this.highlight(this.multipleScripData[0], 0);
            });*/
        }
        this.setScannerDetail();
        this.selectedTool = this.toolMap[this.selected];

        Utility.webReloadComponent.subscribe(data => 
        {
            if(this.isReload === false && data === this.dataType)
            //if(this.isReload === false && (data === 'Resistance & Support' || data === 'Volume Shocker' || data === 'Circuit Breakers' || data === 'High/Low Breakers' || data === 'Open High/Low' || data === 'Technical Scanners'))
            {
                this.isReload = true;
                this.reloadData();
            }
        });

        this.onAppearing();
    }
    addWLdata(stock) {
        const seltctedStock = stock.ExchangeSegment + '|' + stock.Token;
        this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
    }
    addStock(wl, stockdata) {
        this.setLoading.emit(true);
        const seltctedStock = stockdata.ExchangeSegment + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
            this.setLoading.emit(false);
            if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('books.add_stock'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('books.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('books.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
    }
    ngOnChanges() {
        if (!Utility.IS_MOBILE) {
            this.dropDown = false;
            this.indexDropDown = false;
            this.dataType = this.scannerValue;
        }
        this.setScannerDetail();
    }
    setScannerDetail() {
        this.setLoading.emit(this.isLoading);
        this.typeWiseDropDownValue(this.dataType);
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        if (Utility.IS_MOBILE_LAND) {
            if(window.scrollY > this.HEADER_HEIGHT && this.isDHeight){
            this.DHeight = {
                'height': 'calc(100vh - 48px)' ,
                'overflow': 'auto',
                'scroll-behavior': 'smooth',
            };
            let element:HTMLElement;
            element = document.getElementById("ob_tbl1") as HTMLElement;
            element?.focus();
            this.isDHeight = false;
            }
            else if(window.scrollY < this.HEADER_HEIGHT){
            this.DHeight = {
                'height': '100%',
                'overflow': 'auto',
                'scroll-behavior': 'smooth'
            };
            this.isDHeight = true;
            }
        }
    }

    reloadData()
    {
        this.load.emit(true);
        this.isLoading = true;
        if(this.dataType === 'bullish' || this.dataType === 'bearish' || this.dataType === 'neutral' || this.dataType === 'IV')
        {
            this.PS.getScannerService().getNQDerivativeScanner(this.sendJsonObj, this.scannerByIndexResponse.bind(this));
        }
        else if(this.dataType === 'Resistance & Support' || this.dataType === 'Volume Shocker' || this.dataType === 'Circuit Breakers' || this.dataType === 'High/Low Breakers' || this.dataType === 'Open High/Low' || this.dataType === 'Technical Scanners')
        {
            this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
        }
    }

    typeWiseDropDownValue(value) {
        this.load.emit(true);
        switch (value) {
            case 'bullish':
                this.dropDown = true;
                this.dropDownValue = [{ 'value': 'in_the_money', 'viewValue': 'IN THE MONEY' }, { 'value': 'out_the_money', 'viewValue': 'OUT THE MONEY' }];
                this.selected = 'in_the_money';
                this.sendJsonObj = { 'data_type': this.dataType, 'scanner_type': this.selected, 'type': 'OPTION', 'limit': 20 };
                this.PS.getScannerService().getNQDerivativeScanner(this.sendJsonObj, this.scannerByIndexResponse.bind(this));
                break;
            case 'bearish':
                this.dropDown = true;
                this.dropDownValue = [{ 'value': 'in_the_money', 'viewValue': 'IN THE MONEY' }, { 'value': 'out_the_money', 'viewValue': 'OUT THE MONEY' }];
                this.selected = 'in_the_money';
                this.sendJsonObj = { 'data_type': this.dataType, 'scanner_type': this.selected, 'type': 'OPTION', 'limit': 20 };
                this.PS.getScannerService().getNQDerivativeScanner(this.sendJsonObj, this.scannerByIndexResponse.bind(this));
                break;
            case 'neutral':
                this.dropDown = true;
                this.dropDownValue = [{ 'value': 'CALL', 'viewValue': 'CALL' }, { 'value': 'PUT', 'viewValue': 'PUT' }];
                this.selected = 'CALL';
                this.sendJsonObj = { 'data_type': this.dataType, 'scanner_type': this.selected, 'type': 'OPTION', 'limit': 20 };
                this.PS.getScannerService().getNQDerivativeScanner(this.sendJsonObj, this.scannerByIndexResponse.bind(this));
                break;
            case 'IV':
                this.dropDown = true;
                this.dropDownValue = [{ 'value': 'CALL', 'viewValue': 'CALL' }, { 'value': 'PUT', 'viewValue': 'PUT' }];
                this.selected = 'CALL';
                this.sendJsonObj = { 'data_type': this.dataType, 'scanner_type': this.selected, 'type': 'OPTION', 'limit': 20 };
                this.PS.getScannerService().getNQDerivativeScanner(this.sendJsonObj, this.scannerByIndexResponse.bind(this));
                break;
            case 'Open High/Low':
                this.isLoading = true;
                this.indexDropDown = true;
                this.typeDrownDown = true;
                this.getIndexName();
                this.selectedIndex = 'nifty50';
                this.displayedColumns = TableUtility.OHLheaderConfig;
                this.selected = 'openhigh';
                this.dropDownValue = [{ 'value': 'Open=High', 'viewValue': 'openhigh' }, { 'value': 'Open=Low', 'viewValue': 'openlow' }];
                this.ObjData = {'exchange_segment': 'nse_cm', 'index_name': 'nifty50', 'type': 'openhigh' };
                this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
                break;
            case 'Resistance & Support':
                this.isLoading = true;
                this.indexDropDown = true;
                this.typeDrownDown = true;
                this.getIndexName();
                this.selectedIndex = 'nifty50';
                this.displayedColumns = TableUtility.RSheaderConfig;
                this.selected = 'r1';
                this.dropDownValue = [{ 'value': 'R1', 'viewValue': 'r1' }, { 'value': 'R2', 'viewValue': 'r2' },
                { 'value': 'R3', 'viewValue': 'r3' }, { 'value': 'PP', 'viewValue': 'pp' },
                { 'value': 'S1', 'viewValue': 's1' }, { 'value': 'S2', 'viewValue': 's2' }, { 'value': 'S3', 'viewValue': 's3' }];
                this.ObjData = { 'exchange_segment': 'nse_cm', 'index_name': 'nifty50', 'type': 'r1' };
                this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
                break;
            case 'Technical Scanners':
                this.isLoading = true;
                this.indexDropDown = true;
                this.typeDrownDown = true;
                this.getIndexName();
                this.selectedIndex = 'nifty50';
                this.selected = 'ltphigh50DMA';
                this.displayedColumns = TableUtility.TSdmafiftyheaderConfig;
                Utility.scannerTypeValue = this.selected;
                this.dropDownValue = [{ 'value': 'LTP>=50 DMA', 'viewValue': 'ltphigh50DMA' }, { 'value': 'LTP<=50 DMA', 'viewValue': 'ltplow50DMA' },
                { 'value': 'LTP>=200 DMA', 'viewValue': 'ltphigh200DMA' }, { 'value': 'LTP<=200 DMA', 'viewValue': 'ltplow200DMA' },
                { 'value': 'MACD above Signal Line', 'viewValue': 'macdhigh' }, { 'value': 'MACD below Signal Line', 'viewValue': 'macdlow' },
                { 'value': 'RSI below 20', 'viewValue': 'rsi20' }, { 'value': 'RSI between 20 & 30', 'viewValue': 'rsi2030' },
                { 'value': 'RSI between 30 & 70', 'viewValue': 'rsi3070' }, { 'value': 'RSI between 70 & 80', 'viewValue': 'rsi7080' },
                { 'value': 'RSI above 80', 'viewValue': 'rsihigh80' }];
                this.ObjData = { 'exchange_segment': 'nse_cm', 'index_name': 'nifty50', 'type': 'ltphigh50DMA' };
                this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
                break;
            case 'Volume Shocker':
                this.isLoading = true;
                this.indexDropDown = true;
                this.typeDrownDown = true;
                this.getIndexName();
                this.selectedIndex = 'nifty50';
                this.displayedColumns = TableUtility.vsheaderConfig;
                this.selected = 'priceupvolup';
                this.dropDownValue = [{ 'value': 'Price Up & Vol Up', 'viewValue': 'priceupvolup' }, { 'value': 'Price Down & Vol Up', 'viewValue': 'pricedownvolup' },
                { 'value': 'Price Up & Vol Down', 'viewValue': 'priceupvoldown' }, { 'value': 'Price Down & Vol Down', 'viewValue': 'pricedownvoldown' }];
                this.ObjData = { 'exchange_segment': 'nse_cm', 'index_name': 'nifty50', 'type': 'priceupvolup' };
                this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
                break;
            case 'Circuit Breakers':
                this.isLoading = true;
                this.indexDropDown = true;
                this.typeDrownDown = true;
                this.getIndexName();
                this.selectedIndex = 'nifty50';
                this.selected = 'ltpupckt';
                this.displayedColumns = TableUtility.CktBrkheaderConfig;
                this.dropDownValue = [{ 'value': 'LTP>=Upper Circuit', 'viewValue': 'ltpupckt' }, { 'value': 'LTP<=Lower Circuit', 'viewValue': 'ltplowckt' }];
                this.ObjData = { 'exchange_segment': 'nse_cm', 'index_name': 'nifty50', 'type': 'ltpupckt' };
                this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
                break;
            case 'High/Low Breakers':
                this.isLoading = true;
                this.indexDropDown = true;
                this.typeDrownDown = true;
                this.getIndexName();
                this.selectedIndex = 'nifty50';
                this.selected = 'ltphigh';
                this.displayedColumns = TableUtility.HLheaderConfig;
                this.dropDownValue = [{ 'value': 'LTP=High', 'viewValue': 'ltphigh' }, { 'value': 'LTP=Low', 'viewValue': 'ltplow' },
                { 'value': 'LTP=52 Week High', 'viewValue': '52wkhigh' }, { 'value': 'LTP=52 Week Low', 'viewValue': '52wklow' }];
                this.ObjData = { 'exchange_segment': 'nse_cm', 'index_name': 'nifty50', 'type': 'ltphigh' };
                this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
                break;
        }
    }

    scannerByIndexResponse(data, code, message) {
        this.isLoading = false;
        this.isReload = false;
        this.load.emit(this.isLoading);
        this.setLoading.emit(this.isLoading);
        if (code === Keys.RES_CODE_SUCCESS && data.length > 0) {
                this.multipleScripData = data;
                this.isNodata = false;
                setTimeout(() => {
                    if (!Utility.IS_MOBILE)
                        this.highlight(this.multipleScripData[0], 0);
                }, 700);
        } else {
            this.isNodata = true;
        }
    }

    getIndexName() {
        this.PS.getScannerService().getAllIndexName(this.setIndexNames.bind(this));
    }

    setIndexNames(code, data, message) {
        if (code === 200 && message === 'OK') {
            this.indexArray = data;
        }
    }

    openPlaceOrder(buttontype, data) {
        Utility.openPlaceOrder(this.router, data.Token, data.ExchangeSegment, buttontype, this.dialog);
    }
    openStockDetail(data) {
        this.multipleScripData.map((data: any) => {
            data.show = false;
        })
        const obj = {'isCustomDashboard': true,'fromMultiView': true,'fromStockDetail': true,'isHeader': true,'MVtype':'stock','backbtnEnable':false, 'IsStockSpecific': false, 'c_name': 'scannerdetail', 'token': data.Token, 'exch': data.ExchangeSegment, 'symbol': data.TradingSymbol, 'series': data.series};
        Utility.SELECTED_SCRIP = Utility.getScripModel(data.Token,data.ExchangeSegment);
        this.outputs.emit(obj);
    }

    onSelection(type) {
        this.isLoading = true;
        this.setLoading.emit(this.isLoading);
        this.sendJsonObj['scanner_type'] = type;
        this.load.emit(this.isLoading);
        this.PS.getScannerService().getNQDerivativeScanner(this.sendJsonObj, this.scannerByIndexResponse.bind(this));
    }

    onIndexDropDownSelection(val) {
        this.isLoading = true;
        this.setLoading.emit(this.isLoading);
      this.ObjData['index_name'] = val;
      this.load.emit(this.isLoading);
      this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
    }

    onTypeDropDownSelection(val) {
        this.selectedTool = this.toolMap[val];

        Utility.scannerTypeValue = val;

        if(val == 'ltplow50DMA' || val == 'ltphigh50DMA')
          this.displayedColumns = TableUtility.TSdmafiftyheaderConfig;

        if(val == 'ltplow200DMA' || val == 'ltphigh200DMA')
          this.displayedColumns = TableUtility.TSdmatwoheaderConfig;

        if(val == 'rsi20' || val === 'rsi2030' || val == 'rsi3070' || val == 'rsi7080' || val =='rsihigh80')
          this.displayedColumns = TableUtility.TSrsiheaderConfig;

        if(val == 'macdhigh' || val == 'macdlow')
          this.displayedColumns = TableUtility.TSmacdheaderConfig ;

        this.isLoading = true;
        this.setLoading.emit(this.isLoading);
        this.ObjData['type'] = val;
        this.load.emit(this.isLoading);
        this.PS.getScannerService().getScannersDataByIndexName(this.ObjData, this.scannerByIndexResponse.bind(this));
    }
    result(option) {
        const obj = {frmScanner: true, data: option};
        if (this.isMobile) {
            this._bottomSheet.open(StockMoreOptionComponent, {
                data: obj, restoreFocus: false
            });
        }

      }

    handleMouseOver(row) {
        const Symbolname = row.TradingSymbol;
        this.multipleScripData.map((data: any) => {
        if (Symbolname && data.TradingSymbol && data.TradingSymbol === Symbolname) {
            data.show = true;
        }
        });
    }

    handleMouseLeave(row) {
        const Symbolname = row.TradingSymbol;
        this.multipleScripData.map((data: any) => {
        if (data.TradingSymbol === Symbolname) {
            data.show = false;
        }
        });
    }
    highlight(row: any, i: number){
        Mouse.IS_MOUSE = false;
        if(this.selectedRowIndex !== i && !Utility.IS_MOBILE) {
        let element:HTMLElement;
        if(this.cellview) {
            element = document.getElementById("ob_cell"+i) as HTMLElement;
        }
        else {
            element = document.getElementById("ob_tbl"+i) as HTMLElement;
        }
        element?.focus();
        }
        this.selectedRowIndex = i;
        Utility.SELECTED_SCRIP = row;
        Utility.selectedTab = TableUtility.scanner;
        if(this.isMobile) {
        this.result(this.multipleScripData[i]);
        }
    }

    @HostListener('window:keydown.arrowdown', ['$event'])
    keyDownEvent(event: KeyboardEvent) {
        // if (Utility.selectedTab === TableUtility.scanner && !Utility.isDialogOpened && !this.isMobile  && Utility.isCompInCurrentWorkspace(this.scannerValue, this.workspaceName)) {
        if (Utility.selectedTab === TableUtility.scanner && !Utility.isDialogOpened && !this.isMobile) {
            event.preventDefault();
            if(((this.selectedRowIndex + 1) < this.multipleScripData.length)) {
                var nextrow = this.multipleScripData[this.selectedRowIndex + 1];
                this.highlight(nextrow, this.selectedRowIndex + 1);
            } else {
                this.highlight(this.multipleScripData[0], 0);
            }
        }
    }

    @HostListener('window:keydown.arrowup', ['$event'])
    keyUpEvent(event: KeyboardEvent) {
        // if (Utility.selectedTab === TableUtility.scanner && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace(this.scannerValue, this.workspaceName)) {
        if (Utility.selectedTab === TableUtility.scanner && !Utility.isDialogOpened && !this.isMobile) {
            event.preventDefault();
            if(((this.selectedRowIndex - 1) >= 0)) {
                var nextrow = this.multipleScripData[this.selectedRowIndex - 1];
                this.highlight(nextrow, this.selectedRowIndex - 1); 
            } else {
                this.highlight(this.multipleScripData[this.multipleScripData.length - 1], this.multipleScripData.length - 1);
            }
        }
    }

    myRefreshEvent(event: Subject<any>, message: string) {
        setTimeout(() => {
          this.reloadData();
          event.next();
        }, 1000);
      }
      
      alert(message: string) {
        
      }

    onTitleClick() {
        this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    ngOnDestroy()
    {
        this.onDisappearing();
    }
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        Utility.setSize = this.container;
        //if (width < Utility.MinWidth)
        // this.cellview = true;
        // else
        // this.cellview = false;
        this.changeMode();
      }

    openTrigger(data) {
    Utility.openCreateTrigger(this.router, data.Token, data.ExchangeSegment, this.dialog);
    }
}
