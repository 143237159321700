<style>
    .myDiv {
      border: 1px outset rgb(219, 219, 219);
      background-color: rgb(247, 247, 247);
      padding-left: 5px;
      padding-bottom: 5px;
      bottom: 0;
      right: 0;
    }
</style>


    <div class="myDiv">

        <div class="row">
            <div class="col-10" style="color: green; padding-top: 15px;"><h1>Order Placed</h1></div>
            <div class="col-2 text-right">
                <button mat-icon-button ><i class="icon-close"></i></button>
            </div>
        </div>

        <h1>AXISBANK-EQ x 51</h1>
        <h3>Ref No. jsgafkarh89879</h3>
    </div>


                    <!-- <table mat-table [dataSource]="fcmNotificationList">
                        <ng-container matColumnDef="symbol" >
                            <th mat-header-cell *matHeaderCellDef> Title</th>
                            <td mat-cell *matCellDef="let element"> {{element.text}} </td>
                        </ng-container>

                        <ng-container matColumnDef="symbol" >
                            <th mat-header-cell *matHeaderCellDef> Title</th>
                            <td mat-cell *matCellDef="let element"> {{element.text}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table> 

                    <div *ngFor = "let data of fcmNotificationList" >
                        

                        <div class="row">
                            <div class="col" style="background-color:lavender;">{{data.obj["title"]}}</div>
                            <div class="col" style="background-color:orange;">{{data.obj["date"]}}</div>
                        </div>

                        <div class="row">
                            <div class="col" style="background-color:lavender;">{{data.obj["description"]}}</div>
                        </div>
                    </div>-->