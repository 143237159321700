import { Component, Input, OnInit, OnDestroy, Output, ElementRef, EventEmitter, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { ScripModel } from 'src/app/model/scrip.model';
import { Preferences } from 'src/app/services/Preferences';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import * as $ from 'jquery';
import { Location } from '@angular/common';

import {
    widget,
    IChartingLibraryWidget,
    ChartingLibraryWidgetOptions,
    LanguageCode,
    ResolutionString,
} from '../../../../assets/charting_library';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { DataFeederClass } from './datafeeder';
import { SaveLoadAdapterClass } from './saveloadadapter';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { environment } from 'src/environments/environment';
let tradingViewComponent: TradingViewComponent;
@Component({
    selector: 'app-tradingview',
    templateUrl: '../../'+environment.baseviewpath+'/tradingview/tradingview.component.html',
    styleUrls: ['./tradingview.component.scss']
})
export class TradingViewComponent extends BaseComponentDirective implements OnInit, OnDestroy, AfterViewInit {
    private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '/assets/charting_library/';
    private _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';
    private _userId: ChartingLibraryWidgetOptions['user_id'] = 'public_user_id';
    private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
    private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
    private _containerId: ChartingLibraryWidgetOptions['container'] = 'tv_chart_container';
    private _tvWidget: IChartingLibraryWidget | null = null;
    style={'height':100 + 'vh'};
    themestyle;
    themeNo;
    sb;
    bb;
    themeName;
    scripModel: ScripModel;
    datafeed;
    saveloadadapter;
    overrides;
    localdivid;
    isMobile;
    utility;
    isHeader;
    ismob;
    constructor(elRef: ElementRef, private activatedRoute: ActivatedRoute,private location: Location, private tradingRestService: TradingRestService, private PS: PtPlusRestService, private router: Router, public dialog: MatDialog, private ngZone: NgZone) {
        super(elRef.nativeElement);
        tradingViewComponent = this;
        this.themeNo = Preferences.getItem('theme');
        this.localdivid = Utility.LocalDivId++;
        this.isMobile = Utility.IS_MOBILE;
        this.utility = Utility;
    }

    getLanguageFromURL(): LanguageCode | null {
        const regex = new RegExp('[\\?&]lang=([^&#]*)');
        const results = regex.exec(location.search);

        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
    }

    ngOnInit() {
        
    }

    getTheme(){
        if (this.themeNo === '1')
            this.themeName = 'Light';
        else
            this.themeName = 'Dark';
        this._tvWidget?.changeTheme(this.themeName);
    
        this.overrides = {    
            "paneProperties.background": this.rgb2hex(this.themestyle.backgroundColor),
            "paneProperties.backgroundType": "solid",
            "scalesProperties.textColor": this.rgb2hex(this.themestyle.stopColor),
            "mainSeriesProperties.candleStyle.wickUpColor": this.rgb2hex(this.themestyle.columnRuleColor),
            "mainSeriesProperties.candleStyle.wickDownColor": this.rgb2hex(this.themestyle.borderColor),
            "mainSeriesProperties.haStyle.upColor": this.rgb2hex(this.themestyle.columnRuleColor),
            "mainSeriesProperties.haStyle.downColor": this.rgb2hex(this.themestyle.borderColor),
            "mainSeriesProperties.haStyle.borderUpColor": this.rgb2hex(this.themestyle.columnRuleColor),
            "mainSeriesProperties.haStyle.borderDownColor": this.rgb2hex(this.themestyle.borderColor),
            "mainSeriesProperties.candleStyle.upColor": this.rgb2hex(this.themestyle.columnRuleColor),
            "mainSeriesProperties.candleStyle.downColor": this.rgb2hex(this.themestyle.borderColor),
            "mainSeriesProperties.candleStyle.borderUpColor": this.rgb2hex(this.themestyle.columnRuleColor),
            "mainSeriesProperties.candleStyle.borderDownColor": this.rgb2hex(this.themestyle.borderColor),
            "mainSeriesProperties.hollowCandleStyle.upColor": this.rgb2hex(this.themestyle.columnRuleColor),
            "mainSeriesProperties.hollowCandleStyle.downColor": this.rgb2hex(this.themestyle.borderColor),
            "mainSeriesProperties.hollowCandleStyle.borderUpColor": this.rgb2hex(this.themestyle.columnRuleColor),
            "mainSeriesProperties.hollowCandleStyle.borderDownColor": this.rgb2hex(this.themestyle.borderColor),
            "mainSeriesProperties.barStyle.upColor": this.rgb2hex(this.themestyle.columnRuleColor),
            "mainSeriesProperties.barStyle.downColor": this.rgb2hex(this.themestyle.borderColor),
            "scalesProperties.backgroundColor": this.rgb2hex(this.themestyle.backgroundColor), 
            "paneProperties.legendProperties.showSeriesTitle": false,   
        }
        this._tvWidget?.applyOverrides(this.overrides);
    }

    ngAfterViewInit(): void {
        const element = document.querySelector('.chart-color6');
        this.themestyle = getComputedStyle(element);
        this.getTheme();
        if(Utility.IS_MOBILE){
            this.activatedRoute.queryParams.subscribe((params: Params) => {
                this.inputs ={};
                this.inputs.token = params['symbol'];
                this.inputs.exch = params['exch'];
                this.inputs.symbol = params['exch'];
                this.loadchart();
            });    
        }
        else {
            this.loadchart();
        }
        GeneralSettingsComponent.themeChange.subscribe((theme_no) => {
            this.themeNo = theme_no;
            this.getTheme();
            this.applycustom();
        });

    }

    loadchart(){
        this.tradingRestService.getScripService().getSecInfo({ 'exch': this.inputs.exch, 'tk': this.inputs.token }, this.getSecInfoRes.bind(this));
    }
    
    getSecInfoRes(data, code, message){
        this.scripModel = Utility.getScripModel(this.inputs.token, this.inputs.exch, this.tradingRestService, true); 
        this.initChart();
    }

    rgb2hex(rgb) {
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? '#' +
            ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
    }
    initChart() {
        let r1 = '1D' as ResolutionString;
        let r2 = '1' as ResolutionString;
        this.datafeed = new DataFeederClass(this.tradingRestService, this.scripModel, this.PS,this);
        let arr =['study_templates'];
        if(Utility.IS_MOBILE){
            arr = arr.concat(['hide_left_toolbar_by_default']);
        }
        this.saveloadadapter = new SaveLoadAdapterClass();
        const widgetOptions: ChartingLibraryWidgetOptions = {

            symbol: this.scripModel.Token,
            datafeed: this.datafeed,
            save_load_adapter: this.saveloadadapter,
            interval: r2,
            theme: this.themeName,
            container: this._containerId+'_'+this.localdivid,
            library_path: this._libraryPath,
            locale: 'en',
            enabled_features: arr, /* 'study_templates' */
            disabled_features: ['use_localstorage_for_settings','header_compare', 'display_market_status',
                    'header_saveload', 'header_undo_redo', 'header_fullscreen_button'],
            client_id: this._clientId,
            custom_css_url: "css/custom.css",
            timezone: "Asia/Kolkata",
            user_id: this._userId,
            time_frames: [
                { text: "5y", resolution: r1, description: "5 years" },
                { text: "1y", resolution: r1, description: "1 year" },
                { text: "6m", resolution: r1, description: "6 months" },
                { text: "1m", resolution: r1, description: "1 month" },
                { text: "5d", resolution: r2, description: "5 days" },
                { text: "1d", resolution: r2, description: "1 day" },
            ],
            fullscreen: this._fullscreen,
            autosize: this._autosize,
             overrides: this.overrides
        };

        this._tvWidget = new widget(widgetOptions);

        this._tvWidget.onChartReady(() => this.ChartReady(this));
    }

    buyOrSellMe(ref,type) {
        ref.ngZone.run(() => {
            Utility.openPlaceOrder(ref.router, ref.inputs.token, ref.inputs.exch, type, ref.dialog);
        });
    }
    headerReady(ref){
        if(!Utility.IS_MOBILE){
            const buybutton = ref._tvWidget.createButton();
            buybutton.setAttribute('title', 'Buy');
            buybutton.classList.add('apply-common-tooltip');
            buybutton.addEventListener('click', () => ref.buyOrSellMe(ref,'B'));
            buybutton.innerHTML = 'B';
            const sellbutton = ref._tvWidget.createButton();
            sellbutton.setAttribute('title', 'Sell');
            sellbutton.classList.add('apply-common-tooltip');
            sellbutton.addEventListener('click', () => ref.buyOrSellMe(ref,'S'));
            sellbutton.innerHTML = 'S';
            this.bb = buybutton;
            this.sb = sellbutton;
            this.applycustom();
        }

        var set_default_button = ref._tvWidget.createButton();
        set_default_button.setAttribute('title', 'Save as default template');
        set_default_button.textContent = '';
        set_default_button.classList.add("apply-common-tooltip");
        set_default_button.classList.add("header-icon");
        set_default_button.classList.add("template-save");
        set_default_button.addEventListener('click', function() { 
            const options = { saveInterval: false };
            const template = ref._tvWidget.activeChart().createStudyTemplate(options);
            Utility.print(template);
            tradingViewComponent.setDefalutTemplate(template);
            ref._tvWidget.showNoticeDialog({
                title: "Message",
                body: "Default template successfully saved.",
                callback: () => {
                    
                }
            })
        });

        var delete_button = ref._tvWidget.createButton();
        delete_button.setAttribute('title', 'Delete all templates/layouts');
        delete_button.textContent = '';
        delete_button.classList.add("apply-common-tooltip");
        delete_button.classList.add("header-icon");
        delete_button.classList.add("template-delete");
        delete_button.addEventListener('click', function() { 
            ref._tvWidget.showConfirmDialog({
                title: "Are you sure?",
                body: "Clearing your preferences will delete default template, do you want to continue?",
                callback: (result) => {
                    if(result)
                    {
                        tradingViewComponent.deleteAllTemplates();
                    }
                }
            })
        });

    }
    ChartReady(ref){
        let template = tradingViewComponent.getDefalutTemplate();
        if(template)
            ref._tvWidget.activeChart().applyStudyTemplate(template);
        ref._tvWidget.headerReady().then(() => ref.headerReady(ref));            
    }

    setDefalutTemplate(default_template)
    {
        let chart_data:any = localStorage.getItem("StudyTemplate");
        if(chart_data)
        {
            chart_data = JSON.parse(chart_data);
        }
        else
        {
            chart_data = {};
        }
        chart_data['default'] = default_template;
        localStorage.setItem("StudyTemplate", JSON.stringify(chart_data)) 
    }

    deleteAllTemplates()
    {
        let chart_data = localStorage.getItem("StudyTemplate");
        if(chart_data)
        {
            chart_data = JSON.parse(chart_data);
            delete chart_data['default'];
        }
        localStorage.setItem("StudyTemplate", JSON.stringify(chart_data))  

        tradingViewComponent._tvWidget?.activeChart().removeAllStudies();
        // let frames = document.getElementsByTagName("iframe");
        // for(let i = 0; i < frames.length; i++)
        // {
        //     let doc = frames[i].contentDocument;
        //     if(frames[i].name.includes("tradingview"))
        //     {
        //         frames[i].contentWindow.location.reload();
        //     }
        // }
    }

    getDefalutTemplate()
    {
        let default_template;
        let chart_data = localStorage.getItem("StudyTemplate");
        if(chart_data)
        {
            chart_data = JSON.parse(chart_data);
            Utility.print(chart_data);
            if(chart_data['default'])
                default_template = chart_data["default"];
        }
        Utility.print(default_template);
        return default_template;
    }

    applycustom(){
        const hax = this.rgb2hex(this.themestyle.backgroundColor);
        const contents = $('iframe').contents();
        contents.find('body').attr('style','background:'+hax+';');
        contents.find('.group-2_so5thS').attr('style','background:'+hax+';');
        contents.find('.toolbar-2yU8ifXU').attr('style','background:'+hax+';');
        contents.find('.group-3uonVBsm').attr('style','background:'+hax+';');
        this.bb?.parentElement.setAttribute('style','padding:0px;margin:6px;background:'+this.rgb2hex(this.themestyle.backgroundColor)+'');
        this.bb?.setAttribute('style','background:'+this.rgb2hex(this.themestyle.columnRuleColor)+'; color:#ffffff; width:28px; height:28px; border-radius: 2px; text-align:center;font-size:24px;');
        this.sb?.parentElement.setAttribute('style','padding:0px;margin:6px;background:'+this.rgb2hex(this.themestyle.backgroundColor)+'');
        this.sb?.setAttribute('style','background:'+this.rgb2hex(this.themestyle.borderColor)+'; color:#ffffff; width:28px; height:28px; border-radius: 2px; text-align:center;font-size:24px;');               
    }

    ngOnDestroy() {
        if (this._tvWidget !== null) {
            this._tvWidget.remove();
            this._tvWidget = null;
        }
        if (this.datafeed !== null) {
            this.datafeed = null;
        }
    }

    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.style = {'height':height + 'px'};
        this.setBasePositionAndSize(left, top, width, height);
    }
    
    setBaseVisibility(visible: boolean){
        if(visible){
        }
        else {
        }
    }  
    
    back(){
        this.location.back();
    }

    buy(){
        Utility.openPlaceOrder(this.router, this.inputs.token, this.inputs.exch, 'B', this.dialog);
    }

    sell(){
        Utility.openPlaceOrder(this.router, this.inputs.token, this.inputs.exch, 'S', this.dialog);
    }  
}
