import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Url } from '../url';
import { Utility } from '../utility';

@Injectable()
export class EventService {
    constructor (private apiService: ApiService) {
    }
    getAllEvents(isStockSpecific: boolean, start: number, limit: number, scrip_token: string, exchange_segment: string, callback: Function, precallback?: Function) {
        let message = '';
        let corporateData = [];
        const code = '';
        let key = '';
        const obj = {};
        obj['start'] = start;
        obj['limit'] = limit;
        if (isStockSpecific) {
            obj['scrip_token'] = scrip_token;
            obj['exchange_segment'] = exchange_segment;
            key = Url.PTP_EVENTS + '_' + scrip_token + '_' + exchange_segment + '_' + start + '_' + limit;
        } else {
            key = Url.PTP_EVENTS + '_' + start + '_' + limit;
        }
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback?.(predata);
        }
        return this.apiService.ptpluspost(Url.PTP_EVENTS, obj).subscribe((result) => {
            if (result.code === 200) {
                if (result.data && result.data.length > 0) {
                    corporateData = result.data;
                    Utility.CACHE_STORE.setCacheData(key, corporateData);
                } else {
                    message = 'NO DATA';
                }
            } else {
                message = result.message;
            }
            callback(result.code, corporateData, message);
        }, error => {
            message = error;
            callback(code, corporateData, message);
        });
    }

    getMultipleSearchEvents(scrip_token: string, exchange_segment: string, callback: Function, precallback?: Function) {
        let events = [];
        let message = '';
        const code = '';
        const obj = {};
        obj['scrip_token'] = scrip_token;
        obj['exchange_segment'] = exchange_segment;
        const key = Url.PTP_GROUPEVENTS + '_' + scrip_token + '_' + exchange_segment;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        return this.apiService.ptpluspost(Url.PTP_GROUPEVENTS, obj).subscribe((result) => {
            if (result.code === 200) {
                if (result.data && result.data.length > 0) {
                    events = result.data;
                    Utility.CACHE_STORE.setCacheData(key, events);
                    message = result.status;
                }
                else {
                    message = 'NO DATA';
                }
            }
            else {
                message = result.status;
            }
            callback(result.code, events, message);
        }, error => {
            message = error;
            callback(code, events, message);
        });
    }

    fetchFilterData(obj, callback: Function) {
        let message = '';
        const code = '';
        let corporateData = [];
        return this.apiService.ptpluspost(Url.PTP_FILTER_EVENTS, obj).subscribe((result) => {
            if (result.code === 200) {
                if (result.data && result.data.length > 0)
                    corporateData = result.data;
                else
                    message = 'NO DATA';
            }
            else
                message = result.message;
            callback(result.code, corporateData, message);
        },
        error => {
            message = error;
            callback(code, corporateData, message);
        });
    }
}
