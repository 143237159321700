import { Component, OnInit, Injector, Inject, HostListener, ApplicationRef, OnDestroy } from '@angular/core';
/*import { OverlayContainer } from '@angular/cdk/overlay';*/
import { RouterOutlet, Router } from '@angular/router';
/*import { fader, stepper, transformer,  slider, slideInAnimation1 } from './animations/route-animations';*/
import { environment } from 'src/environments/environment';
import { Url } from './services/url';
import { Utility } from './services/utility';
import { Mouse } from './services/mouse';
import { DOCUMENT } from '@angular/common';
import { PersistenceStorage } from './services/storage';
import { TranslateService } from '../../node_modules/@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableUtility } from './services/tableutility';
import { MetaColorService } from './view/meta-color.service';
import { PwaService } from './services/pwa.service';
import { interval } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SwUpdate, SwPush } from '@angular/service-worker';
declare const Module: any;
import { Preferences, ReturnType } from './services/Preferences';
import { TradingRestService } from './services/tradingrest.service';
import { MessagingService } from './services/FCM/messaging.service';
import firebase from 'firebase/app';
import { MatDialog } from '@angular/material/dialog';
import { QProfileComponent } from './view/protradelite/qprofile/qprofile.component';
import { combineQProfileTodaySip } from './view/protradelite/combineqprofiletodaysip/combineqprofiletodaysip.component';
import { Keys } from './services/keys';
import { LinkComponent } from './view/protradelite/link/link.component';

@Component({
  selector: 'app-root',
  templateUrl: './view/' + environment.baseviewpath + '/app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ // <-- add your animations here
      // fader,
     // slider,
      // slideInAnimation1,
      // transformer,
     // stepper
  ]
})
export class AppComponent implements OnInit, OnDestroy{
  static injector: Injector;
  title = 'HyperTrade';
  theme = 'my-dark-theme';
  // public getRouterOutletState(outlet) {
  //   return outlet.isActivated ? outlet.activatedRoute : '';
  // }
  isMobile = true;
  utility: any;
  theme_no = '';
  placeorderSettings;
  message;
  realDimensions;
  isLand: boolean;

  timeOut = false;
  width;
  height;
  
  constructor(@Inject(DOCUMENT) private document: Document, injector: Injector, private router: Router, private translate: TranslateService,
   private snackBar: MatSnackBar, private _metaColorService: MetaColorService, public Pwa: PwaService, private deviceService: DeviceDetectorService,
   private update: SwUpdate, private swPush: SwPush, private appRef: ApplicationRef, private  tradingRestService: TradingRestService,
   private messagingService: MessagingService, public dialog: MatDialog)  {
    if (Preferences.getPreference('userLogIn') != null){
      tradingRestService.getLoginService().getAllSessions((data, code, msg) => {
        if (code === 200 && msg === 'OK'){
          this.router.initialNavigation();
        }
      }); 
    } else {
      this.router.initialNavigation();
    }
    this.width = '500px';
    if(Utility.IS_MOBILE){
      this.width = '90vw'; 
    }

    AppComponent.injector = injector;
    Preferences.clearPrefs(null,false);
    if (environment.baseviewpath === 'fabits' || environment.baseviewpath === 'investor'){
      Preferences.setPreference('homepage', '0');
    } else {
      Preferences.setPreference('homepage', '1');
    }  
    const devinfo = this.deviceService.getDeviceInfo();
    Preferences.setPreference('deviceInfoObj', JSON.stringify(devinfo));
    if(environment.baseviewpath === 'investor'){
      Utility.getQProfileData.subscribe(obj => {
        let callQprofileData =  Preferences.getItem('callQprofile', ReturnType.Undefined);
        let currentData = new Date().getDate();
        if(Utility.iscombineQProfileClose){
          if(callQprofileData == undefined || (callQprofileData['status'] && callQprofileData['date'] !== currentData)) {
            tradingRestService.getQProfileService().getByPhorem(obj, (data, code, msg) => {
              if(code == 200 && data.hasOwnProperty('custId')){
                this.getService(data['custId']);
              }
              else if( Utility.todaysSipData.length > 0 && Utility.isTodaySipOpen){
                Utility.dialogRef = this.dialog.open(combineQProfileTodaySip, { disableClose: false, width: this.width, panelClass:'p-relative', autoFocus: false});
              }
            });
          }
          else if( Utility.todaysSipData.length > 0 && Utility.isTodaySipOpen && callQprofileData['todaySip']) {
            Utility.dialogRef = this.dialog.open(combineQProfileTodaySip, { disableClose: false, width: this.width, panelClass:'p-relative', autoFocus: false});
          }
        }
       
      });
    }
    Utility.preferencesUpdated.subscribe(obj => {
      this.onPreferencesChanged();
    });
    Utility.UA = devinfo['userAgent'];
    let isMobileForce = Preferences.getPreference('isMobileForce');
    Utility.setTabDevice(this.deviceService.isTablet());
    (Utility.IS_TABLET && isMobileForce === 'true') ? Utility.setDevice(true) :  Utility.setDevice(this.deviceService.isMobile());
    if(Utility.IS_TABLET && isMobileForce === 'true'){
      Mouse.IS_MOUSE = false;
    }
    this.isMobile = Utility.getDevice();
    Utility.setIsLoggedIn();
    this.translate.addLangs(['en', 'hi']);
    this.translate.setDefaultLang('en');
    if (Utility.IS_MOBILE) {
      if (Preferences.getItem('m_touch', ReturnType.Undefined) !== undefined && Preferences.getItem('m_touch') !== null
      && Preferences.getItem('m_touch') !== 'M'){
        Utility.TOUCH_TYPE = Preferences.getItem('m_touch');
      }
      else{
        Utility.TOUCH_TYPE = 'T';
        // Preferences.setItem('m_touch', Utility.TOUCH_TYPE);
      }
    }
    else if (Preferences.getItem('w_touch', ReturnType.Undefined) !== undefined && Preferences.getItem('w_touch') !== null){
      Utility.TOUCH_TYPE = Preferences.getItem('w_touch');
    } else {
      Utility.TOUCH_TYPE = 'M';
      // Preferences.setItem('w_touch', Utility.TOUCH_TYPE);
    }
    TableUtility.TOUCH_TYPE = Utility.TOUCH_TYPE;
    TableUtility.setTuchType();

    window.addEventListener('message', (event) => {
      if (event.data === 'Session Expired')
        this.snackBar.open(this.translate.instant('login.session_expired'), '', { duration: 3000 });
    });
    
    window.addEventListener("storage", (ev) => {
      if (ev.key == 'userSessionId') {
        setTimeout(()=>{
          if (Preferences.getPreference('LoggedIn') === 'true') {
            window.location.reload();
          } else {
            router.navigate(['/login'], { replaceUrl: true });
            setTimeout(()=>{
              window.location.reload();
            },100)
          }
        },50)
      }
  });

    Utility.installApp.subscribe(obj => {
      this.installPwa();
    });
    this.updateClient();
    this.checkUpdate();
    this.setTheme();
    this.setAppMode();
    this.placeorderSettings = Preferences.getItem('placeordersettings', ReturnType.Undefined);
    if (this.placeorderSettings)
      this.asignPlaceorderSettings();

    if (Preferences.getItem('language', ReturnType.Undefined))
      this.translate.use(Preferences.getItem('language'));
    else
      this.translate.use('en');
    Utility.IS_MOBILE_LAND = (this.isLandscape() && Utility.IS_MOBILE) ? true : false;
    this.isLand = Utility.IS_MOBILE_LAND;
    document.addEventListener("visibilitychange", function() {
      if (!Utility.isLogout || this.deviceService.browser.toLowerCase() !== 'safari')
      {
        Utility.hidden = document.hidden;
        if (document.hidden)
        {
          Utility.timeId = setTimeout(function(){
            this.timeOut = true;
            this.tradingRestService.marketDataFeeder.DisconnectWS();
          }.bind(this), 40000);
        }
        else
        {
          if (this.timeOut)
          {
            this.tradingRestService.marketDataFeeder.ReconnectWS();
            this.timeOut = false;
          }
          else
          {
            if (Utility.timeId)
              clearTimeout(Utility.timeId);
          }
        }
      }
    }.bind(this), false);
  }
    getService(custId){
      this.tradingRestService.getQProfileService().fetchIBForUser(custId, (data, code, msg) => {
        if(code == Keys.RES_CODE_FAILURE){
          let survey = Preferences.getPreference('survey');
          if(survey === undefined || survey === null || survey !== '1'){
            this.dialog.open(LinkComponent, {
              data: {
                url: 'https://qprofile.fabits.app/',
                s: true
              },
            });
          }
        }
        if(data && (data.constructor === [].constructor) && data.length > 0){
          Utility.dialogData = data;
          Utility.dialogRef = this.dialog.open(combineQProfileTodaySip, { disableClose: false, width: this.width ,panelClass:'p-relative', autoFocus: false});
        }
        else if(Utility.todaysSipData.length > 0 && Utility.isTodaySipOpen) {
          Utility.dialogRef = this.dialog.open(combineQProfileTodaySip, { disableClose: false, width: this.width ,panelClass:'p-relative', autoFocus: false});
        }
      });
    }
  isLandscape(): boolean{
    try {
      return !(window.matchMedia("(orientation: portrait)").matches ? true : false);
    } catch (error) {
    }
  }

  setTheme()
  {
    if(Utility.IS_MOBILE){
      this.document.documentElement.setAttribute('data-view', 'm');
    } else {
      this.document.documentElement.setAttribute('data-view', 'w');
    }
    if(Utility.IS_TABLET){
      this.document.documentElement.setAttribute('data-tab', 't');
    }
    if (Preferences.getItem('theme', ReturnType.Undefined) !== undefined && Preferences.getItem('theme') !== null) {
      this.theme_no = Preferences.getItem('theme');
    } else {
      this.theme_no = '1';
      Preferences.setItem('theme', this.theme_no);
      this._metaColorService.changeThemeColor(this._metaColorService.light);
    }
    switch (this.theme_no) {
      case '1':
        this.document.documentElement.setAttribute('data-theme', 'light');
        this._metaColorService.changeThemeColor(this._metaColorService.light);
        break;
      case '2':
        this.document.documentElement.setAttribute('data-theme', 'dark');
        this._metaColorService.changeThemeColor(this._metaColorService.dark);
        break;
      case '3':
        this.document.documentElement.setAttribute('data-theme', 'blue');
        this._metaColorService.changeThemeColor(this._metaColorService.blue);
        break;
    }
  }

  setAppMode()
  {
    switch (Utility.TOUCH_TYPE) {
      case 'T':
        this.document.documentElement.setAttribute('app-mode', 'touch');
        break;
      case 'M':
        this.document.documentElement.setAttribute('app-mode', 'mouse');
        break;
      case 'C':
        this.document.documentElement.setAttribute('app-mode', 'power');
        break;
    }
  }

  ngOnInit() {
    if (Utility.IS_MOBILE){
      this.realDimensions = window.outerWidth + window.outerHeight - 24;
    }
    if (Utility.IS_MOBILE && Preferences.getPreference('LoggedIn') === 'true') {
      this.tradingRestService.marketDataFeeder.CreateConnection();
    }
    if (Preferences.getPreference('LoggedIn') === 'true') {
      Preferences.getPreferences(this.tradingRestService, true);
    }
    this.utility = Utility;
    Utility.CACHE_STORE = new PersistenceStorage();
//    this.pushSubscription();
    this.swPush.messages.subscribe((message) => console.log(message));
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      window.open(notification.data.url);
    });
    Module().then((instance) => {
      Utility.OCWebAssambly = instance;
    });

    if(environment.isNotificationEnabled)
    {
      navigator.serviceWorker.register('./FCM/firebase.messaging.sw.js').then((registration) => {
        firebase.messaging().useServiceWorker(registration);
      });
  
      const userId = 'user001';
      this.messagingService.requestPermission(userId);
      this.messagingService.receiveMessage();
      this.message = this.messagingService.currentMessage;
    }
  }

  ngOnDestroy() {
    Utility.preferencesUpdated.unsubscribe();
  }

  onPreferencesChanged() {
    this.setTheme();
    if (Preferences.getItem('language', ReturnType.Undefined))
      this.translate.use(Preferences.getItem('language'));
    else
      this.translate.use('en');

    if (!Utility.IS_MOBILE && Preferences.getItem('w_touch', ReturnType.Undefined) !== undefined && Preferences.getItem('w_touch') !== null){
      Utility.TOUCH_TYPE = Preferences.getItem('w_touch');
    }

    if (Utility.IS_MOBILE && Preferences.getItem('m_touch', ReturnType.Undefined) !== undefined && Preferences.getItem('m_touch') !== null) {
      Utility.TOUCH_TYPE = Preferences.getItem('m_touch');
    }

    this.setAppMode();
    if (Preferences.getItem('profile', ReturnType.Undefined) !== undefined && Preferences.getItem('profile') !== null){
      Utility.PROFILE = Preferences.getItem('profile');
    }

    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      Utility.flashType.next(Preferences.getItem('flashType'));
    }

    this.placeorderSettings = Preferences.getItem('placeordersettings', ReturnType.Undefined);
    if (this.placeorderSettings)
      this.asignPlaceorderSettings();

    Utility.recentlySearchedScrips = Preferences.getItem('recentlySearchedScrips', ReturnType.Array);
    if (Preferences.getItem('lwMap', ReturnType.Undefined))
      Utility.LOT_WEIGHT_MAP = Preferences.getItem('lwMap');  
    Preferences.setItem('version', environment.version);

    if (Preferences.getItem('preTradeValues', ReturnType.Undefined) === undefined) {
      Utility.fillPreTradeValues();
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    Utility.IS_MOBILE = Utility.getDevice();
    Utility.IS_MOBILE_LAND = (this.isLandscape() && Utility.IS_MOBILE) ? true : false;
    Utility.orientationChanged.next();
    this.isMobile = Utility.IS_MOBILE;
    this.isLand = Utility.IS_MOBILE_LAND;

    if (Utility.IS_MOBILE){
      let resizeDimensions = window.innerHeight + window.innerWidth;
      (this.realDimensions <= resizeDimensions) ? Utility.isKeyboardHidden.next(true) : Utility.isKeyboardHidden.next(false);
    }
  }

  asignPlaceorderSettings()
  {
    Utility.PLACEORDERSETTINGSMODEL.orderType = this.placeorderSettings.orderType;
    Utility.PLACEORDERSETTINGSMODEL.productType = this.placeorderSettings.productType;
    Utility.PLACEORDERSETTINGSMODEL.SqrOffPercentage = this.placeorderSettings.SqrOffPercentage;
    Utility.PLACEORDERSETTINGSMODEL.stoplossPercentage = this.placeorderSettings.stoplossPercentage;
    Utility.PLACEORDERSETTINGSMODEL.onetouch = this.placeorderSettings.onetouch;
  }
  @HostListener('window:callFromParent', ['$event.detail'])
  callFromParent(data){
    if (data.type === Utility.FINGERPRINT) {
      Utility.IsFingerPrint = parseInt(data.value, 10);
    }
    if (data.type === Utility.FINGERPRINT_RES) {
      const val = parseInt(data.value, 10);
      Utility.loginSubject.next(val);

    }
  }
  installPwa(): void {
    this.Pwa.promptEvent.prompt();
  }

  updateClient() {
    if (!this.update.isEnabled) {
      return;
    }
    this.update.available.subscribe((event) => {
      if (confirm(this.translate.instant('dashboard.update_message'))) {
        this.update.activateUpdate().then(() => location.reload());
      }
    });

    this.update.activated.subscribe((event) => {
    });
  }

  checkUpdate() {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(8 * 60 * 60 * 1000);

        timeInterval.subscribe(() => {
          this.update.checkForUpdate().then(() => console.log('checked'));
        });
      }
    });
  }
  /*pushSubscription() {
    if (!this.swPush.isEnabled) {
      console.log('Notification is not enabled');
      return;
    }

    this.swPush
      .requestSubscription({
        serverPublicKey: this.publicKey,
      })
      .then((sub) => {
        // Make a post call to serve
        console.log(JSON.stringify(sub));
      })
      .catch((err) => console.log(err));
  }*/
  mouseMoved(){
    if(!Utility.IS_MOBILE){
      Mouse.IS_MOUSE = true;
    }
  }
}
