import { ngxCsv } from 'ngx-csv/ngx-csv';
export class CSVUtility {
    static ExportDataToCSV(data, fileName, componentName, title?) {
        let filterData;
        let headers; 
        const myClonedArray = [];
        data.forEach(val => myClonedArray.push(Object.assign({}, val)));
        if (componentName === 'Order Book') {
            filterData = CSVUtility.formatOrderBookData(myClonedArray);
            headers =  ["NestOrd", "Exseg", "Exchange", "TradeSymbol", "ExchangeOrderNo", "Action", "ActionDisplay", "OrderType", "DiscQty", "CanceledQty", "Price", "TriggerPrice", "SymbolName", "Status", "ProductCode", "AvgPrice", "TotalQty", "ExchangeTimeStamp", "RejectionReason", "OrderValidityDate", "MarketProtectionPercentage", "AccountId", "RefLmtPrice", "BrokerClient", "ExpSsbDate", "COPercentage", "Token", "Ticksize", "MarketProtectionFlag", "Stat", "DiscQtyPerc", "SyomOrderId", "Defmktproval", "ExpDate", "Minqty", "ExchangeSegment", "User", "ordersource", "displayOrderSource", "modifiedBy", "AlgoID", "series", "orderentrytime", "ordergenerationtype", "sipindicator", "OrderedTime", "PendingQty", "PendQtyDispl", "FilledShares", "Validity", "TSym", "opType", "stkPrc", "expDtSsb", "DisplaySymbolDetail", "DisplayStatus", "DecimalPrecision"];
        } else if (componentName === 'Trade Book') {
            filterData = CSVUtility.formatTradeBookData(myClonedArray);
            headers =  ["NestOrd", "FillId", "Exseg", "Exchange", "AvgPrice", "AccountId", "CustOFrm", "OrdDuration", "FillTime", "BrokerClient", "Qty", "ExchangeSeg", "Time", "PrcType", "FillDate", "NOReqID", "ExchTime", "FillLeg", "TSym", "Stat", "FillQty", "Symbol", "TranType", "PosFlag", "Price", "ExchordID", "Pcode", "Minqty", "User", "TranTypeDisplay", "FillDateTime", "ExpiryDate", "FilledQty", "Token", "opType", "stkPrc", "DisplaySymbolDetail", "DecimalPrecision"];
        } else if (componentName === 'Position Book') {
            filterData = CSVUtility.formatPositionBookData(myClonedArray);
            headers =  ["RealisedPL", "UnRealisedPL", "MtoM", "DayMtom", "Tsym", "FillBuyQty", "FillSellQty", "FillBuyAmt", "FillSellAmt", "sSqrflg", "Pcode", "BLQty", "discQty", "CFSellQty", "CFBuyQty", "CFBuyAmt", "CFSellAmt", "NetBuyAmt", "NetSellAmt", "NetBuyQty", "NetSellQty", "Netqty", "CFQty", "Opttype", "Stat", "Series", "posflag", "Symbol", "Stikeprc", "Token", "Type", "Exchangeseg", "Exchange", "BuyAvgPrc", "SellAvgPrc", "AvgPrc", "PNLPercentageChg"];
        } else if (componentName === 'Holding Book') {
            filterData = CSVUtility.formatHoldingBookData(myClonedArray);
            headers =  ["Symbol", "ExchangeSeg", "Exchange", "Token", "Ltp", "Token5", "Btst", "Token3", "Token4", "Token2", "Token1", "LTnse", "Ttrind", "Mcxsxcmsym", "Bsetsym", "Series", "Series1", "Usedqty", "Tprod", "Scripcode", "Nsetsym", "Csetsym", "Colqty", "Exch1", "Exch2", "Exch3", "Exch4", "Exch5", "csflag", "Ysxtsym", "LTmcxsxcm", "Coltype", "ExchSeg4", "ExchSeg3", "ExchSeg2", "ExchSeg1", "ExchSeg5", "LTbse", "Holdqty", "LTysx", "LTcse", "isin", "Pcode", "MaturityDate", "IssueDate", "Settlements", "Clientid", "WHqty", "SellableQty", "CUqty", "WCqty", "Price", "HUqty", "TotalHoldingQty", "AvailableQty", "DayPNL", "PrimaryValuation", "DayPNLStr", "PrimaryValuationStr", "NetPNL", "PNLPercentageChg", "Change", "PercentageChange"];
        }
        else if (componentName === 'Transaction Details'){
            filterData = CSVUtility.formatTransaction(myClonedArray);
            headers = ["PaymentId", "Account no", "Amount", "Status", "Method", "Transaction Initiated", "Transaction Type", "IFSC Code", "Trans Charge", "Gst", "Currency", "Amount Refunded", "Refund Status", "Description", "Card Id", "Bank", "Wallet", "VPA", "Contact"];
        }
         else {
            filterData = myClonedArray;
            headers = Object.keys(data[0]);
        }

        var options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true, 
            showTitle: true,
            title: title,
            headers:  headers
          };
          new ngxCsv(filterData, fileName, options);
    }

    static formatTransaction(data){
        data.forEach(function(v) {
            delete v.id;
            delete v.user_id;
            delete v.name;
            delete v.order_id;
            delete v.payment_id;
            delete v.transaction_endtime;
            delete v.enterprise_id;
            delete v.cust_trns_charge;
            delete v.cust_trns_gst;
            delete v.charge_type;
            delete v.remark;
            delete v.invoice_id;
            delete v.international;
            delete v.captured;
            delete v.email;
            delete v.error_code;
            delete v.error_description;
            delete v.error_source;
            delete v.error_step;
            delete v.error_reason;
            delete v.acquirer_data;
            delete v.created_at;
            delete v.notes;
            delete v.currUnit;
            delete v.segment;
            delete v.exchange;
            delete v.product;

        });
        return data;
    }

    static formatOrderBookData(data) {
        data.forEach(function(v){ 
            delete v.IsEdit;
            delete v.IsCancel;
            delete v.IsExit;
            delete v.isalertset;
            delete v.ExcelAction;
            delete v.CheckBoxValue;
            delete v.scripModel;
            delete v.show;
        });
        return data;
    }

    static formatTradeBookData(data) {
        data.forEach(function(v){ 
            delete v.scripModel;
            delete v.show;
          });
          return data;
    }
    static formatPositionBookData(data) {
        data.forEach(function(v){ 
            delete v.IsVisible;
            delete v.scripModel;
            delete v.positionModelChanged;
            delete v.show;
          });
          return data;
    }
    static formatHoldingBookData(data) {
        data.forEach(function(v){ 
            delete v.scripModel;
            delete v.holdingModelChanged;
            delete v.show;
          });
          return data;
    }
}
