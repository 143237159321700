<div class="settingpopupwrapper">
    <app-header *ngIf="isMobile" goback="1" [title]="'header.settings' | translate" [isReloadVisible]="false" (closePlaceOrderSettings) = "close()"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'header.settings' | translate"  (closePlaceOrderSettings) = "close()"></app-titlebar>
<div [class.mh-none] = "isMobile">
    <div  [class.data-container]="isMobile" >
        <mat-tab-group mat-stretch-tabs (selectedIndexChange)="selectedIndexChange($event)" [(selectedIndex)] = "tabIndex">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span translate>header.general_settings</span>
                  </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span translate>header.place_order_settings</span>
                  </ng-template>            
            </mat-tab>
          </mat-tab-group>
    </div>
</div>
    <mat-dialog-content [class.mh-none] = "isMobile" class="settingpopup2">
    
        <ng-container *ngxCacheIf = "tabIndex === 0">
            <div class="row">
            <div class="col-12 sameer">
                
                    <!-- <div *ngIf="!isMobile" class="row myrow">
                        <div class="col-12">
                            <div class="graytext ts-2 mb-1" translate>header.my_profile</div>
                            <mat-radio-group [(ngModel)]="profile">
                                <mat-radio-button value="B" (change) = "toggleProfile('B')" translate>{{('header.beginner' | translate)}}</mat-radio-button>
                                <mat-radio-button value="T" (change) = "toggleProfile('T')" translate>{{('header.trader' | translate)}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <div class="row myrow">
                        <div class="col-12">
                            <span class="sectiontitle" translate>header.change_language</span>
                            <mat-radio-group [(ngModel)]="selectedLang">
                                <mat-radio-button value="en" (change) = "changeLanguage()" checked>English</mat-radio-button>
                                <mat-radio-button value="hi" (change) = "changeLanguage()">हिन्दी</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row myrow">
                        <div class="col-12">
                            <span class="sectiontitle" translate>header.change_theme</span>
                            <mat-radio-group [(ngModel)]="selectedTheme">
                                <mat-radio-button value="1" (change) = "toggleTheme('1')">{{('header.light_theme' | translate)}}</mat-radio-button>
                                <mat-radio-button value="3" (change) = "toggleTheme('3')">{{('header.dark_theme' | translate)}}</mat-radio-button>
                                <mat-radio-button value="2" (change) = "toggleTheme('2')">{{('header.black_theme' | translate)}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row myrow" *ngIf="isMobile && isFingerprintSupport && isFingerPrintEnable">
                        <div class="col-12">
                            <span class="sectiontitle" translate>header.fingerprint</span>
                            <mat-radio-group [(ngModel)]="selectFingerprint">
                                <mat-radio-button value="E" (change) = "toggleFingerprint('E')">{{('header.enabled' | translate)}}</mat-radio-button>
                                <mat-radio-button value="D" (change) = "toggleFingerprint('D')">{{('header.disabled' | translate)}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row myrow">
                        <div class="col-12">
                            <span class="sectiontitle" translate>header.change_flash</span>
                            <mat-radio-group [(ngModel)]="selectedFlashType">
                                <mat-radio-button value="defaultFlash" (change) = "toggleFlash('defaultFlash')">{{('header.default_flash' | translate)}}</mat-radio-button>
                                <mat-radio-button value="noFlash" (change) = "toggleFlash('noFlash')">{{('header.no_flash' | translate)}}</mat-radio-button>
                                <mat-radio-button value="ltpFlash" (change) = "toggleFlash('ltpFlash')">{{('header.ltp_flash' | translate)}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    
                    <div class="row myrow">
                        <div class="col-12">
                            <span class="sectiontitle" translate>settings.reset_localstorage</span>
                            <button  class="btn-primary mt-1 mb-1 settingbutton" mat-flat-button (click) = "reset()">{{'settings.reset' | translate}}</button>
                        </div>
                    </div>
                    <div class="row myrow">
                        <div class="col-12">
                            <span class="sectiontitle" translate>header.mode</span>
                            <mat-radio-group [(ngModel)]="selectedMode">
                                <mat-radio-button value="T" (change) = "openTouchType('T')">{{('header.touch' | translate)}}</mat-radio-button>
                                <mat-radio-button *ngIf="!isMobile" value="M"  (change) = "openTouchType('M')" checked>{{('header.mouse' | translate)}}</mat-radio-button>
                                <mat-radio-button value="C" (change) = "openTouchType('C')">{{('header.compect' | translate)}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="row myrow" *ngIf="istablet">
                        <div class="col-12">
                            <span class="sectiontitle" translate>header.mobileforce</span>
                            <mat-radio-group [(ngModel)]="isMobileForce">
                                <mat-radio-button value="true"  (change)="enableMobileForce()">{{'all.enable' | translate}}</mat-radio-button>
                                <mat-radio-button value="false"  (change)="disableMobileForce()">{{'all.disable' | translate}}</mat-radio-button>
                            </mat-radio-group> 
                        </div>
                    </div>
            </div>
        </div>
        </ng-container>
        <ng-container *ngxCacheIf = "tabIndex === 1">
        <div class="row marginweb" >
            <div class="col-12 sameer">
                <!-- <div class="row">
                    <div class="col-12">
                        <div class="row mt-2">
                            <div class="col-12">
                                <h4 class="m-0 ts-3 primetext" translate>header.placeorder_settings</h4>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row myrow">
                    <div class="col-12">
                        <span class="sectiontitle" translate>all.single_click</span>
                        <mat-radio-group [(ngModel)]="placeOrderSettings.onetouch" aria-label="Select an option">
                            <mat-radio-button value="true">{{('all.enable' | translate)}}</mat-radio-button>
                            <mat-radio-button value="false">{{('all.disable' | translate)}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="row myrow">
                    <div class="col-12">
                        <span class="sectiontitle" translate>placeorder.defaultordertype</span>
                        <mat-radio-group [(ngModel)]="placeOrderSettings.orderType" aria-label="Select an option">
                            <ng-template ngFor let-ortype [ngForOf]="orderTypeArray">
                                <mat-radio-button *ngIf="orarr.includes(ortype)" [value]="ortype">{{ortype | valuepipe}}</mat-radio-button>
                            </ng-template>
                        </mat-radio-group>
                    </div>
                </div>
                      
                <div class="row myrow">
                    <div class="col-12">
                        <span class="sectiontitle" translate>placeorder.defaultproducttype</span>
                        <mat-radio-group [(ngModel)]="placeOrderSettings.productType" aria-label="Select an option">
                            <ng-template ngFor let-prtype [ngForOf]="prodArray">
                                <mat-radio-button *ngIf = "prtype !== 'CO'" [value]="prtype">{{prtype | valuepipe}}</mat-radio-button>
                            </ng-template>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="row pt-3" *ngIf="isboorder">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>placeorder.bosquareoff</mat-label>
                            <input matInput type="number" [placeholder]="'placeorder.squareoff' | translate" #inputPrice [(ngModel)]="placeOrderSettings.SqrOffPercentage">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>placeorder.bostoploss</mat-label>
                            <input matInput type="number" [placeholder]="'placeorder.stoploss' | translate" #inputPrice [(ngModel)]="placeOrderSettings.stoplossPercentage">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-12 settingbox">
                <h4 translate>settings.stock_risk_title
                    <i matTooltip="{{'settings.stock_risk_info' | translate}}" class="icon-help"></i>
                </h4>
                <div class="row myrow">
                    <mat-form-field class="col-12">
                        <input required (keypress)="_keyPress($event)" [(ngModel)]="stockValue" matInput name="futureInput"
                         matInput type="number" placeholder="X% of LTP" inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 settingbox">
                <h4 translate>settings.index_risk_title
                    <i matTooltip="{{'settings.index_risk_info' | translate}}" class="icon-help"></i>
                </h4>
                <div class="row myrow">
                    <mat-form-field class="col-12">
                        <input required (keypress)="_keyPress($event)" [(ngModel)]="indexValue" matInput
                         matInput type="number" placeholder="X% of LTP" inputmode="decimal"  pattern="[0-9]+([\.,][0-9]+)?">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 settingbox">
                <h4 translate>settings.optionrisk_title
                    <i matTooltip="{{'settings.option_risk_info' | translate}}" class="icon-help"></i>
                </h4>
                <div class="row myrow">
                    <mat-form-field class="col-6">
                        <input required (keypress)="_keyPress($event)" [(ngModel)]="optionValueLow" matInput
                            matInput type="number" placeholder="X% if LTP <= 50 Rs." inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">  
                    </mat-form-field>
                    <mat-form-field class="col-6">
                       <input required (keypress)="_keyPress($event)" [(ngModel)]="optionValueHigh" matInput
                            matInput type="number" placeholder="X% if LTP > 50 Rs." inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 settingbox">
                <h4 translate>settings.currency_risk_title
                    <i matTooltip="{{'settings.currency_risk_info' | translate}}" class="icon-help"></i>
                </h4>
                <div class="row myrow">
                    <mat-form-field [ngClass]="{'col-4': !isMobile, 'col-12' : isMobile }">
                        <input required (keypress)="_keyPress($event)" [(ngModel)]="currValueLow" matInput
                            matInput type="number" placeholder="X Rs if LTP <= 0.2500 Rs." inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">  
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'col-4': !isMobile, 'col-12' : isMobile }">
                       <input required (keypress)="_keyPress($event)" [(ngModel)]="currValueMedium" matInput
                            matInput type="number" placeholder="X Rs if 0.2501 < LTP < 0.5000 Rs." inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'col-4': !isMobile, 'col-12' : isMobile }">
                        <input required (keypress)="_keyPress($event)" [(ngModel)]="currValueHigh" matInput
                         matInput type="number" placeholder="X% if LTP > 0.5000 Rs." inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 settingbox">
                <h4 translate>settings.currency_risk_title2
                    <i matTooltip="{{'settings.eur_risk_info' | translate}}" class="icon-help"></i>
                </h4>
                <div class="row myrow">
                    <mat-form-field [ngClass]="{'col-4': !isMobile, 'col-12' : isMobile }">
                        <input required (keypress)="_keyPress($event)" [(ngModel)]="eurValueLow" matInput
                            matInput type="number" placeholder="X Rs if LTP <= 0.0100 Rs." inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">  
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'col-4': !isMobile, 'col-12' : isMobile }">
                       <input required (keypress)="_keyPress($event)" [(ngModel)]="eurValueMedium" matInput
                            matInput type="number" placeholder="X Rs if 0.0101 < LTP < 0.0200 Rs." inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">
                    </mat-form-field>
                    <mat-form-field [ngClass]="{'col-4': !isMobile, 'col-12' : isMobile }">
                        <input required (keypress)="_keyPress($event)" [(ngModel)]="eurValueHigh" matInput
                         matInput type="number" placeholder="X% if LTP > 0.0200" inputmode="decimal" pattern="[0-9]+([\.,][0-9]+)?">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-12 settingbox">
                <h4 translate>settings.amount_risk_title
                    <i matTooltip="{{'settings.amount_risk_info' | translate}}" class="icon-help mt-1 ml-1"></i>
                </h4>
                <div class="row myrow">
                    <mat-form-field class="col-12">
                        <input required (keypress)="_keyPress($event)" [(ngModel)]="totalBuyAmt" matInput
                            matInput type="number" placeholder="Price in Rs" inputmode="decimal" pattern="[0-9]*">  
                    </mat-form-field>
                </div>
            </div>
        </div>
        </ng-container>
   </mat-dialog-content>

<div class="mat-dialog-actions padding-all text-right footerheight" [class.pr-3]="!isMobile">
    <button *ngIf="tabIndex ===  1" class="btn-plain" mat-flat-button (click) = "resetPreTradeValues()">{{'settings.reset' | translate}}</button>
    <button *ngIf="tabIndex ===  1" type="submit" mat-flat-button (click)="savePreTradeValues()" class="btn-primary ml-2">{{'alert.save' | translate}}</button>      
</div>
</div>