import { Injectable, ɵConsole } from '@angular/core';
import { IAlertService } from './ialert.service';
import { ApiService } from '../api.service';
import { TradingRestService } from '../tradingrest.service';


@Injectable()
export class HSAlertService implements IAlertService {
    constructor(apiService: ApiService) {
    }
    GetAlertIndex(callback: Function) { }
    CreateSecTradeAlert(Exchange: string, Source: string, Value: string, Direction: string, SendOn: string, Symbol: string, callback: Function) { }
    GetOpenOrders(Exchange: string, callback: Function) { }
    CreateOrderTradeAlert(Exchange: string, Token: string, Comment: string, Target: string, Senton: string, callback: Function) { }
    GetAlertHistory(callback: Function) { }
    CancelAlert(AlertId: string, callback: Function) { }
    ModifyPerTradeAlert(ATMNumbr: string, Target: string, Senton: string, callback: Function) { }
    ModifySecTradeAlert(ATMNumbr: string, Target: string, Operation: string, Senton: string, callback: Function) { }
}
