import { Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CSVUtility } from 'src/app/services/CSVUtiity';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {ContentObserver} from '@angular/cdk/observers';
import { PricePipe } from 'src/app/utility/pipes/PricePipe';
import { data } from 'jquery';
import { element } from 'protractor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Preferences } from 'src/app/services/Preferences';

declare const moment: any;
@Component({
  selector: 'app-transactiondetails',
  templateUrl: './transactiondetails.component.html'
})
export class TransactiondetailsComponent implements OnInit {
  isMobile = false;
  isClick;
  displayedColumns = ['amount', 'status', 'date', 'tranType', 'info'];
  isShow = false;
  startDate;
  endDate;
  todaysDate;
  tranDetailArray = [];
  filteredTranArray = [];
  isNodata = false;
  month;
  @Output() loaderEvent = new EventEmitter();
  @Output() loadLimit = new EventEmitter<boolean>();
  isLoading = true;
  year;
  pricepipe;
  date;
  showStartDate;
  filterData;
  statusFilter = '';
  transactionFiter = '';
  expandedElement: any;
  showEndDate;
  chipData = ['1D', '1W', '1M', '6M'];
  statusChips = ['ALL', 'SUCCESS', 'FAILURE'];
  transactionChips = ['CREDIT', 'DEBIT'];
  unique: any;
  expand;
  dataLength;
  Height;
  time;
  statusChip;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  payOutStartTime: any;
  payOutEndTime: any;
  presentTime: any;
  payoutEnable: any;
  
  constructor(private tradingRestService: TradingRestService, public dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.isMobile = Utility.IS_MOBILE; 
    this.todaysDate = moment().format('Y-MM-DD');
    this.endDate = moment().format('Y-MM-DD');
    this.startDate = moment(moment().subtract(1, 'months').calendar()).format('Y-MM-DD');
    this.showStartDate = new FormControl(this.startDate);
    this.showEndDate = new FormControl(this.endDate);
    this.getDetails("");
     Utility.transactionRefresh.subscribe(() => {
      this.startDate = moment(moment().subtract(1, 'months').calendar()).format('Y-MM-DD');
      this.endDate = moment().format('Y-MM-DD');
      this.showStartDate = new FormControl(this.startDate);
      this.showEndDate = new FormControl(this.endDate);
      this.getDetails("");
     });
     Utility.closeWithdraw.subscribe(() => {
       this.closedTransactionDetail();
     })
   }

  updateStartDate(startvalue){
   this.startDate = moment(new Date(startvalue)).format('Y-MM-DD');
  }

  updateEndDate(endvalue){
    this.endDate =  moment(new Date(endvalue)).format('Y-MM-DD');
  }
  onRefresh() {
    this.getDetails("");
  }

  exportCSV() {
    const csv = this.filteredTranArray;
    csv.forEach(function (el) {
      Object.keys(el).forEach(function (property) {
        if (property === 'rzrpay_trns_charge' && el[property] !== null){
          el[property] = el[property] / 100;
        }
        else if (property === 'rzrpay_trns_gst' && el[property] !== null){
          el[property] = el[property] / 100;
        }
        else if (el[property] === null) {
          el[property] = '-';
        }
      });
    });
  CSVUtility.ExportDataToCSV(csv, 'Transaction Report', 'Transaction Details', 'Transaction Data');
  }

  filterArrayData(){

    if (this.statusFilter !== '' && this.transactionFiter !== ''){
      if (this.statusFilter === 'SUCCESS'){
        this.filteredTranArray = this.tranDetailArray.filter(data => {
          return this.getStatus(data['status']) === 'Success';
        });
      }
      else if (this.statusFilter === 'FAILURE'){
        this.filteredTranArray = this.tranDetailArray.filter(data => {
          return this.getStatus(data['status']) === 'Failed';
        });
      }

      if (this.transactionFiter === 'CREDIT'){
        this.filteredTranArray = this.filteredTranArray.filter(data => {
          return data['transaction_type'] === 'payin';
        });
      }
      else if (this.transactionFiter === 'DEBIT'){
          this.filteredTranArray = this.filteredTranArray.filter(data => {
            return data['transaction_type'] === 'payout';
          });
        }
    }
    else if (this.statusFilter !== ''){
      if (this.statusFilter === 'SUCCESS'){
        this.filteredTranArray = this.tranDetailArray.filter( data => {
          return  this.getStatus(data['status']) === 'Success';
        });
      }
      else if (this.statusFilter === 'FAILURE'){
        this.filteredTranArray = this.tranDetailArray.filter( data => {
          return this.getStatus(data['status']) === 'Failed';
        });
      }
    }

   else if (this.transactionFiter !== ''){
    if (this.transactionFiter === 'CREDIT'){
      this.filteredTranArray = this.tranDetailArray.filter(data => {
        return data['transaction_type'] === 'payin';
      });
    }
    else if (this.transactionFiter === 'DEBIT'){
        this.filteredTranArray = this.tranDetailArray.filter(data => {
          return data['transaction_type'] === 'payout';
        });
      }
    }

  }

  onInfoClick(unique_key, i){
    if (this.unique === unique_key){
      this.unique = '';
    }
    else{
      this.unique = unique_key;
    }
  }

  onTabChange(obj, filterType){
    this.unique = '';
    if (filterType === 'dateFilter'){
      this.filterData = obj;
    }
    else if (filterType === 'statusFilter'){
       if (obj !== 'ALL'){
         this.statusFilter = obj;
       }
       this.statusChip = obj;
    }
    else{
      this.transactionFiter = obj;
    }
    switch (obj){
      case '1D':
        this.startDate = moment().format('Y-MM-DD');
      break;
      case '1W':
        this.startDate = moment().subtract(7, 'days').calendar();
      break;
      case '1M':
        this.startDate = moment().subtract(1, 'months').calendar();
      break;
      case '6M':
        this.startDate = moment().subtract(6, 'months').calendar();
        break;
      case 'SUCCESS':
         this.filterArrayData();
      break;
      case 'FAILURE':
        this.filterArrayData();
      break;
      case 'ALL':
        this.filteredTranArray = this.tranDetailArray;
        this.transactionFiter = '';
        this.statusFilter = '';
      break;
      case 'CREDIT':
        this.filterArrayData();
        break;
      case 'DEBIT':
        this.filterArrayData();
        break;
    }
    this.startDate = moment(new Date(this.startDate)).format('Y-MM-DD');
    this.showStartDate =  new FormControl(this.startDate);

    if (filterType === 'dateFilter'){
      this.getDetails("");
    }
}

getTime(inputDate : any)
{
  var date = new Date(inputDate);
  var formatted = moment(date).format('MMMM Do YYYY, h:mm:ss a');
  return formatted;
}

  getDetails(requestFrom){
    if (requestFrom === "submitButton"){
      this.statusFilter = "";
      this.filterData = "";
      this.transactionFiter = "";
      this.statusChip = "";
    }
    const data = {uid: Preferences.getPreference('accountId'), startDate: this.startDate, endDate: this.endDate};
    if (this.endDate > moment().format('Y-MM-DD')){
      this.snackBar.open(this.translate.instant('limits.valid_enddate'), '', { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.startDate > this.endDate){
      this.snackBar.open(this.translate.instant('limits.valid_startdate'), '', { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else{
      this.isLoading = true;
      this.loadLimit.emit(this.isLoading);
      this.tradingRestService.getUserService().TransactionDetails((res, code, message) => {
        this.isLoading = false;
        this.loadLimit.emit(this.isLoading);
        if (code === 200 && res.data.length > 0) {
            this.isShow = true;
            this.isNodata = false;
            this.payoutEnable = Utility.SegmentDetails['payout_enable'];
             this.tranDetailArray = res.data;
             this.filteredTranArray = this.tranDetailArray;
             this.dataLength = this.filteredTranArray.length;
             if (this.statusFilter !== '' || this.transactionFiter !== ''){
              this.filterArrayData();
             }
          }
          else {
            this.isNodata = true;
          }
      }, data);
    }
  }

  onCancelWithdrawal(cancelData){
    this.presentTime = moment().format('HHmm');
    this.payOutStartTime =  moment(Utility.SegmentDetails['payout_cutoff']['start'], "HH:mm").format("HHmm");
    this.payOutEndTime =  moment(Utility.SegmentDetails['payout_cutoff']['end'], "HH:mm").format("HHmm");
    this.payoutEnable = Utility.SegmentDetails['payout_enable'];
   if ((this.presentTime < this.payOutStartTime || this.time > this.payOutEndTime)){
      this.snackBar.open(this.translate.instant('limits.transaction_b/w') + Utility.SegmentDetails['payout_cutoff']['start'] + "-" + Utility.SegmentDetails['payout_cutoff']['end'], '', { duration: 4000, panelClass: ['errorSnackBar'] });
   }
  else{
    const alertMessage =  this.translate.instant('alert.alert_withdraw')
    Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: {heading: this.translate.instant('alert.alert_withdraw_hed'), message: alertMessage} });
    Utility.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm'){
        this.isLoading = true;
        this.loadLimit.emit(this.isLoading);
        const cancelRequest = {
          "bill_id": cancelData,
          "status":"cancel",
          "user_id": Preferences.getPreference('accountId'),
          "limit_payout_amt": Utility.payoutamt
      };
      this.tradingRestService.getUserService().cancelWithdrawal((res, code, message) => {
        this.isLoading = false;
        this.loadLimit.emit(this.isLoading);
       if (code === 200){
        Utility.reloadLimit.next();
        this.snackBar.open(this.translate.instant('limits.withdraw_cancel'), '', { duration: 2000, panelClass: ['errorSnackBar'] });
       }
       else{
        this.snackBar.open(this.translate.instant('limits.cancel_failed'), '', { duration: 4000, panelClass: ['errorSnackBar'] });
       }
       this.getDetails('');
      }, cancelRequest);
      }
    });
  }
  }

  onModifyClick(modifyObject){
    this.presentTime = moment().format('HHmm');
    this.payOutStartTime =  moment(Utility.SegmentDetails['payout_cutoff']['start'], "HH:mm").format("HHmm");
    this.payOutEndTime =  moment(Utility.SegmentDetails['payout_cutoff']['end'], "HH:mm").format("HHmm");
    
    if ((this.presentTime < this.payOutStartTime || this.time > this.payOutEndTime)){
      this.snackBar.open(this.translate.instant('limits.transaction_b/w') + Utility.SegmentDetails['payout_cutoff']['start'] + "-" + Utility.SegmentDetails['payout_cutoff']['end'], '', { duration: 4000, panelClass: ['errorSnackBar'] });
   }
   else{
    Utility.closeAddOverLay.next();
    Utility.modifyWithdrawalData = [{'bill_id': modifyObject['unique_key'], 'amount': modifyObject['amount']}];
    
    Utility.openWithDraw.next();
    Utility.fillWithdrawalData.next();
   }
  }

  getStatus(paymentData){
    const paymentStatus = paymentData;
    let status;
    switch(paymentStatus){
      case 'payout_initiated':
        status = 'In Progress';
        break;
      case 'cancelled':
        status = 'Cancelled';
        break;
      case 'modified':
        status = 'Modified';
        break;
      case 'rejected':
        status = 'Rejected';
        break;
      case 'completed':
        status = 'Success';
        break;
      case 'payment_completed':
        status = 'Success';
        break;
      case 'payment_captured':
        status = 'In Progress';
        break;
      case 'invalid_payment':
        status = 'Failed';
      break;

      case 'api_failure':
      status = 'Failed';
      break;
    
      case 'status_mismatch':
      status = 'Failed';
      break;
    
      case 'amount_mismatch':
      status = 'Failed';
      break;
    
      case 'api_error':
      status = 'Failed';
      break;
    
      case 'payin_inprocess':
      status = 'In Progress';
      break;
    
      case 'payment_completed':
      status = 'Success';
      break;
    
      case 'send_reqtolus':
      status = 'In Progress';
      break;
    
      case 'failed_fromlus':
      status = 'Failed';
      break;
    
      case 'order_created':
      status = 'In Progress';
      break;

      case 'send_reqtolus_payout':
      status = 'In Progress';
      break;

      case 'send_reqtolus_payoutCancel':
      status = 'In Progress';
      break;

      case 'send_reqtolus_payoutModify':
      status = 'In Progress';
      break;

      case 'send_reqtolus_payoutReject':
      status = 'In Progress';
      break;

      case 'payout_failed':
      status = 'Failed';
      break;

    }
    return status;
  }

  closedTransactionDetail(){
    this.unique = '';
  }
}
