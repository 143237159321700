import { Component, HostListener, Input, EventEmitter, Output, OnDestroy, ViewChild, OnChanges, OnInit, ChangeDetectorRef, Injector, ElementRef } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Utility } from 'src/app/services/utility';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from 'src/app/services/tableutility';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { Subject } from 'rxjs';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let news : NewsComponent;
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent extends BaseComponentDirective implements OnDestroy, OnInit, OnChanges {
  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
  newsArr = [];
  @Input() IsStockSpecific: boolean;
  @Input() Symbol: string;
  @Output() loaderEvent = new EventEmitter();
  @Output() load = new EventEmitter<boolean>();
  @Output() openNewsSearch = new EventEmitter<boolean>();
  @Output() closeNewsSearch = new EventEmitter<boolean>();
  @Input() isCustomDashboard: boolean;
  isNoData: boolean;
  isLoading = true;
  dummyImg = 'assets/img/news-placeholder.png';
  obj = { 'start': 0, 'limit': 10 };
  allTags = [];
  tagtext = 'All';
  numberofcol = 1;
  isSearch = true;
  tag_item = {};
  goback = '1';
  isMobile: boolean;
  container;
  Height: any;
  modesub: Subscription;
  newsChip : any;
  isReload = false;
  targetElement: Element;

  constructor(elRef: ElementRef, private PS: PtPlusRestService, private cdr: ChangeDetectorRef, private injector: Injector) {
    super(elRef.nativeElement);
    news = this;
    this.isLoading = true;
    this.isMobile = Utility.IS_MOBILE;
    if (!Utility.IS_MOBILE) {
      this.goback = '2';
    }
    this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      this.changeMode();
  });
  Utility.newsSearchReq.subscribe(data => {
    this.IsStockSpecific = false;
    this.update();
  });
  }
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    this.changeMode();
  }
  ngOnInit() {
    this.targetElement = document.querySelector('html');
    if(this.inputs !== undefined)
      this.isCustomDashboard = this.inputs.isCustomDashboard;
    if (this.isCustomDashboard) {
      // this.container = <ComponentContainer>this.injector.get('Container');
      // this.changeMode();
      /* this.container.on('resize', _ => {
        this.changeMode();
      });*/
    }

    Utility.newsComponent = this;
    this.load.emit(true);
    this.loaderEvent.emit(true);
    this.cdr.detectChanges();
    this.onResize();
    this.update();

    Utility.webReloadComponent.subscribe((data) =>
    {
      if(news.isReload === false && data === 'News')
      {
        news.isReload = true;
        this.reloadData();
      }
    });
  }
  onRefresh() {
    this.tagtext = 'All';
    this.onResize();
    this.update();
  }

  onTitleClick() {
    this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  reloadData()
  {
    if(this.tagtext === 'All')
      this.cleartext();
    else
      this.selectionChanged(this.newsChip);
  }

  changeMode(){
    this.Height = {
      'height': (this.container.height - TableUtility.MVHeight) + 'px',
      'overflow': 'auto'
    };
    this.cdr.detectChanges();

    if (this.container.width >= 1200) {
      this.numberofcol = 4;
    } else if (this.container.width >= 768 && this.container.width < 1200) {
      this.numberofcol = 3;
    } else if (this.container.width >= 500 && this.container.width < 768) {
      this.numberofcol = 2;
    } else {
      this.numberofcol = 1;
    }
  }

  openSearch(flag) {
    this.openNewsSearch.emit(flag);
  }
  ngOnChanges() {
    Utility.newsComponent = this;
    this.loaderEvent.emit(true);
    this.cdr.detectChanges();
    if(!this.isCustomDashboard)
      this.onResize();
    this.update();
  }

  update() {
    if (this.IsStockSpecific) {
      this.PS.getNewsService().getSearchedStockNews(this.Symbol, this.setNewsData.bind(this), this.setPreNewsData.bind(this));
    } else {
      if (Preferences.getItem('tag_item', ReturnType.Undefined) !== undefined && Preferences.getItem('tag_item') !== null) {
        this.tag_item = Preferences.getItem('tag_item');
        this.tagtext = this.tag_item['tag'];
        Preferences.removeItem('tag_item');
        this.PS.getNewsService().getAllNews(this.tag_item['id'], 0, 50, this.setNewsData.bind(this), this.setPreNewsData.bind(this));
      } else {
        this.PS.getNewsService().getAllNews(0, 0, 50, this.setNewsData.bind(this), this.setPreNewsData.bind(this));
      }
      this.PS.getNewsService().fetchTags(this.obj, this.getTagData.bind(this), this.getPreTagData.bind(this));
    }
  }

  cleartext() {
    this.tagtext = 'All';
    this.isLoading = true;
    this.PS.getNewsService().getAllNews(0, 0, 50, this.setNewsData.bind(this), this.setPreNewsData.bind(this));
  }

  @HostListener('window:resize')
  onResize() {
    if (!this.isCustomDashboard) {
      if (window.innerWidth >= 1200) {
        this.numberofcol = 4;
      } else if (window.innerWidth >= 768 && window.innerWidth < 1200) {
        this.numberofcol = 3;
      } else {
        this.numberofcol = 1;
      }
    }
  }

  selectionChanged(data) {
    this.newsChip = data;
    this.tagtext = data.tag;
    this.isLoading = true;
    this.load.emit(true);
    this.PS.getNewsService().getAllNews(data.id, 0, 50, this.setNewsData.bind(this), this.setPreNewsData.bind(this));
  }

  setNewsData(code, data, msg) {
    this.loaderEvent.emit(false);
    this.load.emit(false);
    this.closeNewsSearch.emit(true);
    this.isLoading = false;
    this.isReload = false;
    if (code === 200 && data.length > 0) {
      this.newsArr = data;
      this.isNoData = false;
    }
    else {
      this.isNoData = true;
    }
  }
  setPreNewsData(data) {
    this.load.emit(false);
    this.newsArr = data;
  }

  getTagData(code, tags, message) {
    if (tags.length > 0) {
      this.allTags = tags;
    }
  }
  getPreTagData(tags) {
    if (tags.length > 0) {
      this.allTags = tags;
    }
  }
  openNewTab(url, target) {
    window.open(url, target);
  }
  ngOnDestroy() {
    Utility.newsComponent = null;
  }

  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      this.reloadData();
      event.next();
    }, 1000);
  }
  
  alert(message: string) {
    
  }
}
