import { Directive, EventEmitter, InjectionToken, Input, Output } from '@angular/core';
import {
    ComponentContainer
} from "golden-layout";
import { environment } from 'src/environments/environment';

declare const gtag: Function;

@Directive()
export abstract class BaseComponentDirective {
    @Output() outputs = new EventEmitter<any>();
    @Input() inputs: any;
    basename = '';
    constructor(public rootHtmlElement: HTMLElement, basename = 'all') {
        this.basename = basename;
    }

    setPositionAndSize(left: number, top: number, width: number, height: number) {

    }
    protected setBasePositionAndSize(left: number, top: number, width: number, height: number) {
        this.rootHtmlElement.style.left = this.numberToPixels(left);
        this.rootHtmlElement.style.top = this.numberToPixels(top);
        this.rootHtmlElement.style.width = this.numberToPixels(width);
        this.rootHtmlElement.style.height = this.numberToPixels(height);
    } 
    setBaseVisibility(visible: boolean){
    }
    setVisibility(visible: boolean) {
        if (visible) {
            this.rootHtmlElement.style.display = '';
            if(environment.isGoogleAnalytics){
                gtag('event', 'page_view', {
                    page_path: this.basename
                 });    
            }
        } else {
            this.rootHtmlElement.style.display = 'none';
        }
    }

    setZIndex(value: string) {
        this.rootHtmlElement.style.zIndex = value;
    }

    protected numberToPixels(value: number): string {
        return value.toString(10) + 'px';
    }
}

export namespace BaseComponentDirective {
    const GoldenLayoutContainerTokenName = 'GoldenLayoutContainer'; 
    export const GoldenLayoutContainerInjectionToken = new InjectionToken<ComponentContainer>(GoldenLayoutContainerTokenName);
}
