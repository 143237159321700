import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { IFundamentalsService } from './ifundamentals.service';
import { Url } from '../url';
import { Keys } from '../keys';

@Injectable()
export class FundamentalsService implements IFundamentalsService {
    apiService: ApiService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }
    getKeyratioData(scripToken, exch, tokenType, callback: Function) {
        const data = {};
        let message;
        let grpData;
        const params = { scrip_token: scripToken, exchange_seg: exch, type: tokenType };
        this.apiService.ptpluspost(Url.KeyRatio, params).subscribe((response) => {
            if (response.code === Keys.RES_CODE_SUCCESS) {
                if (response.data.Group !== undefined) {
                    if (Object.keys(response.data.Group).length > 0) {
                        grpData = response.data.Group;
                    }
                    else
                        message = 'NO DATA';
                }
                else
                    message = response.message;
            }
            else
                message = response.message;
            callback(response.code, grpData, message);
        },
            (error) => {
                message = error;
                callback(Keys.RES_CODE_FAILURE, '', message);
            });
    }

    getPeerCompData(scripToken, exch, tokenType, callback: Function) {
        let message;
        let data = {};
        const params = { scrip_token: scripToken, exchange_segment: exch, type: tokenType };
        this.apiService.ptpluspost(Url.PeerComp, params).subscribe((response) => {
            if (response.code === Keys.RES_CODE_SUCCESS) {
                if (Object.keys(response.data).length > 0)
                    data = response.data;
                else
                    message = 'NO DATA';
            }
            else
                message = response.message;
            callback(response.code, data, message);
        },
            (error) => {
                message = error;
                callback(Keys.RES_CODE_FAILURE, '', message);
            });
    }

    getTechnicalsData(scripToken, exch, tokenType, requestType, callback: Function) {
        let message;
        let data = {};
        const params = { scrip_token: scripToken, exchange: exch, request_type: requestType };
        this.apiService.ptpluspost(Url.TechnicalsScanners, params).subscribe((response) => {
            if (response.code === Keys.RES_CODE_SUCCESS) {
                if (response && response.data)
                    data = response.data;
                else
                    message = 'NO DATA';
            }
            else
                message = response.message;
            callback(response.code, data, message);
        },
            (error) => {
                message = error;
                callback(Keys.RES_CODE_FAILURE, '', message);
            });
    }

    getFinancialData(scripToken, exch, tokenType, callback: Function) {
        let message;
        let data = {};
        const params = { scrip_token: scripToken, exchange: exch, type: tokenType };
        this.apiService.ptpluspost(Url.FinancialStatement, params).subscribe((response) => {
            if (response.code === Keys.RES_CODE_SUCCESS) {
                if (response && response.data)
                    data = response.data;
                else
                    message = 'NO DATA';
            }
            else
                message = response.message;
            callback(response.code, response, message);
        }, error => {
            message = error;
            callback(Keys.RES_CODE_FAILURE, '', message);
        });
    }

    getCompayData(scripToken, exch, tokenType, callback: Function) {
        let message;
        let data = {};
        const params = { scrip_token: scripToken, exchange_segment: exch, type: tokenType };
        this.apiService.ptpluspost(Url.CompanyData, params).subscribe((response) => {
            if (response.code === Keys.RES_CODE_SUCCESS) {
                if (Object.keys(response.data).length > 0)
                    data = response.data;
                else
                    message = 'NO DATA';
            }
            else
                message = response.message;
            callback(response.code, data, message);
        },
            (error) => {
                message = error;
                callback(Keys.RES_CODE_FAILURE, '', message);
            });

    }

    getIbes(scripToken, exch, tokenType, callback: Function) {
        let message;
        let data = {};
        const params = { exchange_seg: exch, scrip_token: scripToken, type: tokenType };
        this.apiService.ptpluspost(Url.GetIbes, params).subscribe((response) => {
            if (response.code === Keys.RES_CODE_SUCCESS) {
                if (Object.keys(response.data).length > 0)
                    data = response.data;
                else
                    message = 'NO DATA';
            }
            else
                message = response.message;
            callback(response.code, data, message);
        },
            (error) => {
                message = error;
                callback(Keys.RES_CODE_FAILURE, '', message);
            });
    }
}
