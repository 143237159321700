import { Injectable } from '@angular/core';
import { Utility } from '../../utility';
import { Url } from '../../url';
import { environment } from 'src/environments/environment.prod';

let tradeSocketService: TradesocketService;
declare const HSWebSocket: any;

@Injectable()
export class TradesocketService {
    private webSocket: any;
    isOpenConnection = false;

    constructor() {
        tradeSocketService = this;
    }

    public connect(ConnectionCallback: any) {
        tradeSocketService.isOpenConnection = false;
        try {
            tradeSocketService.webSocket = new WebSocket(environment.orderwss);
        } catch (error) {
            Utility.print('======socket Exception======');
        }

        tradeSocketService.webSocket.onopen = function () {
            tradeSocketService.isOpenConnection = true;
            ConnectionCallback('open');
        };

        tradeSocketService.webSocket.onmessage = function (event) {
            ConnectionCallback('message', event);
        };

        tradeSocketService.webSocket.onclose = function () {
            ConnectionCallback('close');
        };
        tradeSocketService.webSocket.onerror = function (event) {
            ConnectionCallback('error');
        };
    }

    public sendMessage(data) {
        if (tradeSocketService.webSocket !== null && data !== undefined) {
            if (tradeSocketService.webSocket.readyState === 1) {
                const str = JSON.stringify(data);
                try {
                    tradeSocketService.webSocket.send(str);
                } catch (err) {
                    Utility.print('exception in sendMessage');
                }
            }
        }
    }

    public closeSocketConnection() {
        try {
            if (tradeSocketService.webSocket != null) {
                tradeSocketService.webSocket.close();
                tradeSocketService.webSocket = null;
            }
        } catch (err) {
            Utility.print(err);
        }
    }

    public SocketStatus() {
        if (tradeSocketService.webSocket !== null) {
            return tradeSocketService.webSocket.OPEN;
        } else {
            return false;
        }
    }
}
