import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import { BasketsipmoreComponent } from '../basketsipmore/basketsipmore.component';
import { SipupdateComponent } from '../sipupdate/sipupdate.component';
import { CreateEmandateComponent } from '../createemandate/createemandate.component';

let basket: BasketsipComponent;
@Component({
  selector: 'app-basketsip',
  templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketsip/basketsip.component.html'
})
export class BasketsipComponent extends BaseComponentDirective implements OnInit {
  @Output() load = new EventEmitter<boolean>();
  @Input() Height;
  isLoading = false;
  isNoData = true;
  basketList = [];
  isMobile;
  statusMap = {};
  weekDay = {};
  isSipUpdate = false;
  isEmandateFlag = environment.emandate.enable;

  constructor(elRef: ElementRef, private tradingRestService: TradingRestService, private PS: PtPlusRestService, private snackBar: MatSnackBar, private _bottomSheet: MatBottomSheet, private router: Router, public dialog: MatDialog) { 
    super(elRef.nativeElement);
    basket = this;
    basket.isMobile = Utility.IS_MOBILE;
    basket.PS.getThematicBasketService().setTradingRestService(tradingRestService);
    Utility.callBasketSip.subscribe(()=>{
      basket.getData();
    });
  }
  ngOnInit(): void {
    basket.setStatusMap();
    basket.getData();
    basket.setWeekDay();
  }

  setStatusMap(){
    basket.statusMap[1] = 'Active';
    basket.statusMap[2] = 'Pause';
    basket.statusMap[-1] = 'Delete';
  }

  setWeekDay() {
     basket.weekDay[1] = 'Monday';
     basket.weekDay[2] = 'Tuesday';
     basket.weekDay[3] = 'Wednesday';
     basket.weekDay[4] = 'Thursday';
     basket.weekDay[5] = 'Friday';
  }

  getData(){
    basket.isLoading = true;
    basket.PS.getThematicBasketService().getUserSip(basket.allBasketDataResponse);
  }

  allBasketDataResponse(result, code, message) {
    basket.isLoading = false;
    basket.isNoData = false;
    basket.isSipUpdate = false;
    basket.load.emit(basket.isLoading);
    // code = 200;
    // message = 'SUCCESS';
    // result = [{"id":1,"sip_frequency":"Quarterly","sip_day":7,"basket_version":"1","basket_id":1,"sip_status":1,"next_trigger_date":"2022-01-07","basket_name":"Equity & Gold","sip_amount":4000,"sip_types":"[{\"day\":\"any\",\"type\":\"Quarterly\"}]"},{"id":3,"sip_frequency":"Monthly","sip_day":22,"basket_version":"1","basket_id":2,"sip_status":2,"next_trigger_date":"2021-12-22","basket_name":"All Weather Investing","sip_amount":6000,"sip_types":"[{\"day\":\"7,15,22\",\"type\":\"Monthly\"}]"}];
    if (code === 200 && message === 'SUCCESS') {
        basket.basketList = result;
    }
    basket.isNoData = (basket.basketList.length === 0 ) ? true: false;
  }

  changeStatus(id, status){
    basket.isLoading = true;
    basket.isSipUpdate = true;
    let obj = {'id': id, 'sip_status': status};
    basket.PS.getThematicBasketService().changeSipStatus(obj, basket.changeStatusResponse);
  }

  changeStatusResponse(code, status, message){
    basket.isSipUpdate = false;
    if(code === 200 && status === 'SUCCESS'){
      basket.snackBar.open(message, 'Close', { duration: 3000, panelClass: ['sucessSnackBar'] });
      basket.getData();
    } else {
      basket.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['errorSnackBar']});
    }
  }

  openSipMore(data){
    Utility.selectedSipData = data;
    const bottomSheetRef = this._bottomSheet.open(BasketsipmoreComponent, {
      restoreFocus: false
    });
  }

  sipUpdate(data){
    Utility.selectedSipData = data;
    Utility.openPage(this.router, 'basketsip', 'sipupdate', SipupdateComponent, this.dialog, 'popup');
  }

  handleMouseOver(row){
    const id = row.id;
    this.basketList.map((data: any) => {
      if (data.id === id) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row){
    const id = row.id;
    this.basketList.map((data: any) => {
      if (data.id === id) {
        data.show = false;
      }
    });
  }

  openEmandate(data) {
    const obj = {'emandateFrm': 'basketSip'};
    Utility.selectedSipData = data;
    Utility.openPage(this.router, 'basketsip', 'createemandate', CreateEmandateComponent, this.dialog, 'popup', obj, '480px', '480px');
  }

}
