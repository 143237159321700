import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { BasketPlaceOrderModel } from 'src/app/model/basketplaceorder.model';
import { Utility } from 'src/app/services/utility';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BasketDetailModel } from 'src/app/model/basketdetail.model';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CreateEmandateComponent } from '../createemandate/createemandate.component';

let basketPlaceOrderSummary: BasketPlaceOrderSummaryComponent;
@Component({
    selector: 'app-basketplaceordersummary',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketplaceordersummary/basketplaceordersummary.component.html',
    styleUrls: ['./basketplaceordersummary.component.scss']
})

export class BasketPlaceOrderSummaryComponent implements OnChanges, OnInit {
    isMobile: boolean;
    isNodata: boolean;
    retryDisable = true;
    isLoading;
    basketOrderlList: Array<BasketPlaceOrderModel>;
    completeOrderList = Array<BasketPlaceOrderModel>();
    displayColumns: string[] = ['Stock', 'Avg Price', 'Filled Qty', 'Status'];
    isEmandateFlag = environment.emandate.enable;
    emandateShow = false;

    constructor(private PS: PtPlusRestService, private router: Router, private dialog: MatDialog, private location: Location) {

        basketPlaceOrderSummary = this;
        basketPlaceOrderSummary.isMobile = Utility.IS_MOBILE;
        this.basketOrderlList = Preferences.getItem('basketPlaceOrderData', ReturnType.Array);
        if(basketPlaceOrderSummary.basketOrderlList.length > 0)
        basketPlaceOrderSummary.checkFailedOrders();
        else 
            this.isNodata = true;
    }

    ngOnInit() {
        if (Utility.placedSipData['isSip'] === 'SIP') {
            basketPlaceOrderSummary.checkCompleteOrders();
        }
    }

    checkCompleteOrders() {
        for (let i = 0; i < basketPlaceOrderSummary.basketOrderlList.length; i++) {
            if (basketPlaceOrderSummary.basketOrderlList[i].status === 'complete') {
                this.emandateShow = true;
                break;
            } 
        }
    }


    checkFailedOrders()
    {
        for(let i = 0; i < basketPlaceOrderSummary.basketOrderlList.length; i++)
        {
            if(basketPlaceOrderSummary.basketOrderlList[i].status == 'cancelled' || basketPlaceOrderSummary.basketOrderlList[i].status == 'rejected')
            {
                basketPlaceOrderSummary.retryDisable = false;
                break;
            }
        }   
    }

    Retry(basketOrderList)
    {
        this.retryDisable = true;
        const jsonObj = {
            basket_id:  basketOrderList[0].basket_id,
            basket_version: basketOrderList[0].basket_version,
            placeorder_type: "RetryPurchase",
            batch_no: basketOrderList[0].batch_no
        };
 
        basketPlaceOrderSummary.isLoading = true;
        this.PS.getThematicBasketService().getRepairOrders(jsonObj, this.repairOrdersResponse);
    }

    repairOrdersResponse(data, code, message){
        if (code === 200 && message === 'OK')
        {
            basketPlaceOrderSummary.basketOrderlList = data;
            basketPlaceOrderSummary.isNodata = false;
        }
        else{
            basketPlaceOrderSummary.isNodata = true;
        }
        basketPlaceOrderSummary.isLoading = false;
        basketPlaceOrderSummary.retryDisable = false;
        basketPlaceOrderSummary.checkFailedOrders();
    }

    ngOnChanges() {

    }

    GoToBasket()
    {
        Utility.SELECTED_BASKET_TAB = 1;
        if(basketPlaceOrderSummary.isMobile){
            this.location.back();
            this.location.back();
            this.location.back();
        } else {
            Utility.dialogRef.close();
            Utility.callInvestorBaskets.next();
            var obj = {"name":"Investment Boxes"}
            Utility.InputsDataGet.next(obj);
        }
    }

    openEmandate() {
        const obj = {'emandateFrm': 'sipBsktOrdersum'};
        if(this.isMobile) {
            this.router.navigate(['/createemandate'], {queryParams:obj});
        } else {
            Utility.dialogRef.close();
            Utility.openPage(this.router, 'basketplaceordersummary', 'createemandate', CreateEmandateComponent, this.dialog, 'popup', obj, '480px', '480px');
        }
    }
}