<app-header *ngIf="isMobile" goback="1" [title]="'all.reg_eMan' | translate" [isLoading]="isLoading"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'all.reg_eMan' | translate" [isLoading]="isLoading"></app-titlebar>
<div *ngIf = "isNodata">
    <app-nodata (refreshEvent) = "getAllTransaction()" [btnTitle]="'utility.reload' | translate"></app-nodata>
</div>
<div *ngIf = "!isNodata">
    <div *ngIf="isMobile" [class.mt-5]="isMobile">
        <div *ngFor="let option of transactionsList; let i = index"  [class.wlweb]="isMobile">
            <div class="row">
                <div class="col-12">
                    <div class="list-view border-b" [ngClass]="{'d-block d-sm-none' : isMobile}">
                        <div class="row">
                            <div class="col-7 pr-0">
                                <span [ngClass]="{'tc-1 ts-4 textclip' : true}" matTooltip="{{option.remark}}">{{option.remark}}</span><br>
                                <span [ngClass]="{'ts-2 subcolor' : true}">{{option.start_date | datepipe:'date'}}</span>
                            </div>
                            <div class="col-5 text-right">
                                <label class="line2 subcolor ts-2">{{'all.amount' | translate}}:&nbsp;</label><span class="ts-2">{{option.debit_amount | pricepipe: 2}}</span><br>
                                <span class="ts-2">{{frequencyMap[option.debit_freq]}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div *ngIf="!isMobile">
        <div #p1 class="table-global"> 
            <table mat-table [dataSource]="transactionsList">
                <ng-container matColumnDef="Remark">
                    <th class="text-left" mat-header-cell *matHeaderCellDef><span translate>all.remark</span></th>
                    <td mat-cell *matCellDef="let element" class="text-left"><span>{{element.remark}}</span></td>
                </ng-container>
                <ng-container matColumnDef="Frequency">
                    <th class="text-left" mat-header-cell *matHeaderCellDef><span translate>all.frequency</span> </th>
                    <td mat-cell *matCellDef="let element" class="text-left"><span>{{frequencyMap[element.debit_freq]}} </span></td>
                </ng-container>
                <ng-container matColumnDef="StartDate">
                    <th mat-header-cell *matHeaderCellDef><span translate>all.startdate</span></th>
                    <td mat-cell *matCellDef="let element"><span>{{element.start_date | datepipe:'date'}}</span></td>
                </ng-container>
                <ng-container matColumnDef="Amount">
                    <th mat-header-cell *matHeaderCellDef><span translate>all.amount</span> </th>
                    <td mat-cell *matCellDef="let element"><span>{{element.debit_amount | pricepipe: 2}} </span></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index"></tr>
            </table>
        </div>
    </div>
</div>