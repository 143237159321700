<app-titlebar *ngIf = "!isMobile"  [title]="sipdata.basket_name" [isLoading]="isLoading"></app-titlebar>
<app-header  *ngIf = "isMobile && sipdata" goback="1" [title]="sipdata.basket_name" [isLoading]="isLoading"></app-header>
<div class="row p-2 mt-2" [class.data-container]="isMobile" *ngIf="sipdata">
    <div class="col-12 d-none">
        <mat-form-field>
            <mat-label>{{'settings.status' | translate}}</mat-label>
            <mat-select [(ngModel)]="sipdata.sip_status">
                <mat-option [value] = "1"><span> {{'all.active' | translate}}</span></mat-option>
                <mat-option [value] = "2"><span>{{'all.pause' | translate}}</span></mat-option>
                <mat-option [value] = "-1"><span>{{'all.delete' | translate}}</span></mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-6">
        <mat-form-field>
            <mat-label>{{'all.frequency' | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedFrequency" (ngModelChange)="SelectSipDay()">
                <mat-option *ngFor="let data of sip_frequency" [value]="data">{{data}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-6">
        <mat-form-field>
            <mat-label>{{'all.day' | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedDay">
                <mat-option *ngFor="let data of sip_day" [value]="data">{{data}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-12">
        <mat-form-field>
            <mat-label>{{'all.sip' | translate}} {{'books.amt' | translate}}</mat-label>
            <input matInput type="text" [(ngModel)] = "sipdata.sip_amount"
            inputmode="numeric" #focus autocomplete="off" appNumberDir dp="2" pricesize=10>
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions class="mat-dialog-actions d-block" [class.mat-action-web]="!isMobile">
    <div class="text-right px-0" [class.footerbtn]="isMobile" [class.p-action-r]="isMobile" [class.padd] = "!isMobile">
        <button class="btn-positive" [disabled]="updateDisable" [class.w-100]="isMobile" mat-flat-button (click)="updateSip()">{{'all.update' | translate}}</button> 
    </div>
</mat-dialog-actions>
