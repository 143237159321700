<app-titlebar *ngIf="!isMobile"  [title]="'login.about_us' | translate"></app-titlebar>
<app-header *ngIf="isMobile" goback="1" [title]="'login.about_us' | translate" [isReloadVisible]="false"></app-header>

<mat-card [class.mx-2]="isMobile" class="p-1">
    <mat-card-content>
        <div [class.mt-5]="isMobile" [class.p-0]="isMobile" class = "mt-5 p-0 webpanelheight">
            <div class="p-2">
                FinSpot comes from the same people that have designed and built one of India’s most widely used OMS and RMS platforms - for both Retail and Institutional trading. 
            </div>
            <div class="pt-2 p-2">
                Our team has several hundred years of cumulative experience in securities markets technology.
    
                We understand this business and it's technology challenges to work better alongside you in your business.
            </div>
            <div class="pt-2 p-2 subcolor2">
                <p [matTooltip] = "version" [matTooltipPosition]="'above'">Software version: HyperTrade {{display_version}}</p>
            </div>
        </div>
       
    </mat-card-content>
</mat-card>