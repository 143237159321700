<app-titlebar *ngIf = "!isMobile"  [title]="'all.todayssip' | translate"></app-titlebar>
<app-header  *ngIf = "isMobile" goback="1" [title]="'all.todayssip' | translate"></app-header>
<div class="p-2" [class.data-container]="isMobile">
    <div *ngFor='let data of sipList'>
        <div class="row" (click) = "placeOrder(data)">
            <div class="col-12">
                <div class="watchlistwrapper">
                    <div class="row">
                        <label class="col-12 tc-1 ts-4 textclip">{{data.basket_name}}</label>
                        <div class="col-7 pr-0">
                            <label class="line2 subcolor ts-2">{{'settings.status' | translate}}:&nbsp;</label><span [class.imnegative]="data.sip_status===2" [class.impositive]="data.sip_status===1">{{statusMap[data.sip_status]}}</span><br/>
                            <label class="line2 subcolor ts-2">{{'all.sip' | translate}} {{'all.frequency' | translate}}:&nbsp;</label>{{data.sip_frequency}}
                        </div>
                        <div class="col-5 pl-0 text-right">
                            <label class="line2 subcolor ts-2">{{'all.date' | translate}}: </label><label>{{data.next_trigger_date | datepipe: 'date'}}</label>
                            <br>
                            <label class="line2 subcolor ts-2">{{'all.sip' | translate}} {{'books.amt' | translate}}:&nbsp;</label><label>{{data.sip_amount | pricepipe}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>