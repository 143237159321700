import { Component, ChangeDetectorRef } from '@angular/core';
import { Utility } from 'src/app/services/utility';
let webScannerComponent: WebScannerComponent;

@Component({
    selector: 'app-web-scanner',
    templateUrl: './webscanner.component.html'
})

export class WebScannerComponent {
    scanner = 'Resistance & Support';
    isSearch = true;
    goback = '2';
    isLoading: boolean;
    icon = 'icon-resi-support';
    scannerDetail = 'Resistance & Support';
    constructor(private cdr: ChangeDetectorRef) {
        webScannerComponent = this;
    }
    setType(value) {
        if (value !== undefined)
            webScannerComponent.scanner = value;
    }
    setDetail(value) {
        if (value !== undefined)
            webScannerComponent.scannerDetail = value;
    }
    setIcon(value) {
        if (value !== undefined)
            webScannerComponent.icon = value;
    }
    setLoading(event) {
        webScannerComponent.isLoading = event;
        webScannerComponent.cdr.detectChanges();
    }
}
