<div class="row mb-4">
    <div class="col-12 mb-2">
        <div class="des-header border-t" translate>basket.funddetails</div>
        <div [class.pt-0]="!isMobile">
            <ng-container>
                <div class="row">
                    <div class="col-7">
                        <div class="firstline subcolor2">Symbol</div>
                    </div>
                    <div class="col-5 text-right">
                        <div class="firstline subcolor2">Weightage (%)</div>
                    </div>
                </div>
                <div *ngFor="let option of basketDetailList; let i = index">
                    <div class="row">
                        <div class="col-12">
                            <div class="watchlistwrapper">
                                <div class="row">
                                    <div class="col-7">
                                        <div class="line2">{{option.company_name}}</div>
                                    </div>
                                    <div class="col-5 text-right">
                                        <div class = "impositive">
                                            <div class="firstline">{{option.percent_weight | absptpipe}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>