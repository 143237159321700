import { Component, EventEmitter, Output,Input, OnInit, ChangeDetectorRef, Inject, Injector, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { PtPlusRestService } from '../../../../services/ptplusrest.service';
import { DatePipe } from '../../../../utility/pipes/datepipe';
import { TradingRestService } from '../../../../services/tradingrest.service';
import { ChannelType, Utility } from '../../../../services/utility';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from 'src/app/services/tableutility';
import { Subscription, Subject } from 'rxjs';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';
import { OptionChainPlaceOrderComponent } from './optionchainplaceorder/optionchainplaceorder.component';
import { OptionChainAnalysisComponent } from './optionchainanalysis/optionchainanalysis.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OptionchainmoreComponent } from './optionchainmore/optionchainmore.component';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { IndexModel } from 'src/app/model/index.model';
import { ScripModel } from 'src/app/model/scrip.model';
let optionChainData : OptionComponent;
@Component({
    selector: 'app-option',
    templateUrl: './optionchain.component.html',
    styleUrls: ['./optionchain.component.scss']
})
export class OptionComponent extends BaseComponentDirective implements OnInit , OnDestroy{
    @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
    @Output() loaderEvent = new EventEmitter();
    @Output() load = new EventEmitter();
    isFirstLoad: boolean;
    @Input() IsStockSpecific: boolean;
    @Input() isCustomDashboard: boolean;
    @Input() tagtype;
    @Input() token;
    @ViewChild('focus') focusElement: ElementRef;
    targetElement: Element;
    isMobile;
    isNoData: boolean;
    spotprice: number;
    optionTypes = [];
    scripArray = [];
    symbols = [];
    expiryDates = [];
    isLot: boolean;
    selectedOptionType: any;
    selectedExpiryDate: any;
    selectedSymbol: any;
    selectedToken: any;
    optionChainData;
    lotQuantity: boolean;
    datepipe;
    utility: any;
    Height = {};
    displayedColumns: string[] = ['callscrip.Quantity', 'callscrip.LastTradePrice',  'strikeprice', 'putscrip.LastTradePrice', 'putscrip.Quantity'];
    container;
    left: any;
    right: any;
    WlArr = [];
    modesub: Subscription;
    optionTypesArr = ['Price', 'Greeks'];
    tagText = 'Price';
    invest_btn = true;
    qty = 0;
    qtyArr = [];
    try;
    isLoading: boolean;
    isNoOptionData: boolean;
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;
    indexSymbolArray = [];
    stockSymbolArray = [];
    limit = 5;
    isReload = false;
    im:IndexModel;
    sm:ScripModel;
    isIndex:boolean;
    constructor(elRef: ElementRef, private injector: Injector, private PS: PtPlusRestService, private tradingRestService: TradingRestService, private router: Router,
        private dialog: MatDialog, private detector: ChangeDetectorRef, private _bottomSheet: MatBottomSheet, private snackBar: MatSnackBar, private translate: TranslateService) {
            super(elRef.nativeElement);
        optionChainData = this;
        this.utility = Utility;
        if (!Utility.IS_MOBILE) {
         //   this.container = <ComponentContainer>this.injector.get('Container');
        }
        this.datepipe = new DatePipe();

        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });
        this.utility.optionchaindata.subscribe(data => {
            this.setDefaultOptionData();
        });

        Utility.webReloadComponent.subscribe(data =>
        {
            if(optionChainData.isReload === false && data === 'Option Chain')
            {
                optionChainData.isReload = true;
                this.reloadData();
            }
        });
    }
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        this.changeMode();
      }
    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.symbols.filter(option =>
             option.toLowerCase().indexOf(filterValue) === 0);
    }

    ngOnDestroy(): void {
        this.tradingRestService.marketDataFeeder.unsubscribe(ChannelType.Optionchain);
    }
    result(option){
        if (Utility.IS_MOBILE){
            option['expirydate'] = this.selectedExpiryDate;
            const bottomSheetRef =  this._bottomSheet.open(OptionchainmoreComponent, {
                data: option, restoreFocus: false
              });
              bottomSheetRef.afterDismissed().subscribe(result => {
                  this.calculateCount();
              });
        }
    }

    calculateCount(){
        let countQty = 0;
        for (let i = 0; i < this.scripArray.length; i++ ){
          countQty += Math.abs(this.scripArray[i].callqtycount);
          countQty += Math.abs(this.scripArray[i].putqtycount);
        }
       this.invest_btn = (countQty > 0) ? false : true;
       Utility.disableResetButton = this.invest_btn;
    }

    changeMode(){
        this.Height = {
            'height': (this.container.height - TableUtility.OCHeight) + 'px',
            'overflow': 'auto'
            };
            this.left = {
                'left': (this.container.width - TableUtility.OCLeft) + 'px'
              };
              this.right = {
                'left':'0px'
              };
            this.detector.detectChanges();
    }

    ngOnInit() {
        this.targetElement = document.querySelector('html');
        this.isMobile = Utility.IS_MOBILE;
        if (this.container) {
            /* this.container.on('resize', _ => {
                this.changeMode();
            });*/
        }
        this.isFirstLoad = true;
        this.isLoading = true;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        if (Utility.isOptionChainReloadData) {
            this.PS.getDerivativeService().getOptionChainData('nse_fo', function(code, result, message) {
                this.loaderEvent.emit(true);
                this.load.emit(true);
                if (code === 200 && message === 'OK') {
                    this.optionChainData = result['data'];
                    this.setDefaultOptionData();
                }
                else {
                    this.isFirstLoad = false;
                    this.isNoData = true;
                }
            }.bind(this), function(data) {
                this.optionChainData = data;
                // this.setDefaultOptionData();
            }.bind(this));
        } else {
            this.optionChainData = Utility.optionChainDataMap.get('optionChainData');
            this.setDefaultOptionData();
        }
        
        if(this.inputs !== undefined){
            this.isCustomDashboard = this.inputs.isCustomDashboard;    
        }
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
          );
    }
    setDefaultOptionData() {
        this.optionTypes = [];
        const tempOptionTypes = Object.keys(this.optionChainData);
        if (tempOptionTypes.includes('OPTIDX')) {
             this.optionTypes.push('OPTIDX');        }
        if (tempOptionTypes.includes('OPTSTK')) {
             this.optionTypes.push('OPTSTK');
        }
        // if(Utility.isOptionChainReloadData)
        //     this.selectedOptionType = 'OPTIDX';
        // else if(this.utility.IS_MOBILE) {
        //     this.selectedOptionType = Utility.optionChainDataMap.get('option_series');
        // }
        this.setData();
    }
    setData() {
        this.indexSymbolArray = Object.keys(this.optionChainData['OPTIDX']);
        this.stockSymbolArray  = Object.keys(this.optionChainData['OPTSTK']);
        if (Utility.isOptionChainReloadData)
            this.symbols = this.stockSymbolArray.concat(this.indexSymbolArray);
        else if(this.utility.IS_MOBILE) {
            this.symbols = Utility.optionChainDataMap.get('symbolsArr');
        }
        if (this.IsStockSpecific){
            this.selectedSymbol = this.tagtype;
            this.selectedOptionType = (this.indexSymbolArray.includes(this.selectedSymbol)) ? 'OPTIDX' : 'OPTSTK';
        }
        else{
            if (Utility.isOptionChainReloadData) {
                this.selectedSymbol = 'NIFTY';
                this.selectedOptionType = 'OPTIDX';
            }
            else if(this.utility.IS_MOBILE) {
                this.selectedSymbol = Utility.optionChainDataMap.get('underlying_symbol');
                this.selectedOptionType = Utility.optionChainDataMap.get('option_series');
            }
        }
        this.setDate();
    }
    setDate() {
        if (this.IsStockSpecific) {
            this.selectedToken = this.token;
        }
        else {
            this.selectedToken = this.optionChainData[this.selectedOptionType][this.selectedSymbol]['token'];
        }
        this.expiryDates = [];
        const dates = this.optionChainData[this.selectedOptionType][this.selectedSymbol]['expiry'];
        dates.forEach(element => {
            const timeDate = Utility.toDateTime(element);
            const date = this.datepipe.transform(timeDate, 'date');
            let dateObj = {};
            dateObj['key'] = date;
            dateObj['value'] = element
            this.expiryDates.push(dateObj);
        });
        if (Utility.isOptionChainReloadData)
            this.selectedExpiryDate =  this.expiryDates[0]['value'];
        else if(this.utility.IS_MOBILE) {
            this.selectedExpiryDate = Utility.optionChainDataMap.get('expiry_date');
        }
        this.getOptionData();
    }

    scrollToOffset() {
        /*setTimeout(()=>{
            let element:HTMLElement;
            element = document.getElementById("oc_"+(this.limit)) as HTMLElement;
            let topPos = element.offsetTop;
            this.sectionNeedToScroll?.nativeElement.scrollTo(topPos, topPos);
        },3000);*/
    }


    getOptionData() {
        if (Utility.isOptionChainReloadData) {
            // if(!this.IsStockSpecific && Object.keys(Preferences.getItem('selectedOptChainSymbl')).length !== 0)
            // {
            //     this.selectedSymbol = Preferences.getItem('selectedOptChainSymbl')
            //     this.selectedToken = Preferences.getItem('selectedOptChainToken')
            // }
            const data = {};
            data['option_series'] = this.selectedOptionType;
            data['expiry_date'] = this.selectedExpiryDate;
            data['exchange_seg'] = 'nse_fo';
            data['underlying_symbol'] = this.selectedSymbol;
            data['underlying_token'] = this.selectedToken;
            data['limit'] = this.limit;
            this.invest_btn = true;
            Utility.disableResetButton = this.invest_btn;
            this.PS.getDerivativeService().getOptionChain(data, function(code, result, spotprice, spot, message) {
                if(code === 200) {
                    this.isLoading = false;
                    if(spot.stktyp == 'index') {
                        this.isIndex = true;
                        this.im = Utility.getIndexModel(spot.omtkn, Utility.segmentToExchange(spot.exseg), this.tradingRestService, true, ChannelType.Optionchain);
                    } else {
                        this.isIndex = false;
                        this.sm = Utility.getScripModel(spot.omtkn, spot.exseg, this.tradingRestService, true, ChannelType.Optionchain);
                        this.sm.DisplaySymbolDescription = spot.cnam;
                    }
                    this.isReload = false;
                    this.loaderEvent.emit(this.isLoading);
                    this.load.emit(this.isLoading);
                    this.isFirstLoad = false;
                    this.scripArray = result;
                    this.lotQuantity = (this.scripArray.length > 0) ? true : false;
                    this.spotprice = Math.round(spotprice / 10) * 10;
                    this.isNoOptionData = (this.scripArray.length > 0) ? false : true;
                    this.isLot = Utility.IsLot(this.scripArray[0].callscrip.ExchangeName);
                    this.scrollToOffset();
                } else {
                    this.isNoOptionData = true;
                    this.loaderEvent.emit(this.isLoading);
                }
            }.bind(this), function(result) {
                this.load.emit(false);
                this.isNoOptionData = (this.scripArray.length > 0) ? false : true;
                this.scripArray = result;
            }.bind(this));
        } else if(this.utility.IS_MOBILE) {
            this.invest_btn = false; 
            Utility.disableResetButton = this.invest_btn;
            if(this.selectedOptionType.includes('IDX')) {
                this.isIndex = true;
                this.im = Utility.getIndexModel(this.selectedToken, Utility.segmentToExchange('nse_cm'), this.tradingRestService, true, ChannelType.Optionchain);
            } else {
                this.isIndex = false;
                this.sm = Utility.getScripModel(this.selectedToken,'nse_cm', this.tradingRestService, true, ChannelType.Optionchain);  
            }
            this.isLoading = false;
            this.isReload = false;
            this.isNoData = false;
            this.loaderEvent.emit(this.isLoading);
            this.load.emit(this.isLoading);
            this.isFirstLoad = false;
            this.scripArray = Utility.optionChainDataMap.get('scripArray');
            this.lotQuantity = (this.scripArray.length > 0) ? true : false;
            this.spotprice = Utility.optionChainDataMap.get('spotprice');
            this.isNoOptionData = (this.scripArray.length > 0) ? false : true;
            this.isLot = Utility.IsLot(this.scripArray[0].callscrip.ExchangeName);
        }
    }
    onSelectChip(data) {
        this.tagText = data;
    }
    selectOptionType(type) {
        this.loaderEvent.emit(true);
        this.setData();
    }

      addWLdata(stock) {
        const seltctedStock = stock.ExchangeSegment + '|' + stock.Token;
        this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
      }

      addStock(wl, stockdata, itemName) {
        const seltctedStock = stockdata.ExchangeSegment + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
          if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('books.add_stock'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('books.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('books.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
      }

    multiplierFunction(data){
        return parseInt(data, 10);
    }

    reloadData() {
        this.isLoading = true;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        this.getOptionData();
    }

    openPlaceOrder(buttontype, data) {
        Utility.openPlaceOrder(this.router, data.Token, data.ExchangeSegment, buttontype, this.dialog);
    }
    onFutCount (buttontype, data, datatype) {
        if (buttontype === 'B') {
            data.futqtycount++;
        } else {
            data.futqtycount--;
        }
        this.invest_btn = (this.qtyArr.length > 0 || data.futqtycount !== 0) ? false : true;
        Utility.disableResetButton = this.invest_btn;
    }
    
    onCount(buttontype, data, datatype) {
       if (buttontype === 'B') {
        this.qty++;
        if (datatype === 'call') {
            data.callqtycount = this.isLot? data.callqtycount + 1 : data.callqtycount + Utility.getNumber(this.scripArray[0].callscrip.BodLotQuantity);
        } else {
            data.putqtycount = this.isLot? data.putqtycount + 1 : data.putqtycount + Utility.getNumber(this.scripArray[0].putscrip.BodLotQuantity);
        }
       } else {
        this.qty--;
        if (datatype === 'call') {
            data.callqtycount = this.isLot? data.callqtycount - 1 : data.callqtycount - Utility.getNumber(this.scripArray[0].callscrip.BodLotQuantity);
        } else {
            data.putqtycount = this.isLot? data.putqtycount - 1 : data.putqtycount - Utility.getNumber(this.scripArray[0].putscrip.BodLotQuantity);
        }
       }
       if (data.callqtycount !== 0 && !this.qtyArr.includes(data.callscrip.Token + '_' + data.callscrip.ExchangeSegment)) {
        this.qtyArr.push(data.callscrip.Token + '_' + data.callscrip.ExchangeSegment);
        } else {
            const callindex = this.qtyArr.indexOf(data.callscrip.Token + '_' + data.callscrip.ExchangeSegment);
            if (data.callqtycount === 0 && callindex !== -1) {
                this.qtyArr.splice(callindex, 1);
            }
        }
        if (data.putqtycount !== 0 && !this.qtyArr.includes(data.putscrip.Token + '_' + data.putscrip.ExchangeSegment)) {
            this.qtyArr.push(data.putscrip.Token + '_' + data.putscrip.ExchangeSegment);
        } else {
            const putindex = this.qtyArr.indexOf(data.putscrip.Token + '_' + data.putscrip.ExchangeSegment);
            if (data.putqtycount === 0 && putindex !== -1) {
                this.qtyArr.splice(putindex, 1);
            }
        }
        this.invest_btn = (this.qtyArr.length > 0 || data.futqtycount !== 0) ? false : true;
        Utility.disableResetButton = this.invest_btn;
    }

    placeOrder() {
        Utility.optionChainDataMap.set('scripArray', this.scripArray);
        Utility.optionChainDataMap.set('expiry_date', this.selectedExpiryDate);
        Utility.optionChainDataMap.set('underlying_symbol', this.selectedSymbol);
        Utility.optionChainDataMap.set('symbolsArr', this.symbols);
        Utility.optionChainDataMap.set('option_series', this.selectedOptionType);
        Utility.optionChainDataMap.set('optionChainData',this.optionChainData);
        Utility.optionChainDataMap.set('spotprice',this.spotprice);
        if (!Utility.IS_MOBILE){
            Utility.ocData = this.scripArray;
            Utility.openPage(this.router, 'optionchain', 'optionchainplaceorder', OptionChainPlaceOrderComponent , this.dialog, 'popup', this.scripArray);
        }
        else{
            Utility.dialogData = this.scripArray;
            Utility.ocData = this.scripArray;
            Utility.openPage(this.router, 'optionchain', 'optionchainplaceorder', OptionChainPlaceOrderComponent , this.dialog);
        }
    }

    openAnalysis() {
        Utility.optionChainDataMap.set('scripArray', this.scripArray);
        Utility.optionChainDataMap.set('expiry_date', this.selectedExpiryDate);
        Utility.optionChainDataMap.set('underlying_symbol', this.selectedSymbol);
        Utility.optionChainDataMap.set('symbolsArr', this.symbols);
        Utility.optionChainDataMap.set('option_series', this.selectedOptionType);
        Utility.optionChainDataMap.set('optionChainData',this.optionChainData);
        Utility.optionChainDataMap.set('spotprice',this.spotprice);
        if (!this.invest_btn){
            if (!Utility.IS_MOBILE){
                Utility.openPage(this.router, 'optionchain', 'optionchainanalysis', OptionChainAnalysisComponent , this.dialog, 'popup', this.scripArray, '580px', '800px');
            }
            else{
                Utility.dialogData = this.scripArray;
                Utility.ocData = this.scripArray;
                Utility.openPage(this.router, 'optionchain', 'optionchainanalysis', OptionChainAnalysisComponent, this.dialog);
            }
        }
    }

    resetOptionChain(){
        Utility.isOptionChainReloadData = true;
        this.reloadData();
    }

    selectExpiryDate(date) {
        this.isLoading = true;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        this.selectedExpiryDate = date;
        Utility.isOptionChainReloadData = true;
        this.invest_btn = true;
        Utility.disableResetButton = this.invest_btn;
        this.getOptionData();
    }

    selectLengthChange(e){
        this.isLoading = true;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        this.limit = e;
        Utility.isOptionChainReloadData = true;
        this.invest_btn = true;
        Utility.disableResetButton = this.invest_btn;
        this.getOptionData();
 
    }

    selectSymbol(symbol) {
        this.isLoading = true;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        this.selectedOptionType = (this.indexSymbolArray.includes(this.selectedSymbol)) ? 'OPTIDX' : 'OPTSTK';
        this.selectedToken = this.optionChainData[this.selectedOptionType][this.selectedSymbol]['token'];
        // Preferences.setItem("selectedOptChainSymbl",this.selectedSymbol);
        // Preferences.setItem("selectedOptChainToken",this.selectedToken);
        Utility.isOptionChainReloadData = true;
        this.invest_btn = true;
        Utility.disableResetButton = this.invest_btn;
        this.setDate();
    }

    handleMouseOver(row) {
    const TradingSymbol = row.strikeprice;
    this.scripArray.map((data: any) => {
      if (TradingSymbol && data.strikeprice && data.strikeprice === TradingSymbol) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const TradingSymbol = row.strikeprice;
    this.scripArray.map((data: any) => {
      if (data.strikeprice === TradingSymbol) {
        data.show = false;
      }
    });
  }

  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      this.reloadData();
      event.next();
    }, 1000);
  }
  
  alert(message: string) {
    
  }

  onTitleClick() {
    this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  onClearSearch() {
    this.selectedSymbol = '';
    setTimeout(()=>{
        this.focusElement.nativeElement.focus();
      },0);  
  }

  validation(ev) {
    var k;  
    k = ev.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 38 || k == 45);
  }
}
