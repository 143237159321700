<app-header *ngIf = "isMobile" [goback]="goback" [title]="'dashboard.investment_box' | translate" ></app-header>
<div class="col-12">
<!-- <app-titlebar *ngIf="!isMobile" [title]="'basket.basket_detail' | translate"></app-titlebar> -->
<div [class.data-container]="isMobile">
    <!-- <div class="row investor-none">
            <div class="col-12">
                <mat-chip-list >
                    <mat-chip (click)= "onTabChanged(1)" [class.imactive] = "tabIndex ===  1" translate>stock_detail.overview</mat-chip>
                    <mat-chip (click)= "onTabChanged(2)" [class.imactive] = "tabIndex ===  2" translate>stock_detail.news</mat-chip>
                    <mat-chip (click)= "onTabChanged(3)" [class.imactive] = "tabIndex ===  3" translate>stock_detail.events</mat-chip>
                </mat-chip-list>
            </div>
        </div> -->
        <div class="row mt-2">
            <div class="col-12">
                <ng-container *ngxCacheIf = "tabIndex === 1">
                    <app-custombasket [basketdata]='basketDataObj'></app-custombasket>
                    <app-custombasketdetailchart [basketdata]='basketDataObj'></app-custombasketdetailchart>
                    <app-custombasketdetail [basketdata]='basketDataObj'></app-custombasketdetail>
                </ng-container>
                <!-- <ng-container *ngxCacheIf = "tabIndex === 2">
                    <app-tagspecificnews [IsStockSpecific]="true"></app-tagspecificnews>
                </ng-container>
                <ng-container *ngxCacheIf = "tabIndex === 3">
                    <app-event [IsStockSpecific]="true"></app-event>
                </ng-container> -->
            </div>
        </div>
    
        <div class="footerbtn fixatbottom">
            <div class="col-12 px-0">
                <button class="w-100 btn-primary"  mat-flat-button (click)="placeOrder(basketDataObj)"><span translate>basket.invt_now</span></button>
            </div>
        </div>
    
</div>
</div>