import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[appNumberDir]'
})
export class NumberDirective {
    @Input() dp: any;
    @Input() quantitysize: any;
    @Input() pricesize: any;

    constructor (private element: ElementRef) { }

    @HostListener('keyup', ['$event']) onKeydownHandler(ev) {
        let regex;
        if (this.dp) {
            regex = new RegExp('^[0-9]{0,' + this.pricesize + '}$|^[0-9]{1,' + this.pricesize + '}[.]{1,1}[0-9]{0,' + this.dp + '}$');
        }
        else {
            regex = new RegExp('^[0-9]{0,' + this.quantitysize + '}$|^[0-9]{1,' + this.quantitysize + '}$');
        }
        const val = this.element.nativeElement.value;
        const index = ev.target.selectionStart;
        if (!val.match(regex)) {
            ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
            this.element.nativeElement.dispatchEvent(new Event('input'));
            this.element.nativeElement.setSelectionRange(index - 1, index - 1);
        }
    }
}
