import { Injectable } from '@angular/core';
import { IUserService } from './iuser.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Utility } from '../utility';
import { Url } from '../url';
import { LimitModel } from 'src/app/model/limit.model';
import { environment } from 'src/environments/environment';
import { Preferences } from '../Preferences';

@Injectable()
export class HSUserService implements IUserService {
    apiService: ApiService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    Logout(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        if (environment.isIAM) {
            const jsonSendObj = {'uid': Preferences.getPreference('userid'), 'brokerId': environment.brokerId};
            this.apiService.iampost(Url.IAM_LOG_OUT, jsonSendObj).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                if (res.status === 'SUCCESS' && res.code === 200) {
                    message = Keys.RES_MSG_OK;
                    Utility.isLogin = false;
                    Preferences.setPreference('userLogIn', '0');
                }
                else {
                    code = res.code;
                    message = res.message;
                }
                callback(res, code, message);
            },
                error => {
                    message = error;
                    Utility.print(error);
                    callback('', code, message);
                }
            );
        }
        else {
            const jsonSendObj = { 'usrId': Preferences.getPreference('userid') };
            const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('userSessionId'));
            this.apiService.post(Url.HS_LOGOUT, param).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                let data = '';
                if (res['stat'] === 'Ok') {
                    Utility.isLogin = false;
                    Preferences.setPreference('userLogIn', '0');
                    data = res;
                    message = Keys.RES_MSG_OK;
                }
                else {
                    code = Keys.RES_CODE_FAILURE;
                    if (!res.Emsg) {
                        message = res.Emsg;
                    } else {
                        message = res.check;
                    }
                }
                callback(res, code, message);
            },
                error => {
                    code = Keys.RES_CODE_FAILURE;
                    message = error;
                    Utility.print(error);
                    callback('', code, message);
                });
        }
    }

    GetPreferences(callback: Function) {
        const param = {
            'uid': Preferences.getPreference('userid'),
            'brokerId': environment.brokerId
        };
        this.apiService.iampost(Url.IAM_GET_PREFERENCES, param).subscribe(res => {
            let preferences = {};
            const status = res['status'];
            const code = res['code'];
            if (status === 'SUCCESS' && code === 200) {
                preferences = res['data']['preferences'];
            } else {
                preferences = {};
            }
            callback(preferences, code, status);
        });
    }

    SetPreferences(value: any, callback: Function) {
        if(Preferences.getPreference('userid') === null || Preferences.getPreference('userid') === undefined || Preferences.getPreference('userid') === '') {
            return callback('', Keys.RES_CODE_FAILURE, 'FAILURE');
        }
        const param = {
            'uid': Preferences.getPreference('userid'),
            'brokerId': environment.brokerId,
            'status': 2,
            'preferences': value
        };
        this.apiService.iampost(Url.IAM_SET_PREFERENCES, param).subscribe(res => {
            const status = res['status'];
            const code = res['code'];
            const data = res['data'];
            callback(data, code, status);
        });
    }

    getAllLimits(data, callback: Function, precallback?: Function) {
        const limitDataModel = new Array<LimitModel>();
        const sendJson = { 'seg': null, 'exch': null, 'prod': null, 'brkName': environment.brokerId };
        const param = Utility.prepareParameters(JSON.stringify(sendJson), Preferences.getPreference('userSessionId'));
        const key = Url.HS_LIMIT + '_' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.hspost(Url.HS_LIMIT, param).subscribe(res => {
            let code = Keys.RES_CODE_SUCCESS;
            let message = '';
            const stat = res['stat'];
            if (stat === 'Ok') {
                const bean = new LimitModel();
                bean.PremiumPrsnt = Number(res['PremiumPrsnt']);
                bean.UsedMargin =  Number(res['MarginUsed']);
                bean.NetcashAvailable = Number(res['Net']).toFixed(2);
                bean.CollateralValue = Number(res['CollateralValue']);
                bean.AdhocMargin = Number(res['AdhocMargin']);
                bean.NotionalCash = Number(res['NotionalCash']);
                bean.OpeningBalance = Number(res['AdhocMargin']) + Number(res['NotionalCash']) + Number(res['AuxAdhocMargin']) + Number(res['AuxNotionalCash']) + Number(res['CollateralValue']);
                bean.PayinAmt = Number(res['RmsPayInAmt']);
                bean.SpanMargin = Number(res['SpanMarginPrsnt']);
                bean.DeliveryMargin = Number(res['CncMrgnVarPrsnt']).toFixed(2);
                bean.Exposure = Number(res['ExposureMarginPrsnt']);
                bean.OptionNRML = Number(res['FoPremiumNrmlPrsnt']).toFixed(2);
                bean.OptionMIS = Number(res['FoPremiumMisPrsnt']).toFixed(2);
                bean.StockValuation = Number(res['Collateral']);
                bean.DirectCollateralValue = Number(res['RmsCollateral']) + Number(res['AuxRmsCollateral']);
                bean.PayoutAmt = Number(res['RmsPayOutAmt']);
                bean.BookedPNL = Number(res['RealizedMtomPrsnt']).toFixed(2);
                bean.UnbookedPNL = Number(res['UnrealizedMtomPrsnt']);
                bean.DeliveryMarginPresent = Number(res['DeliveryMarginPresent']);
                bean.TotalCollateral = Number(bean.StockValuation + bean.DirectCollateralValue);
                bean.AvailableCash = Number(bean.OpeningBalance - bean.UsedMargin + bean.PayinAmt + bean.PayoutAmt);
                bean.AvailableMargin = Number(bean.AvailableCash + bean.TotalCollateral);
                bean.VAR = Number(res['MarginVarPrsnt']);
                bean.Others = bean.UsedMargin - (bean.SpanMargin + bean.Exposure + bean.VAR);


                limitDataModel.push(bean);
                message = 'Ok';
                Utility.CACHE_STORE.setCacheData(key, limitDataModel);
            }
            else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback(limitDataModel, code, message);
        });
    }
    getOmSessionToken(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        callback('', code, message);
    }

    getSegmentData(callback:Function){
        let code = Keys.RES_CODE_SUCCESS;
        let message = '';
        let res = '';
        this.apiService.hypertrackpost(Url.ALL_SEGMENTS).subscribe((result) => {
            result = JSON.parse(JSON.stringify(result));
            if (result['code'] === 200){
                code = result['code'];
                message =  Keys.RES_MSG_OK;
                res = result['data'];
            }
            else{
                code = Keys.RES_CODE_FAILURE;
                message = result['message'];
            }
            callback (res, code, message);
        },
            error => {
                message = error;
                code = Keys.RES_CODE_FAILURE;
                Utility.print(error);
                callback('', code, message);
            });
    }
    getUserLogMessage(responseCallback: Function) { }
    getUserProfile(callback: Function, precallback?: Function) {
        let code = Keys.RES_CODE_SUCCESS;
        let message = '';
        const key = Url.HS_USER_DETAILS + '_' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.hspost(Url.HS_USER_DETAILS, '').subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res['stat'] === 'Ok') {
                res['accountName'] = res.actName;
                res['accountId'] = Preferences.getPreference('accountId');
                res['exchEnabled'] = res['usrEx'];
                message = Keys.RES_MSG_OK;
                Utility.CACHE_STORE.setCacheData(key, res);
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.emsg;
            }
            callback(res, code, message);
        },
            error => {
                message = error;
                code = Keys.RES_CODE_FAILURE;
                Utility.print(error);
                callback('', code, message);
            });
    }


    getBannerData(callback: Function){
            const getObj = {'bannerType': 4,
            'brokerId': environment.brokerId,
            "uid": Preferences.getPreference('userid'),
            'serviceName': "Hypertrade",
            'bannerAccess': (Preferences.getPreference('LoggedIn') === 'true') ? "PostLogin" :  "PreLogin"};
            this.apiService.iampost(Url.IAM_GET_BANNERS, getObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = {};
            let code = res['code'];
            let status = res['status'];
                if (res['status'] === 'SUCCESS' &&   res['code']  === 200) {
                    data = res['message'];
                } else {
                    code = Keys.RES_CODE_FAILURE;
                    status = res['status'];
                }
                callback(data, code, status);
            });
    
    }

    
    IBPayment(callback: Function, orderData) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.IB_PAYMENT, orderData).subscribe((result) => {
            res = result;
            if (result.code === 200 && result.data) {
                code = Keys.RES_CODE_SUCCESS;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = result.message;
            }
            callback(res, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }

    CancelPayment(data, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.CANCEL_PAYMENT, data).subscribe((result) => {
            res = result;
            if (result.code === 200) {
                code = Keys.RES_CODE_SUCCESS;
                message = result.message;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = result.message;
            }
            callback(res, ((result.code == 0) ? result.code : code), message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });

    }

    VerifyPayment(data, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.VERIFY_PAYMENT, data).subscribe((result) => {
            res = result;
            if (result.code === 200) {
                code = Keys.RES_CODE_SUCCESS;
                message = result.message;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = result.message;
            }
            callback(res, ((result.code == 0) ? result.code : code), message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }
    PayOut(callback: Function, orderData) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.PAYOUT, orderData).subscribe((result) => {
            res = result;
            if (result.code === 200) {
                code = Keys.RES_CODE_SUCCESS;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = result.message;
            }
            callback(res, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }

    cancelWithdrawal(callback: Function, data){
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      let res = '';
      return this.apiService.hypertrackpost(Url.CANCEL_WITHDRAWAL, data).subscribe((result) => {
          res = result;
          callback (res, code, message);
      }, error => {
        code = Keys.RES_CODE_FAILURE;
        message = error;
        callback(null, code, message);
      });
    }

    getEdisTransectionID(callback: Function, data) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.EDIS_TRANSECTIONID, data).subscribe((result) => {
            res = result;
            callback (res, code, message);
        }, error => {
          code = Keys.RES_CODE_FAILURE;
          message = error;
          callback(null, code, message);
      });
    }
    modifyWithdrawal(callback: Function, data){
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.MODIFY_WITHDRAWAL, data).subscribe((result) => {
            res = result;
            code = result['code'];
            callback (res, code, message);
        }, error => {
          code = Keys.RES_CODE_FAILURE;
          message = error;
          callback(null, code, message);
      });
      }

    TransactionDetails(callback: Function, data){
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.TRANSACTION_DETAILS, data).subscribe((result) => {
            res = result;
            if (result.code === 200 && result.data) {
                code = Keys.RES_CODE_SUCCESS;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = result.message;
            }
            callback(res, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
       }

       GetAllTransactionCharges(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.ALL_TRANSACTIONCHARGES).subscribe((result) => {
            res = result.data;
            if (result.code === 200 && result.data) {
                code = Keys.RES_CODE_SUCCESS;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = result.message;
            }
            callback(res, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }

    GetTransactionCharge(data: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let res = '';
        return this.apiService.hypertrackpost(Url.TRANSACTION_CHARGES, data).subscribe((result) => {
            res = result.data;
            if (result.code === 200 && result.data) {
                code = Keys.RES_CODE_SUCCESS;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = result.message;
            }
            callback(res, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }
    openBackOffice(callback: Function) {
        let uid = (environment.production)?Preferences.getPreference('userid').split('-')[0]:'S208';
        let url = 'shrdbms/SilentSessionRegister.ssnp?LoginType=CLIENT&apikey='+ environment.apiKeyBackOffice +'&loginid='+ uid;
        this.apiService.get(url).subscribe((result) => {
            callback(200,'',result);
        });
    }

    getEmandateMsgId(reqdata, callback: Function){

        let message = '';
        let code;
        let data = [];
        return this.apiService.hypertrackpost(Url.GET_EMANDATE_MSGID, reqdata).subscribe((result) => {
            code = result.code;
            if (result.code === 200 && result.status === 'SUCCESS') {
                message = 'OK';
                data = result.data;
            }
            else {
                message = result.message;
                data = [];
            }
            callback(data, code, message);
        },
        error => {
            message = error;
            callback([], code, message);
        });
    }

    getAllTransactions(callback: Function){

        let message = '';
        let code;
        let data = [];
        return this.apiService.hypertrackpost(Url.GET_ALL_TRANSACTIONS).subscribe((result) => {
            code = result.code;
            if (result.code === 200 && result.status === 'SUCCESS') {
                message = 'OK';
                data = result.data;
            }
            else {
                message = result.message;
                data = [];
            }
            callback(data, code, message);
        },
        error => {
            message = error;
            callback([], code, message);
        });
    }
}
