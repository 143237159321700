<mat-card class="webpanelnew h-newsupdates">
    <div class="row" *ngIf="action !== 'CHART'">
        <div class="col-12">
            <div class="panelforsearchcom">
                <div class="row">
                    <div *ngIf="!(action === 'B' || action === 'S')" class="col-6">{{sm.TradingSymbol | symbolpipe}} <small class="exchangename">{{sm.ExchangeName}}</small></div>
                    <div [class.col-6]="!(action === 'B' || action === 'S')"  [class.col-12]="(action === 'B' || action === 'S')" class="text-right opensearch">
                        <app-autocompletesearch [pageName]="searchId" (selectData)="selectData($event)"></app-autocompletesearch>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row"  [class.d-none]="!showme">
        <div class="col-12">
            <div class="searchfirst"><img alt="search" src="assets/img/searchfirst.svg" /></div>
        </div>
    </div>
    <div [class.d-none]="showme" class="pl-2 pr-2" [ngStyle]="Height">
        <div *ngIf="action == 'B' || action == 'S'">
            <app-placeorder [nodepth] = 'true' [omtkn] = "omtkn" [exseg] = "exseg" [fromMultiView] = "fromMultiView" [action] = "action"></app-placeorder>
        </div>
        <div *ngIf="action == 'DEPTH'">
            <app-depth isPlaceOrder="true" [omtkn]="omtkn" [exseg]="exseg" (load)="onLoading($event)" [symbol]="symbol"></app-depth>
        </div>
        <div *ngIf="action == 'CHART'">
            <app-tradingview [fromMV] = "true" [size] = "container" [omtkn]="omtkn" (load)="onLoading($event)" [exseg]="exseg" [MVtype]="'stock'" [backbtnEnable]="false" [fromMultiView]="fromMultiView"></app-tradingview>
        </div>
        <div *ngIf="action == 'ANALYSIS'">
            <app-ibes [omtkn]="omtkn" [exseg]="exseg"></app-ibes>
        </div>
        <div *ngIf="action == 'KEYRATIO'">
            <app-keyratio [omtkn]="omtkn" [exseg]="exseg"></app-keyratio>
        </div>
        <div *ngIf="action == 'TECHNICALS'">
            <app-technicals [omtkn]="omtkn" [exseg]="exseg" (load)="onLoading($event)"></app-technicals>
        </div>
        <div *ngIf="action == 'ABOUT'">
            <app-about [omtkn]="omtkn" [exseg]="exseg"></app-about>
        </div>
        <div *ngIf="action == 'PEER'">
            <app-peer [omtkn]="omtkn" [exseg]="exseg"></app-peer>
        </div>
        <div *ngIf="action == 'FINANCIALS'">
            <app-financial [omtkn]="omtkn" [exseg]="exseg"></app-financial>
        </div>
        <div *ngIf="action == 'STOCKNEWS'">
            <app-tagspecificnewsweb [IsStockSpecific]="false" [tagtype]="symbol" [width] = "container.width" [height] = "container.height -40" (load)="onLoading($event)"></app-tagspecificnewsweb>
        </div>
        <div *ngIf="action == 'VISUALTRADE'">
            <app-visualtrade isCustomDashboard="true" [multiomtkn]="omtkn" [multiexseg]="exseg" [fromMultiView] = "fromMultiView" [windowWidth] = "width"></app-visualtrade>
        </div>
    </div>
</mat-card>
