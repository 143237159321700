export class OptionCalcModel
{
    S: number;
    X: number;
    T: number;
    Rf: number;
    sigma: number;
    Q: number;
    d1: number;
    d2: number;
    Call: number;
    Put: number;
    delta_C: number;
    delta_P: number;
    gamma: number;
    vega: number;
    theta_C: number;
    theta_P: number;
    rho_C: number;
    rho_P: number;
}

export class OptionCalcGridModel
{
    Name: string
    call: number
    put: number
    straddle: number
}