import { Component, OnInit } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
let contact: ContactComponent;
@Component({
  selector: 'app-contact',
  templateUrl: '../../../'+environment.baseviewpath+'/pages/contact/contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  isMobile: boolean;
  constructor() {
    contact = this;
   }

  ngOnInit(): void {
    contact.isMobile = Utility.IS_MOBILE;
  }

}
