export class Keys {
    static RES_CODE_SUCCESS = 200;
    static RES_CODE_FAILURE = 500;
    static RES_MSG_OK = 'OK';
    static RSA_KEY_SIZE = 256;
    static NO_DATA = 'No Data';
    static key_map = {
        'ncx_fo': 'NCDEX',
        'nse_fo': 'NFO',
        'nse_cm': 'NSE',
        'mcx_fo': 'MCX',
        'cde_fo': 'CDS',
        'nse_mf': 'MFSS',
        'icx_fo': 'ICEX',
        'nmc_fo': 'NMCE',
        'dgx_fo': 'DGCX',
        'mcx_sx': 'MCXSX',
        'bse_fo': 'BFO',
        'nsel_sm': 'NSEL',
        'mcx_cm': 'MCXSXCM',
        'mcx_cmfo': 'MCXSXFO',
        'nse_dm': 'NDM',
        'bcs_fo': 'BCD',
        'bse_cm': 'BSE',
        'cse_cm': 'CSE',
        'bmv_cm': 'BMV',
        'ysx_cm': 'YSX',
        'bse_mf': 'BSEMF',
        'nse_ipo': 'NSE IPO',
        'bse_ipo': 'BSE IPO',
        'nse_nie': 'NIE',
        'bse_bix': 'BIX',
        'NFO': 'FNO',

        // product
        'BO': 'BO',
        'CO': 'CO',
        'NRML': 'NRML',
        'MIS': 'MIS',
        'CNC': 'CNC',
        'MTF': 'MTF',
        'CASHPLUS': 'CASHPLUS',
        'I':  'MIS',

        // order
        'L': 'LIMIT',
        'MKT': 'MARKET',
        'SL': 'SL',
        'SL-M': 'SL-M',

        // buy/sell
        'B': 'BUY',
        'S': 'SELL',
        'TRADE_VALUE': 'TRADE VALUE',
        'TRADE_VOLUME': 'TRADE VOLUME',
        'OPEN_INTRST_VOLUME': 'OPEN INTEREST VOLUME',
        '%CHANGE_PREV_CLOSE': '%CHANGE PREV CLOSE',
        'OPEN_INTRST_VALUE': 'OPEN INTEREST VALUE',
        'MOST_ACTIVE_PUT': 'MOST ACTIVE PUT',
        'MOST_ACTIVE_CALL': 'MOST ACTIVE CALL',
        'FUTURE': 'FUTURE',
        'NSE_OPEN_MARKET': 'NSE OPEN MARKET',
        'BSE_OPEN_MARKET': 'BSE OPEN MARKET',

        // trade
        'DETAIL_L': 'Limit',
        'DETAIL_S': 'Sell',
        'DETAIL_B': 'Buy',
        'DETAIL_AMOB': 'AMO Buy',
        'DETAIL_AMOS': 'AMO Sell',
        'DETAIL_MKT': 'Market',
        'DETAIL_SLM': 'Stop Loss Market',
        'DETAIL_SL': 'Stop Loss Limit',
        'DETAIL_SP': 'Spread',
        'DETAIL_2L': 'Two Leg',
        'DETAIL_3L': 'Three Leg',
        'DETAIL_SPM': 'SP-M',
        'DETAIL_AU': 'Auction Scrip',
        'DETAIL_MTL': 'MTL',
        'NA': 'NA',
        'DETAIL_BO': 'Bracket Order',
        'DETAIL_CO': 'Cover Order',
        'DETAIL_CNC': 'Cash N Carry',
        'DETAIL_MIS': 'Margin',
        'DETAIL_NRML': 'DETAIL_NRML',
        'DETAIL_INTRADAY': 'INTRADAY',
        'DETAIL_DELIVERY': 'DELIVERY',
        'DETAIL_SHORTSELL': 'SHORTSELL',
        'DETAIL_CARRYFWD': 'CARRYFWD',
        'EX': 'Exercise',
        'DONT_EX': 'Don’t Exercise',
        'DEL': 'Delivery',

        'IA': 'Investment Account',
        'DP': 'Direct Payment',

        // Order Status
        'put order req received': 'In Progress',
        'open': 'Open',
        'complete': 'Traded',
        'modify order req received': 'In Progress',
        'cancel order req received': 'In Progress',
        'modified': 'Modified',
        'cancelled': 'Cancelled',
        'rejected': 'Rejected',
        'not modified': 'In Progress',
        'not cancelled': 'In Progress',
        'open pending': 'In Progress',
        'modify pending': 'In Progress',
        'cancel pending': 'In Progress',
        'trigger pending': 'Trigger pending',
        'validation pending': 'In Progress',
        'modify validation pending': 'In Progress',
        'order frozen': 'In Progress',
        'after market order req received': 'after market order req received',
        'modify after market order req received': 'modify after market order req received',
        'cancelled after market order': 'cancelled after market order',
        'amo validation pending': 'In Progress',
        'amo modify validation pending': 'In Progress',
        'daily': 'DAILY',
        'weekly': 'WEEKLY',
        'monthly': 'MONTHLY',
        'Amount': 'AMOUNT',
        'Quantity': 'QUANTITY',
        "Order Book": 'Orders',
        "Trade Book": 'Trades',
        "Position Book": 'Positions',
        "Holding Book": 'Holdings',
        "General News":"General",
        "Equity News":"Equity",
        "Derivative News":"Derivative",
        "Stock Depth":"Depth",
        "Stock Technicals":"Technicals",    
        "Investment Boxes":"Investment Boxes",
        "Trigger":"Trigger"         
    };
    static INPROGRESS = 'In Progress';

    static PRODUCT_BO = 'BO';
    static PRODUCT_CO = 'CO';
    static PRODUCT_NRML = 'NRML';
    static PRODUCT_MIS = 'MIS';
    static PRODUCT_CNC = 'CNC';
    static PRODUCT_MTF = 'MTF';
    static PRODUCT_VTD = 'GTD';
    static PRODUCT_CASHPLUS = 'CASHPLUS';
    static PRODUCT_T5 = 'T+5';
    static PRODUCT_CFUTURE = 'CFUTURE';
    static PRODUCT_INTRADAY = 'INTRADAY';
    static SEG_CASH = 'CASH';
    static SEG_CUR = 'CUR';
    static SEG_FO = 'FO';
    static SEG_MTF = 'MTF';

    static ORDER_LIMIT = 'L';
    static ORDER_MARKET = 'MKT';
    static ORDER_SL = 'SL';
    static ORDER_SLM = 'SL-M';
    static ORDER_SP = 'SP';
    static ORDER_2L = '2L';
    static ORDER_3L = '3L';
    static RETENTION_DAY = 'DAY';
    static RETENTION_IOC = 'IOC';
    static RETENTION_GTD = 'GTD';
    static RETENTION_GTC = 'GTC';
    static RETENTION_GTT = 'GTT';
    static RETENTION_EOS = 'EOS';
    static RETENTION_COL = 'COL';
    static RETENTION_GTDys = 'GTDys';

    static EXCHANGE_NSE = 'NSE';
    static EXCHANGE_BSE = 'BSE';
    static EXCHANGE_BCD = 'BCD';
    static EXCHANGE_NFO = 'NFO';
    static EXCHANGE_MCX = 'MCX';
    static EXCHANGE_NCDEX = 'NCDEX';
    static EXCHANGE_CDS = 'CDS';
    static EXCHANGE_BFO = 'BFO';
    static EXCHANGE_NSE_IPO = 'NSE IPO';
    static EXCHANGE_BSE_IPO = 'BSE IPO';
}
