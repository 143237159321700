import { Component, ElementRef, OnInit } from '@angular/core';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';

let basketHomeCmp: BasketHomeComponent;
@Component({
    selector: 'app-baskethome',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/baskethome/baskethome.component.html',
    styleUrls: ['./baskethome.component.scss']
})
export class BasketHomeComponent extends BaseComponentDirective implements OnInit {
    isLoading = true;
    container;
    isMobile;
    Height = {};
    selectIndex;
    constructor(elRef: ElementRef) {
        super(elRef.nativeElement);
        basketHomeCmp = this;
        basketHomeCmp.selectIndex = Utility.SELECTED_BASKET_TAB;
        basketHomeCmp.isMobile = Utility.IS_MOBILE;
        if(!basketHomeCmp.isMobile) {
            Utility.callInvestorBaskets.subscribe((data)=>{
                basketHomeCmp.selectIndex = Utility.SELECTED_BASKET_TAB;
            });
        }
    }

    ngOnInit() {
    }

    onLoading(isLoading) {
        basketHomeCmp.isLoading = isLoading;
    }

    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container = {'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        this.changeMode();
    }
    changeMode(){
        this.Height = {
            'height': (this.container.height - 60) + 'px',
            'overflow': 'auto'
        };

    }
    tabClick(data){
        Utility.SELECTED_BASKET_TAB = data.index;
    }
}