<div class="row" [ngStyle]="Height">
    <div class="col-12">
        <mat-chip-list>
            <mat-chip *ngFor="let item of dataArr; let i = index" [class.imactive] = "tabIndex ==  i" (click)="onTabChanged(i)" translate>
                {{item}}
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class="col-12">
        <div *ngIf = "tabIndex == 0">
                <div *ngIf='!isNoData' class = "col-12 mt-3 cont">
                    <div class="row">
                        <div class="col-md-6 col-12 disabletext">
                            <div *ngIf = "generalinfo" class="pb-2">
                                <div class = "col-12 mb-2 white-text" translate>stock_detail.general_info </div>
                                <div  class = "col-12">{{'stock_detail.employees' | translate}}: {{generalinfo.Employees | numberpipe}}</div>
                                <div  class = "col-12">{{'stock_detail.total_shares_out' | translate}}: {{generalinfo.TotalSharesOut | pricepipe}}</div> 
                                <div  class = "col-12">{{'stock_detail.incorporated_in' | translate}}: {{generalinfo.IncorporatedIn | datepipe:'date'}}</div>
                                <div  class = "col-12">{{'stock_detail.public_since' | translate}}: {{generalinfo.PublicSince | datepipe:'date'}}</div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12 disabletext">
                            <div class="mb-2">
                                <div class = "col-12 mb-2 white-text" translate>stock_detail.director_info</div>
                                    <div class = "col-12"*ngFor="let obj of officer">
                                        <span>{{obj.name}}</span><br/>
                                        <span>{{obj.title}}</span>
                                    </div>                       
                            </div>
                        </div>
                    </div>
                  <div class="col-12 disabletext p-0">
                    <div class="mb-2">
                        <div class="col-12 mb-2 white-text" translate>stock_detail.registered_office</div>
                        <div class="col-12">
                            <span>{{adata}}</span><br/>
                            <span class="l1">{{'stock_detail.email' | translate}}: </span>
                            <span>{{emaildata}}</span>
                        </div>
                    </div>
                  </div>
                   
                </div>
                <div *ngIf='!isNoData' class="col-12 p-md-0 cont">
                    <div class="col-12 mt-2 cont">
                        <div class="col-12 mb-2" translate>stock_detail.about_company</div>
                        <div class="col-12 disabletext">{{cbdata}}</div>
                    </div>
                    <div class="col-12 mt-2 cont">
                        <div class="col-12 mb-2" translate>stock_detail.fin_summary</div>
                        <div class="col-12 disabletext">{{cfdata}}</div>
                    </div>
                </div>
                <div style="display: none;" class="chart-about"></div>
                <div *ngIf='isNoData'>
                    <app-nodata message = "No Data"></app-nodata>
            </div>
        </div>

        <div *ngIf = "tabIndex == 1">
            <div *ngIf='!isNoData && !isHoldingData' class = "row">
                <div class = "col-12 mb-2 mt-3 ml-3 header-color" translate>stock_detail.holding_pattern</div>
                <div class = "col-12">
                </div>
            </div>
        <div *ngIf="isHoldingData"><app-nodata></app-nodata></div>     
        </div>
</div>
 </div>

    