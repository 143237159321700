<app-header *ngIf="isMobile" goback="1" [title]="'derivative.option_placeorder' | translate" [isLoading]="isLoading" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'derivative.option_placeorder' | translate" [isLoading]="isLoading"></app-titlebar>
<mat-dialog-content [class.mh-none] = "isMobile">
    <div [class.data-container]="isMobile">
        
            <div class="po-section-data" [class.pb-4] = "isMobile">
                <div  *ngIf = "optiondata[0].futqtycount != 0" class="mycardwrapper">
                    <div class="row"> 
                        <div class="col-6">
                            <div class="firstline">
                                <span class="symbolname">{{optiondata[0].futurescrip.Symbolname}} {{optiondata[0].futurescrip.DisplaySymbolDescription}} <small>{{optiondata[0].futurescrip.ExchangeName}}</small></span>
                            </div>
                            <div class="line2">{{optiondata[0].futqtycount > 0 ? 'B' : 'S' }}</div>
                        </div>
                        <div class="col-6 text-right">
                            <span [ngClass]="{'impositive':optiondata[0].futurescrip.Change >0, 'imnegative': optiondata[0].futurescrip.Change < 0}">
                                {{optiondata[0].futurescrip.LastTradePrice | pricepipe}}
                            </span>
                        </div>
                    </div>
                    <div class="row placeorderfield">
                        <div class="col-4">
                            <mat-form-field>
                                <input matInput type="tel" min="0" inputmode="decimal" [placeholder]="'basket.lastprice' | translate"
                                    value="{{optiondata[0].futurescrip.LastTradePrice | pricepipe : data.futurescrip.DecimalPrecision}}"
                                autocomplete="off" oncopy="return false"
                                    onpaste="return false" appNumberDir dp="2" pricesize=7 (input) = "changeVal('futltp', 0 ,$event.target.value)">
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field>
                                <mat-label><span translate>basket.basket_qty</span><span *ngIf = "isLot"> (In Lots)</span></mat-label>
                                <input #myInput matInput type="tel" min="0" inputmode="decimal"
                                    [value]= "getQty(optiondata[0].futqtycount, optiondata[0].futurescrip.BodLotQuantity)"
                                    autocomplete="off" oncopy="return false" onpaste="return false" appNumberDir quantitysize=7  (input) = "changeVal('futqty', 0 ,$event.target.value)">
                            </mat-form-field>
                            <br *ngIf = "isLotLable"/>
                            <span *ngIf = "isLotLable" class="lotLable">(1 {{'portfolio.lt' | translate}} = {{optiondata[0].futurescrip.BodLotQuantity | numberpipe}}{{'portfolio.qty' | translate}})</span>
                        </div>
                        <div class="col-4 text-right">
                            <span class="text-right"><small class="appsecondarytext">{{'books.amt' | translate}}:&nbsp;&nbsp;</small><span>{{data.futurescrip.LastTradePrice * data.futqtycount | pricepipe : data.futurescrip.DecimalPrecision}}</span></span>
                        </div>
                    </div>
                </div>
                
                <div  *ngFor='let data of optiondata; let i = index;'>
                    <div *ngIf = "data.callqtycount !== 0" class="list-view border-b mb-2">
                        <div class="row mb-2">
                            <div class="col-12">
                                <span class="tc-1 ts-4 asmobile">{{data.callscrip.Symbolname}} {{data.callscrip.DisplaySymbolDescription}} </span><small class="exchangename">{{data.callscrip.ExchangeName}}</small><br>
                                <span class="textuppercase" [class.imnegative]="data.callqtycount < 0" [class.impositive] = "data.callqtycount > 0" translate> {{data.calltransactionType == 'B' ? 'books.buy' : 'books.sell' }}</span>  | 
                                <span class="ts-1 subcolor2">{{'stock_detail.ltp' | translate}} :</span> <span [ngClass]="{'impositive':data.callscrip.Change >0, 'imnegative': data.callscrip.Change < 0}"> {{data.callscrip.LastTradePrice | pricepipe : data.callscrip.DecimalPrecision }}</span>
                            </div>
                        </div>
                        <div class="row placeorderfield">
                            <div class="col-6">
                                <mat-form-field>
                                    <input #callInputPrice matInput type="tel" (keyup)="validatePrice($event, 'CALL', i, data.callscrip.DecimalPrecision)" [placeholder]="'basket.lastprice' | translate"
                                        value="{{data.callPrice}}" 
                                    autocomplete="off" oncopy="return false"
                                        onpaste="return false" (input) = "changeVal('callltp', i ,$event.target.value)">
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field >
                                    <mat-label><span translate>basket.basket_qty</span> <span *ngIf = "isLotLable" class="lotLable ml-1">(1 {{'portfolio.lt' | translate}} = {{data.callscrip.BodLotQuantity | numberpipe}}{{'portfolio.qty' | translate}})</span></mat-label>
                                    <input #callInput   matInput type="tel" 
                                        [placeholder]="'basket.basket_qty' | translate"  (keyup)="validateQty($event, 'CALL', i)"  value = "{{(data.callqtycount < 0) ? (data.callqtycount * -1) : (data.callqtycount)}}" 
                                        (input) = "changeVal('callqty', i ,$event.target.value)"
                                        autocomplete="off" oncopy="return false" onpaste="return false">
                                </mat-form-field>
                                <br *ngIf = "isLotLable"/>
                                <span *ngIf = "isLotLable" class="lotLable2" [class.imnegative] = "lotError">{{'portfolio.lots' | translate}} = {{(data.callqtycount < 0) ? (calculateLots(data.callqtycount, data.callscrip.BodLotQuantity) === 'NA' ? calculateLots(data.callqtycount, data.callscrip.BodLotQuantity) : -1 * calculateLots(data.callqtycount, data.callscrip.BodLotQuantity) ) : (calculateLots(data.callqtycount, data.callscrip.BodLotQuantity)) | numberpipe}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf = "data.putqtycount !== 0"  class="list-view border-b mb-2">
                        <div class="row mb-2">
                            <div class="col-12">
                                <span class="tc-1 ts-4 asmobile">{{data.putscrip.Symbolname}} {{data.putscrip.DisplaySymbolDescription}} </span><small class="exchangename">{{data.putscrip.ExchangeName}}</small><br>
                                <span class="textuppercase" [class.imnegative]="data.putqtycount < 0" [class.impositive] = "data.putqtycount > 0" translate> {{data.putqtycount > 0 ? 'books.buy'  : 'books.sell'}}</span> |
                                <span class="ts-1 subcolor2">{{'stock_detail.ltp' | translate}} :</span><span [ngClass]="{'impositive':data.callscrip.Change >0, 'imnegative': data.callscrip.Change < 0}">{{data.putscrip.LastTradePrice | pricepipe}}</span>
                            </div>
                        </div>
                        <div class="row placeorderfield">
                            <div class="col-6">
                                <mat-form-field>
                                    <input matInput type="tel" min="0" inputmode="decimal" [placeholder]="'basket.lastprice' | translate"
                                        value="{{data.putPrice}}"
                                    autocomplete="off" oncopy="return false"
                                        onpaste="return false"  #putInputPrice matInput type="tel" (keyup)="validatePrice($event, 'PUT', i, data.putscrip.DecimalPrecision)" (input) = "changeVal('putltp', i ,$event.target.value)">
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field>
                                    <mat-label><span translate>basket.basket_qty</span> <span *ngIf = "isLotLable" class="lotLable ml-1">(1 {{'portfolio.lt' | translate}} = {{data.putscrip.BodLotQuantity | numberpipe}}{{'portfolio.qty' | translate}})</span></mat-label>
                                    <input #putInput matInput type="tel" 
                                        [placeholder]="'basket.basket_qty' | translate" value = "{{(data.putqtycount < 0) ? (data.putqtycount * -1) : (data.putqtycount)}}"
                                        autocomplete="off" oncopy="return false" onpaste="return false" (input) = "changeVal('putqty', i ,$event.target.value)" (keyup)="validateQty($event, 'PUT', i)" >
                                </mat-form-field>
                                <br *ngIf = "isLotLable"/>
                                <span *ngIf = "isLotLable" class="lotLable2" [class.imnegative] = "lotError">{{'portfolio.lots' | translate}} = {{(data.putqtycount < 0) ? (calculateLots(data.putqtycount, data.putscrip.BodLotQuantity) === 'NA' ? calculateLots(data.putqtycount, data.putscrip.BodLotQuantity) : -1 * calculateLots(data.putqtycount, data.putscrip.BodLotQuantity) ) : (calculateLots(data.putqtycount, data.putscrip.BodLotQuantity)) | numberpipe}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!isMobile" class="mat-dialog-actions">
    <div class="padding-all">
        <div class="row">
            <div class="col-12 text-right" [class.p-action-r]="isMobile">
                <button  [disabled] = "isOrderPlaced" class="btn-positive" mat-flat-button (click) = "placeBasketOrder()"><span translate>basket.place</span></button>
            </div>
        </div>
    </div>
</mat-dialog-actions>

<mat-dialog-actions *ngIf="isMobile" class="mat-dialog-actions footerbtn" [class.mda-d-block] = "isMobile">
    <div>
        <div class="row">
            <div class="col-12 text-right" [class.p-action-r]="isMobile">
                <button [disabled] = "isOrderPlaced" class="btn-positive w-100" mat-flat-button (click) = "placeBasketOrder()"><span translate>basket.place</span></button>
            </div>
        </div>
    </div>
</mat-dialog-actions>