import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PricePipe } from './utility/pipes/PricePipe';
import { TruncateTextPipe } from './utility/pipes/truncate.text.pipe';
import { DatePipe } from './utility/pipes/datepipe';
import { SymbolPipe } from './utility/pipes/symbolpipe';
import { FormatTimePipe } from './utility/pipes/formatTime';
import { PercentagePipe } from './utility/pipes/percentagepipe';
import { NumberPipe } from './utility/pipes/numberpipe';
import { ValuePipe } from './utility/pipes/displayvaluespipe';
import { SplitPipe } from './utility/pipes/Splitpipe';
import { ApiService } from './services/api.service';
import { TradingRestService } from './services/tradingrest.service';
import { MetaColorService } from './view/meta-color.service';
import { PtPlusRestService } from './services/ptplusrest.service';
import { PwaService } from './services/pwa.service';
import { HeaderComponent } from './view/protradelite/header/header.component';
import { MaterialModule } from './material.module';
import { FooterComponent } from './view/protradelite/footer/footer.component';
import { NgxCacheIfDirective } from './directive/ngx-cache-if.directive';
import { TitlebarComponent } from './view/protradelite/titlebar/titlebar.component';
import { NodataComponent } from './view/protradelite/nodata/nodata.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WatchlistSettingsComponent } from './view/protradelite/home/watchlist/watchlistsettings/watchlistsettings.component';
import { ManageWLComponent } from './view/protradelite/home/watchlist/managewatchlist/managewatchlist.component';
import { WatchlistPopupComponent } from './view/protradelite/home/watchlist/watchlistpopup/watchlistpopup.component';
import { MpininputComponent } from './view/protradelite/mpininput/mpininput.component';
import { BannerComponent } from './view/protradelite/banner/banner.component';
import { SvgComponent } from './view/protradelite/svg/svg.component';
import { LoaderComponent } from './view/protradelite/loader/loader.component';
import { AbsPtPipe } from './utility/pipes/absptpipe';
import { MaskedValuePipe } from './utility/pipes/maskedValuePipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslateModule.forChild({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AbsPtPipe,
        PricePipe,
        TruncateTextPipe,
        DatePipe,
        SymbolPipe,
        PercentagePipe,
        NumberPipe,
        ValuePipe,
        SplitPipe,
        FormatTimePipe,
        HeaderComponent,
        FooterComponent,
        NgxCacheIfDirective,
        TitlebarComponent,
        NodataComponent,
        LoaderComponent,
        WatchlistSettingsComponent,
        ManageWLComponent,
        WatchlistPopupComponent,
        MpininputComponent,
        BannerComponent,
        SvgComponent,
        MaskedValuePipe
    ],
    exports: [
        AbsPtPipe,
        PricePipe,
        TruncateTextPipe,
        DatePipe,
        SymbolPipe,
        PercentagePipe,
        NumberPipe,
        ValuePipe,
        SplitPipe,
        FormatTimePipe,
        HeaderComponent,
        FooterComponent,
        NgxCacheIfDirective,
        TitlebarComponent,
        NodataComponent,
        LoaderComponent,
        TranslateModule,
        WatchlistSettingsComponent,
        ManageWLComponent,
        WatchlistPopupComponent,
        MpininputComponent,
        BannerComponent,
        SvgComponent,
        MaskedValuePipe
    ],
    providers: [ApiService, TradingRestService, MetaColorService, PtPlusRestService, PwaService]
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
