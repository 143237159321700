import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Keys } from '../../../services/keys';
import { TradingRestService } from '../../../services/tradingrest.service';
import { Utility, ChannelType } from '../../../services/utility';
import { BlackScholesFormula } from './BlackScholesFormula';
import { CoxRossFormula } from './CoxRossFormula';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { SearchComponent } from '../home/search/search.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let optioncalculator : OptionCalculatorComponent;

@Component({
    selector: 'app-optioncalculator',
    templateUrl: './optioncalculator.component.html',
    styleUrls: ['./optioncalculator.component.scss']
})
export class OptionCalculatorComponent extends BaseComponentDirective implements OnInit {
    scrip = '';
    strikeControl = new FormControl();
    spotControl = new FormControl();
    volatilityControl = new FormControl();
    interestControl = new FormControl();
    dividendControl = new FormControl();
    expiryControl = new FormControl();
    daysControl = new FormControl();
    @Input() isCustomDashboard: boolean;
    strickprice = '';
    optiontype = '';
    spotprice = '';
    volatility = '';
    interest = '';
    dividend = '';
    days = '';
    selectedType = 'black';
    calculateData;
    optionTableHeader = [];
    searchType: string;
    ScripModel;
    isscripLoad = false;
    expiryDate: Date;
    loadDatePicker: boolean = false;
    isPopup;
    minDate;
    maxDate;
    showTable;
    isMobile: boolean;
    isDataShown = false;
    @Output() openOCSearch = new EventEmitter<boolean>();
    isReload = false;
    container;
    constructor(elRef: ElementRef, private router: Router, private snackBar: MatSnackBar, private tradingRestService: TradingRestService, private translate: TranslateService, private dialog: MatDialog) {
        super(elRef.nativeElement);
        optioncalculator = this;
        this.isMobile = Utility.IS_MOBILE;
        this.translate.get(['optioncalc.parameters', 'optioncalc.call', 'optioncalc.put', 'optioncalc.straddle']).subscribe(
            values => {
                this.optionTableHeader = Object.keys(values).map(key => values[key]);
            }
        );
        this.minDate = new Date();
        this.expiryDate = new Date();
        Utility.callOptionCalculator.subscribe(data => {
            this.setData();
        });

        Utility.webReloadComponent.subscribe(data =>
        {
            if(optioncalculator.isReload === false && data === 'Option Calculator')
            {
                optioncalculator.isReload = true;
                this.showTable = false;
                this.setData();
                if(this.isDataShown)
                    this.SubmitButtonClick();
            }
            setTimeout(()=>
            {
                optioncalculator.isReload = false;
            }, 5000);
        });
    }

    ngOnInit() {
        if(this.inputs !== undefined)
            this.isCustomDashboard = this.inputs.isCustomDashboard;
        if(Utility.IS_MOBILE)
            Preferences.removeItem('currentpage');
        this.setData();
        this.isPopup = Utility.IS_POPUP;
        this.onAppearing();
    }

    setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.onAppearing();
          }, 50);
        }
        else {
          this.onDisappearing();
        }
    }

    showQuoteResponse(data, code, msg) {
        if (code == Keys.RES_CODE_SUCCESS) {
            this.loadDatePicker = true;
            this.ScripModel = data;
            // this.scrip = this.ScripModel.TradingSymbol;
            this.spotprice = this.ScripModel.LastTradePrice !== '--' ? this.ScripModel.LastTradePrice : '';
            this.optiontype = this.ScripModel.OptionType;
            this.strickprice = (this.ScripModel.StrikePrice !== '--') ? this.ScripModel.StrikePrice : '';
            this.isscripLoad = true;
            this.expiryDate = this.ScripModel.ExpiryDateDisplayValue;
            const daysCount = (Math.round(Math.abs((this.expiryDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24))));
            if (!isNaN(Number(daysCount)))
                this.days = daysCount.toString();
            this.maxDate = new Date(this.ScripModel.ExpiryDateDisplayValue);
        }
    }
    onDatePickerSelected() {
        if (this.ScripModel && this.expiryDate) {
            this.days = (Math.round(Math.abs((this.expiryDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24)))).toString();
        }
    }
    SubmitButtonClick() {
        if (this.scrip === '') {
            this.snackBar.open(this.translate.instant('optioncalc.searchscrip'), '', { duration: 3000 });
        } else if (this.strickprice === '' || isNaN(Number(this.strickprice))) {
            this.strikeControl.markAsTouched();
            this.snackBar.open(this.translate.instant('optioncalc.validstrikeprice'), '', { duration: 3000 });
        } else if (this.spotprice === '' || isNaN(Number(this.spotprice))) {
            this.spotControl.markAsTouched();
            this.snackBar.open(this.translate.instant('optioncalc.validspotprice'), '', { duration: 3000 });
        } else if (this.volatility === '' || isNaN(Number(this.volatility)) || Number(this.volatility) < 0 || Number(this.volatility) > 100) {
            this.volatilityControl.markAsTouched();
            this.snackBar.open(this.translate.instant('optioncalc.validvolatility'), '', { duration: 3000 });
        } else if (this.interest === '' || isNaN(Number(this.interest))|| Number(this.interest) < 0 || Number(this.interest) > 100) {
            this.interestControl.markAsTouched();
            this.snackBar.open(this.translate.instant('optioncalc.validinterest'), '', { duration: 3000 });
        } else if (this.dividend === '' || isNaN(Number(this.dividend))) {
            this.dividendControl.markAsTouched();
            this.snackBar.open(this.translate.instant('optioncalc.validdividend'), '', { duration: 3000 });
        } else if (this.expiryDate.toString() === 'Invalid Date' || this.expiryDate.toString() === 'NaN') {
            this.expiryControl.markAsTouched();
            this.snackBar.open(this.translate.instant('optioncalc.validexpiry'), '', { duration: 3000 });
        } else if (this.days === '' || isNaN(Number(this.days))) {
            this.daysControl.markAsTouched();
            this.snackBar.open(this.translate.instant('optioncalc.validdays'), '', { duration: 3000 });
        }
        else {
            this.showTable = true;
            this.isDataShown = true;
            let data;
            if (this.selectedType == 'black') {
                data = new BlackScholesFormula().OptionCalc(parseFloat(this.spotprice), parseFloat(this.strickprice), parseInt(this.days),
                    parseFloat(this.interest), parseFloat(this.volatility), parseFloat(this.dividend));
            }
            else {
                data = new CoxRossFormula().OptionCalc(parseFloat(this.spotprice), parseFloat(this.strickprice), parseInt(this.days),
                    parseFloat(this.interest), parseFloat(this.volatility), parseFloat(this.dividend));
            }
            this.calculateData = data;
        }
    }
    onTypeSelection($event: MatRadioChange) {
        this.selectedType = $event.value;
    }

    openSearch() {
        if (Utility.IS_MOBILE)
            this.router.navigate(['/search'], { queryParams: { isfromOC: true } });
        else {
            this.openOCSearch.emit(true);
        }
    }
    onEnter(id) {
        if (Utility.IS_MOBILE) {
          document.getElementById(id).focus();
        }
    }

    setData() {
        if (this.scrip !== Preferences.getItem('tsym', ReturnType.String))
            this.clearFields();
        this.scrip = Preferences.getItem('tsym', ReturnType.String);
        const exchangeSegment = Preferences.getItem('exg', ReturnType.Undefined);
        const token = Preferences.getItem('token', ReturnType.Undefined);
        if (exchangeSegment && token) {
            const sM = Utility.getScripModel(token, Utility.exchangeToSegment(exchangeSegment));
            this.tradingRestService.getScripService().showQuote(sM, false, this.showQuoteResponse.bind(this));
        }
    }

    validatePrice(event) {
        const key = event.key;
        const decimalPrecision = 3;
        const output = event.target.value.toString() + '' + key;
        const size = 7;
        const regex = new RegExp('^[.][0-9]{0,' + decimalPrecision + '}$|^[0-9]{0,' + size + '}$|^[0-9]{0,' + size + '}[.]{1,1}[0-9]{0,' + decimalPrecision + '}$');
        if (!regex.test(output) && key != 'Backspace' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab') {
            event.returnValue = false;
            if (event.preventDefault)
                event.preventDefault();
        }
    }

    validateQty(event) {
        const key = event.key;
        const output = event.target.value.toString() + '' + key;
        const size = 7;
        const regex = new RegExp('^[0-9]{0,' + size + '}$');
        if (!regex.test(output) && key != 'Backspace' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab') {
            event.returnValue = false;
            if (event.preventDefault)
                event.preventDefault();
        }
    }

    clearFields() {
        this.interestControl.reset();
        this.dividendControl.reset();
        this.daysControl.reset();
        this.volatilityControl.reset();
        this.showTable = false;
        this.volatility = '';
        this.interest = '';
        this.dividend = '';
        this.days = '';
    }

    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Shared);
      }
    
      onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
      }
      
      ngOnDestroy()
      {
        this.onDisappearing();
      }
      setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
      }
}
