import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Subscription, Subject } from 'rxjs';
import { CustomBasket } from '../../thematicbasket/customthematicbasket/custombasket/custombasket.component';
import { Utility } from 'src/app/services/utility';
import { BasketDetailComponent } from '../../thematicbasket/basketdetail/basketdetail.component';

@Component({
  selector: 'app-tagspecificnews',
  templateUrl: './tagspecificnews.component.html',
  styleUrls: ['./tagspecificnews.component.scss']
})
export class TagSpecificNewsComponent implements OnInit {
  targetElement: Element;
  newsArr = [];
  @Input() tagtype: string;
  @Input() IsStockSpecific: boolean;
  @Input() reloadData: Subject<any>;
  isNoData: boolean;
  isLoading: boolean;
  dummyImg = 'assets/img/news-placeholder.png';
  subscription: Subscription;
  isMobile;
  Height = {};
  numberofcol = 1;
  stockSpecificData:any;
  generalEquityNewsParameter:any;
  @Output() loaderEvent = new EventEmitter<boolean>();
  constructor(private PS: PtPlusRestService) {
    this.subscription = BasketDetailComponent.BASKET_NEWS_EVENT.subscribe(data => {
      this.setStockSpecificNews(data);
    });
  }

  ngOnInit() {
    
    this.isMobile = Utility.IS_MOBILE;
    this.targetElement = document.querySelector('html');
    if(!this.IsStockSpecific)
      this.getTagsSpecificNews();
  }

  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      this.getTagsSpecificNews();
      event.next();
    }, 1000);
  }
  
  alert(message: string) {
    
  }

  getTagsSpecificNews()
  {
    if (!this.IsStockSpecific) {
      const obj = {'text' : this.tagtype};
      this.generalEquityNewsParameter = obj;
      this.setGeneralNews(obj);
    }

    this.reloadData?.subscribe(data =>
    {
      if(this.IsStockSpecific)
      {
        this.setStockSpecificNews(this.stockSpecificData);
      }
      else
      {
        this.setGeneralNews(this.generalEquityNewsParameter);
      }
    });
  }

  setGeneralNews(obj :any)
  {
    this.isLoading = true;
    this.loaderEvent.emit(this.isLoading);
    this.PS.getNewsService().fetchTags(obj, this.getTagData.bind(this), this.getPreTagData.bind(this));
  }


  setStockSpecificNews(data) {
    this.stockSpecificData = data;
    this.isLoading = true;
    this.loaderEvent.emit(this.isLoading);
    this.PS.getNewsService().getSearchedStockNews(data, this.getTagData.bind(this), this.getPreTagData.bind(this));
  }
  getPreTagData(tags) {
    this.newsArr = tags;
    this.isLoading = false;
    this.loaderEvent.emit(this.isLoading);
  }
  getTagData(code, tags, message) {

    if (tags.length > 0) {
      this.newsArr = tags;
      this.isNoData = false;
    } else {
      this.isNoData = true;
    }

  }
  openNewTab(url, target) {
    window.open(url, target);
  }
}
