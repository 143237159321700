import { IDatafeedChartApi, ResolutionString, GetMarksCallback, LibrarySymbolInfo, ServerTimeCallback, Mark, TimescaleMark, SearchSymbolsCallback, ResolveCallback, ErrorCallback, SubscribeBarsCallback, HistoryCallback, DomeCallback, IExternalDatafeed, OnReadyCallback, PeriodParams, DatafeedConfiguration } from '../../../../assets/charting_library';
import { BehaviorSubject } from 'rxjs';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import { ScripModel } from 'src/app/model/scrip.model';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { TradingViewComponent } from './tradingview.component';
declare const moment: any;

//HistoryDepth, ResolutionBackValues
let datafeeder: DataFeederClass;
export class DataFeederClass implements IDatafeedChartApi, IExternalDatafeed
{
    static lastBarSubject = new BehaviorSubject<any>(null);
    resolutionGlobal; rangeStartDateGlobal; rangeEndDateGlobal;
    symbol; exchange; days = 20; type; dataType;
    isFirstCallGlobal; callbackGlobal;
    oneDayData; sevenDayData;
    historicalDayData = [];
    oneDayTryCount = 6;
    oneDaySec = 86400;
    scripModelMap = {};
    
    graphService;
    nextTime = -1;
    itemMap = {};
    lastBarGlobal = {};
    timeZoneOffset;
    tv: any; mode; 
    intraday_data_flag = false;
    historical_data_flag = false;
    request_type;
    format1 = "YYYY-MM-DD HH:mm:ss";
    format_date = "YYYY-MM-DD";
    caching_store = {};
    cache_startdate;
    cache_enddate;
    MILISEC_ONEDAY = 86400000;
    MILISEC_0530HOURS = 19800000;
    config: DatafeedConfiguration;
    PS: PtPlusRestService;
    tvc: TradingViewComponent;
    onRealtimeCallback = {};
    constructor(graphService: TradingRestService, sm: ScripModel, PS: PtPlusRestService, tvc: TradingViewComponent)
    { 
        datafeeder = this;
        datafeeder.graphService = graphService;
        datafeeder.scripModelMap[sm.Token] = sm; 
        datafeeder.PS = PS;
        datafeeder.tvc = tvc;
        datafeeder.subscribeStock(sm.Token);
    }
    
    subscribeStock(tk) {
        datafeeder.scripModelMap[tk].scripUpdate.subscribe(data => {

            let d =  new Date(Utility.dateFormatter(datafeeder.scripModelMap[tk].LastTradedTime));
            let l = d.getTime();

            let tick = {};
            tick['LastTradePrice'] = Utility.getNumber(datafeeder.scripModelMap[tk].LastTradePrice);
            tick['PerChange'] = Utility.getNumber(datafeeder.scripModelMap[tk].PercentageChange);
            tick['NetChange'] = Utility.getNumber(datafeeder.scripModelMap[tk].Change);
            tick['TradeVolume'] = datafeeder.scripModelMap[tk].TradeVolume;
            tick['ts'] = l;

            let newtick = datafeeder.updateBar(tick, tk);
            if(datafeeder.onRealtimeCallback[tk]) {
                datafeeder.onRealtimeCallback[tk](newtick);        
            }
        });
    }

    unsubscribeStock(){
        /*for (const [key, value] of Object.entries(datafeeder.scripModelMap)) {
            Utility.print(key);
            datafeeder.scripModelMap[key].scripUpdate.unsubscribe();  
        }*/
    }

    onReady(callback: OnReadyCallback): void {
        Utility.print('DataFeederClass onReady');
        datafeeder.mode = 'web';
        datafeeder.dataType = 'trkd';
        let r1 = '1' as ResolutionString;
        let r2 = '5' as ResolutionString;
        let r3 = '10' as ResolutionString;
        let r4 = '1D' as ResolutionString;
        let r5 = '1W' as ResolutionString;
        datafeeder.config = { supported_resolutions: [r1, r2, r3, r4, r5] };
        setTimeout(() => callback(datafeeder.config), 0);
    }

    getMarks?(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<Mark>, resolution: ResolutionString): void {

    }

    getTimescaleMarks?(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<TimescaleMark>, resolution: ResolutionString): void {
    }

    getServerTime?(callback: ServerTimeCallback): void {
        Utility.print("getServerTime got called");
        let serverTime = new Date().getTime() / 1000;
        callback(serverTime);

    }

    
    counter=0;
    res = [];
    searchSymbols(searchValue: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
        Utility.print('searchSymbols: ' + searchValue+' :: '+ exchange+' :: '+ symbolType);
        let filteredArray;

        let tagssearch_obj = {};
        if (searchValue.length >= 2 && searchValue !== ' ') {
            tagssearch_obj['text'] = searchValue;
            const obj = {};
            let allowedExchange = [];
            const exarr = Utility.getTradingExchanges();
            for (let i = 0; i < exarr.length; i++) {
                allowedExchange.push(Utility.exchangeToSegment(exarr[i]));
            }
            obj['keyword'] = searchValue;
            obj['search_type'] = 'ALL';
            obj['allowed_exchange'] = allowedExchange;
            datafeeder.PS.getSearchService().fetchData(++datafeeder.counter, obj, (obj, msg, counter) => {
                if (datafeeder.counter === counter) {
                    Utility.print(obj);
                    this.res = [];
                    for(let i =0;i<obj.length;i++){
                        let sym = {
                            "symbol": obj[i].tsym,
                            "full_name": obj[i].tsym, // e.g. BTCE:BTCUSD
                            "description": obj[i].symdes,
                            "exchange": Utility.segmentToExchange(obj[i].exseg),
                            "ticker": obj[i].omtkn,
                            "wtoken": obj[i].omtkn,
                            "type": obj[i].stktyp // or "futures" or "bitcoin" or "forex" or "index"
                        }
                        this.res.push(sym);
                    }
                    onResult(this.res);
                }
            });
        }
    }

    resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void {
        Utility.print("resolveSymbol got called");
        const symbolStub = datafeeder.generateSymbolStub(symbolName) as any;
        setTimeout(() => onResolve(symbolStub));
    }
    
    getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: ErrorCallback) {
        Utility.print('getBars'+ resolution);
        Utility.print('symbolInfo: ' + JSON.stringify(symbolInfo) + ", resolution: " + resolution + ', rangeStartDate: ' + new Date(periodParams.from * 1000) +
            ', rangeEndDate: ' + new Date(periodParams.to * 1000));
        let caching_key ;
        let request_type;
        let request_days = 0;

        if(resolution == '1' || resolution == '5' || resolution == '10' || resolution == '30') {
            Utility.print('getBars 1');
            caching_key = symbolInfo.ticker + '_1';
            request_type = 'Interday';
            request_days = 7;
        }
        else if(resolution == 'D' || resolution == '1D' || resolution == 'W' || resolution == '1W') {
            Utility.print('getBars 2');
            request_type = 'Historical';
            caching_key = symbolInfo.ticker + '_D' ;
            request_days = 1825;
            //resolution = '1D' as ResolutionString;
        }

        let caching_data = {};
        if(datafeeder.caching_store[caching_key])
            caching_data  = datafeeder.caching_store[caching_key];
            
        let startdate_long = periodParams.from * 1000;
        let enddate_long = periodParams.to * 1000;
        let startdate_date = new Date(startdate_long);
        let enddate_date = new Date(enddate_long);
        let startdate1 = moment(startdate_date).format(datafeeder.format1);
        let enddate1 = moment(enddate_date).format(datafeeder.format1);
        let startdate2 = moment(startdate_date).format(datafeeder.format_date);
        let enddate2 = moment(enddate_date).format(datafeeder.format_date);

        let bars = [];
        let lastweek = -1;
        let temp_bar;
        var caching_dates = datafeeder.getCacheDates(caching_data);
        if(caching_dates && startdate_long >= caching_dates['min_date'] && enddate_long <= caching_dates['max_date'])
        {
            Utility.print('get data from cache store, request_type: ' + request_type + ', resolution: ' + resolution);
            for (let datekey in caching_data)
            {
                let len =  caching_data[datekey].length;
                for(let i = 0; i < len; i++)
                {
                    let bar = caching_data[datekey][i];
                    if(bar['time'] > startdate_long && bar['time'] <= enddate_long)
                    {
                        if(request_type == 'Interday')
                        {
                            if((bar['time'] % (Number(resolution) * 1000 * 60)) == 0)
                            {
                                if(temp_bar)
                                    bars.push(temp_bar);
                                temp_bar = Object.assign({}, bar);
                            }
                            else
                            {
                                datafeeder.processIntervalTicks(temp_bar, bar);
                            }
                        }
                        else if(request_type == 'Historical')
                        {
                            if(resolution == 'D' || resolution == '1D')
                                bars.push(bar);
                            else if(resolution == 'W' || resolution == '1W') {
                                let cal = new Date(bar['time']);
                                let week = datafeeder.getWeekOfYear(cal);
                                if(lastweek != week)
                                {
                                    if(temp_bar)
                                        bars.push(temp_bar);
                                    temp_bar = Object.assign({}, bar);
                                }
                                else
                                {
                                    datafeeder.processIntervalTicks(temp_bar, bar);
                                }
                                lastweek = week;
                            }
                        }
                    }
                }
            }
            Utility.print('bars length: ' + bars.length);
            Utility.print(bars);
            onResult(bars, { noData: false});
        }
        else
        {
            Utility.print('getBars 3');
            if(request_type == 'Interday' && datafeeder.intraday_data_flag)
            {
                Utility.print('getBars 4');

                for (let datekey in caching_data)
                {
                    let len =  caching_data[datekey].length;
                    for(let i = 0; i < len; i++)
                    {
                        let bar = caching_data[datekey][i];
                        if(bar['time'] > startdate_long && bar['time'] <= enddate_long)
                        {
                            if((bar['time'] % (Number(resolution) * 1000 * 60)) == 0)
                            {
                                if(temp_bar)
                                    bars.push(temp_bar);
                                temp_bar = Object.assign({}, bar);
                            }
                            else
                            {
                                datafeeder.processIntervalTicks(temp_bar, bar);
                            }
                        }
                    }
                }
                if(bars.length > 0)
                    onResult(bars, { noData: false});
                else
                    onResult([], { noData: true});

                return;
            }

            if(request_type == 'Historical' && datafeeder.historical_data_flag)
            {
                Utility.print('Historical');
                for (var datekey in caching_data)
                {
                    let len =  caching_data[datekey].length;
                    for(let i = 0; i < len; i++)
                    {
                        let bar = caching_data[datekey][i];
                        if(bar['time'] > startdate_long && bar['time'] <= enddate_long)
                        {
                            if(resolution == 'D' || resolution == '1D')
                                bars.push(bar);
                            else if(resolution == 'W' || resolution == '1W') {
                                let cal = new Date(bar['time']);
                                let week = datafeeder.getWeekOfYear(cal);
                                if(lastweek != week)
                                {
                                    if(temp_bar)
                                        bars.push(temp_bar);
                                    temp_bar = Object.assign({}, bar);
                                }
                                else
                                {
                                    datafeeder.processIntervalTicks(temp_bar, bar);
                                }
                                lastweek = week;
                            }
                        }
                    }
                }
                Utility.print('Historical length: ' + bars.length);
                if(bars.length > 0)
                    onResult(bars, { noData: false});
                else
                    onResult([], { noData: true});

                return;
            }

            // remove earlier cached data. convert start date to date key
            startdate_date.setHours(0); startdate_date.setMinutes(0); startdate_date.setSeconds(0); startdate_date.setMilliseconds(0);
            enddate_date.setHours(0); enddate_date.setMinutes(0); enddate_date.setSeconds(0); enddate_date.setMilliseconds(0);

            let startdate_datekey = startdate_date.getTime();
            let enddate_datekey = enddate_date.getTime();
            for(; startdate_datekey <= enddate_datekey; startdate_datekey += datafeeder.MILISEC_ONEDAY){
                if(caching_data[startdate_datekey])
                {
                    caching_data[startdate_datekey] = [];
                }
            }

            const data = { 'symbol': datafeeder.scripModelMap[symbolInfo.ticker].Token, 'exch': datafeeder.scripModelMap[symbolInfo.ticker].ExchangeSegment, 'days': request_days, 'type': 'stock'};
            
            this.graphService.getTRKDChartService().getTRKDChartData(data, function (code, response, message) {
                
                if(response['status'] == 'SUCCESS')
                {
                    let record_count = response['data']['timestamparray'].length;
                    let isDataInRange = false;
                    let date_key;
                    let days_data;
                    if(record_count > 0)
                    {
                        let data = response['data'];
                        for(let i = 0; i < response['data']['timestamparray'].length; i++)
                        {
                            let bar = {};
                            let time = new Date(data.timestamparray[i]).getTime();
                            bar = {
                                time: time, open: data.openpricearray[i], high: data.highpricearray[i],
                                low: data.lowpricearray[i], close: data.closepricearray[i], volume: data.volumearray[i]
                            };
                            if(time > periodParams.from * 1000 && time <= periodParams.to * 1000)
                            {
                                if(resolution == 'W' || resolution == '1W') {
                                    let cal = new Date(bar['time']);
                                    let week = datafeeder.getWeekOfYear(cal);
                                    if(lastweek != week)
                                    {
                                        if(temp_bar)
                                            bars.push(temp_bar);
                                        temp_bar = Object.assign({}, bar);
                                    }
                                    else
                                    {
                                        datafeeder.processIntervalTicks(temp_bar, bar);
                                    }
                                    lastweek = week;
                                }
                                else
                                    bars.push(bar);    
                            }

                            if(resolution == '1' || resolution == '5' || resolution == '10' || resolution == '30')
                                datafeeder.intraday_data_flag = true;
                            else if(resolution == 'D' || resolution == '1D' || resolution == 'W' || resolution == '1W')
                                datafeeder.historical_data_flag = true;
                                                       
                            date_key = parseInt((time - time % 86400000)+'') - 19800000; // convert to date with 12:00:00 AM of IST timezone. 19800000 sec in 5:30hours
                            days_data = caching_data[date_key];
                            if(days_data)
                            {
                                days_data.push(bar);
                                caching_data[date_key] = days_data;
                            }
                            else
                            {
                                days_data = [];
                                days_data.push(bar);
                                caching_data[date_key] = days_data;
                            }
                            
                            if(date_key >= startdate_date.getTime() && date_key <= enddate_date.getTime())
                            {
                                isDataInRange = true;
                            }
                        }
                    }
                    datafeeder.caching_store[caching_key] = caching_data;
                    if(bars.length > 0)
                    {
                        if(!datafeeder.lastBarGlobal[symbolInfo.ticker])
                        {
                            datafeeder.lastBarGlobal[symbolInfo.ticker] = Object.assign({}, bars[bars.length - 1]);
                        }
                    }

                    onResult(bars, { noData: false});
                }
                else
                {
                    let nextTime = new Date(startdate1);
                    nextTime.setDate(nextTime.getDate() - 1);
                    nextTime.setHours(15);
                    nextTime.setMinutes(30);
                    onResult([], { noData: false, nextTime: Math.floor(nextTime.getTime() / 1000)});
                }
            }.bind(this));
        }
    }

    getWeekOfYear = (date) => {
        let oneJan: any = new Date(date.getFullYear(),0,1);
        let numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
        let result = Math.ceil(( oneJan.getDay() + 1 + numberOfDays) / 7);
        Utility.print(`The week number of the current date (${date}) is ${result}. ${numberOfDays} - ${date.getDay()}`);
        return result;
    }

    processIntervalTicks(temp_bar, bar): void{
        if(temp_bar)
        {
            if(bar.low < temp_bar.low)
                temp_bar.low = bar.low;
            
            if(bar.high > temp_bar.high)
                temp_bar.high = bar.high;
            
            temp_bar.close = bar.close;
            temp_bar.volume = temp_bar.volume + bar.volume;
        }
    }

    subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {
        Utility.print("subscribeBars got called");
        datafeeder.onRealtimeCallback[symbolInfo.ticker] = onTick;
    }

    unsubscribeBars(listenerGuid: string): void {
        datafeeder.unsubscribeStock();
        Utility.print("unsubscribeBars got called");
    }

    getVolumeProfileResolutionForPeriod?(currentResolution: ResolutionString, from: number, to: number, symbolInfo: LibrarySymbolInfo): ResolutionString;

    subscribeDepth?(symbol: string, callback: DomeCallback): string;
    
    unsubscribeDepth?(subscriberUID: string): void {
    }

    generateSymbolStub(symbolTicker) {
        Utility.print('generateSymbolStub: ' + symbolTicker);
        var hasNumber = /\d/;
        var selectedSym  = this.res.find(sym => sym['ticker'] == symbolTicker);
        if (!selectedSym)
        {
            selectedSym = {};
            selectedSym['exchange_segment'] = datafeeder.scripModelMap[symbolTicker].ExchangeSegment;
            selectedSym['exchange'] = datafeeder.scripModelMap[symbolTicker].ExchangeName;
            selectedSym['description'] = datafeeder.scripModelMap[symbolTicker].CompanyName;
            selectedSym['ticker'] = datafeeder.scripModelMap[symbolTicker].Token;
            selectedSym['full_name'] = datafeeder.scripModelMap[symbolTicker].TradingSymbol;
            selectedSym['wtoken'] = datafeeder.scripModelMap[symbolTicker].Token;
        
        }
        else {
            datafeeder.tvc.inputs ={};
            datafeeder.tvc.inputs.token = selectedSym.ticker;
            datafeeder.tvc.inputs.exch = Utility.exchangeToSegment(selectedSym.exchange);
            datafeeder.intraday_data_flag = false;
            datafeeder.historical_data_flag = false;
            datafeeder.scripModelMap[selectedSym.ticker] = Utility.getScripModel(selectedSym.ticker, Utility.exchangeToSegment(selectedSym.exchange), datafeeder.graphService, true); 
            datafeeder.subscribeStock(selectedSym.ticker);
        }
        return {
            "name": selectedSym.full_name,
            "exchange_segment": selectedSym.exchange,
            "exchange-traded": selectedSym.exchange,
            "exchange-listed": selectedSym.exchange,
            "wtoken": selectedSym.wtoken,
            "exchangename": selectedSym.exchange,
            "session": datafeeder.tradingSession(selectedSym.exchange),
            "minmov": 1,
            "minmov2": 0,
            "pointvalue": 1,
            "has_intraday": true,
            "has_weekly_and_monthly": true,
            "has_no_volume": false,
            "description": selectedSym.description,
            "type": "stock",
            "pricescale": 100,
            "ticker": selectedSym.ticker,
            "timezone": "Asia/Kolkata"
        };
    }

    getCacheDates(store)
    {
        let min_date, max_date;
        for (let datekey in store){

            if(!min_date)
                min_date = datekey;
            if(!max_date)
                max_date = datekey;

            if(datekey < min_date)
                min_date = datekey;

            if(datekey > max_date)
                max_date = datekey;

        }
        if(min_date && max_date)
        {
            max_date = Number(max_date) + (85000 * 1000);
            return { 'min_date': parseInt(min_date), 'max_date': parseInt(max_date) };
        }
        else 
            return null;
    }

    updateBar(tick, tkn) 
    {
        let _lastBar;
        try {
            let resolution = 1;
            let coeff = resolution * 60;
            let rounded = Math.floor((tick.ts/1000) / coeff) * coeff
            let lastBarSec = datafeeder.lastBarGlobal[tkn].time / 1000
            if (rounded > lastBarSec) 
            {
                _lastBar = {
                    time: rounded * 1000,
                    open: datafeeder.lastBarGlobal[tkn].close,
                    high: datafeeder.lastBarGlobal[tkn].close,
                    low: datafeeder.lastBarGlobal[tkn].close,
                    close: tick.LastTradePrice,
                    volume: parseInt(tick.TradeVolume) - datafeeder.lastBarGlobal[tkn].cumulativeVolume,
                    cumulativeVolume: parseInt(tick.TradeVolume)
                }
                Utility.print('Create new Bar. add last bar to memory');
                let caching_key = tkn + '_1';
                let one_memdata = datafeeder.caching_store[caching_key];
                if(one_memdata)
                    datafeeder.caching_store[caching_key] = datafeeder.addLastBar(one_memdata, datafeeder.lastBarGlobal[tkn]);
            } 
            else 
            {
                 // update lastBar candle!
                if (tick.LastTradePrice < datafeeder.lastBarGlobal[tkn].low) 
                {
                    datafeeder.lastBarGlobal[tkn].low = tick.LastTradePrice
                } 
                else if (tick.LastTradePrice > datafeeder.lastBarGlobal[tkn].high) 
                {
                    datafeeder.lastBarGlobal[tkn].high = tick.LastTradePrice
                }
                datafeeder.lastBarGlobal[tkn].volume += parseInt(tick.TradeVolume) - datafeeder.lastBarGlobal[tkn].cumulativeVolume
                datafeeder.lastBarGlobal[tkn].cumulativeVolume = parseInt(tick.TradeVolume);
                datafeeder.lastBarGlobal[tkn].close = tick.LastTradePrice
                 _lastBar = datafeeder.lastBarGlobal[tkn];
            }
            if(_lastBar.volume < 0)
                _lastBar.volume = 0;
        }
        catch (error)
        {
            //Utility.print(error);
        }
        datafeeder.lastBarGlobal[tkn] = _lastBar;

        return _lastBar
    }
    
    addLastBar(memdata, lastbar)
    {
        if(memdata && memdata.length > 0)
        {
            let memlasttime = memdata[memdata.length - 1]['time'];
            if(lastbar['time'] > memlasttime.getTime())
            {
                memdata.push(parseInt(lastbar));
            }
        }
        return memdata;
    }

    tradingSession(asset)
    {
        let session = '0915-1530';
        switch(asset)
        {
            case 'NSE': case 'NFO': case 'BSE': session = '0915-1530'; break;
            case 'CDS': session = '0915-1700'; break;
        }
        Utility.print('Session: ' + session + ", asset: "  +asset);
        return session;
    }

}
