import { Component, OnInit, Output, EventEmitter, Input, Inject, ChangeDetectorRef, Injector, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { PtPlusRestService } from '../../../../services/ptplusrest.service';
import { ComponentContainer } from 'golden-layout';
import { ChannelType, Utility } from 'src/app/services/utility';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let indices : IndicesComponent;

@Component({
    selector: 'app-indices-market',
    templateUrl: './indices.component.html',
    styleUrls: ['./indices.component.scss']
})
export class IndicesComponent extends BaseComponentDirective implements OnInit, OnDestroy {
    @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
    targetElement: Element;
    indicesArr = [];
    isLoading: boolean;
    isSearch = false;
    goback = '1';
    @Output() loaderEvent = new EventEmitter();
    @Output() load = new EventEmitter();
    @Input() isCustomDashboard = false;
    Height = {};
    numberofcol = 1;
    isMobile;
    container;
    modesub: Subscription;
    noData = false;
    showHeader = false;
    isReload = false;

    constructor(elRef: ElementRef, private injector: Injector, private detector: ChangeDetectorRef, private tradingRestService: TradingRestService,
        private ptPlusRestService: PtPlusRestService, private activatedRoute: ActivatedRoute) {
        super(elRef.nativeElement);
        indices = this;
        if (!Utility.IS_MOBILE){
        //    this.container = <ComponentContainer>this.injector.get('Container');
        }
        this.isMobile = Utility.IS_MOBILE;
        this.isLoading = true;
        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });
    }
    
    ngOnDestroy(): void {
        this.onDisappearing();
    }

    changeMode(){
        this.container = this.container === undefined ? Utility.setSize : this.container;
        this.Height = {
            'height': (this.container.height) + 'px',
            'overflow': 'auto'
        };
        this.detector.detectChanges();
        if (this.container.width >= 1200) {
          this.numberofcol = 4;
        } else if (this.container.width >= 768 && this.container.width < 1200) {
          this.numberofcol = 3;
        } else if (this.container.width >= 500 && this.container.width < 768) {
          this.numberofcol = 2;
        } else {
          this.numberofcol = 1;
        }
    }

    ngOnInit() {
        if(this.inputs !== undefined)
            this.isCustomDashboard = this.inputs.isCustomDashboard;
        this.targetElement = document.querySelector('html');
        this.activatedRoute.queryParams.subscribe(params => {
            this.showHeader = params['h'];
        });
        if (this.container) {
            /* this.container.on('resize', _ => {
                this.changeMode();
            });*/
        }
       this.getIndicesData();

       Utility.webReloadComponent.subscribe(data =>
        {
            if(indices.isReload === false && data === 'Advances & Declines')
            {
                indices.isReload = true;
                this.reloadData();            
            }
        });
    }

    myRefreshEvent(event: Subject<any>, message: string) {
        setTimeout(() => {
          this.reloadData();
          event.next();
        }, 1000);
    }
      
    alert(message: string) {
        
    }

    reloadData()
    {
        this.getIndicesData();
    }

    getIndicesData() {
        this.isLoading = true;
        this.loaderEvent.emit(this.isLoading);
        this.load.emit(this.isLoading);
        this.ptPlusRestService.getEquityMarketService().getAllIndicesDataNQ('nse_cm', this.resData.bind(this), function(data) {
            this.indicesArr = data;
        }.bind(this));
        if (this.isCustomDashboard) {
            this.changeMode();
        }
    }

    resData(code, data, message) {
        this.isLoading = false;
        this.isReload = false;
        this.load.emit(this.isLoading);
        this.loaderEvent.emit(this.isLoading);
        if (code === 200 && data.length > 0) {
            this.indicesArr = data;
            this.noData = false;
        } else {
            this.noData = true;
        }
    }

    onTitleClick() {
        this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.onAppearing();
            this.changeMode();
          }, 50);
        }
        else {
          this.onDisappearing();
        }
    }

    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Index);
    }

    onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Index);
    }
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
       // this.cellview = (width < Utility.MinWidth) ? true : false;
       Utility.setSize = this.container;
        this.changeMode();
    }
}
