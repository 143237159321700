import { Injectable, ɵConsole } from '@angular/core';
import { IBookService } from './ibook.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { Utility } from '../utility';
import { OrderModel } from 'src/app/model/order.model';
import { TradeModel } from 'src/app/model/trade.model';
import { PositionModel } from '../../model/position.model';
import { HoldingModel } from 'src/app/model/holding.model';
import { Holdings } from 'src/app/model/holdingsummary.model';
import { TradingRestService } from '../tradingrest.service';
import { IScripService } from '../scrip/iscrip.service';
import { OrderHistoryModel } from 'src/app/model/orderhistory.model';
import { Positions } from 'src/app/model/positionsummary.model';
import { Preferences } from '../Preferences';

let bookService: BookService;
@Injectable()
export class BookService implements IBookService {
    apiService: ApiService;
    availableQtyQtyMap = new Map();
    scripService: IScripService;
    tradingRestService: TradingRestService;
    s_prdt_ali;
    uid;
    userParameter;
    actId;
    holdings = new Holdings();
    positions = new Positions();
    constructor(apiService: ApiService) {
        bookService = this;
        bookService.userParameter = JSON.parse(Preferences.getPreference('userParameter'));
        bookService.s_prdt_ali = bookService.userParameter['s_prdt_ali'];
        bookService.actId = bookService.userParameter['sAccountId'];
        bookService.uid = Preferences.getPreference('userid');
        bookService.apiService = apiService;
    }
    getSpreadOrderHistory(callback: Function, precallback?: Function) {
        throw new Error('Method not implemented.');
    }
    getAllSpreadOrders(callback: Function, precallback?: Function) {
        throw new Error('Method not implemented.');
    }

    setTradingRestService(tradingRest: TradingRestService) {
        bookService.tradingRestService = tradingRest;
    }
    
    SpreadCancelOrder(nestOrd, callback: Function) {
        throw new Error('Method not implemented.');
    }

    getAllOrders(callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const orderList = new Array<OrderModel>();
        const jsonSendObj = {
            'uid': Preferences.getPreference('userid'),
            's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali']
        };
        const key = Url.ORDER_BOOK + '_' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.ORDER_BOOK, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            const stat = res[0]['stat'];
            const accountId = JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'];
            if (stat === 'Ok') {
                for (let i = 0; i < res.length; i++) {
                    const obj = res[i];
                    const bean = Utility.GetOrderModels(obj['Nstordno'], obj['Exseg']);
                    // if (accountId === obj['accountId']) {
                        bean.Exchange = obj['Exchange'];
                        bean.TradeSymbol = obj['Trsym'];
                        bean.NestOrd = obj['Nstordno'];
                        bean.ExchangeOrderNo = obj['ExchOrdID'];
                        bean.Action = obj['Trantype'];
                        bean.ActionDisplay = Utility.Action[bean.Action];
                        bean.OrderType = obj['Prctype'];
                        bean.DiscQty = obj['Dscqty'];
                        bean.CanceledQty = obj['Cancelqty'];
                        bean.Price = obj['Prc'];
                        bean.TriggerPrice = obj['Trgprc'];
                        bean.Validity = obj['Validity'];
                        bean.ScripName = obj['Scripname'];
                        bean.SymbolName = obj['Sym'];
                        bean.Status = obj['Status'];
                        bean.DisplayStatus = Utility.getDisplayStatus(bean.Status);
                        bean.FilledShares = obj['Fillshares'];
                        bean.ProductCode = Utility.getProductKey(obj['Pcode']);
                        bean.AvgPrice = obj['Avgprc'];
                        bean.TotalQty = obj['Qty'];
                        bean.PendingQty = obj['Unfilledsize'];
                        bean.ExchangeTimeStamp = obj['ExchConfrmtime'] ? obj['ExchConfrmtime'] : '--';
                        bean.RejectionReason = (obj['RejReason'] && obj['RejReason'] !== '--') ? obj['RejReason'] : 'NA';
                        bean.OrderValidityDate = obj['Ordvaldate'];
                        bean.MarketProtectionPercentage = obj['Mktpro'];
                        bean.AccountId = obj['accountId'];
                        bean.RefLmtPrice = obj['RefLmtPrice'];
                        bean.BrokerClient = obj['BrokerClient'];
                        bean.ExpSsbDate = obj['ExpSsbDate'];
                        bean.COPercentage = obj['COPercentage'] * 100;
                        bean.RequestID = obj['RequestID'];
                        bean.Token = obj['token'];
                        bean.Ticksize = obj['ticksize'];
                        bean.NoMktPro = obj['noMktPro'];
                        bean.Bqty = obj['bqty'];
                        bean.MarketProtectionFlag = obj['mpro'];
                        bean.Multiplier = obj['multiplier'];
                        bean.Stat = obj['stat'];
                        bean.DiscQtyPerc = obj['discQtyPerc'];
                        bean.SyomOrderId = obj['SyomOrderId'];
                        bean.Defmktproval = obj['defmktproval'];
                        bean.ExpDate = obj['ExpDate'];
                        bean.Minqty = obj['Minqty'];
                        bean.Exseg = obj['Exseg'];
                        bean.ExchangeSegment = obj['Exseg'];
                        bean.User = obj['user'];
                        bean.Decprec = obj['decprec'];
                        bean.ordersource = obj['ordersource'];
                        bean.displayOrderSource = Utility.displayOrderSource(bean.ordersource);
                        bean.modifiedBy = (obj['modifiedBy'] && obj['modifiedBy'] !== '--') ? obj['modifiedBy'] : 'NA';
                        bean.AlgoID = obj['AlgoID'];
                        bean.AlgoCategory = obj['AlgoCategory'];
                        bean.panNo = obj['panNo'];
                        bean.InstName = obj['InstName'] ? obj['InstName'] : '--';
                        bean.PriceNumerator = obj['PriceNumerator'];
                        bean.GeneralNumerator = obj['GeneralNumerator'];
                        bean.PriceDenomenator = obj['PriceDenomenator'];
                        bean.GeneralDenomenator = obj['GeneralDenomenator'];
                        bean.series = obj['series'] ? obj['series'] : 'NA';
                        bean.orderentrytime = obj['orderentrytime'] ? obj['orderentrytime'] : 'NA';
                        bean.ordergenerationtype = obj['ordergenerationtype'] ? obj['ordergenerationtype'] : 'NA';
                        bean.sipindicator = obj['sipindicator'] ? obj['sipindicator'] : 'NA';
                        bean.Usercomments = obj['Usercomments'];
                        bean.Remarks = obj['remarks'];
                        bean.OrderedTime = obj['OrderedTime'] ? obj['OrderedTime'] : '--';
                        bean.GuiOrderId = obj['guiOrderId'];
                        bean.PendQtyDispl = bean.PendingQty + '/' + bean.TotalQty;
                        bean.applyStatusConditions();
                        orderList.push(bean);
                    // }
                }
                Utility.CACHE_STORE.setCacheData(key, orderList);
                message = 'OK';
            } else {
                message = res[0]['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(orderList, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(orderList, code, message);
            });
    }

    getAllTradeList(flag, callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const tradeBookList = new Array<TradeModel>();
        const jsonSendObj = {
            'uid': Preferences.getPreference('userid'),
            's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali']
        };
        const key = Url.TRADE_BOOK + '_' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.TRADE_BOOK, param).subscribe(resonseData => {
            const stat = resonseData[0]['stat'];
            const accountId = JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'];
            if (stat === 'Ok') {
                for (let i = 0; i < resonseData.length; i++) {
                    const tradeListObj = resonseData[i];
                    const bean = Utility.GetTradeModels(tradeListObj['Nstordno'], tradeListObj['Exseg'], tradeListObj['FillId']);
                    if (accountId === tradeListObj['accountId']) {
                        bean.AvgPrice = tradeListObj['AvgPrice'];
                        bean.AccountId = tradeListObj['accountId'];
                        bean.CustOFrm = tradeListObj['Custofrm'];
                        bean.OrdDuration = tradeListObj['Ordduration'];
                        bean.FillTime = tradeListObj['Filltime'];
                        bean.BrokerClient = tradeListObj['BrokerClient'];
                        bean.Qty = tradeListObj['Qty'];
                        bean.Exchange = tradeListObj['Exchange'];
                        bean.Time = tradeListObj['Time'];
                        bean.PrcType = tradeListObj['Prctype'];
                        bean.FillDate = tradeListObj['Filldate'];
                        bean.NOReqID = tradeListObj['NOReqID'];
                        bean.ExchTime = tradeListObj['Exchtime'];
                        bean.FillLeg = tradeListObj['FillLeg'];
                        bean.NestOrd = tradeListObj['Nstordno'];
                        bean.TSym = tradeListObj['Tsym'];
                        bean.FillId = tradeListObj['FillId'];
                        bean.Stat = tradeListObj['stat'];
                        bean.FillQty = tradeListObj['Fillqty'];
                        bean.Symbol = tradeListObj['Symbol'];
                        bean.TranType = tradeListObj['Trantype'];
                        bean.PosFlag = tradeListObj['posflag'];
                        bean.Price = parseFloat(tradeListObj['Price']);
                        bean.Exseg = tradeListObj['Exchseg'];
                        bean.ExchordID = tradeListObj['ExchordID'];
                        bean.Pcode = Utility.getProductKey(tradeListObj['Pcode']);
                        bean.Minqty = tradeListObj['Minqty'];
                        bean.User = tradeListObj['user'];
                        bean.FilledQty = tradeListObj['Fillqty'];
                        bean.TranTypeDisplay = 'DETAIL_' + bean.TranType;
                        bean.FillDateTime = bean.FillDate + ' ' + bean.FillTime;
                        bean.ExpiryDate = tradeListObj['Expiry'];
                        bean.CompanyName = tradeListObj['companyname'];
                        bean.SymbolName = tradeListObj['symbolname'];
                        tradeBookList.push(bean);
                    }
                }
                Utility.CACHE_STORE.setCacheData(key, tradeBookList);
                message = 'OK';
            }
            else {
                message = resonseData[0]['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(tradeBookList, code, message);
        }
            ,
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(tradeBookList, code, message);
            });
    }

    getAllPositionList(data, callback: Function) {
        const type = data.type;
        let PositionModelListTemp = new Positions();
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const cachedKey = Url.POSITION_BOOK + '_' + data.type;
        const param = Utility.prepareParameters(JSON.stringify(data), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.POSITION_BOOK, param).subscribe(resonseData => {
            const stat = resonseData[0]['stat'];
            const scripTempList = [];
            if (stat === 'Ok') {
                bookService.positions.totalMtoM = 0;
                bookService.positions.totalURPL = 0;
                bookService.positions.totalRPL = 0;
                bookService.positions.PositionsModel = [];
                for (let i = 0; i < resonseData.length; i++) {
                    const positionListObj = resonseData[i];
                    const bean = Utility.GetPositionModels(positionListObj['Token'], positionListObj['Exchangeseg'], positionListObj['Pcode']);
                    bean.realisedprofitloss = positionListObj['realisedprofitloss'];
                    bean.FillSellAmt = Utility.getNumber(positionListObj['Fillsellamt']);
                    bean.FillBuyAmt = Utility.getNumber((positionListObj['Fillbuyamt']));
                    bean.Netqty = positionListObj['Netqty'];
                    bean.Symbol = positionListObj['Symbol'];
                    bean.Instname = positionListObj['Instname'];
                    bean.Expdate = positionListObj['Expdate'];
                    bean.LTP = Utility.formatNumberToDecimalPrecision(positionListObj['LTP'], 2);
                    bean.Opttype = positionListObj['Opttype'];
                    bean.BLQty = positionListObj['BLQty'];
                    bean.Token = positionListObj['Token'];
                    bean.FillBuyQty = Utility.getNumber(positionListObj['Fillbuyqty']);
                    bean.FillSellQty = Utility.getNumber(positionListObj['Fillsellqty']);
                    bean.Tsym = positionListObj['Tsym'];
                    bean.sSqrflg = positionListObj['sSqrflg'];
                    bean.unrealisedprofitloss = positionListObj['unrealisedprofitloss'];
                    bean.Buyavgprc = positionListObj['Buyavgprc'];
                    bean.MtoM = Utility.getNumber(positionListObj['MtoM']);
                    bean.s_NetQtyPosConv = positionListObj['s_NetQtyPosConv'];
                    bean.Sqty = positionListObj['Sqty'];
                    bean.Sellavgprc = positionListObj['Sellavgprc'];
                    bean.PriceDenomenator = positionListObj['PriceDenomenator'];
                    bean.PriceNumerator = positionListObj['PriceNumerator'];
                    bean.posflag = positionListObj['posflag'];
                    bean.Pcode = positionListObj['Pcode'];
                    bean.Stikeprc = Utility.getNumber(positionListObj['Stikeprc']);
                    bean.Bqty = positionListObj['Bqty'];
                    bean.BEP = positionListObj['BEP'];
                    bean.Exchange = positionListObj['Exchange'];
                    bean.Series = positionListObj['Series'];
                    bean.GeneralDenomenator = positionListObj['GeneralDenomenator'];
                    bean.Type = positionListObj['Type'];
                    bean.Netamt = positionListObj['Netamt'];
                    bean.UniqueKey = positionListObj['UniqueKey'];
                    bean.companyname = positionListObj['companyname'];
                    bean.GeneralNumerator = positionListObj['GeneralNumerator'];
                    bean.Exchangeseg = positionListObj['Exchangeseg'];
                    bean.discQty = positionListObj['discQty'];
                    bean.CFSellQty = positionListObj['CFsellqty'] === undefined ? 0 : Utility.getNumber(positionListObj['CFsellqty']);
                    bean.CFBuyQty = positionListObj['CFbuyqty'] === undefined ? 0 : Utility.getNumber(positionListObj['CFbuyqty']);
                    bean.DecimalPrecision = positionListObj['LTP'].split('.')[1].length;
                    bean.CFNetSellAvgPrc = positionListObj['CFSellavgprc'] === undefined ? '0.00' : Utility.formatNumberToDecimalPrecision(positionListObj['CFSellavgprc'], bean.DecimalPrecision);
                    bean.CFNetBuyAvgPrc = positionListObj['CFNetBuyAvgPrc'] === undefined ? '0.00' : Utility.formatNumberToDecimalPrecision(positionListObj['CFNetBuyAvgPrc'], bean.DecimalPrecision);
                    bean.FillSellAmtCF = positionListObj['FillsellamtCF'] === undefined ? '0.00' : Utility.formatNumberToDecimalPrecision(positionListObj['FillsellamtCF'], bean.DecimalPrecision);
                    bean.FillBuyAmtCF = positionListObj['FillbuyamtCF'] === undefined ? '0.00' : Utility.formatNumberToDecimalPrecision(positionListObj['FillbuyamtCF'], bean.DecimalPrecision);
                    bean.SNetQtyPosConv = positionListObj['s_NetQtyPosConv'];
                    bean.Stat = positionListObj['stat'];
                    bean.DayBuyAvgPrc = Utility.getNumber(positionListObj['Buyavgprc']);
                    bean.DaySellAvgPrc = Utility.getNumber(positionListObj['Sellavgprc']);

                    // derived values
                    bean.NetBuyQty = bean.FillBuyQty + bean.CFBuyQty;
                    bean.NetSellQty = bean.FillSellQty + bean.CFSellQty;
                    bean.NetBuyAmt = bean.FillBuyAmt + Utility.getNumber(bean.FillBuyAmtCF);
                    bean.NetSellAmt = bean.FillSellAmt + Utility.getNumber(bean.FillSellAmtCF);

                    if (type === 'DAY') {
                        bean.BuyQTY = Utility.getNumber(positionListObj['Bqty']);
                        bean.SellQTY = Utility.getNumber(positionListObj['Sqty']);
                        bean.BuyAvgPrc = Utility.getNumber(positionListObj['Buyavgprc']);
                        bean.SellAvgPrc = Utility.getNumber(positionListObj['Sellavgprc']);
                        bean.BuyAvgPrcStr = positionListObj['Buyavgprc'];
                        bean.SellAvgPrcStr = positionListObj['Sellavgprc'];
                        bean.NetBuyAvgPrc = Utility.getNumber(positionListObj['Buyavgprc']);
                        bean.NetSellAvgPrc = Utility.getNumber(positionListObj['Sellavgprc']);
                    }

                    else {
                        bean.BuyQTY = Utility.getNumber(positionListObj['netbuyqty']);
                        bean.SellQTY = Utility.getNumber(positionListObj['netsellqty']);
                        if (positionListObj['NetBuyavgprc'] !== null) {
                            bean.BuyAvgPrc = Utility.getNumber(positionListObj['NetBuyavgprc']);
                            bean.BuyAvgPrcStr = positionListObj['NetBuyavgprc'];
                        }
                        if (positionListObj['NetSellavgprc'] !== null) {
                            bean.SellAvgPrc = Utility.getNumber(positionListObj['NetSellavgprc']);
                            bean.SellAvgPrcStr = positionListObj['NetSellavgprc'];
                        }
                        bean.NetBuyAvgPrc = Utility.getNumber(positionListObj['NetBuyavgprc']);
                        bean.NetSellAvgPrc = Utility.getNumber(positionListObj['NetSellavgprc']);
                    }
                    // JObject jdata = Utility.ExtractCompanyNameFromSymbol(Bean.TSym, Bean.CompanyName, Bean.Symbol, Bean.ExpDate, bean.StikePrc.ToString(), Bean.OptType, Bean.Series);
                    // bean.DisplaySymbol = jdatt['DisplaySymbol'];
                    // bean.SymbolDetail = jdata["CompanyName"].ToString();

                    bean.UnRealisedPL = Utility.getNumber(positionListObj['unrealisedprofitloss']);
                    bean.RealisedPL = Utility.getNumber(positionListObj['realisedprofitloss']);
                    // bean.LTPStr =
                    // bean.RealisedPLStr =
                    // bean.MtoMStr =
                    bean.GDGNPDPN = (Utility.getNumber(positionListObj['GeneralNumerator']) /
                        Utility.getNumber(positionListObj['GeneralDenomenator'])) * (Utility.getNumber(positionListObj['PriceNumerator']) / Utility.getNumber(positionListObj['PriceDenomenator']));
                    scripTempList.push(bean.Exchangeseg + '|' + bean.Token);
                    bookService.positions.PositionsModel.push(bean);

                    // Multiple Scrip Data Code need to be implemented
                }
                PositionModelListTemp = bookService.positions;
                Utility.CACHE_STORE.setCacheData(cachedKey, PositionModelListTemp);
                message = 'OK';
                const count = scripTempList.length;
                for (let i = 0; i < PositionModelListTemp.PositionsModel.length; i++) {
                    bookService.positions.totalRPL += PositionModelListTemp.PositionsModel[i].RealisedPL;
                    bookService.positions.totalURPL += PositionModelListTemp.PositionsModel[i].UnRealisedPL;
                    bookService.positions.totalMtoM += PositionModelListTemp.PositionsModel[i].MtoM;
                }
                if (count > 0) {
                    const positionScripListObj = {
                        'uid': Preferences.getPreference('userid'),
                        'tomcatCount': Preferences.getPreference('tomcatcount'),
                        'scripList': scripTempList.toString()
                    };
                    bookService.tradingRestService.getScripService().getMultipleScrips(positionScripListObj, (positiondata, resCode, resMessage) => {
                        if (resCode === 200 && resMessage === 'Ok') {
                            const scripListMap = new Map();
                            positiondata.forEach(element => {
                                const scripKey = element.Token + element.ExchangeSegment;
                                if (!scripListMap.has(scripKey)) {
                                    scripListMap.set(scripKey, element);
                                }
                            });
                            const scripcount = positiondata.length;
                            for (let i = 0; i < scripcount; i++) {
                                const pModel = bookService.positions.PositionsModel[i];
                                let key = '';
                                if (!(pModel.Exchangeseg === '0') && !(pModel.Token === '0')) {
                                    key = pModel.Token + pModel.Exchangeseg;
                                }
                                const sm = scripListMap.get(key);
                                pModel.positionModelChanged = this.CalculateTotal;
                                const buyQty = Utility.IsLot(sm.ExchangeName) ? pModel.NetBuyQty * pModel.GDGNPDPN * sm.Multiplier * sm.BodLotQuantity
                                    : pModel.NetBuyQty * pModel.GDGNPDPN * sm.Multiplier;
                                const sellQty = Utility.IsLot(sm.ExchangeName) ? pModel.NetSellQty * pModel.GDGNPDPN * sm.Multiplier * sm.BodLotQuantity
                                    : pModel.NetSellQty * pModel.GDGNPDPN * sm.Multiplier;
                                if (pModel.Type === 'DAY1') {
                                    pModel.BuyAvgPrc = buyQty === 0 ? 0 : pModel.FillBuyAmt / buyQty;
                                    pModel.SellAvgPrc = sellQty === 0 ? 0 : pModel.FillSellAmt / sellQty;
                                }
                                else {
                                    pModel.BuyAvgPrc = buyQty === 0 ? 0 : pModel.NetBuyAmt / buyQty;
                                    pModel.SellAvgPrc = sellQty === 0 ? 0 : pModel.NetSellAmt / sellQty;
                                }
                                pModel.setScripModel(sm);
                            }
                            bookService.positions.TotalURPLStr = bookService.positions.totalURPL.toString();
                            bookService.positions.TotalRPLStr = bookService.positions.totalRPL.toString();
                            bookService.positions.TotalMtoMStr = bookService.positions.totalMtoM.toString();
                        }
                    });
                }
            }
            else {
                message = resonseData[0]['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(PositionModelListTemp, code, message);
        }
            ,
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(PositionModelListTemp, code, message);
            });
    }

    CalculateTotal(model: PositionModel) {
        let urpl = 0;
        let rpl = 0;
        rpl = model.RealisedPL;
        urpl = model.UnRealisedPL;
        if (model.NetBuyQty > model.NetSellQty) {
            model.RealisedPL = Utility.IsLot(model.scripModel.ExchangeName) ? ((model.SellAvgPrc - model.BuyAvgPrc)
                * model.NetSellQty * model.GDGNPDPN * model.scripModel.BodLotQuantity * model.scripModel.Multiplier)
                : ((model.SellAvgPrc - model.BuyAvgPrc) * model.NetSellQty * model.GDGNPDPN * model.scripModel.Multiplier);
            model.UnRealisedPL = Utility.IsLot(model.scripModel.ExchangeName) ? ((model.scripModel.LastTradePrice - model.BuyAvgPrc) * (model.NetBuyQty - model.NetSellQty)
                * model.GDGNPDPN * model.scripModel.BodLotQuantity * model.scripModel.Multiplier) :
                (model.scripModel.LastTradePrice - model.BuyAvgPrc) * (model.NetBuyQty - model.NetSellQty) * model.GDGNPDPN * model.scripModel.Multiplier;
        }
        else if (model.NetBuyQty <= model.NetSellQty) {
            model.RealisedPL = Utility.IsLot(model.scripModel.ExchangeName) ?
                ((model.SellAvgPrc - model.BuyAvgPrc) * model.NetBuyQty * model.GDGNPDPN
                    * model.scripModel.BodLotQuantity * model.scripModel.Multiplier) :
                ((model.SellAvgPrc - model.BuyAvgPrc) * model.NetBuyQty * model.GDGNPDPN * model.scripModel.Multiplier);
            model.UnRealisedPL = Utility.IsLot(model.scripModel.ExchangeName) ?
                ((model.scripModel.LastTradePrice - model.SellAvgPrc) * (model.NetBuyQty - model.NetSellQty)
                    * model.GDGNPDPN * model.scripModel.BodLotQuantity * model.scripModel.Multiplier)
                : (model.scripModel.LastTradePrice - model.SellAvgPrc) *
                (model.NetBuyQty - model.NetSellQty) * model.GDGNPDPN * model.scripModel.Multiplier;
        }
        else {
            model.RealisedPL = 0;
            model.UnRealisedPL = 0;
        }
        model.MtoM = model.RealisedPL + model.UnRealisedPL;
        bookService.positions.totalURPL += model.UnRealisedPL - urpl;
        bookService.positions.totalRPL += model.RealisedPL - rpl;
        bookService.positions.totalMtoM = bookService.positions.totalURPL + bookService.positions.totalRPL;
    }

    getAllHoldings(callback: Function, precallback?: Function) {
        let tempHoldings = new Holdings();
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const userParameter = JSON.parse(Preferences.getPreference('userParameter'));
        const userId = Preferences.getPreference('userid');
        const jsonSendObj = {
            'uid': userId,
            'acctid': userParameter['sAccountId'],
            'brkname': userParameter['brkname'],
            's_prdt_ali': userParameter['s_prdt_ali'],
            'Pcode': Utility.PRODUCT_CNC
        };
        const cachedKey = Url.HOLDING + '_' + userId + '_' + userParameter['brkname'];
        const predata = Utility.CACHE_STORE.getCacheData(cachedKey);
        if (predata !== undefined) {
            precallback(predata);
        }
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.HOLDING, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            bookService.holdings.TotalDayPNL = 0;
            const stat = res['stat'];
            const scripTempList = [];
            bookService.holdings.HoldingsModel = [];
            bookService.holdings.TotalValue = 0;
            if (stat === 'Ok') {
                const holdingData = res['HoldingVal'];
                const Totalval = res['Totalval'];
                const clientid = res['clientid'];
                bookService.holdings.TotalMCXHoldingValue = parseFloat(Totalval['TotalMCXHoldingValue']);
                bookService.holdings.TotalCSEHoldingValue = parseFloat(Totalval['TotalCSEHoldingValue']);
                bookService.holdings.TotalBSEHoldingValue = parseFloat(Totalval['TotalBSEHoldingValue']);
                bookService.holdings.TotalNSEHoldingValue = parseFloat(Totalval['TotalNSEHoldingValue']);
                bookService.holdings.TotalYSXHoldingValue = parseFloat(Totalval['TotalYSXHoldingValue']);
                for (let i = 0; i < holdingData.length; i++) {
                    const obj = holdingData[i];
                    if ((obj.Nsetsym && obj.Nsetsym !== '0') || (obj.Bsetsym && obj.Bsetsym !== '0')) {
                        const bean = new HoldingModel();
                        bean.Symbol = (holdingData[i].Nsetsym && holdingData[i].Nsetsym !== '0') ? holdingData[i].Nsetsym : holdingData[i].Bsetsym;
                        bean.ExchangeSeg = (holdingData[i].Nsetsym && holdingData[i].Nsetsym !== '0') ? holdingData[i].Exch1 : holdingData[i].Exch2;
                        bean.Exchange = (holdingData[i].Nsetsym && holdingData[i].Nsetsym !== '0') ? holdingData[i].ExchSeg1 : holdingData[i].ExchSeg2;
                        bean.Token = (holdingData[i].Nsetsym && holdingData[i].Nsetsym !== '0') ? holdingData[i].Token1 : holdingData[i].Token2;
                        bean.Ltp = (holdingData[i].Nsetsym && holdingData[i].Nsetsym !== '0') ? parseInt(holdingData[i].LTnse, 10) : parseInt(holdingData[i].LTbse, 10);
                        bean.WHqty = parseInt(obj['WHqty'], 10);
                        bean.SellableQty = parseInt(obj['SellableQty'], 10);
                        bean.CUqty = parseInt(obj['CUqty'], 10);
                        bean.hsflag = obj['CUqty'];
                        bean.CSEHOldingValue = parseFloat(obj['CSEHOldingValue']);
                        bean.Token5 = obj['Token5'];
                        bean.Btst = parseInt(obj['Btst'], 10);
                        bean.Token3 = obj['Token3'];
                        bean.Token4 = obj['Token4'];
                        bean.Token2 = obj['Token2'];
                        bean.Token1 = obj['Token1'];
                        bean.WCqty = parseInt(obj['WCqty'], 10);
                        bean.DaysMTM = parseInt(obj['DaysMTM'], 10);
                        bean.LTnse = parseFloat(obj['LTnse']);
                        bean.Ttrind = obj['Ttrind'];
                        bean.Mcxsxcmsym = obj['Mcxsxcmsym'];
                        bean.Price = parseFloat(obj['Price']);
                        bean.Haircut = obj['Haircut'];
                        bean.Bsetsym = obj['Bsetsym'];
                        bean.Series = obj['Series'];
                        bean.Series1 = obj['Series1'];
                        bean.Usedqty = parseInt(obj['Usedqty'], 10);
                        bean.HUqty = parseInt(obj['HUqty'], 10);
                        bean.Tprod = obj['Tprod'];
                        bean.Scripcode = obj['Scripcode'];
                        bean.NSEHoldingValue = parseFloat(obj['NSEHOldingValue']);
                        bean.Nsetsym = obj['Nsetsym'] !== 0 && obj['Nsetsym'] !== null ? obj['Nsetsym'] : obj['Bsetsym'];
                        bean.Csetsym = obj['Csetsym'];
                        bean.Colqty = parseInt(obj['Colqty'], 10);
                        bean.Exch1 = obj['ExchSeg1'];
                        bean.Exch2 = obj['ExchSeg2'];
                        bean.Exch3 = obj['ExchSeg3'];
                        bean.Exch4 = obj['ExchSeg4'];
                        bean.Exch5 = obj['ExchSeg5'];
                        const avlQty = (Number(obj['Holdqty']) + Number(obj['Btst']) - Number(obj['Usedqty']));
                        this.setAvailableQty(obj['Exch1'], obj['Token1'], avlQty);
                        this.setAvailableQty(obj['Exch2'], obj['Token2'], avlQty);
                        this.setAvailableQty(obj['Exch3'], obj['Token3'], avlQty);
                        this.setAvailableQty(obj['Exch4'], obj['Token4'], avlQty);
                        this.setAvailableQty(obj['Exch5'], obj['Token5'], avlQty);
                        bean.csflag = obj['csflag'];
                        bean.BSEHoldingValue = parseFloat(obj['BSEHOldingValue']);
                        bean.Ysxtsym = obj['Ysxtsym'];
                        bean.LTmcxsxcm = parseFloat(obj['LTmcxsxcm']);
                        bean.Coltype = obj['Coltype'];
                        bean.LTPValuation = parseInt(obj['LTPValuation'], 10);
                        bean.ExchSeg4 = obj['Exch4'];
                        bean.ExchSeg3 = obj['Exch3'];
                        bean.ExchSeg2 = obj['Exch2'];
                        bean.ExchSeg1 = obj['Exch1'];
                        bean.ExchSeg5 = obj['Exch5'];
                        bean.YSXHoldingValue = parseFloat(obj['YSXHOldingValue']);
                        bean.LTbse = parseFloat(obj['LTbse']);
                        bean.Holdqty = parseInt(obj['Holdqty'], 10);
                        bean.LTysx = parseFloat(obj['LTysx']);
                        bean.LTcse = parseFloat(obj['LTcse']);
                        bean.MCXHoldingValue = parseFloat(obj['MCXHOldingValue']);
                        bean.isin = obj['isin'];
                        bean.Pcode = obj['Pcode'];
                        bean.Clientid = clientid;
                        bean.MaturityDate = '-';
                        bean.IssueDate = '-';
                        bean.Settlements = '-';
                        bean.DecimalPrecision = obj['Ltp'].toString().split('.')[1].length;
                        bean.PriceDisplay = obj['Price'] === '' ? '---' : obj['Price'].toString();
                        bean.isin = obj['isin'];
                        bean.TotalHoldingQty = bean.Holdqty + bean.HUqty + bean.WHqty + bean.Btst;
                        bean.AvailableQty = bean.TotalHoldingQty - bean.Usedqty;
                        if (!(bean.ExchSeg1 === '0') && !(bean.Token1 === '0')) {
                            scripTempList.push(bean.ExchSeg1 + '|' + bean.Token1);
                            bean.PrimaryLTP = bean.LTnse;
                            bean.PrimaryLTPStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryLTP, bean.DecimalPrecision);
                            bean.PrimaryValuation = bean.NSEHoldingValue;
                            bean.PrimaryValuationStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryValuation, bean.DecimalPrecision);
                            bookService.holdings.TotalValue += bean.NSEHoldingValue;
                        }
                        else if (!(bean.ExchSeg2 === '0') && !(bean.Token2 === '0')) {
                            scripTempList.push(bean.ExchSeg2 + '|' + bean.Token2);
                            bean.PrimaryLTP = bean.LTbse;
                            bean.PrimaryLTPStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryLTP, bean.DecimalPrecision);
                            bean.PrimaryValuation = bean.BSEHoldingValue;
                            bean.PrimaryValuationStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryValuation, bean.DecimalPrecision);
                            bookService.holdings.TotalValue += bean.BSEHoldingValue;
                        }
                        else if (!(bean.ExchSeg3 === '0') && !(bean.Token3 === '0')) {
                            scripTempList.push(bean.ExchSeg3 + '|' + bean.ExchSeg3);
                            bean.PrimaryLTP = bean.LTmcxsxcm;
                            bean.PrimaryLTPStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryLTP, bean.DecimalPrecision);
                            bean.PrimaryValuation = bean.MCXHoldingValue;
                            bean.PrimaryValuationStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryValuation, bean.DecimalPrecision);
                            bookService.holdings.TotalValue += bean.MCXHoldingValue;
                        }
                        else if (!(bean.ExchSeg4 === '0') && !(bean.Token4 === '0')) {
                            scripTempList.push(bean.ExchSeg4 + '|' + bean.ExchSeg4);
                            bean.PrimaryLTP = bean.LTcse;
                            bean.PrimaryLTPStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryLTP, bean.DecimalPrecision);
                            bean.PrimaryValuation = bean.CSEHOldingValue;
                            bean.PrimaryValuationStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryValuation, bean.DecimalPrecision);
                            bookService.holdings.TotalValue += bean.CSEHOldingValue;
                        }
                        else if (!(bean.ExchSeg5 === '0') && !(bean.Token5 === '0')) {
                            scripTempList.push(bean.ExchSeg5 + '|' + bean.Token5);
                            bean.PrimaryLTP = bean.LTysx;
                            bean.PrimaryLTPStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryLTP, bean.DecimalPrecision);
                            bean.PrimaryValuation = bean.YSXHoldingValue;
                            bean.PrimaryValuationStr = Utility.formatNumberToDecimalPrecision(bean.PrimaryValuation, bean.DecimalPrecision);
                            bookService.holdings.TotalValue += bean.YSXHoldingValue;
                        }
                        bookService.holdings.HoldingsModel.push(bean);
                    }
                }
                tempHoldings = bookService.holdings;
                Utility.CACHE_STORE.setCacheData(cachedKey, tempHoldings);
                message = 'Ok';
                const count = scripTempList.length;
                if (count > 0) {
                    const scripListObj = {
                        'uid': userId,
                        'tomcatCount': Preferences.getPreference('tomcatcount'),
                        'scripList': scripTempList.toString()
                    };
                    bookService.tradingRestService.getScripService().getMultipleScrips(scripListObj, (data, resCode, resMessage) => {
                        if (resCode === 200 && resMessage === 'Ok') {
                            const scripListMap = new Map();
                            data.forEach(element => {
                                const scripKey = element.Token + element.ExchangeSegment;
                                if (!scripListMap.has(scripKey)) {
                                    scripListMap.set(scripKey, element);
                                }
                            });
                            for (let j = 0; j < count; j++) {
                                const hModel = bookService.holdings.HoldingsModel[j];
                                let key = '';
                                if (!(hModel.ExchSeg1 === '0') && !(hModel.Token1 === '0')) {
                                    key = hModel.Token1 + hModel.ExchSeg1;
                                }
                                else if (!(hModel.ExchSeg2 === '0') && !(hModel.Token2 === '0')) {
                                    key = hModel.Token2 + hModel.ExchSeg2;
                                }
                                else if (!(hModel.ExchSeg3 === '0') && !(hModel.Token3 === '0')) {
                                    key = hModel.Token3 + hModel.ExchSeg3;
                                }
                                else if (!(hModel.ExchSeg4 === '0') && !(hModel.Token4 === '0')) {
                                    key = hModel.Token4 + hModel.ExchSeg4;
                                }
                                else if (!(hModel.ExchSeg5 === '0') && !(hModel.Token5 === '0')) {
                                    key = hModel.Token5 + hModel.ExchSeg5;
                                }
                                const sModel = scripListMap.get(key);
                                hModel.DayPNL = (sModel.Change * hModel.AvailableQty) === 0 ? 0 : (sModel.Change * hModel.AvailableQty);
                                bookService.holdings.TotalDayPNL += hModel.DayPNL;
                                hModel.DayPNLStr = Utility.formatNumberToDecimalPrecision(hModel.DayPNL, hModel.DecimalPrecision);
                                hModel.holdingModelChanged = this.calculateHoldingFields;
                                hModel.setScripModel(sModel);
                            }
                            bookService.holdings.TotalDayPNLStr = Utility.formatNumberToDecimalPrecision(bookService.holdings.TotalDayPNL, 2);
                            bookService.holdings.TotalValueStr = Utility.formatNumberToDecimalPrecision(bookService.holdings.TotalValue, 2);
                        }
                    });
                }
            }
            else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback(tempHoldings, code, message);
        });
    }

    calculateHoldingFields(hm: HoldingModel, holdings: Holdings = bookService.holdings) {
        let valuation = 0, daypnl = 0;
        valuation = hm.PrimaryValuation;
        daypnl = hm.DayPNL;
        hm.PrimaryValuation = hm.AvailableQty * hm.PrimaryLTP;
        hm.DayPNL = hm.scripModel.Change * hm.AvailableQty;
        bookService.holdings.TotalDayPNL += hm.DayPNL - daypnl;
        bookService.holdings.TotalValue += hm.PrimaryValuation - valuation;
    }
    setAvailableQty(exchangeSegment, token, availableQty) {
        if (exchangeSegment !== '' && exchangeSegment != null && exchangeSegment !== '0' && token !== '' && token !== null && token !== '0') {
            this.availableQtyQtyMap.set(exchangeSegment + '_' + token, availableQty);
        }
    }

    GetOrderHistory(nestOrderNo, callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const orderHistoryList = new Array<OrderHistoryModel>();
        const userId = Preferences.getPreference('userid');
        const cachedKey = Url.ORDERHISTORY_URL + '_' + nestOrderNo + '_' + userId;
        const predata = Utility.CACHE_STORE.getCacheData(cachedKey);
        if (predata !== undefined) {
            precallback(predata);
        }
        const sendJson = {
            'uid': userId,
            's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali'],
            'NOrdNo': nestOrderNo
        };
        const param = Utility.prepareParameters(JSON.stringify(sendJson), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.ORDERHISTORY_URL, param).subscribe(res => {
            const stat = res[0]['stat'];
            if (stat === 'Ok') {
                const resData = JSON.parse(JSON.stringify(res));
                resData.forEach(element => {
                    const orderHistoryBean = new OrderHistoryModel();
                    orderHistoryBean.TradingSymbol = element['Trsym'];
                    orderHistoryBean.Qty = element['Qty'];
                    orderHistoryBean.PendingQuantity = element['unfilledSize'];
                    orderHistoryBean.Prc = Number(element['Prc']).toFixed(2);
                    orderHistoryBean.Action = element['Action'];
                    orderHistoryBean.Type = element['Ordtype'];
                    orderHistoryBean.rejectionreason = element['rejectionreason'] === '--' ? '' : element['rejectionreason'];
                    orderHistoryBean.AveragePrice = Number(element['averageprice']).toFixed(2);
                    orderHistoryBean.Status = element['Status'];
                    orderHistoryBean.ExchTimeStamp = element['ExchTimeStamp'];
                    orderHistoryBean.FilledSharesQuantity = element['filledShares'];
                    orderHistoryList.push(orderHistoryBean);
                });
                Utility.CACHE_STORE.setCacheData(cachedKey, orderHistoryList);
                message = Keys.RES_MSG_OK;
            }
            else {
                message = 'Not Ok';
                code = Keys.RES_CODE_FAILURE;
            }
            callback(orderHistoryList, code, message);
        });
    }

    CancelOrder(obj: OrderModel, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let response = {};
        const jsonSendObj = {
            'uid': Preferences.getPreference('userid'), 'NestOrd': obj.NestOrd, 'sTradeSymbol': obj.TradeSymbol, 'sExch': obj.Exchange,
            's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali'], 'orderSource': obj.ordersource
        };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.CANCEL_OPEN_ORDER, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            const stat = res['stat'];
            if (stat === 'Ok') {
                response = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(response, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(response, code, message);
            });
    }

    ExitOrder(obj: OrderModel, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let response = {};
        const jsonSendObj = {
            'uid': Preferences.getPreference('userid'), 'NestOrd': obj.NestOrd,
            's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali']
        };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.EXIT_ORDER, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            const stat = res['stat'];
            const accountId = JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'];
            if (stat === 'Ok') {
                response = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(response, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(response, code, message);
            });
    }

    PositionConvertion(productType, tradeDetailObj, callback: Function) {
        const sendJsonObj = {
            'Nstordno': tradeDetailObj.NestOrd,
            'Transtype': tradeDetailObj.TranType,
            'FillID': tradeDetailObj.FillId,
            'Symbol': tradeDetailObj.Symbol,
            'Exchange': tradeDetailObj.Exchange,
            'Pcode': tradeDetailObj.Pcode,
            'Fillqty': tradeDetailObj.FillQty.toString(),
            'Price': tradeDetailObj.Price.toString(),
            'Custofrm': tradeDetailObj.CustOFrm,
            'Prctype': tradeDetailObj.PrcType,
            'Ordduration': tradeDetailObj.OrdDuration,
            'NOReqID': tradeDetailObj.NOReqID,
            'Fillleg': tradeDetailObj.FillLeg.toString(),
            'Filledqty': tradeDetailObj.FilledQty.toString(),
            'Filldate': tradeDetailObj.FillDate,
            'Filltime': tradeDetailObj.FillTime,
            'Qty': tradeDetailObj.Qty.toString(),
            'Tsym': tradeDetailObj.TSym,
            'Pchange': productType,
            's_prdt_ali': bookService.s_prdt_ali,
            'uid': bookService.uid
        };
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const param = Utility.prepareParameters(JSON.stringify(sendJsonObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.POSITIONCONVERTION_URL, param).subscribe(res => {
            if (res['stat'] === 'Ok') {
                message = Keys.RES_MSG_OK;
            }
            else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback(code, message);
        });
    }

    PartialPositionConvertion(productType, positionType, transactionType, qunatity, obj, callback) {
        const sendJsonObj = {
            'uid': bookService.uid,
            'actid': bookService.actId,
            'branchid': bookService.userParameter['brnchid'],
            'brokname': bookService.userParameter['brkname'],
            's_prdt_ali': bookService.s_prdt_ali,
            'Pcode': obj.Pcode,
            'Ptocode': productType,
            'Exchange': obj.Exchange,
            'Qty': qunatity.toString(),
            'Tsym': obj.Symbol,
            'Transtype': transactionType,
            'Token': obj.Token,
            'Type': obj.Type
        };

        if (positionType === 'D') {
            sendJsonObj['Buyavgprice'] = obj.Buyavgprc;
            sendJsonObj['Sellavgprice'] = obj.Sellavgprc;
        }
        else {
            sendJsonObj['Buyavgprice'] = obj.CFNetBuyAvgPrc;
            sendJsonObj['Sellavgprice'] = obj.CFNetSellAvgPrc;
        }
        sendJsonObj['Type'] = positionType;
        let result = '';
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const param = Utility.prepareParameters(JSON.stringify(sendJsonObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.PARTIAL_POSITIONCONVERTION_URL, param).subscribe(res => {
            if (res['stat'] === 'Ok') {
                message = Keys.RES_MSG_OK;
                result = res['Result'];
            }
            else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                result = message;
            }
            callback(result, code, message);
        });
    }
}
