import { ScripModel } from './scrip.model';

export class TradeModel {
    NestOrd: string;
    Exseg: string;
    ExchangeSeg: string;
    Exchange: string;
    CustOFrm: string;
    OrdDuration: string;
    FillTime: string;
    Qty: number;
    Time: string;
    PrcType: string;
    FillDate: string;
    NOReqID: string;
    ExchTime: string;
    FillLeg: number;
    TSym: string;
    FillId: string;
    FilledQty: number;
    PosFlag: string;
    Symbol: string;
    TranType: string;
    ExchordID: string;
    Pcode: string;
    PcodeDisplay: string;
    TranTypeDisplay: string;
    FillDateTime: string;
    DecimalPrecision: any;
    ExpiryDate: string;
    CompanyName: string;
    AvgPrice: number;
    AccountId: string;
    BrokerClient: string;
    Stat: string;
    Price: number;
    Minqty: number;
    User: string;
    FillQty: any;
    SymbolName: string;
    Token: string;
    DisplaySymbolDetail: string;
    opType: string;
    stkPrc: string;
    scripModel: ScripModel;
}
