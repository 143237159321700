<mat-progress-bar *ngIf="isLoad"  mode="indeterminate"></mat-progress-bar>
<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<div class="titlebar">
    <div class="d-flex">
        <div class="mr-auto">
            <button mat-icon-button class="sidenav-trigger btn-primary"  #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i class="icon-leftnav"></i></button>
            <button class="homebtn" mat-icon-button (click)="loadWorkSpace(0)" [class.activeme]="utility.selectedWorkspace==0"><i class="icon-home"></i></button>
            <ng-container *ngFor = "let layout of utility?.WorkspaceArr; let i = index ">
                <button mat-flat-button *ngIf = "i !== 0" (click)="loadWorkSpace(layout,false)" class="closetab" [class.activeme]="utility.selectedWorkspace==layout">
                    <span>{{i}}<a (click) = "deleteWorkspace(layout,false)" class="close"><i class="icon-close"></i></a></span>
                </button>
            </ng-container>

            <button mat-flat-button class="closetab" *ngFor = "let layout of utility.StockDetailArrs; let i = index " (click)="loadWorkSpace(layout, true)" [class.activeme]="utility.selectedWorkspace==layout">
                <span>{{utility.StockKeyMap[layout].symbol }}
                <a class="close" (click) = "deleteWorkspace(layout, true)"><i class="icon-close"></i></a></span>
            </button>
            <button mat-icon-button (click)="addWorkspace()"><i class="icon-plus"></i></button>
            <!-- <button class="iconbutton activeme">ADANIPORTS-EQ <a class="close"><i class="icon-close"></i></a></button> -->
        </div>
        <div>
            <!--button mat-icon-button (click) = "fullScreen()"><i class="icon-fullscreen"></i></button-->
            <span *ngIf="contactList.length > 0"><a href="tel:+91{{contactList[0]}}"><button mat-icon-button><i class="icon-callus"></i></button></a></span>
            <button mat-icon-button class="imnegative" [matTooltip]="((utility.SYNC == 2) ? 'settings.save_pro' : 'settings.save_work') | translate " [disabled]="utility.SYNC == 0" [class.imnegative]="utility.SYNC == 1" [class.impositive]="utility.SYNC == 0" (click) = "syncMe()">
                <svg viewBox="0 0 100 100"><path d="M0,0H100V100H0Z" class="nofillsvg"></path><polygon points="50 8.33 15.88 42.46 35.05 42.46 35.05 72.21 64.52 72.21 64.52 42.17 84.12 42.17 50 8.33" class="ani-syncicon"></polygon><rect x="15.6" y="82.22" width="68.81" height="9.45"></rect></svg>
            </button>
            <button mat-icon-button (click) = "openSearch(true,1)"><i class="icon-search"></i></button>
            <!-- <button *ngIf = "betaFeature" mat-icon-button (click) = "openPopupsPage('alert')"><i class="icon-noti"></i></button> -->
            <button *ngIf="isNotificationEnabled" mat-flat-button (click) = "getFcmNotification()">
                <i class="icon-noti"></i>
                <em *ngIf = "badgeIconHidden" class="notibubble">{{notificationNumber}}</em>
            </button>
            <button mat-flat-button [matMenuTriggerFor]="profileMenu"  class="profilepic">
                <img alt="user" src="assets/img/user.svg" />
                <span>{{loginId}}</span>
            </button>
            <mat-menu class="sameer" #profileMenu="matMenu">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                        <div class="padding-all float-left border-b">
                            <div class="float-left mr-2">
                                <img alt="user" src="assets/img/user.svg" width="60px" />
                            </div>
                            <div class="float-left tc-1">
                                <div><small class="tc-4" translate>settings.client_name</small> <span class="text-uppercase">{{actName}}</span></div>
                                <div><small class="tc-4" translate>settings.login_id</small> <span class="text-uppercase">{{loginId}} </span></div>
                                <div><small class="tc-4" translate>settings.acc_id</small> <span class="text-uppercase">{{accountId}} </span></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button mat-menu-item (click) = "openPopupsPage('profile')" translate>header.my_profile</button>
                            <button mat-menu-item (click) = "openPopupsPage('changepwd')" translate>header.change_password</button>
                            <!-- <button *ngIf = "isIAM" mat-menu-item (click) = "openPopupsPage('changempin')" translate>header.change_mpin</button> -->
                            <button *ngIf="false" mat-menu-item (click) = "openPopupsPage('logs')" translate>header.user_logs</button>
                            <button mat-menu-item (click) = "openPopupsPage('lotweight')" translate>header.lot_weight</button>
                            <button mat-menu-item (click) = "openPopupsPage('keyboardshortcuts')" translate>header.keyboard_shortcuts</button>
                            <button mat-menu-item (click) = "openPopupsPage('generalsettings')" translate>header.settings <i class="icon-setting right-navicon "></i></button>
                            <button mat-menu-item (click) = "openPopups('about')" translate>login.about</button>
                            <button mat-menu-item (click) = "openPopups('terms')" translate>login.t_c</button>
                            <button mat-menu-item (click) = "openPopups('privacy')" translate>login.policies</button> 
                            <button mat-menu-item (click) = "openPopups('contact')" translate>login.contact_us</button>
                            <!-- <button mat-menu-item (click) = "openPopupsPage('userinfo')" translate>header.update_info</button> -->
                            <!-- <button mat-menu-item (click) = "openInNewTab('feedback', 2)" translate>header.feedback</button> -->
                            <button *ngIf = "backOfficeType != 0" mat-menu-item (click) = "openBackOffice()">Back Office</button>
                            <button *ngIf = "ticketSupport" mat-menu-item (click) = "openTicket()" translate>header.raise_ticket</button>
                            <button mat-menu-item translate (click) = "onLogout()">header.logout</button> 
                        </div>
                    </div>
                    <div class="row" *ngIf="contactList.length > 0">
                        <div class="col-12 callme">
                            <span translate>header.call_us</span>
                            <a *ngFor="let contact of contactList" href="tel:+91{{contact}}">+91 {{contact}}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row versionnav">
                                <div class="col-8 pt-1">{{'header.version' | translate}} {{version}}</div>
                                <div class="col-4 text-right" *ngIf = "isUpdateAvailable"><button class="btn-positive" (click)="checkUpdate()" translate>portfolio.update</button></div>
                            </div>
                    </div>
                    </div>
                    <div class="row" *ngIf="Pwa.promptEvent">
                        <div class="col-12">
                            <div class="row versionnav">
                                <div class="col-7 pt-1" translate>header.install_msz</div>
                                <div class="col-5 text-right"><button class="btn-plain" (click)="installApp()" translate><i class="icon-refresh primetext"></i> <span class="primetext" translate> header.install</span></button></div>
                            </div>
                    </div>
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>
</div>
<app-banner [isBannerClose] = "true" [isLogin] = "false"></app-banner>
<div class="" *ngxCacheIf="overlaysearch || overlayMWL || overlayadd || overlaywithdraw || overlaytrans || overlayManageBooks || overlayBasketDetail || overlayUserBasket">
    <div *ngxCacheIf = "overlaysearch" class="maskoverlay" (click)="closeSearch()"></div>
    <div *ngxCacheIf = "overlayMWL" class="maskoverlay" (click)="closeMWL()"></div>
    <div *ngxCacheIf = "overlayadd" class="maskoverlay" (click)="closeFunds()"></div>
    <div *ngxCacheIf = "overlaywithdraw" class="maskoverlay" (click)="closeFunds()"></div>
    <div *ngxCacheIf = "overlaytrans" class="maskoverlay" (click)="closeFunds()"></div>
    <div *ngxCacheIf = "overlayNotification" style="opacity: 0;" class="maskoverlay" (click)="closeFunds()"></div>
    <div *ngxCacheIf = "overlayManageBooks" class="maskoverlay" (click)="closeManageBooks()"></div>
    <div *ngxCacheIf = "overlayBasketDetail" class="maskoverlay" (click)="closeBasketDetail()" ></div>
    <div *ngxCacheIf = "overlayUserBasket" class="maskoverlay" (click)="closeUserBasket()" ></div>

    <div class="rightpanelwrapper">
        <div *ngxCacheIf = "overlaysearch">
            <app-search [isOpen]="overlaycounter" (closeSearch)="closeSearch()" [fromNews] = "fromNews" [formTopSearch]="formTopSearch" [isfromMWLSearch] = "isfromMWLSearch" [formWL]="formWL" [wlnameSearch] = "wlName" (selectData)="selectData($event)" [isfromOCSearch] = "isfromOCSearch"></app-search>
        </div>
        <div *ngxCacheIf = "overlayMWL">
            <app-watchlistsettings [isOpen]="overlaycounter" [showManageColumns]="isManageCol" [formCustomDB]="formCustomDB" (addStockToNewWL) = "addStockToNewWL($event)" (selectedLabelChip)="watchlistSelectedChip($event)" (closePopup) = "closePopup()"></app-watchlistsettings>
        </div>
        <div *ngxCacheIf = "overlayadd" class="p-1">
            <app-addfunds (loadLimit) = 'limitLoader($event)' [awlBalance] = "balance" [overlayFrom] = "addFundsFrom" (close) = 'closeFunds()'></app-addfunds>
        </div>
        <div *ngxCacheIf = "overlaywithdraw" class="p-1">
            <app-withdrawfunds [awlBalance] = "balance" (close) = 'closeFunds()'></app-withdrawfunds>
        </div>
        <div *ngxCacheIf = "overlaytrans" class="p-1">
            <app-transactiondetails (loadLimit) = 'limitLoader($event)' (close) = 'closeFunds()'></app-transactiondetails>
        </div>
        <div *ngxCacheIf = "overlayNotification" class="p-1">
            <app-fcm  (close) = 'closeFunds()' [fcmNotificationList] = "fcmNotificationList"></app-fcm>
        </div>
        <div *ngxCacheIf = "overlayManageBooks" class="p-1">
            <app-managebooks [isOpen]="overlaycounter" (closeManagebooks) = 'closeManageBooks()' [pageName] = "manageBooksPageName"></app-managebooks>
        </div>
        <div *ngxCacheIf = "overlayBasketDetail">
            <app-basketdetail [isOpen]="overlaycounter" (closeBasketDetail) = 'closeBasketDetail()'></app-basketdetail>
        </div>
        <div *ngxCacheIf = "overlayUserBasket">
            <app-userbaskethome [isOpen]="overlaycounter" (closeUserBasket) = 'closeUserBasket()'></app-userbaskethome>
        </div>
    </div>
</div>

<!--side navigation-->
<mat-menu #menu="matMenu" id="sidenav" class="nodefaultbg" (closed) = "openMenu()"> 
    <div class="new-sidenav">
        <header (click)="$event.stopPropagation();">
            <div class="logonav">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.8 40.04">
                    <path class="color-1" d="M17.6.4c.1-.2-.2-.5-.5-.4L8.6,2.3c-.2,0-.3.2-.3.4V22.1L17.6,17Z" transform="translate(0 0.02)"/>
                    <path class="color-1" d="M8.4,39c-.1.7.5,1.1,1.2,1L17,38a1.08,1.08,0,0,0,.8-1V21.8L8.4,25.9Z" transform="translate(0 0.02)"/>
                    <path class="color-1" d="M28,26.5l-.1,13a.64.64,0,0,0,.8.5l8-1.9c.3-.1.5-.3.5-.5V21.8Z" transform="translate(0 0.02)"/>
                    <path class="color-2" d="M38.1,15c-3.1,1.6-6.3,3.3-9.5,4.9.5-2.9.9-5.8,1.4-8.7L0,28s24.4-9.9,24.1-10c-.6,3.5-1.1,6.8-1.7,10.2,5.9-3.1,11.8-6.1,17.7-9.1L42.2,23c1-3.1,1.9-6.1,2.8-9.2-3-.9-5.9-1.8-9-2.8C36.7,12.4,37.4,13.7,38.1,15Z" transform="translate(0 0.02)"/>
                    <path class="color-2" d="M100.6,13.5A9.51,9.51,0,0,0,95,12.1H88.5V31.5h4.2V24.7h2.5a8.46,8.46,0,0,0,5.4-1.6,5.81,5.81,0,0,0,2.1-4.9C102.7,16,102,14.4,100.6,13.5Zm-5.5,7.9H92.7V15.2h2.2a5.13,5.13,0,0,1,3,.7,2.46,2.46,0,0,1,1,2.2C98.9,20.3,97.6,21.4,95.1,21.4Z" transform="translate(0 0.02)"/>
                    <polygon class="color-2" points="110.4 23.22 117.2 23.22 117.2 19.82 110.4 19.82 110.4 15.52 118.4 15.52 118.4 12.02 106.3 12.02 106.3 31.52 118.7 31.52 118.7 28.12 110.4 28.12 110.4 23.22"/>
                    <path class="color-2" d="M80.3,16.7c-.3.8-.7,1.9-1.2,3.1-.1.2-.2.5-.3.7a1.42,1.42,0,0,0-.2.6h-.1l-1.6-4.3L75,12H70.6l5.9,12.5v7h4.1V24.4L86.5,12H82.2Z" transform="translate(0 0.02)"/>
                    <polygon class="color-2" points="64.3 19.62 57.2 19.62 57.2 12.02 53.1 12.02 53.1 31.52 57.2 31.52 57.2 23.22 64.3 23.22 64.3 31.52 68.4 31.52 68.4 12.02 64.3 12.02 64.3 19.62"/>
                    <polygon class="color-2" points="138.6 15.42 144 15.42 144 31.52 148.1 31.52 148.1 15.42 153.6 15.42 153.6 12.02 138.6 12.02 138.6 15.42"/>
                    <polygon class="color-2" points="214.5 28.12 214.5 23.22 221.3 23.22 221.3 19.82 214.5 19.82 214.5 15.52 222.5 15.52 222.5 12.02 210.4 12.02 210.4 31.52 210.4 31.52 222.8 31.52 222.8 28.12 214.5 28.12"/>
                    <path class="color-2" d="M196.9,12h-5.4V31.5h5.6c2.9,0,5.3-.8,6.9-2.5s2.5-4.1,2.5-7.3-.9-5.6-2.6-7.3S200,12,196.9,12Zm-.1,16.2h-1.2V15.3h1.1a5.77,5.77,0,0,1,4.3,1.5c1,1,1.5,2.6,1.5,4.9C202.5,26,200.6,28.2,196.8,28.2Z" transform="translate(0 0.02)"/>
                    <path class="color-2" d="M170.1,21.5a5.58,5.58,0,0,0,1-3.5,5.3,5.3,0,0,0-2-4.6,9.37,9.37,0,0,0-5.4-1.4h-6.9V31.5h4.1V24.3h2.6l3.9,7.2h4.5l-4.5-7.9A7.05,7.05,0,0,0,170.1,21.5Zm-4.2-1.2a4.65,4.65,0,0,1-2.8.8h-2.4l.1-5.8h2.4a5.06,5.06,0,0,1,2.8.6,2.34,2.34,0,0,1,.9,2.1A2.64,2.64,0,0,1,165.9,20.3Z" transform="translate(0 0.02)"/>
                    <path class="color-2" d="M178.2,12,172,31.5h4.2l1.3-4.9h6.1l1.4,4.9h4.3L183.1,12Zm.2,11.5.5-2c.5-1.6,1-3.8,1.6-6.3h.1l.6,2.2c.5,1.9.8,3.3,1.1,4.1l.5,2Z" transform="translate(0 0.02)"/>
                    <path class="color-2" d="M136,21.5a5.58,5.58,0,0,0,1-3.5,5.3,5.3,0,0,0-2-4.6,9.37,9.37,0,0,0-5.4-1.4h-6.9V31.5h4.1V24.3h2.6l3.9,7.2h4.5l-4.5-7.9A7.05,7.05,0,0,0,136,21.5Zm-4.2-1.2a4.65,4.65,0,0,1-2.8.8h-2.4c.1-.1.1-5.8.1-5.8h2.4a5.06,5.06,0,0,1,2.8.6,2.34,2.34,0,0,1,.9,2.1A2.64,2.64,0,0,1,131.8,20.3Z" transform="translate(0 0.02)"/>
                </svg>
            </div>
            <div class="searchwrapper" (click)="$event.stopPropagation();">
                <input type="search" (input) = "filterData($event.target.value)" [(ngModel)]="inputValue" placeholder="Search"/>
            </div>
        </header>
        <div class="sidenavalert" (click)="$event.stopPropagation();">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0ZM14,14a2,2,0,0,1,4,0V24a2,2,0,0,1-4,0Zm2-4a2,2,0,1,1,2-2A2,2,0,0,1,16,10Z"/></svg>
            <span *ngIf="utility.selectedWorkspace > 5 || utility.selectedWorkspace==0">Drag-Drop below widgets allowed to new workspace. <a (click)="addWorkspace()">ADD NEW</a>. </span>
            <span *ngIf="!(utility.selectedWorkspace > 5 || utility.selectedWorkspace==0)"> Drag-Drop below widgets to this workspace.</span>
        </div>
        <div  class="nav-items">
            <div *ngFor="let groupname of objectKeys(filterComponents)"  >
                <span class="groupname" (click)="$event.stopPropagation();" [class.d-none]="!hmap[groupname] || ((!basketFeature && !isTriggerenable) && groupname == 'Trade') || (!isSpreadOrderenable && groupname == 'Spread Order')">{{groupname}}</span>

                <span  *ngFor="let key of objectVal(filterComponents[groupname])"  [class.pointer-event-none] ="(utility.workspaceMap[utility.selectedWorkspace]?.includes(key.val)) && !(key.val == 'Buy Order' || key.val == 'Sell Order')" [class.d-none]='key.cls===0 || (!basketFeature && key.val==="Investment Boxes") || (!isTriggerenable && key.val=="GTT") || (!isSpreadOrderenable && key.val == "Spread Order Book")'>
                    <a class="d-none" [class.d-inline-block]="utility.selectedWorkspace===0 || utility.selectedWorkspace > 5" (click) = "addComponentWorkspaceWithAdd(key.val,$event)">{{key.val | valuepipe}}<em *ngIf="key.val =='General News'|| key.val =='Equity News' || key.val =='Derivative News' || key.val =='Corporate Events'|| key.val =='Stock News'" class="freetrial">Trial</em></a>
                    <a class="d-none" [class.d-inline-block]="utility.selectedWorkspace===1" [class.cursor-move] = "!(key.val == 'Buy Order' || key.val == 'Sell Order')" [class.cursor-pointer] = "(key.val == 'Buy Order' || key.val == 'Sell Order')" (click) = "addComponentWorkspace(key.val,$event)" #dragComponentList1>{{key.val | valuepipe}}<em *ngIf="key.val =='General News'|| key.val =='Equity News' || key.val =='Derivative News' || key.val =='Corporate Events'|| key.val =='Stock News'" class="freetrial">Trial</em></a>
                    <a class="d-none" [class.d-inline-block]="utility.selectedWorkspace===2" [class.cursor-move] = "!(key.val == 'Buy Order' || key.val == 'Sell Order')" [class.cursor-pointer] = "(key.val == 'Buy Order' || key.val == 'Sell Order')" (click) = "addComponentWorkspace(key.val,$event)" #dragComponentList2>{{key.val | valuepipe}}<em *ngIf="key.val =='General News'|| key.val =='Equity News' || key.val =='Derivative News' || key.val =='Corporate Events'|| key.val =='Stock News'" class="freetrial">Trial</em></a>
                    <a class="d-none" [class.d-inline-block]="utility.selectedWorkspace===3" [class.cursor-move] = "!(key.val == 'Buy Order' || key.val == 'Sell Order')" [class.cursor-pointer] = "(key.val == 'Buy Order' || key.val == 'Sell Order')" (click) = "addComponentWorkspace(key.val,$event)" #dragComponentList3>{{key.val | valuepipe}}<em *ngIf="key.val =='General News'|| key.val =='Equity News' || key.val =='Derivative News' || key.val =='Corporate Events'|| key.val =='Stock News'" class="freetrial">Trial</em></a>
                    <a class="d-none" [class.d-inline-block]="utility.selectedWorkspace===4" [class.cursor-move] = "!(key.val == 'Buy Order' || key.val == 'Sell Order')" [class.cursor-pointer] = "(key.val == 'Buy Order' || key.val == 'Sell Order')" (click) = "addComponentWorkspace(key.val,$event)" #dragComponentList4>{{key.val | valuepipe}}<em *ngIf="key.val =='General News'|| key.val =='Equity News' || key.val =='Derivative News' || key.val =='Corporate Events'|| key.val =='Stock News'" class="freetrial">Trial</em></a>
                    <a class="d-none" [class.d-inline-block]="utility.selectedWorkspace===5" [class.cursor-move] = "!(key.val == 'Buy Order' || key.val == 'Sell Order')" [class.cursor-pointer] = "(key.val == 'Buy Order' || key.val == 'Sell Order')" (click) = "addComponentWorkspace(key.val,$event)" #dragComponentList5>{{key.val | valuepipe}}<em *ngIf="key.val =='General News'|| key.val =='Equity News' || key.val =='Derivative News' || key.val =='Corporate Events'|| key.val =='Stock News'" class="freetrial">Trial</em></a>
                </span>
                <div [class.d-none]="!hmap[groupname] || ((!basketFeature && !isTriggerenable) && groupname == 'Trade')" (click)="$event.stopPropagation();" class="separator"></div>
            </div>
        </div>
    </div>
</mat-menu>