<app-header *ngIf="isMobile" goback="1" [title]="'more.market_status' | translate" [isLoading]="isLoading" (reloadPage) = "reloadData()"></app-header>
<mat-card class="webpanelnew">
    <mat-card-content>
        <div [class.pt-0]="!isMobile">
            <div class="row" [class.data-container]="isMobile">
                <div class="col-12">
                    <mat-chip-list>
                        <mat-chip *ngFor="let exchange of exchangeArray" [class.imactive] = "tagText ==  exchange" (click)="getMrktStatus(exchange)">{{exchange}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <ngx-pull-to-refresh
            spinnerColor="#ff0000"
            distanceForRefresh="80"
            [isEnable]="isMobile"
            [targetElement]="targetElement"
            (refresh)="myRefreshEvent($event, 'refresh')"
            (loadMore)="alert('loadmore')"
            class = "loader">
            <ng-container *ngIf="!isLoading && !isNoData" >
                <div class="row">
                    <div class = "subheaderbg" [class.col-12]="numberofcol==1" [class.col-6]="numberofcol==2" [class.col-4]="numberofcol==3" [class.col-3]="numberofcol==4">
                        <h2 class="primetext ts-5 fw-3 p-2">{{tagText}}</h2>
                    </div>
                </div>
                <div class="row"> 
                    <div [class.col-12]="numberofcol==1" [class.col-6]="numberofcol==2" [class.col-4]="numberofcol==3" [class.col-3]="numberofcol==4">     
                        <div class="table-global">             
                            <table mat-table [dataSource]="marketStatusData">
                                <ng-container matColumnDef="type">
                                <th mat-header-cell class="text-left" *matHeaderCellDef>{{'more.market_type' | translate}} </th>
                                <td mat-cell class="text-left" *matCellDef="let element">  {{element.MarketMode === null ? '-' : element.MarketMode}} </td>
                                </ng-container>
                            
                                <ng-container matColumnDef="status">
                                <th mat-header-cell class="text-left" *matHeaderCellDef>{{'more.market_status' | translate}} </th>
                                <td mat-cell class="text-left" *matCellDef="let element">{{element.Status === '' ? '-' : element.Status}}</td>
                                </ng-container>
                            
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div> 
                </div>
            </ng-container>
            <ng-container *ngIf='!isLoading && isNoData' class='nodata'>
                <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
            </ng-container>
        </ngx-pull-to-refresh>
        </div> 
    </mat-card-content>
</mat-card>