import { Injectable, ɵConsole } from '@angular/core';
import { ILoginService } from './ilogin.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { Utility } from '../utility';
import { Preferences } from '../Preferences';
import { TradingRestService } from '../tradingrest.service';

@Injectable()
export class LoginService implements ILoginService {
    apiService: ApiService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }
    setTradingRestService(tradingRest: TradingRestService) {
    }
    getAllSessions(callback: Function) {
        throw new Error('Method not implemented.');
    }
    clearAllSessions(callback: Function) {
        throw new Error('Method not implemented.');
    }
    ChangeMpin(oldmpin: any, newmpin: any, callback: Function) {
        callback({}, 200, '');
    }
    NewPassword (uid: any, password: any, callback: Function) {
        callback({}, 200, '');
    }
    ForgotMPIN(uid: any, email: any, pan: any, mob: any, callback: Function) {
        callback({}, 200, '');
    }
    ValidateOTP(uid: string, otp: string, requestCode: number, callback: Function) {
        callback({}, 200, '');
    }
    MPinLogin(uId: any, mpin: string, callback: Function) {
        callback({}, 200, '');
    }
    SetMPin(uiD: string, password: string, mpin: string, callback: Function) {
        callback({}, 200, '');
    }
    ForgotUserId(email: any, mobile: any, pan: any, callback: Function) {
        callback({}, 200, '');
    }
    updateUserInfo(uid: any, pwd: any, emailId: any, mobileNo: any, callback: Function) {
        callback({}, 200, '');
    }
    setPassword(uid: any, oldpwd: any, newpwd: any, callback: Function) {
        callback({}, 200, '');
    }

    resendOTP(uID: any, requestCode: number, callback: Function) {
        callback({}, 200, '');
    }
    getInitialKey(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        this.apiService.post(Url.GET_INITIAL_KEY, null).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'OK' || res['stat'] === 'Ok') {
                data = res;
                Preferences.setPreference('tomcatcount', res['tomcatCount']);
                const generateKeys = new JSEncrypt({ default_key_size: Keys.RSA_KEY_SIZE });
                Preferences.setPreference('decryptionKey', generateKeys.getPrivateKey());
                const encryptedPublicKey2 = Utility.encryptBlock(generateKeys.getPublicKey(), atob(res['publicKey']));
                const hash_serverPublicKey1 = CryptoJS.SHA256(atob(res['publicKey']));
                this.getPreAuthenticationKey(encryptedPublicKey2, hash_serverPublicKey1, callback);
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(undefined, code, message);
            });
    }
    getPreAuthenticationKey(encryptedPublicKey2, hash_serverPublicKey1, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const request_params = 'jData=' + encryptedPublicKey2 + '&jKey=' + hash_serverPublicKey1.toString();
        this.apiService.post(Url.GET_PRE_AUTH_KEY, request_params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                Preferences.setPreference('publicKey3', Utility.decrypt(Preferences.getPreference('decryptionKey'), res['publicKey3']));
                data = res;
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    QuickLogin(uId: any, password: any, panordob: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = {
            'uid': uId,
            'pwd': Utility.getSHAEncryptedData(password),
            'panOrdob': Utility.getSHAEncryptedData(panordob),
            'apkVersion': '0', 'imei': '', 'Source': 'WEB'
        };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const request_params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.QUICK_LOGIN, request_params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                Preferences.setPreference('publicKey4', res['sUserToken']);
                Preferences.setPreference('userSessionId', res['userSessionId']);
                Preferences.setPreference('userid', uId);
                data = res;
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                if (res.Emsg !== undefined) {
                    message = res.Emsg;
                } else {
                    message = res.emsg;
                }
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    DefaultLogin(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'uid': Preferences.getPreference('userid') };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.DEFAULT_LOGIN, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            Utility.isLogin = true;
            Preferences.setPreference('userLogIn', '1');
            if (res.hasOwnProperty('jEncResp')) {
                res = JSON.parse(Utility.decrypt(Preferences.getPreference('decryptionKey'), res.jEncResp));
            }
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                Preferences.setPreference('userParameter', JSON.stringify(data));
                Preferences.setPreference('accountId', data['sAccountId']);
                Preferences.setPreference('productAlias', data['s_prdt_ali']);
                Preferences.setPreference('userExchEnabled', res['exarr']);
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    Login2FA(uid: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'uid': uid };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.Login2FA, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                Preferences.setPreference('userid', uid);
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    ValidPwd(uid: any, pwd: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let passwordHash = CryptoJS.SHA256(pwd);
        for (let i = 1; i <= 999; i++) {
            passwordHash = CryptoJS.SHA256(passwordHash);
        }
        const jsonSendObj = { 'uid': uid, 'pwd': passwordHash.toString(), 'ftl': 'N', 'Imei': '192.168.111.85', 'apk': '1.1', 'Source': 'WEB' };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.validPwd, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.hasOwnProperty('jEncResp')) {
                res = JSON.parse(Utility.decrypt(Preferences.getPreference('decryptionKey'), res.jEncResp));
            }
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    checkvalidAns(uid: any, iaArray: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'uid': uid, 'iaArray': iaArray };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.validAns, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.hasOwnProperty('jEncResp')) {
                res = JSON.parse(Utility.decrypt(Preferences.getPreference('decryptionKey'), res.jEncResp));
            }
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                Preferences.setPreference('publicKey4', res['sUserToken']);
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    DeviceInfo(uID:any, callback : Function)
    {
        let code;
        let message = '';
        const jsonSendObj = {
            'uid': uID,
            'devicempinkey': Preferences.getPreference('devicempinkey'),
            'source': Utility.IS_MOBILE ? "Mobile" : "Web",
        };
        this.apiService.iampost(Url.IAM_CHANGE_DEVICE_INFO, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            code = res.code;
            message = res.message;
            callback(res, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            Utility.print(error);
            callback('', code, message);
        });
    }

    UnblockUser(uid: any, email: any, mob: any, pan: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const sendJson = { 'uid': uid, 'email': email, 'pan': pan };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(sendJson), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.UnblockUser, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            Utility.print(res);
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    ForgotPassword(uid: any, email: any, mobileNum: any, pan: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const sendJson = { 'uid': uid, 'email': email, 'pan': pan };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(sendJson), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.ForgotPwd, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            Utility.print(res);
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    DisplayQuestion(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jKey=' + key.toString();
        this.apiService.post(Url.DisplayQuestion, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            message = Keys.RES_MSG_OK;
            callback(res, code, message);

        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    SetImages(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = {};
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.SetImages, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    SaveImage(uId: any, imageIndex: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'uid': uId, 'index': imageIndex };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.SaveImages, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            Utility.print(res);
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    SaveAnswers(uid: any, iaArray: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'uid': uid, 'iaArray': iaArray };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.SaveAns, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    ForgotLoginId(pan: any, email: any, mobile: any, mode: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'panno': pan, 'mobilenumber': mobile, 'emailid': email, 'mode': mode };
        const encjsonSendObj = Utility.encryptBlock(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey3'));
        const key = CryptoJS.SHA256(Preferences.getPreference('publicKey3')).toString();
        const params = 'jData=' + encjsonSendObj + '&jKey=' + key.toString();
        this.apiService.post(Url.ForgotLoginId, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            Utility.print(res);
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                if (res.emsg !== undefined)
                    message = res.emsg;
                else
                    message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    ResetUser(callback: Function) {}

    ChangePassword(uid: any, oldpwd: any, newpwd: any, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let params;
        const sOldPwd = Utility.getSHAEncryptedData(oldpwd);
        const sNewPwd = Utility.getSHAEncryptedData(newpwd);
        const jsonSendObj = {
            'uid': uid, 'sOldPwd': sOldPwd, 'sNewPwd': sNewPwd, 'sOldTPwd': '',
            'sNewTPwd': '', 'sTxFlag': 'NO'
        };
        if (Preferences.getPreference('publicKey4') === 'true')
            params = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        else
            params = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey1'));
        this.apiService.post(Url.ChangePwd, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            Utility.print(res);
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = res.Result;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    verifyLoginOTP(otp: any, callback: Function){
        let res = {};
        let code = 200;
        let message = 'Success';
        callback(res, code, message)
    }

    resendLoginOTP(callback: Function){
        let res = {};
        let code = 200;
        let message = 'Success';
        callback(res, code, message)
    }

}
