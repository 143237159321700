import { Component, Input, OnChanges, OnInit, Injector, ChangeDetectorRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { PtPlusRestService } from '../../../services/ptplusrest.service';
import { Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let technicals : TechnicalsComponent;

@Component({
    selector: 'app-technicals',
    templateUrl: './technicals.component.html',
    styleUrls: ['./technicals.component.scss']
})

export class TechnicalsComponent extends BaseComponentDirective implements OnChanges, OnInit {
    @Input() omtkn: string;
    @Input() exseg: string;
    @Input() isCustomDashboard: boolean;
    @Output() load = new EventEmitter<boolean>();
    @Output() loaderEvent = new EventEmitter<boolean>();
    isMobile;
    targetElement: Element;
    technicalsData;
    isNoData;
    Height = {};
    container;
    modesub: Subscription;
    isReload = false;

    constructor(elRef: ElementRef, private ptPlusRestService: PtPlusRestService, private injector: Injector, private detector: ChangeDetectorRef) {
       super(elRef.nativeElement);
        technicals = this;
        if (!Utility.IS_MOBILE) {
            // this.container = <ComponentContainer>this.injector.get('Container');
        }
        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });
    }

    changeMode(){
        this.Height = {
            'height': (this.container.height - 40) + 'px',
            'overflow': 'auto'
        };
        this.detector.detectChanges();
    }
    ngOnInit() {
        if(this.inputs !== undefined){
            this.exseg = this.inputs.exch;
            this.isCustomDashboard = this.inputs.isCustomDashboard;
            this.omtkn = this.inputs.token;
        }
        this.isMobile = Utility.IS_MOBILE;
        this.targetElement = document.querySelector('html');
        if (this.container) {
            /* this.container.on('resize', _ => {
                this.changeMode();
            });*/
        }
        if (this.isCustomDashboard) {
            this.loadTechnicals();
        }
        if (this.isCustomDashboard) {
            this.changeMode();
        }

        Utility.webReloadComponent.subscribe(data =>
        {
            if(technicals.isReload === false && data === 'Technicals')
            {
                technicals.isReload = true;
                this.loadTechnicals();
            }
        });
    }
    ngOnChanges() {
        this.loadTechnicals();
    }
    loadTechnicals() {
        this.load.emit(true);
        this.loaderEvent.emit(true);
        this.ptPlusRestService.getFundamentalsService().getTechnicalsData(this.omtkn, this.exseg, 'omnisys', 'stock', function(code, data, message) {
            this.load.emit(false);
            this.loaderEvent.emit(false);
            this.isReload = false;
            if (code === 200 && data && Object.keys(data).length > 0) {
                this.isNoData = false;
                this.technicalsData = data;
            }
            else {
                if (!this.technicalsData)
                    this.isNoData = true;
            }
        }.bind(this));
    }

    myRefreshEvent(event: Subject<any>, message: string) {
        setTimeout(() => {
          this.loadTechnicals();
          event.next();
        }, 1000);
      }
      
      alert(message: string) {
        
      }
      setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        this.changeMode();
      }
}
