import { Component, OnInit, EventEmitter, Output, Input, Inject, ChangeDetectorRef, Injector, ElementRef, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { TradingRestService } from '../../../services/tradingrest.service';
import { IndexModel } from '../../../model/index.model';
import { ChannelType, Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from 'src/app/services/tableutility';
import { Subject, Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';

let indexComponent: IndexComponent;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent extends BaseComponentDirective implements OnInit, OnDestroy {
  @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
  targetElement: Element;
  @Output() load = new EventEmitter<boolean>();
  displayedColumns = TableUtility.indexheaderConfig;

  isLoading: boolean;
  isNoData: boolean;
  indexList: Array<IndexModel>;
  @Input() isCustomDashboard = false;
  indexExSegArr = [];
  isMobile;
  tagText;
  Height = {};
  DHeight: {};
  isDHeight = true;
  readonly HEADER_HEIGHT = 48;
  utility: any;
  container;
  modesub: Subscription;
  isReload = false;
  cellview;
  constructor(elRef: ElementRef, private injector: Injector, private detector: ChangeDetectorRef, private tradingRestService: TradingRestService) {
    super(elRef.nativeElement);
    indexComponent = this;
    if (!Utility.IS_MOBILE) {
      // indexComponent.container = <ComponentContainer>indexComponent.injector.get('Container');
    }
    indexComponent.isLoading = true;
    indexComponent.load.emit(indexComponent.isLoading);
    indexComponent.utility = Utility;

    indexComponent.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      indexComponent.changeMode();
  });
}
myRefreshEvent(event: Subject<any>, message: string) {
  setTimeout(() => {
      this.onRefresh();
      event.next();
  }, 1000);
}

alert(message: string) {
  //alert(message);
}
changeMode(){
  indexComponent.container = indexComponent.container === undefined ? Utility.setSize : indexComponent.container;
  indexComponent.Height = {
    'height': (indexComponent.container.height - TableUtility.INHeight) + 'px',
    'overflow': 'auto'
  };
  this.cellview =  (this.container.width < Utility.MinWidth) ? true : false;
  indexComponent.detector.detectChanges();
}

  ngOnInit() {
    if(this.inputs !== undefined)
      this.isCustomDashboard = this.inputs.isCustomDashboard;
    this.targetElement = document.querySelector('html');
    if (indexComponent.container) {
    /*  indexComponent.container.on('resize', _ => {
        indexComponent.changeMode();
      });*/
    }
    indexComponent.isMobile = Utility.IS_MOBILE;
    indexComponent.tradingRestService.getIndexService().getIndexExchange(indexComponent.indexExchangeRes);
    if (indexComponent.isCustomDashboard) {
      indexComponent.changeMode();
    }

    Utility.webReloadComponent.subscribe(data =>
    {
      if(indexComponent.isReload === false && data === 'Top Indices')
      {
        indexComponent.isReload = true;
        this.reloadData();
      }
    });
  }
  onRefresh() {
    if(indexComponent.indexExSegArr.length === 0)
      indexComponent.tradingRestService.getIndexService().getIndexExchange(indexComponent.indexExchangeRes);
    else
      indexComponent.getIndexData(indexComponent.tagText);
  }

  reloadData()
  {
    this.onRefresh();
  }

  indexExchangeRes(data, code, msg) {
    indexComponent.isLoading = false;
    indexComponent.isReload = false;
    indexComponent.load.emit(indexComponent.isLoading);
    if (code === 200) {
      indexComponent.indexExSegArr = data['IndexExchange'];
      indexComponent.getIndexData(indexComponent.indexExSegArr[0]);
    }
    else
        indexComponent.isNoData = true;
  }

  getIndexData(data) {
    indexComponent.tagText = data;
    indexComponent.isLoading = true;
    indexComponent.load.emit(indexComponent.isLoading);
    indexComponent.tradingRestService.getIndexService().getIndexDetails(data, indexComponent.indexDetailRes);
  }

  indexDetailRes(data, code, msg) {
    indexComponent.isLoading = false;
    indexComponent.isReload = false;
    indexComponent.load.emit(indexComponent.isLoading);
    if (code === 200) {
      indexComponent.isNoData = ((msg === 'No Data') ? true : false);
      indexComponent.indexList = data;
    }
    else {
      indexComponent.isNoData = true;
    }
  }

  onTitleClick() {
    this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (Utility.IS_MOBILE_LAND) {
      if(window.scrollY > this.HEADER_HEIGHT && this.isDHeight){
        this.DHeight = {
         'height': 'calc(100vh - 48px)',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };

        this.isDHeight = false;
      }
      else if(window.scrollY < this.HEADER_HEIGHT){
        this.DHeight = {
          'height': '100%',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };
        this.isDHeight = true;
      }
    }
  }

  setBaseVisibility(visible: boolean){
    if(visible){
      setTimeout(() => {
        this.onAppearing();
        this.changeMode();
      }, 50);
    }
    else {
      this.onDisappearing();
    }
  }

  onAppearing() {
    Utility.resumeChannel(this.tradingRestService, ChannelType.Index);
  }

  onDisappearing() {
    Utility.pauseChannel(this.tradingRestService, ChannelType.Index);
  }

  ngOnDestroy(): void {
    this.onDisappearing();
  }
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    this.cellview = (width < Utility.MinWidth) ? true : false;
    Utility.setSize = this.container;
    this.changeMode();
  }
}
