import { Utility } from './utility';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

export class TableUtility {
    static TOUCH_TYPE;
    static WATCHLIST_HEADER_HEIGHT;
    static BOOK_HEADER_HEIGHT;
    static indexheaderConfig = ['Name', 'IndexValue', 'Change', 'PerCng', 'Open', 'Close', 'Low', 'High'];
    static headerConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange', 'CompanyName'];
    static RSheaderConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','R1','R2','R3','S1','S2','S3','PIVOT', 'CompanyName'];
    static vsheaderConfig =['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','vol', 'pltp1', 'pltp2', 'pvol1', 'pvol2', 'CompanyName'];
    static HLheaderConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','High','Low','52 WEEK HIGH','52 WEEK LOW', 'CompanyName'];
    static CktBrkheaderConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','Low Circuit Limit', 'High Circuit Limit', 'CompanyName'];
    static OHLheaderConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','Open', 'High', 'Low', 'CompanyName'];
    static TSdmafiftyheaderConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','50DMA', 'CompanyName',];
    static TSdmatwoheaderConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','200DMA','CompanyName'];
    static TSrsiheaderConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','RSI', 'CompanyName'];
    static TSmacdheaderConfig = ['TradingSymbol', 'LastTradePrice', 'Change', 'PercentageChange','MACD','SIGNAL LINE', 'CompanyName'];
    static marketConfig = ['TradingSymbol', 'BestBuySize', 'BestBuyPrice', 'BestSellPrice', 'BestSellSize',  'LastTradePrice', 'Lasttradedquantity', 'TradeVolume', 'Change', 'PercentageChange', 'OpenPrice', 'PrvClose', 'High', 'Low', 'CompanyName', 'VwapAveragePrice'];
    static triggerSingleConfig = ['TradingSymbol', 'Status', 'TriggerType','LastTradePrice', 'Quantity1', 'Price1','TriggerPrice1'];
    static triggerOcoConfig = ['TradingSymbol', 'Status', 'TriggerType','LastTradePrice', 'Quantity1', 'Price1','TriggerPrice1', 'Quantity2', 'Price2','TriggerPrice2'];
    static qProfileConfiq = ['Name', 'MinimumInvestment', 'trade'];
    static EmandtTransactionConfiq = ['Remark', 'Frequency', 'StartDate', 'Amount'];

    static watchlistPopupConfig = [
        { key: 'TradingSymbol', checked: true, val: 'all.symbol','isDisabled': true},
        { key: 'LastTradePrice', checked: true, val: 'all.ltp','isDisabled': true},
        { key: 'Change', checked: true, val: 'all.change'},
        { key: 'PercentageChange', checked: true, val: 'all.per_change'},
        { key: 'OpenPrice', checked: true, val: 'all.open'},
        { key: 'High', checked: true, val: 'all.high'},
        { key: 'Low', checked: true, val: 'all.low'},
        { key: 'PrvClose', checked: true, val: 'all.close'},
        { key: 'Lasttradedquantity', checked: true, val: 'all.last_traded_qty'},
        { key: 'BestBuyPrice', checked: true, val: 'all.bid_price'},
        { key: 'BestSellPrice', checked: true, val: 'all.ask_price'},
        { key: 'BestBuySize', checked: true, val: 'all.bid_qty'},
        { key: 'BestSellSize', checked: true, val: 'all.ask_qty'},
        { key: 'TradeVolume', checked: true, val: 'all.volume'},
        { key: 'CompanyName', checked: true, val: 'all.company_name'}, 
        { key: 'VwapAveragePrice', checked: true, val: 'all.atp'},
        { key: 'ExpireDate', checked: false, val: 'all.expir_date'},
        { key: 'Strike', checked: false, val: 'all.strike'},
        { key: 'OptionType', checked: false, val: 'all.option_type'},
        { key: 'UpCkt', checked: false, val: 'all.upper_ckt'},
        { key: 'LoCkt', checked: false, val: 'all.lower_ckt'},
        { key: 'Ltt', checked: false, val: 'all.ltt'},
        { key: 'Tbq', checked: false, val: 'all.total_buy_qty'},
        { key: 'Tsq', checked: false, val: 'all.total_sell_qty'},
        { key: 'Series', checked: false, val: 'all.series'},
        { key: 'OI', checked: false, val: 'all.oi'},
        { key: 'LotSize', checked: false, val: 'all.lot_size'}
    ];

    static orderBookColumns = [
        {'key': 'TSym', 'value': 'all.instrument_type', 'isChecked': true, 'isDisabled': true}, 
        {'key': 'ActionDisplay', 'value': 'all.transaction_type', 'isChecked': true, 'isDisabled': true}, 
        {'key': 'ProductCode', 'value': 'all.product', 'isChecked': true}, 
        {'key': 'Qty', 'value': 'portfolio.qty', 'isChecked': true}, 
        {'key': 'LastTradePrice', 'value': 'all.ltp', 'isChecked': true}, 
        {'key': 'Price', 'value': 'all.prc', 'isChecked': true}, 
        {'key': 'DisplayStatus', 'value': 'all.status', 'isChecked': true}, 
        {'key': 'OrderedTime', 'value': 'all.ordertime', 'isChecked': true},
        {'key': 'TriggerPrice', 'value': 'all.trig_price', 'isChecked': false}, 
        {'key': 'OrderType', 'value': 'all.ordertype', 'isChecked': false}, 
        {'key': 'Validity', 'value': 'all.ordervalidity', 'isChecked': false}, 
        {'key': 'DiscQty', 'value': 'all.discqty', 'isChecked': false}
    ];
    static updateOrderBookColumns = new Subject();

    static tradeBookColumns = [
        {'key': 'TSym', 'value': 'all.instrument', 'isChecked': true, 'isDisabled': true}, 
        {'key': 'TranType', 'value': 'all.transaction_type', 'isChecked': true, 'isDisabled': true}, 
        {'key': 'Pcode', 'value': 'all.product', 'isChecked': true}, 
        {'key': 'FilledQty', 'value': 'all.qty', 'isChecked': true}, 
        {'key': 'AvgPrice', 'value': 'all.avgprice', 'isChecked': true}, 
        {'key': 'NestOrd', 'value': 'all.trade_id', 'isChecked': true}, 
        {'key': 'FillDateTime', 'value': 'all.trade_time', 'isChecked': true}, 
    ];
    static updateTradeBookColumns = new Subject();
    static positionBookColumns = [
        {'key': 'Tsym', 'value': 'all.instrument', 'isChecked': true, 'isDisabled': true}, 
        {'key': 'Pcode', 'value': 'all.product', 'isChecked': true}, 
        {'key': 'Netqty', 'value': 'all.net_qty', 'isChecked': true}, 
        {'key': 'AvgPrc', 'value': 'all.avgprice', 'isChecked': true}, 
        {'key': 'LastTradePrice', 'value': 'all.ltp', 'isChecked': true}, 
        {'key': 'MtoM', 'value': 'all.mtom', 'isChecked': true}, 
        {'key': 'PNLPercentageChg', 'value': 'all.return_per_change', 'isChecked': true},
        {'key': 'Netbuyamt', 'value': 'all.net_buy_val', 'isChecked': false}, 
        {'key': 'Netsellamt', 'value': 'all.net_sell_val', 'isChecked': false}, 
        {'key': 'Netamt', 'value': 'all.net_amt', 'isChecked': false}, 
        {'key': 'AvgBuyPrice', 'value': 'all.avg_buy_price', 'isChecked': false},
        {'key': 'AvgSellPrice', 'value': 'all.avg_sell_price', 'isChecked': false}, 
    ];
    static holdingBookColumns = [
        {'key': 'Symbol', 'value': 'all.instrument', 'isChecked': true, 'isDisabled': true}, 
        {'key': 'AvailableQty', 'value': 'all.available', 'isChecked': true, 'isDisabled': true}, 
        {'key': 'Price', 'value': 'all.avgprice', 'isChecked': true}, 
        {'key': 'LastTradePrice', 'value': 'all.ltp', 'isChecked': true}, 
        {'key': 'Invest', 'value': 'basket.invst_val', 'isChecked': true}, 
        {'key': 'PrimaryValuation', 'value': 'all.current_valuation', 'isChecked': true}, 
        {'key': 'NetPNL', 'value': 'all.p_and_l', 'isChecked': true}, 
        {'key': 'DayPNL', 'value': 'all.dayPL', 'isChecked': true}, 
    ];
    static updateHoldingBookColumns = new Subject();
    static updatePositionBookColumns = new Subject();
    static datamap = {
        "BtnWidth": {"M":32,"T":40,"C":32},
        "TabWidth": {"M": 118,"T":118,"C":118},
        "OCLeft": {"M": 600,"T":600,"C":600},
        "OCHeight": {"M": 60,"T":75,"C":55},
        "RolLeft": {"M": 120,"T":180,"C":120},
        "RolHeight": {"M": 60,"T":85,"C":60},
        "MMLeft": {"M": 110,"T":180,"C":110},
        "MMHeight": {"M": 160,"T":180,"C":160},
        "TMLeft": {"M": 180,"T":260,"C":160},
        "TMHeight": {"M": 60,"T":80,"C":62},
        "HBLeft": {"M": 220,"T":293,"C":220},
        "HBHeight": {"M": (environment.baseviewpath ==='investor') ? 65 : 105, "T":180, "C":145},
        "WLeft": {"M": 195,"T":295,"C":175},
        "WHeight": {"M": 62,"T":62,"C":43},
        "OBLeft": {"M": 300,"T":450,"C":270},
        "OBHeight": {"M": (environment.baseviewpath ==='investor') ? 8 : 65, "T":120, "C":100},
        "PBLeft": {"M": 220,"T":310,"C":220},
        "PBHeight": {"M": (environment.baseviewpath ==='investor') ? 65 : 107, "T":130, "C":107},
        "SLeft": {"M": 170,"T":250,"C":150},
        "SHeight": {"M": 60,"T":90,"C":60},
        "TBLeft": {"M": 165,"T":245,"C":165},
        "TBHeight":{"M": (environment.baseviewpath ==='investor') ? 8 : 55, "T":65, "C":60},
        "NWHeight": {"M": 5,"T":15,"C":5},
        "EVHeight": {"M": 33,"T":48,"C":40},
        "PEERHeight": {"M": 15,"T":30,"C":15},
        "FINHeight": {"M": 15,"T":30,"C":15},
        "ABHeight": {"M": 10,"T":20,"C":10},
        "MVHeight": {"M": 60,"T":125,"C":90},
        "ExMSGHeight": {"M": 40,"T":40,"C":40},
        "limitHeight": {"M": 170,"T":170,"C":150},
        "MSHeight": {"M": 40,"T":40,"C":40},
        "INHeight": {"M": 60,"T":70,"C":50},
        "WatchlistHeaderHeight": {"M": 95,"T":95,"C":80},
        "BookHeaderHeight": {"M": 85,"T":85,"C":70},
        "EHeight": {"M": 5,"T":5,"C":5}
    };

    static rowHeightMap = {
        "watchlist": {"M": 32,"T":40,"C":40},
        "orderBook": {"M": 32,"T":40,"C":40},
        "tradeBook": {"M": 32,"T":40,"C":40},
        "positionBook": {"M": 32,"T":40,"C":40},
        "holdingBook": {"M": 32,"T":40,"C":40},
        "searchBook": {"M": 32,"T":40,"C":40},
        "recentSearch": {"M": 32,"T":40,"C":40}
    };

    static BtnWidth;
    static TabWidth;

    static OCLeft;
    static OCHeight;

    static RolLeft;
    static RolHeight;

    static MMLeft;
    static MMHeight;

    static TMLeft;
    static TMHeight;

    static HBLeft;
    static HBHeight;

    static WLeft;
    static WHeight;

    static OBLeft;
    static OBHeight;

    static PBLeft;
    static PBHeight;

    static SLeft;
    static SHeight;

    static TBLeft;
    static TBHeight;

    static NWHeight;
    static ABHeight;

    static EHeight;

    static EVHeight;
    static PEERHeight;
    static FINHeight;
    static MVHeight;
    static limitHeight;
    static ExMSGHeight;
    static MSHeight;
    static INHeight;

    static watchlist = 1;
    static orderBook = 2;
    static tradeBook = 3;
    static positionBook = 4;
    static holdingBook = 5;
    static searchBook = 6;
    static recentSearch = 7;
    static scanner = 8;
    static topmarket = 9;

    static setTuchType()
    {
        TableUtility.WATCHLIST_HEADER_HEIGHT = TableUtility.datamap["WatchlistHeaderHeight"][TableUtility.TOUCH_TYPE];
       TableUtility.BOOK_HEADER_HEIGHT = TableUtility.datamap["BookHeaderHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.BtnWidth = TableUtility.datamap["BtnWidth"][TableUtility.TOUCH_TYPE];
        TableUtility.TabWidth = TableUtility.datamap["TabWidth"][TableUtility.TOUCH_TYPE];
        TableUtility.OCLeft = TableUtility.datamap["OCLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.OCHeight = TableUtility.datamap["OCHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.RolLeft = TableUtility.datamap["RolLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.RolHeight = TableUtility.datamap["RolHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.MMLeft = TableUtility.datamap["MMLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.MMHeight = TableUtility.datamap["MMHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.TMLeft = TableUtility.datamap["TMLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.TMHeight = TableUtility.datamap["TMHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.HBLeft = TableUtility.datamap["HBLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.HBHeight = TableUtility.datamap["HBHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.WLeft = TableUtility.datamap["WLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.WHeight = TableUtility.datamap["WHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.OBLeft = TableUtility.datamap["OBLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.OBHeight = TableUtility.datamap["OBHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.PBLeft = TableUtility.datamap["PBLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.PBHeight = TableUtility.datamap["PBHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.SLeft = TableUtility.datamap["SLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.SHeight = TableUtility.datamap["SHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.TBLeft = TableUtility.datamap["TBLeft"][TableUtility.TOUCH_TYPE];
        TableUtility.TBHeight = TableUtility.datamap["TBHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.NWHeight = TableUtility.datamap["NWHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.EVHeight = TableUtility.datamap["EVHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.PEERHeight = TableUtility.datamap["PEERHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.FINHeight = TableUtility.datamap["FINHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.ABHeight = TableUtility.datamap["ABHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.MVHeight = TableUtility.datamap["MVHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.ExMSGHeight = TableUtility.datamap["ExMSGHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.limitHeight = TableUtility.datamap["limitHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.MSHeight = TableUtility.datamap["MSHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.INHeight = TableUtility.datamap["INHeight"][TableUtility.TOUCH_TYPE];
        TableUtility.EHeight = TableUtility.datamap["EHeight"][TableUtility.TOUCH_TYPE];
    }

    static getRowHeight(viewnum, cellview) {
        switch(viewnum){
            case this.watchlist:
                return TableUtility.BtnWidth = (cellview) ? 53 : TableUtility.rowHeightMap["watchlist"][TableUtility.TOUCH_TYPE];
            case this.orderBook:
                return TableUtility.BtnWidth = (cellview) ? 90 : TableUtility.rowHeightMap["orderBook"][TableUtility.TOUCH_TYPE];
            case this.tradeBook:
                return TableUtility.BtnWidth = (cellview) ? 90 : TableUtility.rowHeightMap["tradeBook"][TableUtility.TOUCH_TYPE];
            case this.positionBook:
                return TableUtility.BtnWidth = (cellview) ? 90 : TableUtility.rowHeightMap["positionBook"][TableUtility.TOUCH_TYPE];
            case this.holdingBook:
                return TableUtility.BtnWidth = (cellview) ? 90 : TableUtility.rowHeightMap["holdingBook"][TableUtility.TOUCH_TYPE];
            case this.searchBook:
                return TableUtility.BtnWidth = (cellview) ? 90 : TableUtility.rowHeightMap["searchBook"][TableUtility.TOUCH_TYPE];
            case this.recentSearch:
                return TableUtility.BtnWidth = (cellview) ? 90 : TableUtility.rowHeightMap["recentSearch"][TableUtility.TOUCH_TYPE];
        }
    }

    static getTempObj = [
        {'property': 'displayedColumns', 'key': 'ATP'},
        {'property': 'displayedColumns', 'key': 'Ask'},
        {'property': 'displayedColumns', 'key': 'Bid'},
        {'property': 'tradeColumns', 'key': 'Instrument'},
        {'property': 'tradeColumns', 'key': 'TradeID'},
        {'property': 'positionColumns', 'key': 'Instrument'},
        {'property': 'positionColumns', 'key': 'Avg'},
        {'property': 'positionColumns', 'key': 'LTP'},
        {'property': 'positionColumns', 'key': 'P&L'},
        {'property': 'positionColumns', 'key': 'Chg'},
        {'property': 'holdingColumns', 'key': 'Instrument'},
        {'property': 'holdingColumns', 'key': 'Avg. cost'},
        {'property': 'holdingColumns', 'key': 'LTP'},
        {'property': 'holdingColumns', 'key': 'Cur. val'},
        {'property': 'holdingColumns', 'key': 'P&L'},
        {'property': 'holdingColumns', 'key': 'Day pnl'},
    ]
}
