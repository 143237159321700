import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";
import { TradingRestService } from "src/app/services/tradingrest.service";
import { Utility } from "src/app/services/utility";
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { TriggerMoreComponent } from "../triggermore/triggermore.component";
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TableUtility } from "src/app/services/tableutility";
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { Mouse } from "src/app/services/mouse";
import { MyAlertDialogComponent } from "../home/watchlist/watchlist.component";
import { MatDialog } from "@angular/material/dialog";
import { CreateTriggerComponent } from "../createTrigger/createTrigger.component";
import { Preferences, ReturnType } from "src/app/services/Preferences";

@Component({
    selector: 'app-trigger',
    templateUrl: '../../'+environment.baseviewpath+'/trigger/trigger.component.html',
    styleUrls: ['./trigger.component.scss']
})
export class TriggerComponent extends BaseComponentDirective {

  triggerTypes = [{'key': 'SINGLE', 'val': 'GTT_SINGLE'}, {'key': 'OCO', 'val': 'GTT_OCO'}];
  selectedTriggerType = 'GTT_SINGLE';
  statusMap = {'-1':'Cancelled', '0':'Failed', '1':'Active', '2': 'Executed', 
  '3':'In Progress', '4':'In Progress', '5':'Failed', '6':'Failed', '7':'In Progress', 
  '8':'In Progress', '9':'In Progress', '10':'In Progress', '11':'In Progress',
  '12':'In Progress', '13':'In Progress', '14':'OCO Disable', '15':'Expired'};
  triggersList = [];
  isNoData = false;
  isMobile: boolean;
  title = 'GTT';
  message = '';
  isLoading = false;
  @Output() load = new EventEmitter<boolean>();
  container: { width: number; height: number; };
  cellview: boolean;
  totalheight: number;
  left: any;
  scripResult = [];
  Height: {};
  isDHeight = true;
  DHeight: {};
  selectedRowIndex: any;
  utility: any;
  M: typeof Mouse;
  displayedColumns = [];
  segmentToExchange;
  flashType = 'defaultFlash';


  constructor(elRef: ElementRef, private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private translate: TranslateService,
    private router: Router, private _bottomSheet: MatBottomSheet, private detector: ChangeDetectorRef, public dialog: MatDialog) {
    
    super(elRef.nativeElement);
    this.utility = Utility;
    this.M = Mouse;
    this.isMobile = Utility.IS_MOBILE;
    this.segmentToExchange = Utility.segmentToExchange;
    this.selectedTriggerType = Utility.selectedTriggertyp;
    this.displayedColumns = (this.selectedTriggerType === 'GTT_OCO') ? TableUtility.triggerOcoConfig :  TableUtility.triggerSingleConfig;
    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      this.flashType = Preferences.getItem('flashType');
    }
    if(this.isMobile) {
      this.getAllTriggersData();
    }
    Utility.getTriggers.subscribe(() => {
      this.getAllTriggersData();
    });
    Utility.flashType.subscribe(obj => {
      this.flashType = obj;
    });
  }

  selectType() {
    Utility.selectedTriggertyp = this.selectedTriggerType;
    if(this.selectedTriggerType === 'GTT_OCO') {
      this.displayedColumns = TableUtility.triggerOcoConfig;
    } else {
      this.displayedColumns = TableUtility.triggerSingleConfig;
    }
    this.getAllTriggersData();
  }

  highlight(row: any, i: number) {
    Mouse.IS_MOUSE = false;
    if(this.isMobile) {
      row.cond.Symbolname = row.scripModel.Symbolname;
      row.cond.symdes = row.scripModel.DisplaySymbolDescription;  
      Utility.selectedTriggerData = row;
        const bottomSheetRef = this._bottomSheet.open(TriggerMoreComponent, {
          restoreFocus: false
        });
        bottomSheetRef.afterDismissed().subscribe(result => {
          if (result && result.isCancel)
            this.getAllTriggersData();
      });
    }
  }
      
  getAllTriggersData() {
    this.isLoading = true;
    this.load.emit(this.isLoading);
    const dataObj = {'trigger_type':this.selectedTriggerType}
    this.tradingRestService.getTriggerService().getAllTriggers(dataObj, function (data, code, msg) {
      if(code === 200 && msg === 'SUCCESS') {
        this.triggersList = data;
        this.isNoData = false;
      } else {
        this.isNoData = true;
        this.message = msg;
      }
      this.isLoading = false;
      this.load.emit(this.isLoading);
    }.bind(this));
  }

  deleteTrigger(data) {
    const str = this.translate.instant('all.areusure_delete') +"<br/>"+ data.cond.sym + this.translate.instant('all.c_order');
    Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: "Delete Order", message: str }});
    Utility.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.load.emit(true);
        const tid = data.tid;
        this.tradingRestService.getTriggerService().deleteTrigger(tid, function(data, code, msg) {
          if(code === 200 && msg === 'SUCCESS') {
            this.snackBar.open(this.translate.instant('all.trigger_deleted'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['successSnackBar'] });
            this.getAllTriggersData();  
          } 
          else if(code === 0 && msg === "Please verify your request.") {
            this.snackBar.open(msg, this.translate.instant('all.close'),  { duration: 3000, panelClass: ['errorSnackBar'] });
            this.getAllTriggersData();  
          } else {
            this.snackBar.open(msg, this.translate.instant('all.close'),  { duration: 3000, panelClass: ['errorSnackBar'] });
          }
          this.load.emit(false);
        }.bind(this));
      }
    });
  }

  editTrigger(data) {
    Utility.triggerData = data;
    if(this.isMobile) {
      this.router.navigate(["/createtrigger"], {queryParams: {'isEdit':true, 'exseg':data.ord[0].ex, 'omtkn':data.cond.tkn}});
    } else {
      const data_obj = { 'exseg':data.ord[0].ex, 'omtkn':data.cond.tkn, 'isEdit': true };
        Utility.openPage(this.router, 'trigger', 'createtrigger', CreateTriggerComponent, this.dialog, 'popup', data_obj, '500px', '850px');
    }
  }

  refresh(){
    this.selectedTriggerType = Utility.selectedTriggertyp;
    this.getAllTriggersData();
  }

  changeMode() {
    if (this.container?.width !== null){
      this.totalheight = (this.container.height - TableUtility.WHeight);
      this.Height = {
        'height': this.totalheight + 'px',
        'overflow': 'auto',
        'scroll-behavior': 'smooth'
      };
      this.left = {
        'left': (this.container.width - TableUtility.WLeft) + 'px'
      };
      if (this.container.width < Utility.MinWidth) {
        this.cellview = true;
        
      } else {
        this.cellview = false;
        this.scripResult.map((data: any) => {
          data.show = false;
        });
      }
      this.detector.detectChanges();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (Utility.IS_MOBILE_LAND) {
      if(window.scrollY > TableUtility.WATCHLIST_HEADER_HEIGHT && this.isDHeight){
        this.DHeight = {
          'height': '100vh',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };
        let element:HTMLElement;
        element = document.getElementById("wl_tbl1") as HTMLElement;
        element?.focus();
        this.isDHeight = false;
      }
      else if(window.scrollY < TableUtility.WATCHLIST_HEADER_HEIGHT){
        this.DHeight = {
          'height': '100%',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };
        this.isDHeight = true;
      }
    }
  }

  handleMouseOver(row) {
    const Symbolname = row.cond.sym;
    this.triggersList.map((data: any) => {
      if (Symbolname && data.cond.sym && (data.cond.sym === Symbolname) && (data.ord[0].oid === row.ord[0].oid)) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const Symbolname = row.cond.sym;
    this.triggersList.map((data: any) => {
      if (data.cond.sym === Symbolname) {
        data.show = false;
      }
    });
  }

  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    if (width < Utility.MinWidth)
      this.cellview = true;
    else
      this.cellview = false;
    this.changeMode();
  }

  getClass(stat){
    return {'open-status': stat === 'Active',
            'traded-status': stat === 'Executed', 
            'rejected-status': stat === 'Failed',
            'Cancelled-status': stat === 'Cancelled',
            'in-progress-status': stat === 'In Progress',
          };
  }

  setBaseVisibility(visible: boolean){
    if(visible){
      this.getAllTriggersData();
      setTimeout(() => {
        this.changeMode();
      }, 50);
    }
  }
}