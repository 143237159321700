export class Url {

    static GET_INITIAL_KEY = 'rest/GetInitialKey';
    static GET_PRE_AUTH_KEY = 'rest/GetPreAuthenticationKey';
    static QUICK_LOGIN = 'rest/quickLogin';
    static DEFAULT_LOGIN = 'rest/DefaultLogin';
    static ORDER_BOOK = 'rest/OrderBook';
    static CANCEL_OPEN_ORDER = 'rest/CancelOrder';
    static EXIT_ORDER = 'rest/ExecuteExitOrder';
    static SHOW_QUOTE = 'rest/ShowQuote';
    static WATCH_LIST = 'rest/MWList';
    static SCRIPT_INFO_WATCH_LIST = 'rest/MarketWatch';
    static ADD_SCRIPT_WATCH_LIST = 'rest/AddMultiScripsToMW';
    static DELETE_SCRIPT_WATCH_LIST = 'rest/DeleteMultiMWScrips';
    static DELETE_WATCH_LIST = 'rest/DeleteMW';
    static Set_DEFAULT_WATCH_LIST = 'rest/SetDefaultMarketWatch';
    static PLACE_ORDER = 'rest/PlaceOrder';
    static TRADE_BOOK = 'rest/TradeBook';
    static Logout = 'rest/Logout';
    static POSITION_BOOK = 'rest/PositionBook';
    static Login2FA = 'rest/Login2FA';
    static validPwd = 'rest/ValidPwd';
    static validAns = 'rest/ValidAns';
    static userProfile = 'rest/user/account/details';
    // index
    static IndexExchange = 'rest/IndexExchanges';
    static IndexDetails = 'rest/IndexDetails';
    // alert
    static AlertIndex = 'rest/AlertIndex';
    static SecTradeAlert = 'rest/SecTradeAlert';
    static OpenOrders = 'rest/Openorders';
    static PerTradeAlert = 'rest/PresonalTradeAlert';
    static AlertHistory = 'rest/AlertHistory';
    static CancelAlert = 'rest/CancelAlert';
    static ModifyPerTradeAlert = 'rest/ModifyPersonalAlrt';
    static ModifySecTradeAlert = 'rest/ModifySecAlert';

    static HOLDING = 'rest/Holding';
    static MULTIPLE_SCRIP = 'rest/broker/mw/scrips';
    static GRAPH_URL = 'rest/GetChartData';
    static LIMIT_URL = 'rest/Limits';
    static OM_SESSION_TOKEN = 'rest/GetOMSessionToken';
    static USER_LOG_MESSAGE = 'rest/Userlogsmessage';
    static MARKET_PICTURE_URL = 'rest/ShowMrktPicture';
    static UnblockUser = 'rest/UnblockUser';
    static ForgotPwd = 'rest/ForgotPassword';
    static DisplayQuestion = 'rest/Displayquestions';
    static SetImages = 'rest/SetImage';
    static SaveImages = 'rest/SaveImages';
    static SaveAns = 'rest/SaveAns';
    static ForgotLoginId = 'rest/user/loginid/forgot';
    static ChangePwd = 'rest/Changepwd';

    // PT Plus
    static PTP_SEARCH = '/htpl/search/symbol';
    static PTP_TAGS_FOR_TEXT = '/htpl/news/gettagsfortext';
    static PTP_NEWS = '/htpl/news/getnews';
    static IB_PAYMENT = '/api/payment/getorderid';
    static PAYOUT = '/api/payment/payout';
    static ALL_SEGMENTS = '/api/funddetails/fundlist';
    static CANCEL_WITHDRAWAL = '/api/payment/payoutCancel';
    static MODIFY_WITHDRAWAL = '/api/payment/payoutModify';
    static EDIS_TRANSECTIONID = '/api/payment/gettxnid';
    static VERIFY_PAYMENT = '/api/payment/verifypayment';
    static CANCEL_PAYMENT = '/api/payment/cancelpayment';
    static TRANSACTION_DETAILS = '/api/payment/gettransactions';
    static ALL_TRANSACTIONCHARGES = '/api/payment/getalltransactioncharges';
    static TRANSACTION_CHARGES = '/api/payment/gettransactioncharge';
    static PTP_STOCK_CHART = '/htpl/chart/htplstockchart';
    static PTP_INDEX_CHART = '/htpl/chart/htplindexchart';
    static PTP_EVENTS = '/htpl/corporateaction/getcorporateaction';
    static PTP_FILTER_EVENTS = '/htpl/corporateaction/getcorporatedatewise';
    static PTP_GROUPNEWS = '/htpl/news/getgroupnews';
    static PTP_NEWS_TAGS = '/htpl/news/getalltags';
    static GET_RETENTION = 'rest/LoadRetentionType';
    static ORDERHISTORY_URL = 'rest/OrderHistory';
    static GET_LOT_WEIGHT = 'rest/GetLotWeight';
    static GET_SEC_INFO = 'rest/SecurityInfo';
    static VALIDATE_GTD = 'rest/ValidateGTD';
    static POSITIONCONVERTION_URL = 'rest/PositionConvertion';
    static PARTIAL_POSITIONCONVERTION_URL = 'rest/PartialPositionconvertion';
    static MODIFY_ORDER = 'rest/ModifyOrder';
    static GET_ALLBASKET_URL = '/api/content/basket/getallbasket';
    static TB_PLACE_ORDER = '/api/content/placeorder/v2/place';
    static TB_GET_POSITIONS = '/api/content/books/getpositions';
    static TB_REBALANCE = '/api/content/rebalance/rebalancing';
    static TB_EXITORDERS = '/api/content/placeorder/exitorders'
    static REPAIRORDERS = '/api/content/placeorder/repairorders';
    static TB_GET_BASKETCONSTITUENT_ORDERS = '/api/content/books/getordersbyuser';
    static GET_INSTRUMENTRATES = '/api/content/basket/getinstrumentrates';
    static KeyRatio = '/api/trkd/fundamental/ratioreports';
    static PeerComp = '/api/trkd/market/peercomp';
    static TechnicalsScanners = '/htpl/screeners/htpltechnicals';
    static FinancialStatement = '/api/trkd/fundamental/getfinancialstatementsdata';
    static CompanyData = '/api/trkd/fundamental/v2/getcompanydata';
    static GetIbes = '/api/trkd/ibes/getibes';
    static BENCHMARK_URL = '/api/content/basketdetail/v2/getbenchmark';
    static BASKETDETAIL_URL = '/api/content/basketdetail/getbasketdetail';
    static PTP_GROUPEVENTS = '/htpl/corporateaction/getcorporateaction';
    static MULTIPLE_BASKETDETAIL_URL = '/api/content/basketdetail/v2/getbasketdetail';
    static INDICESTODAYNQ = '/api/noquoteapi/market/getindicestodayconstituentsNQ';
    static ALLINDICESNQ = '/htpl/market/getallindicesdata';
    static INDEXFUTUREROLLOVERNQ = '/api/noquoteapi/market/getindexfuturerolloverdataNQ';
    static FUTUREROLLOVERNQ = '/api/noquoteapi/market/getfuturerolloverdataNQ';
    static LTP_COVER_PRCNTGE = 'rest/LTPCoverpercentage';
    static COVER_ORDER_PRICE_FLAG = 'rest/GetCOPriceFlag';
    static VALIDATE_SLM_PRICE = 'rest/validateSLMPrice';
    static OPTION_CHAIN = '/api/content/search/optionchain';
    static OPTION_CHAIN_DATA = '/api/content/search/getoptionchaindata';
    static MARKET_MOOD = 'api/noquoteapi/market/getexchangemarketmoodNQ';
    static GetAccessToken = '/api/trkd/authentication/getaccesstoken';
    static PUT_CALL_RATIO = '/api/noquoteapi/derivatives/getputcallratioNQ';
    static GetMarketMovers = '/htpl/market/getmarketmovers';
    static PLACE_BO_ORDER = 'rest/user/bo/placeorder';
    static PREDEFINED_WATCHLIST = '/api/content/predefineWatchList/getallpredefinewl';
    static CALCULATE_QTY = '/api/content/placeorder/calculateqty';
    
    // Order status
    static Open = 'open';
    static OpenPending = 'open pending';
    static ValidationPending = 'validation pending';
    static TriggerPending = 'trigger pending';
    static ModifyAfterMarketOrderReqReceived = 'modify after market order req received';
    static AfterMarketOrderReqReceived = 'after market order req received';
    static Complete = 'complete';
    static Cancelled = 'cancelled';
    static Rejected = 'rejected';
    static CancelledAfterMarketOrder = 'cancelled after market order';
    static PutOrderReqReceived = 'put order req received';

    static marketStatus = 'rest/MarketStatus';
    static exchangeMessage = 'rest/ExchangeMessage';

    // scanner
    static DERIVATIVE_SCANNER = '/api/noquoteapi/screeners/getNQDerivativeScanner';
    static NSE_BSE_INDEX = '/htpl/stockdata/getnsebseindexname';
    static NQSCREENERS = '.api/noquoteapi/screeners/getscreenersNQ';
    static SCANNER_INDEX_URL = '/htpl/screeners/getscreeners';

    // live market
    static MARKET_TODAY = '/api/noquoteapi/market/getmarkettodaydataNQ';
    static MARKET_TODAY_DERIVATIVE = '/api/noquoteapi/market/getindexfuturedataNQ';

    // hypersync
    static HS_QUICK_LOGIN = 'quick/user/login';
    static HS_GET_INITIAL_KEY = 'quick/GetInitialKey';
    static HS_GET_PRE_AUTH_KEY = 'quick/GetPreAuthenticationKey';
    static HS_DEFAULT_LOGIN = 'quick/user/login/default';
    static HS_USER_RESET = 'quick/user/reset';
    static HS_LOGOUT = 'quick/user/logout';
    static HS_CHANGE_PWD = 'quick/user/change-pwd';
    static HS_LOT_WEIGHT = 'quick/user/get/lot-weight';
    static HS_ORDER_BOOK = 'quick/user/orders';
    static HS_SPREADORDER_BOOK = 'quick/order/sp/reports';
    static HS_SCRIP_INFO = 'quick/scrip/info'; // multiple scrip detail
    static HS_SECURITY_INFO = 'quick/SecurityInfo'; // show quote
    static HS_HOLDING = 'quick/user/holdings';
    static HS_TRADE_BOOK = 'quick/user/trades';
    static HS_POSITION_BOOK = 'quick/user/positions';
    static HS_ORDER_HISTORY = 'quick/order/history';
    static HS_SPREADORDER_HISTORY = 'quick/order/sp/history'
    static HS_WATCH_LIST = '/htpl/userwatchlist/getusergroups';
    static HS_DELETE_WATCH_LIST = '/htpl/userwatchlist/deleteusergroups';
    static HS_INDEX_DETAILS = 'quick/index/list';
    static HS_UPDATE_MARKETWATCH = '/htpl/userwatchlist/updatewatchlist';
    static HS_SET_DEFAULT_MARKETWATCH = 'quick/user/mw/default';
    static HS_GET_MARKET_WATCH = 'quick/GetMW';
    static HS_PLACE_ORDER = 'quick/order/place';
    static HS_SPREAD_ORDER = 'quick/order/vr/sp/place';
    static HS_LIMIT = 'quick/user/limits';
    static HS_USER_DETAILS = 'quick/user/details';
    static HS_INTRADAY_CHART = 'quick/scrip/chart';
    static HS_RETENTION_TYPE = 'quick/load/retention/types';
    static HS_CANCEL_ORDER = 'quick/order/cancel';
    static HS_EXIT_BRACKET_ORDER = 'quick/order/co/exit';
    static HS_MODIFY_ORDER = 'quick/order/modify';
    static HS_SPREADMODIFY_ORDER = 'quick/order/sp/modify';
    static HS_SPREADCANCEL = 'quick/order/sp/cancel'
    static HS_BRACKET_ORDER = 'quick/order/bo/place';
    static EXCHANGE_TOKEN = 'quick/exchange/tokens';
    static HS_MARKET_STATUS = 'quick/MarketStatus';
    static HS_EXCHANGE_MESSAGE = 'quick/ExchangeMessage';
    static HS_PARTIAL_POSITION_CONVERSION = 'quick/convert/partialposition';
    static HS_LTP_COVER_PER = 'quick/order/co/range';

    // IAM Server
    static IAM_QUICK_LOGIN = 'api/v2/login';
    static IAM_FORGET_PASSWORD = 'api/v2/frgtpwd';
    static IAM_LOG_OUT = 'api/logout';
    static IAM_SET_MPIN = 'api/setmpin';
    static IAM_MPIN_LOGIN = 'api/mpinlogin';
    static IAM_CHANGE_DEVICE_INFO = 'api/checkdeviceinfo';
    static IAM_CHANGE_PASSWORD = 'api/chngpwd';
    static IAM_FORGOT_MPIN = 'api/frgtmpin';
    static IAM_VALIDATE_OTP = 'api/validateotp';
    static IAM_NEWPASS = 'api/v2/newpwd';
    static IAM_FORGOT_UID = 'api/frgtuid';
    static IAM_MODIFY_MPIN = 'api/modifympin';
    static IAM_UPDATE_USERINFO = 'api/updateuserinfo';
    static IAM_UNBLOCK_USER = 'api/unblockuser';
    static IAM_SET_PASSWORD = 'api/setpwd';
    static IAM_SESSIONS = 'api/getallsessions';
    static IAM_CLEARSESSIONS = 'api/clearallsessions';
    static IAM_RESEND_OTP = 'api/v2/resendotp';
    static IAM_GET_PREFERENCES = 'api/getuserpreferences';
    static IAM_SET_PREFERENCES = 'api/storeuserpreferences';
    static IAM_GET_BANNERS = 'api/getbannersbyservice';
    static IAM_VERIFYOTP = 'api/v2/verifyotp';

    // Basket SIP
    static GET_USER_SIP = '/api/content/sip/getusersip';
    static GET_USER_TODAY_SIP = '/api/content/sip/getusertodayssip';
    static CHANGE_SIP_STATUS = '/api/content/sip/sip_changestatus';
    static UPDATE_SIP = '/api/content/sip/sip_update';

    //Trigger
    static GET_TRIGGERS = '/api/get_triggers';
    static CREATE_TRIGGERS = '/api/create_triggers';
    static EDIT_TRIGGERS = '/api/edit_triggers';
    static DELETE_TRIGGERS = '/api/delete_triggers';
    static EXECUTED_TRIGGERS = '/api/executed_triggers';
    static GET_BY_PHOREM = '/api/customer/getbyphorem';
    static FETCH_IB_FOR_USER = '/api/survey/fetchIBForUser?custid=';

    //emandate
    static GET_EMANDATE_MSGID = '/api/enach/getEmandateMsgId';
    static GET_ALL_TRANSACTIONS = '/api/enach/getalltransactions';
}
