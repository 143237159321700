<app-header *ngIf="isMobile && !investSummry && !isExit" goback="1" [title]="headTitle" [isLoading]="isLoading"></app-header>
<app-header *ngIf="isMobile && !investSummry && isExit" goback="1" [title]="exitTitle" [isLoading]="isLoading"></app-header>

<app-titlebar *ngIf="!isMobile && !investSummry && !isExit" [title]="headTitle" [isLoading]="isLoading"></app-titlebar>
<app-titlebar *ngIf="!isMobile && !investSummry && isExit" [title]="exitTitle" [isLoading]="isLoading"></app-titlebar>
<mat-dialog-content *ngIf="!investSummry"  [class.p-0] = "!isMobile">
    <div [class.onlyheaderfromtop]="isMobile" [class.mb-1]="isMobile">
        <div [class.px-3]="!isMobile" [class.pt-3]="!isMobile">
            <div class="placeorderwrapper buy" *ngIf='isPlaceOrder && !isNodata && !fromTodaySip'>
                <div class="row">
                    <div class="col-12" [class.mb-1]="!isMobile" [class.mb-1]="isMobile">
                        <span class="primetext ts-2">{{'all.how_po' | translate}}</span>
                    </div>
                    <div [class.col-7]="!isMobile" [class.col-12]="isMobile" [class.mb-2]="!isMobile" [class.mb-1]="isMobile">
                        <mat-radio-group [(ngModel)]="selectedType" [disabled]="disableForm">
                            <mat-radio-button value="OneTime" (change) = "selectType('OneTime')">{{'all.one_time' | translate}}</mat-radio-button>&nbsp;
                            <mat-radio-button value="SIP" [disabled]="!isSip" (change) = "selectType('SIP')">{{'all.sip' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-5 text-right" *ngIf="!isMobile">
                        <span class="ts-3 pl-1">{{'limits.avl_margin' | translate}}<br/><span class="primetext">{{balance | pricepipe}}</span></span>
                    </div>
                </div>
                <div class="row" [class.pr-3]="!isMobile" [class.pt-2]="isMobile" *ngIf='selectedType === "SIP"'>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>all.frequency</mat-label>
                            <mat-select [(ngModel)]="selectedFrequency" (ngModelChange)="SelectSipData()" [disabled]="disableForm">
                                <mat-option *ngFor="let data of sip_frequency" [value]="data">{{data}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>all.date</mat-label>
                            <mat-select [(ngModel)]="selectedDay" [disabled]="disableForm">
                                <mat-option *ngFor="let data of sip_day" [value]="data">{{data}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" [class.pr-3]="!isMobile" [class.pr-4]="isMobile">
                    <div class="col-12 " [class.mb-1]="!isMobile" [class.mb-1]="isMobile">
                        <span class="primetext ts-2">{{'all.place_order_on' | translate}}</span>
                    </div>
                    <div class="col-5" [class.mb-2]="!isMobile" [class.mb-1]="isMobile" [class.pt-1]="isMobile">
                        <mat-radio-group [(ngModel)]="selectedTab" [disabled]="disableForm">
                            <mat-radio-button value="Lot" (change) = "selectTab('Lot')">{{'portfolio.lt' | translate}}</mat-radio-button>&nbsp;
                            <mat-radio-button value="Amount" (change) = "selectTab('Amount')">{{'books.amt' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <!-- in case of Amount -->
                    <div class="col-7 mt-1" [class.mt-2]="isMobile" *ngIf='isPlaceOrder && !isNodata && selectedTab === "Amount"'>
                        <div class="row">
                            <div class="col-10 pr-0">
                                <mat-form-field class="poform">
                                    <mat-label *ngIf="isPlaceOrder" translate>basket.minAmt</mat-label>
                                    <input matInput type="text" [(ngModel)] = "investmentAmount"
                                    inputmode="numeric" #focus value={{investmentAmount}} autocomplete="off" appNumberDir dp="2" pricesize=10 [readonly]="toggle" [disabled]="disableForm">
                                </mat-form-field>
                            </div>  
                            <div class="col-2">
                                <button *ngIf = 'toggle' [disabled] = "editDisable || disableForm" class="btn-primary cursor-pointer bheight" mat-icon-button matTooltip="Edit" (click)="enable()"><i class="icon-edit ic"></i></button>
                                <button *ngIf = '!toggle' class="btn-primary cursor-pointer bheight" mat-icon-button matTooltip="Confirm" (click)="onChangeMinimumInvestAmnt(focus.value)"><i class="icon-check ic"></i></button>
                            </div>
                        </div>
                    </div>
                    
                    <!-- in case of lot -->
                    <div class="col-7 mt-1" [class.mt-2]="isMobile" *ngIf='isPlaceOrder && !isNodata && selectedTab === "Lot"'>
                        <div class="row">
                            <div class="col-2" [class.pl-1]="!isMobile" [class.pl-0]="isMobile">
                                <button (click)='checkQty("D")' [disabled]="qty===1 || isLot || isDisabledMinus || disableForm" class="btn-base bheight" matTooltip="{{'tooltip.remove' | translate}}"  mat-icon-button><i class="icon-minus ic"></i></button>
                            </div>
                            <div class="col-8 p-0" [class.pl-2]="isMobile">
                                <mat-form-field class="poform">
                                    <mat-label translate>{{'all.enter_lots'}}</mat-label>
                                    <input matInput type="tel" [(ngModel)] = "qty" autocomplete="off"  #inputQty maxlength="8" (keyup)='checkQty($event)' (keyup)="validateQty($event)" [disabled]="disableForm">
                                </mat-form-field>
                            </div>
                            <div class="col-2">
                                <button [disabled]="isDisabledPlus || disableForm" (click)='checkQty("I")' class="btn-base bheight" matTooltip="{{'limits.add' | translate}}" mat-icon-button><i class="icon-plus ic"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="placeorderwrapper buy" *ngIf='isExit && !isNodata'>
                <div class="row">
                    <div [class.col-8]="selectedOption === 'Fully Exit' || selectedOption === 'Partially Exit'" [class.mb-2]="!isMobile" [class.mb-1]="isMobile">
                        <mat-radio-group [(ngModel)]="selectedOption" [disabled]="disableForm">
                            <mat-radio-button value="Fully Exit" (change) = "selectOption('Fully Exit')">{{'all.fully_exit' | translate}}</mat-radio-button>&nbsp;
                            <mat-radio-button value="Partially Exit" (change) = "selectOption('Partially Exit')">{{'all.partially_exit' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="selectedOption === 'Fully Exit' || selectedOption === 'Partially Exit'" [class.col-4]="selectedOption === 'Fully Exit' || selectedOption === 'Partially Exit'" [class.mb-2]="!isMobile" [class.mb-1]="isMobile">
                        <p class="mt-0 text-right"> {{'all.exit_amount' | translate}} <br/> {{basketData.marketVal | pricepipe}}</p>
                    </div>
                </div>
            </div>
            <div class="row" [class.pr-3]="!isMobile" [class.pt-2]="isMobile" *ngIf='isExit && selectedOption === "Partially Exit"'>
                <div [class.col-11]="!isMobile" [class.col-10]="isMobile">
                    <mat-form-field [disabled]="disableForm">
                        <mat-label *ngIf="isExit" translate>{{'all.enter_exitamt'}}</mat-label>
                        <input matInput type="text" [(ngModel)] = "investmentAmount"
                        inputmode="numeric" #focus value={{investmentAmount}} autocomplete="off" appNumberDir dp="2" pricesize=10 [readonly]="toggle">
                    </mat-form-field>
                </div>
                <div [class.col-1]="!isMobile" [class.pl-0]="!isMobile" [class.col-2]="isMobile">
                    <button *ngIf = 'toggle' [disabled] = "editDisable || disableForm" class="btn-primary cursor-pointer" mat-icon-button matTooltip="Edit" (click)="enable()"><i class="icon-edit"></i></button>
                    <button *ngIf = '!toggle' [disabled]="disableForm" class="btn-primary cursor-pointer" mat-icon-button matTooltip="Confirm" (click)="onChangeMinimumInvestAmnt(focus.value)"><i class="icon-check"></i></button>
                </div>
            </div>
            <div class="nodata" *ngIf='isNodata && !isLoading'>
                <div>{{msg}}</div>
                <mat-dialog-actions *ngIf = "utility.todaysSipData.length > 1 && fromTodaySip" class="mat-dialog-actions d-block" [class.mat-action-web]="!isMobile">
                    <div class="text-right px-0" [class.footerbtn]="isMobile" [class.p-action-r]="isMobile" [class.pr-4] = "!isMobile">
                        <button class="btn-positive" [class.w-100]="isMobile" mat-flat-button (click)="backToSip()">{{'all.todays_sip' | translate}}</button> 
                    </div>
                </mat-dialog-actions>
            </div>
            <div class="nodata" *ngIf='isNoStock && !isLoading' translate>basket.basket_rebal</div>
                <div *ngIf="!isNodata" class="po-section-data" [class.basket-popup-content2]="isExit && selectedOption === 'Partially Exit'" [class.basket-popup-content6] = "(isExit && selectedOption === 'Partially Exit') && isMobile">
                    <div *ngIf='isPlaceOrder || (isExit && cnfrmFlag && selectedOption === "Partially Exit")' [class.basket-popup-content5] = "isPlaceOrder && (selectedType !== 'SIP')" [class.basket-popup-content7] = "selectedType === 'SIP'">
                        <div class="row" *ngFor='let data of detailedBaskets; let i = index;'>
                            <div class="col-12">
                                <div class="list-view  border-b">
                                    <div class="row">
                                        <div class="col-7">
                                            <span class="tc-1 ts-4 textclip">{{data.symbol}}</span>
                                            <span><small class="exchangename">{{data.exchange}}</small></span>
                                            <div class="pt-1">
                                                <span class="subcolor ts-2">{{'basket.weights' | translate}}(%):</span>
                                                <span class="pl-1">{{data.weights | absptpipe}}%</span><span class="subcolor ts-2"> | </span>
                                                <span class="subcolor ts-2">{{'portfolio.qty' | translate}}:</span>
                                                <span class="fw-4 tc-3 pl-1">{{data.qty}}</span>
                                           </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="subcolor ts-2 text-right">
                                                <div class="">
                                                    <span class="subcolor2 ts-2">{{'stock_detail.ltp' | translate}}:</span>
                                                    <span class="ts-4 tc-1 pl-1" [ngClass]="{'impositive':data.change >0, 'imnegative': data.change < 0}">
                                                        {{data.ltp | pricepipe}}
                                                    </span>
                                                    <div class="pt-1">
                                                        <span class="subcolor2 ts-2">{{'books.amt' | translate}} :</span>
                                                        <span class="pl-1">{{data.amount | pricepipe}}</span>
                                                    </div>   
                                               </div>  
                                            </div>
                                        </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf='isRebalance' [class.basket-popup-content2]="!isMobile" [class.basket-popup-content3]="isMobile">
                        <div class="row" *ngFor='let data of basketPositionData; let i = index;'>
                            <div class="col-12">
                                <div class="list-view  border-b">
                                    <div class="row">
                                        <div class="col-6">
                                            <span class="tc-1 ts-4 textclip">{{data.symbol | symbolpipe}}</span>
                                            <span><small class="exchangename">{{scripModelArr[i]?.ExchangeName}}</small></span>
                                            <div class="pt-1">
                                                <span [class.tag-red]="data.trans_type=='S'" [class.tag-green]="data.trans_type=='B'">{{(data.trans_type==='B'?'BUY':'SELL')}}</span>
                                                | {{'portfolio.qty' | translate}}: <span class="fw-4 tc-3">{{data.qty}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="subcolor ts-2 text-right">
                                                <div class="">
                                                    <span class="subcolor2 ts-2">{{'stock_detail.ltp' | translate}}:</span>
                                                    <span class="ts-4 tc-1 pl-1" [class.impositive] = "scripModelArr[i]?.Change > 0" [class.imnegative] = "scripModelArr[i]?.Change < 0">
                                                        {{scripModelArr[i]?.LastTradePrice | pricepipe}}
                                                    </span>
                                                    <div class="pt-1">
                                                        <span class="subcolor2 ts-2">{{'books.amt' | translate}} :</span>
                                                        <span class="pl-1">{{(scripModelArr[i]?.LastTradePrice * data.qty) | numberpipe:"short"}}</span>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf='isExit && selectedOption === "Fully Exit"' [class.basket-popup-content]="!isMobile" [class.basket-popup-content4]="isMobile">
                        <div class="row">
                            <div class="col-12">
                                <div *ngFor='let data of basketPositionData'>
                                    <div class="watchlistwrapper">
                                        <div class="row">
                                            <div class ="col-12">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label class="tc-1 ts-4 line2">{{data.trdSym | symbolpipe}}</label><br>
                                                        <label class="line2">{{'basket.invst_val' | translate}}:&nbsp;</label><span>{{(data.buy_avg_price*data.net_qty) | pricepipe}}</span>
                                                    </div>
                                                    <div class="col-6 text-right">
                                                        <label class="line2">{{'portfolio.qty' | translate}}.:&nbsp;</label>{{data.net_qty | numberpipe}}
                                                        <br>
                                                        <label class="line2">{{'basket.curr_val' | translate}}: </label><span [ngClass]="{'traded-status': data.pnl > 0, 'rejected-status': data.pnl < 0}">{{(data.ltp*data.net_qty) | pricepipe}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div><br/>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-actions d-block" [class.mat-action-web]="!isMobile" *ngIf="!investSummry && !isNodata">
    <div class="row px-3 pb-3" *ngIf="!isMobile && isPlaceOrder && selectedType !== 'SIP'">
        <div class="col-12">
            <mat-checkbox class="mr-3" (change) = "onAMO($event)" [disabled]="disableForm">AMO</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div [class.col-12]="isMobile" [class.col-5]="!isMobile" [class.bottom-align2]="isMobile" *ngIf='isPlaceOrder || (isExit && selectedOption === "Partially Exit")'[class.p-action-l]="isMobile">
            <div class="row pb-3" *ngIf="isMobile && isPlaceOrder && selectedType !== 'SIP'">
                <div class="col-12">
                    <mat-checkbox class="mr-3" (change) = "onAMO($event)" [disabled]="disableForm">AMO</mat-checkbox>
                </div>
            </div>
            <div class="row mt-1" *ngIf="isMobile">
                
                <div class ="col-12 text-left btn-negative mb-1" *ngIf = "lowBalance">
                    <div class="ts-1 p-1"><i class="icon-info-alert"></i> {{'all.less_balance' | translate}}</div> 
                </div>
            </div>
            <div class="row" *ngIf="isPlaceOrder || (cnfrmFlag && selectedOption === 'Partially Exit')">
                <div [class.col-6]="isMobile" [class.col-12]="!isMobile">
                    <span class="subcolor2"  [class.pl-1]="!isMobile" translate>basket.total_amt</span>
                </div>
                <div [class.text-right]="isMobile" [class.col-6]="isMobile" [class.col-12]="!isMobile">
                    <span class="ts-4 tc-1"  [class.pl-1]="!isMobile">{{totalAmount | pricepipe}}</span>
                </div>
                <div class="col-6" *ngIf="isMobile && !isExit">
                    <span class="subcolor2" translate>limits.avl_margin</span>
                </div>
                <div class="text-right col-6" *ngIf="isMobile && !isExit">
                    <span class="ts-4 tc-1">{{balance | pricepipe}}</span>
                </div>
            </div>
        </div>
        <div class="text-right px-0" [class.col-7]="!isMobile && (isPlaceOrder || isExit)" [class.col-12]="isRebalance || (isExit && selectedOption === 'Fully Exit')" [class.footerbtn]="isMobile" [class.p-action-r]="isMobile" [class.padd] = "!isMobile">
            <button class="btn-positive" [class.w-50]="isMobile"  mat-flat-button (click)="openoverlay()"
             *ngIf="!isRebalance && !isExit && !disableForm"><span translate>{{'limits.add_funds'}}</span></button>
            
            <button *ngIf="!isExit && !disableForm" class="btn-positive" [class.w-50]="isMobile && !isRebalance" [class.w-100]="isMobile && isRebalance" mat-flat-button (click)="onSubmit()"
                [disabled]='isButtonDisable || isNoStock || isLot'><span translate>{{(orderType === 'FirstPurchase' ? 'basket.place' : 'basket.rebalance' )}}</span></button>
            <button *ngIf="isExit && selectedOption === 'Partially Exit' && !disableForm" class="btn-positive" [class.w-100]="isMobile && isExit" mat-flat-button (click)="onSubmit()"
                [disabled]='!cnfrmFlag || isButtonDisable || isNoStock'><span translate>{{'all.exitorders'}}</span></button> 
            <button *ngIf="isExit && selectedOption === 'Fully Exit' && !disableForm"class="btn-positive" [class.w-100]="isMobile && isExit" mat-flat-button (click)="onSubmit()"
                [disabled]="isButtonDisable"><span>{{'books.exit' | translate}}</span></button> 
        
            <button class="btn-positive" [class.w-50]="isMobile"  mat-flat-button (click)="onEdit()" [disabled]="isButtonDisable" *ngIf="(isPlaceOrder || (isExit && selectedOption === 'Partially Exit')) && disableForm">
                <span translate>{{'placeorder.edit'}}</span>
            </button>
            
            <button *ngIf="disableForm" class="btn-positive" mat-flat-button [class.w-50]="isMobile && !isRebalance" [class.w-100]="isMobile && (isRebalance || (isExit && selectedOption === 'Fully Exit'))" [disabled]='isButtonDisable' (click)="(isExit && selectedOption === 'Fully Exit') ? fullyExit() : placeBasketOrder(detailedBaskets)">
                <span translate>watchlist.confirm</span>
            </button>
        </div>
    </div>
</mat-dialog-actions>
<app-basketplaceordersummary *ngIf = "investSummry"></app-basketplaceordersummary>