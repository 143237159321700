import { PositionModel } from './position.model';
import { ScripModel } from './scrip.model';


export class Positions {
    PositionsModel: PositionModel[] = [];
    ScripModel: ScripModel[] = [];
    totalURPL: number;
    totalMtoM: number;
    totalRPL: number;
    NetValue: number;
    totalDayMtom: number;
    TotalURPLStr: string;
    TotalRPLStr: string;
    TotalMtoMStr: string;
    TotalDayMtomStr: string;
}
