import { Component, OnChanges, OnInit } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Preferences } from 'src/app/services/Preferences';
import { ActivatedRoute, Router } from '@angular/router';
import { BasketPlaceOrderSummaryComponent } from 'src/app/view/protradelite/thematicbasket/basketplaceordersummary/basketplaceordersummary.component';
import { MatDialog } from '@angular/material/dialog';
import { element } from 'protractor';
import { TradingRestService } from 'src/app/services/tradingrest.service';



let retrybasketorders: RetryBasketOrdersComponent;

@Component({
    selector: 'app-retrybasketorders',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/retrybasketorders/retrybasketorders.component.html',
})

export class RetryBasketOrdersComponent implements OnInit, OnChanges {

    isMobile = true;
    isButtonDisable;
    repairDataDetails;
    dataObj;
    batchNo;
    isLoading;
    scripModelArr = [];
    

    constructor(private snackBar: MatSnackBar, private PS: PtPlusRestService, private router: Router, private dialog: MatDialog, private activatedRoute: ActivatedRoute, private tradingRestService: TradingRestService) {
       
        retrybasketorders = this;
        this.isMobile = Utility.IS_MOBILE;

        this.repairDataDetails = Preferences.getItem('retryData');
        
        this.batchNo =  this.repairDataDetails[0].batch_no;
        for(let j = 0; j < this.repairDataDetails.length; j++){
            this.scripModelArr.push(Utility.getScripModel(this.repairDataDetails[j].scrip_token, this.repairDataDetails[j].exch_seg, this.tradingRestService));
        }
    }

    ngOnInit() {
      
    }

    ngOnChanges(){
       
    } 

    RepairLOrders() {
        retrybasketorders.isLoading = true;
        this.isButtonDisable = true;

        this.dataObj = {basket_id:  this.repairDataDetails[0].basket_id,
            basket_version: this.repairDataDetails[0].basket_version,
            placeorder_type: "RepairPurchase",
            batch_no: this.repairDataDetails[0].batch_no,
        };

        this.PS.getThematicBasketService().getRepairOrders(this.dataObj, this.RepairLOrdersResponse); 
    }

    RepairLOrdersResponse(data, code, message)
    {
        retrybasketorders.isLoading = false;
        retrybasketorders.isButtonDisable = false;

        if (code === 200 && message === 'OK')
        {
            Preferences.setItem('basketPlaceOrderData',data)
            if (Utility.IS_MOBILE) {
                retrybasketorders.router.navigate(['/basketplaceordersummary']);

            } else {
                Utility.dialogRef.close();
                Utility.openPage(retrybasketorders.router, 'retrybasketorders', 'basketplaceordersummary', BasketPlaceOrderSummaryComponent, retrybasketorders.dialog, 'popup', {}, '480px');
            }
        }
        else {
            retrybasketorders.snackBar.open(message, '', { duration: 2000, panelClass: ['errorSnackBar'] });
        }
    }
}

