import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Url } from '../url';

@Injectable()
export class SearchService {
    constructor(private apiService: ApiService) {
    }
    fetchData(counter, name, callback: Function) {
        let message = '';
        let data = [];
        return this.apiService.ptpluspost(Url.PTP_SEARCH, name).subscribe((result) => {
            if (result.data)
                data = result.data;
            callback(data, message, counter);
        }, error => {
            message = error;
            callback(data, message, counter);
        });
    }

    getTagsForText(data, callback:Function)
    {
        let message = '';
        let tags = [];
        const code = '';
        return this.apiService.ptpluspost(Url.PTP_TAGS_FOR_TEXT, data).subscribe((result) => {
        if (result.code === 200) {
            if (result.data && result.data.length) {
                tags = result.data;
            } else {
                message = 'NO DATA';
            }
        } else {
            message = result.message;
        }
        callback(result.code, tags, message);
        }, error => {
            message = error;
            callback(code, tags, message);
        });
    }
}





