export const environment = {
  production: true,
  needForceClear: false,
  // tradeurl: 'https://omnesystrade.refinitiv.com/PRE65_1_SCSI/',
  // tradewss: 'wss://omnesystrade.refinitiv.com/PRE65_1_SCSI/socket/stream',
  // isencryptionneeded: true,
  // isHypersync: false,
  // isQuickRest: false,
  suffixId: '',
  isExe: false,
  isInteropsEnabled: true,
  preferredExchange:['NSE', 'BSE'],
  BetaFeature: false,
  DefaultFeature: true,
  AdvanceFeature: false,
  BasketFeature: false,
  isGTTEnable: false,
  display_version: '1.0.0',
  version: '1.15.18',
  tradeurl: 'https://uathsint.hypertrade.in/',
  tradewss: 'wss://uathsmkt.hypertrade.in',
  orderwss: 'wss://uathsint.hypertrade.in',
  isencryptionneeded: false,
  loadFromTRKD: false,
  isHypersync: true,
  isQuickRest: true,
  isHoldingPriceEditable: true,
  contactList:[],
  isEdis: false,
  isIAM: true,
  urlIAM: 'https://uathsauth.hypertrade.in/',
  brokerId: '99991',
  apiKeyPTP: 'wvqXSq8dN5x1',
  BASKET_API_KEY: 'aTqCbkuBkAzXND8U',
  BASKET_SERVER_URL: 'https://uathsthematic.hypertrade.in',
  urlPTP: 'https://uathsplus.hypertrade.in',
  urlHTK: 'https://uathtrack.hypertrade.in',
  urlHSPARK: 'https://uathsspark.hypertrade.in/rest',
  apiKeyHSPARK: 'xdSX5Bxv77Mrqj6R',
  apiKeyHTK: 'wvqXSq8dN5x1',
  SEBIRNO: 'IND000021078',
  loginEnabledFor: 3, // 1 -> mob, 2 -> web 3 -> all
  enableSpreadOrder: false,
  isNotificationEnabled: false,
  cmId:"M50680",
  tmId:"08125",
  dpId:"61200",
  ticketSupport: false,
  ticketSupportUrl: "https://thriveadvisors.freshdesk.com/support/tickets/new",
  onboard: false,
  onboardurl:"https://uathsonboard.hypertrade.in/finspot-48c2c27838dd2052b20d65f8e9d39907",
  firebase : {
    apiKey: "AIzaSyAd1ZpCxnVz1SRDALGxwLfBxO6B5ODSaz8",
    authDomain: "pwapush-236d3.firebaseapp.com",
    databaseURL: "https://pwapush-236d3-default-rtdb.firebaseio.com",
    projectId: "pwapush-236d3",
    storageBucket: "pwapush-236d3.appspot.com",
    messagingSenderId: "250997406353",
    appId: "1:250997406353:web:f06449f539758595b4dd30",
    measurementId: "G-XWFLG0EWPX"
  },
  baseviewpath:'protradelite',
  backOfficeUrl: 'https://backoffice.horizonfinancial.co.in/',
  backOfficeType: 0, //0 -> no bak office, 1 -> share pro
  backOfficeSecType: 0, //0 -> silent login, 1 -> login,
  apiKeyBackOffice: 'ezFBQzE1QjQzLTJDNkYtNDA1RS1CRkZCLTNBQkUwNTNFM0YwMH0=',
  isPositionSquaerOff: false,
  isGoogleAnalytics: false,
  qprofileUrl: 'https://qprofile.fabits.app/ib-services',
  emandate:{
    enable:false,
    shortCode:'SHARESL',
    utilCode:'NACH00000000058441',
    aesKey:'k2hLr4X0ozNyZByj5DT66edtCEee1x+6',
    url:'https://emandate.hdfcbank.com/Emandate.aspx',
    channel:'Net',
    merchantCategoryCode:'U099'
  }
}; 