import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility, ChannelType } from '../../../services/utility';
import { ScripModel } from '../../../model/scrip.model';
import { TradingRestService } from '../../../services/tradingrest.service';
import { IScripService } from '../../../services/scrip/iscrip.service';
import { PlaceOrderModel } from '../../../model/place.order.model';
import { Keys } from '../../../services/keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { Subject } from '../../../../../node_modules/rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { element } from 'protractor';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { timingSafeEqual } from 'crypto';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { HoldingModel } from 'src/app/model/holding.model';
@Component({
  selector: 'app-placeorder',
  templateUrl: '../../'+environment.baseviewpath+'/placeorder/placeorder.component.html',
  styleUrls: ['./placeorder.component.scss'],
  animations: [
    trigger('slideInOut', [
        state('', style({

        })),
        transition('initial=>final', [
          style({ height: '0px', opacity: '0' }),
          animate('400ms ease-in', style({ height: '200px', opacity: '0.2' })),
        ]),
        transition('final=>initial', [
          animate('400ms ease-in', style({ height: '0px', opacity: '0' }))
        ]),
    ])
  ]
})
export class PlaceorderComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  viewstep=1;
  priceError = '';
  qtyError = '';
  balance: number;
  constructor(private activatedRoute: ActivatedRoute, private tradingRestService: TradingRestService,
    private snackBar: MatSnackBar, private location: Location, private _bottomSheet: MatBottomSheet, private translate: TranslateService, public dialog: MatDialog, private router: Router) {
    this.isMobile = Utility.IS_MOBILE;
    this.isPopup = Utility.IS_POPUP;
    this.scripService = tradingRestService.getScripService();
    this.balance = Utility.avl_margin;
    this.userdata = JSON.parse(Preferences.getPreference('userParameter'));
    if (environment.isQuickRest) {
      this.orarr = this.userdata['ordArr'];
    }
    else {
      this.orarr = this.userdata['orarr'];
    }
    this.GTDMindate = new Date();
    this.pom = new PlaceOrderModel();

    Utility.changeTranctionType.subscribe(ele =>{
      if(ele=='AB') {
        this.pom.Ttranstype = 'B';
        this.pom.AMO = 'YES';
      } else if (ele=='AS') {
        this.pom.Ttranstype = 'S';
        this.pom.AMO = 'YES';
      } else {
        this.pom.Ttranstype = ele;
        this.pom.AMO = 'NO';
      }
    })
    
    if (this.isEdisEnable){
      this.tradingRestService.getBookService().getAllHoldings(function(holdings, code, message) {
        if (code === 200 && message === 'Ok') {
          this.holdings = holdings;
          this.holdingList = holdings.HoldingsModel;
          this.holdingList.forEach(arrData => {
            this.holdingsMap.set(arrData.scripModel.Token + '_' + arrData.scripModel.ExchangeSegment, arrData.AvailableQty);
            this.holdingsMap1.set(arrData.scripModel.Token + '_' + arrData.scripModel.ExchangeSegment, arrData.Usedqty);
            this.holdingsMap2.set(arrData.scripModel.Token + '_' + arrData.scripModel.ExchangeSegment, (arrData.TotalHoldingQty - arrData.Usedqty));
          });
        }
      }.bind(this), null);
    }
    

  }
  scrpModel: ScripModel;
  transectionType: string;
  pom: PlaceOrderModel;
  dialogRef;
  holdings: any = {};
  holdingList: Array<HoldingModel>;
  productArr = [];
  advancedProductArray = [];
  orderTypeArray = ['L', 'MKT'];
  basicOrderTypeArray = ['L', 'MKT'];
  stopLossOrderTypeArray = ['SL', 'SL-M'];
  BOOrderTypeArray = ['L', 'SL'];
  orarr = [];
  scripService: IScripService;
  showAdvanced = false;
  allRetentionTypeArray = [];
  retentionTypeArray = [];
  isSL = false;
  isPO = true;
  isLot = false;
  ticksize;
  isDiscQty = true;
  GTDMindate;
  GTDVal;
  GTTval;
  GTDayaVal;
  daysArr = ['1', '2', '3', '4', '5', '6', '7'];
  discQtyValidationMsg;
  isEdit = false;
  orderData;
  isReverseOrder = false;
  isLTPCoverageCallInProgress = false;
  isCoveragePercentageCalled = false;
  isOrderType = true;
  isQtyDisable = false;
  isEnablePrice = true;
  isSquareOff = false;
  holdingData;
  reqCount = 0;
  isMktPro = false;
  userdata;
  isMobile = true;
  isAddRemove = false;
  isEdisEnable = environment.isEdis;
  @ViewChild('inputQty', { static: false }) inputQty: ElementRef;
  @ViewChild('inputPrice', { static: false }) inputPrice: ElementRef;
  @ViewChild('inputTriggerPrice', { static: false }) inputTriggerPrice: ElementRef;
  @ViewChild('discQty', { static: false }) discQty: ElementRef;
  @ViewChild('inputSquareOffvalue', { static: false }) inputSquareOffvalue: ElementRef;
  @ViewChild('inputStoplossValue', { static: false }) inputStoplossValue: ElementRef;
  @ViewChild('inputMktPro', { static: false }) inputMktPro: ElementRef;
  isPopup;
  verityType = 'RGLR';
  isProductType = true;
  orderObjArr = [];
  isOrderSummary = false;
  @Input() nodepth = false;
  @Input() omtkn: string;
  @Input() exseg: string;
  @Input() action: string;
  @Input() fromMultiView = false;
  isShowDepth = false;
  depthAnimation = 'initial'
  isDisableForm = false;
  submitType;
  squareOffvalue;
  stoplossValue;
  holdingsMap = new Map();
  holdingsMap1 = new Map();
  holdingsMap2 = new Map();
  lotError = true;
  filledQuantity = 0;
  isQtyUpdate = true;
  scripSubscription;
  disablePrice = false;
  disableTriggerPrice = false;
  tokenArray = [];
  isAllowExch = false;
  isInterop = false;
  scripArray = [];
  selectedExch;
  priceCondition;
  limitCondition1;
  limitCondition2;
  limitCondition3;
  numOfCondition = 0;
  lowBalance = false;
  isReorder = false;
  onetouch;
  ngOnChanges() {
    if (this.fromMultiView) {
      this.isPopup = true;
      const obj = { action: this.action, exseg: this.exseg, omtkn: this.omtkn };
      this.initPlaceorder(obj);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputQty.nativeElement.focus();
      this.inputQty.nativeElement.select()
    },100)
  }

  setDefaultProduct() {
    if(this.productArr.includes(Utility.PLACEORDERSETTINGSMODEL.productType)){
      this.pom.Pcode = Utility.PLACEORDERSETTINGSMODEL.productType;
    } else if(this.advancedProductArray.includes(Utility.PLACEORDERSETTINGSMODEL.productType)){
      this.pom.Pcode = Utility.PLACEORDERSETTINGSMODEL.productType;
    } else {
      this.pom.Pcode = this.productArr[0];
    }

    if(this.pom.Pcode === 'BO' || this.pom.Pcode === 'CO'){
      this.verityType = this.pom.Pcode;
    } else {
      this.verityType = 'RGLR';
    }
  }
  initPlaceorder(params) {
    this.onetouch = (Utility.PLACEORDERSETTINGSMODEL.onetouch === 'true') ? true: false;
    this.isDisableForm = false;
    this.isInterop = false;
    this.getData();
    if (params.isEdit) {
      this.isEdit = true;
      this.showAdvanced = true;
      this.orderData = Utility.detailedOrder;
      this.pom.Ttranstype = this.orderData.Action;
      this.pom.exch = this.orderData.Exchange;
      this.pom.Exchangeseg = this.orderData.Exseg;
      this.pom.Token = this.orderData.Token;
      this.scrpModel = Utility.getScripModel(this.pom.Token, this.pom.Exchangeseg, this.tradingRestService);
      this.updateOrderData();
      if (this.orderData.ProductCode == 'CO') {
        this.filterCOEditCondtions();
      }
      this.filledQuantity = this.orderData.FilledShares;
    }
    else if (params.isSquareOff) {
      this.isSquareOff = true;
      this.holdingData = Utility.holdingDetails;
      this.pom.Ttranstype = params['action'];
      this.pom.exch = Keys.key_map[params['exseg']];
      this.pom.Exchangeseg = params['exseg'];
      this.pom.Token = this.holdingData.Token;
      this.scrpModel = Utility.getScripModel(this.pom.Token, this.pom.Exchangeseg, this.tradingRestService);
      this.updateHoldingData();
    }
    else if (params.add || params.remove) {
      this.isAddRemove = true;
      this.pom.Ttranstype = params.add ? 'B' : 'S';
      this.pom.exch = params.Exchange;
      this.pom.Exchangeseg = Utility.exchangeToSegment(this.pom.exch);
      this.pom.Token = params.Token;
      this.pom.prctyp = params.OrderType;
      this.pom.Pcode = params.ProductCode;
      if (params.qty)
      {
        this.isQtyUpdate = false;
        this.pom.qty = Utility.getNumber(params.qty) < 0? (-1 * Utility.getNumber(params.qty)): params.qty;
      }
      if(params.Ret){
        this.isReorder = true;
        this.pom.Ret = params.Ret;
      }
      if(this.isMobile && this.isReorder && (this.pom.Pcode === 'CO' || this.pom.Pcode === 'BO' )){
        this.verityType = this.pom.Pcode;
      }
      this.scrpModel = Utility.getScripModel(this.pom.Token, this.pom.Exchangeseg, this.tradingRestService);
    }
    else {
      this.setDefaultProduct();
      this.pom.prctyp = Utility.PLACEORDERSETTINGSMODEL.orderType;
      if(params['action']=='AB') {
        this.pom.Ttranstype = 'B';
        this.pom.AMO = 'YES';
      } else if (params['action']=='AS') {
        this.pom.Ttranstype = 'S';
        this.pom.AMO = 'YES';
      } else {
        this.pom.Ttranstype = params['action'];
      }
      this.pom.exch = Keys.key_map[params['exseg']];
      this.pom.Exchangeseg = params['exseg'];
      this.pom.Token = params['omtkn'];
      this.scrpModel = Utility.getScripModel(this.pom.Token, this.pom.Exchangeseg, this.tradingRestService);
    }
    
    if (this.scrpModel.isInfoCalled) {
      this.getSecInfoRes(this.scrpModel, Keys.RES_CODE_SUCCESS, '');
    } else {
      this.scripService.getSecInfo({ 'exch': this.pom.exch, 'tk': this.pom.Token }, this.getSecInfoRes.bind(this));
    }      
  }

  getRetentionRes(data, code, message) {
    if (code === Keys.RES_CODE_SUCCESS) {
      this.allRetentionTypeArray = data;
      this.filterRetentionData();

      const preTradeValues = Preferences.getItem("preTradeValues", ReturnType.Undefined);
      
      if (preTradeValues) {
        if (preTradeValues['totalBuyAmt']) {
          this.priceCondition = preTradeValues['totalBuyAmt'];
        }
        if (this.scrpModel.segment === "FX") {
          if (this.scrpModel.Symbolname === "EURUSD" || this.scrpModel.Symbolname === "GBPUSD") {
            this.limitCondition1 = preTradeValues['eurValueLow'];
            this.limitCondition2 = preTradeValues['eurValueMedium'];
            this.limitCondition3 = preTradeValues['eurValueHigh'];
          }
          else {
            this.limitCondition1 = preTradeValues['currValueLow'];
            this.limitCondition2 = preTradeValues['currValueMedium'];
            this.limitCondition3 = preTradeValues['currValueHigh'];
          }
          this.numOfCondition = 3;
        }
        else if (this.scrpModel.segment === "DER") {
          if (this.scrpModel.InstrumentName === "OPTIDX" || this.scrpModel.InstrumentName === "OPTSTK") {
            this.limitCondition1 = preTradeValues['optionValueLow'];
            this.limitCondition2 = preTradeValues['optionValueHigh'];
            this.numOfCondition = 2;
          } else if (this.scrpModel.InstrumentName === "FUTSTK" ) {
            this.limitCondition1 = preTradeValues['stockValue'];
            this.numOfCondition = 1;
          } else if (this.scrpModel.InstrumentName === "FUTIDX" ) {
            this.limitCondition1 = preTradeValues['indexValue'];
            this.numOfCondition = 1;
          }
        }
      }
    }
    else {
      console.log('=============Empty Retention type array, Not able to placeorder==================')
      this.isPO = false;
    }
  }
  priceFormDepth(data) {
    if (this.pom.prctyp === 'L') {
      this.pom.Price = data;
    }
  }
  getLotWeightRes(data, code, message) {
    if (code === Keys.RES_CODE_SUCCESS && Object.keys(data).length) {
      if (Object.keys(data).includes(this.pom.exch) && data[this.pom.exch] === 'Lots')
        this.isLot = true;
      else
      {
        if (!this.isEdit && !this.isSquareOff && this.isQtyUpdate)
          setTimeout(function(){
            this.pom.qty = this.scrpModel.BodLotQuantity;
          }.bind(this), 1000);
      }
    } else {
      if (!this.isEdit && !this.isSquareOff && this.isQtyUpdate)
        this.pom.qty = this.scrpModel.BodLotQuantity;
    }
  }

  setPrdefinedLotWeight(data) {
    if (Object.keys(data).includes(this.pom.exch) && data[this.pom.exch] === 'Lots') {
      this.isLot = true;
    }
  }

  getSecInfoRes(data, code, message) {
    if (code === Keys.RES_CODE_SUCCESS) {
      // this.LCL = data.LCircuitLimit;
      // this.HCL = data.HCircuitLimit;
      
      if (!this.isEdit && environment.isInteropsEnabled && this.userdata.exDtl.EQUITY)
      {
        this.tokenArray = data.exchTokens;
        this.tokenArray = this.tokenArray.filter(ele => (!ele.ts.split('-').includes("BL") && !ele.ts.split('-').includes("AF")));
        let exArray = this.userdata.exDtl.EQUITY.map(ele => {
          return ele.exch
        });
        this.tokenArray = this.tokenArray.filter(ele => exArray.includes(ele.ex));
        if (this.tokenArray.length > 1)
        {
          this.isInterop = true;
          this.scripArray = [];
          this.scripArray = this.tokenArray.map(ele => Utility.getScripModel(ele.tk, ele.es, this.tradingRestService))
          setTimeout(function(){
            this.scrpModel = this.getInteropScrip();
            this.pom.exch = this.scrpModel.ExchangeName;
            this.pom.Exchangeseg = this.scrpModel.ExchangeSegment;
            this.pom.Token = this.scrpModel.Token;
            this.selectedExch = this.scrpModel.ExchangeName;
            this.initPoAfterInterOpCheck();
          }.bind(this), 650);
        }
        else
          this.initPoAfterInterOpCheck();
      }
      else
        this.initPoAfterInterOpCheck();
    }
  }

  getInteropScrip()
  {
    if (this.pom.Ttranstype === 'B')
    {
      if (Utility.getNumber(this.scripArray[0].BestSellPrice) === 0 && Utility.getNumber(this.scripArray[1].BestSellPrice) === 0)
        return this.scripArray[0];
      else if (Utility.getNumber(this.scripArray[0].BestSellPrice) === 0)
        return this.scripArray[1]
      else if (Utility.getNumber(this.scripArray[1].BestSellPrice) === 0)
        return this.scripArray[0];
      else
        return (Utility.getNumber(this.scripArray[0].BestSellPrice) <= Utility.getNumber(this.scripArray[1].BestSellPrice) ? this.scripArray[0] : this.scripArray[1]);
    }
    else
    {
      if (Utility.getNumber(this.scripArray[0].BestBuyPrice) === 0 && Utility.getNumber(this.scripArray[1].BestBuyPrice) === 0)
        return this.scripArray[0];
      else if (Utility.getNumber(this.scripArray[0].BestBuyPrice) === 0)
        return this.scripArray[1]
      else if (Utility.getNumber(this.scripArray[1].BestBuyPrice) === 0)
        return this.scripArray[0];
      else
        return (Utility.getNumber(this.scripArray[0].BestBuyPrice) >= Utility.getNumber(this.scripArray[1].BestBuyPrice) ? this.scripArray[0] : this.scripArray[1]);
    }
  }

  initPoAfterInterOpCheck()
  {
    this.scripService.showQuote(this.scrpModel, true, this.showQuoteResponse.bind(this));
    this.filterProducts(this.userdata, this.pom.Exchangeseg);
    this.scripService.getLotWeight(this.getLotWeightRes.bind(this), this.setPrdefinedLotWeight.bind(this));
    this.filterExchDepencies();
    this.filterOrderTypeArray();  
    if(this.productArr.length > 0)
      this.scripService.getRetention(this.pom.exch, this.getRetentionRes.bind(this));
    else {
      console.log('=============Empty product type array, Not able to placeorder==================');
      this.isPO = false;
    }
    
    if (this.scrpModel.Token == 'asdfg') {
      this.isDisableForm = true;
    }

    this.scripSubscription = this.scrpModel.scripUpdate.subscribe(ele =>{
      if (this.pom.prctyp !== 'MKT' && this.pom.prctyp !== 'SL-M' && Utility.getNumber(this.pom.Price) === 0)
      {
          if (this.pom.Ttranstype === 'B')
            this.pom.Price = this.scrpModel.BestSellPrice;
          else
            this.pom.Price = this.scrpModel.BestBuyPrice;
      }
      this.scripSubscription.unsubscribe();
    })
    if(this.isMobile && this.isReorder && (this.pom.Pcode === 'CO' || this.pom.Pcode === 'BO' )){
      setTimeout(() => {
        this.onVerityTypeChange();
      }, 10);
    }
  }
  showQuoteResponse(sm, code, msg) {
    this.scrpModel.BodLotQuantity = sm.BodLotQuantity;
    this.pom.Tsym = this.scrpModel.TradingSymbol;
    // this.pom.exch = this.scrpModel.ExchangeName;
    if (!this.isEdit && !this.isSquareOff && !this.isAddRemove) {
      if (this.pom.Ttranstype === 'B')
        this.pom.Price = this.scrpModel.BestSellPrice;
      else
        this.pom.Price = this.scrpModel.BestBuyPrice;
    }

    if (this.scrpModel.NoMarketProtectionFlag) {
      this.isMktPro = false;
      if (this.pom.prctyp === 'MKT' || this.pom.prctyp === 'SL-M')
        this.pom.MktPro = '0';
      else
        this.pom.MktPro = 'NA';
    }

    if (this.scrpModel.MarketProtection) {
      if (this.pom.prctyp === 'MKT' || this.pom.prctyp === 'SL-M') {
        this.isMktPro = true;
        this.isEnablePrice = false;
        this.pom.MktPro = this.scrpModel.DefaultMarketProtectionValue;
      }
    }

    this.ticksize = Utility.getTickSize(this.scrpModel.TickSize, this.scrpModel.Multiplier, this.scrpModel.DecimalPrecision);
  }
  ngOnInit() {
    if (!this.fromMultiView) {
      if (Utility.IS_MOBILE) {
        this.activatedRoute.queryParams.subscribe(params => {
          this.initPlaceorder(params);
        });
      }
      else {
        this.initPlaceorder(Utility.dialogData);
      }
    }
    this.onAppearing();
  }
  changeTransectionType(trtype) {
    this.pom.Ttranstype = trtype;
    if (this.pom.Pcode === 'CO')
      this.calculateCOTriggerRabge();
  }

  placeOrder() {
    // this.isPO = false;
    if (!this.isEdit){
      if (!this.isEdisEnable || !this.holdingsMap.has(this.scrpModel.Token + '_' + this.scrpModel.ExchangeSegment)){
        this.submitType = 'placeorder';
        this.showAlert();
      }
      else{
        if(Utility.edisAuthorizationCheck(this.pom.Pcode, this.holdingsMap2.has(this.scrpModel.Token + '_' + this.scrpModel.ExchangeSegment), this.pom.Ttranstype, this.scrpModel.Token, this.scrpModel.ExchangeSegment, (this.holdingsMap1.has(this.scrpModel.Token + '_' + this.scrpModel.ExchangeSegment)))) {
          this.submitType = 'placeorder';
          this.showAlert();
        }
        else{
          let hld = parseInt(this.holdingsMap2.get(this.scrpModel.Token + '_' + this.scrpModel.ExchangeSegment), 10);
          let usd = parseInt(this.holdingsMap1.get(this.scrpModel.Token + '_' + this.scrpModel.ExchangeSegment), 10);
          let avail:number = hld - usd;
          if(parseInt(this.pom.qty, 10) > avail){
            this.snackBar.open(this.translate.instant('all.lessthen') + avail + '.', this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
          }
          else {
            Utility.edisAuthorize(this.holdingList, window.location.hostname,this.tradingRestService);
          }
        }
      }
    }
    else
    {
      // this.pom.qty = Utility.getNumber(this.pom.qty) + Utility.getNumber(this.filledQuantity);
      this.submitType = 'modify';
      this.showAlert();
    }
  }

  filterProducts(userdata, exch) {
    this.productArr = [];
    this.advancedProductArray = [];
    let exchDeatilObj;
    if (environment.isQuickRest) {
      exchDeatilObj = userdata.exDtl;
    }
    else {
      exchDeatilObj = userdata.exchDeatil;
    }
    Object.keys(exchDeatilObj).forEach(el => {
      const arr = exchDeatilObj[el];
      let products;
      for (let i = 0; i < arr.length; i++) {
        if (environment.isQuickRest) {
          if (arr[i].exSeg === exch) {
            products = arr[i].prod;
            // if(userdata['prodArr'].includes('CNC')){
            //   this.productArr.push('CNC');
            // }
            for (let j = 0; j < userdata['prodArr'].length; j++) {
              const ele = userdata['prodArr'][j];
              if (products.indexOf(ele) !== -1) {
                if (ele === 'BO' || ele === 'CO')
                  this.advancedProductArray.push(ele);
                else {
                  this.productArr.push(ele);
                  // if (ele!='CNC') {
                  // }
                }
              }
            }
            break;
          }
        }
        else {
          if (arr[i].exchseg === exch) {
            products = arr[i].product;
            for (let j = 0; j < userdata['prarr'].length; j++) {
              const ele = userdata['prarr'][j];
              if (products.indexOf(ele) !== -1) {
                if (ele === 'BO' || ele === 'CO')
                  this.advancedProductArray.push(ele);
                else
                  this.productArr.push(ele);
              }
            }
            break;
          }
        }
      }
    });

    if (!this.isMobile)
      this.productArr = this.productArr.concat(this.advancedProductArray);

    if (!this.isEdit && !this.isSquareOff && !this.isAddRemove)
    {
      if (this.productArr.length === 0)
      {
        console.log('=============Empty product type array, Not able to placeorder==================')
        this.isPO = false;
      }

      this.setDefaultProduct();
    }
  }

  onAdvanced() {
    if (!this.showAdvanced)
      this.showAdvanced = true;
    else
      this.showAdvanced = false;
  }

  filterRetentionData() {
    this.isPO = true;
    this.retentionTypeArray = [];
    if (this.pom.Pcode === Keys.PRODUCT_CO) {
      this.retentionTypeArray.push(Keys.RETENTION_DAY);
    }
    else {
      Array.prototype.push.apply(this.retentionTypeArray, this.allRetentionTypeArray);

      if (this.isSL && this.retentionTypeArray.includes(Keys.RETENTION_IOC)) {
        this.retentionTypeArray.splice(this.retentionTypeArray.indexOf(Keys.RETENTION_IOC), 1);
      }
    }

    if (this.pom.Pcode === Keys.PRODUCT_BO && this.pom.exch === Keys.EXCHANGE_BSE && this.retentionTypeArray.includes(Keys.RETENTION_EOS))
    {
      this.retentionTypeArray.splice(this.retentionTypeArray.indexOf(Keys.RETENTION_EOS), 1);
    }

    if (this.retentionTypeArray.length) {
      if(!this.isReorder)
        this.pom.Ret = this.orderData ? this.orderData.Validity : this.retentionTypeArray[0];
    }
    else
    {
      console.log('=============Empty Retention type array, Not able to placeorder==================')
      this.isPO = false;
    }
    this.filterExchDepencies();
  }

  onAMO(ev) {
    if (ev.checked)
      this.pom.AMO = 'YES';
    else
      this.pom.AMO = 'NO';
  }

  onOrderTypeChange(ev) {
    this.orderTypeDependencies(ev.value)

    this.filterRetentionData();
    // this.filterOrderTypeArray();
  }

  orderTypeDependencies(value)
  {
    this.disablePrice = false;
    if (value === 'MKT' || value === 'SL-M') {
      this.pom.Price = '0';
      this.isEnablePrice = false;
      this.isMktPro = true;
      if (this.isMktPro)
        this.pom.MktPro = this.scrpModel.DefaultMarketProtectionValue;
      else
        this.pom.MktPro = '0';
    }
    else {
      if (this.pom.Price === '0' && !this.isEdit && !this.isSquareOff && !this.isAddRemove) {
        if (this.pom.Ttranstype === 'B')
          this.pom.Price = this.scrpModel.BestSellPrice;
        else
          this.pom.Price = this.scrpModel.BestBuyPrice;
      }
      this.isEnablePrice = true;
      this.isMktPro = false;
      this.pom.MktPro = 'NA';
    }

    if (value === 'SL' || value === 'SL-M')
    {
      this.isSL = true;
    }
    else
      this.isSL = false;

    if ((this.isSL || this.pom.Pcode === 'CO') && this.orderData && this.orderData.Status.toLowerCase() === 'trigger pending')
    {
      this.disablePrice = (!this.isSL)?true:false;
      this.disableTriggerPrice = false;
    }
  }

  onStopLoss(ev) {
    if (ev.checked) {
      this.isSL = true;
      // if (this.pom.prctyp === 'L')
      //   this.pom.prctyp = 'SL';
      // else if (this.pom.prctyp === 'MKT')
      //   this.pom.prctyp = 'SL-M';
    }
    else {
      this.isSL = false;
      // if (this.pom.prctyp === 'SL')
      //   this.pom.prctyp = 'L';
      // else if (this.pom.prctyp === 'SL-M')
      //   this.pom.prctyp = 'MKT';
    }

    this.filterRetentionData();
    this.filterOrderTypeArray();
  }

  validateQty(ev) {
    const regex = new RegExp('^[0-9]{0,7}$|^[0-9]{1,' + 2 + '}$');
    const val = this.inputQty.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.key, 'qty')
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputQty.nativeElement.dispatchEvent(new Event('input'));
      this.inputQty.nativeElement.setSelectionRange(index - 1, index - 1);
    }

    this.onSubmitForm(ev)
  }

  validatePrice(ev) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputPrice.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      if (this.pom.Price === '.')
      {
        this.pom.Price = '0.'
      }
      else
      {
        this.validateKey(ev.key, 'price');
        ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
        this.inputPrice.nativeElement.dispatchEvent(new Event('input'));
        this.inputPrice.nativeElement.setSelectionRange(index - 1, index - 1);
      }
    }

    this.onSubmitForm(ev)
  }

  validateKey(key, str)
  {
    if (/[0-9]/i.test(key))
    {
      if (str === 'price')
        this.snackBar.open(this.translate.instant('all.max_bfr_digits') +this.scrpModel.DecimalPrecision + this.translate.instant('all.max_aft_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      else if (str === 'mkt')
        this.snackBar.open(this.translate.instant('all.max_two_digits'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      else
        this.snackBar.open(this.translate.instant('all.max_sevn_digits'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (/[a-zA-Z]/i.test(key))
      this.snackBar.open(this.translate.instant('all.only_digits'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
  }

  validateTriggerPrice(ev) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputTriggerPrice.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.key, 'price');
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputTriggerPrice.nativeElement.dispatchEvent(new Event('input'));
      this.inputTriggerPrice.nativeElement.setSelectionRange(index - 1, index - 1);
    }

    this.onSubmitForm(ev)
  }

  validateMKTPro(ev)
  {
    const regex = new RegExp('^[0-9]{0,' + 2 + '}$|^[0-9]{1,' + 2 + '}[.]{1,1}[0-9]{0,' + 2 + '}$');
    const val = this.inputMktPro.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex) || Utility.getNumber(val) > 99) {
      this.validateKey(ev.key, 'mkt')
      if (Utility.getNumber(val) > 99)
        this.snackBar.open(this.translate.instant('all.max_mrktprotect'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputMktPro.nativeElement.dispatchEvent(new Event('input'));
      this.inputMktPro.nativeElement.setSelectionRange(index - 1, index - 1);
    }

    this.onSubmitForm(ev)
  }

  validateDiscQty(ev) {
    const regex = new RegExp('^[0-9]{0,8}$|^[0-9]{1,' + 2 + '}$');
    const val = this.discQty.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.key, 'qty')
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.discQty.nativeElement.dispatchEvent(new Event('input'));
      this.discQty.nativeElement.setSelectionRange(index - 1, index - 1);
    }

    this.onSubmitForm(ev) 
  }

  validateSquareOffvalue(ev) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputSquareOffvalue.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.keyCode, 'price');
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputSquareOffvalue.nativeElement.dispatchEvent(new Event('input'));
      this.inputSquareOffvalue.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  validateStoplossValue(ev) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    const val = this.inputStoplossValue.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.keyCode, 'price');
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      this.inputStoplossValue.nativeElement.dispatchEvent(new Event('input'));
      this.inputStoplossValue.nativeElement.setSelectionRange(index - 1, index - 1);
    }
  }

  placeOrderRes(data, code, message) {
    // this.isPO = true;
    if (code === Keys.RES_CODE_SUCCESS) {
      // this.isOrderSummary = true;
      Utility.ORDER_PLACED_EVENT.next('true');
      if(!this.onetouch){
        this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
          if (resCode === Keys.RES_CODE_SUCCESS) {
            if (environment.isQuickRest) {
              this.orderObjArr = list.filter(el => (el.NestOrd === data.nOrdNo || el.SyomOrderId.split('|')[0] === data.nOrdNo));
            }
            else {
              this.orderObjArr = list.filter(el => (el.NestOrd === data.NOrdNo || el.SyomOrderId.split('|')[0] === data.NOrdNo));
            }
          }
        }.bind(this), this.setPreDefinedOrders.bind(this));
      } else {
        this.snackBar.open(this.translate.instant('all.ord_place_success') + data.nOrdNo, this.translate.instant('all.close'), { duration: 2000, panelClass: ['successSnackBar'] });
        if(this.isMobile){
          this.location.back();
        } else {
          Utility.dialogRef.close()
        }
      }
    }
    else {
      this.snackBar.open(message, this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  setPreDefinedOrders() { }

  floatSafeModulus(val, step) {
    if (val && step)
    {
      const valDecCount = (val.toString().split('.')[1] || '').length;
      const stepDecCount = (step.toString().split('.')[1] || '').length;
      const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
      let valInt;
      if (val !== undefined && val !== null && val !== NaN)
        valInt = parseInt(Utility.getNumber(val).toFixed(decCount).replace(/\./g, ''), 10);
      const stepInt = parseInt(Utility.getNumber(step).toFixed(decCount).replace(/\./g, ''), 10);
      return (valInt % stepInt) / Math.pow(10, decCount);
    }
    else
      return 0;
  }

  onRetentionChange(ev) {
    this.filterExchDepencies();
  }

  filterExchDepencies() {
    if ((this.pom.exch === Keys.EXCHANGE_NFO || this.pom.exch === Keys.EXCHANGE_BFO) || (this.pom.Pcode === Keys.PRODUCT_CO || this.pom.Pcode === Keys.PRODUCT_BO) ||
      this.pom.Ret === 'IOC') {
      this.pom.discqty = '0';
      this.isDiscQty = false;
    }
    else
      this.isDiscQty = true;
  }

  onSubmit() {
    this.priceError = '';
    this.qtyError = '';
    const total_price = (this.isEnablePrice ? Utility.getNumber(this.pom.Price) : Utility.getNumber(this.scrpModel.LastTradePrice)) * Utility.getNumber(this.pom.qty)* (Utility.IsLot(this.scrpModel.ExchangeSegment) ? this.scrpModel.BodLotQuantity : 1);
    if (!this.pom.qty || !this.checkQty(this.pom.qty))
    {
      this.qtyError = 'Enter Valid Quantity.';
      this.snackBar.open(this.translate.instant('all.invalid_qty'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isEnablePrice && !this.disablePrice && (!this.pom.Price || !this.checkPriceLength(this.pom.Price)))
    {
      this.priceError = 'Enter Valid Price.';
      this.snackBar.open(this.translate.instant('all.invalid_price'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isMktPro && (!this.pom.MktPro || Utility.getNumber(this.pom.MktPro) > 99 || !this.checkMktPro(this.pom.MktPro)))
    {
      this.snackBar.open(this.translate.instant('all.invalid_mktpro'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (!this.isLot && Utility.getNumber(this.scrpModel.BodLotQuantity) > 1 &&
      this.floatSafeModulus(Utility.getNumber(this.pom.qty), Utility.getNumber(this.scrpModel.BodLotQuantity)) !== 0) {
        this.qtyError = 'Quantity should be multiple of' + this.scrpModel.BodLotQuantity + '.';
      this.snackBar.open(this.translate.instant('all.qtymultiple') + this.scrpModel.BodLotQuantity + '.', this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isEnablePrice && this.pom.AMO!=='YES' && !this.disablePrice && (this.scrpModel.HCircuitLimit && this.scrpModel.LCircuitLimit) && (Utility.getNumber(this.pom.Price) < Utility.getNumber(this.scrpModel.LCircuitLimit) ||
      Utility.getNumber(this.pom.Price) > Utility.getNumber(this.scrpModel.HCircuitLimit))) {
        this.priceError = 'Price should between ' + this.scrpModel.LCircuitLimit + ' & ' + this.scrpModel.HCircuitLimit + '.';
      this.snackBar.open(this.translate.instant('all.pricebtn') + this.scrpModel.LCircuitLimit + ' & ' + this.scrpModel.HCircuitLimit + '.', this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isEnablePrice && !this.disablePrice && (this.floatSafeModulus(this.pom.Price, this.ticksize) !== 0)) {
      this.priceError = 'Price should be multiple of ' + this.ticksize + '.';
      this.snackBar.open(this.translate.instant('all.pricemultiple') + this.ticksize + '.', this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isSL && (this.scrpModel.HCircuitLimit && this.scrpModel.LCircuitLimit) && (Utility.getNumber(this.pom.TrigPrice) < Utility.getNumber(this.scrpModel.LCircuitLimit) ||
      Utility.getNumber(this.pom.TrigPrice) > Utility.getNumber(this.scrpModel.HCircuitLimit))) {
        this.priceError = 'Trigger price should between ' + this.scrpModel.LCircuitLimit + ' & ' + this.scrpModel.HCircuitLimit + '.';
      this.snackBar.open(this.translate.instant('all.trigpricebtn') + this.scrpModel.LCircuitLimit + ' & ' + this.scrpModel.HCircuitLimit + '.', this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (Utility.getNumber(this.pom.qty) <= 0) {
      this.qtyError = 'Quantity cannot be less than or equal to 0.';
      this.snackBar.open(this.translate.instant('all.qtyequal'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isEnablePrice && !this.disablePrice && Utility.getNumber(this.pom.Price) <= 0) {
      this.priceError = 'Price cannot be less than or equal to 0.';
      this.snackBar.open(this.translate.instant('all.priceequal'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isSquareOff && this.pom.qty > this.holdingData.AvailableQty) {
      this.qtyError = 'Quantity should be less than'  + this.holdingData.AvailableQty;
      this.snackBar.open(this.translate.instant('all.qtyless') + this.holdingData.AvailableQty, this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if ((((this.pom.Pcode == 'CO' || this.isSL) && (this.isEdit? this.pom.Status.toLowerCase() != 'open': true))) && !this.disableTriggerPrice && (!Utility.getNumber(this.pom.TrigPrice) || !this.checkPriceLength(this.pom.TrigPrice))) {
      this.snackBar.open(this.translate.instant('all.invalidtrigger'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if ((this.pom.Pcode === 'CO' && (this.isEdit? this.pom.Status.toLowerCase() != 'open': true)) && !this.disableTriggerPrice && (Utility.getNumber(this.pom.TrigPrice) > Utility.getNumber(this.scrpModel.TriggerRangeEnd) || Utility.getNumber(this.pom.TrigPrice) < Utility.getNumber(this.scrpModel.TriggerRangeStart))) {
      this.snackBar.open(this.translate.instant('all.triggerRange'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if ((this.isSL || (this.pom.Pcode === 'CO' && !this.isEdit)) && !this.disableTriggerPrice && this.pom.Ttranstype === 'B' && this.isEnablePrice && Utility.getNumber(this.pom.TrigPrice) > Utility.getNumber(this.pom.Price)) {
        this.snackBar.open(this.translate.instant('all.triggerbuy'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if ((this.isSL || (this.pom.Pcode === 'CO' && !this.isEdit)) && !this.disableTriggerPrice && this.pom.Ttranstype === 'S' && this.isEnablePrice && Utility.getNumber(this.pom.TrigPrice) < Utility.getNumber(this.pom.Price)) {
      this.snackBar.open(this.translate.instant('all.triggersell'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if ((((this.pom.Pcode == 'CO' || this.isSL) && !this.disableTriggerPrice && (this.isEdit? this.pom.Status.toLowerCase() != 'open': true))) && (this.floatSafeModulus(this.pom.TrigPrice, this.ticksize) !== 0)) {
      this.snackBar.open(this.translate.instant('all.triggerpricemultiple') + this.ticksize + '.', this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if(!this.pom.Ret)
    {
      this.snackBar.open(this.translate.instant('all.invalid_retention'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.isDiscQty && (this.discQtyValidationMsg = Utility.validateDiscQuantity(this.pom.qty, this.pom.discqty, this.pom.exch)) !== '') {
      this.snackBar.open(this.translate.instant(this.discQtyValidationMsg), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.pom.Pcode === 'BO' && !this.isEdit) {
      this.placeBOOrder();
    }
    else if (this.pom.Ret === 'GTD' || this.pom.Ret === 'GTT' || this.pom.Ret === 'GTDys' || this.pom.prctyp === 'SL-M') {
      if (this.pom.prctyp === 'SL-M') {
        this.validateSLM();
      }
      else if (this.pom.Ret === 'GTD' || this.pom.Ret === 'GTT' || this.pom.Ret === 'GTDys') {
        this.validateGTD();
      }
    }
    else if(environment.baseviewpath === "investor" && total_price > Utility.avl_margin && this.pom.Ttranstype === 'B') {
      this.lowBalance = true;
      if(!this.isMobile){
        this.snackBar.open(this.translate.instant('all.less_balance'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
      }
    }
    else if (this.numOfCondition > 0 && this.isEnablePrice) {
      let percentValue: number;
      if (this.numOfCondition === 1) {
        percentValue = ((this.scrpModel.LastTradePrice * this.limitCondition1) / 100);
      }
      else if (this.numOfCondition === 2) {
        percentValue =  (this.scrpModel.LastTradePrice <= 50) ? ((this.scrpModel.LastTradePrice * this.limitCondition1) / 100) : ((this.scrpModel.LastTradePrice * this.limitCondition2) / 100);
      }
      else if (this.numOfCondition === 3) {
        if (this.scrpModel.Symbolname === "EURUSD" || this.scrpModel.Symbolname === "GBPUSD") {
          if (this.scrpModel.LastTradePrice <= 0.0100) {
            percentValue = this.limitCondition1;
          } 
          else if (0.0101 <= this.scrpModel.LastTradePrice && this.scrpModel.LastTradePrice <= 0.0200){
            percentValue = this.limitCondition2;
          } 
          else if (this.scrpModel.LastTradePrice > 0.0200){
            percentValue = ((this.scrpModel.LastTradePrice * this.limitCondition3) / 100);
          }
        }
        else {
          if (this.scrpModel.LastTradePrice <= 0.2500)
            percentValue = this.limitCondition1;
          else if (0.2501 <= this.scrpModel.LastTradePrice && this.scrpModel.LastTradePrice <= 0.5000)
            percentValue = this.limitCondition2;
          else if (this.scrpModel.LastTradePrice > 0.5000)
            percentValue = ((this.scrpModel.LastTradePrice * this.limitCondition3) / 100);
        }
      }

      let message = '';
      let isError = false;
      if (this.pom.Ttranstype === "B" &&  Utility.getNumber(this.pom.Price) >= (percentValue +  Utility.getNumber(this.scrpModel.LastTradePrice))) {
        message = this.translate.instant('all.exceedinglimit');
        isError = true;
      }
      else if (this.pom.Ttranstype === "S" && Utility.getNumber(this.pom.Price) <= (Utility.getNumber(this.scrpModel.LastTradePrice) - percentValue)) {
        message = this.translate.instant('all.exceedinglimit');
        isError = true;
      }

      if (isError) {
        this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.exceedinglimit_hed'), message: message, type: "placeorder" } });
        this.dialogRef.afterClosed().subscribe(result => {
          if (result === 'confirm') {
            this.placeOrder();
            this.onConfirm();
          }
        }).bind(this);
      } else if ((Utility.getNumber(this.pom.Price) * Utility.getNumber(this.pom.qty) * (Utility.IsLot(this.scrpModel.ExchangeSegment) ? this.scrpModel.BodLotQuantity : 1)) > this.priceCondition) {
          this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.exceedinglimit_hed'), message: this.translate.instant('all.exceedingprice'), type: "placeorder"}});
          this.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
              this.placeOrder();
              this.onConfirm();
            }
          }).bind(this);
      } 
      else if (this.isEnablePrice && this.pom.AMO ==='YES' && !this.disablePrice && (this.scrpModel.HCircuitLimit && this.scrpModel.LCircuitLimit) && (Utility.getNumber(this.pom.Price) < Utility.getNumber(this.scrpModel.LCircuitLimit) ||
        Utility.getNumber(this.pom.Price) > Utility.getNumber(this.scrpModel.HCircuitLimit))) {
          this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { message: this.translate.instant('placeorder.amopricenotinrange'), type: "placeorder"}});
          this.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
              this.placeOrder();
              this.onConfirm();
            }
          }).bind(this);
      }
      else {
        this.placeOrder();
        if(this.onetouch){
          this.onConfirm();
        }
      }
    }
    else if ((this.isEnablePrice ? Utility.getNumber(this.pom.Price) : Utility.getNumber(this.scrpModel.LastTradePrice)) * Utility.getNumber(this.pom.qty) * 
    (Utility.IsLot(this.scrpModel.ExchangeSegment) ? this.scrpModel.BodLotQuantity : 1) > this.priceCondition) {
      this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.exceedinglimit_hed'), message: this.translate.instant('all.exceedingprice'), type: "placeorder"}});
      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') {
          this.placeOrder();
          this.onConfirm();
        }
      }).bind(this);
    }
    else if (this.isEnablePrice && this.pom.AMO ==='YES' && !this.disablePrice && (this.scrpModel.HCircuitLimit && this.scrpModel.LCircuitLimit) && (Utility.getNumber(this.pom.Price) < Utility.getNumber(this.scrpModel.LCircuitLimit) ||
        Utility.getNumber(this.pom.Price) > Utility.getNumber(this.scrpModel.HCircuitLimit))) {
          this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { message: this.translate.instant('placeorder.amopricenotinrange'), type: "placeorder"}});
          this.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
              this.placeOrder();
              this.onConfirm();
            }
          }).bind(this);
    }
    else {
      this.placeOrder();
      if(this.onetouch){
        this.onConfirm();
      }
    }
  }

  openSettings() {
    if (!this.isMobile) {
      Utility.dialogRef.close();     
      Utility.openPage(this.router, 'placeorder', 'generalSettingsComponent', GeneralSettingsComponent,
      this.dialog, 'popup', {tabIndex: 1}, '480px', '500px');
    } else {
      this.router.navigate(['/generalsettings'], {queryParams: {'tabIndex': 1}});
    }
  }

  validateGTD() {
    let tempGTDVal;
    if (this.pom.Ret === 'GTD') {
      if (!this.GTDVal) {
        this.snackBar.open(this.translate.instant('all.invaliddate'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        return 0;
      }
      else {
        this.GTDVal = new Date(this.GTDVal);
        const day = this.GTDVal.getDate() < '10' ? '0' + this.GTDVal.getDate() : this.GTDVal.getDate();
        const month = (Utility.getNumber(this.GTDVal.getMonth()) + 1) < '10' ? '0' + (Utility.getNumber(this.GTDVal.getMonth()) + 1) : (Utility.getNumber(this.GTDVal.getMonth()) + 1);
        const year = this.GTDVal.getFullYear();
        tempGTDVal = month + '/' + day + '/' + year;
      }
    }
    else if (this.pom.Ret === 'GTDys') {
      tempGTDVal = this.GTDayaVal;
    }
    else if (this.pom.Ret === 'GTT') {
      if (!this.GTTval) {
        this.snackBar.open(this.translate.instant('all.invalidtime'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        return 0;
      }
      else {
        tempGTDVal = this.GTTval + ':00';
      }
    }

    this.reqCount++;
    const data = { 'Exchange': this.pom.exch, 'Expdate': this.scrpModel.ExpiryDate, 'Retention': this.pom.Ret, 'Gtdval': tempGTDVal }
    this.scripService.validateGTD(data, this.validateGTDRes.bind(this))
  }

  validateGTDRes(data, code, message) {
    this.reqCount--;
    if (code === Keys.RES_CODE_SUCCESS) {
      this.pom.DateDays = data;
      if (this.reqCount === 0)
        this.placeOrder();
    }
    else {
      this.snackBar.open(message, this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  updateOrderData() {
    this.pom.Price = this.orderData.Price;
    this.pom.qty = this.orderData.PendingQty;
    this.pom.Pcode = this.orderData.ProductCode;
    if (this.pom.Pcode === 'BO') {
      this.applyBOModifyConditions();
    }
    this.pom.prctyp = this.orderData.OrderType;
    if (this.pom.prctyp === 'SL' || this.pom.prctyp === 'SL-M')
      this.isSL = true;
    this.pom.discqty = this.orderData.DiscQty.toString();
    this.pom.Ret = this.orderData.Validity;
    this.pom.Nstordno = this.orderData.NestOrd;
    this.pom.Filledqty = this.orderData.FilledShares.toString();
    this.pom.Scripname = this.orderData.ScripName;
    this.pom.Symbol = this.orderData.SymbolName;
    this.pom.Status = this.orderData.Status;
    this.pom.Token = this.orderData.Token;
    this.pom.RequestID = this.orderData.RequestID;
    this.pom.Tsym = this.orderData.TradeSymbol;
    this.pom.TrigPrice = this.orderData.TriggerPrice;

    
    if ((this.isSL || this.pom.Pcode === 'CO') && this.orderData.Status.toLowerCase() === 'trigger pending')
    {
      this.disablePrice = (!this.isSL)?true:false;
      this.disableTriggerPrice = false;
    }
  }

  modifyOrderRes(data, code, message) {
    // this.isPO = true;
    if (code === Keys.RES_CODE_SUCCESS) {
      // this.isOrderSummary = true;
      Utility.ORDER_PLACED_EVENT.next('true');
      if(!this.onetouch){
        this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
          if (resCode === Keys.RES_CODE_SUCCESS) {
            if (environment.isQuickRest) {
              this.orderObjArr = list.filter(el => (el.NestOrd === data.nOrdNo || el.SyomOrderId.split('|')[0] === data.nOrdNo));
            }
            else {
              this.orderObjArr = list.filter(el => (el.NestOrd === data.NOrdNo || el.SyomOrderId.split('|')[0] === data.NOrdNo));
            }
          }
        }.bind(this), this.setPreDefinedOrders.bind(this));
      } else {
        this.snackBar.open(this.translate.instant('all.ord_modify_success') + data.nOrdNo, this.translate.instant('all.close'), { duration: 2000, panelClass: ['successSnackBar'] });
        if(this.isMobile){
          this.location.back();
        } else {
          Utility.dialogRef.close()
        }
      }
    }
    else {
      this.snackBar.open(message, this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  onProductTypeChange() {
    if (this.pom.Pcode === 'CO') {
      this.pom.AMO = 'NO';
      this.calculateCOTriggerRabge();
    } else if (this.pom.Pcode === 'BO') {
      this.calculateBOValues();
    }
    this.filterOrderType();
    this.orderTypeDependencies(this.pom.prctyp);
    this.filterRetentionData();
    this.filterExchDepencies();
  }

  calculateBOValues() {
    const value = this.scrpModel.LastTradePrice;
    const sqrOffPer = (Utility.getNumber(Utility.PLACEORDERSETTINGSMODEL.SqrOffPercentage) / 100) * Utility.getNumber(value);
    const stopLossPer = (Utility.getNumber(Utility.PLACEORDERSETTINGSMODEL.stoplossPercentage) / 100) * Utility.getNumber(value);
    let sqrOffvalue: any;
    let slvalue: any;
    if (this.pom.Ttranstype === 'B') {
      sqrOffvalue = (Utility.getNumber(value) + sqrOffPer).toFixed(this.scrpModel.DecimalPrecision);
      slvalue = (Utility.getNumber(value) - stopLossPer).toFixed(this.scrpModel.DecimalPrecision);
    }
    else {
      sqrOffvalue = (Utility.getNumber(value) - sqrOffPer).toFixed(this.scrpModel.DecimalPrecision);
      slvalue = (Utility.getNumber(value) + stopLossPer).toFixed(this.scrpModel.DecimalPrecision);
    }
    const squreOffMod = this.floatSafeModulus(sqrOffvalue, this.ticksize);
    const slMod = this.floatSafeModulus(slvalue, this.ticksize);
    sqrOffvalue = squreOffMod === 0 ? Utility.getNumber(sqrOffvalue).toFixed(this.scrpModel.DecimalPrecision) : (sqrOffvalue - squreOffMod).toFixed(this.scrpModel.DecimalPrecision);
    slvalue = slMod === 0 ? Utility.getNumber(slvalue).toFixed(this.scrpModel.DecimalPrecision) : (slvalue - slMod).toFixed(this.scrpModel.DecimalPrecision);
    this.squareOffvalue = this.scrpModel.HCircuitLimit ? (sqrOffvalue < this.scrpModel.HCircuitLimit ? sqrOffvalue.toString() : this.scrpModel.HCircuitLimit.toString()): sqrOffvalue.toString();
    this.stoplossValue = this.scrpModel.LCircuitLimit ? (slvalue > this.scrpModel.LCircuitLimit ? slvalue.toString() : this.scrpModel.LCircuitLimit.toString()): slvalue.toString();
  }

  onVerityTypeChange() {
    this.pom.AMO = 'NO';
    this.isProductType = true;
    document.getElementById('I').classList.remove('mat-radio-checked');
    if (this.verityType === 'RGLR') {
      if (this.pom.Pcode === 'BO' || this.pom.Pcode === 'CO'){
        this.setDefaultProduct();
      }
    }
    else if (this.verityType === 'BO' || this.verityType === 'CO') {
      if (this.verityType === 'CO'){
        setTimeout(function(){document.getElementById('I').classList.add('mat-radio-checked')}, 100);
      }
      this.pom.Pcode = this.verityType;
      this.isProductType = false;
    }
    else {
      if (this.pom.Pcode === 'BO' || this.pom.Pcode === 'CO'){
        this.setDefaultProduct();
      }
      this.pom.AMO = 'YES';
    }
    this.onProductTypeChange();
  }

  calculateCOTriggerRabge() {
    const dataObj = { 'Exchange': this.pom.exch, 'Symbol': this.pom.Tsym, 'Transtype': this.pom.Ttranstype };
    if (this.isEdit && this.orderData.Status.toLowerCase() === 'trigger pending') {
      this.isReverseOrder = true;
      this.scrpModel.COLTP = Utility.getNumber(this.orderData.RefLmtPrice)
    }
    this.scripService.LTPCoverPrctge(dataObj, this.calculateCOTriggerRabgeRes.bind(this));
    this.isLTPCoverageCallInProgress = true;
  }

  calculateCOTriggerRabgeRes(data, code, message) {
    this.isLTPCoverageCallInProgress = false;
    this.isCoveragePercentageCalled = true;
    if (code === Keys.RES_CODE_SUCCESS) {
      this.scrpModel.CoverPercentage = data;
      if (this.isReverseOrder) {
        this.getCOPriceFlag();
      }
      else {
        this.calculateTriggerRange();
        if (!this.isEdit) {
          this.scrpModel.tracsactionType = this.pom.Ttranstype;
          this.scrpModel.isUpdateTriggerRange = true;
        }
      }
    }
    else {

    }
  }

  getCOPriceFlag() {
    this.scripService.coverOrderPriceFlag(this.coverOrderPriceFlagRes.bind(this))
  }

  coverOrderPriceFlagRes(data, code, message) {
    this.pom.IsCOPriceFlagEnabled = data;
    this.calculateTriggerRange();
  }

  calculateTriggerRange() {
    this.scrpModel.CalculateTriggerPriceRange(this.pom.Ttranstype, this.pom.IsCOPriceFlagEnabled);
    if (!this.isEdit)
      this.pom.TrigPrice = this.pom.Ttranstype === 'B' ? this.scrpModel.TriggerRangeStart : this.scrpModel.TriggerRangeEnd;
  }

  updateTriggerRange() {
    this.scrpModel.UpdateTriggerPriceRange(this.pom.Ttranstype);
  }

  filterCOEditCondtions() {
    this.showAdvanced = true;
    this.verityType = 'CO';
    if (this.pom.Status.toLowerCase() === 'trigger pending') {
      this.calculateCOTriggerRabge();
      this.isOrderType = false;
      this.isQtyDisable = true;
      this.isEnablePrice = false;
    }
    else if (this.pom.Status.toLowerCase() === 'open') {
      this.isOrderType = true;
      // this.isQtyDisable = true;
    }
  }

  updateHoldingData() {
    this.pom.prctyp = 'MKT';
    this.isEnablePrice = false;
    this.pom.Pcode = this.holdingData.Pcode;
    this.pom.qty = this.holdingData.AvailableQty.toString();
  }

  validateSLM() {
    this.reqCount++;
    const data = { 'sExchange': this.pom.exch, 'sToken': this.pom.Token, 'sSLPrice': this.pom.TrigPrice.toString(), 'sOrderType': this.pom.Ttranstype, 'sMarketProtection': this.pom.MktPro };
    this.scripService.validateSLM(data, this.validateSLMRes.bind(this));
  }

  validateSLMRes(data, code, message) {
    this.reqCount--;
    if (code === Keys.RES_CODE_SUCCESS) {
      if (this.reqCount === 0)
        this.placeOrder();
    }
    else {
      this.snackBar.open(message, this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  filterOrderTypeArray() {
    this.filterOrderType();
    this.orderTypeDependencies(this.pom.prctyp);
  }

  filterOrderType()
  {
    this.orderTypeArray = [];
    // this.orderTypeArray = this.pom.Pcode == 'BO'? this.BOOrderTypeArray : ['L', 'MKT', 'SL', 'SL-M'];
    if (this.pom.Pcode === 'BO')
      this.orderTypeArray = this.BOOrderTypeArray;
    else if (this.pom.Pcode === 'CO' || this.isSquareOff)
      this.orderTypeArray = this.basicOrderTypeArray;
    else
      this.orderTypeArray = ['L', 'MKT', 'SL', 'SL-M'];

    if (!this.isEdit && !this.isSquareOff && !this.isAddRemove) {
      if (this.orderTypeArray.includes(Utility.PLACEORDERSETTINGSMODEL.orderType))
        this.pom.prctyp = Utility.PLACEORDERSETTINGSMODEL.orderType;
      else
        this.pom.prctyp = this.orderTypeArray[0];
    }
    if (this.orderTypeArray.length === 0)
    {
      console.log('=============Empty order type array, Not able to placeorder==================')
      this.isPO = false;
    }
  }

  placeBOOrder() {
    if (!this.squareOffvalue || Utility.getNumber(this.squareOffvalue) <= 0) {
      this.snackBar.open(this.translate.instant('all.invalid_squareoff'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.pom.SqrOffAbsOrticks === 'Absolute' && this.floatSafeModulus(this.squareOffvalue, this.ticksize) !== 0) {
      this.snackBar.open(this.translate.instant('all.squareoffmult') + this.ticksize + '.', this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (!this.stoplossValue || Utility.getNumber(this.stoplossValue) <= 0) {
      this.snackBar.open(this.translate.instant('all.invalidSL'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (this.pom.SLAbsOrticks === 'Absolute' && this.floatSafeModulus(this.stoplossValue, this.ticksize) !== 0) {
      this.snackBar.open(this.translate.instant('all.slmultiple') + this.ticksize + '.', this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else {
      // this.isPO = false;
      this.pom.SqrOffvalue = (Utility.getNumber(this.squareOffvalue) - Utility.getNumber(this.pom.Price)).toString();
      this.pom.SLvalue = (Utility.getNumber(this.pom.Price) - Utility.getNumber(this.stoplossValue)).toString();
      this.submitType = 'bo';
      this.showAlert();

    }
  }

  placeBOOrderRes(data, code, message) {
    // this.isPO = true;
    if (code === Keys.RES_CODE_SUCCESS) {
      // this.isOrderSummary = true;
      Utility.ORDER_PLACED_EVENT.next('true');
      if(!this.onetouch){
        this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
          if (resCode === Keys.RES_CODE_SUCCESS) {
            this.orderObjArr = list.filter(el => (el.NestOrd === data.nestOrderNumber || el.SyomOrderId.split('|')[0] === data.nestOrderNumber));
          }
        }.bind(this), this.setPreDefinedOrders.bind(this));
      } else {
        this.snackBar.open(this.translate.instant('all.ord_place_success') + data.nOrdNo, this.translate.instant('all.close'), { duration: 2000, panelClass: ['successSnackBar'] });
        if(this.isMobile){
          this.location.back();
        } else {
          Utility.dialogRef.close()
        }
      }
    }
    else {
      this.snackBar.open(message, this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  applyBOModifyConditions() {
    this.verityType = 'BO';
    if (this.orderData.Status.toLowerCase() === 'trigger pending') {
      this.isQtyDisable = true;
      this.isEnablePrice = false;
    }
    else {
      if (this.orderData.IsCancel) {
        this.isQtyDisable = false;
        this.isEnablePrice = true;
      }
      else {
        this.isQtyDisable = true;
        this.isEnablePrice = true;
      }
    }
  }

  onPlaceAnother() {
    this.isOrderSummary = false;
    if (this.pom.Ttranstype === 'B')
      this.pom.Price = this.scrpModel.BestSellPrice;
    else
      this.pom.Price = this.scrpModel.BestBuyPrice;
    this.pom.qty = this.scrpModel.BodLotQuantity;
    this.setDefaultProduct();
    this.verityType = 'RGLR';
    this.isProductType = true;
    if (this.pom.Pcode === 'BO') {
      this.applyBOModifyConditions();
    }
    this.pom.prctyp = this.orderTypeArray[0];
    this.filterOrderTypeArray();
    this.filterRetentionData();
    this.pom.discqty = '0';
    this.pom.Ret = this.retentionTypeArray[0];
    this.pom.TrigPrice = '0';
    this.pom.AMO = 'NO';
    this.isEdit = false;
    this.isSquareOff = false;
    this.getData();
  }

  openDepth() {
    if (this.isShowDepth)
    {
      this.depthAnimation = 'initial';
      setTimeout(function(){this.isShowDepth = false}.bind(this), 400);
    }
    else
    {
      this.isShowDepth = true;
      this.depthAnimation = 'final'
    }
  }

  onModify(obj) {
    this.isOrderSummary = false;
    Utility.detailedOrder = obj;
    this.initPlaceorder({ 'isEdit': true })
  }

  onCancel(obj) {
    const alertMessage = this.translate.instant('all.cancel_ordermsg');
     this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.cancel_hed'), message: alertMessage } });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.tradingRestService.getBookService().CancelOrder(obj, function(responsedata, code, message){
          if (responsedata['stat'] === 'Ok') {
            Utility.ORDER_PLACED_EVENT.next('true');
            this.isOrderSummary = true;
            this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
              if (resCode === Keys.RES_CODE_SUCCESS) {
                this.orderObjArr = list.filter(el => el.NestOrd === responsedata.result);
              }
            }.bind(this), this.setPreDefinedOrders.bind(this));
            this.snackBar.open(this.translate.instant('all.order_cancelledmsg'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['successSnackBar'] });
          }
          else
            this.snackBar.open(this.translate.instant('all.order_failed'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }.bind(this));
      }
    });
  }

  showAlert() {
    this.isDisableForm = true;
    setTimeout(function(){
      document.getElementById('confirmbutton').focus();      
    }, 200);
  }

  onEdit() {
    this.isDisableForm = false;
    // this.isPO = true;
  }

  onConfirm() {
    this.isDisableForm = false;
    this.isOrderSummary = true;
    switch (this.submitType) {
      case 'placeorder':
        this.scripService.placeOrder(this.pom, this.placeOrderRes.bind(this));
        break;
      case 'modify':
        this.pom.qty = Utility.getNumber(this.pom.qty) + Utility.getNumber(this.filledQuantity);
        this.scripService.modifyOrder(this.pom, this.modifyOrderRes.bind(this));
        break;
      case 'bo':
        this.scripService.placeBOOrder(this.pom, this.placeBOOrderRes.bind(this));
        break;
    }
  }

  onExit(obj) {
    const str = this.translate.instant('all.exit_ordermsg');
     this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.exit_hed'), message: str } });
     this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.tradingRestService.getBookService().ExitOrder(obj, function(responsedata, code, message){
          if (responsedata['stat'] === 'Ok') {
            Utility.ORDER_PLACED_EVENT.next('true')
            this.isOrderSummary = true;
            this.tradingRestService.getBookService().getAllOrders(function(list, resCode, resMsg){
              if (resCode === Keys.RES_CODE_SUCCESS) {
                this.orderObjArr = list.filter(el => el.NestOrd === obj.NestOrd);
              }
            }.bind(this), this.setPreDefinedOrders.bind(this));
            this.snackBar.open(this.translate.instant('all.order_exited'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['successSnackBar'] });
          }
          else
            this.snackBar.open(this.translate.instant('all.order_exit_failed'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }.bind(this));
      }
    });
  }

  onEnter(id) {
    if (Utility.IS_MOBILE) {
      document.getElementById(id).focus();
    }
}

  onOpenOrderBook()
  {
    this.router.navigate(['/home'], { replaceUrl: true });
    Utility.openOrderBook.next();
    Utility.stockDetailIndex = 0;
  }

  calculateLots(qty, lotSize)
  {
    this.lotError = false;
    if (qty && lotSize && this.floatSafeModulus(Utility.getNumber(qty), Utility.getNumber(lotSize)) === 0)
      return Math.floor(Utility.getNumber(qty)/Utility.getNumber(lotSize));
    this.lotError = true;
    return 'NA';
  }

  close()
  {
    Utility.dialogRef.close();
  }

  selectData(value) {
    if (value.omtkn && value.exseg)
      Utility.addRecentlySearchedScrips(value.omtkn, value.exseg, value.tsym, value.cnam);
    
    this.initPlaceorder({'action': this.pom.Ttranstype, 'omtkn': value.omtkn, 'exseg': value.exseg})
  }

  onSubmitForm(ev)
  {
    if (ev.keyCode === 13)
    {
      if (!this.isDisableForm && this.isPO)
        this.onSubmit();
    }
  }
  
  // checkSL()
  // {
  //   return this.isEdit && (this.orderData? (Utility.getNumber(this.orderData.TriggerPrice) !== 0) : false);
  // }

  checkPriceLength(str)
  {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + this.scrpModel.DecimalPrecision + '}$');
    console.log(regex.test(str))
    return regex.test(str);
  }

  checkQty(str)
  {
    const regex = new RegExp('^[0-9]{0,8}$|^[0-9]{1,' + 2 + '}$');
    return regex.test(str);
  }

  checkMktPro(str)
  {
    const regex = new RegExp('^[0-9]{0,' + 2 + '}$|^[0-9]{1,' + 2 + '}[.]{1,1}[0-9]{0,' + 2 + '}$');
    return regex.test(str);
  }

  setBaseVisibility(visible: boolean){
    if(visible){
      setTimeout(() => {
        this.onAppearing();
      }, 50);
    }
    else {
      this.onDisappearing();
    }
  }

  onAppearing() {
    Utility.resumeChannel(this.tradingRestService, ChannelType.Shared);
  }

  onDisappearing() {
    Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
  }
  
  onExch(ev)
  {
    console.log(ev)
    this.selectedExch = ev.value;
    this.scrpModel = this.scripArray.filter(ele => ele.ExchangeName === ev.value)[0];
    this.pom.exch = this.scrpModel.ExchangeName;
    this.pom.Exchangeseg = this.scrpModel.ExchangeSegment;
    this.pom.Token = this.scrpModel.Token;
    this.initPoAfterInterOpCheck();
  }
  
  ngOnDestroy()
  {
    if (this.scripSubscription)
      this.scripSubscription.unsubscribe();
    this.onDisappearing();
  }
  goBack(){
    this.location.back();
  }

  checked = false;
  ao = false;
  pto = false;
  amoClicked($event) {
    this.checked = !this.checked;
    this.pom.AMO = (this.checked) ? 'YES' : 'NO';
    $event.stopPropagation()
  }

  priceClicked($event) {
    this.pto = !this.pto;
    this.pom.prctyp = (this.pto) ? 'MKT' : 'L';
  }

  openoverlay(){
    this.balance = Utility.avl_margin;
    if (this.isMobile) {
        let url = 'addfunds';
        this.router.navigate([url], { queryParams: { bal: this.balance} });
    } else {
        Utility.dialogRef.close();
        var obj = {'btnindex': 1, 'bal': this.balance, 'overlayFrom':'placeOrder'};
        var jsonObj = {"inputs":obj, "name":"addFunds"};
        Utility.InputsDataGet.next(jsonObj);
    }
  }

  getData(){
    let sendJson={};
    sendJson = {
      'uid': Preferences.getPreference('userid'),
      'actid': JSON.parse(Preferences.getPreference('userParameter')).sAccountId,
      'segment': "ALL",
      'calcType': 'DIRECT',
      'product': null,
      'seg': null,
      'ex_segment': null,
      'exchange': null
    };

    this.tradingRestService.getUserService().getAllLimits(sendJson, function(limitData, code, message) {
      if (code === 200 && message === 'Ok') {
            this.getAllLimitsResponse(limitData);
        }
        else {
            this.isNoData = true;
        }
    }.bind(this), this.getAllLimitsResponse.bind(this));
  }

  getAllLimitsResponse(data){
    Utility.limitBalance = data[0]['AvailableCash'];
    Utility.avl_margin = data[0]['AvailableMargin'];
    this.balance = Utility.avl_margin;
  }
}
