import { Component, OnInit, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { MatSelectChange } from '@angular/material/select';
import { TradeModel } from 'src/app/model/trade.model';
import { ChannelType, Utility } from 'src/app/services/utility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TradebookComponent } from '../tradebook/tradebook.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-positionconvertion',
    templateUrl: './positionconvertion.component.html',
    styleUrls: ['./positionconvertion.component.scss']
})

export class PositionConvertionComponent implements OnInit, OnDestroy {
    @Output() load = new EventEmitter<boolean>();
    isLoading = true;
    isNoData: boolean;
    tradeDetailData;
    productList = [];
    selectedProduct = '';
    jsonObj = {};
    tradeList: Array<TradeModel>;
    transactionTypeMap = {};
    isMobile = true;
    constructor (private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private router: Router, public dialog: MatDialog, private location: Location,  private translate: TranslateService) {
        this.isMobile = Utility.IS_MOBILE;
        this.tradeDetailData = Utility.tradeDetail;
    }

    ngOnInit() {
        this.prepareDropDownView(this.tradeDetailData.Pcode, this.tradeDetailData.Exchange, this.tradeDetailData.Symbol, this.tradeDetailData.Exseg);
        this.onAppearing();
    }

    setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.onAppearing();
          }, 50);
        }
        else {
          this.onDisappearing();
        }
    }

    ngOnDestroy() {
        this.onDisappearing();
    }

    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Shared);
    }
    
    onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
      Utility.selectedBookTab = 1;
    }

    prepareDropDownView(pCode, exchange, token, excSegment) {
        const sm = Utility.getScripModel(token, excSegment, this.tradingRestService, true);
        sm.ExchangeName = exchange;
        const productsArray = Utility.GetAllProductForExchange(sm.ExchangeName);
        productsArray.forEach(product => {
            if (product !== this.tradeDetailData.Pcode && product !== 'CO' && product !== 'BO') {
                this.productList.push(product);
            }
        });
        this.selectedProduct = this.productList[0];
    }

    createMap() {
        this.transactionTypeMap['B'] = 'BUY';
        this.transactionTypeMap['S'] = 'SELL';
    }

    onSendSelection($event: MatSelectChange) {
        this.selectedProduct = $event.value;
        this.jsonObj['Pchange'] = this.selectedProduct;
    }

    onSubmit() {
        this.isLoading = true;
        this.tradingRestService.getBookService().PositionConvertion(this.selectedProduct, this.tradeDetailData, this.PositionConvertionResponse.bind(this));
    }

    PositionConvertionResponse(code, message) {
        this.isLoading = false;
        this.load.emit(this.isLoading);
        if (code === 200 && message === 'OK') {
            this.snackBar.open(this.translate.instant("all.position_sucess"), '', { duration: 3000 });
            if (this.isMobile) {
                this.location.back();
            } else {
                Utility.dialogRef.close();
            }
            this.snackBar.open(this.translate.instant("all.position_fail"), '', { duration: 3000 });
        }
        else {
            this.snackBar.open(this.translate.instant("all.position_fail"), '', { duration: 3000 });

        }
    }

    onCancel() {
        if (this.isMobile) {
            this.location.back();
        } else {
            Utility.dialogRef.close();
        }
    }
}
