import '../polyfills';
import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PlaceorderComponent } from './view/protradelite/placeorder/placeorder.component';
import { SearchComponent } from './view/protradelite/home/search/search.component';
import { StockdetailComponent } from './view/protradelite/stockdetail/stockdetail.component';
import { AlertComponent } from './view/protradelite/alert/alert.component';
import { AddAlertComponent } from './view/protradelite/alert/addalert/addalert.component';
import { LightWeightChartComponent } from './view/protradelite/lightweightchart/lightweightchart.component';
import { LimitComponent } from './view/protradelite/limit/limit.component';
import { LogsComponent } from './view/protradelite/logs/logs.component';
import { LotWeightComponent } from './view/protradelite/lotweight/lotweight.component';
import { ProfileComponent } from './view/protradelite/profile/profile.component';
import { DepthComponent } from './view/protradelite/depth/depth.component';
import { LoginDialogComponent } from './view/protradelite/logindialog/logindialog.component';
import { ChangePwdComponent } from './view/protradelite/changepassword/changepassword.component';
import { ChangeQuestionComponent } from './view/protradelite/changequestion/changequestion.component';
import { MarketStatusComponent } from './view/protradelite/marketstatus/marketstatus.component';
import { ExchangeMessageComponent } from './view/protradelite/exchangemessage/exchangemessage.component';
import { IndexComponent } from './view/protradelite/index/index.component';
import { NewsComponent } from './view/protradelite/news/news.component';
import { BasketComponent } from './view/protradelite/thematicbasket/basket.component';
import { TechnicalsComponent } from './view/protradelite/technicals/technicals.component';
import { IbesComponent } from './view/protradelite/ibes/ibes.component';
import { KeyRatioComponent } from './view/protradelite/keyratio/keyratio.component';
import { AboutComponent } from './view/protradelite/about/about.component';
import { CanActivateGuard } from './view/protradelite/header/canactivateguard';
import { CanLoginGuard } from './view/protradelite/header/canloginguard';
import { DecimalPipe } from '@angular/common';
import { PeerComponent } from './view/protradelite/peer/peer.component';
import { FinancialComponent } from './view/protradelite/financial/financial.component';
import { BasketDetailComponent } from './view/protradelite/thematicbasket/basketdetail/basketdetail.component';
import { EventComponent } from './view/protradelite/events/events.component';
import { BasketPlaceOrderComponent } from './view/protradelite/thematicbasket/basketplaceorder/basketplaceorder.component';
import { NumberDirective } from './directives/number.directive';
import { BasketOrdersummaryComponent } from './view/protradelite/thematicbasket/basketordersummary/basketordersummary.component';
import { BasketCompareComponent } from './view/protradelite/thematicbasket/basketcompare/basketcompare.component';
import { VisualTradeComponent } from './view/protradelite/visualtrading/visualtrading.component';
import { TradingViewComponent } from './view/protradelite/tradingview/tradingview.component';
import { ScannerComponent } from './view/protradelite/scanner/scannercomponent/scanner.component';
import { ScannerDetailComponent } from './view/protradelite/scanner/scannerdetail/scannerdetail.component';
import { CanDeactivateGuard } from './view/protradelite/header/candeactivateguard';
import { EquityMarketComponent } from './view/protradelite/equitymarket/equitymarket.component';
import { OptionCalculatorComponent } from './view/protradelite/optioncalculator/optioncalculator.component';
import { CustomDashboardComponent } from './view/protradelite/home/customdashboard/customdashboard.component';
import { IndicesComponent } from './view/protradelite/equitymarket/indices/indices.component';
import { OptionComponent } from './view/protradelite/derivativemarket/optionchain/optionchain.component';
import { DerivativeMarketComponent } from './view/protradelite/derivativemarket/derivativemarket.component';
import { TopMarketComponent } from './view/protradelite/equitymarket/topmarket/topmarket.component';
import { TagSpecificNewsComponent } from './view/protradelite/news/tagspecificnews/tagspecificnews.component';
import { RollOverComponent } from './view/protradelite/derivativemarket/rollover/rollover.component';
import { LiveMarketComponent } from './view/protradelite/equitymarket/livemarket/livemarket.component';
import { MarketMoodComponent } from './view/protradelite/equitymarket/marketmood/marketmood.component';
import { PutCallRatioComponent } from './view/protradelite/derivativemarket/putcallratio/putcallratio.component';
import { MarketTodayComponent } from './view/protradelite/derivativemarket/markettoday/markettoday.component';
import { PersistenceModule  } from 'angular-persistence';
import { WebScannerComponent } from './view/protradelite/webscanner/webscanner.component';
import { AutoSearchComponent } from './view/protradelite/autocompletesearch/autocompletesearch.component';
import { StockMoreOptionComponent } from './view/protradelite/stockmoreoption/stockmoreoption.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ChartsModule } from 'ng2-charts';
import { GeneralSettingsComponent } from './view/protradelite/generalsettings/generalsettings.component';
import { KeyboardShortcutsComponent } from './view/protradelite/keyboardshortcuts/keyboardshortcuts.component';
import { MultiViewComponent } from './view/protradelite/multiviewcomponent/multiviewcomponent.component';
import { BasketWidgetComponent } from './view/protradelite/thematicbasket/widget/basketwidget.component';
import * as $ from 'jquery';
import { QuotesComponent } from './view/protradelite/quotes/quotes.component';
import { TagSpecificNewsWebComponent } from './view/protradelite/news/tagspecificnewsweb/tagspecificnewsweb.component';
import { CustomBasketDetail } from './view/protradelite/thematicbasket/customthematicbasket/custombasketdetail/custombasketdetail.component';
import { BasketOrderBookComponent } from './view/protradelite/thematicbasket/basketorderbook/basketorderbook.component';
import { BasketConstituentsComponent } from './view/protradelite/thematicbasket/basketconstituents/basketconstituents.component';
import { BasketPlaceOrderSummaryComponent } from './view/protradelite/thematicbasket/basketplaceordersummary/basketplaceordersummary.component';
import { BasketHomeComponent } from './view/protradelite/thematicbasket/baskethome/baskethome.component'
import { UserBasketHomeComponent } from './view/protradelite/thematicbasket/userbaskethome/userbaskethome.component'
import { BasketOrdersComponent } from './view/protradelite/thematicbasket/basketorders/basketorders.component'
import { CustomBasketDetailChart } from './view/protradelite/thematicbasket/customthematicbasket/custombasketdetailchart/custombasketdetailchart.component';
import { CustomBasket } from './view/protradelite/thematicbasket/customthematicbasket/custombasket/custombasket.component';
import { EventFilterOptionComponent, EventFilterOptionPopupComponent, EventFilterOptionBottomSheetComponent } from './view/protradelite/events/eventFilterOption/eventFilterOption.component';
import { OptionChainPlaceOrderComponent } from './view/protradelite/derivativemarket/optionchain/optionchainplaceorder/optionchainplaceorder.component';
import { OptionChainAnalysisComponent } from './view/protradelite/derivativemarket/optionchain/optionchainanalysis/optionchainanalysis.component';
import { RecentlyViewComponent } from './view/protradelite/home/recentlyview/recentlyview.component';
import { UpdateuserinfoComponent } from './view/protradelite/updateuserinfo/updateuserinfo.component';
import { OrderdetailComponent } from './view/protradelite/orderdetail/orderdetail.component';
import { AddfundsComponent } from './view/protradelite/addfunds/addfunds.component';
import { WithdrawfundsComponent } from './view/protradelite/withdrawfunds/withdrawfunds.component';
import { TransactiondetailsComponent } from './view/protradelite/transactiondetails/transactiondetails.component';
import { SearchsuggestionComponent } from './view/protradelite/searchsuggestion/searchsuggestion.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaService } from './services/pwa.service';
import { OptionchainmoreComponent } from './view/protradelite/derivativemarket/optionchain/optionchainmore/optionchainmore.component';
import { SharedModule } from './shared.module';
import { StockHeaderComponent } from './view/protradelite/header/stockheader/stockheader.component';
import { OrderHistoryComponent } from './view/protradelite/orderhistory/orderhistory.component';
import { KeyboardShortcutsModule }     from 'ng-keyboard-shortcuts';  
import { AboutusComponent } from './view/protradelite/pages/aboutus/aboutus.component';
import { TermsComponent } from './view/protradelite/pages/terms/terms.component';
import { PrivacyComponent } from './view/protradelite/pages/privacy/privacy.component';
import { ContactComponent } from './view/protradelite/pages/contact/contact.component';
import { FeedbackComponent } from './view/protradelite/feedback/feedback.component';
import { SpreadOrderComponent } from './view/protradelite/spreadorder/spreadorder.component';
import { SpreadPlaceorderComponent } from './view/protradelite/spreadorder/spreadplaceorder/spreadplaceorder.component';
import { SpreadOrderdetailComponent } from './view/protradelite/spreadorder/spreadorderdetail/spreadorderdetail.component';
import { SpreadOrderHistoryComponent } from './view/protradelite/spreadorder/spreadorderhistory/spreadorderhistory.component';
import { SpreadOrderMoreOptionComponent } from './view/protradelite/spreadorder/spreadordermoreoption/spreadordermoreoption.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/FCM/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { FcmComponent } from './view/protradelite/fcmnotification/fcm.component'
import { NgxPullToRefreshModule } from 'ngx-pull-to-refresh';
import { ManagebooksComponent } from './view/protradelite/books/managebooks/managebooks.component';
import { ChangeMpinComponent } from './view/protradelite/changempin/changempin.component';
import { PartialPositionConvertionComponent } from './view/protradelite/partialpositionconvertion/partialpositionconvertion.component';
import { PositionConvertionComponent } from './view/protradelite/positionconvertion/positionconvertion.component';
import { PositiondetailComponent } from './view/protradelite/positiondetail/positiondetail.component';
import { PositionBreakupPopupComponent } from './view/protradelite/positionbook/positionbreakuppopup/positionbreakuppopup.component';
import * as Hammer from 'hammerjs';
import {HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { GoldenLayoutHostComponent } from './goldenlayout/golden-layout-host.component';
import { GoldencontrolsComponent } from './view/protradelite/home/goldencontrols/goldencontrols.component';
import { GoldenLayoutComponentService } from './goldenlayout/golden-layout-component.service';
import { PageSheet } from './view/protradelite/pages/page.sheet';
import { SelllistComponent } from './view/protradelite/selllist/selllist.component';
import { SellmoreComponent } from './view/protradelite/selllist/sellmore/sellmore.component';
import { RetryBasketOrdersComponent } from './view/protradelite/thematicbasket/retrybasketorders/retrybasketorders.component';
import { BasketsipComponent } from './view/protradelite/thematicbasket/basketsip/basketsip.component';
import { UsertodayssipComponent } from './view/protradelite/thematicbasket/usertodayssip/usertodayssip.component';
import { BasketsipmoreComponent } from './view/protradelite/thematicbasket/basketsipmore/basketsipmore.component';
import { SipupdateComponent } from './view/protradelite/thematicbasket/sipupdate/sipupdate.component';
import { LinkComponent } from './view/protradelite/link/link.component';
import { SquareOffDialogComponent } from './view/protradelite/positionbook/positionbook.component';
import { TriggerComponent } from './view/protradelite/trigger/trigger.component';
import { CreateTriggerComponent } from './view/protradelite/createTrigger/createTrigger.component';
import { TriggerMoreComponent } from './view/protradelite/triggermore/triggermore.component';
import { TradesummaryComponent } from './view/protradelite/tradebook/tradesummary/tradesummary.component';
import { OrderMoreOptionComponent } from './view/protradelite/orderbook/ordermoreoption/ordermoreoption.component';
import { QProfileComponent } from './view/protradelite/qprofile/qprofile.component';
import { EmandateComponent } from './view/protradelite/thematicbasket/emandate/emandate.component';
import { CreateEmandateComponent } from './view/protradelite/thematicbasket/createemandate/createemandate.component';
import { combineQProfileTodaySip } from './view/protradelite/combineqprofiletodaysip/combineqprofiletodaysip.component';
import { IndexSpotComponent } from './view/protradelite/indexspot/indexspot.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'swipe': {direction: Hammer.DIRECTION_DOWN }
  };
}

@NgModule({
  declarations: [
    QProfileComponent,
    combineQProfileTodaySip,
    SquareOffDialogComponent,
    GoldencontrolsComponent,
    GoldenLayoutHostComponent,
    PositionBreakupPopupComponent,
    PartialPositionConvertionComponent,
    PositionConvertionComponent,
    PositiondetailComponent,
    AboutusComponent,
    TermsComponent,
    PrivacyComponent,
    ContactComponent,
    SearchsuggestionComponent,
    OrderdetailComponent,
    UpdateuserinfoComponent,
    OptionChainAnalysisComponent,
    OptionChainPlaceOrderComponent,
    EventFilterOptionComponent,
    EventFilterOptionPopupComponent,
    EventFilterOptionBottomSheetComponent,
    MultiViewComponent,
    GeneralSettingsComponent,
    KeyboardShortcutsComponent,
    AutoSearchComponent,
    WebScannerComponent,
    RollOverComponent,
    RecentlyViewComponent,
    TagSpecificNewsComponent,
    IndicesComponent,
    EquityMarketComponent,
    EventComponent,
    NewsComponent,
    IndexComponent,
    SearchComponent,
    AppComponent,
    PlaceorderComponent,
    StockdetailComponent,
    LightWeightChartComponent,
    AlertComponent,
    AddAlertComponent,
    LightWeightChartComponent,
    LimitComponent,
    LogsComponent,
    LotWeightComponent,
    ProfileComponent,
    DepthComponent,
    LoginDialogComponent,
    ChangeMpinComponent,
    ChangePwdComponent,
    ChangeQuestionComponent,
    MarketStatusComponent,
    ExchangeMessageComponent,
    BasketComponent,
    BasketPlaceOrderComponent,
    NumberDirective,
    BasketOrdersummaryComponent,
    TechnicalsComponent,
    IbesComponent,
    KeyRatioComponent,
    AboutComponent,
    PeerComponent,
    FinancialComponent,
    BasketDetailComponent,
    VisualTradeComponent,
    BasketCompareComponent,
    TradingViewComponent,
    ScannerComponent,
    ScannerDetailComponent,
    CustomDashboardComponent,
    DerivativeMarketComponent,
    OptionComponent,
    OptionCalculatorComponent,
    LiveMarketComponent,
    MarketMoodComponent,
    PutCallRatioComponent,
    MarketTodayComponent,
    StockMoreOptionComponent,
    BasketWidgetComponent,
    QuotesComponent,
    TagSpecificNewsWebComponent,
    CustomBasketDetail,
    CustomBasketDetailChart,
    CustomBasket,
    AddfundsComponent,
    WithdrawfundsComponent,
    TransactiondetailsComponent,
    OptionchainmoreComponent,
    StockHeaderComponent,
    OrderHistoryComponent,
    FeedbackComponent,
    SpreadOrderComponent,
    SpreadPlaceorderComponent,
    SpreadOrderdetailComponent,
    SpreadOrderHistoryComponent,
    SpreadOrderMoreOptionComponent,
    FcmComponent,
    ManagebooksComponent,
    PageSheet,
    SelllistComponent,
    SellmoreComponent,
    BasketOrderBookComponent,
    BasketsipComponent,
    BasketPlaceOrderSummaryComponent,
    BasketHomeComponent,
    BasketConstituentsComponent,
    UserBasketHomeComponent,
    BasketOrdersComponent,
    RetryBasketOrdersComponent,
    UsertodayssipComponent,
    BasketsipmoreComponent,
    SipupdateComponent,
    LinkComponent,
    TriggerComponent,
    CreateTriggerComponent,
    TriggerMoreComponent,
    TradesummaryComponent,
    OrderMoreOptionComponent,
    EmandateComponent,
    CreateEmandateComponent,
    IndexSpotComponent
  ],
  imports: [
    ContextMenuModule.forRoot(),
    BrowserModule,
    NgxPullToRefreshModule,
    BrowserAnimationsModule, HttpClientModule,
    AppRoutingModule, FormsModule, ReactiveFormsModule, MatNativeDateModule,
    MaterialModule, PersistenceModule, ChartsModule, SharedModule, HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    KeyboardShortcutsModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  entryComponents: [LoginDialogComponent],
  bootstrap: [AppComponent],
  exports: [NumberDirective],
  providers: [GoldenLayoutComponentService,DecimalPipe, CanDeactivateGuard, CanActivateGuard, CanLoginGuard, MessagingService, AsyncPipe, 
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
