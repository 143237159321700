   <div floatLabel="never" class="topsearchweb">
      <!-- used in visual trading-->
      <!-- <input #mtinput (input) = "onInput($event, eventParameter)"  type="search" placeholder="Search..." aria-label="Search" [formControl]="myControl" [matAutocomplete]="auto" (focusout)="onFocusOut()"> -->
      <input #mtinput [(ngModel)]="inputValue" (input) = "onInput($event, eventParameter)"  type="search" placeholder="{{'search.placeholder_search' | translate }}" aria-label="Search" (keypress)="validation($event)" [formControl]="myControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)" >
         <mat-option *ngFor="let option of filteredOptions | async" [value]="option" >
            {{option.tsym}}&nbsp;<span class="ts-2" *ngIf = "option.exseg === 'bse_cm'">({{option.seris}})</span> <small class="exchangename">{{segmentToExchange(option.exseg)}}</small>
         </mat-option>
      </mat-autocomplete>
      <a class="searchclearicon" *ngIf="!isPlaceOrder && inputValue" (click)="onClearSearch()">
         <i class="icon-close"></i>
      </a>
   </div>
