import { Component, OnInit, Input, Output, EventEmitter, Injector, ChangeDetectorRef, OnChanges, ElementRef } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Subscription } from 'rxjs';
import { CustomBasket } from '../../thematicbasket/customthematicbasket/custombasket/custombasket.component';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';
import { ComponentContainer } from 'golden-layout';
import { Utility } from 'src/app/services/utility';
import { TableUtility } from 'src/app/services/tableutility';
import { Subject } from 'rxjs';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { BasketDetailComponent } from '../../thematicbasket/basketdetail/basketdetail.component';
let tagspecificnewsweb: TagSpecificNewsWebComponent;
@Component({
  selector: 'app-tagspecificnewsweb',
  templateUrl: './tagspecificnewsweb.component.html',
  styleUrls: ['./tagspecificnewsweb.component.scss']
})
export class TagSpecificNewsWebComponent extends BaseComponentDirective implements OnInit, OnChanges {
  isMobile;
  targetElement: Element;
  newsArr = [];
  @Input() tagtype: string;
  @Input() isCustomDashboard: boolean;
  isNoData: boolean;
  isLoading: boolean;
  dummyImg = 'assets/img/news-placeholder.png';
  @Output() loaderEvent = new EventEmitter<boolean>();
  subscription: Subscription;
  @Input() IsStockSpecific: boolean;
  @Input() width: number;
  @Input() height: number;
  Height: any;
  modesub: Subscription;
  container;
  numberofcol = 1;
  @Output() load = new EventEmitter<boolean>();
  isReload = false;
  constructor(elRef: ElementRef, private PS: PtPlusRestService, private injector: Injector, private detector: ChangeDetectorRef) {
    super(elRef.nativeElement);
    tagspecificnewsweb = this;
    this.subscription = BasketDetailComponent.BASKET_NEWS_EVENT.subscribe(data => {
      this.setStockSpecificNews(data);
    });
    this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      this.changeMode();
  });
  if (!Utility.IS_MOBILE) {
    // this.container = <ComponentContainer>this.injector.get('Container');
  }

  Utility.webReloadComponent.subscribe(data =>
  {
    if(tagspecificnewsweb.isReload === false && (data === 'Equity News' || data === 'Derivative News' || data === 'Stock News'))
    {
      tagspecificnewsweb.isReload = true;
      this.getnewsData();
    }
  });
  }
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    Utility.setSize = this.container;
    this.changeMode();
  }
  setStockSpecificNews(data) {
    this.PS.getNewsService().getSearchedStockNews(data, this.getTagData.bind(this), this.getPreTagData.bind(this));
  }
  changeMode(){
    this.container = this.container === undefined ? Utility.setSize : this.container;
    this.Height = {
      'height': (this.container.height - TableUtility.NWHeight) + 'px',
      'overflow': 'auto'
      };
    this.detector.detectChanges();
    if (this.container.width >= 1200) {
      this.numberofcol = 4;
    } else if (this.container.width >= 768 && this.container.width < 1200) {
      this.numberofcol = 3;
    } else if (this.container.width >= 500 && this.container.width < 768) {
      this.numberofcol = 2;
    } else {
      this.numberofcol = 1;
    }
  }

  ngOnInit() {
    if(this.inputs !== undefined){
      this.IsStockSpecific = this.inputs.IsStockSpecific;
      this.isCustomDashboard = this.inputs.isCustomDashboard;
      this.tagtype = this.inputs.symbol;
    }
    this.isMobile = Utility.IS_MOBILE;
    this.targetElement = document.querySelector('html');
    if (this.isCustomDashboard) {
      this.changeMode();
      /* this.container.on('resize', _ => {
        this.changeMode();
      });*/
    }
    this.getnewsData();
  }
  ngOnChanges() {
    this.getnewsData();
    if(this.width)
      this.container={'width': this.width, 'height':this.height}
    this.changeMode();
  }
  getnewsData() {
    if (!this.IsStockSpecific) {
      const obj = {'text' : this.tagtype.split('-')[0]};
      this.isLoading = true;
      this.load.emit(this.isLoading);
      this.loaderEvent.emit(this.isLoading);
      this.PS.getNewsService().fetchTags(obj, this.getTagData.bind(this), this.getPreTagData.bind(this));
    }
  }
  getPreTagData(tags) {
    this.newsArr = tags;
  }
  getTagData(code, tags, message) {
    this.isLoading = false;
    this.isReload = false;
    this.load.emit(this.isLoading);
    this.loaderEvent.emit(this.isLoading);
    if (tags.length > 0) {
      this.newsArr = tags;
      this.isNoData = false;
    } else {
      this.isNoData = true;
    }

  }
  openNewTab(url, target) {
    window.open(url, target);
  }

  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      this.getnewsData();
      event.next();
    }, 1000);
  }
  
  alert(message: string) {
    
  }
}
