import { Component, OnInit, Inject } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { WorkspaceUtility } from '../home/customdashboard/workspaceutility';

@Component({
  selector: 'app-keyboardshortcuts',
  templateUrl: './keyboardshortcuts.component.html',
  styleUrls: []
})
export class KeyboardShortcutsComponent implements OnInit {

    isMobile;
    shortcutsMap;
    objectKeys = Object.keys;
    constructor() {
      this.isMobile = Utility.IS_MOBILE;
      this.shortcutsMap = Utility.shortcutsMap;
    }
    ngOnInit() {}
}
