import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '../../../../../../../node_modules/@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { Utility } from 'src/app/services/utility';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

let ob: WatchlistSettingsComponent;
@Component({
  selector: 'app-watchlistsettings',
  templateUrl: './watchlistsettings.component.html',
  styleUrls: ['./watchlistsettings.component.scss']
})
export class WatchlistSettingsComponent implements OnInit, OnDestroy {
  selectedLabel = 'mangewatchlist';
  colData;
  showheader = true;
  isMobile;
  orientationSubscription : Subscription;
  isMobileLand;
  @Input() formCustomDB;
  @Input() showManageColumns;
  @Output() addStockToNewWL = new EventEmitter<string>();
  @Output() closePopup = new EventEmitter();
  @Output() selectedLabelChip = new EventEmitter<any>();
  @Input() isOpen;
  constructor(private translate: TranslateService, private activatedRoute: ActivatedRoute) {
    ob = this;
    ob.isMobile = Utility.IS_MOBILE;
    ob.isMobileLand = Utility.IS_MOBILE_LAND;
    ob.colData = Preferences.getItem('displayedColumns', ReturnType.Array);
    this.activatedRoute.queryParams.subscribe(params => {
      this.showManageColumns = params['showManageCol'];
      this.formCustomDB = params['formCustomDB'];
      this.isOpen = params['isOpen'];
      ob.showheader = false;
    });
    this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
      this.isMobileLand = Utility.IS_MOBILE_LAND;
    });
  }
  
  ngOnDestroy(): void {
    this.orientationSubscription.unsubscribe();
  }

  ngOnInit() {
    if (ob.formCustomDB) {
      ob.showheader = false;
  }
  Utility.watchlistSettingOpen.subscribe((sampleData)=>
  {
    ob.selectedLabel = sampleData;
  })
  }
  addStockToNewWL1(data) {
    ob.addStockToNewWL.emit(data);
  }

  closeWatchlistPopup1() {
    ob.closePopup.emit();
  }

  onTabChange(str)
  {
    ob.selectedLabel = str;
    ob.selectedLabelChip.emit(ob.selectedLabel);
  }
}
