import { Component} from "@angular/core";
import { TradingRestService } from "src/app/services/tradingrest.service";
import { ChannelType, Utility } from "src/app/services/utility";
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MyAlertDialogComponent } from "../home/watchlist/watchlist.component";
import { MatDialog } from "@angular/material/dialog";
import { ScripModel } from "src/app/model/scrip.model";
import { Preferences, ReturnType } from "src/app/services/Preferences";

@Component({
    selector: 'app-triggermore',
    templateUrl: '../../'+environment.baseviewpath+'/triggermore/triggermore.component.html',
})
export class TriggerMoreComponent{
    selectedTrgData;
    scripModel: ScripModel;
    flashType = 'defaultFlash';
    statusMap = {'-1':'Cancelled', '0':'Failed', '1':'Active', '2': 'Executed', '3':'In Progress', 
    '4':'In Progress', '5':'Failed', '6':'Failed', '7':'In Progress', '8':'In Progress', '9':'In Progress', 
    '10':'In Progress', '11':'In Progress', '12':'In Progress', '13':'In Progress', '14':'OCO Disable', '15':'Expired'};
    isCancel: boolean;
    exchName: string;

    constructor(private _bottomSheetRef: MatBottomSheetRef<TriggerMoreComponent>,private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private translate: TranslateService, private router: Router, public dialog: MatDialog) {
        this.selectedTrgData = Utility.selectedTriggerData;
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
            this.flashType = Preferences.getItem('flashType');
          }
        Utility.flashType.subscribe(obj => {
            this.flashType = obj;
        });
        this.scripModel = Utility.getScripModel(this.selectedTrgData.cond.tkn, this.selectedTrgData.ord[0].ex, this.tradingRestService, true); 
        this.exchName = Utility.segmentToExchange(this.selectedTrgData.cond.ex);
    }

    deleteTrigger(data) {
        const str = this.translate.instant('all.areusure_delete') + data.cond.sym + this.translate.instant('all.c_order');
        Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: "Delete Order", message: str } });
        Utility.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
                const tid = data.tid;
                this.tradingRestService.getTriggerService().deleteTrigger(tid, function(data, code, msg) {
                    if(code === 200 && msg === 'SUCCESS') {
                        this._bottomSheetRef.dismiss({isCancel: true});
                        this.snackBar.open(this.translate.instant('all.trigger_deleted'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['successSnackBar'] });
                    } 
                    else if(code === 0 && msg === "Please verify your request.") {
                        this._bottomSheetRef.dismiss({isCancel: true});
                        this.snackBar.open(msg, this.translate.instant('all.close'),  { duration: 3000, panelClass: ['errorSnackBar'] });
                    }
                    else {
                        this._bottomSheetRef.dismiss();
                        this.snackBar.open(msg, this.translate.instant('all.close'),  { duration: 3000, panelClass: ['errorSnackBar'] });
                    }
                }.bind(this));
            }
        });
    }

    editTrigger(data) {
        Utility.triggerData = data;
        this._bottomSheetRef.dismiss();
        this.router.navigate(["/createtrigger"], {queryParams: {'isEdit':true, 'exseg':data.ord[0].ex, 'omtkn':data.cond.tkn}});
    }

    onSwipeDown() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
        this._bottomSheetRef.dismiss();
    }

    getClass(stat){
        return {'open-status': stat === 'Active',
                'traded-status': stat === 'Executed', 
                'rejected-status': stat === 'Failed',
                'Cancelled-status': stat === 'Cancelled',
                'in-progress-status': stat === 'In Progress',
              };
    }
}