import { Component, EventEmitter, Output, OnChanges, Input, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { DecimalPipe } from '@angular/common';
import { PtPlusRestService } from '../../../services/ptplusrest.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { TableUtility } from 'src/app/services/tableutility';

let peerComponent: PeerComponent;

@Component({
    selector: 'app-peer',
    templateUrl: './peer.component.html',
    styleUrls: ['./peer.component.scss']
})
export class PeerComponent implements OnChanges, OnInit {
    @Output() loaderEvent = new EventEmitter();
    @Input() omtkn: string;
    @Input() exseg: string;
    @Input() isCustomDashboard: boolean;
    temp;
    isyield: boolean;
    isbeta: boolean;
    ispe: boolean;
    ispb: boolean;
    isroe: boolean;
    iseps: boolean;
    public PerformanceData = [];
    public keyRatioData = [];
    datasource = [];
    style;
    isNoData: boolean;
    isLoading: boolean;
    selectedperformance;
    keycompare;
    performamcefilters = [];
    keycomparisonfilters = [];
    displayedColumns = ['Stock', 'PE', 'PB', 'Yield', 'Beta', 'ROE', 'EPS'];
    chartData = [];
    performanceLabels;
    chartOptions = {};
    chartType;
    colors;
    keyRatioLabels;
    chartPlugins;
    Height: any;
    container?: ComponentContainer;
    modesub: Subscription;

    constructor(private injector: Injector, private detector: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private ptPlusRestService: PtPlusRestService, private decimalPipe: DecimalPipe, private translate: TranslateService) {
        peerComponent = this;
        if (!Utility.IS_MOBILE) {
            peerComponent.container = <ComponentContainer>peerComponent.injector.get('Container');
        }
        peerComponent.isLoading = true;
        peerComponent.translate.get(['stock_detail.yield', 'stock_detail.beta']).subscribe(
            values => {
                peerComponent.performamcefilters = Object.keys(values).map(key => values[key]);
            }
        );
        peerComponent.translate.get(['stock_detail.p/e', 'stock_detail.p/b', 'stock_detail.roe', 'stock_detail.eps']).subscribe(
            values => {
                peerComponent.keycomparisonfilters = Object.keys(values).map(key => values[key]);
            }
        );
        peerComponent.selectedperformance = peerComponent.performamcefilters[0];
        peerComponent.keycompare = peerComponent.keycomparisonfilters[0];
        peerComponent.ispe = true;
        peerComponent.isyield = true;
        peerComponent.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            peerComponent.changeMode();
        });
    }

    changeMode(){
        peerComponent.Height = {
            'height': (peerComponent.container.height - TableUtility.PEERHeight) + 'px',
            'overflow': 'auto'
        };
        peerComponent.detector.detectChanges();
    }
    ngOnInit() {
        if (peerComponent.isCustomDashboard) {
            peerComponent.loadPeerData();
        }
        if (peerComponent.isCustomDashboard) {
            peerComponent.changeMode();
           /* peerComponent.container.on('resize', _ => {
              peerComponent.changeMode();
            });*/
          }
    }
    ngOnChanges() {
        peerComponent.loadPeerData();
    }
    loadPeerData() {
        peerComponent.loaderEvent.emit(true);
        peerComponent.ptPlusRestService.getFundamentalsService().getPeerCompData(peerComponent.omtkn, peerComponent.exseg, 'omnisys', (code, resData, message) => {
            peerComponent.isLoading = false;
            peerComponent.loaderEvent.emit(false);
            if (code === 200 && resData && Object.keys(resData).length > 0) {
                peerComponent.isNoData = false;
                peerComponent.temp = resData;
                peerComponent.datasource = peerComponent.getpeertableData();
                peerComponent.loadPerformanceData();
                peerComponent.loadKeyRatioData();
                peerComponent.loadChart();
            }
            else
                peerComponent.isNoData = true;
        });
    }
    loadChart() {
        const element = document.querySelector('.chart-color1');
        const style = getComputedStyle(element);
        peerComponent.chartType = 'bar';
        peerComponent.chartPlugins = [{
            beforeDraw: function (c) {
                const chartWidth = c.chart.width;
                c.scales['x-axis-0'].options.ticks.fontSize = chartWidth * 3 / 100;
            }
        }, { pluginDataLabels }]
        peerComponent.chartOptions = {
            layout: { padding: { left: 8, right: 8, top: 20, bottom: 8 } },
            responsive: true,
            maintainAspectRatio: false,
            legend: { display: false },
            scales: {
                xAxes: [{
                    gridLines: { display: false },
                    ticks: {
                        maxRotation: 0,
                        fontColor: style.color,
                        callback: function (label, index, labels) {
                            if (label.length > 8) {
                                const labelSplit = label.match(/.{1,7}/g);
                                return labelSplit;
                            } else
                                return label;
                        }
                    },
                    categoryPercentage: 0.5, barPercentage: 0.8
                }],
                yAxes: [{ gridLines: { lineWidth: 0, zeroLineColor: style.color }, ticks: { display: false } }]
            },
            plugins: { datalabels: { anchor: 'end', align: 'end', font: { size: 10 }, color: style.color }, crosshair: false },
            tooltips: {
                bodyFontColor: style.color,
                callbacks: {
                    title: function (tooltipItems, data) {
                        return '';
                    },
                    label: function (tooltipItem, data) {
                        const label = data.labels[tooltipItem.index];
                        return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    }
                }
            }
        };
        peerComponent.colors = [{ backgroundColor: style.fill }];
    }

    onperformanceSelection(event: MatSelectChange) {
        peerComponent.isbeta = false;
        peerComponent.isyield = false;
        if (event.value === peerComponent.translate.instant('stock_detail.yield'))
            peerComponent.isyield = true;
        else if (event.value === peerComponent.translate.instant('stock_detail.beta'))
            peerComponent.isbeta = true;
        peerComponent.loadPerformanceData();
    }

    onkeyratioSelection(event: MatSelectChange) {
        peerComponent.ispe = false;
        peerComponent.ispb = false;
        peerComponent.iseps = false;
        peerComponent.isroe = false;
        if (event.value === peerComponent.translate.instant('stock_detail.p/e'))
            peerComponent.ispe = true;
        else if (event.value === peerComponent.translate.instant('stock_detail.p/b'))
            peerComponent.ispb = true;
        else if (event.value === peerComponent.translate.instant('stock_detail.roe'))
            peerComponent.isroe = true;
        else if (event.value === peerComponent.translate.instant('stock_detail.eps'))
            peerComponent.iseps = true;
        peerComponent.loadKeyRatioData();
    }

    loadPerformanceData() {
        let count = 0;
        let peerValue;
        const peerPerformance = [];
        peerComponent.PerformanceData = [];
        peerComponent.performanceLabels = [];
        for (let i = 0; i < peerComponent.temp.length; i++) {
            if (peerComponent.isyield)
                peerValue = peerComponent.temp[i].ratio.YIELD.Value;
            else if (peerComponent.isbeta)
                peerValue = peerComponent.temp[i].ratio.BETA.Value;
            if (peerValue > 0) {
                count++;
                if (count <= 5) {
                    peerPerformance.push(peerValue);
                    peerComponent.performanceLabels.push(peerComponent.temp[i].TSYMBOL);
                }
            }
        }
        peerComponent.PerformanceData = peerComponent.loadgeneraldata(peerPerformance);
    }

    loadKeyRatioData() {
        let count = 0;
        let keyratioValue;
        const keyRatio = [];
        peerComponent.keyRatioLabels = [];
        peerComponent.keyRatioData = [];
        for (let i = 0; i < peerComponent.temp.length; i++) {
            if (peerComponent.ispe)
                keyratioValue = peerComponent.temp[i].ratio.APENORM.Value;
            else if (peerComponent.ispb)
                keyratioValue = peerComponent.temp[i].ratio.APRICE2BK.Value;
            else if (peerComponent.isroe)
                keyratioValue = peerComponent.temp[i].ratio.AROEPCT.Value;
            else
                keyratioValue = peerComponent.temp[i].ratio.AEPSNORM.Value;
            if (keyratioValue > 0) {
                count++;
                if (count <= 5) {
                    keyRatio.push(keyratioValue);
                    peerComponent.keyRatioLabels.push(peerComponent.temp[i].TSYMBOL);
                }
            }
        }
        peerComponent.keyRatioData = peerComponent.loadgeneraldata(keyRatio);
    }

    loadgeneraldata(yterm) {
        const roundoffres = [];
        for (let i = 0; i < yterm.length; i++) {
            roundoffres.push(peerComponent.transformDecimal(yterm[i]));
        }
        const pterm = roundoffres.map(function (v) {
            return parseFloat(v);
        });
        peerComponent.chartData = [{ data: pterm, stack: '' }];
        return peerComponent.chartData;
    }

    transformDecimal(num) {
        return peerComponent.decimalPipe.transform(num, '1.0-2');
    }

    getpeertableData() {
        const elementdata = [];
        for (let i = 0; i < 5; i++) {
            if (peerComponent.temp[i]) {
                elementdata.push({
                    Stock: peerComponent.temp[i].TSYMBOL,
                    Yield: peerComponent.temp[i].ratio.YIELD.Value,
                    Beta: peerComponent.temp[i].ratio.BETA.Value,
                    PE: peerComponent.temp[i].ratio.APENORM.Value,
                    PB: peerComponent.temp[i].ratio.APRICE2BK.Value,
                    ROE: peerComponent.temp[i].ratio.AROEPCT.Value,
                    EPS: peerComponent.temp[i].ratio.AEPSNORM.Value
                });
            }
        }
        elementdata.map(obj => {
            for (const value in obj) {
                if (obj[value] === '-99999.99000') {
                    obj[value] = '-';
                }
            }
        });
        return elementdata;
    }

}
