<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row list-view border-b" (swipedown)="onSwipeDown()">
    <div class="col-8 pl-0" *ngIf="showWlBtn">
        <div class="addwatchlistbtn" matRipple>
            <button mat-icon-button (click) = "addWLdata(scripModelData)" [matMenuTriggerFor]="menu" class="primetext"><i class="icon-plus"></i></button>
            <mat-menu #menu="matMenu">
                <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                <ng-container *ngFor = "let data of WlArr">
                    <button mat-menu-item (click) = "addStock(data.wlName, scripModelData)" [disabled] = "data.isDisable">
                        <span>{{data.wlName}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </div>
        <div class="float-left min-w160 subcolor ts-2">
            <span class="tc-1 ts-4 textclip">{{scripModelData.TradingSymbol | symbolpipe}}</span><span *ngIf = "scripModelData.ExchangeName === 'BSE'">({{scripModelData.InstrumentName}})</span><small class="exchangename">{{scripModelData.ExchangeName}}</small><br>
            <div class="texttrim" matTooltip = "{{scripModelData.DisplaySymbolDescription}}">{{scripModelData.DisplaySymbolDescription}}</div>
        </div>
    </div>
    <div class="col-8" *ngIf="!showWlBtn">
        <span class="tc-1 ts-4 textclip">{{((scripModelData.ExchangeSegment == 'nse_cm' || scripModelData.ExchangeSegment == 'bsc_cm') ? scripModelData.TradingSymbol : scripModelData.Symbolname) | symbolpipe}}</span><span *ngIf = "scripModelData.ExchangeName === 'BSE'">({{scripModelData.InstrumentName}})</span><small class="exchangename">{{scripModelData.ExchangeName}}</small><br>
        <div class="subcolor texttrim" matTooltip = "{{scripModelData.DisplaySymbolDescription}}">{{scripModelData.DisplaySymbolDescription}}</div>
    </div>
    <div class="col-4 text-right" [class.imnegative]="scripModelData.Change<0" [class.impositive]="scripModelData.Change>0">
        <div>
            <div class="ts-4"><span [class.imnegativebg]="scripModelData.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="scripModelData.TickDirection === 1 && flashType !== 'noFlash'">{{scripModelData.LastTradePrice | pricepipe:scripModelData.DecimalPrecision}}</span><br>
                <span class="ts-2" [class.imnegativebg]="scripModelData.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="scripModelData.TickDirection === 1 && flashType === 'defaultFlash'"> {{scripModelData.Change | pricepipe:scripModelData.DecimalPrecision}} <small class="ts-1">({{scripModelData.PercentageChange | percentagepipe}})</small></span>
        </div>
    </div>
</div>
</div>
<div class="row">
    <div class="col-12  nofilter">
        <mat-chip-list>
            <mat-chip (click)= "onTabChanged(0)" [class.imactive] = "tabIndex ===  0" translate>stock_detail.overview</mat-chip>
            <mat-chip (click)= "onTabChanged(1)" [class.imactive] = "tabIndex ===  1" translate>stock_detail.depth</mat-chip>
        </mat-chip-list>
    </div>
</div>
<div class="sheetscrollarea" [class.d-none] = "tabIndex !== 1">
    <app-depth smallrow="true" [omtkn] = "scripModelData.Token" [exseg] = "scripModelData.ExchangeSegment"></app-depth>
</div>
<div class="sheetscrollarea" [class.d-none] = "tabIndex !== 0">
    <div class="container-fluid">
        <div class="row text-center ts-3 py-2 border-b border-t mb-2">
            <div class="col-3"><span class="subcolor ts-1" translate>stock_detail.open</span><br>{{scripModelData.OpenPrice | pricepipe:scripModelData.DecimalPrecision }}</div>
            <div class="col-3"><span class="subcolor ts-1" translate>stock_detail.close</span><br>{{scripModelData.PrvClose | pricepipe:scripModelData.DecimalPrecision }}</div>
            <div class="col-3"><span class="subcolor ts-1" translate>stock_detail.low</span><br>{{scripModelData.Low | pricepipe:scripModelData.DecimalPrecision }}</div>
            <div class="col-3"><span class="subcolor ts-1" translate>stock_detail.high</span><br>{{scripModelData.High | pricepipe:scripModelData.DecimalPrecision }}</div>
        </div>
        <div class="row">
            <div class="col-12 p-0">
                <table class="table-bottomsheet">
                    <!--tr>
                        <td><span class="namefield" translate>stock_detail.open</span>{{scripModelData.OpenPrice | pricepipe:scripModelData.DecimalPrecision }}</td>
                        <td><span class="namefield" translate>stock_detail.close</span>{{scripModelData.PrvClose | pricepipe:scripModelData.DecimalPrecision }}</td>
                    </tr>
                    <tr>
                        <td><span class="namefield" translate>stock_detail.low</span>{{scripModelData.Low | pricepipe:scripModelData.DecimalPrecision }}</td>
                        <td><span class="namefield" translate>stock_detail.high</span>{{scripModelData.High | pricepipe:scripModelData.DecimalPrecision }}</td>
                    </tr-->
                    <tr>
                        <td><span class="namefield" translate>stock_detail.bid_price</span>{{scripModelData.BestBuyPrice | pricepipe:scripModelData.DecimalPrecision }}</td>
                        <td class="border-l"><span class="namefield" translate>stock_detail.ask_price</span>{{scripModelData.BestSellPrice | pricepipe:scripModelData.DecimalPrecision }}</td>
                    </tr>
                    <tr>
                        <td><span class="namefield" translate>stock_detail.ltq</span>{{scripModelData.Lasttradedquantity | numberpipe}}</td>
                        <td class="border-l"><span class="namefield" translate>stock_detail.ltt</span><span class="ts-2"> {{scripModelData.LastTradedTime |  datepipe:'datetime'}}</span></td>
                    </tr>
                    <tr>
                        <td><span class="namefield" translate>basket.average_price</span>{{scripModelData.VwapAveragePrice | pricepipe:scripModelData.DecimalPrecision}}</td>
                        <td class="border-l"><span class="namefield" translate>stock_detail.volume</span>{{scripModelData.TradeVolume | numberpipe}}</td>
                    </tr>
                    <tr>
                        <td><span class="namefield" translate>stock_detail.lower_ckt</span>{{scripModelData.LCircuitLimit | pricepipe: scripModelData.DecimalPrecision}}</td>
                        <td class="border-l"><span class="namefield" translate>stock_detail.upper_ckt</span>{{scripModelData.HCircuitLimit | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>

                    
                    <tr  *ngIf="scanner_name == 'Resistance & Support'">
                        <td><span class="namefield">R1</span>{{scripModelData.ResistanceOne | pricepipe: scripModelData.DecimalPrecision}}</td>
                        <td class="border-l"><span class="namefield">S1</span>{{scripModelData.SupportOne | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>
                    <tr  *ngIf="scanner_name == 'Resistance & Support'">
                        <td><span class="namefield">R2</span>{{scripModelData.ResistanceTwo | pricepipe: scripModelData.DecimalPrecision}}</td>
                        <td class="border-l"><span class="namefield">S2</span>{{scripModelData.SupportTwo | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>
                    <tr  *ngIf="scanner_name == 'Resistance & Support'">
                        <td><span class="namefield">R3</span>{{scripModelData.ResistanceThree | pricepipe: scripModelData.DecimalPrecision}}</td>
                        <td class="border-l"><span class="namefield">S3</span>{{scripModelData.SupportThree | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>
                    <tr  *ngIf="scanner_name == 'Resistance & Support'">
                        <td><span class="namefield">PIVOT</span>{{scripModelData.Pivot | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>    
                  
                 
                    <tr *ngIf="scanner_name == 'High/Low Breakers'">
                        <td><span class="namefield">52-WK HIGH</span>{{scripModelData.wkhigh | pricepipe: scripModelData.DecimalPrecision}}</td>
                        <td><span class="namefield">52-WK LOW</span>{{scripModelData.wklow | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>
  
                    <tr *ngIf="scanner_name == 'Technical Scanners' && (scanner_type_val == 'ltplow50DMA' || scanner_type_val == 'ltphigh50DMA') ">
                        <td><span class="namefield">50DMA</span>{{scripModelData.dmaFifty | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>

                    <tr *ngIf="scanner_name == 'Technical Scanners' && (scanner_type_val == 'ltplow200DMA' || scanner_type_val == 'ltphigh200DMA') ">
                        <td><span class="namefield">200DMA</span>{{scripModelData.dmaTwoHundred | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>

                    <tr *ngIf="scanner_name == 'Technical Scanners' && (scanner_type_val == 'macdhigh' || scanner_type_val == 'macdlow')">
                        <td><span class="namefield">MACD</span>{{scripModelData.macd | pricepipe: scripModelData.DecimalPrecision}}</td>
                        <td><span class="namefield">SIGNAL LINE</span>{{scripModelData.macd | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>

                    <tr *ngIf="scanner_name == 'Technical Scanners' && (scanner_type_val == 'rsi20' || scanner_type_val == 'rsi2030' || scanner_type_val == 'rsi3070' || scanner_type_val == 'rsi7080' || scanner_type_val =='rsihigh80')">
                        <td><span class="namefield">RSI</span>{{scripModelData.rsi | pricepipe: scripModelData.DecimalPrecision}}</td>
                    </tr>
                    
                </table>
            </div>
        </div>
    </div>
</div>


<div class="row bottomsheet-buttons">
    <div class="col-12">
        <div class="btngroup">
            <!--div *ngIf = "showWlBtn" [matMenuTriggerFor]="menu" matRipple><i class="icon-add-wlist primetext"></i>Watchlist</div>
            <mat-menu #menu="matMenu">
                <mat-optgroup label="Select Watchlist" class="watchlist-nav"></mat-optgroup>
                <ng-container *ngFor = "let data of WlArr">
                    <button mat-menu-item (click) = "addStock(data, scripModelData)">
                        <span>{{data}}</span>
                    </button>
                </ng-container>
            </mat-menu-->
            <div class="impositive" (click)="handleButton('B')" matRipple><i class="icon-bb"></i>{{'stock_detail.buy' | translate}}</div>
            <div class="imnegative" (click)="handleButton('S')" matRipple><i class="icon-sb"></i>{{'stock_detail.sell' | translate}}</div>
            <div (click)="openChart()" matRipple><i class="icon-insert-chart"></i>{{'stock_detail.chart' | translate}}</div>
            <div (click)="handleButton('D')" matRipple><i class="icon-ib"></i>{{'stock_detail.detail' | translate}}</div>
            <div *ngIf="createGttFlag" (click)="handleButton('T')" matRipple><i class="icon-aim"></i>{{'all.create_gtt' | translate}}</div>
        </div>
    </div>
</div>
