<div class="logoutpopup alertalign">
  <div class="mat-dialog-title">
    <span *ngIf = "!data.fromCSV">{{'all.confirm_btn' | translate}} ?</span>
    <span *ngIf = "data.fromCSV">{{'all.download' | translate}}</span>
  </div>
  <mat-dialog-content class="text-center">
    <svg class="infoIconPopup" viewBox="0 0 24 24">
      <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.86,15.86A8.28,8.28,0,1,1,20.29,12,8.26,8.26,0,0,1,17.86,17.86Z"/>
      <path d="M12,10.51a1.14,1.14,0,0,0-1.14,1.14v4.92a1.14,1.14,0,0,0,2.28,0V11.65A1.14,1.14,0,0,0,12,10.51Z"/>
      <circle cx="12" cy="7.43" r="1.14"/>
  </svg>
    <h1>{{data.heading}}</h1>
    <span class="subtext" [innerHTML] = "data.message"></span>
  </mat-dialog-content>
  <div class="mat-dialog-actions">
    <div class="row">
      <div *ngIf = "!data.fromCSV" class="col-12 text-right p-3" tabindex="-1" (keydown.arrowleft)="keyleft()" (keydown.arrowright)="keyright()" (keydown.enter) = "keyenter($event)">
        <button #b1 mat-flat-button matDialogClose="cancel" class="btn-plain" [class.h-btn]="selectbtn==1" >{{data.fromOrder ? ('all.dontcancel' | translate) :('all.cancel' | translate)}}</button>
        <button #b2 mat-flat-button matDialogClose="confirm" class="btn-primary" [class.h-btn]="selectbtn==2">{{data.fromOrder ? ('all.cancel_order' | translate) :  ('all.confirm_btn' | translate)}}</button>
      </div>
      <div *ngIf = "data.fromCSV" class="col-12 text-right pl-3 pr-3">
        <button  mat-flat-button matDialogClose="cancel" class="">{{'all.all' | translate}}</button>
        <button mat-flat-button matDialogClose="confirm" class="btn-primary">{{'all.filter' | translate}}</button>
      </div>
    </div>
  </div>
</div>