<mat-progress-bar *ngIf="isLoading" class="pageloader" mode="indeterminate"></mat-progress-bar>
<div class="cotainer-fluid header-stockname">
  <div class="row">
    <div class="col-8">
      <button mat-icon-button (click)="back()" class="backbutton"><i class="icon-goback"></i></button>
      <div class="stocknamewrapper">
        <div class="firstline">{{((scripModel.ExchangeSegment == 'nse_cm' || scripModel.ExchangeSegment == 'bsc_cm') ? scripModel.TradingSymbol : scripModel.Symbolname) | symbolpipe}}&nbsp;<span class="ts-2" *ngIf = "scripModel.ExchangeName === 'BSE'">({{scripModel.InstrumentName}})</span><small>{{scripModel.ExchangeName}}</small></div>
        <div class="line2 stock-texttrim">{{scripModel.DisplaySymbolDescription}}</div>
      </div>
    </div>
    <div class="col-4">
      <div class="float-right ml-4" *ngIf="isLand">
        <button mat-icon-button (click)="placeorder('B')" class="btn-positive mat-raised-button mr-1"><i class="icon-bb"></i></button>
        <button mat-icon-button (click)="placeorder('S')" class="btn-negative mat-raised-button"><i class="icon-sb"></i></button>
      </div>
      <div class="stockpricewrapper">
        <div [class.imnegative]="scripModel.Change<0" [class.impositive]="scripModel.Change>0">
          <div [class.imnegativebg]="scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="scripModel.TickDirection === 1 && flashType === 'defaultFlash'">
              <span [class.imnegativebg]="scripModel.TickDirection === -1 && flashType === 'ltpFlash'" [class.impositivebg]="scripModel.TickDirection === 1 && flashType === 'ltpFlash'" class="ts-4">{{scripModel.LastTradePrice | pricepipe:scripModel.DecimalPrecision}}</span><br>
              <span>{{scripModel.Change | pricepipe:scripModel.DecimalPrecision}} <small class="ts-2">({{scripModel.PercentageChange | percentagepipe}})</small></span>
          </div>    
        </div>
      </div>
    </div>
  </div>
</div>