import { Component, NgZone, OnInit } from '@angular/core';
import { TradingRestService } from '../../../services/tradingrest.service';
import { Utility } from '../../../services/utility';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { Router } from '@angular/router';
import { Preferences } from 'src/app/services/Preferences';

let profileComponent: ProfileComponent;
@Component({
  selector: 'app-profile',
  templateUrl: '../../'+environment.baseviewpath+'/profile/profile.component.html',
  styleUrls: ['./profile.component.scss']

})
export class ProfileComponent implements OnInit {

  isLoading: boolean;
  resData;
  accountDetails;
  bankDetails;
  selected;
  personalDetails;
  dpDetails;
  pObj = {};
  profileObject;
  personalData;
  bankData;
  bankDetail = [];
  accountData;
  Dpdata;
  loginId;
  accountId;
  clientName;
  isNoData: boolean;
  isNoSessionData: boolean;
  displayData;
  displayArray = [];
  bankAccountDisplay = [];
  isMobile = true;
  tabIndex;
  isBankDetail: boolean;
  isAccountDetail: boolean;
  isDpDetail: boolean;
  sessionData = [];
 
  constructor(private tradingRestService: TradingRestService, private ngZone: NgZone, private translate: TranslateService, private router: Router, private snackBar: MatSnackBar,  public dialog: MatDialog) {
    profileComponent = this;
    profileComponent.isMobile = Utility.IS_MOBILE;
    profileComponent.isLoading = true;
    profileComponent.translate.get(['settings.status_ind']).subscribe(
      values => {
        if (environment.isQuickRest) {
          profileComponent.profileObject = {
            'Bank': [
              { 'DataKey': 'bnkName', 'Display': profileComponent.translate.instant('settings.name') },
              { 'DataKey': 'bnkActNo', 'Display': profileComponent.translate.instant('settings.acc_no') },
              {'DataKey': 'bnkIfscCode', 'Display': profileComponent.translate.instant('settings.ifsc')}],
            'Account': [
              { 'DataKey': 'exchEnabled', 'Display': profileComponent.translate.instant('settings.exchanges_enabled') },
              { 'DataKey': 'email', 'Display': profileComponent.translate.instant('settings.email') },
              { 'DataKey': 'cellAddr', 'Display': profileComponent.translate.instant('settings.cell_add') },
              { 'DataKey': 'resAddr', 'Display': profileComponent.translate.instant('settings.address') },
              { 'DataKey': 'pan', 'Display': profileComponent.translate.instant('settings.pan') },
              { 'DataKey': 'offAddr', 'Display': profileComponent.translate.instant('settings.office_add') },
              { 'DataKey': 'actId', 'Display': profileComponent.translate.instant('settings.id') }, { 'DataKey': 'actName', 'Display': profileComponent.translate.instant('settings.name') },
              { 'DataKey': 'actTp', 'Display': profileComponent.translate.instant('settings.type') },
              { 'DataKey': 'actSt', 'Display': profileComponent.translate.instant('settings.status') }],
            'DP': [
              { 'DataKey': 'dpId', 'Display': profileComponent.translate.instant('settings.id') },
              { 'DataKey': 'dpNm', 'Display': profileComponent.translate.instant('settings.name') },
              { 'DataKey': 'dp', 'Display': profileComponent.translate.instant('settings.type') },
              { 'DataKey': 'dpNo', 'Display': profileComponent.translate.instant('settings.acc_no') },
              { 'DataKey': 'dpNo2', 'Display': profileComponent.translate.instant('settings.acc_no2') },
              { 'DataKey': 'dpNo3', 'Display': profileComponent.translate.instant('settings.acc_no3') }
            ]
          };
        }
        else {
          profileComponent.profileObject = {
            'Bank': [
              { 'DataKey': 'statusIndicator', 'Display': profileComponent.translate.instant('settings.status_ind') },
              { 'DataKey': 'exchEnabled', 'Display': profileComponent.translate.instant('settings.exchanges_enabled') },
              { 'DataKey': 'emailAddr', 'Display': profileComponent.translate.instant('settings.email') },
              { 'DataKey': 'cellAddr', 'Display': profileComponent.translate.instant('settings.cell_add') },
              { 'DataKey': 'address', 'Display': profileComponent.translate.instant('settings.address') },
              { 'DataKey': 'residencePhone', 'Display': profileComponent.translate.instant('settings.res_phone') },
              { 'DataKey': 'customerId', 'Display': profileComponent.translate.instant('settings.cust_id') },
              { 'DataKey': 'panNo', 'Display': profileComponent.translate.instant('settings.pan') },
              { 'DataKey': 'officephone', 'Display': profileComponent.translate.instant('settings.office_phone') },
              { 'DataKey': 'officeAddress', 'Display': profileComponent.translate.instant('settings.office_add') },
              { 'DataKey': '', 'Display': profileComponent.translate.instant('settings.id') }, 
              { 'DataKey': 'bankName', 'Display': profileComponent.translate.instant('settings.name') },
              { 'DataKey': 'bankAccountNo', 'Display': profileComponent.translate.instant('settings.acc_no') },
              { 'DataKey': 'bankAddres', 'Display': profileComponent.translate.instant('settings.address') },
              { 'DataKey': 'bankBranchName', 'Display': profileComponent.translate.instant('settings.branch_name') }],
            'Account': [
              { 'DataKey': 'accountId', 'Display': profileComponent.translate.instant('settings.id') }, 
              { 'DataKey': 'accountName', 'Display': profileComponent.translate.instant('settings.name') },
              { 'DataKey': 'accountType', 'Display': profileComponent.translate.instant('settings.type') },
              { 'DataKey': 'accountStatus', 'Display': profileComponent.translate.instant('settings.status') }],
            'DP': [
              { 'DataKey': 'dpId', 'Display': profileComponent.translate.instant('settings.id') }, 
              { 'DataKey': 'dpName', 'Display': profileComponent.translate.instant('settings.name') },
              { 'DataKey': 'depository', 'Display': profileComponent.translate.instant('settings.type') },
              { 'DataKey': 'dpAccountNumber', 'Display': profileComponent.translate.instant('settings.acc_no') },
              { 'DataKey': 'dpAccountNumber2', 'Display': profileComponent.translate.instant('settings.acc_no2') },
              { 'DataKey': 'dpAccountNumber3', 'Display': profileComponent.translate.instant('settings.acc_no3') }
            ]
          };
        }
        this.getData();
      }
    );
  }

  getData(){
    profileComponent.tradingRestService.getUserService().getUserProfile((data, code, msg) => {
      profileComponent.isLoading = false;
      if (code === 200 && msg === 'OK') {
        profileComponent.resData = data;
        profileComponent.getProfileData();
        profileComponent.accountDetails = profileComponent.pObj['accountDetails'];
        profileComponent.bankDetails = profileComponent.pObj['bankDetails'];
        profileComponent.dpDetails = profileComponent.pObj['dpDetails'];
        profileComponent.getMaskedValue(profileComponent.accountDetails);
        profileComponent.getMaskedValue(profileComponent.dpDetails);
        profileComponent.getMaskedValue(profileComponent.bankDetails);

      }
      else
        profileComponent.isNoData = true;
    }, profileComponent.setPreProfileData); 
    this.getSessionDetails();
    this.displayArray = ['settings.account_details', 'settings.bank_details', 'settings.dp_details'];
    this.tabIndex = 0;
  }

  ngOnInit() {
  }

  setPreProfileData(data){
        profileComponent.resData = data;
        profileComponent.getProfileData();
        profileComponent.accountDetails = profileComponent.pObj['accountDetails'];
        profileComponent.bankDetails = profileComponent.pObj['bankDetails'];
        profileComponent.dpDetails = profileComponent.pObj['dpDetails'];
        profileComponent.getMaskedValue(profileComponent.accountDetails);
        profileComponent.getMaskedValue(profileComponent.dpDetails);
        profileComponent.getMaskedValue(profileComponent.bankDetails);
  }

  onTabChanged(index){
    this.tabIndex = index;
  }

  getSessionDetails(){
    this.tradingRestService.getLoginService().getAllSessions((data, code, msg) => {
      profileComponent.isLoading = false;
      if (code === 200 && msg === 'OK'){
       this.sessionData = data;
       profileComponent.isNoSessionData = false;
      }
      else{
        profileComponent.isNoSessionData = true;
      }
    });

  }

  clearSession(){

        const alertMessage = 'Do you want to clear session?';
        const dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('settings.clear_session_hed'), message: alertMessage, fromOrder: false } });
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'confirm') {
            this.tradingRestService.getLoginService().clearAllSessions((data, code, msg) => {
              if (code === 200 && msg === 'OK'){
               this.sessionData = [];
               Utility.dialogRef?.close();
               Preferences.setPreference('LoggedIn', 'false');
               Preferences.setPreference('userSessionId', null);
               Utility.candeactivate = false;
               Preferences.setPreference('finallogin', 'false');
               this.ngZone.run(() => {
                this.router.navigate(['/login'], { replaceUrl: true });
          });
              }
              this.snackBar.open(this.translate.instant(Utility.getErrorMessage(code,msg)), '', { duration: 3000 });
            });
          }
        });
  }


  getProfileData() {
    profileComponent.loginId = Preferences.getPreference('userid');
    profileComponent.accountId = profileComponent.resData['accountId'];
    profileComponent.clientName = profileComponent.resData['accountName'];
    // profileComponent.getPersonalDetails();
    profileComponent.getAccountDetails();
    profileComponent.getBankDetails();
    profileComponent.getDpDetails();

    profileComponent.pObj = {
      'bankDetails': profileComponent.bankData, 'accountDetails': profileComponent.accountData, 'dpDetails': profileComponent.Dpdata
    };
  }

  getAccountDetails() {
    profileComponent.accountData = profileComponent.profileObject.Account;
    let exchArr = [];
    let exchObj;
    if(profileComponent.accountData.length > 0) {
      for (let i = 0; i < profileComponent.accountData.length; i++) {
        const obj = profileComponent.accountData[i];
        if (profileComponent.resData[obj['DataKey']] != null) {
          if (obj['DataKey'] === 'exchEnabled') {
            exchArr = profileComponent.resData[obj['DataKey']].split('|');
            const concatExch = [];
            for (let j = 0; j < exchArr.length; j++) {
              concatExch.push(Utility.segmentToExchange(exchArr[j]));
              exchObj = concatExch.join(', ');
            }
            obj['Value'] = exchObj;
          }
          else{
            obj['Value'] = profileComponent.resData[obj['DataKey']];
          }
        }
        else {
          obj['Value'] = '-';
        }
      }
    } 
    else {
      this.isAccountDetail = true;
    }    
  }

  getBankDetails() {
    profileComponent.bankData = profileComponent.profileObject.Bank;
    profileComponent.bankDetail = profileComponent.resData['bnkDtl'];
    for (let i = 0; i < profileComponent.bankDetail.length; i++){
      profileComponent.bankAccountDisplay[i] = { 'value': profileComponent.bankDetail[i]['bnkActNo'], 'viewValue': profileComponent.bankDetail[i]['bnkName']+ '-' +Utility.getMaskedValue(profileComponent.bankDetail[i]['bnkActNo']) };
    }
    if(profileComponent.bankDetail.length > 0) {
      profileComponent.selected = profileComponent.bankDetail[0]['bnkActNo'];
      for (let i = 0; i < profileComponent.bankData.length; i++) {
        const obj = profileComponent.bankData[i];
        if (profileComponent.bankDetail[0][obj['DataKey']] != null) {
          obj['Value'] = profileComponent.bankDetail[0][obj['DataKey']];
        }
        else {
          obj['Value'] = '-';
        }
      }
    } else {
      this.isBankDetail = true;
    }
  }

  getDpDetails() {
    profileComponent.Dpdata = profileComponent.profileObject.DP;
    if(profileComponent.Dpdata.length > 0) {
      for (let i = 0; i < profileComponent.Dpdata.length; i++) {
        const obj = profileComponent.Dpdata[i];
        if (profileComponent.resData[obj['DataKey']] != null) {
          obj['Value'] = profileComponent.resData[obj['DataKey']];
        }
        else {
          obj['Value'] = '-';
        }
      }
    }
    else {
      this.isDpDetail = true;
    } 
  }

  onSelection(event: any){
    for (let i = 0; i < profileComponent.bankDetail.length; i++){
      if (event === profileComponent.bankDetail[i]['bnkActNo']){
        for (let j = 0; j < profileComponent.bankData.length; j++) {
          const obj = profileComponent.bankData[j];
          if (profileComponent.bankDetail[i][obj['DataKey']] != null) {
            obj['Value'] = profileComponent.bankDetail[i][obj['DataKey']];
          }
          else {
            obj['Value'] = '-';
          }
        }
      }
    }
    profileComponent.bankDetails = profileComponent.bankData;
    profileComponent.getMaskedValue(profileComponent.bankDetails);
    }

  getMaskedValue(displayData) {
    for (let i = 0; i < displayData.length; i++) {
      if (displayData[i]['DataKey'] === 'pan' || displayData[i]['DataKey'] === 'panNo' || displayData[i]['DataKey'] === 'cellAddr' || displayData[i]['DataKey'] === 'bankAccountNo' || displayData[i]['DataKey'] === 'dpAccountNumber' ||
        displayData[i]['DataKey'] === 'officephone' || displayData[i]['DataKey'] === 'residencePhone' || displayData[i]['DataKey'] === 'dpAccountNumber2' ||
        displayData[i]['DataKey'] === 'dpAccountNumber3' || displayData[i]['DataKey'] === 'bnkActNo') {
        displayData[i]['Value'] = Utility.getMaskedValue(displayData[i]['Value']);
      }
    }
  }

}
