import { PersistenceService, StorageType } from 'angular-persistence';
import { AppComponent } from '../app.component';
import { Utility } from './utility';

export class PersistenceStorage
{
    private persistenceService;
    constructor(){
        this.persistenceService = AppComponent.injector.get(PersistenceService);
    }

    public setCacheData(key, value) {
        const newKey = Utility.getUserId() + '_' + key;
        this.persistenceService.set(newKey, value, {type: StorageType.LOCAL});
    }

    public getCacheData(key) {
        const newKey = Utility.getUserId() + '_' + key;
        return this.persistenceService.get(newKey, StorageType.LOCAL);
    }

    public removeCacheData(key) {
        const newKey = Utility.getUserId() + '_' + key;
        return this.persistenceService.remove(newKey, StorageType.LOCAL);
    }
}