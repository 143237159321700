<app-header *ngIf="isMobile" goback="1" title="Spread Order History" ></app-header>
<app-titlebar *ngIf="!isMobile && showtitle" title="Spread Order History" ></app-titlebar>
<mat-card [class.webpanelnew]="!isMobile">
    <mat-card-content>
        <div [class.pt-0]="!isMobile" [class.data-container]="isMobile" *ngIf = "!isLoading && !isNoData">
            <div [class.px-3]="!isMobile">
				<div class="watchlistwrapper">
					<div class="row">
						<div class="col-7">
							<div class="firstline">{{detailedOrderData.TradeSymbol}} <small>{{detailedOrderData.Exchange}}</small></div>
							<div class="line2 subcolor">{{detailedOrderData.SymbolName}}</div>
						</div>
						<div [class.imnegative]="scripModel.Change<0" [class.impositive]="scripModel.Change>0" class="col-5 text-right">
							<div [class.imnegativebg]="scripModel.TickDirection === -1" [class.impositivebg]="scripModel.TickDirection === 1">
								<div class="firstline">{{scripModel.LastTradePrice | pricepipe:scripModel.DecimalPrecision}}</div>
								<div class="line2">{{scripModel.Change | pricepipe:scripModel.DecimalPrecision}} ({{scripModel.PercentageChange | percentagepipe}})</div>
							</div>
						</div>
						<div class="col-5 text-right">
						</div>
					</div>
				</div>
				<div *ngFor="let data of orderHistoryList;">
					<div class="watchlistwrapper">
						<div class="row">
							<div class="col-7 line2 graytext" >{{data.Status}}</div>
							<div class="col-6">
								<div class="line-height">
								  <span class="graytext" translate>L1 Avg Price</span><span class="graytext">:</span>
								  <span class="float-right" >{{data.AveragePrice | pricepipe:scripModel.DecimalPrecision}}</span>
								</div>
							  </div>
							  <div class="col-6 text-right">
								<div class="line-height">
								  <span class="graytext float-left" translate>Fill/Qty</span><span class="graytext float-left">:</span>
								{{data.FilledSharesQuantity}}/{{data.Qty}}
								</div>
							  </div>
						</div>
						<div class="row">
							<div class="col-6">
								<div class="line-height">
								  <span class="graytext" translate>L2 Avg Price</span><span class="graytext">:</span>
								  <span class="float-right" >{{data.AveragePriceLeg2 | pricepipe:scripModel.DecimalPrecision}}</span>
								</div>
							  </div>
							  <div class="col-6 text-right">
								<div class="line-height">
								  <span class="graytext float-left" translate>Fill/Qty</span><span class="graytext float-left">:</span>
								{{data.FilledSharesQuantityLeg2}}/{{data.QtyLeg2}}
								</div>
							  </div>
						</div>

					</div>
				</div>
            </div>
		</div>
		<div *ngIf = "!isLoading && isNoData">
			<app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
		</div>
	</mat-card-content>
</mat-card>
