import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pricepipe' })

export class PricePipe implements PipeTransform {
  transform(price, priceformater) {
    if (priceformater === -1) {
      return price.toString().replace(',', '');
    }
    if (priceformater === undefined || priceformater === '--') {
      priceformater = 2;
    }else {
      priceformater = parseInt(priceformater, 10);
    }
    if (price !== undefined && isNaN(price) && price.toString().indexOf(',') > -1) {
      price = price.replace(',', '');
    }
    if (price === '0' || price === '-0' || Number(price) === 0) {
      if (priceformater === 2) {
        return '0.00';
      }
      else {
        return '0.0000';
      }
    }
    else if (price === undefined || price === '' || isNaN(price) || price === '-99999.99000') {
      return '-';
    }
    else if (parseFloat(price)) {
      return this.convertINR(price, priceformater);
    }
    else {
      return price;
    }
  }

  convertINR(num, priceformater) {
    let isNegative = false;
    num = this.convertFloat(num, priceformater);
    if (num < 0) {
      isNegative = true;
      num = num * -1;
    }
    num = num.toString();
    let afterPoint;
    if (num.indexOf('.') > 0) {
      afterPoint = num.substring(num.indexOf('.'), num.length);
    }
    else {
      afterPoint = '.';
    }
    afterPoint = this.addZeroes(afterPoint, Number(priceformater) + 1);
    num = Math.floor(num);
    num = num.toString();
    let lastThree = num.substring(num.length - 3);
    const otherNumbers = num.substring(0, num.length - 3);
    if (otherNumbers !== '') {
      lastThree = ',' + lastThree;
    }
    const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
    if (res === 'NaN') {
      return '-';
    }
    return (isNegative ? '-' : '') + res;
  }

  convertFloat(amt, priceformater) {
    amt = parseFloat(amt);
    return amt.toFixed(priceformater);
  }

  addZeroes(str, length) {
    let zeroes = '';
    for (let i = length - str.length; i > 0; i--) {
      zeroes += '0';
    }
    return str + zeroes;
  }
}
