import { Component, OnInit } from "@angular/core";
import { Preferences } from "src/app/services/Preferences";
import { PtPlusRestService } from "src/app/services/ptplusrest.service";
import { TradingRestService } from "src/app/services/tradingrest.service";
import { Url } from "src/app/services/url";
import { Utility } from "src/app/services/utility";
import { environment } from "src/environments/environment";
import { UsertodayssipComponent } from "../thematicbasket/usertodayssip/usertodayssip.component";

@Component({
    selector: 'app-combineqprofiletodaysip',
    templateUrl: '../../'+environment.baseviewpath+'/combineqprofiletodaysip/combineqprofiletodaysip.component.html',
})

export class combineQProfileTodaySip implements OnInit {
    isUserTodaySip = false;
    isQProfileData =  false;
    isMobile;
    isTodaySipOpen;
    fromBasket;
    constructor(private PS: PtPlusRestService, private  tradingRestService: TradingRestService){
        this.isMobile = Utility.IS_MOBILE;
        this.isTodaySipOpen = Utility.isTodaySipOpen;
        this.fromBasket = Utility.fromBasket;
        if(Utility.todaysSipData !== undefined && Utility.todaysSipData.length > 0){
            this.isUserTodaySip = true;
        }
        
        if(Utility.dialogData !== undefined && Utility.dialogData.length > 0){
            this.isQProfileData =  true; 
        }  
    }
    
    ngOnInit(): void {
    }

    setDate(flag) {
        let currentData = new Date().getDate();
        let obj = {'status': flag, 'todaySip': false, 'date': currentData};
        Preferences.setItem('callQprofile', obj);
        Utility.dialogRef.close();
    }

    close() {
        Utility.iscombineQProfileClose = false;
        Utility.dialogRef.close();
    }

}
