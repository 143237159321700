import { Component, OnInit, Input, OnChanges, Injector, ChangeDetectorRef, Output, EventEmitter, ElementRef } from '@angular/core';
import { ScripMarketPictureModel } from 'src/app/model/scripMarketPictureModel';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { ActivatedRoute } from '@angular/router';
import { ChannelType, Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { ScripModel } from 'src/app/model/scrip.model';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let depth : DepthComponent;

@Component({
    selector: 'app-depth',
    templateUrl: './depth.component.html',
    styleUrls: ['./depth.component.scss']
})
export class DepthComponent extends BaseComponentDirective implements OnChanges, OnInit {
    isNoData;
    loadBaiKaiDetailList: ScripMarketPictureModel;
    scripmodel;
    @Input() omtkn: string;
    @Input() smallrow: boolean;
    @Input() exseg: string;
    @Input() symbol: string;
    @Input() isCustomDashboard: boolean;
    @Input() isPlaceOrder: boolean;
    @Output() priceEmit = new EventEmitter<any>();
    @Output() load = new EventEmitter<boolean>();
    @Output() loaderEvent = new EventEmitter<boolean>();
    Height = {};
    container;
    modesub: Subscription;
    isDerivativeScrip = false;
    isReload = false;
    ismobile;

    constructor(elRef: ElementRef, private injector: Injector, private detector: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private tradingRestService: TradingRestService) {
         super(elRef.nativeElement);
        depth = this;
        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });
        this.ismobile = Utility.IS_MOBILE;
    }

    changeMode(){
        this.Height = {
            'height': (this.container.height) + 'px',
            'overflow-x': 'hidden',
            'overflow-y': 'auto'
        };
        this.detector.detectChanges();
    }
    ngOnInit() {
        if(this.inputs !== undefined){
            this.exseg = this.inputs.exch;
            this.isCustomDashboard = this.inputs.isCustomDashboard;
            this.omtkn = this.inputs.token;
            this.symbol = this.inputs.symbol;
        }
        if (this.isCustomDashboard) {
            // this.container = <ComponentContainer>this.injector.get('Container');
        }
        if (this.container) {
            /* this.container.on('resize', _ => {
                this.changeMode();
            });*/
        }
        if (this.isCustomDashboard) {
            this.loadDepth();
        }
        if (this.isCustomDashboard) {
            this.changeMode();
        }

        Utility.webReloadComponent.subscribe(data =>
        {
            if(depth.isReload === false && data === 'Depth')
            {
                depth.isReload = true;
                this.loadDepth();
            }
            setTimeout(()=>
            {
                depth.isReload = false;
            }, 5000);
        });
    }
    depthPrice(price) {
        this.priceEmit.emit(price);
    }
    loadDepth() {
        // const data = {};
        // data['exch'] = this.exseg;
        // data['scripToken'] = this.omtkn;
        // data['restClient'] = this.tradingRestService;
        // this.scripmodel = Utility.getScripModel(this.omtkn, this.exseg, this.tradingRestService);
        // this.loadBaiKaiDetailList = [];
        // this.tradingRestService.getScripService().ShowMarketPicture(data, function(responseData, code, message) {
        //     if (code === 200 && message === 'Ok') {
        //         this.isNoData = false;
        //         this.loadBaiKaiDetailList = responseData;
        //         // this.loadBaiKaiDetailList.push(responseData);
        //     }
        //     else {
        //         this.isNoData = true;
        //     }
        // }.bind(this));
        this.load.emit(true);
        this.loaderEvent.emit(true);
        this.scripmodel = Utility.getScripModel(this.omtkn, this.exseg, this.tradingRestService);
        this.isDerivativeScrip = Utility.GetParentSegment(this.scripmodel.ExchangeName) === 'DERIVATIVE';
        this.loadBaiKaiDetailList = Utility.GetScripMarketPictureModel(this.omtkn, this.exseg, this.tradingRestService);
        setTimeout(() => {
            this.load.emit(false);
            this.loaderEvent.emit(false);
            this.detector.detectChanges();
        }, 1000);
        this.symbol = this.scripmodel.TradingSymbol === undefined ? this.symbol : this.scripmodel.TradingSymbol;
        if (this.omtkn && this.exseg)
            Utility.addRecentlySearchedScrips(this.omtkn, this.exseg, this.symbol, this.scripmodel.CompanyName);
    }
    ngOnChanges() {
        this.loadDepth();
    }
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        this.changeMode();
      }
}
