import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Utility } from 'src/app/services/utility';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { environment } from 'src/environments/environment';
let autoSearch: AutoSearchComponent;
let autoSearchComponent = {};
@Component({
  selector: 'app-autocompletesearch',
  templateUrl: '../../'+environment.baseviewpath+'/autocompletesearch/autocompletesearch.component.html'
})
export class AutoSearchComponent implements OnInit {
  @Output() selectData = new EventEmitter();
  @Input() pageName;
  @ViewChild('mtinput', { static: true }) mtinput: ElementRef;
  filteredOptions: Observable<any>;
  obj = { 'start': 0, 'limit': 50 };
  searchType = 'ALL';
  keyword = '';
  allowedExchange = [];
  myControl = new FormControl();
  eventParameter;
  options = [];
  isselectdata = false;
  @Input() searchData;
  @Input() frompage;
  segmentToExchange;
  inputValue: any;
  isPlaceOrder = false;
  counter = 0;
  constructor(private PS: PtPlusRestService, private detector: ChangeDetectorRef) {
    this.segmentToExchange = Utility.segmentToExchange;
  }

  ngOnInit() {
    if(this.frompage === 'isPlaceOrder')
      this.isPlaceOrder = true;
    this.eventParameter = this.pageName;
    autoSearchComponent[this.pageName] = this;
    if (this.searchData !== undefined)
      autoSearchComponent[this.pageName].myControl.setValue({'tsym': this.searchData});
    const exarr = Utility.getTradingExchanges();
    for (let i = 0; i < exarr.length; i++) {
      autoSearchComponent[this.pageName].allowedExchange.push(Utility.exchangeToSegment(exarr[i]));
    }
  }
  onInput(event, page) {
    autoSearch = autoSearchComponent[page];
    autoSearch.pageName = page;
    this.inputValue = autoSearchComponent[autoSearch.pageName].keyword = event.target.value;
    if (autoSearchComponent[autoSearch.pageName].keyword.length >= 3 && autoSearchComponent[autoSearch.pageName].keyword !== ' ') {
      const obj = {};
      obj['keyword'] = (this.frompage === 'spreadOrder') ? autoSearchComponent[autoSearch.pageName].keyword + ' spread' : autoSearchComponent[autoSearch.pageName].keyword;
      obj['search_type'] = autoSearchComponent[autoSearch.pageName].searchType;
      obj['allowed_exchange'] = autoSearchComponent[autoSearch.pageName].allowedExchange;
      autoSearchComponent[autoSearch.pageName].PS.getSearchService().fetchData(++autoSearchComponent[autoSearch.pageName].counter, obj, autoSearchComponent[autoSearch.pageName].setData);
    } else {
      if (this.pageName === 'EventFilter')
        autoSearchComponent[autoSearch.pageName].selectData.emit('');
    }
    autoSearchComponent[autoSearch.pageName].filteredOptions = of([]);
  }
  setData(tags, message, counter) {
    if (autoSearchComponent[autoSearch.pageName].counter === counter) {
      if (tags.length > 0) {
        autoSearchComponent[autoSearch.pageName].options = tags;
        autoSearchComponent[autoSearch.pageName].filteredOptions = autoSearchComponent[autoSearch.pageName].myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value),
            map(tag => tag ? autoSearchComponent[autoSearch.pageName]._filter(tag) : autoSearchComponent[autoSearch.pageName].options.slice(0, 20))
          );
      }
      setTimeout( () => {
        autoSearchComponent[autoSearch.pageName].detector.detectChanges();
      }, 500);
    }
  }
  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    autoSearchComponent[autoSearch.pageName].selectData.emit(event.option.value);
    autoSearch.isselectdata = true;
  }
  private _filter(tag: any) {
    const filterValue = tag['tsym']?.toLowerCase();
    return autoSearchComponent[autoSearch.pageName].options.filter(option => option.tsym.toLowerCase().indexOf(filterValue) === 0);
  }
  displayFn(data?: any): string | undefined {
    return data ? data.tsym : undefined;
  }

  onFocusOut()
  {
    setTimeout(() => {
      if (!autoSearch.isselectdata) {
        autoSearchComponent[autoSearch.pageName].keyword = '';
        autoSearchComponent[autoSearch.pageName].mtinput.nativeElement.value = '';
        autoSearchComponent[autoSearch.pageName].filteredOptions = of([]);
      }
      autoSearch.isselectdata = false;
    }, 1000);
  }

  onClearSearch() {
    this.inputValue = '';
  }
  validation(ev) {
    var k;  
    k = ev.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 38 || k == 45);
  }
}
