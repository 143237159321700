<mat-card class="webpanelnew" [class.rightcon2]="isMobile" [ngStyle]="Height">
    <mat-card-content>
        <div [class.pt-0]="!isMobile">
            <ng-container *ngIf = "!isNoData && !isMobile">
                <div class="table-global ismousemode">
                    <table class="mat-table cdk-table" role="grid">
                        <thead role="rowgroup">
                            <tr role="row" class="mat-header-row cdk-header-row">
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left" colspan="2">{{'settings.name' | translate}}</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">{{'basket.min_investment' | translate}}</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">{{'dashboard.risk' | translate}}</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">{{'all.rt_oneyr' | translate}}</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">{{'basket.invest_period' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            <tr role="row" class="mat-row cdk-row cursor-pointer"  *ngFor='let data of basketList' (click)="onClickBasket(data)">
                                <td role="gridcell" class="mat-cell cdk-cell text-center" width="50px"><img alt="" style="height:40px; width: 40px;" class="img-responsive" src={{data.image}} ></td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.name}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.min_investment | pricepipe}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left" [class.imnegative]="data.risk_factors==='h'" [class.impositive]="data.risk_factors==='l'">{{riskMap[data.risk_factors]}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left" [class.imnegative]="data.percentChange365<0" [class.impositive]="data.percentChange365>0">{{data.percentChange365|percentagepipe}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{investmentPeriodMap[data.investment_period]}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>                                
            </ng-container>

            <ng-container *ngIf = "!isNoData && isMobile">
                <div *ngFor='let data of basketList'>
                    <div class="row" (click)="onClickBasket(data)">
                        <div class="col-12">
                            <div class="watchlistwrapper">
                                <div class="row">
                                    <div class="col-2 col-md-3">
                                        <img alt="" class="stockimg" src={{data.image}} >
                                    </div>
                                    <div class ="col-10 col-md-9 pl-1 my-auto">
                                        <div class="row">
                                            <label class="col-12 tc-1 ts-4 textclip">{{data.name}}</label>
                                            <div class="col-7 pr-0">
                                                <label class="line2 subcolor ts-2">{{'basket.min_investment' | translate}}:&nbsp;</label><span>{{data.min_investment | pricepipe}}</span><br/>
                                                <label class="line2 subcolor ts-2">{{'basket.invest_period' | translate}}:&nbsp;</label>{{investmentPeriodMap[data.investment_period]}}
                                            </div>
                                            <div class="col-5 pl-0 text-right">
                                                <label class="line2 subcolor ts-2">{{'dashboard.risk' | translate}}: </label><label [class.imnegative]="data.risk_factors==='h'" [class.impositive]="data.risk_factors==='l'">{{riskMap[data.risk_factors]}}</label>
                                                <br>
                                                <label class="line2 subcolor ts-2">{{'all.rt_oneyr' | translate}}:&nbsp;</label><label [class.imnegative]="data.percentChange365<0" [class.impositive]="data.percentChange365>0">{{data.percentChange365|percentagepipe}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf='compareBasketStatus' class="row">
                        <div class="col-12 text-right">
                            <mat-checkbox [disabled]="selectedBasket.length>=2 && !selectedBasket.includes(data.id)"
                                [checked]='selectedBasket.includes(data.id)' (change)="userSelectClick($event, data)"><span translate>basket.compare</span>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="row border-b">
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf='!isLoading && isNoData' class='nodata'>
                <app-nodata message = "N0 DATA" (refreshEvent) = "getData()" [btnTitle]="'utility.reload' | translate"></app-nodata>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>