<app-header *ngIf="isMobile" goback="1" [isLoading]="isLoading" [title]="'basket.repair_orders' | translate"></app-header>
<app-titlebar *ngIf="!isMobile" [isLoading]="isLoading" [title]="'basket.repair_orders' | translate"></app-titlebar>
<mat-dialog-content>
    <div [class.onlyheaderfromtop]="isMobile" [class.mb-5]="isMobile">
        <div [class.pl-2] = "!isMobile" [class.pr-2] = "!isMobile" [class.pt-3] = "isMobile">
            <div class="row pb-2">
                <div class="col-7">
                    <span class="primetext">{{'basket.batch_orders' | translate}} #{{batchNo}}</span>
                </div>
            </div>   
            <div class="po-section-data" [class.basket-popup-content2]="!isMobile" [class.basket-popup-content4]="isMobile">
                <div class="row" *ngFor='let data of repairDataDetails; let i = index;'>
                    <div class="col-12">
                        <div class="list-view  border-b">
                            <div class="row">
                                <div class="col-6">
                                    <span class="tc-1 ts-4 textclip">{{data.trdSym}}</span>
                                    <span><small class="exchangename">{{scripModelArr[i].ExchangeName}}</small></span>
                                    <div class="pt-1">
                                        <!-- <span class="subcolor ts-2">Weights(%):</span>
                                        <span class="pl-1">{{data.percent_weight | numberpipe}}%</span><span class="subcolor ts-2"> | </span> -->
                                        <span class="subcolor ts-2">{{'portfolio.qty' | translate}}:</span>
                                        <span class="fw-4 tc-3 pl-1">{{data.req_qty-data.fill_qty}}</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="subcolor ts-2 text-right">
                                        <div class="">
                                            <span class="subcolor2 ts-2">{{'stock_detail.ltp' | translate}}: </span>
                                            <span class="ts-4 tc-1 pl-1" [class.impositive] = "scripModelArr[i].Change > 0" [class.imnegative] = "scripModelArr[i].Change < 0">
                                                {{scripModelArr[i].LastTradePrice | pricepipe}}
                                            </span>
                                            <div class="pt-1">
                                                <span class="subcolor2 ts-2">{{'books.amt' | translate}} :</span>
                                                <span class="pl-1">{{scripModelArr[i].LastTradePrice*data.req_qty | pricepipe}}</span>
                                            </div>   
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-actions d-block" [class.mat-action-web]="!isMobile">
    <div class="row">
        <div class="col-12 text-right px-0" [class.padd]="!isMobile" [class.footerbtn]="isMobile" [class.p-action-r]="isMobile">
            <button class="btn-positive"  [class.w-100]="isMobile" mat-flat-button (click)="RepairLOrders()"
                [disabled]='isButtonDisable'><span translate>{{'basket.repair' | translate}}</span></button>
        </div>
    </div>
</mat-dialog-actions>