import { Component, Output, EventEmitter, Input, Inject, ChangeDetectorRef, Injector, OnInit, ElementRef } from '@angular/core';
import { Utility } from '../../../services/utility';
import { TradingRestService } from '../../../services/tradingrest.service';
import { Keys } from '../../../services/keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentContainer } from 'golden-layout';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { TableUtility } from 'src/app/services/tableutility';
import { Subject } from 'rxjs';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let marketStatus : MarketStatusComponent;

@Component({
    selector: 'app-market-status',
    templateUrl: './marketstatus.component.html',
    styleUrls: ['./marketstatus.component.scss']
})

export class MarketStatusComponent extends BaseComponentDirective implements OnInit {
    @Output() load = new EventEmitter<boolean>();
    @Input() isCustomDashboard: boolean;
    isLoading = true;
    exchangeArray;
    exchange;
    isNoData;
    marketStatusData;
    tagText;
    Height: any;
    container;
    isMobile: boolean;
    modesub: Subscription;
    isReload = false;
    targetElement: Element;
    numberofcol = 1;
    displayedColumns =  ['type', 'status'];

    constructor(elRef: ElementRef, private injector: Injector, private detector: ChangeDetectorRef, private tradingRestService: TradingRestService, private snackBar: MatSnackBar) {
        super(elRef.nativeElement);
        marketStatus = this;
        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });
    }

    changeMode() {
        this.container = this.container === undefined ? Utility.setSize : this.container;
        this.Height = {
            'height': (this.container.height - TableUtility.MSHeight) + 'px',
            'overflow': 'auto'
        };
        this.detector.detectChanges();

        if (this.container.width >= 1200) {
            this.numberofcol = 4;
            } else if (this.container.width >= 768 && this.container.width < 1200) {
            this.numberofcol = 3;
            } else if (this.container.width >= 500 && this.container.width < 768) {
            this.numberofcol = 2;
            } else {
            this.numberofcol = 1;
            }
    }

    ngOnInit() {
        if(this.inputs !== undefined)
            this.isCustomDashboard = this.inputs.isCustomDashboard;
        this.targetElement = document.querySelector('html');
        if (this.isCustomDashboard) {
            // this.container = <ComponentContainer>this.injector.get('Container');
            this.changeMode();
            /* this.container.on('resize', _ => {
                this.changeMode();
            });*/
        }
        this.isMobile = Utility.IS_MOBILE;
        this.exchangeArray = Utility.ChangeOrderOfExchange();
        this.getMrktStatus(this.exchangeArray[0]);
        Utility.webReloadComponent.subscribe(data =>
        {
            if(marketStatus.isReload === false && data === 'Market Status')
            {
                marketStatus.isReload = true;
                this.reloadData();
            }
        });
    }
    onRefresh() {
        this.getMrktStatus(this.tagText);
    }

    reloadData()
    {
        this.onRefresh();
    }

    myRefreshEvent(event: Subject<any>, message: string) {
        setTimeout(() => {
          this.reloadData();
          event.next();
        }, 1000);
      }
      
    alert(message: string) {
        
    }

    getMrktStatus(exchange) {
        this.tagText = exchange;
        this.exchange = exchange;
        this.isLoading = true;
        this.load.emit(this.isLoading);
        this.tradingRestService.getmarketService().getMarketStatus(exchange, this.MarketStatusResponse.bind(this), this.setPreDefinedMarketStatus.bind(this));
    }

    MarketStatusResponse(data, code, message) {
        this.isLoading = false;
        this.isReload = false;
        this.load.emit(this.isLoading);
        if (code === Keys.RES_CODE_SUCCESS && message === 'OK' && data.length > 0) {
            this.marketStatusData = data;
            this.isNoData = false;
        }
        else {
            this.isNoData = true;
        }
    }

    setPreDefinedMarketStatus(data) {
        this.marketStatusData = data;
    }
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        Utility.setSize = this.container;
        this.changeMode();
    }
}
