import { ApplicationRef, Component, EventEmitter, ComponentRef, ElementRef, EmbeddedViewRef, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentContainer, DragSource, GoldenLayout, LogicalZIndex, ResolvedComponentItemConfig } from "golden-layout";
import { bindCallback } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Preferences } from '../services/Preferences';
import { Utility } from '../services/utility';
import { DashbaordComponent } from '../view/protradelite/dashboard/dashboard.component';
import { DepthComponent } from '../view/protradelite/depth/depth.component';
import { OptionComponent } from '../view/protradelite/derivativemarket/optionchain/optionchain.component';
import { IndicesComponent } from '../view/protradelite/equitymarket/indices/indices.component';
import { TopMarketComponent } from '../view/protradelite/equitymarket/topmarket/topmarket.component';
import { EventComponent } from '../view/protradelite/events/events.component';
import { ExchangeMessageComponent } from '../view/protradelite/exchangemessage/exchangemessage.component';
import { HoldingComponent } from '../view/protradelite/home/portfolio/holding/holding.component';
import { WatchlistComponent } from '../view/protradelite/home/watchlist/watchlist.component';
import { IndexComponent } from '../view/protradelite/index/index.component';
import { LimitComponent } from '../view/protradelite/limit/limit.component';
import { MarketStatusComponent } from '../view/protradelite/marketstatus/marketstatus.component';
import { MultiViewComponent } from '../view/protradelite/multiviewcomponent/multiviewcomponent.component';
import { NewsComponent } from '../view/protradelite/news/news.component';
import { TagSpecificNewsWebComponent } from '../view/protradelite/news/tagspecificnewsweb/tagspecificnewsweb.component';
import { OptionCalculatorComponent } from '../view/protradelite/optioncalculator/optioncalculator.component';
import { OrderBookComponent } from '../view/protradelite/orderbook/orderbook.component';
import { PositionBookComponent } from '../view/protradelite/positionbook/positionbook.component';
import { QuotesComponent } from '../view/protradelite/quotes/quotes.component';
import { ScannerDetailComponent } from '../view/protradelite/scanner/scannerdetail/scannerdetail.component';
import { SpreadOrderComponent } from '../view/protradelite/spreadorder/spreadorder.component';
import { TechnicalsComponent } from '../view/protradelite/technicals/technicals.component';
import { BasketHomeComponent } from '../view/protradelite/thematicbasket/baskethome/baskethome.component';
import { TradingViewComponent } from '../view/protradelite/tradingview/tradingview.component';
import { TriggerComponent } from '../view/protradelite/trigger/trigger.component';
import { BaseComponentDirective } from './base-component.directive';
import { GoldenLayoutComponentService } from './golden-layout-component.service';
import { TradebookComponent } from '../view/protradelite/tradebook/tradebook.component';

@Component({
  selector: 'app-golden-layout-host',
  template: '<ng-template #componentViewContainer></ng-template>',
  styles: [`
 :host {
 height: 100%;
 width: 100%;
 padding: 0;
 position: relative;
 }
 `,
]
})
export class GoldenLayoutHostComponent implements OnDestroy {
  setStockData = {}
  inputmap = {
        'Resistance & Support': 'Resistance & Support', 'Volume Shocker': 'Volume Shocker', 'Circuit Breakers': 'Circuit Breakers',
         'High/Low Breakers': 'High/Low Breakers', 'Open High/Low': 'Open High/Low', 'Technical Scanners': 'Technical Scanners',
         'Bullish - Covered Call': 'bullish', 'Neutral - Long Straddle': 'neutral', 'Bearish': 'bearish', 'IV Scanners': 'IV',
         'Equity News': 'Equity-', 'Derivative News': 'Derivative-',
         'Buy Order': 'B', 'Sell Order': 'S', 'Stock Depth': 'DEPTH',
         'Chart': 'CHART', 'Key Ratio': 'KEYRATIO', 'Stock Technicals': 'TECHNICALS', 'Analysis': 'ANALYSIS', 'About Stock': 'ABOUT',
         'Peer': 'PEER', 'Financials': 'FINANCIALS', 'Stock News': 'STOCKNEWS', 'Visual Trading': 'VISUALTRADE',
         'Top Gainer/Losers': 'G', 'Most Active Volume/Value': 'V'
  };
  userId = Preferences.getPreference('userid');
 /* data = {
       'Spread Order Book': SpreadOrderComponent,
      
      'Buy Order': MultiViewComponent, 'Sell Order': MultiViewComponent, 'Depth': MultiViewComponent,
      'Chart': MultiViewComponent, 'Key Ratio': MultiViewComponent, 'Technicals': MultiViewComponent, 'Analysis': MultiViewComponent, 'About Stock': MultiViewComponent,
      'Peer': MultiViewComponent, 'Financials': MultiViewComponent, 'Stock News': MultiViewComponent, 'Visual Trading': MultiViewComponent,
      'Events': EventComponent, 'News': NewsComponent, 'Equity News': TagSpecificNewsWebComponent, 'Derivative News': TagSpecificNewsWebComponent,
      'S_Events': EventComponent, 'S_Chart': TradingViewComponent, 'S_Quote': QuotesComponent, 'S_Depth': DepthComponent, 'S_Key Ratio': KeyRatioComponent, 'S_Technicals': TechnicalsComponent,
      'S_Analysis': IbesComponent, 'S_About Company': AboutComponent, 'S_Peer': PeerComponent, 'S_Financials': FinancialComponent, 'S_News': TagSpecificNewsWebComponent,
      'Tag News': TagSpecificNewsWebComponent, 'feedback': FeedbackComponent
  };*/    
  data={
    "Trade Book": TradebookComponent,
    "Option Chain":OptionComponent,
    "News":NewsComponent, 
    "Stock News": MultiViewComponent,
    "StockDetail News": TagSpecificNewsWebComponent,
    "Depth": DepthComponent,
    "Stock Depth": MultiViewComponent,
    "Technicals": TechnicalsComponent,
    "Stock Technicals": MultiViewComponent,
    "Quotes": QuotesComponent,
    "Events": EventComponent,
    'Resistance & Support': ScannerDetailComponent,
    'Volume Shocker': ScannerDetailComponent,
    'Circuit Breakers': ScannerDetailComponent,
    'High/Low Breakers': ScannerDetailComponent,
    'Open High/Low': ScannerDetailComponent,
    'Technical Scanners': ScannerDetailComponent,
    "Chart": TradingViewComponent,
    "Equity News": TagSpecificNewsWebComponent,
    "Derivative News": TagSpecificNewsWebComponent,
    "Exchange Message": ExchangeMessageComponent,
    'Top Indices': IndexComponent, 'Advances & Declines': IndicesComponent,
    'Top Gainer/Losers': TopMarketComponent,
    'Most Active Volume/Value': TopMarketComponent, 'Market Status': MarketStatusComponent,
    'Option Calculator': OptionCalculatorComponent,
    'Funds': LimitComponent,
    'Investment Boxes': BasketHomeComponent,
    'Tag News': TagSpecificNewsWebComponent,
    'GTT': TriggerComponent,
    'Spread Order Book': SpreadOrderComponent
    };
  private _goldenLayout: GoldenLayout;
  private _goldenLayoutElement: HTMLElement;
  private _virtualActive = true;
  private _viewContainerRefActive = true;
  private _componentRefMap = new Map<ComponentContainer, ComponentRef<BaseComponentDirective>>();
  private _goldenLayoutBoundingClientRect: DOMRect = new DOMRect();
  @Output() setStockInputs = new EventEmitter<any>();
  @Output() addstockInputs = new EventEmitter<any>();
  @Output() manageWLInputs = new EventEmitter<any>();
  @Output() optionCalInputs = new EventEmitter<any>();
  @Output() newsInputs = new EventEmitter<any>();
  @Output() setChartInputs = new EventEmitter<any>();
  @Output() addFundsInputs = new EventEmitter<any>();
  @Output() addOpenManageBooksInputs = new EventEmitter<any>();
  tagSymbol;
  @Output() load = new EventEmitter<boolean>();
  tabmap ={};
  previous;
  myWork;
  inputData: any = {'isCustomDashboard': true};
  private _goldenLayoutBindComponentEventListener =
    (container: ComponentContainer, itemConfig: ResolvedComponentItemConfig) => this.handleBindComponentEvent(container, itemConfig);
  private _goldenLayoutUnbindComponentEventListener =
    (container: ComponentContainer) => this.handleUnbindComponentEvent(container);

  @ViewChild('componentViewContainer', { read: ViewContainerRef, static: true }) private _componentViewContainerRef: ViewContainerRef;

  get goldenLayout() { return this._goldenLayout; }
  get virtualActive() { return this._virtualActive; }
  get viewContainerRefActive() { return this._viewContainerRefActive; }

  constructor(private _appRef: ApplicationRef,
    private _elRef: ElementRef<HTMLElement>,
    private goldenLayoutComponentService: GoldenLayoutComponentService,
  ) {
    this._goldenLayoutElement = this._elRef.nativeElement;
    this._goldenLayout = new GoldenLayout(
      this._goldenLayoutElement,
      this._goldenLayoutBindComponentEventListener,
      this._goldenLayoutUnbindComponentEventListener,
    );
    this._goldenLayout.beforeVirtualRectingEvent = (count) => this.handleBeforeVirtualRectingEvent(count);
    this._goldenLayout.on( 'stateChanged', function(){
      if(this.myWork <= 5)
        Utility.saveLayoutArr[this.myWork] = this._goldenLayout.saveLayout();
      Utility.saveWorkspace();
    }.bind(this));
    this.goldenLayoutComponentService.registerComponentType("Order Book", OrderBookComponent);
    this.goldenLayoutComponentService.registerComponentType("Position Book", PositionBookComponent);
    this.goldenLayoutComponentService.registerComponentType("Holding Book", HoldingComponent);
    this.goldenLayoutComponentService.registerComponentType("WatchList", WatchlistComponent);
    this.goldenLayoutComponentService.registerComponentType("Dashboard", DashbaordComponent);
  }

  ngOnDestroy() {
    this._goldenLayout.destroy();
  }

  setVirtualActive(value: boolean) {
    this._goldenLayout.clear();
    this._virtualActive = value;
    if (!this._virtualActive) {
      this._viewContainerRefActive = false;
    }
  }

  setViewContainerRefActive(value: boolean) {
    this._goldenLayout.clear();
    if (value && !this.virtualActive) {
      throw new Error('ViewContainerRef active only possible if VirtualActive');
    }
    this._viewContainerRefActive = value;
  }

  setSize(width: number, height: number) {
    this._goldenLayout.setSize(width, height)
  }

  getComponentRef(container: ComponentContainer) {
    return this._componentRefMap.get(container);
  }

  private handleBindComponentEvent(container: ComponentContainer, itemConfig: ResolvedComponentItemConfig): ComponentContainer.BindableComponent {
    const componentType = itemConfig.componentType;
    const type = componentType.toString();
    if(this.data[type]!==undefined)
      this.goldenLayoutComponentService.registerComponentType(type , this.data[type]);
    
    const componentRef = this.goldenLayoutComponentService.createComponent(componentType, container);
    const component = componentRef.instance;
    let data;
    if(Utility.recentlySearchedScrips.length > 0)
      data = Utility.recentlySearchedScrips[Utility.recentlySearchedScrips.length - 1];
    else {
      data = {cname: "TATA CONSULTANCY SERV LT", exseg: "nse_cm", token: "11536", tsym: "TCS-EQ"}
    }
    if(type === 'Resistance & Support' || type === 'Volume Shocker' || type === 'Circuit Breakers' || type === 'High/Low Breakers'|| type === 'Open High/Low'|| type === 'Technical Scanners') {
      this.inputData = {'scannerValue': this.inputmap[type], 'workspaceName': 0, 'isCustomDashboard': true};
    } else if(type === 'Equity News' || type === 'Derivative News') {
      this.inputData = {'isCustomDashboard': true, 'IsStockSpecific': false, 'symbol': this.inputmap[type]};
    } else if(type === 'Top Gainer/Losers' || type === 'Most Active Volume/Value') {
      this.inputData = {'isCustomDashboard': true, 'webType': this.inputmap[type]};
    } else if(data &&(type === 'Stock Depth' || type === 'Stock Technicals' || type === 'Stock News')) {
      this.inputData = {'isCustomDashboard': true, 'action': this.inputmap[type], 'type':type, 'selectedwork': this.myWork, 'token': data.token, 'exseg': data.exseg, 'symbol': data.tsym};
    } else if(componentType=="Events" && this.myWork <= 5){
      this.inputData = {'isCustomDashboard': true};
    } else if(componentType=="Tag News") {
      this.inputData =  {'isCustomDashboard': true, 'IsStockSpecific': false, 'symbol': this.tagSymbol}
    }
    else if(Object.keys(this.setStockData).length !== 0 && this.myWork > 5){
      if(typeof(this.setStockData) === 'string')
        this.setStockData = JSON.parse(this.setStockData);
      this.inputData = {IsStockSpecific: false,
        MVtype: "stock",
        backbtnEnable: false,
        c_name: "watchlist",
        exch: this.setStockData['exch'],
        fromMultiView: true,
        fromStockDetail: true,
        isCustomDashboard: true,
        isHeader: true,
        symbol: this.setStockData['symbol'],
        token:this.setStockData['token']}
    }
    else if(Utility.SELECTED_SCRIP !== undefined && !Utility.stockFlag && type === 'Chart'){
        this.inputData = {IsStockSpecific: false,
        MVtype: "stock",
        backbtnEnable: false,
        c_name: "watchlist",
        exch: Utility.SELECTED_SCRIP.ExchangeSegment,
        fromMultiView: true,
        fromStockDetail: false,
        isCustomDashboard: true,
        isHeader: true,
        symbol: Utility.SELECTED_SCRIP.TradingSymbol,
        token: Utility.SELECTED_SCRIP.Token}  
    }
    if(this.myWork !==0 && Utility.inputproplist[this.myWork] && Utility.inputproplist[this.myWork][type] && this.myWork <= 5){
      this.inputData = Utility.inputproplist[this.myWork][type];
    }
    if(this.myWork !==0 && Utility.inputproplist[this.myWork] && !Utility.inputproplist[this.myWork][type] && this.myWork <= 5){
      if(type === 'Chart' && Utility.SELECTED_SCRIP === undefined){
        this.inputData = {IsStockSpecific: false,
          MVtype: "stock",
          backbtnEnable: false,
          c_name: "watchlist",
          exch: "nse_cm",
          fromMultiView: true,
          fromStockDetail: false,
          isCustomDashboard: true,
          isHeader: true,
          symbol: "TCS-EQ",
          token: "11536"}  
      }
      Utility.inputproplist[this.myWork][type]=this.inputData;
      Preferences.setItem('w_iplist',Utility.inputproplist);  
    }
    
    if(componentType != 'Investment Boxes' && componentType != "Option Calculator" && componentType != "Quotes" && componentType != "Chart"){
      component['load'].subscribe(isLoader => {
        this.load.emit(isLoader);
    });
  }

    component['inputs'] = this.inputData;
    component['outputs'].subscribe(data => {
      this.inputData = data;
      this.setStockInputs.emit(data);
    });
    
    if (component['addStockWL']) {
      component['addStockWL'].subscribe(data => {
        data = JSON.parse(data);
        this.addstockInputs.emit(data);
       });
    }
    if (component['mvEmit']) {
      component['mvEmit'].subscribe(data => {
        Utility.inputproplist[this.myWork][data.type]=data;
        Preferences.setItem('w_iplist',Utility.inputproplist);  
      });
    }
    
    if (component['addfunds']) {
     component['addfunds'].subscribe(data => {
        this.addFundsInputs.emit(data);
     });
    }
    
    if (component['manegeWL']) {
      component['manegeWL'].subscribe(data => {
        this.manageWLInputs.emit(data);
       });
    }

    if (component['openOCSearch']) {
      component['openOCSearch'].subscribe(data => {
        this.optionCalInputs.emit(data)
      });
    }

    if (component['openNewsSearch']) {
      component['openNewsSearch'].subscribe(data => {
        this.newsInputs.emit(data)
      });
    }

    if (component['openChartPage']) {
      component['openChartPage'].subscribe(data => {
        data = JSON.parse(data);
        this.setChartInputs.emit(data)
      });
    }  
    if (component['openManageBooks']) {
      component['openManageBooks'].subscribe(data => {
         this.addOpenManageBooksInputs.emit(data);
      });
     }  

    if(this.myWork !== 0 && Utility.workspaceMap[this.myWork] && !Utility.workspaceMap[this.myWork].includes(type) && this.myWork <= 5){
      if(type === "News" && !Utility.workspaceMap[this.myWork].includes('General News')) {
        Utility.workspaceMap[this.myWork].push('General News');
      }
      else if(type !== "News")
        Utility.workspaceMap[this.myWork].push(type);
     // Utility.fromNewWorkspace = false;
    }
   
    this._componentRefMap.set(container, componentRef);
    if(environment.baseviewpath != 'investor'){
      if(componentType=="News" || componentType=="Derivative News" || componentType=="Equity News" || componentType=="Events" || componentType=="Stock News" ||componentType=="Option Chain" ||  componentType=="Tag News" || componentType=="StockDetail News"){
        this.tabmap[componentType] = '<div class = "freetrial webview tabfreetrial"><b>Trial</b></div>';
        // var doc = new DOMParser().parseFromString(counter, 'text/html');
        // container.on( 'tab', function( tab ){
        //   tab.element.append(doc.body.firstChild);
        // });
      }

      let tabclass = (this.myWork==0) ? 'freetrial webview' : 'freetrial webview tabfreetrial';
      this.tabmap['Order Book'] = '<div class="' + tabclass + '"><b>F3</b></div>';
      this.tabmap['Holding Book'] = '<div class="' + tabclass + '"><b>alt + F9</b></div>';
      this.tabmap['Position Book'] = '<div class="' + tabclass + '"><b>alt + F6</b></div>';
      this.tabmap['WatchList'] = '<div class="' + tabclass + '"><b>F4</b></div>';
      this.tabmap['Funds'] = '<div class="' + tabclass + '"><b>Ctrl + Shift + U</b></div>';
      if(this.tabmap[type]){
        let doc = new DOMParser().parseFromString(this.tabmap[type], 'text/html');
        container.on( 'tab', function( tab ){
          tab.element.append( doc.body.firstChild );
        });
      } 
    }      
    if (this._virtualActive) {
      container.virtualRectingRequiredEvent = (container, width, height) => this.handleContainerVirtualRectingRequiredEvent(container, width, height);
      container.virtualVisibilityChangeRequiredEvent = (container, visible) => this.handleContainerVisibilityChangeRequiredEvent(container, visible);
      container.virtualZIndexChangeRequiredEvent = (container, logicalZIndex, defaultZIndex) => this.handleContainerVirtualZIndexChangeRequiredEvent(container, logicalZIndex, defaultZIndex);

      if (this._viewContainerRefActive) {
        this._componentViewContainerRef.insert(componentRef.hostView);
      } else {
        this._appRef.attachView(componentRef.hostView);
        const componentRootElement = component.rootHtmlElement;
        this._goldenLayoutElement.appendChild(componentRootElement);
      }
    } else {
      this._appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
      container.element.appendChild(domElem);
    } 
   return {
      component,
      virtual: this._virtualActive,
    }
  }

  private handleUnbindComponentEvent(container: ComponentContainer) {
    let item = Utility.workspaceMap[this.myWork];
    // if(this.myWork !== 0 && Utility.fromNewWorkspace === false) {
    //   item.splice(item.indexOf(container.componentType), 1);
    // }

    const componentRef = this._componentRefMap.get(container);
    if (componentRef === undefined) {
      throw new Error('Could not unbind component. Container not found');
    }
    this._componentRefMap.delete(container);

    const hostView = componentRef.hostView;

    if (container.virtual) {
      if (this._viewContainerRefActive) {
        const viewRefIndex = this._componentViewContainerRef.indexOf(hostView);
        if (viewRefIndex < 0) {
          throw new Error('Could not unbind component. ViewRef not found');
        }
        this._componentViewContainerRef.remove(viewRefIndex);
      } else {
        const component = componentRef.instance;
        const componentRootElement = component.rootHtmlElement;
        this._goldenLayoutElement.removeChild(componentRootElement);
        this._appRef.detachView(hostView);
      }
    } else {
      const component = componentRef.instance;
      const componentRootElement = component.rootHtmlElement;
      container.element.removeChild(componentRootElement);
      this._appRef.detachView(hostView);
    }
    componentRef.destroy();
    // this._goldenLayout.on('stateChanged', function(){
    //   if(this.myWork <= 5)
    //     this.saveLayoutArr[this.myWork] = this._goldenLayout.saveLayout();
    // }.bind(this));
  
  }

  private handleBeforeVirtualRectingEvent(count: number) {
    this._goldenLayoutBoundingClientRect = this._goldenLayoutElement.getBoundingClientRect();
  }

  private handleContainerVirtualRectingRequiredEvent(container: ComponentContainer, width: number, height: number) {
    const containerBoundingClientRect = container.element.getBoundingClientRect();
    const left = containerBoundingClientRect.left - this._goldenLayoutBoundingClientRect.left;
    const top = containerBoundingClientRect.top - this._goldenLayoutBoundingClientRect.top;

    const componentRef = this._componentRefMap.get(container);
    if (componentRef === undefined) {
        throw new Error('handleContainerVirtualRectingRequiredEvent: ComponentRef not found');
    }
    const component = componentRef.instance;
    component.setPositionAndSize(left, top, width, height);
  }

  private handleContainerVisibilityChangeRequiredEvent(container: ComponentContainer, visible: boolean) {
    const componentRef = this._componentRefMap.get(container);
    if (componentRef === undefined) {
        throw new Error('handleContainerVisibilityChangeRequiredEvent: ComponentRef not found');
    }
    const component = componentRef.instance;
    component.setVisibility(visible);
    component.setBaseVisibility(visible);
  }

  private handleContainerVirtualZIndexChangeRequiredEvent(container: ComponentContainer, logicalZIndex: LogicalZIndex, defaultZIndex: string) {
    const type = container.componentType.toString();
    const componentRef = this._componentRefMap.get(container);
    if (componentRef === undefined) {
        throw new Error('handleContainerVirtualZIndexChangeRequiredEvent: ComponentRef not found');
    }
    const component = componentRef.instance;
    if(logicalZIndex === 'stackMaximised'){
      let zindex = parseInt(defaultZIndex)+61;
      defaultZIndex = zindex.toString();
    }
    component.setZIndex(defaultZIndex);
    if(environment.baseviewpath != 'investor' && this.tabmap[type]){
      let doc = new DOMParser().parseFromString(this.tabmap[type], 'text/html');
      container.on( 'tab', function( tab ){
        if(tab.element.lastChild !== this.previous){
          tab.element.removeChild(tab.element.lastChild);
          this.previous = tab.element.lastChild;
        }
        else
         tab.element.append( doc.body.firstChild );
      }.bind(this));
    }      

  }
}