import { Component, Output, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ChannelType, Utility } from 'src/app/services/utility';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { Location } from '@angular/common';
import { PositionBookComponent } from '../positionbook/positionbook.component';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { Keys } from '../../../services/keys';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-partialpositionconvertion',
    templateUrl: './partialpositionconvertion.component.html',
    styleUrls: ['./partialpositionconvertion.component.scss']
})

export class PartialPositionConvertionComponent implements OnInit, OnDestroy {
    @Output() load = new EventEmitter<boolean>();
    isLoading = true;
    positionDetailData: any;
    selectedProduct = '';
    productList = [];
    selectedPositionType;
    qtyLabel;
    quantity;
    isDay;
    selectedTransactionType: any;
    isError: boolean;
    errMessage: string;
    isMobile = true;
    dialogRef;
    isLot = false;
    isLotLable = false;
    lotError = true;
    isPositionType = false;
    positionBreakUpDetailData;
    selectedRadioButtonValue;
    isInteropsEnabled = environment.isInteropsEnabled;
    constructor(private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private router: Router, private dialog: MatDialog, private translate: TranslateService, private location: Location) {
        this.translate.get(['all.sell_qty']).subscribe(
            values => {
                this.qtyLabel = this.translate.instant('all.sell_qty');
            });
        this.isMobile = Utility.IS_MOBILE;
        this.positionDetailData = Utility.positionDetail;
        if(this.isInteropsEnabled) {
            this.positionBreakUpDetailData = Utility.positionBreakUpDetail;
            this.selectedRadioButtonValue = this.positionBreakUpDetailData.list[0]['Exchange'];
        }
        this.setData();
    }
    setData(){
        if ((Utility.getNumber(this.positionDetailData.FillBuyAmt) !== 0 || Utility.getNumber(this.positionDetailData.FillSellAmt) !== 0) && (Utility.getNumber(this.positionDetailData.CFBuyAmt) !== 0 || Utility.getNumber(this.positionDetailData.CFSellAmt) !== 0))
        {
            this.isPositionType = true;
            this.fillDay();
        }
        if (Utility.getNumber(this.positionDetailData.FillBuyAmt) !== 0 || Utility.getNumber(this.positionDetailData.FillSellAmt) !== 0) {
            this.fillDay();
        }
        else {
            this.fillCf();
        }
        // this.defaultData();
        this.qtyLabel = this.translate.instant('all.buy_qty');
        // this.quantity = (Utility.getNumber(this.positionDetailData.Netqty) < 0? this.positionDetailData.Netqty * -1 : Utility.getNumber(this.positionDetailData.Netqty)).toString();
        this.isLot = Utility.IsLot(this.positionDetailData.Exchange);
        if (Utility.getNumber(this.positionDetailData.BLQty) > 1)
            this.isLotLable = true;
    }
    fillDay()
    {
        this.isDay = true;
        this.selectedPositionType = 'DAY';
        if (this.positionDetailData.FillBuyQty > this.positionDetailData.FillSellQty)
        {
            this.selectedTransactionType = 'B';
            this.quantity = (Utility.getNumber(this.positionDetailData.FillBuyQty) - Utility.getNumber(this.positionDetailData.FillSellQty)).toString();
        }
        else
        {
            this.selectedTransactionType = 'S';
            this.quantity = (Utility.getNumber(this.positionDetailData.FillSellQty) - Utility.getNumber(this.positionDetailData.FillBuyQty)).toString();
        }
    }

    fillCf()
    {
        this.isDay = false;
        this.selectedPositionType = 'CF';
        if (this.positionDetailData.CFBuyQty > this.positionDetailData.CFSellQty)
        {
            this.selectedTransactionType = 'B';
            this.quantity = (Utility.getNumber(this.positionDetailData.CFBuyQty) - Utility.getNumber(this.positionDetailData.CFSellQty)).toString();
        }
        else
        {
            this.selectedTransactionType = 'S';
            this.quantity = (Utility.getNumber(this.positionDetailData.CFSellQty) - Utility.getNumber(this.positionDetailData.CFBuyQty)).toString();
        }
    }

    setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.onAppearing();
          }, 50);
        }
        else {
          this.onDisappearing();
        }
    }

    ngOnInit() {
        this.prepareDropDownView(this.positionDetailData.Pcode, this.positionDetailData.Exchange, this.positionDetailData.Symbol, this.positionDetailData.Exchangeseg);
        this.onAppearing();
    }

    ngOnDestroy() {
        this.onDisappearing();
    }

    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Shared);
    }
    
    onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
    }

    prepareDropDownView(pCode, exchange, token, excSegment) {
        const sm = Utility.getScripModel(token, excSegment, this.tradingRestService, true);
        sm.ExchangeName = exchange;
        const productsArray = Utility.GetAllProductForExchange(sm.ExchangeName);
        productsArray.forEach(product => {
            if (product !== 'CO' && product !== 'BO' && product !== this.positionDetailData.Pcode) {
                this.productList.push(product);
            }
        });
        this.selectedProduct = this.productList[0];
    }

    MakePositiveNumber(number: any)
    {
        return Math.abs(number);
    }

    // onSendSelection($event: MatSelectChange) {
    //     this.selectedProduct = $event.value;
    // }

    // onTabChange(event) {
    //     this.isError = false;
    //     const tabValue = event.tab['textLabel'];
    //     if (tabValue === this.translate.instant('stock_detail.sell')) {
    //         this.qtyLabel = this.translate.instant('books.sell_qty');
    //         this.selectedTransactionType = 'S';
    //         if (this.selectedPositionType === 'D') {
    //             this.quantity = (this.positionDetailData.Sqty).toString();
    //         }
    //         else {
    //             this.quantity = (this.positionDetailData.CFSellQty).toString();
    //         }
    //     }
    //     else {
    //         this.defaultData();
    //     }
    // }

    getQuantityFromUser(value) {
        value = value.replace(/[^0-9]*/g, '');
        this.quantity = value;
        if (this.quantity <= 0 || this.quantity === '') {
            this.isError = true;
            this.errMessage = this.translate.instant('all.valid_quant');
        }
        else {
            this.isError = false;
        }
    }

    onCancel() {
        if (this.isMobile) {
            this.location.back();
        } else {
            Utility.dialogRef.close();
        }
    }

    onSubmit() {
        let boardLotQty = parseInt(this.positionDetailData.BLQty, 10);
        if (boardLotQty === 0) {
            boardLotQty = 1;
        }
        if (this.quantity <= 0 || this.quantity === '') {
            this.isError = true;
            this.errMessage = this.translate.instant('all.valid_quant');
        }
        else if (this.selectedTransactionType === 'B' && parseInt(this.quantity, 10) > parseInt(this.positionDetailData.FillBuyQty, 10) && this.selectedPositionType === 'DAY') {
            this.isError = true;
            this.errMessage = this.translate.instant('all.avail_bquant')+`${this.positionDetailData.NetBuyQty}`;
        }
        else if (this.selectedTransactionType === 'S' && parseInt(this.quantity, 10) > parseInt(this.positionDetailData.FillSellQty, 10) && this.selectedPositionType === 'DAY') {
            this.isError = true;
            this.errMessage = this.translate.instant('all.avail_squant')+`${this.positionDetailData.NetSellQty}`;
        }
        else if (this.selectedTransactionType === 'B' && parseInt(this.quantity, 10) > parseInt(this.positionDetailData.CFBuyQty, 10) && this.selectedPositionType === 'CF') {
            this.isError = true;
            this.errMessage = this.translate.instant('all.availcf_bquant')+` ${this.positionDetailData.CFBuyQty}`;
        }
        else if (this.selectedTransactionType === 'S' && parseInt(this.quantity, 10) > parseInt(this.positionDetailData.CFSellQty, 10) && this.selectedPositionType === 'CF') {
            this.isError = true;
            this.errMessage = this.translate.instant('all.availcf_squant')+` ${this.positionDetailData.CFSellQty}`;
        }
        else if (parseInt(this.quantity, 10) % boardLotQty !== 0) {
            this.isError = true;
            this.errMessage = this.translate.instant('all.quant')+` ${boardLotQty}`;
        }
        else {
            this.isError = false;
            this.openDialog();
        }
    }

    openDialog() {
        this.dialogRef = this.dialog.open(MyAlertDialogComponent,
            { data: {heading: this.translate.instant('all.position_cvrt_hed'), message: this.translate.instant('all.position_cvrt') + Keys.key_map[this.positionDetailData.Pcode] + this.translate.instant('all.to')  + Keys.key_map[this.selectedProduct] + this.translate.instant('all.position_cnvrt') , 'fromOrder': false } });
           this.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
                this.getPartialPositionConvertionData();
            }
        });
    }

    getPartialPositionConvertionData() {
        this.isLoading = true;
        this.tradingRestService.getBookService().PartialPositionConvertion(this.selectedProduct, this.selectedPositionType,
            this.selectedTransactionType, this.quantity, this.positionDetailData, this.PartialPositionConvertionResponse.bind(this));
    }

    PartialPositionConvertionResponse(result, code, message) {
        this.isLoading = false;
        this.load.emit(this.isLoading);
        this.onCancel();
        if (code === 200 && message === 'OK') {
            this.snackBar.open(`${result}`, '', { duration: 3000 });
            Utility.positionBook.next();
        }
        else {
            this.snackBar.open(`${result}`, '', { duration: 3000 });
        }
    }

    calculateLots()
    {
        this.lotError = false;
        if (this.quantity && this.positionDetailData.BLQty && this.floatSafeModulus(Utility.getNumber(this.quantity), Utility.getNumber(this.positionDetailData.BLQty)) === 0)
        return Math.floor(Utility.getNumber(this.quantity)/Utility.getNumber(this.positionDetailData.BLQty));
        this.lotError = true;
        return 'NA';
    }

    floatSafeModulus(val, step) {
        if (val && step)
        {
          const valDecCount = (val.toString().split('.')[1] || '').length;
          const stepDecCount = (step.toString().split('.')[1] || '').length;
          const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
          let valInt;
          if (val !== undefined && val !== null && val !== NaN)
            valInt = parseInt(Utility.getNumber(val).toFixed(decCount).replace(/\./g, ''), 10);
          const stepInt = parseInt(Utility.getNumber(step).toFixed(decCount).replace(/\./g, ''), 10);
          return (valInt % stepInt) / Math.pow(10, decCount);
        }
        else
          return 0;
      }

    onPositionTypeChange(ev)
    {
        if (ev.value === 'DAY')
            this.fillDay();
        else
            this.fillCf();
    }
    handleChange(data){
        this.selectedRadioButtonValue = data;
        for(let i =0; i< this.positionBreakUpDetailData.list.length; i++){
          if(this.positionBreakUpDetailData.list[i].Exchange === this.selectedRadioButtonValue)
            Utility.positionDetail = this.positionBreakUpDetailData.list[i];
            this.positionDetailData = Utility.positionDetail;
        }
        this.setData();  
    }

}
