<div *ngIf="scripResult?.length > 0" [class.mt-3] = "!isMobile" [class.pt-194] = "isMobile" #p2>
    <span class="subcolor2" [class.pl-2] = "!isMobile">
        {{'search.recently_view' | translate}}        
    </span>
    <div *ngFor="let option of scripResult; let i = index" tabindex="999" (keydown.arrowdown)="arrowDownEvent(option, selectedRowIndex,$event,p2)" (keydown.arrowup)="arrowUpEvent(option, selectedRowIndex,$event,p2)" [attr.id]="'recent_search_list' + i">
        <div class="wlweb" [class.container] = "!isMobile" [ngClass]="{'highlight': selectedRowIndex == i}">
            <div (click)="result(option)">
                <div class="watchlistwrapper">
                    <div class="row">
                        <div class="col-6">
                            <div [ngClass]="{'firstline' : true}"><span class="texttrim">{{option.Symbolname | symbolpipe}}&nbsp;<span class="ts-2" *ngIf = "option.ExchangeName === 'BSE'">({{option.InstrumentName}})</span></span><small>{{option.ExchangeName}}</small></div>
                            <div [ngClass]="{'line2' : true, 'texttrim' : true, 'subcolor' : true}">{{option.DisplaySymbolDescription}}</div>
                        </div>
                        <div *ngIf="!isDelete" class="col-6 text-right" [class.imnegative]="option.Change<0" [class.impositive]="option.Change>0">
                            <div [class.imnegativebg]="option.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="option.TickDirection === 1 && flashType === 'defaultFlash'" class="valuetext">
                                <div class="firstline" [class.imnegativebg]="option.TickDirection === -1 && flashType === 'ltpFlash'" [class.impositivebg]="option.TickDirection === 1 && flashType === 'ltpFlash'">{{option.LastTradePrice | pricepipe:option.DecimalPrecision}}</div>
                                <div class="line2">{{option.Change | pricepipe:option.DecimalPrecision}} ({{option.PercentageChange | percentagepipe}})</div>
                            </div>
                            <div class="addbtn" *ngIf = "fromWatchlist && selWLName?.length > 0">
                                <button mat-icon-button class="borderbutton" (click) = "$event.stopPropagation(); addStockToSelWL(option)">
                                <i class="icon-minus" *ngIf = 'wltokens.includes(option.Token+"_"+option.ExchangeSegment)' ></i>
                                <i class="icon-plus" *ngIf = '!wltokens.includes(option.Token+"_"+option.ExchangeSegment)'></i>
                                </button>
                            </div>
                            <div *ngIf="isMobile && !fromWatchlist" class="text-right addbtn">
                                <button mat-icon-button (click)="addWLdata(option,$event)"  [matMenuTriggerFor]="menu"><i class="icon-plus"></i></button>
                                <mat-menu #menu="matMenu">
                                    <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                    <ng-container *ngFor = "let data of WlArr">
                                        <button mat-menu-item (click) = "addStockPopup(data.wlName, option)" [disabled] = "data.isDisable">
                                            <span>{{data.wlName}}</span>
                                        </button>
                                    </ng-container>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wl-btn" *ngxCacheIf="!isMobile" [class.d-none]="!M.IS_MOUSE">
                <button mat-icon-button (click)="openPlaceOrder('B', option)" matTooltip="{{'books.buy' | translate}}" class="btn-positive" ><i class="icon-bb"></i></button>
                <button mat-icon-button (click)="openPlaceOrder('S', option)" matTooltip="{{'books.sell' | translate}}" class="btn-negative ml-2" ><i class="icon-sb"></i></button>
                <button *ngIf="!fromWatchlist" mat-icon-button (click) = "addWLdata(option)" matTooltip="{{'search.addto_watchlist' | translate}}"  class="btn-primary ml-2" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                <mat-menu #menu="matMenu">
                    <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                    <ng-container *ngFor = "let data of WlArr">
                        <button mat-menu-item (click) = "addStockPopup(data.wlName, option)"  [disabled] = "data.isDisable">
                            <span>{{data.wlName}}</span>
                        </button>
                    </ng-container>
                </mat-menu>
            </div>
        </div>
    </div>
</div>