import { Injectable, ɵConsole } from '@angular/core';
import { IWatchlistService } from './iwatchlist.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { Utility } from '../utility';
import { WatchListModel } from 'src/app/model/watchlist.model';
import { ScripModel } from 'src/app/model/scrip.model';
import { TradingRestService } from '../tradingrest.service';
import { Preferences, ReturnType } from '../Preferences';

let wl: WatchListService;

@Injectable()
export class WatchListService implements IWatchlistService {
    apiService: ApiService;
    tradingRestService: TradingRestService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
        wl = this;
    }

    getAllWatchlist(callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        // const watchList = new Array<WatchListModel>();
        let watchList = {};
        const jsonSendObj = { 'uid': Preferences.getPreference('userid') };
        const key = Url.WATCH_LIST + '_' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.WATCH_LIST, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok') {
                watchList = res;
                message = 'OK';
                Utility.CACHE_STORE.setCacheData(key, watchList);
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(watchList, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(watchList, code, message);
            });
    }

    getScriptDetailforWL(mrvData: string, MWname: string, callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        // const watchList = new Array<WatchListModel>();
        const watchList = [];
        const token_exseg = [];
        const jsonSendObj = { 'uid': Preferences.getPreference('userid'), 'mrvData': mrvData, 'MWname': MWname };
        const key = Url.SCRIPT_INFO_WATCH_LIST + '_' + mrvData + MWname + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.SCRIPT_INFO_WATCH_LIST, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok') {
                for (let i = 0; i < res['values'].length; i++) {
                    const sm = Utility.getScripModel(res['values'][i]['token'], res['values'][i]['ExchSeg'], wl.tradingRestService, true);
                    sm.DecimalPrecision = res['values'][i]['decimalPrecision'];
                    sm.BodLotQuantity = res['values'][i]['bodlot'];
                    sm.OptionType = res['values'][i]['optiontype'];
                    sm.StrikePrice = res['values'][i]['strikeprice'];
                    sm.TradingSymbol = res['values'][i]['TradSym'];
                    sm.MaxQty = res['values'][i]['maxQty'];
                    sm.ExpiryDate = res['values'][i]['expdate'];
                    sm.ExpiryDateDisplayValue = res['values'][i]['Expiry'];
                    sm.BestSellSize = res['values'][i]['BestSellSize'];
                    sm.MarketProtection = res['values'][i]['defmktproval'];
                    sm.ExchangeName = res['values'][i]['Exchange'];
                    sm.BestBuySize = res['values'][i]['BestBuySize'];
                    sm.TickSize = res['values'][i]['tcksize'];
                    sm.TradeVolume = res['values'][i]['TradeVolume'];
                    sm.DiscQty = res['values'][i]['discQty'];
                    sm.Change = res['values'][i]['Change'];
                    sm.MinQty = res['values'][i]['minQty'];
                    sm.PercentageChange = res['values'][i]['PerChange'];
                    sm.Multiplier = res['values'][i]['multiplier'];
                    sm.NoMarketProtectionFlag = res['values'][i]['noMktPro'];
                    sm.DefaultMarketProtectionValue = res['values'][i]['defmktproval'];
                    sm.UniqueKey = res['values'][i]['UniqueKey'];
                    sm.High = res['values'][i]['high'];
                    sm.Low = res['values'][i]['low'];
                    sm.OpenPrice = res['values'][i]['open'];
                    sm.PrvClose = res['values'][i]['close'];
                    sm.InstrumentName = res['values'][i]['Instrument'];
                    sm.BestSellPrice = res['values'][i]['BestSellPrice'];
                    sm.Lasttradedquantity = res['values'][i]['lasttradedquantity'];
                    sm.LastTradePrice = res['values'][i]['ltp'];
                    sm.CompanyName = res['values'][i]['companyname'];
                    sm.SymbolDescription = res['values'][i]['companyname'];
                    sm.CombinedSymbol = res['values'][i]['CombinedSymbol'];
                    sm.Openinterest = res['values'][i]['openinterest'];
                    sm.TotalBuyQty = res['values'][i]['totalbuyqty'];
                    sm.TotalSellQty = res['values'][i]['totalsellqty'];
                    sm.LastTradedTime = res['values'][i]['lasttradedtime'];
                    sm.Series = res['values'][i]['Series'];
                    sm.Symbolname = res['values'][i]['symbolname'];
                    sm.Corporateaction = res['values'][i]['corporateaction'];
                    //  DisplaySymbol, SellQty, TotalSell, YearlyLowPrice, VwapAveragePrice
                    // YearlyHighPrice, ExchangeFeedTime, LastTradeQuantity, TotalBuy, BuyQuantity, CoverPercentage
                    // TriggerPriceBuy, TriggerPriceSell, SymbolDescriptionTopN, Name, IndexValue, PerCng, underlyingScripToken
                    token_exseg.push(res['values'][i]['token'] + '_' + res['values'][i]['ExchSeg']);
                    watchList.push(sm);
                    Utility.CACHE_STORE.setCacheData(key, watchList);
                }
                Preferences.setItem('stocks', token_exseg);
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(watchList, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(watchList, code, message);
            });
    }

    addScripToWL(mWString: any, MWname: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let watchList = {};
        const jsonSendObj = { 'uid': Preferences.getPreference('userid'), 'mWString': mWString, 'MWname': MWname };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.ADD_SCRIPT_WATCH_LIST, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok') {
                watchList = res;
                message = 'OK';
                let watchlistnamearr = [];
                if (Preferences.getItem('watchlistname', ReturnType.Undefined) !== undefined && Preferences.getItem('watchlistname') !== null && Preferences.getItem('watchlistname') !== 'undefined') {
                    watchlistnamearr = Preferences.getItem('watchlistname', ReturnType.Array);
                }
                if (watchlistnamearr.indexOf(MWname) === -1) {
                    watchlistnamearr.push(MWname);
                    Preferences.removeItem('watchlistname');
                    Preferences.setItem('watchlistname', watchlistnamearr + '');
                }
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(watchList, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(watchList, code, message);
            });
    }

    deleteScripToWL(mWString: any, MWname: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        // const watchList = new Array<WatchListModel>();
        let watchList = {};
        const jsonSendObj = { 'uid': Preferences.getPreference('userid'), 'mWString': mWString, 'MWname': MWname };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.DELETE_SCRIPT_WATCH_LIST, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok') {
                watchList = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(watchList, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(watchList, code, message);
            });
    }

    deleteWL(MWName: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        // const watchList = new Array<WatchListModel>();
        let watchList = {};
        const jsonSendObj = { 'uid': Preferences.getPreference('userid'), 'MWName': MWName };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.DELETE_WATCH_LIST, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok') {
                watchList = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(watchList, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(watchList, code, message);
            });
    }

    setDefaultWL(MWName: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        // const watchList = new Array<WatchListModel>();
        let watchList = {};
        const jsonSendObj = { 'uid': Preferences.getPreference('userid'), 'MWName': MWName };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.Set_DEFAULT_WATCH_LIST, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok') {
                watchList = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(watchList, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(watchList, code, message);
            });
    }

    setTradingRestService(tradingRest: TradingRestService) {
        wl.tradingRestService = tradingRest;
    }

    getPredefinedWatchlistData(callback: Function) {
        
    }
}
