<div class="mat-dialog-title">
    <div class="row">
        <div class="col-8"><h1 translate>portfolio.update</h1></div>
        <div class="col-4 text-right"><button mat-icon-button (click) = "close()"><i class="icon-close"></i></button></div>
    </div>
</div>

<div class="mat-dialog-content">
    <div class="row">
        <div class = "col-12">
            <mat-form-field class="w-100">
                <input type="text" class="uitest-ub-fp-emailId" matInput [(ngModel)]="email" [placeholder]="'alert.email' | translate" required>
            </mat-form-field>
        </div>
        <div class = "col-12">
            <mat-form-field class="w-100">
                <input type="text" class="uitest-ub-fp-emailId" matInput [(ngModel)]="mob" [placeholder]="'login.mobile' | translate" required>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="mat-dialog-actions">
    <div class="row">
        <div class="col-12 text-right pr-3">
            <button mat-flat-button class="uitest-ub-fp-closebtn btn-plain" (click) = "close()"><span translate>stock_detail.close</span></button> 
            <button  mat-flat-button class="uitest-frgtuid-submitbtn btn-primary"  (click) = "submit()"><span translate>settings.submit</span></button>
        </div>
    </div>
</div>