import { Component, ViewEncapsulation } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { MatRadioChange } from '@angular/material/radio';
import { Utility } from 'src/app/services/utility';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MyAlertDialogComponent } from '../../home/watchlist/watchlist.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchComponent } from '../../home/search/search.component';
import { AlertComponent } from '../alert.component';
import { Location } from '@angular/common';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';

let addalertComponent: AddAlertComponent;
@Component({
  selector: 'app-addalert',
  templateUrl: './addalert.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./addalert.component.scss']
})
export class AddAlertComponent {
  isLoading = false;
  basedarr = [];
  Exchange = '';
  Source = '';
  Value = '';
  Direction = 'l=';
  SendOn = '';
  SymbolName = 'Search';
  Symbol = '';
  sendonArr = [];
  basedMap = {};
  sendonMap = {};
  operationMap = {};
  isEdit = false;
  ATMNumber = '';
  type = 'Stock';
  indexarr = [];
  Index = '';
  exarr = [];
  alertarr = [];
  orderarr = [];
  Token = '';
  Comment = '';
  Target = '';
  basedOnDisable = false;
  decimalPrecision;
  Order = '';
  isMobile = true;
  constructor(private tradingRestService: TradingRestService, private router: Router, private activatedRoute: ActivatedRoute, private dialog: MatDialog, private snackBar: MatSnackBar,
    private location: Location,
    private translate: TranslateService) {
    addalertComponent = this;
    addalertComponent.translate.get(['alert.cancelled', 'alert.modified', 'alert.completed']).subscribe(
      values => {
        addalertComponent.alertarr = Object.keys(values).map(key => values[key]);
        addalertComponent.basedarr = [
          { value: 'SLTP', viewValue: addalertComponent.translate.instant('alert.SLTP') },
          { value: 'TTVOLFD', viewValue: addalertComponent.translate.instant('alert.TTVOLFD') },
          { value: 'TTVALFD', viewValue: addalertComponent.translate.instant('alert.TTVALFD') },
          { value: 'SVWAP', viewValue: addalertComponent.translate.instant('alert.SVWAP') }
        ];
        addalertComponent.sendonArr = [
          { value: 'SMS', viewValue: 'SMS' },
          { value: 'EMAIL', viewValue: addalertComponent.translate.instant('alert.email') },
          { value: 'APP_NOTIFICATION', viewValue: addalertComponent.translate.instant('alert.app') }
        ];
      });
    addalertComponent.isMobile = Utility.IS_MOBILE;
    addalertComponent.exarr = JSON.parse(Preferences.getPreference('userParameter'))['exarr'];
    addalertComponent.tradingRestService.getAlertService().GetAlertIndex(addalertComponent.GetAlertIndexRes);
    addalertComponent.decimalPrecision = 2;
    if (addalertComponent.isMobile) {
      addalertComponent.activatedRoute.queryParams.subscribe(params => {
        if (params['isEdit']) {
          addalertComponent.isEdit = params['isEdit'];
        }
      });
    } else {
      if (Utility.dialogData !== undefined) {
        addalertComponent.isEdit = Utility.dialogData.isEdit;
      } else {
        addalertComponent.isEdit = false;
      }
    }
    addalertComponent.setData();
    addalertComponent.basedMap['Security Last trade Price'] = 'SLTP';
    addalertComponent.basedMap['Total Traded Volume for the day'] = 'TTVOLFD';
    addalertComponent.basedMap['Total traded value for the day'] = 'TTVALFD';
    addalertComponent.basedMap['Security Volume weighted Avg price'] = 'SVWAP';
    addalertComponent.basedMap['Index Value'] = 'indxalrt';
    addalertComponent.sendonMap['SMS'] = 'SMS';
    addalertComponent.sendonMap['EMAIL'] = 'EMAIL';
    addalertComponent.sendonMap['APP NOTIFICATION'] = 'APP_NOTIFICATION';
    addalertComponent.operationMap['>='] = 'g=';
    addalertComponent.operationMap['<='] = 'l=';

    const data = Utility.getNavigationData();
    if (addalertComponent.isEdit) {
      addalertComponent.ATMNumber = data.ATMNumbr;
      addalertComponent.basedOnDisable = true;
      addalertComponent.Value = data.Target;
      addalertComponent.SendOn = addalertComponent.sendonMap[data.Senton];
      addalertComponent.Symbol = data.Symbol;
      addalertComponent.Direction = addalertComponent.operationMap[data.Operation];
      if (data.Field === 'Index Value') {
        addalertComponent.Source = addalertComponent.basedMap[data.Field];
        addalertComponent.type = 'Index';
      } else if (data.Field === '--') {
        addalertComponent.type = 'Order';
        addalertComponent.Exchange = data.Exchange;
        addalertComponent.Target = data.Target;
        addalertComponent.SymbolName = data.Tsym;
        addalertComponent.Comment = data.Nordno;
      } else {
        addalertComponent.Exchange = data.Exchange;
        addalertComponent.Source = addalertComponent.basedMap[data.Field];
        addalertComponent.SymbolName = data.Tsym;
      }
    }
  }
  setData() {
    if (Preferences.getItem('tsym') != null && Preferences.getItem('exg') != null) {
      addalertComponent.Exchange = Preferences.getItem('exg', ReturnType.String);
      addalertComponent.SymbolName = Preferences.getItem('tsym', ReturnType.String);
      addalertComponent.Symbol = Preferences.getItem('token', ReturnType.String);
      addalertComponent.decimalPrecision = Preferences.getItem('decimalPrecision', ReturnType.Number);
    }
  }
  setAlert() {
    if (addalertComponent.type === 'Order') {
      if (addalertComponent.Exchange === '')
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.select_exch'), '', { duration: 3000 });
      else if (addalertComponent.Order === '' && !addalertComponent.isEdit)
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.select_order'), '', { duration: 3000 });
      else if (addalertComponent.orderarr.length === 0 && !addalertComponent.isEdit)
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.no_open_order'), '', { duration: 3000 });
      else if (addalertComponent.Target === '')
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.select_if'), '', { duration: 3000 });
      else if (addalertComponent.SendOn === '')
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.select_number'), '', { duration: 3000 });
      else {
        if (!addalertComponent.isEdit)
          addalertComponent.tradingRestService.getAlertService().CreateOrderTradeAlert(addalertComponent.Exchange, addalertComponent.Token, addalertComponent.Comment,
            addalertComponent.Target, addalertComponent.SendOn, addalertComponent.GetPerTradeAlertRes);
        else
          addalertComponent.tradingRestService.getAlertService().ModifyPerTradeAlert(addalertComponent.ATMNumber, addalertComponent.Target, addalertComponent.SendOn,
            addalertComponent.modifyPerTradeAlertRes);
      }
    }
    else {
      if (addalertComponent.Symbol === '')
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.select_scrip'), '', { duration: 3000 });
      else if (addalertComponent.Source === '')
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.select_alert'), '', { duration: 3000 });
      else if (addalertComponent.Value === '' || isNaN(Number(addalertComponent.Value)) || Number(addalertComponent.Value) === 0)
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.valid_value'), '', { duration: 3000 });
      else if (addalertComponent.SendOn === '')
        addalertComponent.snackBar.open(addalertComponent.translate.instant('alert.select_number'), '', { duration: 3000 });
      else {
        if (!addalertComponent.isEdit)
          addalertComponent.tradingRestService.getAlertService().CreateSecTradeAlert(addalertComponent.Exchange, addalertComponent.Source, addalertComponent.Value,
            addalertComponent.Direction, addalertComponent.SendOn, addalertComponent.Symbol, addalertComponent.GetSecTradeAlertRes);
        else
          addalertComponent.tradingRestService.getAlertService().ModifySecTradeAlert(addalertComponent.ATMNumber, addalertComponent.Value,
            addalertComponent.Direction, addalertComponent.SendOn, addalertComponent.GetSecTradeAlertRes);
      }
    }
  }
  onTypeSelection($event: MatRadioChange) {
    addalertComponent.type = $event.value;
    addalertComponent.Symbol = '';
    addalertComponent.Exchange = '';
    if (addalertComponent.type === 'Index') {
      addalertComponent.decimalPrecision = 2;
      addalertComponent.Source = 'indxalrt';
    }
  }
  onExchangeSelection($event: MatSelectChange) {
    addalertComponent.Exchange = $event.value;
    addalertComponent.tradingRestService.getAlertService().GetOpenOrders(addalertComponent.Exchange, addalertComponent.getOpenOrderRes);
  }
  onDirSelection($event: MatRadioChange) {
    addalertComponent.Direction = $event.value;
  }
  onSourceSelection($event: MatSelectChange) {
    addalertComponent.Source = $event.value;
  }
  onSendSelection($event: MatSelectChange) {
    addalertComponent.SendOn = $event.value;
  }
  onOrderSelection($event: MatSelectChange) {
    addalertComponent.Token = $event.value.Symbol;
    addalertComponent.Comment = $event.value.Nstordno;
  }
  onAlertSelection($event: MatSelectChange) {
    addalertComponent.Target = $event.value;
  }
  onIndexSelection($event: MatSelectChange) {
    addalertComponent.Exchange = $event.value.ExchName;
    addalertComponent.Symbol = $event.value.name;
  }
  cancel() {
    Preferences.removeItem('tsym');
    Preferences.removeItem('exg');
    Preferences.removeItem('token');
    Preferences.removeItem('decimalPrecision');
    if (addalertComponent.isEdit) {
      const dialogRef = this.dialog.open(MyAlertDialogComponent,
        { data: { heading: this.translate.instant('alert.cancel_alert_hed'), message: addalertComponent.translate.instant('alert.cancel_alert') } });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') {
          addalertComponent.tradingRestService.getAlertService().CancelAlert(addalertComponent.ATMNumber, addalertComponent.GetCancelAlertRes);
        }
      });
    } else {
      if (addalertComponent.isMobile) {
        addalertComponent.location.back();
      } else {
        Utility.dialogRef.close();
        Utility.openPage(addalertComponent.router, 'addalertComponent', 'alert', AlertComponent, addalertComponent.dialog, 'popup');
      }
    }
  }
  GetCancelAlertRes(data, code, msg) {
    if (code === 200) {
      if (addalertComponent.isMobile) {
        addalertComponent.location.back();
      } else {
        Utility.dialogRef.close();
        Utility.openPage(addalertComponent.router, 'addalertComponent', 'alert', AlertComponent, addalertComponent.dialog, 'popup');
      }
    }
  }
  getOpenOrderRes(data, code, msg) {
    if (code === 200) {
      const openOrderData = [];
      let count = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].Status && (data[i].Status.toLowerCase() === 'open') || data[i].Status.toLowerCase() === 'trigger pending' || data[i].Status.toLowerCase() === 'after market order req received') {
          openOrderData[count] = data[i];
          count++;
        }
      }
      addalertComponent.orderarr = openOrderData;
    }

  }
  GetAlertIndexRes(data, code, msg) {
    if (code === 200) {
      addalertComponent.indexarr = data.IndexDetail;
    }
  }
  GetSecTradeAlertRes(data, code, msg) {
    if (code === 200) {
      if (addalertComponent.isMobile) {
        addalertComponent.location.back();
      } else {
        Utility.dialogRef.close();
        Utility.openPage(addalertComponent.router, 'addalertComponent', 'alert', AlertComponent, addalertComponent.dialog, 'popup');
      }
    }
    else{
      addalertComponent.snackBar.open(msg, '', { duration: 3000 });
    }
  }
  modifyPerTradeAlertRes(data, code, msg) {
    if (code === 200) {
      if (addalertComponent.isMobile) {
        addalertComponent.location.back();
      } else {
        Utility.dialogRef.close();
        Utility.openPage(addalertComponent.router, 'addalertComponent', 'alert', AlertComponent, addalertComponent.dialog, 'popup');
      }
    }
  }
  GetPerTradeAlertRes(data, code, msg) {
    if (code === 200) {
      if (addalertComponent.isMobile) {
        addalertComponent.location.back();
      } else {
        Utility.dialogRef.close();
        Utility.openPage(addalertComponent.router, 'addalertComponent', 'alert', AlertComponent, addalertComponent.dialog, 'popup');
      }
    }
  }
  openSearch() {
    if (!addalertComponent.isEdit) {
      if (addalertComponent.isMobile) {
        addalertComponent.router.navigate(['/search'], { queryParams: { isfromalert: true } });
      } else {
        const data_obj = { isfromalert: true };
        Utility.olddialogRef = Utility.dialogRef;
        Utility.openPage(addalertComponent.router, 'addalert', 'search', SearchComponent, addalertComponent.dialog, 'popup', data_obj, '580px', '500px', addalertComponent);
      }
      Preferences.getItem('tsym', ReturnType.String);
      Preferences.getItem('exg', ReturnType.String);
    }
  }

  validatePrice(event) {
    const key = event.key;
    const output = event.target.value.toString() + '' + key;
    const size = 7;
    const regex = new RegExp('^[.][0-9]{0,' + addalertComponent.decimalPrecision + '}$|^[0-9]{0,' + size + '}$|^[0-9]{0,' + size + '}[.]{1,1}[0-9]{0,' + addalertComponent.decimalPrecision + '}$');
    if (!regex.test(output) && key !== 'Backspace' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab') {
      event.returnValue = false;
      if (event.preventDefault)
        event.preventDefault();
    }
  }

}
