import { Component, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MyAlertDialogComponent } from '../../home/watchlist/watchlist.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from '../../../../services/utility';
import { Preferences, ReturnType } from 'src/app/services/Preferences';

@Component({
    selector: 'app-spreadordermoreoption',
    templateUrl: './spreadordermoreoption.component.html',
    styleUrls: []
})

export class SpreadOrderMoreOptionComponent {
    orderDetailData;
    fillQuantity;
    isEdit: boolean;
    isCancel: boolean;
    alertMessage = '';
    WlArr = [];
    subscription;
    dataModel;

    constructor(private _bottomSheetRef: MatBottomSheetRef<SpreadOrderMoreOptionComponent>, private dialog: MatDialog, private router: Router,
        private translate: TranslateService, private snackBar: MatSnackBar, private detector: ChangeDetectorRef) {
        this.orderDetailData = Utility.spreadOrderDetail;
        this.dataModel = this.orderDetailData;
        if(this.dataModel.Status.toLowerCase() == "open")
        {
            this.isCancel=true;
            if(this.dataModel.PriceType == "SP")
            {
                this.isEdit=true;
            }
        }
        
    }

    openLink(): void {
        this._bottomSheetRef.dismiss();
    }


    handleButton(buttonType) {
        switch (buttonType) {
            case 'E':
                this.router.navigate(['placeorder'], { queryParams: { 'isEdit': true } });
                this.openLink();
                break;
            case 'H':
                this.router.navigate(['/spreadorderhistory']);
                this.openLink();
                break;
        }
    }

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onSwipeDown() {
        this._bottomSheetRef.dismiss();
    }
}
