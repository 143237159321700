import { Component, Input, OnChanges, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { PtPlusRestService } from '../../../services/ptplusrest.service';
import { ActivatedRoute } from '@angular/router';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';

let ibesComponent: IbesComponent;

@Component({
    selector: 'app-ibes',
    templateUrl: './ibes.component.html',
    styleUrls: ['./ibes.component.scss']
})

export class IbesComponent implements OnChanges, OnInit {
    @Input() omtkn: string;
    @Input() exseg: string;
    @Input() isCustomDashboard: boolean;
    targetPrice: string;
    total: string;
    maxIndex: any;
    isNoData;
    chartOptions = {};
    chartLabel = [];
    chartData = [];
    colors = [];
    chartType;
    ibesChartData = [];
    chartPlugins;
    scripToken;
    exchangeSegment;
    Height = {};
    container?: ComponentContainer;
    modesub: Subscription;

    constructor(private injector: Injector, private detector: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private ptPlusRestService: PtPlusRestService) {
        ibesComponent = this;
        if (!Utility.IS_MOBILE) {
            ibesComponent.container = <ComponentContainer>ibesComponent.injector.get('Container');
        }
        ibesComponent.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            ibesComponent.changeMode();
        });
    }

    changeMode(){
        ibesComponent.Height = {
            'height': (ibesComponent.container.height) + 'px',
            'overflow': 'auto'
        };
        ibesComponent.detector.detectChanges();
    }

    ngOnInit() {
        if (ibesComponent.container) {
           /* ibesComponent.container.on('resize', _ => {
                ibesComponent.changeMode();
            });*/
        }
        if (ibesComponent.isCustomDashboard) {
            ibesComponent.scripToken = ibesComponent.omtkn;
            ibesComponent.exchangeSegment = ibesComponent.exseg;
            ibesComponent.fetchIbesData();
        }
        if (ibesComponent.isCustomDashboard) {
            ibesComponent.changeMode();
        }
    }
    ngOnChanges() {
        ibesComponent.scripToken = ibesComponent.omtkn;
        ibesComponent.exchangeSegment = ibesComponent.exseg;
        ibesComponent.fetchIbesData();
    }

    fetchIbesData() {
        ibesComponent.ptPlusRestService.getFundamentalsService().getIbes(ibesComponent.scripToken, ibesComponent.exchangeSegment, 'omnisys', (code, data, message) => {
            let ibesData = [];
            if (code === 200) {
                if (data['Statistic'] && data['Statistic'].length) {
                    ibesComponent.isNoData = false;
                    ibesData = data.Statistic;
                    ibesComponent.total = data.NumberOfRecommendations;
                    ibesComponent.targetPrice = data.median;
                    ibesComponent.drawBarChart(ibesData);
                }
                else
                    ibesComponent.isNoData = true;
            }
            else
                ibesComponent.isNoData = true;
        });
    }

    drawBarChart(data) {
        ibesComponent.clearPreviousChart();
        if (data && data.length) {
            const element = document.querySelector('.chart-color3');
            const style = getComputedStyle(element);
            data = data.reverse();
            const minobj = data[0];
            const keyArr = { 1: 'STRONG BUY', 2: 'BUY', 3: 'HOLD', 4: 'REDUCE', 5: 'SELL' };
            let minvalueY = minobj['Recommendation'];
            let maxvalueY = minobj['Recommendation'];
            let minvalueX = minobj['NumberOfAnalysts'];
            let maxvalueX = minobj['NumberOfAnalysts'];
            for (let i = 0; i < data.length; i++) {
                if (minvalueX > data[i]['Recommendation']) {
                    minvalueX = data[i]['Recommendation'];
                }
                if (maxvalueX < data[i]['Recommendation']) {
                    maxvalueX = data[i]['Recommendation'];
                }
                if (minvalueY > data[i]['NumberOfAnalysts']) {
                    minvalueY = data[i]['NumberOfAnalysts'];
                }
                if (maxvalueY < data[i]['NumberOfAnalysts']) {
                    maxvalueY = data[i]['NumberOfAnalysts'];
                    ibesComponent.maxIndex = i;
                }
            }
            for (let i = 0; i < data.length; i++) {
                ibesComponent.chartLabel.push(keyArr[data[i]['Recommendation']] + ' (' + data[i]['NumberOfAnalysts'] + ')');
                ibesComponent.chartData.push(data[i]['NumberOfAnalysts']);
                data[i]['RecommendationLabel'] = keyArr[data[i]['Recommendation']] + ' (' + data[i]['NumberOfAnalysts'] + ')';
            }
            ibesComponent.chartOptions = {
                layout: { padding: { left: 4, right: 24 } },
                responsive: true,
                maintainAspectRatio: false,
                legend: { display: false },
                scales: {
                    xAxes: [{ gridLines: { lineWidth: 0, zeroLineColor: style.color }, ticks: { display: false } }],
                    yAxes: [{
                        gridLines: { display: false }, ticks: {
                            fontColor: style.color, min: 0, max: maxvalueY + ((maxvalueY / 100) * 30),
                            stepSize: parseInt('(((maxvalueY - minvalueY) / 100) * 5).toString()', 10)
                        }
                    }]
                },
                plugins: { datalabels: { anchor: 'end', align: 'end', font: { size: 10 }, color: style.color }, crosshair: false },
                tooltips: {
                    bodyFontColor: style.color,
                    callbacks: {
                        title: function (tooltipItems, titledata) {
                            return '';
                        },
                        label: function (tooltipItem, labelData) {
                            const label = labelData.labels[tooltipItem.index];
                            return labelData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        }
                    }
                }
            };
            ibesComponent.chartType = 'horizontalBar';
            ibesComponent.chartPlugins = [pluginDataLabels];
            ibesComponent.ibesChartData = [{ data: ibesComponent.chartData, stack: '' }];
            ibesComponent.colors = [{ backgroundColor: [style.columnRuleColor, style.floodColor, style.fill, style.borderColor, style.outlineColor] }];
        }
    }

    clearPreviousChart() {
        ibesComponent.chartOptions = {};
        ibesComponent.chartLabel = [];
        ibesComponent.chartData = [];
        ibesComponent.ibesChartData = [];
    }
}
