import { Component, OnInit, ViewChild } from '@angular/core';
import { TradingRestService } from '../../../services/tradingrest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Keys } from '../../../services/keys';
import { Router } from '@angular/router';
import { Utility } from 'src/app/services/utility';
import { PtPlusRestService } from '../../../services/ptplusrest.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { MpininputComponent } from '../mpininput/mpininput.component';
import { environment } from 'src/environments/environment';
import { Preferences } from 'src/app/services/Preferences';

let changeMpinComponent: ChangeMpinComponent;
@Component({
  selector: 'app-change-mpin',
  templateUrl: '../../'+environment.baseviewpath+'/changempin/changempin.component.html'
})
export class ChangeMpinComponent implements OnInit {
  oldmpin = '';
  newmpin = '';
  isMobile;
  confirm_mpin = '';
  @ViewChild('newMpinInput') newMpinInput: MpininputComponent;
  @ViewChild('confirmMpinInput') confirmMpinInput: MpininputComponent;

  constructor(private tradingRestService: TradingRestService, private ptPlusRestService: PtPlusRestService, private snackBar: MatSnackBar, private router: Router,
    private translate: TranslateService) {
      changeMpinComponent = this;
      changeMpinComponent.isMobile = Utility.IS_MOBILE;
  }

  ngOnInit() {
  }
  checkPin(data, type) {
    if (type === 'O') {
      changeMpinComponent.oldmpin = data;
    } else if (type === 'N') {
      changeMpinComponent.newmpin = data;
    } else if (type === 'C') {
      changeMpinComponent.confirm_mpin = data;
    }
  }
  close() {
    Utility.dialogRef.close();
  }
  submit() {
    if (changeMpinComponent.oldmpin === '') {
      changeMpinComponent.snackBar.open(changeMpinComponent.translate.instant('login.old_mpin'), '', { duration: 3000 });
    } else if (changeMpinComponent.newmpin === '') {
      changeMpinComponent.snackBar.open(changeMpinComponent.translate.instant('login.new_mpin'), '', { duration: 3000 });
    } else if (changeMpinComponent.confirm_mpin === '') {
      changeMpinComponent.snackBar.open(changeMpinComponent.translate.instant('login.confirm'), '', { duration: 3000 });
    } else if(changeMpinComponent.newmpin.length < 4 || changeMpinComponent.confirm_mpin.length < 4 || changeMpinComponent.oldmpin.length < 4) {
      changeMpinComponent.snackBar.open(changeMpinComponent.translate.instant('login.short_pin'), '', { duration: 3000 });
    }else if (changeMpinComponent.oldmpin === changeMpinComponent.newmpin) {
      changeMpinComponent.snackBar.open(changeMpinComponent.translate.instant('login.old_new_pin'), '', { duration: 3000 });
    }else if (changeMpinComponent.newmpin !== changeMpinComponent.confirm_mpin) {
      changeMpinComponent.snackBar.open(changeMpinComponent.translate.instant('login.pin_and_confirm'), '', { duration: 3000 });
    } else {
      changeMpinComponent.tradingRestService.getLoginService().ChangeMpin(changeMpinComponent.oldmpin,
        changeMpinComponent.newmpin, changeMpinComponent.ChangePinResponse);
    }
  }

  oldPinChanged(pin) {
    changeMpinComponent.oldmpin = pin;
    if (changeMpinComponent.oldmpin.length === 4)
      changeMpinComponent.newMpinInput.setFocus();
  }

  newPinChanged(pin) {
    changeMpinComponent.newmpin = pin;
    if (changeMpinComponent.newmpin.length === 4)
      changeMpinComponent.confirmMpinInput.setFocus();
  }

  confirmPinChanged(pin) {
    changeMpinComponent.confirm_mpin = pin;
  }

  ChangePinResponse(obj, code, message) {
    if (code === 200) {
      Preferences.setPreference('ht_key', Utility.encryptUserMessage(changeMpinComponent.newmpin));
      if(Utility.dialogRef)
        Utility.dialogRef.close();
      Preferences.setPreference('isMPIN', 'false');
      changeMpinComponent.logoutForcefully();
    }
    changeMpinComponent.snackBar.open(changeMpinComponent.translate.instant(Utility.getErrorMessage(code,message)), '', { duration: 3000 });    
  }

  logoutForcefully()
  {
    Utility.candeactivate = false;
    Preferences.setPreference('LoggedIn', 'false');
    Preferences.setPreference('finallogin', 'false');
    if (environment.isQuickRest) {
        changeMpinComponent.tradingRestService.tradeFeeder.closeSocketConnection();
    }
        Preferences.setPreference('userSessionId', null);
        Utility.userId = '';
        if (Utility.orderDataSubscription)
            Utility.orderDataSubscription.unsubscribe();
        changeMpinComponent.router.navigate(['']);
  }
}
