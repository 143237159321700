import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, OnInit, HostListener, OnChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SellmoreComponent } from './sellmore/sellmore.component';
import { HoldingModel } from 'src/app/model/holding.model';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { TranslateService } from '@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { TableUtility } from 'src/app/services/tableutility';
import { Subscription, Subject } from 'rxjs';
import { ChannelType, Utility } from 'src/app/services/utility';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { PortfolioMoreOptionComponent } from '../home/portfolio/portfoliomoreoption/portfoliomoreoption.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-selllist',
  templateUrl: '../../'+environment.baseviewpath+'/selllist/selllist.component.html'
})
export class SelllistComponent extends BaseComponentDirective implements OnInit, OnChanges{
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  filteredHoldingList: Array<HoldingModel> = [];
  dataArr = [];
  noData: boolean;
  isFilterNoData: boolean;
  dataArrLen = 0;
  selectedFilter = { 'type': 'All', 'data': 'ALL' };
  selectedFilterOption = '';
  tempFilteredHoldingList: Array<HoldingModel> = [];
  dataArray : Array<HoldingModel> = [];
  selectedRowIndex : any;
  bookColumnSubscription: Subscription;
  displayedColumns;
  utility: any;
  subscription: any;
  holdingList: Array<HoldingModel>;
  isEdisEnable = environment.isEdis;
  holdings: any = {};
  keyword ='';
  tradingSym = "";
  compName = '';
  inputValue ='';
  isMobile;
  @Input() isOpen;
  @Output() closeSellList  = new EventEmitter();
  edisCheck: boolean;
  hostName: string;
  constructor(elRef: ElementRef, private _bottomSheet: MatBottomSheet, private router: Router, private location: Location, private tradingRestService: TradingRestService, private translate: TranslateService, public dialog: MatDialog) {
   
    super(elRef.nativeElement);
    this.utility = Utility;
    this.isMobile = Utility.IS_MOBILE;
    this.getColumns();

    this.bookColumnSubscription = TableUtility.updateHoldingBookColumns.subscribe(data =>{
      this.updateColumns();
    })

    this.subscription = Utility.ORDER_PLACED_EVENT.subscribe(data => {
      this.callHoldingBookService();
    });

    this.hostName = window.location.hostname;
  }

  setBaseVisibility(visible: boolean){
    if(visible){
      setTimeout(() => {
        this.onAppearing();
      }, 50);
    }
    else {
      this.onDisappearing();
    }
  }

  onAppearing() {
    Utility.resumeChannel(this.tradingRestService, ChannelType.Holding);
  }

  onDisappearing() {
    Utility.pauseChannel(this.tradingRestService, ChannelType.Holding);
  }

  ngOnInit() {
    if (this.searchElement !== undefined)
      this.searchElement.nativeElement.focus();
    this.callHoldingBookService();
  }

  ngOnChanges() {
    if (this.filteredHoldingList.length > 0) {
      this.highlight(this.filteredHoldingList[0], 0)
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== 0 && event.currentIndex !== 1) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      Preferences.setItem('holdingColumns', this.displayedColumns);
    }
  }
  getColumns()
  {
    let tempColumns = Preferences.getItem('holdingBookColumns', ReturnType.Undefined);
    if (!tempColumns)
    {
      tempColumns = JSON.parse(JSON.stringify(TableUtility.holdingBookColumns));
      Preferences.setItem('holdingBookColumns', tempColumns);
    }
    this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
  }


  updateColumns()
  {
    let tempColumns = Preferences.getItem('holdingBookColumns', ReturnType.Undefined);
    this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
  }

  callHoldingBookService() {
    this.tradingRestService.getBookService().getAllHoldings(function(holdings, code, message) {
      this.isLoader = false;
      if (code === 200 && message === 'Ok') {
        this.noData = false;
        this.holdings = holdings;
        this.holdingList = holdings.HoldingsModel;
        this.filteredHoldingList = this.holdingList;
        this.tempFilteredHoldingList = this.holdingList;
        this.dataArray = Array.from(this.filteredHoldingList);
        this.ProcessTags();

        if (this.filteredHoldingList.length > 0) {
          this.highlight(this.filteredHoldingList[0], 0)
          this.isfromSpOrder = true;
        }
        if(this.searchType === 'ALL'){
          this.filteredHoldingList = this.holdingList;
        }
      }
      else {
        this.noData = true;
      }
    }.bind(this), null);
  }

  ProcessTags() {
    const resultMap = new Map();
    let obj = {};
    this.dataArr = [];
    const symbolArr = [];
    const exchangeArr = [];
    this.dataArr = [];

    if (this.holdingList.length > 1) {
      for (let i = 0; i < this.holdingList.length; i++) {
        if (this.holdingList[i].Exchange != null) {
          if (!resultMap.has(this.holdingList[i].Exchange)) {
            resultMap.set(this.holdingList[i].Exchange, true);
            obj = {};
            obj['data'] = this.holdingList[i].Exchange;
            obj['type'] = 'Exchange';
            obj['count'] = 1;
            obj['visibility'] = false;
            exchangeArr.push(obj);
          }
          else {
            for (let j = 0; j < exchangeArr.length; j++) {
              if (exchangeArr[j]['data'] === this.holdingList[i].Exchange) {
                exchangeArr[j]['count'] = exchangeArr[j]['count'] + 1;
              }
            }
          }
        }
        if (this.holdingList[i].Symbol != null) {
          if (!resultMap.has(this.holdingList[i].Symbol)) {
            resultMap.set(this.holdingList[i].Symbol, true);
            obj = {};
            obj['data'] = this.holdingList[i].Symbol;
            obj['type'] = 'Symbol';
            obj['count'] = 1;
            obj['visibility'] = false;

            symbolArr.push(obj);
          }

          else {
            for (let j = 0; j < symbolArr.length; j++) {
              if (symbolArr[j]['data'] === this.holdingList[i].Symbol) {
                symbolArr[j]['count'] = symbolArr[j]['count'] + 1;
              }
            }
          }
        }
      }
      if (exchangeArr.length > 1)
        this.processChipData(exchangeArr.sort(this.compare.bind(this)));
      if (symbolArr.length > 1)
        this.processChipData(symbolArr.sort(this.compare.bind(this)));

      for (let i = 0; i < this.holdingList.length; i++) {
        if (this.holdingList[i].Btst > 0) {
            const dataObj = {};
            dataObj['data'] = 'T + 1';
            dataObj['type'] = 'Btst';
            dataObj['count'] = 1;
            dataObj['visibility'] = false;
            this.dataArr.splice(0, 0, dataObj);
            break;
        }
      }
      this.dataArray = Array.from(this.filteredHoldingList);
    }
  }

  processChipData(dataArr) {
    for (let i = 0; i < dataArr.length; i++) {
      this.dataArr.push(dataArr[i]);
    }
  }
  compare(a, b) {
    const count1 = a.count;
    const count2 = b.count;
    let comparison = 0;
    if (count1 < count2) {
      comparison = 1;
    } else if (count1 > count2) {
      comparison = -1;
    }
    return comparison;
  }

  noDataCheck() {
    if (this.filteredHoldingList.length === 0) {
      this.noData = true;
    }
    else {
      this.noData = false;
      this.isFilterNoData = true;
    }
  }

  highlight(row: any, i: number){
    if(this.selectedRowIndex !== i) {
      let element:HTMLElement;
      element = document.getElementById("hb_cell"+i) as HTMLElement;
      element?.focus();
    }
    this.selectedRowIndex = i;
    Utility.SELECTED_HOLDING = row;
    Utility.SELECTED_SCRIP = row.scripModel;
    Utility.selectedTab = TableUtility.holdingBook;
  }

  @HostListener('window:keydown.arrowup', ['$event'])  
  arrowUpEvent(event: KeyboardEvent){
    event.preventDefault();
    if(((this.selectedRowIndex - 1) >= 0))
    {
        var nextrow = this.filteredHoldingList[this.selectedRowIndex - 1];
        this.highlight(nextrow, this.selectedRowIndex - 1);
    }
    else
    {
      this.highlight(this.filteredHoldingList[this.filteredHoldingList.length - 1], this.filteredHoldingList.length - 1);
    }
  }

  @HostListener('window:keydown.arrowdown', ['$event'])
  arrowDownEvent(event: KeyboardEvent){
    event.preventDefault();
    if(((this.selectedRowIndex + 1) < this.filteredHoldingList.length))
    {
      var nextrow = this.filteredHoldingList[this.selectedRowIndex + 1];
      this.highlight(nextrow, this.selectedRowIndex + 1);
    }
    else {
      this.highlight(this.filteredHoldingList[0], 0);
    } 
  }

  close(){
    this.location.back();
  }

  openSheet(data){
    Utility.holdingDetails = data;
    this.edisCheck = this.isEdisEnable ? Utility.edisAuthorizationCheck(data.Pcode, data.AvailableQty, 'S', data.Token, data.ExchangeSeg, (data.TotalHoldingQty - data.Usedqty)) : true;
        if (this.edisCheck){
          if(this.isMobile){
            this._bottomSheet.open(SellmoreComponent, {
              restoreFocus: false
            });
          } else {
            Utility.openPlaceOrder(this.router, data.Token, data.ExchangeSeg, 'S', this.dialog, (environment.baseviewpath === 'fabits') ? false : true);
            this.closeSellList.emit();
          }
        }
        else{
          this.authorize();
        }
    
  }
  validation(ev) {
    var k;  
    k = ev.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 38 || k == 45);
  }
  onSearchChange(searchValue: string) {
    this.inputValue = searchValue;
    this.keyword = searchValue.toUpperCase();
    if (searchValue !== ' ') {
      this.filteredHoldingList = [];
      for (let i = 0; i < this.holdingList.length; i++){
        this.compName = this.holdingList[i].scripModel.CompanyName;
        this.tradingSym = this.holdingList[i].Symbol;
        if(this.compName.includes(this.keyword) || this.tradingSym.includes(this.keyword)){
         this.filteredHoldingList.push(this.holdingList[i]); 
        }
      }
    }
    else{
      this.filteredHoldingList = this.holdingList;
    }
    this.noData = (this.filteredHoldingList.length>0) ? false : true;
  }

  onClearSearch() {
    this.inputValue = '';
    this.keyword = '';
    this.filteredHoldingList = this.holdingList;
    this.noData = (this.filteredHoldingList.length>0) ? false : true;
  }

  authorize(){
    Utility.edisAuthorize(this.holdingList, this.hostName, this.tradingRestService);
  }
}
