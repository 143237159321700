import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import {Subject} from 'rxjs';

let eqMarket: EquityMarketComponent;
@Component({
    selector: 'app-equity-market',
    templateUrl: './equitymarket.component.html',
    styleUrls: ['./equitymarket.component.scss']
})
export class EquityMarketComponent implements OnInit {
    @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
    isLoading: boolean;
    goback = '1';
    isSearch = false;
    tabIndex;
    defaultFeature = environment.DefaultFeature;
    betaFeature = environment.BetaFeature;
    advanceFeature = environment.AdvanceFeature;
    reloadEquityNews: Subject<any> = new Subject();

    constructor(private cdr: ChangeDetectorRef) {
        eqMarket = this;
        if (!Utility.IS_MOBILE) {
            eqMarket.goback = '2';
            eqMarket.isSearch = true;
        }
        eqMarket.tabIndex = eqMarket.defaultFeature ? 4 : 1;
    }

    reloadData()
    {
        this.reloadEquityNews.next();
    }

    ngOnInit() {
    }
    onTabChanged(i){
        eqMarket.tabIndex = i;
    }
    loaderEvent(event) {
        eqMarket.isLoading = event;
        eqMarket.cdr.detectChanges();
    }

    onTitleClick() {
        this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
}
