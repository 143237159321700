import { ComponentItemConfig, ItemType, LayoutConfig } from "golden-layout";

export interface Layout {
    name: string;
    config: LayoutConfig;
}
const defaultConfig: LayoutConfig = {
    dimensions: { headerHeight: 31, minItemWidth: 300 },
        settings: {constrainDragToContainer: true, reorderEnabled: false, showPopoutIcon: false },
        root: {
        type: ItemType.row,
        content: [
            { width:100, height:100, type: ItemType.column, isClosable: false,
                content: [{
                    type: ItemType.stack,
                    content:[]
                }
            ]
            }  
        ]
    }
};

const defaultLayout: Layout = {
    name: 'defaultRow',
    config: defaultConfig,
};
const stockdetailConfig: LayoutConfig = {
    dimensions: { headerHeight: 31, minItemWidth: 300 },
        settings: { constrainDragToContainer: false, reorderEnabled: false, showPopoutIcon: false },
        root:{ type: ItemType.column,
                content: [
                    { height:50, type: ItemType.row, isClosable: false,
                        content:[{ type:ItemType.column, width:50,
                            content:[{  type: "component",
                                        title: "Chart",
                                        isClosable: false,
                                        componentType: "Chart",
                                    } as ComponentItemConfig]
                                },{ type:ItemType.column, width:25,
                            content:[{  type: "component",
                                        title: "Quotes",
                                        isClosable: false,
                                        componentType: "Quotes",
                                    } as ComponentItemConfig]
                                },{ type:ItemType.column, width:25,
                            content:[{  type: "component",
                                        title: "Depth",
                                        isClosable: false,
                                        componentType: "Depth",
                                    } as ComponentItemConfig]}
                            ]
                    },{ height:50, type: ItemType.row, isClosable: false,
                        content:[{ type: ItemType.column, width: 67,
                            content:[{ type: ItemType.stack, 
                                content:[{  type: "component",
                                            title: "News",
                                            isClosable: false,
                                            reorderEnabled: false,
                                            componentType: "StockDetail News",
                                        } as ComponentItemConfig,
                                        {   type: "component",
                                            title: "Events",
                                            isClosable: false,
                                            reorderEnabled: false,
                                            componentType: "Events",
                                        } as ComponentItemConfig]}
                                    ]},{ type:ItemType.column, width:33,
                                    content:[{  type: "component",
                                                title: "Technicals",
                                                isClosable: false,
                                                componentType: "Technicals",
                                            } as ComponentItemConfig]}
                        ]
                    }
                ]
        }
};

const stockdetailLayout: Layout = {
    name: 'defaultRow',
    config: stockdetailConfig,
};

const responsiveConfig: LayoutConfig = {
        dimensions: { headerHeight: 31, minItemWidth: 300 },
        settings: { constrainDragToContainer: false, reorderEnabled: false, showPopoutIcon: false },
        root: {
        type: ItemType.row,
        content: [
            { width:30, type: ItemType.column, isClosable: false,
                content:[{
                    type: "component",
                    title: "WatchList",
                    isClosable: false,
                    reorderEnabled: false,
                    componentType: "WatchList",
                } as ComponentItemConfig ]
            },
            { width:70, type: ItemType.column, isClosable: false,
                content: [{
                    type:"stack",
                    content:[
                        {
                            type: "component",
                            title: "Order Book",
                            isClosable: false,
                            reorderEnabled: false,
                            componentType: "Order Book",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Position Book",
                            isClosable: false,
                            reorderEnabled: false,
                            componentType: "Position Book",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Holding Book",
                            isClosable: false,
                            reorderEnabled: false,
                            componentType: "Holding Book",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Funds",
                            isClosable: false,
                            reorderEnabled: false,
                            componentType: "Funds",
                        } as ComponentItemConfig
                    ]
                }
            ]
            }  
        ]
    }
};

const responsiveLayout: Layout = {
    name: 'responsive',
    config: responsiveConfig,
};

const investorConfig: LayoutConfig = {
    dimensions: { headerHeight: 31, minItemWidth: 300 },
    settings: { constrainDragToContainer: false, reorderEnabled: false, showPopoutIcon: false },
    root: {
        type: ItemType.row,
        content: [
            { width:30, type: ItemType.column, isClosable: false,
                content:[{
                    type: "component",
                    title: "WatchList",
                    isClosable: false,
                    reorderEnabled:false,
                    componentType: "WatchList",
                } as ComponentItemConfig ]
            },
            { width:70, type: ItemType.column, isClosable: false,
                content: [{
                    type:"stack",
                    content:[
                        {
                            type: "component",
                            title: "Dashboard",
                            isClosable: false,
                            reorderEnabled:false,
                            componentType: "Dashboard",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Markets",
                            isClosable: false,
                            reorderEnabled:false,
                            componentType: "Top Gainer/Losers",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Holdings",
                            isClosable: false,
                            reorderEnabled:false,
                            componentType: "Holding Book",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Positions",
                            isClosable: false,
                            reorderEnabled:false,
                            componentType: "Position Book",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Orders",
                            isClosable: false,
                            reorderEnabled:false,
                            componentType: "Order Book",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Funds",
                            isClosable: false,
                            reorderEnabled:false,
                            componentType: "Funds",
                        } as ComponentItemConfig,
                        {
                            type: "component",
                            title: "Investment Boxes",
                            isClosable: false,
                            reorderEnabled:false,
                            componentType: "Investment Boxes",
                        } as ComponentItemConfig,
                    ]
                }
            ]
            }  
        ]
    }
};
   
   const investorLayout: Layout = {
    name: 'investor',
    config: investorConfig,
   };

export const predefinedLayouts: readonly Layout[] = [responsiveLayout, defaultLayout, stockdetailLayout, investorLayout];
export const predefinedLayoutNames: readonly string[] = predefinedLayouts.map((layout) => layout.name);
