
  <div class="logoutpopup">
    <div class="mat-dialog-title">
      <span>{{'more.back_title' | translate}}</span>
    </div>
    <mat-dialog-content class="text-center">
      <svg class="infoIconPopup" viewBox="0 0 24 24">
        <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.86,15.86A8.28,8.28,0,1,1,20.29,12,8.26,8.26,0,0,1,17.86,17.86Z"/>
        <path d="M12,10.51a1.14,1.14,0,0,0-1.14,1.14v4.92a1.14,1.14,0,0,0,2.28,0V11.65A1.14,1.14,0,0,0,12,10.51Z"/>
        <circle cx="12" cy="7.43" r="1.14"/>
    </svg>
    <h1>Hey!</h1>
      <span class="subtext">{{'more.back_msg' | translate}}</span>
    </mat-dialog-content>
    <div class="mat-dialog-actions">
      <div class="row">
        <div class="col-12 text-right p-3" >
          <button mat-flat-button class="" (click)="cancel()">{{'watchlist.cancel' | translate}}</button> 
          <a mat-flat-button [href]="url" target="_blank" class="btn-primary">{{'more.open' | translate}}</a>
       </div>
      </div>
    </div>
  </div>