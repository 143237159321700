import { Pipe, PipeTransform } from '@angular/core';
declare const moment: any;
@Pipe({ name: 'symbolpipe' })

export class SymbolPipe implements PipeTransform {

  transform(symbol: string): any {
    if (symbol && symbol.length > 3 && symbol.includes('-EQ')) {
      return symbol.substring(0, symbol.length - 3);
    }
    return symbol;
  }
}