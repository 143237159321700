<!-- <app-titlebar [title]="'header.select_col' | translate"></app-titlebar> -->
<div class="row m-0">
    <div [class.col-12]="!isMobile" [class.col-6]="isMobile" *ngFor = "let item of columns">
        <div class="p-1" *ngIf="!item.isDisabled"> 
            <mat-checkbox class = "pr-2" [(ngModel)]="item.checked" (change)="checkReset($event, item)"></mat-checkbox>
            {{ item.val | translate }}
        </div>
    </div>
</div>
<div class="row m-0">
    <div class="col-12 pt-3 text-right">
        <button  mat-button [disabled] = "!isReset" (click) = "resetCol()" class="text-uppercase">{{'all.reset' | translate}}</button> &nbsp;&nbsp;
        <button class = "btn-primary" mat-flat-button [disabled] = "!isSave" (click) = "save()">{{'all.save' | translate}}</button>
    </div>
</div>

