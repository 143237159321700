import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Url } from '../url';
import { Utility } from '../utility';

@Injectable()
export class NewsService {
    constructor(private apiService: ApiService) {
    }
    getAllNews(tag: number, start: number, limit: number, callback: Function, precallback?: Function) {
        let message = '';
        let headlines = [];
        const code = '';
        let data = {};
        if (tag === 0)
            data = { 'start': start, 'limit': limit };
        else
            data = { 'tagid': tag, 'start': start, 'limit': limit };
        const key = Url.PTP_NEWS + '_' + tag + '_' + start + '_' + limit;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        return this.apiService.ptpluspost(Url.PTP_NEWS, data).subscribe((result) => {
            if (result.code === 200) {
                if (result.data && result.data.length > 0) {
                    for (let i = 0; i < result.data.length; i++)
                    {
                        if (result.data[i]['is_similar_news'] !== undefined && result.data[i]['is_similar_news'] === 1) {
                            result.data[i]['similar_news'] = JSON.parse(result.data[i]['similar_news']);
                        }
                    }
                    headlines = result.data;
                    Utility.CACHE_STORE.setCacheData(key, headlines);
                } else {
                    message = 'NO DATA';
                }
            } else {
                message = result.message;
            }
            callback(result.code, headlines, message);
        }, error => {
            message = error;
            callback(code, headlines, message);
        });
    }
    getSearchedStockNews(stocks, callback: Function, precallback?: Function)
    {
        let message = '';
        let headlines = [];
        const code = '';
        const data = {'text' : stocks};
        const key = Url.PTP_GROUPNEWS + '_' + stocks;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        return  this.apiService.ptpluspost(Url.PTP_GROUPNEWS, data).subscribe((result) => {
            if (result.code === 200) {
                if (result.data && result.data.length) {
                    headlines = result.data;
                    Utility.CACHE_STORE.setCacheData(key, headlines);
                } else {
                    message = 'NO DATA';
                }
            } else {
                message = result.message;
            }
            callback(result.code, headlines, message);
        }, error => {
            message = error;
            callback(code, headlines, message);
        });
    }
    fetchTags(data, callback: Function, precallback?: Function) {
        let message = '';
        let tags = [];
        const code = '';
        const predata = Utility.CACHE_STORE.getCacheData(Url.PTP_NEWS_TAGS);
        if (predata !== undefined) {
            precallback(predata);
        }
        return this.apiService.ptpluspost(Url.PTP_NEWS_TAGS, data).subscribe((result) => {
            if (result.code === 200) {
                if (result.data && result.data.length) {
                    tags = result.data;
                    Utility.CACHE_STORE.setCacheData(Url.PTP_NEWS_TAGS, tags);
                } else {
                    message = 'NO DATA';
                }
            } else {
                message = result.message;
            }
            callback(result.code, tags, message);
        }, error => {
            message = error;
            callback(code, tags, message);
        });
    }
}
