
import {throwError as observableThrowError, of, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Url } from './url';
import { Utility } from './utility';
import { SSL_OP_TLS_D5_BUG } from 'constants';
import { environment } from '../../environments/environment';
import { Preferences } from './Preferences';
let router: Router;
let apiService: ApiService;

@Injectable()
export class ApiService {
  platformLocation: PlatformLocation;
  rest_url;
  api_url: string;
  time = 30000;
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  });
  hsheaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  });
  ptp_headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'api-key': environment.apiKeyPTP,
    'x-compress': 'true',
  });

  basket_headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'api-key': environment.BASKET_API_KEY,
    'x-compress': 'true',
  });

  htk_headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'api-key': environment.apiKeyHTK,
    'x-compress': 'true'
  });
  iam_headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json'
  });

  hspk_headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'api-key': environment.apiKeyHSPARK,
    'x-compress': 'true',
  });

  constructor(private http: HttpClient, private r: Router, platformLocation: PlatformLocation) {
    router = r;
    this.rest_url = environment.tradeurl;
    this.platformLocation = platformLocation;
    apiService = this;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 500) {
      return of({stat: 'Not_Ok', status: 300, 'msg': 'Invalid data response'});
    } else if (error.status === 401) {
      const loginUrl = '';
      apiService.sessionExp();
      router.navigate([loginUrl]);
      return of({status: 300, 'msg': 'Session Expired'});
    } else if (error.status === 404) {
      return of({status: 404, 'msg': 'Not Found'});
    }
    if (error.status === 200) {
      window.parent.postMessage('Invalid data response', '*');
      return of({status: 300, 'msg': 'Invalid data response'});
    }
    else
    {
      if(error && error.error && error.error.message){
        return of({status : 'Failure', code: 0, 'message': error.error.message});  
      } else {
        return of({status : 'Failure', code: 0, 'message': 'Something went wrong please try again.'});
      }
    }
  }
  private getJson(response: Response) {
    try {
      if ((response['code'] === 503 && response['message'] === 'Invalid session.') || (response['status'] === 300 && response['msg'] === 'Session Expired') || (response['data'] === 'All sessions cleared successfully. Login again' && response['code'] === 200)
       || response['Emsg'] === 'Session Expired' || response['emsg'] === 'Session Expired'
       || (response[0] && (response[0]['Emsg'] === 'Session Expired' || response[0]['emsg'] === 'Session Expired'))) {
          const loginUrl = '';
          apiService.sessionExp();
         router.navigate([loginUrl]);
      }
      return response;
    }
    catch (e) {
    }
  }

  post(path: string, body): Observable<any> {
    if ( Preferences.getPreference('tomcatcount') ) {
      path = this.rest_url + path + '?jsessionid=' + 'Mobile' + '.' + Preferences.getPreference('tomcatcount');
    } else {
      path = this.rest_url + path;
    }
    return this.http.post( `${path}`, body, { headers: this.headers }).pipe(catchError(this.handleError), tap(this.getJson));
  }

  get(path: string): Observable<any> {
    let url = environment.backOfficeUrl + path;
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get( url, { headers, responseType: 'text'});
  }

  hspost(path: string, body): Observable<any> {
    this.hsheaders = this.hsheaders.delete('x-access-token');
    this.hsheaders = this.hsheaders.append('x-access-token', Preferences.getPreference('userSessionId'));
    if ( Preferences.getPreference('tomcatcount') ) {
      path = this.rest_url + path + '?jsessionid=' + 'Mobile' + '.' + Preferences.getPreference('tomcatcount');
    } else {
      path = this.rest_url + path;
    }
    return this.http.post( `${path}`, body, { headers: this.hsheaders }).pipe(catchError(this.handleError), tap(this.getJson));
  }

  ptpluspost(path: string, body?): Observable<any> {
    this.ptp_headers = this.ptp_headers.delete('x-access-token');
    this.ptp_headers = this.ptp_headers.append('x-access-token', Preferences.getPreference('userSessionId')); 
    return this.http.post(`${environment.urlPTP}${path}`, JSON.stringify(body), { headers: this.ptp_headers }).pipe(timeout(this.time), catchError(this.handleError), tap(this.getJson));
  }

  basketpost(path: string, body?): Observable<any> {
    this.basket_headers = this.basket_headers.delete('x-access-token');
    this.basket_headers = this.basket_headers.append('x-access-token', Preferences.getPreference('userSessionId')); 
    return this.http.post(`${environment.BASKET_SERVER_URL}${path}`, JSON.stringify(body), { headers: this.basket_headers }).pipe(timeout(this.time), catchError(this.handleError), tap(this.getJson));
  }

  hypertrackpost(path: string, body?): Observable<any> {
    this.htk_headers = this.htk_headers.delete('x-access-token');
    this.htk_headers = this.htk_headers.append('x-access-token', Preferences.getPreference('userSessionId'));
    return this.http.post(`${environment.urlHTK}${path}`, JSON.stringify(body), { headers: this.htk_headers }).pipe(timeout(this.time), catchError(this.handleError), tap(this.getJson));
  }

  iampost(path: string, body?): Observable<any> {
    const tkn = (Preferences.getPreference('userKey') && Preferences.getPreference('userKey') !== 'undefined') ? Preferences.getPreference('userKey') : Preferences.getPreference('userSessionId');
    this.iam_headers = this.iam_headers.delete('x-access-token');
    this.iam_headers = this.iam_headers.append('x-access-token', tkn ?? '');
    return this.http.post(`${environment.urlIAM}${path}`, JSON.stringify(body), { headers: this.iam_headers }).pipe(timeout(this.time), catchError(this.handleError), tap(this.getJson));
  }

  hssparkpost(path: string, body): Observable<any> {
    this.hspk_headers = this.hspk_headers.delete('x-access-token');
    this.hspk_headers = this.hspk_headers.append('x-access-token', Preferences.getPreference('userSessionId')); 
    return this.http.post(`${environment.urlHSPARK}${path}`, JSON.stringify(body), { headers: this.hspk_headers }).pipe(timeout(this.time), catchError(this.handleError), tap(this.getJson));
  }  

  qprofilepost(path: string, body): Observable<any> {
    return this.http.post( `${environment.qprofileUrl}${path}`, body).pipe(catchError(this.handleError), tap(this.getJson));
  }

  qprofileget(path: string): Observable<any> {
    let url = environment.qprofileUrl + path;
    return this.http.get( url );
  }

  sessionExp() {
    Utility.ScripModelMap = new Map();
    Utility.ChannelScripMap = new Map();
    Utility.MarketPictureModelMap = new Map();
    Utility.IndexModelMap = new Map();
    Utility.ChannelIndexMap = new Map();
    if (Preferences.getPreference('userLogIn') !== '0')
      window.parent.postMessage('Session Expired', '*');
    Utility.isLogin = false;
    Preferences.setPreference('LoggedIn', 'false');
    Preferences.setPreference('userLogIn', '0');
    Preferences.setPreference('checkSession', 'false');
    Preferences.setPreference('finallogin', 'false');
    if (Utility.orderDataSubscription)
        Utility.orderDataSubscription.unsubscribe();
                            
    Utility.candeactivate = false;
    if (Utility.dialogRef){
      Utility.dialogRef.close();
    }
  }
}
