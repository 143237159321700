<app-header *ngIf="isMobile" goback="1" [title]="'all.manage_columns' | translate" ></app-header>

<div *ngIf="!isMobile" mat-dialog-title="" class="mat-dialog-title" id="mat-dialog-title-2 col-12">
    <div class="row px-2">
        <div class="col-10"><h1 translate>all.manage_columns</h1></div>
        <div class="col-2 text-right">
            <button mat-icon-button (click) = "close()"><i class="icon-close"></i></button>
        </div>
    </div>
</div>
<div class="row pt-3 pb-3 px-1">
    <div [class.col-12]="!isMobile" [class.col-6]="isMobile" *ngFor = "let item of columns">
        <div class="p-1" *ngIf="!item.isDisabled"> 
            <mat-checkbox class = "pr-2" [(ngModel)]="item.isChecked" (change)="checkReset($event, item)"></mat-checkbox>
            {{ item.value | translate }}
        </div>
    </div>
    <div class=" col-12 pt-3 text-right">
            <button  mat-button [disabled] = "!isReset" (click) = "resetCol()" class="text-uppercase">{{'all.reset' | translate}}</button> &nbsp;&nbsp;
            <button class = "btn-primary" mat-flat-button [disabled] = "!isSave" (click) = "save()">{{'all.save' | translate}}</button>
    </div>
</div>

