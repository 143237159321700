<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row list-view border-b" (swipedown)="onSwipeDown()">
    <div class="col-8 pl-0">
        <div class="addwatchlistbtn" matRipple>
            <button mat-icon-button (click) = "addWLdata(orderDetailData)" [matMenuTriggerFor]="menu" class="primetext"><i class="icon-plus"></i></button>
            <mat-menu #menu="matMenu">
                <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                <ng-container *ngFor = "let data of WlArr">
                    <button mat-menu-item (click) = "addStock(data.wlName, orderDetailData)" [disabled] = "data.isDisable">
                        <span>{{data.wlName}}</span> 
                    </button>
                </ng-container>
            </mat-menu>
        </div>
        <div class="subcolor min-w160 ts-2 bottomsheetwidth">
            <span class="tc-1 ts-4 textclip">{{orderDetailData.ExchangeSegment == 'nse_fo' ? orderDetailData.TradeSymbol : orderDetailData.TSym | symbolpipe}}</span><small class="exchangename">{{orderDetailData.Exchange}}</small>
            <div class="textclip d-block" matTooltip = "{{orderDetailData.DisplaySymbolDetail}}">{{orderDetailData.scripModel.DisplaySymbolDescription}}</div>
        </div>
    </div>
    <div class="col-4 text-right" [class.imnegative]="orderDetailData.scripModel.Change<0" [class.impositive]="orderDetailData.scripModel.Change>0">
        <div >
            <div [class.ts-3] = "orderDetailData.scripModel.DecimalPrecision == 4" [class.ts-4] = "orderDetailData.scripModel.DecimalPrecision != 4"><span [class.imnegativebg]="orderDetailData.scripModel.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="orderDetailData.scripModel.TickDirection === 1 && flashType !== 'noFlash'">{{orderDetailData.scripModel.LastTradePrice | pricepipe:orderDetailData.scripModel.DecimalPrecision}}</span><br>
                <span class="ts-2" [class.imnegativebg]="orderDetailData.scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="orderDetailData.scripModel.TickDirection === 1 && flashType === 'defaultFlash'">{{orderDetailData.scripModel.Change | pricepipe:orderDetailData.scripModel.DecimalPrecision}} <small class="ts-1">({{orderDetailData.scripModel.PercentageChange | percentagepipe}})</small></span>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12  nofilter">
        <mat-chip-list>
            <mat-chip (click)= "onTabChanged(0)" [class.imactive] = "tabIndex ===  0" translate>stock_detail.overview</mat-chip>
            <mat-chip (click)= "onTabChanged(1)" [class.imactive] = "tabIndex ===  1" translate>all.trades</mat-chip>
        </mat-chip-list>
    </div>
</div>
<div class="sheetscrollarea" [class.d-none] = "tabIndex !== 1">
    <app-tradesummary [nOrderNumber] = "orderDetailData.NestOrd"></app-tradesummary>
</div>
<div class="sheetscrollarea orderbook-area"  [class.d-none] = "tabIndex !== 0">
    <div class="row text-center ts-2 py-2 border-b mb-2">
        <div class="col-4">
            <span class="subcolor ts-1" translate>all.status</span><br>
            <label [class.in-progress-status] = "orderDetailData.DisplayStatus=='In Progress'" [class.trigger-pend-status] = "orderDetailData.DisplayStatus=='Trigger pending'" [class.amo-req-status] = "orderDetailData.DisplayStatus=='after market order req received'"  [class.traded-status] = "orderDetailData.DisplayStatus=='Traded'"   
                    [class.Cancelled-status] = "orderDetailData.DisplayStatus=='Cancelled'" [class.rejected-status] = "orderDetailData.DisplayStatus=='Rejected'" [class.cancelled-amo-status] = "orderDetailData.DisplayStatus=='cancelled after market order'"
                    [class.open-status] = "orderDetailData.DisplayStatus=='Open'" [class.modified-status] = "orderDetailData.DisplayStatus=='Modified'">{{orderDetailData.DisplayStatus}}</label>
        </div>
        <div class="col-4"><span class="subcolor ts-1" translate>all.action_type</span><br>{{orderDetailData.ActionDisplay}}</div>
        <div class="col-4"><span class="subcolor ts-1" translate>all.basket_qty</span><br>{{orderDetailData.TotalQty}}</div>
    </div>
    <div class="row">
        <div class="col-12 pr-0">
            
            <div class="row">
                <div class="col-12">
                    <table class="table-bottomsheet">
                        <tr><td><span class="namefield" translate>all.avgprice</span></td><td> {{orderDetailData.AvgPrice}}</td></tr>
                        <tr><td><span class="namefield" translate>all.trig_price</span></td><td>  {{orderDetailData.TriggerPrice}}</td></tr>
                        <tr><td><span class="namefield" translate>all.discqty</span></td><td>  {{orderDetailData.DiscQty}}</td></tr>
                        <tr><td><span class="namefield" translate>all.product</span></td><td>  {{orderDetailData.ProductCode | valuepipe}}</td></tr>
                        <tr><td><span class="namefield" translate>all.ordervalidity</span></td><td>  {{orderDetailData.Validity}}</td></tr>
                        <tr><td><span class="namefield" translate>all.ordertype</span></td><td>  {{orderDetailData.OrderType | valuepipe}}</td></tr>
                        <tr><td><span class="namefield" translate>all.ordertime</span> </td><td> {{orderDetailData.OrderedTime | datepipe:'mediumtime'}}</td></tr>
                        <tr><td><span class="namefield" translate>all.orderid</span></td><td>  {{orderDetailData.NestOrd}}</td></tr>
                        <tr><td><span class="namefield" translate>all.exordid</span></td><td>  {{orderDetailData.ExchangeOrderNo}}</td></tr>
                        <tr><td><span class="namefield" translate>all.placeby</span> </td><td> {{orderDetailData.User}}</td></tr>
                        <tr><td colspan="2" class="rejection_reason" *ngIf="orderDetailData.DisplayStatus=='Rejected'"><span class="namefield" translate>all.rejection_reason</span><br> {{orderDetailData.RejectionReason}}</td></tr>
                    </table>
                <div >
            </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row bottomsheet-buttons">
    <div class="col-12 p-0">
        <div class="btngroup">
            <div class="impositive" (click)="handleButton('ADD')" matRipple><i class="icon-bb"></i>{{'all.buy' | translate}}</div>
            <div class="imnegative" (click)="handleButton('REMOVE')" matRipple><i class="icon-sb"></i>{{'all.sell' | translate}}</div>
            <div (click)="handleButton('reorder')" matRipple><i class="icon-pcb"></i>{{'all.reorder' | translate}}</div>
            <div *ngIf='orderModel.IsEdit' (click)='handleButton("M")' matRipple><i class="icon-edit"></i>{{'all.modify' | translate}}</div>
            <div *ngIf="orderModel.IsCancel" (click)="handleButton('C')" matRipple><i class="icon-cancel"></i>{{'all.cancel' | translate}}</div>
            <div *ngIf="orderModel.IsExit" (click)="handleButton('E')" matRipple><i class="icon-minusb"></i>{{'all.exit' | translate}}</div>
            <div (click)="handleButton('H')" matRipple ><i class="icon-history "></i>{{'all.history' | translate}}</div>
            
            <div (click)="handleButton('sd')" matRipple><i class="icon-ib "></i>{{'all.detail' | translate}}</div>
        </div>
    </div>
</div>