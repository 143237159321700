import { Component, OnInit, OnChanges, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { Preferences } from 'src/app/services/Preferences';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Url } from 'src/app/services/url';
import { ChannelType, Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: '../../'+environment.baseviewpath+'/dashboard/dashboard.component.html',
    styleUrls:['./dashboard.component.scss']
})
export class DashbaordComponent extends BaseComponentDirective implements OnChanges, OnInit {
    container;
    @Output() load = new EventEmitter<boolean>();
    name: string;
    holdings: any = {};
    isMobile:boolean;
    ipoUrl = '';
  
    
    constructor(elRef: ElementRef, private router: Router, private tradingRestService: TradingRestService) {
        super(elRef.nativeElement);
        this.isMobile = Utility.MOBILE;
    }
    ngOnChanges(){

    }
    ngOnInit(){
        if (environment.baseviewpath === 'fabits' || environment.baseviewpath === 'investor'){
            this.load.emit(true);
            this.getHoldingData();
            this.getData();
        }
    }

    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Holding);
    }
    
    onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Holding);
    }    
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
    }

    openbuyList(){
       if(this.isMobile)
            this.router.navigate(['/search']);
       else{
            var obj = {"name":"buy"}
            Utility.InputsDataGet.next(obj)  
       }
       

    }

    openSellList(){
        if(this.isMobile)
            this.router.navigate(['/selllist']);
        else{
            var obj = {"name":"sell"};
            Utility.InputsDataGet.next(obj);  
        }
    }

    openFunds(){
        if(this.isMobile)
            this.router.navigate(['/limit']);
        else {
            var obj = {"name":"Funds"}
            Utility.InputsDataGet.next(obj);
        }
    }

    openBasket()
    {
        if(this.isMobile) {
            this.router.navigate(['/baskethome']);
        }  else{
            var obj = {"name":"Investment Boxes"}
            Utility.InputsDataGet.next(obj);
        }
    }

    getData(){
        this.tradingRestService.getUserService().getUserProfile(function(data, code, msg) {
          if (code === 200 && msg === 'OK') {
              this.name = data['accountName'];
              let key = Url.HS_USER_DETAILS + '_' + Preferences.getPreference('userid');
              let predata = Utility.CACHE_STORE.getCacheData(key);
              if(environment.baseviewpath === 'investor'){
                  Utility.fromBasket = false;
                  Utility.getQProfileData.next(data['cellAddr']);
              }
              this.ipoUrl = 'https://eipo.fabits.app/Login/indexpre/'+predata['usrId'].split('-')[0]+'/'+predata['pan'];
          }
        }.bind(this), function(data, code, msg) {});
      }
      getHoldingData() {
        this.tradingRestService.getBookService().getAllHoldings(function(holdings, code, message) {
            this.load.emit(false);
            this.holdings = holdings;
        }.bind(this), null);
      }
      setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.onAppearing();
          }, 50);
        }
        else{
            this.onDisappearing();
        }
      }    
}
