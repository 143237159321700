import { data } from 'jquery';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BasketOrdersummaryComponent } from '../basketordersummary/basketordersummary.component';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { BasketPlaceOrderSummaryComponent } from '../basketplaceordersummary/basketplaceordersummary.component';
import { BasketPositionModel } from 'src/app/model/basketposition.model';
import { environment } from 'src/environments/environment';
import { MyAlertDialogComponent } from '../../home/watchlist/watchlist.component';
import { TranslateService } from '@ngx-translate/core';
import { Location} from '@angular/common';
import { UsertodayssipComponent } from '../usertodayssip/usertodayssip.component';

let basketplaceorder: BasketPlaceOrderComponent;

@Component({
    selector: 'app-basketplaceorder',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketplaceorder/basketplaceorder.component.html',
    styleUrls: ['./basketplaceorder.component.scss']
})
export class BasketPlaceOrderComponent {
    @Output() load = new EventEmitter<boolean>();
    @ViewChild('focus') focusElement: ElementRef;
    isLoading = true;
    detailedBaskets: any;
    totalAmount: number;
    isNodata: boolean;
    minInvestAmount: any;
    minInvestAmountstr:any;
    tempminInvestAmount: any;
    isError = false;
    isButtonDisable = true;
    orderSummary = [];
    detailedOrderSummary = [];
    invalidQuantity = false;
    isMobile = true;
    basketDataObj;
    isRebalance: boolean;
    isExit: boolean;
    isPlaceOrder: boolean;
    basketPositionData;
    basketData;
    orderType;
    isNoStock: boolean;
    investmentAmount = 0;
    toggle = true;
    key = '';
    scripModelArr = [];
    investSummry: boolean;
    cnfrmDisable: boolean;
    editDisable = true;
    msg: string;
    balance: number;
    lowBalance = false;
    qty =  1;
    originalamount;
    selectedTab = "Lot";
    totalToInvest;
    isLot = false;
    isDisabledPlus = false;
    isDisabledMinus = false;
    @ViewChild('inputQty', { static: false }) inputQty: ElementRef;
    selectedOption = "Fully Exit";
    selectedType = "OneTime"
    cnfrmFlag = false;
    exitTitle = "";
    isSip = false;
    sip_frequency = [];
    selectedFrequency: string;
    sip_day = [];
    selectedDay: string;
    fromTodaySip = Utility.fromTodaySip;
    utility;
    headTitle: string;
    disableForm: boolean;
    sip_data = [];
    amo = 'NO';
    

    constructor(private tradingRestService: TradingRestService, private activatedRoute: ActivatedRoute, private PS: PtPlusRestService, private snackBar: MatSnackBar, private router: Router, private dialog: MatDialog,  private translate: TranslateService, private location: Location) {
        basketplaceorder = this;
        this.balance = Utility.avl_margin;
        this.utility = Utility;
        if(this.balance == undefined){
            this.getData();
        }
        basketplaceorder.isMobile = Utility.IS_MOBILE;
        basketplaceorder.minInvestAmount = Preferences.getItem('basketData')['min_investment'];
        if(Preferences.getItem('basketData').sip_enable) {
            this.isSip = true;
        }

        if(basketplaceorder.isMobile) {
            basketplaceorder.activatedRoute.queryParams.subscribe(params => {
                this.setOrderType(params.basketOrderType);
            });
        } else {
            this.setOrderType(Utility.dialogData.basketOrderType);
        }
        
        if(Utility.fromTodaySip) {
            this.headTitle = Utility.selectedSipData.basket_name;
        } else {
            this.headTitle = "Invest";
        }
        
    }
    getData(){
        let sendJson={};
        sendJson = {
          'uid': Preferences.getPreference('userid'),
          'actid': JSON.parse(Preferences.getPreference('userParameter')).sAccountId,
          'segment': "ALL",
          'calcType': 'DIRECT',
          'product': null,
          'seg': null,
          'ex_segment': null,
          'exchange': null
        };
        this.tradingRestService.getUserService().getAllLimits(sendJson, function(limitData, code, message) {
          if (code === 200 && message === 'Ok') {
                this.getAllLimitsResponse(limitData);
            }
            else {
                this.isNoData = true;
            }
        }.bind(this), this.getAllLimitsResponse.bind(this));  
    }
    
    getAllLimitsResponse(data){
        Utility.limitBalance = data[0]['AvailableCash'];
        Utility.avl_margin = data[0]['AvailableMargin'];
        this.balance = Utility.avl_margin;
    }
    setOrderType(type){
        switch (type) {
            case 'FirstPurchase': 
                basketplaceorder.initPlaceorder();
                basketplaceorder.orderType = 'FirstPurchase';
                break;
            case 'RebalancePurchase': 
                basketplaceorder.initRebalancePlaceorder();
                basketplaceorder.orderType = 'RebalancePurchase';
                break;
            case 'ExitPurchase': 
                basketplaceorder.initExitPlaceorder();
                basketplaceorder.orderType = 'ExitPurchase';
                break;
            default: 
                basketplaceorder.isNodata = true;
        }        
    }
    initPlaceorder()
    {
        basketplaceorder.investmentAmount = Preferences.getItem('basketData')['min_investment'];
        this.originalamount = basketplaceorder.investmentAmount;
        basketplaceorder.isPlaceOrder = true;
        this.getBasketDetail();
        basketplaceorder.totalAmount = 0;   
    }
    
    initExitPlaceorder()
    {
        if(Utility.basketPositionData !== undefined) {
            basketplaceorder.exitTitle = "Exiting "+Utility.basketPositionData.basket_name;
            basketplaceorder.editDisable = false;
            basketplaceorder.isExit = true;
            basketplaceorder.isLoading = false;
            basketplaceorder.basketData = Utility.basketPositionData;
            basketplaceorder.basketPositionData = basketplaceorder.basketData.positionData;
            basketplaceorder.isButtonDisable = false;
        } else {
            this.location.back();
            Utility.SELECTED_BASKET_TAB = 1;
        }

    }

    initRebalancePlaceorder()
    {
        if(Utility.basketPositionData !== undefined){
            basketplaceorder.isRebalance = true;
            basketplaceorder.basketData = Utility.basketPositionData;
            basketplaceorder.basketPositionData = basketplaceorder.basketData.positionData;
            basketplaceorder.isButtonDisable = false;
            basketplaceorder.isLoading = true;
            basketplaceorder.load.emit(basketplaceorder.isLoading);
            let data = {
                "basket_user_id": basketplaceorder.basketData.basket_user_id,
                "active_version": basketplaceorder.basketData.active_version,
                "type": "v2"
            };
            this.PS.getThematicBasketService().getRebalnceData(data, this.getRebalanceResponse);
        }
        else{
            this.location.back();
            Utility.SELECTED_BASKET_TAB = 1;
        } 
    }

    getBasketDetail(){
        let obj = {};
        this.toggle = true;
        basketplaceorder.isLoading = true;
        if(this.selectedTab === "Lot" && basketplaceorder.totalToInvest!== undefined)
            basketplaceorder.minInvestAmount = basketplaceorder.totalToInvest;
        else
            basketplaceorder.minInvestAmount = basketplaceorder.investmentAmount;
        if(Utility.fromTodaySip){
            obj = {"id": Utility.selectedSipData.basket_id, "amount": Utility.selectedSipData.sip_amount, "investment_type": 'investment'};
        }
        else if(this.isPlaceOrder) {
            obj = {"id": Preferences.getItem('basketData')['id'], "amount": basketplaceorder.minInvestAmount, "investment_type": 'investment'};
        }
        if(this.isExit) {
            obj = {"id": Utility.basketPositionData.basket_id, "amount": basketplaceorder.minInvestAmount, "investment_type": 'partiallyexit'};
        }
        this.PS.getThematicBasketService().calculateBasketQty(obj, this.basketDetailResponse);

    }

    basketDetailResponse(data, code, message){
        basketplaceorder.isDisabledPlus = false;
        basketplaceorder.isDisabledMinus = false;
        if(code === 200)
        {
            if(basketplaceorder.isExit) {
                basketplaceorder.cnfrmFlag = true;
            }
            basketplaceorder.editDisable = false;
            basketplaceorder.isButtonDisable = false;
            basketplaceorder.detailedBaskets = data['scripts'];
            basketplaceorder.totalAmount = data['totalAmount'];
            basketplaceorder.key = data["expiryKey"];
        }
        else if(code === 521) {
            basketplaceorder.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
        }
        else{
            basketplaceorder.snackBar.open(basketplaceorder.translate.instant('login.wrong'), '', { duration: 3000 });
        }        
        basketplaceorder.isLoading = false;
    }

    getRebalanceResponse(data, code, message)
    {
        basketplaceorder.isLoading = false;
        basketplaceorder.load.emit(basketplaceorder.isLoading);
        if (code === 200 && message === 'OK' && data["scripts"].length > 0)
        {
            for(let i = 0; i < data['scripts'].length; i++){
                basketplaceorder.scripModelArr.push(Utility.getScripModel(data['scripts'][i]['scrip_token'], data['scripts'][i]['exchange_segment'], basketplaceorder.tradingRestService));
            }
            basketplaceorder.basketPositionData = data['scripts'];
            basketplaceorder.key = data["expiryKey"];
        }
        else
            basketplaceorder.isNodata = true;
    }

    onChangeMinimumInvestAmnt(event) {
        const userInputAmnt = Number(event);
        if(userInputAmnt === 0){
            basketplaceorder.snackBar.open(this.translate.instant('placeorder.invalid_price'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
        }
        else if (this.isPlaceOrder && (userInputAmnt < Preferences.getItem('basketData')['min_investment'])) {
            this.cnfrmDisable = true;
            basketplaceorder.snackBar.open(this.translate.instant('basket.invest_amount_limit'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
        }
        else if (this.isExit && (userInputAmnt > basketplaceorder.basketData.marketVal)) {
            basketplaceorder.snackBar.open(this.translate.instant('basket.exit_amount_limit'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
        }
        else {
            this.cnfrmDisable = false;
            this.getBasketDetail();
        }
    }

    onSubmit() {
        if(basketplaceorder.isPlaceOrder){
            if(basketplaceorder.totalAmount > Utility.avl_margin){
                this.lowBalance = true;
                if(!basketplaceorder.isMobile)
                    basketplaceorder.snackBar.open(basketplaceorder.translate.instant('all.less_balance'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
                basketplaceorder.isLoading = false;
                basketplaceorder.isButtonDisable = false;
            }
            else {
                this.lowBalance = false;
                basketplaceorder.disableForm = true;
            }
        }
        else{
            basketplaceorder.disableForm = true;
        }
    }

    onEdit() {
        basketplaceorder.disableForm = false;
    }

    placeBasketOrder(detailedBaskets) {
        let jsonObj = {};
        let type;
        let id;
        let version;
        
       if((this.isSip && this.selectedType === "SIP") || Utility.fromTodaySip) {
            if(Utility.fromTodaySip){
                type = 'INVEST_SIP';
                id = Utility.selectedSipData.basket_id;
                version = Utility.selectedSipData.basket_version;
            } else {
                type = 'CREATE_SIP';
                id = Preferences.getItem('basketData')['id'];
                version = Preferences.getItem('DetailedBaskets')[0].version;
            }

            if(this.selectedFrequency === 'Weekly'){
                this.selectedDay = (this.sip_day.indexOf(this.selectedDay) + 1).toString();
            }
            jsonObj = {
                basket_id: id,
                basket_version: version,
                placeorder_type: basketplaceorder.orderType,
                key: basketplaceorder.key,
                margin_amt: basketplaceorder.totalAmount, 
                sip_action: type,
                sip_frequency: this.selectedFrequency,
                sip_day: this.selectedDay,
                sip_amount: basketplaceorder.totalAmount
            }

        } else {
            jsonObj = {
                basket_id: basketplaceorder.orderType === 'FirstPurchase' ? Preferences.getItem('basketData')['id'] : basketplaceorder.orderType === 'RebalancePurchase' || 'PartialExitPurchase' ?  basketplaceorder.basketData.basket_id : false,
                basket_version: basketplaceorder.orderType === 'FirstPurchase' ? Preferences.getItem('DetailedBaskets')[0].version : basketplaceorder.orderType === 'RebalancePurchase' || 'PartialExitPurchase' ?  basketplaceorder.basketData.active_version : false,
                placeorder_type: basketplaceorder.orderType,
                key: basketplaceorder.key,
                margin_amt: basketplaceorder.orderType === 'FirstPurchase' || 'ExitPurchase' ? basketplaceorder.totalAmount : 0,  
                am: this.amo
            }
        }

        if(Utility.fromTodaySip) {
            jsonObj['sip_id'] = Utility.selectedSipData.id;
        }

        basketplaceorder.isLoading = true;
        basketplaceorder.isButtonDisable = true;
        this.balance = Utility.avl_margin;
        if(basketplaceorder.isPlaceOrder){
           this.PS.getThematicBasketService().placeBasketOrder(jsonObj, {'basketOrderType': 'FirstPurchase'}, basketplaceorder.placeBasketOrderResponse);
        }
        else {
            this.PS.getThematicBasketService().placeBasketOrder(jsonObj, {'basketOrderType': "RebalancePurchase"}, basketplaceorder.placeBasketOrderResponse);
        }
        
    }

    placeBasketOrderResponse(data, code, message)
    {
        let ordrData = data['ordrData'];
        Utility.fromTodaySip = false;
        if (code === 200 && message === 'OK')
        { 
            basketplaceorder.isNodata = false;
            Preferences.setItem('basketPlaceOrderData', ordrData);
            Utility.ORDER_PLACED_EVENT.next('true');
            if(basketplaceorder.isSip) {
                for(let i = 0; i < ordrData.length; i++) {
                    if(ordrData[i].status == 'complete') {
                        Utility.callBasketSip.next();
                        break;
                    }
                }   
            }

            let obj = {
                'sip_frequency': basketplaceorder.selectedFrequency,
                'sip_day': basketplaceorder.selectedDay,
                'sip_amount': basketplaceorder.totalAmount,
                'next_trigger_date': data['next_trigger_date'],
                'basket_id': ordrData[0]['basket_id'],
                'basket_name': Preferences.getItem('basketData')['name']
            };
            Utility.placedSipData = {'isSip': basketplaceorder.selectedType, 'data': obj};

            if (Utility.IS_MOBILE) {
                basketplaceorder.router.navigate(['/basketplaceordersummary']);
            } else {
                basketplaceorder.investSummry = true;
            }
        }
        else{
            basketplaceorder.isNodata = true;
            basketplaceorder.msg = message;
          //  Utility.fromTodaySip = true;

        }
        basketplaceorder.isLoading = false;
    }

    enable(){     
        this.toggle = false;
        basketplaceorder.isButtonDisable = true;
        setTimeout(()=>{
            this.focusElement.nativeElement.focus();
            if(this.investmentAmount === 0)
                this.investmentAmount = null;
        },0);
    }

    onAMO(ev){
        if (ev.checked)
            this.amo = 'YES';
        else
            this.amo = 'NO';
    }

    checkQty(value){
        if(value === "I"){
            basketplaceorder.isDisabledPlus = true;
            this.qty++;
            if(this.qty >= 1 ){
                this.totalToInvest = this.originalamount * this.qty;
            }
        }
        else if(value === "D"){
            basketplaceorder.isDisabledMinus = true;
            this.qty--;
            this.totalToInvest = this.totalToInvest - this.originalamount;
        }
        else{
            if(this.qty >=1){
                this.totalToInvest = this.originalamount * this.qty;
            }
        }
        this.isLot = (this.qty >=1) ? false : true;
        if((value.which >=48 && value.which <= 57) || value.which == 8 || value === "D" || value === "I")
            this.getBasketDetail();
    }
    selectTab(value){
        if(!this.toggle) {
            this.investmentAmount = this.originalamount
        }
        if(value === "Amount"){
            this.isLot = false;
            this.selectedTab = "Amount";
        }    
        else{
            if(this.qty === 0 || this.qty === null)
                this.isLot = true;

            this.selectedTab = "Lot";
        }
        this.getBasketDetail();  
    }

    selectOption(value){
        if(value === "Fully Exit"){
            this.selectedOption = "Fully Exit";
            this.cnfrmFlag = false;
            this.isButtonDisable = false;
        }    
        else{
            if(!this.toggle) {
                basketplaceorder.investmentAmount = 0;
                this.toggle = true; 
            }
            else {
                this.selectedOption = "Partial Exit";
                basketplaceorder.orderType = "PartialExitPurchase"
                if(!(basketplaceorder.investmentAmount < 1))
                    this.getBasketDetail();
            } 
        }
    }

    selectType(value){
        if(value === "OneTime"){
            this.selectedType = "OneTime";
        }    
        else{
            this.selectedType = "SIP";
            this.sip_data = JSON.parse(Preferences.getItem('basketData').sip_types);
            this.sip_data.forEach(element=>{
                this.sip_frequency.push(element.type);
            });
            this.selectedFrequency = this.sip_frequency[0];
            this.SelectSipData();
        }  
    }

    SelectSipData(){
        this.sip_day = [];
        this.sip_data.forEach(element=>{
            if(element.type === this.selectedFrequency) {
                if(element.day !== "any") {
                    this.sip_day = element.day.split(",");
                } else {
                    if(this.selectedFrequency === 'Weekly'){
                        this.sip_day = [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
                    } else {
                        for(let i=1; i<=28; i++) {
                            this.sip_day.push(i.toString());
                        }
                    }
                }
            }
        });
        this.selectedDay = this.sip_day[0];
    }

    validateQty(ev) {
        const regex = new RegExp('^[0-9]{0,7}$|^[0-9]{1,' + 2 + '}$');
        const val = this.inputQty.nativeElement.value;
        const index = ev.target.selectionStart;
        if (!val.match(regex)) {
          this.validateKey(ev.key)
          ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
          this.inputQty.nativeElement.dispatchEvent(new Event('input'));
          this.inputQty.nativeElement.setSelectionRange(index - 1, index - 1);
        }
    }

    validateKey(key) {
        if (/[a-zA-Z]/i.test(key))
            this.snackBar.open(this.translate.instant('all.only_digits'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }

    openoverlay(){
        if (this.isMobile) {
            let url = 'addfunds';
            this.router.navigate([url], { queryParams: { bal: this.balance} });
        } else {
            Utility.dialogRef.close();
            var obj = {'btnindex': 1, 'bal': this.balance, 'overlayFrom':'basketPO'};
            var jsonObj = {"inputs":obj, "name":"addFunds"};
            Utility.InputsDataGet.next(jsonObj);
        }
    }

    fullyExit(){
        basketplaceorder.isLoading = true;
        basketplaceorder.isButtonDisable = true;
        let data = {
            "basket_id": Utility.basketPositionData.basket_id,
            "basket_version": Utility.basketPositionData.active_version,
            "placeorder_type": "ExitPurchase"
        }

        this.PS.getThematicBasketService().getExitOrdersData(data,  function(resdata, code, message) {
            if (code === 200)
            {
                basketplaceorder.snackBar.open(this.translate.instant('books.order_exited'), 'Close', { duration: 2000, panelClass: ['successSnackBar'] });
                if(this.isMobile) {
                    this.location.back();
                    this.location.back();
                    Utility.SELECTED_BASKET_TAB = 1;
                } else {
                    Utility.dialogRef.close();
                    Utility.callInvestorBaskets.next(); 
                }
                basketplaceorder.isButtonDisable = false;  
            }
            else {
                basketplaceorder.snackBar.open(this.translate.instant('books.order_exit_failed'), 'Close', { duration: 2000, panelClass: ['errorSnackBar'] });
                basketplaceorder.isButtonDisable = false;  
                Utility.dialogRef.close();
            }
            basketplaceorder.isLoading = false;
        }.bind(this));
    }
    backToSip(){
        // if(Utility.IS_MOBILE)
        //     this.location.back();
        // else {
        //     Utility.dialogRef.close();
        //     Utility.openPage(this.router, 'control', 'todayssip', UsertodayssipComponent, this.dialog, 'popup');
        // }
    }
}
