<div class="container">
    <div class="desktop-login feedbackbg"></div>
    <div *ngIf = "!isCustomDashboard" class="row">
        <div class="col-10 py-1">
            <button mat-icon-button (click)="back()" class="float-left mt-2"><i class="icon-goback"></i></button>
            <img class="mt-2" src="assets/img/app-name.svg" alt="hypertrade" width="200"/>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6"><iframe (load) = onLoad() id = "feedback" src="/assets/feedback/feedback.html"></iframe></div>
        <div class="login-footer">
            <div class="row">
                <div class="col-2 col-sm-1 text-right"><img src="assets/img/app-name_bw.svg" alt="hypertrade"/></div>
                <div class="col-10 con-sm-11 tc-4">
                <small class="tc-2">version - <span [matTooltip] = "version">{{dis_version}}</span></small><br>
                NSE - SEBI Registration no.: {{SEBIRNO}}  
                <!-- NSE & BSE - SEBI Registration no.: {{SEBIRNO}}  I   MCX - SEBI Registration no.: ind000021078  I   CDSL - SEBI Registration no.: IND000021078 more info -->
                </div>
            </div>
        </div>
    </div>
</div>
