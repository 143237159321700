import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ScripModel } from 'src/app/model/scrip.model';
import { ChannelType, Utility } from 'src/app/services/utility';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { IScripService } from 'src/app/services/scrip/iscrip.service';
import { HoldingModel } from 'src/app/model/holding.model';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { Subscription } from 'rxjs';
let pmo: PortfolioMoreOptionComponent;
@Component({
    selector: 'app-portfoliomoreoption',
    templateUrl: '../../../../'+environment.baseviewpath+'/home/portfolio/portfoliomoreoption/portfoliomoreoption.component.html',
    styleUrls: []
})

export class PortfolioMoreOptionComponent implements OnInit, OnDestroy{
    holdingDetailData: any;
    isSquareOFFNSE: boolean;
    scrpModel: ScripModel;
    scripService: IScripService;
    isSquareOFFBSE: boolean;
    isAvgPriceEditable: boolean;
    WlArr = [];
    subscription: Subscription;
    iseditTrue: boolean;
    isEdisEnable = environment.isEdis;
    flashType = 'defaultFlash';
    constructor(private _bottomSheetRef: MatBottomSheetRef<PortfolioMoreOptionComponent>, private router: Router, private tradingRestService: TradingRestService, private snackBar: MatSnackBar,
         private translate: TranslateService, private detector: ChangeDetectorRef) {
        pmo = this;
        pmo.isAvgPriceEditable = environment.isHoldingPriceEditable;
        pmo.scripService = tradingRestService.getScripService();
        pmo.holdingDetailData = Utility.holdingDetails;
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
            this.flashType = Preferences.getItem('flashType');
        }
        Utility.flashType.subscribe(obj => {
            this.flashType = obj;
        });
        if (pmo.holdingDetailData.Exch1) {
            pmo.isSquareOFFNSE = true;
        }
        if (pmo.holdingDetailData.Exch2) {
            pmo.isSquareOFFBSE = true;
        }
        pmo.scrpModel = pmo.holdingDetailData.scripModel;
        pmo.subscription = pmo.scrpModel.scripUpdate.subscribe(ele =>{
            pmo.detector.markForCheck();
        });
    }
    openLink(): void {
        pmo._bottomSheetRef.dismiss();
    }
   
    addWLdata(stock: { ExchangeSeg: string; Token: string; }) {
        const seltctedStock = stock.ExchangeSeg + '|' + stock.Token;
        this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
      }
    addStock(wl: any, stockdata: { ExchangeSeg: string; Token: string; }) {
        const seltctedStock = stockdata.ExchangeSeg + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result: any, msg: string) {
          if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
    }

    handleButton(buttonType: any) {
        let data_obj = {};
        switch (buttonType) {
            case 'NSE':
                data_obj = { 'isSquareOff': true, 'exseg': 'nse_cm', 'action': 'S' };
                pmo.router.navigate(['placeorder'], { queryParams: data_obj });
                break;
            case 'BSE':
                data_obj = { 'isSquareOff': true, 'exseg': 'bse_cm', 'action': 'S' };
                pmo.router.navigate(['placeorder'], { queryParams: data_obj });
                break;
            case 'ADD':
                const data_obj1 = { 'add': true, 'Exchange': pmo.holdingDetailData.Exchange, 'Token': pmo.holdingDetailData.Token, 'OrderType': 'L', 'ProductCode': pmo.holdingDetailData.Pcode };
                pmo.router.navigate(['placeorder'], { queryParams: data_obj1 });
                break;
            case 'REMOVE':
                let data_obj2;
                if(environment.baseviewpath === 'investor'){
                    data_obj2= { 'remove': true, 'Exchange': pmo.holdingDetailData.Exchange, 'Token': pmo.holdingDetailData.Token, 'OrderType': 'L', 'ProductCode': pmo.holdingDetailData.Pcode, 'qty': 1};
                } else {
                    data_obj2= { 'remove': true, 'Exchange': pmo.holdingDetailData.Exchange, 'Token': pmo.holdingDetailData.Token, 'OrderType': 'L', 'ProductCode': pmo.holdingDetailData.Pcode, 'qty': pmo.holdingDetailData.AvailableQty };
                }
                const edisCheck =  this.isEdisEnable ? Utility.edisAuthorizationCheck(pmo.holdingDetailData.Pcode, (pmo.holdingDetailData.TotalHoldingQty - pmo.holdingDetailData.Usedqty), 'S', pmo.holdingDetailData.Token, pmo.holdingDetailData.ExchangeSeg, pmo.holdingDetailData.Usedqty) : true;
                if (edisCheck) {
                    pmo.router.navigate(['placeorder'], { queryParams: data_obj2 });    
                }
                else{
                    Utility.edisAuthorize(Utility.holdings, window.location.hostname, this.tradingRestService);
                }
                break;
            case 'sd':
                Utility.openStockDetail(this.router, this.holdingDetailData.Token, this.holdingDetailData.ExchangeSeg);
                break;
        }
        Utility.selectedPortfolioTab = (environment.baseviewpath === 'investor') ? 0 : 1; // Select Holding Tab On Back
        pmo.openLink();
    }

    _keyPress(e: any) {
        let input: string;
        if (e.metaKey || e.ctrlKey) {
          return true;
        }
        if (e.which === 32) {
         return false;
        }
        if (e.which === 0) {
         return true;
        }
        if (e.which < 33) {
          return true;
        }
        if(e.which  === 46) {
            return true;
          }
        input = String.fromCharCode(e.which);
        return !!/[\d\s]/.test(input);
      }

    onPriceInputFocus(model: HoldingModel, inputField: any, id: any) {
        model.isSelected = true;
        setTimeout(() => {
            inputField.select(model.Price);
        }, 0);
    }

    changeAvgPrice(model: HoldingModel, price: any) {
        this.iseditTrue = false;
        model.isSelected = false;
        Utility.holdingAvgPriceChanged.next({'model': model, 'price': price});
    }

    onEditIconClick(id: any, model: HoldingModel){ 
        model.isSelected = true;
        this.iseditTrue = true;
        setTimeout(() => {
            document.getElementById(id).focus();
        }, 0);
    }

    ngOnInit() {
    }

    ngOnDestroy()
    {
        if (pmo.subscription)
            pmo.subscription.unsubscribe();
    }

    onSwipeDown() {
        this._bottomSheetRef.dismiss();
    }
}
