<ng-container *ngIf = "!isNoData">
    <div class="rightcon pr-0">
        <div class="row tab123">
            <div class="col-12">
                <div *ngFor='let data of basketPositionData'>
                    <div class="watchlistwrapper">
                        <div class="row">
                            <div class ="col-12">
                                <div class="row">
                                    <div class="col-6">
                                        <label class="tc-1 ts-4 line2">{{data.trdSym | symbolpipe}}</label><br>
                                        <label class="line2">{{'basket.invst_val' | translate}}:&nbsp;</label><span>{{(data.buy_avg_price*data.net_qty) | pricepipe}}</span>
                                    </div>
                                    <div class="col-6 text-right">
                                        <label class="line2">{{'portfolio.qty' | translate}}.:&nbsp;</label>{{data.net_qty | numberpipe}}
                                        <br>
                                        <label class="line2">{{'basket.curr_val' | translate}}: </label><span [ngClass]="{'traded-status': data.pnl > 0, 'rejected-status': data.pnl < 0}">{{(data.ltp*data.net_qty) | pricepipe}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footerbtn fixatbottom" *ngIf = "isMobile">
        <button class="w-50 btn-primary no-radius" [disabled]='!rebalanceAvl' mat-flat-button (click)="rebalanceBasket()"><span translate>{{'basket.rebalance' | translate}}</span></button>
        <button class="w-50 btn-negative no-radius" mat-flat-button (click)="exitBasket()"><span translate>{{'books.exit' | translate}}</span></button>
    </div>

    <div class="footerbtn fixatbottom" *ngIf = "!isMobile">
        <div class="row m-0">
            <div class="col-6 px-0">
                <button class="w-100 btn-primary no-radius" [disabled]='!rebalanceAvl' mat-flat-button (click)="rebalanceBasket()"
                ><span translate>{{'basket.rebalance' | translate}}</span></button>
              </div>
              <div class="col-6 px-0" >
                <button class="w-100 btn-negative no-radius" mat-flat-button (click)="exitBasket()"
                ><span translate>{{'books.exit' | translate}}</span></button>
              </div>
        </div>
    </div>

</ng-container>
<ng-container *ngIf='isNoData' class='nodata'>
    <app-nodata message = "N0 DATA"></app-nodata>
</ng-container>