<app-header *ngIf="isMobile" goback="1" [title]="'all.order_history' | translate" [isLoading]="isLoading" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="!isMobile && showtitle" [title]="'all.order_history' | translate" [isLoading]="isLoading"></app-titlebar>
<mat-card [class.webpanelnew]="!isMobile">
    <mat-card-content>
        <div [class.pt-0]="!isMobile" [class.data-container]="isMobile">
            <div [class.px-3]="!isMobile">
				<div class="watchlistwrapper">
					<div class="row">
						<div class="col-7">
							<div class="firstline">{{detailedOrderData.TradeSymbol}} <small>{{detailedOrderData.Exchange}}</small></div>
							<div class="line2 subcolor">{{detailedOrderData.SymbolName}}</div>
						</div>
						<div [class.imnegative]="scripModel.Change<0" [class.impositive]="scripModel.Change>0" class="col-5 text-right">
							<div [class.imnegativebg]="scripModel.TickDirection === -1" [class.impositivebg]="scripModel.TickDirection === 1">
								<div class="firstline">{{scripModel.LastTradePrice | pricepipe:scripModel.DecimalPrecision}}</div>
								<div class="line2">{{scripModel.Change | pricepipe:scripModel.DecimalPrecision}} ({{scripModel.PercentageChange | percentagepipe}})</div>
							</div>
						</div>
						<div class="col-5 text-right">
						</div>
					</div>
				</div>
				<div class="row watchlistwrapper">
                    <div class="col-7 line2" translate>all.fill_total</div>
					<div class="col-5 text-right firstline">{{fillQuantity | numberpipe}}/{{detailedOrderData.TotalQty | numberpipe}}</div>
					<div class="col-7 line2" translate>all.prc</div>
                    <div class="col-5 text-right text-red firstline">{{detailedOrderData.Price | pricepipe:scripModel.DecimalPrecision}}</div>
				</div>
				<div *ngIf="!isLoading && !isNoData">
					<div *ngFor="let data of orderHistoryList;">
						<div class="watchlistwrapper">
							<div class="row">
								<div class="col-7 line2">{{data.Status}}</div>
								<div class="col-5 text-right firstline">{{data.FilledSharesQuantity | numberpipe}}/{{data.Qty | numberpipe}}<span class="pl-1" translate>all.qty</span></div>
								<div class="col-7 line2">{{data.ExchTimeStamp | datepipe:'mediumtime'}}</div>
								<div class="col-5 text-right text-red firstline">{{data.Prc | pricepipe:scripModel.DecimalPrecision}}</div>
								<div class="col-12" [class.mt-1] = "data.rejectionreason">  {{data.rejectionreason}}</div>
							</div>
						</div>
					</div>
				</div>			
            </div>
		</div>
	</mat-card-content>
</mat-card>
<mat-dialog-actions class="mat-dialog-actions" [class.mda-d-block] = "isMobile">
    <div class="row">
		<div *ngIf="!isMobile" class="col-12 text-right">
			<button *ngIf="om.IsCancel && !isLoading && !isNoData" mat-flat-button  class = "m-2 custom-btn btn-primary" (click)="cancelOpenOrder()"><span translate>all.cancel</span></button>
			<button *ngIf="om.IsExit && !isLoading && !isNoData" mat-flat-button  class = "m-2 custom-btn btn-primary" (click)="exitOrder()"><span translate>all.exit</span></button>
			<button *ngIf='om.IsEdit && !isLoading && !isNoData' mat-flat-button class = "m-2 custom-btn btn-primary" (click)='onModify()'><span translate>all.modify</span></button>
		</div>
        <div class="col-6 p-action"  *ngIf="isMobile && om.IsCancel && !isLoading && !isNoData">
            <button mat-flat-button class = "w-100 custom-btn btn-primary" (click)="cancelOpenOrder()"><span translate>all.cancel</span></button>
		</div>
		<div class="col-6 text-right p-action"  *ngIf="isMobile && om.IsExit && !isLoading && !isNoData">
            <button mat-flat-button class = "w-100 custom-btn btn-primary" (click)="exitOrder()"><span translate>all.exit</span></button>
		</div>
		<div class="col-6 p-action"  *ngIf='isMobile && om.IsEdit && !isLoading && !isNoData'>
			<button mat-flat-button  class = "w-100 custom-btn btn-primary" (click)='onModify()'><span translate>all.modify</span></button>
		</div>
    </div>
</mat-dialog-actions>
