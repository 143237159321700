import {CanActivate, Router} from '@angular/router';
import { Injectable } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { Preferences } from 'src/app/services/Preferences';

@Injectable()
export class CanActivateGuard implements CanActivate {
    canActivate() {
        const isLoggedIn = Preferences.getPreference('LoggedIn');
        if (isLoggedIn) {
            return true;
        }
        return false;
    }
}