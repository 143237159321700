<app-header *ngIf="isMobile" goback="1" [title]="'all.create_eman' | translate" [isLoading]="isLoading"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'all.create_eman' | translate" [isLoading]="isLoading"></app-titlebar>
<mat-dialog-content [class.p-0]="!isMobile">
    <div class="buy placeorderwrapper" [class.narrowpadding]="!isMobile" [class.mt-5]="isMobile">
        <div class="row">   
            <div class="col-12" [class.px-2]="isMobile">   
                <div class="row" [class.mx-1]="!isMobile">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>all.name</mat-label>
                            <input id="name" matInput type="text" [(ngModel)]="name" autocomplete="off" [readonly]="true">
                        </mat-form-field>
                    </div> 
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>all.mobile</mat-label>
                            <input id="mobile" matInput type="text" [ngModel]="mobile | maskedvaluepipe" (ngModelChange)="mobile=$event" autocomplete="off" [readonly]="true">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" [class.mx-1]="!isMobile">
                    <div class="col-12">
                        <mat-form-field>
                            <mat-label translate>all.email_id</mat-label>
                            <input id="email" matInput type="email" [ngModel]="email | maskedvaluepipe : 'mail'" (ngModelChange)="email=$event" autocomplete="off" [readonly]="true">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" [class.mx-1]="!isMobile">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>all.acc_no</mat-label>
                            <mat-select [(ngModel)]="selectedActNo" (ngModelChange)="selectIfscCode()">
                                <mat-option *ngFor="let data of bankDetail" [value]="data.bnkActNo">{{data.bnkActNo | maskedvaluepipe}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>all.ifsc</mat-label>
                            <input id="ifsc" matInput type="text" [ngModel]="ifscCode  | maskedvaluepipe" (ngModelChange)="ifscCode=$event" autocomplete="off" [readonly]="true">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" [class.mx-1]="!isMobile">
                    <div [class.col-6]="!isMobile" [class.col-12]="isMobile">
                        <mat-form-field>
                            <mat-label translate>all.acc_type</mat-label>
                            <mat-select [(ngModel)]="selectedActTyp">
                                <mat-option *ngFor="let data of objectKeys(accountTyp)" [value]="data">{{data}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" [class.mx-1]="!isMobile">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>all.startdate</mat-label>
                            <input id="startdate" matInput type="text" [ngModel]="startDate | datepipe: 'date'" (ngModelChange)="startDate=$event" autocomplete="off" [readonly]="true">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label translate>all.frequency</mat-label>
                            <input id="frequency" matInput type="text" [(ngModel)]="frequency" autocomplete="off" [readonly]="true">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" [class.mx-1]="!isMobile">
                    <div [class.col-6]="!isMobile" [class.col-12]="isMobile">
                        <mat-form-field>
                            <mat-label class="d-flex" translate>all.amount <span>({{'all.prcntof_sip' | translate}})</span></mat-label>
                            <input id="amount" matInput type="number" [(ngModel)]="amount" autocomplete="off" (input)="validatePrice($event)">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions d-block" [class.mat-action-web]="!isMobile">
    <div class="text-right" [class.footerbtn]="isMobile" [class.padd]="!isMobile">
        <button class="btn-primary" [class.w-100]="isMobile" mat-flat-button (click)='checkValidation()'><span>{{'all.create_eman' | translate}}</span></button>
    </div>              
</mat-dialog-actions>
