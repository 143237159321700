import { environment } from "src/environments/environment";

export class PlaceorderSettingsModel
{
    // priceType: string = 'LTP';
    orderType: string = environment.baseviewpath === 'investor' ? 'MKT' : 'L';
    productType: string = 'CNC';

    SqrOffPercentage: string = '5';
    stoplossPercentage: string = '2';
    onetouch = 'false';
}