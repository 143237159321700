import { Component, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { ChannelType, Utility } from 'src/app/services/utility';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-trademoreoption',
    templateUrl: '../../../'+environment.baseviewpath+'/tradebook/trademoreoption/trademoreoption.component.html',
    styleUrls: []
})
export class TradeMoreOptionComponent {
    tradeDetailData: any;
    WlArr = [];
    subscription;
    flashType = 'defaultFlash';
    constructor(private _bottomSheetRef: MatBottomSheetRef<TradeMoreOptionComponent>, private router: Router, private translate: TranslateService, private snackBar: MatSnackBar,
        private tradingRestService: TradingRestService, private detector: ChangeDetectorRef) {
        this.tradeDetailData = Utility.tradeDetail;
        const WLstr = Preferences.getItem('watchlistname', ReturnType.Undefined);
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
            this.flashType = Preferences.getItem('flashType');
          }
        Utility.flashType.subscribe(obj => {
            this.flashType = obj;
        });
        if (WLstr !== null && WLstr !== undefined)
            this.WlArr = WLstr;

        this.subscription = this.tradeDetailData.scripModel.scripUpdate.subscribe(ele =>{
            this.detector.markForCheck();
        });
    }
    openLink(): void {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
        this._bottomSheetRef.dismiss();
    }
    addWLdata(stock) {
        const seltctedStock = stock.Exseg + '|' + stock.Token;
        this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
    }
    addStock(wl, stockdata) {
        const seltctedStock = stockdata.Exseg + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
          if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
    }

    handleButton(buttonType) {
        const addobj = { 'action': 'B', 'exseg': this.tradeDetailData.ExchangeSeg, 'omtkn': this.tradeDetailData.Token};
        const removeobj = { 'action': 'S', 'exseg': this.tradeDetailData.ExchangeSeg, 'omtkn': this.tradeDetailData.Token};

        switch (buttonType) {
            case 'PC':
                this.router.navigate(['/positionconvertion']);
                break;
            case 'ADD':
                this.router.navigate(['placeorder'], { queryParams: addobj });
                break;
            case 'REMOVE':
                this.router.navigate(['placeorder'], { queryParams: removeobj });
                break;
            case 'sd':
                Utility.openStockDetail(this.router, this.tradeDetailData.Token, this.tradeDetailData.Exseg);
                break;
        }
        Utility.selectedBookTab = 1; // Select Trade Tab On Back
        this.openLink();
    }

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onSwipeDown() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
        this._bottomSheetRef.dismiss();
    }
}
