import { Component, EventEmitter, Output, OnChanges, Input, OnInit, ChangeDetectorRef, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';
import { PtPlusRestService } from '../../../services/ptplusrest.service';
import { NumberPipe } from '../../../utility/pipes/numberpipe';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { TableUtility } from 'src/app/services/tableutility';

let financialComponent: FinancialComponent;

@Component({
    selector: 'app-financial',
    templateUrl: './finanacial.component.html',
    styleUrls: ['./financial.component.scss']
})

export class FinancialComponent implements OnChanges, OnInit {
    @Output() loaderEvent = new EventEmitter();
    @Input() omtkn: string;
    @Input() exseg: string;
    @Input() isCustomDashboard: boolean;
    isLoading: boolean;
    bal;
    cas;
    inc;
    public plrtitle: String;
    public plotitle: String;
    public plptitle: String;
    public bsatitle: String;
    public bsntitle: String;
    public bsltitle: String;
    public cfotitle: String;
    public cfititle: String;
    public cfftitle: String;
    public nchartData = [];
    public rchartData = [];
    public opchartData = [];
    public ichartData = [];
    public cochartData = [];
    public cichartData = [];
    public cfchartData = [];
    public bachartData = [];
    public blchartData = [];
    public bnchartData = [];
    finmodel;
    ispl: boolean;
    ispli: boolean;
    isbs: boolean;
    isbsi: boolean;
    iscf: boolean;
    iscfi: boolean;
    eventValue = 1;
    pldatasource = [];
    bsdatasource = [];
    cfdatasource = [];
    element;
    style;
    iconcolor;
    plshow: boolean;
    bsshow: boolean;
    cfshow: boolean;
    selectedVal;
    isProfitLoss;
    isBalanceSheet;
    isCashFlow;
    index;
    eventLabel;
    isChart;
    isNoData;
    displayedColumns = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh'];
    finFilters: string[];
    chartLabels;
    chartData = [];
    chartType;
    ChartOptions = {};
    plChartOptions = {};
    plChartOptions1 = {};
    plChartOptions2 = {};
    bsChartOptions = {};
    bsChartOptions1 = {};
    bsChartOptions2 = {};
    cfChartOptions = {};
    cfChartOptions1 = {};
    cfChartOptions2 = {};
    colors = [];
    dateOptions;
    chartPlugins;
    Height = {};
    container?: ComponentContainer;
    modesub: Subscription;

    constructor(private injector: Injector, private detector: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private ptPlusRestService: PtPlusRestService, private translate: TranslateService) {
        financialComponent = this;
        if (!Utility.IS_MOBILE) {
            financialComponent.container = <ComponentContainer>financialComponent.injector.get('Container');
        }
        financialComponent.translate.get(['stock_detail.profit_loss', 'stock_detail.bal_sheet', 'stock_detail.cash_flow']).subscribe(
            values => {
                financialComponent.finFilters = Object.keys(values).map(key => values[key]);
            }
        );
        financialComponent.plshow = true;
        financialComponent.bsshow = true;
        financialComponent.cfshow = true;
        financialComponent.selectedVal = financialComponent.finFilters[0];
        financialComponent.isProfitLoss = true;
        financialComponent.plrtitle = financialComponent.translate.instant('stock_detail.revenue(value_in_cr.)');
        financialComponent.plotitle = financialComponent.translate.instant('stock_detail.operating_profit(value_in_cr.)');
        financialComponent.plptitle = financialComponent.translate.instant('stock_detail.profit_after_tax(value_in_cr.)');
        financialComponent.bsatitle = financialComponent.translate.instant('stock_detail.assets(value_in_cr.)');
        financialComponent.bsntitle = financialComponent.translate.instant('stock_detail.net_worth(value_in_cr.)');
        financialComponent.bsltitle = financialComponent.translate.instant('stock_detail.liabilities(value_in_cr.)');
        financialComponent.cfotitle = financialComponent.translate.instant('stock_detail.operating(value_in_cr.)');
        financialComponent.cfititle = financialComponent.translate.instant('stock_detail.investing(value_in_cr.)');
        financialComponent.cfftitle = financialComponent.translate.instant('stock_detail.financing(value_in_cr.)');
        financialComponent.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            financialComponent.changeMode();
        });
    }

    changeMode(){
        financialComponent.Height = {
            'height': (financialComponent.container.height - TableUtility.FINHeight) + 'px',
            'overflow': 'auto'
        };

        financialComponent.detector.detectChanges();
    }

    ngOnInit() {
        if (financialComponent.isCustomDashboard) {
            financialComponent.loadFinancialData();
        }
        if (financialComponent.isCustomDashboard) {
            financialComponent.changeMode();
            /*financialComponent.container.on('resize', _ => {
              financialComponent.changeMode();
            });*/
          }    }
    ngOnChanges() {
        financialComponent.loadFinancialData();
    }
    loadFinancialData() {
        financialComponent.loaderEvent.emit(true);
        financialComponent.ptPlusRestService.getFundamentalsService().getFinancialData(financialComponent.omtkn, financialComponent.exseg, 'omnisys', (code, resData, message) => {
            financialComponent.loaderEvent.emit(false);
            if (code === 200 && Object.keys(resData).length > 0) {
                financialComponent.isNoData = false;
                financialComponent.finmodel = resData;
                setTimeout(() => {
                    financialComponent.loadData();
                    financialComponent.loadChart();
                }, 300);
            }
            else
                financialComponent.isNoData = true;
        });
    }

    loadData() {
        financialComponent.loadpldata(financialComponent.eventValue);
        financialComponent.pldatasource = financialComponent.loadpltabledata();
    }


    finChange($event: MatRadioChange) {
        financialComponent.eventValue = $event.value;
        if ($event.value === '2')
            financialComponent.dateOptions = { month: 'short', year: 'numeric' };
        else
            financialComponent.dateOptions = { year: 'numeric' };
        switch (financialComponent.index) {
            case 0: financialComponent.loadpldata($event.value);
                financialComponent.pldatasource = financialComponent.loadpltabledata();
                break;
            case 1: financialComponent.loadbsdata($event.value);
                financialComponent.bsdatasource = financialComponent.loadbstabledata();
                break;
            case 3: financialComponent.loadcfdata($event.value);
                financialComponent.cfdatasource = financialComponent.loadcftabledata();
                break;
        }

    }

    onSelection($event) {
        financialComponent.eventLabel = $event.value;
        if ($event.value === financialComponent.translate.instant('stock_detail.profit_loss')) {
            financialComponent.isProfitLoss = true;
            financialComponent.isBalanceSheet = false;
            financialComponent.isCashFlow = false;
            financialComponent.loadpldata(financialComponent.eventValue);
            financialComponent.pldatasource = financialComponent.loadpltabledata();
        }
        else if ($event.value === financialComponent.translate.instant('stock_detail.bal_sheet')) {
            financialComponent.isBalanceSheet = true;
            financialComponent.isProfitLoss = false;
            financialComponent.isCashFlow = false;
            financialComponent.loadbsdata(financialComponent.eventValue);
            financialComponent.bsdatasource = financialComponent.loadbstabledata();
        }
        else {
            financialComponent.isCashFlow = true;
            financialComponent.isProfitLoss = false;
            financialComponent.isBalanceSheet = false;
            financialComponent.loadcfdata(financialComponent.eventValue);
            financialComponent.cfdatasource = financialComponent.loadcftabledata();
        }
    }

    toggle() {
        financialComponent.isChart = !financialComponent.isChart;
        if (financialComponent.eventLabel === financialComponent.translate.instant('stock_detail.cash_flow'))
            financialComponent.cfshow = !financialComponent.cfshow;
        else if (financialComponent.eventLabel === financialComponent.translate.instant('stock_detail.bal_sheet'))
            financialComponent.bsshow = !financialComponent.bsshow;
        else
            financialComponent.plshow = !financialComponent.plshow;
    }

    loadChart() {
        financialComponent.element = document.querySelector('.chart-color2');
        financialComponent.style = getComputedStyle(financialComponent.element);
        financialComponent.iconcolor = financialComponent.style.color;
        financialComponent.chartType = 'bar';
        financialComponent.dateOptions = { year: 'numeric' };
        financialComponent.chartPlugins = [pluginDataLabels];
        financialComponent.plChartOptions = financialComponent.getChartOptions(financialComponent.plrtitle);
        financialComponent.plChartOptions1 = financialComponent.getChartOptions(financialComponent.plotitle);
        financialComponent.plChartOptions2 = financialComponent.getChartOptions(financialComponent.plptitle);
        financialComponent.bsChartOptions = financialComponent.getChartOptions(financialComponent.bsatitle);
        financialComponent.bsChartOptions1 = financialComponent.getChartOptions(financialComponent.bsntitle);
        financialComponent.bsChartOptions2 = financialComponent.getChartOptions(financialComponent.bsltitle);
        financialComponent.cfChartOptions = financialComponent.getChartOptions(financialComponent.cfotitle);
        financialComponent.cfChartOptions1 = financialComponent.getChartOptions(financialComponent.cfititle);
        financialComponent.cfChartOptions2 = financialComponent.getChartOptions(financialComponent.cfftitle);
        financialComponent.colors = [{ backgroundColor: financialComponent.style.fill }];
    }

    getChartOptions(title) {
        financialComponent.ChartOptions = {
            layout: { padding: { left: 16, right: 16, top: 16, bottom: 16 } },
            responsive: true,
            maintainAspectRatio: false,
            legend: { display: false },
            title: { text: title, fontColor: financialComponent.style.color, display: true, padding: 16 },
            scales: {
                xAxes: [{
                    ticks: {
                        source: 'data', maxRotation: 0, fontColor: financialComponent.style.color,
                        callback: function (value) {
                            return new Date(value).toLocaleDateString('en-DE', financialComponent.dateOptions);
                        },
                    },
                    gridLines: { display: false },
                    categoryPercentage: 0.5,
                    barPercentage: 0.8
                }],
                yAxes: [{ ticks: { display: false }, gridLines: { lineWidth: 0 , zeroLineColor: financialComponent.style.color } }]
            },
            plugins: {
                datalabels: {
                    formatter: (value, context) => {
                        const numberpipe = new NumberPipe();
                        return numberpipe.transform(value);
                    },
                    anchor: 'end', align: 'end', font: { size: 10 }, color: financialComponent.style.color
                },
                crosshair: false
            },
            tooltips: {
                bodyFontColor: financialComponent.style.color,
                callbacks: {
                    title: function (tooltipItems, data) {
                        return '';
                    },
                    label: function (tooltipItem, data) {
                        const numberpipe = new NumberPipe();
                        const label = data.labels[tooltipItem.index];
                        return numberpipe.transform(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) + ' Cr.';
                    }
                }
            }
        };
        return financialComponent.ChartOptions;
    }

    loadpltabledata() {
        financialComponent.pldatasource = [];
        const profitloss = [];
        for (let i = 0; i < financialComponent.inc.length; i++) {
            const plobj = {};
            for (let k = 0; k < financialComponent.displayedColumns.length; k++) {
                plobj[financialComponent.displayedColumns[k]] = financialComponent.inc[i][k];
            }

            profitloss.push(plobj);
        }
        return profitloss;
    }

    loadbstabledata() {
        financialComponent.bsdatasource = [];
        const balancesheet = [];
        for (let i = 0; i < financialComponent.bal.length; i++) {
            const bsobj = {};
            for (let k = 0; k < financialComponent.displayedColumns.length; k++) {
                bsobj[financialComponent.displayedColumns[k]] = financialComponent.bal[i][k];
            }

            balancesheet.push(bsobj);
        }
        return balancesheet;
    }

    loadcftabledata() {
        financialComponent.cfdatasource = [];
        const cashflow = [];
        for (let i = 0; i < financialComponent.cas.length; i++) {
            const cfobj = {};
            for (let k = 0; k < financialComponent.displayedColumns.length; k++) {
                cfobj[financialComponent.displayedColumns[k]] = financialComponent.cas[i][k];
            }

            cashflow.push(cfobj);
        }
        return cashflow;
    }

    loadbsdata(eventValue) {
        financialComponent.index = 1;
        financialComponent.bachartData = [];
        financialComponent.blchartData = [];
        financialComponent.bnchartData = [];
        const assets = [];
        const liability = [];
        const year = [];
        if (eventValue === '2') {
            financialComponent.isbsi = true;
            financialComponent.isbs = false;
            financialComponent.bal = financialComponent.finmodel.data.BAL.Interim;
        }
        else {
            financialComponent.isbs = true;
            financialComponent.isbsi = false;
            financialComponent.bal = financialComponent.finmodel.data.BAL.Annual;
        }
        for (let i = 0; i < financialComponent.bal.length; i++) {
            for (let j = 0; j < financialComponent.bal[i].length; j++) {
                if (financialComponent.bal[i][j] === 'Total Assets') {
                    assets.push(financialComponent.bal[i]);
                    break;
                }
                if (financialComponent.bal[i][j] === 'Total Liabilities') {
                    liability.push(financialComponent.bal[i]);
                    break;
                }
                if (financialComponent.bal[i][j] === 'Period End Date:') {
                    year.push(financialComponent.bal[i]);
                    break;
                }
            }
        }
        financialComponent.bachartData = financialComponent.loadgeneraldata(assets, year);
        financialComponent.blchartData = financialComponent.loadgeneraldata(liability, year);
        financialComponent.bnchartData = financialComponent.loadnetworthdata(assets, liability, year);
    }

    loadpldata(eventValue) {
        financialComponent.index = 0;
        financialComponent.rchartData = [];
        financialComponent.opchartData = [];
        financialComponent.ichartData = [];
        const revenue = [];
        const year = [];
        const operating = [];
        const income = [];
        if (eventValue === '2') {
            financialComponent.ispli = true;
            financialComponent.ispl = false;
            financialComponent.inc = financialComponent.finmodel.data.INC.Interim;
        }
        else {
            financialComponent.ispl = true;
            financialComponent.ispli = false;
            financialComponent.inc = financialComponent.finmodel.data.INC.Annual;
        }
        for (let i = 0; i < financialComponent.inc.length; i++) {
            for (let j = 0; j < financialComponent.inc[i].length; j++) {
                if (financialComponent.inc[i][j] === 'Total Revenue') {
                    revenue.push(financialComponent.inc[i]);
                    break;
                }
                if (financialComponent.inc[i][j] === 'Operating Income') {
                    operating.push(financialComponent.inc[i]);
                    break;
                }
                if (financialComponent.inc[i][j] === 'Net Income After Taxes') {
                    income.push(financialComponent.inc[i]);
                    break;
                }
                if (financialComponent.inc[i][j] === 'Period End Date:') {
                    year.push(financialComponent.inc[i]);
                    break;
                }
            }
        }
        financialComponent.rchartData = financialComponent.loadgeneraldata(revenue, year);
        financialComponent.opchartData = financialComponent.loadgeneraldata(operating, year);
        financialComponent.ichartData = financialComponent.loadgeneraldata(income, year);
    }

    loadcfdata(eventValue) {
        financialComponent.index = 3;
        financialComponent.cochartData = [];
        financialComponent.cichartData = [];
        financialComponent.cfchartData = [];
        const operating = [];
        const year = [];
        const investing = [];
        const financing = [];
        if (eventValue === '2') {
            financialComponent.iscfi = true;
            financialComponent.iscf = false;
            financialComponent.cas = financialComponent.finmodel.data.CAS.Interim;
        }
        else {
            financialComponent.iscf = true;
            financialComponent.iscfi = false;
            financialComponent.cas = financialComponent.finmodel.data.CAS.Annual;
        }
        for (let i = 0; i < financialComponent.cas.length; i++) {
            for (let j = 0; j < financialComponent.cas[i].length; j++) {
                if (financialComponent.cas[i][j] === 'Cash from Operating Activities') {
                    operating.push(financialComponent.cas[i]);
                    break;
                }
                if (financialComponent.cas[i][j] === 'Cash from Investing Activities') {
                    investing.push(financialComponent.cas[i]);
                    break;
                }
                if (financialComponent.cas[i][j] === 'Cash from Financing Activities') {
                    financing.push(financialComponent.cas[i]);
                    break;
                }
                if (financialComponent.cas[i][j] === 'Period End Date:') {
                    year.push(financialComponent.cas[i]);
                    break;
                }
            }
        }
        financialComponent.cochartData = financialComponent.loadgeneraldata(operating, year);
        financialComponent.cichartData = financialComponent.loadgeneraldata(investing, year);
        financialComponent.cfchartData = financialComponent.loadgeneraldata(financing, year);
    }

    loadnetworthdata(assets, liability, year) {
        const networth = [];
        const yterm = [];
        const fassets = [].concat.apply([], assets);
        const fliability = [].concat.apply([], liability);
        financialComponent.chartLabels = [].concat.apply([], year);
        fassets.shift();
        fliability.shift();
        financialComponent.chartLabels.shift();
        const assetsparse = fassets.map(function (v) {
            return parseFloat(v.toString().replace(/,/g, ''));
        });
        const liabilityparse = fliability.map(function (v) {
            return parseFloat(v.toString().replace(/,/g, ''));
        });
        for (let i = 0; i < liabilityparse.length; i++) {
            networth.push(assetsparse[i] - liabilityparse[i]);
        }
        for (let i = 0; i < networth.length; i++) {
            if (!isNaN(networth[i])) {
                yterm.push(networth[i]);
            }
        }
        financialComponent.chartData = [{ data: yterm }];
        return financialComponent.chartData;
    }


    loadgeneraldata(fin, year) {
        const yterm = [];
        financialComponent.chartData = [];
        if (fin.length > 0) {
            const finalfin = [].concat.apply([], fin);
            financialComponent.chartLabels = [].concat.apply([], year);
            finalfin.shift();
            financialComponent.chartLabels.shift();
            const finparse = finalfin.map(function (v) {
                return parseFloat(v.toString().replace(/,/g, ''));
            });
            for (let i = 0; i < finparse.length; i++) {
                if (!isNaN(finparse[i])) {
                    yterm.push(finparse[i]);
                }
            }
            financialComponent.chartData = [{ data: yterm, stack: '' }];
        }
        return financialComponent.chartData;
    }
}
