<div class="row">
    <div class="col-12">
        <div class="watchlistwrapper">
            <div class="row">
                <div class="col-md-3 col-2 pr-md-1 px-md-3 px-0" style="max-width:90px;">
                    <img alt="" style="height:100%; width: 100%;" class="img-responsive pr-2" src={{basketDataObj.image}} >
                </div>
                <div class ="col-md-9 col-10 pl-md-1 pl-0 my-auto">
                    <div class="row">
                        <label class="col-12 tc-1 ts-4 texttrim" matTooltip="{{basketDataObj.name}}">{{basketDataObj.name}}</label>
                        <div class="col-7">
                            <label class="line2 subcolor ts-2">{{'basket.min_investment' | translate}}:&nbsp;</label><span>{{basketDataObj.min_investment | pricepipe}}</span><br/>
                            <label class="line2 subcolor ts-2">Invt. period:&nbsp;</label>{{investmentPeriodMap[basketDataObj.investment_period]}}
                        </div>
                        <div class="col-5 text-right">
                            <label class="line2 subcolor ts-2">{{'dashboard.risk' | translate}}: </label><label [class.imnegative]="basketDataObj.risk_factors==='h'" [class.impositive]="basketDataObj.risk_factors==='l'">{{riskMap[basketDataObj.risk_factors]}}</label>
                            <br>
                            <label class="line2 subcolor ts-2">Rt. 1 yr.:&nbsp;</label><label [class.imnegative]="basketDataObj.percentChange365<0" [class.impositive]="basketDataObj.percentChange365>0">{{basketDataObj.percentChange365|percentagepipe}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row pb-2">
    <div class="col-12 mt-2">
        <div class="des-header">
            description
        </div>
        <div class="">
            <small *ngIf="!isMore" class="disabletext" [innerHTML]="shortdesc"></small>
            <small *ngIf="isMore" class="disabletext" [innerHTML]="longdesc"></small>
            <small *ngIf="length>=200" class="disabletext">..<a (click)="moreclick()">{{text}}</a></small>
        </div>
    </div>
</div>
<div class="row py-2 border-t">
    <div class="col-12">
        <div class="row">
            <div class="col-lg-4 col-4 text-left">
                <div class="pb-1"><label class="line2 subcolor ts-2">Frequency</label></div>
                <div class="ts-3">{{basketDataObj.frequency}}</div>
            </div>
            <div class="col-lg-4 col-4 text-center">
                <div class="pb-1"><label class="line2 subcolor ts-2">Last Review</label></div>
                <div class="ts-3">{{basketDataObj.last_review_date}}</div>
            </div>
            <div class="col-lg-4 col-4 text-right">
                <div class="pb-1"><label class="line2 subcolor ts-2">Next Review</label></div>
                <div class="ts-3">{{basketDataObj.next_review_date}}</div>
            </div>
        </div>
    </div> 
</div>
<div class="row pb-2 border-b">
    <div class="col-12">
        <div class="des-header border-t">
            CAGR
        </div>
        <div class="row">
            <div class="col-lg-4 col-4 text-left">
                <div class="pb-1"><label class="line2 subcolor ts-2">1 Yr</label></div>
                <div class="ts-3">{{basketDataObj.oneyrcagr === "NA" ? basketDataObj.oneyrcagr : basketDataObj.oneyrcagr | percentagepipe}}</div>
            </div>
            <div class="col-lg-4 col-4 text-center">
                <div class="pb-1"><label class="line2 subcolor ts-2">3 Yr</label></div>
                <div class="ts-3">{{basketDataObj.threeyrcagr === "NA" ? basketDataObj.threeyrcagr : basketDataObj.threeyrcagr | percentagepipe}}</div>
            </div>
            <div class="col-lg-4 col-4 text-right">
                <div class="pb-1"><label class="line2 subcolor ts-2">5 Yr</label></div>
                <div class="ts-3">{{basketDataObj.fiveyrcagr === "NA" ? basketDataObj.fiveyrcagr : basketDataObj.fiveyrcagr | percentagepipe}}</div>
            </div>
        </div>
    </div>
</div>
<div class="row py-2">
    <div class="col-12">
        <div class="des-header">
            Past performances
        </div>
    </div>
</div>