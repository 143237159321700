import { Component, ElementRef, QueryList, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import { MyAlertDialogComponent } from '../../home/watchlist/watchlist.component';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { element } from 'protractor';
import { OptionChainPlaceOrderComponent } from '../../derivativemarket/optionchain/optionchainplaceorder/optionchainplaceorder.component';
import { IScripService } from 'src/app/services/scrip/iscrip.service';
import { PlaceOrderModel } from 'src/app/model/place.order.model';
import { Keys } from 'src/app/services/keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';


let basketordersummary: BasketOrdersummaryComponent;
@Component({
  selector: 'app-basketordersummary',
  templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketordersummary/basketordersummary.component.html',
  styleUrls: ['./basketordersummary.component.scss']
})

export class BasketOrdersummaryComponent {
  orderDetailSummary = [];
  basketName;
  isMobile = true;
  isLot = false;
  basket_order = '';
  dialogRef;
  orderSummary = [];
  allOrders = [];
  optionChainData;
  orderdata;
  modifyData = new Array();
  isModify: boolean;
  selectedTradeSymbol;
  modifiedTradeSymbol;
  orderChecked = new Map();
  isLotLable = false;
  isCall: boolean;
  isPut: boolean;
  lotError = true;
  scripService: IScripService;
  pom: PlaceOrderModel;
  orderModified: boolean;
  ticksize;
  @ViewChild('callInputPrice', { static: false }) callInputPrice: ElementRef;
  @ViewChild('callInput', { static: false }) callInput: ElementRef;
  @ViewChild('putInput', { static: false }) putInput: ElementRef;
  @ViewChild('putInputPrice', { static: false }) putInputPrice: ElementRef;
  constructor(private router: Router, private dialog: MatDialog, private translate: TranslateService, private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private location: Location) {
    basketordersummary = this;
    basketordersummary.isMobile = Utility.IS_MOBILE;
    if (Preferences.getItem('currentpage', ReturnType.String) === 'optionchainplaceorder') {
      basketordersummary.basket_order = 'derivative.option_order';
    } else {
      basketordersummary.basket_order = 'basket.basket_order';
      const basketSettingObj = Preferences.getItem('basket_setting', ReturnType.Object);
      basketordersummary.basketName = basketSettingObj.basket_name;
    }
    basketordersummary.orderDetailSummary = Utility.basketOrderSummary;
    this.isLot = Utility.IsLot(Utility.segmentToExchange(Utility.ocData[0].callscrip.ExchangeSegment));
    if (Utility.getNumber(Utility.ocData[0].callscrip.BodLotQuantity) > 1)
      basketordersummary.isLotLable = true;

      basketordersummary.ticksize = Utility.getTickSize(Utility.ocData[0].callscrip.TickSize, Utility.ocData[0].callscrip.Multiplier, Utility.ocData[0].callscrip.DecimalPrecision);
    basketordersummary.scripService = tradingRestService.getScripService();
    basketordersummary.pom = new PlaceOrderModel();
  }

  onModifyClick(orderModel) {
    basketordersummary.isModify = true;
    basketordersummary.isCall = false;
    basketordersummary.isPut = false;
    basketordersummary.optionChainData = Utility.ocData;
    basketordersummary.orderdata = orderModel;
    for (let i = 0; i < basketordersummary.optionChainData.length; i++) {
      if (basketordersummary.orderdata['TSym'] === basketordersummary.optionChainData[i]['callscrip']['TradingSymbol']) {
        basketordersummary.modifyData = basketordersummary.optionChainData[i];
        basketordersummary.selectedTradeSymbol = basketordersummary.optionChainData[i]['callscrip']['TradingSymbol'];
        basketordersummary.isCall = true;
        break;
      }
      else if (basketordersummary.orderdata['TSym'] === basketordersummary.optionChainData[i]['putscrip']['TradingSymbol']) {
        basketordersummary.modifyData = basketordersummary.optionChainData[i];
        this.selectedTradeSymbol = basketordersummary.optionChainData[i]['putscrip']['TradingSymbol'];
        basketordersummary.isPut = true;
        break;
      }
    }
  }

  onButtonClick() {
    // this.router.navigate(['/home'], { replaceUrl: true });
    basketordersummary.location.back();
    basketordersummary.location.back();
    Utility.openOrderBook.next();
    Utility.stockDetailIndex = 0;
  }

  onBackOC() {
    // this.router.navigate(['/optionchain']);
    basketordersummary.location.back();
  }

  calculateLots(qty, lotSize) {
    this.lotError = false;
    if (qty && lotSize && this.floatSafeModulus(Utility.getNumber(qty), Utility.getNumber(lotSize)) === 0)
      return Math.floor(Utility.getNumber(qty) / Utility.getNumber(lotSize));
    this.lotError = true;
    return 'NA';
  }

  floatSafeModulus(val, step) {
    val = Utility.getNumber(val);
    step = Utility.getNumber(step);
    if (val && step) {
      const valDecCount = (val.toString().split('.')[1] || '').length;
      const stepDecCount = (step.toString().split('.')[1] || '').length;
      const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
      let valInt;
      if (val !== undefined && val !== null && val !== NaN)
        valInt = parseInt(Utility.getNumber(val).toFixed(decCount).replace(/\./g, ''), 10);
      const stepInt = parseInt(step.toFixed(decCount).replace(/\./g, ''), 10);
      return (valInt % stepInt) / Math.pow(10, decCount);
    }
    else
      return 0;
  }

  setPreDefinedOrders(data) { }

  onCheckBoxClicked(orderData, event) {
    if (event.checked) {
      this.orderChecked.set(orderData['TSym'], true);
    }
    else {
      this.orderChecked.delete(orderData['TSym']);
    }
  }

  confirmModify() {
    basketordersummary.pom.Nstordno = basketordersummary.orderdata.NestOrd;
    basketordersummary.pom.Token = basketordersummary.orderdata.Token;
    let message = '';
    if (basketordersummary.isCall) {
      if (basketordersummary.modifyData['callPrice'] == '' || !Utility.checkPriceLength(basketordersummary.modifyData['callPrice'], basketordersummary.modifyData['callscrip']['DecimalPrecision'])){
        message = this.translate.instant('placeorder.invalid_price');
      }
      else if (basketordersummary.modifyData['callqtycount'] == '' || !Utility.checkQty(basketordersummary.modifyData['callqtycount'], basketordersummary.modifyData['callscrip']['DecimalPrecision'])){
        message =  this.translate.instant('placeorder.invalid_qty');
      }
      else if (this.isLot ? false : (this.floatSafeModulus(basketordersummary.modifyData['callqtycount'], basketordersummary.modifyData['callscrip']['BodLotQuantity'])) !== 0) {
        message = this.translate.instant('placeorder.qtymultiple') + basketordersummary.modifyData['callscrip']['BodLotQuantity'];
      }
      if (this.floatSafeModulus(basketordersummary.modifyData['callPrice'], basketordersummary.ticksize) !== 0) {
        message = this.translate.instant('placeorder.pricemultiple') + basketordersummary.ticksize;
      }
      basketordersummary.pom.Price = basketordersummary.modifyData['callPrice'];
      basketordersummary.pom.qty = basketordersummary.modifyData['callqtycount'];
    }
    else {
      if (basketordersummary.modifyData['putPrice'] == '' || !Utility.checkPriceLength(basketordersummary.modifyData['putPrice'], basketordersummary.modifyData['putscrip']['DecimalPrecision'])){
        message = this.translate.instant('placeorder.invalid_price');
      }
      else if (basketordersummary.modifyData['putqtycount'] == '' || !Utility.checkQty(basketordersummary.modifyData['putqtycount'], basketordersummary.modifyData['putscrip']['DecimalPrecision'] )){
        message =  this.translate.instant('placeorder.invalid_qty');
      }
      else if (this.isLot ? false : (this.floatSafeModulus(basketordersummary.modifyData['putqtycount'], basketordersummary.modifyData['putscrip']['BodLotQuantity'])) !== 0) {
        message = this.translate.instant('placeorder.qtymultiple') + basketordersummary.modifyData['putscrip']['BodLotQuantity'];
      }
      else if (this.floatSafeModulus(basketordersummary.modifyData['putPrice'], basketordersummary.ticksize) !== 0) {
        message = this.translate.instant('placeorder.pricemultiple') + basketordersummary.ticksize;
      }
      basketordersummary.pom.Price = basketordersummary.modifyData['putPrice'];
      basketordersummary.pom.qty = basketordersummary.modifyData['putqtycount'];
    }
    if (message === '') {
      basketordersummary.pom.Tsym = basketordersummary.orderdata.TSym;
      basketordersummary.pom.AMO = 'NO';
      basketordersummary.pom.TrigPrice = '0';
      basketordersummary.pom.prctyp = 'L';
      basketordersummary.pom.Ret = 'DAY';
      basketordersummary.scripService.modifyOrder(basketordersummary.pom, this.modifyOrderRes.bind(this));
    }
    else {
      this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  modifyOrderRes(data, code, msg) {
    if (code === Keys.RES_CODE_SUCCESS){
      basketordersummary.orderModified = true;
      basketordersummary.orderdata.isModified = true;
      this.snackBar.open(this.translate.instant('stock_detail.modify_msg'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      basketordersummary.close();
    }
    else {
      this.snackBar.open(msg, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  close() {
    basketordersummary.isCall = false;
    basketordersummary.isPut = false;
  }

  onCancel(obj) {
    const alertMessage = this.translate.instant('books.cancel_order')
    basketordersummary.allOrders = [];
    this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: {heading: this.translate.instant('alert.cancel_hed'), message: alertMessage } });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.tradingRestService.getBookService().CancelOrder(obj, function (responsedata, code, message) {
          if (responsedata['stat'] === 'Ok') {
            message = this.translate.instant('placeorder.order_cancelled');
            this.tradingRestService.getBookService().getAllOrders(function (list, resCode, resMsg) {
              Utility.basketOrderSummary.forEach(element => {
                if (element['status'] === 'Ok') {
                  const temp = list.find(ordermodel => ordermodel['NestOrd'] === element['orderId']);
                  element['orderData'] = temp;
                }
                basketordersummary.allOrders.push(element);
              });
              basketordersummary.orderDetailSummary = basketordersummary.allOrders;
            }.bind(this), this.setPreDefinedOrders.bind(this));
            this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['successSnackBar'] });
          }
          else
            this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }.bind(this));
      }
    });
  }

  validateKey(key, str)
  {
    if (/[0-9]/i.test(key))
    {
      if (str === 'price')
        this.snackBar.open(this.translate.instant('limits.max_bfr_digits') + this.translate.instant('limits.max_aft_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      else if (str === 'mkt')
        this.snackBar.open(this.translate.instant('placeorder.max_two_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
      else
        this.snackBar.open(this.translate.instant('placeorder.max_sevn_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (/[a-zA-Z]/i.test(key)){
        this.snackBar.open(this.translate.instant('limits.only_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else{
        this.snackBar.open(this.translate.instant('limits.only_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  validateQty(ev, type, ind) {
    const regex = new RegExp('^[0-9]{0,7}$|^[0-9]{1,' + 2 + '}$');
    const index = ev.target.selectionStart;
    let elementRef;
    if (type ===  'CALL'){
      elementRef = this.callInput.nativeElement;
    }
    else{
      elementRef = this.putInput.nativeElement;
    }
    const val = elementRef.value;
        if (!val.match(regex)) {
          this.validateKey(ev.key, 'qty')
          ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
          elementRef.dispatchEvent(new Event('input'));
          elementRef.setSelectionRange(index - 1, index - 1);
        }
  }

  validatePrice(ev, type, dp) {
    const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + dp + '}$');
    let elementRef;
    if (type === 'CALL'){
      elementRef = this.callInputPrice.nativeElement;
    }
    else{
      elementRef = this.putInputPrice.nativeElement;
    }
    const val = elementRef.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      this.validateKey(ev.key, 'price');
      ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
      elementRef.dispatchEvent(new Event('input'));
      elementRef.setSelectionRange(index - 1, index - 1);
    }
  }
}
