import { Injectable } from '@angular/core';
import { ScripModel } from 'src/app/model/scrip.model';
import { PlaceOrderModel } from 'src/app/model/place.order.model';
import { IScripService } from './iscrip.service';
import { ApiService } from '../api.service';
import { TradingRestService } from '../tradingrest.service';
import { Keys } from '../keys';
import { ChannelType, Utility } from '../utility';
import { Url } from '../url';
import { ScripMarketPictureModel } from 'src/app/model/scripMarketPictureModel';
import { forkJoin, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Preferences } from '../Preferences';
let hsScripService: HSScripService;

@Injectable()
export class HSScripService implements IScripService {
   apiService: ApiService;
   scripDetailMap = new Map();
   isMobile = false;

   constructor(apiService: ApiService) {
      hsScripService = this;
      hsScripService.apiService = apiService;
      hsScripService.isMobile = Utility.getDevice();
   }

   showQuote(sm: ScripModel, isCached: boolean, callback: Function) {
      const message = '';
      const code = Keys.RES_CODE_SUCCESS;
      if (sm.isShowQuotesLoaded && isCached) {
         callback(sm, code, message);
      }
      else {
         const sendData = [];
         sendData.push(sm.ExchangeSegment + '|' + sm.Token);
         const reqdata = { 'scripList': sendData.toString() };
         this.getMultipleScrips(reqdata, (scripModelData, code1, message1) => {
            if (scripModelData.length > 0) {
               message1 = 'OK';
            }
            callback(scripModelData[0], code1, message1);
         });
      }
   }
   placeOrder(pom: PlaceOrderModel, callback: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      const reqdata = {
         'es': pom.Exchangeseg,
         'pc': pom.Pcode,
         'pr': pom.prctyp === 'MKT' || pom.prctyp === 'SL-M' ? '0' : pom.Price.toString(),
         'mp': pom.prctyp === 'MKT' || pom.prctyp === 'SL-M' ? pom.MktPro.toString() : '0',
         'ot': pom.prctyp,
         'qt': pom.qty.toString(),
         'rt': pom.Ret,
         'tk': pom.Token,
         'tp': pom.TrigPrice.toString(),
         'ts': pom.Tsym.replace('&', '%26'),
         'tt': pom.Ttranstype,
         'am': pom.AMO,
         'os': hsScripService.isMobile ? 'MOB' : 'WEB',
         'dq': pom.discqty
      };
      const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('userSessionId'));
      this.apiService.hspost(Url.HS_PLACE_ORDER, param).subscribe(res => {
         res = JSON.parse(JSON.stringify(res));
         let data = '';
         if (res['stat'] === 'Ok') {
            data = res;
            message = Keys.RES_MSG_OK;
         }
         else {
            code = Keys.RES_CODE_FAILURE;
            message = res.errMsg;
         }
         callback(data, code, message);
      }, error => {
         code = Keys.RES_CODE_FAILURE;
         message = error;
         callback(null, code, message);
      });
   }

   getMultipleScrips(data, callback: Function) {
      let message = '';
      let code;
      let scripArr = data.scripList.split(',');
      if (Preferences.getPreference('nestMode') !== 'true')
      {
         scripArr = scripArr.map(ele => {
            const arr = ele.split('|');
            const token = arr[1].replace(' ', '|');
            return arr[0] + '|' + token;
         });
      }
      const scripModelData = new Array<ScripModel>();
      const sendJSONObj = { 'scripArr': scripArr };
      const param = Utility.prepareParameters(JSON.stringify(sendJSONObj), Preferences.getPreference('userSessionId'));
      this.apiService.hspost(Url.HS_SCRIP_INFO, param).subscribe(res => {
         code = res.stCode;
         message = res.stat;
         if (code === 200 && message === 'Ok') {
            if (res.data.length > 0) {
               res = JSON.parse(JSON.stringify(res['data']));
               for (let i = 0; i < res.length; i++) {
                  const obj = res[i];
                  const bean = Utility.getScripModel(obj['tok'], obj['exSeg'], null, false, ChannelType.None);
                  bean.GDGNPDPN = ((obj['prcNum'] / obj['prcDen'])  === 0 || (obj['genNum'] / obj['genDen']) === 0) ?
                   1 : (obj['genNum'] / obj['genDen']) * (obj['prcNum'] / obj['prcDen']);
                  bean.ExchangeSegment = obj['exSeg'];
                  bean.isInfoCalled = true;
                  bean.TradingSymbol = obj['trdSym'];
                  bean.Token = obj['tok'];
                  bean.Isin = obj['isin'];
                  bean.segment  = Utility.getSegment(bean.ExchangeSegment);
                  bean.ExchangeName = obj['exch'];
                  bean.InstrumentName = obj['inst'];
                  bean.DecimalPrecision = obj['prcn'];
                  bean.Multiplier = obj['multplr'];
                  bean.NoMarketProtectionFlag = obj['noMkPro'];
                  bean.MinQty = obj['minQty'];
                  bean.MaxQty = obj['maxQty'];
                  bean.DiscQty = obj['dscQty'];
                  bean.MarketProtection = obj['mktPro'];
                  bean.TickSize = obj['tckSz'];
                  bean.DefaultMarketProtectionValue = obj['defMkt'];
                  bean.UniqueKey = obj['unqKey'];
                  bean.CombinedSymbol = obj['combSym'];
                  bean.Symbolname = obj['sym'];
                  bean.StrikePrice = obj['strkPrc'];
                  bean.SpotPrice = obj['sptPrc'];
                  bean.Corporateaction = obj['crpActn'];
                  bean.CompanyName = (obj['cmpName'] === 'NA') ? ' ' : obj['cmpName'];
                  bean.ExpiryDate = obj['expDt'];
                  bean.OptionType = obj['optType'] == null ? 'XX' : obj['optType'];
                  bean.ExpiryDateDisplayValue = Utility.toDateTime(obj['expDt']);
                  bean.exchTokens = obj["exchTokens"];
                  bean.BodLotQuantity = obj['brdLtQty'];
                  bean.DisplaySymbolDescription = Utility.getSymDes(bean.ExpiryDate, bean.CompanyName, bean.StrikePrice, bean.OptionType);
                  scripModelData.push(bean);
               }
            }
            else {
               message = 'Not Ok';
            }
         }
         callback(scripModelData, code, message);
      });
   }

   getRetention(exch: string, callback: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      if (Utility.RETENTION_MAP.get(exch)) {
         callback(Utility.RETENTION_MAP.get(exch), code, message);
      }
      else {
         const reqdata = { 'exch': exch };
         const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('userSessionId'));
         this.apiService.hspost(Url.HS_RETENTION_TYPE, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data;
            if (res['stat'] === 'Ok') {
               data = res['ret'];
               message = Keys.RES_MSG_OK;
               Utility.RETENTION_MAP.set(exch, data);
            }
            else {
               code = Keys.RES_CODE_FAILURE;
               message = res.Emsg;
            }
            callback(data, code, message);
         }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
         });
      }
   }

   ShowMarketPicture(scripData, callback: Function) {
      const bean = Utility.GetScripMarketPictureModel(scripData['scripToken'], scripData['exch'], scripData['restClient']);
      callback(bean, Keys.RES_CODE_SUCCESS, 'Ok');
   }
   getLotWeight(callback: Function, precallback?: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      const userId = Preferences.getPreference('userid');
      const key = Url.HS_LOT_WEIGHT + '_' + userId;
      const predata = Utility.CACHE_STORE.getCacheData(key);
      if (Utility.LOT_WEIGHT_MAP) {
         callback(Utility.LOT_WEIGHT_MAP, code, message);
      }
      else {
         if (predata !== undefined) {
            precallback(predata);
         }
         this.apiService.hspost(Url.HS_LOT_WEIGHT, {}).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data;
            if (res['stat'] === 'Ok') {
               data = res['lotWt'];
               message = Keys.RES_MSG_OK;
               code = res.stCode;
               Utility.LOT_WEIGHT_MAP = data;
               Preferences.setItem('lwMap', data);
               Utility.CACHE_STORE.setCacheData(key, data);
            }
            else {
               code = res.stCode;
               message = res.errMsg;
            }
            callback(data, code, message);
         }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
         });
      }
   }
   getSecInfo(data, callback: Function) {
      const sendData = [];
      sendData.push(Utility.exchangeToSegment(data.exch) + '|' + data.tk);
      const reqdata = { 'scripList': sendData.toString() };
      this.getMultipleScrips(reqdata, (scripModelData, code1, message1) => {
         if (scripModelData.length > 0) {
            message1 = 'OK';
         }
         callback(scripModelData[0], code1, message1);
      });
   }
   validateGTD(data, callback: Function) { }
   modifyOrder(pom: PlaceOrderModel, callback: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      const reqdata = {
         'on': pom.Nstordno,
         'tk': pom.Token,
         'ot': pom.prctyp,
         'pr': pom.prctyp === 'MKT' || pom.prctyp === 'SL-M'? '0': pom.Price.toString(),
         'qt': pom.qty.toString(),
         'tp': pom.TrigPrice.toString(),
         'ts': pom.Tsym.replace('&', '%26'),
         'vd': pom.Ret,
         'am': pom.AMO,
         'os': hsScripService.isMobile ? 'MOB' : 'WEB',
         'dq': pom.discqty
      };
      const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('userSessionId'));
      this.apiService.hspost(Url.HS_MODIFY_ORDER, param).subscribe(res => {
         res = JSON.parse(JSON.stringify(res));
         let data = '';
         if (res['stat'] === 'Ok') {
            data = res;
            message = Keys.RES_MSG_OK;
         }
         else {
            code = Keys.RES_CODE_FAILURE;
            message = res.errMsg;
         }
         callback(data, code, message);
      }, error => {
         code = Keys.RES_CODE_FAILURE;
         message = error;
         callback(null, code, message);
      });
   }
   placeMultipleReqOrder(reqArray: any, responseCallback: Function) {
      const urls = [];
      reqArray.forEach(function (element) {
          const reqdata = {
            'es': element.exchange,
            'pc': element.pc,
            'qt': element.qty.toString(),
            'ts': element.symbol,
            'ot': element.orderType,
            'pr': element.ltp.toString(),
            'rt': element.rt,
            'tk': element.tk,
            'tp': element.tp.toString(),
            'tt': element.tt,
            'am': element.AMO,
            'os': hsScripService.isMobile ? 'MOB' : 'WEB'
         };
         const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('userSessionId'));
          urls.push(hsScripService.apiService.hspost(Url.HS_PLACE_ORDER, param).pipe(map(res => res)));
      });

      forkJoin(urls).subscribe(results => {
          const responseArray = [];
          const temp = [];
          for (let i = 0; i < results.length; i++) {
              if (temp.indexOf(results[i]['Emsg']) === -1) {
                  const obj = {};
                  obj['id'] = i + 1;

                  if (results[i]['stat'] === 'Not_Ok') {
                      obj['Emsg'] = results[i]['Emsg'];
                      obj['status'] = results[i]['stat'];
                      temp.push(obj['Emsg']);
                  }
                  else {
                      obj['status'] = results[i]['stat'];
                      obj['orderId'] = results[i]['nOrdNo'];
                  }
                  responseArray.push(obj);
              }
          }
          responseCallback(responseArray);
      });
  }
   LTPCoverPrctge(data, callback: Function) {
      // let dataObj = Utility.LTPCOVERPER;
      // callback(dataObj, Keys.RES_CODE_SUCCESS, Keys.RES_MSG_OK);
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      const key = Utility.exchangeToSegment(data.Exchange) + '_' + data.Symbol + '_' + data.Transtype;
      const predata = Utility.CACHE_STORE.getCacheData(key);
      if (Utility.LTPCOVERPER_MAP.has(key)) {
         callback(Utility.LTPCOVERPER_MAP.get(key), code, message);
      }
      else {
         // if (predata !== undefined) {
         //    precallback(predata);
         // }
         let obj = {"exSeg":Utility.exchangeToSegment(data.Exchange),"trdSym":data.Symbol,"trsnTp":data.Transtype}
         const param = Utility.prepareParameters(JSON.stringify(obj), Preferences.getPreference('userSessionId'));
         this.apiService.hspost(Url.HS_LTP_COVER_PER, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data;
            if (res['stat'] === 'Ok') {
               data = res['pct'];
               message = Keys.RES_MSG_OK;
               code = res.stCode;
               Utility.LTPCOVERPER_MAP.set(key, data);
               Utility.CACHE_STORE.setCacheData(key, data);
            }
            else {
               code = res.stCode;
               message = res.errMsg;
            }
            callback(data, code, message);
         }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
         });
      }
   }
   coverOrderPriceFlag(callback: Function) {
      let data = "Disabled";
      callback(data, Keys.RES_CODE_SUCCESS, "Ok");
    }
   validateSLM(data, callback: Function) {
      let dataObj = {"msg": "Y"};
      callback(dataObj, Keys.RES_CODE_SUCCESS, Keys.RES_MSG_OK);
    }
   placeBOOrder(pom: PlaceOrderModel, callback: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      const reqdata = {
         'es': pom.Exchangeseg,
         'tk': pom.Token,
         'tt': pom.Ttranstype,
         'qt': pom.qty.toString(),
         'ui': Preferences.getPreference('userid'),
         'rt': pom.Ret,
         'pr': pom.prctyp === 'MKT' || pom.prctyp === 'SL-M'? '0': pom.Price.toString(),
         'la': pom.ltpOratp,
         'so': pom.SqrOffAbsOrticks,
         'sv': pom.SqrOffvalue,
         'st': pom.SLAbsOrticks,
         'sl': pom.SLvalue,
         'tr': pom.trailingSL,
         'ot': pom.prctyp,
         'tp': pom.TrigPrice.toString(),
         'am': pom.AMO,
         'os': hsScripService.isMobile ? 'MOB' : 'WEB',
         'dq': pom.discqty
      };
      const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('userSessionId'));
      this.apiService.hspost(Url.HS_BRACKET_ORDER, param).subscribe(res => {
         res = JSON.parse(JSON.stringify(res));
         let data = '';
         if (res['stat'] === 'Ok') {
            data = res;
            message = Keys.RES_MSG_OK;
         }
         else {
            code = Keys.RES_CODE_FAILURE;
            message = res.errMsg;
         }
         callback(data, code, message);
      }, error => {
         code = Keys.RES_CODE_FAILURE;
         message = error;
         callback(null, code, message);
      });
   }

   spreadPlaceOrder(pom: PlaceOrderModel, callback: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      const reqdata = {
         'es': pom.Exchangeseg,
         'pc': pom.Pcode,
         'pr': pom.prctyp === 'MKT' || pom.prctyp === 'SL-M'? '0': pom.Price.toString(),
         'pr2': pom.Price.toString(),
         'pt': pom.prctyp,
         'qt': pom.qty.toString(),
         'qt2': pom.qty.toString(),
         'rt': pom.Ret,
         'tk': pom.Token.split(' ')[0],
         'tk2': pom.Token.split(' ')[1],
         'tp': pom.TrigPrice.toString(),
         'ts': pom.Tsym.replace('&', '%26'),
         'tt': pom.Ttranstype,
         'am': pom.AMO,
         'os': hsScripService.isMobile ? 'MOB' : 'WEB',
         'dq': pom.discqty,
         'mp': '0'
      };
      const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('userSessionId'));
      this.apiService.hspost(Url.HS_SPREAD_ORDER, param).subscribe(res => {
         res = JSON.parse(JSON.stringify(res));
         let data = '';
         if (res['stat'] === 'Ok') {
            data = res;
            message = Keys.RES_MSG_OK;
         }
         else {
            code = Keys.RES_CODE_FAILURE;
            message = res.errMsg;
         }
         callback(data, code, message);
      }, error => {
         code = Keys.RES_CODE_FAILURE;
         message = error;
         callback(null, code, message);
      });
   }

   spreadModifyOrder(pom: PlaceOrderModel, callback: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      const reqdata = {
         'no': pom.Nstordno,
         'tk': pom.Token,
         'pt': pom.prctyp,
         'pr': pom.Price.toString(),
         'qt': pom.qty.toString(),
         'tp': pom.TrigPrice.toString(),
         'ts': pom.Tsym.replace('&', '%26'),
         'vd': pom.Ret,
         'am': pom.AMO,
         'os': hsScripService.isMobile ? 'MOB' : 'WEB',
         'dq': pom.discqty,
         'es': pom.Exchangeseg,
         'mp': '0',
         'pc': pom.Pcode,
         "dd":"NA"
      };
      const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('userSessionId'));
      this.apiService.hspost(Url.HS_SPREADMODIFY_ORDER, param).subscribe(res => {
         res = JSON.parse(JSON.stringify(res));
         let data = '';
         if (res['stat'] === 'Ok') {
            data = res;
            message = Keys.RES_MSG_OK;
         }
         else {
            code = Keys.RES_CODE_FAILURE;
            message = res.errMsg;
         }
         callback(data, code, message);
      }, error => {
         code = Keys.RES_CODE_FAILURE;
         message = error;
         callback(null, code, message);
      });
   }
}
