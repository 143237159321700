<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row list-view border-b" (swipedown)="onSwipeDown()">
    <div class="col-12 subcolor ts-2">
        <span class="tc-1 ts-4 textclip">{{orderDetailData.TradeSymbol}}</span><small class="exchangename">{{orderDetailData.Exchange}}</small>
    </div>
</div>
<div class="sheetscrollarea orderbook-area">
    <div class="row">
        <div class="col-12 pr-0">
            <div class="row">
                <div class="col-12">
                    <table class="table-bottomsheet">
                        <tr><td><span class="namefield" translate>settings.status</span></td><td> {{orderDetailData.Status | uppercase}}</td></tr>
                        <tr><td><span class="namefield" translate>Symbol Leg 1</span></td><td> {{orderDetailData.TSym}}</td></tr>
                        <tr><td><span class="namefield" translate>optioncalc.expiry</span></td><td *ngIf="(orderDetailData.ExpiryDate != '--')">{{orderDetailData.ExpiryDate | datepipe:'date'}}</td><td *ngIf="(orderDetailData.ExpiryDate == '--')">--</td></tr>
                        <tr><td><span class="namefield" translate>detail.action_type</span></td><td>  {{orderDetailData.ActionDisplay}}</td></tr>
                        <tr><td><span class="namefield" translate>stock_detail.price</span></td><td>  {{orderDetailData.FillPrice | pricepipe: orderDetailData.scripModel.DecimalPrecision}}</td></tr>
                        <tr><td><span class="namefield" translate>books.avgprice</span></td><td>  {{orderDetailData.AvgPrice | pricepipe: orderDetailData.scripModel.DecimalPrecision}}</td></tr>
                        <tr><td><span class="namefield" translate>books.pendingqty</span></td><td>  {{orderDetailData.PendingQty}}</td></tr>
                        <tr><td><span class="namefield" translate>books.filled_qty</span> </td><td> {{orderDetailData.FilledShares}}</td></tr>
                        <tr><td><span class="namefield" translate>portfolio.qty</span></td><td>  {{orderDetailData.FillQty}}</td></tr>
                        <tr><td><span class="namefield" translate>books.exch_orderno</span></td><td>  {{orderDetailData.ExchangeOrderNo}}</td></tr>
                        <tr><td><span class="namefield" translate>Symbol Leg 2</span></td><td> {{orderDetailData.TSymLeg2}}</td></tr>
                        <tr><td><span class="namefield" translate>optioncalc.expiry</span></td><td *ngIf="(orderDetailData.ExpiryDateLeg2 != '--')"> {{orderDetailData.ExpiryDateLeg2 | datepipe:'date'}}</td><td *ngIf="(orderDetailData.ExpiryDateLeg2 == '--')">--</td></tr>
                        <tr><td><span class="namefield" translate>detail.action_type</span></td><td>  {{orderDetailData.ActionDisplayLeg2}}</td></tr>
                        <tr><td><span class="namefield" translate>stock_detail.price</span></td><td>  {{orderDetailData.FillPriceLeg2 | pricepipe: orderDetailData.scripModel.DecimalPrecision}}</td></tr>
                        <tr><td><span class="namefield" translate>books.avgprice</span></td><td>  {{orderDetailData.AvgPriceLeg2 | pricepipe: orderDetailData.scripModel.DecimalPrecision}}</td></tr>
                        <tr><td><span class="namefield" translate>books.pendingqty</span></td><td>  {{orderDetailData.PendingQtyLeg2}}</td></tr>
                        <tr><td><span class="namefield" translate>books.filled_qty</span> </td><td> {{orderDetailData.FilledSharesLeg2}}</td></tr>
                        <tr><td><span class="namefield" translate>portfolio.qty</span></td><td>  {{orderDetailData.FillQtyLeg2}}</td></tr>
                        <tr><td><span class="namefield" translate>books.exch_orderno</span></td><td>  {{orderDetailData.ExchangeOrderNoLeg2}}</td></tr>
                        <tr><td><span class="namefield" translate>spread.odr_updt_time</span> </td><td *ngIf="(orderDetailData.exchOrderUpdateTime != '--')">{{orderDetailData.exchOrderUpdateTime | datepipe:'mediumtime'}}</td><td *ngIf="(orderDetailData.exchOrderUpdateTime == '--')">--</td></tr>
                        <tr><td><span class="namefield" translate>books.nestorderid</span></td><td>  {{orderDetailData.nestOrderNumber}}</td></tr>
                        <tr><td><span class="namefield" translate>books.product</span></td><td>  {{orderDetailData.ProductCode}}</td></tr>
                        <tr><td><span class="namefield" translate>placeorder.pricetype</span> </td><td> {{orderDetailData.PriceType}}</td></tr>
                        <tr><td><span class="namefield" translate>alert.exchange</span></td><td>  {{orderDetailData.Exchange}}</td></tr>
                        <tr><td><span class="namefield" translate>login.username</span></td><td>  {{orderDetailData.UserId}}</td></tr>
                        <tr><td><span class="namefield" translate>spread.act_id</span> </td><td> {{orderDetailData.AccountId}}</td></tr>
                        <tr><td colspan="2" *ngIf="(orderDetailData.Text != null && orderDetailData.Text.Length != 0 && orderDetailData.Text != 'NA')"><span class="namefield" translate>books.rejection_reason</span><br> {{orderDetailData.Text}}</td></tr>
                    </table>
                <div >
            </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row bottomsheet-buttons">
    <div class="col-12 p-0">
        <div class="btngroup">
            <div *ngIf="isEdit" (click)="handleButton('E')" matRipple ><i class="icon-edit"></i>Edit</div>
            <div *ngIf="isCancel" (click)="handleButton('C')" matRipple ><i class="icon-cancel"></i>Cancel</div>
            <div (click)="handleButton('H')" matRipple ><i class="icon-history"></i>History</div>
        </div>
    </div>
</div>