<app-header *ngIf="isMobile" goback="1" [title]="'basket.option_chain_sum' | translate"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'basket.option_chain_sum' | translate"></app-titlebar>
<mat-dialog-content [class.mh-none] = "isMobile">
	<div [class.contarea]="!isMobile" class="col-12">
		<div [class.data-container]="isMobile" class="row">
			<div class="col-12">
                <div class="row mb-2">
                    <div class="col-12 impositivebg"><span class="impositive"><i class="icon-check"></i> {{'stock_detail.place_msg' | translate}}</span> {{basketName}}</div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor='let data of orderDetailSummary; let i = index;' class="list-view border-b mb-2">
                            <div *ngIf='data.status == "Ok" && data.orderData != undefined' class="row">
                                <div class="col-sm-8">
                                    <span class="tc-1 ts-4 asmobile">{{data.orderData.TradeSymbol}} {{data.orderData.DisplaySymbolDetail}}</span> <small class="exchangename"> {{data.orderData.Exchange}}</small><br>
                                        <small class="subcolor2">{{'basket.orderid' | translate}}</small>: {{data.orderId}}<br>
                                        <small class="subcolor2">{{'basket.orderstatus' | translate}}</small><span class="text-uppercase mr-2">{{data.orderData.Status}}</span><span  *ngIf = "data.orderData.isModified" class="ts-1 subcolor2"> | ( Modified )</span>
                                </div>
                                
                                <div class="col-sm-4 iconbtn text-right">
                                    <div class="d-inline-block text-center">
                                        <a mat-button class="mat-button-base mat-ripple" (click) = 'onModifyClick(data.orderData)' *ngIf = "data.orderData && data.orderData.Status == 'open'" ><i class="icon-edit"></i></a>
                                        <div class="btntext" *ngIf = "data.orderData && data.orderData.IsEdit"><span translate>watchlist.modify_order</span></div>
                                    </div>
                                    <div class="d-inline-block text-center">
                                        <a mat-button class="mat-button-base mat-ripple" (click) = 'onCancel(data.orderData)' *ngIf = "data.orderData && data.orderData.Status == 'open'" ><i class="icon-cancel"></i></a>
                                        <div class="btntext" *ngIf = "data.orderData && data.orderData.IsCancel"><span translate>watchlist.cancel_order</span></div>
                                    </div>
                                </div>
                                
                            </div>
                            <div *ngIf = "modifyData.callqtycount !== 0 && data.orderData.TSym == selectedTradeSymbol && isCall" class="subheaderbg p-2 border-all">
                                <!--div class="row pb-1">
                                    <div class="col-6">
                                        <div class="firstline">
                                            <div class="symbolname">{{modifyData.callscrip.TradingSymbol}}</div>
                                            <small class="m-0">{{modifyData.callscrip.ExchangeName}}</small> | <span [class.imnegative]="modifyData.callqtycount < 0" [class.impositive] = "modifyData.callqtycount > 0"> {{modifyData.callqtycount > 0 ? 'Buy' : 'Sell' }}</span>
                                        </div>
                                        </div>
                                    <div class="col-6 text-right"><div class="ts-1 subcolor2">LTP</div>
                                        <div [ngClass]="{'impositive':modifyData.callscrip.Change >0, 'imnegative': modifyData.callscrip.Change < 0}">
                                           {{modifyData.callscrip.LastTradePrice | pricepipe : modifyData.callscrip.DecimalPrecision }}
                                        </div>
                                    </div>
                                </div-->
                                <div class="row placeorderfield">
                                    <div class="col-6">
                                        <mat-form-field>
                                            <input #callInputPrice matInput type="tel"  [placeholder]="'basket.lastprice' | translate"
                                                value="{{modifyData.callPrice}}"
                                            autocomplete="off" oncopy="return false"
                                                onpaste="return false" [(ngModel)] = modifyData.callPrice (keyup)="validatePrice($event, 'CALL', modifyData.callscrip.DecimalPrecision)">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <mat-form-field>
                                            <mat-label><span translate>basket.basket_qty</span><span class="lotLable">(1 Lot = {{modifyData.callscrip.BodLotQuantity | numberpipe}}Qty)</span></mat-label>
                                            <input #callInput matInput type="tel" 
                                                [placeholder]="'basket.basket_qty' | translate"  [(ngModel)]= "modifyData.callqtycount"
                                                autocomplete="off" oncopy="return false" onpaste="return false" (keyup)="validateQty($event, 'CALL')">
                                        </mat-form-field>
                                        <br />
                                        <span  class="lotLable2" [class.imnegative] = "lotError">Lots = {{calculateLots(modifyData.callqtycount, modifyData.callscrip.BodLotQuantity) | numberpipe}}</span>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class ="col-12 text-right">
                                        <button  class="btn-base mr-1" mat-flat-button (click)="close()"><span translate>Close</span></button>
                                        <button  class="btn-primary" mat-flat-button (click)="confirmModify()"><span translate>Confirm</span></button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf = "modifyData.putqtycount !== 0  && data.orderData.TSym == selectedTradeSymbol && isPut" class="subheaderbg p-2 border-all">
                                <!--div class="row pb-1">
                                    <div class="col-6">
                                        <div class="firstline">
                                            <div class="symbolname">{{modifyData.putscrip.TradingSymbol}}</div>
                                            <small class="m-0">{{modifyData.putscrip.ExchangeName}}</small> | <span [class.imnegative]="modifyData.putqtycount < 0" [class.impositive] = "modifyData.putqtycount > 0"> {{modifyData.putqtycount > 0 ? 'Buy' : 'Sell' }}</span>
                                        </div>
                                        </div>
                                    <div class="col-6 text-right"><div class="ts-1 subcolor2">LTP</div>
                                        <div [ngClass]="{'impositive':modifyData.callscrip.Change >0, 'imnegative': modifyData.callscrip.Change < 0}">
                                           {{modifyData.putscrip.LastTradePrice | pricepipe}}
                                        </div>
                                    </div>
                                </div-->
                                <div class="row placeorderfield">
                                    <div class="col-6">
                                        <mat-form-field>
                                            <input #putInputPrice matInput type="tel"  [placeholder]="'basket.lastprice' | translate"
                                            value="{{modifyData.callPrice}}"
                                            autocomplete="off" oncopy="return false"
                                                onpaste="return false"  [(ngModel)]= "modifyData.putPrice" (keyup)="validatePrice($event, 'PUT', modifyData.putscrip.DecimalPrecision)">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <mat-form-field>
                                            <mat-label><span translate>basket.basket_qty</span><span *ngIf = "isLotLable" class="lotLable">(1 Lot = {{modifyData.putscrip.BodLotQuantity | numberpipe}}Qty)</span></mat-label>
                                            <input #putInput matInput type="tel" 
                                                [placeholder]="'basket.basket_qty' | translate"  [(ngModel)]= "modifyData.putqtycount"
                                                autocomplete="off" oncopy="return false" onpaste="return false" (keyup)="validateQty($event, 'PUT')">
                                        </mat-form-field>
                                        <br *ngIf = "isLotLable"/>
                                        <span *ngIf = "isLotLable" class="lotLable2" [class.imnegative] = "lotError">Lots = {{calculateLots(modifyData.putqtycount, modifyData.putscrip.BodLotQuantity) | numberpipe}}</span>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class ="col-12 text-right">
                                        <button  class="btn-base mr-1" mat-flat-button (click)="close()"><span translate>Close</span></button>
                                        <button  class="btn-primary" mat-flat-button (click)="confirmModify()"><span translate>Confirm</span></button>
                                    </div>                                   
                                </div>
                            </div>
                            <div *ngIf='data.status == "Not_Ok"'>
                                <div class="col-12">
                                    <span translate>basket.notretrive</span>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>				
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions *ngIf = "isMobile" class="mat-dialog-actions d-block" [class.mda-d-block] = "isMobile">
    <div class="row">
        <div class="col-12 text-right" [class.p-action]="isMobile">
			<button  mat-flat-button [class.w-100] = "isMobile" class = "custom-btn btn-primary mr-2" (click) = "onButtonClick()"><span translate>placeorder.open_order_book</span></button>
		</div>
	</div>
	<div class="row pt-2">
		<div class="col-12 text-right" [class.p-action]="isMobile">
			<button  mat-flat-button [class.w-100] = "isMobile" class = "custom-btn btn-primary mr-2" (click) = "onBackOC()"><span translate>basket.back_oc</span></button>
        </div>
	</div>
</mat-dialog-actions>
