import { Component, EventEmitter, Output, ChangeDetectorRef, Input, Inject, Injector, OnInit } from '@angular/core';
import { PtPlusRestService } from '../../../../services/ptplusrest.service';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';

let pcr: PutCallRatioComponent;
@Component({
    selector: 'app-putcallratio',
    templateUrl: './putcallratio.component.html',
    styleUrls: ['./putcallratio.component.scss']
})
export class PutCallRatioComponent implements OnInit {
    displayedColumns = ['TradingSymbol', 'PUT OI', 'CALL OI', 'PUT/CALL RATIO', 'Ratio Type'];
    @Output() loaderEvent = new EventEmitter();
    typesArray = [];
    isLoading: boolean;
    isNoData: boolean;
    type;
    putCallData = [];
    selectedDate: any;
    dates = [];
    pcdata = {};
    isMobile = true;
    utility: any;
    @Input() isCustomDashboard = false;
    Height: any;
    container?: ComponentContainer;
    modesub: Subscription;

    constructor(private injector: Injector, private detector: ChangeDetectorRef, private PS: PtPlusRestService, private translate: TranslateService) {
        pcr = this;
        pcr.utility = Utility;
        if (!Utility.IS_MOBILE) {
            pcr.container = <ComponentContainer>pcr.injector.get('Container');
        }
        pcr.isMobile = Utility.IS_MOBILE;
        pcr.translate.get(['derivative.option', 'derivative.index_option']).subscribe(
            values => {
                this.typesArray = Object.keys(values).map(key => values[key]);
                pcr.type = pcr.translate.instant('derivative.option');
            }
        );
        pcr.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            pcr.changeMode();
        });
    }

    changeMode(){
        pcr.Height = {
            'height': (pcr.container.height) + 'px',
            'overflow': 'auto'
        };
        pcr.detector.detectChanges();
    }

    ngOnInit() {
        if (pcr.container) {
          /*  pcr.container.on('resize', _ => {
                pcr.changeMode();
            });*/
        }
        pcr.getPutCallRatioData('OPTION');
    }

    getPutCallRatioData(type) {
        pcr.isLoading = true;
        pcr.loaderEvent.emit(true);
        pcr.putCallData = []; pcr.dates = [];
        const data = { 'datatype': 'PC_OI', 'type': type, 'limit': 50, 'exchange_segment': 'nse_fo' };
        pcr.PS.getDerivativeService().getPutCallRatioData(data, (code, result, message) => {
            pcr.isLoading = false;
            pcr.loaderEvent.emit(false);
            if (code === 200 && message === 'OK') {
                pcr.isNoData = false;
                pcr.drawPutCallUI(result);
            }
            else {
                pcr.isNoData = true;
            }
        }, (data) => {
            pcr.drawPutCallUI(data);
        });
        if (pcr.isCustomDashboard) {
            pcr.changeMode();
        }
    }

    drawPutCallUI(data) {
        pcr.pcdata = Object.values(data)[0];
        pcr.dates = Object.keys(pcr.pcdata);
        pcr.selectedDate = pcr.dates[1];
        pcr.putCallData = pcr.pcdata[pcr.selectedDate];
    }

    selectDate(date) {
        pcr.putCallData = pcr.pcdata[date];
    }

    selectType(type) {
        if (pcr.translate.instant('derivative.option') === type)
            pcr.getPutCallRatioData('OPTION');
        else
            pcr.getPutCallRatioData('INDEXOPTION');
    }

    getSymbolName(tsym) {
        if (tsym)
            return tsym.split(/\d/)[0];
        else
            return '-';
    }

    getStrikePrice(tsym) {
        if (tsym)
            return tsym.match(/\d+/g)[1];
        else
            return '-';
    }
}
