import { Component, Inject, OnInit, Output, ViewChild, EventEmitter, Input, Injector, ChangeDetectorRef, ElementRef , HostListener, OnDestroy } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Observable, Subscription, Subject } from 'rxjs';
import { ScripModel } from '../../../../model/scrip.model';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChannelType, Utility } from 'src/app/services/utility';
import { Mouse } from 'src/app/services/mouse';
import { SearchComponent } from '../search/search.component';
import { ManageWLComponent } from './managewatchlist/managewatchlist.component';
import { StockMoreOptionComponent } from '../../stockmoreoption/stockmoreoption.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Keys } from 'src/app/services/keys';
import { WatchlistPopupComponent } from './watchlistpopup/watchlistpopup.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from '../../../../services/tableutility';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';
import { PtPlusRestService } from '../../../../services/ptplusrest.service';
import { WatchlistSettingsComponent } from './watchlistsettings/watchlistsettings.component';
import { MatSelect } from '@angular/material/select';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';

export interface DialogData {
  heading: string;
  message: string;
  fromOrder: boolean;
  fromCSV: boolean;
}

@Component({
  selector: 'app-watchlist',
  templateUrl: '../../../'+environment.baseviewpath+'/home/watchlist/watchlist.component.html',
  styleUrls: ['./watchlist.component.scss']
})

export class WatchlistComponent  extends BaseComponentDirective implements OnInit, OnDestroy {
  targetElement: Element;
  displayedColumns;
  @Output() load = new EventEmitter<boolean>();
  @Output() openStockDetailPage = new EventEmitter<string>();
  @Output() openChartPage = new EventEmitter<string>();
  @Output() addStockWL = new EventEmitter<string>();
  @Output() manegeWL = new EventEmitter<boolean>();
  @Input() isMobile: boolean;
  @Input() workspaceName: string;
  @Input() isCustomDashboard: boolean;
  @Input() inputs: any;
  @ViewChild('matref', { static: false }) matref: MatTabGroup;
  @ViewChild('watchlistSelect') watchlistSelect: MatSelect;
  @ViewChild('menuTrigger') matMenuTriggerClose;
  watchnamelist = [];
  scripResult = [];
  dataArray;
  isLoader = false;
  isFirstTime = true;
  wlName = '';
  isDelete = false;
  checkstockdata = '';
  selecttoken = [];
  selectexseg = [];
  isdisable = true;
  isDeleteDisable = false;
  noData = false;
  scripListLength;
  utility: any;
  hideColArr = [];
  Height: {};
  DHeight:{};
  isDHeight = true;
  left: any;
  container;
  modesub: Subscription;
  cellview;
  errorbtntext = '';
  isManageColumns = true;
  predefinedWatchlist = [];
  flashType = 'defaultFlash';
  selectedFilter = '';
  filterKey : any;
  filterValue : any;
  isReload = false;
  selectedRowIndex: any;
  isSelectedRow: boolean = false;
  totalheight;
  M;
  matChipItems = ['Asc', 'Desc'];
  sortItemList = ['Unsorted', 'Symbol','LTP', '%Change'];
  chipSelected = this.matChipItems[0];
  selectedSortedItem = this.sortItemList[0];
  filteObject : any;
  sortingDictionary = new Map<any, any>();
  isTopSticky = false;
  isTabChanged = false;
  deleteSub: Subscription;
  createGttFlag = environment.isGTTEnable;

  constructor(elRef: ElementRef, private tradingRestService: TradingRestService, private router: Router, private dialog: MatDialog,
    private snackBar: MatSnackBar, private _bottomSheet: MatBottomSheet,
    private translate: TranslateService, private injector: Injector, private detector: ChangeDetectorRef, private ptPlusRestService: PtPlusRestService) {
    super(elRef.nativeElement);
    this.errorbtntext = this.translate.instant('all.add_watchlist');
    this.M = Mouse;
    this.utility = Utility;
    this.checkstockdata = '';
    this.isLoader = true;
    this.getColumns();
    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      this.flashType = Preferences.getItem('flashType');
    }
    this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      this.changeMode();
    });
    Utility.watchlistData.subscribe(data => {
      this.resetSortingFilter();
      this.getAllWatchlistData();
    });
    Utility.watchlistDataFromMWL.subscribe(data => {
      this.getAllWatchlistData();
    });
    Utility.getColInWL.subscribe(() => {
      this.updateColumns();
    });
    Utility.flashType.subscribe(obj => {
      this.flashType = obj;
    });
    Utility.selectWLTab.subscribe(data => {
      if(data === 'WatchList' && !this.isMobile)
      {
        this.highlight(this.scripResult[0], 0);
      }
    });
    Utility.webReloadComponent.subscribe((data)=>
    {
      if(this.isReload === false && data === 'Watchlist')
      {
        this.isReload = true;
        this.resetSortingFilter();
        this.getWatchlistData();
      }
    });
  }

  getColumns()
  {
    if(Preferences.getItem('displayedColumns', ReturnType.Undefined) !== undefined){
      Preferences.removeItem('displayedColumns')
    }
    let tempColumns = Preferences.getItem('watchlistColumns', ReturnType.Undefined);
    if (!tempColumns)
    {
      tempColumns = JSON.parse(JSON.stringify(TableUtility.watchlistPopupConfig));
      Preferences.setItem('watchlistColumns', tempColumns)
    }
    this.displayedColumns = tempColumns.filter(ele => ele.checked).map(ele => ele.key);
  }
  updateColumns()
  {
    let tempColumns = Preferences.getItem('watchlistColumns', ReturnType.Undefined);
    this.displayedColumns = tempColumns.filter(ele => ele.checked).map(ele => ele.key);
  }

  ngOnDestroy(): void {
    this.onDisappearing();
    this.deleteSub.unsubscribe();
  }
 
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    if (width < Utility.MinWidth)
    this.cellview = true;
    else
    this.cellview = false;
    this.changeMode();
  }
  sortingItemSelected(sortingItem)
  {
    if(this.sortingDictionary === undefined || !this.sortingDictionary.has(sortingItem))
    {
      this.sortingDictionary.set(sortingItem, this.matChipItems[1]);
    }

    if(this.selectedSortedItem !== sortingItem && this.sortingDictionary.has(this.selectedSortedItem))
    {
      this.sortingDictionary.set(this.selectedSortedItem, this.matChipItems[1]);
      this.selectedSortedItem = '';
    }
  
    if(this.sortingDictionary.get(sortingItem) as string === this.matChipItems[0])
    {
      this.chipSelected = this.matChipItems[1]; 
    }
    else
    {
      this.chipSelected = this.matChipItems[0]; 
    }
    this.selectedSortedItem = sortingItem;
    this.sortingDictionary.set(sortingItem, this.chipSelected);
    this.filteObject = {[this.chipSelected] : this.selectedSortedItem};
    this.selectedFilter = this.filteObject;
    this.dataSortingInMobile(this.filteObject);
  }

  matMenuClosed()
  {
  }

  changeMode() {
    if (this.container?.width !== null){
      this.totalheight = (this.container.height - TableUtility.WHeight);
      this.Height = {
        'height': this.totalheight + 'px',
        'overflow': 'auto',
        'scroll-behavior': 'smooth'
      };
      this.left = {
        'left': (this.container.width - TableUtility.WLeft) + 'px'
      };
      if (this.container.width < Utility.MinWidth) {
        this.cellview = true;
        this.isManageColumns = false;
        if (this.dataArray) this.dataSortingInMobile(this.selectedFilter);
      }
      else {
        this.cellview = false;
        this.isManageColumns = true;
        this.scripResult.map((data: any) => {
          data.show = false;
        });
      }
      this.detector.detectChanges();
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (Utility.IS_MOBILE_LAND) {
      if(window.scrollY > TableUtility.WATCHLIST_HEADER_HEIGHT && this.isDHeight){
        this.DHeight = {
          'height': '100vh',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };
        let element:HTMLElement;
        element = document.getElementById("wl_tbl1") as HTMLElement;
        element?.focus();
        this.isDHeight = false;
      }
      else if(window.scrollY < TableUtility.WATCHLIST_HEADER_HEIGHT){
        this.DHeight = {
          'height': '100%',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };
        this.isDHeight = true;
      }
    }
  }

  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      this.resetSortingFilter();
      this.getAllWatchlistData();
      event.next();
    }, 1000);
  }
  
  alert(message: string) {
    //alert(message);
  }
  
  ngOnInit() {
     this.targetElement = document.querySelector('html');
    if (this.isCustomDashboard) {
      this.changeMode();
      /* this.container.on('resize', _ => {
        this.changeMode();
      });
      this.container.on('show', _ => {
        setTimeout(() => {
          this.changeMode();
        }, 50);
      });*/
    }
    this.getAllWatchlistData();
    Utility.watchlistSortIconClicked.subscribe(data =>
    {
      this.selectedFilter = data;
      this.dataSortingInMobile(data);
    })
    this.deleteSub = Utility.watchlistAction.subscribe((pressedKey) => {
      if (Utility.selectedTab === TableUtility.watchlist && !Utility.isDialogOpened && !this.isMobile) {
        if (pressedKey === 'UP' || pressedKey === 'DOWN') {
          const scripList = [...Utility.watchlistDataMap.keys()];
          let index = scripList.indexOf(this.wlName);
          if (pressedKey === 'UP') {
            (index === 0) ? index = scripList.length - 1 : index--;
          } else if (pressedKey === 'DOWN') {
            (index ===  scripList.length - 1) ? index = 0 : index ++;
          }
          this.wlName = scripList[index];
          this.selectTab1();
        }
        else if (pressedKey === 'PLUS' && !this.predefinedWatchlist.includes(this.wlName) && this.wlName !== undefined) {
          this.addStock();
        }
        else if (pressedKey === 'DEL' && !this.predefinedWatchlist.includes(this.wlName)) {
          this.deleteSpecificStock(this.scripResult[Utility.selectedRowIndex], Utility.selectedRowIndex);
        }
        else if (pressedKey === 'CHART') {
          this.openChart(this.scripResult[this.selectedRowIndex]);
        }
      }
    });
  }

  dataSortingInMobile(sortingSelection)
  {
    const originalWatchList = Array.from(this.dataArray);
    this.filterKey = Object.keys(sortingSelection)[0];
    this.filterValue = Object.values(sortingSelection)[0];

    if(this.filterKey === 'Asc')
    {
      if(this.filterValue === 'Symbol')
      {
        this.scripResult = originalWatchList.slice().sort((a,b) =>
        (a['TradingSymbol'] > b['TradingSymbol']) ? 1 : ((b['TradingSymbol'] > a['TradingSymbol']) ? -1 : 0));
      }
      else if(this.filterValue === 'LTP')
      {
        this.scripResult = originalWatchList.slice().sort((a, b) =>
        (Utility.getNumber(a['LastTradePrice']) > Utility.getNumber(b['LastTradePrice'])) ? 1 : ((Utility.getNumber(b['LastTradePrice']) > Utility.getNumber(a['LastTradePrice'])) ? -1 : 0));
      }
      else if(this.filterValue === '%Change')
      {
        this.scripResult = originalWatchList.slice().sort((a, b) =>
        (Utility.getNumber(a['PercentageChange']) > Utility.getNumber(b['PercentageChange'])) ? 1 : ((Utility.getNumber(b['PercentageChange']) > Utility.getNumber(a['PercentageChange'])) ? -1 : 0));      
      }
      // else if(this.filterValue === 'Abs %Change')
      // {
      //   this.scripResult = originalWatchList.slice().sort((a, b) =>
      //   (Utility.getNumber(Math.abs(a['PercentageChange'])) > Utility.getNumber(Math.abs(b['PercentageChange']))) ? 1 : ((Utility.getNumber(Math.abs(b['PercentageChange'])) > Utility.getNumber(Math.abs(a['PercentageChange']))) ? -1 : 0));
      // }
      else if (this.filterValue === 'Unsorted')
      {
        this.scripResult = originalWatchList;
      }
    }
    else if(this.filterKey === 'Desc')
    {
      if(this.filterValue === 'Symbol')
      {
        this.scripResult = originalWatchList.slice().sort((a,b) =>
        (a['TradingSymbol'] < b['TradingSymbol']) ? 1 : ((b['TradingSymbol'] < a['TradingSymbol']) ? -1 : 0));
      }    
      else if(this.filterValue === 'LTP')
      {
        this.scripResult = originalWatchList.slice().sort((a, b) =>
        (Utility.getNumber(a['LastTradePrice']) < Utility.getNumber(b['LastTradePrice'])) ? 1 : ((Utility.getNumber(b['LastTradePrice']) < Utility.getNumber(a['LastTradePrice'])) ? -1 : 0));      
      }
      else if(this.filterValue === '%Change')
      {
        this.scripResult = originalWatchList.slice().sort((a, b) =>
        (Utility.getNumber(a['PercentageChange']) < Utility.getNumber(b['PercentageChange'])) ? 1 : ((Utility.getNumber(b['PercentageChange']) < Utility.getNumber(a['PercentageChange'])) ? -1 : 0));      
      }
      // else if(this.filterValue === 'Abs %Change')
      // {
      //   this.scripResult = originalWatchList.slice().sort((a, b) =>
      //   (Utility.getNumber(Math.abs(a['PercentageChange'])) < Utility.getNumber(Math.abs(b['PercentageChange']))) ? 1 : ((Utility.getNumber(Math.abs(b['PercentageChange'])) < Utility.getNumber(Math.abs(a['PercentageChange']))) ? -1 : 0));        
      // }
      else if (this.filterValue === 'Unsorted')
      {
        this.scripResult = originalWatchList.reverse();
      }
    }
    else
    {
      this.scripResult = originalWatchList;      
    }
  }

  onAppearing() {
    Utility.resumeChannel(this.tradingRestService, ChannelType.Watchlist);
  }

  onDisappearing() {
    Utility.pauseChannel(this.tradingRestService, ChannelType.Watchlist);
  }

  getAllWatchlistData() {
    this.isLoader = true;
    this.load.emit(this.isLoader);
    this.tradingRestService.getWatchListService().getAllWatchlist(function(list, code, msg){
     if (code !== 300) {
      this.errorbtntext = this.translate.instant('all.add_watchlist');
      (list['values'] !== undefined) ? Preferences.setItem('watchlistname', list['values']) : Preferences.removeItem('watchlistname');
      if (code === 200)
      {
        this.watchnamelist = list['values'];
      }
      this.tradingRestService.getWatchListService().getPredefinedWatchlistData(function(code, data, message){
        this.isLoader = false;
        this.load.emit(this.isLoader);
        if (code === 200)
        {
          // this.watchnamelist = data.concat(this.watchnamelist);
          this.predefinedWatchlist = data;
          const name = Preferences.getItem('defaultMW');
          if (name === 'null' || !name || name === undefined || name === 'undefined' || typeof name === 'object') {
            this.wlName = (this.predefinedWatchlist.length > 0) ? this.predefinedWatchlist[0] : this.watchnamelist[0];
          }
          else {
            this.wlName = name;
          }
          Preferences.setItem('defaultMW', this.wlName);
        }

        if (this.watchnamelist.length === 0 && this.predefinedWatchlist.length === 0)
        {
          this.noData = true;
        }
        this.getWatchlistData();
      }.bind(this));
      }
      else {
        this.isLoader = false;
        this.load.emit(this.isLoader);
        this.noData = true;
        this.errorbtntext = this.translate.instant('all.reload');
      }
    }.bind(this), this.setPreWatchList.bind(this));
  }

  setPreWatchList(data) {
    Preferences.removeItem('watchlistname');
    Preferences.setItem('watchlistname', data['values']);
    this.watchnamelist = data['values'];
    if (Preferences.getItem('defaultMW') === 'null') {
      this.wlName = this.watchnamelist[0];
    }
    else {
      this.wlName = Preferences.getItem('defaultMW');
    }
  }

  sortWatchlist(event) {
    const data = Array.from(this.dataArray);
    if (event.active === 'TradingSymbol' || event.active === 'CompanyName') {
      if (event.direction === 'asc')
        this.scripResult = data.sort((a, b) => (a[event.active] > b[event.active]) ? 1 : ((b[event.active] > a[event.active]) ? -1 : 0));
      else
        this.scripResult = data.sort((a, b) => (a[event.active] < b[event.active]) ? 1 : ((b[event.active] < a[event.active]) ? -1 : 0));
    }
    else {
      if (event.direction === 'asc')
        this.scripResult = data.sort((a, b) =>
          (Utility.getNumber(a[event.active]) > Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) > Utility.getNumber(a[event.active])) ? -1 : 0));
      else
        this.scripResult = data.sort((a, b) =>
          (Utility.getNumber(a[event.active]) < Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) < Utility.getNumber(a[event.active])) ? -1 : 0));
    }
  }



  result(option) {
    const obj = {formWL: true, data: option};
    if (Utility.IS_MOBILE && !this.isDelete) {
      this._bottomSheet.open(StockMoreOptionComponent, {
        data: obj, restoreFocus: false
      });
    }
  }

  // addWL() {
  //   if (this.isMobile) {
  //     this.router.navigate(['/managewatchlist']);
  //   } else {
  //     Utility.openPage(this.router, 'watchList', 'managewatchlist', ManageWLComponent, this.dialog, 'popup');
  //   }
  // }

  manageWL() {
    this.watchlistSelect.close();
    if (this.errorbtntext === this.translate.instant('all.reload')){
      this.getAllWatchlistData();
    }
    else {
    if (this.isMobile) {
      if (Utility.IS_MOBILE_LAND)
        this.router.navigate(['/watchlistsettings'], { queryParams: {'showManageCol': true, 'formCustomDB': false, 'isOpen': true}});
      else
        this.router.navigate(['/managewatchlist']);
    } else {

      this.manegeWL.emit(this.isManageColumns);
     // Utility.openPage(this.router, 'watchList', 'managewatchlist', ManageWLComponent, this.dialog, 'popup', ' ', '580px', '500px', this);
    }
  }
  }

  addStock() {
    if (Utility.IS_MOBILE) {
      this.router.navigate(['/search'], { queryParams: { wlname: this.wlName, isfromWL: true } });
    } else {
      const obj = { 'wlname': this.wlName, isformWL: true };
      this.addStockWL.emit(JSON.stringify(obj));
      // const data_obj = { wlname: this.wlName, isfromWL: true };
      // Utility.openPage(this.router, 'watchList', 'search', SearchComponent, this.dialog, 'popup', data_obj, '580px', '500px', this);
    }
  }

  deleteStock() {
    this.isDelete = true;
  }

  onCheck(data, event) {
    if (event.checked) {
      this.isdisable = false;
      this.selecttoken.push(data.Token);
      this.selectexseg.push(data.ExchangeSegment);
    } else {
      if (this.selecttoken.indexOf(data.Token) !== -1) {
        this.selecttoken.splice(this.selecttoken.indexOf(data.Token), 1);
      }
      if (this.selectexseg.indexOf(data.ExchangeSegment) !== -1) {
        this.selectexseg.splice(this.selectexseg.indexOf(data.ExchangeSegment), 1);
      }
      if (this.selecttoken.length === 0) {
        this.isdisable = true;
      }
    }
  }

  deleteWL() {
    const dialogRef = this.dialog.open(MyAlertDialogComponent,
      { data: {heading: this.translate.instant('all.confirm_WL_hed'), message: this.translate.instant('all.confirm_WL_msg') + this.wlName + ' ?', disableClose: true }});
    dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') {
          this.isLoader = true;
          this.load.emit(this.isLoader);
          this.tradingRestService.getWatchListService().deleteWL(this.wlName, (list, code, msg) => {
            this.isLoader = false;
            this.load.emit(this.isLoader);
            if (code === 200 && msg === 'OK') {
              this.snackBar.open(this.translate.instant('all.watchlist_del_msg'), '', { duration: 2000, panelClass: ['sucessSnackBar'] });
              this.watchnamelist.splice(this.watchnamelist.indexOf(this.wlName), 1);
              Preferences.setItem('watchlistname', this.watchnamelist);
              Preferences.setItem('defaultMW', (this.watchnamelist.length > 0) ? this.watchnamelist[this.watchnamelist.length - 1] : this.predefinedWatchlist[0]);
              this.getAllWatchlistData();
              this.isDelete = false;
            } else {
              this.snackBar.open(this.translate.instant('all.del_defaultWL_msg'), '', { duration: 2000, panelClass: ['sucessSnackBar'] });
            }
          });
        } else {
          this.isDelete = false;
        }
    });
  }

  deleteSpecificStock(item: any, i) {
    if (this.scripResult.length === 1) {
      this.snackBar.open(this.translate.instant('all.delete_last_scrip_msg'), '', { duration: 2000 });
    }
    else {
      this.isLoader = true;
      this.load.emit(this.isLoader);
      let scripObj;
      if (environment.isHypersync)
        scripObj = item.ExchangeSegment + '|' + item.Token;
      else
        scripObj = Utility.segmentToExchange(item.ExchangeSegment) + '|' + item.Token;
      this.isDeleteDisable = true;
      Utility.watchlistToastCnt = 0;
      this.tradingRestService.getWatchListService().deleteScripToWL(scripObj, this.wlName, function(list, code, msg){
        this.isLoader = false;
        this.isDeleteDisable = false;
        this.load.emit(this.isLoader);
        this.isSelectedRow = true;
        this.selectedRowIndex = (i === this.scripResult.length - 1)?this.scripResult.length - 2 : i;
        if (code === 200) {
          this.resetSortingFilter();
          if(Utility.watchlistToastCnt === 0)
            this.snackBar.open(this.translate.instant('all.deleted_msg'), '', { duration: 2000, panelClass: ['sucessSnackBar'] });
          if (this.wlName !== undefined)
          this.tradingRestService.getWatchListService().getScriptDetailforWL('Y', this.wlName,
            this.GetScripDetailResponse.bind(this), (function(data){}.bind(this)) );
        }
        else
        {
          if(Utility.watchlistToastCnt === 0)
            this.snackBar.open(this.translate.instant('all.delete_last_scrip_msg'), '', { duration: 2000 });
        }
        if(Utility.watchlistAction.observers.length > 1)
          Utility.watchlistToastCnt++;
      }.bind(this));
    }
  }

  deleteStockWL() {
    if (this.selecttoken.length === this.scripListLength)
      this.deleteWL();
    else {
      for (let i = 0; i < this.selecttoken.length; i++) {
        if (environment.isHypersync)
        this.checkstockdata += Utility.exchangeToSegment(this.selectexseg[i]) + '|' +
          this.selecttoken[i] + ',';
        else
          this.checkstockdata += this.selectexseg[i] + '|' +
            this.selecttoken[i] + '#';
      }
      this.checkstockdata = this.checkstockdata.substring(0, this.checkstockdata.length - 1);
      this.isLoader = true;
      this.load.emit(this.isLoader);
      this.tradingRestService.getWatchListService().deleteScripToWL(this.checkstockdata, this.wlName, function(list, code, msg){
        this.isdisable = true;
        this.isDelete = false;
        this.selecttoken = [];
        this.selectexseg = [];
        this.checkstockdata = '';
        this.isLoader = false;
        this.load.emit(this.isLoader);
        if (code === 200) {
          this.resetSortingFilter();
          this.snackBar.open(this.translate.instant('all.deleted_msg'), '', { duration: 2000, panelClass: ['sucessSnackBar'] });
          if (this.wlName !== undefined)
          this.tradingRestService.getWatchListService().getScriptDetailforWL('Y', this.wlName,
            this.GetScripDetailResponse.bind(this), (function(data){}.bind(this)) );
        }
        else
          this.snackBar.open(this.translate.instant('all.delete_last_scrip_msg'), '', { duration: 2000 });
      }.bind(this));
    }
  }

  reset() {
    this.isDelete = false;
    this.selecttoken = [];
    this.selectexseg = [];
    this.scripResult.forEach(result => {
      result.checked = false;
    });
    this.isdisable = true;
  }

  selectTab1() {
    if (this.wlName !== undefined){
      this.isTabChanged = true;
      Preferences.setItem('defaultMW', this.wlName);
      Preferences.removeItem('stocks');
      this.resetSortingFilter();
      this.getWatchlistData();
    }
  }

  resetSortingFilter() {
    this.chipSelected = this.matChipItems[0]; 
    this.selectedFilter = "";
    this.selectedSortedItem = this.sortItemList[0];
    this.sortingDictionary.clear();
  }

  hideCol() {
    Utility.openPage(this.router, 'watchList', 'watchListPopup', WatchlistPopupComponent, this.dialog, 'popup', '',
      '580px', '500px', this);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== 0 && event.currentIndex !== 1) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      Preferences.setItem('displayedColumns', this.displayedColumns);
    }
  }

  getWatchlistData() {
    this.isLoader = true;
    this.load.emit(this.isLoader);
    if (this.wlName !== undefined && this.wlName !== 'undefined') {
      this.tradingRestService.getWatchListService().getScriptDetailforWL('Y', this.wlName,
        this.GetScripDetailResponse.bind(this), this.preScripDetailResponse.bind(this));
    }
  }

  preScripDetailResponse(data) {
    this.scripListLength = data.length;
    this.scripResult = data;
    this.dataArray = Array.from(this.scripResult);
  }

  GetScripDetailResponse(list, code, msg) {
    this.isLoader = false;
    this.isReload = false;
    this.scripListLength = list.length;
    this.load.emit(this.isLoader);
    list.forEach(function (element) {
      element.checked = false;
    });
    this.scripResult = list;
    setTimeout(() => {
      if (!this.isMobile)
      {
        if(this.isTabChanged){
          this.selectedRowIndex = -1;
          this.highlight(this.scripResult[0], 0);
          this.isSelectedRow = false;
          this.isTabChanged = false;
        } else if(this.isSelectedRow) {
          this.highlight(this.scripResult[this.selectedRowIndex], this.selectedRowIndex);
          this.isSelectedRow = false;
        } else {
          this.highlight(this.scripResult[0], 0);
        }
      }
    }, 200);
    this.dataArray = Array.from(this.scripResult);
  }

  openPlaceOrder(buttontype, data) {
    Utility.openPlaceOrder(this.router, data.Token, data.ExchangeSegment, buttontype, this.dialog);
  }
  openStockDetail(data) {
    this.scripResult.map((data: any) => {
        data.show = false;
    })
    // const obj = { 'token': data.Token, 'exch': data.ExchangeSegment, 'symbol': data.TradingSymbol };
    const obj = {'isCustomDashboard': true,'fromMultiView': true,'fromStockDetail': true,'isHeader': true,'MVtype':'stock','backbtnEnable':false, 'IsStockSpecific': false, 'c_name': 'watchlist', 'token': data.Token, 'exch': data.ExchangeSegment, 'symbol': data.TradingSymbol, 'series': data.InstrumentName};
    Utility.SELECTED_SCRIP = Utility.getScripModel(data.Token,data.ExchangeSegment);
    this.outputs.emit(obj);
    // this.openStockDetailPage.emit(JSON.stringify(obj));
  }

  openTrigger(data) {
    Utility.openCreateTrigger(this.router, data.Token, data.ExchangeSegment, this.dialog);
  }

  handleMouseOver(row) {
    const Symbolname = row.TradingSymbol;
    this.scripResult.map((data: any) => {
      if (Symbolname && data.TradingSymbol && (data.TradingSymbol === Symbolname)) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const Symbolname = row.TradingSymbol;
    this.scripResult.map((data: any) => {
      if (data.TradingSymbol === Symbolname) {
        data.show = false;
      }
    });
  }
  
  highlight(row: any, i: number){
    Mouse.IS_MOUSE = false;
    if(this.selectedRowIndex !== i && !Utility.IS_MOBILE) {
      let element:HTMLElement;
      if(this.cellview) {
        element = document.getElementById("wl_cell"+i) as HTMLElement;
      }
      else {
        element = document.getElementById("wl_tbl"+i) as HTMLElement;
      }
      element?.focus();
    }
    this.selectedRowIndex = i;
    Utility.selectedRowIndex = i;
    Utility.SELECTED_SCRIP = row;
    Utility.selectedTab = TableUtility.watchlist;
    if(this.isMobile) {
      this.result(this.scripResult[i]);
    }
  }

  @HostListener('window:keydown.arrowdown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    //if (Utility.selectedTab === TableUtility.watchlist && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Watchlist', this.workspaceName)) {
    if (Utility.selectedTab === TableUtility.watchlist && !Utility.isDialogOpened) {
      event.preventDefault();
      if(((this.selectedRowIndex + 1) < this.scripResult.length)) {
        var nextrow = this.scripResult[this.selectedRowIndex + 1];
        this.highlight(nextrow, this.selectedRowIndex + 1);
      } else {
      this.highlight(this.scripResult[0], 0);
    }
    }
  }

  @HostListener('window:keydown.arrowup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    //if (Utility.selectedTab === TableUtility.watchlist && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Watchlist', this.workspaceName)) {
    if(Utility.selectedTab === TableUtility.watchlist && !Utility.isDialogOpened){
      event.preventDefault();
      if(((this.selectedRowIndex - 1) >= 0)) {
        var nextrow = this.scripResult[this.selectedRowIndex - 1];
        this.highlight(nextrow, this.selectedRowIndex - 1); 
      }  else {
        this.highlight(this.scripResult[this.scripResult.length - 1], this.scripResult.length - 1);
      }
    }
  }

  openChart(data)
  {
    this.scripResult.map((data: any) => {
      data.show = false;
    });
    const obj = { 'token': data.Token, 'exch': data.ExchangeSegment, 'symbol': data.TradingSymbol };
    Utility.SELECTED_SCRIP = Utility.getScripModel(data.Token,data.ExchangeSegment);
    this.openChartPage.emit(JSON.stringify(obj));
  }
  setBaseVisibility(visible: boolean){
    if(visible){
      setTimeout(() => {
        this.onAppearing();
      }, 50);
    }
    else{
      this.onDisappearing();
    }
  }
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: '../../../'+environment.baseviewpath+'/home/watchlist/alert-dialog.component.html',

})
export class MyAlertDialogComponent {
  @ViewChild('v1') b1: ElementRef;
  @ViewChild('b2') b2: ElementRef;
  selectbtn = 0;
  constructor(
    public dialogRef: MatDialogRef<MyAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  keyleft() {
    this.setFocus();
  }
  
  keyright() {
    this.setFocus();
  }

  setFocus(){
    // switch ( this.selectbtn)
    // {
    //   case 0:
    //     this.selectbtn =1;
    //   break;
    //   case 1:
    //     this.selectbtn =2;
    //     this.b2.nativeElement.focus();
    //     break;
    //   case 2:
    //     this.selectbtn =1;
    //     this.b1.nativeElement.focus();
    //     break;
    // }



    //left right key events was not working as buttons will not be available in dom while initializing.
  }

  keyenter(ev) {
    // ev.preventDefault(); //As event is preventing logout on enter is not working.
    switch ( this.selectbtn)
    {
      case 1:
        this.dialogRef.close('cancel');
        break;
      case 2:
        this.dialogRef.close('confirm');
        break;
    }
  }
}
