import { Component, OnInit, EventEmitter, Output, Inject, Input, ChangeDetectorRef, Injector, OnDestroy, ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR, ViewChild, HostListener, ElementRef } from '@angular/core';
import { TradingRestService } from '../../../services/tradingrest.service';
import { OrderModel } from '../../../model/order.model';
import { Router } from '@angular/router';
import { ChannelType, Utility } from 'src/app/services/utility';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatDialog } from '@angular/material/dialog';
import { OrderHistoryComponent } from '../orderhistory/orderhistory.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OrderMoreOptionComponent } from './ordermoreoption/ordermoreoption.component';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { PlaceorderComponent } from '../placeorder/placeorder.component';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { Subscription, Subject } from '../../../../../node_modules/rxjs';
import { ComponentContainer } from 'golden-layout';
import * as $ from 'jquery';
import { TableUtility } from 'src/app/services/tableutility';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { OrderdetailComponent } from '../orderdetail/orderdetail.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CSVUtility } from 'src/app/services/CSVUtiity';
import { Mouse } from 'src/app/services/mouse';
import { ThematicBasketService } from 'src/app/services/thematicbasket/thematic.service';
import { extend } from 'jquery';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { environment } from 'src/environments/environment';


declare const moment : any
let autoGenId = 0;
let orderBook : OrderBookComponent;
@Component({
  selector: 'app-orderbook',
  templateUrl: '../../'+environment.baseviewpath+'/orderbook/orderbook.component.html',
  styleUrls: ['./orderbook.component.scss']
})
export class OrderBookComponent extends BaseComponentDirective implements OnInit, OnDestroy {
  targetElement: Element;
  @Output() load = new EventEmitter<boolean>();
  @Output() openStockDetailPage = new EventEmitter<string>();
  @Input() isCustomDashboard: boolean;
  @Input() selectedBookTabIndex: any;
  @Input() workspaceName: string;
  @ViewChild('sortMenu') sortMenu;
  @ViewChild('menuTrigger') matMenuTriggerClose;
  @Output() openManageBooks = new EventEmitter<string>();
  @Output() totalScripCount = new EventEmitter<number>();
  isLoading: boolean;
  expandText = 'More';
  hideCancel = false;
  openOrderCount;
  checkedOpenOrder = 0;
  searchId;
  dataArray : Array<OrderModel> = [];
  isFromTabChange: boolean;
  isMobile = true;
  orderChecked = new Map();
  selectedLabel = 'ALL';
  isNoData: boolean;
  orderList: Array<OrderModel> = [];
  filteredOrderList: Array<OrderModel> = [];
  utility: any;
  uniqueMap = new Map();
  isFilterNoData: boolean;
  result = [];
  indexArray = [];
  displayArray = [];
  selectedFilter = 'ALL';
  subscription: Subscription;
  webReloadSubscription: Subscription;
  reloadPageSubscription: Subscription;
  sortIconSubscription: Subscription;
  bookColumnSubscription: Subscription;
  orientationSubscription : Subscription;
  displayedColumns;
  Height: any;
  DHeight: {};
  isDHeight = true;
  container;
  left: any;
  isExpandVisible: boolean;
  isExpand = false;
  displayArrLen = 0;
  widthArr = [];
  modesub: Subscription;
  currentTabData;
  cellview: boolean;
  WlArr = [];
  selectedFilterOption = '';
  tempFilteredOrderList: Array<OrderModel> = [];
  isReloadData = false;
  filteredKey : any;
  filteredValue : any;
  isReload = false;
  selectedRowIndex : any;
  isMobileLand;
  totalheight : any;
  M;
  matChipItems = ['Asc', 'Desc'];
  sortItemList = ['Unsorted', 'Symbol', 'Order Time', 'LTP', '%Change'];
  chipSelected = this.matChipItems[0];
  selectedSortedItem = this.sortItemList[0];
  filteObject : any;
  sortingDictionary = new Map<any, any>();
  flashType = 'defaultFlash';

  constructor(elRef: ElementRef,
      private injector: Injector,
      private tradingRestService: TradingRestService, private router: Router,
      public dialog: MatDialog, private _bottomSheet: MatBottomSheet,
      private translate: TranslateService, private detector: ChangeDetectorRef,
      private snackBar: MatSnackBar) {
      super(elRef.nativeElement, 'OrderBook');
      orderBook = this;
    this.M = Mouse;
    this.searchId = 'OrderBookView-' + (autoGenId++);
    this.utility = Utility;
    this.isMobile = Utility.IS_MOBILE;
    this.isMobileLand = Utility.IS_MOBILE_LAND;
    this.sortingDictionary.set(this.selectedSortedItem, this.chipSelected);
    this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
      this.isMobileLand = Utility.IS_MOBILE_LAND;
    });
    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      this.flashType = Preferences.getItem('flashType');
    }
    this.subscription = Utility.ORDER_PLACED_EVENT.subscribe(data => {
      Utility.reloadLimit.next();
      this.callOrderBookService();
    });

    this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      this.changeMode();
    });
    Utility.flashType.subscribe(obj => {
      this.flashType = obj;
    });

    Utility.orderDataSubscription = Utility.orderBookData.subscribe(obj => {
      this.isExpandVisible = false;
      this.isNoData = false;

      const foundIndex = this.orderList.findIndex(x => x.NestOrd === obj.NestOrd);
      if (foundIndex !== -1)
        this.orderList.splice(foundIndex, 1);
      this.orderList.push(obj);

      this.filteredOrderList = [...this.orderList];
      this.ProcessTags();

      if (this.currentTabData)
        this.onTabChange(this.currentTabData.tabData, this.currentTabData.type, this.currentTabData.index);
      else
        this.onTabChange('ALL', 'All', null);

    });

    this.reloadPageSubscription = Utility.orderBookReloadPage.subscribe(data => 
    {
      this.isLoading = true;
      this.load.emit(this.isLoading);
      this.detector.detectChanges();
      this.isReloadData = true;
      this.callOrderBookService();
    })

    this.webReloadSubscription = Utility.webReloadComponent.subscribe(data =>
    {
      if(orderBook.isReload === false && data === 'Order Book')
      {
        orderBook.isReload = true;
        this.onRefresh();
      }
    });

    this.getColumns()
    this.bookColumnSubscription = TableUtility.updateOrderBookColumns.subscribe(data =>{
      this.updateColumns();
    })
    Utility.selectWLTab.subscribe(data => {
      if(data === 'Order Book' && !this.isMobile) {
        this.highlight(this.filteredOrderList[0], 0);
      }
    });
}
myRefreshEvent(event: Subject<any>, message: string) {
  setTimeout(() => {
      this.onRefresh();
      event.next();
  }, 1000);
}

setPositionAndSize(left: number, top: number, width: number, height: number) {
  this.container={'width': width, 'height':height}
  this.setBasePositionAndSize(left, top, width, height);
  if (width < Utility.MinWidth)
  this.cellview = true;
  else
  this.cellview = false;
  this.changeMode();
}


alert(message: string) {
  //alert(message);
}

@HostListener('window:scroll', ['$event'])
onWindowScroll(e) {
  if (Utility.IS_MOBILE_LAND && this.selectedBookTabIndex === 0) {
    if(window.scrollY > (TableUtility.BOOK_HEADER_HEIGHT + this.sortMenu?.nativeElement.scrollHeight) && this.isDHeight){
      this.DHeight = {
        'height': '100vh',
        'overflow': 'auto',
        'scroll-behavior': 'smooth',
      };
      let element:HTMLElement;
      element = document.getElementById("ob_tbl1") as HTMLElement;
      element?.focus();
      this.isDHeight = false;
    }
    else if(window.scrollY <  (TableUtility.BOOK_HEADER_HEIGHT + this.sortMenu?.nativeElement.scrollHeight)){
      this.DHeight = {
        'height': '100%',
        'overflow': 'auto',
        'scroll-behavior': 'smooth'
      };
      this.isDHeight = true;
    }
  }
}

sortingItemSelected(sortingItem)
{
  if(orderBook.sortingDictionary === undefined || !orderBook.sortingDictionary.has(sortingItem))
  {
    orderBook.sortingDictionary.set(sortingItem,orderBook.matChipItems[1]);
  }
  
  if(orderBook.selectedSortedItem !== sortingItem && orderBook.sortingDictionary.has(orderBook.selectedSortedItem))
  {
    orderBook.sortingDictionary.set(orderBook.selectedSortedItem, orderBook.matChipItems[1]);
    orderBook.selectedSortedItem = '';
  }

  if(orderBook.sortingDictionary.get(sortingItem) as string === orderBook.matChipItems[0])
  {
   orderBook.chipSelected = orderBook.matChipItems[1];
  }
  else
  {
    orderBook.chipSelected = orderBook.matChipItems[0]; 
  }
  orderBook.selectedSortedItem = sortingItem;
  orderBook.sortingDictionary.set(sortingItem, orderBook.chipSelected);
  orderBook.filteObject = {[orderBook.chipSelected] : orderBook.selectedSortedItem};
  this.selectedFilterOption = orderBook.filteObject;
  orderBook.SortOrderBookInMobile(orderBook.filteObject);
}

matMenuClosed()
{
}

  changeMode() {
    if (this.container?.width !== null){
      if (this.orderList !== undefined && this.orderList.length === 1) {
        this.totalheight = this.container?.height;
        this.Height = {
          'height': this.container?.height + 'px',
          'overflow': 'auto'
        };
      } else {
        this.totalheight = (this.container?.height - TableUtility.OBHeight);
        this.Height = {
          'height': (this.container?.height - TableUtility.OBHeight) + 'px',
          'overflow': 'auto'
        };
      }
      this.left = {
        'left': (this.container?.width - TableUtility.OBLeft) + 'px'
      };
      this.detector.detectChanges();
      if (Utility.IS_MOBILE) {
        this.displayArrLen = this.displayArray.length;
      } else {
        this.getChipWidth();
      }

      if (this.container?.width < Utility.MinWidth)
      {
        this.cellview = true;
        if(this.tempFilteredOrderList) this.SortOrderBookInMobile(this.selectedFilterOption)
      }
      else {
        this.cellview = false;
        this.filteredOrderList.map((data: any) => {
          data.show = false;
        });
      }
    }
  }

  ngOnInit() {
    this.targetElement = document.querySelector('html');
    if (this.container) {
      /* this.container.on('resize', _ => {
        this.changeMode();
      });
      this.container.on('show', _ => {
        this.callOrderBookService();
        setTimeout(() => {
          this.changeMode();
        }, 50);
      });*/
    }
    this.isLoading = true;
    // if (Preferences.getItem('orderColumns', ReturnType.Undefined) !== undefined && Preferences.getItem('orderColumns') !== null)
    //   this.displayedColumns = Preferences.getItem('orderColumns', ReturnType.Array);
    // else
    //   this.displayedColumns = ['TSym', 'ActionDisplay', 'ProductCode', 'Qty', 'LastTradePrice', 'Price', 'DisplayStatus', 'OrderedTime'];
    this.load.emit(this.isLoading);
    if(this.isMobile){
      this.callOrderBookService();
    }

   this.sortIconSubscription = Utility.orderBookSortIconClicked.subscribe(data =>
    {
      this.selectedFilterOption = data;
      this.SortOrderBookInMobile(data);      
    })
  }

  SortOrderBookInMobile(selectedFilterOption)
  {
    this.filteredKey = Object.keys(selectedFilterOption)[0];
    this.filteredValue = Object.values(selectedFilterOption)[0];

    if(this.filteredKey === 'Asc')
    {
      if(this.filteredValue === 'Symbol')
      {      
        this.filteredOrderList = this.tempFilteredOrderList.slice().sort((a,b) =>
        (a['TSym'] > b['TSym']) ? 1 : ((b['TSym'] > a['TSym']) ? -1 : 0));
      }
      else if(this.filteredValue === 'Order Time')
      {
        this.filteredOrderList = this.tempFilteredOrderList.slice().sort((a, b) =>
        (this.getTime(a['OrderedTime']) > this.getTime(b['OrderedTime'])) ? 1 : ((this.getTime(b['OrderedTime']) > this.getTime(a['OrderedTime'])) ? -1 : 0));      
      }
      else if(this.filteredValue === 'LTP')
      {
        this.filteredOrderList = this.tempFilteredOrderList.slice().sort((a, b) =>
        (Utility.getNumber(a.scripModel['LastTradePrice']) > Utility.getNumber(b.scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.scripModel['LastTradePrice']) > Utility.getNumber(a.scripModel['LastTradePrice'])) ? -1 : 0));
      }
      else if(this.filteredValue === '%Change')
      {
        this.filteredOrderList = this.tempFilteredOrderList.slice().sort((a, b) =>
        (Utility.getNumber(a.scripModel['PercentageChange']) > Utility.getNumber(b.scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.scripModel['PercentageChange']) > Utility.getNumber(a.scripModel['PercentageChange'])) ? -1 : 0));      
      }
      else if (this.filteredValue === 'Unsorted')
      {
        this.filteredOrderList = this.tempFilteredOrderList;
      }
    }
    else if(this.filteredKey === 'Desc')
    {
      if(this.filteredValue === 'Symbol')
      {
        this.filteredOrderList = this.tempFilteredOrderList.slice().sort((a,b) =>
        (a['TSym'] < b['TSym']) ? 1 : ((b['TSym'] < a['TSym']) ? -1 : 0));
      }
      else if(this.filteredValue === 'Order Time')
      {
        this.filteredOrderList = this.tempFilteredOrderList.slice().sort((a, b) =>
        (this.getTime(a['OrderedTime']) < this.getTime(b['OrderedTime'])) ? 1 : ((this.getTime(b['OrderedTime']) < this.getTime(a['OrderedTime'])) ? -1 : 0));      
      }
      else if(this.filteredValue === 'LTP')
      {
        this.filteredOrderList = this.tempFilteredOrderList.slice().sort((a, b) =>
        (Utility.getNumber(a.scripModel['LastTradePrice']) < Utility.getNumber(b.scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.scripModel['LastTradePrice']) < Utility.getNumber(a.scripModel['LastTradePrice'])) ? -1 : 0));
      }
      else if(this.filteredValue === '%Change')
      {
        this.filteredOrderList = this.tempFilteredOrderList.slice().sort((a, b) =>
        (Utility.getNumber(a.scripModel['PercentageChange']) < Utility.getNumber(b.scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.scripModel['PercentageChange']) < Utility.getNumber(a.scripModel['PercentageChange'])) ? -1 : 0));      
      }
      else if (this.filteredValue === 'Unsorted')
      {
        this.filteredOrderList.reverse();
      }
    }
    else
    {
      this.filteredOrderList = this.tempFilteredOrderList;      
    }

    if(this.filteredOrderList.length === 0)
      this.isNoData = true;
    else
      this.isNoData = false;
  }

  addWLdata(stock) {
    const seltctedStock = stock.ExchangeSegment + '|' + stock.Token;
    this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
  }
  callOrderBookService() {
    this.load.emit(true);
    this.isExpandVisible = false;
    this.resetSortingFilter();
    this.tradingRestService.getBookService().getAllOrders(function(list, code, msg) {
      this.isLoading = false;
      orderBook.isReload = false;
      this.load.emit(this.isLoading);
      if (code === 200 && msg === 'OK' && list.length > 0) {
        this.isNoData = false;
        this.orderList = list;
        this.orderList.sort(function(a, b) {
          return parseInt(b.NestOrd, 10) - parseInt(a.NestOrd, 10);
        });
        this.filteredOrderList = this.orderList;
        this.tempFilteredOrderList = this.orderList;
        this.totalScripCount.emit(this.orderList.length);
        this.ProcessTags();
        let decpre;
        this.orderList.forEach(element => {
          const decimalValue = element.Price.toString().split('.')[1];
          decpre = ((decimalValue === undefined) ? 2 : decimalValue.length);
          element['DecimalPrecision'] = decpre;
        });
        this.dataArray = Array.from(this.orderList);
        this.onTabChange('ALL', 'All', null);
        if(this.isReloadData)
        {
          this.isReloadData = false;
          if (this.currentTabData)
            this.onTabChange(this.currentTabData['tabData'], this.currentTabData['type'], this.currentTabData['index']);
          else
            this.onTabChange('ALL', 'All', null);
        }
        setTimeout(()=>
        {
          if (!Utility.IS_MOBILE)
            this.highlight(this.filteredOrderList[0], 0);
        }, 200);
      }
      else {
        this.isNoData = true;
      }
    }.bind(this), this.setPredefinedOrderBook.bind(this));
    if (this.isCustomDashboard) {
      this.changeMode();
    }
  }
  addStock(wl, stockdata) {
    const seltctedStock = stockdata.ExchangeSegment + '|' + stockdata.Token;
    WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
      if (result && msg === 'Success') {
        this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
      } else if (result && msg === 'Fail') {
        this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
      } else {
        this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
      }
    }.bind(this));
  }
  setPredefinedOrderBook(data) {
    this.orderList = data;
    this.isLoading = false;
    this.load.emit(this.isLoading);
    this.orderList = this.orderList;
    this.ProcessTags();
    let decpre;
    this.orderList.forEach(element => {
      const decimalValue = element.Price.toString().split('.')[1];
      decpre = ((decimalValue === undefined) ? 2 : decimalValue.length);
      element['DecimalPrecision'] = decpre;
    });
  }

  sortList() {
    this.filteredOrderList = this.filteredOrderList.sort((a, b) => (a.OrderedTime < b.OrderedTime) ? 1 : ((b.OrderedTime < a.OrderedTime) ? -1 : 0));
  }

  ProcessTags() {
    const resultMap = new Map();
    let obj = {};
    this.openOrderCount = 0;
    this.displayArray = [];
    const displayStatus = [];
    const exchangeArr = [];
    const actionArr = [];
    const symbolArr = [];

    if (this.orderList.length > 1) {
      for (let i = 0; i < this.orderList.length; i++) {

        if (this.orderList[i].DisplayStatus != null) {
          if (!resultMap.has(this.orderList[i].DisplayStatus)) {
            obj = {};
            resultMap.set(this.orderList[i].DisplayStatus, true);
            obj['data'] = this.orderList[i].DisplayStatus;
            obj['type'] = 'DisplayStatus';
            obj['count'] = 1;
            obj['visibility'] = false;

            if (obj['data'] === 'Open'){
              this.openOrderCount = this.openOrderCount + 1;
            }
            displayStatus.push(obj);
          }
          else {
            for (let j = 0; j < displayStatus.length; j++) {
              if (displayStatus[j]['data'] === this.orderList[i].DisplayStatus) {
                displayStatus[j]['count'] = displayStatus[j]['count'] + 1;
                if (this.orderList[i].DisplayStatus === 'Open'){
                  this.openOrderCount = this.openOrderCount + 1;
                }
              }
            }
          }
        }

        if (this.orderList[i].Exchange != null) {
          if (!resultMap.has(this.orderList[i].Exchange)) {
            resultMap.set(this.orderList[i].Exchange, true);
            obj = {};
            obj['data'] = this.orderList[i].Exchange;
            obj['type'] = 'Exchange';
            obj['count'] = 1;
            obj['visibility'] = false;

            exchangeArr.push(obj);
          }

          else {
            for (let j = 0; j < exchangeArr.length; j++) {
              if (exchangeArr[j]['data'] === this.orderList[i].Exchange) {
                exchangeArr[j]['count'] = exchangeArr[j]['count'] + 1;
              }
            }
          }
        }

        if (this.orderList[i].ActionDisplay != null) {
          if (!resultMap.has(this.orderList[i].ActionDisplay)) {
            obj = {};
            resultMap.set(this.orderList[i].ActionDisplay, true);
            obj['data'] = this.orderList[i].ActionDisplay;
            obj['type'] = 'ActionDisplay';
            obj['count'] = 1;
            obj['visibility'] = false;
            actionArr.push(obj);
          }
          else {
            for (let j = 0; j < actionArr.length; j++) {
              if (actionArr[j]['data'] === this.orderList[i].ActionDisplay) {
                actionArr[j]['count'] = actionArr[j]['count'] + 1;
              }
            }
          }
        }

        if (this.orderList[i].TradeSymbol != null) {
          if (!resultMap.has(this.orderList[i].TradeSymbol)) {
            obj = {};
            resultMap.set(this.orderList[i].TradeSymbol, true);
            obj['data'] = this.orderList[i].TradeSymbol;
            obj['type'] = 'TradeSymbol';
            obj['count'] = 1;
            obj['visibility'] = false;
            symbolArr.push(obj);
          }
          else {
            for (let j = 0; j < symbolArr.length; j++) {
              if (symbolArr[j]['data'] === this.orderList[i].TradeSymbol) {
                symbolArr[j]['count'] = symbolArr[j]['count'] + 1;
              }
            }
          }
        }
      }
      this.processChipData(displayStatus.sort(this.compare.bind(this)));
      this.processChipData(exchangeArr.sort(this.compare.bind(this)));
      this.processChipData(actionArr.sort(this.compare.bind(this)));
      this.processChipData(symbolArr.sort(this.compare.bind(this)));
      if (Utility.IS_MOBILE) {
        this.displayArrLen = this.displayArray.length;
      } else {
        this.getChipWidth();
      }
      this.sortList();
    }
  }
  onRefresh() {
    this.isLoading = true;
    this.isReloadData = true;
    this.load.emit(this.isLoading);
    this.detector.detectChanges();
    this.callOrderBookService();
  }

  onReset(){
    this.currentTabData = undefined;
    this.callOrderBookService();
    this.onTabChange('ALL', 'All', null);
  }

  processChipData(dataArr) {
    if (dataArr.length > 1) {
      for (let i = 0; i < dataArr.length; i++) {
        this.displayArray.push(dataArr[i]);
      }
    }
  }

  getChipWidth() {
    setTimeout(() => {
      if (this.sortMenu !== undefined) {
        this.expandText  = 'More';
        const chipArrWidth = this.container?.width - this.sortMenu.nativeElement.scrollWidth - Utility.getChipLetterWidth('ALL') - Utility.getChipLetterWidth('More');
        let WidCount = 0;
        let width = 0;
        this.widthArr = [];
        for (let i = 0; i < this.displayArray.length; i++) {
          width = Utility.getChipLetterWidth(this.displayArray[i].data);
          WidCount = WidCount + width;
          if (WidCount < chipArrWidth) {
            this.widthArr.push(this.displayArray[i]);
            this.isExpandVisible = false;
            this.displayArrLen = this.widthArr.length;
          }
          else {
            this.displayArrLen = this.widthArr.length - 1;
            this.isExpandVisible = true;
            break;
          }
        }
      }
    }, 100);
  }

  compare(a, b) {
    const count1 = a.count;
    const count2 = b.count;
    let comparison = 0;
    if (count1 < count2) {
      comparison = 1;
    } else if (count1 > count2) {
      comparison = -1;
    }
    return comparison;
  }

  sortOrderBook(event) {
    const data = Array.from(this.filteredOrderList);
    if (event.active === 'TSym' || event.active === 'ActionDisplay' || event.active === 'ProductCode' || event.active === 'DisplayStatus') {
      if (event.direction === 'asc')
        this.filteredOrderList = data.sort((a, b) => (a[event.active] > b[event.active]) ? 1 : ((b[event.active] > a[event.active]) ? -1 : 0));
      else
        this.filteredOrderList = data.sort((a, b) => (a[event.active] < b[event.active]) ? 1 : ((b[event.active] < a[event.active]) ? -1 : 0));
    }
    else if(event.active === 'Price')
    {
      if (event.direction === 'asc')
        this.filteredOrderList = data.sort((a, b) =>
          (Utility.getNumber(a[event.active]) > Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) > Utility.getNumber(a[event.active])) ? -1 : 0));
      else
        this.filteredOrderList = data.sort((a, b) =>
          (Utility.getNumber(a[event.active]) < Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) < Utility.getNumber(a[event.active])) ? -1 : 0));
    }
    else if(event.active === 'Qty')
    {
      if (event.direction === 'asc')
        this.filteredOrderList = data.sort((a, b) => ((a['TotalQty'] - a['PendingQty']) > (b['TotalQty'] - b['PendingQty'])) ? 1 : (((b['TotalQty'] - b['PendingQty']) > (a['TotalQty'] - a['PendingQty'])) ? -1 : 0));
      else
        this.filteredOrderList = data.sort((a, b) => ((a['TotalQty'] - a['PendingQty']) < (b['TotalQty'] - b['PendingQty'])) ? 1 : (((b['TotalQty'] - b['PendingQty']) < (a['TotalQty'] - a['PendingQty'])) ? -1 : 0));
    }
    else if(event.active === 'LastTradePrice')
    {
      if (event.direction === 'asc')
        this.filteredOrderList = data.sort((a, b) =>
          (Utility.getNumber(a.scripModel[event.active]) > Utility.getNumber(b.scripModel[event.active])) ? 1 : ((Utility.getNumber(b.scripModel[event.active]) > Utility.getNumber(a.scripModel[event.active])) ? -1 : 0));
      else
        this.filteredOrderList = data.sort((a, b) =>
          (Utility.getNumber(a.scripModel[event.active]) < Utility.getNumber(b.scripModel[event.active])) ? 1 : ((Utility.getNumber(b.scripModel[event.active]) < Utility.getNumber(a.scripModel[event.active])) ? -1 : 0));
    }
    else {
      if (event.direction === 'asc')
        this.filteredOrderList = data.sort((a, b) =>
          (this.getTime(a[event.active]) > this.getTime(b[event.active])) ? 1 : ((this.getTime(b[event.active]) > this.getTime(a[event.active])) ? -1 : 0));
      else
        this.filteredOrderList = data.sort((a, b) =>
          (this.getTime(a[event.active]) < this.getTime(b[event.active])) ? 1 : ((this.getTime(b[event.active]) < this.getTime(a[event.active])) ? -1 : 0));
    }
  }

  getTime(inputDate : any)
  {
    var date = new Date(inputDate);
    var formatted = moment(date).format('HH:mm:ss');
    return formatted;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== 0 && event.currentIndex !== 1) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      Preferences.setItem('orderColumns', this.displayedColumns);
    }
  }

  onTabChange(tabData, type, index) {
    this.checkedOpenOrder = 0;
    this.openOrderCount = 0;
    this.currentTabData = { 'tabData': tabData, 'type': type, 'index': index };
    const tabType = type;
    this.isFromTabChange = false;
    const data = tabData;
    this.selectedLabel = '';
    this.filteredOrderList = [];
    let dataArr = [];
    this.uniqueMap.clear();
    if (data !== 'ALL') {
      if (this.displayArray[index]['visibility'] === true) {
        this.displayArray[index]['visibility'] = false;
      }
      else {
        this.displayArray[index]['visibility'] = true;
        this.indexArray.push(index);
      }
    }
    else {
      this.indexArray.forEach(arrData => {
        if (this.displayArray[arrData]['visibility'] === true)
          this.displayArray[arrData]['visibility'] = false;
      });
    }

    if (this.displayArray && data !== 'ALL') {
      dataArr = this.displayArray.filter(obj => {
        return obj['visibility'] === true;
      });
    }

    if (dataArr.length > 0) {
      dataArr.forEach(arrData => {
        this.uniqueMap.set(arrData['type'], true);
      });

      for (let i = 0; i < this.orderList.length; i++) {
        let count = 0;
        dataArr.forEach(arrData => {
          if (arrData['data'] === this.orderList[i][arrData['type']]) {
            count++;
          }
        });
        if (count === this.uniqueMap.size) {
          this.filteredOrderList.push(this.orderList[i]);
          if (this.orderList[i]['DisplayStatus'] === 'Open'){
            this.openOrderCount = this.openOrderCount + 1;
          }
        }
      }
      this.isFromTabChange = true;
      this.onCheckBoxClicked('ALL', '', '', '');
    }
    else {
      this.selectedLabel = 'ALL';
      this.orderChecked.clear();
      this.filteredOrderList = [...this.orderList];
      for (let i = 0; i < this.filteredOrderList.length; i++){
        if (this.filteredOrderList[i]['DisplayStatus'] === 'Open'){
          if (this.filteredOrderList[i]['CheckBoxValue'] === true){
            this.filteredOrderList[i]['CheckBoxValue'] = false;
          }
          this.openOrderCount = this.openOrderCount + 1;
        }
      }
    }
    this.tempFilteredOrderList = this.filteredOrderList;
    this.noDataCheck();
    this.sortList();
    if(this.selectedSortedItem !== this.sortItemList[0] || (this.selectedSortedItem === this.sortItemList[0] && this.chipSelected === this.matChipItems[1]) && (this.cellview || this.isMobile))
      this.SortOrderBookInMobile(this.selectedFilterOption);
    if (this.filteredOrderList.length > 0 && !Utility.IS_MOBILE)
      this.highlight(this.filteredOrderList[0], 0);
  }

  noDataCheck() {
    if (this.filteredOrderList.length > 0) {
      this.isNoData = false;
    }
    else {
      this.isFilterNoData = true;
      this.isNoData = true;
    }
  }
  exportCSV(){
    if (this.selectedLabel === 'ALL')
      CSVUtility.ExportDataToCSV(this.orderList, 'OrderBook Report', 'Order Book','Order Book Data');
    else {
      const dialogRef = this.dialog.open(MyAlertDialogComponent,
        { data: { heading: this.translate.instant('all.export_hed'), message: this.translate.instant('all.export_msg'),
         disableClose: true,  fromCSV: true}});
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm')
          CSVUtility.ExportDataToCSV(this.filteredOrderList, 'OrderBook Report', 'Order Book','Order Book Data');
        else if (result === 'cancel')
          CSVUtility.ExportDataToCSV(this.orderList, 'OrderBook Report', 'Order Book','Order Book Data');
      })
    }
  }
  onClickOrderDetail(data) {
    Utility.detailedOrder = data;
    if (this.isMobile) {
      const bottomSheetRef = this._bottomSheet.open(OrderMoreOptionComponent, {
        restoreFocus: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result && result.isCancel)
          this.callOrderBookService();
      });
    }
  }

  onCheckBoxClicked(data, nestOrderNumber, event, index){
    let orderCount = 0;
    let eventChecked = false;
    if (!this.isFromTabChange) {
           eventChecked = event.source._checked;
      }
    else{
      this.orderChecked.clear();
    }

    if (data === 'ALL') {
          if (eventChecked || this.isFromTabChange) {
              this.filteredOrderList.forEach((arrData, i) => {
              if (arrData['DisplayStatus'] === 'Open') {
                this.orderChecked.set(arrData['NestOrd'], true);
                this.filteredOrderList[i]['CheckBoxValue'] = true;
                orderCount = orderCount + 1;
                this.isFromTabChange = false;
              }
            });
            this.checkedOpenOrder = orderCount;
          }
          else {
              this.filteredOrderList.forEach((arrData, i) => {
              if (arrData['DisplayStatus'] === 'Open') {
                this.filteredOrderList[i]['CheckBoxValue'] = false;
              }
            });
            this.checkedOpenOrder = 0;
            this.orderChecked.clear();
          }
        }
        else {
          if (this.orderChecked.has(nestOrderNumber)) {
            this.filteredOrderList[index]['CheckBoxValue'] = false;
            this.checkedOpenOrder = this.checkedOpenOrder - 1;
            this.orderChecked.delete(nestOrderNumber);
          }
          else {
            if (data['DisplayStatus'] === 'Open'){
              this.filteredOrderList[index]['CheckBoxValue'] = true;
              this.orderChecked.set(nestOrderNumber, true);
              this.checkedOpenOrder = this.checkedOpenOrder + 1;
            }
          }
        }
      }


  handleButton(buttonType, omo) {
    Utility.detailedOrder = omo;
    let alertMessage = '';
    const addobj = { 'action': 'B', 'exseg': omo.ExchangeSegment, 'omtkn': omo.Token};
    const removeobj = { 'action': 'S', 'exseg': omo.ExchangeSegment, 'omtkn': omo.Token};
    this.filteredOrderList.map((data: any) => {
        data.show = false;
    })

    switch (buttonType) {
      case 'E':
        alertMessage = this.translate.instant('all.exit_ordermsg');
        Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.exit_hed'), message: alertMessage } });
        Utility.dialogRef.afterClosed().subscribe(result => {
          if (result === 'confirm') {
            this.tradingRestService.getBookService().ExitOrder(omo, function(responsedata, code, message) {
              if (responsedata['stat'] === 'Ok') {
                this.callOrderBookService();
                this.snackBar.open(this.translate.instant('all.order_exited'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['successSnackBar'] });
              }
              else
                this.snackBar.open(this.translate.instant('all.order_exit_failed'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            }.bind(this));
          }
        });
        break;
      case 'C':
        alertMessage = this.translate.instant('all.cancel_msg') + omo.SymbolName +  this.translate.instant('all.c_order');
        Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.cancel_hed'), message: alertMessage, fromOrder: true } });
        Utility.dialogRef.afterClosed().subscribe(result => {
          if (result === 'confirm') {
            this.tradingRestService.getBookService().CancelOrder(omo, function(responsedata, code, message)  {
              if (responsedata['stat'] === 'Ok') {
                this.callOrderBookService();
                this.snackBar.open(this.translate.instant('all.order_cancelled'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['successSnackBar'] });
              }
              else
              this.snackBar.open(this.translate.instant('all.order_failed'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
            }.bind(this));
          }
        });
        break;
      case 'M':
        const data_obj = { 'isEdit': true };
        Utility.openPage(this.router, 'orderhistory', 'placeorder', PlaceorderComponent, this.dialog, 'popup', data_obj, '500px', (environment.baseviewpath === 'investor')?'620px':'1250px');
        break;
      case 'H':
        // Utility.openPage(this.router, 'order', 'orderhistory', OrderHistoryComponent, this.dialog, 'popup');
        Utility.openPage(this.router, 'order', 'orderdetail', OrderdetailComponent, this.dialog, 'popup');
        break;
      case 'ADD':
        Utility.openPage(this.router, 'orderDetail', 'placeorder', PlaceorderComponent, this.dialog, 'popup', addobj, '500px', '1250px');
        break;
      case 'REMOVE':
        Utility.openPage(this.router, 'orderDetail', 'placeorder', PlaceorderComponent, this.dialog, 'popup', removeobj, '500px', '1250px');
        break;
      case 'sd':
        // const obj = { 'token': omo.Token, 'exch': omo.ExchangeSegment, 'symbol': omo.TSym };
        // this.openStockDetailPage.emit(JSON.stringify(obj));
        const object = { 'isCustomDashboard': true, 'fromMultiView': true, 'fromStockDetail': true, 'isHeader': true, 'MVtype': 'stock', 'backbtnEnable': false, 'IsStockSpecific': false, 'c_name': 'orderbook', 'token': omo.Token, 'exch': omo.ExchangeSegment, 'symbol': omo.TradeSymbol, 'series': omo.series};
        Utility.SELECTED_SCRIP = Utility.getScripModel(omo.Token, omo.ExchangeSegment);
        this.outputs.emit(object);
        break;
      case 'reorder':
        const removeobj1 = {'remove': true,  'Exchange': omo.Exchange, 'Token': omo.Token, 'qty': omo.TotalQty, 'OrderType': omo.OrderType, 'ProductCode': omo.ProductCode, 'Ret': omo.Validity};
        const addobj1 = {'add': true,  'Exchange': omo.Exchange, 'Token': omo.Token, 'qty': omo.TotalQty, 'OrderType': omo.OrderType, 'ProductCode': omo.ProductCode, 'Ret': omo.Validity};
        const data_obj1 = (omo.Action == 'B') ? addobj1 : removeobj1;
        Utility.openPage(this.router, 'orderDetail', 'placeorder', PlaceorderComponent, this.dialog, 'popup', data_obj1, '500px', '1250px');
        break;
    }
  }

  handleMouseOver(row) {
    const NestOrd = row.NestOrd;
    this.filteredOrderList.map((data: any) => {
      if (NestOrd && data.NestOrd && data.NestOrd === NestOrd) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const NestOrd = row.NestOrd;
    this.filteredOrderList.map((data: any) => {
      if (data.NestOrd === NestOrd) {
        data.show = false;
      }
    });
  }

  highlight(row: any, i: number){
    Mouse.IS_MOUSE = false;
    if(this.selectedRowIndex !== i && !Utility.IS_MOBILE) {
      let element:HTMLElement;
      if(this.cellview) {
        element = document.getElementById("ob_cell"+i) as HTMLElement;
      }
      else {
        element = document.getElementById("ob_tbl"+i) as HTMLElement;
      }
      element?.focus();
    }
    this.selectedRowIndex = i;
    Utility.SELECTED_ORDER = row;
    Utility.SELECTED_SCRIP = row.scripModel;
    Utility.selectedTab = TableUtility.orderBook;
    if(this.isMobile) {
      this.onClickOrderDetail(this.filteredOrderList[i]);
    }
  }

  @HostListener('window:keydown.arrowdown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    //if (Utility.selectedTab === TableUtility.orderBook && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Order Book', this.workspaceName)) {
    if (Utility.selectedTab === TableUtility.orderBook && !Utility.isDialogOpened && !this.isMobile) {
      event.preventDefault();
      if(((this.selectedRowIndex + 1) < this.filteredOrderList.length)) {
        var nextrow = this.filteredOrderList[this.selectedRowIndex + 1];
        this.highlight(nextrow, this.selectedRowIndex + 1);
      } else {
      this.highlight(this.filteredOrderList[0], 0);
    }
    }
  }

  @HostListener('window:keydown.arrowup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    //if (Utility.selectedTab === TableUtility.orderBook && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Order Book', this.workspaceName)) {
    if (Utility.selectedTab === TableUtility.orderBook && !Utility.isDialogOpened && !this.isMobile) {
      event.preventDefault();
      if(((this.selectedRowIndex - 1) >= 0)) {
        var nextrow = this.filteredOrderList[this.selectedRowIndex - 1];
        this.highlight(nextrow, this.selectedRowIndex - 1);
      }  else {
        this.highlight(this.filteredOrderList[this.filteredOrderList.length - 1], this.filteredOrderList.length - 1);
      }
    }
  }

  cancelMultipleOrder() {
    let alertMessage = '';
    if (this.orderChecked.size > 0) {
      alertMessage =  this.translate.instant('all.do_you_cancel')+ `${this.orderChecked.size}` +  this.translate.instant('all.selected_order') ;
      Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.cancel_hed'), message: alertMessage, fromOrder: true } });
      Utility.dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') {
          for (let i = 0; i < this.filteredOrderList.length; i++) {
            if (this.orderChecked.has(this.filteredOrderList[i]['NestOrd'])) {
              this.tradingRestService.getBookService().CancelOrder(this.filteredOrderList[i], function(responsedata, code, message) {
                if (responsedata['stat'] === 'Ok') {
                  this.callOrderBookService();
                  this.filteredOrderList[i]['DisplayStatus'] = 'Cancelled';
                  this.filteredOrderList[i]['CheckBoxValue'] = false;
                  this.checkedOpenOrder = this.checkedOpenOrder - 1;
                  this.openOrderCount = this.openOrderCount - 1;
                }
              }.bind(this));
            }
          }
          this.orderChecked.clear();
        }
      });
    }
    else {
      this.orderChecked.clear();
      this.filteredOrderList.forEach((arrData, i) => {
          this.filteredOrderList[i]['CheckBoxValue'] = true;
      });
      this.checkedOpenOrder = 0;
    }
  }

  onExpanClick() {
    this.isExpand = !this.isExpand;
    if (this.isExpand) {
      this.expandText = 'Less';
      this.hideCancel = true;  
    } else {
      this.expandText = 'More';
      this.hideCancel = false;
    }
    this.displayArrLen = (this.isExpand && this.displayArray) ? this.displayArray.length : this.widthArr.length - 1;
  }

  openManageBook()
  {
    if (this.isMobile) {
      this.router.navigate(['/managebooks'], { queryParams: {'name': 'orderbook'}});
    } else {
      this.openManageBooks.emit('orderbook');
    }
  }

  getColumns()
  {
    let tempColumns = Preferences.getItem('orderBookColumns', ReturnType.Undefined);
    if (!tempColumns)
    {
      tempColumns = JSON.parse(JSON.stringify(TableUtility.orderBookColumns));
      Preferences.setItem('orderBookColumns', tempColumns)
    }
    this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
  }

  updateColumns()
  {
    let tempColumns = Preferences.getItem('orderBookColumns', ReturnType.Undefined);
    this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.modesub.unsubscribe();
    this.webReloadSubscription.unsubscribe();
    this.reloadPageSubscription.unsubscribe();
    this.sortIconSubscription.unsubscribe();
    this.bookColumnSubscription.unsubscribe();
    this.orientationSubscription.unsubscribe();
  }

  onAppearing() {
    Utility.resumeChannel(this.tradingRestService, ChannelType.Order);
  }

  onDisappearing() {
    Utility.pauseChannel(this.tradingRestService, ChannelType.Order);
  }
  
  resetSortingFilter() {
    this.chipSelected = this.matChipItems[0]; 
    this.selectedFilterOption = "";
    this.selectedSortedItem = this.sortItemList[0];
    this.sortingDictionary.clear();
  }
  setBaseVisibility(visible: boolean){
    if(visible){
      this.callOrderBookService();
      setTimeout(() => {
        this.onAppearing();
        this.changeMode();
      }, 50);
    }
    else {
      this.onDisappearing();
    }
  }
}
export namespace OrderBookComponent {
  export const undefinedOrderBookValue = '<undefined>';
}