import { Component, OnInit, Output, EventEmitter, Inject, Input, ChangeDetectorRef, Injector, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { TradeModel } from '../../../model/trade.model';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Router } from '@angular/router';
import { ChannelType, Utility } from 'src/app/services/utility';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TradeMoreOptionComponent } from './trademoreoption/trademoreoption.component';
import { PositionConvertionComponent } from '../positionconvertion/positionconvertion.component';
import { PlaceorderComponent } from '../placeorder/placeorder.component';
import { Subscription, Subject } from '../../../../../node_modules/rxjs';
import { ComponentContainer } from 'golden-layout';
import * as $ from 'jquery';
import { TableUtility } from 'src/app/services/tableutility';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { CSVUtility } from 'src/app/services/CSVUtiity';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { Mouse } from 'src/app/services/mouse';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { environment } from 'src/environments/environment';

let autoGenId = 0;
declare const moment : any
let tradeBook : TradebookComponent;
@Component({
  selector: 'app-tradebook',
  templateUrl: '../../'+environment.baseviewpath+'/tradebook/tradebook.component.html',
  styleUrls: ['./tradebook.component.scss']
})
export class TradebookComponent  extends BaseComponentDirective implements OnInit, OnDestroy {
  targetElement: Element;
  displayedColumns;
  isLoading: boolean;
  expandText = 'More';
  hideDownload = false;
  isNoData: boolean;
  isMobile = true;
  noDataMessage = '';
  selectedLabel = 'ALL';
  searchId;
  dataArray : Array<TradeModel> = [];
  tradeList: Array<TradeModel>;
  filteredTradeList: Array<TradeModel> = [];
  transactionTypeMap = {};
  utility: any;
  dataArr = [];
  selectedFilter = 'ALL';
  isExpandVisible: boolean;
  isExpand = false;
  dataArrLen = 0;
  widthArr = [];
  indexArray = [];
  uniqueMap = new Map();
  isFilterNoData: boolean;
  subscription: Subscription;
  @ViewChild('sortMenu') sortMenu;
  @Output() load = new EventEmitter<boolean>();
  @Output() openStockDetailPage = new EventEmitter<string>();
  @Input() isCustomDashboard: boolean;
  @Input() workspaceName: string;
  @ViewChild('menuTrigger') matMenuTriggerClose;
  @Output() totalScripCount = new EventEmitter<number>();
  Height: any;
  DHeight: {};
  isDHeight = true;
  left: any;
  container;
  modesub: Subscription;
  webReloadSubscription: Subscription;
  reloadPageSubscription: Subscription;
  sortIconSubscription: Subscription;
  bookColumnSubscription: Subscription;
  orientationSubscription : Subscription;
  currentTabData;
  isMobileLand;
  cellView: boolean;
  WlArr = [];
  filteredKey : any;
  filteredValue : any;
  selectedFilterOption : any;
  tempFilteredTradeList : Array<TradeModel> = [];
  isReloadData = false;
  isReload = false;
  selectedRowIndex : any;
  totalheight : any;
  M;
  matChipItems = ['Asc', 'Desc'];
  sortItemList = ['Unsorted', 'Symbol', 'Trade Time', 'LTP', '%Change'];
  chipSelected = this.matChipItems[0];
  selectedSortedItem = this.sortItemList[0];
  filteObject : any;
  sortingDictionary = new Map<any, any>();
  @Output() openManageBooks = new EventEmitter<string>();
  @Input() selectedBookTabIndex: any;
  flashType = 'defaultFlash';
  constructor(elRef: ElementRef, private injector: Injector, private tradingRestService: TradingRestService, private router: Router, public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet, private detector: ChangeDetectorRef,  private snackBar: MatSnackBar, private translate: TranslateService
  ) {
    super(elRef.nativeElement,"TradeBook");
    tradeBook = this;
    this.M = Mouse;
    this.searchId = 'TradeBookView-' + (autoGenId++);
    this.isMobile = Utility.IS_MOBILE;
    this.isMobileLand = Utility.IS_MOBILE_LAND;
    this.utility = Utility;
    this.createMap();
    this.sortingDictionary.set(this.selectedSortedItem, this.chipSelected);
    this.subscription = Utility.ORDER_PLACED_EVENT.subscribe(data => {
      this.callTradeBookService();
    });
    this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      this.changeMode();
    });

    Utility.tradeBookData.subscribe(obj => {
      const foundIndex = this.tradeList.findIndex(x => x.NestOrd === obj.NestOrd && x.FillId === obj.FillId);
      if (foundIndex !== -1)
        this.tradeList.splice(foundIndex, 1);
      this.tradeList.push(obj);
      if (this.currentTabData)
        this.onTabChange(this.currentTabData.tabData, this.currentTabData.type, this.currentTabData.index);
      else
        this.onTabChange('ALL', 'All', null);
    });

    this.reloadPageSubscription = Utility.tradeBookReloadPage.subscribe(data =>
    {
      this.isReloadData = true;
      this.onRefresh();
    })
    this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
      this.isMobileLand = Utility.IS_MOBILE_LAND;
    });
    this.webReloadSubscription = Utility.webReloadComponent.subscribe(data =>
    {
      if(tradeBook.isReload === false && data === 'Trade Book')
      {
        tradeBook.isReload = true;
        this.isReloadData = true;
        this.onRefresh();
      }   
    });

    this.sortIconSubscription = Utility.tradeBookSortIconClicked.subscribe(data =>
    {
        this.selectedFilterOption = data;
        this.sortTradebookInMobile(data);
    })

    this.getColumns()
    this.bookColumnSubscription = TableUtility.updateTradeBookColumns.subscribe(data =>{
      this.updateColumns();
    })

    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      this.flashType = Preferences.getItem('flashType');
    }
    Utility.flashType.subscribe(obj => {
        this.flashType = obj;
    });


  }
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    if (width < Utility.MinWidth)
    this.cellView = true;
    else
    this.cellView = false;
    this.changeMode();
  }
  
  exportCSV(){
    if (this.selectedLabel === 'ALL')
      CSVUtility.ExportDataToCSV(this.tradeList, 'TradeBook Report', 'Trade Book','Trade Book Data');
    else {
      const dialogRef = this.dialog.open(MyAlertDialogComponent,
        { data: { heading: this.translate.instant('all.export_hed'), message: this.translate.instant('all.export_msg'),
         disableClose: true,  fromCSV: true}});
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm')
          CSVUtility.ExportDataToCSV(this.filteredTradeList, 'TradeBook Report', 'Trade Book','Trade Book Data');
        else if (result === 'cancel')
          CSVUtility.ExportDataToCSV(this.tradeList, 'TradeBook Report', 'Trade Book','Trade Book Data');
      })
    }
  }
  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      this.onRefresh();
      event.next();
    }, 1000);
  }
  
  alert(message: string) {
    //alert(message);
  }
  

  sortingItemSelected(sortingItem)
  {
    if(tradeBook.sortingDictionary === undefined || !tradeBook.sortingDictionary.has(sortingItem))
    {
      tradeBook.sortingDictionary.set(sortingItem, tradeBook.matChipItems[1]); 
    }
    
    if(tradeBook.selectedSortedItem !== sortingItem && tradeBook.sortingDictionary.has(tradeBook.selectedSortedItem))
    {
      tradeBook.sortingDictionary.set(tradeBook.selectedSortedItem, tradeBook.matChipItems[1]);
      tradeBook.selectedSortedItem = '';
    }
  
    if(tradeBook.sortingDictionary.get(sortingItem) as string === tradeBook.matChipItems[0])
    {
      tradeBook.chipSelected = tradeBook.matChipItems[1]; 
    }
    else
    {
      tradeBook.chipSelected = tradeBook.matChipItems[0]; 
    }
    tradeBook.selectedSortedItem = sortingItem;
    tradeBook.sortingDictionary.set(sortingItem, tradeBook.chipSelected);
    tradeBook.filteObject = {[tradeBook.chipSelected] : tradeBook.selectedSortedItem};
    this.selectedFilterOption = tradeBook.filteObject;
    tradeBook.sortTradebookInMobile(tradeBook.filteObject);
  }

  matMenuClosed()
  {
  }

  sortTradeBook(event)
  {
    const data = Array.from(this.filteredTradeList);
    if (event.direction === 'asc')
      this.filteredTradeList = data.sort((a, b) => (a[event.active] > b[event.active]) ? 1 : ((b[event.active] > a[event.active]) ? -1 : 0));
    else
      this.filteredTradeList = data.sort((a, b) => (a[event.active] < b[event.active]) ? 1 : ((b[event.active] < a[event.active]) ? -1 : 0));
  }

  sortTradebookInMobile(selectedFilterOption)
  {
    if(selectedFilterOption !== undefined) {
      this.filteredKey = Object.keys(selectedFilterOption)[0];
      this.filteredValue = Object.values(selectedFilterOption)[0];
      if(this.filteredKey === 'Asc')
      {
        if(this.filteredValue === 'Symbol')
        {      
          this.filteredTradeList = this.tempFilteredTradeList.slice().sort((a,b) =>
          (a['TSym'] > b['TSym']) ? 1 : ((b['TSym'] > a['TSym']) ? -1 : 0));
        }
        else if(this.filteredValue === 'Trade Time')
        {
          this.filteredTradeList = this.tempFilteredTradeList.slice().sort((a, b) =>
          (this.getTime(a['ExchTime']) > this.getTime(b['ExchTime'])) ? 1 : ((this.getTime(b['ExchTime']) > this.getTime(a['ExchTime'])) ? -1 : 0));      
        }
        else if(this.filteredValue === 'LTP')
        {
          this.filteredTradeList = this.tempFilteredTradeList.slice().sort((a, b) =>
          (Utility.getNumber(a.scripModel['LastTradePrice']) > Utility.getNumber(b.scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.scripModel['LastTradePrice']) > Utility.getNumber(a.scripModel['LastTradePrice'])) ? -1 : 0));
        }
        else if(this.filteredValue === '%Change')
        {
          this.filteredTradeList = this.tempFilteredTradeList.slice().sort((a, b) =>
          (Utility.getNumber(a.scripModel['PercentageChange']) > Utility.getNumber(b.scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.scripModel['PercentageChange']) > Utility.getNumber(a.scripModel['PercentageChange'])) ? -1 : 0));      
        }
        else if (this.filteredValue === 'Unsorted')
        {
          this.filteredTradeList = this.tempFilteredTradeList;
        }
      }
      else if(this.filteredKey === 'Desc')
      {
        if(this.filteredValue === 'Symbol')
        {      
          this.filteredTradeList = this.tempFilteredTradeList.slice().sort((a,b) =>
          (a['TSym'] < b['TSym']) ? 1 : ((b['TSym'] < a['TSym']) ? -1 : 0));
        }
        else if(this.filteredValue === 'Trade Time')
        {
          this.filteredTradeList = this.tempFilteredTradeList.slice().sort((a, b) =>
          (this.getTime(a['ExchTime']) < this.getTime(b['ExchTime'])) ? 1 : ((this.getTime(b['ExchTime']) < this.getTime(a['ExchTime'])) ? -1 : 0));      
        }
        else if(this.filteredValue === 'LTP')
        {
          this.filteredTradeList = this.tempFilteredTradeList.slice().sort((a, b) =>
          (Utility.getNumber(a.scripModel['LastTradePrice']) < Utility.getNumber(b.scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.scripModel['LastTradePrice']) < Utility.getNumber(a.scripModel['LastTradePrice'])) ? -1 : 0));
        }
        else if(this.filteredValue === '%Change')
        {
          this.filteredTradeList = this.tempFilteredTradeList.slice().sort((a, b) =>
          (Utility.getNumber(a.scripModel['PercentageChange']) < Utility.getNumber(b.scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.scripModel['PercentageChange']) < Utility.getNumber(a.scripModel['PercentageChange'])) ? -1 : 0));      
        }
        else if (this.filteredValue === 'Unsorted')
        {
          this.filteredTradeList.reverse();
        }
      }
    }
    else
    {
      this.filteredTradeList = this.tempFilteredTradeList;
    }

    if(this.filteredTradeList.length === 0)
    { 
      this.isNoData = true;
      this.noDataMessage = 'NO DATA';
    }
    else
      this.isNoData = false;
  }

  getTime(inputDate : any)
  {
    var date = new Date(inputDate);
    var formatted = moment(date).format('HH:mm:ss');
    return formatted;
  }

  changeMode() {
    if (this.container?.width !== null){
      if (this.tradeList !== undefined && this.tradeList.length === 1) {
        this.totalheight = this.container?.height;
        this.Height = {
          'height': this.container?.height + 'px',
          'overflow': 'auto'
        };
      } else {
        this.totalheight = (this.container?.height - TableUtility.TBHeight);
        this.Height = {
          'height': (this.container?.height - TableUtility.TBHeight) + 'px',
          'overflow': 'auto'
        };
      }
      this.left = {
        'left': (this.container?.width - TableUtility.TBLeft) + 'px'
      };
      this.detector.detectChanges();
      if (Utility.IS_MOBILE) {
        this.dataArrLen = this.dataArr.length;
      } else {
        this.getChipWidth();
      }

      if (this.container?.width < Utility.MinWidth)
      { 
        this.cellView = true;
        if(this.tempFilteredTradeList) this.sortTradebookInMobile(this.selectedFilterOption)
      }
      else {
        this.cellView = false;
        this.filteredTradeList.map((data: any) => {
          data.show = false;
        });
      }
    }
  }

  ngOnInit() {
    this.targetElement = document.querySelector('html');
    if (this.container) {
      /* this.container.on('resize', _ => {
        this.changeMode();
      });
      this.container.on('show', _ => {
        this.callTradeBookService();
        setTimeout(() => {
          this.changeMode();
        }, 50);
      });*/
    }
    // if (Preferences.getItem('tradeColumns', ReturnType.Undefined) !== undefined && Preferences.getItem('tradeColumns') !== null)
    //   this.displayedColumns = Preferences.getItem('tradeColumns', ReturnType.Array);
    // else
    //   this.displayedColumns = ['TSym', 'TranType', 'Pcode', 'FilledQty', 'AvgPrice', 'NestOrd', 'FillDateTime'];
    this.isLoading = true;
    this.load.emit(this.isLoading);
    if(this.isMobile){
      this.callTradeBookService();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== 0 && event.currentIndex !== 1) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      Preferences.setItem('tradeColumns', this.displayedColumns);
    }
  }

  addWLdata(stock) {
    const seltctedStock = stock.Exseg + '|' + stock.Token;
    this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
  }
  addStock(wl, stockdata) {
    const seltctedStock = stockdata.Exseg + '|' + stockdata.Token;
    WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
      if (result && msg === 'Success') {
        this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
      } else if (result && msg === 'Fail') {
        this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
      } else {
        this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
      }
    }.bind(this));
  }
  callTradeBookService() {
    this.load.emit(true);
    this.isExpandVisible = false;
    this.resetSortingFilter();
    this.tradingRestService.getBookService().getAllTradeList(true, function(data, code, message) {
      this.isLoading = false;
      tradeBook.isReload = false;
      this.load.emit(this.isLoading);
      if (code === 200 && message === 'OK') {
        this.isNoData = false;
        this.tradeList = data;
        this.filteredTradeList = this.tradeList;
        this.totalScripCount.emit(this.tradeList.length);
        this.tempFilteredTradeList = this.tradeList;
        this.ProcessTags();
        let decpre;
        this.tradeList.forEach(element => {
          const decimalValue = element.Price.toString().split('.')[1];
          decpre = ((decimalValue === undefined) ? 2 : decimalValue.length);
          element['DecimalPrecision'] = decpre;
        });
        this.sortList();
        this.dataArray = Array.from(this.filteredTradeList);  
        if(this.isReloadData)
        {
          this.isReloadData = false;
          if (this.currentTabData)
            this.onTabChange(this.currentTabData['tabData'], this.currentTabData['type'], this.currentTabData['index']);
          else
            this.onTabChange('ALL', 'All', null);
        }
        if (this.filteredTradeList.length > 0 && !Utility.IS_MOBILE) {
          this.highlight(this.filteredTradeList[0], 0);
        }
      }
      else {
        this.isNoData = true;
        this.noDataMessage = 'NO DATA';
      }
    }.bind(this), this.setPredefinedTradeBook.bind(this));

    if (this.isCustomDashboard) {
      this.changeMode();
    }

  }

  sortList() {
    this.filteredTradeList = this.filteredTradeList.sort((a, b) => (a.FillDateTime < b.FillDateTime) ? 1 : ((b.FillDateTime < a.FillDateTime) ? -1 : 0));
  }

  setPredefinedTradeBook(data) {
    this.tradeList = data;
    this.filteredTradeList = this.tradeList;
    this.ProcessTags();
    let decpre;
    this.tradeList.forEach(element => {
      if (element.Price !== null)
      {
        const decimalValue = element.Price.toString().split('.')[1];
      decpre = ((decimalValue === undefined) ? 2 : decimalValue.length);
      element['DecimalPrecision'] = decpre;
      }
    });
    this.sortList();
    this.dataArray = Array.from(this.filteredTradeList);  
  }

  createMap() {
    this.transactionTypeMap['B'] = 'BUY';
    this.transactionTypeMap['S'] = 'SELL';
  }

  ProcessTags() {
    const resultMap = new Map();
    let obj = {};
    let transactionType;
    this.dataArr = [];
    const symbolArr = [];
    const exchangeArr = [];
    const transactionArr = [];
    this.dataArr = [];

    if (this.tradeList.length > 1) {
      for (let i = 0; i < this.tradeList.length; i++) {
        if (this.tradeList[i].Exchange != null) {
          if (!resultMap.has(this.tradeList[i].Exchange)) {
            resultMap.set(this.tradeList[i].Exchange, true);
            obj = {};
            obj['data'] = this.tradeList[i].Exchange;
            obj['type'] = 'Exchange';
            obj['count'] = 1;
            obj['visibility'] = false;

            exchangeArr.push(obj);
          }

          else {
            for (let j = 0; j < exchangeArr.length; j++) {
              if (exchangeArr[j]['data'] === this.tradeList[i].Exchange) {
                exchangeArr[j]['count'] = exchangeArr[j]['count'] + 1;
              }
            }
          }
        }

        if (this.tradeList[i].TSym != null) {
          if (!resultMap.has(this.tradeList[i].TSym)) {
            resultMap.set(this.tradeList[i].TSym, true);
            obj = {};
            obj['data'] = this.tradeList[i].TSym;
            obj['type'] = 'TSym';
            obj['count'] = 1;
            obj['visibility'] = false;

            symbolArr.push(obj);
          }

          else {
            for (let j = 0; j < symbolArr.length; j++) {
              if (symbolArr[j]['data'] === this.tradeList[i].TSym) {
                symbolArr[j]['count'] = symbolArr[j]['count'] + 1;
              }
            }
          }
        }

        if (this.tradeList[i].TranType != null) {
          if (this.tradeList[i].TranType === 'S') {
            transactionType = 'Sell';
          }
          else {
            transactionType = 'Buy';
          }
          if (!resultMap.has(this.tradeList[i].TranType)) {
            resultMap.set(this.tradeList[i].TranType, true);
            obj = {};
            obj['data'] = transactionType;
            obj['type'] = 'TranType';
            obj['count'] = 1;
            obj['visibility'] = false;
            transactionArr.push(obj);
          }
          else {
            for (let j = 0; j < transactionArr.length; j++) {
              if (transactionArr[j]['data'] === transactionType) {
                transactionArr[j]['count'] = transactionArr[j]['count'] + 1;
              }
            }
          }
        }
      }
      this.processChipData(exchangeArr.sort(this.compare.bind(this)));
      this.processChipData(transactionArr.sort(this.compare.bind(this)));
      this.processChipData(symbolArr.sort(this.compare.bind(this)));
      if (Utility.IS_MOBILE) {
        this.dataArrLen = this.dataArr.length;
      } else {
        this.getChipWidth();
      }
    }
  }

  processChipData(dataArr) {
    if (dataArr.length > 1) {
      for (let i = 0; i < dataArr.length; i++) {
        this.dataArr.push(dataArr[i]);
      }
    }
  }

  getChipWidth() {
    setTimeout(() => {
      if (this.sortMenu !== undefined) {
        this.expandText  = 'More';
        const wrapperWidth = this.container?.width - Utility.getChipLetterWidth('ALL') - 75 - this.sortMenu.nativeElement.scrollWidth;
        let WidCount = 0;
        let width = 0;
        this.widthArr = [];
        for (let i = 0; i < this.dataArr.length; i++) {
          width = Utility.getChipLetterWidth(this.dataArr[i].data);
          WidCount = WidCount + width;
          if (WidCount < wrapperWidth) {
            this.widthArr.push(this.dataArr[i]);
            this.isExpandVisible = false;
            this.dataArrLen = this.widthArr.length;
          }
          else {
            this.dataArrLen = this.widthArr.length - 1;
            this.isExpandVisible = true;
            break;
          }
        }
      }
    }, 200);
  }

  compare(a, b) {
    const count1 = a.count;
    const count2 = b.count;
    let comparison = 0;
    if (count1 < count2) {
      comparison = 1;
    } else if (count1 > count2) {
      comparison = -1;
    }
    return comparison;
  }

  onTabChange(tabData, type, index) {
    this.currentTabData = { 'tabData': tabData, 'type': type, 'index': index };
    const tabType = type;
    const data = tabData;
    let dataType = '';
    this.selectedLabel = '';
    this.filteredTradeList = [];
    let filterArr = [];
    this.uniqueMap.clear();
    if (data !== 'ALL') {
      if (this.dataArr[index]['visibility'] === true) {
        this.dataArr[index]['visibility'] = false;
      }
      else {
        this.dataArr[index]['visibility'] = true;
        this.indexArray.push(index);
      }
    }
    else {
      this.indexArray.forEach(arrData => {
        if (this.dataArr[arrData]['visibility'] === true)
          this.dataArr[arrData]['visibility'] = false;
      });
    }

    if (this.dataArr && data !== 'ALL') {
      filterArr = this.dataArr.filter(obj => {
        return obj['visibility'] === true;
      });
    }

    if (filterArr.length > 0) {

      filterArr.forEach(arrData => {
        this.uniqueMap.set(arrData['type'], true);
      });

      for (let i = 0; i < this.tradeList.length; i++) {
        let count = 0;
        filterArr.forEach(arrData => {
          if (this.tradeList[i][arrData['type']] === 'B') {
            dataType = 'Buy';
          }
          else if (this.tradeList[i][arrData['type']] === 'S') {
            dataType = 'Sell';
          }
          else {
            dataType = this.tradeList[i][arrData['type']];
          }
          if (arrData['data'] === dataType) {
            count++;
          }
        });

        if (count === this.uniqueMap.size) {
          this.filteredTradeList.push(this.tradeList[i]);
        }
      }
    }
    else {
      this.selectedLabel = 'ALL';
      this.filteredTradeList = this.tradeList;
    }
    this.tempFilteredTradeList = this.filteredTradeList;
    this.sortList();
    this.noDataCheck();
    if((this.selectedSortedItem !== this.sortItemList[0] || (this.selectedSortedItem === this.sortItemList[0] && this.chipSelected === this.matChipItems[1])) && (this.cellView || this.isMobile))
      this.sortTradebookInMobile(this.selectedFilterOption);
    if (this.filteredTradeList.length > 0 && !Utility.IS_MOBILE)
      this.highlight(this.filteredTradeList[0], 0);
  }

  noDataCheck() {
    if (this.filteredTradeList.length === 0) {
      this.isNoData = true;
    }
    else {
      this.isFilterNoData = true;
      this.isNoData = false;
    }
  }
  onRefresh() {
    this.isLoading = true;
    this.isReloadData = true;
    this.load.emit(this.isLoading);
    this.detector.detectChanges();
    this.callTradeBookService();
  }

  onReset(){
    this.currentTabData = undefined;
    this.callTradeBookService();
    this.onTabChange('ALL', 'All', null);
  }

  handleButton(buttonType, tmo) {
    const addobj = { 'action': 'B', 'exseg': tmo.ExchangeSeg, 'omtkn': tmo.Token};
    const removeobj = { 'action': 'S', 'exseg': tmo.ExchangeSeg, 'omtkn': tmo.Token};
    Utility.tradeDetail = tmo;
    this.filteredTradeList.map((data: any) => {
      data.show = false;
    })  
    switch (buttonType) {
      case 'PC':
        Utility.openPage(this.router, 'trade', 'positionconvertion', PositionConvertionComponent, this.dialog, 'popup', {}, '200px');
        break;
      case 'ADD':
        Utility.openPage(this.router, 'tradeDetail', 'placeorder', PlaceorderComponent, this.dialog, 'popup', addobj, '500px', '1250px');
        break;
      case 'REMOVE':
        Utility.openPage(this.router, 'tradeDetail', 'placeorder', PlaceorderComponent, this.dialog, 'popup', removeobj, '500px', '1250px');
        break;
      case 'sd':
        // const obj = { 'token': tmo.Token, 'exch': tmo.Exseg, 'symbol': tmo.TSym };
        // this.openStockDetailPage.emit(JSON.stringify(obj));
        Utility.SELECTED_SCRIP = Utility.getScripModel(tmo.Token, tmo.ExchangeSeg);
        const object = { 'isCustomDashboard': true, 'fromMultiView': true, 'fromStockDetail': true, 'isHeader': true, 'MVtype': 'stock', 'backbtnEnable': false, 'IsStockSpecific': false, 'c_name': 'tradebook', 'token': tmo.Token, 'exch': tmo.ExchangeSeg, 'symbol': tmo.TSym, 'series': Utility.SELECTED_SCRIP.InstrumentName };
        console.log(Utility.SELECTED_SCRIP)
        this.outputs.emit(object);
        break;
    }
  }

  onClickTradeBookData(data) {
    Utility.tradeDetail = data;
    if (this.isMobile) {
      this._bottomSheet.open(TradeMoreOptionComponent, {
        restoreFocus: false
      });
    }
  }

  onExpanClick() {
    this.isExpand = !this.isExpand;
    if (this.isExpand) {
      this.expandText = 'Less';
      this.hideDownload = true;  
    } else {
      this.expandText = 'More';
      this.hideDownload = false;
    }
    this.dataArrLen = (this.isExpand && this.dataArr) ? this.dataArr.length: this.widthArr.length - 1;
  }

  openManageBook()
  {
    if (this.isMobile) {
      Utility.selectedBookTab = 1;
      this.router.navigate(['/managebooks'], { queryParams: {'name': 'tradebook'}});
    } else {
      this.openManageBooks.emit('tradebook');
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (Utility.IS_MOBILE_LAND && this.selectedBookTabIndex === 1) {
      if(window.scrollY > (TableUtility.BOOK_HEADER_HEIGHT + this.sortMenu?.nativeElement.scrollHeight) && this.isDHeight){
        this.DHeight = {
          'height': '100vh',
          'overflow': 'auto',
          'scroll-behavior': 'smooth',
        };
        let element:HTMLElement;
        element = document.getElementById("tb_tbl1") as HTMLElement;
        element?.focus();
        this.isDHeight = false;
      }
      else if(window.scrollY < (TableUtility.BOOK_HEADER_HEIGHT + this.sortMenu?.nativeElement.scrollHeight)){
        this.DHeight = {
          'height': '100%',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };
        this.isDHeight = true;
      }
    }
  }

  getColumns()
  {
    let tempColumns = Preferences.getItem('tradeBookColumns', ReturnType.Undefined);
    if (!tempColumns)
    {
      tempColumns = JSON.parse(JSON.stringify(TableUtility.tradeBookColumns));
      Preferences.setItem('tradeBookColumns', tempColumns)
    }
    this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
  }

  updateColumns()
  {
    let tempColumns = Preferences.getItem('tradeBookColumns', ReturnType.Undefined);
    this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.modesub.unsubscribe();
    this.webReloadSubscription.unsubscribe();
    this.reloadPageSubscription.unsubscribe();
    this.sortIconSubscription.unsubscribe();
    this.bookColumnSubscription.unsubscribe();
    this.orientationSubscription.unsubscribe();
  }

  onAppearing() {
    Utility.resumeChannel(this.tradingRestService, ChannelType.Trade);
  }

  onDisappearing() {
    Utility.pauseChannel(this.tradingRestService, ChannelType.Trade);
  }

  handleMouseOver(row) {

    const NestOrd = row.NestOrd + '_' + row.FillId;
    this.filteredTradeList.map((data: any) => {
      const val = data.NestOrd + '_' + data.FillId;
      if (NestOrd && val && val === NestOrd) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const NestOrd = row.NestOrd + '_' + row.FillId;
    this.filteredTradeList.map((data: any) => {
      const val = data.NestOrd + '_' + data.FillId;
      if (val === NestOrd) {
        data.show = false;
      }
    });
  }

  highlight(row: any, i: number){
    Mouse.IS_MOUSE = false;
    if(this.selectedRowIndex !== i && !Utility.IS_MOBILE) {
      let element:HTMLElement;
      if(this.cellView) {
        element = document.getElementById("tb_cell"+i) as HTMLElement;
      }
      else {
        element = document.getElementById("tb_tbl"+i) as HTMLElement;
      }
      element?.focus();
    }
    this.selectedRowIndex = i;
    Utility.SELECTED_TRADE_ORDER = row;
    Utility.SELECTED_SCRIP = row.scripModel;
    Utility.selectedTab = TableUtility.tradeBook;
    if(this.isMobile) {
      this.onClickTradeBookData(this.filteredTradeList[i]);
    }
  }

  @HostListener('window:keydown.arrowdown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    //if (Utility.selectedTab === TableUtility.tradeBook && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Trade Book', this.workspaceName)) {
    if (Utility.selectedTab === TableUtility.tradeBook && !Utility.isDialogOpened && !this.isMobile) {
      event.preventDefault();
      if(((this.selectedRowIndex + 1) < this.filteredTradeList.length)) {
        var nextrow = this.filteredTradeList[this.selectedRowIndex + 1];
        this.highlight(nextrow, this.selectedRowIndex + 1);
      } else {
      this.highlight(this.filteredTradeList[0], 0);
    }
    }
  }

  @HostListener('window:keydown.arrowup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    //if (Utility.selectedTab === TableUtility.tradeBook && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Trade Book', this.workspaceName)) {
    if (Utility.selectedTab === TableUtility.tradeBook && !Utility.isDialogOpened && !this.isMobile) {
      event.preventDefault();
      if(((this.selectedRowIndex - 1) >= 0)) {
        var nextrow = this.filteredTradeList[this.selectedRowIndex - 1];
        this.highlight(nextrow, this.selectedRowIndex - 1);
      }  else {
        this.highlight(this.filteredTradeList[this.filteredTradeList.length - 1], this.filteredTradeList.length - 1);
      }
    }
  }

  resetSortingFilter() {
    this.chipSelected = this.matChipItems[0]; 
    this.selectedFilterOption = "";
    this.selectedSortedItem = this.sortItemList[0];
    this.sortingDictionary.clear();
  }
  setBaseVisibility(visible: boolean){
    if(visible){
      this.callTradeBookService();
      this.onAppearing();
      setTimeout(() => {
        this.onAppearing();
        this.changeMode();
      }, 50);
    }
    else {
      this.onDisappearing();
    }
  }
}
