import { Injectable, ɵConsole } from '@angular/core';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import { Utility } from '../utility';
import { IMarketService } from './imarket.service';
import { MarketStatusModel } from '../../model/marketstatus.model';
import { ExchangeMessageModel } from '../../model/exchangemessage.model';
import { Preferences } from '../Preferences';

let ms: HSMarketService;
@Injectable()
export class HSMarketService implements IMarketService {
    apiService: ApiService;
    constructor(apiService: ApiService) {
        ms = this;
        ms.apiService = apiService;
    }

    getMarketStatus(exchange: string, callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'exch': exchange };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('userSessionId'));
        const key = Url.marketStatus + '_' + 'exchange' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.hspost(Url.HS_MARKET_STATUS, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            const MarketModelList = new Array<MarketStatusModel>();
            if (res['stat'] === 'Ok') {
                for (let i = 0; i < res['marketStatus'].length; i++) {
                    const jdata = res['marketStatus'][i];
                    const model = new MarketStatusModel();
                    model.MarketStatus = jdata['mktSt'];
                    model.MarketMode = jdata['text'];
                    model.Status = model.MarketStatus;
                    MarketModelList.push(model);
                }
                Utility.CACHE_STORE.setCacheData(key, MarketModelList);
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res['Emsg'];
            }
            callback(MarketModelList, code, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }

    getExchangeMessage(exchange: string, callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'exch': exchange };
        const key = Url.exchangeMessage + '_' + exchange + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('userSessionId'));
        this.apiService.hspost(Url.HS_EXCHANGE_MESSAGE, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            const ModelList = new Array<ExchangeMessageModel>();
            let arr = [];
            let msgArr = [];
            if (res['stat'] === 'Ok') {
                if (res['exchMsg'] !== 'No Data') {
                    arr = res['exchMsg'].split('#$#');
                    for (let i = 0; i < arr.length; i++) {
                        const model = new ExchangeMessageModel();
                        const msg = arr[i].replaceAll('@#@', '<br/>');
                        if (msg) {
                            model.Message = msg;
                            model.Exchange = '-';
                            model.DateTime = '-';
                            ModelList.push(model);
                        }
                    }
                    message = Keys.RES_MSG_OK;
                    Utility.CACHE_STORE.setCacheData(key, ModelList);
                }
                else
                    message = res['Exchmsg'];
            }
            else {
                message = Response['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback(ModelList, code, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }
}

