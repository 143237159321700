import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';
import { PricePipe } from 'src/app/utility/pipes/PricePipe';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { Location} from '@angular/common';
import { BasketPlaceOrderComponent } from '../thematicbasket/basketplaceorder/basketplaceorder.component';
import { MatDialog } from '@angular/material/dialog';

declare var Razorpay: any;
let addfunds : AddfundsComponent;
@Component({
  selector: 'app-addfunds',
  templateUrl: '../../'+environment.baseviewpath+'/addfunds/addfunds.component.html',
})


export class AddfundsComponent implements OnInit {
  orderData = {};
  key_id = '';
  balance : number;
  key_secret = '';
  disableContinue: boolean;
  paymentTypes: string[] = ['Net Banking', 'UPI'];
  paymentTypeSelected = 'netbanking';
  uid = '';
  isLoading = true;
  amount;
  totalAmount;
  @Output() reload = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() loadLimit = new EventEmitter<boolean>();
  @ViewChild('inputPrice', { static: false }) inputPrice: ElementRef;
  @ViewChild('upiField', { static: false }) upiField: ElementRef;
  accountNumber = '';
  holderName = '';
  upiId: string;
  isMobile = true;
  userMail;
  mob;
  ifsc;
  verifiedData;
  bankName;
  accountsDetail = [];
  bankAccountDisplay = [];
  selected;
  notes;
  transactionCharges = [];
  transactionData;
  transactionChargesData;
  displayedColumns: string[] = ['Bank Name', 'Trans Charge', 'GST', 'total'];
  displayedTableColumns : string[] = ['key', 'values'];
  disablePay: boolean;
  isPayment: boolean;
  @Input() awlBalance;
  isExpand: boolean;
  isDefault = "true";
  segmentSelected;
  paymentStatus;
  isPaymentSuccess: boolean;
  pricepipe: PricePipe;
  enabledSegments = [];
  @Input() overlayFrom;
  constructor(private router: Router, private tradingRestService: TradingRestService, private ngZone: NgZone, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private translate: TranslateService, private location: Location, private dialog: MatDialog ) { }

  ngOnInit(): void {
    this.isMobile = Utility.IS_MOBILE;
    addfunds = this;
    this.pricepipe = new PricePipe();

    if (this.isMobile) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.balance = Number(params['bal']);
      });
    } else {
      this.balance = Number(this.awlBalance);
    }
    this.isLoading = true;
    this.loadLimit.emit(this.isLoading);
    this.tradingRestService.getUserService().GetAllTransactionCharges((data, code, msg) => {
      this.transactionCharges = data;
    });
    this.tradingRestService.getUserService().getUserProfile((data, code, msg) => {
      this.isLoading = false;
      this.loadLimit.emit(this.isLoading);
      if (code === 200 && msg === 'OK') {
        this.uid = data.usrId;
        this.accountNumber = data.bnkActNo;
        if (data['bnkDtl'] !== 'null'){
          this.accountsDetail = data['bnkDtl'];
        }
        if (this.accountsDetail.length > 0){
          for (let i = 0; i < this.accountsDetail.length; i++){
          this.bankAccountDisplay[i] = { 'value': this.accountsDetail[i]['bnkActNo'], 'viewValue': this.accountsDetail[i]['bnkName']+ '-' +Utility.getMaskedValue(this.accountsDetail[i]['bnkActNo']) };
          }
        }
        if (this.accountsDetail.length > 0){
          this.selected = this.accountsDetail[0]['bnkActNo'];
        }
        this.holderName = this.toTitleCase(data.actName);
        this.userMail = data.email;
        this.mob = data.cellAddr;
        this.ifsc = data.bnkIfscCode;
        this.bankName = data.bnkName;
      }
      else{}
    }, this.setPreProfileData);
    Utility.addFundsMainPage.subscribe(() => {
      addfunds.isPaymentSuccess = false;
      Utility.checkfunds = false;
      addfunds.isPayment = false;
      addfunds.disableContinue = false;
      addfunds.amount = '';
    });
    if(Object.keys(Utility.SegmentDetails).length === 0) {
      addfunds.location.back();
    }
    else {
      this.enabledSegments = Utility.SegmentDetails['segments'];
      this.segmentSelected = this.enabledSegments[0];
    }

    this.enabledSegments = Utility.SegmentDetails['segments'];
    this.segmentSelected = this.enabledSegments[0];
  }

  onSegmentSelection(event: any){
    this.segmentSelected = event;
  }

  setPreProfileData(){

  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  onSelection(event: any){
    for (let i = 0; i < this.accountsDetail.length; i++){
      if (event === this.accountsDetail[i]['bnkActNo']){
        this.accountNumber = this.accountsDetail[i]['bnkActNo'];
        this.ifsc = this.accountsDetail[i]['bnkIfscCode'];
        this.bankName = this.accountsDetail[i]['bnkName'];
      }
    }
    }

    payWithRazor() {
      const options: any = {
          'key': Utility.SegmentDetails['payment_api'][this.segmentSelected],
          'amount': this.orderData['amount'],
          'currency': this.orderData['currency'],
          'order_id': this.orderData['id'],
          'name': this.orderData['name'],
          'description': 'Validate Account',
          'ex_segment': this.segmentSelected,
          'exchange': 'NSE',
          'product': 'CNC',
      modal: {
          escape: false,
      },
      prefill: {
          'name': this.holderName,
          'email': ((this.userMail.includes('.') && this.userMail.includes('@'))?this.userMail:''),
          'contact': ((this.mob.length>=10)?this.mob:''),
          'method': this.paymentTypeSelected,
          'vpa': this.upiId
      },
      notes: this.orderData['notes'],
      theme: {
          color: '#0c238a'
      },
      readonly: {
        email: 1,
        contact: 1
      },
      };
      options.handler = ((response, error) => {
          options.response = response;
          this.key_id = '';
          this.key_secret = ''
          this.isLoading = true;
          this.loadLimit.emit(this.isLoading);
          this.ngZone.run(() => {
            this.tradingRestService.getUserService().VerifyPayment(response, (data, code, message) => {
              this.disableContinue = false;
              this.isLoading = false;
              this.loadLimit.emit(this.isLoading);
              if (code === 200){
                this.balance += Number(this.amount);
                Utility.avl_margin = this.balance;
                this.amount = '';
                Utility.reloadLimit.next();
                if(!this.isMobile){
                  Utility.transactionRefresh.next();
                }
                addfunds.verifiedData = data;
                addfunds.paymentStatus = 'Payment Successful';
                
                if (this.paymentTypeSelected === 'upi'){
                  Preferences.setItem('upi_id', this.upiId);
                }
              } else if(code === 0){
                  if(!this.isMobile){
                    Utility.transactionRefresh.next();
                  }
                  addfunds.paymentStatus = 'Time Out';
                  addfunds.verifiedData = data;
              }
              else{
                if(!this.isMobile){
                  Utility.transactionRefresh.next();
                }
                addfunds.paymentStatus = 'Payment Unsuccessful';
                addfunds.verifiedData = data;
              }
              addfunds.isPaymentSuccess = true;
              Utility.checkfunds = true;
              addfunds.isPayment = false;
            });
          });
      });
      options.modal.ondismiss = (() => {
        this.snackBar.open(this.translate.instant('placeorder.unsuccess_msg'), '', { duration: 2000});
        const data = {"razorpay_order_id": options.order_id, "remark": "Payment cancelled by user"};
        this.tradingRestService.getUserService().CancelPayment(data, (data, code, message) => {console.log("data", data) });
        if(!this.isMobile){
          Utility.transactionRefresh.next();
        }
        this.disableContinue = false;
      });
      const rzp = new Razorpay(options);
      rzp.open();
      rzp.on("payment.failed", (response) => {
        if(response.error.code === "BAD_REQUEST_ERROR"){
          const data = {"razorpay_order_id": options.order_id, "remark": response.error.description};
          this.tradingRestService.getUserService().CancelPayment(data, (data, code, message) => {console.log("data", data) });
        }
      });
  }

  transactionDone(){
    addfunds.isPaymentSuccess = false;
    Utility.checkfunds = false;
  }

  validatePrice(ev) {
    const regex = new RegExp('^[0-9]{0,' + 10 + '}$|^[0-9]{1,' + 10 + '}[.]{1,1}[0-9]{0,' + 2 + '}$');
    const val = this.inputPrice.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      if (addfunds.amount === '.')
      {
        addfunds.amount = '0.';
      }
      else
      {
        this.validateKey(ev.key, 'price');
        ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
        this.inputPrice.nativeElement.dispatchEvent(new Event('input'));
        this.inputPrice.nativeElement.setSelectionRange(index - 1, index - 1);
      }
    }
  }

  calculateCharges(transType, transCharge, calculationType){
    let charges;
      if (this.amount > 0 && transType === 'percentage'){
        charges =  (Number(this.amount) * transCharge / 100)
        return this.pricepipe.transform(charges, 2)  ;
      }
      else if (transType === 'percentage'){
        return transCharge + '%';
      }
      else if ( transType === 'flat'){
        charges = transCharge / 100;
        return this.pricepipe.transform(charges, 2);
      }
  }

  calculateGst(gstValue, transCharge, transType){
    let charges;
    let gst;
    if (this.amount > 0){
      if (transType === 'percentage'){
        charges = Number(this.amount) * transCharge / 100;
        gst = charges * gstValue / 100;
      }
      else{
        charges =  transCharge / 100;
        gst = charges * gstValue / 100;
      }
      return this.pricepipe.transform(gst, 2);
    }
    else{
      return gstValue;
    }

  }

  payableAmount(gstValue, transCharge, transType){
    let charges;
    let gst;
    if (this.amount > 0){
      if (transType === 'percentage'){
        charges = Number(this.amount) * transCharge / 100;
      }
      else{
        charges = transCharge / 100;
      }
      gst =  charges * gstValue / 100;
      return this.pricepipe.transform(charges + gst + Number(this.amount), 2);
    }
    else{
      return '-';
    }
  }

  validateKey(key, str)
  {
    if (/[0-9]/i.test(key) || key == "Backspace")
    {
      if (str === 'price')
        this.snackBar.open(this.translate.instant('limits.maximum_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (/[a-zA-Z]/i.test(key) && key != "Backspace")
      this.snackBar.open(this.translate.instant('limits.only_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
  }


  paymentType(e: any){
    if (e.value === 'upi'){
      this.upiId = Preferences.getItem('upi_id', ReturnType.String);
    }
  }

  continue() {
    addfunds.isLoading = true;
    addfunds.loadLimit.emit(this.isLoading);
    addfunds.disableContinue = true;
    addfunds.disablePay = false;
    let data;
    let transdata;
    if (this.paymentTypeSelected === 'netbanking') {
      data = {  uid: this.uid,
                'amount': (this.amount * 100).toFixed(0),
                'paymentType': this.paymentTypeSelected,
                'acc_num': this.accountNumber,
                'accHolder_name': this.holderName,
                'ifsc_code': this.ifsc,
                'bank_name': this.bankName,
                'enterprise_id': environment.brokerId,
                'ex_segment': this.segmentSelected,
                'exchange': 'NSE',
                'product': 'CNC'
              };
    } else {
      data = {  uid: this.uid,
                'amount': (this.amount * 100).toFixed(0),
                'paymentType': this.paymentTypeSelected,
                'acc_num': this.accountNumber,
                'accHolder_name': this.holderName,
                'ifsc_code': this.ifsc,
                'bank_name': this.bankName,
                'enterprise_id': environment.brokerId,
                'upi_id': this.upiId,
                'ex_segment': this.segmentSelected,
                'exchange': 'NSE',
                'product': 'CNC'
              };
    }
    transdata = {
      'amount': (this.amount * 100).toFixed(0),
      'paymentType': this.paymentTypeSelected,
      'ifsc_code': this.ifsc
    };
    addfunds.transactionData = data;
    const regex = new RegExp('^[0-9]{0,' + 10 + '}$|^[0-9]{1,' + 10 + '}[.]{1,1}[0-9]{0,' + 2 + '}$');
    const val = this.inputPrice.nativeElement.value;
    if (val.match(regex)){
      if (this.paymentTypeSelected === 'upi'){
        const upi = this.upiField.nativeElement.value;
        if ( /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/.test(upi)){
          this.tradingRestService.getUserService().GetTransactionCharge(transdata, this.GetTransactionResponse);
        }
        else{
          addfunds.isLoading = false;
         addfunds.loadLimit.emit(this.isLoading);
         addfunds.disableContinue = false;
         this.snackBar.open(this.translate.instant('limits.invalid_upi'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }
      }
      else{
        this.tradingRestService.getUserService().GetTransactionCharge(transdata, this.GetTransactionResponse);
      }
    }
    else{
      addfunds.isLoading = false;
      addfunds.loadLimit.emit(this.isLoading);
      addfunds.disableContinue = false;
      this.snackBar.open(this.translate.instant('limits.max_digit'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  cancelClick(){
    if (this.isMobile){
      addfunds.isPaymentSuccess = false;
      Utility.checkfunds =false;
      addfunds.isPayment = false;
      addfunds.disableContinue = false;
      addfunds.amount = '';
      this.location.back();
      this.location.back();
    }
    else{
      Utility.closeAddOverLay.next();
      this.close.emit();
    }
  }

  GetTransactionResponse(data, code, message){
    addfunds.isLoading = false;
    addfunds.loadLimit.emit(addfunds.isLoading);
    if (data){
      addfunds.isDefault = data["is_rzrpay_charge"];
    }
    if (addfunds.isDefault === "false") {
      addfunds.totalAmount = Number(addfunds.amount * 100).toFixed(0) + data.trns_charge + data.gst;
      addfunds.transactionChargesData = [
        {key: 'Amount', values: data.trns_amount / 100},
        {key: 'TransactionCharge', values: data.trns_charge / 100 },
        {key: 'GST', values: data.gst / 100},
        {key: 'Total Amount', values: addfunds.totalAmount / 100}
      ];
      addfunds.isPayment = true;
    }
    else{
      addfunds.pay();
    }
  }

  AnchorClicked(){
   this.isExpand = !this.isExpand;
  }

  OnCancel(){
    this.isPayment = false;
    this.disableContinue = false;
  }

  pay(){
    this.disablePay = true;
    this.isLoading = true;
    this.loadLimit.emit(this.isLoading);
    this.tradingRestService.getUserService().IBPayment((res, code, message) => {
      if (code === 200 && res) {
      
          this.orderData = res.data;
          this.key_id = res.key_id;
          this.key_secret = res.key_secret;
          this.payWithRazor();
      } 
      else {
        //this.disablePay=false;
        addfunds.disableContinue = false;
        this.snackBar.open(res.message, '', { duration: 3000 });
      }
      this.isLoading = false;
      this.loadLimit.emit(this.isLoading);
  }, addfunds.transactionData);
  }

  enable(){
    this.close.emit();
    if(this.overlayFrom === 'basketPO'){
      Utility.openPage(addfunds.router, 'addfunds', 'basketplaceorder', BasketPlaceOrderComponent, addfunds.dialog, 'popup', { 'basketOrderType': 'FirstPurchase' });
    }
    else if(this.overlayFrom === 'placeOrder'){
      Utility.openPlaceOrder(this.router, Utility.dialogData.omtkn, Utility.dialogData.exseg, Utility.dialogData.action, this.dialog);
    }
  }
}
