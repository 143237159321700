<app-header *ngIf = "isMobile" goback="1" [isLoading]="isLoading" [title]="'basket.basket_order' | translate"></app-header>
<app-titlebar *ngIf="!isMobile" [isLoading]="isLoading" [title]="'basket.basket_order' | translate"></app-titlebar>
<mat-dialog-content [class.p-0] = "!isMobile">
<div [class.onlyheaderfromtop]="isMobile" [class.mb-5]="isMobile">
  <div [class.p-2]="!isMobile">
    <div *ngIf = "!isLoading && isNodata">
      <app-nodata message = "No Data"></app-nodata>
    </div>
    <div *ngIf="!isNodata" class="po-section-data" [class.pt-3] = "isMobile">
      <div class="row" *ngIf="isEmandateFlag && emandateShow" >
        <div class="col-12 text-right pt-2">
          <a class="plainlink" (click)="openEmandate()">
              <span><b translate>all.emandate</b></span>
          </a>
        </div>
      </div>
      <div class="pb-2 ts-3 border-b line-height"><span>{{'basket.your_orders' | translate}}</span> &nbsp;<span class="text-primary">({{'basket.batch_no' | translate}} {{basketOrderlList[0].batch_no}})</span></div>
      <div  class = "basket-popup-content2" [class.basket-popup-content4]="isMobile">
      <div class="row" *ngFor='let data of basketOrderlList; let i = index;'>
        <div class="col-12">
          <div class="list-view  border-b">
              <div class="row">
                  <div class="col-6">
                      <span class="tc-1 ts-4 textclip">{{data.trading_symbol | symbolpipe}}</span>
                      <div class="pt-1">
                          <span class="subcolor ts-2">{{'books.avg_order' | translate}}:</span>
                          <span class="pl-1">{{data.avg_price | pricepipe}}</span>
                      </div>
                  </div>
                  <div class="col-6">
                      <div class="subcolor ts-2 text-right">
                          <div class="">
                              <span class="subcolor2 ts-2">{{'books.filled_qty' | translate}}.</span>
                              <span class="ts-4 tc-1 pl-1">
                                  {{data.fill_qty}}
                              </span>
                              <div class="pt-1">
                                  <span class="pl-1 tc-1" [ngClass]="{'rejected-status': !(data.status == 'complete' || data.status == 'open')}" [ngClass]="{'traded-status': data.status == 'complete'}">{{data.status}}</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
</mat-dialog-content>

<mat-dialog-actions *ngIf = "!isMobile" class="mat-dialog-actions d-block" [class.mat-action-web]="!isMobile">
  <div class="row">
      <div [class.col-12]="isMobile" [class.col-6]="!isMobile" [class.bottom-align]="isMobile" [class.p-action-l]="isMobile" [class.pl-3]="!isMobile">
        <button class="w-100 btn-primary" mat-flat-button (click)="GoToBasket()"><span translate>{{'basket.gt_invest' | translate}}</span></button>
      </div>

      <div class="px-0" [class.col-12]="isMobile" [class.col-6]="!isMobile" [class.footerbtn]="isMobile" [class.p-action-r]="isMobile" [class.pr-3]="!isMobile">
        <button class="w-100 btn-negative" [disabled]='retryDisable' mat-flat-button (click)="Retry(basketOrderlList)"><span translate>{{'basket.retry_fail_ordr' | translate}}</span></button>
      </div>

  </div>
</mat-dialog-actions>

<div class="footerbtn fixatbottom" *ngIf = "isMobile">
  <div class="row m-0">
      <div class="col-6 px-0">
          <button class="w-100 btn-primary" mat-flat-button (click)="GoToBasket()"><span translate>{{'basket.gt_invest' | translate}}</span></button>
        </div>
        <div class="col-6 px-0" >
          <button class="w-100 btn-negative" [disabled]='retryDisable' mat-flat-button (click)="Retry(basketOrderlList)"><span translate>{{'basket.retry_fail_ordr' | translate}}</span></button>
        </div>
  </div>
</div>