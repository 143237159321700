import { Injectable, ɵConsole } from '@angular/core';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import { IScripService } from './iscrip.service';
import { ScripModel } from 'src/app/model/scrip.model';
import { Utility } from '../utility';
import { PlaceOrderModel } from 'src/app/model/place.order.model';
import { ScripMarketPictureModel } from 'src/app/model/scripMarketPictureModel';
import { map } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { Preferences } from '../Preferences';

let scripService: ScripService;

@Injectable()
export class ScripService implements IScripService {
    apiService: ApiService;
    private subject = new Subject<any>();

    constructor(apiService: ApiService) {
        scripService = this;
        this.apiService = apiService;
    }
   placeOrder(pom: PlaceOrderModel, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const reqdata = {
            's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali'],
            'uid': Preferences.getPreference('userid'),
            'actid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'],
            'Tsym': pom.Tsym,
            'exch': pom.exch,
            'Ttranstype': pom.Ttranstype,
            'Ret': pom.Ret,
            'prctyp': pom.prctyp,
            'qty': pom.qty.toString(),
            'discqty': pom.discqty.toString(),
            'MktPro': pom.MktPro,
            'Price': pom.Price.toString(),
            'TrigPrice': pom.TrigPrice.toString(),
            'Pcode': pom.Pcode,
            'DateDays': pom.DateDays,
            'AMO': pom.AMO,
            'PosSquareFlg': pom.PosSquareFlg,
            'MinQty': pom.MinQty,
            'BrokerClient': pom.BrokerClient,
            'naicCode': pom.naicCode,
            'orderSource': pom.orderSource,
            'userTag': pom.userTag,
            'Exch_Algo_Id': pom.Exch_Algo_Id,
            'Exch_Algo_Category': pom.Exch_Algo_Category,
            'remarks': pom.remarks,
            'criteriaAttribute': pom.criteriaAttribute
        };
        const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.PLACE_ORDER, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(data, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }

    showQuote(sm: ScripModel, isCached: boolean, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        message = Keys.RES_MSG_OK;
        if (sm.isShowQuotesLoaded && isCached) {
            callback(sm, code, message);
        } else {
            const reqdata = { 'Exchange': sm.ExchangeName, 'Symbol': sm.Token, 'uid': Preferences.getPreference('userid') };
            const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
            this.apiService.post(Url.SHOW_QUOTE, param).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                let data = '';
                if (res['stat'] === 'Ok') {
                    data = res;
                    if (data['TSymbl'] !== '0') {
                        sm.isShowQuotesLoaded = true;
                        sm.DecimalPrecision = data['DecimalPrecision'];
                        sm.Low = data['Low'];
                        sm.High = data['High'];
                        sm.SellQty = data['SQty'];
                        sm.OpenPrice = data['openPrice'];
                        sm.TradingSymbol = data['TSymbl'];
                        sm.TotalSell = data['TotalSell'];
                        sm.YearlyLowPrice = data['yearlyLowPrice'];
                        sm.ExpiryDateDisplayValue = data['Exp'];
                        sm.PrvClose = data['PrvClose'];
                        sm.VwapAveragePrice = data['vwapAveragePrice'];
                        sm.YearlyHighPrice = data['yearlyHighPrice'];
                        sm.ExchangeFeedTime = data['exchFeedTime'];
                        sm.TickSize = data['TickSize'];
                        sm.Multiplier = data['Multiplier'];
                        sm.TradeVolume = data['TradeVolume'];
                        sm.BestBuyPrice = data['BRate'];
                        sm.Change = data['Change'];
                        sm.Lasttradedquantity = data['LTQ'];
                        sm.PercentageChange = data['PerChange'];
                        sm.OptionType = data['optiontype'];
                        sm.TotalBuy = data['TotalBuy'];
                        sm.LastTradedTime = data['LTT'];
                        sm.LastTradePrice = Utility.formatNumberToDecimalPrecision(data['Ltp'], 2);
                        sm.BestSellPrice = data['SRate'];
                        sm.BuyQuantity = data['BQty'];
                        sm.BodLotQuantity = data['BodLotQty'];
                        sm.UniqueKey = data['UniqueKey'];
                        sm.Symbolname = data['symbolname'];
                        sm.Series = data['Series']; sm.NoMarketProtectionFlag = data['noMktPro'];
                        sm.MarketProtection = data['mktpro'];
                        sm.DefaultMarketProtectionValue = data['defmktproval'];
                        sm.StrikePrice = data['strikeprice'];
                        if (sm.BestBuySize == null) {
                            sm.BestBuySize = 0;
                        }
                        if (sm.BestSellSize == null) {
                            sm.BestSellSize = 0;
                        }
                        sm.CompanyName = data['companyname'];
                    } else {
                        code = Keys.RES_CODE_FAILURE;
                        message = Keys.NO_DATA;
                    }
                } else {
                    code = Keys.RES_CODE_FAILURE;
                    message = res.Emsg;
                }
                callback(sm, code, message);
            }, error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(sm, code, message);
            });
        }
    }

    getMultipleScrips(scripList, callback: Function) {
        let message = '';
        let code;
        const scripModelData = new Array<ScripModel>();
        const param = Utility.prepareParameters(JSON.stringify(scripList), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.MULTIPLE_SCRIP, param).subscribe(res => {
            if (res.length > 0) {
                code = 200;
                message = 'Ok';
                res = JSON.parse(JSON.stringify(res));
                for (let i = 0; i < res.length; i++) {
                    const obj = res[i];
                    const bean = Utility.getScripModel(obj['token'], obj['ExchSeg']);
                    bean.ExchangeSegment = obj['ExchSeg'];
                    bean.TradingSymbol = obj['TradSym'];
                    bean.LastTradePrice = obj['ltp'];
                    bean.PercentageChange = obj['PerChange'];
                    bean.Token = obj['token'];
                    bean.ExchangeName = obj['Exchange'];
                    bean.BestBuyPrice = obj['BestBuyPrice'];
                    bean.BestBuySize = obj['BestBuySize'];
                    bean.BestSellPrice = obj['BestSellPrice'];
                    bean.BestSellSize = obj['BestSellSize'];
                    bean.TradeVolume = obj['TradeVolume'] === 'null' ? 'NA' : obj['TradeVolume'];
                    bean.Change = obj['Change'];
                    bean.InstrumentName = obj['Instrument'];
                    bean.DecimalPrecision = obj['decimalPrecision'];
                    bean.Multiplier = obj['multiplier'];
                    bean.BodLotQuantity = obj['bodlot'];
                    bean.NoMarketProtectionFlag = obj['noMktPro'];
                    bean.MinQty = obj['minQty'];
                    bean.MaxQty = obj['maxQty'];
                    bean.DiscQty = obj['discQty'];
                    bean.MarketProtection = obj['mktpro'];
                    bean.ExpiryDate = obj['expdate'];
                    bean.TickSize = obj['tcksize'];
                    bean.DefaultMarketProtectionValue = obj['defmktproval'];
                    bean.High = obj['high'];
                    bean.Low = obj['low'];
                    bean.UniqueKey = obj['UniqueKey'];
                    bean.CombinedSymbol = obj['CombinedSymbol'];
                    bean.Openinterest = obj['openinterest'];
                    bean.Symbolname = obj['symbolname'];
                    bean.StrikePrice = obj['strikeprice'];
                    bean.LastTradeQuantity = obj['lasttradedquantity'];
                    bean.CombinedSymbol = obj['CombinedSymbol'];
                    bean.OpenPrice = obj['open'];
                    bean.PrvClose = obj['close'];
                    bean.SpotPrice = obj['spotprice'];
                    bean.LastTradedTime = obj['lasttradedtime'];
                    bean.Corporateaction = obj['corporateaction'];
                    bean.VwapAveragePrice = obj['averageprice'];
                    bean.TotalBuyQty = obj['totalbuyqty'];
                    bean.TotalSellQty = obj['totalsellqty'];
                    bean.CompanyName = obj['companyname'];
                    bean.ExpiryDate = obj['Expiry'];
                    bean.OptionType = obj['optiontype'] == null ? 'XX' : obj['optiontype'];
                    bean.ExpiryDateDisplayValue = obj['Expiry'];
                    scripModelData.push(bean);
                }
            }
            else {
                message = 'Not Ok';
            }
            callback(scripModelData, code, message);
        });
    }
    getRetention(exch: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        if (Utility.RETENTION_MAP.get(exch)) {
            callback(Utility.RETENTION_MAP.get(exch), code, message);
        }
        else {
            const reqdata = { 'Exchange': exch };
            const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
            this.apiService.post(Url.GET_RETENTION, param).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                let data;
                if (res['stat'] === 'Ok') {
                    data = res['Ret'];
                    message = Keys.RES_MSG_OK;
                    Utility.RETENTION_MAP.set(exch, data);
                }
                else {
                    code = Keys.RES_CODE_FAILURE;
                    message = res.Emsg;
                }
                callback(data, code, message);
            }, error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(null, code, message);
            });
        }
    }



    ShowMarketPicture(scripData, callback: Function) {
        let message = '';
        let code;
        let bean = new ScripMarketPictureModel();
        const data = { 'uid': Preferences.getPreference('userid'), 'Exchange': Utility.segmentToExchange(scripData['exch']), 'Symbol': scripData['scripToken'] };
        const param = Utility.prepareParameters(JSON.stringify(data), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.MARKET_PICTURE_URL, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            const stat = res['stat'];
            if (stat === 'Ok') {
                message = 'Ok';
                code = Keys.RES_CODE_SUCCESS;
                bean = Utility.GetScripMarketPictureModel(scripData['scripToken'], scripData['exch'], scripData['restClient']);
                for (let i = 0; i < 5; i++) {
                    bean['BestBuyQuantity'][i] = res['BQty' + (i + 1)];
                    bean['BestBuyPrice'][i] = res['BPrice' + (i + 1)];
                    bean['BestBuyOrders'][i] = res['BOrders' + (i + 1)];
                    bean['BestSellQuantity'][i] = res['SQty' + (i + 1)];
                    bean['BestSellPrice'][i] = res['SPrice' + (i + 1)];
                    bean['BestSellOrder'][i] = res['SOrders' + (i + 1)];
                }
                bean.Symbol = res['Symbol'];
                bean.Exchange = res['Exchg'];
                bean.Ltp = Utility.getNumber(res['Ltp']);
                bean.TotalSellQty = Utility.getNumber(res['totalsellqty']);
                bean.TotalBuyQty = Utility.getNumber(res['totalbuyqty']);
                bean.TradingSymbol = res['TradingSymbol'];
                bean.HCircuitLimit = Utility.getNumber(res['highercircuitlimit']);
                bean.LCircuitLimit = Utility.getNumber(res['lowercircuitlimit']);
            }
            else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback(bean, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback('', code, message);
            });
    }

    getLotWeight(callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const userId = Preferences.getPreference('userid');
        const key = Url.GET_LOT_WEIGHT + '_' + userId;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (Utility.LOT_WEIGHT_MAP) {
            callback(Utility.LOT_WEIGHT_MAP, code, message);
        }
        else {
            if (predata !== undefined) {
                precallback(predata);
            }
            const reqdata = { 'uid': userId };
            const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
            this.apiService.post(Url.GET_LOT_WEIGHT, param).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                let data;
                if (res['stat'] === 'Ok') {
                    data = res['LtWt'];
                    message = Keys.RES_MSG_OK;
                    Utility.LOT_WEIGHT_MAP = data;
                    Utility.CACHE_STORE.setCacheData(key, data);
                }
                else {
                    code = Keys.RES_CODE_FAILURE;
                    message = res.Emsg;
                }
                callback(data, code, message);
            }, error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(null, code, message);
            });
        }
    }

    getSecInfo(data, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        if (Utility.SEC_INFO_MAP.get(data.exch + '_' + data.tk)) {
            callback(Utility.SEC_INFO_MAP.get(data.exch + '_' + data.tk), code, message);
        }
        else {
            const reqdata = { 'uid': Preferences.getPreference('userid'), 'Exchange': data.exch, 'SrchFor': data.tk };
            const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
            this.apiService.post(Url.GET_SEC_INFO, param).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                const ob = new ScripModel;
                if (res['stat'] === 'Ok') {
                    ob.LCircuitLimit = res['LCircuitLimit'];
                    ob.HCircuitLimit = res['HCircuitLimit'];
                    ob.BodLotQuantity = res['Blq'];
                    message = Keys.RES_MSG_OK;
                    Utility.SEC_INFO_MAP.set(data.exch + '_' + data.tk, ob);
                }
                else {
                    code = Keys.RES_CODE_FAILURE;
                    message = res.Emsg;
                }
                callback(ob, code, message);
            }, error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(null, code, message);
            });
        }
    }

    validateGTD(data, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const reqdata = { 'Exchange': data.Exchange, 'Expdate': data.Expdate, 'Retention': data.Retention, 'Gtdval': data.Gtdval };
        const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.VALIDATE_GTD, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let resData;
            if (res['stat'] === 'Ok') {
                resData = res.Date;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(resData, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }

    modifyOrder(pom: PlaceOrderModel, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const reqdata = {
            'uid': Preferences.getPreference('userid'),
            'Actid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'],
            'Exchangeseg': pom.exch,
            'Tsym': pom.Tsym,
            'Nstordno': pom.Nstordno,
            'Transtype': pom.Ttranstype,
            'Prctype': pom.prctyp,
            'Price': pom.Price.toString(),
            'Qty': pom.qty.toString(),
            'Dscqty': pom.discqty.toString(),
            'Trgprc': pom.TrigPrice.toString(),
            'Validity': pom.Ret,
            'Filledqty': pom.Filledqty,
            'Pcode': pom.Pcode,
            'Mktpro': pom.MktPro,
            'DateDays': pom.DateDays,
            's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali'],
            'orderSource': 'PROWEB',
            'Scripname': pom.Scripname,
            'Symbol': pom.Token,
            'Status': pom.Status,
            'RequestID': pom.RequestID
        };
        const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.MODIFY_ORDER, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(data, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }

    placeMultipleReqOrder(reqArray: any, responseCallback: Function) {
        const urls = [];
        reqArray.forEach(function (element) {
            const reqdata = {
                'uid': Preferences.getPreference('userid'),
                'actid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'],
                'exch': element.exchange,
                'Tsym': element.symbol,
                'Ttranstype': 'B',
                'Ret': 'DAY',
                'prctyp': element.orderType,
                'Price': element.ltp,
                'qty': element.qty.toString(),
                'discqty': '0',
                'MktPro': '0',
                'TrigPrice': '0',
                'Pcode': 'CNC',
                'DateDays': 'NA',
                'AMO': 'NO',
                'PosSquareFlg': 'N',
                'MinQty': '0',
                'BrokerClient': '1',
                'naicCode': '',
                'orderSource': 'WEB',
                'userTag': 'WEB',
                'remarks': '',
                's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali'],
            };
            const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
            urls.push(scripService.apiService.post(Url.PLACE_ORDER, param).pipe(map(res => res)));
        });

        forkJoin(urls).subscribe(results => {
            const responseArray = [];
            const temp = [];
            for (let i = 0; i < results.length; i++) {
                if (temp.indexOf(results[i]['Emsg']) === -1) {
                    const obj = {};
                    obj['id'] = i + 1;

                    if (results[i]['stat'] === 'Not_Ok') {
                        obj['Emsg'] = results[i]['Emsg'];
                        obj['status'] = results[i]['stat'];
                        temp.push(obj['Emsg']);
                    }
                    else {
                        obj['status'] = results[i]['stat'];
                        obj['orderId'] = results[i]['NOrdNo'];
                    }
                    responseArray.push(obj);
                }
            }
            responseCallback(responseArray);
        });
    }

    LTPCoverPrctge(data, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const key = data.Exchange + '_' + data.Symbol + '_' + data.Transtype;
        if (Utility.RETENTION_MAP.get(key)) {
            callback(Utility.RETENTION_MAP.get(key), code, message);
        }
        else {
            const reqdata = { 'Exchange': data.Exchange, 'Symbol': data.Symbol, 'Transtype': data.Transtype };
            const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
            this.apiService.post(Url.LTP_COVER_PRCNTGE, param).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                let dataObj;
                if (res['stat'] === 'Ok') {
                    dataObj = res['Coverprc'];
                    message = Keys.RES_MSG_OK;
                    Utility.RETENTION_MAP.set(key, dataObj);
                }
                else {
                    code = Keys.RES_CODE_FAILURE;
                    message = res.Emsg;
                }
                callback(dataObj, code, message);
            }, error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(null, code, message);
            });
        }
    }

    coverOrderPriceFlag(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        if (Utility.COVER_ORDER_PRICE_FLAG) {
            callback(Utility.COVER_ORDER_PRICE_FLAG, code, message);
        }
        else {
            const reqdata = {};
            const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
            this.apiService.post(Url.COVER_ORDER_PRICE_FLAG, param).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                let data;
                if (res['stat'] === 'Ok') {
                    data = res.Result;
                    message = Keys.RES_MSG_OK;
                    Utility.COVER_ORDER_PRICE_FLAG = data;
                }
                else {
                    code = Keys.RES_CODE_FAILURE;
                    message = res.Emsg;
                }
                callback(data, code, message);
            }, error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(null, code, message);
            });
        }
    }

    validateSLM(data, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const reqdata = { 'sExchange': data.sExchange, 'sToken': data.sToken, 'sSLPrice': data.sSLPrice, 'sOrderType': data.sOrderType, 'sMarketProtection': data.sMarketProtection };
        const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.VALIDATE_SLM_PRICE, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res['stat'] === 'Ok') {
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(null, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }

    placeBOOrder(pom: PlaceOrderModel, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const reqdata = {
            'exch': pom.exch,
            'TokenNo': pom.Token,
            'Ttranstype': pom.Ttranstype,
            'qty': pom.qty.toString(),
            'actid': JSON.parse(Preferences.getPreference('userParameter'))['sAccountId'],
            'uid': Preferences.getPreference('userid'),
            's_prdt_ali': JSON.parse(Preferences.getPreference('userParameter'))['s_prdt_ali'],
            'Ret': pom.Ret,
            'discqty': pom.discqty,
            'Price': pom.Price.toString(),
            'ltpOratp': pom.ltpOratp,
            'SqrOffAbsOrticks': pom.SqrOffAbsOrticks,
            'SqrOffvalue': pom.SqrOffvalue,
            'SLAbsOrticks': pom.SLAbsOrticks,
            'SLvalue': pom.SLvalue,
            'trailingSL': pom.trailingSL,
            'tSLticks ': pom.tSLticks,
            'naicCode': pom.naicCode,
            'orderSource': pom.orderSource,
            'userTag': pom.userTag,
            'Exch_Algo_Id': pom.Exch_Algo_Id,
            'Exch_Algo_Category': pom.Exch_Algo_Category,
            'prctyp': pom.prctyp,
            'TrigPrice': pom.TrigPrice.toString()
        };
        const param = Utility.prepareParameters(JSON.stringify(reqdata), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.PLACE_BO_ORDER, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.emsg;
            }
            callback(data, code, message);
        }, error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(null, code, message);
        });
    }

    spreadPlaceOrder(pom: PlaceOrderModel, callback: Function) {
        
    }
  
    spreadModifyOrder(pom: PlaceOrderModel, callback: Function) {
        
    }
}
