import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginModel } from '../login2factor/login.model';
import { TradingRestService } from '../../../services/tradingrest.service';
import { Utility } from '../../../services/utility';
import { Router } from '@angular/router';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { Preferences } from 'src/app/services/Preferences';

let changeQuestionComponent: ChangeQuestionComponent;
@Component({
  selector: 'app-change-question',
  templateUrl: './changequestion.component.html'
})
export class ChangeQuestionComponent implements OnInit {
  qdata;
  key;
  uid;
  loginModel: LoginModel;
  login2FDetails: LoginModel[] = [];
  questionList;
  selectedQuestion = 'please select 5 questions';
  selectedQuestionList = [];
  hideQuestion = true;
  selectQuestionCount = 5;
  hideq2 = true;
  hideq1 = true;
  panError;
  iaArray = [];
  isLoading = true;
  loginFlag;
  indices;
  isMobile = true;
  constructor(private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private router: Router, private translate: TranslateService) {
    changeQuestionComponent = this;
    changeQuestionComponent.isMobile = Utility.IS_MOBILE;
    changeQuestionComponent.uid = Preferences.getPreference('userid');
    changeQuestionComponent.loginFlag = Preferences.getPreference('LoggedIn');
    if (changeQuestionComponent.loginFlag)
      changeQuestionComponent.tradingRestService.getLoginService().DisplayQuestion(changeQuestionComponent.changeQuestionDataAfterLogin);
  }

  ngOnInit() {

  }

  onqSelection(event) {
    if (changeQuestionComponent.selectedQuestionList.length < 5)
      changeQuestionComponent.selectedQuestionList.push(event.value);
    else
      changeQuestionComponent.snackBar.open(changeQuestionComponent.translate.instant('login.selected_comp'), '', { duration: 3000 });
  }

  deleteQuestions(list) {
    changeQuestionComponent.selectedQuestionList = changeQuestionComponent.selectedQuestionList.filter(questionList => questionList.indices !== list.indices);
  }

  changeQuestionData(data) {
    changeQuestionComponent.isLoading = false;
    changeQuestionComponent.qdata = data;
    changeQuestionComponent.uid = changeQuestionComponent.qdata.loginid;
    changeQuestionComponent.questionList = data.sQuestions.split('|');
    const indicesData = changeQuestionComponent.qdata.sIndex.split('|');
    for (let i = 0; i < data.scount; i++) {
      changeQuestionComponent.loginModel = { indices: indicesData[i], question: changeQuestionComponent.questionList[i], ans: '' };
      changeQuestionComponent.login2FDetails.push(changeQuestionComponent.loginModel);
    }
  }

  changeQuestionDataAfterLogin(data, code, msg) {
    changeQuestionComponent.isLoading = false;
    if (code === 200 && msg === 'OK') {
      changeQuestionComponent.qdata = data;
      changeQuestionComponent.questionList = data.questions.split('|');
      const indicesdata = changeQuestionComponent.qdata.index.split('|');
      for (let i = 0; i < data.count; i++) {
        changeQuestionComponent.loginModel = { indices: indicesdata[i], question: changeQuestionComponent.questionList[i], ans: '' };
        changeQuestionComponent.login2FDetails.push(changeQuestionComponent.loginModel);
      }
    }
  }

  submit() {
    if (changeQuestionComponent.selectedQuestionList.length >= 5) {
      if (changeQuestionComponent.loginFlag === 'true')
        changeQuestionComponent.indices = changeQuestionComponent.qdata.index.replace(/\|/g, '-');
      else
        changeQuestionComponent.indices = changeQuestionComponent.qdata.sIndex.replace(/\|/g, '-');
      let j = 0;
      for (let i = 0; i < changeQuestionComponent.login2FDetails.length; i++) {
        if (changeQuestionComponent.login2FDetails[i]['ans'] !== '') {
          if (changeQuestionComponent.selectQuestionCount >= j) {
            j++;
            changeQuestionComponent.login2FDetails[i]['answers'] = Utility.getSHAEncryptedData(changeQuestionComponent.login2FDetails[i]['ans']);
            changeQuestionComponent.iaArray.push(changeQuestionComponent.login2FDetails[i]['indices'] + ' ' + changeQuestionComponent.login2FDetails[i]['answers']);
          }
        }
      }
      if (j === changeQuestionComponent.selectQuestionCount)
        changeQuestionComponent.tradingRestService.getLoginService().SaveAnswers(changeQuestionComponent.uid, changeQuestionComponent.iaArray, changeQuestionComponent.questionAnswerResponse);
      else {
        changeQuestionComponent.panError = true;
        changeQuestionComponent.snackBar.open(changeQuestionComponent.translate.instant('login.fill_answer'), '', { duration: 3000 });
      }
    }
    else
      changeQuestionComponent.snackBar.open(changeQuestionComponent.translate.instant('login.select_questions'), '', { duration: 3000 });
  }

  questionAnswerResponse(data, code, msg) {
    if (code === 200 && msg === 'OK') {
      changeQuestionComponent.snackBar.open(changeQuestionComponent.translate.instant('login.task_comp'), '', { duration: 3000 });
      if (!changeQuestionComponent.isMobile) {
        Utility.dialogRef.close();
      }
      changeQuestionComponent.router.navigate(['/home']);
    }
  }
}
