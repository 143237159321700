<app-titlebar [title]="'Index Spot'"></app-titlebar>
<mat-card>
    <mat-card-content class="p-2">
        <div [class.pt-0]="!isMobile">
            <div class="row">
                <div class="col-12">
                    <mat-chip-list class="nofilter">
                        <mat-chip *ngFor="let data of indexExSegArr" [class.imactive] = "tagText ==  data" (click)="getIndexData(data)">{{data}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="spotindexscroll">
                <ng-container *ngIf="!isLoading && !isNoData" >
                    <div class="pl-2 pr-2"  *ngFor="let data of indexList">
                        <div class="row cursor-pointer" (click)="setSpotValue(data)">
                            <div class="col-12">
                                <div class="watchlistwrapper">
                                    <div class="row">
                                        <div class="col-7">
                                            <div class="ts-3">{{data.Name}}</div>
                                        </div>
                                        <div class="col-5 text-right">
                                            <span [class.text-primary]="data.disable"><i class="icon-pin"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf='!isLoading && isNoData'>
                    <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
                </ng-container>
            </div>
        </div>
    </mat-card-content>
</mat-card>