<app-header (onTitleClick)="onTitleClick()" [goback]="goback" [title]="'more.derivative_news' | translate" [isLoading]="isLoading" [isSearch] = "isSearch" [freetrial] =  "true" (reloadPage) = "reloadData()"></app-header>
<div #sectionNeedToScroll class="data-container">
	<!-- <div class="row">
		<div class="col-12 pl-0">
			<mat-chip-list>
                <mat-chip *ngIf = "betaFeature" (click)= "onTabChanged(1)" [class.imactive] = "tabIndex ===  1" translate>derivative.option_chain</mat-chip>
                <mat-chip *ngIf = "betaFeature" (click)= "onTabChanged(2)" [class.imactive] = "tabIndex ===  2" translate>derivative.rollover</mat-chip>
                <mat-chip *ngIf = "betaFeature" (click)= "onTabChanged(3)" [class.imactive] = "tabIndex ===  3">{{'derivative.put' | translate}}/{{'derivative.call' | translate}} {{'derivative.ratio' | translate}}</mat-chip>
                <mat-chip *ngIf = "defaultFeature" (click)= "onTabChanged(4)" [class.imactive] = "tabIndex ===  4" translate>dashboard.news_updates</mat-chip>
			</mat-chip-list>
		</div>
	</div> -->
	<div class="row">
		<div class="col-12">
			<ng-container *ngxCacheIf = "tabIndex === 1">
				<app-option (loaderEvent)="loaderEvent($event)"></app-option>
			</ng-container>
			<ng-container *ngxCacheIf = "tabIndex === 2">
				<app-rollover-market (loaderEvent)="loaderEvent($event)"></app-rollover-market>
			</ng-container>
			<ng-container *ngxCacheIf = "tabIndex === 3">
				<app-putcallratio (loaderEvent)="loaderEvent($event)"></app-putcallratio>
			</ng-container>
			<ng-container *ngxCacheIf = "tabIndex === 4">
				<app-tagspecificnews tagtype="Derivative" [reloadData] = "reloadDerivativeNews" (loaderEvent)="loaderEvent($event)"></app-tagspecificnews>
			</ng-container>			
		</div>
	</div>
</div>