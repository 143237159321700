import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import * as crosshair from 'chartjs-plugin-crosshair';
import { Preferences } from 'src/app/services/Preferences';
import { Color} from 'ng2-charts';
import { Subscription } from 'rxjs-compat';
import { CustomBasket } from '../custombasket/custombasket.component';
import { environment } from 'src/environments/environment';
import { Z_DATA_ERROR } from 'zlib';
declare const moment: any;

let custombasketdetailchart: CustomBasketDetailChart;
@Component({
    selector: 'app-custombasketdetailchart',
    templateUrl: '../../../../'+environment.baseviewpath+'/thematicbasket/customthematicbasket/custombasketdetailchart/custombasketdetailchart.component.html',
    styleUrls: ['./custombasketdetailchart.component.scss']
})

export class CustomBasketDetailChart implements OnChanges, OnInit {
    @Input() basketdata: any;
    @Input() isCustomDashboard: boolean;
    basketId;
    basketName: string;
    baseIndexName: string;
    isNodata: boolean;
    isLoading: boolean;
    basketBenchmarkData;
    width;
    element;
    style;
    minimum;
    maximum;
    interval;
    palette: string[];
    chartData = [];
    chartLabels = [];
    chartOptions = {};
    chartColors = [];
    chartType;
    chartPlugins;
    chartLoaded: boolean;
    graphData = {};

    instrumentRatesData;
    barChartLoaded: boolean;
    barChartOptions = {};
    barChartLabels = [];
    barChartType;
    barChartData = [];
    barChartColors: Color[] = [
        { backgroundColor: 'rgba(0%,48.2%,100%)' }
    ];

    chartSubscription: Subscription;
    basketDetailList;
    pieChartOptions = {};
    pieChartLabels = [];
    pieChartData = [];
    pieChartType;
      
    constructor(private PS: PtPlusRestService) {
        custombasketdetailchart = this;
    }

    ngOnChanges() {
        custombasketdetailchart.basketId = custombasketdetailchart.basketdata.id;
        custombasketdetailchart.basketName = custombasketdetailchart.basketdata.name;
        custombasketdetailchart.baseIndexName = custombasketdetailchart.basketdata.base_index_name;

        let date: Date = new Date(custombasketdetailchart.basketdata.creation_time.split(' ')[0]); 
        let newDate = (date.getFullYear() - 1) + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        let formatedDate = moment(newDate).format('YYYY-MM-DD');
        custombasketdetailchart.getBenchMarkData(custombasketdetailchart.basketId, custombasketdetailchart.basketdata.base_index_name, formatedDate);

        const obj = {'basket_id': custombasketdetailchart.basketId };
    }
    ngOnInit() {
        if (custombasketdetailchart.isCustomDashboard) {
            custombasketdetailchart.basketId = custombasketdetailchart.basketdata.id;
            custombasketdetailchart.basketName = custombasketdetailchart.basketdata.name;
            custombasketdetailchart.baseIndexName = custombasketdetailchart.basketdata.base_index_name;

            let date: Date = new Date(custombasketdetailchart.basketdata.creation_time.split(' ')[0]); 
            let newDate = (date.getFullYear() - 1) + '-' + (date.getMonth() + 1) + '-' + date.getDate();
            let formatedDate = moment(newDate).format('YYYY-MM-DD');
            custombasketdetailchart.getBenchMarkData(custombasketdetailchart.basketId, custombasketdetailchart.basketdata.base_index_name, formatedDate);    
        }
    }

    drawPieChart()
    {
        const labelArr = [];
        const dataArr = [];
        custombasketdetailchart.pieChartType = 'pie';
        custombasketdetailchart.basketDetailList.forEach((element) => {
            dataArr.push(element['percent_weight']);
            labelArr.push(element['symbol']);
        });

        this.pieChartLabels = labelArr;
        this.pieChartData = dataArr;
        custombasketdetailchart.pieChartOptions = {
            plugins: {
                crosshair: false
            },
            responsive: true
        }
    }

    instrumentRatesResponse(data, code, message) {
        if (code === 200 && message === 'SUCCESS') {
            custombasketdetailchart.instrumentRatesData = data;
            custombasketdetailchart.drawBarChart();
        }
        else custombasketdetailchart.barChartLoaded = false;
    }

    drawBarChart()
    {
        const dataArr = [];
        custombasketdetailchart.barChartLoaded = true;
        custombasketdetailchart.barChartType = 'horizontalBar';
        dataArr.push(custombasketdetailchart.instrumentRatesData['FD']);
        dataArr.push(custombasketdetailchart.instrumentRatesData['Inflation']);
        dataArr.push(custombasketdetailchart.instrumentRatesData['Equity']);
        dataArr.push(custombasketdetailchart.instrumentRatesData['BasketReturns']);

        custombasketdetailchart.barChartLabels = ['FD', 'Inflation', 'Equity', 'BasketReturns'];
        custombasketdetailchart.barChartData = [
            { data: dataArr, label: 'Past Performance' }
        ];
       custombasketdetailchart.barChartOptions = {
        plugins: {
            crosshair: false
        },
        responsive: true,
        scales: {
            xAxes: [
            {
                ticks: {
                max: 20,
                min: 0
                }
            }],
            yAxes: [{}]
        }
       };
    }

    getBenchMarkData(id1, id2, date) {
        custombasketdetailchart.isLoading = true;
        const obj = { 'basket_or_index': id1 + ',' + id2, 'type': 'basket,index', 'date': date, 'yearlydata': false };
        custombasketdetailchart.PS.getThematicBasketService().getMultipleBenchmarkData(obj, custombasketdetailchart.multipleBenchmarkDataResponse);
    }

    multipleBenchmarkDataResponse(data, code, message) {
        custombasketdetailchart.isLoading = false;
        custombasketdetailchart.chartLoaded = false;
        if (code === 200 && message === 'SUCCESS' && data[custombasketdetailchart.basketId].length > 0 && data[custombasketdetailchart.baseIndexName].length > 0) {
            custombasketdetailchart.basketBenchmarkData = data;
            custombasketdetailchart.drawComparisonChart();
        }
        else {
            custombasketdetailchart.isNodata = true;
        }
    }

    drawComparisonChart() {
        custombasketdetailchart.chartLoaded = true;
        const plottingValue = 35;
        const valueArr = [];
        const valueArr1 = [];
        const tempArr = [];
        const dateArr = [];
        const map = {};
        for (let i = 0; i < custombasketdetailchart.basketBenchmarkData[custombasketdetailchart.baseIndexName].length;i++) {
            let date = custombasketdetailchart.basketBenchmarkData[custombasketdetailchart.baseIndexName][i]['date'];
            let val = Number(custombasketdetailchart.basketBenchmarkData[custombasketdetailchart.baseIndexName][i]['value'].toFixed(2));
            if(!map[date]){
                let arr = [];
                map[date] = arr;    
            }
            map[date].push(val);
        }

        if (custombasketdetailchart.basketId) {
            for (let i = 0; i < custombasketdetailchart.basketBenchmarkData[custombasketdetailchart.basketId].length;i++) {
                let date = custombasketdetailchart.basketBenchmarkData[custombasketdetailchart.basketId][i]['date'];
                let val = Number(custombasketdetailchart.basketBenchmarkData[custombasketdetailchart.basketId][i]['value'].toFixed(2));
                if(!map[date]){
                    let arr = [];
                    map[date] = arr;    
                }
                map[date].push(val);
                if(map[date].length === 2){
                    tempArr.push(date);
                }
            }
        }

            for (let i = 0; i < tempArr.length;) {
                dateArr.push(tempArr[i]);
                valueArr.push(map[tempArr[i]][0]);
                valueArr1.push(map[tempArr[i]][1]);
                i = i + Math.ceil(tempArr.length / plottingValue);
            }

        custombasketdetailchart.chartData = [
            { data: valueArr, label: 'NIFTY', fill: false },
            { data: valueArr1, label: custombasketdetailchart.basketName, fill: false }
        ];
        const concatValueArr = valueArr1.concat(valueArr);
        custombasketdetailchart.chartLabels = dateArr;
        custombasketdetailchart.minimum = Math.min(...concatValueArr);
        custombasketdetailchart.minimum = custombasketdetailchart.getNearestMinValue(Math.ceil(custombasketdetailchart.minimum));
        custombasketdetailchart.maximum = Math.max(...concatValueArr);
        custombasketdetailchart.maximum = custombasketdetailchart.getNearestMaxValue(Math.ceil(custombasketdetailchart.maximum));
        custombasketdetailchart.interval = Math.floor((custombasketdetailchart.maximum - custombasketdetailchart.minimum) / 10);
    
        custombasketdetailchart.loadChart();
    }
    loadChart() {
        custombasketdetailchart.element = document.querySelector('.chart-color4');
        custombasketdetailchart.style = getComputedStyle(custombasketdetailchart.element);
        custombasketdetailchart.chartType = 'line';
        custombasketdetailchart.chartColors = [
            { borderColor: custombasketdetailchart.style.outlineColor },
            { borderColor: custombasketdetailchart.style.borderColor },
        ];
        custombasketdetailchart.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                titleAlign: 'center',
                titleSpacing: 10,
                titleMarginBottom: 14,
                bodySpacing: 10,
                intersect: false,
                mode: 'index',
                callbacks: {
                    title: function (tooltipItems, data) {
                        return new Date(tooltipItems[0].xLabel).toLocaleDateString('en-DE', { month: 'short', year: 'numeric' });
                    },
                    label: function (tooltipItem, data) {
                        return (' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel.toFixed(2) + '%');
                    }
                }
            },
            elements: { point: { radius: 0 } },
            layout: { padding: { left: 8, right: 8, top: 16 } },
            legend: { position: 'bottom', labels: { boxWidth: 25, fontColor: custombasketdetailchart.style.color } },
            plugins: {
                datalabels: { display: false },
                crosshair: {
                    line: { color: custombasketdetailchart.style.columnRuleColor, width: 3 },
                },
            },
            scales: {
                yAxes: [{
                    ticks: {
                        min: custombasketdetailchart.minimum, max: custombasketdetailchart.maximum, stepSize: custombasketdetailchart.interval,
                        fontColor: custombasketdetailchart.style.color,
                        callback: function (label, index, dataset) {
                            return label + '%';
                        }
                    },
                    gridLines: { display: false },
                }],
                xAxes: [{
                    type: 'time',
                    time: { unit: 'quarter', displayFormats: { quarter: 'MMM YYYY' } },
                    ticks: { fontColor: custombasketdetailchart.style.color },
//                    gridLines: { lineWidth: 0, zeroLineColor: custombasketdetailchart.style.color },
                    gridLines: { display: false },

                }]

            },
        };
        custombasketdetailchart.chartPlugins = [crosshair];
    }

    getNearestMinValue(minimumValue) {
        const remainder = minimumValue % 10;
        minimumValue = minimumValue - remainder;
        minimumValue = minimumValue - 10;
        return minimumValue;
    }
    getNearestMaxValue(maximumValue) {
        const remainder = maximumValue % 10;
        maximumValue = maximumValue - remainder;
        maximumValue = maximumValue + 10;
        return maximumValue;
    }
}
