import { WebsocketService } from './websocket.service';
import { ChannelType, Utility } from '../utility';
import { ScripModel } from '../../model/scrip.model';
import { Keys } from '../keys';
import { inflate } from 'pako';
import { ScripMarketPictureModel } from 'src/app/model/scripMarketPictureModel';
import { ScripService } from '../scrip/scrip.service';
import { environment } from 'src/environments/environment';
import { Preferences } from '../Preferences';

const HB = 'hb';
const CN = 'cn';
const TASK = 'task';
const MW = environment.isHypersync === true ? 'mws' : 'mw';
const MWSP = 'mwsp';
const SF = 'sf';
const INDEX = 'sfi';
const ORDER = 'os';
const DEPTH = environment.isHypersync === true ? 'dps' : 'dp';
let hbTimeInterval;
let marketDataFeeder: MarketDataFeeder;
let marketDataWebSocket: WebsocketService;
let channelData = '';
let indexchannelData = '';
let orderChannelData = '';
let depthChannelData = '';
let isConnected = false;
const RES_SF = 'sf';
const RES_IF = 'if';
const RES_DP = 'dp';
const RES_OSF = 'osf';
const AMSG = 'amsg';
let timeout: any;
let unSubTimeout: any;
const adminTimeout = null;
let indexTimeout: any;
const scripLimit = 40;
let depthTimeout: any;
const orderTimeout = null;
const min_sec = 10, max_sec = 15;
const actid = environment.isQuickRest === true ? 'actId' : 'sAccountId';
export class MarketDataFeeder {
    constructor(private scripService?: ScripService) {
        marketDataFeeder = this;
    }

    CreateConnection() {
        isConnected = false;
        marketDataWebSocket = new WebsocketService();
        marketDataWebSocket.Connect(marketDataFeeder.ConnectionCallback);
    }
    closeSocketConnection() {
        if (marketDataWebSocket !== undefined) {
            marketDataWebSocket.closeSocketConnection();
        }
    }
    ConnectionCallback(status, response = null) {
        if (status === 'open') {
            const obj = marketDataFeeder.getJsonObj(CN, '', 1);
            marketDataWebSocket.sendMessage(obj);
        } else if (status === 'error') {
            isConnected = false;
            const millsec = (Math.floor(Math.random() * (max_sec - min_sec)) + min_sec) * 1000;
            setTimeout(function () {
                marketDataWebSocket.Connect(marketDataFeeder.ConnectionCallback);
            }, millsec);
        } else if (status === 'message') {
        //    const decoded = window.atob(response);
        //    let data = marketDataFeeder.ctos(inflate(decoded));
            let data = JSON.parse(response);
            for (let cnt = 0; cnt < data.length; cnt++) {
                const obj = JSON.parse(JSON.stringify(data[cnt]));
                if (isConnected) {
                    if (obj.hasOwnProperty('name') && (obj.name === RES_SF)) {
                        if (obj.hasOwnProperty('tk') && obj.hasOwnProperty('e')) {
                            const sModel = Utility.ScripModelMap.get(obj.e+'_'+obj.tk);
                            marketDataFeeder.UpdateScripModel(obj, sModel);
                        }
                    } else if (obj.hasOwnProperty('name') && (obj.name === RES_IF)) {
                        if (obj.hasOwnProperty('tk') && obj.hasOwnProperty('e')) {
                            const token = obj.tk;
                            const exchange = obj.e;
                            const sModel = Utility.getIndexModel(token, exchange, null, false, ChannelType.None);
                            marketDataFeeder.UpdateIndexModel(obj, sModel);
                        }
                    } else if (obj.hasOwnProperty('name') && obj.name === RES_DP) {
                        if (obj.hasOwnProperty('tk') && obj.hasOwnProperty('e')) {
                            const scripMarketPictureModel = Utility.GetScripMarketPictureModel(obj.tk, obj.e);
                            marketDataFeeder.updateScripMarketPictureModel(obj, scripMarketPictureModel);
                            const sModel = Utility.ScripModelMap.get(obj.e+'_'+obj.tk);
                            if(sModel != undefined)
                                marketDataFeeder.bestBidAndAsk(obj, sModel)
                        }
                    }
                } else if (obj.hasOwnProperty('task') && obj.task === CN && obj.hasOwnProperty('msg') && obj.msg === 'connected') {
                    marketDataFeeder.ConnectionCallback('connected');
                } else if (obj.hasOwnProperty('type') && obj.type === CN && obj.hasOwnProperty('msg') && obj.msg === 'successful') {
                    marketDataFeeder.ConnectionCallback('connected');
                }

            }
        } else if (status === 'connected') {
            isConnected = true;
            clearInterval(hbTimeInterval);
            hbTimeInterval = setInterval(marketDataFeeder.StartHB, 30000);
            if (Utility.ChannelScripMap) {
                Utility.ChannelScripMap.forEach(objMap => {
                    if (objMap !== undefined) {
                        objMap.forEach(value => {
                            value.SubscribedCn = 0;
                        });
                    }
                });
            }
            if (Utility.MarketPictureModelMap !== undefined) {
                Utility.MarketPictureModelMap.forEach(value => {
                    value.IsSubscribe = false;
                });
            }
            if (environment.isQuickRest)
            {
                if (Utility.ChannelScripMap)
                    marketDataFeeder.SubscribeSnapshot(Array.from(Utility.ChannelScripMap.keys()));
            } else {
                marketDataFeeder.SubscribeNow(Array.from(Utility.ChannelScripMap.keys()));
            }
            marketDataFeeder.subscribeDepthScrip();
            marketDataFeeder.SubscribeIndexSnap(ChannelType.Index);
            marketDataFeeder.SubscribeOrderNow();
            marketDataFeeder.SubscribeAdminNow();
            setTimeout(() => {
                marketDataFeeder.SubscribeIndexSnap(ChannelType.Dashboard);
            }, 1000);
        } else if (status === 'close' && marketDataWebSocket?.isOpenConn) {
            isConnected = false;
            if (!Utility.hidden)
            {
                const millsec = (Math.floor(Math.random() * (max_sec - min_sec)) + min_sec) * 1000;
                setTimeout(function () {
                    marketDataFeeder.ReconnectWS();
                }, millsec);
            }
        }
    }

    UpdateScripModel(jObj, sModel) {        
        sModel.YearlyLowPrice = jObj.yl ? jObj.yl : sModel.YearlyLowPrice;
        sModel.YearlyHighPrice = jObj.yh ? jObj.yh : sModel.YearlyHighPrice;
        sModel.HCircuitLimit = jObj.ucl ? jObj.ucl : sModel.HCircuitLimit;
        sModel.LCircuitLimit = jObj.lcl ? jObj.lcl : sModel.LCircuitLimit;
        sModel.VwapAveragePrice = jObj.ap ? jObj.ap : sModel.VwapAveragePrice;
        sModel.TradingSymbol = jObj.ts ? jObj.ts : sModel.TradingSymbol;
        sModel.PrvClose = jObj.c ? jObj.c : sModel.PrvClose;
        sModel.PercentageChange = jObj.nc ? jObj.nc : sModel.PercentageChange;
        sModel.Change = jObj.cng ? jObj.cng : sModel.Change;
        sModel.TradeVolume = jObj.v ? jObj.v : sModel.TradeVolume;
        sModel.Lasttradedquantity = jObj.ltq ? jObj.ltq : sModel.Lasttradedquantity;
        sModel.LastTradedTime = jObj.ltt && jObj.ltt !== 'NA' ? jObj.ltt : sModel.LastTradedTime;
        sModel.OpenPrice = jObj.op ? jObj.op : sModel.OpenPrice;
        sModel.High = jObj.h ? jObj.h : sModel.High;
        sModel.Low = jObj.lo ? jObj.lo : sModel.Low;
        sModel.TotalSell = jObj.tsq ? jObj.tsq : sModel.TotalSell;
        sModel.TotalBuy = jObj.tbq ? jObj.tbq : sModel.TotalBuy;
        sModel.Openinterest = jObj.oi ? jObj.oi : sModel.Openinterest;
        sModel.setTimeOut(jObj);
    }

    UpdateIndexModel(jObj, indexModel) {
        if (jObj.nc)
            indexModel.PerCng = parseFloat(jObj.nc);  // % Change
        if (jObj.cng)
            indexModel.Change = parseFloat(jObj.cng); // Change
        indexModel.IndexValue = parseFloat(jObj.iv); // Index Value
        if(jObj.ftm0)
            indexModel.UpdTime = jObj.ftm0; // Update Time
        if (jObj.openingPrice)
            indexModel.OpenPrice = parseFloat(jObj.openingPrice);
        if (jObj.lowPrice)
            indexModel.lowPrice = parseFloat(jObj.lowPrice);
        if (jObj.highPrice)
            indexModel.highPrice = parseFloat(jObj.highPrice);
        if (jObj.ic)
            indexModel.close = parseFloat(jObj.ic);
    }

    updateScripMarketPictureModel(jobj, scripMarketPictureModel: ScripMarketPictureModel) {
        scripMarketPictureModel.BestBuyQuantity[0] = jobj.bq ? jobj.bq : scripMarketPictureModel.BestBuyQuantity[0];
        scripMarketPictureModel.BestBuyQuantity[1] = jobj.bq1 ? jobj.bq1 : scripMarketPictureModel.BestBuyQuantity[1];
        scripMarketPictureModel.BestBuyQuantity[2] = jobj.bq2 ? jobj.bq2 : scripMarketPictureModel.BestBuyQuantity[2];
        scripMarketPictureModel.BestBuyQuantity[3] = jobj.bq3 ? jobj.bq3 : scripMarketPictureModel.BestBuyQuantity[3];
        scripMarketPictureModel.BestBuyQuantity[4] = jobj.bq4 ? jobj.bq4 : scripMarketPictureModel.BestBuyQuantity[4];

        scripMarketPictureModel.BestSellQuantity[0] = jobj.bs ? jobj.bs : scripMarketPictureModel.BestSellQuantity[0];
        scripMarketPictureModel.BestSellQuantity[1] = jobj.bs1 ? jobj.bs1 : scripMarketPictureModel.BestSellQuantity[1];
        scripMarketPictureModel.BestSellQuantity[2] = jobj.bs2 ? jobj.bs2 : scripMarketPictureModel.BestSellQuantity[2];
        scripMarketPictureModel.BestSellQuantity[3] = jobj.bs3 ? jobj.bs3 : scripMarketPictureModel.BestSellQuantity[3];
        scripMarketPictureModel.BestSellQuantity[4] = jobj.bs4 ? jobj.bs4 : scripMarketPictureModel.BestSellQuantity[4];

        scripMarketPictureModel.BestBuyPrice[0] = jobj.bp ? jobj.bp : scripMarketPictureModel.BestBuyPrice[0];
        scripMarketPictureModel.BestBuyPrice[1] = jobj.bp1 ? jobj.bp1 : scripMarketPictureModel.BestBuyPrice[1];
        scripMarketPictureModel.BestBuyPrice[2] = jobj.bp2 ? jobj.bp2 : scripMarketPictureModel.BestBuyPrice[2];
        scripMarketPictureModel.BestBuyPrice[3] = jobj.bp3 ? jobj.bp3 : scripMarketPictureModel.BestBuyPrice[3];
        scripMarketPictureModel.BestBuyPrice[4] = jobj.bp4 ? jobj.bp4 : scripMarketPictureModel.BestBuyPrice[4];

        scripMarketPictureModel.BestSellPrice[0] = jobj.sp ? jobj.sp : scripMarketPictureModel.BestSellPrice[0];
        scripMarketPictureModel.BestSellPrice[1] = jobj.sp1 ? jobj.sp1 : scripMarketPictureModel.BestSellPrice[1];
        scripMarketPictureModel.BestSellPrice[2] = jobj.sp2 ? jobj.sp2 : scripMarketPictureModel.BestSellPrice[2];
        scripMarketPictureModel.BestSellPrice[3] = jobj.sp3 ? jobj.sp3 : scripMarketPictureModel.BestSellPrice[3];
        scripMarketPictureModel.BestSellPrice[4] = jobj.sp4 ? jobj.sp4 : scripMarketPictureModel.BestSellPrice[4];

        scripMarketPictureModel.BestBuyOrders[0] = jobj.bno1 ? jobj.bno1 : scripMarketPictureModel.BestBuyOrders[0];
        scripMarketPictureModel.BestBuyOrders[1] = jobj.bno2 ? jobj.bno2 : scripMarketPictureModel.BestBuyOrders[1];
        scripMarketPictureModel.BestBuyOrders[2] = jobj.bno3 ? jobj.bno3 : scripMarketPictureModel.BestBuyOrders[2];
        scripMarketPictureModel.BestBuyOrders[3] = jobj.bno4 ? jobj.bno4 : scripMarketPictureModel.BestBuyOrders[3];
        scripMarketPictureModel.BestBuyOrders[4] = jobj.bno5 ? jobj.bno5 : scripMarketPictureModel.BestBuyOrders[4];

        scripMarketPictureModel.BestSellOrder[0] = jobj.sno1 ? jobj.sno1 : scripMarketPictureModel.BestSellOrder[0];
        scripMarketPictureModel.BestSellOrder[1] = jobj.sno2 ? jobj.sno2 : scripMarketPictureModel.BestSellOrder[1];
        scripMarketPictureModel.BestSellOrder[2] = jobj.sno3 ? jobj.sno3 : scripMarketPictureModel.BestSellOrder[2];
        scripMarketPictureModel.BestSellOrder[3] = jobj.sno4 ? jobj.sno4 : scripMarketPictureModel.BestSellOrder[3];
        scripMarketPictureModel.BestSellOrder[4] = jobj.sno5 ? jobj.sno5 : scripMarketPictureModel.BestSellOrder[4];

        scripMarketPictureModel.depthUpdate.next();
    }

    bestBidAndAsk(obj, scripModel)
    {
        scripModel.BestBuyPrice = obj.bp ? obj.bp : scripModel?.BestBuyPrice;
        scripModel.BestBuySize = obj.bq ? obj.bq : scripModel?.BestBuySize;
        scripModel.BestSellPrice = obj.sp ? obj.sp : scripModel?.BestSellPrice;
        scripModel.BestSellSize = obj.bs ? obj.bs : scripModel?.BestSellSize;
    }

    ctos(array) {
        const newarray = [];
        for (let i = 0; i < array.length; i++) {
            newarray.push(String.fromCharCode(array[i]));
        }
        return newarray.join('');
    }

    StartHB() {
        const obj = marketDataFeeder.getJsonObj(HB, '');
        marketDataWebSocket.sendMessage(obj);
    }

    getJsonObj(task, channel, type?, channelId?) {
        const uID = Preferences.getPreference('userid');
        const accountid = JSON.parse(Preferences.getPreference('userParameter'))[actid];
        const token = Preferences.getPreference('userSessionId');
        if (environment.isQuickRest) {
            switch (type) {
                case 1:
                    return {"sessionid": token, "type": "cn"}
                case 2:
                    return { 'scrips': channel, 'type': MWSP};
                case 3:
                    return { 'scrips': channel, 'type': MW, 'channelnum': channelId};
                case 4:
                    return { 'type': 'dpsp', 'scrips': channel };
                case 5:
                    return { 'type': DEPTH, 'scrips': channel, 'channelnum': channelId};
                case 6:
                    return { 'type': 'ifsp', 'scrips': channel };
                case 7:
                    return { 'type': 'ifs', 'scrips': channel, 'channelnum': channelId };
                case 8:
                    return { 'type': 'mwu', 'scrips': channel};            
                
            }
        }
        else
        {
            return { task: task, channel: channel, token: token, user: uID, acctid: accountid };
        }
    }

    DisconnectWS() {
        if (marketDataWebSocket != null && marketDataWebSocket.SocketStatus()) {
            clearInterval(hbTimeInterval);
            marketDataWebSocket.closeSocketConnection();
            marketDataWebSocket.isOpenConn = false;
            if (Utility.ChannelScripMap)
            {
                Utility.ChannelScripMap.forEach(objMap =>
                {
                    // let objMap = Utility.ChannelScripMap.get(i);
                    if (objMap !== undefined)
                    {
                        objMap.forEach(value =>
                        {
                            value.SubscribedCn = 0;
                        });
                    }
                });
            }
            if (Utility.MarketPictureModelMap !== undefined)
            {
                Utility.MarketPictureModelMap.forEach(value =>
                {
                    value.IsSubscribe = false;
                });
            }
        }
        isConnected = false;
        marketDataWebSocket = null;
    }

    ReconnectWS() {
        marketDataFeeder.DisconnectWS();
        if (marketDataWebSocket != null) {
            marketDataWebSocket.Connect(marketDataFeeder.ConnectionCallback);
        } else {
            marketDataFeeder.CreateConnection();
        }
    }

    SubscribeSnapshot(chanelArray) {
        if (marketDataWebSocket === undefined) {
            this.CreateConnection();
        }
        else {
            marketDataFeeder.SubscribeNow(Array.from(Utility.ChannelScripMap.keys()));
           //marketDataFeeder.SubscribeNow(Array.from(Utility.ChannelScripMap.keys()));
        }
    }
    SubscribeNow(chanelArray) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            for (var j = 0; j < chanelArray.length; j++)
            {
                channelData = '';
                let objMap = Utility.ChannelScripMap.get(chanelArray[j]);
                if (objMap !== undefined) {
                    objMap.forEach((value, key, map) => marketDataFeeder.loopMapElementsForSubscribe(value, key, map, chanelArray[j]));
                    if (channelData !== '') {
                        if (channelData.endsWith('&')) {
                            channelData = channelData.substring(0, channelData.length - 1);
                        }
                        Utility.print(channelData + ' ::Utility.print(channelData);');
                        if (marketDataWebSocket === undefined) {
                            this.CreateConnection();
                        }

                        const buffer = channelData.split('&');
                        if (scripLimit < buffer.length) {
                            let dataLimit = 0;
                            let data = '';
                            for (let i = 0; i < buffer.length; i++) {
                                if (dataLimit <= scripLimit) {
                                    data += buffer[i] + '&';
                                    dataLimit ++;
                                }
                                if (dataLimit === 40 || i === buffer.length - 1)
                                {
                                    data = data.substring(0, data.length - 1);
                                    const obj = marketDataFeeder.getJsonObj(MW, data, 3, chanelArray[j]);
                                    marketDataWebSocket.sendMessage(obj);
                                    dataLimit = 0;
                                    data = '';
                                }
                            }
                        }
                        else {
                            const obj = marketDataFeeder.getJsonObj(MW, channelData, 3, chanelArray[j]);
                            marketDataWebSocket.sendMessage(obj);
                        }
                    }
                }
            }
        }, 50);
    }

    loopMapElementsForSubscribe(value, key, map, index) {    
        if (value.ExchangeSegment && value.Token && (value.SubscribedCn & Math.pow(2,index)) == 0) {
            channelData += value.ExchangeSegment + '|' + value.Token + '&';
            value.SubscribedCn += Math.pow(2, index);
        }
    }

    loopMapElementsForUnsubscribe(value, key, map, index) {
        if (value.ExchangeSegment && value.Token && (value.SubscribedCn & Math.pow(2,index)) != 0) {
            channelData += value.ExchangeSegment + '|' + value.Token + '&';
            value.SubscribedCn -= Math.pow(2, index);
        }
    }

    loopMapElementsForIndexSubscribe(value, key, map) {
        if (value.ExchangeSegment && value.Token) {
            indexchannelData += value.ExchangeSegment + '|' + value.Token + '&';
        }
    }

    loopMapElementsForDepthSubscribe(value, key, map) {
        if (value.ExchangeSegment && value.Token && !value.IsSubscribe) {
            depthChannelData += value.ExchangeSegment + '|' + value.Token + '&';
            value.IsSubscribe = true;
        }
    }

    loopMapElementsForOrderSubscribe(value, key, map) {
        if (value.Exseg && value.Token) {
            orderChannelData += value.Exseg + '|' + value.Token + '&';
        }
    }

    SubscribeAdminNow() {
    }

    SubscribeIndexNow(channelID) {
        clearTimeout(indexTimeout);
        indexTimeout = setTimeout(() => {
                indexchannelData = '';
                let objMap = Utility.ChannelIndexMap?.get(channelID);
                if (objMap !== undefined) {
                    objMap.forEach(marketDataFeeder.loopMapElementsForIndexSubscribe);
                    if (indexchannelData !== '') {
                        if (indexchannelData.endsWith('&')) {
                            indexchannelData = indexchannelData.substring(0, indexchannelData.length - 1);
                        }
                        if (marketDataWebSocket === undefined)
                            this.CreateConnection();
                        const buffer = indexchannelData.split('&');
                        if (scripLimit < buffer.length) {
                            let dataLimit = 0;
                            let data = '';
                            for (let i = 0; i < buffer.length; i++) {
                                if (dataLimit <= scripLimit) {
                                    data += buffer[i] + '&';
                                    dataLimit ++;
                                }
                                if (dataLimit === 40 || i === buffer.length - 1)
                                {
                                    data = data.substring(0, data.length - 1);
                                    const obj = marketDataFeeder.getJsonObj('', data, 7, channelID);
                                    marketDataWebSocket.sendMessage(obj);
                                    dataLimit = 0;
                                    data = '';
                                }
                            }
                        }
                        else {
                            const obj = marketDataFeeder.getJsonObj('', indexchannelData, 7, channelID);
                            marketDataWebSocket.sendMessage(obj);
                        }
                    }
            }
        }, 50);
    }
 
    SubscribeIndexSnap(channelID) {
        if (marketDataWebSocket === undefined){
            this.CreateConnection();
        }
        else {
            marketDataFeeder.SubscribeIndexNow(channelID);
        }
    }

    subscribeDepthScripSnapshot(tkn, exseg) {
        if (!marketDataWebSocket) {
            this.CreateConnection();
        } else {
            marketDataFeeder.subscribeDepthScrip();
        }
    }

    subscribeDepthScrip() {
        clearTimeout(depthTimeout);
        depthTimeout = setTimeout(() => {
            depthChannelData = '';
            if (Utility.MarketPictureModelMap !== undefined) {
                Utility.MarketPictureModelMap.forEach(marketDataFeeder.loopMapElementsForDepthSubscribe);
                if (depthChannelData !== '') {
                    if (depthChannelData.endsWith('&')) {
                        depthChannelData = depthChannelData.substring(0, depthChannelData.length - 1);
                    }
                    let sendJson = {}
                    if (environment.isQuickRest){
                        sendJson = marketDataFeeder.getJsonObj('', depthChannelData, 5, ChannelType.Shared);
                    } else {
                        sendJson = {
                            'user': Preferences.getPreference('userid'),
                            'acctid': JSON.parse(Preferences.getPreference('userParameter'))[actid],
                            'channel': depthChannelData,
                            'task': DEPTH,
                            'token': Preferences.getPreference('userSessionId')
                        };
                    }
                    if (!marketDataWebSocket) {
                        this.CreateConnection();
                    }
                    marketDataWebSocket.sendMessage(sendJson);
                }
            }
        }, 50);
    }

    pauseChannel(channelArray)
    {
        const obj = { "type": "cp", "channelnums": channelArray };
        marketDataWebSocket?.sendMessage(obj);
    }

    resumeChannel(channelArray)
    {
        const obj = { "type": "cr", "channelnums": channelArray };
        marketDataWebSocket?.sendMessage(obj);
    }

    SubscribeOrderNow() {
    }

    unsubscribe(channelId) {
        clearTimeout(unSubTimeout);
        unSubTimeout = setTimeout(() => {
            channelData = '';
            let objMap = Utility.ChannelScripMap?.get(channelId);
            if (objMap !== undefined) {
                objMap.forEach((value, key, map) => marketDataFeeder.loopMapElementsForUnsubscribe(value, key, map, channelId));
                if (channelData !== '') {
                    if (channelData.endsWith('&')) {
                        channelData = channelData.substring(0, channelData.length - 1);
                    }
                    const obj = marketDataFeeder.getJsonObj('mwu', channelData, 8);
                    obj['channelnum'] = channelId;
                    marketDataWebSocket.sendMessage(obj);
                }
            }
            if(Utility.ChannelScripMap){
                Utility.ChannelScripMap.set(channelId, new Map());
            }
        }, 50);
    }
}
