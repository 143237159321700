import {  Component, OnInit, Output, EventEmitter, Input, Injector, ChangeDetectorRef, ElementRef} from '@angular/core';
import { Utility } from '../../../services/utility';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { TableUtility } from 'src/app/services/tableutility';
import { Router } from '@angular/router';
import { SpreadOrderdetailComponent } from './spreadorderdetail/spreadorderdetail.component';
import { SpreadOrderMoreOptionComponent } from './spreadordermoreoption/spreadordermoreoption.component';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { SpreadOrderModel } from 'src/app/model/spreadorder.model';
import { SpreadPlaceorderComponent } from './spreadplaceorder/spreadplaceorder.component';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let spreadOrder: SpreadOrderComponent;
@Component({
  selector: 'app-spreadorder',
  templateUrl: './spreadorder.component.html',
  styleUrls: ['./spreadorder.component.scss']
})
export class SpreadOrderComponent extends BaseComponentDirective implements OnInit {
  isMobile: boolean;
  utility: any;
  Height: {};
  totalheight;
  container;
  left: any;
  cellview;
  isManageColumns = true;
  isEdit: boolean;
  isCancel: boolean;
  spreadOrderList: Array<SpreadOrderModel>;
  isNoData: boolean;
  isLoading: boolean;
  @Output() load = new EventEmitter<boolean>();

  displayedColumns = ['TSym', 'ProductCode', 'AvgPrice', 'DisplayStatus', 'ActionDisplay', 'OrderType', 'OrderedTime'];

  constructor(elRef: ElementRef, private injector: Injector, private router: Router, private dialog: MatDialog, private detector: ChangeDetectorRef, private _bottomSheet: MatBottomSheet, private tradingRestService: TradingRestService){
    super(elRef.nativeElement);
    this.isMobile = Utility.IS_MOBILE;
    spreadOrder = this;
    this.utility=Utility;
    if (!Utility.IS_MOBILE) {
      // this.container = <ComponentContainer>this.injector.get('Container');
    }
  }

  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    if (width < Utility.MinWidth)
    this.cellview = true;
    else
    this.cellview = false;
    this.changeMode();
  }

  changeMode() {
    if (this.container.width !== null){
      this.totalheight = (this.container.height - TableUtility.WHeight);
      this.Height = {
        'height': this.totalheight + 'px',
        'overflow': 'auto',
        'scroll-behavior': 'smooth'
      };
      this.left = {
        'left': (this.container.width - TableUtility.WLeft) + 'px'
      };
      if (this.container.width < Utility.MinWidth) {
        this.cellview = true;
        this.isManageColumns = false;
      }
      else {
        this.cellview = false;
        this.isManageColumns = true;
      }
      this.detector.detectChanges();
    }
  }

  ngOnInit() {
    spreadOrder.callSpreadOrderService();
  }

  callSpreadOrderService(){
    spreadOrder.isLoading = true;
    spreadOrder.load.emit(spreadOrder.isLoading);
    spreadOrder.tradingRestService.getBookService().getAllSpreadOrders((response,code,message) => {
      spreadOrder.isLoading = false;
      spreadOrder.load.emit(spreadOrder.isLoading);
      if (code === 200 && message === 'OK' && response.length > 0){
        spreadOrder.isNoData = false;
        spreadOrder.spreadOrderList = response;
      }
      else{
        spreadOrder.isNoData = true;
      }
    }, spreadOrder.predefineSpreadOrder);
  }

  predefineSpreadOrder(data){
    spreadOrder.isLoading = false;
    spreadOrder.load.emit(spreadOrder.isLoading);
    spreadOrder.spreadOrderList = data;
  }

  onRefresh(){
    spreadOrder.callSpreadOrderService();
  }

  IsEdit(dataModel){
    if(dataModel.Status.toLowerCase() == "open" && dataModel.PriceType == "SP")
    {
      this.isEdit=true;
    }
    else{
      this.isEdit = false;
    }
    return this.isEdit;
  }

  IsCancel(dataModel){
    if(dataModel.Status.toLowerCase() == "open")
    {
      this.isCancel=true;
    }
    else{
      this.isCancel = false;
    }
    return this.isCancel;
  }

  onPlaceSpreadOrder(){
    Utility.openSpreadPlaceOrder(this.router, "", "", "B", this.dialog);
  }

  handleButton(buttonType, data) {
    Utility.spreadOrderDetail = data;
    switch (buttonType) {
      case 'E':
          const data_obj = { 'isEdit': true };
          Utility.openPage(this.router, 'orderhistory', 'placeorder', SpreadPlaceorderComponent, this.dialog, 'popup', data_obj, '500px', '1250px');
        break;
      case 'C':
          let alertMessage = 'Do you want to cancel ' + data.SymbolName + ' order?';
          Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { message: alertMessage, fromOrder: true } });
          Utility.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
              this.tradingRestService.getBookService().SpreadCancelOrder(data.nestOrderNumber, function(responsedata, code, message)  {
                if (responsedata['stat'] === 'Ok') {
                  this.callSpreadOrderService();
                }
              }.bind(this));
            }
          });
          break;
      case 'D':
        Utility.spreadOrderDetail = data;
        Utility.openPage(this.router, 'spreadorder', 'spreadorderdetail', SpreadOrderdetailComponent, this.dialog, 'popup');  
      break;
    }
  }

  onClickOrderDetail(data) {
    Utility.spreadOrderDetail = data;
    if (this.isMobile) {
      const bottomSheetRef = this._bottomSheet.open(SpreadOrderMoreOptionComponent, {
        restoreFocus: false
      });
      bottomSheetRef.afterDismissed().subscribe(result => {

      });
    }
  }

  handleMouseOver(row) {
    const NestOrd = row.NestOrd;
    this.spreadOrderList.map((data: any) => {
      if (NestOrd && data.NestOrd && data.NestOrd === NestOrd) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const NestOrd = row.NestOrd;
    this.spreadOrderList.map((data: any) => {
      if (NestOrd && data.NestOrd && data.NestOrd === NestOrd) {
        data.show = false;
      }
    });
  }
}
