import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import { Location} from '@angular/common';

let basketHomeCmp: UserBasketHomeComponent;
@Component({
    selector: 'app-userbaskethome',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/userbaskethome/userbaskethome.component.html',
    styleUrls: ['./userbaskethome.component.scss']
})
export class UserBasketHomeComponent implements OnInit, OnChanges {
    isLoading = true;
    isMobile;
    data;
    @Input() isOpen;
    @Output() closeUserBasket = new EventEmitter<any>();
    constructor(private location: Location) {
        basketHomeCmp = this;
        basketHomeCmp.isMobile = Utility.IS_MOBILE;
        basketHomeCmp.isLoading = false;
    }

    ngOnInit() {
        if(Utility.basketPositionData === undefined){
            this.location.back();
            Utility.SELECTED_BASKET_TAB = 1;
        }   
        else{
            this.data = Utility.basketPositionData;
        }
    }
    ngOnChanges() {
        this.data = Utility.basketPositionData;
    }
    onLoading(isLoading) {
        basketHomeCmp.isLoading = isLoading;
    }

    closeBasket(){
        this.closeUserBasket.emit();
    }

}

