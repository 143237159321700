import { Injectable } from '@angular/core';
import { ILoginService } from './login/ilogin.service';
import { LoginService } from './login/login.service';
// import { BookService } from './books/book.service';
// import { IBookService } from './books/ibook.service';
import { ApiService } from './api.service';
import { SearchService } from './search/search.service';
import { ISearchService } from './search/isearch.service';
import { INewsService } from './news/inews.service';
import { NewsService } from './news/news.service';
import { IThematicBasketService } from './thematicbasket/ithematic.service';
import { ThematicBasketService } from './thematicbasket/thematic.service';
import { IEventService } from './event/ievent.service';
import { EventService } from './event/event.service';
import { IFundamentalsService } from './fundamentals/ifundamentals.service';
import { FundamentalsService } from './fundamentals/fundamentals.service';
import { IScannerService } from './scanner/iscanner.service';
import { ScannerService } from './scanner/scanner.service';
import { IDerivativeService } from './derivative/iderivative.service';
import { DerivativeService } from './derivative/derivative.service';
import { IEquityMarketService } from './equitymarket/iequitymarket.service';
import { EquityMarketService } from './equitymarket/equitymarket.service';
import { IAuthenticationService } from './authentication/iauthentication.service';
import { AuthenticationService } from './authentication/authentication.service';
import { IWorkBookService } from './workbook/iworkbook.service';
import { WorkBookService } from './workbook/workbook.service';
import { TradingRestService } from './tradingrest.service';
import { IPredefinedWatchlistService } from './predefinedwatchlist/ipredefinedwatchlist.service';
import { PredefinedWatchlistService } from './predefinedwatchlist/predefinedwatchlist.service';

let ptplusRestService: PtPlusRestService;
@Injectable()
export class PtPlusRestService {
    fundamentalsService: IFundamentalsService;
    searchService: ISearchService;
    newsService: INewsService;
    eventService: IEventService;
    thematicBasketService: IThematicBasketService;
    scannerService: IScannerService;
    derivativeService: IDerivativeService;
    equityMarketService: IEquityMarketService;
    authenticationService: IAuthenticationService;
    workbookService: IWorkBookService;
    predefinedWatchlist: IPredefinedWatchlistService;
    constructor(private tradeApiService: TradingRestService, private apiService: ApiService) {
        ptplusRestService = this;
    }

    getSearchService() {
        if (ptplusRestService.searchService == null) {
            ptplusRestService.searchService = new SearchService(ptplusRestService.apiService);
        }
        return ptplusRestService.searchService;
    }
    getNewsService() {
        if (ptplusRestService.newsService == null) {
            ptplusRestService.newsService = new NewsService(ptplusRestService.apiService);
        }
        return ptplusRestService.newsService;
    }

    getThematicBasketService() {
        if (ptplusRestService.thematicBasketService == null) {
            ptplusRestService.thematicBasketService = new ThematicBasketService(ptplusRestService.apiService);
        }
        return ptplusRestService.thematicBasketService;
    }
    getEventService() {
        if (ptplusRestService.eventService == null) {
            ptplusRestService.eventService = new EventService(ptplusRestService.apiService);
        }
        return ptplusRestService.eventService;
    }
    getFundamentalsService() {
        if (ptplusRestService.fundamentalsService == null) {
            ptplusRestService.fundamentalsService = new FundamentalsService(ptplusRestService.apiService);
        }
        return ptplusRestService.fundamentalsService;
    }
    getScannerService() {
        if (ptplusRestService.scannerService == null) {
            ptplusRestService.scannerService = new ScannerService(ptplusRestService.tradeApiService, ptplusRestService.apiService);
        }
        return ptplusRestService.scannerService;
    }
    getDerivativeService() {
        if (!ptplusRestService.derivativeService) {
            ptplusRestService.derivativeService = new DerivativeService(this.tradeApiService, this.apiService);
        }
        return ptplusRestService.derivativeService;
    }
    getEquityMarketService() {
        if (!ptplusRestService.equityMarketService)
            ptplusRestService.equityMarketService = new EquityMarketService(this.tradeApiService, this.apiService);
        return ptplusRestService.equityMarketService;
    }
    getAuthenticationService() {
        if (!ptplusRestService.authenticationService)
            ptplusRestService.authenticationService = new AuthenticationService(this.apiService);
        return ptplusRestService.authenticationService;
    }
    getWorkBookService() {
        if (ptplusRestService.workbookService == null) {
            ptplusRestService.workbookService = new WorkBookService(ptplusRestService.apiService);
        }
        return ptplusRestService.workbookService;
    }

    getPredefinedWatchlistService() {
        if (!ptplusRestService.predefinedWatchlist) {
            ptplusRestService.predefinedWatchlist = new PredefinedWatchlistService(ptplusRestService.apiService);
        }
        return ptplusRestService.predefinedWatchlist;
    }
}
