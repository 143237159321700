<div class="row stickychips" [class.sidenav-on]="isMobile" *ngIf = "orderList">
    <div class="col-12">
        <div class="row">
        <div class="col-10 pr-0">
            <div *ngIf = "displayArray.length > 1">
                <mat-chip-list  *ngIf='!(!isLoading && isNoData) || isFilterNoData'>
                    <mat-chip  *ngIf = "!isLoading"  [class.imactive]="selectedLabel === 'ALL'" id="tab-header" (click) = "onTabChange('ALL','All')">ALL</mat-chip>
                    <mat-chip *ngFor="let obj of displayArray | slice:0: displayArrLen; let i = index" (click)="onTabChange(obj.data, obj.type, i)" [class.imactive]="obj.visibility === true">
                    {{obj.data}}</mat-chip>
                    <mat-chip *ngIf = "isExpandVisible" id="tab-header" (click) = "onExpanClick()" title="Expand">{{expandText}}</mat-chip>
                </mat-chip-list>
            </div>
            
        </div>
        <div #sortMenu class="col-2 text-right pl-0 my-auto" *ngIf="orderList.length > 0">
            <button *ngIf = "!isNoData" disabled="false" #menuTrigger="matMenuTrigger" class="btn-icon mr-1" mat-icon-button [matMenuTriggerFor]="sortnav" (menuClosed)="matMenuClosed()">
                <i class="icon-menu"></i>
                <em *ngIf="(selectedSortedItem !== sortItemList[0] || (selectedSortedItem === sortItemList[0] && chipSelected === matChipItems[1])) && (cellview || isMobile)" class="wl-bubblesmall"></em>
            </button>
            <mat-menu #sortnav="matMenu" xPosition="before" [class.col-12] = "true" class="panelnav">
                <div *ngIf="cellview || isMobile" [ngClass]="{'d-sm-none' : isMobile}" class="primetext p-2 ts-2">{{'all.sorting' | translate}}</div>
                <div *ngIf="cellview || isMobile" [ngClass]="{'d-sm-none' : isMobile}" (click)="$event.stopPropagation();$event.preventDefault();"><button (click)="sortingItemSelected(sortingItem)" mat-menu-item *ngFor="let sortingItem of sortItemList" >{{sortingItem}} <span class="rightitem impositive"><i [class.icon-godown]= "chipSelected === 'Asc' && selectedSortedItem === sortingItem" [class.icon-goup]= "chipSelected === 'Desc' && selectedSortedItem === sortingItem"></i></span></button></div>
                <div class="border-t pt-2">
                    <button *ngIf = "!isLoading && !isNoData && !isMobile && orderChecked.size > 0" mat-menu-item matTooltip="{{'all.cancelAll' | translate}}" (click) = "cancelMultipleOrder()">{{'all.cancelAll' | translate}}<span *ngIf="orderChecked.size > 0" class="rightitem">({{orderChecked.size}})</span></button>
                    <button *ngIf = "!isLoading && !isNoData" mat-menu-item matTooltip="{{'all.export' | translate}}" (click)="exportCSV()">{{'all.download' | translate}}<span class="rightitem"><i class="icon-refresh"></i></span></button>
                    <button *ngIf = "!isLoading && !isNoData && ((!cellview && !isMobile) || isMobileLand)" mat-menu-item matTooltip="{{'all.manage_columns' | translate}}" mat-menu-item  (click)="openManageBook()">{{'all.manage_columns' | translate}}<span class="rightitem"><i class="icon-setting"></i></span></button>
                </div>
            </mat-menu>        
        </div>
    </div>
    </div>
</div>
<div class="row" [class.sidenav-on]="isMobile">
    <div class="col-12">
        <div *ngIf='!isLoading && isNoData' class="">
            <app-nodata (refreshEvent) = "isFilterNoData ? onReset() : onRefresh()" [btnTitle]="isFilterNoData ? ('all.reset' | translate) : ('all.reload' | translate)"></app-nodata>
        </div>
        <ngx-pull-to-refresh
        spinnerColor="#ff0000"
        distanceForRefresh="80"
        [isEnable]="isMobile"
        [targetElement]="targetElement"
        (refresh)="myRefreshEvent($event, 'refresh')"
        (loadMore)="alert('loadmore')"
        class="loader">
        <div *ngIf='!isLoading && !isNoData'>
            <div #p2 *ngIf="isMobile || cellview" [ngStyle]="Height">
                <div *ngFor="let order of filteredOrderList; let i = index" [attr.id]="'ob_cell' + i" [class.wlweb]="cellview" [class.pl-2]="cellview" [class.pr-2]="cellview" (click)="highlight(order, i)" tabindex="999">
                    <div class="row">
                        <div class="col-12" [ngClass]="{'highlight': selectedRowIndex == i}">
                            <div class="list-view  border-b" [ngClass]="{'d-block d-sm-none' : isMobile}">                                
                                <div class="row">
                                    <div class="col-6">
                                        <div class="subcolor ts-2">
                                            <div><span [class.tag-red]="order.Action=='S'" [class.tag-green]="order.Action=='B'">{{order.ActionDisplay}}</span> | {{'all.qty' | translate}}: <span class="fw-3 tc-3">{{order.FilledShares | numberpipe}}<small class="ts-1 subcolor">/{{order.TotalQty | numberpipe}}</small></span></div>
                                            <span [ngClass]="{'tc-1' : true, 'ts-4' : true, 'texttrim' : true, 'fnotext' : order.Exchange === 'NFO'}" matTooltip="{{order.TSym | symbolpipe}}">{{order.TSym | symbolpipe}}</span><small class="exchangename">{{order.Exchange}}</small><br>
                                            {{order.ProductCode | valuepipe}} | {{order.OrderType | valuepipe}}
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="subcolor ts-2 text-right">
                                            <div class="">{{(order.OrderedTime === '--') ? '--' : (order.OrderedTime | datepipe:'mediumtime')}} | <span [class.in-progress-status] = "order.DisplayStatus=='In Progress'" [class.trigger-pend-status] = "order.DisplayStatus=='Trigger pending'" [class.amo-req-status] = "order.DisplayStatus=='after market order req received'"  [class.traded-status] = "order.DisplayStatus=='Traded'"   [class.Cancelled-status] = "order.DisplayStatus=='Cancelled'" [class.cancelled-amo-status] = "order.DisplayStatus=='cancelled after market order'" [class.open-status] = "order.DisplayStatus=='Open'" [class.modified-status] = "order.DisplayStatus=='Modified'" [class.rejected-status] = "order.DisplayStatus=='Rejected'">{{order.DisplayStatus}}</span></div>
                                            <span class="subcolor2">{{'all.prc' | translate}}: </span><span class="ts-4 tc-1">{{order.Price  | pricepipe:order.scripModel.DecimalPrecision}}</span>
                                            <div [class.imnegative]="order.scripModel.PercentageChange<0" [class.impositive]="order.scripModel.PercentageChange>0">
                                                <div [class.imnegativebg]="order.scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="order.scripModel.TickDirection === 1 && flashType === 'defaultFlash'">
                                                    <span [class.imnegativebg]="order.scripModel.TickDirection === -1 && flashType === 'ltpFlash'" [class.impositivebg]="order.scripModel.TickDirection === 1 && flashType === 'ltpFlash'">{{order.scripModel.LastTradePrice | pricepipe:order.scripModel.DecimalPrecision}}</span>&nbsp;
                                                    <small class="ts-1">({{order.scripModel.PercentageChange | percentagepipe}})</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div class="wl-btn" *ngxCacheIf="!isMobile && cellview" [class.d-none]="!M.IS_MOUSE">
                                    <button mat-icon-button matTooltip="{{'all.buy' | translate}}" (click)="handleButton('ADD', order)" class="btn-positive"><i class="icon-bb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.sell' | translate}}" (click)="handleButton('REMOVE', order)" class="btn-negative ml-2"><i class="icon-sb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.reorder' | translate}}" (click)="handleButton('reorder', order)" class="btn-primary ml-2"><i class="icon-pcb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.history' | translate}}" class="btn-primary ml-2" (click)="handleButton('H', order)"><i class="icon-history"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.modify' | translate}}" class="btn-primary ml-2" *ngIf="order.IsEdit" (click)='handleButton("M", order)'><i class="icon-edit"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.cancel' | translate}}" class="btn-primary ml-2" *ngIf="order.IsCancel" (click)="handleButton('C', order)"><i class="icon-cancel"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.exit' | translate}}" class="btn-primary ml-2" *ngIf="order.IsExit" (click)="handleButton('E', order)"><i class="icon-exit"></i></button>
                                    <button mat-icon-button (click) = "addWLdata(order)" matTooltip="{{'all.addto_watchlist' | translate}}"  class="btn-primary ml-2" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                    <mat-menu #menu="matMenu">
                                    <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                    <ng-container *ngFor = "let data of WlArr">
                                        <button mat-menu-item (click) = "addStock(data.wlName, order)" [disabled] = "data.isDisable">
                                            <span>{{data.wlName}}</span>
                                        </button>
                                    </ng-container>
                                    </mat-menu>
                                    <button mat-icon-button matTooltip="{{'all.stock_detail' | translate}}" class="btn-primary ml-2"  (click)="handleButton('sd', order)"><i class="icon-ib"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!cellview">
                <div #p1 class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'" [class.tabletscroll]="utility.IS_TABLET">
                    <table mat-table [dataSource]="filteredOrderList"  matSort (matSortChange)="sortOrderBook($event)" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                        <ng-container matColumnDef="TSym" sticky>
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag [cdkDragDisabled]="true">
                            <mat-checkbox *ngIf="!isMobile" [checked] = "(openOrderCount > 0 && checkedOpenOrder > 0) && openOrderCount === checkedOpenOrder" [disabled] = "!openOrderCount > 0" class = "p-2" (change) = "onCheckBoxClicked('ALL', '', $event, '')">
                            </mat-checkbox>&nbsp;
                            <span translate>all.instrument</span>&nbsp;
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">
                            <mat-checkbox *ngIf="!isMobile" [checked] = "element.CheckBoxValue && element.DisplayStatus === 'Open'"  class = "p-2" [disabled] = "element.DisplayStatus !== 'Open'" (change) = "onCheckBoxClicked(element, element.NestOrd, $event, i)">
                            </mat-checkbox>&nbsp;
                            <span class="button-row">
                                <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true" [class.d-none]="!M.IS_MOUSE">
                                    <button mat-icon-button matTooltip="{{'all.buy' | translate}}" (click)="handleButton('ADD', element)" class="btn-positive"><i class="icon-bb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.sell' | translate}}" (click)="handleButton('REMOVE', element)" class="btn-negative"><i class="icon-sb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.reorder' | translate}}" (click)="handleButton('reorder', element)" class="btn-primary"><i class="icon-pcb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.order_detail' | translate}}" class="btn-primary" (click)="handleButton('H', element)"><i class="icon-summary"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.modify' | translate}}" class="btn-primary" [disabled]="!element.IsEdit" (click)='handleButton("M", element)'><i class="icon-edit"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.cancel' | translate}}" class="btn-primary" [disabled]="!element.IsCancel" (click)="handleButton('C', element)"><i class="icon-cancel"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.exit' | translate}}" class="btn-primary" [disabled]="!element.IsExit" (click)="handleButton('E', element)"><i class="icon-exit"></i></button>
                                    <button mat-icon-button (click) = "addWLdata(element)" matTooltip="{{'all.addto_watchlist' | translate}}"  class="btn-primary" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                    <mat-menu #menu="matMenu">
                                        <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                        <ng-container *ngFor = "let data of WlArr">
                                            <button mat-menu-item (click) = "addStock(data.wlName, element)" [disabled] = "data.isDisable">
                                                <span>{{data.wlName}}</span>
                                            </button>
                                        </ng-container>
                                    </mat-menu>
                                    <button mat-icon-button matTooltip="{{'all.stock_detail' | translate}}" class="btn-primary"  (click)="handleButton('sd', element)"><i class="icon-ib"></i></button>
                                </div>
                            </span>
                            <span [matTooltip]="element.scripModel.CompanyName" class="txtsmall">{{element.TSym | symbolpipe}} <small class="nsecolor">{{element.Exchange}}</small></span>
                            <span class="infoicon"><i *ngIf="element.DisplayStatus=='Rejected'" [matTooltip]="element.RejectionReason" class="mt-1 float-right rejected-status icon-info-alert"></i></span>
                            </td>
                        </ng-container>  
                        <ng-container matColumnDef="ActionDisplay">
                            <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag><span translate> all.transaction_type </span> </th>
                            <td class="text-left" mat-cell *matCellDef="let element"><span [class.imnegative]="element.Action=='S'" [class.impositive]="element.Action=='B'"> {{element.ActionDisplay}} </span></td>
                        </ng-container>
                        <ng-container matColumnDef="ProductCode">
                            <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate> all.product </span></th>
                            <td class="text-left" mat-cell *matCellDef="let element"> {{element.ProductCode | valuepipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Qty">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag><span translate>all.qty</span> </th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{(element.TotalQty - element.PendingQty)  | numberpipe}}/ <small class="subcolor2">{{element.TotalQty | numberpipe}}</small></td>
                        </ng-container>
                        <ng-container matColumnDef="LastTradePrice">
                            <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag  [cdkDragDisabled]="true"><span translate> all.ltp</span> </th>
                            <td class="text-right" mat-cell *matCellDef="let element" [class.imnegative]="element.scripModel.Change<0" [class.impositive]="element.scripModel.Change>0">
                                <div [class.imnegativebg]="element.scripModel.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="element.scripModel.TickDirection === 1 && flashType !== 'noFlash'">
                                    {{element.scripModel.LastTradePrice| pricepipe:element.scripModel.DecimalPrecision}}            
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Price">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag><span translate> all.prc</span> </th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{element.Price | pricepipe:element.scripModel.DecimalPrecision}} </td>
                        </ng-container>
                        <ng-container matColumnDef="DisplayStatus">
                            <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag><span translate> all.status</span> </th>
                            <td class="text-left" mat-cell *matCellDef="let element" [class.in-progress-status] = "element.DisplayStatus=='In Progress'" [class.trigger-pend-status] = "element.DisplayStatus=='Trigger pending'" [class.amo-req-status] = "element.DisplayStatus=='after market order req received'"  [class.traded-status] = "element.DisplayStatus=='Traded'"   
                            [class.Cancelled-status] = "element.DisplayStatus=='Cancelled'" [class.rejected-status] = "element.DisplayStatus=='Rejected'" [class.cancelled-amo-status] = "element.DisplayStatus=='cancelled after market order'" [class.open-status] = "element.DisplayStatus=='Open'" [class.modified-status] = "element.DisplayStatus=='Modified'"> {{element.DisplayStatus}} </td>
                        </ng-container>
                        <ng-container matColumnDef="OrderedTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag><span translate> all.ordertime</span></th>
                            <td mat-cell *matCellDef="let element">
                                <span>{{(element.OrderedTime === '--') ? '--' : (element.OrderedTime | datepipe:'mediumtime')}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="TriggerPrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag> <span translate>all.trig_price </span></th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{element.TriggerPrice  | numberpipe}}</td>
                        </ng-container>
                        <ng-container matColumnDef="OrderType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>all.ordertype </span></th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{element.OrderType  | numberpipe}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Validity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>all.ordervalidity </span></th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{element.Validity  | numberpipe}}</td>
                        </ng-container>
                        <ng-container matColumnDef="DiscQty">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header lass="text-right" cdkDrag> <span translate>all.discqty </span></th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{element.DiscQty  | numberpipe}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" [ngClass]="{'highlight': selectedRowIndex == i}" (click)="highlight(row, i)" tabindex="999" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)" [attr.id]="'ob_tbl' + i"></tr>
                    </table>
                    </div>
            </ng-container>
        </div>
        </ngx-pull-to-refresh>
    </div>
</div>