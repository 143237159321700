<div class="dataimgwrapper">
    <div class="nd-align">
        <svg version="1.1" id="Layer_5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 470 470" style="enable-background:new 0 0 470 470;" xml:space="preserve">
   
        <path class="st0" d="M349.9,316.1c-1.3,0.9-2.6,1.8-4,2.7c-0.2,0.1-0.5,0.3-0.7,0.4c-1.3,0.8-2.7,1.5-4.1,2.3l-1.2,0.6
            c-1.6,0.8-3.3,1.6-5.1,2.4l-1.1,0.4c-1.6,0.7-3.2,1.3-4.9,1.9l-1.4,0.5c-2,0.7-4,1.4-6.1,2l-1.4,0.4c-1.8,0.6-3.7,1.1-5.6,1.6
            c-0.5,0.1-1,0.3-1.5,0.4c-2.2,0.6-4.5,1.1-6.9,1.7c-0.6,0.1-1.1,0.2-1.7,0.4c-2,0.5-4.1,0.9-6.2,1.3l-1.5,0.3
            c-2.5,0.5-5,0.9-7.5,1.3c-0.6,0.1-1.2,0.2-1.9,0.3c-2.2,0.4-4.5,0.7-6.8,1l-1.4,0.2c-2.6,0.4-5.3,0.7-8,1l-2,0.2
            c-1.6,0.2-3.2,0.3-4.9,0.5l36.9,61.2c31.5-6.9,49.2-17.3,49.2-25.8l0-59.5C350.2,316,350.1,316,349.9,316.1z"/>
        <path class="st0" d="M183.9,340.3l-0.7-0.1c-2.5-0.2-5-0.5-7.4-0.7l-2-0.2c-2.7-0.3-5.3-0.6-8-1l-1.4-0.2c-2.3-0.3-4.6-0.7-6.8-1
            c-0.6-0.1-1.3-0.2-1.9-0.3c-2.5-0.4-5-0.9-7.5-1.3l-1.5-0.3c-2.1-0.4-4.2-0.9-6.2-1.3c-0.6-0.1-1.1-0.2-1.7-0.4
            c-2.3-0.5-4.6-1.1-6.9-1.7c-0.5-0.1-1-0.3-1.5-0.4c-1.9-0.5-3.8-1-5.6-1.6l-1.4-0.4c-2.1-0.6-4.1-1.3-6.1-2l-1.4-0.5
            c-1.7-0.6-3.3-1.3-4.9-1.9l-1.1-0.4c-1.8-0.8-3.5-1.6-5.1-2.4l-1.2-0.6c-1.4-0.7-2.8-1.5-4.1-2.3c-0.2-0.1-0.5-0.3-0.7-0.4
            c-1.4-0.9-2.8-1.8-4-2.7c-0.1-0.1-0.3-0.2-0.4-0.3v59.5c0,8.7,18.9,19.5,52.3,26.4L183.9,340.3z"/>
        <path class="st0" d="M349.9,238.3c-1.3,0.9-2.6,1.8-4,2.7c-0.2,0.1-0.5,0.3-0.7,0.4c-1.3,0.8-2.7,1.5-4.1,2.3l-1.2,0.6
            c-1.6,0.8-3.3,1.6-5.1,2.4l-1.1,0.4c-1.6,0.7-3.2,1.3-4.9,1.9l-1.4,0.5c-2,0.7-4,1.4-6.1,2l-1.4,0.4c-1.8,0.6-3.7,1.1-5.6,1.6
            c-0.5,0.1-1,0.3-1.5,0.4c-2.2,0.6-4.5,1.1-6.9,1.7c-0.6,0.1-1.1,0.2-1.7,0.4c-2,0.5-4.1,0.9-6.2,1.3l-1.5,0.3
            c-2.5,0.5-5,0.9-7.5,1.3c-0.6,0.1-1.2,0.2-1.9,0.3c-2.2,0.4-4.5,0.7-6.8,1l-1.4,0.2c-2.6,0.4-5.3,0.7-8,1l-2,0.2
            c-2.5,0.3-4.9,0.5-7.4,0.7l-1,0.1c-2.8,0.2-5.6,0.4-8.3,0.6l-2.1,0.2c-2.6,0.2-5.3,0.3-8,0.4l-0.7,0c-2.8,0.1-5.7,0.2-8.5,0.3
            l-2.1,0c-2.9,0.1-5.7,0.1-8.6,0.1c-2.9,0-5.7,0-8.6-0.1l-2.1,0c-2.8-0.1-5.7-0.2-8.5-0.3l-0.7,0c-2.7-0.1-5.3-0.3-8-0.4l-2.1-0.1
            c-2.8-0.2-5.6-0.4-8.3-0.6l-1-0.1c-2.5-0.2-5-0.5-7.4-0.7l-2-0.2c-2.7-0.3-5.3-0.6-8-1l-1.4-0.2c-2.3-0.3-4.6-0.7-6.8-1
            c-0.6-0.1-1.3-0.2-1.9-0.3c-2.5-0.4-5-0.9-7.5-1.3l-1.5-0.3c-2.1-0.4-4.2-0.9-6.2-1.3c-0.6-0.1-1.1-0.2-1.7-0.4
            c-2.3-0.5-4.6-1.1-6.9-1.7c-0.5-0.1-1-0.3-1.5-0.4c-1.9-0.5-3.8-1-5.6-1.6l-1.4-0.4c-2.1-0.7-4.1-1.3-6.1-2L116,249
            c-1.7-0.6-3.3-1.3-4.9-1.9l-1.1-0.4c-1.8-0.8-3.5-1.6-5.1-2.4l-1.2-0.6c-1.4-0.7-2.8-1.5-4.1-2.3c-0.2-0.1-0.5-0.3-0.7-0.4
            c-1.4-0.9-2.8-1.8-4-2.7c-0.1-0.1-0.3-0.2-0.4-0.3v59.5c0,11.9,35.7,27.9,95.7,32.2l25.2-41.8c1.8-3,5.1-4.9,8.7-4.9
            s6.8,1.9,8.7,4.9l25.1,41.6c58-4.7,92.4-20.3,92.4-32l0-59.5C350.2,238.1,350.1,238.2,349.9,238.3z"/>
        <path class="st0" d="M345.9,163.2c-0.2,0.1-0.5,0.3-0.7,0.4c-1.3,0.8-2.7,1.5-4.1,2.2l-1.2,0.6c-1.6,0.8-3.3,1.6-5.1,2.4l-1.1,0.4
            c-1.6,0.7-3.2,1.3-4.9,1.9l-1.4,0.5c-2,0.7-4,1.4-6.1,2l-1.4,0.4c-1.8,0.6-3.7,1.1-5.6,1.6c-0.5,0.1-1,0.3-1.5,0.4
            c-2.2,0.6-4.5,1.1-6.9,1.7c-0.6,0.1-1.1,0.2-1.7,0.4c-2,0.5-4.1,0.9-6.2,1.3l-1.5,0.3c-2.5,0.5-5,0.9-7.5,1.3
            c-0.6,0.1-1.2,0.2-1.9,0.3c-2.2,0.4-4.5,0.7-6.8,1l-1.4,0.2c-2.6,0.4-5.3,0.7-8,1l-2,0.2c-2.5,0.3-4.9,0.5-7.4,0.7l-1,0.1
            c-2.8,0.2-5.6,0.5-8.3,0.6l-2.1,0.2c-2.6,0.2-5.3,0.3-8,0.4l-0.7,0c-2.8,0.1-5.7,0.2-8.5,0.3l-2.1,0c-2.9,0.1-5.7,0.1-8.6,0.1
            c-2.9,0-5.7,0-8.6-0.1l-2.1,0c-2.8-0.1-5.7-0.2-8.5-0.3l-0.7,0c-2.7-0.1-5.3-0.3-8-0.4l-2.1-0.1c-2.8-0.2-5.6-0.4-8.3-0.6l-1-0.1
            c-2.5-0.2-5-0.5-7.4-0.7l-2-0.2c-2.7-0.3-5.3-0.6-8-1l-1.4-0.2c-2.3-0.3-4.6-0.7-6.8-1c-0.6-0.1-1.3-0.2-1.9-0.3
            c-2.5-0.4-5-0.9-7.5-1.3l-1.5-0.3c-2.1-0.4-4.2-0.9-6.2-1.3c-0.6-0.1-1.1-0.2-1.7-0.4c-2.3-0.5-4.6-1.1-6.9-1.7
            c-0.5-0.1-1-0.3-1.5-0.4c-1.9-0.5-3.8-1-5.6-1.6l-1.4-0.4c-2.1-0.7-4.1-1.3-6.1-2l-1.4-0.5c-1.7-0.6-3.3-1.3-4.9-1.9l-1.1-0.4
            c-1.8-0.8-3.5-1.6-5.1-2.4l-1.2-0.6c-1.4-0.7-2.8-1.5-4.1-2.2c-0.2-0.1-0.5-0.3-0.7-0.4c-1.4-0.9-2.8-1.8-4-2.7
            c-0.1-0.1-0.3-0.2-0.4-0.3v59.5c0,13.9,48.7,33.4,127.9,33.4s127.9-19.5,127.9-33.4l0-59.5c-0.1,0.1-0.3,0.2-0.4,0.3
            C348.7,161.4,347.3,162.3,345.9,163.2z"/>
        <ellipse class="st0" cx="222.4" cy="141.8" rx="127.9" ry="33.4"/>
        <g>
            <path class="st1" d="M51.8,60.2c-0.4-0.3-1-0.3-1.3,0.1l-1.4,1.6c-0.3,0.4-0.3,1,0.1,1.3c0.4,0.3,1,0.3,1.3-0.1l1.4-1.6
                C52.3,61.1,52.2,60.6,51.8,60.2z"/>
            <path class="st1" d="M51.4,57l-1.6-1.4c-0.4-0.3-1-0.3-1.3,0.1c-0.3,0.4-0.3,1,0.1,1.3l1.6,1.4c0.4,0.3,1,0.3,1.3-0.1
                C51.9,58,51.8,57.4,51.4,57z"/>
            <path class="st1" d="M56.2,55.3c-0.4-0.3-1-0.3-1.3,0.1L53.5,57c-0.3,0.4-0.3,1,0.1,1.3c0.4,0.3,1,0.3,1.3-0.1l1.4-1.6
                C56.6,56.2,56.5,55.6,56.2,55.3z"/>
            <path class="st1" d="M56.8,61.5l-1.6-1.4c-0.4-0.3-1-0.3-1.3,0.1c-0.3,0.4-0.3,1,0.1,1.3l1.6,1.4c0.4,0.3,1,0.3,1.3-0.1
                C57.2,62.4,57.1,61.8,56.8,61.5z"/>
        </g>
        <path class="st2" d="M150.6,74.3c0,0-1.1,11.4-13.9,8.1c0,0,11.1,2.8,8.3,13.1c0,0,2.9-7.9,13.6-7.3C158.5,88.1,149.5,88,150.6,74.3
            z"/>
        <path class="st3" d="M76.4,120.9c0-5.6,4.6-10.2,10.2-10.2c5.6,0,10.2,4.6,10.2,10.2c0,5.6-4.6,10.2-10.2,10.2
            C80.9,131.1,76.4,126.5,76.4,120.9z M81.4,120.9c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1
            C83.7,115.8,81.4,118.1,81.4,120.9z"/>
        <circle class="st4" cx="93.6" cy="66.6" r="3.1"/>
        <circle class="st1" cx="60.1" cy="156.6" r="1.7"/>
        <polygon class="st5" points="28.2,122.6 21.3,122.6 21.3,115.8 19.4,115.8 19.4,122.6 12.5,122.6 12.5,124.6 19.4,124.6 19.4,131.5 
            21.3,131.5 21.3,124.6 28.2,124.6 "/>
        <polygon class="st5" points="136.7,28 129.8,28 129.8,21.2 127.9,21.2 127.9,28 121,28 121,30 127.9,30 127.9,36.9 129.8,36.9 
            129.8,30 136.7,30 "/>
        <circle class="st1" cx="65.3" cy="86.5" r="1.7"/>
        <path class="st4" d="M198.8,47.8c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10S204.3,47.8,198.8,47.8z M198.8,66.4
            c-4.7,0-8.5-3.8-8.5-8.5c0-4.7,3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5C207.3,62.6,203.5,66.4,198.8,66.4z"/>
        <circle class="st3" cx="193.4" cy="93.2" r="3.1"/>
        <circle class="st1" cx="155.6" cy="47.8" r="1.7"/>
        <circle class="st4" cx="67.1" cy="198.3" r="3.1"/>
        <g>
            <path class="st1" d="M451.9,322c0.5-0.1,0.8-0.6,0.7-1.1l-0.4-2.1c-0.1-0.5-0.6-0.8-1.1-0.7c-0.5,0.1-0.8,0.6-0.7,1.1l0.4,2.1
                C450.9,321.8,451.4,322.1,451.9,322z"/>
            <path class="st1" d="M454.7,323.7l2.1-0.4c0.5-0.1,0.8-0.6,0.7-1.1c-0.1-0.5-0.6-0.8-1.1-0.7l-2.1,0.4c-0.5,0.1-0.8,0.6-0.7,1.1
                C453.7,323.5,454.2,323.8,454.7,323.7z"/>
            <path class="st1" d="M453.1,328.5c0.5-0.1,0.8-0.6,0.7-1.1l-0.4-2.1c-0.1-0.5-0.6-0.8-1.1-0.7c-0.5,0.1-0.8,0.6-0.7,1.1l0.4,2.1
                C452.1,328.2,452.6,328.6,453.1,328.5z"/>
            <path class="st1" d="M447.9,325.1l2.1-0.4c0.5-0.1,0.8-0.6,0.7-1.1c-0.1-0.5-0.6-0.8-1.1-0.7l-2.1,0.4c-0.5,0.1-0.8,0.6-0.7,1.1
                C446.9,324.9,447.4,325.2,447.9,325.1z"/>
        </g>
        <path class="st2" d="M379.7,390.9c0,0-8.3-7.9,2.3-15.9c0,0-9,7-15.4-1.6c0,0,4.4,7.2-2.7,15.2C363.9,388.5,369.6,381.4,379.7,390.9
            z"/>
        <path class="st3" d="M389.1,303.7c4.4,3.5,5.2,9.9,1.7,14.3c-3.5,4.4-9.9,5.2-14.3,1.7c-4.4-3.5-5.2-9.9-1.7-14.3
            C378.3,300.9,384.7,300.2,389.1,303.7z M386,307.6c-2.2-1.8-5.5-1.4-7.2,0.8c-1.8,2.2-1.4,5.5,0.8,7.2c2.2,1.8,5.5,1.4,7.2-0.8
            C388.6,312.6,388.2,309.4,386,307.6z"/>
        <circle class="st4" cx="421" cy="350.9" r="3.1"/>
        <circle class="st1" cx="371.1" cy="268.8" r="1.7"/>
        <polygon class="st5" points="417.6,264.8 421.9,259.4 427.3,263.7 428.5,262.1 423.1,257.9 427.3,252.5 425.8,251.3 421.6,256.7 
            416.2,252.4 414.9,253.9 420.3,258.2 416.1,263.6 "/>
        <polygon class="st5" points="424.6,408.6 428.8,403.2 434.2,407.4 435.4,405.9 430,401.6 434.3,396.3 432.8,395 428.5,400.4 
            423.1,396.2 421.9,397.7 427.3,402 423,407.4 "/>
        <circle class="st1" cx="422.9" cy="316.3" r="1.7"/>
        <path class="st4" d="M370.5,445c3.4-4.3,2.7-10.7-1.7-14.1s-10.7-2.7-14.1,1.7c-3.4,4.3-2.7,10.7,1.7,14.1S367.1,449.4,370.5,445z
                M356,433.5c2.9-3.7,8.3-4.3,11.9-1.4c3.7,2.9,4.3,8.3,1.4,11.9c-2.9,3.7-8.3,4.3-11.9,1.4C353.7,442.6,353.1,437.2,356,433.5z"/>
        <circle class="st3" cx="338.3" cy="412.7" r="3.1"/>
        <circle class="st1" cx="397.3" cy="411.1" r="1.7"/>
        <g>
            <path class="st6" d="M288.2,396.6L231,301.8c-1.4-2.4-4-3.9-6.8-3.9c-2.8,0-5.4,1.5-6.8,3.9l-57.2,94.8c-1.5,2.5-1.5,5.5-0.1,8.1
                c1.4,2.5,4.1,4.1,7,4.1h114.4c2.9,0,5.5-1.6,7-4.1C289.7,402.2,289.7,399.1,288.2,396.6z M224.2,330.7c3.3,0,6.1,1.9,6.1,5.1
                c0,10-1.2,24.4-1.2,34.4c0,2.6-2.9,3.7-4.9,3.7c-2.7,0-5-1.1-5-3.7c0-10-1.2-24.4-1.2-34.4C218.1,332.6,220.7,330.7,224.2,330.7z
                    M224.3,392.8c-3.7,0-6.5-3-6.5-6.5c0-3.5,2.8-6.5,6.5-6.5c3.5,0,6.4,2.9,6.4,6.5C230.7,389.8,227.7,392.8,224.3,392.8z"/>
        </g>
        </svg>
    </div>
    <div class="nd-align">
        <div class="headingnodata">{{ 'all.short_message' | translate }}</div>
        <div>{{ 'all.long_message' | translate }}<br>{{ 'all.right_now' | translate }}</div>
        <!-- <div *ngIf="link"><a [href]="link" target="_blank"> {{link}}</a></div> -->
        <div *ngIf = "btnTitle"><button mat-flat-button class="btn-primary mt-3" (click) = "onRefresh()">{{btnTitle}}</button></div>
        <!-- <div *ngIf = "action"><button mat-flat-button class="btn-primary mt-3" (click) = "actionRequired(action)">Action</button></div> -->
    </div>
</div>