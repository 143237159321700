<app-header (onTitleClick)="onTitleClick()" *ngIf="!IsStockSpecific && utility.IS_MOBILE" isOC = "true"  (ocEvent) = "openAnalysis()" (resetEvent) = "resetOptionChain()" goback="1" [title]="'derivative.option_chain' | translate" [isLoading]="isLoading" [freetrial] =  "true" (reloadPage) = "reloadData()"></app-header>
<div [class.maskingdiv]="isLoading"></div>
<mat-card [class.webpanelnew]="!IsStockSpecific" [class.mt-2] ="utility.IS_MOBILE && IsStockSpecific" >
    <mat-card-content>
        <div #sectionNeedToScroll *ngIf="!isFirstLoad">
            <div *ngIf = "!isNoData" class="row" [class.data-container]="utility.IS_MOBILE && !IsStockSpecific">
                <div class="col-12">
                    
                    <div class="panelsubheader pb-2">
                        <div class="row ml-1 mr-1">
                                <div [class.col-4] = "utility.IS_MOBILE" [class.col-2] = "!utility.IS_MOBILE" class="search-ochain"  *ngIf="!IsStockSpecific">
                                        <input class="" #focus [(ngModel)]="selectedSymbol"  type="search" (keypress)="validation($event)" [formControl]="myControl" [matAutocomplete]="auto">
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected) = "selectSymbol()">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                            {{option}}
                                        </mat-option>
                                        </mat-autocomplete>
                                        <a [class.searchclearicon-web] = "!utility.IS_MOBILE" [class.searchclearicon-mob] = "utility.IS_MOBILE" *ngIf="selectedSymbol" (click)="onClearSearch()">
                                            <i class="icon-close"></i>
                                        </a>
                                </div>
                                <div [class.col-4] = "utility.IS_MOBILE" [class.col-2]="!utility.IS_MOBILE">
                                    <mat-form-field [class.w-60]="!utility.IS_MOBILE">
                                        <mat-select [(ngModel)]="selectedExpiryDate" (ngModelChange)="selectExpiryDate($event)">
                                            <mat-option *ngFor="let date of expiryDates" [value]="date.value">
                                                {{date.key}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class ="col-2">
                                    <mat-form-field [class.w-60]="!utility.IS_MOBILE">
                                        <mat-select [(ngModel)]="limit" (ngModelChange)="selectLengthChange($event)">
                                            <mat-option [value]="5">5 <span translate>derivative.call</span>/<span translate>derivative.put</span></mat-option>
                                            <mat-option [value]="10">10 <span translate>derivative.call</span>/<span translate>derivative.put</span></mat-option>
                                            <mat-option [value]="15">15 <span translate>derivative.call</span>/<span translate>derivative.put</span></mat-option>
                                            <mat-option [value]="20">20 <span translate>derivative.call</span>/<span translate>derivative.put</span></mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class ="col-3" *ngIf="!utility.IS_MOBILE">
                                    <span translate>optioncalc.spotprice</span><br/>
                                    <span *ngIf="isIndex" [class.imnegative]="im.Change<0" [class.impositive]="im.Change>0">{{im.IndexValue | pricepipe:2}} <span class="ts-2">({{im.PerCng | percentagepipe}})</span></span>
                                    <span *ngIf="!isIndex" [class.imnegative]="sm?.Change<0" [class.impositive]="sm?.Change>0">{{sm?.LastTradePrice | pricepipe:2}} <span  class="ts-2">({{sm?.PercentageChange | percentagepipe}})</span></span>
                                </div>
                                
                                <div [class.p-0] = "utility.IS_MOBILE && !IsStockSpecific" [class.col-5] = "utility.IS_MOBILE && IsStockSpecific" [class.col-2] = "utility.IS_MOBILE && !IsStockSpecific" [class.col-3]="!utility.IS_MOBILE" [class.text-right]="!utility.IS_MOBILE">
                                    <button [disabled] = "invest_btn" class="btn-primary mr-1 pl-1 pr-1" mat-flat-button (click)="placeOrder()"><span translate>basket.invest</span></button>
                                    <button *ngIf="!utility.IS_MOBILE || (utility.IS_MOBILE && IsStockSpecific)" [disabled] = "invest_btn" mat-flat-button class="btn-primary pl-1 pr-1" (click)="openAnalysis()"><i class="icon-insert-chart"></i></button>
                                    <button *ngIf="!utility.IS_MOBILE || (utility.IS_MOBILE && IsStockSpecific)" [disabled] = "invest_btn" class="ml-1"   mat-icon-button  (click)="resetOptionChain()"><i class="icon-reset"></i></button>
                                </div>
                          </div>
                          <div *ngIf = "!isNoData && utility.IS_MOBILE" class="table-global pt-0" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                            <div class="watchlistwrapper">
                                <div class="row">
                                    <div class="col-5">
                                        <div class="firstline" translate>optioncalc.spotprice</div>
                                    </div>
                                    <div *ngIf="isIndex" [class.imnegative]="im.Change<0" [class.impositive]="im.Change>0" class="col-7 text-right">
                                        <div class="firstline">{{im.IndexValue | pricepipe:2}} <span class="ts-2">({{im.PerCng | percentagepipe}})</span></div>
                                    </div>
                                    <div *ngIf="!isIndex" [class.imnegative]="sm.Change<0" [class.impositive]="sm.Change>0" class="col-7 text-right">
                                        <div class="firstline">{{sm.LastTradePrice | pricepipe:2}} <span  class="ts-2">({{sm.PercentageChange | percentagepipe}})</span></div>
                                    </div>
                                    
                                </div>
                            </div>
                            <table class="mat-table cdk-table">
                                <tr class="mat-header-row cdk-header-row">
                                    <th colspan="2" class="text-center p-2 primetext" translate>derivative.call</th>
                                    <th class="text-center" translate> </th>
                                    <th colspan="2"class="text-center p-2 primetext" translate>derivative.put</th>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf = "!isNoData && utility.IS_MOBILE" class="table-global pt-88" [ngStyle]="Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                <ngx-pull-to-refresh
                spinnerColor="#ff0000"
                distanceForRefresh="80"
                [isEnable]="false"
                [targetElement]="targetElement"
                (refresh)="myRefreshEvent($event, 'refresh')"
                (loadMore)="alert('loadmore')"
                class = "loader">
                    <table mat-table [dataSource]="scripArray" matSort  cdkDropList cdkDropListOrientation="horizontal" class="twolinetable text-right">
                        <ng-container matColumnDef="callscrip.Quantity">
                            <th class="text-right" mat-header-cell *matHeaderCellDef cdkDrag><div>{{'portfolio.qty' | translate }}</div></th>
                            <td class="text-right" [class.nagbg] = "element.callqtycount < 0" [class.posbg] = "element.callqtycount > 0" mat-cell *matCellDef="let element" [class.impositive]="element.putscrip.Change > 0" >
                                <span>{{element.callqtycount}}</span>
                                <!-- <span   *ngIf = "scripArray[0].putscrip.BodLotQuantity != undefined && scripArray[0].putscrip.BodLotQuantity > 0" >{{isLot ? element.callqtycount : element.callqtycount * multiplierFunction(scripArray[0].callscrip.BodLotQuantity)}}</span> -->
                            </td>
                        </ng-container>

                        <ng-container  matColumnDef="callscrip.LastTradePrice">
                        <th class="border-l border-r" mat-header-cell *matHeaderCellDef  cdkDrag ><div translate>stock_detail.ltp<span>/</span>derivative.iv</div></th>
                        <td class="brl text-right" [class.nagbg] = "element.callqtycount < 0" [class.posbg] = "element.callqtycount > 0" mat-cell *matCellDef="let element" [class.impositive]="element.callscrip.Change > 0" [class.imnegative]="element.callscrip.Change < 0">{{element.callscrip.LastTradePrice | pricepipe:element.callscrip.DecimalPrecision}}<div class="ts-1">{{(element.iv == '-Infinity') ? ('--') : (element.iv | pricepipe:element.callscrip.DecimalPrecision)}}</div></td>
                        </ng-container>

                        <ng-container matColumnDef="strikeprice">
                            <th class="strikeprice fw-4" mat-header-cell *matHeaderCellDef cdkDrag translate> derivative.strike </th>
                            <td mat-cell *matCellDef="let element" class="strikeprice fw-4 text-center">{{element.strikeprice | pricepipe:1}} <div class="ts-1 tc-2" *ngIf = "element.strikeprice == utility.atmStrike">F: {{element.futurescrip === undefined ? (element.futureLTP | pricepipe:element.callscrip.DecimalPrecision) : (element.futurescrip.LastTradePrice | pricepipe:element.callscrip.DecimalPrecision)}}</div></td>
                        </ng-container>

                        <ng-container matColumnDef="putscrip.LastTradePrice">
                            <th class="brl" mat-header-cell *matHeaderCellDef cdkDrag><div translate>stock_detail.ltp<span>/</span>derivative.iv</div></th>
                            <td class="brl text-right" [class.nagbg] = "element.putqtycount < 0" [class.posbg] = "element.putqtycount > 0"  mat-cell *matCellDef="let element" [class.impositive]="element.putscrip.Change > 0" [class.imnegative]="element.putscrip.Change < 0">{{element.putscrip.LastTradePrice | pricepipe:element.putscrip.DecimalPrecision}}<div class="ts-1">{{(element.iv == '-Infinity') ? ('--') : (element.iv | pricepipe:element.putscrip.DecimalPrecision)}}</div></td>
                        </ng-container>

                        <ng-container matColumnDef="putscrip.Quantity">
                            <th class="" mat-header-cell *matHeaderCellDef cdkDrag><div>{{'portfolio.qty' | translate }}</div></th>
                            <td class="text-right" [class.nagbg] = "element.putqtycount < 0" [class.posbg] = "element.putqtycount > 0" mat-cell *matCellDef="let element" [class.impositive]="element.putscrip.Change > 0">
                                <span >{{element.putqtycount}}</span>
                                <!-- <span  *ngIf = "scripArray[0].putscrip.BodLotQuantity != undefined" >{{isLot ? element.putqtycount : element.putqtycount * multiplierFunction(scripArray[0].putscrip.BodLotQuantity)}}</span> -->
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" [class.strikeprice] = "scripArray[i].strikeprice == utility.atmStrike" class="showhide" (mouseover)="handleMouseOver(row)"  (mouseleave)="handleMouseLeave(row)" [attr.id]="'oc_' + i" (click)="result(row)"></tr>
                    </table>
                </ngx-pull-to-refresh>
            </div>
            <div *ngIf = "!isNoOptionData && !utility.IS_MOBILE" [ngStyle]="Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                <table *ngIf = "tagText === 'Price'" class="table-other">
                    <tr class="cdk-header-row">
                        <th colspan="6"><div class="text-center ts-4 primetext" translate>derivative.call</div></th>
                        <th> </th>
                        <th colspan="6"><div class="text-center ts-4 primetext" translate>derivative.put</div></th>
                    </tr>
                    <tr class="ts-1 tc-2">
                        <th class="text-left"><br><span *ngIf='lotQuantity'>1 {{'portfolio.lot' | translate }}={{scripArray[0].putscrip.BodLotQuantity | numberpipe}} {{'portfolio.qty' | translate }}</span></th>
                        <th>{{'stock_detail.ltp' | translate}}(%) <br>{{'derivative.iv' | translate}}</th>
                        <th>{{'stock_detail.bid' | translate }}/{{'portfolio.qty' | translate }} <br> {{'stock_detail.ask' | translate }}/{{'portfolio.qty' | translate }}</th>
                        <th>OI</th>
                        <th translate>optioncalc.delta<br> optioncalc.theta</th>
                        <th translate>optioncalc.vega <br> optioncalc.gamma</th> 
                        <th class="strikeprice" translate>derivative.strike</th>
                        <th translate>optioncalc.vega <br> optioncalc.gamma</th>
                        <th translate>optioncalc.delta <br> optioncalc.theta</th>
                        <th>OI</th>
                        <th>{{'stock_detail.bid' | translate }}/{{'portfolio.qty' | translate }} <br> {{'stock_detail.ask' | translate }}/{{'portfolio.qty' | translate }}</th>
                        <th>{{'stock_detail.ltp' | translate}}(%) <br>{{'derivative.iv' | translate}}</th>
                        <th><br><span *ngIf='lotQuantity'>1 {{'portfolio.lot' | translate }}={{scripArray[0].putscrip.BodLotQuantity | numberpipe}} {{'portfolio.qty' | translate }}</span></th>
                    </tr>
                    
                    <tr class="mat-row"  *ngFor = "let data of scripArray; index as i" [class.strikeprice] = "data.strikeprice == utility.atmStrike">
                        <td  [class.nagbg] = "data.callqtycount < 0" [class.posbg] = "data.callqtycount > 0" class="text-left">
                            <button (click)='onCount("S", data, "call")' class="btn-negative" matTooltip="{{'tooltip.remove' | translate}}"  mat-icon-button>-</button>
                            <span  class="op-qty">{{data.callqtycount}}</span>
                            <!-- <span *ngIf = "scripArray[0].putscrip.BodLotQuantity != undefined" class="op-qty">{{isLot ? data.callqtycount : data.callqtycount * multiplierFunction(scripArray[0].putscrip.BodLotQuantity)}}</span> -->
                            <button (click)='onCount("B", data, "call")' class="btn-positive mr-3" matTooltip="{{'limits.add' | translate}}" mat-icon-button>+</button>
                            <button mat-icon-button (click) = "addWLdata(data.callscrip)" matTooltip="{{'search.addto_watchlist' | translate}}"  class="btn-base" [matMenuTriggerFor]="menu"><i class="icon-add-wlist ts-2 subcolor"></i></button>
                            <mat-menu #menu="matMenu">
                                <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                <ng-container *ngFor = "let item of WlArr">
                                    <button mat-menu-item (click) = "addStock(item.wlName, data.callscrip)" [disabled] = "item.isDisable">
                                        <span>{{item.wlName}}</span>
                                    </button>
                                </ng-container>
                            </mat-menu>
                        </td>
                        <td [class.nagbg] = "data.callqtycount < 0" [class.posbg] = "data.callqtycount > 0">{{data.callscrip.LastTradePrice | pricepipe:data.callscrip.DecimalPrecision}}({{data.callscrip.PercentageChange | percentagepipe}})<br>{{(data.iv == '-Infinity') ? ('--') : (data.iv | pricepipe:2)}}</td>
                        <td [class.nagbg] = "data.callqtycount < 0" [class.posbg] = "data.callqtycount > 0">
                            {{data.callscrip.BestBuyPrice | pricepipe:data.callscrip.DecimalPrecision}}/{{data.callscrip.BestBuySize | numberpipe}}<br>
                            {{data.callscrip.BestSellPrice | pricepipe:data.callscrip.DecimalPrecision}}/{{data.callscrip.BestSellSize | numberpipe}}
                        </td>
                        <td [class.nagbg] = "data.callqtycount < 0" [class.posbg] = "data.callqtycount > 0">{{data.callscrip.Openinterest | numberpipe}}</td>
                        <td [class.nagbg] = "data.callqtycount < 0" [class.posbg] = "data.callqtycount > 0">{{data.calldelta}}<br>{{data.calltheta}}</td>
                        <td [class.nagbg] = "data.callqtycount < 0" [class.posbg] = "data.callqtycount > 0">{{data.callvega}}<br>{{data.callgamma}}</td>
                        <td class="strikeprice fw-4">{{data.strikeprice | pricepipe:1}} <div  class="tc-2 ts-1" *ngIf = "data.strikeprice == utility.atmStrike">F: {{data.futurescrip === undefined ? (data.futureLTP | pricepipe:data.callscrip.DecimalPrecision) : (data.futurescrip.LastTradePrice | pricepipe:data.callscrip.DecimalPrecision)}} </div></td>                        
                        <td [class.nagbg] = "data.putqtycount < 0" [class.posbg] = "data.putqtycount > 0">{{data.putvega}}<br>{{data.putgamma}}</td>
                        <td [class.nagbg] = "data.putqtycount < 0" [class.posbg] = "data.putqtycount > 0">{{data.putdelta}}<br>{{data.puttheta}}</td>
                        <td [class.nagbg] = "data.putqtycount < 0" [class.posbg] = "data.putqtycount > 0">{{data.putscrip.Openinterest | numberpipe}}</td>
                        <td [class.nagbg] = "data.putqtycount < 0" [class.posbg] = "data.putqtycount > 0">
                            {{data.putscrip.BestBuyPrice | pricepipe:data.putscrip.DecimalPrecision}}/{{data.putscrip.BestBuySize | numberpipe}}
                            <br>
                            {{data.putscrip.BestSellPrice | pricepipe:data.putscrip.DecimalPrecision}}/{{data.putscrip.BestSellSize | numberpipe}}
                        </td>
                        <td [class.nagbg] = "data.putqtycount < 0" [class.posbg] = "data.putqtycount > 0">{{data.putscrip.LastTradePrice | pricepipe:data.putscrip.DecimalPrecision}}({{data.putscrip.PercentageChange | percentagepipe}})<br>{{(data.iv == '-Infinity') ? ('--') : (data.iv | pricepipe:2)}}</td>
                        <td [class.nagbg] = "data.putqtycount < 0" [class.posbg] = "data.putqtycount > 0">
                            <button mat-icon-button (click) = "addWLdata(data.putscrip)" matTooltip="{{'search.addto_watchlist' | translate}}"  class="btn-base mr-3" [matMenuTriggerFor]="scripMenu"><i class="icon-add-wlist ts-2 subcolor"></i></button>
                            <mat-menu #scripMenu="matMenu">
                                <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                <ng-container *ngFor = "let item of WlArr">
                                    <button mat-menu-item (click) = "addStock(item.wlName, data.putscrip)" [disabled] = "item.isDisable">
                                        <span>{{item.wlName}}</span>
                                    </button>
                                </ng-container>
                            </mat-menu>
                            <button (click)='onCount("S", data, "put")' class="btn-negative"  matTooltip="{{'tooltip.remove' | translate}}" mat-icon-button >-</button>
                            <span  class="op-qty">{{data.putqtycount}}</span>
                            <!-- <span *ngIf = "scripArray[0].putscrip.BodLotQuantity != undefined" class="op-qty">{{isLot ? data.putqtycount : data.putqtycount * multiplierFunction(scripArray[0].putscrip.BodLotQuantity)}}</span> -->
                            <button (click)='onCount("B", data, "put")' class="btn-positive mr-1" matTooltip="{{'limits.add' | translate}}"  mat-icon-button>+</button>
                        </td>
                       
                    </tr>
                </table>
            </div>
            <ng-container *ngIf='!isLoading && (isNoData || isNoOptionData)' class='nodata'>
                <app-nodata message = "N0 DATA"></app-nodata>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>
