import { Utility } from '../services/utility';
import { ScripModel } from './scrip.model';
declare const Module: any;

export class OptionChainModel {
    optionType: number; // 1 for monthly 2 for weekly
    callscrip: ScripModel;
    atm: number;
    diff;
    putscrip: ScripModel;
    futurescrip: ScripModel;
    calloi: number;
    putoi: number;
    strikeprice: number;
    iv: number;
    calliv: number;
    putiv: number;
    callrho: number;
    putrho: number;
    callgamma: number;
    calltheta: number;
    callvega: number;
    calldelta: number;
    putgamma: number;
    puttheta: number;
    putvega: number;
    putdelta: number;
    days: number;
    tmpday;
    pdsd;
    spotLTP: number;
    spotCng;
    spotTsym;
    spotpchng;
    callqtycount = 0;
    putqtycount = 0;
    futqtycount = 0;
    rate = 0.08;
    calltransactionType;
    puttransactionType;
    futureLTP: number;
    callPrice: number;
    putPrice: number;
    updatemodel() {
        if (this.optionType == 1){
            this.futureLTP = this.futurescrip.LastTradePrice;
        }
        else{
            this.futureLTP = Utility.SyntheticFuturePrice;
        }
        this.callscrip.scripUpdate.subscribe( data => {
            if (this.callscrip.LastTradePrice !== 0 && this.putscrip.LastTradePrice !== 0 && this.futureLTP !== 0){
                this.calculatevalues();
            }
        });
        this.putscrip.scripUpdate.subscribe( data => {
            if (this.putscrip.LastTradePrice !== 0 && this.callscrip.LastTradePrice !== 0 && this.futureLTP !== 0){
                this.calculatevalues();
            }
        });
        if (this.futurescrip !== undefined) {
            this.futurescrip.scripUpdate.subscribe( data => {
                if (this.futureLTP !== 0 && this.putscrip.LastTradePrice !== 0 && this.callscrip.LastTradePrice !== 0){
                    this.calculatevalues();
                }
            });
        }
    }
    calculatevalues(){
        if (this.callscrip.LastTradePrice !== 0 && this.futureLTP !== 0 && this.putscrip.LastTradePrice !== 0) {
            let d = this.getDays();
            if(this.futurescrip)
                this.getATM();  

            if(this.strikeprice == Utility.atmStrike && this.optionType == 2){
                Utility.SyntheticFuturePrice = Number(this.strikeprice) + Number(this.callscrip.LastTradePrice) - Number(this.putscrip.LastTradePrice);
                this.futureLTP = Utility.SyntheticFuturePrice;
            }
            else {
                if(this.futurescrip)
                    this.futureLTP = this.futurescrip.LastTradePrice;
            }
            let greekLenght = 11;
            let nDataBytes = greekLenght * Float64Array.BYTES_PER_ELEMENT;
            let dataPtr = Utility.OCWebAssambly._malloc(nDataBytes);
            let dataHeap = new Float64Array(Utility.OCWebAssambly.HEAPF64.buffer, dataPtr, nDataBytes);
            Utility.OCWebAssambly._getGreeks(this.callscrip.LastTradePrice, this.putscrip.LastTradePrice, this.futureLTP, this.strikeprice, d, (this.strikeprice < Utility.atmStrike)?-1:1, dataHeap.byteOffset);
            this.iv = dataHeap[0];
            this.calliv = dataHeap[1];
            this.calldelta = dataHeap[2];
            this.calltheta = dataHeap[3];
            this.callvega = dataHeap[4];
            this.callgamma = dataHeap[5];
            this.putiv = dataHeap[6];
            this.putdelta = dataHeap[7];
            this.puttheta = dataHeap[8];
            this.putvega = dataHeap[9];
            this.putgamma = dataHeap[10];
            Utility.OCWebAssambly._free(dataHeap.byteOffset);
        }
    }
    getDays(){
        let now = new Date();
        let d = Date.parse(now+'');
        let td = ((d-this.pdsd)/(1000*60))/375;
        let day;
        if(td<=0){
            day = this.tmpday;
        } else if(td > 1){
            day = this.tmpday-1;
        } else {
            day = this.tmpday - td;
        }
        return day;
    }

    getATM(){
        let mod = this.futurescrip.LastTradePrice % Utility.ocDiff;
        let val = Math.floor(this.futurescrip.LastTradePrice / Utility.ocDiff);
        if(Utility.ocDiff/2 >= mod){
            Utility.atmStrike = val * Utility.ocDiff;
        } else {
            Utility.atmStrike = (val+1) * Utility.ocDiff;
        }
    }
}
