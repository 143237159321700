import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Url } from '../url';
import { IPredefinedWatchlistService } from './ipredefinedwatchlist.service';

@Injectable()
export class PredefinedWatchlistService implements IPredefinedWatchlistService {
    apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    getPredefinedWatchlistData(callback: Function) {
        let message = '';
        return this.apiService.ptpluspost(Url.PREDEFINED_WATCHLIST, {}).subscribe(result => {
            let arr;
            if (result.code == 200) {
                result.data.forEach(element => {
                    arr.push(element.mwName);
                 });
            }
            else {
                message = result.message;
            }
            callback(result.code, arr, message);
        }, error => {
            message = error;
            callback('', '', message);
        });
    }
}
