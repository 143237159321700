import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Inject, ViewChild, OnChanges, ApplicationRef } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { TradingRestService } from '../../../services/tradingrest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelType, Utility } from '../../../services/utility';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { PwaService } from 'src/app/services/pwa.service';
import { interval, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LinkComponent } from '../link/link.component';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { IndexSpotComponent } from '../indexspot/indexspot.component';
import { Preferences } from 'src/app/services/Preferences';
let header: HeaderComponent;

@Component({
  selector: 'app-header',
  templateUrl: '../../'+environment.baseviewpath+'/header/header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy, OnChanges{
  @Output() hideShow = new EventEmitter<number>();
  @Output() onTitleClick = new EventEmitter();
  @Input() isSearch: boolean;
  @Input() goback: number;
  @Input() title: string;
  @Input() isLoading: boolean;
  @Input() fromLogin = false;
  @Input() isEventFilter = false;
  @Output() goToEvent = new EventEmitter();
  @Input() isDisplayMenu = false;
  @Input() balanceValue = '';
  @Input() freetrial = false;
  @Input() isOC = false;
  @Input() isFunds = false;
  @Input() isWithdrawFunds: boolean;
  @Output() ocEvent = new EventEmitter();
  @Output() resetEvent = new EventEmitter();
  @Output() reloadPage = new EventEmitter();
  @Output() sortIconClicked = new EventEmitter<string>();
  @Output() closePlaceOrderSettings = new EventEmitter();
  @ViewChild('menuTrigger') matMenuTriggerClose;

  oldscroll = 0;
  hideshow = 0;
  lastScrollTime = 0;
  is_moveup = false;
  page = 1;
  utility: any;
  previousUrl;
  isfromspecificnews;
  isfromMWL;
  isUpdateAvailable: boolean;
  ticketSupport = environment.ticketSupport;
  isIAM = environment.isIAM;
  isBetaFeature: boolean;
  @Input() isReloadVisible = true;
  @Input() isSortVisible = false;
  @Input() sortItemList = ['Symbol','LTP', 'Change', '%Change', 'Abs %Change'];
  @Input() selectedSortedItem = '';
  matChipItems = ['None', 'Asc', 'Desc'];
  @Input() chipSelected;
  filteObject : any;
  sortingDictionary = new Map<any, any>();
  version;
  isMobileLand;
  orientationSubscription: Subscription;
  contactList : number[];
  hideBanner : boolean;
  backOfficeType = environment.backOfficeType;
  nameArr = [];
  indexList = [];

  constructor(@Inject(DOCUMENT) private document: Document, private location: Location, private tradingRestService: TradingRestService, private router: Router, private dialog: MatDialog,
    private activatedRoute: ActivatedRoute, private translate: TranslateService, private update: SwUpdate, public Pwa: PwaService, private appRef: ApplicationRef, private snackBar: MatSnackBar) {
    header = this;
    header.isBetaFeature = environment.BetaFeature;
    header.version = environment.version;
    header.isUpdateAvailable = false;
    header.update.available.subscribe((event) => {
      header.isUpdateAvailable = header.update.isEnabled;
    });
    header.update.activated.subscribe((event) => {
      header.isUpdateAvailable = false;
    });
    header.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(8 * 60 * 60 * 1000);

        timeInterval.subscribe(() => {
          header.update.checkForUpdate().then(() => console.log('checked'));
        });
      }
    });
    header.contactList = environment.contactList;
    this.isMobileLand = Utility.IS_MOBILE_LAND;
    this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
      this.isMobileLand = Utility.IS_MOBILE_LAND;
    });
    Utility.indexCall.subscribe(() => {
      this.indexcall();
    });
  }

  ngOnInit() {
    header.utility = Utility;
  //  window.addEventListener('scroll', header.scroll, true);
    header.previousUrl = this.router.url ? this.router.url.split('?')[0] : '/home';
    if(this.goback != 1 && environment.baseviewpath !== 'investor') {
      this.indexcall();
    }
  }
  ngOnChanges() {
    if (header.chipSelected === '') {
      header.chipSelected = header.matChipItems[0];
    }
  }

  notificationClicked(){
    Utility.showBannerMessage.next(!Utility.isBannerHidden);
  }

  titleClicked() {
    this.onTitleClick.emit();
  }

  checkUpdate() {
    this.update.activateUpdate().then(() => location.reload());
  }
  
  installApp() {
    Utility.installApp.next();
  }

  gotoOptionChain() {
    this.ocEvent.emit();
  }

  resetOptionChain(){
    this.resetEvent.emit();
  }

  gotoEvent() {
    header.goToEvent.emit();
  }

  ngOnDestroy() {
    this.orientationSubscription.unsubscribe();
    Utility.pauseChannel(this.tradingRestService, ChannelType.Index);
  }

  reloadData()
  {
    header.reloadPage.emit();
  }

  sortingItemSelected(sortingItem)
  {
    if(header.sortingDictionary === undefined || !header.sortingDictionary.has(sortingItem))
    {
      header.sortingDictionary.set(sortingItem, header.matChipItems[0]);
    }
    
    if(header.selectedSortedItem !== sortingItem && header.sortingDictionary.has(header.selectedSortedItem))
    {
      header.sortingDictionary.set(header.selectedSortedItem, header.matChipItems[0]);
      header.selectedSortedItem = '';
    }
  
    if(header.sortingDictionary.get(sortingItem) as string === header.matChipItems[0])
    {
      header.chipSelected = header.matChipItems[1]; 
    }
    else if(header.sortingDictionary.get(sortingItem) as string === header.matChipItems[1])
    {
      header.chipSelected = header.matChipItems[2]; 
    }
    else
    {
      header.chipSelected = header.matChipItems[0]; 
    }
    header.selectedSortedItem = sortingItem;
    header.sortingDictionary.set(sortingItem, header.chipSelected);
    header.filteObject = {[header.chipSelected] : header.selectedSortedItem};
    header.sortIconClicked.emit(header.filteObject);
  }

  matMenuClosed()
  {
  }

  logout() {
      Utility.logoutPopup(header.router, header.translate, header.tradingRestService, header.dialog, '', header.snackBar);
  }
  
  gotoSearch() {
    if (header.previousUrl === '/news')
      header.router.navigate(['/search'], { queryParams: { isfromnews: true } });
    else
      header.router.navigate(['/search']);
  }
  gotoAlert() {
    header.router.navigate(['/alert']);
  }
  /*scroll = (): void => {
    const currentScrollY = window.pageYOffset;
    if (currentScrollY > 65) {
      header.is_moveup = true;
    } else {
      header.is_moveup = false;
    }
    if (currentScrollY <= 0) {
      header.hideshow = 1;
      header.hideShow.emit(header.hideshow);
    } else if (currentScrollY < header.oldscroll) {
      header.hideshow = 1;
      header.hideShow.emit(header.hideshow);
    } else if (currentScrollY > header.oldscroll) {
      header.hideshow = 2;
      header.hideShow.emit(header.hideshow);
    }
    header.oldscroll = currentScrollY;
    header.lastScrollTime = new Date().getTime();
  }*/
  back() {
    header.activatedRoute.queryParams.subscribe(params => {
      header.isfromspecificnews = params['isfromspecificnews'];
      header.isfromMWL = params['isfromMWL'];
    });
    Utility.iscombineQProfileClose = false;
    if (this.isWithdrawFunds){
      Utility.modifyWithdrawalData = [];
    }
    if (header.previousUrl === '/search') {
        if (header.isfromMWL) {
          header.location.back();
          header.location.back();
        }
        else
          header.location.back();
    }
    else if (header.previousUrl === '/stockdetail')
    header.router.navigate(['/home'], { replaceUrl: true});
    else if (header.previousUrl === '/visualtrading' || header.isfromspecificnews) {
      if (header.previousUrl === '/news') {
        header.router.navigate(['/home']);
      } else {
        header.location.back();
        header.location.back();
      }
    }
    else if (header.previousUrl === '/positionconvertion') {
      header.location.back();
      Utility.selectedBookTab = 1;
    }
    else if (header.previousUrl === '/basketordersummary'){
      header.location.back();
      header.location.back();
    }
    else if (header.previousUrl === '/basketplaceordersummary'){
      header.location.back();
      header.location.back();
      header.location.back();
    }
    else if (header.previousUrl === '/optionchain'){
      Utility.isOptionChainReloadData = true;
      header.location.back();
    } else if(header.previousUrl === '/optionchainplaceorder') {
      Utility.isOptionChainReloadData = false;
      header.location.back();
    }
    else if(header.previousUrl === '/optionchainanalysis') {
      Utility.isOptionChainReloadData = false;
      header.location.back();
    }
    else if (header.previousUrl === '/addfunds' && environment.baseviewpath!='investor'){
      if(Utility.checkfunds === true)
      {
        Utility.checkfunds = false;
        header.location.back();
        header.location.back();

      } 
      else
      {
        header.location.back();
      }
    }
    else if (header.previousUrl === '/limit' && environment.baseviewpath != 'investor'){
      header.location.back();
    }
    else if(header.previousUrl === '/generalsettings') {
      if(Utility.isFromSettingPO) {
        this.closePlaceOrderSettings.emit();        
      } else {
        header.location.back();
      }
    }
    else {
      header.utility.fromTodaySip = false;
      header.location.back();
    }
  }

  profile() {
    header.router.navigate(['/profile']);
  }

  changePwd() {
    header.router.navigate(['/changepwd']);
  }

  changeMpin() {
    header.router.navigate(['/changempin']);
  }

  userLogs() {
    header.router.navigate(['/logs']);
  }

  openPrivacy(){
    header.router.navigate(['/privacy']);
  }

  openTerms(){
    header.router.navigate(['/terms']);
  }

  openAbout(){
    header.router.navigate(['/aboutus']);
  }

  openContact(){
    header.router.navigate(['/contact']);
  }

  lotWeight() {
    header.router.navigate(['/lotweight']);
  }

  changeQuestion() {
    header.router.navigate(['/changequestion']);
  }

  openGenSetting() {
    header.router.navigate(['/generalsettings'], {queryParams: {'tabIndex': 0}});
  }

  openTicket()
  {
    window.open('https://thriveadvisors.freshdesk.com/support/tickets/new','_blank');
  }

  openTransaction(){
    this.router.navigate(['/transactiondetails'], { queryParams: { bal: 0}});
  }

  openBackOffice(){
    if(environment.backOfficeSecType == 0){
      this.tradingRestService.getUserService().openBackOffice(this.backOfficeCallback.bind(this));
    }
    else {
      this.dialog.open(LinkComponent, {
        data: {
          url: environment.backOfficeUrl,
        },
      });
    }
  }

  backOfficeCallback(code,msg,data){
    let url = environment.backOfficeUrl+'shrdbms/userlogin.ss?login=silent&token='+data;
    this.dialog.open(LinkComponent, {
      data: {
        url: url,
      },
    });
  }

  indexcall(){
    this.nameArr = [];
    this.indexList = [];
    let spotValue;
    let userid = Preferences.getPreference('userid');
    Utility.resumeChannel(this.tradingRestService, ChannelType.Index);
    const allowedExchanges = Utility.getTradingExchanges();
    if(allowedExchanges.includes('BSE') && allowedExchanges.includes('NSE')){
      this.nameArr.push({'s':'Nifty 50','e':'NSE'});
      this.nameArr.push({'s':'SENSEX','e':'BSE'});
    } else if (allowedExchanges.includes('BSE') && !allowedExchanges.includes('NSE')){
      this.nameArr.push({'s':'SENSEX','e':'BSE'});
      this.nameArr.push({'s':'BSE IT','e':'BSE'});
    } else if (!allowedExchanges.includes('BSE') && allowedExchanges.includes('NSE')){
      this.nameArr.push({'s':'Nifty 50','e':'NSE'});
      this.nameArr.push({'s':'Nifty Bank','e':'NSE'});      
    }
    if(Preferences.getPreference(userid+'_spot1') !== null) {
      spotValue=Preferences.getPreference(userid+'_spot1').split('|');
      this.nameArr[0] = {'s':spotValue[0],'e':spotValue[1]};
    }
    if(Preferences.getPreference(userid+'_spot2') !== null) {
      spotValue=Preferences.getPreference(userid+'_spot2').split('|');
      this.nameArr[1] = {'s':spotValue[0],'e':spotValue[1]};
    }
    for (let i = 0; i < this.nameArr.length; i++) {
      const indexdata = Utility.getIndexModel(this.nameArr[i]['s'], this.nameArr[i]['e'], this.tradingRestService, true, ChannelType.Index);
      this.indexList.push(indexdata);
    }
  }

  openIndexSpot(i) {
    const obj = {'index' : i};
    let userid = Preferences.getPreference('userid');
    Preferences.setPreference(userid+'_spot'+(i+1), this.indexList[i].Name +'|'+ this.indexList[i].Exchange);
    Utility.openPage(this.router, 'customdashboard', 'indexspot', IndexSpotComponent, this.dialog, 'popup', obj, '400px', '400px');
  }
}
