import { PositionModelChanged } from '../interface/PositionModelChanged';
import { ScripModel } from './scrip.model';
export class PositionModel {
    scripModel: ScripModel;
    Exchange: string;
    Tsym: string;
    Netqty: number;
    Netamt: number;
    Pcode: string;
    MtoM: number;
    DayMtom: number;
    BEP: string;
    LTP: string;
    Instname: string;
    Expdate: string;
    Opttype: string;
    IsVisible = true;
    Stikeprc: number;
    Buyavgprc: string;
    Sellavgprc: string;
    Bqty: string;
    Sqty: string;
    BLQty: number;
    Token: string;
    Symbol: string;
    Exchangeseg: string;
    FillBuyQty: number;
    FillSellQty: number;
    s_NetQtyPosConv: string;
    CFBuyAmt: number;
    CFSellAmt: number;
    posflag: string;
    sSqrflg: string;
    discQty: string;
    PriceNumerator: string;
    GeneralNumerator: string;
    companyname: string;
    realisedprofitloss: string;
    unrealisedprofitloss: string;
    Type: string;
    PriceDenomenator: string;
    Series: string;
    GeneralDenomenator: string;
    UniqueKey: string;
    CFSellQty: number;
    CFBuyQty: number;
    DecimalPrecision: number;
    SNetQtyPosConv: string;
    Stat: string;
    DayBuyAvgPrc: number;
    DaySellAvgPrc: number;
    BuyQTY: number;
    SellQTY: number;
    BuyAvgPrc: number;
    SellAvgPrc: number;
    BuyAvgPrcStr: string;
    SellAvgPrcStr: string;
    NetBuyAvgPrc: number;
    NetSellAvgPrc: number;
    UnRealisedPL: number;
    RealisedPL: number;
    GDGNPDPN: number;
    NetBuyQty: number;
    CFNetSellAvgPrc: string;
    CFNetBuyAvgPrc: string;
    FillSellAmtCF: string;
    CFBuyAvgPrice: number;
    CFSellAvgPrice: number;
    FillBuyAmtCF: string;
    NetSellQty: number;
    NetBuyAmt: number;
    FillBuyAmt: number;
    FillSellAmt: number;
    NetSellAmt: number;
    PCodeDisplay: string;
    CFQty: number;
    AvgPrc: number;
    DayQty: number;
    NetBQty: number;
    NetSQty: number;
    PNLPercentageChg: number;
    positionModelChanged: PositionModelChanged;
    constructor () {
    }
    setScripModel(x) {
        this.scripModel = x;
        this.scripModel.onLTPChange.subscribe(() => {
            this.positionModelChanged(this);
        });
    }
    onLtpChange(sm) {
        this.positionModelChanged(this);
    }
}
