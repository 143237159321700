<mat-card class="webpanelnew">
    <mat-card-content>
        <div [class.pt-0]="!isMobile">
            <div *ngIf='isNoData'>
                <app-nodata message="NO DATA"></app-nodata>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-chip-list >
                        <mat-chip *ngFor="let obj of dataArr; let i = index" [class.imactive] = "tagText ==  i" (click)="onTabChange(i)" translate>
                                    {{obj}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <ng-container *ngIf="isMobile" >
            <div *ngFor="let option of rolloverArr; let i = index">
                <div class="row" (click)="openScripModel(option)">
                    <div class="col-12">
                        <div class="watchlistwrapper">
                            <div class="row">
                                <div class="col-7">
                                    <div class="firstline">{{option.TradingSymbol}} <small>{{option.Exchange}}</small></div>
                                    <div class="line2 subcolor">{{option.CompanyName === '' ? '-' : option.CompanyName}}</div>
                                </div>
                                <div  [class.imnegative]="option.Change<0" [class.impositive]="option.Change>0" class="col-5 text-right">
                                    <div [class.imnegativebg]="option.TickDirection === -1" [class.impositivebg]="option.TickDirection === 1">
                                        <div class="firstline">{{option.LastTradePrice | pricepipe:option.DecimalPrecision}}</div>
                                        <div class="line2">{{option.Change | pricepipe:option.DecimalPrecision}} ({{option.PercentageChange | percentagepipe}})</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="!isMobile">
                <div *ngIf = "!noData" class="table-global" [ngStyle]="Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                    <table mat-table [dataSource]="rolloverArr" matSort (matSortChange)="sortWatchlist($event)" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                        <ng-container matColumnDef="TradingSymbol" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag  [cdkDragDisabled]="true"> <span translate> stock_detail.symbol </span> </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="button-row">
                                <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true">
                                    <button (click)='openPlaceOrder("B", element)' class="btn-positive" mat-icon-button><i class="icon-bb"></i></button> 
                                    <button (click)='openPlaceOrder("S", element)' class="mr-2 ml-2 btn-negative" mat-icon-button ><i class="icon-sb"></i></button>
                                    <button (click)='openStockDetail(element)' class="btn-primary" mat-icon-button><i class="icon-ib"></i></button> 
                                </div>
                            </span>
                            {{element.TradingSymbol}}
                             </td>
                        </ng-container>
                        <ng-container matColumnDef="CompanyName">
                        <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span *ngIf="!isDelete" translate>stock_detail.company_name</span> </th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            <small>{{element.CompanyName === '' ? '-' : element.CompanyName}} </small></td>
                        </ng-container>

                        <ng-container matColumnDef="LastTradePrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag  [cdkDragDisabled]="true"> <span translate>stock_detail.ltp</span> </th>
                            <td mat-cell *matCellDef="let element; let i = index" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0" >
                                <span [class.imnegativebg]="element.TickDirection === -1" [class.impositivebg]="element.TickDirection === 1"> {{element.LastTradePrice | pricepipe:element.DecimalPrecision}} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Change">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"cdkDrag> <span translate>stock_detail.change</span> </th>
                            <td mat-cell *matCellDef="let element" ><span [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">{{element.Change | pricepipe:element.DecimalPrecision}} </span></td>
                        </ng-container>
                        <ng-container matColumnDef="PercentageChange">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> % {{'stock_detail.change' | translate}} </th>
                            <td mat-cell *matCellDef="let element" ><span [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">{{element.PercentageChange | percentagepipe}}</span></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)"></tr>
                    </table>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>