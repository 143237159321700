<div style="display: none;" class="chart-color6"></div>
<div class="header headerlandscape" *ngIf="isMobile">
    <div class="row">
        <div class="col-12">
            <button mat-icon-button (click)="back()">
                <i class="icon-goback"></i>
            </button>
            <div class="float-right ml-4 landnofloat">
                <button mat-icon-button (click)="buy()" class="btn-positive mr-1 mland"><i class="icon-bb"></i></button>
                <button mat-icon-button (click)="sell()" class="btn-negative mr-1 mland"><i class="icon-sb"></i></button>
            </div>
        </div>
    </div>
</div>
<div [class.data-chart-container] ="isMobile" class="app-tv-chart-container" [ngStyle]="style" id="tv_chart_container_{{localdivid}}"></div>

