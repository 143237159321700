<app-header *ngIf="isMobile" goback="1" [title]="'header.keyboard_shortcuts' | translate" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'header.keyboard_shortcuts' | translate"></app-titlebar>
<mat-dialog-content class="narrowpadding">
    <div class="row" [class.data-container]="isMobile">
        <div class="col-12">
            <mat-card>
                <div class="row">
                    <div  class="col-12" *ngFor="let groupname of objectKeys(shortcutsMap)">
                        <div class="row keyrow">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12"><span class="heading4key">{{groupname | translate}}</span></div>
                                </div>
                                <div class="row">
                                    <div class="col-4" *ngFor="let compname of objectKeys(shortcutsMap[groupname])">
                                        <div class="keylist">
                                            <span class="">{{compname | translate}}</span>
                                            <span class="primetext fw-4 text-capitalize float-right">{{shortcutsMap[groupname][compname]}}</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
             </mat-card>
        </div>
    </div>
</mat-dialog-content>