<div class="col-12 mt-2" [ngStyle]="Height" *ngIf='!isNoData && !isLoading'>
  <div class="row">
  <div class="col-md-6 col-12 pr-md-2">
    <mat-card class="webpanelnew">
      <div class="row">
        <div class="col-8">
            <div class="heading-panel">
                <div class="row">
                <div class="col-10"><h3 translate><img alt="" src="assets/img/heading/basket.svg" />  stock_detail.peer_performance</h3></div>
                <div class="col-2 text-right">
                    <!--a href="#"><i class="icon-gonext"></i></a-->
                </div>
            </div>
        </div>
      </div>
      <div class="col-4 pt-md-4 p-2">
      <mat-form-field>
        <mat-select (selectionChange)="onperformanceSelection($event)" [(value)]="selectedperformance">
          <mat-option *ngFor="let performamcefilter of performamcefilters" [value]="performamcefilter">
            {{performamcefilter}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    </div>
    <div class="col-12 p-0 peer-height">
        <canvas  class = "chart-color1" *ngIf = "isyield && PerformanceData.length>0" baseChart  [datasets]="PerformanceData" [labels]="performanceLabels"
          [options]="chartOptions" [colors]="colors" [chartType]="chartType"  [plugins]="chartPlugins"></canvas>
        <canvas  class = "chart-color1" *ngIf = "isbeta && PerformanceData.length>0" baseChart [datasets]="PerformanceData" [labels]="performanceLabels"
            [options]="chartOptions" [colors]="colors" [chartType]="chartType" [plugins]="chartPlugins"></canvas>
    </div>
    </mat-card>

  </div>
  <div class="col-md-6 col-12 pl-md-2 mt-md-0 mt-3">
    <mat-card class="webpanelnew">
      <div class="row">
        <div class="col-9">
            <div class="heading-panel">
                <div class="row">
                <div class="col-10"><h3 translate><img alt="" src="assets/img/heading/basket.svg" />  stock_detail.key_ratio_comparison</h3></div>
                <div class="col-2 text-right">
                    <!--a href="#"><i class="icon-gonext"></i></a-->
                </div>
            </div>
        </div>
      </div>
      <div class="col-3 pt-md-4 p-2">
      <mat-form-field>
        <mat-select (selectionChange)="onkeyratioSelection($event)" [(value)]="keycompare">
          <mat-option *ngFor="let keycomparisonfilter of keycomparisonfilters" [value]="keycomparisonfilter">
            {{keycomparisonfilter}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
    <div class="col-12 p-0 peer-height">
      <canvas  class = "chart-color1" *ngIf = "ispe && keyRatioData.length>0" baseChart  [datasets]="keyRatioData" [labels]="keyRatioLabels"
          [options]="chartOptions" [colors]="colors" [chartType]="chartType" [plugins]="chartPlugins"></canvas>
      <canvas  class = "chart-color1" *ngIf = "ispb && keyRatioData.length>0" baseChart  [datasets]="keyRatioData" [labels]="keyRatioLabels"
        [options]="chartOptions" [colors]="colors" [chartType]="chartType" [plugins]="chartPlugins"></canvas>
      <canvas  class = "chart-color1" *ngIf = "isroe && keyRatioData.length>0" baseChart  [datasets]="keyRatioData" [labels]="keyRatioLabels"
        [options]="chartOptions" [colors]="colors" [chartType]="chartType" [plugins]="chartPlugins"></canvas>
      <canvas  class = "chart-color1" *ngIf = "iseps && keyRatioData.length>0" baseChart  [datasets]="keyRatioData" [labels]="keyRatioLabels"
        [options]="chartOptions" [colors]="colors" [chartType]="chartType" [plugins]="chartPlugins"></canvas>
    </div>
    </mat-card>

  </div>
  <div class="col-12 peer-cont" >
    <mat-card class="webpanelnew">
      <div class="row">
        <div class="col-12">
            <div class="heading-panel">
                <div class="row">
                <div class="col-10"><h3 translate><img alt="" src="assets/img/heading/basket.svg" />  stock_detail.stock_comparison</h3></div>
                <div class="col-2 text-right">
                    <!--a href="#"><i class="icon-gonext"></i></a-->
                </div>
            </div>
        </div>
      </div>
    </div>
        <div class="row mx-0 mt-md-3 mt-3 table-wrapper">
        <table mat-table [dataSource]="datasource">
          <ng-container matColumnDef="Stock" sticky>
            <th mat-header-cell *matHeaderCellDef class="thstock text-uppercase" translate>stock_detail.stock</th>
            <td mat-cell *matCellDef="let element" class="tdstock size14">{{element.Stock}} </td>
          </ng-container>
          <ng-container matColumnDef="PE">
            <th mat-header-cell *matHeaderCellDef class="pl-2 thstock text-right" translate>stock_detail.pe_ratio</th>
            <td mat-cell *matCellDef="let element" class="pl-2 tdstock size14 text-right">{{element.PE}}</td>
          </ng-container>
          <ng-container matColumnDef="PB">
            <th mat-header-cell *matHeaderCellDef class="thstock text-right" translate>stock_detail.pb_ratio</th>
            <td mat-cell *matCellDef="let element" class="tdstock size14 text-right">{{element.PB}} </td>
          </ng-container>
          <ng-container matColumnDef="Yield">
            <th mat-header-cell *matHeaderCellDef class="thstock text-right" translate>stock_detail.yield</th>
            <td mat-cell *matCellDef="let element" class="tdstock size14 text-right">{{element.Yield}} </td>
          </ng-container>
          <ng-container matColumnDef="Beta">
            <th mat-header-cell *matHeaderCellDef class="thstock text-right" translate>stock_detail.beta</th>
            <td mat-cell *matCellDef="let element" class="tdstock size14 text-right">{{element.Beta}} </td>
          </ng-container>
          <ng-container matColumnDef="ROE">
            <th mat-header-cell *matHeaderCellDef class="thstock text-right" translate>stock_detail.roe</th>
            <td mat-cell *matCellDef="let element" class="tdstock size14 text-right">{{element.ROE}} </td>
          </ng-container>
          <ng-container matColumnDef="EPS">
            <th mat-header-cell *matHeaderCellDef class="thstock text-right" translate>stock_detail.eps</th>
            <td mat-cell *matCellDef="let element" class="tdstock size14 text-right">{{element.EPS}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      </mat-card>
  </div>
  <!-- <div class="col-12 peer-tab-container pt-3">
    <mat-card>
    <div class="row">
    
      <div class="heading heading-ab p-2">
        <div class="col-10 px-2">
          <h3 class="fw-300 mb-1 my-1">Stock Comparison</h3>
        </div>
  
      </div>
      <table mat-table [dataSource]="datasource" class="w-100 pt-md-4 pt-5">
        <ng-container matColumnDef="Stock" sticky>
          <th mat-header-cell *matHeaderCellDef class="thstock">Stocks</th>
          <td mat-cell *matCellDef="let element" class="tdstock size14">{{element.Stock}} </td>
        </ng-container>
        <ng-container matColumnDef="PE">
          <th mat-header-cell *matHeaderCellDef class="pl-2 thstock">PE Ratio</th>
          <td mat-cell *matCellDef="let element" class="pl-2 tdstock size14">{{element.PE}}</td>
        </ng-container>
        <ng-container matColumnDef="PB">
          <th mat-header-cell *matHeaderCellDef class="thstock">PB Ratio</th>
          <td mat-cell *matCellDef="let element" class="tdstock size14">{{element.PB}} </td>
        </ng-container>
        <ng-container matColumnDef="Yield">
          <th mat-header-cell *matHeaderCellDef class="thstock">Yield</th>
          <td mat-cell *matCellDef="let element" class="tdstock size14">{{element.Yield}} </td>
        </ng-container>
        <ng-container matColumnDef="Beta">
          <th mat-header-cell *matHeaderCellDef class="thstock">Beta</th>
          <td mat-cell *matCellDef="let element" class="tdstock size14">{{element.Beta}} </td>
        </ng-container>
        <ng-container matColumnDef="ROE">
          <th mat-header-cell *matHeaderCellDef class="thstock">ROE</th>
          <td mat-cell *matCellDef="let element" class="tdstock size14">{{element.ROE}} </td>
        </ng-container>
        <ng-container matColumnDef="EPS">
          <th mat-header-cell *matHeaderCellDef class="thstock">EPS</th>
          <td mat-cell *matCellDef="let element" class="tdstock size14">{{element.EPS}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    </mat-card>
   
   </div> -->
  </div>
</div>
<div style="display: none;" class="chart-color1"></div>
<div *ngIf='isNoData'>
  <app-nodata message="No Data"></app-nodata>
</div>