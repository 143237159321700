<app-header *ngIf="isMobile" goback="1" [title]="'Spread Order Book' | translate" [isReloadVisible]="false"></app-header>
<!-- <app-titlebar *ngIf="!isMobile" [title]="'Spread Order Book' | translate"></app-titlebar> -->
<mat-dialog-content class="spreadorder">
<div *ngIf = '!isNoData && !isLoading'>
    <div [class.data-container]="isMobile" class="placeorderfield row" [class.maxwidthsmall] = "isMobile" [class.p-2] = "!isMobile">
        <div *ngIf="isMobile|| cellview" [ngStyle]="Height" class="col-12">
            <div [class.pl-2]="cellview" [class.pr-2]="cellview" [class.wlweb]="cellview" *ngFor="let data of spreadOrderList;let i = index">
                <div class="col-12">
                    <div class="list-view border-b"> 
                        <div class="row" (click)="onClickOrderDetail(data)">
                            <div class="col-6">
                                <div >
                                    <span [ngClass]="{'tc-1' : true, 'ts-4' : true, 'textclip' : true, 'fnotext' : data.exchange === 'NFO'}" matTooltip="{{data.SymbolName}}">{{data.SymbolName}} </span><small class="exchangename">{{data.exchange}}</small><br>
                                    <span *ngIf="data.exchOrderUpdateTime != '--'"> {{data.exchOrderUpdateTime | datepipe:'mediumtime'}}</span><span *ngIf="data.exchOrderUpdateTime == '--'"> {{data.exchOrderUpdateTime}}</span><br>
                                    <span [class.tag-red]="data.Action=='S'" [class.tag-green]="data.Action=='B'">{{data.ActionDisplay}}</span>&nbsp;<span class="subcolor2">{{data.ExpiryDate}}</span><br>
                                    <span [class.tag-red]="data.ActionLeg2=='S'" [class.tag-green]="data.ActionLeg2=='B'">{{data.ActionDisplayLeg2}}</span>&nbsp;<span class="subcolor2">{{data.ExpiryDateLeg2}}</span><br>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ts-2 text-right">
                                    <div class="ts-4">{{data.PriceType}}</div> <br>
                                    <div><span class="ts-2">{{data.FillPrice}}</span> </div>
                                    <div> <span class="ts-2">{{data.FillPriceLeg2}}</span> </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ts-2 text-right">
                                    <div class="ts-4">{{data.ProductCode}}</div> <span class="ts-2">{{data.Status}}</span>
                                    <div >
                                        <div> <span class="fw-4 tc-3">{{data.FilledShares | numberpipe}}<small class="ts-1 tc-2">/{{data.FillQty | numberpipe}}</small></span></div>
                                        <div > <span class="fw-4 tc-3">{{data.FilledSharesLeg2 | numberpipe}}<small class="ts-1 tc-2">/{{data.FillQtyLeg2 | numberpipe}}</small></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="wl-btn" *ngIf="cellview" >
                                <button mat-icon-button matTooltip="Edit" class="btn-primary ml-2" *ngIf="IsEdit(data)" (click)='handleButton("E", data)'><i class="icon-edit"></i></button>
                                <button mat-icon-button matTooltip="Cancel" class="btn-primary ml-2" *ngIf="IsCancel(data)" (click)="handleButton('C', data)"><i class="icon-cancel"></i></button>
                                <button mat-icon-button matTooltip="Detail" class="btn-primary ml-2" (click)="handleButton('D', data)"><i class="icon-summary"></i></button>    
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isMobile && !cellview">
        <div #p1 class="table-global" [ngStyle]="Height" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
            <table mat-table [dataSource]="spreadOrderList" cdkDropList cdkDropListOrientation="horizontal" >
                <ng-container matColumnDef="TSym" sticky>
                    <th mat-header-cell *matHeaderCellDef cdkDrag  [cdkDragDisabled]="true" translate> basket.instrument </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <span class="button-row">
                            <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true">
                                <button mat-icon-button matTooltip="Edit" class="btn-primary ml-2" *ngIf="IsEdit(element)" (click)='handleButton("E", element)'><i class="icon-edit"></i></button>
                                <button mat-icon-button matTooltip="Cancel" class="btn-primary ml-2" *ngIf="IsCancel(element)" (click)="handleButton('C', element)"><i class="icon-cancel"></i></button>
                                <button mat-icon-button matTooltip="Detail" class="btn-primary ml-2" (click)='handleButton("D", element)'><i class="icon-summary"></i></button> 
                            </div>
                        </span>
                        <div class="firstline">{{element.TSym}} <small>{{element.Exchange}}</small></div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="ProductCode">
                    <th class="text-left" mat-header-cell *matHeaderCellDef translate> placeorder.producttype </th>
                    <td class="text-left" mat-cell *matCellDef="let element"> {{element.ProductCode | valuepipe}} </td>
                </ng-container>
                <ng-container matColumnDef="AvgPrice">
                    <th class="text-left" mat-header-cell *matHeaderCellDef translate>books.avgprice </th>
                    <td class="text-left" mat-cell *matCellDef="let element" >{{element.AvgPrice | pricepipe : element.scripModel.DecimalPrecision}} </td>
                </ng-container>
                <ng-container matColumnDef="DisplayStatus">
                    <th class="text-left" mat-header-cell *matHeaderCellDef translate> settings.status </th>
                    <td class="text-left" mat-cell *matCellDef="let element" [class.in-progress-status] = "element.DisplayStatus=='In Progress'" [class.trigger-pend-status] = "element.DisplayStatus=='Trigger pending'" [class.amo-req-status] = "element.DisplayStatus=='after market order req received'"  [class.traded-status] = "element.DisplayStatus=='Traded'"   
                        [class.Cancelled-status] = "element.DisplayStatus=='Cancelled'" [class.rejected-status] = "element.DisplayStatus=='Rejected'" [class.cancelled-amo-status] = "element.DisplayStatus=='cancelled after market order'" [class.open-status] = "element.DisplayStatus=='Open'" [class.modified-status] = "element.DisplayStatus=='Modified'"> {{element.DisplayStatus}} </td>>
                </ng-container>
                <ng-container matColumnDef="ActionDisplay">
                    <th class="text-left" mat-header-cell *matHeaderCellDef translate> books.transaction_type </th>
                    <td class="text-left" mat-cell *matCellDef="let element"><span [class.imnegative]="element.Action=='S'" [class.impositive]="element.Action=='B'"> {{element.ActionDisplay}} </span></td>
                </ng-container>
                <ng-container matColumnDef="OrderType">
                    <th class="text-left" mat-header-cell *matHeaderCellDef translate> books.ordertype </th>
                    <td class="text-left" mat-cell *matCellDef="let element" > {{element.PriceType}} </td>
                </ng-container>
                <ng-container matColumnDef="OrderedTime">
                    <th class="text-left" mat-header-cell *matHeaderCellDef translate> spread.odr_updt_time </th>
                    <td class="text-left" mat-cell *matCellDef="let element">
                            <span>{{(element.OrderedTime === '--') ? '--' : (element.OrderedTime | datepipe:'mediumtime')}}</span> </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index"  tabindex="999" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)"></tr>
            </table>
        </div>
    </ng-container>
</div>
<!-- <div class="footerbtn" *ngIf="isMobile" >
    <button mat-flat-button  class="w-100 btn-primary" (click)='onPlaceSpreadOrder()'><span translate>PLACE SPREAD ORDER</span></button>
</div> -->
<div *ngIf = ' !isLoading && isNoData '>
    <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
</div>
</mat-dialog-content>