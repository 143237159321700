import { Component, Inject, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import { IScripService } from 'src/app/services/scrip/iscrip.service';
import { HoldingModel } from 'src/app/model/holding.model';
import { ScripModel } from 'src/app/model/scrip.model';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { Subscription } from 'rxjs';

let sellMore: SellmoreComponent ;

@Component({
    selector: 'app-sellmore',
    templateUrl: '../../../'+environment.baseviewpath+'/selllist/sellmore/sellmore.component.html'
})

export class SellmoreComponent {
    holdingDetailData: any;
    scrpModel: ScripModel;
    scripService: IScripService;
    subscription: Subscription;

    tempdata= {chng: -56.6,  cnam: "TATA CONSULTANCY SERV LT",  expry: "",  exseg: "nse_cm",  last: 3759.3,  mktcap: 117550304,  omexs: "11536_nse_cm",  omtkn: "11536",  opdiff: 0,  optyp: "",  pchng: -1.48,  seris: "EQ",  stktyp: "spot",  strikprc: "-",  symdes: "TATA CONSULTANCY SERV LT",  time: "1631085787",  tsym: "TCS-EQ",  uomtkn: "",  usym: "TCS-EQ",  vol: 3346207,  wgt: "11"}  
    constructor(private router: Router, private zone: NgZone, private dialog: MatDialog, private _bottomSheetRef: MatBottomSheetRef<SellmoreComponent>, private detector: ChangeDetectorRef, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private translate: TranslateService) {

            sellMore = this;
            sellMore.scripService = tradingRestService.getScripService();
            sellMore.holdingDetailData = Utility.holdingDetails;
       /* if (sellMore.holdingDetailData.Exch1) {
            sellMore.isSquareOFFNSE = true;
        }
        if (sellMore.holdingDetailData.Exch2) {
            sellMore.isSquareOFFBSE = true;
        }*/
        sellMore.scrpModel = Utility.getScripModel(sellMore.holdingDetailData['Token'], sellMore.holdingDetailData['ExchangeSeg']);
        sellMore.subscription = sellMore.scrpModel.scripUpdate.subscribe(ele =>{
            sellMore.detector.markForCheck();
        });
    }
    openPlaceOrder(buttontype) {
         /* let data_obj = {};
        const data_obj1 = { 'add': true, 'Exchange': sellMore.holdingDetailData.Exchange, 'Token': sellMore.holdingDetailData.Token, 'OrderType': 'L', 'ProductCode': sellMore.holdingDetailData.Pcode };
        sellMore.router.navigate(['placeorder'], { queryParams: data_obj1 });*/
        Utility.openPlaceOrder(sellMore.router, sellMore.holdingDetailData['Token'], sellMore.holdingDetailData['ExchangeSeg'], buttontype, sellMore.dialog, true);
        this._bottomSheetRef.dismiss();
    }
    onSwipeDown() {
        this._bottomSheetRef.dismiss();
    }
}
