 
<!-- <app-header  *ngIf = "isMobile" goback="1" [title]="'limits.transaction_details' | translate" ></app-header>
<div *ngIf = "!isMobile" mat-dialog-title="" class="mat-dialog-title mt-3" id="mat-dialog-title-2;">
    <div class="row">
        <div class="col-6"><h1>{{'limits.transaction_details' | translate}}</h1></div>
    </div>
</div> -->
<app-header  *ngIf = "isMobile" goback="1" [isLoading]="isLoading" [title]="'limits.transaction_details' | translate" [isReloadVisible]="false"></app-header>
<div  *ngIf = "!isMobile" class="rp-header" id="mat-dialog-title-2;">
    <div class="row">
        <div class="col-12">{{'limits.transaction_details' | translate}}</div>
    </div>
</div>
 <div [class.data-container]="isMobile" class="col-12">
        <div class="row">
            <div class="col-12">
               <mat-chip-list >
                    <mat-chip (click)="onTabChange(obj, 'dateFilter')" *ngFor="let obj of chipData" [class.imactive]="obj === filterData">
                    {{obj}}
                    </mat-chip>
                    <div class = "verticalline"></div>
                    <mat-chip (click)="onTabChange(obj, 'statusFilter')" *ngFor="let obj of statusChips" [class.imactive]="obj === statusChip">
                        {{obj}}
                </mat-chip>
                <div class = "verticalline"></div>
                <mat-chip (click)="onTabChange(obj, 'transactionFiter')" *ngFor="let obj of transactionChips" [class.imactive]="obj === transactionFiter">
                    {{obj}}
                </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row pl-2">
            <div class="col-4">
                <mat-form-field>
                    <input matInput #start [max]="endDate"   [matDatepicker]="picker" placeholder="{{'placeorder.startdate' | translate}}"  [formControl]="showStartDate" (dateChange)="updateStartDate(start.value)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker ></mat-datepicker>
                    </mat-form-field>  
            </div>
            <div class="col-4">
                <mat-form-field>
                    <input matInput #end [max]="todaysDate"  [matDatepicker]="picker1" [formControl]="showEndDate" placeholder="{{'events.end_date' | translate}}" (dateChange)="updateEndDate(end.value)">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class = "col-4" *ngIf = "!isMobile">
                <button class = "col-2" (click) = "getDetails('submitButton')" class="btn-primary login-button mt-3  mr-2" mat-flat-button>{{'settings.submit' | translate}}</button>
                <button class = "col-2"  [disabled] = "isNodata" mat-icon-button matTooltip="{{'books.export' | translate}}" (click)="exportCSV()" class="mt-2  subcolor2"><i class="icon-refresh"></i></button>
            </div>
            <div class = "col-4" *ngIf = "isMobile" >
                <button  (click) = "getDetails('submitButton')" class="btn-primary  mt-2 mr-1"  mat-icon-button><i class="icon-check"></i></button>
                <button  [disabled] = "isNodata" mat-icon-button matTooltip="{{'books.export' | translate}}" (click)="exportCSV()" class="mt-2 float-right subcolor2"><i class="icon-refresh"></i></button>
            </div>
        </div>  
       <ng-container *ngIf = "!isNodata && !isMobile && filteredTranArray.length > 0">
           <div class="transactiontable">
            <div class="table-global">
                <table mat-table [dataSource]="filteredTranArray" multiTemplateDataRows>
                    <ng-container matColumnDef="amount" class="mr-2">
                        <th  mat-header-cell *matHeaderCellDef translate  class = "text-left" >books.amt</th>
                        <td class = "text-left fw-4 " mat-cell  *matCellDef="let element" >{{element.amount/100 | pricepipe:2}}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef translate class = "text-left">settings.status</th>
                        <td mat-cell class="text-left" [class.impositive] = "element.status === 'payment_completed' || element.status === 'completed' || element.status === 'send_reqtolus'" [class.imnegative] = "element.status === 'rejected'   || element.status === 'api_error' || element.status === 'status_mismatch'  || element.status === 'amount_mismatch' || element.status === 'api_failure' || element.status === 'invalid_payment' || element.status === 'failed_fromlus'" [class.impartial] = "element.status === 'payout_initiated' || element.status === 'modified'   || element.status === 'modified' || element.status === 'order_created' || element.status === 'payment_captured' || element.status === 'payin_inprocess'"  [class.subcolor2]="element.status === 'cancelled'   || element.status === 'modified'"  *matCellDef="let element" >{{getStatus(element.status)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th  mat-header-cell *matHeaderCellDef translate  class = "text-left" translate>limits.date</th>
                        <td class = "text-left" mat-cell  *matCellDef="let element" >{{element.transaction_initiated | datepipe: 'datetime'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="tranType">
                        <th  mat-header-cell *matHeaderCellDef translate  class = "text-left" translate>placeorder.trans</th>
                        <td  mat-cell  *matCellDef="let element" class = "text-left">{{element.transaction_type === 'payin' ? ('placeorder.credit' | translate) : ('placeorder.debit' | translate) }}</td>
                    </ng-container>
                    <ng-container matColumnDef="info">
                        <th  mat-header-cell *matHeaderCellDef translate  class = "text-left" ></th>
                        <td  mat-cell  *matCellDef="let element; let i= index;" class = "text-left"><span class="ts-1 subcolor2"><i [class.icon-addb]= "unique !== element.unique_key" [class.icon-minusb]= "unique === element.unique_key"></i></span></td>
                    </ng-container>
                    
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" >
                          <div class="row"> 
                            <div class="col-6 text-left">
                                <div>
                                  <span class="subcolor2" translate>limits.bill_id</span><span class="graytext">:</span><br>
                                  <span class="float-left">{{element.unique_key}}</span>
                                </div>
                            </div>
                            <div class="col-6 text-left">
                                <div>
                                <span class="subcolor2"  translate>settings.source</span><span class="graytext">:</span><br>
                                <span class="float-left"> {{element.method}}</span>
                                </div>
                            </div>
                          </div>
                          <div class="row mt-1" *ngIf =  "element.transaction_type === 'payin'"> 
                            <div class="col-6 text-left">
                                <div>
                                  <span class="subcolor2">{{'placeorder.trans' | translate}} {{'limits.charges' | translate}}</span><span class="graytext">:</span><br>
                                  <span class="float-left">{{(element.rzrpay_trns_charge) ? (element.rzrpay_trns_charge / 100 | pricepipe:2) : ('-') }}</span>
                                </div>
                            </div>
                            <div class="col-6 text-left">
                                <div>
                                  <span class="subcolor2"  translate>limits.gst_trans</span><span class="graytext">:</span><br>
                                  <span class = "float-left">{{(element.rzrpay_trns_gst) ? (element.rzrpay_trns_gst / 100 | pricepipe:2) : ('-') }}</span>
                                </div>
                            </div>
                           </div>
                          <div class="row mt-1" *ngIf="element.status !== 'payment_completed' &&  element.transaction_type === 'payin'"> 
                            <div class="col-6 text-left">
                                <div>
                                  <span class="subcolor2" translate>limits.refund_status</span><span class="graytext">:</span><br>
                                  <span class="float-left">{{(element.refund_status === null) ? '-' : element.refund_status}}</span>
                                </div>
                            </div>
                            <div class="col-6 text-left">
                                <div>
                                    <span class="subcolor2" translate>limits.amt_ref</span><span class="graytext">:</span><br>
                                    <span class = "float-left">{{(element.amount_refunded > 0) ? (element.amount_refunded / 100 | pricepipe : 2) : ('-') }}</span>
                                </div>
                            </div>
                          </div>
                          <div class="row mt-1"> 
                            <div class="col-6 text-left">
                                <div>
                                  <span class="subcolor2" translate>limits.created</span><span class="graytext">:</span><br>
                                  <span class="float-left">{{element.transaction_initiated | datepipe: 'datetime'}}</span>
                                </div>
                            </div>
                            <div class="col-6 text-left">
                                <div>
                                    <span class="subcolor2"  translate>placeorder.trans</span><span class="graytext ">:</span><br>
                                    <span class="float-left">{{element.transaction_type === 'payin' ? ('placeorder.credit' | translate) : ('placeorder.debit' | translate)}}</span>
                                </div>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-12 text-left">
                                <div>
                                  <span class="subcolor2" translate>limits.remark</span><span class="graytext">:</span><br>
                                  <span class="float-left">{{element.remark}}</span>
                                </div>
                            </div>
                          </div>

                          <div *ngIf = "element.transaction_type !== 'payin'" class="row mt-1">
                              <div class = "col-2">
                              </div>
                                <div class = "col-10 float-right">
                                    <button [disabled] = "getStatus(element.status) === 'Failed' || getStatus(element.status) === 'Rejected' || getStatus(element.status) === 'Cancelled'  || getStatus(element.status) === 'Success' || !payoutEnable" class = "col-3"  (click) = "onModifyClick(element)"   class="btn-primary login-button mt-3  mr-2" mat-flat-button>{{'limits.modify_amount' | translate}} </button>
                                    <button  [disabled] = "getStatus(element.status) === 'Failed' || getStatus(element.status) === 'Rejected' || getStatus(element.status) === 'Cancelled'  || getStatus(element.status) === 'Success' || !payoutEnable" class = "col-3" (click) = "onCancelWithdrawal(element.unique_key)" class="  btn-primary login-button mt-3  mr-2" mat-flat-button>{{'limits.cancel_withdraw' | translate}} </button>
                                </div>
                          </div>
                        </td>
                      </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns; let i= index" class="mouse-p" (click) = "onInfoClick(element.unique_key, i)">     
                    </tr>
                    <tr [class.expansion] = "unique === element.unique_key" [class.collaps] = "unique !== element.unique_key" mat-row *matRowDef="let element; columns: ['expandedDetail']"></tr>
                </table>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isNodata && isMobile  && filteredTranArray.length > 0"> 
        <div class = "transactiontable">
            <div *ngFor="let data of filteredTranArray; let i = index">
                <div class="row" (click) = "onInfoClick(data.unique_key)" >
                    <div class="col-12">
                        <div class="list-view  border-b">                                
                            <div class="row">
                                <div class="col-7">
                                    <div class="subcolor ts-2">
                                        <span [ngClass]="{'tc-1' : true, 'ts-4' : true3}">{{data.transaction_initiated | datepipe : 'datetime'}}</span><br>
                                        <span [class.tag-red]="data.transaction_type === 'payout'" [class.tag-green]="data.transaction_type === 'payin'">{{data.transaction_type === 'payin' ? ('placeorder.credit' | translate) : ('placeorder.debit' | translate) }}</span> | {{data.method === null ? '-' : data.method  }}<br>
                                        <span>{{data.unique_key}}</span>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="subcolor ts-2 text-right">
                                        <span class="subcolor2">{{'books.amt' | translate}}: </span><span class="ts-4 tc-1">{{data.amount/100 | pricepipe:2}}</span>
                                        <div>
                                            <span [class.impositive] = "data.status === 'payment_completed' || data.status === 'completed' || data.status === 'send_reqtolus'" [class.imnegative] = "data.status === 'rejected'   || data.status === 'api_error' || data.status === 'status_mismatch'  || data.status === 'amount_mismatch' || data.status === 'api_failure' || data.status === 'invalid_payment' || data.status === 'failed_fromlus'" [class.impartial] = "data.status === 'payout_initiated' || data.status === 'modified'   || data.status === 'modified' || data.status === 'order_created' || data.status === 'payment_captured' || data.status === 'payin_inprocess'"  [class.subcolor2]="data.status === 'cancelled'   || data.status === 'modified'">{{getStatus(data.status)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf = "data.unique_key === unique">
                    <div class="row mt-1"> 
                        <div class="col-8 text-left">
                            <div>
                              <span class="subcolor2" translate>limits.bill_id</span><span class="graytext">:</span><br>
                              <span class="float-left">{{data.unique_key}}</span>
                            </div>
                        </div>
                        <div class="col-4 text-left">
                            <div>
                            <span class="subcolor2"  translate>settings.source</span><span class="graytext">:</span><br>
                            <span> {{data.method}}</span>
                            </div>
                        </div>
                    </div>
                      <div class="row mt-1"> 
                        <div class="col-8 text-left">
                            <div>
                              <span class="subcolor2"  translate>limits.created</span><span class="graytext">:</span><br>
                              <span class = "float-left">{{data.transaction_initiated | datepipe: 'datetime'}}</span>
                            </div>
                        </div>
                        <div class="col-4 text-left">
                            <div>
                              <span class="subcolor2"  translate>placeorder.trans</span><span class="graytext ">:</span><br>
                              <span class="float-left" [class.impositive] = "data.transaction_type === 'payin'" [class.imnegative] = "data.transaction_type === 'payout'">{{data.transaction_type === 'payin' ? ('placeorder.credit' | translate) : ('placeorder.debit' | translate) }}</span>
                            </div>
                        </div>
                      </div>
                      <div  *ngIf = "data.transaction_type === 'payin'" class = "row mt-1">
                        <div class="col-8 text-left">
                            <div>
                              <span class="subcolor2" translate>limits.charges</span><span class="graytext">:</span><br>
                              <span class="float-left">{{(data.rzrpay_trns_charge) > 0 ? (data.rzrpay_trns_charge / 100 | pricepipe : 2) : ('-')}}</span>
                            </div>
                        </div>
                        <div class="col-4 text-left">
                            <div>
                              <span class="subcolor2"  translate>limits.gst_trans</span><span class="graytext">:</span><br>
                              <span class = "float-left">{{data.rzrpay_trns_gst ? data.rzrpay_trns_gst / 100 : '-' | pricepipe}}</span>
                            </div>
                        </div>
                      </div>
                      <div class="row mt-1" *ngIf = "data.status !== 'PaymentCompleted' && data.transaction_type === 'payin'"> 
                        <div class="col-4 text-left">
                            <div>
                              <span class="subcolor2" translate>limits.refund_status</span><span class="graytext">:</span><br>
                              <span class="float-left">{{(data.refund_status === null) ? '-' : data.refund_status}}</span>
                            </div>
                        </div>
                        <div class="col-8 text-left">
                            <div>
                                <span class="subcolor2" translate>limits.amt_ref</span><span class="graytext">:</span><br>
                                <span class = "float-left">{{(data.amount_refunded > 0) ? (data.amount_refunded / 100 | pricepipe : 2) : ('-')}}</span>
                            </div>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-10 text-left">
                            <span class="subcolor2"  translate>limits.remark</span><span class="graytext">:</span><br>
                            <span class = "float-left">{{data.remark}}</span>
                        </div>
                      </div>
                      <div class="row mt-1" *ngIf = "data.transaction_type !== 'payin'">
                        <div class="col-12 text-right">
                            <button [disabled] = "getStatus(data.status) === 'Failed' || getStatus(data.status) === 'Rejected' || getStatus(data.status) === 'Cancelled'  || getStatus(data.status) === 'Success' || !payoutEnable"   (click) = "onModifyClick(data)"   class="btn-primary login-button mt-3  mr-2" mat-flat-button>{{'stock_detail.modify' | translate}}</button>
                            <button  [disabled] = "getStatus(data.status) === 'Failed' || getStatus(data.status) === 'Rejected' || getStatus(data.status) === 'Cancelled'  || getStatus(data.status) === 'Success' || !payoutEnable"  (click) = "onCancelWithdrawal(data.unique_key)" class="  btn-primary login-button mt-3" mat-flat-button>{{'watchlist.cancel' | translate}}</button>
                        </div>
                      </div>
                </div>
            </div>
        </div>
        </ng-container>
        <ng-container *ngIf = "(!isLoading && isNodata) || (!isLoading && filteredTranArray.length === 0)">
            <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
        </ng-container>
</div>

