import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, Inject, Injector } from '@angular/core';
import { PtPlusRestService } from '../../../../services/ptplusrest.service';
import { TradingRestService } from '../../../../services/tradingrest.service';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { Keys } from 'src/app/services/keys';
import { StockMoreOptionComponent } from '../../stockmoreoption/stockmoreoption.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Utility } from 'src/app/services/utility';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from '../../../../services/tableutility';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';

let rollover: RollOverComponent;
@Component({
    selector: 'app-rollover-market',
    templateUrl: './rollover.component.html',
    styleUrls: ['./rollover.component.scss']
})
export class RollOverComponent implements OnInit {
    displayedColumns = TableUtility.headerConfig;
    rolloverArr = [];
    isLoading: boolean;
    isSearch = false;
    goback = '1';
    isMobile = true;
    scripList;
    isNoData;
    dataArray;
    dataArr = [];
    tagText = 0;
    utility: any;
    @Output() loaderEvent = new EventEmitter();
    @Output() openStockDetailPage = new EventEmitter<string>();
    @Input() isCustomDashboard = false;
    Height: any;
    container?: ComponentContainer;
    left: any;
    modesub: Subscription;

    constructor(private injector: Injector, private detector: ChangeDetectorRef, private PS: PtPlusRestService, private tradingRestService: TradingRestService, 
        private translate: TranslateService, private _bottomSheet: MatBottomSheet,
        private router: Router, private dialog: MatDialog) {
        rollover = this;
        rollover.utility = Utility;
        if (!Utility.IS_MOBILE) {
            rollover.container = <ComponentContainer>rollover.injector.get('Container');
        }
        rollover.isMobile = Utility.IS_MOBILE;
        rollover.isLoading = true;
        rollover.loaderEvent.emit(true);
        rollover.PS.getDerivativeService().getIndexFutureRollOverDataNQ('nse_fo', 'Index', rollover.resData, (data) => {
            rollover.rolloverArr = data;
        });
        rollover.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
          rollover.changeMode();
      });
  }

  changeMode(){
    rollover.Height = {
      'height': (rollover.container.height - TableUtility.RolHeight) + 'px',
      'overflow': 'auto'
    };
    rollover.left = {
        'left': (rollover.container.width - TableUtility.RolLeft) + 'px'
      };
    rollover.detector.detectChanges();
  }

    ngOnInit() {
        if (rollover.container) {
           /* rollover.container.on('resize', _ => {
              rollover.changeMode();
            });*/
        }
        rollover.dataArr = ['dashboard.index', 'stock_detail.stock'];
        if (rollover.isCustomDashboard) {
          rollover.changeMode();
        }
    }

    onTabChange(event) {
        let type;
        rollover.tagText = event;
        rollover.isLoading = true;
        rollover.loaderEvent.emit(true);

        switch (event) {
            case 0:
                type = 'Index';
                break;

            case 1:
                type = 'Stock';
                break;
        }
        rollover.PS.getDerivativeService().getIndexFutureRollOverDataNQ('nse_fo', type, rollover.resData, (data) => {
            rollover.rolloverArr = data;
        });
    }

    resData(data, code, message) {
        rollover.isLoading = false;
        rollover.loaderEvent.emit(rollover.isLoading);
        if (code === Keys.RES_CODE_SUCCESS) {
            rollover.isNoData = false;
            rollover.rolloverArr = data;
            rollover.dataArray = Array.from(rollover.rolloverArr);
        }
        else {
            rollover.isNoData = true;
        }
    }
    openScripModel(option) {
        rollover._bottomSheet.open(StockMoreOptionComponent, {
            data: option, restoreFocus: false
        });
    }
    openPlaceOrder(buttontype, data) {
        Utility.openPlaceOrder(rollover.router, data.Token, data.ExchangeSegment, buttontype, rollover.dialog);
    }
    openStockDetail(data) {
        const obj = { 'token': data.Token, 'exch': data.ExchangeSegment, 'symbol': data.TradingSymbol }
        rollover.openStockDetailPage.emit(JSON.stringify(obj));
    }

    handleMouseOver(row) {
    const TradingSymbol = row.TradingSymbol;
    rollover.rolloverArr.map((data: any) => {
      if (TradingSymbol && data.TradingSymbol && data.TradingSymbol === TradingSymbol) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const TradingSymbol = row.TradingSymbol;
    rollover.rolloverArr.map((data: any) => {
      if (data.TradingSymbol === TradingSymbol) {
        data.show = false;
      }
    });
  }

  sortWatchlist(event) {
    const data = Array.from(rollover.dataArray);
    if (event.active === 'TradingSymbol' || event.active === 'CompanyName') {
      if (event.direction === 'asc')
        rollover.rolloverArr = data.sort((a, b) => (a[event.active] > b[event.active]) ? 1 : ((b[event.active] > a[event.active]) ? -1 : 0));
      else
        rollover.rolloverArr = data.sort((a, b) => (a[event.active] < b[event.active]) ? 1 : ((b[event.active] < a[event.active]) ? -1 : 0));
    }
    else {
      if (event.direction === 'asc')
        rollover.rolloverArr = data.sort((a, b) => (Utility.getNumber(a[event.active]) > Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) > Utility.getNumber(a[event.active])) ? -1 : 0));
      else
        rollover.rolloverArr = data.sort((a, b) => (Utility.getNumber(a[event.active]) < Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) < Utility.getNumber(a[event.active])) ? -1 : 0));
    }
  }
}
