import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';


let fcmComponent: FcmComponent;
@Component({
  selector: 'app-fcm',
  templateUrl: './fcm.component.html',
  styleUrls: ['./fcm.component.scss']

})

export class FcmComponent implements OnInit {

  afcmNotificationList: Array<{title: String, text: String}> = [
    {'text': '1', 'title': 'd'},
    {'text': '2', 'title': 'd'},
    {'text': '3', 'title': 'd'},
    {'text': '4', 'title': 'd'}
  ];
  displayedColumns: string[] = ['symbol'];

  @Input() fcmNotificationList: Array<{title: String, text: String}>;
  constructor()
  {

  }

    ngOnInit() {

    }
}
