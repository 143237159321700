import { Injectable } from '@angular/core';
import { Preferences } from 'src/app/services/Preferences';
import { User } from '../Fingerprint/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  getUser(userID: string): User {
    return this.getUsers().find(u => u.userId === userID);
  }

  getUsers(): User[] {
    const usersString  = Preferences.getPreference('users');
    return usersString ? JSON.parse(usersString) : [];
  }

  saveUsers(users: User[]) {
    return Preferences.setPreference('users', JSON.stringify(users));
  }

  addUser(user: User) {
    const users = [...this.getUsers(), user];
    this.saveUsers(users);
  }

  removeUser(userID: string) {
    const filteredUsers = this.getUsers().filter(user => user.userId !== userID);
    this.saveUsers(filteredUsers);
  }
}