import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Preferences } from "../Preferences";
import { TradingRestService } from "../tradingrest.service";
import { Url } from "../url";
import { ChannelType, Utility } from "../utility";
import { ITriggerService } from "./itrigger.service";
let hst: HSTriggerService;
@Injectable()
export class HSTriggerService implements ITriggerService {
    apiService: ApiService;
   tradingRestService: TradingRestService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
        hst = this;
    }

    setTradingRestService(tradingRest: TradingRestService) {
        hst.tradingRestService = tradingRest;
    }

    getAllTriggers(reqdata: any, callback: Function) {
        let message = '';
        let code;
        let status = '';
        let respData = [];
        let sm;
        return this.apiService.hssparkpost(Url.GET_TRIGGERS, reqdata).subscribe((res) => {
            code = res['code'];
            status = res['status'];
            
            if(code === 200 && status === 'SUCCESS') {
                respData = res['data'];
                const scripTempList = [];
                message = status;
                respData.forEach((element,i) => { 
                    sm = Utility.getScripModel(element.cond.tkn,element.cond.ex, hst.tradingRestService, true, ChannelType.GTT);
                    respData[i].scripModel = sm; 
                    scripTempList.push(element.cond.ex + '|' + element.cond.tkn);
                });
                if(scripTempList.length>0){
                    const scripListObj = {
                        'uid': Preferences.getPreference('userid'),
                        'tomcatCount': Preferences.getPreference('tomcatcount'),
                        'scripList': scripTempList.toString()
                    };
                    const tradingRestService = new TradingRestService(this.apiService);
                    tradingRestService.getScripService().getMultipleScrips(scripListObj, (data, resCode, resMessage) => {
                    });
                }  
            } else {
                message = res['message'];
            }
            callback(respData, code, message); 
        },error => {
            message = error;
            callback(code, status, message);
        });
    }

    createTrigger(reqdata: any, callback: Function) {
        let message = '';
        let code;
        let status = '';
        let respData = {};
        return this.apiService.hssparkpost(Url.CREATE_TRIGGERS, reqdata).subscribe((res) => {
            code = res['code'];
            status = res['status'];
            if(code === 200 && status === 'SUCCESS') {
                respData = res['data'];
                message = status;
            } else {
                message = res['message'];
            }
            callback(respData, code, message);   
        },error => {
            message = error;
            callback(code, status, message);
        });
    }

    editTrigger(id: any, reqdata: any, callback: Function) {
        let message = '';
        let code;
        let status = '';
        let respData = {};
        return this.apiService.hssparkpost(Url.EDIT_TRIGGERS + '/' + id, reqdata).subscribe((res) => {
            code = res['code'];
            status = res['status'];
            if(code === 200 && status === 'SUCCESS') {
                respData = res['data'];
                message = status;
            } else {
                message = res['message'];
            }
            callback(respData, code, message);
        },error => {
            message = error;
            callback(code, status, message);
        });
    }

    deleteTrigger(id: any, callback: Function) {
        let message='';
        let code;
        let status = '';
        let respData = {};
        return this.apiService.hssparkpost(Url.DELETE_TRIGGERS + '/' + id, {}).subscribe((res) => {
            code = res['code'];
            status = res['status'];
            if(code === 200 && status === 'SUCCESS') {
                respData = res['data'];
                message = status;
            } else {
                message = res['message'];
            }
            callback(respData, code, message);
        },error => {
            message = error;
            callback(code, status, message);
        });
    }

    getExecutedTriggers(reqdata: any, callback: Function) {
        let message='';
        let code;
        let status = '';
        let respData = [];
        return this.apiService.hssparkpost(Url.EXECUTED_TRIGGERS, reqdata).subscribe((res) => {
            code = res['code'];
            status = res['status'];
            if(code === 200 && status === 'SUCCESS') {
                respData = res['data'];
                message = status;
            } else {
                message = res['message'];
            }
            callback(respData, code, message);
           
        },error => {
            message = error;
            callback(code, status, message);
        });
    }
    
}