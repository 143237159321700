<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row list-view border-b" (swipedown)="onSwipeDown()">
    <div class="col-8 pl-0">
        <div class="addwatchlistbtn" matRipple>
            <button mat-icon-button (click) = "addWLdata(holdingDetailData)" [matMenuTriggerFor]="menu" class="primetext"><i class="icon-plus"></i></button>
            <mat-menu #menu="matMenu">
                <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                <ng-container *ngFor = "let data of WlArr">
                    <button mat-menu-item (click) = "addStock(data.wlName, holdingDetailData)" [disabled] = "data.isDisable">
                        <span>{{data.wlName}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </div>
        <div class="min-w160 subcolor ts-2 bottomsheetwidth">
            <span class="tc-1 ts-4 textclip">{{scrpModel.TradingSymbol | symbolpipe}}</span><small class="exchangename">{{scrpModel.ExchangeName}}</small><br>
            <div class="textclip d-block" matTooltip="{{scrpModel.DisplaySymbolDescription}}">{{scrpModel.DisplaySymbolDescription}}</div>
        </div>
    </div>
    <div class="col-4 text-right" [class.imnegative]="scrpModel.Change<0" [class.impositive]="scrpModel.Change>0">
        <div>
            <div class="ts-4"><span [class.imnegativebg]="scrpModel.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="scrpModel.TickDirection === 1 && flashType !== 'noFlash'">{{scrpModel.LastTradePrice |  pricepipe:scrpModel.DecimalPrecision}}</span><br>
                <span class="ts-2" [class.imnegativebg]="scrpModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="scrpModel.TickDirection === 1 && flashType === 'defaultFlash'">{{scrpModel.Change | pricepipe:scrpModel.DecimalPrecision}} <small class="ts-1">({{scrpModel.PercentageChange | percentagepipe}})</small></span>
            </div>
        </div>
    </div>
</div>
<div class="sheetscrollarea position-area">
    <div class="row">
        <div class="col-12">
            <table class="table-bottomsheet">
                <tr>
                    <td><span class="namefield" translate>{{'all.available' | translate}} {{'all.qty' | translate}}</span></td><td> {{holdingDetailData.AvailableQty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>{{'all.total' | translate}} {{'all.qty' | translate}}</span></td><td>{{holdingDetailData.TotalHoldingQty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>{{'all.holding' | translate}} {{'all.qty' | translate}}</span></td><td>{{holdingDetailData.Holdqty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>{{'all.withheld' | translate}} {{'all.qty' | translate}}</span></td><td>{{holdingDetailData.WHqty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>{{'all.collateral' | translate}} {{'all.withheld' | translate}} {{'all.qty' | translate}}</span></td><td>{{holdingDetailData.WCqty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.prc</span></td>
                    <td class="w50">
                        <input id="avgPriceInput" class="onFocus" [value]="holdingDetailData.Price" *ngIf="isAvgPriceEditable && holdingDetailData.isSelected"  (keypress)="_keyPress($event)"
                        (focusout)="changeAvgPrice(holdingDetailData, $event.target.value)" matInput type="number" placeholder="0.00" inputmode="decimal" (focusin)="onPriceInputFocus(holdingDetailData, $event.target, 'avgPriceInput')">
                        <div *ngIf="isAvgPriceEditable && !holdingDetailData.isSelected" (click)="onEditIconClick('avgPriceInput', holdingDetailData)">
                            {{holdingDetailData.Price | pricepipe:holdingDetailData.DecimalPrecision}} 
                            <em (click)="onEditIconClick('avgPriceInput', holdingDetailData)" [class.invisible] ="iseditTrue" *ngIf="isAvgPriceEditable"><i class="icon-edit float-right"></i></em>
                        </div>
                        <div *ngIf="!isAvgPriceEditable">
                            {{holdingDetailData.Price | pricepipe: holdingDetailData.DecimalPrecision}} 
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>BTST</span></td><td>{{holdingDetailData.Btst | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.series</span></td><td>{{holdingDetailData.Series}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>{{'all.update' | translate}} {{'all.qty' | translate}}</span></td><td>{{holdingDetailData.HUqty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>{{'all.collateral' | translate}} {{'all.qty' | translate}}</span></td><td>{{holdingDetailData.Colqty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>{{'all.used' | translate}} {{'all.qty' | translate}}</span></td><td>{{holdingDetailData.Usedqty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.haircut</span></td><td> {{holdingDetailData.Haircut | pricepipe: holdingDetailData.DecimalPrecision}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>
<div class="row bottomsheet-buttons">
    <div class="col-12">
        <div class="btngroup">
            <!-- <div (click)="handleButton('NSE')" *ngIf="!isSquareOFFBSE"><i class="icon-pcb"></i>SQ. OFF NSE</div>
            <div (click)="handleButton('BSE')" *ngIf="!isSquareOFFNSE"><i class="icon-pcb"></i>SQ. OFF BSE</div> -->
            <!--div [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i>watchlist</div>
                <mat-menu #menu="matMenu">
                    <mat-optgroup label="Select Watchlist" class="watchlist-nav"></mat-optgroup>
                    <ng-container *ngFor = "let data of WlArr">
                        <button mat-menu-item (click) = "addStock(data, holdingDetailData)">
                            <span>{{data}}</span>
                        </button>
                    </ng-container>
                </mat-menu-->
                <div (click)="handleButton('ADD')" class="impositive"><i class="icon-addb"></i>{{'all.add' | translate}}</div>
                <div *ngIf = "holdingDetailData.AvailableQty > 0" (click)="handleButton('REMOVE')" class="imnegative"><i class="icon-minusb"></i>{{'all.exit' | translate}}</div>
                <div (click)="handleButton('sd')"><i class="icon-ib"></i>{{'all.detail' | translate}}</div>
        </div>    
    </div>
</div>