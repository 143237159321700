<div class="webpanelnew" [class.sidenav-on]="isMobile" [class.pt-0]="!isMobile && !cellview">
    <div *ngIf = "noData"><app-nodata (refreshEvent) = "manageWL()" [btnTitle]="errorbtntext"></app-nodata></div>
        <div *ngIf = "!noData" class="row px-0" [class.topsticky] = "isTopSticky">
            <div class="col-12">
                <div class="row panelsubheader">
                        <div class="col-6 h-100">
                            <mat-form-field class="noborder-b">
                                <mat-select panelClass="my-select" #watchlistSelect [(ngModel)]="wlName" (ngModelChange)="selectTab1()">
                                    <mat-optgroup *ngIf = "predefinedWatchlist.length > 0" label="{{'all.indices' | translate}}">
                                        <mat-option *ngFor="let data of predefinedWatchlist" [value]="data">{{data}}
                                            <!-- <button class="ts-2" mat-icon-button><i class="icon-delete"></i></button> -->
                                        </mat-option>
                                        </mat-optgroup>
                                        <mat-optgroup label="{{'all.user_watchlist' | translate}}">
                                            <mat-option *ngFor="let data of watchnamelist" [value]="data">{{data}}
                                                <!-- <button class="ts-2" mat-icon-button><i class="icon-delete"></i></button> -->
                                            </mat-option>
                                            <mat-option disabled (click)="manageWL()" class="border-t pl-3 pr-0">
                                                <span class="primetext" translate>all.manage_watchlist</span>
                                                <button class="ts-3 primetext" mat-icon-button><i class="icon-setting"></i></button>
                                            </mat-option>
                                        </mat-optgroup>
                                    </mat-select>
                                </mat-form-field>
                        </div>
                        <div class = "col-6 text-right h-100">
                            <div *ngIf="!isLoader && isDelete">
                                <button mat-icon-button (click)="reset()" class=""><i class="icon-close"></i></button>
                                <button class="deletewatchlist" [disabled] = "isdisable || (selecttoken.length === scripResult.length)" mat-icon-button  (click)="deleteStockWL()" ><i class="icon-delete"></i></button>
                            </div>
                            <div *ngIf="!isLoader && !isDelete">
                                <button  *ngIf = "!predefinedWatchlist.includes(wlName) && wlName !== undefined" matTooltip="{{'all.add_stock' | translate}}" (click)="addStock()" mat-icon-button><i class="icon-addb"></i></button>
                                <button  matTooltip="{{'all.delete' | translate}}" *ngIf = "!predefinedWatchlist.includes(wlName) && isMobile && scripResult.length > 1" (click)="deleteStock()" mat-icon-button><i class="icon-minusb"></i></button>
                                <button *ngIf="cellview || isMobile" [ngClass]="{'d-sm-none' : isMobile}" disabled="false" #menuTrigger="matMenuTrigger" class="btn-icon" mat-icon-button [matMenuTriggerFor]="sortnav" (menuClosed)="matMenuClosed()">
                                    <i class="icon-menu"></i>
                                    <em *ngIf="selectedSortedItem !== sortItemList[0] || (selectedSortedItem === sortItemList[0] && chipSelected === matChipItems[1])" class="wl-bubblesmall"></em>
                                </button>
                                <mat-menu #sortnav="matMenu" class="panelnav" xPosition="before">
                                    <div class="primetext ts-2 pt-2 px-2">{{'all.sorting' | translate}}</div>
                                    <div (click)="$event.stopPropagation();$event.preventDefault();"><button (click)="sortingItemSelected(sortingItem)" mat-menu-item *ngFor="let sortingItem of sortItemList" >{{sortingItem}} <span class="rightitem impositive"><i [class.icon-godown]= "chipSelected === 'Asc' && selectedSortedItem === sortingItem" [class.icon-goup]= "chipSelected === 'Desc' && selectedSortedItem === sortingItem"></i></span></button></div>
                                </mat-menu>
                                <!-- <button *ngIf="!isLoader && !isDelete" mat-icon-button (click)="manageWL()" matTooltip="Settings"><i class="icon-setting"></i></button> -->
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <ngx-pull-to-refresh
        spinnerColor="#ff0000"
        distanceForRefresh="80"
        [isEnable]="false"
        [targetElement]="targetElement"
        (refresh)="myRefreshEvent($event, 'refresh')"
        (loadMore)="alert('loadmore')"
        class = "loader">
        <div #p2 *ngxCacheIf="isMobile || cellview" [ngStyle]="Height" (scroll)="onWindowScroll($event)">
            <div [class.pl-2]="cellview" [class.pr-2]="cellview" *ngFor="let option of scripResult; let i = index"  [class.wlweb]="cellview" (click)="highlight(option, i)" tabindex="999" [attr.id]="'wl_cell' + i">
                <div class="row">
                    <div class="col-12" [ngClass]="{'highlight': selectedRowIndex == i}">
                        <!--portrait view-->
                        <div class="list-view border-b" [ngClass]="{'d-block d-sm-none' : isMobile}">
                            <div class="row">
                                <div class="col-7 pr-0">
                                    <span [ngClass]="{'tc-1' : true, 'ts-4' : true, 'textclip' : true}" matTooltip="{{option.TradingSymbol}}">{{((option.ExchangeSegment == 'nse_cm' || option.ExchangeSegment == 'bsc_cm') ? option.TradingSymbol : option.Symbolname) | symbolpipe}}&nbsp;<span class="ts-2" *ngIf = "option.ExchangeName === 'BSE'">({{option.InstrumentName}})</span> </span><small class="exchangename">{{option.ExchangeName}}</small><br>
                                    <span [ngClass]="{'subcolor' : true, 'textuppercase': true, 'texttrim' : true, 'fnotext' : option.ExchangeName === 'NFO'}">{{option.DisplaySymbolDescription}}</span>
                                </div>
                                <div *ngIf="!isDelete" class="col-5 text-right">
                                    <div [class.imnegative]="option.Change<0" [class.impositive]="option.Change>0">
                                        <div [class.imnegativebg]="option.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="option.TickDirection === 1 && flashType === 'defaultFlash'">
                                            <span [class.imnegativebg]="option.TickDirection === -1 && flashType === 'ltpFlash'" [class.impositivebg]="option.TickDirection === 1 && flashType === 'ltpFlash'" class="ts-4">{{option.LastTradePrice | pricepipe:option.DecimalPrecision}}</span><br>
                                            <span>{{option.Change | pricepipe:option.DecimalPrecision}} <small class="ts-2">({{option.PercentageChange | percentagepipe}})</small></span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="isDelete" class="col-5 text-right">
                                    <mat-checkbox (change)="onCheck(option, $event)" [(ngModel)]="option.checked" class="p-2 d-block"></mat-checkbox>
                                </div>
                                <div class="wl-btn" *ngxCacheIf="!isDelete && cellview" [class.d-none]="!M.IS_MOUSE">
                                    <button (click)='openPlaceOrder("B", option)' class="btn-positive" matTooltip="{{'all.buy' | translate}}" mat-icon-button><i class="icon-bb"></i></button> 
                                    <button (click)='openPlaceOrder("S", option)' class="ml-2 btn-negative" matTooltip="{{'all.sell' | translate}}" mat-icon-button ><i class="icon-sb"></i></button>
                                    <button *ngIf="createGttFlag" matTooltip="{{'all.create_gtt' | translate}}" (click)='openTrigger(option)' class="ml-2 btn-primary" mat-icon-button><i class="icon-aim"></i></button>
                                    <button *ngIf = "!predefinedWatchlist.includes(wlName) && scripResult.length > 1" (click)='deleteSpecificStock(option,i)' matTooltip="{{'all.delete' | translate}}" class="ml-2 btn-primary" mat-icon-button [disabled] = "isDeleteDisable"><i class="icon-delete"></i></button>
                                    <button matTooltip="{{'all.chart' | translate}}" (click)='openChart(option)' class="ml-2 btn-primary" mat-icon-button><i class="icon-insert-chart"></i></button>
                                    <button matTooltip="{{'all.stock_detail' | translate}}" (click)='openStockDetail(option)' class="ml-2 btn-primary" mat-icon-button><i class="icon-ib"></i></button>     
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <ng-container *ngxCacheIf="!cellview">
            <div #p1 *ngIf = "!noData" class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'" [class.tabletscroll]="utility.IS_TABLET">
                <table mat-table [dataSource]="scripResult" matSort (matSortChange)="sortWatchlist($event)" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                    <ng-container matColumnDef="TradingSymbol" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag  [cdkDragDisabled]="true"> <span translate>all.symbol</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <span class="button-row">
                            <!-- || selectedRowIndex == i-->
                            <div [class.d-none]="!M.IS_MOUSE" class="float-button-container" [ngStyle]="left" [class.active]="element.show == true">
                                <button matTooltip="{{'all.buy' | translate}}" (click)='openPlaceOrder("B", element)' class="btn-positive" mat-icon-button><i class="icon-bb"></i></button> 
                                <button matTooltip="{{'all.sell' | translate}}" (click)='openPlaceOrder("S", element)' class="btn-negative" mat-icon-button ><i class="icon-sb"></i></button>
                                <button *ngIf="createGttFlag" matTooltip="{{'all.create_gtt' | translate}}" (click)='openTrigger(element)' class="btn-primary" mat-icon-button><i class="icon-aim"></i></button> 
                                <button *ngIf = "!predefinedWatchlist.includes(wlName) && scripResult.length > 1" (click)='deleteSpecificStock(element, i)' matTooltip="{{'all.delete' | translate}}" class="btn-primary" mat-icon-button [disabled] = "isDeleteDisable"><i class="icon-delete"></i></button>
                                <button matTooltip="{{'all.chart' | translate}}" (click)='openChart(element)' class="btn-primary" mat-icon-button><i class="icon-insert-chart"></i></button>
                                <button matTooltip="{{'all.stock_detail' | translate}}" (click)='openStockDetail(element)' class="btn-primary" mat-icon-button><i class="icon-ib"></i></button>  
                            </div>
                        </span>
                        <mat-checkbox *ngIf="isDelete" (change)="onCheck(element, $event)" [(ngModel)]="element.checked"></mat-checkbox>
                        <div class="firstline">{{element.TradingSymbol | symbolpipe}}&nbsp;<span class="ts-2" *ngIf = "element.ExchangeName === 'BSE'">({{element.InstrumentName}})</span> <small>{{element.ExchangeName}}</small></div>
                            </td>
                    </ng-container>
                
                    <ng-container matColumnDef="CompanyName">
                    <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span *ngIf="!isDelete" translate>all.company_name</span> </th>
                    <td mat-cell *matCellDef="let element" class="text-left">
                        <small *ngIf="!isDelete">{{element.CompanyName === '' ? '-' : element.CompanyName}} </small></td>
                    </ng-container>

                    <ng-container matColumnDef="LastTradePrice">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>all.ltp</span> </th>
                        <td mat-cell *matCellDef="let element; let i = index" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">
                            <span [class.imnegativebg]="element.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType !== 'noFlash'"> 
                                {{element.LastTradePrice | pricepipe:element.DecimalPrecision}} 
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Change">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"cdkDrag> <span translate>all.change</span> </th>
                        <td mat-cell *matCellDef="let element" [class.imnegative]="element.Change<0 " [class.impositive]="element.Change>0">
                            <span [class.imnegativebg]="element.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType === 'defaultFlash'">{{element.Change | pricepipe:element.DecimalPrecision}} </span></td>
                    </ng-container>
                    <ng-container matColumnDef="PercentageChange">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> % {{'all.change' | translate}} </th>
                        <td mat-cell *matCellDef="let element" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">
                            <span [class.imnegativebg]="element.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType === 'defaultFlash'">{{element.PercentageChange | percentagepipe}}</span></td>
                    </ng-container>
                    <ng-container matColumnDef="OpenPrice">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>all.open</span> </th>
                        <td mat-cell *matCellDef="let element" > {{element.OpenPrice | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="High">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.high</span> </th>
                        <td mat-cell *matCellDef="let element" > {{element.High | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Low">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.low</span> </th>
                        <td mat-cell *matCellDef="let element" > {{element.Low | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="PrvClose">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.close</span> </th>
                        <td mat-cell *matCellDef="let element" > {{element.PrvClose | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Lasttradedquantity">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>all.ltq</span> </th>
                        <td class="ltq-width" mat-cell *matCellDef="let element" > {{element.Lasttradedquantity | numberpipe}} </td>
                    </ng-container>
                    <ng-container matColumnDef="BestBuyPrice">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>all.bid_price</span> </th>
                        <td mat-cell *matCellDef="let element" > {{element.BestBuyPrice | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="BestSellPrice">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.ask_price</span> </th>
                        <td mat-cell *matCellDef="let element" > {{element.BestSellPrice | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="BestBuySize">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.bid_qty</span> </th>
                        <td mat-cell *matCellDef="let element" > {{element.BestBuySize | numberpipe}} </td>
                    </ng-container>
                    <ng-container matColumnDef="BestSellSize">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.ask_qty</span> </th>
                        <td mat-cell *matCellDef="let element" > {{element.BestSellSize | numberpipe}} </td>
                    </ng-container>
                    <ng-container matColumnDef="TradeVolume">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.volume</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.TradeVolume | numberpipe}} </td>
                    </ng-container>
                    <ng-container matColumnDef="VwapAveragePrice">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.atp</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.VwapAveragePrice | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ExpireDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.expir_date</span> </th>
                        <td mat-cell *matCellDef="let element"> {{(element.ExchangeName === 'NSE' || element.ExchangeName === 'BSE') ? '-': element.ExpiryDateDisplayValue | datepipe:'date'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Strike">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.strike</span> </th>
                        <td mat-cell *matCellDef="let element"> {{(element.OptionType === 'CE' || element.OptionType === 'PE') ? (element.StrikePrice | pricepipe:element.DecimalPrecision) : '-'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="OptionType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.option_type</span> </th>
                        <td mat-cell *matCellDef="let element"> {{(element.ExchangeName === 'NSE' || element.ExchangeName === 'BSE' || element.OptionType == 'XX') ? '-' : element.OptionType}} </td>
                    </ng-container>
                    <ng-container matColumnDef="UpCkt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.upper_ckt</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.HCircuitLimit | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="LoCkt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.lower_ckt</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.LCircuitLimit | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Ltt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.ltt</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.LastTradedTime |  datepipe:'datetime'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Tbq">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.total_buy_qty</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.TotalBuy | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Tsq">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.total_sell_qty</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.TotalSell | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Series">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.series</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.InstrumentName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="OI">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.oi</span> </th>
                        <td mat-cell *matCellDef="let element"> {{(element.Openinterest == undefined) ? '-' : element.Openinterest | pricepipe:element.DecimalPrecision}} </td>
                    </ng-container>
                    <ng-container matColumnDef="LotSize">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>all.lot_size</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.BodLotQuantity | numberpipe}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" [ngClass]="{'highlight': selectedRowIndex == i}" (click)="highlight(row, i)" tabindex="999" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)" [attr.id]="'wl_tbl' + i"></tr>
                </table>
            </div>
        </ng-container>
        </ngx-pull-to-refresh>
</div>