<app-titlebar title="Spread Order Detail"></app-titlebar>
<mat-card class="webpanelnew mx-2">
  <mat-card-content>
    <div class="container  nofilter">
        <mat-chip-list>
            <mat-chip  [class.imactive]="selectedLabel === 'orderdetail'" id="tab-header" (click) = "onTabChange('orderdetail')">Order Detail</mat-chip>
            <mat-chip  [class.imactive]="selectedLabel === 'orderhistory'" id="tab-header" (click) = "onTabChange('orderhistory')" translate>History</mat-chip>
        </mat-chip-list>
    </div>
    
    <div *ngIf = "selectedLabel === 'orderdetail'">
      <div class="container myrow smalltext">
        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>Symbol Leg 1</span><span class="graytext">:</span>
              {{orderDetailData.TSym}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>optioncalc.expiry</span><span class="graytext">: </span>
              <span *ngIf="(orderDetailData.ExpiryDate != '--')">{{orderDetailData.ExpiryDate | datepipe:'date'}}</span><span *ngIf="(orderDetailData.ExpiryDate == '--')">--</span>
              </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>detail.action_type</span><span class="graytext">:</span>
              {{orderDetailData.ActionDisplay}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>stock_detail.price</span><span class="graytext">:</span>
              {{orderDetailData.FillPrice | pricepipe: orderDetailData.scripModel.DecimalPrecision }}
              </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>books.avgprice</span><span class="graytext">:</span>
              {{orderDetailData.AvgPrice | pricepipe: orderDetailData.scripModel.DecimalPrecision }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>books.pendingqty</span><span class="graytext">:</span>
              {{orderDetailData.PendingQty}}
              </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>books.filled_qty</span><span class="graytext">:</span>
              {{orderDetailData.FilledShares}}
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>portfolio.qty</span><span class="graytext">:</span>
              {{orderDetailData.FillQty}}
              </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>alert.exchange</span><span class="graytext">:</span>
              {{orderDetailData.Exchange}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>books.exch_orderno</span><span class="graytext">:</span>
              {{orderDetailData.ExchangeOrderNo}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>Symbol Leg 2</span><span class="graytext">:</span>
              {{orderDetailData.TSymLeg2}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>optioncalc.expiry</span><span class="graytext">: </span>
              <span *ngIf="(orderDetailData.ExpiryDateLeg2 != '--')">{{orderDetailData.ExpiryDateLeg2 | datepipe:'date'}}</span><span *ngIf="(orderDetailData.ExpiryDateLeg2 == '--')">--</span>
            </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>detail.action_type</span><span class="graytext ">:</span>
              {{orderDetailData.ActionDisplayLeg2}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>stock_detail.price</span><span class="graytext">:</span>
              {{orderDetailData.FillPriceLeg2 | pricepipe: orderDetailData.scripModel.DecimalPrecision}}
              </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>books.avgprice</span><span class="graytext">:</span>
              {{orderDetailData.AvgPriceLeg2 | pricepipe: orderDetailData.scripModel.DecimalPrecision}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>books.pendingqty</span><span class="graytext">:</span>
              {{orderDetailData.PendingQtyLeg2}}
              </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>books.filled_qty</span><span class="graytext">:</span>
              {{orderDetailData.FilledSharesLeg2}}
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>portfolio.qty</span><span class="graytext">:</span>
              {{orderDetailData.FillQtyLeg2}}
              </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>alert.exchange</span><span class="graytext">:</span>
              {{orderDetailData.Exchange}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>books.exch_orderno</span><span class="graytext">:</span>
              {{orderDetailData.ExchangeOrderNoLeg2}}
            </div>
          </div>
        </div><div class="row">
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>books.product</span><span class="graytext">:</span>
              {{orderDetailData.ProductCode}}
              </div>
          </div>
          <div class="col-6">
            <div class="line-height">
              <span class="graytext" translate>placeorder.pricetype</span><span class="graytext">:</span>
              {{orderDetailData.PriceType}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>spread.odr_updt_time</span><span class="graytext">: </span>
              <span *ngIf="(orderDetailData.exchOrderUpdateTime != '--')">{{orderDetailData.exchOrderUpdateTime | datepipe:'mediumtime'}}</span><span *ngIf="(orderDetailData.exchOrderUpdateTime == '--')">--</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>books.nestorderid</span><span class="graytext">:</span>
              {{orderDetailData.NestOrd}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>login.username</span><span class="graytext">:</span>
              {{orderDetailData.UserId}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>spread.act_id</span><span class="graytext">:</span>
              {{orderDetailData.AccountId}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>settings.status</span><span class="graytext">:</span>
              {{orderDetailData.Status | uppercase}}
            </div>
          </div>
        </div>
        <div class="row" *ngIf="(orderDetailData.Text != null && orderDetailData.Text.Length != 0 && orderDetailData.Text != 'NA')">
          <div class="col-12">
            <div class="line-height">
              <span class="graytext" translate>books.rejection_reason</span><span class="graytext">:</span>
              {{orderDetailData.Text}}
            </div>
          </div>
        </div>
      </div>
    </div>
        
    <div *ngIf = "selectedLabel === 'orderhistory'">
      <app-spreadorderhistory [formOrder] = 'true'></app-spreadorderhistory>
    </div>
  </mat-card-content>
</mat-card>
