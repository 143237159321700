import { ScripModel } from "./scrip.model";

export class SpreadOrderHistoryModel {
    Status: string;
    ExchTimeStamp: any;
    rejectionreason: string;
    Qty: number;
    QtyLeg2: number;
    filledShares: number;
    Prc: string;
    TradingSymbol: any;
    PendingQuantity: any;
    Action: any;
    Type: any;
    FilledSharesQuantity: any;
    FilledSharesQuantityLeg2: any;
    AveragePrice: any;
    AveragePriceLeg2: any;
    ScripModel: ScripModel;
    Token: string;
}
