<app-header goback="1" [title]="'basket.basket_cmp' | translate" [isLoading]="isLoading"></app-header>
<div class="data-container row">
    <div class="col-6" *ngFor='let data of basketData; let i = index;'>
        <mat-card class="col-12">
            <span class="font18">{{(data.name.length>15) ? (data.name | slice:0:12)+'..':(data.name)}}</span>
            <span class="float-right pt-1"><i [ngClass]="{'hide-close-icon':closeButtonHide==true}" class="icon-close"
                    (click)='onClose(data)'></i></span>
            <br><br>

            <span class="disabletext">{{'dashboard.risk' | translate}}:&nbsp;</span><span
                [ngClass]="{'text-green':data.risk_factors =='l', 'text-red': data.risk_factors =='h'}">{{riskMap[data.risk_factors]}}</span>
            <br>
            <span class="disabletext">{{'basket.gain' | translate}}:&nbsp;</span><span>{{data.percent_change  | percent}}</span>
            <br>
            <span
                class="disabletext">{{'dashboard.investment' | translate}}:&nbsp;</span><span>{{investmentPeriodMap[data.investment_period]}}</span>
        </mat-card>
    </div>
    <div *ngFor='let data of itemArray; let i = index;' class="col-6 text-center pt-4">
        <button class="buy-button mat-flat-button" (click)='addBasket()'><span translate>basket.additem</span></button>
    </div>
</div>
<div class="pt-5">
    <div class="font20 uppercase-text text-center" translate>basket.performance</div>
    <div class="pt-2">
        <div class="bc-sectionchart">
            <div *ngIf="!chartLoaded" fxLayout="row" fxLayoutAlign="space-around center" class="spinner-loader"
                id="chartcontainer">
            </div>
            <div *ngIf="chartLoaded" class="chart-height">
                <canvas baseChart [datasets]="chartData" class = "chart-color5" [labels]="chartLabels" [options]="chartOptions" [chartType]="chartType">
                </canvas>
            </div>
            <div style="display: none;" class="chart-color5"></div>
            <div *ngIf="!isLoading && isNodata" class="text-center chart-conatiner-nodata">
                <span class="font18">no_data.NODATA</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf='!isLoading' class="row pb-4">
    <div class="col-6" *ngFor='let data of basketData; let i = index;'>
        <h4 class="text-center">{{data.name}}</h4>
        <mat-card class="col-12">
            <div *ngFor='let item of basketDetailData[data.id]'>
                <div class="text-uppercase font12 text-center">
                    {{(item.company_name.length>22) ? (item.company_name | slice:0:22)+' ...':(item.company_name)}}
                </div>
                <div class="font20 text-center pt-2">{{item.percent_weight}}%</div>
            </div>
        </mat-card>
    </div>
</div>