import { Component } from "@angular/core";
import { IndexModel } from "src/app/model/index.model";
import { Preferences } from "src/app/services/Preferences";
import { TradingRestService } from "src/app/services/tradingrest.service";
import { Utility } from "src/app/services/utility";

@Component({
    selector: 'app-indexspot',
    templateUrl: './indexspot.component.html',
})

export class IndexSpotComponent {
    index: number;
    isLoading: boolean;
    isNoData: boolean;
    indexList: Array<IndexModel>;
    indexExSegArr = [];
    isMobile: boolean;
    tagText: any;
    userid: string;
    spotOne: string;
    spotTwo: string;
    spotThree: string;
    spotFour: string;

    constructor(private tradingRestService: TradingRestService) {
        this.index = Utility.dialogData['index'];
        this.isLoading = true;
        this.userid = Preferences.getPreference('userid');
        this.spotOne = this.userid+'_spot1';
        this.spotTwo = this.userid+'_spot2';
        this.spotThree = this.userid+'_spot3';
        this.spotFour = this.userid+'_spot4';
    }

    ngOnInit() {
        this.isMobile = Utility.IS_MOBILE;
        this.tradingRestService.getIndexService().getIndexExchange(function(data, code, msg) {
            this.isLoading = false;
            if (code === 200) {
                this.indexExSegArr = data['IndexExchange'];
                this.getIndexData(this.indexExSegArr[0]);
            }
            else
                this.isNoData = true;
        }.bind(this));
    }

    getIndexData(data) {
        this.tagText = data;
        this.isLoading = true;
        this.tradingRestService.getIndexService().getIndexDetails(data, this.indexDetailRes.bind(this));
    }

    indexDetailRes(data, code, msg) {
        this.isLoading = false;
        if (code === 200) {
            this.isNoData = ((msg === 'No Data') ? true : false);
            this.indexList = data;
            this.checkSpot();
        }
        else {
            this.isNoData = true;
        }
    }

    setSpotValue(element) {
        const index = Utility.dialogData['index'];
        if(index === 0) {
            Preferences.setPreference(this.spotOne, element.Name +'|'+ element.Exchange);
            Utility.indexCall.next();
        }
        else if(index === 1){
            Preferences.setPreference(this.spotTwo, element.Name +'|'+ element.Exchange);
            Utility.indexCall.next();
        }
        else if(index === 2){
            Preferences.setPreference(this.spotThree, element.Name +'|'+ element.Exchange);
            Utility.indexCall.next();
        }
        else if(index === 3){
            Preferences.setPreference(this.spotFour, element.Name +'|'+ element.Exchange);
            Utility.indexCall.next();
        }
        Utility.dialogRef.close();
    }

    checkSpot() {
        const index = Utility.dialogData['index'];
        this.indexList.map((data: any) => {
            data.disable = false;
            if(index === 0 && Preferences.getPreference(this.spotOne) !== null && data.Name === Preferences.getPreference(this.spotOne).split('|')[0]) {
                data.disable = true;
                return;
            }
            if(index === 1 && Preferences.getPreference(this.spotTwo) !== null && data.Name === Preferences.getPreference(this.spotTwo).split('|')[0]) {
                data.disable = true;
                return;
            }
            if(index === 2 && Preferences.getPreference(this.spotThree) !== null && data.Name === Preferences.getPreference(this.spotThree).split('|')[0]) {
                data.disable = true;
                return;
            }
            if(index === 3 && Preferences.getPreference(this.spotFour) !== null && data.Name === Preferences.getPreference(this.spotFour).split('|')[0]) {
                data.disable = true;
                return;
            }
        });
    }
}