
<div *ngIf="!isNoData" [ngStyle]="Height" class="row" [class.m-0] ="!ismobile">
    <div class="col-6 border-r">
        <table class="table-bottomsheet depthtable">
            <tr class="subcolor2">
                <td> {{'portfolio.qty' | translate}} </td>
                <td class="text-center" translate>stock_detail.order</td>
                <td class="text-right"><span translate>stock_detail.bid</span></td>
            </tr>
            <tr class="impositive">
                <td>{{loadBaiKaiDetailList?.BestBuyQuantity[0] | numberpipe}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestBuyOrders[0] | numberpipe}}</td>
                <td class="text-right" (click) = "depthPrice(loadBaiKaiDetailList?.BestBuyPrice[0])">{{loadBaiKaiDetailList?.BestBuyPrice[0] | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
            <tr class="impositive">
                <td>{{loadBaiKaiDetailList?.BestBuyQuantity[1] | numberpipe}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestBuyOrders[1] | numberpipe}}</td>
                <td class="text-right" (click) = "depthPrice(loadBaiKaiDetailList?.BestBuyPrice[1])">{{loadBaiKaiDetailList?.BestBuyPrice[1] | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
            <tr class="impositive">
                <td>{{loadBaiKaiDetailList?.BestBuyQuantity[2] | numberpipe}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestBuyOrders[2] | numberpipe}}</td>
                <td class="text-right" (click) = "depthPrice(loadBaiKaiDetailList?.BestBuyPrice[2])">{{loadBaiKaiDetailList?.BestBuyPrice[2] | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
            <tr class="impositive">
                <td>{{loadBaiKaiDetailList?.BestBuyQuantity[3] | numberpipe}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestBuyOrders[3] | numberpipe}}</td>
                <td class="text-right" (click) = "depthPrice(loadBaiKaiDetailList?.BestBuyPrice[3])">{{loadBaiKaiDetailList?.BestBuyPrice[3] | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
            <tr class="impositive">
                <td>{{loadBaiKaiDetailList?.BestBuyQuantity[4] | numberpipe}} </td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestBuyOrders[4] | numberpipe}}</td>
                <td class="text-right" (click) = "depthPrice(loadBaiKaiDetailList?.BestBuyPrice[4])">{{loadBaiKaiDetailList?.BestBuyPrice[4] | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
            <tr class="border-tb plainbg">
                <td class="subcolor2 ts-1">({{'stock_detail.total_buy_qty' | translate}})</td>
                <td class="text-right impositive fw-4" colspan="2">{{scripmodel?.TotalBuy | numberpipe}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.lower_ckt' | translate}}:</span> {{scripmodel?.LCircuitLimit | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.volume' | translate}}:</span> {{scripmodel?.TradeVolume | numberpipe}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.ltq' | translate}}:</span> {{scripmodel?.Lasttradedquantity}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder && isDerivativeScrip" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.expiry' | translate}}:</span> {{scripmodel?.ExpiryDateDisplayValue | datepipe: 'date'}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder && !isDerivativeScrip" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.52_week_high' | translate}}:</span> {{scripmodel?.YearlyHighPrice | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
        </table>
    </div>
    <div class="col-6">
        <table class="table-bottomsheet depthtable">
            <tr class="subcolor2">
                <td><span translate>stock_detail.ask</span></td>
                <td class="text-center" translate>stock_detail.order</td>
                <td class="text-right">{{'portfolio.qty' | translate}}</td>
            </tr>
            <tr class="imnegative">
                <td (click) = "depthPrice(loadBaiKaiDetailList?.BestSellPrice[0])">{{loadBaiKaiDetailList?.BestSellPrice[0] | pricepipe: scripmodel?.DecimalPrecision}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestSellOrder[0] | numberpipe}}</td>
                <td class="text-right">{{loadBaiKaiDetailList?.BestSellQuantity[0] | numberpipe}}</td>
            </tr>
            <tr class="imnegative">
                <td (click) = "depthPrice(loadBaiKaiDetailList?.BestSellPrice[1])">{{loadBaiKaiDetailList?.BestSellPrice[1] | pricepipe: scripmodel?.DecimalPrecision}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestSellOrder[1] | numberpipe}}</td>
                <td class="text-right">{{loadBaiKaiDetailList?.BestSellQuantity[1] | numberpipe}}</td>
            </tr>
            <tr class="imnegative">
                <td (click) = "depthPrice(loadBaiKaiDetailList?.BestSellPrice[2])">{{loadBaiKaiDetailList?.BestSellPrice[2] | pricepipe: scripmodel?.DecimalPrecision}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestSellOrder[2] | numberpipe}}</td>
                <td class="text-right">{{loadBaiKaiDetailList?.BestSellQuantity[2] | numberpipe}}</td>
            </tr>
            <tr class="imnegative">
                <td (click) = "depthPrice(loadBaiKaiDetailList?.BestSellPrice[3])">{{loadBaiKaiDetailList?.BestSellPrice[3] | pricepipe: scripmodel?.DecimalPrecision}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestSellOrder[3] | numberpipe}}</td>
                <td class="text-right">{{loadBaiKaiDetailList?.BestSellQuantity[3] | numberpipe}}</td>
            </tr>
            <tr class="imnegative">
                <td (click) = "depthPrice(loadBaiKaiDetailList?.BestSellPrice[4])">{{loadBaiKaiDetailList?.BestSellPrice[4] | pricepipe: scripmodel?.DecimalPrecision}}</td>
                <td class="text-center">{{loadBaiKaiDetailList?.BestSellOrder[4] | numberpipe}}</td>
                <td class="text-right">{{loadBaiKaiDetailList?.BestSellQuantity[4] | numberpipe}}</td>
            </tr>
            <tr class="border-tb plainbg">
                <td class="subcolor2"><span  class="subcolor2">({{'stock_detail.total_sell_qty' | translate}})</span></td>
                <td colspan="2" class="text-right imnegative fw-4">{{scripmodel?.TotalSell | numberpipe}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.upper_ckt' | translate}}:</span>&nbsp; {{scripmodel?.HCircuitLimit | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'basket.average_price' | translate}}:</span>&nbsp;  {{scripmodel?.VwapAveragePrice | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.ltt' | translate}}:</span>&nbsp;  <span class=" ts-0">{{scripmodel?.LastTradedTime | datepipe:'datetime'}}</span></td>
            </tr>
            <tr *ngIf="isPlaceOrder && isDerivativeScrip" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.oi' | translate}}:</span>&nbsp;  {{scripmodel?.Openinterest | numberpipe}}</td>
            </tr>
            <tr *ngIf="isPlaceOrder && !isDerivativeScrip" class="plainbg">
                <td colspan="3"><span class="subcolor2">{{'stock_detail.52_week_low' | translate}}:</span>&nbsp;  {{scripmodel?.YearlyLowPrice | pricepipe: scripmodel?.DecimalPrecision}}</td>
            </tr>
        </table>
    </div>
</div>

<div *ngIf="isNoData">
    <app-nodata message = "No Data"></app-nodata>
</div>