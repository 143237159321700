<app-header (onTitleClick)="onTitleClick()" *ngIf="!IsStockSpecific && !isCustomDashboard" [goback]="goback" [title]="'more.news_lwcase' | translate" [isSearch] = "isSearch" [isLoading]="isLoading" [freetrial] =  "true" (reloadPage) = "reloadData()"></app-header>
<div #sectionNeedToScroll [class.data-container]="!IsStockSpecific && !isCustomDashboard">
    <div class="row news-chips">
        <div [class.col-11] = "!isMobile" [class.col-12] = "isMobile">
            <mat-chip-list *ngIf="!IsStockSpecific">
                <mat-chip [class.imactive]="tagtext == 'All'" (click)="cleartext()">All</mat-chip>
                <mat-chip *ngFor="let data of allTags" (click)="selectionChanged(data)" [class.imactive]="tagtext == data.tag">
                    {{data.tag}}</mat-chip>
            </mat-chip-list>        
        </div>
        <div *ngIf = "!isMobile" class="col-1 text-right">
            <button mat-icon-button (click) = "openSearch(true)" class="mt-2"><i class="icon-search"></i></button>
        </div>
    </div>
    <ngx-pull-to-refresh
    spinnerColor="#ff0000"
    distanceForRefresh="80"
    [isEnable]="isMobile"
    [targetElement]="targetElement"
    (refresh)="myRefreshEvent($event, 'refresh')"
    (loadMore)="alert('loadmore')"
    class = "loader">
        <div *ngIf = "!isNoData" class="row px-sm-2 righmargin" [ngStyle]="Height">
            <div [class.col-12]="numberofcol==1" [class.col-6]="numberofcol==2" [class.col-4]="numberofcol==3" [class.col-3]="numberofcol==4" *ngFor="let news of newsArr; let i = index;" [contextMenu]="basicMenu" [contextMenuSubject]="news">
                <div class="newscardwrapper">
                    <div (click)='openNewTab(news.url, _blank)'>
                        <div *ngIf="news.img !== ''" class="imgwrapper"><img alt="news" [src]="news.img" /></div>
                        <div class="summary">
                            <small>{{news.dt | datepipe:'ISTFromNow'}}</small>
                            <h5 class="block-with-text truncate-overflow" [innerHTML]="news.ttl | truncatetext:50"></h5>
                            <div class="datetime news-src">{{news.src}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ngx-pull-to-refresh>
</div>
<div *ngIf='!isLoading && isNoData'>
    <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
</div>
<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="openNewTab($event.item.url, '_blank')">{{'watchlist.open' | translate}}</ng-template>
    <!-- <ng-template contextMenuItem (execute)="openNewTab($event.item.url, '_blank')">Block</ng-template>
    <ng-template contextMenuItem (execute)="openNewTab($event.item.url, '_blank')">Like</ng-template>
    <ng-template contextMenuItem (execute)="openNewTab($event.item.url, '_blank')">Share</ng-template> -->

</context-menu>