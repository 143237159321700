import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { MyAlertDialogComponent } from '../watchlist.component';
import { MatDialog } from '@angular/material/dialog';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utility } from 'src/app/services/utility';
import { SearchComponent } from '../../search/search.component';
import { TranslateService } from '../../../../../../../node_modules/@ngx-translate/core';
import { environment } from '../../../../../../environments/environment';
import { Preferences, ReturnType } from 'src/app/services/Preferences';

let managewl: ManageWLComponent;
@Component({
  selector: 'app-manage-watchlist',
  templateUrl: './managewatchlist.component.html',
  styleUrls: ['./managewatchlist.component.scss']
})
export class ManageWLComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('addInput', { static: false }) addInput: ElementRef;
  watchlistdata = [];
  isLoader = false;
  selectedWL = '';
  iswatchlistbtn = false;
  newWL = '';
  wlName = '';
  isMobile = true;
  showheader = true;
  @Input() formCustomDB;
  @Output() addStockToNewWL = new EventEmitter<string>();
  isOpenLast = 1;
  @Input() isOpen;
  @Input() isMobileLand;
  constructor(private dialog: MatDialog, private location: Location, private tradingRestService: TradingRestService,
    private router: Router, private snackBar: MatSnackBar, private translate: TranslateService, private cdr: ChangeDetectorRef) {
    managewl = this;
    managewl.wlName = Preferences.getItem('defaultMW');
    managewl.isMobile = Utility.IS_MOBILE;
    managewl.isMobileLand = Utility.IS_MOBILE_LAND;
  }
  ngOnChanges() {
    if (managewl.isOpen !== managewl.isOpenLast){
      managewl.newWL = '';
    }
    this.setWatchList();
    managewl.isOpenLast = managewl.isOpen;
  }
  ngOnInit() {
    if (managewl.formCustomDB) {
        managewl.showheader = false;
    }
    this.setWatchList();
  }

  ngAfterViewInit() {
    managewl.addInput.nativeElement.focus();
    managewl.cdr.detectChanges();
  }

  setWatchList() {
    managewl.iswatchlistbtn = false;
    managewl.wlName = Preferences.getItem('defaultMW');
    managewl.newWL = '';
    if (Preferences.getItem('watchlistname', ReturnType.String) !== 'undefined' && Preferences.getItem('watchlistname', ReturnType.Undefined) !== undefined && Preferences.getItem('watchlistname') !== null) {
      managewl.watchlistdata = Preferences.getItem('watchlistname', ReturnType.Array);
    }

    managewl.tradingRestService.getWatchListService().getAllWatchlist((list, code, msg) => {
      if (code === 200) {
        Preferences.removeItem('watchlistname');
        Preferences.setItem('watchlistname', list['values']);
        managewl.watchlistdata = list['values'];
      }
    }, managewl.setPreWatchList);
  }

  setPreWatchList(data) {
    Preferences.removeItem('watchlistname');
    Preferences.setItem('watchlistname', data['values']);
    managewl.watchlistdata = data['values'];
    if (Preferences.getItem('defaultMW') === 'null') {
      managewl.wlName = managewl.watchlistdata[0];
    }
    else {
      managewl.wlName = Preferences.getItem('defaultMW');
    }
  }
  // defaultWl(watchlistname) {
  //   managewl.selectedWL = watchlistname;
  //   Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { message: managewl.translate.instant('watchlist.set_defaultWL_msg') } });
  //   Utility.dialogRef.afterClosed().subscribe(result => {
  //     if (result === 'confirm') {
  //       managewl.isLoader = true;
  //       managewl.tradingRestService.getWatchListService().setDefaultWL(watchlistname, (list, code, msg) => {
  //         managewl.isLoader = false;
  //         if (code === 200 && msg === 'OK') {
  //           Preferences.setPreference('defaultMW', managewl.selectedWL);
  //           managewl.wlName = Preferences.getPreference('defaultMW');
  //         }
  //       });
  //     }
  //   });
  // }

  deleteWL(watchlistname) {
    managewl.selectedWL = watchlistname;
    managewl.isLoader = true;
    managewl.tradingRestService.getWatchListService().deleteWL(watchlistname, (list, code, msg) => {
      managewl.isLoader = false;
      if (code === 200 && msg === 'OK') {
        managewl.snackBar.open(managewl.translate.instant('all.watchlist_del_msg'), '', { duration: 2000, panelClass: ['sucessSnackBar'] });
        managewl.watchlistdata.splice(managewl.watchlistdata.indexOf(managewl.selectedWL), 1);
        Preferences.setItem('watchlistname', managewl.watchlistdata + '');
        Preferences.setItem('defaultMW', managewl.watchlistdata[managewl.watchlistdata.length - 1]);
        Utility.watchlistDataFromMWL.next(true);
      } else {
        managewl.snackBar.open(managewl.translate.instant('all.del_defaultWL_msg'), '', { duration: 2000, panelClass: ['sucessSnackBar'] });
      }
    });
  }

  inputWL() {
    if (managewl.newWL.length > 0) {
      managewl.iswatchlistbtn = true;
    } else {
      managewl.iswatchlistbtn = false;
    }
  }

  addWatchlist() {
    if (environment.DefaultFeature ? managewl.watchlistdata.length < 5 : true)
    {
      const regex = new RegExp('^[A-Za-z][A-Za-z0-9]*$');
      const wlnameArr = (managewl.watchlistdata.length>0)?managewl.watchlistdata.map(n => n.toLowerCase()):[];
      if (managewl.newWL.indexOf(' ') > -1)
      managewl.snackBar.open(managewl.translate.instant('all.wlname_space'), '', { duration: 3000 });
      else if (!regex.test(managewl.newWL) || managewl.newWL === 'null')
        managewl.snackBar.open(managewl.translate.instant('all.valid_WLname_msg'), '', { duration: 2000 });
      else if (wlnameArr.indexOf(managewl.newWL.toLowerCase()) !== -1) {
        managewl.snackBar.open(managewl.translate.instant('all.same_nameWL_msg'), '', { duration: 2000, panelClass: ['sucessSnackBar'] });
      } else {
        const token_exseg = [];
        Preferences.setItem('stocks', token_exseg);
        if (managewl.isMobile) {
          managewl.router.navigate(['/search'], { queryParams: { wlname: managewl.newWL, isfromMWL: true } });
        } else {
          const data_obj = { wlname: managewl.newWL, isfromMWL: true };
          managewl.addStockToNewWL.emit(JSON.stringify(data_obj));
          // Utility.olddialogRef = Utility.dialogRef;
          // Utility.openPage(managewl.router, 'managewl', 'search', SearchComponent, managewl.dialog, 'popup', data_obj, '580px', '500px', managewl);
        }
      }
    }
    else
      managewl.snackBar.open(managewl.translate.instant('all.max_watchlist'), managewl.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
  }

  back() {
    managewl.location.back();
  }

}
