import { Component, OnInit } from '@angular/core';
import { TradingRestService } from '../../../services/tradingrest.service';
import { Utility } from 'src/app/services/utility';

let logsComponent: LogsComponent;
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html'
})
export class LogsComponent implements OnInit {
  isNoData: boolean;
  isLoading: boolean;
  url = '';
  logs = [];
  isMobile = true;
  constructor(private tradingRestService: TradingRestService) {
    logsComponent = this;
    logsComponent.isMobile = Utility.IS_MOBILE;
  }

  ngOnInit() {
    logsComponent.isLoading = true;
    logsComponent.tradingRestService.getUserService().getUserLogMessage(logsComponent.logResponse);
  }

  logResponse(log, code, message) {
    if (code === 200 && message === 'OK'){
      logsComponent.isLoading = false;
      logsComponent.logs = log['Usermsg'].split('/n');
      logsComponent.isNoData = false;
    }
    else{
      logsComponent.isNoData = true;
    }
  }
}
