<app-header  *ngIf = "isMobile" goback="1" [isLoading]="isLoading" [balanceValue]="balance" [title]="'limits.add_funds' | translate"  [isWithdrawFunds]=false></app-header>
<div  *ngIf = "!isMobile" class="rp-header" id="mat-dialog-title-2;">
    <div class="row">
        <div class="col-6">{{'limits.add_funds' | translate}}</div>
        <div class="col-6 text-right primetext"><small class="subcolor ts-2">{{'limits.avl_margin' | translate}}:</small> {{balance | pricepipe}}</div>
        
    </div>
</div>
<div class = "m-3" [class.data-container]="isMobile" *ngIf="!isPaymentSuccess">
        <div  *ngIf = "!isMobile" class="row pt-3 pb-3">
            <div class="col-sm-4">
                <div class="float-left tc-1"><span class="ts-1 subcolor" translate>limits.account_holder</span><br>{{holderName}}</div>
                <!--span class="ts-1 subcolor">UID:</span><br/>{{uid}}--></div>
            <div class="col-4 col-sm-4"><span class="ts-1 subcolor">{{'login.username' | translate}}</span><br/>{{uid}}</div>
        </div>

        <div *ngIf = "!isMobile" class="row pt-3">
            <div class = "col-12 d-flex">
                <mat-label class="subcolor ts-2 lablepadding">{{'limits.segment' | translate}}</mat-label>
                <mat-chip-list class="nofilter">
                    <mat-chip *ngFor="let obj of enabledSegments; let i = index" (click)="onSegmentSelection(obj)" [class.imactive]="obj === segmentSelected">
                        {{obj}}</mat-chip>
                </mat-chip-list>
            </div>
        </div>
        
        <div *ngIf = "!isMobile && !isPayment" class="row pt-1">
            <div class = "col-sm-12" *ngIf = "!isPayment">
                <mat-form-field>
                    <mat-label>{{'books.amt' | translate}}</mat-label>
                    <input  matInput (keyup)="validatePrice($event)"  autocomplete="off" #inputPrice inputmode="decimal" [(ngModel)]="amount"/>
                </mat-form-field>
            </div>
            </div>
            <div  class="table-global" *ngIf = "isPayment">   
                <table  mat-table [dataSource]="transactionChargesData">
                    <ng-container matColumnDef="key">
                        <td class="text-left" mat-cell *matCellDef="let element" translate>{{element.key}}</td>
                    </ng-container>
                    <ng-container matColumnDef="values">
                        <td class="text-right" mat-cell *matCellDef="let element"><span class = "subcolor2" [class.ts-1]="element.key == 'TransactionCharge' || element.key == 'GST'" [class.ts-3]="element.key == 'Amount' || element.key == 'Total Amount'"> {{element.values | pricepipe: 2}} </span></td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedTableColumns;"></tr>
                </table>
            </div>
            
            <div  *ngIf = "!isMobile" class = "row pt-3">
                <mat-form-field class = "col-12">
                    <mat-label translate>limits.select_bank</mat-label>
                    <mat-select [(value)]="selected" (selectionChange)="onSelection($event.value)">
                       <mat-option *ngFor="let data of bankAccountDisplay" [value]="data.value">
                           {{data.viewValue}}
                       </mat-option>
                   </mat-select>
                 </mat-form-field>
            </div>
        <div *ngIf = "!isMobile && !isPayment" class="row pt-3">
            <div class = "col-sm-6">
                <mat-card *ngIf = "paymentTypeSelected === 'upi'">
                    <mat-form-field>
                        <mat-label translate>limits.upi_id</mat-label>
                        <input autocomplete="off"   #upiField matInput type = "text" [(ngModel)]="upiId" />
                    </mat-form-field>
                </mat-card>
            </div>
        </div>
        <div *ngIf = "isMobile" class = "pt-1">
            <div class = "row">
                <div class = "col-6"><span class="ts-1 subcolor" translate>limits.account_holder</span><br>{{holderName}}</div>
                <div class="col-6 float-right tc-1"><span class="ts-1 subcolor" translate>login.username</span><br>{{uid}}</div>
            </div>
            <div class="row pt-3">
                    <div class="col-12 disp">
                        <mat-label class="subcolor ts-2 lablepadding">{{'limits.segment' | translate}}</mat-label>
                        <mat-chip-list class = "nofilter chippad" [class.noline]="enabledSegments.length < 4">
                            <mat-chip class="chipmargn" *ngFor="let obj of enabledSegments; let i = index" (click)="onSegmentSelection(obj)" [class.imactive]="obj === segmentSelected">
                                {{obj}}</mat-chip>
                        </mat-chip-list>
                    </div>
                    <div class="col-sm-6 col-md-12" *ngIf = "!isPayment">
                        <mat-form-field>
                            <mat-label>{{'books.amt' | translate}}</mat-label>
                            <input (keyup)="validatePrice($event)" matInput  autocomplete="off" inputmode="numeric" #inputPrice [(ngModel)]="amount"/>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6 col-md-12">
                        <mat-form-field>
                            <mat-label translate>limits.select_bank</mat-label>
                            <mat-select [(value)]="selected" (selectionChange)="onSelection($event.value)">
                               <mat-option *ngFor="let data of bankAccountDisplay" [value]="data.value">
                                   {{data.viewValue}}
                               </mat-option>
                           </mat-select>
                         </mat-form-field> 
                    </div>
                </div>
            
            <div class="row">
                        <mat-form-field *ngIf = "paymentTypeSelected === 'upi'">
                            <mat-label translate>limits.upi_id</mat-label>
                            <input matInput #upiField type = "text" autocomplete="off" [(ngModel)]="upiId" />
                        </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class = "col-12 sameer pb-3">
                <span class="subcolor ts-2 mr-2">{{'limits.payment_type' | translate}}</span>
                    <mat-radio-group [(ngModel)]="paymentTypeSelected">
                            <mat-radio-button (change) = "paymentType($event)"value = "netbanking" checked>{{'limits.net_banking' | translate}}</mat-radio-button>
                            <mat-radio-button (change) = "paymentType($event)" value = "upi"><span translate>limits.upi</span></mat-radio-button>
                    </mat-radio-group>
            </div>
        </div>
        <div class="row pt-2 pb-4" *ngIf = "!isPayment">
            <div [class.col-12] = "!isMobile" [class.text-right] = "!isMobile" [class.col-sm-6] = "isMobile">
                <button  id="rzp-button1" [disabled] = "amount === undefined || amount == 0 || amount === '' || disableContinue || (paymentTypeSelected === 'upi' && (upiId.length === 0 || upiId === undefined)) || bankAccountDisplay.length === 0"   (click)="continue()" class="btn-positive" [class.w80] = "!isMobile" [class.w100] = "isMobile"  mat-flat-button>{{'limits.continue' | translate}}</button>
            </div>
        </div>
        <div class="row pt-2 pb-4" *ngIf = "isPayment">
            <div class = "col-sm-6">
                <button  id="rzp-button1" [disabled] = "amount === undefined || amount == 0 || amount === '' || disablePay" (click)="pay()" class="btn-positive w100" mat-flat-button>{{'limits.pay_now' | translate}}</button>
            </div>
            <div class = "col-sm-6">
                <button  mat-flat-button (click)="OnCancel()" >{{('watchlist.cancel' | translate)}}</button>
            </div>
        </div>     
        <div class="row border-t">
            <div class="col-12 notelist">
                <div translate>limits.note</div>
                <ul>
                    <li>{{'limits.note1' | translate}}</li>
                    <li>{{'limits.note2' | translate}}</li>
                    <li>{{'limits.note3' | translate}}</li>
                    <li *ngIf = "transactionCharges?.length > 0"><a class="primelinks" (click)="AnchorClicked()">{{'limits.bnk_charge' | translate}}<div class="d-inline mat-select-arrow"></div></a></li>
                </ul>
            
            </div>
        </div>

        <div class="table-global ts-1" *ngIf = "isExpand">   
            <table  mat-table [dataSource]="transactionCharges">
                <ng-container matColumnDef="Bank Name">
                  <th class = "text-left" mat-header-cell *matHeaderCellDef translate>limits.bank_name</th>
                  <td class = "text-left" mat-cell *matCellDef="let element"> {{(element.bank.length>22 && isMobile)? (element.bank.toUpperCase() | slice:0:22)+'..':(element.bank.toUpperCase())}} </td>
                </ng-container>
                <ng-container matColumnDef="Trans Charge">
                  <th class = "text-right" mat-header-cell *matHeaderCellDef><small>{{(amount > 0) ? ('limits.charge_rs' | translate) : ('limits.charge' | translate) }}</small></th>
                  <td class = "text-right" mat-cell *matCellDef="let element"> {{calculateCharges(element.trns_chrg_type, element.trns_chrg)}} </td>
                </ng-container>
                <ng-container matColumnDef="GST">
                  <th class = "text-right" mat-header-cell *matHeaderCellDef><small>{{(amount > 0) ? ('limits.gst_rs' | translate): ('limits.gst' | translate)}}</small></th>
                  <td class = "text-right" mat-cell *matCellDef="let element"> {{calculateGst(element.trns_gst, element.trns_chrg, element.trns_chrg_type)}} </td>
                </ng-container>
                <ng-container matColumnDef="total">
                    <th class = "text-right" mat-header-cell *matHeaderCellDef><small translate>limits.payable</small></th>
                    <td class = "text-right" mat-cell *matCellDef="let element"> {{payableAmount(element.trns_gst, element.trns_chrg, element.trns_chrg_type)}} </td>
                  </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
</div>

<div  *ngIf="isPaymentSuccess" class="row">
    <div class="col-12">
        <div class="p-2">
            <div class="row text-center mb-3" [class.data-container]="isMobile" >
                <div class="col-12" [class.mt-4] = "isMobile" *ngIf = "paymentStatus === 'Payment Successful'" [class.impositive]="paymentStatus === 'Payment Successful'">
                    <svg class="paysuccess" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="colorg" d="M226,346a19.86,19.86,0,0,0,14.14-5.86l120-120a20,20,0,1,0-28.28-28.28L226,297.72l-45.86-45.86a20,20,0,1,0-28.28,28.28l60,60A19.86,19.86,0,0,0,226,346Z"/>
                        <path class="nocolor" d="M256,20C128.08,20,20,128.08,20,256S128.08,492,256,492,492,383.92,492,256,383.92,20,256,20ZM115,105a10,10,0,1,1-10,10A10,10,0,0,1,115,105ZM405.92,405.93a216.52,216.52,0,0,1-67.72,46,206.9,206.9,0,0,1-164.4,0A218.75,218.75,0,0,1,60,338.2,206.78,206.78,0,0,1,43,256c0-38.45,13.13-78.55,38-115.94a10,10,0,1,1,16.66,11.06C75,185.23,63,221.5,63,256c0,104.62,88.38,193,193,193s193-88.38,193-193S360.62,63,256,63c-34.51,0-70.78,12-104.88,34.64A10,10,0,0,1,140.05,81c37.4-24.85,77.49-38,116-38a206.78,206.78,0,0,1,82.2,17A218.81,218.81,0,0,1,452,173.8a207,207,0,0,1,0,164.4A216.54,216.54,0,0,1,405.92,405.93ZM146,266a20,20,0,0,1,34.14-14.14L226,297.72,331.86,191.86a20,20,0,1,1,28.28,28.28l-120,120a20,20,0,0,1-28.28,0l-60-60A19.86,19.86,0,0,1,146,266Z"/>
                        <path class="colorg" d="M226,346a19.86,19.86,0,0,0,14.14-5.86l120-120a20,20,0,1,0-28.28-28.28L226,297.72l-45.86-45.86a20,20,0,1,0-28.28,28.28l60,60A19.86,19.86,0,0,0,226,346Z"/>
                        <path class="colorg" d="M491.38,157.66a266.09,266.09,0,0,0-137-137,244.81,244.81,0,0,0-196.68,0,266.09,266.09,0,0,0-137,137,244.81,244.81,0,0,0,0,196.68,266.09,266.09,0,0,0,137,137,244.81,244.81,0,0,0,196.68,0,266.09,266.09,0,0,0,137-137,244.81,244.81,0,0,0,0-196.68ZM256,492C128.08,492,20,383.92,20,256S128.08,20,256,20,492,128.08,492,256,383.92,492,256,492Z"/>
                        <path class="colorg" d="M452,173.8A218.81,218.81,0,0,0,338.2,60,206.78,206.78,0,0,0,256,43c-38.46,0-78.55,13.13-115.95,38a10,10,0,0,0,11.07,16.66C185.22,75,221.49,63,256,63c104.62,0,193,88.38,193,193S360.62,449,256,449,63,360.62,63,256c0-34.5,12-70.77,34.63-104.88A10,10,0,1,0,81,140.06C56.13,177.45,43,217.55,43,256a206.78,206.78,0,0,0,17,82.2A218.75,218.75,0,0,0,173.8,452a206.9,206.9,0,0,0,164.4,0A218.75,218.75,0,0,0,452,338.2a207,207,0,0,0,0-164.4Z"/>
                        <circle class="colorg" cx="115" cy="115" r="10"/>
                    </svg>
                    <p>
                        <span class="ts-5 fw-4">{{'placeorder.pay_success' | translate}}</span><br>
                        <span class="subcolor2" translate>placeorder.success_msg</span>
                    </p>
                </div>
                <div class="col-12" [class.mt-4] = "isMobile"  *ngIf = "paymentStatus === 'Payment Unsuccessful'"  [class.imnegative] = "paymentStatus === 'Payment Unsuccessful'" >
                    <svg class="payunsuccess" id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="colorr" d="M186,346a19.86,19.86,0,0,0,14.14-5.86l48.79-48.79a10,10,0,0,1,14.14,0l48.79,48.79a20,20,0,1,0,28.28-28.28l-48.79-48.79a10,10,0,0,1,0-14.14l48.79-48.79a20,20,0,1,0-28.28-28.28l-48.79,48.79a10,10,0,0,1-14.14,0l-48.79-48.79a20,20,0,1,0-28.28,28.28l48.79,48.79a10,10,0,0,1,0,14.14l-48.79,48.79A20,20,0,0,0,186,346Z"/>
                        <path class="colorr" d="M491.38,157.66a266.09,266.09,0,0,0-137-137,244.81,244.81,0,0,0-196.68,0,266.09,266.09,0,0,0-137,137,244.81,244.81,0,0,0,0,196.68,266.09,266.09,0,0,0,137,137,244.81,244.81,0,0,0,196.68,0,266.09,266.09,0,0,0,137-137,244.81,244.81,0,0,0,0-196.68ZM256,492C128.08,492,20,383.92,20,256S128.08,20,256,20,492,128.08,492,256,383.92,492,256,492Z"/>
                        <path class="colorr" d="M452,173.8A218.81,218.81,0,0,0,338.2,60,206.78,206.78,0,0,0,256,43c-38.46,0-78.55,13.13-115.95,38a10,10,0,0,0,11.07,16.66C185.22,75,221.49,63,256,63c104.62,0,193,88.38,193,193S360.62,449,256,449,63,360.62,63,256c0-34.5,12-70.77,34.63-104.88A10,10,0,1,0,81,140.06C56.13,177.45,43,217.55,43,256a206.78,206.78,0,0,0,17,82.2A218.75,218.75,0,0,0,173.8,452a206.9,206.9,0,0,0,164.4,0A218.75,218.75,0,0,0,452,338.2a207,207,0,0,0,0-164.4Z"/>
                        <circle class="colorr" cx="114" cy="115" r="10"/>
                    </svg>
                    <p>
                        <span class="ts-5 fw-4">{{'placeorder.pay_unsuccess' | translate}}</span><br>
                        <span class="subcolor2" translate>placeorder.unsuccess_msg</span>
                    </p>
                </div>
                <div class="col-12" [class.mt-4] = "isMobile"  *ngIf = "paymentStatus === 'Time Out'">
                    <svg class="payprogress" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="colorg" d="M226,346a19.86,19.86,0,0,0,14.14-5.86l120-120a20,20,0,1,0-28.28-28.28L226,297.72l-45.86-45.86a20,20,0,1,0-28.28,28.28l60,60A19.86,19.86,0,0,0,226,346Z"/>
                        <path class="nocolor" d="M256,20C128.08,20,20,128.08,20,256S128.08,492,256,492,492,383.92,492,256,383.92,20,256,20ZM115,105a10,10,0,1,1-10,10A10,10,0,0,1,115,105ZM405.92,405.93a216.52,216.52,0,0,1-67.72,46,206.9,206.9,0,0,1-164.4,0A218.75,218.75,0,0,1,60,338.2,206.78,206.78,0,0,1,43,256c0-38.45,13.13-78.55,38-115.94a10,10,0,1,1,16.66,11.06C75,185.23,63,221.5,63,256c0,104.62,88.38,193,193,193s193-88.38,193-193S360.62,63,256,63c-34.51,0-70.78,12-104.88,34.64A10,10,0,0,1,140.05,81c37.4-24.85,77.49-38,116-38a206.78,206.78,0,0,1,82.2,17A218.81,218.81,0,0,1,452,173.8a207,207,0,0,1,0,164.4A216.54,216.54,0,0,1,405.92,405.93ZM146,266a20,20,0,0,1,34.14-14.14L226,297.72,331.86,191.86a20,20,0,1,1,28.28,28.28l-120,120a20,20,0,0,1-28.28,0l-60-60A19.86,19.86,0,0,1,146,266Z"/>
                        <path class="colorg" d="M226,346a19.86,19.86,0,0,0,14.14-5.86l120-120a20,20,0,1,0-28.28-28.28L226,297.72l-45.86-45.86a20,20,0,1,0-28.28,28.28l60,60A19.86,19.86,0,0,0,226,346Z"/>
                        <path class="colorg" d="M491.38,157.66a266.09,266.09,0,0,0-137-137,244.81,244.81,0,0,0-196.68,0,266.09,266.09,0,0,0-137,137,244.81,244.81,0,0,0,0,196.68,266.09,266.09,0,0,0,137,137,244.81,244.81,0,0,0,196.68,0,266.09,266.09,0,0,0,137-137,244.81,244.81,0,0,0,0-196.68ZM256,492C128.08,492,20,383.92,20,256S128.08,20,256,20,492,128.08,492,256,383.92,492,256,492Z"/>
                        <path class="colorg" d="M452,173.8A218.81,218.81,0,0,0,338.2,60,206.78,206.78,0,0,0,256,43c-38.46,0-78.55,13.13-115.95,38a10,10,0,0,0,11.07,16.66C185.22,75,221.49,63,256,63c104.62,0,193,88.38,193,193S360.62,449,256,449,63,360.62,63,256c0-34.5,12-70.77,34.63-104.88A10,10,0,1,0,81,140.06C56.13,177.45,43,217.55,43,256a206.78,206.78,0,0,0,17,82.2A218.75,218.75,0,0,0,173.8,452a206.9,206.9,0,0,0,164.4,0A218.75,218.75,0,0,0,452,338.2a207,207,0,0,0,0-164.4Z"/>
                        <circle class="colorg" cx="115" cy="115" r="10"/>
                    </svg>
                    <p>
                        <span class="ts-5 fw-4">{{'placeorder.pay_progress' | translate}}</span><br>
                        <span class="subcolor2" translate>placeorder.pay_progress_msg</span>
                    </p>
                </div>
            </div>
            <div  *ngIf = "paymentStatus === 'Payment Successful'" class="row pt-2">
                <div class="col-5 py-2 subcolor2"  translate>placeorder.trans_id</div>
                <div class="col-7 py-2">{{verifiedData.bill_id}}</div> 
            </div>
            <div  *ngIf = "paymentStatus === 'Payment Successful'" class="row">
                <div class="col-5 py-2 subcolor2" translate>placeorder.trans_amt</div>
                <div class="col-7 py-2"> ₹ {{verifiedData.payin_amount/100 | pricepipe:2 }}</div>
            </div>
            <div  *ngIf = "paymentStatus === 'Payment Successful'" class="row">
                <div class="col-5 py-2 subcolor2" translate>{{'limits.transaction' | translate}} {{'limits.charges' | translate}}</div>
                <div class="col-7 py-2"> ₹ {{ verifiedData.trns_charges/100  | pricepipe:2 }} ({{'limits.include_gst' | translate}})</div>
            </div>
            <div  *ngIf = "paymentStatus === 'Payment Successful'" class="row">
                <div class="col-5 py-2 subcolor2" translate>limits.total_amt</div>
                <div class="col-7 py-2"> ₹ {{(verifiedData.trns_charges + verifiedData.payin_amount)/100 | pricepipe:2 }}</div>
            </div>
            <div  *ngIf = "paymentStatus === 'Payment Successful'" class="row">
                <div class="col-5 py-2 subcolor2" translate>limits.remark</div>
                <div class="col-7 py-2">{{verifiedData.remark}} </div>
            </div>
            <div class="row">
                <div class="col-12 py-4 border-t mt-3 text-right">
                    <button (click)="cancelClick()" class="btn-plain mr-2" [class.w80] = "!isMobile" [class.w100] = "isMobile"  mat-flat-button>{{('stock_detail.close' | translate)}}</button>
                    <button (click)="transactionDone()" class="btn-positive mr-2" [class.w80] = "!isMobile" [class.w100] = "isMobile"  mat-flat-button>{{'limits.addmore_funds' | translate}}</button>
                    <button *ngIf = "overlayFrom === 'basketPO'" (click)="enable()" class="btn-positive" [class.w80] = "!isMobile" mat-flat-button>{{'all.goto_po' | translate}}</button>
                </div> 
            </div>
        </div>
    </div>
</div>

    

