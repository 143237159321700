<app-header *ngIf="isMobile" goback="1" [title]="'more.exchange_message' | translate" [isLoading]="isLoading" (reloadPage) = "reloadData()"></app-header>
<mat-card class="webpanelnew">
    <mat-card-content>
        <div [class.pt-0]="!isMobile">
            <div class="row" [class.data-container]="isMobile">
                <div class="col-12">
                    <mat-chip-list>
                        <mat-chip *ngFor="let exchange of exchangeArray" [class.imactive] = "tagText ==  exchange" (click)="getExchStatus(exchange)">{{exchange}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <ngx-pull-to-refresh
            spinnerColor="#ff0000"
            distanceForRefresh="80"
            [isEnable]="isMobile"
            [targetElement]="targetElement"
            (refresh)="myRefreshEvent($event, 'refresh')"
            (loadMore)="alert('loadmore')"
            class = "loader">
                <div [ngStyle]="Height">
                    <ng-container *ngIf="!isLoading && !isNoData" >
                        <div class="p-2">
                            <div *ngFor="let item of exchangeMessageData">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="watchlistwrapper">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="firstline"  [innerHtml]="item.Message"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf='!isLoading && isNoData'>
                        <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
                    </ng-container>
                </div>
            </ngx-pull-to-refresh>
        </div>
    </mat-card-content>
</mat-card>