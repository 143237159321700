import { Component, OnInit, Input, Injector, ChangeDetectorRef, Output, EventEmitter, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChannelType, Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from 'src/app/services/tableutility';
import { ScripModel } from 'src/app/model/scrip.model';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let mv: MultiViewComponent;
const multiViewComponent = {};
let autoGenId = 0;
@Component({
  selector: 'app-multiviewcomponent',
  templateUrl: './multiviewcomponent.component.html',
  styleUrls: ['./multiviewcomponent.component.scss']
})
export class MultiViewComponent extends BaseComponentDirective implements OnInit, OnDestroy {
  omtkn: string;
  exseg: string;
  type: string;
  fromMultiView: boolean;
  symbol: string;
  cname: string;
  showme = false;
  searchId;
  @Output() load = new EventEmitter<boolean>();
  @Output() mvEmit = new EventEmitter<any>();
  
  @Input() isCustomDashboard: boolean;
  @Input() action: string;
  Height: {};
  width;
  container;
  sm: ScripModel;
  titlemap = {'B': 'Place Order', 'S': 'Place Order', 'DEPTH': 'Depth', 'CHART': 'Chart', 'ANALYSIS': 'Analysis', 'KEYRATIO': 'Key Ratio',
               'TECHNICALS': 'Technicals', 'ABOUT': 'About Stock', 'PEER': 'Peer', 'FINANCIALS': 'Financials',  'STOCKNEWS': 'Stock News', 'VISUALTRADE': 'Visual Trading' };
  constructor(elRef: ElementRef, private injector: Injector, private detector: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private tradingRestService: TradingRestService) {
      super(elRef.nativeElement);
    mv = this;
    mv.sm = new ScripModel();
    mv.fromMultiView = true;
    mv.searchId = 'MultiView-' + (autoGenId++);
    multiViewComponent[this.searchId] = this;
  }

  changeMode(){
    multiViewComponent[this.searchId].container = multiViewComponent[this.searchId].container === undefined ? Utility.setSize : multiViewComponent[this.searchId].container;
    mv.container = multiViewComponent[this.searchId].container;
    multiViewComponent[this.searchId].Height = {
      'height': (multiViewComponent[this.searchId].container.height - TableUtility.MVHeight) + 'px',
      'overflow': 'auto'
    };
    multiViewComponent[this.searchId].width = multiViewComponent[this.searchId].container.width;
    multiViewComponent[this.searchId].detector.detectChanges();
  }

  ngOnInit(): void {
    if(multiViewComponent[this.searchId].inputs!=undefined){
      multiViewComponent[this.searchId].isCustomDashboard=multiViewComponent[this.searchId].inputs.isCustomDashboard;
      multiViewComponent[this.searchId].action=multiViewComponent[this.searchId].inputs.action;
      multiViewComponent[this.searchId].type=multiViewComponent[this.searchId].inputs.type;
      multiViewComponent[this.searchId].symbol=multiViewComponent[this.searchId].inputs.symbol;
      if(multiViewComponent[this.searchId].inputs.token){
        multiViewComponent[this.searchId].omtkn=multiViewComponent[this.searchId].inputs.token;  
        multiViewComponent[this.searchId].exseg=multiViewComponent[this.searchId].inputs.exseg;    
      }
    }
    if (Utility.IS_MOBILE) {
      mv.activatedRoute.queryParams.subscribe(params => {
        mv.action = params['buttonType'];
      });
    }
    if (multiViewComponent[this.searchId].omtkn == undefined && Utility.recentlySearchedScrips.length)
    {
      const data = Utility.recentlySearchedScrips[Utility.recentlySearchedScrips.length - 1];
      if (data)
      {
        multiViewComponent[this.searchId].omtkn = data.token;
        multiViewComponent[this.searchId].exseg = data.exseg;
        multiViewComponent[this.searchId].symbol = data.tsym;
        multiViewComponent[this.searchId].cname = data.cname;
        multiViewComponent[this.searchId].sm = Utility.getScripModel(data.token,data.exseg,this.tradingRestService,true);
        multiViewComponent[this.searchId].sm.TradingSymbol = data.tsym;
      }
      else { multiViewComponent[this.searchId].showme = true; }
    }
    else { multiViewComponent[this.searchId].showme = true; }

    if(multiViewComponent[this.searchId].omtkn !== undefined){
      multiViewComponent[this.searchId].showme = false;
      multiViewComponent[this.searchId].sm = Utility.getScripModel(multiViewComponent[this.searchId].omtkn,multiViewComponent[this.searchId].exseg,this.tradingRestService,true);
    }
    if(multiViewComponent[this.searchId].isCustomDashboard)
      this.changeMode();
  }

  ngOnDestroy(){
    Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
  }

  onLoading(isloader) {
    mv.load.emit(isloader);
    mv.detector.detectChanges();
  }

  selectData(value) { 
    mv = multiViewComponent[this.searchId];
    mv.showme = false;
    mv.omtkn = value.omtkn;
    mv.exseg = value.exseg;
    mv.symbol = value.tsym;
    mv.cname = value.cname;
    this.sm = Utility.getScripModel(value.omtkn,value.exseg,this.tradingRestService,true);
    this.sm.TradingSymbol = value.tsym;
    mv.mvEmit.emit({'isCustomDashboard':mv.isCustomDashboard,'action':mv.action,'selectedwork':Utility.selectedWorkspace, 'token':mv.omtkn,'exseg': mv.exseg, 'symbol': mv.symbol, 'type':mv.type });
  }
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    
    multiViewComponent[this.searchId].container={'width': width, 'height':height};
    multiViewComponent[this.searchId].setBasePositionAndSize(left, top, width, height);
    Utility.setSize = multiViewComponent[this.searchId].container;
    multiViewComponent[this.searchId].changeMode();
  }

}
