export class OrderHistoryModel {
    Status: string;
    ExchTimeStamp: any;
    rejectionreason: string;
    Qty: number;
    filledShares: number;
    Prc: string;
    TradingSymbol: any;
    PendingQuantity: any;
    Action: any;
    Type: any;
    FilledSharesQuantity: any;
    AveragePrice: any;
}
