import { Injectable } from '@angular/core';
import { ITRKDChartService } from './itrkdchart.service';
import { ApiService } from '../api.service';
import { Url } from '../url';

@Injectable()
export class TRKDChartService implements ITRKDChartService {
    apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    getTRKDChartData(data, callback: Function) {
        if (data['type'] === 'stock') {
            let message = '';
            return this.apiService.ptpluspost(Url.PTP_STOCK_CHART, { scrip_token: data['symbol'], exchange: data['exch'], days: data['days']}).subscribe(result => {
                if (result.code == 200) {
                    if (result && result.data) {
                        message = 'Ok';
                    }
                    else {
                        message = 'NO DATA';
                    }
                }
                else {
                    message = result.message;
                }
                callback(result.code, result, message);
            }, error => {
                message = error;
                callback('', '', message);
            });
        }
        else {
            let message = '';
            return this.apiService.ptpluspost(Url.PTP_INDEX_CHART, { symbol: data['symbol'], exchange_segment: data['exch'], days: data['days'] }).subscribe(result => {
                if (result.code == 200) {
                    if (result && result.data) {
                        message = 'Ok';
                    }
                    else {
                        message = 'NO DATA';
                    }
                }
                else {
                    message = result.message;
                }
                callback(result.code, result, message);
            }, error => {
                message = error;
                callback('', '', message);
            });
        }
    }
}
