import { ScripModel } from "./scrip.model";

export class BasketDetailModel {
    id: number;
    basket_id: number;
    scrip_token: string;
    exchange_segment: string;
    company_name: string;
    symbol: string;
    percent_weight: number;
    creation_time: string;
    update_time: string;
    status: string;
    transition_type: string;
    stock_type: string;
    version: string;
    basket_user_id: string;
}
