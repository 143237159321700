import { Injectable } from '@angular/core';
import { ILoginService } from './ilogin.service';
import { ApiService } from '../api.service';
import { Utility } from '../utility';
import { Url } from '../url';
import { Keys } from '../keys';
import { environment } from 'src/environments/environment';
import { Preferences } from '../Preferences';
import { TradingRestService } from '../tradingrest.service';

@Injectable()
export class HSLoginService implements ILoginService {
    apiService: ApiService;
    deviceInfoObj;
    tradingRestService: TradingRestService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
        this.deviceInfoObj = JSON.parse(Preferences.getPreference('deviceInfoObj'));
    }
    
    setTradingRestService(tradingRest: TradingRestService) {
        this.tradingRestService = tradingRest;
    }
  
    getInitialKey(callback: Function) {
        callback('', Keys.RES_CODE_SUCCESS, Keys.RES_MSG_OK);
    }

    getPreAuthenticationKey(encryptedPublicKey2, hash_serverPublicKey1, callback: Function) {
        callback('', Keys.RES_CODE_SUCCESS, Keys.RES_MSG_OK);
    }

    updateUserInfo(uid: any, pwd: any, emailId: any, mobileNo: any, callback: Function) {
        let message = '';
        let data: any;
        let code;
        const jsonObj = {
            'uid': uid,
            'pwd':  Utility.getMD5EncryptedData(pwd),
            'emailId': emailId,
            'mobileNo': mobileNo,
            'brokerId': environment.brokerId
        };
        this.apiService.hspost(Url.IAM_UPDATE_USERINFO, jsonObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            message = res.message;
            code = res.code;
            data = res.data;
            callback(data, code, message);
        },
        error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }

    UnblockUser(uid: any, emailId: any, mobileNo: any, pan: any, callback: Function) {
        let message = '';
        let data: any;
        let code;
        const jsonObj = {
            'uid': uid,
            'emailId': emailId,
            'mobileNo': mobileNo,
            'pan': pan,
            'brokerId': environment.brokerId
        };
        this.apiService.iampost(Url.IAM_UNBLOCK_USER, jsonObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200) {
                code = res.code;
                message = res.data;
            }
            else {
                code = res.code;
                message = res.message;
            }
            callback(res, code, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }

    iamLogin(uId: any, password: any, panordob: any, callback: Function) {
        let message = '';
        let code;
        let jsonSendObj = {};
        jsonSendObj = {
            'uid': uId,
            'pwd':  Utility.getMD5EncryptedData(password),
            'brokerId': environment.brokerId,
            'deviceInfo': this.getDevicInfo(uId),
            'source': Utility.getSource(),
            'deviceName': (Utility.IS_MOBILE) ? this.deviceInfoObj.device : this.deviceInfoObj.browser
        };
        this.apiService.iampost(Url.IAM_QUICK_LOGIN, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200) {
                if (res.data.devicempinkey !== null)
                    Preferences.setPreference('devicempinkey', res.data.devicempinkey);
                Preferences.setPreference('userid', uId);
                const userInfo = { 'uid': uId.toUpperCase().trim()};
                Preferences.setPreference('userInfo', JSON.stringify(userInfo));
                Preferences.setPreference('userKey', res.data.sessionKey);
                Utility.requestCode = res.data.requestCode;
                code = res.code;
                message = res.data.message;
            } else if(res.code === 441){
                code = res.code;
                message = res.message.data;
                Preferences.setPreference('userKey', res.message.sessionKey);
            }
            else {
                code = res.code;
                message = res.message;
            }
            callback(res, code, message);
        },
            error => {
                message = error;
                Utility.print(error);
                callback('', code, message);
            }
        );
    }
    ForgotMPIN(uid: any, email: any, pan: any, mob: any, callback: Function){
        let code;
        let message = '';
        let data = '';
        const jsonSendObj = {'uid': uid, 'emailId': email, pan: pan, 'mobileNo': mob, 'brokerId': environment.brokerId};
        this.apiService.iampost(Url.IAM_FORGOT_MPIN, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200){
                data = res.data;
            }
            else{
                data = '';
            }
            message = res.message;
            code = res.code;

            callback(data, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback('', code, message);
        });
    }
    NewPassword(uid: any, password: any, callback: Function) {
        let code;
        let message = '';
        let data = '';
        const jsonSendObj =
        {
            'uid': uid,
            'pwd': Utility.getMD5EncryptedData(password),
            'brokerId': environment.brokerId,
            'deviceInfo': this.getDevicInfo(uid),
            'deviceName': (Utility.IS_MOBILE) ? this.deviceInfoObj.device : this.deviceInfoObj.browser,
            'source': Utility.getSource()
        };
        this.apiService.iampost(Url.IAM_NEWPASS, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200){
                data = res.data;
                Preferences.setPreference('userKey', undefined);
                Preferences.setPreference('userSessionId', res.data.sessionKey);
                if (res.data.preferences)
                    Preferences.setUserData(res.data.preferences, this.tradingRestService);
                else
                    Preferences.setUserData({}, this.tradingRestService);
                message = res.data.status;
            } else{
                data = res.message;
            }
            message = res.status;
            code = res.code;
            callback(data, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback('', code, message);
        });
    }

    setPassword(uid: any, oldpwd: any, newpwd: any, callback: Function) {
        let code;
        let message = '';
        let data = '';
        const jsonSendObj = {
            'uid': uid,
            'oldpwd':  Utility.getMD5EncryptedData(oldpwd),
            'newpwd': Utility.getMD5EncryptedData(newpwd),
            'brokerId': environment.brokerId,
            'deviceInfo': this.getDevicInfo(uid),
            'source': Utility.getSource(),
            'deviceName': (Utility.IS_MOBILE) ? this.deviceInfoObj.device : this.deviceInfoObj.browser
        };
        this.apiService.iampost(Url.IAM_SET_PASSWORD, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            message = res.status;
            code = res.code;
            data = res.data;
            callback(data, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback('', code, message);
        });
    }

    resendOTP(uID: any, requestCode: number, callback: Function) {
        let code;
        let message = '';
        let data = '';
        const jsonSendObj =
        {
            'uid': uID.toUpperCase(),
            'brokerId': environment.brokerId,
            'requestCode': requestCode
        };
        this.apiService.iampost(Url.IAM_RESEND_OTP, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            message = res.status;
            code = res.code;
            data = res.data;
            callback(data, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback('', code, message);
        });
    }

    DeviceInfo(uID:any, callback : Function)
    {
        let code;
        let message = '';
        const jsonSendObj = {
            'uid': uID,
            'devicempinkey': Preferences.getPreference('devicempinkey'),
            'source': Utility.getSource(),
            'bid':environment.brokerId
        };
        this.apiService.iampost(Url.IAM_CHANGE_DEVICE_INFO, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            code = res.code;
            message = res.message;
            callback(res, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            Utility.print(error);
            callback('', code, message);
        });
    }

    ValidateOTP(uid: string, otp: string, requestCode: number, callback: Function){
        let code;
        let message = '';
        let data = '';
        const jsonSendObj =
        {
            'uid': uid.toUpperCase(),
            'otp': otp,
            'requestCode': requestCode,
            'brokerid': environment.brokerId
        };
        this.apiService.iampost(Url.IAM_VALIDATE_OTP, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200){
                Preferences.setPreference('userKey', res.data.accesstoken);
                Preferences.setPreference('devicempinkey', res.data.devicempinkey);
                data = res.data;
            }
            else{
                data = res.message;
            }
            message = res.status;
            code = res.code;

            callback(data, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback('', code, message);
        });
    }


    QuickLogin(uId: any, password: any, panordob: any, callback: Function) {
        let message = '';
        let code;
        let jsonSendObj = {};
        if (environment.isIAM) {
            this.iamLogin(uId.concat(environment.suffixId), password, panordob, callback);
        }
        else {
            jsonSendObj = {
                'usrId': uId.toUpperCase(),
                'pwd': Utility.getSHAEncryptedData(password),
                'panOrDob': Utility.getSHAEncryptedData(panordob),
                'apkVersion': '0', 'imei': '', 'Source': Utility.getSource()
            };
            const request_params = 'jData=' + JSON.stringify(jsonSendObj);
            this.apiService.post(Url.HS_QUICK_LOGIN, request_params).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                if (res['stat'] === 'Ok') {
                    Preferences.setPreference('publicKey4', res['usrTok']);
                    Preferences.setPreference('userSessionId', res['usrSessId']);
                    Preferences.setPreference('userid', uId);
                    code = res.stCode;
                    message = res.comment;
                }
                else {
                    code = res.stCode;
                    message = res.stat;
                }
                callback(res, code, message);
            },
                error => {
                    message = error;
                    Utility.print(error);
                    callback('', code, message);
                }
            );
        }
    }

    DefaultLogin(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        this.apiService.hspost(Url.HS_DEFAULT_LOGIN, '').subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            Utility.isLogin = true;
            Preferences.setPreference('userLogIn', '1');
            let data = '';
            let exArr = [];
            let prodArr = [];
            if (res['stCode'] === 200) {
                data = res;
                if(environment.baseviewpath === 'investor'){
                    if(data['exArr'].includes('NSE')){
                        exArr.push('NSE'); 
                    }
                    if(data['exArr'].includes('BSE')){
                        exArr.push('BSE'); 
                    }
                    if(data['prodArr'].includes('CNC')){
                        prodArr.push('CNC'); 
                    }
                    data['exArr'] = exArr;
                    data['prodArr'] = prodArr;
                }
                Preferences.setPreference('finallogin', 'true');
                Preferences.setPreference('userParameter', JSON.stringify(data));
                Preferences.setPreference('accountId', data['actId']);
                Preferences.setPreference('productAlias', data['prodAli']);
                Preferences.setPreference('brkName', data['brkName']);
                Preferences.setPreference('userExchEnabled', data['exArr']);
                Preferences.setPreference('brnchId', data['brnchId']);
                Preferences.setPreference('actName', data['actName']);
                Preferences.setPreference('nestMode', data['nestMode']);
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                message = res.Emsg;
            }
            callback(res, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            Utility.print(error);
            callback('', code, message);
        });
    }
    Login2FA(uid: any, callback: Function) { }
    ValidPwd(uid: any, pwd: any, callback: Function) { }
    checkvalidAns(uid: any, iaArray: any, callback: Function) { }
    ForgotPassword(uid: any, email: any, mobileNum: any, pan: any, callback: Function) {
        let message = '';
        let code;
        let data;
        let jsonSendObj = {};
        if (environment.isIAM) {
            jsonSendObj = {
                'uid': uid,
                'mobileNo': mobileNum,
                'emailId': email,
                'pan': pan,
                'brokerId': environment.brokerId,
                'deviceInfo': this.getDevicInfo(uid),
                'deviceName': (Utility.IS_MOBILE) ? this.deviceInfoObj.device : this.deviceInfoObj.browser
            };
            this.apiService.iampost(Url.IAM_FORGET_PASSWORD, jsonSendObj).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                if (res.status === 'SUCCESS' && res.code === 200) {
                    Preferences.setPreference('userid', uid);
                    Preferences.setPreference('devicempinkey', res.data.devicempinkey);
                    Utility.requestCode = res.data.requestCode;
                    data = res.data;
                    code = res.code;
                    message = res.data.message;
                }
                else {
                    data = res.status;
                    code = res.code;
                    message = res.message;
                }
                callback(data, code, message);
            },
                error => {
                    message = error;
                    Utility.print(error);
                    callback('', code, message);
                }
            );
        }
    }

    getAllSessions(callback: Function){
       let message = '';
       let code;
       let jsonSendObj = {};

       if (environment.isIAM){
        jsonSendObj = {
            'uid': Preferences.getPreference('userid'),
            'brokerId': environment.brokerId
        };
        this.apiService.iampost(Url.IAM_SESSIONS, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200) {
                code = res.code;
                message = Keys.RES_MSG_OK;
            }
            else {
                code = res.code;
                message = res.status;
            }
            callback(res.data, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            }
        );
        }
    }

    clearAllSessions(callback: Function){
        let message = '';
        let code;
        let jsonSendObj = {};
 
        if (environment.isIAM){
         jsonSendObj = {
             'uid': Preferences.getPreference('userid'),
             'brokerId': environment.brokerId
         };
         this.apiService.iampost(Url.IAM_CLEARSESSIONS, jsonSendObj).subscribe(res => {
             res = JSON.parse(JSON.stringify(res));
             if (res.status === 'SUCCESS' && res.code === 200) {
                 code = res.code;
                 message = Keys.RES_MSG_OK;
             }
             else {
                 code = res.code;
                 message = res.status;
             }
             callback(res.data, code, message);
         },
             error => {
                 code = Keys.RES_CODE_FAILURE;
                 message = error;
                 Utility.print(error);
                 callback('', code, message);
             }
         );
         }
     }

    ForgotUserId(email: any, mobile: any, pan: any, callback: Function) {
        let message = '';
        let code;
        let jsonSendObj = {};
        if (environment.isIAM) {
            jsonSendObj = {
                'mobileNo': mobile,
                'emailId': email,
                'pan': pan,
                'brokerId': environment.brokerId
            };
            this.apiService.iampost(Url.IAM_FORGOT_UID, jsonSendObj).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                if (res.status === 'SUCCESS' && res.code === 200) {
                    code = res.code;
                    message = res.data;
                }
                else {
                    code = res.code;
                    message = res.message;
                }
                callback(res, code, message);
            },
                error => {
                    message = error;
                    Utility.print(error);
                    callback('', code, message);
                }
            );
        }

    }
    DisplayQuestion(callback: Function) { }
    SetImages(callback: Function) { }
    SaveImage(uId: any, imageIndex: any, callback: Function) { }
    SaveAnswers(uId: any, ans: any, callback: Function) { }
    ForgotLoginId(pan: any, email: any, mobile: any, mode: any, callback: Function) { }
    ChangePassword(uiD: any, oldpwd: any, newpwd: any, callback: Function) {
        let message = '';
        let code;
        let jsonSendObj = {};
        if (environment.isIAM) {
            jsonSendObj = {
                'uid': uiD,
                'oldpwd': Utility.getMD5EncryptedData(oldpwd),
                'newpwd': Utility.getMD5EncryptedData(newpwd),
                'brokerId': environment.brokerId,
                'deviceInfo': this.getDevicInfo(uiD)
            };
            this.apiService.iampost(Url.IAM_CHANGE_PASSWORD, jsonSendObj).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                if (res.status === 'SUCCESS' && res.code === 200) {
                    code = res.code;
                    // Preferences.setPreference('userKey', res.data.key);
                    message = res.data.message;
                }
                else {
                    code = res.code;
                    message = res.message;
                }
                callback(res, code, message);
            },
                error => {
                    message = error;
                    Utility.print(error);
                    callback('', code, message);
                }
            );
        }
        else {
            const sOldPwd = Utility.getSHAEncryptedData(oldpwd);
            const sNewPwd = Utility.getSHAEncryptedData(newpwd);
            jsonSendObj = { 'oldPwd': sOldPwd, 'newPwd': sNewPwd };
            const params = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('userSessionId'));
            this.apiService.post(Url.HS_CHANGE_PWD, params).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                if (res['stat'] === 'Ok') {
                    message = res.msg;
                    code = res.stCode;
                }
                else {
                    code = res.stCode;
                    message = res.errMsg;
                }
                callback(res, code, message);
            },
                error => {
                    code = Keys.RES_CODE_FAILURE;
                    message = error;
                    Utility.print(error);
                    callback('', code, message);
                });
        }
    }
    ChangeMpin(oldmpin: any, newmpin: any, callback: Function) {
        let message = '';
        let code;
        let jsonSendObj = {};
        if (environment.isIAM) {
            let uid = Preferences.getPreference('userid');
            jsonSendObj = {
                'uid': uid,
                'oldmpin': Utility.getMD5EncryptedData(oldmpin),
                'newmpin': Utility.getMD5EncryptedData(newmpin),
                'brokerId': environment.brokerId,
                'deviceInfo': this.getDevicInfo(uid),
                'source': Utility.getSource(),
                'deviceName': (Utility.IS_MOBILE) ? this.deviceInfoObj.device : this.deviceInfoObj.browser
            };
            this.apiService.iampost(Url.IAM_MODIFY_MPIN, jsonSendObj).subscribe(res => {
                res = JSON.parse(JSON.stringify(res));
                if (res.status === 'SUCCESS' && res.code === 200) {
                    code = res.code;
                    message = res.data.message;
                }
                else {
                    code = res.code;
                    message = res.message;
                }
                callback(res, code, message);
            },
                error => {
                    message = error;
                    Utility.print(error);
                    callback('', code, message);
                }
            );
        }
    }
    SetMPin(uiD: string, password: string, mpin: string, callback: Function) {
        let code;
        let message = '';
        const jsonSendObj = {
            'uid': uiD,
            'mpin': Utility.getMD5EncryptedData(mpin),
            'brokerId': environment.brokerId,
            'devicempinkey': Preferences.getPreference('devicempinkey'),
            'source': Utility.getSource()
        };
        this.apiService.iampost(Url.IAM_SET_MPIN, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200) {
                message = Keys.RES_MSG_OK;
                code = res.code;
                Preferences.setPreference('userKey', undefined);
                Preferences.setPreference('userSessionId', res.data.sessionKey);
                Preferences.setPreference('userid', uiD.toUpperCase().trim());
                if (res.data.preferences)
                    Preferences.setUserData(res.data.preferences, this.tradingRestService);
                else
                    Preferences.setUserData({}, this.tradingRestService);
            }
            else {
                code = res.code;
                message = res.message;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    ResetUser(callback: Function) {
        let code;
        let message = '';
        this.apiService.hspost(Url.HS_USER_RESET, '').subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.stCode === 200) {
                message = res.stat;
                code = res.stCode;
            }
            else {
                if(res.hasOwnProperty(res.stCode))
                {
                    code = res.stCode;
                    message = res.stat;
                }
                else
                {
                    code = res.code;
                    message = res.message
                }
            }
            callback(res, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback('', code, message);
        });
    }

    MPinLogin(uId: any, mpin: string, callback: Function) {
        let code;
        let message = '';

        const jsonSendObj = {
            'uid': uId,
            'mpin': Utility.getMD5EncryptedData(mpin),
            'brokerId': environment.brokerId,
            'devicempinkey': Preferences.getPreference('devicempinkey'),
            'deviceInfo': this.getDevicInfo(uId),
            'source': Utility.getSource(),
            'deviceName': (Utility.IS_MOBILE) ? this.deviceInfoObj.device : this.deviceInfoObj.browser
        };
        this.apiService.iampost(Url.IAM_MPIN_LOGIN, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200) {
                message = res.data.status;
                code = res.code;
                Preferences.setPreference('userSessionId', res.data.sessionKey);
                Preferences.setPreference('userid', uId.toUpperCase().trim());
                const userInfo = { 'uid': uId.toUpperCase().trim()};
                Preferences.setPreference('userInfo', JSON.stringify(userInfo));
                if (res.data.preferences)
                    Preferences.setUserData(res.data.preferences, this.tradingRestService);
                else
                    Preferences.setUserData({}, this.tradingRestService);
            }else if(res.code === 441){
                code = res.code;
                message = res.message.data;
                Preferences.setPreference('userKey', res.message.sessionKey);
            }
            else {
                code = res.code;
                message = res.message;
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }
    getDevicInfo(uId){
        let key = Preferences.getPreference('deviceInfo')
        if(key==null) {
            key = this.shuffle(uId) + '_' + Date.now()+'_'+Math.floor(Math.random()*10000);
            Preferences.setPreference('deviceInfo', key);
        }
        return key;
    }
    shuffle (str) {
        let a = str.split("");
        let n = a.length;
    
        for(var i = n - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join("");
    }
    verifyLoginOTP(otp: any, callback: Function){
        let message = '';
        let code;
        let jsonSendObj = {};
        let uID = Preferences.getPreference('userid');
        jsonSendObj = {
            'uid': uID,
            'otp':  otp,
            'brokerId': environment.brokerId,
            'source': Utility.getSource(),
            'devicempinkey': Preferences.getPreference('devicempinkey'),
            'deviceInfo': this.getDevicInfo(uID),
            'deviceName': (Utility.IS_MOBILE) ? this.deviceInfoObj.device : this.deviceInfoObj.browser,
            'requestCode': Utility.requestCode
        };
        this.apiService.iampost(Url.IAM_VERIFYOTP, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res.status === 'SUCCESS' && res.code === 200) {
                if(res.data.status && res.data.status === 'Logged in successfully.'){
                    //exits user login case
                    Preferences.setPreference('userSessionId', res.data.sessionKey);
                    code = res.code;
                    message = res.data.status;
                } else if(res.data.action && res.data.action === 101) {
                    // forgot password case
                    message = res.data.message;
                    Preferences.setPreference('userKey', res.data.accesstoken);
                    code = res.code;
                }
            }
            else {
                code = res.code;
                message = res.message;
            }
            callback(res, code, message);
        },
            error => {
                message = error;
                Utility.print(error);
                callback('', code, message);
            }
        );
    }

    resendLoginOTP(callback: Function){
        let code;
        let message = '';
        let data = '';
        let uID = Preferences.getPreference('userid');
        const jsonSendObj =
        {
            'uid': uID.toUpperCase(),
            'brokerId': environment.brokerId,
            'requestCode': Utility.requestCode,
            'deviceInfo': this.getDevicInfo(uID)
        };
        this.apiService.iampost(Url.IAM_RESEND_OTP, jsonSendObj).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            Utility.requestCode = res.data.requestCode;
            Preferences.setPreference('devicempinkey', res.data.devicempinkey);
            message = res.data.message;
            code = res.code;
            data = res.data;
            callback(data, code, message);
        },
        error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback('', code, message);
        });
    }
    
}
