import { Component, OnInit, Output, EventEmitter, Input, Injector, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { LimitModel } from 'src/app/model/limit.model';
import { Utility } from 'src/app/services/utility';
import { Router } from '@angular/router';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from 'src/app/services/tableutility';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ResourceLoader } from '@angular/compiler';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { Preferences } from 'src/app/services/Preferences';
import { EmandateComponent } from '../thematicbasket/emandate/emandate.component';
let limit : LimitComponent;
export interface LimitDataModel {
    key: string;
    values: any;
  }
  declare const moment: any;
@Component({
    selector: 'app-limit',
    templateUrl: '../../'+environment.baseviewpath+'/limit/limit.component.html',
    styleUrls: ['./limit.component.scss']
})


export class LimitComponent extends BaseComponentDirective implements OnInit {
    @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
    @Output() load = new EventEmitter<boolean>();
    @Output() addfunds = new EventEmitter<any>();
    isNoData: boolean;
    isLoading: boolean;
    limitList: Array<LimitModel>;
    AvailableMargin;
    @Input() isCustomDashboard: boolean;
    selectedTab = 'ALL';
    sendJson = {};
    userParameter;
    tagText;
    dataArr = [];
    isMobile: boolean;
    Height: any;
    segmentsData;
    modesub: Subscription;
    container;
    segment = 'EQUITY';
    exchangeDetail = {};
    isDerivative: boolean;
    isEquity: boolean;
    isCurrency: boolean;
    isCommodity: boolean;
    limitData: LimitDataModel[] = [];
    breakupData = [];
    displayedColumns = ['key', 'values'];
    isReload = false;
    displayArray;
    targetElement: Element;
    time;
    payInStartTime: any;
    payInEndTime: any;
    payOutStartTime: any;
    payOutEndTime: any;
    numberofcol = 1;
    cellview: boolean;
    isNoFundData;
    iseMandate = environment.emandate.enable;


    constructor(elRef: ElementRef, private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private injector: Injector, 
        private detector: ChangeDetectorRef, private router: Router, public dialog: MatDialog,private translate: TranslateService) {
        super(elRef.nativeElement);
        limit = this;
        this.userParameter = JSON.parse(Preferences.getPreference('userParameter'));
        this.tagText = 0;
        this.isMobile = Utility.IS_MOBILE;
        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });
        Utility.webReloadComponent.subscribe(data =>
        {
            if(limit.isReload === false && data === 'Funds')
            {
                limit.isReload = true;
                this.getData(); 
            }
        });

        Utility.openWithDraw.subscribe(() => {
            this.openWithdraw();
        });

        this.time = moment().format('HHmm');
    }
    onRefresh() {
        this.getData();
    }
    ngOnInit() {
        if(this.inputs !== undefined)
            this.isCustomDashboard = this.inputs.isCustomDashboard;
        this.targetElement = document.querySelector('html');
        this.getLimits();
        Utility.reloadLimit.subscribe(() => {
           this.getLimits();
        });
    }

    getPaymentDetails(){
        this.tradingRestService.getUserService().getSegmentData((res, code, message) => {

            this.isLoading = false;
            this.load.emit(this.isLoading);
            if (code === 200 && message === 'OK'){
                this.segmentsData = res;
                Utility.SegmentDetails = this.segmentsData;
                this.displayArray = this.segmentsData['segments'];
                this.selectedTab = this.segmentsData['segments'][0];
                this.payInStartTime =  moment(this.segmentsData['payin_cutoff']['start'], "HH:mm").format("HHmm");
                this.payInEndTime =  moment(this.segmentsData['payin_cutoff']['end'], "HH:mm").format("HHmm");
                this.payOutStartTime =  moment(this.segmentsData['payout_cutoff']['start'], "HH:mm").format("HHmm");
                this.payOutEndTime =  moment(this.segmentsData['payout_cutoff']['end'], "HH:mm").format("HHmm");
            }
            else{
                this.snackBar.open(message, '', { duration: 4000, panelClass: ['errorSnackBar'] });
                this.isNoData = true;
            } 
        });
    }

    myRefreshEvent(event: Subject<any>, message: string) {
        setTimeout(() => {
          this.getData();
          event.next();
        }, 1000);
      }
    alert(message: string) {
    }

    onTabChange(tabValue){
        this.selectedTab = tabValue;
    }

    reloadData()
    {
        this.getData();
    }

    getLimits(){
        this.getData();
        if (this.isCustomDashboard) {
            // this.container = <ComponentContainer>this.injector.get('Container');
             this.changeMode();
            /* this.container.on('resize', _ => {
              this.changeMode();
            });*/
          }
        this.isMobile = Utility.IS_MOBILE;
        this.exchangeDetail = this.userParameter.exDtl;
        this.isDerivative = this.exchangeDetail.hasOwnProperty('DERIVATIVE');
        this.isEquity = this.exchangeDetail.hasOwnProperty('EQUITY');
        this.isCurrency = this.exchangeDetail.hasOwnProperty('CURRENCY');
        this.isCommodity =  this.exchangeDetail.hasOwnProperty('COMMODITY');
    }

    changeMode(){
        this.Height = {
          'height': (this.container?.height - TableUtility.limitHeight) + 'px',
          'overflow': 'auto'
          };
          if (this.container?.width < Utility.MinWidth) {
            this.cellview = true;
          }
          else {
            this.cellview = false;
          }
        this.detector.detectChanges();
        if (this.container?.width >= 1200) {
            this.numberofcol = 4;
            } else if (this.container?.width >= 768 && this.container?.width < 1200) {
            this.numberofcol = 3;
            } else if (this.container?.width >= 500 && this.container?.width < 768) {
            this.numberofcol = 2;
            } else {
            this.numberofcol = 1;
            }
      }

    getData() {
        this.isLoading = true;
        this.load.emit(this.isLoading);
        this.sendJson = {
            'uid': Preferences.getPreference('userid'),
            'actid': this.userParameter.sAccountId,
            'segment': this.selectedTab,
            'calcType': 'DIRECT',
            'product': null,
            'seg': null,
            'ex_segment': null,
            'exchange': null
        };
        this.tradingRestService.getUserService().getAllLimits(this.sendJson, function(limitData, code, message) {
            this.isLoading = false;
            this.isReload = false;
            this.load.emit(this.isLoading);
            if (code === 200 && message === 'Ok') {
                this.setPredefinedLimitData(limitData);
            }
            else {
                this.isNoData = true;
            }
        }.bind(this), this.setPredefinedLimitData.bind(this));

        this.getPaymentDetails();
    }

    setPredefinedLimitData(data){
        this.limitList = data[0];
        Utility.payoutamt = this.limitList['PayoutAmt'];
        this.limitData = [
            {key: 'limits.opening_balance',values: this.limitList['OpeningBalance']},
            {key: 'limits.payin', values: this.limitList['PayinAmt']},
            {key: 'limits.payout',values: this.limitList['PayoutAmt']},
            {key: 'limits.collateral_valuation', values: this.limitList['StockValuation']},
            {key: 'limits.direct_collateral', values: this.limitList['DirectCollateralValue']},
            {key: 'limits.total_collateral', values: this.limitList['TotalCollateral']},
            {key: 'all.delivery_margin', values: this.limitList['DeliveryMarginPresent']},
            {key: 'all.options_premium',values: this.limitList['PremiumPrsnt']}
        ];

        this.breakupData = [
            {key: 'limits.span_margin', values: this.limitList['SpanMargin']},
            {key: 'limits.exposure', values: this.limitList['Exposure']},
            {key: 'VAR', values: this.limitList['VAR']},
            {key: 'limits.others',values: this.limitList['Others']} // 0 for VAR add later
        ];

        Utility.limitBalance = this.limitList['AvailableCash'];
        Utility.avl_margin = this.limitList['AvailableMargin'];
    }

    openoverlay(btnindex, bal){
        let balance = bal;
        if (btnindex === 1 && (this.time < this.payInStartTime || this.time > this.payInEndTime)){
            this.snackBar.open(this.translate.instant('limits.transaction_b/w') + this.segmentsData['payin_cutoff']['start'] + "-" + this.segmentsData['payin_cutoff']['end'], '', { duration: 4000, panelClass: ['errorSnackBar'] });
        }
        else if (btnindex === 2 && (this.time < this.payOutStartTime || this.time > this.payOutEndTime)){
            this.snackBar.open(this.translate.instant('limits.transaction_b/w') + this.segmentsData['payout_cutoff']['start'] + "-" + this.segmentsData['payout_cutoff']['end'], '', { duration: 4000, panelClass: ['errorSnackBar'] });
        }
        else if (this.isMobile) {
            let url;
            if (btnindex === 1) {
                url = 'addfunds';
            } else if (btnindex === 2) {
                url = '/withdrawfunds';
            } else {
                url = '/transactiondetails';
            }
            this.router.navigate([url], { queryParams: { bal: balance} });
        } else {
            const data = {'btnindex': btnindex, 'bal': balance};
            this.addfunds.emit(data);
        }
    }

    openWithdraw(){
        let url;
        if (this.isMobile){
            url = '/withdrawfunds';
            this.router.navigate([url], { queryParams: { bal: this.limitList['AvailableCash'] } });
        }
        else{
            const data = {'btnindex': 2, 'bal': parseFloat(this.limitList['AvailableCash'])};
            this.addfunds.emit(data);
        }
        
    }

    onTitleClick() {
        this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        this.cellview = (width < Utility.MinWidth) ? true : false;
        this.changeMode();
      }

    setBaseVisibility(visible: boolean) {
        if(visible){
            this.getData();
            setTimeout(() => {
              this.changeMode();  
            }, 50);
        }
    }

    openeMandate(){
        if(this.isMobile) {
            this.router.navigate(['/emandate']);
        } else {            
            const obj = { height: '60vh', width: '35vw', autoFocus: false};
            Utility.dialogRef = this.dialog.open(EmandateComponent, obj);
        }
    }
}
