import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from 'src/app/services/utility';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';

let scannerComponent: ScannerComponent;

@Component({
    selector: 'app-scanner',
    templateUrl: './scanner.component.html',
    styleUrls: ['./scanner.component.scss']
})

export class ScannerComponent implements OnInit {
    @Output() setScannerType = new EventEmitter<string>();
    @Output() setScannerIcon = new EventEmitter<string>();
    @Output() setScannerDetail = new EventEmitter<string>();
    scannerEquityTypes = [];
    scannerDerivativeTypes = [];
    isMobile = true;
    sType = 'equity';
    dataArr = [];
    constructor(private router: Router, private translate: TranslateService) {
        scannerComponent = this;
        scannerComponent.translate.get('scanners.res_sup').subscribe((translated: string) => {
            scannerComponent.scannerEquityTypes = [
                { 'scannerType': 'Resistance & Support', 't_scannerType': scannerComponent.translate.instant('scanners.res_sup'), 'iconClass': 'icon-resi-support' },
                { 'scannerType': 'Volume Shocker', 't_scannerType': scannerComponent.translate.instant('scanners.vol_shocker'), 'iconClass': 'icon-price-shok' },
                { 'scannerType': 'Circuit Breakers', 't_scannerType': scannerComponent.translate.instant('scanners.circuit_break'), 'iconClass': 'icon-c-breaker' },
                { 'scannerType': 'High/Low Breakers', 't_scannerType': scannerComponent.translate.instant('scanners.HLBreaker'), 'iconClass': 'icon-open-hi-lo2' },
                { 'scannerType': 'Open High/Low', 't_scannerType': scannerComponent.translate.instant('scanners.openHL'), 'iconClass': 'icon-open-hi-lo' },
                { 'scannerType': 'Technical Scanners', 't_scannerType': scannerComponent.translate.instant('scanners.tech_scanner'), 'iconClass': 'icon-tech_scanr' },
            ];

            scannerComponent.scannerDerivativeTypes = [
                { 'scannerType': 'bullish', 't_scannerType': scannerComponent.translate.instant('scanners.bullish_covered_call'), 'iconClass': 'icon-bullish' },
                { 'scannerType': 'neutral', 't_scannerType': scannerComponent.translate.instant('scanners.neutral_long'), 'iconClass': 'icon-neutral' },
                { 'scannerType': 'bearish', 't_scannerType': scannerComponent.translate.instant('derivative.bearish'), 'iconClass': 'icon-bearish' },
                { 'scannerType': 'IV', 't_scannerType': scannerComponent.translate.instant('scanners.IV-scanner'), 'iconClass': 'icon-tech-indi' },
            ];
        });
        this.dataArr = [
            {name: 'Equity Scanner', value: 'equity'},
            {name: 'Derivative Scanner', value: 'derivative'}
        ]
        scannerComponent.isMobile = Utility.IS_MOBILE;
    }
    ngOnInit() {
        if (!Utility.IS_MOBILE) {
            scannerComponent.setScannerDetail.emit(scannerComponent.scannerEquityTypes[0]['scannerType']);
            scannerComponent.setScannerType.emit(scannerComponent.scannerEquityTypes[0]['scannerType']);
            scannerComponent.setScannerIcon.emit(scannerComponent.scannerEquityTypes[0]['iconClass']);
        }
    }
    onTabChange(value) {
        this.sType = value;
    }
    onClick(data) {
        Preferences.setItem('datatype', data.scannerType);
        if (Utility.IS_MOBILE) {
            scannerComponent.router.navigate(['/scannerdetail']);
        } else {
            Preferences.setItem('icontype', data.iconClass);
            Preferences.setItem('scannerdetail', data.scannerType);
            scannerComponent.setScannerDetail.emit(Preferences.getItem('scannerdetail', ReturnType.String));
            scannerComponent.setScannerType.emit(Preferences.getItem('datatype', ReturnType.String));
            scannerComponent.setScannerIcon.emit(Preferences.getItem('icontype', ReturnType.String));
        }
    }
}
