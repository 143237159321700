import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { BasketDetailModel } from 'src/app/model/basketdetail.model';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';

let custombasketdetail: CustomBasketDetail;
@Component({
    selector: 'app-custombasketdetail',
    templateUrl: '../../../../'+environment.baseviewpath+'/thematicbasket/customthematicbasket/custombasketdetail/custombasketdetail.component.html',
    styleUrls: ['./custombasketdetail.component.scss']
})

export class CustomBasketDetail implements OnChanges, OnInit {
    @Input() basketdata: any;
    @Input() isCustomDashboard: boolean;
    isMobile: boolean;
    basketId;
    basketSetting: string;
    isNodata: boolean;
    isLoading: boolean;
    basketDetailList: Array<BasketDetailModel>;
    total = 0;
    symbol = '';
    displayColumns: string[] = ['symbol', 'weight'];
    constructor(private PS: PtPlusRestService) {
        custombasketdetail = this;
        custombasketdetail.isMobile = Utility.IS_MOBILE;
    }

    ngOnChanges() {
        custombasketdetail.basketSetting = custombasketdetail.basketdata.basket_setting;
        custombasketdetail.basketId = custombasketdetail.basketdata.id;
        custombasketdetail.getBasketDetailData(custombasketdetail.basketId);
    }
    ngOnInit() {
        if (custombasketdetail.isCustomDashboard) {
            custombasketdetail.basketSetting = custombasketdetail.basketdata.basket_setting;
            custombasketdetail.basketId = custombasketdetail.basketdata.id;
            custombasketdetail.getBasketDetailData(custombasketdetail.basketId);
        }
    }

    getBasketDetailData(basketId) {
        custombasketdetail.isLoading = true;
        custombasketdetail.PS.getThematicBasketService().getBasketDetailData(basketId, custombasketdetail.basketDetailResponse);
    }

    basketDetailResponse(responseData, code, message) {
        custombasketdetail.isLoading = false;
        custombasketdetail.isNodata = false;
        const stockSymbol = [];
        const scrip_token_arr = [];
        const exchange_segment_arr = [];
        if (code === 200 && message === 'SUCCESS') {
            custombasketdetail.basketDetailList = responseData;
            custombasketdetail.basketDetailList.forEach(element => {
                element['exchange'] = Utility.segmentToExchange(element['exchange_segment']);
                custombasketdetail.total += element['percent_weight'];
                custombasketdetail.symbol = element.symbol.split('-')[0];
                stockSymbol.push(custombasketdetail.symbol);
                scrip_token_arr.push(element['scrip_token']);
                exchange_segment_arr.push(element['exchange_segment']);
            });

            if (custombasketdetail.basketSetting === 'proportional') {
                custombasketdetail.basketDetailList.forEach(element => {
                    element['percent_weight'] = Number(((element['percent_weight'] / custombasketdetail.total) * 100).toFixed(2));
                });
            }
        }
        else {
            custombasketdetail.isNodata = true;
        }
    }
}
