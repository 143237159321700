<app-header *ngIf="isMobile" goback="1" [title]="'header.change_question' | translate" [isLoading]="isLoading"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'header.change_question' | translate" [isLoading]="isLoading"></app-titlebar>
<div [class.contarea]="!isMobile" class="col-12">
	<div [class.data-container]="isMobile" class="row">    
		<div [class.p-2]="!isMobile" class="col-12 pt-3">
				<div class ="question-height">
					<mat-form-field class = "w-100">
						<mat-select [placeholder]="'settings.select_quest' | translate" (selectionChange)="onqSelection($event)" [(value)]="selectedQuestion">
							<mat-option *ngFor="let item of login2FDetails" [value]="item">
								{{item.question}}
							</mat-option>
						</mat-select>
					</mat-form-field> 
					<div class = "mb-3" *ngFor="let item of selectedQuestionList; let i = index">
						<mat-form-field class = "w-100">
								<input  class = "col-8" [type]="hideQuestion ? 'password' : 'text'" matInput [placeholder]="item.question" [(ngModel)]="item.ans">
								<span  (click)="hideQuestion = !hideQuestion" class="overlap-icons"><i [class.icon-visibility-off]="hideQuestion" [class.icon-visibility-on]="!hideQuestion"></i></span>
								<button mat-flat-button color="primary" type="submit"  (click) = "deleteQuestions(item)">close</button>
								<mat-error *ngIf="panError"><span translate>* settings.answer</span></mat-error>
						</mat-form-field>
					</div>
					<div class="nodata" *ngIf = "selectedQuestionList.length<=0" translate>settings.no_question</div>
				</div>
		</div>
	</div>
</div>
<div class="popup-footer col-12" *ngIf="!isMobile">
	<div class="row p-2 pr-0">
		<div class="col-6"></div>
		<div class="col-6 text-right">
         <button mat-flat-button class = "custom-btn" color="primary" type="submit" (click) = "submit()"><span translate>alert.save</span></button>
      </div>
		
	</div>
</div>
<div class="col-12 button-footer" *ngIf="isMobile">
  <div class="row">
       <button mat-flat-button class = "custom-btn w-100" color="primary" type="submit" (click) = "submit()"><span translate>alert.save</span></button>
  </div>
</div>