import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { OrderModel } from 'src/app/model/order.model';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MyAlertDialogComponent } from '../../home/watchlist/watchlist.component';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ChannelType, Utility } from 'src/app/services/utility';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-ordermoreoption',
    templateUrl: '../../../'+environment.baseviewpath+'/orderbook/ordermoreoption/ordermoreoption.component.html',
    styleUrls: []
})

export class OrderMoreOptionComponent {
    orderDetailData;
    fillQuantity;
    orderModel: OrderModel;
    isExit: boolean;
    isCancel: boolean;
    alertMessage = '';
    WlArr = [];
    flashType = 'defaultFlash';
    subscription;
    tabIndex = 0;
    constructor(private _bottomSheetRef: MatBottomSheetRef<OrderMoreOptionComponent>, private dialog: MatDialog, private router: Router, private tradingRestService: TradingRestService,
        private translate: TranslateService, private snackBar: MatSnackBar, private detector: ChangeDetectorRef, private zone: NgZone) {
        this.orderDetailData = Utility.detailedOrder;
        this.orderModel = this.orderDetailData;
        this.fillQuantity = this.orderDetailData['TotalQty'] - this.orderDetailData['PendingQty'];
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
            this.flashType = Preferences.getItem('flashType');
          }
        Utility.flashType.subscribe(obj => {
            this.flashType = obj;
        });

        this.subscription = this.orderDetailData.scripModel.scripUpdate.subscribe(ele =>{
            this.detector.markForCheck();
        });
    }

    openLink(): void {
        this._bottomSheetRef.dismiss();
    }
    addWLdata(stock) {
        const seltctedStock = stock.ExchangeSegment + '|' + stock.Token;
        this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
    }
    addStock(wl, stockdata) {
        const seltctedStock = stockdata.ExchangeSegment + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
          if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
      }

    handleButton(buttonType) {
        const addobj = { 'action': 'B', 'exseg': this.orderDetailData.ExchangeSegment, 'omtkn': this.orderDetailData.Token};
        const removeobj = { 'action': 'S', 'exseg': this.orderDetailData.ExchangeSegment, 'omtkn': this.orderDetailData.Token};
        switch (buttonType) {
            case 'E':
                this.alertMessage = this.translate.instant('all.exit_ordermsg');;
                Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.exit_hed'), message: this.alertMessage } });
                Utility.dialogRef.afterClosed().subscribe(result => {
                    if (result === 'confirm') {
                        this.tradingRestService.getBookService().ExitOrder(this.orderModel, function(responsedata, code, message) {
                            if (responsedata['stat'] === 'Ok') {
                                this.openLink();
                                this._bottomSheetRef.dismiss({isCancel: true});
                                this.snackBar.open(this.translate.instant('all.order_exited'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['successSnackBar'] });
                            }
                            else
                                this.snackBar.open(this.translate.instant('all.order_exit_failed'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
                        }.bind(this));
                    }
                });
                break;
            case 'C':
                this.alertMessage = this.translate.instant('all.cancel_ordermsg');
                Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.cancel_hed'), message: this.alertMessage, fromOrder: true } });
                Utility.dialogRef.afterClosed().subscribe(result => {
                    if (result === 'confirm') {
                        this.tradingRestService.getBookService().CancelOrder(this.orderModel, function(responsedata, code, message) {
                            if (responsedata['stat'] === 'Ok') {
                                this._bottomSheetRef.dismiss({isCancel: true});
                                this.snackBar.open(this.translate.instant('all.order_cancelled'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['successSnackBar'] });
                            }
                            else
                            this.snackBar.open(this.translate.instant('all.order_failed'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
                        }.bind(this));
                    }
                });
                break;
            case 'M':
                this.router.navigate(['placeorder'], { queryParams: { 'isEdit': true } });
                this.openLink();
                break;
            case 'H':
                this.router.navigate(['/orderhistory']);
                this.openLink();
                break;
            case 'ADD':
                this.router.navigate(['placeorder'], { queryParams: addobj });
                this.openLink();
                break;
            case 'REMOVE':
                this.router.navigate(['placeorder'], { queryParams: removeobj });
                this.openLink();
                break;
            case 'sd':
                Utility.openStockDetail(this.router, this.orderDetailData.Token, this.orderDetailData.ExchangeSegment);
                this.openLink();
                break;
            case 'reorder':
                const removeobj1 = {'remove': true,  'Exchange': this.orderDetailData.Exchange, 'Token': this.orderDetailData.Token, 'qty': this.orderDetailData.TotalQty, 'OrderType': this.orderDetailData.OrderType, 'ProductCode': this.orderDetailData.ProductCode, 'Ret': this.orderDetailData.Validity};
                const addobj1 = {'add': true,  'Exchange': this.orderDetailData.Exchange, 'Token': this.orderDetailData.Token, 'qty': this.orderDetailData.TotalQty, 'OrderType': this.orderDetailData.OrderType, 'ProductCode': this.orderDetailData.ProductCode, 'Ret': this.orderDetailData.Validity};
                const data_obj1 = (this.orderDetailData.Action == 'B') ? addobj1 : removeobj1;
                this._bottomSheetRef.dismiss();
                this.router.navigate(['placeorder'], { queryParams: data_obj1 });
            break;
        }
    }

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onSwipeDown() {
        this._bottomSheetRef.dismiss();
    }

    onTabChanged(index) {
        this.zone.run(() => {
            this.tabIndex = index;
        });
    }
}
