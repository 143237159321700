import { Injectable } from '@angular/core';
import { IWatchlistService } from './iwatchlist.service';
import { TradingRestService } from '../tradingrest.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { ChannelType, Utility } from '../utility';
import { Url } from '../url';
import { Preferences } from '../Preferences';

let hsw: HSWatchlistService;
// let Utility.watchlistDataMap = Utility.watchlistDataMap;
@Injectable()
export class HSWatchlistService implements IWatchlistService {
   apiService: ApiService;
   tradingRestService: TradingRestService;
   watchlistMap = {};
   userId;
   constructor(apiService: ApiService) {
      this.apiService = apiService;
      hsw = this;
      this.watchlistMap = Preferences.getPreference('watchlistDataMap') === null ? {}:  JSON.parse(Preferences.getPreference('watchlistDataMap'));
   }

   // quick/GetAllMW
   getAllWatchlist(callback: Function, precallback?: Function) {
      let message = '';
      let code;
      const watchList = {};
      const key = Url.HS_WATCH_LIST + '_' + Preferences.getPreference('userid');
      const predata = Utility.CACHE_STORE.getCacheData(key);
      // if (predata !== undefined) {
      //    precallback(predata);
      // }
      const req = { 'userid': Preferences.getPreference('userid') };
      this.apiService.ptpluspost(Url.HS_WATCH_LIST, req).subscribe(res => {
         if ( res.status === 300 ){
            message = res.msg;
            code = res.status;
         }
         else {
            if (res.status === 'SUCCESS') {
               if (res['code'] === 200 && res.data.length > 0) {
                  hsw.createWatchlistMap(res.data);
                  const arr = [];
                  res.data.forEach(element => {
                     arr.push(element.mwName);
                     watchList['values'] = arr;
                     Utility.CACHE_STORE.setCacheData(key, watchList);
                  });
                  message = Keys.RES_MSG_OK;
                  code = Keys.RES_CODE_SUCCESS;
               } else {
                  message = Keys.NO_DATA;
                  code = Keys.RES_CODE_FAILURE;
               }
            }
            else {
               message = res.errMsg;
               code = Keys.RES_CODE_FAILURE;
            }
         }
         callback(watchList, code, message);
      },
         error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(watchList, code, message);
         });
   }

   // quick/GetMW
   getScriptDetailforWL(mrvData: string, MWname: string, callback: Function, precallback?: Function) {
      let message = '';
      const code = Keys.RES_CODE_SUCCESS;
      const watchList = [];
      const token_exseg = [];
      let scripList;
      if (Utility.watchlistDataMap.get(MWname))
         scripList = Utility.watchlistDataMap.get(MWname).split(',');
      const key = Url.HS_SCRIP_INFO + '_' + MWname + Preferences.getPreference('userid');
      const predata = Utility.CACHE_STORE.getCacheData(key);
      if (predata !== undefined) {
         precallback(predata);
      }
      for (let i = 0; i < scripList.length; i++)
      {
         const dt = scripList[i].split('|');
         const sm = Utility.getScripModel(dt[1], dt[0], hsw.tradingRestService, true, ChannelType.Watchlist);
         watchList.push(sm);
         token_exseg.push(dt[1] + '_' + dt[0]);
      }
      Utility.CACHE_STORE.setCacheData(key, watchList);
      Preferences.setItem('stocks', token_exseg);
      message = 'OK';
      callback(watchList, code, message);
      hsw.tradingRestService.getScripService().getMultipleScrips({ 'scripList': Utility.watchlistDataMap.get(MWname).toString() }, (positiondata, resCode, resMessage) => {
         // do something
      });
   }

   // quick/UpdateMW
   addScripToWL(scripData: string, MWname: string, callback: Function) {
      let message = '';
      let code;
      let watchList = {};
      scripData = hsw.changeExchange(scripData);
      const obj = Utility.watchlistDataMap.get(MWname) ? Utility.watchlistDataMap.get(MWname) + ',' + scripData : scripData;
      const jsonSendObj = { 'mwName': MWname, 'scrips': obj, 'userid': Preferences.getPreference('userid')};
      this.apiService.ptpluspost(Url.HS_UPDATE_MARKETWATCH, jsonSendObj).subscribe(res => {
         if (res['status'] === 'SUCCESS' && res['code'] === 200) {
            Utility.watchlistDataMap.set(MWname, obj);
            this.watchlistMap[MWname] = obj;
            Preferences.setPreference("watchlistDataMap", JSON.stringify(this.watchlistMap));
            Preferences.setPreference
            watchList = res;
            message = Keys.RES_MSG_OK;
            code = Keys.RES_CODE_SUCCESS;
         } else {
            message = res.errMsg;
            code = Keys.RES_CODE_FAILURE;
         }
         callback(watchList, code, message);
      },
         error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(watchList, code, message);
         });
   }

   deleteScripToWL(mWString: any, MWname: string, callback: Function) {
      let message = '';
      let code;
      let watchList = {};
      const obj = hsw.removeScrip(mWString, MWname);
      const jsonSendObj = { 'mwName': MWname, 'scrips': obj, 'userid': Preferences.getPreference('userid')};
      this.apiService.ptpluspost(Url.HS_UPDATE_MARKETWATCH, jsonSendObj).subscribe(res => {
         if (res['status'] === 'SUCCESS' && res['code'] === 200) {
            Utility.watchlistDataMap.set(MWname, obj);
            this.watchlistMap[MWname] = obj;
            Preferences.setPreference("watchlistDataMap", JSON.stringify(this.watchlistMap));
            watchList = res;
            message = Keys.RES_MSG_OK;
            code = Keys.RES_CODE_SUCCESS;
         } else {
            message = res.errMsg;
            code = Keys.RES_CODE_FAILURE;
         }
         callback(watchList, code, message);
      },
         error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(watchList, code, message);
         });
   }

   deleteWL(MWName: string, callback: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      let watchList = {};
      const jsonSendObj = { 'mwName': MWName, 'userid': Preferences.getPreference('userid') };
      this.apiService.ptpluspost(Url.HS_DELETE_WATCH_LIST, jsonSendObj).subscribe(res => {
         const stat = res['status'];
         if (stat === 'SUCCESS') {
            Utility.watchlistDataMap.delete(res['mwName']);
            delete this.watchlistMap[res['mwName']];
            Preferences.setPreference("watchlistDataMap", JSON.stringify(this.watchlistMap)); 
            watchList = res;
            message = 'OK';
         } else {
            message = res['errMsg'];
            code = Keys.RES_CODE_FAILURE;
            if (message === Keys.NO_DATA) {
               code = Keys.RES_CODE_SUCCESS;
            }
         }
         callback(watchList, code, message);
      },
         error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(watchList, code, message);
         });
   }

   // quick/SetDefaultMW
   setDefaultWL(MWName: string, callback: Function) {
      let message = '';
      let code;
      const jsonSendObj = { 'mwName': MWName };
      const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('userSessionId'));
      this.apiService.hspost(Url.HS_SET_DEFAULT_MARKETWATCH, param).subscribe(res => {
         if (res['stat'] === 'Ok' && res['stCode'] === 200) {
            message = Keys.RES_MSG_OK;
            code = Keys.RES_CODE_SUCCESS;
         } else {
            message = Keys.NO_DATA;
            code = Keys.RES_CODE_FAILURE;
         }
         callback('', code, message);
      },
         error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback('', code, message);
         });
   }

   setTradingRestService(tradingRest: TradingRestService) {
      hsw.tradingRestService = tradingRest;
   }

   createWatchlistMap(data) {
      for (let i = 0; i < data.length; i++) {
         Utility.watchlistDataMap.set(data[i].mwName, data[i].scrips);
         this.watchlistMap[data[i].mwName] = data[i].scrips;
      }
      Preferences.setPreference("watchlistDataMap", JSON.stringify(this.watchlistMap));   
   }

   removeScrip(scrip, MWname) {
      const arr = Utility.watchlistDataMap.get(MWname).split(',');
      const scriparr = scrip.split(',');
      for (let i = 0; i < scriparr.length; i++) {
         const index = arr.indexOf(scriparr[i]);
         if (index !== -1) {
            arr.splice(index, 1);
         }
      }
      return arr.join();
   }

   changeExchange(str) {
      const arr = str.split('|');
      arr[0] = Utility.exchangeToSegment(arr[0]);
      return arr[0] + '|' + arr[1];
   }

   getPredefinedWatchlistData(callback: Function) {
      let message = '';
      return this.apiService.ptpluspost(Url.PREDEFINED_WATCHLIST, {}).subscribe(result => {
          hsw.createWatchlistMap(result.data);
          let arr = [];
          if (result.code == 200) {
              result.data.forEach(element => {
                  arr.push(element.mwName);
               });
          }
          else {
              message = result.message;
          }
          callback(result.code, arr, message);
      }, error => {
          message = error;
          callback('', '', message);
      });
  }
}
