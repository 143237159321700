<app-titlebar *ngIf="!isMobile&& showheader" [title]="'all.manage_watchlist' | translate"></app-titlebar>
<mat-progress-bar *ngIf="isLoader" style="position: fixed;left: 0; top:0;right:0;z-index:1100" mode="indeterminate"></mat-progress-bar>
<div class="header" *ngIf="isMobile && !isMobileLand">
    <div class="row">
      <div class="col-12 search-container placeorderfield">
          <button *ngIf="!isMobileLand" class="search-left" mat-icon-button (click)="back()"><i class="icon-goback"></i></button>
          <div  [class.pl-4]="isMobileLand"  class="search-right">
            <mat-form-field class="w-100">
              <input autofocus #addInput type="search" (input)="inputWL()"  [(ngModel)]="newWL" [placeholder]="'all.new_watchlist_name' | translate"  aria-label="+ New Watchlist Name" matInput autocomplete="off" class="p-1" [disabled] = "watchlistdata.length>4">
            </mat-form-field>
          </div>
      </div>
    </div>  
</div>
<mat-card class="webpanelnew" [class.mx-2]="!isMobile">
  <mat-card-content [class.mb-5]="isMobileLand">
    <div class="container">

    <div class="row" *ngIf="!isMobile || isMobileLand">
      <div class="col-9 search-container placeorderfield">
        <div class="search-right">
          <mat-form-field class="w-100">
            <input autofocus #addInput type="search" (input)="inputWL()"  [(ngModel)]="newWL" [placeholder]="'all.new_watchlist_name' | translate"  aria-label="+ New Watchlist Name" matInput autocomplete="off" class="p-1" [disabled] = "watchlistdata.length>4">
          </mat-form-field>
        </div>
      </div>
      <div class="col-3 pt-3 text-right">
        <button [disabled] = "!iswatchlistbtn"  mat-flat-button class = "btn-primary" (click) = "addWatchlist()"><span translate>all.create</span></button>
      </div>
    </div> 
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 py-4" [class.pl-0]="isMobileLand"><small class="subcolor2" translate>all.other_watchlist</small></div>
        </div>
          <ng-container *ngFor="let watchlist of watchlistdata; let i = index">
            <div class="watchlistwrapper p-0">
                <div class="row">
                  <div class="col-8 linebutton" [class.pl-0]="isMobileLand">{{watchlist}}</div>
                  <div class="col-4 text-right">
                    <button matTooltip="{{'all.delete_watchlist' | translate}}" *ngIf="watchlistdata.length > 1" (click)="deleteWL(watchlist)" mat-icon-button><i class="icon-delete"></i></button>
                    <!--button (click) = "defaultWl(watchlist)" mat-icon-button><i [class.primetext]="wlName === watchlist" [class.appdisabletext]="wlName !== watchlist" [class.appopacity50]="wlName !== watchlist" class="icon-check"></i></button-->
                  </div>
                </div>
              </div>
          </ng-container>
      </div>
      <div class="row">
          <div class="col-12 py-4">
            <small class="subcolor2" translate>all.max_watchlist</small>
          </div>
      </div>
    </div>
  </div>

  </mat-card-content>
</mat-card>
<mat-dialog-actions class="mat-dialog-actions d-block">
  <div class="container" *ngIf="isMobile && !isMobileLand">
  <div class="row">
      <div class="col-12 pt-2 text-right button-footer">
          <button [disabled] = "!iswatchlistbtn"  mat-flat-button class = "btn-primary w-100" (click) = "addWatchlist()"><span translate>all.create</span></button>
      </div>
  </div>
  <!-- <div class="col-12 pt-2 text-right button-footer">
    <button [disabled] = "!iswatchlistbtn"  mat-flat-button [class.w-100] = "isMobile" class = "btn-primary" (click) = "addWatchlist()"><span translate>watchlist.create</span></button>
</div> -->
</div>
</mat-dialog-actions>