<mat-card class="webpanelnew">
    <mat-card-content>
        <div [class.pt-0]="!isMobile">
            <div class="row">
                <div class="col-12">
                    <div class="panelsubheader">
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field>
                                    <mat-select (selectionChange)="onExchangeSelection($event.value)" [(value)]="selectedExch">
                                        <mat-option *ngFor="let exch of  exchArr" [value]="exch">
                                            {{exch}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field>
                                    <mat-select (selectionChange)="onIndexSelection($event.value)" [(value)]="selectedIndex">
                                        <mat-option *ngFor="let index of indexArr" [value]="index.symbol">
                                            {{index.symbol}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pt-3 d-flex justify-content-between pb-1">
                    <div *ngFor="let item of marketMoodData" class="pd text-center" >
                        <small>{{item.perChng}}</small>
                    </div>
                </div>
                <div class="col-12">
                    <div class="slide">
                        <span class="d-g"></span><span class="m-g"></span><span class="g"></span><span class="n"></span><span class="r"></span><span class="m-r"></span><span class="d-r"></span>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <mat-tab-group mat-stretch-tabs [dynamicHeight]="true" (selectedTabChange)="onTabChange($event.index)">
                        <mat-tab *ngFor="let item of marketMoodData" [label]="item.value"></mat-tab>
                    </mat-tab-group>
                </div>
                <div *ngIf = "isNoData" class="col-12 border-b text-center p-16 size14 disabletext" translate>equity.marketmood_desc</div>
            </div>
           
            <ng-container *ngIf="!isNoData && (isMobile || cellView)" [ngStyle]="Height">
                <div *ngFor="let option of indexConstituentData; let i = index" [class.wlweb]="cellView" [class.pl-2]="cellView" [class.pr-2]="cellView">
                    <div class="row" (click)="openScripModel(option)">
                        <div class="col-12">
                            <div class="watchlistwrapper">
                                <div class="row">
                                    <div class="col-7">
                                        <div class="firstline">{{option.TradingSymbol}} <small>{{option.Exchange}}</small></div>
                                        <div class="line2 subcolor">{{option.CompanyName === '' ? '-' : option.CompanyName}}</div>
                                    </div>
                                    <div [class.imnegative]="option.Change<0" [class.impositive]="option.Change>0" class="col-5 text-right">
                                        <div  [class.imnegativebg]="option.TickDirection === -1" [class.impositivebg]="option.TickDirection === 1">
                                            <div class="firstline">{{option.LastTradePrice | pricepipe:option.DecimalPrecision}}</div>
                                            <div class="line2">{{option.Change | pricepipe:option.DecimalPrecision}} ({{option.PercentageChange | percentagepipe}})</div>
                                        </div>
                                    </div>
                                    <div class="wl-btn" *ngIf="!isMobile && cellView">
                                        <button (click)='openPlaceOrder("B", option)' class="btn-positive" mat-icon-button><i class="icon-bb"></i></button> 
                                        <button (click)='openPlaceOrder("S", option)' class="mr-2 ml-2 btn-negative" mat-icon-button ><i class="icon-sb"></i></button>
                                        <button (click)='openStockDetail(option)' class="btn-primary" mat-icon-button><i class="icon-ib"></i></button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
           
            <ng-container *ngIf="!isNoData && !isMobile && !cellView">
                <div *ngIf = "!isNoData" class="table-global" [ngStyle]="Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                    <table mat-table [dataSource]="indexConstituentData" matSort (matSortChange)="sortMarketMood($event)" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                       
                        <ng-container matColumnDef="TradingSymbol" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag  [cdkDragDisabled]="true"> <span translate>stock_detail.symbol</span> </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="button-row">
                                <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true">
                                    <button (click)='openPlaceOrder("B", element)' class="btn-positive" mat-icon-button><i class="icon-bb"></i></button> 
                                    <button (click)='openPlaceOrder("S", element)' class="mr-2 ml-2 btn-negative" mat-icon-button ><i class="icon-sb"></i></button>
                                    <button (click)='openStockDetail(element)' class="btn-primary" mat-icon-button><i class="icon-ib"></i></button> 
                                </div>
                            </span>
                            {{element.TradingSymbol}}
                             </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="CompanyName">
                        <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>stock_detail.company_name</span> </th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            <small>{{element.CompanyName === '' ? '-' : element.CompanyName}} </small></td>
                        </ng-container>

                        <ng-container matColumnDef="LastTradePrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag  [cdkDragDisabled]="true"> <span translate>stock_detail.ltp</span> </th>
                            <td mat-cell *matCellDef="let element; let i = index" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">
                                <span [class.imnegativebg]="element.TickDirection === -1" [class.impositivebg]="element.TickDirection === 1"> {{element.LastTradePrice | pricepipe:element.DecimalPrecision}} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Change">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"cdkDrag> <span translate>stock_detail.change</span> </th>
                            <td mat-cell *matCellDef="let element" ><span [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">{{element.Change | pricepipe:element.DecimalPrecision}} </span></td>
                        </ng-container>

                        <ng-container matColumnDef="PercentageChange">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> % {{'stock_detail.change' | translate}} </th>
                            <td mat-cell *matCellDef="let element" ><span [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">{{element.PercentageChange | percentagepipe}}</span></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)"></tr>
                    </table>
                </div>
            </ng-container>
            <div *ngIf = "isNoData">
                <app-nodata message = "No Data"></app-nodata>
            </div>
        </div>
    </mat-card-content>
</mat-card>