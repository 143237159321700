import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'percentagepipe' })
export class PercentagePipe implements PipeTransform {
  transform(number, defaultValue) {
    if (number === undefined || number === '' || number === Infinity) {
      return (defaultValue === undefined) ? '-' : defaultValue;
    }
    else if (parseFloat(number) || typeof(number) === 'number') {
      return this.convertToDecimal(number);
    }
    else {
      return (defaultValue === undefined) ? '-' : defaultValue;
    }
  }
  convertToDecimal(num) {
    return num = this.convertFloat(num) + '%';
  }
  convertFloat(amt) {
    amt = parseFloat(amt);
    return amt.toFixed(2);
  }
}
