import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import { IEquityMarketService } from './iequitymarket.service';
import { IndexModel } from '../../model/index.model';
import { ChannelType, Utility } from '../utility';
import { TradingRestService } from '../tradingrest.service';
import { ScripContentDataModel } from 'src/app/model/scripcontentdata.model';
import { ScripModel } from 'src/app/model/scrip.model';
import { Preferences } from '../Preferences';
let equityMarketService: EquityMarketService;
let tradingRest: TradingRestService;
@Injectable()
export class EquityMarketService implements IEquityMarketService {
    apiService: ApiService;
    constructor(private tradingRestService: TradingRestService, apiService: ApiService) {
        equityMarketService = this;
        tradingRest = tradingRestService;
        equityMarketService.apiService = apiService;
    }

    getMarketMovers(exchange, type, indexName, day, callback: Function, precallback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const scripModelArr = new Array<ScripModel>();
        const params = { exchange_segment: exchange, type: type, indexname: indexName, day: day, limit: 20 };
        const key = Url.GetMarketMovers + '_' + params;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        equityMarketService.apiService.ptpluspost(Url.GetMarketMovers, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res['code'] === Keys.RES_CODE_SUCCESS && Object.keys(res.data).length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    const value = res['data'][i];
                    const bean = Utility.getScripModel(value['omtkn'], value['exseg'], tradingRest, true, ChannelType.Market);
                    bean.TradingSymbol = value['tsym'];
                    bean.ExchangeSegment = value['exseg'];
                    bean.Token = value['omtkn'];
                    bean.DisplaySymbolDescription = value['cname'];
                    bean.CompanyName = value['cname'];
                    bean.Change = value['netchg'];
                    bean.PerCng = value['chg'];
                    bean.LastTradePrice = value['ltp'];
                    bean.YearlyHighPrice = value['yrhi'];
                    bean.YearlyLowPrice = value['yrlo'];
                    bean.BestBuyPrice = value['bid'];
                    bean.BestBuySize = value['bsize'];
                    bean.BestSellPrice = value['ask'];
                    bean.BestSellSize = value['asize'];
                    bean.TradeVolume = value['vol'];
                    bean.scripContentDataModel = new ScripContentDataModel();
                    bean.scripContentDataModel.Ema20 = value['ema20'];
                    bean.scripContentDataModel.Ema50 = value['ema50'];
                    bean.scripContentDataModel.Ema100 = value['ema100'];
                    bean.scripContentDataModel.Ema200 = value['ema200'];
                    bean.scripContentDataModel.UpperBand = value['uprband'];
                    bean.scripContentDataModel.MiddleBand = value['mdlband'];
                    bean.scripContentDataModel.LowerBand = value['lwrband'];
                    scripModelArr.push(bean);
                }
                Utility.CACHE_STORE.setCacheData(key, scripModelArr);
                callback(scripModelArr, code, message);
            }
            else {
                message = res.message;
                code = Keys.RES_CODE_FAILURE;
                callback([], code, message);
            }
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback([], code, message);
        });
    }
    getMarketMood(exchange, indexName, callback: Function, precallback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let data = '';
        const params = { exchange_seg: exchange, index_name: indexName };
        const key = Url.MARKET_MOOD + '_' + params;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.ptpluspost(Url.MARKET_MOOD, params).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res['code'] === Keys.RES_CODE_SUCCESS) {
                if (Object.keys(res.data).length > 0) {
                    data = res.data;
                    Utility.CACHE_STORE.setCacheData(key, data);
                }
            }
            else {
                message = res.message;
                code = Keys.RES_CODE_FAILURE;
            }
            callback(data, code, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }

    getMarketTodayData(data, callback: Function, precallback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let marketTodayData = [];
        const key = Url.MARKET_TODAY + '_' + data;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.ptpluspost(Url.MARKET_TODAY, data).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res['code'] === Keys.RES_CODE_SUCCESS) {
                if (Object.keys(res.data).length > 0) {
                    marketTodayData = res.data;
                    message = Keys.RES_MSG_OK;
                    Utility.CACHE_STORE.setCacheData(key, marketTodayData);
                }
            }
            else {
                message = res.message;
                code = Keys.RES_CODE_FAILURE;
            }
            callback(marketTodayData, code, message);

        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }

    getIndicesTodayConstituentsNQ(exchange_seg: string, index_name: string, type: string, moodtype: string, callback: Function, precallback: Function) {
        let message;
        let code = Keys.RES_CODE_SUCCESS;
        const scripModelArr = new Array<ScripModel>();
        const scripTempList = [];
        const params = { exchange_seg: exchange_seg, index_name: index_name, type: type, moodtype: moodtype, isdetail: true };
        const key = Url.INDICESTODAYNQ + '_' + params;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.ptpluspost(Url.INDICESTODAYNQ, params).subscribe((response) => {
            response = JSON.parse(JSON.stringify(response));
            if (response['code'] === Keys.RES_CODE_SUCCESS && Object.keys(response.data).length > 0) {
                for (let i = 0; i < response.data.length; i++) {
                    const value = response['data'][i];
                    const bean = Utility.getScripModel(value['omtkn'], value['exseg'], tradingRest, true, ChannelType.Market);
                    bean.TradingSymbol = value['tsym'];
                    bean.ExchangeSegment = value['exseg'];
                    bean.Token = value['omtkn'];
                    bean.Change = value['chng'];
                    bean.PercentageChange = value['pchng'];
                    bean.scripContentDataModel = new ScripContentDataModel();
                    bean.scripContentDataModel.SevenDayReturn = value['7drt'];
                    bean.scripContentDataModel.OneMonthReturn = value['1mrt'];
                    bean.scripContentDataModel.ThreeMonthReturn = value['3mrt'];
                    bean.scripContentDataModel.SixMonthReturn = value['6mrt'];
                    bean.scripContentDataModel.OneYearReturn = value['1yrt'];
                    bean.scripContentDataModel.SevenDayVolume = value['7avol'];
                    bean.scripContentDataModel.OneMonthVolume = value['mavol'];
                    bean.scripContentDataModel.ThreeMonthVolume = value['3mav'];
                    bean.scripContentDataModel.SixMonthVolume = value['6mav'];
                    bean.scripContentDataModel.OneYearVolume = value['yavol'];
                    bean.scripContentDataModel.VolumeOne = value['vol_1'];
                    bean.scripContentDataModel.VolumeTwo = value['vol_2'];
                    bean.scripContentDataModel.SevenDayReturnVolume = value['7drtv'];
                    bean.scripContentDataModel.OneMonthReturnVolume = value['1mrtv'];
                    bean.scripContentDataModel.ThreeMonthReturnVolume = value['3mrtv'];
                    bean.scripContentDataModel.SixMonthReturnVolume = value['6mrtv'];
                    bean.scripContentDataModel.OneYearReturnVolume = value['1yrtv'];
                    bean.scripContentDataModel.Sma20 = value['sma20'];
                    bean.scripContentDataModel.Sma50 = value['sma50'];
                    bean.scripContentDataModel.Sma100 = value['sma100'];
                    bean.scripContentDataModel.Sma200 = value['sma200'];
                    bean.scripContentDataModel.Ema20 = value['ema20'];
                    bean.scripContentDataModel.Ema50 = value['ema50'];
                    bean.scripContentDataModel.Ema100 = value['ema100'];
                    bean.scripContentDataModel.Ema200 = value['ema200'];
                    bean.scripContentDataModel.UpperBand = value['uprband'];
                    bean.scripContentDataModel.MiddleBand = value['mdlband'];
                    bean.scripContentDataModel.LowerBand = value['lwrband'];
                    scripTempList.push(value['exseg'] + '|' + value['omtkn']);
                    scripModelArr.push(bean);
                }
                Utility.CACHE_STORE.setCacheData(key, scripModelArr);
                callback(scripModelArr, code, message);

                const count = scripTempList.length;
                if (count > 0) {
                    const scripListObj = {
                        'uid': Preferences.getPreference('userid'),
                        'tomcatCount': Preferences.getPreference('tomcatcount'),
                        'scripList': scripTempList.toString()
                    };
                    tradingRest.getScripService().getMultipleScrips(scripListObj, (data, resCode, resMessage) => {});
                }
            }
            else {
                message = response.message;
                code = Keys.RES_CODE_FAILURE;
                callback([], code, message);
            }
        }, error => {
            message = error;
            callback([], code, message);
        });
    }

    getAllIndicesDataNQ(exchange_segment: string, callback: Function, precallback: Function) {
        let message;
        const indexModelData = new Array<IndexModel>();
        const params = { exchange_segment: exchange_segment };
        const key = Url.ALLINDICESNQ + '_' + params;
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.ptpluspost(Url.ALLINDICESNQ, params).subscribe((response) => {
            if (response.code === Keys.RES_CODE_SUCCESS) {
                if (response && response.data && Object.keys(response['data']).length > 0) {
                    Object.keys(response['data']).forEach(key => {
                        const value = response['data'][key];
                        const bean = Utility.getIndexModel(value['tsym'], 'NSE');
                        bean.OmToken = value['omtkn'];
                        bean.ExchangeSegment = value['exseg'];
                        bean.TSymbol = value['tsym'];
                        bean.SevenDayReturn = value['wrtn'];
                        bean.OneMonthReturn = value['mrtn'];
                        bean.ThreeMonthReturn = value['3mrtn'];
                        bean.SixMonthReturn = value['6mtrn'];
                        bean.OneYearReturn = value['yrtn'];
                        bean.SevenDayReturnVolume = value['wrtbval'];
                        bean.OneYearReturnVolume = value['mrtnval'];
                        bean.ThreeMonthReturnVolume = value['3mrtnval'];
                        bean.SixMonthReturnVolume = value['6mrtnval'];
                        bean.OneYearReturnVolume = value['yrtnval'];
                        bean.SevenDayVolume = value['wavol'];
                        bean.OneMonthVolume = value['mavol'];
                        bean.ThreeMonthVolume = value['3mavol'];
                        bean.SixMonthVolume = value['6mavol'];
                        bean.OneYearVolume = value['yavol'];
                        bean.VolumeOne = value['vol1'];
                        bean.VolumeTwo = value['vol2'];
                        bean.Advance = value['advance'];
                        bean.Decline = value['decline'];
                        bean.Neutral = value['neutral'];
                        bean.AdvancePer = ((bean.Advance/(bean.Advance+bean.Decline+bean.Neutral))*100) + '%';
                        bean.DeclinePer = ((bean.Decline/(bean.Advance+bean.Decline+bean.Neutral))*100) + '%';
                        bean.NeutralPer = ((bean.Neutral/(bean.Advance+bean.Decline+bean.Neutral))*100) + '%';
                        bean.Change = value['chg'];
                        bean.Name = value['cname'];
                        bean.Exchange = Utility.segmentToExchange(value['exseg']);
                        bean.Date = value['date'];
                        bean.DateTime = value['dtime'];
                        bean.Turnover = value['tovr'];
                        bean.High = value['hi'];
                        bean.Low = value['lo'];
                        bean.Open = value['open'];
                        bean.last = value['last'];
                        bean.Yearhigh = value['yrhi'];
                        bean.Yearlow = value['yrlo'];
                        bean.Cls = value['cls'];
                        bean.Tym = value['tym'];
                        bean.IndexValue = parseInt(bean.last);
                        indexModelData.push(bean);
                    });
                    message = 'OK';
                    Utility.CACHE_STORE.setCacheData(key, indexModelData);
                } else {
                    message = 'NO DATA';
                }
            }
            else
                message = response.message;
            callback(response.code, indexModelData, message);
        }, error => {
            message = error;
            callback(Keys.RES_CODE_FAILURE, '', message);
        });
    }

}

