<mat-card class="webpanelnew">
    <mat-card-content>
        <div [class.pt-0]="!isMobile">
            <div *ngIf = "!isNoData" class="row">
                <div class="col-12">
                    <div class="panelsubheader">
                        <div class="row">
                                <div class="col-6">
                                    <mat-form-field>
                                        <mat-select [(ngModel)]="type" (ngModelChange)="selectType($event)">
                                            <mat-option *ngFor="let item of typesArray" [value]="item">
                                                {{item}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field>
                                        <mat-select [(ngModel)]="selectedDate" (ngModelChange)="selectDate($event)">
                                            <mat-option *ngFor="let date of dates" [value]="date">
                                                {{date}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                          </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isMobile && !isNoData && !isLoading">
                <div *ngFor="let item of putCallData; let i = index">
                    <div class="row">
                        <div class="col-12">
                            <div class="watchlistwrapper">
                                <div class="row">
                                    <div class="col-7">
                                        <div class="firstline">{{getSymbolName(item.tsym)}} <small>{{item.exseg | valuepipe}}</small></div>
                                        <div class="line2 subcolor">{{item.putclky | splitpipe: '_' : 1}} {{getStrikePrice(item.tsym)}} {{(item.putcl == 'CALL')? 'CE': 'PE'}}</div>
                                    </div>
                                    <div [class.imnegative] = "item.pcOIrtio < 1" [class.impositive]="item.pcOIrtio > 1" class="col-5 text-right">
                                        <div>
                                            <div class="firstline"> {{item.pcOIrtio.toFixed(2)}}</div>
                                            <div class="line2">{{(item.pcOIrtio > 1) ? ('derivative.bullish' | translate) : ('derivative.bearish' | translate)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!isMobile && !isNoData && !isLoading">
                <div class="table-global" [ngStyle]="Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                <table mat-table [dataSource]="putCallData">
                    <ng-container matColumnDef="TradingSymbol" sticky>
                    <th mat-header-cell *matHeaderCellDef translate> stock_detail.symbol </th>
                    <td mat-cell *matCellDef="let element"> {{getSymbolName(element.tsym)}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="PUT OI">
                    <th mat-header-cell *matHeaderCellDef class="text-right"><span translate>derivative.call</span> OI</th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                        <span>{{element.poi | numberpipe}}</span></td>
                    </ng-container>

                    <ng-container matColumnDef="CALL OI">
                        <th mat-header-cell *matHeaderCellDef class="text-right"><span translate>derivative.call</span> OI</th>
                        <td mat-cell *matCellDef="let element" class="text-right">{{element.coi | numberpipe}}</td>
                    </ng-container>

                    <ng-container matColumnDef="PUT/CALL RATIO">
                        <th mat-header-cell *matHeaderCellDef class="text-right"><span translate>derivative.pcratio</span></th>
                        <td mat-cell *matCellDef="let element" class="text-right" [class.negative-t]="element.pcOIrtio < 1" [class.positive-t]="element.pcOIrtio > 1">{{element.pcOIrtio.toFixed(2)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Ratio Type">
                        <th mat-header-cell *matHeaderCellDef> <span translate>derivative.ratio</span> <span translate> derivative.type</span></th>
                        <td mat-cell *matCellDef="let element"  [class.negative-t]="element.pcOIrtio < 1" [class.positive-t]="element.pcOIrtio > 1">{{(element.pcOIrtio > 1) ? ('derivative.bullish' | translate) : ('derivative.bearish' | translate)}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                </div>
            </ng-container>
            <ng-container *ngIf='!isLoading && isNoData' class='nodata'>
                <app-nodata message = "N0 DATA"></app-nodata>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>