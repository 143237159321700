import { BasketPositionModel } from 'src/app/model/basketposition.model';

export class BasketOrderBookModel {
    basket_id: number;
    basket_name: string;
    image: string;
    actId: string;
    intrument_update_date: string;
    basket_user_id: string;
    isRebalance: string;
    totalPNL: number;
    active_version: string;
    place_on: string;
    total_investment: number;
    basket_ins_id: string;
    marketVal: number;
    positionData: Array<BasketPositionModel>;
    total_withdrawl: number;
}