import { Component, OnInit } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
let aboutUs: AboutusComponent;
@Component({
  selector: 'app-aboutus',
  templateUrl: '../../../'+environment.baseviewpath+'/pages/aboutus/aboutus.component.html'
})
export class AboutusComponent implements OnInit {
  isMobile: boolean;
  version = environment.version;
  display_version = environment.display_version;
  constructor() {
    aboutUs = this;
   }

  ngOnInit(): void {
    aboutUs.isMobile = Utility.IS_MOBILE;
  }

}
