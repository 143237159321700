<div *ngIf="!isNoData" [ngStyle]="Height">
<div class="p-1">
    <div class="row myrow smalltext">
        <div class="col-6">
            <small class="ts-1 subcolor2" translate>watchlist.low</small>
            <div>{{scrpModel?.Low | pricepipe: scrpModel?.DecimalPrecision}}</div>
        </div>
        <div class="col-6 text-right">
            <small class="ts-1 subcolor2" translate>watchlist.high</small>
            <div>{{scrpModel?.High | pricepipe: scrpModel?.DecimalPrecision}}</div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="sliderwrapper">
                <div class="slider ui-slider">
                    <div class="ui-slider-handle" [style.left]="lowHighData.circuit_high_low_percent">
                        <span class="handleText" [style.right]="lowHighData.circuit_high_low_percent_right" [class.gtext]="scrpModel?.Change>0" [class.rtext]="scrpModel?.Change<=0">{{scrpModel?.LastTradePrice | pricepipe: scrpModel?.DecimalPrecision}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row myrow smalltext">
        <div class="col-6">
            <div><span class="graytext ts-1" translate>watchlist.open</span> {{scrpModel?.OpenPrice | pricepipe: scrpModel?.DecimalPrecision}}</div>
        </div>
        <div class="col-6 text-right">
            <div><span class="graytext ts-1" translate>watchlist.close</span> {{scrpModel?.PrvClose | pricepipe: scrpModel?.DecimalPrecision}}</div>
        </div>
    </div>

    <div class="row myrow smalltext">
        <div class="col-6"><div><span class="graytext ts-1" translate>stock_detail.volume</span> {{scrpModel?.TradeVolume | numberpipe}}</div></div>
        <div class="col-6 text-right"><div><span class="graytext ts-1" translate>stock_detail.atp</span> {{scrpModel?.VwapAveragePrice | pricepipe: scrpModel?.DecimalPrecision}}</div></div>
    </div>

    <div class="row myrow smalltext">
        <div class="col-4"><div><span class="graytext ts-1" translate>stock_detail.ltq</span> {{scrpModel?.Lasttradedquantity | numberpipe}}</div></div>
        <div class="col-8 text-right"><div><span class="graytext ts-1" translate>stock_detail.ltt</span> {{scrpModel?.LastTradedTime |  datepipe:'datetime'}}</div></div>
    </div>

    <div class="row myrow smalltext">
        <!-- <div class="col-6"><div><span class="graytext" translate>stock_detail.lut</span> {{scrpModel?.ExchangeFeedTime |  datepipe:'datetime'}}</div></div> -->
        <div class="col-8"><div><span class="graytext ts-1" translate>stock_detail.lut</span> {{scrpModel?.LastTradedTime |  datepipe:'datetime'}}</div></div>
        <div class="col-4 text-right"><div><span class="graytext ts-1" translate>stock_detail.ltp</span> {{scrpModel?.LastTradePrice | pricepipe: scrpModel?.DecimalPrecision}}</div></div>
    </div>
    <div class="row myrow smalltext">
        <div class="col-6"><div><span class="graytext ts-1" translate>stock_detail.lower_ckt</span> {{scrpModel?.LCircuitLimit | pricepipe: scrpModel?.DecimalPrecision}}</div></div>
        <div class="col-6 text-right"><div><span class="graytext ts-1" translate>stock_detail.upper_ckt</span> {{scrpModel?.HCircuitLimit | pricepipe: scrpModel?.DecimalPrecision}}</div></div>
    </div>
    <div class="row myrow smalltext" *ngIf="!isDerivativeScrip">
        <div class="col-6"><div><span class="graytext ts-1" translate>stock_detail.52_week_high</span> {{scrpModel?.YearlyHighPrice | pricepipe: scrpModel?.DecimalPrecision}}</div></div>
        <div class="col-6 text-right"><div><span class="graytext ts-1" translate>stock_detail.52_week_low</span> {{scrpModel?.YearlyLowPrice | pricepipe: scrpModel?.DecimalPrecision}}</div></div>
    </div>
</div>
</div>
<div *ngIf="isNoData">
    <app-nodata message="No Data"></app-nodata>
</div>