import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Utility } from 'src/app/services/utility';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    public snackBar: MatSnackBar,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messages.subscribe(
          (_messaging: any) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            _messaging.onBackgroundMessage = _messaging.onBackgroundMessage.bind(_messaging);
          }
        )
    }

  updateToken(userId: any, token: any) {
      this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
      const data = {};
      data[userId] = token
      this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  requestPermission(userId: any) {
    this.angularFireMessaging.requestToken.subscribe((token) => {
      this.updateToken(userId, token);
    },
    (err) => {
      console.error('Unable to get permission to notify.', err);
    }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(async (payload) => {
      console.log("TS receiveMessage() method ", payload);
      this.currentMessage.next(payload);
      let title = payload["notification"]["title"];
      let description = payload["notification"]["body"];
      let transactionID = payload["data"]["transactionID"];
      let date = payload["data"]["date"];
      let id = payload["data"]["google.c.a.c_id"];

      Utility.fcmNotificationCollection.set(id , {title: title, description: description, transactionID: transactionID});
      Utility.fcmNotificationCount.next({title: title, description: description, transactionID: transactionID});
    })
  }
}