import { Subject } from 'rxjs';

export class ScripMarketPictureModel
{
    ExchangeSegment: string;
    Token: any;
    Symbol: any;
    Ltp: any;
    PerChange: any;
    BestBuyQuantity: any = [];
    BestBuyPrice: any = [];
    BestBuyOrders: any = [];
    BestSellQuantity: any = [];
    BestSellPrice: any = [];
    BestSellOrder: any = [];
    Exchange: string;
    TheoreticalPrice: any;
    MarketOrderBuySize: any;
    MarketOrderSellSize: any;
    TotalBuyQty: any;
    TotalSellQty: any;
    TradingSymbol: any;
    HCircuitLimit: any;
    LCircuitLimit: any;
    depthUpdate = new Subject<any>();
    IsSubscribe = false;
}
