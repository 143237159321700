import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Preferences } from 'src/app/services/Preferences';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-link',
  templateUrl: '../../'+environment.baseviewpath+'/link/link.component.html'
})
export class LinkComponent implements OnInit {
  url = '';
  s = false; 
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<LinkComponent>) {
    this.url = data.url;
    if(data.s){
      this.s = data.s;
    }
  }

  ngOnInit(): void {
  }
  cancel(){
    this.dialogRef.close();
  }
  close(){
    setTimeout(()=> {
      this.dialogRef.close();
    },2000);
  }

  dontshow(){
    Preferences.setPreference('survey','1');  
    this.dialogRef.close();
  }
}
