<app-header *ngIf="isMobile" goback="1" [title]="'scanners.scanners' | translate" [isReloadVisible]="false"></app-header>
<div [class.data-container] = "isMobile" class=" row">
    <div class="col-12">
        <!-- <mat-chip-list>
            <mat-chip *ngFor="let obj of dataArr;" [class.imactive] = "obj.value ==  sType" (click)="onTabChange(obj.value)" translate>{{obj.name}}</mat-chip>
        </mat-chip-list> -->
        <mat-card *ngIf = "sType == 'equity'" class="webpanelnew h-watchlist">
            <div class="row">
                <div class="col-12">
                    <div class="heading-panel">
                        <div class="row">
                            <div class="col-12">
                                <h3 translate>scanners.equity_analy</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6" *ngFor="let scannerType of scannerEquityTypes">
                    <mat-card class="tiledetail" matRipple (click)="onClick(scannerType)" translate>
                        <i class="{{scannerType.iconClass}}"></i>
                        {{scannerType.t_scannerType}}
                    </mat-card>                
                </div>
            </div> 
        </mat-card>
        <mat-card *ngIf = "sType == 'derivative'" class="webpanelnew h-watchlist">
            <div class="row">
                <div class="col-12">
                    <div class="heading-panel">
                        <div class="row">
                            <div class="col-12">
                                <h3 translate>scanners.der_strat</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 p-1 pt-3 pb-3" *ngFor="let scannerType of scannerDerivativeTypes">
                    <mat-card class="tiledetail" matRipple (click)="onClick(scannerType)" translate>
                        <i class="{{scannerType.iconClass}}"></i> <br/><br/>
                        {{scannerType.t_scannerType}}
                    </mat-card>                
                </div>
            </div>
        </mat-card>
    </div>
</div>