import { OnInit, Component, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { OrderHistoryModel } from 'src/app/model/orderhistory.model';
import { MatDialog } from '@angular/material/dialog';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { Router } from '@angular/router';
import { OrderModel } from 'src/app/model/order.model';
import { ChannelType, Utility } from 'src/app/services/utility';
import { PlaceorderComponent } from '../placeorder/placeorder.component';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { ScripModel } from 'src/app/model/scrip.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';


let orderHistory: OrderHistoryComponent;
@Component({
    selector: 'app-orderhistory',
    templateUrl: './orderhistory.component.html',
    styleUrls: ['./orderhistory.component.scss']
})

export class OrderHistoryComponent implements OnInit, OnDestroy {
    @Output() load = new EventEmitter<boolean>();
    isLoading: boolean;
    isNoData: boolean;
    detailedOrderData;
    orderHistoryList: Array<OrderHistoryModel>;
    fillQuantity;
    isExit: boolean;
    isCancel: boolean;
    om: OrderModel;
    isMobile = true;
    scripModel: ScripModel;
    dialogRef;
    showtitle = true;
    @Input() formOrder: boolean;
    constructor(private tradingRestService: TradingRestService, private dialog: MatDialog, private router: Router, private translate: TranslateService, private snackBar: MatSnackBar, private location: Location) {
        orderHistory = this;
        orderHistory.isMobile = Utility.IS_MOBILE;
        orderHistory.isLoading = true;
        orderHistory.load.emit(orderHistory.isLoading);
        orderHistory.detailedOrderData = Utility.detailedOrder;
        orderHistory.om = orderHistory.detailedOrderData;
        orderHistory.fillQuantity = orderHistory.detailedOrderData['TotalQty'] - orderHistory.detailedOrderData['PendingQty'];
        if (orderHistory.om.Status.toLowerCase() === 'open' || orderHistory.om.Status.toLowerCase() === 'trigger pending'
            || orderHistory.om.Status.toLowerCase() === 'after market order req received'
            || orderHistory.om.Status.toLowerCase() === 'modify after market order req received') {
            if (orderHistory.om.ProductCode !== 'CO') {
                if (orderHistory.om.ProductCode !== 'BO' || orderHistory.om.SyomOrderId === '') {
                    orderHistory.isCancel = true;
                }
            }
            if (orderHistory.om.ProductCode === 'CO' || orderHistory.om.ProductCode === 'BO') {
                orderHistory.isExit = true;
            }
        }
        orderHistory.tradingRestService.getBookService().GetOrderHistory(orderHistory.detailedOrderData['NestOrd'], (responsedata, code, message) => {
            orderHistory.isLoading = false;
            orderHistory.load.emit(orderHistory.isLoading);
            if (code === 200 && message === 'OK') {
                orderHistory.orderHistoryList = responsedata;
            }
            else {
                orderHistory.isNoData = true;
            }
        }, orderHistory.preDefinedOrderHistory);
    }

    preDefinedOrderHistory(data) {
        orderHistory.orderHistoryList = data;
    }
    ngOnInit() {
        orderHistory.showtitle = !orderHistory.formOrder;
        orderHistory.scripModel = Utility.getScripModel(orderHistory.detailedOrderData['Token'], orderHistory.detailedOrderData['ExchangeSegment'], orderHistory.tradingRestService, true);
        this.onAppearing();
    }

    ngOnDestroy() {
        this.onDisappearing();
    }

    setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.onAppearing();
          }, 50);
        }
        else {
          this.onDisappearing();
        }
    }

    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Shared);
    }
    
    onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
    }

    cancelOpenOrder() {
        this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.cancel_hed'), message: orderHistory.translate.instant('all.cancel_ordermsg') } });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
                orderHistory.tradingRestService.getBookService().CancelOrder(orderHistory.om, (responsedata, code, message) => {
                    if (responsedata['stat'] === 'Ok')
                    {
                        if (!this.isMobile)
                            Utility.dialogRef.close();
                        else
                            this.location.back();
                        Utility.ORDER_PLACED_EVENT.next('true');
                        this.snackBar.open(this.translate.instant('all.order_cancelled'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['successSnackBar'] });
                    }
                    else
                        this.snackBar.open(this.translate.instant('all.order_failed'), this.translate.instant('all.close'), { duration: 3000, panelClass: ['errorSnackBar'] });
                });
            }
        });
    }

    exitOrder() {
        this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('all.exit_hed'), message: orderHistory.translate.instant('books.exit_order') } });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
                orderHistory.tradingRestService.getBookService().ExitOrder(orderHistory.om, (responsedata, code, message) => {
                    if (responsedata['stat'] === 'Ok')
                    {
                        if (!this.isMobile)
                            Utility.dialogRef.close();
                        else
                            this.location.back();
                        Utility.ORDER_PLACED_EVENT.next('true');
                        this.snackBar.open(this.translate.instant('all.order_exited'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['successSnackBar'] });
                    }
                    else
                        this.snackBar.open(this.translate.instant('all.order_exit_failed'), this.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
                });
            }
        });
    }

    onModify() {
        if (this.isMobile) {
            orderHistory.router.navigate(['placeorder'], { queryParams: { 'isEdit': true } });
        } else {
            Utility.dialogRef.close();
            const data_obj = { 'isEdit': true };
            Utility.openPage(orderHistory.router, 'orderHistory', 'placeorder', PlaceorderComponent, orderHistory.dialog, 'popup', data_obj, '500px', '1250px');
        }
    }
}
