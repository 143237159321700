<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <mat-card class="p-2">
                <ngx-pull-to-refresh
                spinnerColor="#ff0000"
                distanceForRefresh="80"
                [isEnable]="isMobile"
                [targetElement]="targetElement"
                (refresh)="myRefreshEvent($event, 'refresh')"
                (loadMore)="alert('loadmore')"
                class = "loader">
                    <div [ngStyle]="Height">
                        <div class="container-fluid padding8">
                            <div *ngIf = "!isNoData" class="row">
                                <div [class.col-12]="numberofcol==1" [class.col-6]="numberofcol==2" [class.col-4]="numberofcol==3" [class.col-3]="numberofcol==4" *ngFor="let news of newsArr; let i = index;">
                                    <div class="newscardwrapper">
                                        <div (click)='openNewTab(news.url, _blank)'>
                                            <div *ngIf="news.img !== ''" class="imgwrapper"><img alt="news" [src]="news.img" /></div>
                                            <div class="summary">
                                                <small>{{news.dt | datepipe:'ISTFromNow'}}</small>
                                                <h5 class="block-with-text truncate-overflow" [innerHTML]="news.ttl | truncatetext:50"></h5>
                                                <div class="datetime news-src">{{news.src}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf = "isNoData">
                            <app-nodata message = "No Data"></app-nodata>
                        </div>
                    </div>
                </ngx-pull-to-refresh>
            </mat-card>
        </div>
    </div>
</div>