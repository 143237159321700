<app-header *ngIf = "isMobile" goback="1" [isLoading]="isLoading" [title]="'dashboard.investment_boxes' | translate"></app-header>
<div [class.data-container] = "isMobile">
  <mat-tab-group mat-stretch-tabs (selectedTabChange)="tabClick($event)" [(selectedIndex)] = "selectIndex">
    <mat-tab label="Investment Boxes">
      <app-basket (load)="onLoading($event)" [Height] = "Height"></app-basket>
    </mat-tab>
    <mat-tab label="My Investments">
      <app-basketorderbook [Height] = "Height"></app-basketorderbook>
    </mat-tab>
    <mat-tab label="SIP">
      <app-basketsip></app-basketsip>
    </mat-tab>
  </mat-tab-group>
</div>