import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
let termsComponent: TermsComponent;
@Component({
  selector: 'app-terms',
  templateUrl: '../../../'+environment.baseviewpath+'/pages/terms/terms.component.html'
})
export class TermsComponent implements OnInit {
  @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
  selectedParagraph;
  isMobile;
  constructor() {
    termsComponent = this;
   }

  ngOnInit(): void {
    termsComponent.isMobile = Utility.IS_MOBILE;
  }

  AnchorClicked(selectedText){
    if (selectedText === termsComponent.selectedParagraph){
      termsComponent.selectedParagraph = '';
    }
    else{
      termsComponent.selectedParagraph = selectedText;
    }
  }

  onTitleClick() {
    this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
}
