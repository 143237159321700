import { Injectable, ɵConsole } from '@angular/core';
import { IUserService } from './iuser.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import CryptoJS from 'crypto-js';
import { Utility } from '../utility';
import { LimitModel } from 'src/app/model/limit.model';
import { Preferences } from '../Preferences';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService implements IUserService {
    apiService: ApiService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }
    modifyWithdrawal(callback: Function, data: any) {
        throw new Error('Method not implemented.');
    }
    cancelWithdrawal(callback: Function, data: any) {
        throw new Error('Method not implemented.');
    }
    getSegmentData(callback: Function) {
        throw new Error('Method not implemented.');
    }

    Logout(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'uid': Preferences.getPreference('userid') };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.Logout, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                Utility.isLogin = false;
                Preferences.setPreference('userLogIn', '0');
                // Preferences.setPreference('publicKey4', res['sUserToken']);
                // Preferences.setPreference('userSessionId', res['userSessionId']);
                // Preferences.setPreference('userid', uId);
                data = res;
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                if (res.Emsg !== undefined) {
                    message = res.Emsg;
                } else {
                    message = res.emsg;
                }
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    getOmSessionToken(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'uid': Preferences.getPreference('userid') };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.OM_SESSION_TOKEN, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
            } else {
                code = Keys.RES_CODE_FAILURE;
                if (res.Emsg !== undefined) {
                    message = res.Emsg;
                } else {
                    message = res.emsg;
                }
            }
            callback(res, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }

    getAllLimits(sendJson, callback: Function, precallback?: Function ) {
        const limitDataModel = new Array<LimitModel>();
        const param = Utility.prepareParameters(JSON.stringify(sendJson), Preferences.getPreference('publicKey4'));
        const key = Url.LIMIT_URL + '_' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.post(Url.LIMIT_URL, param).subscribe(res => {
            let code = Keys.RES_CODE_SUCCESS;
            let message = '';
            const stat = res['stat'];
            if (stat === 'Ok') {
                const bean = new LimitModel();
                bean.PayoutAmt = Number(res['PayoutAmt']).toFixed(2);
                bean.StockValuation = Number(res['StockValuation']).toFixed(2);
                bean.DirectCollateralValue = Number(res['directcollateralvalue']).toFixed(2);
                bean.NetcashAvailable = Number(res['Netcashavailable']).toFixed(2);
                bean.OpeningBalance = Number(res['OpeningBalance']).toFixed(2);
                bean.IPOAmount = Number(res['IPOAmount']).toFixed(2);
                bean.UtilizedAmount = Number(res['Utilizedamount']).toFixed(2);
                bean.PayinAmt = Number(res['PayinAmt']).toFixed(2);
                bean.BookedPNL = Number(res['BookedPNL']).toFixed(2);
                bean.UnbookedPNL = Number(res['UnbookedPNL']).toFixed(2);
                bean.AdhocMargin = Number(res['adhocmargin']).toFixed(2);
                bean.NotionalCash = Number(res['notionalcash']).toFixed(2);
                bean.Segment = res['segment'];
                limitDataModel.push(bean);
                message = 'Ok';
                Utility.CACHE_STORE.setCacheData(key, limitDataModel);
            }
            else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback(limitDataModel, code, message);
        });
    }

    getUserLogMessage(responseCallback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'uid': Preferences.getPreference('userid') };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.USER_LOG_MESSAGE, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            if (res['stat'] !== 'Ok') {
                code = Keys.RES_CODE_FAILURE;
                message = res['Emsg'];
            } else {
                message = 'OK';
            }
            responseCallback(res, code, message);
        });
    }

    getUserProfile(callback: Function, precallback?: Function) {
        let code = Keys.RES_CODE_SUCCESS;
        let message = '';
        const jsonSendObj = { 'uid': Preferences.getPreference('userid'), 'acctId': Preferences.getPreference('accountId'), 's_prdt_ali': Preferences.getPreference('productAlias') };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        const key = Url.userProfile + '_' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.post(Url.userProfile, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            let data = '';
            if (res['stat'] === 'Ok') {
                data = res;
                message = Keys.RES_MSG_OK;
                Utility.CACHE_STORE.setCacheData(key, res);
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res.emsg;
            }
            callback(res, code, message);
        },
            error => {
                message = error;
                code = Keys.RES_CODE_FAILURE;
                Utility.print(error);
                callback('', code, message);
            });

    }

    getBannerData(callback: Function)
    {
        throw new Error('Method not implemented.');
    }

    IBPayment(callback: Function, data: any) {
        throw new Error('Method not implemented.');
    }

    CancelPayment(data, callback: Function) {
        throw new Error('Method not implemented.'); 
    }

    VerifyPayment(data: any, callback: Function) {
        throw new Error('Method not implemented.');
    }
    PayOut(callback: Function, data: any) {
        throw new Error('Method not implemented.');
    }
    TransactionDetails(callback: Function, data: any) {
        throw new Error('Method not implemented.');
    }

    GetPreferences(callback: Function) {
    }

    SetPreferences(value: any, callback: Function) {
    }

    GetTransactionCharge(data: any, callback: Function) {
        throw new Error('Method not implemented.');
    }
    GetAllTransactionCharges(callback: Function) {
        throw new Error('Method not implemented.');
    }

    getEmandateMsgId(data, callback: Function){
    }

    getAllTransactions(callback: Function){
    }

    openBackOffice(callback: Function) {
        let uid = '179S3855';
        let url = 'shrdbms/SilentSessionRegister.ssnp?LoginType=CLIENT&apikey='+ environment.apiKeyBackOffice +'&loginid='+ uid;
        this.apiService.get(url).subscribe({
            next: data => {
                console.log('data ', data);
            },
            error: error => {
                console.error('There was an error!', error);
            }
            })
    }
    getEdisTransectionID(callback: Function, data){
        
    }

}
