import { Component, OnInit } from '@angular/core';


import { TradingRestService } from '../../../services/tradingrest.service';
import { IScripService } from 'src/app/services/scrip/iscrip.service';
import { Utility } from 'src/app/services/utility';

let lotweightComponent: LotWeightComponent;
@Component({
  selector: 'app-lotweight',
  templateUrl: './lotweight.component.html'
})
export class LotWeightComponent implements OnInit {

  isLoading;
  url = '';
  lotweight = {};
  scripService: IScripService;
  isMobile = true;
  isNoData: boolean;

  constructor(private tradingRestService: TradingRestService) {
    lotweightComponent = this;
    lotweightComponent.isMobile = Utility.IS_MOBILE;
    lotweightComponent.isLoading = true;

  }
  ngOnInit() {
    lotweightComponent.tradingRestService.getScripService().getLotWeight(lotweightComponent.getLotWeightRes, lotweightComponent.setPrdefinedLotWeight);
  }
  getLotWeightRes(data, code, message) {
    if (code === 200) {
      lotweightComponent.isLoading = false;
      lotweightComponent.lotweight = data;
      lotweightComponent.isNoData = false;
    }
    else {
      lotweightComponent.isNoData = true;
    }
  }

  setPrdefinedLotWeight(data) {
    lotweightComponent.isLoading = false;
    lotweightComponent.lotweight = data;
    lotweightComponent.isNoData = false;
  }
}
