import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewChild
} from '@angular/core';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-mpininput',
  templateUrl: './mpininput.component.html',
  styleUrls: ['./mpininput.component.scss']
})
export class MpininputComponent implements OnInit {

  @ViewChildren('input') inputsList: QueryList<ElementRef>;
  @ViewChild('mpinInput') mpinInput;

  @Input() readonly isFocus = true;
  @Input() readonly isLoading = false;
  @Input() readonly placeholderText = '';
  @Input() isMPINEnabled = true;

  @Output() codeChanged = new EventEmitter<string>();
  @Output() codeCompleted = new EventEmitter<string>();
  pin = '';
  hidePassword = true;
  mpinControl = new FormControl('');

  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    if (this.isFocus) {
      this.setFocus();
    }
  }

  setFocus() {
    setTimeout(() => {
      this.mpinInput.nativeElement.focus();
    }, 200);
  }

  onKeydown(e: any) {
    this.codeChanged.emit(this.pin);
    if (this.pin.length === 4){
      this.codeCompleted.emit(this.pin);
    }
  }

  _keyPress(e: any) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
     return false;
    }
    if (e.which === 0) {
     return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }
}