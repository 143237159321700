import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild,Output, EventEmitter } from '@angular/core';
import { MatBottomSheetRef, MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AutoSearchComponent } from '../../autocompletesearch/autocompletesearch.component';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utility } from 'src/app/services/utility';
declare const moment: any;
let DialogRef: MatDialogRef<EventFilterOptionComponent>;
let SheetRef: MatBottomSheetRef<EventFilterOptionComponent>;
@Component({
    selector: 'app-eventfilteroption',
    templateUrl: './eventFilterOption.component.html',
    styleUrls: []
})

export class EventFilterOptionComponent implements OnInit {
    @ViewChild('advancesearch') advancesearch: AutoSearchComponent;
    flag = false;
    isMobile;
    activeCategory = [];
    symbol = '';
    filterObj: any;
    searchId = 'EventFilter';
    selectedcategory: any;
    categories = [
      { value: 'ALL', viewValue: 'All' },
      { value: 'BOARD MEETING', viewValue: 'Board Meeting' },
      { value: 'BONUS', viewValue: 'Bonus' },
      { value: 'BUY BACK', viewValue: 'Buy Back' },
      { value: 'CONSOLIDATION', viewValue: 'Consolidation' },
      { value: 'DIVIDEND', viewValue: 'Dividend' },
      { value: 'INTEREST', viewValue: 'Interest' },
      { value: 'MERGER', viewValue: 'Merger' },
      { value: 'REDEMPTION', viewValue: 'Redemption' },
      { value: 'REDUCTION IN CAPITAL', viewValue: 'Reduction In Capital' },
      { value: 'RIGHTS', viewValue: 'Rights' },
      { value: 'SPLIT', viewValue: 'Split' },
      { value: 'OTHER', viewValue: 'Other' }
  ];
  date1: any;
  date2: any;
  filterObjFromEvent: any;
  @Output() load = new EventEmitter<boolean>();
  @Output() loaderEvent = new EventEmitter<boolean>();
  @Input() filterObjInput: any;
  start = 0;
  limit = 50;
  isLoading: boolean;
  searchData: any;
    constructor(private PS: PtPlusRestService, private translate: TranslateService, private snackBar: MatSnackBar) {
        this.isMobile = Utility.IS_MOBILE;
    }
    ngOnInit() {
        this.setFilterData();
    }
    setFilterData() {
        this.activeCategory = [{ value: 'nse_cm', name: 'NSE', checked: false, disabled: false },
         { value: 'bse_cm', name: 'BSE', checked: false, disabled: false }];
        this.filterObjInput['exchange'].forEach(element => {
            const temp = this.activeCategory.find(data => data['value'] === element);
            temp.checked = true;
            temp.disabled = (this.filterObjInput['exchange'].length === 1) ? true : false;
        });
        if (this.filterObjInput['symbol'] !== undefined)
            this.searchData = this.filterObjInput['symbol'];
        if (this.filterObjInput['type'] === undefined)
            this.filterObjInput['type'] = 'ALL';
            this.selectedcategory = this.filterObjInput['type'];
            this.filterObj = this.filterObjInput;
    }
    openLink(code, result, message): void {
        this.filterObj['exchange'] = JSON.parse(this.filterObj['exchange']);
        if (!Utility.IS_MOBILE) {
            DialogRef.close({code: code, data: result, message: message, filter: this.filterObj});
        }
        else {
           SheetRef.dismiss({code: code, data: result, message: message, filter: this.filterObj});
        }
    }


    onChangeExchange(data) {
        if (data.checked) {
            const index = this.filterObj['exchange'].indexOf(data.value);
            if (index === -1)
                this.filterObj['exchange'].push(data.value);
            this.activeCategory.forEach(function (element) {
                element.disabled = false;
            });
        } else {
            if (this.filterObj['exchange'].length === 2) {
                const index = this.filterObj['exchange'].indexOf(data.value);
                if (index > -1)
                    this.filterObj['exchange'].splice(index, 1);
            }
            if (this.filterObj['exchange'].length === 1) {
                for (let i = 0; i < this.activeCategory.length; i++) {
                    if (this.activeCategory[i]['value'] !== data.value)
                        this.activeCategory[i].disabled = true;
                }
            }
        }
        if (this.filterObj['exchange'].length === 2)
          this.symbol = '';
        else
          this.symbol = (data.name === 'NSE') ? 'bse' : 'nse';
    }

    oncategorySelection($event: any) {
        if ($event.value !== 'ALL')
          this.filterObj['type'] = $event.value;
        else
          delete this.filterObj['type'];
    }

    showData(data) {
        if (data === '')
        {
            delete this.filterObj['scriptoken'];
            delete this.filterObj['symbol'];
        }
        else
        {
            this.filterObj['scriptoken'] = data['omtkn'];
            this.filterObj['symbol'] = data['tsym'];
        }
    }

    datetypeChange($event: any) {
        this.filterObj['datetype'] = $event.value;
    }

    updateStartDate(event) {
        if (this.filterObj['startdate'] >= new Date(this.filterObj['enddate'])) {
            this.filterObj['enddate'] = moment(new Date(new Date(this.filterObj['startdate'])
                .getFullYear(), new Date(this.filterObj['startdate']).getMonth(), new Date(this.filterObj['startdate']).getDate() + 1)).format('Y-MM-DD');
        }
        // this.date1 = new Date(this.filterObj['enddate']);
        // this.date2 = new Date(this.filterObj['startdate']);
        // const diffTime = Math.abs(this.date2 - this.date1);
        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // if (diffDays > 365) {
        //     this.snackBar.open(this.translate.instant('events.date_validation'), '', { duration: 3000 });
        //     this.filterObj['enddate'] = moment(new Date(new Date(this.filterObj['startdate'])
        //         .getFullYear() + 1, new Date(this.filterObj['startdate']).getMonth(), new Date(this.filterObj['startdate']).getDate())).format('Y-MM-DD');
        // } else
            this.filterObj['startdate'] = moment(this.filterObj['startdate']).format('Y-MM-DD');
    }

    updateEndDate(event) {
        if (this.filterObj['enddate'] < new Date(this.filterObj['startdate'])) {
            this.filterObj['startdate'] = moment(new Date(new Date(this.filterObj['enddate'])
                .getFullYear(), new Date(this.filterObj['enddate']).getMonth(), new Date(this.filterObj['enddate']).getDate() - 1)).format('Y-MM-DD');
        }
        // this.date1 = new Date(this.filterObj['enddate']);
        // this.date2 = new Date(this.filterObj['startdate']);
        // const diffTime = Math.abs(this.date2 - this.date1);
        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        // if (diffDays > 365) {
        //     this.snackBar.open(this.translate.instant('events.date_validation'), '', { duration: 3000 });
        //     this.filterObj['startdate'] = moment(new Date(new Date(this.filterObj['enddate'])
        //         .getFullYear() - 1, new Date(this.filterObj['enddate']).getMonth(), new Date(this.filterObj['enddate']).getDate())).format('Y-MM-DD');
        // } else
            this.filterObj['enddate'] = moment(this.filterObj['enddate']).format('Y-MM-DD');
    }

    onResetMode() {
        /*this.activeCategory.forEach(function (element) {
            element.checked = true;
            element.disabled = false;
        });
        this.selectedcategory = this.categories[0]['value'];
        this.filterObj = {
            datetype: 'record_date',
            startdate: moment(new Date()).format('Y-MM-DD'),
            enddate: moment(new Date(new Date().getFullYear(), new Date().getMonth() + 3, new Date().getDate())).format('Y-MM-DD'),
            type: this.categories[0]['value'],
            exchange: ['nse_cm', 'bse_cm']
        };
        if (this.advancesearch !== undefined)
          // this.advancesearch.mtinput.nativeElement.value = '';
        delete this.filterObj['symbol'];
        delete this.filterObj['scriptoken'];
        //this.onChangeMode();*/
        this.isLoading = true;
        this.load.emit(true);
        this.loaderEvent.emit(true);
        this.PS.getEventService().getAllEvents(false, this.start, this.limit,'', '', this.getdataResponse.bind(this));
    }
    getdataResponse(code, result, message){
        if (!Utility.IS_MOBILE) {
            DialogRef.close({code: code, data: result, message: message, filter: this.filterObj});
        }
        else {
           SheetRef.dismiss({code: code, data: result, message: message, filter: this.filterObj});
        }
    }

    onChangeMode() {
        const aftertwoyear = moment(new Date(new Date(this.filterObj['startdate']).getFullYear() + 2, new Date(this.filterObj['startdate'])
            .getMonth(), new Date(this.filterObj['startdate']).getDate())).format('Y-MM-DD');
        if (this.filterObj['enddate'] > aftertwoyear) {
            if (this.filterObj['symbol'] === undefined)
                this.flag = true;
        }
        else
            this.flag = false;
        if (this.filterObj['type'] === 'ALL' && this.filterObj['symbol'] === undefined && this.flag)
        { }
        else
        {
            if (this.filterObj['type'] === 'ALL')
                delete this.filterObj['type'];
            this.filterObj['exchange'] = JSON.stringify(this.filterObj['exchange']);
            this.PS.getEventService().fetchFilterData(this.filterObj, (code, result, message) => {
                this.openLink(code, result, message);
            });
        }
    }

}

@Component({
    selector: 'app-eventfilteroptionsheet',
    template: '<app-eventfilteroption [filterObjInput] = "dummydata"></app-eventfilteroption>'
})
export class EventFilterOptionBottomSheetComponent implements OnInit {
    dummydata: any;
    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, public sheetRef: MatBottomSheetRef<EventFilterOptionComponent>, private detector: ChangeDetectorRef) {
        SheetRef = sheetRef;
        this.dummydata  = data;
    }
    ngOnInit(){
        setTimeout( () => {
            this.detector.detectChanges();
        }, 500);
    }
}

@Component({
    selector: 'app-eventfilteroptionpopup',
    template: '<app-eventfilteroption [filterObjInput] = "dummydata"></app-eventfilteroption>'
})

export class EventFilterOptionPopupComponent implements OnInit {
    dummydata: any;
    constructor(public dialogRef: MatDialogRef<EventFilterOptionComponent>, private detector: ChangeDetectorRef) {
        DialogRef = dialogRef;
        this.dummydata  =   Utility.dialogData;
    }
    ngOnInit(){
        setTimeout( () => {
            this.detector.detectChanges();
        }, 500);
    }
}
