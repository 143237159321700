import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Router } from '@angular/router';
import { BasketDetailModel } from 'src/app/model/basketdetail.model';
import { Utility } from 'src/app/services/utility';
import { MatDialog } from '@angular/material/dialog';
import { BasketPlaceOrderComponent } from '../basketplaceorder/basketplaceorder.component';
import { CustomBasket } from '../customthematicbasket/custombasket/custombasket.component';
import { Subject, Subscription } from 'rxjs';
import { Preferences } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';

let basketdetail: BasketDetailComponent;
@Component({
    selector: 'app-basketdetail',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketdetail/basketdetail.component.html',
    styleUrls: ['./basketdetail.component.scss']
})

export class BasketDetailComponent implements OnInit, OnChanges {
    basketDataObj: any;
    goback = '1';
    isSearch = true;
    tabIndex = 1;
    subscription1: Subscription;
    subscription2: Subscription;
    newsdata;
    eventdata;
    isnewsdata = true;
    iseventdata = true;
    isLoading;

    static BASKET_NEWS_EVENT = new Subject<string>();
    static CHART_DATA_EVENT = new Subject<any>();
    basketDetailList: Array<BasketDetailModel>;
    total = 0;
    symbol = '';
    @Output() closeBasketDetail = new EventEmitter<any>();
    @Input() isOpen;
    isMobile;
    constructor(private PS: PtPlusRestService, private router: Router, private dialog: MatDialog) {
        basketdetail = this;
        this.isMobile = Utility.IS_MOBILE
        if (!Utility.IS_MOBILE) {
            basketdetail.goback = '2';
        }
        basketdetail.subscription1 = CustomBasket.BASKET_CORPORATE_EVENT.subscribe(data => {
            basketdetail.eventdata = data;
        });
        basketdetail.subscription2 = CustomBasket.BASKET_NEWS_DATA_EVENT.subscribe(data => {
            basketdetail.newsdata = data;
        });
    }
    onTabChanged(index) {
        basketdetail.tabIndex = index;
        switch (index) {
            case 2:
                if (basketdetail.isnewsdata) {
                    setTimeout(() => {
                        BasketDetailComponent.BASKET_NEWS_EVENT.next(basketdetail.newsdata);
                        basketdetail.isnewsdata = false;
                    }, 200);
                }
                break;
            case 3:
                if (basketdetail.iseventdata) {
                    setTimeout(() => {
                        CustomBasket.BASKET_CORPORATE_EVENT.next(basketdetail.eventdata);
                        basketdetail.iseventdata = false;
                    }, 200);
                }
                break;
        }

    }
    ngOnInit() {
        if(Utility.IS_MOBILE) {
            basketdetail.basketDataObj = Preferences.getItem('basketData');
            basketdetail.PS.getThematicBasketService().getBasketDetailData(basketdetail.basketDataObj.id, basketdetail.basketDetailDataDataResponse);
        }
    }
    ngOnChanges(){
        if(!Utility.IS_MOBILE) {
            basketdetail.basketDataObj = Preferences.getItem('basketData');
            basketdetail.PS.getThematicBasketService().getBasketDetailData(basketdetail.basketDataObj.id, basketdetail.basketDetailDataDataResponse);
        }
    }

    basketDetailDataDataResponse(responseData, code, message) {
        const stockSymbol = [];
        const scrip_token_arr = [];
        const exchange_segment_arr = [];
        if (code === 200 && message === 'SUCCESS') {
            basketdetail.basketDetailList = responseData;
            BasketDetailComponent.CHART_DATA_EVENT.next(basketdetail.basketDetailList);
            basketdetail.basketDetailList.forEach(element => {
                element['exchange'] = Utility.segmentToExchange(element['exchange_segment']);
                basketdetail.total += element['percent_weight'];
                basketdetail.symbol = element.symbol.split('-')[0];
                stockSymbol.push(basketdetail.symbol);
                scrip_token_arr.push(element['scrip_token']);
                exchange_segment_arr.push(element['exchange_segment']);
            });

            if (basketdetail.basketDataObj.basket_setting === 'proportional') {
                basketdetail.basketDetailList.forEach(element => {
                    element['percent_weight'] = Number(((element['percent_weight'] / basketdetail.total) * 100).toFixed(2));
                });
            }
        }
        else {
        }
        CustomBasket.BASKET_NEWS_DATA_EVENT.next(stockSymbol.toString());
        const obj =  {};
        obj['exsegarr'] = exchange_segment_arr.toString();
        obj['tokenarr'] = scrip_token_arr.toString();
        CustomBasket.BASKET_CORPORATE_EVENT.next(obj);
    }

    getBasketDetail(){
        const jsonObj = {'id': basketdetail.basketDataObj.id, 'amount':  basketdetail.basketDataObj.min_investment, 'investment_type': 'investment'};
        Utility.scripDetails = jsonObj;
    }

    placeOrder(obj) {
        this.getBasketDetail();
        const basketObj = { 'basket_setting': obj['basket_setting'], 'basket_name': obj['name'] };
        Preferences.setItem('basket_setting', basketObj);
        Preferences.setItem('DetailedBaskets', basketdetail.basketDetailList);
        if (Utility.IS_MOBILE) {
            basketdetail.router.navigate(['/basketplaceorder'], { queryParams: { 'basketOrderType': 'FirstPurchase' } });
        } else {
            this.closeBasketDetail.emit();
            Utility.openPage(basketdetail.router, 'basketdetail', 'basketplaceorder', BasketPlaceOrderComponent, basketdetail.dialog, 'popup', { 'basketOrderType': 'FirstPurchase' });
        }
    }
}
