<app-header  *ngIf = "isMobile" goback="1" [balanceValue]="withdrawbleAmount" [isLoading]="isLoading" [title]="'limits.withdraw_funds' | translate" [isWithdrawFunds]=true ></app-header>
<mat-progress-bar *ngIf="isLoading" style="position: fixed;left: 0; top:0;right:0;z-index:1100" mode="indeterminate"></mat-progress-bar>
<div  *ngIf = "!isMobile" class="rp-header" id="mat-dialog-title-2;">
    <div class="row">
        <div class="col-6">{{'limits.withdraw_funds' | translate}}</div>
        <div class="col-6 text-right primetext"><small class="subcolor ts-2 ">{{'limits.avl_cash' | translate}}:</small> {{withdrawbleAmount | pricepipe}}</div>
    </div>
</div>
<div *ngIf="!isWithdrawSuccess" class = "m-3" [class.data-container]="isMobile">
    <mat-dialog-content>
        <div *ngIf = "!isMobile" class="row pt-3 pb-3">
            <div class="col-sm-4">
                <div class="float-left tc-1"><span class="ts-1 subcolor" translate>limits.account_holder</span><br>{{holderName}}</div>
                <!--span class="ts-1 subcolor">UID:</span><br/>{{uid}}--></div>
            <div class="col-6 col-sm-4"><span class="ts-1 subcolor">{{'login.username' | translate}}</span><br/>{{uid}}</div>
        </div>
        <div *ngIf = "isMobile" class = "row pt-3">
            <div class = "col-6"><span class="ts-1 subcolor" translate>limits.account_holder</span><br>{{holderName}}</div>
            <div class="col-6 float-right tc-1"><span class="ts-1 subcolor" translate>login.username</span><br>{{uid}}</div>
        </div>
        <div  class="row pt-2 d-none">
            <div [class.col-sm-6] = "isMobile" [class.col-12] = "!isMobile">
                <mat-label translate>limits.segment</mat-label>
                <mat-chip-list class="nofilter">
                    <mat-chip *ngFor="let obj of enabledSegments; let i = index" (click)="onSegmentSelection(obj)" [class.imactive]="obj === segmentSelected">
                        {{obj}}</mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div class="row pt-2">
            <div [class.col-sm-6] = "isMobile" [class.col-12] = "!isMobile">
                <mat-form-field>
                    <mat-label>{{'books.amt' | translate}}</mat-label>
                    <input matInput (keyup)="validatePrice($event)"  autocomplete="off" #inputPrice inputmode="decimal" [(ngModel)]="amount"/>
                </mat-form-field>
            </div>
        </div>
        <!-- <div class="row pt-1">
            <div class = "col-12">
                <mat-checkbox [disabled] = "withdrawbleAmount === 0" (change) = "withdrawAll($event)">{{'limits.withdraw_all' | translate}}</mat-checkbox>
            </div>
        </div>
         -->
        <div class = "row pt-2">
            <div [class.col-sm-6] = "isMobile" [class.col-12] = "!isMobile">
                <mat-form-field>
                    <mat-label translate>limits.select_bank</mat-label>
                    <mat-select [(value)]="selectedAccount" (selectionChange)="onSelection($event.value)">
                       <mat-option *ngFor="let data of bankAccountDisplay" [value]="data.value">
                           {{data.viewValue}}
                       </mat-option>
                   </mat-select>
                 </mat-form-field>
            </div>
        </div>
        <div class="row pt-2 pb-4">
            <div class = "col-12 text-right">
                <button  id="rzp-button1" (click)="onContinueClick()" class="btn-positive w80" [disabled] = "disableContinue || amount === undefined || amount == 0 || amount === '' || bankAccountDisplay.length === 0" mat-flat-button>{{'limits.continue' | translate}}</button>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div  *ngIf="isWithdrawSuccess" class="row">
    <div class="col-12">
        <div class="p-2">
            <div class="row text-center mb-3" [class.data-container]="isMobile" >
                <div class="col-12" [class.mt-4] = "isMobile" *ngIf = "paymentStatus === 'Payment Successful'" [class.impositive]="paymentStatus === 'Payment Successful'">
                    <svg class="paysuccess" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="colorg" d="M226,346a19.86,19.86,0,0,0,14.14-5.86l120-120a20,20,0,1,0-28.28-28.28L226,297.72l-45.86-45.86a20,20,0,1,0-28.28,28.28l60,60A19.86,19.86,0,0,0,226,346Z"/>
                        <path class="nocolor" d="M256,20C128.08,20,20,128.08,20,256S128.08,492,256,492,492,383.92,492,256,383.92,20,256,20ZM115,105a10,10,0,1,1-10,10A10,10,0,0,1,115,105ZM405.92,405.93a216.52,216.52,0,0,1-67.72,46,206.9,206.9,0,0,1-164.4,0A218.75,218.75,0,0,1,60,338.2,206.78,206.78,0,0,1,43,256c0-38.45,13.13-78.55,38-115.94a10,10,0,1,1,16.66,11.06C75,185.23,63,221.5,63,256c0,104.62,88.38,193,193,193s193-88.38,193-193S360.62,63,256,63c-34.51,0-70.78,12-104.88,34.64A10,10,0,0,1,140.05,81c37.4-24.85,77.49-38,116-38a206.78,206.78,0,0,1,82.2,17A218.81,218.81,0,0,1,452,173.8a207,207,0,0,1,0,164.4A216.54,216.54,0,0,1,405.92,405.93ZM146,266a20,20,0,0,1,34.14-14.14L226,297.72,331.86,191.86a20,20,0,1,1,28.28,28.28l-120,120a20,20,0,0,1-28.28,0l-60-60A19.86,19.86,0,0,1,146,266Z"/>
                        <path class="colorg" d="M226,346a19.86,19.86,0,0,0,14.14-5.86l120-120a20,20,0,1,0-28.28-28.28L226,297.72l-45.86-45.86a20,20,0,1,0-28.28,28.28l60,60A19.86,19.86,0,0,0,226,346Z"/>
                        <path class="colorg" d="M491.38,157.66a266.09,266.09,0,0,0-137-137,244.81,244.81,0,0,0-196.68,0,266.09,266.09,0,0,0-137,137,244.81,244.81,0,0,0,0,196.68,266.09,266.09,0,0,0,137,137,244.81,244.81,0,0,0,196.68,0,266.09,266.09,0,0,0,137-137,244.81,244.81,0,0,0,0-196.68ZM256,492C128.08,492,20,383.92,20,256S128.08,20,256,20,492,128.08,492,256,383.92,492,256,492Z"/>
                        <path class="colorg" d="M452,173.8A218.81,218.81,0,0,0,338.2,60,206.78,206.78,0,0,0,256,43c-38.46,0-78.55,13.13-115.95,38a10,10,0,0,0,11.07,16.66C185.22,75,221.49,63,256,63c104.62,0,193,88.38,193,193S360.62,449,256,449,63,360.62,63,256c0-34.5,12-70.77,34.63-104.88A10,10,0,1,0,81,140.06C56.13,177.45,43,217.55,43,256a206.78,206.78,0,0,0,17,82.2A218.75,218.75,0,0,0,173.8,452a206.9,206.9,0,0,0,164.4,0A218.75,218.75,0,0,0,452,338.2a207,207,0,0,0,0-164.4Z"/>
                        <circle class="colorg" cx="115" cy="115" r="10"/>
                    </svg>
                    <p>
                        <span class="ts-5 fw-4" translate>limits.withdraw_success</span><br>
                        <span class="subcolor2" translate>limits.withdraw_amt</span>
                    </p>
                </div>
                <div class="col-12" [class.mt-4] = "isMobile"  *ngIf = "paymentStatus === 'Payment Unsuccessful'"  [class.imnegative] = "paymentStatus === 'Payment Unsuccessful'" >
                    <svg class="payunsuccess" id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="colorr" d="M186,346a19.86,19.86,0,0,0,14.14-5.86l48.79-48.79a10,10,0,0,1,14.14,0l48.79,48.79a20,20,0,1,0,28.28-28.28l-48.79-48.79a10,10,0,0,1,0-14.14l48.79-48.79a20,20,0,1,0-28.28-28.28l-48.79,48.79a10,10,0,0,1-14.14,0l-48.79-48.79a20,20,0,1,0-28.28,28.28l48.79,48.79a10,10,0,0,1,0,14.14l-48.79,48.79A20,20,0,0,0,186,346Z"/>
                        <path class="colorr" d="M491.38,157.66a266.09,266.09,0,0,0-137-137,244.81,244.81,0,0,0-196.68,0,266.09,266.09,0,0,0-137,137,244.81,244.81,0,0,0,0,196.68,266.09,266.09,0,0,0,137,137,244.81,244.81,0,0,0,196.68,0,266.09,266.09,0,0,0,137-137,244.81,244.81,0,0,0,0-196.68ZM256,492C128.08,492,20,383.92,20,256S128.08,20,256,20,492,128.08,492,256,383.92,492,256,492Z"/>
                        <path class="colorr" d="M452,173.8A218.81,218.81,0,0,0,338.2,60,206.78,206.78,0,0,0,256,43c-38.46,0-78.55,13.13-115.95,38a10,10,0,0,0,11.07,16.66C185.22,75,221.49,63,256,63c104.62,0,193,88.38,193,193S360.62,449,256,449,63,360.62,63,256c0-34.5,12-70.77,34.63-104.88A10,10,0,1,0,81,140.06C56.13,177.45,43,217.55,43,256a206.78,206.78,0,0,0,17,82.2A218.75,218.75,0,0,0,173.8,452a206.9,206.9,0,0,0,164.4,0A218.75,218.75,0,0,0,452,338.2a207,207,0,0,0,0-164.4Z"/>
                        <circle class="colorr" cx="114" cy="115" r="10"/>
                    </svg>
                    <p>
                        <span class="ts-5 fw-4" translate>limits.withdraw_unsuccess</span><br>
                        <span class="subcolor2" translate>placeorder.unsuccess_msg</span>
                    </p>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-5 py-2 subcolor2"  translate>placeorder.trans_id</div>
                <div class="col-7 py-2">{{withdrawalDetails.bill_id}}</div> 
            </div>
            <div class="row">
                <div class="col-5 py-2 subcolor2" translate>placeorder.trans_amt</div>
                <div class="col-7 py-2"> ₹ {{withdrawalDetails.amount/100 | pricepipe:2 }}</div>
            </div>
            <div class="row">
                <div class="col-5 py-2 subcolor2" translate>limits.remark</div>
                <div class="col-7 py-2">{{withdrawalDetails.remark}} </div>
            </div>
            <div class="row">
                <div class="col-12 py-4 border-t mt-3 text-right">
                    <button [disabled] = "withdrawbleAmount === 0" (click)="cancelClick()" class="btn-plain mr-2" [class.w80] = "!isMobile" [class.w100] = "isMobile"  mat-flat-button>{{('stock_detail.close' | translate)}}</button>
                    <button (click)="transactionDone()" class="btn-positive" [class.w80] = "!isMobile" [class.w100] = "isMobile"  mat-flat-button>{{'limits.withdraw_more' | translate}}</button>
                </div> 
            </div>
        </div>
    </div>
    
</div>
