import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { TableUtility } from '../../../../services/tableutility';
import { Preferences, ReturnType } from '../../../../services/Preferences';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ColumnState, Utility } from 'src/app/services/utility';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-managebooks',
    templateUrl: './managebooks.component.html',
})
export class ManagebooksComponent implements OnInit, OnChanges, OnDestroy {
    columns = [];
    isReset;
    isSave;
    isMobile;
    orientationSubscription: Subscription;
    @Input() pageName;
    @Input() isOpen;
    colStateMap = new Map<String, ColumnState>(); 
    @Output() closeManagebooks = new EventEmitter<any>();
    preferencesKey = {'orderbook': 'orderBookColumns', 'tradebook': 'tradeBookColumns', 'positionbook': 'positionBookColumns', 'holdingbook': 'holdingBookColumns'}
    isBack;

    constructor(private activatedRoute: ActivatedRoute, private location: Location) {
        this.isMobile = Utility.IS_MOBILE;
        this.activatedRoute.queryParams.subscribe(params => {
            this.pageName = params['name'];
        });
        this.isBack = true;
        this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
            if (!Utility.IS_MOBILE_LAND && this.isBack) {
                this.isBack = false;
                this.location.back();
            }
        });
    }

    ngOnDestroy(): void {
        this.orientationSubscription.unsubscribe();
    }

    ngOnChanges() {
        // Due to Caching of this component. It's necessary to implement OnChange and call getCols and CheckReset method
        // from here as OnInit dosen't get called in web.
        this.getCols();
        this.userSelectClick();
    }

    ngOnInit()
    {
        this.getCols();
        this.userSelectClick();
    }

    userSelectClick() {
        const defaultColKeys = this.getDefaultCols();
        let count =  0;
        this.columns.forEach(item => {
            if ((item.isChecked && defaultColKeys.includes(item.key)) || item.isChecked) {
                count ++;
            } 
        });
        this.isReset = (count === defaultColKeys.length) ? false : true;
    }

    getCols() {
        const tempColumns = Preferences.getItem(this.preferencesKey[this.pageName], ReturnType.Undefined);
        switch(this.pageName)
        {
            case 'orderbook':
                // JSON.parse(JSON.stringyfy()) is used here for deep cloning of array of objects
                this.columns = tempColumns? JSON.parse(JSON.stringify(tempColumns)) : JSON.parse(JSON.stringify(TableUtility.orderBookColumns));
                break;
            case 'tradebook':
                this.columns = tempColumns? JSON.parse(JSON.stringify(tempColumns)): JSON.parse(JSON.stringify(TableUtility.tradeBookColumns));
                break;
            case 'positionbook':
                this.columns = tempColumns? JSON.parse(JSON.stringify(tempColumns)): JSON.parse(JSON.stringify(TableUtility.positionBookColumns));
                break;
            case 'holdingbook':
                this.columns = tempColumns? JSON.parse(JSON.stringify(tempColumns)): JSON.parse(JSON.stringify(TableUtility.holdingBookColumns));
                break;
        }
        this.getColumnState();
    }

    getColumnState(){
        this.isSave = false;
        this.colStateMap.clear();
        this.columns.forEach((element)=>{
            this.colStateMap.set(element.key, ColumnState.Stable);
        });
        
    }

    save() {
        Preferences.setItem(this.preferencesKey[this.pageName], this.columns);
        switch(this.pageName)
        {
            case 'orderbook':
                    TableUtility.updateOrderBookColumns.next()
                break;
            case 'tradebook':
                    TableUtility.updateTradeBookColumns.next()
                break;
            case 'positionbook':
                    TableUtility.updatePositionBookColumns.next()
                break;
            case 'holdingbook':
                    TableUtility.updateHoldingBookColumns.next()
                break;
        }
        if (this.isMobile) {
            if (this.pageName === "tradebook") {
                Utility.selectedBookTab = 1;
            } else if (this.pageName === "holdingbook") {
                Utility.selectedPortfolioTab = 1;
            }
            this.location.back()
        } else {
            this.closeManagebooks.emit();
        }
    }
   
    resetCol() {
        this.isReset = false;
        this.columns = [];
        switch(this.pageName)
        {
            case 'orderbook':
                this.columns = JSON.parse(JSON.stringify(TableUtility.orderBookColumns));
                break;
            case 'tradebook':
                this.columns = JSON.parse(JSON.stringify(TableUtility.tradeBookColumns));
                break;
            case 'positionbook':
                this.columns = JSON.parse(JSON.stringify(TableUtility.positionBookColumns));
                break;
            case 'holdingbook':
                this.columns = JSON.parse(JSON.stringify(TableUtility.holdingBookColumns));
                break;
        }
        this.save();
    }

    checkReset(ev, data)
    {
        this.userSelectClick();
        (this.colStateMap.get(data.key) === ColumnState.Stable) ? this.colStateMap.set(data.key, ColumnState.Modified) : this.colStateMap.set(data.key, ColumnState.Stable);
        this.isSave =false;
        for (const [key, value] of this.colStateMap.entries()) {
            if(value === ColumnState.Modified){
                this.isSave = true;
                break;
            }
        }
    }

    close()
    {
        this.closeManagebooks.emit();
    }

    getDefaultCols() {
        let bookColumns;
        let defaultCols = [];

        switch(this.pageName)
        {
            case 'orderbook':
                bookColumns = TableUtility.orderBookColumns
                break;
            case 'tradebook':
                bookColumns = TableUtility.tradeBookColumns;
                break;
            case 'positionbook':
                bookColumns = TableUtility.positionBookColumns;
                break;
            case 'holdingbook':
                bookColumns = TableUtility.holdingBookColumns;
                break;
        }
        bookColumns.forEach(element => { 
            if (element.isChecked)
                defaultCols.push(element.key);
        });
        return defaultCols;
    }
}
