<app-titlebar *ngIf="!isMobile" [title]="'events.event_filter' | translate"></app-titlebar>
<mat-dialog-content class="narrowpadding">
    <div class="row sameer">
        <div class="col-12">
            <div class="row myrow2">
                <div class="col-12">
                    <div>
                        <small class="primetext mr-3" translate>alert.exchange</small>
                        <ng-container *ngFor="let data of activeCategory; let i = index">
                            <mat-checkbox [(ngModel)]="data.checked" [disabled]="data.disabled" (change)="onChangeExchange(data)" [value]="data.value" >{{data.name}}</mat-checkbox>&nbsp;
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row myrow2">
                <div class="col-12">
                    <div class="opensearch">
                        <app-autocompletesearch [pageName]="searchId" [searchData] = "searchData" (selectData)="showData($event)" #advancesearch></app-autocompletesearch>
                    </div>
                </div>
            </div>
            <div class="row myrow2">
                <div class="col-12">
                    <div class="primetext pb-1"><small translate>events.date_type</small></div>
                    <mat-radio-group aria-label="Select an option" fxFlex fxLayoutAlign="end center" (change)="datetypeChange($event)" >
                        <mat-radio-button value="record_date"  [checked]="filterObj.datetype == 'record_date'" ><span translate>events.record_date</span></mat-radio-button>
                        <mat-radio-button value="ex_date" [checked]="filterObj.datetype == 'ex_date'" ><span translate>events.expiry_date</span></mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field fxLayoutAlign="end start" fxFlex="" >
                        <mat-label translate>events.category</mat-label>
                        <mat-select (selectionChange)="oncategorySelection($event)" [(value)]="selectedcategory">
                            <mat-option *ngFor="let cat of categories" [value]="cat.value">
                                {{cat.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <input matInput #start   [matDatepicker]="picker" [placeholder]="'placeorder.startdate' | translate" [(ngModel)]="filterObj.startdate"  (dateChange)="updateStartDate(start.value)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                        </mat-form-field>  
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <input matInput #end  [matDatepicker]="picker1" [placeholder]="'events.end_date' | translate" [(ngModel)]="filterObj.enddate" (dateChange)="updateEndDate(end.value)">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-right btn-margin">
                    <button mat-flat-button (click) = "onResetMode()"><span translate>events.reset</span></button>
                    <button mat-flat-button class="btn-positive" (click) = "onChangeMode()"><span translate>events.apply</span></button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
