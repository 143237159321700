<div *ngIf = 'isMobile'>
    <app-stockheader [isPlaceOrder] = true [scripModel] = "scrpModel"></app-stockheader>
    <div class ="data-container pb-5" *ngIf = "!isOrderSummary">
        <div>
            <div class="row">
                <div class="col-10 pt-3" *ngIf = "isInterop && !isEdit">
                    <section class="exchange-radio" [class.buy-r]="pom.Ttranstype=='B'" [class.sell-r]="pom.Ttranstype=='S'">
                        <mat-radio-group>
                            <mat-radio-group [(ngModel)] = "selectedExch" aria-label="Select an option" (change) = "onExch($event)">
                                <ng-template ngFor let-extype [ngForOf]="scripArray">
                                    <mat-radio-button [disabled] = "isDisableForm" [id] = "extype.ExchangeName" class="pr-1" [value]="extype.ExchangeName">
                                        <span class="ts-2">
                                            {{extype.ExchangeName}}: {{(this.pom.Ttranstype === 'B' ? extype.BestSellPrice : extype.BestBuyPrice) | pricepipe: extype.DecimalPrecision}}
                                        </span>
                                    </mat-radio-button>
                                </ng-template>
                            </mat-radio-group>
                        </mat-radio-group>
                      </section>
                </div>
                <span class="col-10" *ngIf = "!isInterop"></span>
                <button class="col-2 mt-1" tabindex="-1" mat-icon-button (click) = "openSettings()" matTooltip="{{'all.settings' | translate}}" [disabled] = "isDisableForm"><i class="icon-setting"></i></button>
            </div>
            <mat-card class="stockdetailstop">
                <div class="row smalltext mt-2 myrow ">
                    <div class="col-6 col-sm-3"><span class="subcolor2">{{'all.open' | translate}}:</span><span> {{scrpModel.OpenPrice | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                    <div class="col-6 col-sm-3 text-right"><span class="subcolor2">{{'all.low' | translate}}:</span><span> {{scrpModel.Low | pricepipe: scrpModel.DecimalPrecision}}</span></div>

                    <div class="col-6 col-sm-3"><span class="subcolor2">{{'all.high' | translate}}:</span><span> {{scrpModel.High | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                    <div class="col-6 col-sm-3 text-right"><span class="subcolor2">{{'all.prv_close' | translate}}:</span><span> {{scrpModel.PrvClose | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                </div>
                
                <div class="row btn-margin">
                    <div class="col-6">
                        <button mat-flat-button (click) = "openDepth()" class="btn-primary"><span translate>all.depth</span><i *ngIf = "!isShowDepth" class="icon-godown"></i><i *ngIf = "isShowDepth" class="icon-goup"></i></button>
                    </div>
                    <div class="col-6 text-right">
                        <button mat-flat-button [class.custom-btn]="pom.Ttranstype!=='B'" [class.btn-positive]="pom.Ttranstype=='B'" (click) = "changeTransectionType('B')" [disabled] = "isEdit  || isSquareOff || isDisableForm"><span translate>all.buy</span></button>
                        <button mat-flat-button  [class.custom-btn]="pom.Ttranstype!=='S'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = "changeTransectionType('S')" [disabled] = "isEdit  || isSquareOff || isDisableForm"><span translate>all.sell</span></button>
                    </div>
                </div>
                    
                <div [@slideInOut] = depthAnimation [hidden] = "!isShowDepth">
                    <app-depth [omtkn] = "pom.Token" isPlaceOrder="true" [exseg] = "pom.Exchangeseg" (priceEmit) = "priceFormDepth($event)"></app-depth>
                </div>
            </mat-card>
            <div [class.buy]="pom.Ttranstype=='B'" [class.sell]="pom.Ttranstype=='S'"  class="placeorderwrapper">
                <div class="row placeorderfield">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label><span translate>all.basket_qty </span><span *ngIf = "isLot || scrpModel.BodLotQuantity > 1" class="lotLable">(1 {{'all.lt' | translate}} = {{scrpModel.BodLotQuantity | numberpipe}}{{'all.qty' | translate}})</span></mat-label>
                            <input [disabled] = "isQtyDisable || isDisableForm" matInput type="tel" [placeholder]="'all.basket_qty' | translate" #inputQty [(ngModel)]="pom.qty" (keyup)="validateQty($event)" (keyup.enter)="onEnter('price')" maxlength="8" autocomplete="off">
                        </mat-form-field>
                        <br *ngIf = "isLot"/>
                        <span *ngIf = "isLot || scrpModel.BodLotQuantity > 1" class="lotLable" [class.imnegative] = "lotError">{{'all.lots' | translate}} = {{calculateLots(pom.qty, scrpModel.BodLotQuantity) | numberpipe}}</span>
                    </div>
                    <div class="col-6" *ngIf = "isEnablePrice">
                        <mat-form-field>
                            <mat-label translate>all.prc</mat-label>
                            <input id="price" (keyup.enter)="onEnter('retention')"  [disabled] = "isDisableForm || disablePrice" matInput type="tel" [placeholder]="'all.prc' | translate" #inputPrice [(ngModel)]="pom.Price" (keyup)="validatePrice($event)" inputmode="decimal">
                        </mat-form-field>
                    </div>
                    <div class="col-6" *ngIf = "isMktPro">
                        <mat-form-field>
                            <mat-label translate>all.mktpro</mat-label>
                            <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'all.mktpro' | translate" #inputMktPro [(ngModel)]="pom.MktPro" (keyup)="validateMKTPro($event)" autocomplete="off">
                        </mat-form-field>
                    </div>
                </div>
                
                    <div class="row">
                        <div class="col-sm-3 ">
                            <div class="mt-1"><small class="primetext" translate>all.producttype</small></div>
                            <mat-radio-group [(ngModel)]="pom.Pcode" aria-label="Select an option" [disabled] = "isEdit || isSquareOff || !isProductType || isDisableForm" (change) = "onProductTypeChange()">
                                <ng-template ngFor let-prtype [ngForOf]="productArr">
                                    <mat-radio-button [id] = "prtype" class="pr-1" [value]="prtype">{{prtype | valuepipe}}</mat-radio-button>
                                </ng-template>
                            </mat-radio-group>
                        </div>

                        <div class="col-sm-6 rowmargin2">
                            <div class="mt-1" ><small class="primetext" translate>all.ordertype</small></div>
                            <mat-radio-group [disabled] = "(isEdit && pom.Pcode == 'BO') || isDisableForm || !isOrderType" [(ngModel)]="pom.prctyp" aria-label="Select an option" (change) = "onOrderTypeChange($event)"> 
                                <ng-template ngFor let-ortype [ngForOf]="orderTypeArray">
                                    <mat-radio-button *ngIf="orarr.includes(ortype)" class = "pr-1" [value]="ortype">{{ortype | valuepipe}}</mat-radio-button>
                                </ng-template>
                            </mat-radio-group>
                        </div>

                        <div class="col-sm-3 pr-0">
                            <div class="mt-1" ><small class="primetext" translate>all.verity</small></div>
                            <mat-radio-group aria-label="Select an option" [(ngModel)] = 'verityType' (change) = "onVerityTypeChange()" [disabled] = "isEdit  || isSquareOff || isDisableForm"    >
                                <mat-radio-button class="pr-1" value="RGLR">RGLR</mat-radio-button>
                                <mat-radio-button class="pr-1" value="BO" *ngIf = "advancedProductArray.includes('BO')">BO</mat-radio-button>
                                <mat-radio-button class="pr-1" value="CO" *ngIf = "advancedProductArray.includes('CO')">CO</mat-radio-button>
                                <mat-radio-button class="pr-1" value="AMO">AMO</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </div>
                    
                    <!-- <div class="row" *ngIf = "isSL || checkSL()" [ngStyle]="{minHeight: '40px', maxHeight: '40px'}">
                        <div class="col-sm-4">
                            <mat-form-field class="w-100">
                                <mat-label translate>books.trig_price</mat-label>
                                <input [disabled] = "isDisableForm || checkSL()" matInput type="tel" [placeholder]="'books.trig_price' | translate" [(ngModel)]="pom.TrigPrice" autocomplete="off" (keyup)="validateTriggerPrice($event)" #inputTriggerPrice>
                            </mat-form-field>
                        </div>
                    </div> -->
                    
                    <div class="row mt-2 placeorderfield" *ngIf = "isSL || (pom.Pcode == 'CO' && (isEdit? pom.Status.toLowerCase() != 'open': true))">
                        <div class="col-6">
                            <mat-form-field>
                                <mat-label translate>all.trig_price</mat-label>
                                <input [disabled] = "isDisableForm || disableTriggerPrice" matInput type="tel" [placeholder]="'all.trig_price' | translate" [(ngModel)]="pom.TrigPrice" autocomplete="off" (keyup)="validateTriggerPrice($event)" #inputTriggerPrice inputmode="decimal">
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf = "pom.Pcode == 'CO' && (isEdit? pom.Status.toLowerCase() != 'open': true)">
                            <div class="primetext ts-1" translate>all.triggerrange</div>
                            <div class="pt-1">{{scrpModel.TriggerRangeStart | pricepipe: scrpModel.DecimalPrecision}} - {{scrpModel.TriggerRangeEnd | pricepipe: scrpModel.DecimalPrecision}} ( {{scrpModel.CoverPercentage}}% )</div>
                        </div>
                    </div>
                    <div class="row mt-2 placeorderfield" *ngIf = "pom.Pcode == 'BO' && !isEdit">
                        <div class="col-6">
                            <mat-form-field>
                                <mat-label translate>all.squareoffvalue</mat-label>
                                <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'all.squareoff' | translate" #inputSquareOffvalue [(ngModel)]="squareOffvalue" (keyup)="validateSquareOffvalue($event)" autocomplete="off">
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field>  
                                <mat-label translate>all.stoplossvalue</mat-label>
                                <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'all.squareoff' | translate" #inputStoplossValue [(ngModel)]="stoplossValue" (keyup)="validateStoplossValue($event)" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-6">
                            <mat-form-field [ngStyle] = "">
                                <mat-label translate>all.retentiontype </mat-label>
                                <mat-select id="retention" (keyup.enter)="onSubmit()"  [disabled] = "(isEdit && (pom.Pcode == 'BO' || pom.Pcode == 'CO')) || isDisableForm" (selectionChange)="onRetentionChange($event)" [(value)]="pom.Ret">
                                    <mat-option *ngFor="let obj of retentionTypeArray" [value]="obj">
                                        {{obj}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf = "pom.Ret == 'GTD'">
                            <mat-form-field [disable] = "isDisableForm">
                                <mat-label translate>all.choosedate</mat-label>
                                <input matInput [min]="GTDMindate" [matDatepicker]="picker" [(ngModel)] = 'GTDVal' autocomplete="off" disabled>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf = "pom.Ret == 'GTDys'">
                            <mat-form-field>
                                <mat-label translate>all.validity</mat-label>
                                <mat-select [(value)]="GTDayaVal" [disbaled] = "isDisableForm">
                                    <mat-option *ngFor="let obj of daysArr" [value]="obj">
                                        {{obj}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf = "pom.Ret == 'GTT'">
                            <mat-form-field [ngStyle] = "{width: '150px'}">
                                <input [disabled] = "isDisableForm" matInput type="time" [placeholder]="'all.startdate' | translate" [(ngModel)] = 'GTTval' autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf = 'isDiscQty'>
                        <div class="col-6">
                            <mat-form-field>
                                <mat-label translate>all.discqty</mat-label>
                                <input [disabled] = "isDisableForm" matInput type="tel" (keyup)="validateDiscQty($event)" [placeholder]="'all.discqty' | translate" [(ngModel)]="pom.discqty" autocomplete="off" #discQty>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="footerbtn" *ngIf="!isDisableForm">
                    <div class ="row text-left mb-1">
                        <div class="col-12">
                            <span class="ts-3 pl-1">{{'limits.avl_margin' | translate}}: <span class="primetext">{{balance | pricepipe}}</span></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left btn-negative" *ngIf="!isPO">
                            <div class="ts-1 p-1"><i class="icon-info-alert"></i> {{'all.error_place' | translate}}</div>
                        </div>
                    </div>
                    <button *ngIf = '!isEdit' mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" class="w-100" (click) = 'onSubmit()' [disabled] = "!isPO"><span translate class="text-uppercase">all.place</span></button>
                    <button *ngIf = 'isEdit' mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" class="w-100" (click) = 'onSubmit()' [disabled] = "!isPO"><span translate>all.modify_order</span></button>
                </div>

                <div class="footerbtn" *ngIf="isDisableForm">
                    <div class ="row text-left mb-1">
                        <div class="col-12">
                            <span class="ts-3 pl-1">{{'limits.avl_margin' | translate}}: <span class="primetext">{{balance | pricepipe}}</span></span>
                        </div>
                    </div>
                    <span *ngIf="isDisableForm"><button mat-flat-button class="btn-primary w-25" (click) = 'onEdit()'><span translate>all.edit</span></button></span>
                    <span *ngIf="isDisableForm"><button class="w-75" mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = 'onConfirm()'><span translate class="text-uppercase">all.confirm_btn</span></button></span>
                </div>
                
            </div>
            
    </div>

    <div *ngIf = "isOrderSummary" [class.data-container] = 'isMobile'>
        <div class="row">
            <div class="col-12 pt-lg-0 pt-2">{{'all.place_msg' | translate}}
            </div>
        </div>
        <div *ngFor = "let orderObj of orderObjArr" class="watchlistwrapper">
            <div *ngIf = "orderObj">
                <div>{{orderObj.TradeSymbol}} <small>{{orderObj.Exchange}}</small></div>
                <div class="row">
                    <div class="col-6">
                        <div class="line2 subcolor">{{'all.orderid' | translate}}<br/>{{orderObj.NestOrd}}</div>
                    </div>
                    <div class="col-6">
                        <div class="line2 subcolor text-right">{{'all.orderstatus' | translate}}<br/><span class="primetext textuppercase">{{orderObj.Status}}</span></div>
                    </div>
                </div><br/>
                <div class="line2 subcolor" *ngIf = "orderObj.Status == 'rejected'">{{'all.rejection_reason' | translate}}: {{orderObj.RejectionReason}}</div>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-center btn-margin">
                            <button mat-flat-button class = "btn-primary" (click) = 'onModify(orderObj)' *ngIf = "orderObj && orderObj.IsEdit"><span translate>all.modify_order</span></button>
                            <button mat-flat-button class = "btn-negative" (click) = 'onCancel(orderObj)' *ngIf = "orderObj && orderObj.IsCancel"><span translate>all.cancel_order</span></button>
                            <div class="d-inline" *ngIf="orderObj && orderObj.IsExit">
                                <button mat-flat-button class="btn-primary" (click)="onExit(orderObj)"><span translate>all.exit</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-dialog-actions class="mat-dialog-actions d-block m-lg-2 text-right mb-0 pt-0" *ngIf="!onetouch">   
            <button [class.col]="isMobile" mat-flat-button class = "btn-primary" (click) = 'onPlaceAnother()'>
                <span translate class="text-uppercase">all.place_another</span></button>
                <button [class.col]="isMobile" mat-flat-button class = "btn-primary mt-2 ml-0" (click) = 'onOpenOrderBook()'>
                        <span translate class="text-uppercase">all.open_order_book</span></button>
        </mat-dialog-actions>
    </div>
</div>

<div *ngIf = '!isMobile'>
    <div mat-dialog-title="" class="mat-dialog-title" id="mat-dialog-title-2">
        <div class="row">
            <div class="col-2"><h1>{{'all.place' | translate}}</h1></div>
            <div class="col-6 border-l mt-1"><div class="expand-search" *ngIf = "(!isDisableForm && !isEdit && !isOrderSummary) || scrpModel.Token === 'asdfg'"><app-autocompletesearch [frompage] = "'isPlaceOrder'" pageName="placeorder" (selectData)="selectData($event)"></app-autocompletesearch></div></div>
            <div class="col-4 text-right">
                <span class="ts-3" *ngIf = "!isOrderSummary">{{'limits.avl_margin' | translate}}: <span class="primetext">{{balance | pricepipe}}</span></span>
                <button *ngIf = "!isOrderSummary" tabindex="-1" mat-icon-button (click) = "openSettings()" matTooltip="{{'all.settings' | translate}}" [disabled] = "isDisableForm"><i class="icon-setting"></i></button>
                <button tabindex="-1" mat-icon-button (click) = "close()"><i class="icon-close"></i></button>
            </div>
        </div>
    </div>
    <div *ngIf = "!isOrderSummary">
        <div class="row">
            <div [class.col-12]="nodepth" [class.col-8]="!nodepth" >
                <mat-dialog-content class="h-auto narrowpadding">
                <div [class.p-50]="isPopup">
                    <div class="row stockdetailstop pb-2">
                        <div class="col-xl-6 col-sm-4">
                            <div class="firstline"><span class="symbolname">{{((scrpModel.ExchangeSegment == 'nse_cm' || scrpModel.ExchangeSegment == 'bsc_cm') ? scrpModel.TradingSymbol : scrpModel.Symbolname) | symbolpipe}}&nbsp;<span class="ts-2" *ngIf = "scrpModel.ExchangeName === 'BSE'">({{scrpModel.InstrumentName}})</span><small>{{scrpModel.ExchangeName}}</small></span></div>
                            <div class="line2">{{scrpModel.DisplaySymbolDescription}}</div>
                        </div>
                        <div class="col-xl-3 col-sm-4 text-right" [class.imnegative]="scrpModel.Change<0" [class.impositive]="scrpModel.Change>0" >
                            <div [class.imnegativebg]="scrpModel.TickDirection === -1" [class.impositivebg]="scrpModel.TickDirection === 1">
                                <div class="firstline">{{scrpModel.LastTradePrice | pricepipe: scrpModel.DecimalPrecision}}</div>
                                <div class="line2">{{scrpModel.Change | pricepipe: scrpModel.DecimalPrecision}} ({{scrpModel.PercentageChange | percentagepipe}})</div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-4 text-right">
                            <button mat-flat-button tabindex="-1" [class.custom-btn]="pom.Ttranstype!=='B'" [class.btn-positive]="pom.Ttranstype=='B'" (click) = "changeTransectionType('B')" [disabled] = "isEdit  || isSquareOff || isDisableForm"><span translate>all.buy</span></button> &nbsp;
                            <button mat-flat-button tabindex="-1" [class.custom-btn]="pom.Ttranstype!=='S'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = "changeTransectionType('S')" [disabled] = "isEdit  || isSquareOff || isDisableForm"><span translate>all.sell</span></button>
                        </div>
                    </div>
                    <div *ngIf = "isInterop && !isEdit" class="row myrow">
                        <div class="col-12">
                            <section class="exchange-radio" [class.buy-r]="pom.Ttranstype=='B'" [class.sell-r]="pom.Ttranstype=='S'">
                                <mat-radio-group [(ngModel)] = "selectedExch" aria-label="Select an option" (change) = "onExch($event)">
                                    <ng-template ngFor let-extype [ngForOf]="scripArray">
                                        <mat-radio-button [disabled] = "isDisableForm" [value]="extype.ExchangeName">
                                            <span class="ts-2">{{extype.ExchangeName}}: {{(this.pom.Ttranstype === 'B' ? extype.BestSellPrice : extype.BestBuyPrice) | pricepipe: extype.DecimalPrecision}}</span>
                                        </mat-radio-button>
                                    </ng-template>
                                </mat-radio-group>
                            </section>
                        </div>
                    </div>
                    <div class="row myrow smalltext">
                        <div class="col-3"><span class="subcolor2">{{'all.open' | translate}}:</span><span> {{scrpModel.OpenPrice | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                        <div class="col-3 text-center"><span class="subcolor2">{{'all.high' | translate}}:</span><span> {{scrpModel.High | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                        <div class="col-3 text-center"><span class="subcolor2">{{'all.low' | translate}}:</span><span> {{scrpModel.Low | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                        <div class="col-3 text-right"><span class="subcolor2">{{'all.prv_close' | translate}}:</span><span> {{scrpModel.PrvClose | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                    </div>
                    <div [class.buy]="pom.Ttranstype=='B'" [class.sell]="pom.Ttranstype=='S'" class="">
                        <div class="row myrow">
                            <div class="col-12 col-md-6">
                                <div class="mt-1"><small class="primetext" translate>all.producttype</small></div>
                                <mat-radio-group [(ngModel)]="pom.Pcode" aria-label="Select an option" [disabled] = "isEdit || isSquareOff || isDisableForm" (change) = "onProductTypeChange()">
                                    <div class="row">
                                        <div class="col-12 mb-2">
                                        <ng-template ngFor let-prtype [ngForOf]="productArr">
                                            <mat-radio-button class = "pr-1" [value]="prtype">{{prtype | valuepipe}}</mat-radio-button>
                                        </ng-template></div>
                                    </div>
                                </mat-radio-group>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="mt-1"><small class="primetext" translate>all.ordertype</small> </div>
                                <mat-radio-group [disabled] = "(isEdit && pom.Pcode == 'BO') || isDisableForm || !isOrderType" [(ngModel)]="pom.prctyp" aria-label="Select an option" (change) = "onOrderTypeChange($event)"> 
                                    <div class="row"><div class="col-12 mb-2">
                                        <ng-template ngFor let-ortype [ngForOf]="orderTypeArray">
                                            <mat-radio-button *ngIf="orarr.includes(ortype)" class = "pr-1" [value]="ortype">{{ortype | valuepipe}}</mat-radio-button>
                                        </ng-template></div>
                                    </div>
                                </mat-radio-group>
                            </div>
                            
                        </div>
                        <div class="row myrow placeorderfield pt-3">
                            <div class="col-2">
                                <mat-form-field>
                                    <mat-label><span translate>all.basket_qty </span><span *ngIf = "isLot || scrpModel.BodLotQuantity > 1" class="lotLable">(1 {{'all.lt' | translate}} = {{scrpModel.BodLotQuantity | numberpipe}}{{'all.qty' | translate}})</span></mat-label>
                                    <input [disabled] = "isQtyDisable || isDisableForm" matInput type="tel" [placeholder]="'all.basket_qty' | translate" #inputQty [(ngModel)]="pom.qty" (keyup)="validateQty($event)" maxlength="8" autocomplete="off">
                                </mat-form-field><br/>
                                <span *ngIf = "isLot || scrpModel.BodLotQuantity > 1" class="lotLable" [class.imnegative] = "lotError">{{'all.lots' | translate}} = {{calculateLots(pom.qty, scrpModel.BodLotQuantity) | numberpipe}}</span>
                            </div>
                            <div class="col-2" *ngIf = "isEnablePrice">
                                <mat-form-field>
                                    <mat-label translate>all.prc</mat-label>
                                    <input  [disabled] = "isDisableForm || disablePrice" matInput type="tel" [placeholder]="'all.prc' | translate" #inputPrice [(ngModel)]="pom.Price" (keyup)="validatePrice($event)" autocomplete="off" inputmode="decimal">
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "isMktPro">
                                <mat-form-field>
                                    <mat-label translate>all.mktpro</mat-label>
                                    <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'all.mktpro' | translate" #inputMktPro [(ngModel)]="pom.MktPro" (keyup)="validateMKTPro($event)" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = 'isDiscQty'>
                                <mat-form-field>
                                    <mat-label translate>all.discqty</mat-label>
                                    <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'all.discqty' | translate" (keyup)="validateDiscQty($event)" [(ngModel)]="pom.discqty" autocomplete="off" #discQty>
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "isSL || (pom.Pcode == 'CO' && (isEdit? pom.Status.toLowerCase() != 'open': true))">
                                <mat-form-field>
                                    <mat-label><span translate>all.trig_price</span></mat-label>
                                    <input [disabled] = "isDisableForm || disableTriggerPrice" matInput type="tel" [placeholder]="'all.trig_price' | translate" [(ngModel)]="pom.TrigPrice" autocomplete="off" (keyup)="validateTriggerPrice($event)" #inputTriggerPrice inputmode="decimal">
                                </mat-form-field>
                            </div>
                            
                            <div class="col-2" *ngIf = "pom.Pcode == 'BO' && !isEdit">
                                <mat-form-field>
                                    <mat-label translate>all.squareoffvalue</mat-label>
                                    <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'all.squareoff' | translate" #inputSquareOffvalue [(ngModel)]="squareOffvalue" (keyup)="validateSquareOffvalue($event)" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "pom.Pcode == 'BO' && !isEdit">
                                <mat-form-field>
                                    <mat-label translate>all.stoplossvalue</mat-label>
                                    <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'all.squareoff' | translate" #inputStoplossValue [(ngModel)]="stoplossValue" (keyup)="validateStoplossValue($event)" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-2">
                                <mat-form-field>
                                    <mat-label translate>all.retentiontype </mat-label>
                                    <mat-select [disabled] = "(isEdit && (pom.Pcode == 'BO' || pom.Pcode == 'CO')) || isDisableForm" (selectionChange)="onRetentionChange($event)" [(value)]="pom.Ret">
                                        <mat-option *ngFor="let obj of retentionTypeArray" [value]="obj">
                                            {{obj}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "pom.Ret == 'GTD'">
                                    <mat-form-field [disabled] = "isDisableForm" [ngStyle] = "{width: '150px'}">
                                        <mat-label translate>all.choosedate</mat-label>
                                        <input matInput [min]="GTDMindate" [matDatepicker]="picker" [(ngModel)] = 'GTDVal' autocomplete="off" disabled>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            <div class="col-2" *ngIf = "pom.Ret == 'GTDys'">
                                <mat-form-field>
                                    <mat-label translate>all.validity</mat-label>
                                    <mat-select [disabled] = "isDisableForm" [(value)]="GTDayaVal">
                                        <mat-option *ngFor="let obj of daysArr" [value]="obj">
                                            {{obj}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "pom.Ret == 'GTT'">
                                <mat-form-field [ngStyle] = "{width: '150px'}">
                                    <input [disabled] = "isDisableForm" matInput type="time" [placeholder]="'all.startdate' | translate" [(ngModel)] = 'GTTval' autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-2 amoweb" *ngIf = "(pom.Pcode != 'CO') && (pom.Pcode != 'BO')">
                                <mat-checkbox [checked]="(pom.AMO=='YES')?true:false" (change) = "onAMO($event)" [disabled] = "isEdit || isDisableForm">AMO</mat-checkbox>
                            </div>
                            <div class="col-12 ts-1" *ngIf = "pom.Pcode == 'CO' && (isEdit? pom.Status.toLowerCase() != 'open': true)">
                                <small class="subcolor2" translate>all.triggerrange</small>: {{scrpModel.TriggerRangeStart | pricepipe: scrpModel.DecimalPrecision}} - {{scrpModel.TriggerRangeEnd | pricepipe: scrpModel.DecimalPrecision}} ( {{scrpModel.CoverPercentage}}% )
                            </div>
                        </div>
                        
                    </div>
                </div>
                </mat-dialog-content>
            <mat-dialog-actions class="mat-dialog-actions d-block text-right">
                <div *ngIf = "!isDisableForm || scrpModel.Token === 'asdfg'">
                    <div class="row">
                        <div class="col-9 p-0 imnegative pt-2">
                            <span *ngIf="!isPO"><i class="icon-info-alert"></i> {{'all.error_place' | translate}}</span>
                        </div>
                        <div class="col-3 text-right">
                            <button *ngIf = '!isEdit' mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = 'onSubmit()' [disabled] = "!isPO || scrpModel.Token === 'asdfg'"><span translate class="text-uppercase">all.place</span></button>
                            <button *ngIf = 'isEdit' mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = 'onSubmit()' [disabled] = "!isPO || scrpModel.Token === 'asdfg'"><span translate>all.modify_order</span></button>
                        </div>
                    </div>
                </div>
                <div [hidden]="!(isDisableForm && scrpModel.Token !== 'asdfg')">
                    <button mat-flat-button class="btn-primary" (click) = 'onEdit()'><span translate>all.edit</span></button>
                    <button mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = 'onConfirm()' id = "confirmbutton"><span translate class="text-uppercase">all.confirm_btn</span></button>
                </div>
            
            </mat-dialog-actions>
        </div>
        <div *ngIf="!nodepth" class="col-4 border-l  pr-3">
            <mat-card>
                <app-depth isPlaceOrder="true" [omtkn] = "pom.Token" [exseg] = "pom.Exchangeseg" (priceEmit) = "priceFormDepth($event)" class="height-intact"></app-depth>
            </mat-card>
        </div>
    </div>
    </div>
    <div *ngIf = "isOrderSummary">
        <div class="row m-2">
            <div class="col-12">
                <div class="impositivebg">{{'all.place_msg' | translate}}</div>
                <div *ngFor = "let orderObj of orderObjArr" class="watchlistwrapper mb-1">
                    <div *ngIf = "orderObj">
                        <div class="row">
                            <dv class="col-8">
                                <div class="row mb-1">
                                    <div class="col-4">
                                        <div class="firstline">{{orderObj.TradeSymbol}} <small>{{orderObj.Exchange}}</small></div>
                                        <div><small class="line2 subcolor2">{{'all.qty' | translate}}:</small> {{orderObj.TotalQty}} @ <small class="line2 subcolor2">{{'all.prc' | translate}}:</small> {{orderObj.Price}}</div>
                                    </div>
                                    <div class="col-4">
                                        <div class="line2 subcolor2">{{'all.orderid' | translate}}</div>
                                        <div class="">{{orderObj.NestOrd}}</div>
                                    </div>
                                    <div class="col-4">
                                        <div class="line2 subcolor2">{{'all.orderstatus' | translate}}</div>
                                        <div class="primetext mecaps">{{orderObj.Status}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="line2 subcolor2" *ngIf = "orderObj.Status == 'rejected'">{{'all.rejection_reason' | translate}}:</div>
                                        <div class="" *ngIf = "orderObj.Status == 'rejected'">{{orderObj.RejectionReason}}</div>
                                    </div>
                                </div>
                            </dv>
                            
                            <div class="col-4 text-right">
                                <button mat-flat-button class = "ml-1 btn-primary" (click) = 'onModify(orderObj)' *ngIf = "orderObj && orderObj.IsEdit"><span translate>all.modify_order</span></button>
                                <button mat-flat-button class = "ml-1 btn-negative" (click) = 'onCancel(orderObj)' *ngIf = "orderObj && orderObj.IsCancel"><span translate>all.cancel</span></button>
                                <button mat-flat-button class="ml-1 btn-primary" (click)="onExit(orderObj)" *ngIf="orderObj && orderObj.IsExit"><span translate>all.exit</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-dialog-actions class="mat-dialog-actions d-block pt-0" *ngIf="!onetouch">   
                    <div class="row">
                        <div class="col-12 text-right">
                            <button [class.col]="isMobile" mat-flat-button class = "btn-primary" (click) = 'onPlaceAnother()'><span translate class="text-uppercase">all.place_another</span></button>
                        </div>
                    </div>
                </mat-dialog-actions>
            </div>
        </div>
    </div>
</div>
