import { Injectable } from '@angular/core';
import { ILoginService } from './login/ilogin.service';
import { LoginService } from './login/login.service';
import { BookService } from './books/book.service';
import { IBookService } from './books/ibook.service';
import { ApiService } from './api.service';
import { IScripService } from './scrip/iscrip.service';
import { ScripService } from './scrip/scrip.service';
import { IWatchlistService } from './watchlist/iwatchlist.service';
import { WatchListService } from './watchlist/watchlist.service';
import { MarketDataFeeder } from './websocket/marketdatafeeder';
import { IUserService } from './user/iuser.service';
import { UserService } from './user/user.service';
import { IAlertService } from './alert/ialert.service';
import { AlertService } from './alert/alert.service';
import { IGraphService } from './graph/igraph.service';
import { GraphService } from './graph/graph.service';
import { IMarketService } from './market/imarket.service';
import { MarketService } from './market/market.service';
import { HSMarketService } from './market/hs.market.service';
import { IIndexService } from './index/iindex.service';
import { IndexService } from './index/index.service';
import { TradeFeeder } from './websocket/tradesocket/tradefeeder';
import { environment } from 'src/environments/environment';
import { HSIndexService } from './index/hs.index.service';
import { HSAlertService } from './alert/hs.alert.service';
import { HSUserService } from './user/hs.user.service';
import { HSWatchlistService } from './watchlist/hs.watchlist.service';
import { HSScripService } from './scrip/hs.scrip.service';
import { HSBookService } from './books/hs.book.service';
import { HSLoginService } from './login/hs.login.service';
import { HSGraphService } from './graph/hs.graph.service';
import { TRKDChartService } from './trkdchart/trkdchart.service';
import { ITRKDChartService } from './trkdchart/itrkdchart.service';
import { ITriggerService } from './trigger/itrigger.service';
import { HSTriggerService } from './trigger/hs.trigger.service';
import { IQProfileService } from './qprofile/iprofile.service';
import { QProfileService } from './qprofile/qprofile.service';

let tradingRestService: TradingRestService;
@Injectable()
export class TradingRestService {
    loginService: ILoginService;
    bookService: IBookService;
    graphService: IGraphService;
    scripService: IScripService;
    watchlistService: IWatchlistService;
    marketDataFeeder: MarketDataFeeder;
    userService: IUserService;
    alertService: IAlertService;
    marketService: IMarketService;
    indexService: IIndexService;
    tradeFeeder: TradeFeeder;
    trkdChartService: ITRKDChartService;
    triggerService: ITriggerService;
    qprofileService: IQProfileService;

    constructor(private tradeApiService: ApiService) {
        tradingRestService = this;
        tradingRestService.marketDataFeeder = new MarketDataFeeder();
        tradingRestService.tradeFeeder = new TradeFeeder();
    }

    getLoginService() {
        if (tradingRestService.loginService == null) {
            if (environment.isQuickRest) {
                tradingRestService.loginService = new HSLoginService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.loginService = new LoginService(tradingRestService.tradeApiService);
            }
            tradingRestService.loginService.setTradingRestService(tradingRestService);
        }
        return tradingRestService.loginService;
    }

    getBookService() {
        if (tradingRestService.bookService == null) {
            if (environment.isQuickRest) {
                tradingRestService.bookService = new HSBookService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.bookService = new BookService(tradingRestService.tradeApiService);
            }
            tradingRestService.bookService.setTradingRestService(tradingRestService);
        }
        return tradingRestService.bookService;
    }

    getScripService() {
        if (!tradingRestService.scripService) {
            if (environment.isQuickRest) {
                tradingRestService.scripService = new HSScripService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.scripService = new ScripService(tradingRestService.tradeApiService);
            }
        }
        return tradingRestService.scripService;
    }

    getWatchListService() {
        if (tradingRestService.watchlistService == null) {
            if (environment.isQuickRest) {
                tradingRestService.watchlistService = new HSWatchlistService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.watchlistService = new WatchListService(tradingRestService.tradeApiService);
            }
            tradingRestService.watchlistService.setTradingRestService(tradingRestService);
        }
        return tradingRestService.watchlistService;
    }

    getGraphService() {
        if (tradingRestService.graphService == null) {
            if(environment.isQuickRest) {
                tradingRestService.graphService = new HSGraphService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.graphService = new GraphService(tradingRestService.tradeApiService);
            }
        }
        return tradingRestService.graphService;
    }

    getTRKDChartService() {
        if (tradingRestService.trkdChartService == null) {
            tradingRestService.trkdChartService = new TRKDChartService(tradingRestService.tradeApiService);
        }
        return tradingRestService.trkdChartService;
    }


    getUserService() {
        if (!tradingRestService.userService) {
            if (environment.isQuickRest) {
                tradingRestService.userService = new HSUserService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.userService = new UserService(tradingRestService.tradeApiService);
            }
        }
        return tradingRestService.userService;
    }

    getAlertService() {
        if (tradingRestService.alertService == null) {
            if (environment.isQuickRest) {
                tradingRestService.alertService = new HSAlertService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.alertService = new AlertService(tradingRestService.tradeApiService);
            }
        }
        return tradingRestService.alertService;
    }

    getmarketService() {
        if (!tradingRestService.marketService)
        {
            if (environment.isQuickRest) {
                tradingRestService.marketService = new HSMarketService(this.tradeApiService);
            }
            else {
                tradingRestService.marketService = new MarketService(this.tradeApiService);
            }
        }
        return tradingRestService.marketService;
    }

    getIndexService() {
        if (tradingRestService.indexService == null) {
            if (environment.isQuickRest) {
                tradingRestService.indexService = new HSIndexService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.indexService = new IndexService(tradingRestService.tradeApiService);
            }
            tradingRestService.indexService.setTradingRestService(tradingRestService);
        }
        return tradingRestService.indexService;
    }

    getTriggerService() {
        if (!tradingRestService.triggerService) {
            if (environment.isQuickRest) {
                tradingRestService.triggerService = new HSTriggerService(tradingRestService.tradeApiService);
            }
            else {
                tradingRestService.triggerService = new HSTriggerService(tradingRestService.tradeApiService);
            }
            tradingRestService.triggerService.setTradingRestService(tradingRestService);
        }
        return tradingRestService.triggerService;
    }

    getQProfileService() {
        if (tradingRestService.qprofileService == null) {
            if (environment.isQuickRest) {
                tradingRestService.qprofileService = new QProfileService(tradingRestService.tradeApiService);
            }
        }
        return tradingRestService.qprofileService;
    }
}
