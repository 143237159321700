<mat-card class="webpanelnew" [class.rightcon2]="isMobile" [ngStyle]="Height">
    <mat-card-content>
        <div [class.pt-0]="!isMobile">
            <ng-container *ngIf = "!isNoData && !isMobile">
                <div class="col-12 py-2 border-b">
                    <mat-card>
                        <div class="row cont">
                            <div class="col-4 text-left">
                                <div class="pb-1"><small class="disabletext">Investment</small></div>
                                <div class="">{{currentInvestment | pricepipe}}</div>
                            </div>
                            <div class="col-4 text-center">
                                <div class="pb-1"><small class="disabletext">Current Value</small></div>
                                <div class="">{{currentValue | pricepipe}}</div>
                            </div>
                            <div class="col-4 text-right">
                                <div class="pb-1"><small class="disabletext">Total Return</small></div>
                                <label [ngClass]="{'impositive': currentReturn > 0, 'imnegative': currentReturn < 0}">{{currentReturn | pricepipe}}<small class="disabletext">-({{currentReturnPer | percentagepipe}})</small></label>
                            </div>
                        </div>
                    </mat-card>
                </div>

                <div class="table-global ismousemode">
                    <table class="mat-table cdk-table" role="grid">
                        <thead role="rowgroup">
                            <tr role="row" class="mat-header-row cdk-header-row">
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">Name</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">Invt. Val.</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">Current Val.</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">Return</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left">Remark</th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            <tr role="row" class="mat-row cdk-row" *ngFor='let data of basketList' (click)="onClickBasket(data)">
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.basket_name}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.total_investment | pricepipe}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.marketVal | pricepipe}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left"><span class="uppercase-text" [ngClass]="{'traded-status': data.totalPNL > 0, 'rejected-status': data.totalPNL < 0}">{{data.totalPNL | pricepipe}} ({{((data.totalPNL / data.total_investment)*100) | percentagepipe}})</span></td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left"><span *ngIf="data.isRebalance">Update available, Please rebalance.</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>                                
            </ng-container>

            <ng-container *ngIf = "!isNoData && isMobile">
                <div class="col-12 py-2 border-b">
                    <mat-card>
                        <div class="row cont">
                            <div class="col-lg-2 col-4 text-left">
                                <div class="pb-1"><small class="disabletext">Investment</small></div>
                                <div class="">{{currentInvestment | pricepipe}}</div>
                            </div>
                            <div class="col-lg-2 col-4 text-center">
                                <div class="pb-1"><small class="disabletext">Current Value</small></div>
                                <div class="" >{{currentValue | pricepipe}}</div>
                            </div>
                            <div class="col-lg-2 col-4 text-right">
                                <div class="pb-1"><small class="disabletext">Total Return</small></div>
                                <label [ngClass]="{'impositive': currentReturn > 0, 'imnegative': currentReturn < 0}">{{currentReturn | pricepipe}}<small class="disabletext">-({{currentReturnPer | percentagepipe}})</small></label>
                            </div>
                        </div>
                    </mat-card>
                </div>

                <div *ngFor='let data of basketList'>
                    <div class="row" (click)="onClickBasket(data)">
                        <div class="col-12">
                            <div class="watchlistwrapper">
                                <div class="row">
                                    <!-- <div class="col-3 pr-1" style="height:70px;width:70px;max-width:90px;">
                                        <img alt="" style="height:100%; width: 100%;" class="img-responsive" src={{data.image}} >
                                    </div> -->
                                    <div class ="col-12  my-auto">
                                        <div class="row">
                                            <label class="col-5 tc-1 ts-4 texttrim" matTooltip="{{data.basket_name}}">{{data.basket_name}}</label>
                                            <label class="col-7 tc-1 ts-2 text-right" ><span class="line2 subcolor">Return: </span> <span [ngClass]="{'traded-status': data.totalPNL > 0, 'rejected-status': data.totalPNL < 0}">{{data.totalPNL | pricepipe}} ({{((data.totalPNL / data.total_investment)*100) | percentagepipe}})</span> </label>
                                            <div class="col-6">
                                                <label class="line2 subcolor ts-2">Invt. Val.:&nbsp;</label>{{data.total_investment | pricepipe}}
                                            </div>
                                            <div class="col-6 text-right">
                                                <label class="line2 subcolor ts-2">Current Val.: </label><span>{{data.marketVal | pricepipe}}</span>
                                            </div>
                                            <div class="col-12" *ngIf="data.isRebalance">
                                                Update available, Please rebalance.
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row border-b"></div>
                </div>
            </ng-container>
            <ng-container *ngIf='isNoData' class='nodata'>
                <app-nodata message = "N0 DATA" (refreshEvent) = "getData()" [btnTitle]="'utility.reload' | translate"></app-nodata>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>