import { HoldingModel } from './holding.model';
import { ScripModel } from './scrip.model';


export class Holdings {
    TotalMCXHoldingValue: number;
    TotalCSEHoldingValue: number;
    TotalBSEHoldingValue: number;
    TotalNSEHoldingValue: number;
    TotalYSXHoldingValue: number;
    TotalValue: number;
    HoldingsModel: HoldingModel[] = [];
    ScripModel: ScripModel[] = [];
    totalDayPNL: number;
    totalDayColor: string;
    TotalDayPNL: number;
    totalDayPNLStr: string;
    TotalDayPNLStr: string;
    TotalDayColor: string;
    TotalValueStr: any;
    TotalPNL: number;
    TotalInvestment: number;
}
