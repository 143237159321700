import { data } from 'jquery';
import { ApiService } from '../api.service';
import { Url } from '../url';
import { BasketModel } from 'src/app/model/basket.model';
import { BasketDetailModel } from 'src/app/model/basketdetail.model';
import { BasketOrderBookModel } from 'src/app/model/basketorderbook.model';
import { environment } from 'src/environments/environment.prod';
import { ChannelType, Utility } from '../utility';
import { TradingRestService } from '../tradingrest.service';
import { Preferences } from '../Preferences';

export class ThematicBasketService {
    tradingservice:TradingRestService;
    constructor (private apiService: ApiService) { }
    
    setTradingRestService(tradingrest: TradingRestService){
        this.tradingservice = tradingrest;
    }

    getAllBasketData(callback: Function) {
        const data = {}
        let message = '';
        let code;
        const allBasketList = new Array<BasketModel>();
        return this.apiService.basketpost(Url.GET_ALLBASKET_URL, data).subscribe((result) => {
            if (result.code === 200) {
                const length = result.data.length;
                if (result.data && length > 0) {
                    for (let i = 0; i < length; i++) {
                        const basketListObj = result.data[i];
                        const BasketBean = new BasketModel();
                        BasketBean.id = basketListObj['id'];
                        BasketBean.name = basketListObj['name']
                        BasketBean.description = basketListObj['description'];
                        BasketBean.creation_time = basketListObj['creation_time'];
                        BasketBean.update_time = basketListObj['update_time'];
                        BasketBean.status = basketListObj['status'];
                        BasketBean.enterprise_id = basketListObj['enterprise_id'];
                        BasketBean.percent_change = basketListObj['percent_change'];
                        BasketBean.percentChange30 = basketListObj['percentChange30'];
                        BasketBean.percentChange90 = basketListObj['percentChange90'];
                        BasketBean.percentChange180 = basketListObj['percentChange180'];
                        BasketBean.percentChange365 = basketListObj['percentChange365'];

                        BasketBean.oneyrcagr = basketListObj['1yrcagr'];
                        BasketBean.twoyrcagr = basketListObj['2yrcagr'];
                        BasketBean.threeyrcagr = basketListObj['3yrcagr'];
                        BasketBean.fouryrcagr = basketListObj['4yrcagr'];
                        BasketBean.fiveyrcagr = basketListObj['5yrcagr'];

                        BasketBean.min_investment = basketListObj['min_investment'];
                        BasketBean.category = basketListObj['category'];
                        const imagePath = environment.BASKET_SERVER_URL + '/' + basketListObj['image'];
                        BasketBean.image = imagePath;
                        BasketBean.rationale = basketListObj['rationale'];
                        BasketBean.basket_setting = basketListObj['basket_setting'];
                        BasketBean.investment_close_on = basketListObj['investment_close_on'];
                        BasketBean.investment_period = basketListObj['investment_period'];
                        BasketBean.risk_factors = basketListObj['risk_factors'];
                        BasketBean.last_review_date = basketListObj['last_review_date'];
                        BasketBean.next_review_date = basketListObj['next_review_date'];
                        BasketBean.base_index_name = basketListObj['base_index_name'];
                        BasketBean.frequency = basketListObj['frequency'];
                        BasketBean.sip_enable = basketListObj['sip_enable'];
                        BasketBean.sip_types = basketListObj['sip_types'];
                        allBasketList.push(BasketBean);
                    }
                    code = result.code;
                    message = result.status;
                }
                else {
                    message = 'NO DATA';
                }
            }
            else {
                message = result.status;
            }
            callback(allBasketList, code, message);
        });
    }

    calculateBasketQty(data, callback:Function){
        let message='';
        let code = '';
        return this.apiService.basketpost(Url.CALCULATE_QTY, data).subscribe((resul) => {
            if(resul['code'] === 200){
                message = resul['status'];
                code = resul['code'];
            }
            else{
                code = resul['code'];
                message = resul['message'];
            }
            callback (resul['data'], code, message);

        },
            error =>{
                message = error;
                callback([], code, message)
            });
    }

    getInstrumentRates(data, callback: Function) {
        let message = '';
        let code;
        return this.apiService.basketpost(Url.GET_INSTRUMENTRATES, data).subscribe((result) => {
            if (result.data && result.status === 'SUCCESS') {
                message = result['status'];
                code = result.code;
            }
            else {
                message = 'NO DATA';
            }
            callback(result.data, code, message);
        },
            error => {
                message = error;
                callback([], code, message);
            });
    }

    getMultipleBenchmarkData(data, callback: Function) {
        let message = '';
        let code;
        return this.apiService.basketpost(Url.BENCHMARK_URL, data).subscribe((result) => {
            if (result.data && result.status === 'SUCCESS') {
                message = result['status'];
                code = result.code;
            }
            else {
                message = 'NO DATA';
            }
            callback(result.data, code, message);
        },
            error => {
                message = error;
                callback([], code, message);
            });
    }

    getBasketDetailData(basketID, callback: Function) {
        const data = { 'basket_id': basketID };
        let message = '';
        let code;
        const basketDetailData = new Array<BasketDetailModel>();
        return this.apiService.basketpost(Url.BASKETDETAIL_URL, data).subscribe((result) => {
            if (result.code === 200) {
                if (result.data && result.data.length > 0) {
                    const length = result.data.length;
                    for (let i = 0; i < length; i++) {
                        const basketDetailObj = result.data[i];
                        const BasketDetailBean = new BasketDetailModel();
                        BasketDetailBean.id = basketDetailObj['id'];
                        BasketDetailBean.basket_id = basketDetailObj['basket_id'];
                        BasketDetailBean.version = basketDetailObj['version'];
                        BasketDetailBean.scrip_token = basketDetailObj['scrip_token'];
                        BasketDetailBean.exchange_segment = basketDetailObj['exchange_segment'];
                        BasketDetailBean.company_name = basketDetailObj['company_name'];
                        BasketDetailBean.symbol = basketDetailObj['symbol'];
                        BasketDetailBean.percent_weight = basketDetailObj['percent_weight'];
                        BasketDetailBean.creation_time = basketDetailObj['creation_time'];
                        BasketDetailBean.update_time = basketDetailObj['update_time'];
                        BasketDetailBean.status = basketDetailObj['status'];
                        BasketDetailBean.transition_type = basketDetailObj['transition_type'];
                        BasketDetailBean.stock_type = basketDetailObj['stock_type'];
                        basketDetailData.push(BasketDetailBean);
                        code = result.code;
                        message = result.status;
                    }
                }
                else {
                    message = 'NO DATA';
                }
            }
            else {
                message = result.status;
            }
            callback(basketDetailData, code, message);
        });
    }

    getMultipleBasketDetailData(id, callback: Function) {
        const basketId = { 'basket_id': id };
        let message = '';
        let code;
        return this.apiService.ptpluspost(Url.MULTIPLE_BASKETDETAIL_URL, basketId).subscribe((result) => {
            if (result.data) {
                message = result['status'];
                code = result.code;
            }
            else {
                message = 'NO DATA';
            }
            callback(code, result.data, message);
        },
            error => {
                message = error;
                callback(code, [], message);
            });
    }

    placeBasketOrder(reqData: any, data: any, callback: Function)
    {
        let message = '';
        let code;
        let responseData;
        // let basket_id;
        // let basket_version;
        // let margin_amt = 0;
        // let jData = [];
        // let tData;
        // let basket_ins_id = [];

        // if(data.basketOrderType === "placeOrder" || data.basketOrderType === "isRetry")
        // {
        //     reqData.forEach(function (element) {
        //         const scripdata = {
        //           "es": "nse_cm",
        //           "pc": "CNC",
        //           "qt": element.qty.toString(),
        //           "ts": element.symbol,
        //           "ot": "MKT",
        //           "rt": "IOC",
        //           "tk": "0",
        //           "tp": "0",
        //           "tt": "B",
        //           "am": "NO",
        //           "pr": "0",
        //           "os": Utility.IS_MOBILE ? 'MOB' : 'WEB',
        //           "dq": "0",
        //           "ur": 'basket_' + element.basket_id
        //        };
        //        basket_id = parseInt(element.basket_id);
        //        basket_version = element.version;
        //        margin_amt = margin_amt + (parseFloat(element.ltp) * parseInt(element.qty));
        //        jData.push(scripdata);
        //        basket_ins_id.push(parseInt(element.id));
        //     });

        //     tData = {
        //         "userid":  Utility.getSession('userid'),
        //         "basket_id": basket_id,
        //         "basket_ins_id": basket_ins_id,
        //         "basket_version": basket_version,
        //         "basket_trns_tp": "investment",
        //         "margin_amt": margin_amt,
        //         "jData": jData
        //     }

        //     if(data.basketOrderType === "isRetry")
        //     {
        //         Object.assign(tData, {order_id: data.oID});
        //         Object.assign(tData, {basket_user_id: data.bID});
        //         Object.assign(tData, {placeorder_type: "RetryPurchase"});
        //     }
        //     else
        //     {   
        //         Object.assign(tData, {placeorder_type: "FirstPurchase"});
        //     }
        // }
        // else if(data.basketOrderType === "exitOrder" || data.basketOrderType === "rebalanceOrder")
        // {
        //     let positionData = reqData.positionData;
        //     let buyJData = [];
        //     positionData.forEach(function (element) {
        //         const scripdata = {
        //           "es": "nse_cm",
        //           "pc": "CNC",
        //           "qt": element.net_qty.toString(),
        //           "ts": element.trdSym,
        //           "ot": "MKT",
        //           "rt": "IOC",
        //           "tk": "0",
        //           "tp": "0",
        //           "tt": data.basketOrderType === "exitOrder" ? "S" : element.trans_type,
        //           "am": "NO",
        //           "pr": "0",
        //           "os": Utility.IS_MOBILE ? 'MOB' : 'WEB',
        //           "dq": "0"
        //        };
        //        margin_amt = margin_amt + (parseFloat(element.ltp) * parseInt(element.net_qty));
        //        basket_ins_id.push(parseInt(element.id));

        //        if(element.trans_type === 'S')
        //             jData.push(scripdata);
        //         else
        //             buyJData.push(scripdata);
        //     });
                
        //     jData.push(... buyJData);
        //     tData = {
        //         "userid":  Utility.getSession('userid'),
        //         "basket_id": reqData.basket_id,
        //         "basket_ins_id": basket_ins_id,
        //         "basket_version": reqData.active_version,
        //         "margin_amt": margin_amt,
        //         "jData": jData
        //     }

        //     if(data.basketOrderType === "exitOrder")
        //     {
        //         Object.assign(tData, {basket_trns_tp: "exit"});
        //         Object.assign(tData, {basket_user_id: reqData.basket_user_id});
        //         Object.assign(tData, {placeorder_type: "ExitPurchase"});
        //     }
        //     else
        //     {   
        //         Object.assign(tData, {basket_trns_tp: "investment"});
        //         Object.assign(tData, {placeorder_type: "RebalancePurchase"});
        //     }
        // }

        return this.apiService.basketpost(Url.TB_PLACE_ORDER, reqData).subscribe((result) => {
            if (result.code === 200) {
                message = 'OK';
                code = result.code;
                responseData = { 'ordrData': result.data['orderData'], 'next_trigger_date': result.data['next_trigger_date'] };
            }
            else {
                message = result.message;
                responseData = [];
            }
            callback(responseData, code, message);
        },
            error => {
                message = error;
                callback([], code, message);
            });
    }

    getBasketConstituentOrders(userID, callback: Function) {
        const data = { 'userid': userID };
        let message = '';
        let code;
        let batchMap = {};
        return this.apiService.basketpost(Url.TB_GET_BASKETCONSTITUENT_ORDERS, data).subscribe((result) => {
            if (result.code === 200) {
                if (result.data && result.data.length > 0) {
                    const length = result.data.length;
                    for (let i = 0; i < length; i++) {
                        const basket_orders = result.data[i]["basket_orders"];
                        if(Utility.basketName == result.data[i]["basket_name"])
                        {
                            let arr = [];
                            for (let j = 0; j < basket_orders.length; j++)
                            {
                                batchMap[basket_orders[j]["batch_no"]]=basket_orders[j]["orderbook"];
                                arr.push(basket_orders[j]["batch_no"]);
                            }
                            batchMap['bch'] = arr.reverse();

                        }
                    }
                    code = result.code;
                    message = 'OK';
                }
                else {
                    message = 'NO DATA';
                }
            }
            else {
                message = 'NOT_OK';
            }
            callback(batchMap, code, message);
        });
    }

    getPositions(id, callback: Function) {
        const data = { 'userid': id };
        let message = '';
        let code;
        let totalPNL;
        const basketOrderBookData = new Array<BasketOrderBookModel>();
        return this.apiService.basketpost(Url.TB_GET_POSITIONS, data).subscribe((result) => {
            if (result.code === 200) {
                totalPNL = result.totalPNL;
                if (result.data && result.data.length > 0) {
                    const length = result.data.length;
                    for (let i = 0; i < length; i++) {
                        const Obj = result.data[i];
                        const bean = new BasketOrderBookModel();
                        bean.image = Obj['image'];
                        bean.basket_id = Obj['basket_id'];
                        bean.basket_name = Obj['basket_name'];
                        bean.actId = Obj['actId'];
                        bean.active_version = Obj['active_version'];
                        bean.intrument_update_date = Obj['intrument_update_date'];
                        bean.basket_user_id = Obj['basket_user_id'];
                        bean.isRebalance = Obj['isRebalance'];
                        bean.total_investment = Obj['total_investment'];;
                        bean.totalPNL = Obj['totalPNL'];
                        bean.positionData = Obj['postions'];
                        bean.place_on = Obj['place_on'];
                        bean.marketVal = Obj['marketVal'];
                        bean.total_withdrawl = Obj['total_withdrawl'];
                        basketOrderBookData.push(bean);
                        code = result.code;
                        message = 'OK';
                    }
                }
                else {
                    message = 'NO DATA';
                }
            }
            else {
                message = 'NOT_OK';
            }
            callback(basketOrderBookData, totalPNL, code, message);
        });
    }

    getRebalnceData(data, callback: Function)
    {
        let message = '';
        let code;
        let responseData;

        return this.apiService.basketpost(Url.TB_REBALANCE, data).subscribe((result) => {
            if (result.code === 200) {
                message = 'OK';
                code = result.code;
                responseData = result.data;
            }
            else {
                message = 'NOT_OK';
                responseData = [];
            }
            callback(responseData, code, message);
        },
            error => {
                message = error;
                callback([], code, message);
            });
    }

    getExitOrdersData(data, callback: Function)
    {
        let message = '';
        let code;
        let responseData;

        return this.apiService.basketpost(Url.TB_EXITORDERS, data).subscribe((result) => {
            if (result.code === 200 && result.status === 'SUCCESS') {
                message = 'OK';
                code = result.code;
                responseData = result.data.orderData;
            }
            else {
                message = 'NOT_OK';
                responseData = [];
            }
            callback(responseData, code, message);
        },
        error => {
            message = error;
            callback([], code, message);
        });
    }

    getRepairOrders(reqdata, callback: Function){

        let message = '';
        let code;
        let data;

        return this.apiService.basketpost(Url.REPAIRORDERS, reqdata).subscribe((result) => {
            if (result.code === 200 && result.status === 'SUCCESS') {
                message = 'OK';
                code = result.code;
                data = result.data.orderData;
            }
            else {
                message = result.message;
                data = [];
            }
            callback(data, code, message);
        },
        error => {
            message = error;
            callback([], code, message);
    
        });
    }

    getUserSip(callback:Function){
        let message='';
        let code;
        return this.apiService.basketpost(Url.GET_USER_SIP).subscribe((res) => {
            if(res['code'] === 200){
                message = res['status'];
                code = res['code'];
            } else {
                code = res['code'];
                message = res['message'];
            }
            callback (res['data'], code, message);
        },error => {
            message = error;
            callback([], code, message)
        });
    }

    getUserTodaySip(callback:Function){
        let message='';
        let code;
        return this.apiService.basketpost(Url.GET_USER_TODAY_SIP).subscribe((res) => {
            if(res['code'] === 200){
                message = res['status'];
                code = res['code'];
            } else {
                code = res['code'];
                message = res['message'];
            }
            callback (res['data'], code, message);
        },error => {
            message = error;
            callback([], code, message)
        });
    }

    changeSipStatus(data, callback:Function){
        let message='';
        let code;
        let status = '';
        return this.apiService.basketpost(Url.CHANGE_SIP_STATUS, data).subscribe((res) => {
            if(res['code'] === 200){
                message = res['message'];
                code = res['code'];
                status = res['status'];
            } else {
                status = res['status'];
                code = res['code'];
                message = res['message'];
            }
            callback (code, status, message);
        },error => {
            message = error;
            callback(code, status, message)
        });
    }

    UpdateSip(data, callback:Function){
        let message='';
        let code;
        let status = '';
        return this.apiService.basketpost(Url.UPDATE_SIP, data).subscribe((res) => {
            if(res['code'] === 200){
                message = res['message'];
                code = res['code'];
                status = res['status'];
            } else {
                status = res['status'];
                code = res['code'];
                message = res['message'];
            }
            callback (code, status, message);
        },error => {
            message = error;
            callback(code, status, message)
        });
    }
}
