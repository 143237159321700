import { environment } from 'src/environments/environment';
import { TableUtility } from './tableutility';
import { TradingRestService } from './tradingrest.service';
import { Utility } from './utility';

export enum ReturnType {
    Array,
    Object,
    Undefined,
    String,
    Number,
    Null
}
export class Preferences {

    private static userData = {};
    static lastupdate;

    static removePreference(key) {
        localStorage.removeItem(key);
    }

    static setPreference(key, value) {
        localStorage.setItem(key, value);
    }

    static getPreference(key) {
        return localStorage.getItem(key);
    }

    static serverSync(tradingRestService){
        Utility.SYNC = 2;
        if((Preferences.userData['lastupdate'] > Preferences.lastupdate || Preferences.lastupdate == undefined) && Preferences.getPreference('LoggedIn') === 'true') {
            const jsondata = Preferences.userData;
            tradingRestService.getUserService().SetPreferences(jsondata, (data, code, status) => {
                Utility.SYNC =1;
                if (code === 200 && status === 'SUCCESS') {
                    Utility.SYNC = 0;
                    Preferences.lastupdate = Preferences.userData['lastupdate'];
                }
            });
        }
    }

    static clearPrefs(tradingRestService, clearOnServer, onlyServer = false){
        if (clearOnServer) {
            tradingRestService.getUserService().SetPreferences({}, (data, code, status) => {
                if (code === 200 && status === 'SUCCESS') {
                    Preferences.userData = {};
                    localStorage.removeItem('UserData_'+Preferences.getPreference('userid'));

                    if(!onlyServer) {
                        window.location.reload();    
                    }
                }
            });
        }
        else if(environment.needForceClear && localStorage.getItem('version_l') !== environment.version)
        {
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('version_l', environment.version)
            window.location.reload();        
        }
    }

    static getPreferences(tradingRestService,isReload) {
        tradingRestService.getUserService().GetPreferences((data, code, status) => {
            this.setUserData(data, tradingRestService,isReload);
        });
    }

    static setUserData(data: any, tradingRestService,isReload = false) {
        const user = Preferences.getPreference('userid');
        if(user != null){
            if(!data['w_warr'] && environment.needForceClear && localStorage.getItem('version_s') !== environment.version)
            {
             Preferences.clearPrefs(tradingRestService, true, true);   
             localStorage.setItem('version_s', environment.version);
            }
            else
            {
                let localdata = JSON.parse(localStorage.getItem('UserData_'+user));
                if(isReload){
                    Preferences.userData = localdata;
                    Utility.SYNC = 1;
                }
                else {
                    Preferences.userData = this.removeProperties(data);
                    Utility.SYNC = 0;
                    if(localdata != null && localdata !== undefined && localdata['lastupdate'] !== undefined && localdata['lastupdate'] != null)
                    {
                        if(localdata['lastupdate']>Preferences.userData['lastupdate']){
                            Preferences.userData = localdata;
                            Utility.SYNC = 1;
                        }
                    }
                    Preferences.lastupdate = Preferences.userData['lastupdate'];
                    localStorage.setItem('UserData_' + user, JSON.stringify(Preferences.userData));
                    Utility.preferencesUpdated.next(true);
                }
            }
        }
    }

    static getItem(key: string, returnType = ReturnType.Object): any {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let uID = (userInfo) ? userInfo.uid : Preferences.getPreference('userid');
        if (key !== undefined && key !== null && key.length > 0 && Preferences.userData[key] !== undefined) {
            return Preferences.userData[key];
        } else if (key !== undefined && key !== null && key.length > 0 && JSON.parse(localStorage.getItem('UserData_'+uID)) !== null
        && JSON.parse(localStorage.getItem('UserData_'+uID)) !== undefined && JSON.parse(localStorage.getItem('UserData_'+uID))[key] !== undefined) {
            Preferences.userData = JSON.parse(localStorage.getItem('UserData_'+uID));
            return Preferences.userData[key];
        }
        else {
            switch (returnType) {
                case ReturnType.Object:
                    return {};
                case ReturnType.Array:
                    return [];
                case ReturnType.Undefined:
                    return undefined;
                case ReturnType.String:
                    return '';
                case ReturnType.Number:
                    return 0;
                case ReturnType.Null:
                    return null;
                                
            }
        }
    }

    static setItem(key: string, value: any) {
        if (key === undefined || key === null || key.length === 0) {
            return;
        } else if (value === undefined || value === 'undefined' || value === null) {
            return;
        } else {
            Preferences.setPreferences(key, value);
        }
    }

    static removeItem(key: string) {
        if (key !== undefined && key !== null && key.length > 0 && Preferences.userData[key]) {
            Preferences.setPreferences(key, undefined);
        }
    }

    private static setPreferences(key: string, value: any) {
        (value === undefined) ? delete Preferences.userData[key] : Preferences.userData[key] = value;
        const d = new Date();
        const n = d.getTime();
        Utility.SYNC = 1;
        Preferences.userData['lastupdate'] = n;
        const user = Preferences.getPreference('userid');
        if(user != null){
            localStorage.setItem('UserData_'+user, JSON.stringify(Preferences.userData));
        }
    }

    private static removeProperties(data) {
        TableUtility.getTempObj.forEach(item => {
            if (data[item.property] && item.key && data[item.property].includes(item.key)) {
                delete data[item.property];
                if (item.property === 'displayedColumns' && data['hideDisplaycols'])
                    delete data['hideDisplaycols'];
            }
        });
        return data;
    }  
}
