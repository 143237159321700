<app-titlebar   [title]="'all.position_detail' | translate"></app-titlebar>

<div *ngIf = "positionBreakUpDetailData.list.length>1">
    <mat-card class="mx-2">
        <mat-card-content>
          <div class="myrow smalltext container">
              <div class="row">
                <div class="col-6">
                    <div class="line-height">
                        <span class="graytext" translate>all.trade_symbol</span><span class="graytext">:</span>
                        <span class="float-right">{{positionBreakUpDetailData.Tsym}}</span>
                    </div>
                </div>
                <div class="col-6 text-right">
                    <div class="line-height">
                        <span class="graytext float-left" translate>all.net_qty</span><span class="graytext float-left">:</span>
                        {{positionBreakUpDetailData.Netqty === undefined ? '-' : positionBreakUpDetailData.Netqty}}
                    </div>  
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                    <div class="line-height">
                      <span class="graytext"  translate>all.avgprice</span><span class="graytext">:</span>
                      <span class="float-right">{{positionBreakUpDetailData.AvgPrc | pricepipe: positionBreakUpDetailData.list[0].scripModel.DecimalPrecision}}</span>
                    </div>
                </div>
                <div class="col-6 text-right">
                    <div class="line-height">
                        <span class="graytext float-left" translate>all.product</span><span class="graytext float-left">:</span>
                        {{positionBreakUpDetailData.Pcode === undefined ? '-' : positionBreakUpDetailData.Pcode | valuepipe}}
                    </div>
                </div>
              </div>
          </div>
        </mat-card-content>
    </mat-card>
</div>


<div class="mx-2" *ngIf = "positionBreakUpDetailData.list.length>1">
    <div class="row">
        <div class="col-12 sameer">
            <mat-radio-group [(ngModel)]="selectedRadioButtonValue">
                <mat-radio-button value="NSE" (change) = "handleChange('NSE')">NSE</mat-radio-button>
                <mat-radio-button value="BSE" (change) = "handleChange('BSE')">BSE</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>
<app-positiondetail [isHeader] = "false" [count] = "count"></app-positiondetail>
