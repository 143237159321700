<app-header *ngIf = "isMobile" goback="1" [isLoading]="isLoading" [title]="'basket.investment_detail' | translate"></app-header>
<!-- <app-titlebar *ngIf="!isMobile" [title]="'basket.basket_detail' | translate"></app-titlebar> -->



  <div [class.data-container]="isMobile" *ngIf="data">
    <div class="container-fluid">
      <div class="row">
            <div class ="col-12" [class.pt-2] ="!isMobile">
              <div class="watchlistwrapper px-2">
                  <div class="row">
                      <div class ="col-12 my-auto">
                          <div class="row">
                              <label class="col-5 tc-1 ts-4 texttrim" matTooltip="{{data.basket_name}}">{{data.basket_name}}</label>
                              <label class="col-7 tc-1 ts-2 text-right" ><span class="line2 subcolor">Return: </span> <span [ngClass]="{'traded-status': data.totalPNL > 0, 'rejected-status': data.totalPNL < 0}">{{data.totalPNL | pricepipe}} ({{((data.totalPNL / data.total_investment)*100) | percentagepipe}})</span> </label>
                              <div class="col-6">
                                  <label class="line2 subcolor ts-2">{{'basket.invst_val' | translate}}.:&nbsp;</label>{{data.total_investment | pricepipe}}
                              </div>
                              <div class="col-6 text-right">
                                  <label class="line2 subcolor ts-2">{{'basket.curr_val' | translate}}.: </label><span class="uppercase-text" [ngClass]="{'traded-status': data.totalPNL > 0, 'rejected-status': data.totalPNL < 0}" >{{data.marketVal | pricepipe}}</span>
                              </div>
                              <div class="col-12 baskethomeheight">
                                <label  *ngIf="data.isRebalance"  class="line2 subcolor ts-2">{{'basket.update_avail' | translate}}.</label>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
      </div>
    </div>
    <mat-tab-group  mat-stretch-tabs >
      <mat-tab label="Holdings">
        <app-basketconstituents (load)="onLoading($event)" [isOpen] = "isOpen" (closeBasket) = "closeBasket()"></app-basketconstituents>
      </mat-tab>
      <mat-tab label="ORDERS">
        <app-basketorders [isOpen] = "isOpen" (load)="onLoading($event)"  (closeUserBasket) = "closeBasket()"></app-basketorders>
      </mat-tab>
    </mat-tab-group>
  </div>