<app-header [goback]="goback"  [isLoading]="isLoading" [isSearch] = "isSearch"></app-header>
<div class='data-container pb-2'>
    <div class="row">
        <div class="col-3">
            <app-scanner (setScannerType) = "setType($event)" (setScannerIcon) = "setIcon($event)" (setScannerDetail) = "setDetail($event)"></app-scanner>
        </div>
        <div class="col-9">
            <i class = "{{icon}}" matTooltip="{{scannerDetail}}"></i>&nbsp;<span matTooltip="{{scannerDetail}}">{{scanner}}</span> <br/><br/>
            <app-scannerdetail [scannerValue] = "scanner" (setLoading) = "setLoading($event)"></app-scannerdetail>
        </div>
    </div>
</div>