import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'absptpipe' })

export class AbsPtPipe implements PipeTransform {
    transform(value) {
        if (value == undefined) {
            return '-';
        } else if(value.toString().includes('.')){
            return parseFloat(value).toFixed(2);
        } else {
            return value;
        }
    }
}
