import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Url } from '../url';
import { Keys } from '../keys';

@Injectable()
export class WorkBookService {
    constructor(private apiService: ApiService) {
    }
    getWorkBook(callback: Function) {
        
    }

    addWorkBook(data, callback: Function) {
        
    }
}





