<app-header (onTitleClick)="onTitleClick()" *ngIf = "showHeader" [goback]="goback" [title]="'equity.topmarket' | translate" [isLoading]="isLoading" (reloadPage) = "reloadData()"></app-header>
<mat-card class="webpanelnew">
    <mat-card-content>
        <div #sectionNeedToScroll class ="pt-3">
            <div class="row" [class.pt-0]="!isMobile && !cellview" [class.data-container] = "showHeader">
                <div class="col-12">
                    <div class="panelsubheader">
                        <div class="row">
                                <div class="col-4"> 
                                    <mat-form-field>
                                        <mat-label *ngIf = "type == 'G'" translate >more.market_type</mat-label>
                                        <mat-label *ngIf = "type == 'V'" translate>equity.mostActive</mat-label>
                                        <mat-select (selectionChange)="gainersLosers($event.value)" [(value)]="selectedType">
                                            <mat-option *ngIf = "type == 'G'" value = "gainer"><span translate>equity.gainers</span></mat-option>
                                            <mat-option *ngIf = "type == 'G'" value = "loser"><span translate>equity.losers</span></mat-option>
                                            <mat-option *ngIf = "type == 'V'" value = "mostactivebyvalue"><span translate>equity.by_value</span></mat-option>
                                            <mat-option *ngIf = "type == 'V'" value = "mostactivebyvolume"><span translate>equity.by_volume</span></mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!--div class="col-3">
                                    <mat-form-field>
                                        <mat-select (selectionChange)="onExchangeSelection($event.value)" [(value)]="selectedExch">
                                            <mat-option *ngFor="let exch of exchArr" [value]="exch">
                                                {{exch}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div-->
                                <div class="col-4">
                                    <mat-form-field>
                                        <mat-label translate>dashboard.index</mat-label>
                                        <mat-select (selectionChange)="onIndexSelection($event.value)" [(value)]="selectedIndex">
                                            <mat-option *ngFor="let index of indexArr" [value]="index.TSymbol">
                                                {{index.TSymbol}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                <mat-form-field>
                                    <mat-label translate>books.return_change</mat-label>
                                    <mat-select (selectionChange)="onDaysSelection($event.value)" [(value)]="selectedDay">
                                        <mat-option *ngFor="let days of daysArr" [value]="days.value">
                                            {{days.displayName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                    
                                </div>
                          </div>
                    </div>
                </div>
            </div>
            <ngx-pull-to-refresh
            spinnerColor="#ff0000"
            distanceForRefresh="80"
            [isEnable]="isMobile"
            [targetElement]="targetElement"
            (refresh)="myRefreshEvent($event, 'refresh')"
            (loadMore)="alert('loadmore')"
            class = "loader">
                <div *ngIf="isMobile || cellview" [ngStyle]="Height" >
                    <div [class.pl-2]="cellview" [class.pr-2]="cellview" *ngFor="let option of marketMoversData; let i = index" [attr.id]="'ob_cell' + i" [class.wlweb]="cellview" [class.pl-2]="cellview" [class.pr-2]="cellview" (click)="highlight(option, i)" tabindex="999">
                        <div class="row">
                            <div class="col-12" [ngClass]="{'highlight': selectedRowIndex == i}">
                                <div class="watchlistwrapper" [ngClass]="{'d-block d-sm-none' : isMobile}">
                                    <div class="row">
                                        <div class="col-7">
                                            <div class="firstline">{{option.TradingSymbol | symbolpipe}} <small>{{option.Exchange}}</small></div>
                                            <div class="line2 subcolor">{{option.CompanyName === '' ? '-' : option.CompanyName}}</div>
                                        </div>
                                        <div [class.imnegative]="option.Change<0" [class.impositive]="option.Change>0" class="col-5 text-right">
                                            <div>
                                                <div class="firstline"><span [class.imnegativebg]="option.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="option.TickDirection === 1 && flashType !== 'noFlash'">{{option.LastTradePrice | pricepipe:option.DecimalPrecision}}</span><br>
                                                    <span class="line2" [class.imnegativebg]="option.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="option.TickDirection === 1 && flashType === 'defaultFlash'">{{option.Change | pricepipe:option.DecimalPrecision}} ({{option.PercentageChange | percentagepipe}})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wl-btn" *ngIf="cellview" [class.d-none]="!M.IS_MOUSE">
                                        <button (click)='openPlaceOrder("B", option)' class="ml-2 btn-positive" matTooltip="{{'books.buy' | translate}}" mat-icon-button><i class="icon-bb"></i></button> 
                                        <button (click)='openPlaceOrder("S", option)' class="ml-2 btn-negative" matTooltip="{{'books.sell' | translate}}" mat-icon-button ><i class="icon-sb"></i></button>
                                        <button *ngIf="createGttFlag" matTooltip="{{'all.create_gtt' | translate}}" (click)='openTrigger(option)' class="btn-primary ml-2" mat-icon-button><i class="icon-aim"></i></button>
                                        <button mat-icon-button (click) = "addWLdata(option)" matTooltip="{{'search.addto_watchlist' | translate}}"  class="btn-primary ml-2" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                        <mat-menu #menu="matMenu">
                                        <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                        <ng-container *ngFor = "let data of WlArr">
                                            <button mat-menu-item (click) = "addStock(data.wlName, option)" [disabled] = "data.isDisable">
                                                <span>{{data.wlName}}</span>
                                            </button>
                                        </ng-container>
                                        </mat-menu>
                                        <button matTooltip="{{'tooltip.stock_detail' | translate}}" (click)='openStockDetail(option)' class="ml-2 btn-primary" mat-icon-button><i class="icon-ib"></i></button> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div *ngIf="!cellview">
                <div *ngIf = "!isNoData" class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'" [class.tabletscroll]="utility.IS_TABLET">
                    <table mat-table [dataSource]="marketMoversData" matSort (matSortChange)="sortTopMarket($event)" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                        
                        <ng-container matColumnDef="TradingSymbol" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag  [cdkDragDisabled]="true"> <span translate>stock_detail.symbol</span> </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="button-row">
                                <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true" [class.d-none]="!M.IS_MOUSE">
                                    <button (click)='openPlaceOrder("B", element)' class="btn-positive" matTooltip="{{'books.buy' | translate}}" mat-icon-button><i class="icon-bb"></i></button> 
                                    <button (click)='openPlaceOrder("S", element)' class="btn-negative" matTooltip="{{'books.sell' | translate}}" mat-icon-button ><i class="icon-sb"></i></button>
                                    <button *ngIf="createGttFlag" matTooltip="{{'all.create_gtt' | translate}}" (click)='openTrigger(element)' class="btn-primary" mat-icon-button><i class="icon-aim"></i></button>
                                    <button mat-icon-button (click) = "addWLdata(element)" matTooltip="{{'search.addto_watchlist' | translate}}"  class="btn-primary" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                    <mat-menu #menu="matMenu">
                                    <mat-optgroup label="{{'watchlist.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                    <ng-container *ngFor = "let data of WlArr">
                                        <button mat-menu-item (click) = "addStock(data.wlName, element)" [disabled] = "data.isDisable">
                                            <span>{{data.wlName}}</span>
                                        </button>
                                    </ng-container>
                                    </mat-menu>
                                    <button matTooltip="{{'tooltip.stock_detail' | translate}}" (click)='openStockDetail(element)' class="btn-primary" mat-icon-button><i class="icon-ib"></i></button> 
                                </div>
                            </span>
                            {{element.TradingSymbol | symbolpipe}}
                             </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="CompanyName">
                        <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>stock_detail.company_name</span> </th>
                        <td mat-cell *matCellDef="let element" class="text-left">
                            <small>{{element.CompanyName === '' ? '-' : element.CompanyName}} </small></td>
                        </ng-container>

                        <ng-container matColumnDef="LastTradePrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag  [cdkDragDisabled]="true"> <span translate>stock_detail.ltp</span> </th>
                            <td mat-cell *matCellDef="let element; let i = index" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">
                                <span [class.imnegativebg]="element.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType !== 'noFlash'"> {{element.LastTradePrice | pricepipe:element.DecimalPrecision}} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Change">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"cdkDrag> <span translate>stock_detail.change</span> </th>
                            <td mat-cell *matCellDef="let element" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">
                                <span [class.imnegativebg]="element.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType === 'defaultFlash'">
                                    {{element.Change | pricepipe:element.DecimalPrecision}} 
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="PercentageChange">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> % {{'stock_detail.change' | translate}} </th>
                            <td mat-cell *matCellDef="let element" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">
                                <span [class.imnegativebg]="element.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="element.TickDirection === 1 && flashType === 'defaultFlash'">
                                    {{element.PercentageChange | percentagepipe}} 
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="OpenPrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>watchlist.open</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.OpenPrice | pricepipe:element.DecimalPrecision}} </td>
                        </ng-container>
                        <ng-container matColumnDef="High">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>watchlist.high</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.High | pricepipe:element.DecimalPrecision}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Low">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>watchlist.low</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.Low | pricepipe:element.DecimalPrecision}} </td>
                        </ng-container>
                        <ng-container matColumnDef="PrvClose">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>watchlist.close</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.PrvClose | pricepipe:element.DecimalPrecision}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Lasttradedquantity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>stock_detail.last_traded_qty</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.Lasttradedquantity | numberpipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="BestBuyPrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate>stock_detail.bid_price</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.BestBuyPrice | pricepipe:element.DecimalPrecision}} </td>
                        </ng-container>
                        <ng-container matColumnDef="BestSellPrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>stock_detail.ask_price</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.BestSellPrice | pricepipe:element.DecimalPrecision}} </td>
                        </ng-container>
                        <ng-container matColumnDef="BestBuySize">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>stock_detail.bid_qty</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.BestBuySize | numberpipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="BestSellSize">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>stock_detail.ask_qty</span> </th>
                            <td mat-cell *matCellDef="let element" > {{element.BestSellSize | numberpipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="TradeVolume">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>stock_detail.volume</span> </th>
                            <td mat-cell *matCellDef="let element"> {{element.TradeVolume | numberpipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="VwapAveragePrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  cdkDrag> <span translate>stock_detail.atp</span> </th>
                            <td mat-cell *matCellDef="let element"> {{element.VwapAveragePrice | pricepipe:element.DecimalPrecision}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" [ngClass]="{'highlight': selectedRowIndex == i}" (click)="highlight(row, i)" tabindex="999" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)" [attr.id]="'ob_tbl' + i"></tr>
                    </table>
                </div>
            </div>
            <ng-container *ngIf = "isNoData">
                <app-nodata  (refreshEvent) = "reloadData()" message = "N0 DATA" [btnTitle]="'utility.reload' | translate"></app-nodata>
             </ng-container>
            </ngx-pull-to-refresh>

        </div>
    </mat-card-content>
</mat-card>