<app-header *ngIf="isMobile" goback="1" [title]="'all.ppc' | translate"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'all.ppc' | translate"></app-titlebar>
<mat-dialog-content class="mat-dialog-content-small" [class.mh-none] = "isMobile">
	<div [class.data-container]="isMobile">
		<div class="row" *ngIf = "isInteropsEnabled && (selectedRadioButtonValue == 'NSE' || selectedRadioButtonValue == 'BSE')">
			<div class="col-12 sameer">
				<div class="mb-1" *ngIf = "selectedRadioButtonValue == 'NSE' || selectedRadioButtonValue == 'BSE'"><small class="textcolor5">{{'all.sel_pos' | translate}}</small></div>
				<mat-radio-group [(ngModel)]="selectedRadioButtonValue">
					<mat-radio-button *ngIf = "positionBreakUpDetailData.list.length>1 || (positionBreakUpDetailData.list.length == 1 && selectedRadioButtonValue == 'NSE')" value="NSE" (change) = "handleChange('NSE')">NSE</mat-radio-button>
					<mat-radio-button *ngIf = "positionBreakUpDetailData.list.length>1 || (positionBreakUpDetailData.list.length == 1 && selectedRadioButtonValue == 'BSE')" value="BSE" (change) = "handleChange('BSE')">BSE</mat-radio-button>
				</mat-radio-group>
			</div>
		</div>
		<div class="row">
			<div class="col-12 sameer">
				<div class="mb-1"><small class="textcolor5" translate>{{'all.position_type' | translate}}</small></div>
				<mat-radio-group [(ngModel)]="selectedPositionType" aria-label="Select an option"  (change) = "onPositionTypeChange($event)">
					<mat-radio-button class="pr-1" [disabled] = "positionDetailData.FillBuyQty === 0 && positionDetailData.FillSellQty === 0"  value="DAY">DAY</mat-radio-button>
					<mat-radio-button class="pr-1" [disabled] = "positionDetailData.CFBuyQty === 0 && positionDetailData.CFSellQty === 0" value="CF">CF</mat-radio-button>
				</mat-radio-group>
			</div>
		</div>
		<div class="row placeorderfield ">
			<div class="col-3 col-sm-2 mt-4">
				<span>{{positionDetailData.Pcode | valuepipe}} <i class="icon-gonext mt-2 primetext"></i> </span>
				<!-- <mat-radio-group [(ngModel)]="selectedProduct" aria-label="Select an option">
					<ng-template ngFor let-prtype [ngForOf]="productList">
						<mat-radio-button class="pr-1" [value]="prtype">{{prtype | valuepipe}}</mat-radio-button>
					</ng-template>
				</mat-radio-group> -->
			</div>
			<div class="col-6 col-sm-4">
				<!-- <div class="mb-1"><small class="textcolor5" translate>Convert Product Type</small></div> -->
				<mat-form-field [ngStyle] = "">
					<mat-select [(value)]="selectedProduct">
						<mat-option *ngFor="let obj of productList" [value]="obj">
							{{obj | valuepipe}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		<!-- </div>
		<div class="row"> -->
			<div class="col-12 col-sm-6">
				<mat-form-field>
					<mat-label><span translate>all.basket_qty</span><span *ngIf = "isLotLable" class="subcolor2">&nbsp;(1 {{'all.lt' | translate}} = {{positionDetailData.BLQty | numberpipe}}{{'all.qty' | translate}})</span></mat-label>
					<input type="number" matInput #myInput matInput [(ngModel)]="quantity"
							onkeydown="if(event.key==='.'){event.preventDefault();}" (input)="getQuantityFromUser(myInput.value)"
							autocomplete="off" oncopy="return false" required>
				</mat-form-field>
				<div class="ts-1 subcolor2">
					<span  *ngIf = "isDay" class="tc-1">{{'all.avail' | translate}} {{'all.qty' | translate}}: {{MakePositiveNumber(positionDetailData.DayQty) | numberpipe}}</span>
					<span  *ngIf = "!isDay" class="tc-1">CF {{'all.avail' | translate}} {{'all.qty' | translate}}: {{MakePositiveNumber(positionDetailData.CFQty) | numberpipe}}</span> <span *ngIf = "isLotLable" class="tc-1" [class.imnegative] = "lotError">&nbsp;&nbsp;{{'all.lots' | translate}} = {{calculateLots() | numberpipe}}</span>
				</div>
				<div *ngIf='isError' class="errormsg text-red">{{errMessage}}</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions d-block" [class.mda-d-block] = "isMobile">
	<div class="row">
		<div *ngIf="!isMobile" class="col-12 text-right">
			<button class="m-2 custom-btn" mat-flat-button (click)="onCancel()"><span translate>all.cancel</span></button>
			<button class="m-2 custom-btn btn-primary" mat-flat-button (click)="onSubmit()"><span translate>all.submit</span></button>
		</div>
	</div>
	<div *ngIf="isMobile" class="footerbtn">
		<button class="w-100 custom-btn btn-primary" mat-flat-button (click)="onSubmit()"><span translate>all.submit</span></button>
	</div>
</mat-dialog-actions>