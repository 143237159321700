<div class="detailchart py-1">
    <div class="bd-sectionchart">
        <div *ngIf="!chartLoaded" fxLayout="row" fxLayoutAlign="space-around center" class="spinner-loader"
            id="chartcontainer">
            <div *ngIf="!isLoading && isNodata" class="text-center chart-conatiner-nodata">
                <span class="font18" translate>no_data.NODATA</span>
            </div>
        </div>
        <div *ngIf="chartLoaded" class="chart-height">
            <canvas baseChart class="chart-color4" [datasets]="chartData" [labels]="chartLabels"
                [options]="chartOptions" [colors]="chartColors" [plugins]="chartPlugins" [chartType]="chartType">
            </canvas>
        </div>
    </div>
</div>

<div class="py-1 investor-none">
    <div class="bd-sectionchart">
        <div class="py-2 text-left h3new">RELATIVE PERFORMANCE</div>
        <div *ngIf="barChartLoaded">
            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" 
                [options]="barChartOptions" [chartType]="barChartType" [colors]="barChartColors">
            </canvas>
        </div>
        <div *ngIf="!barChartLoaded" class="text-center chart-conatiner-nodata">
            <span class="font18" translate>no_data.NODATA</span>
        </div>
    </div>
    <div style="display: none;" class="chart-color4"></div>
</div>

 <!-- <div class="bd-sectionchart">
    <div class="py-2 text-left h3new">STOCKS WEIGHTAGE</div>
    <div class="chart-height">
        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" 
        [options]="pieChartOptions" [chartType]="pieChartType" [colors]="barChartColors"></canvas>
    </div>
    <div *ngIf="!barChartLoaded" class="text-center chart-conatiner-nodata">
        <span class="font18" translate>no_data.NODATA</span>
    </div>
</div> -->