import { Component, OnInit } from '@angular/core';
import { OrderModel } from 'src/app/model/order.model';
import { Utility } from 'src/app/services/utility';
@Component({
  selector: 'app-orderdetail',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.scss']
})
export class OrderdetailComponent implements OnInit {
  selectedLabel = 'orderdetail';
  orderDetailData: OrderModel;
  constructor() {
    this.orderDetailData = Utility.detailedOrder;
  }

  ngOnInit(): void {
  }

  onTabChange(type) {
    this.selectedLabel = type;
  }
}
