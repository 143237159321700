<div class="mat-dialog-title" *ngIf="!isMobile">
   <div class="row">
       <div class="col-8"><h1 translate>header.change_mpin</h1></div>
       <div class="col-4 text-right"><button mat-icon-button (click) = "close()"><i class="icon-close"></i></button></div>
   </div>
</div>
<app-header *ngIf="isMobile" goback="1" [title]="'header.change_mpin' | translate" [isLoading]="isLoading" [isReloadVisible]="false"></app-header>

<div class="mat-dialog-content" [class.mh-none] = "isMobile">
   <div [class.data-container]="isMobile" [class.mt-2]="isMobile">
      <div class="row">
         <div class="col-12">
            <div class="divOuter">
               <app-mpininput [placeholderText]="'login.old_pin' | translate"
               (codeChanged)="oldPinChanged($event)"
               (codeCompleted)="checkPin($event, 'O')">
               </app-mpininput>
            </div>
         </div>
         <div class="col-12">
            <div class="divOuter">
               <app-mpininput [placeholderText]="'login.new_pin' | translate"
               #newMpinInput [isFocus]="false"
               (codeChanged)="newPinChanged($event)"
               (codeCompleted)="checkPin($event, 'N')">
               </app-mpininput>
            </div>
         </div>
         <div class="col-12">
            <div class="divOuter">
               <app-mpininput [placeholderText]="'login.confirm_pin' | translate"
                  #confirmMpinInput [isFocus]="false"
                  (codeChanged)="confirmPinChanged($event)"
                  (codeCompleted)="checkPin($event, 'C')">
               </app-mpininput>
            </div>
         </div>
      </div>
</div>
<div class="mat-dialog-actions" [class.mda-d-block] = "isMobile">
   <div class="row">
       <div class="col-12 text-right pr-3">
           <button  mat-flat-button class="uitest-frgtuid-submitbtn btn-primary"  (click) = "submit()"><span translate>settings.submit</span></button>
       </div>
   </div>
</div>