<div class="row stickychips" *ngIf = "tradeList">
    <div class="col-12" *ngIf="tradeList"><div class="row">
        <div class="col-10">
            <div class="row">
                <div class="col-12" *ngIf = "tradeList.length > 1">
                    <mat-chip-list *ngIf='!(!isLoading && isNoData) || isFilterNoData'>
                        <mat-chip  *ngIf = "!isLoading"  [class.imactive]="selectedLabel === 'ALL'" id="tab-header" (click) = "onTabChange('ALL','All')">ALL</mat-chip>
                        <mat-chip *ngFor="let obj of dataArr | slice:0: dataArrLen; let i = index" (click)="onTabChange(obj.data, obj.type, i)" [class.imactive]="obj.visibility === true">{{obj.data}}</mat-chip>
                        <mat-chip *ngIf = "isExpandVisible" id="tab-header" (click) = "onExpanClick()" title="Expand">{{expandText}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div #sortMenu class="col-2 text-right pl-0 my-auto" *ngIf="tradeList.length > 0">
            <button disabled="false" #menuTrigger="matMenuTrigger" class="btn-icon mr-1" mat-icon-button [matMenuTriggerFor]="sortnav" (menuClosed)="matMenuClosed()">
                <i class="icon-menu"></i>
                <em *ngIf="(selectedSortedItem !== sortItemList[0] || (selectedSortedItem === sortItemList[0] && chipSelected === matChipItems[1])) && (cellView || isMobile)" class="wl-bubblesmall"></em>
            </button>
            <mat-menu #sortnav="matMenu" xPosition="before" [class.col-12] = "true" class="panelnav">
                <div *ngIf="cellView || isMobile" [ngClass]="{'d-sm-none' : isMobile}" class="primetext p-2 ts-2">{{'all.sorting' | translate}}</div>
                <div *ngIf="cellView || isMobile" [ngClass]="{'d-sm-none' : isMobile}" (click)="$event.stopPropagation();$event.preventDefault();"><button (click)="sortingItemSelected(sortingItem)" mat-menu-item *ngFor="let sortingItem of sortItemList" >{{sortingItem}} <span class="rightitem impositive"><i [class.icon-godown]= "chipSelected === 'Asc' && selectedSortedItem === sortingItem" [class.icon-goup]= "chipSelected === 'Desc' && selectedSortedItem === sortingItem"></i></span></button></div>
                <div class="border-t pt-2">
                    <button *ngIf = "!isLoading && !isNoData" mat-menu-item matTooltip="{{'all.export' | translate}}" (click)="exportCSV()">{{'all.download' | translate}}<span class="rightitem"><i class="icon-refresh"></i></span></button>
                    <button *ngIf = "!isLoading && !isNoData && ((!cellView && !isMobile) || isMobileLand)" matTooltip="{{'all.manage_columns' | translate}}" mat-menu-item  (click)="openManageBook()">{{'all.manage_columns' | translate}}<span class="rightitem"><i class="icon-setting"></i></span></button>
                </div>
            </mat-menu>        
        </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div *ngIf='!isLoading && isNoData' class="">
            <app-nodata (refreshEvent) = "isFilterNoData ? onReset() : onRefresh()" [btnTitle]="isFilterNoData ? ('all.reset' | translate) : ('all.reload' | translate)"></app-nodata>
        </div>
        <ngx-pull-to-refresh
        spinnerColor="#ff0000"
        distanceForRefresh="80"
        [isEnable]="isMobile"
        [targetElement]="targetElement"
        (refresh)="myRefreshEvent($event, 'refresh')"
        (loadMore)="alert('loadmore')"
        class = "loader">
        <div *ngIf='!isLoading && !isNoData'>
            <div #p2 *ngIf="isMobile || cellView"  [ngStyle]="Height">
                <div *ngFor="let data of filteredTradeList; let i = index" [attr.id]="'tb_cell' + i" [class.wlweb]="cellView" [class.pl-2]="cellView" [class.pr-2]="cellView" (click)="highlight(data, i)" tabindex="999" >
                    <div class="row">
                        <div class="col-12" [ngClass]="{'highlight': selectedRowIndex == i}">
                            <div class="list-view border-b" [ngClass]="{'d-block d-sm-none' : isMobile}">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="subcolor ts-2">
                                            <div><span [class.tag-red]="data.TranType=='S'" [class.tag-green]="data.TranType=='B'">{{transactionTypeMap[data.TranType]}}</span> | {{'all.qty' | translate}}: <span class="fw-3 tc-3">{{data.FilledQty | numberpipe}}</span></div>
                                            <span [ngClass]="{'tc-1' : true, 'ts-4' : true, 'texttrim' : true, 'fnotext' : data.Exchange === 'NFO'}" matTooltip="{{data.TSym | symbolpipe}}">{{data.TSym | symbolpipe}}</span><small class="exchangename">{{data.Exchange}}</small><br>
                                            {{data.Pcode | valuepipe}} | <span>{{data.PrcType | valuepipe}}</span>
                                        </div>
                                    </div>
                                   <div class="col-6">
                                       <div class="subcolor ts-2 text-right">
                                           <div>{{(data.ExchTime === '--') ? '--' : (data.ExchTime | datepipe:'mediumtime')}}</div>
                                           <span class="subcolor2">{{'all.prc' | translate}}: </span><span class="ts-4 tc-1">{{data.AvgPrice  | pricepipe:data.scripModel.DecimalPrecision}}</span>
                                           <div [class.imnegative]="data.scripModel.PercentageChange<0" [class.impositive]="data.scripModel.PercentageChange>0">
                                                <div [class.imnegativebg]="data.scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="data.scripModel.TickDirection === 1 && flashType === 'defaultFlash'">
                                                    <span [class.imnegativebg]="data.scripModel.TickDirection === -1 && flashType === 'ltpFlash'" [class.impositivebg]="data.scripModel.TickDirection === 1 && flashType === 'ltpFlash'">{{data.scripModel.LastTradePrice | pricepipe:data.scripModel.DecimalPrecision}}</span>&nbsp;
                                                    <small class="ts-1">({{data.scripModel.PercentageChange | percentagepipe}})</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="wl-btn" *ngxCacheIf="!isMobile && cellView" [class.d-none]="!M.IS_MOUSE">
                                    <button mat-icon-button matTooltip="{{'all.buy' | translate}}" (click)="handleButton('ADD', data)" class="btn-positive"><i class="icon-bb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.sell' | translate}}" (click)="handleButton('REMOVE', data)" class="btn-negative ml-2"><i class="icon-sb"></i></button>
                                    <button mat-icon-button (click) = "addWLdata(data)" matTooltip="{{'all.addto_watchlist' | translate}}"  class="ml-2 btn-primary" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                    <mat-menu #menu="matMenu">
                                        <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                        <ng-container *ngFor = "let wldata of WlArr">
                                            <button mat-menu-item (click) = "addStock(wldata.wlName, data)" [disabled] = "wldata.isDisable">
                                                <span>{{wldata.wlName}}</span>
                                            </button>
                                        </ng-container>
                                    </mat-menu>
                                    <button mat-icon-button matTooltip="{{'all.stock_detail' | translate}}" class="btn-primary ml-2"  (click)="handleButton('sd', data)"><i class="icon-ib"></i></button>
                                    <!-- <button mat-icon-button [disabled] = "data.Pcode == 'CO' || data.Pcode == 'BO'" matTooltip="{{'tooltip.position_conversion' | translate}}" class="btn-primary ml-2" (click)="handleButton('PC', data)"><i class="icon-pcb"></i></button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <ng-container *ngIf="!cellView">
                <div #p1 class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                    <table mat-table [dataSource]="filteredTradeList" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matSort (matSortChange)="sortTradeBook($event)">
                        <ng-container matColumnDef="TSym" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag [cdkDragDisabled]="true"> <span translate>all.instrument</span> </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="button-row" *ngIf = "!isMobile">
                                <div class="float-button-container" [ngStyle]="left"  [class.active]="element.show == true" [class.d-none]="!M.IS_MOUSE">
                                    <button mat-icon-button matTooltip="{{'all.buy' | translate}}" class="btn-positive" (click)="handleButton('ADD', element)"><i class="icon-bb"></i></button>
                                    <button mat-icon-button matTooltip="{{'all.sell' | translate}}" class="btn-negative" (click)="handleButton('REMOVE', element)"><i class="icon-sb"></i></button>
                                    <button mat-icon-button (click) = "addWLdata(element)" matTooltip="{{'all.addto_watchlist' | translate}}"  class="btn-primary" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                    <mat-menu #menu="matMenu">
                                        <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                        <ng-container *ngFor = "let data of WlArr">
                                            <button mat-menu-item (click) = "addStock(data.wlName, element)" [disabled] = "data.isDisable">
                                                <span>{{data.wlName}}</span>
                                            </button>
                                        </ng-container>
                                    </mat-menu>
                                    <button mat-icon-button matTooltip="{{'all.stock_detail' | translate}}" class="btn-primary"  (click)="handleButton('sd', element)"><i class="icon-ib"></i></button>
                                    <!-- <button mat-icon-button  [disabled] = "element.Pcode == 'CO' || element.Pcode == 'BO'" matTooltip="{{'tooltip.position_conversion' | translate}}" class="btn-primary" (click)="handleButton('PC', element)"><i class="icon-pcb"></i></button> -->
                                </div>
                            </span>
                            <span [matTooltip]="element.scripModel.CompanyName" class="txtsmall">{{element.TSym | symbolpipe}} <small class="verti">{{element.Exchange}}</small></span></td>
                        </ng-container>
                        <ng-container matColumnDef="TranType">
                            <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag> <span translate> all.transaction_type</span> </th>
                            <td class="text-left" mat-cell *matCellDef="let element"><span [class.imnegative]="element.TranType=='S'" [class.impositive]="element.TranType=='B'">{{transactionTypeMap[element.TranType]}}</span></td>
                        </ng-container>
                        <ng-container matColumnDef="FilledQty">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag> <span translate>all.qty</span></th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{element.FilledQty | numberpipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Pcode">
                            <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag><span translate> all.product</span> </th>
                            <td class="text-left" mat-cell *matCellDef="let element"> {{element.Pcode | valuepipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="AvgPrice">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header  class="text-right" cdkDrag><span translate> all.avgprice</span> </th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{element.AvgPrice | pricepipe: element.scripModel.DecimalPrecision}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NestOrd">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag><span translate> all.trade_id</span> </th>
                            <td mat-cell *matCellDef="let element" class="text-right">{{element.FillId}}</td>
                        </ng-container>
                        <ng-container matColumnDef="FillDateTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag><span translate> all.trade_time</span> </th>
                            <td mat-cell *matCellDef="let element"> {{element.FillTime | datepipe:'timeformat'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NestNo1">
                            <th mat-header-cell *matHeaderCellDef  class="text-right" cdkDrag><span translate>all.NestNo</span></th>
                            <td mat-cell *matCellDef="let element" class="text-right"> {{element.AvgPrice | pricepipe: element.DecimalPrecision}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" [ngClass]="{'highlight': selectedRowIndex == i}" (click)="highlight(row, i)" tabindex="999" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)" [attr.id]="'tb_tbl' + i"></tr>
                    </table>
                    </div>
            </ng-container>
        </div>
        </ngx-pull-to-refresh>
    </div>
</div>
