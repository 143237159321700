import { Injectable } from '@angular/core';
import { IIndexService } from './iindex.service';
import { ApiService } from '../api.service';
import { TradingRestService } from '../tradingrest.service';
import { Keys } from '../keys';
import { Utility } from '../utility';
import { Url } from '../url';
import { Preferences } from '../Preferences';

let hypersyncIndexService: HSIndexService;

@Injectable()
export class HSIndexService implements IIndexService {
   apiService: ApiService;
   tradingRestService: TradingRestService;
   constructor(apiService: ApiService) {
      this.apiService = apiService;
      hypersyncIndexService = this;
   }
   getIndexExchange(callback: Function) {
      const returndata = {'stat': 'Ok', 'IndexExchange' : JSON.parse(Preferences.getPreference('userParameter'))['exArr'] };
      callback(returndata, Keys.RES_CODE_SUCCESS, '');

   }
   getIndexDetails(Exchange: string, callback: Function) {
      let message = '';
      let code = Keys.RES_CODE_SUCCESS;
      const data = [];
      const jsonSendObj = { 'exch': Exchange };
      const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('userSessionId'));
      this.apiService.hspost(Url.HS_INDEX_DETAILS, param).subscribe(res => {
         const stat = res['stat'];
         if (stat === 'Ok') {
            for (let i = 0; i < res['idxList'].length; i++) {
               const im = Utility.getIndexModel(res['idxList'][i], Exchange, hypersyncIndexService.tradingRestService, true);
               im.Name = res['idxList'][i];
               data.push(im);
            }
            message = 'OK';
         } else {
            message = res['Emsg'];
            code = Keys.RES_CODE_FAILURE;
            if (message === Keys.NO_DATA) {
               code = Keys.RES_CODE_SUCCESS;
            }
         }
         callback(data, code, message);
      },
         error => {
            code = Keys.RES_CODE_FAILURE;
            message = error;
            callback(data, code, message);
         });
   }

   setTradingRestService(tradingRest: TradingRestService) {
      hypersyncIndexService.tradingRestService = tradingRest;
   }
}
