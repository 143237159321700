import { Component, Injectable, OnInit, EventEmitter, Output, Input, ChangeDetectorRef, Injector, ElementRef } from '@angular/core';
import { TradingRestService } from '../../../services/tradingrest.service';
import { Utility } from '../../../services/utility';
import { Keys } from '../../../services/keys';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { TableUtility } from 'src/app/services/tableutility';
import { ComponentContainer } from 'golden-layout';
import { Subject } from 'rxjs';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
let exchangeMessage : ExchangeMessageComponent;

@Component({
  selector: 'app-exch-message',
  templateUrl: './exchangemessage.component.html',
  styleUrls: ['./exchangemessage.component.scss']

})
@Injectable()

export class ExchangeMessageComponent extends BaseComponentDirective implements OnInit {
  @Output() load = new EventEmitter<boolean>();
  @Input() isCustomDashboard = false;
  targetElement: Element;
  exchangeArray;
  isNoData;
  isLoading: boolean;
  exchangeMessageData;
  tagText;
  isMobile: boolean;
  Height: any;
  modesub: Subscription;
  container;
  isReload = false;
  constructor(elRef : ElementRef, private tradingRestService: TradingRestService, private injector: Injector, private detector: ChangeDetectorRef) {
    super(elRef.nativeElement);
    exchangeMessage = this;
    this.isLoading = true;
    this.load.emit(this.isLoading);
    this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      this.changeMode();
    });
    this.isMobile = Utility.IS_MOBILE;
    this.exchangeArray = Utility.ChangeOrderOfExchange();
    this.tagText = this.exchangeArray[0];
    this.getExchStatus(this.tagText);

    Utility.webReloadComponent.subscribe(data =>
    {
      if(exchangeMessage.isReload === false && data === 'Exchange Message')
      {
        exchangeMessage.isReload = true;
        console.log('reload');
        this.reloadData();
      }
    });
  }

  reloadData()
  {
    this.onRefresh();    
  }

  ngOnInit() {
    this.targetElement = document.querySelector('html');
    if(this.inputs !== undefined)
      this.isCustomDashboard = this.inputs.isCustomDashboard;
    if (this.isCustomDashboard) {
    //  this.container = <ComponentContainer>this.injector.get('Container');
      this.changeMode();
      /* this.container.on('resize', _ => {
        this.changeMode();
      });*/
    }
  }
  changeMode() {
    this.container = this.container === undefined ? Utility.setSize : this.container;
    this.Height = {
      'height': (this.container.height - TableUtility.ExMSGHeight) + 'px',
      'overflow': 'auto'
    };
    this.detector.detectChanges();
  }
  onRefresh() {
    this.getExchStatus(this.tagText);
  }
  getExchStatus(exch) {
    this.tagText = exch;
    this.isLoading = true;
    this.load.emit(this.isLoading);
    this.tradingRestService.getmarketService().getExchangeMessage(exch, function(data, code, message) {
      this.isLoading = false;
      this.isReload = false;
      this.load.emit(this.isLoading);
      if (code === Keys.RES_CODE_SUCCESS) {
        if (message === 'No Data')
          this.isNoData = true;
        else
          this.isNoData = false;
        this.exchangeMessageData = data;
      }
      else
        this.isNoData = true;
    }.bind(this), this.preExchangeData.bind(this));
  }

  preExchangeData(data){
    this.isLoading = false;
    this.load.emit(this.isLoading);
    if (data){
      this.isNoData = false;
        this.exchangeMessageData = data;
    }
    else{
      this.isNoData = true;
    }
  }

  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      console.log('my ref');
      this.reloadData();
      event.next();
    }, 1000);
  }
  
  alert(message: string) {
    
  }
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    Utility.setSize = this.container;
    this.changeMode();
  }
}
