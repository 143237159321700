export class LimitModel {
    CollateralValue: any;
    AvailableMargin: any;
    AvailableCash: any;
    Others: any;
    PayOut: any;
    VAR: any;
    PayoutAmt: any;
    StockValuation: any;
    DirectCollateralValue: any;
    NetcashAvailable: any;
    OpeningBalance: any;
    IPOAmount: any;
    UtilizedAmount: any;
    PayinAmt: any;
    BookedPNL: any;
    UnbookedPNL: any;
    AdhocMargin: any;
    NotionalCash: any;
    LienFunds: any;
    FinanceLimit: any;
    CashMargin: any;
    NonCashMargin: any;
    MarginAvailableForUnlienFo: any;
    fOclearcashUtilization: number;
    marginNetofM2MBPLTPPFW: number;
    creditforDematSell: number;
    MarginAvaolableForUnlienCash: any;
    MarginAvailableForFundWithdrawal: number;
    Segment: any;
    SpanMargin: any;
    Exposure: any;
    UsedMargin: any;
    DeliveryMargin: any;
    OptionNRML: any;
    OptionMIS: any;
    TotalCollateral: any;
    PremiumPrsnt: any;
    DeliveryMarginPresent: any;
}
