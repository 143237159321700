<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row list-view border-b" (swipedown)="onSwipeDown()">
    <div class="col-8 pl-0">
        <div class="subcolor min-w160 ts-2 bottomsheetwidth ml-2">
            <span class="tc-1 ts-4 textclip">{{selectedTrgData.cond.Symbolname | symbolpipe}}</span><small class="exchangename">{{exchName}}</small>
            <div class="textclip d-block" matTooltip = "{{selectedTrgData.cond.sym}}">{{selectedTrgData.cond.symdes}}</div>
        </div>
    </div>
    <div class="col-4 text-right" [class.imnegative]="scripModel.Change<0" [class.impositive]="scripModel.Change>0">
        <div>
            <div [class.ts-3] = "scripModel.DecimalPrecision == 4" [class.ts-4] = "scripModel.DecimalPrecision != 4"><span [class.imnegativebg]="scripModel.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="scripModel.TickDirection === 1 && flashType !== 'noFlash'">{{scripModel.LastTradePrice | pricepipe:scripModel.DecimalPrecision}}</span><br>
                <span class="ts-2" [class.imnegativebg]="scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="scripModel.TickDirection === 1 && flashType === 'defaultFlash'">{{scripModel.Change | pricepipe:scripModel.DecimalPrecision}} <small class="ts-1">({{scripModel.PercentageChange | percentagepipe}})</small></span>
            </div>
        </div>
    </div>
</div>
<div class="sheetscrollarea orderbook-area">
    <div class="row text-center ts-2 py-2 border-b mb-2">
        <div class="col-6">
            <span class="subcolor ts-1">{{'all.status' | translate}}</span><br>
            <label [ngClass]="getClass(statusMap[selectedTrgData.stat])">{{statusMap[selectedTrgData.stat]}}</label>
        </div>
        <div class="col-6"><span class="subcolor ts-1">{{'all.action_type' | translate}}</span><br>{{selectedTrgData.ord[0].ttyp === 'B' ? ('all.buy' | translate) : ('all.sell' | translate)}}</div>
    </div>
    <div class="row">
        <div class="col-12 pr-0">
            <div class="row">
                <div class="col-12">
                    <table class="table-bottomsheet">
                        <tr><td><span class="namefield">{{'all.created_at' | translate}}</span></td><td> {{selectedTrgData.crtAt | datepipe: 'datetime'}}</td></tr>
                        <tr><td><span class="namefield">{{'all.updated_at' | translate}}</span></td><td> {{selectedTrgData.upAt | datepipe: 'datetime'}}</td></tr>
                        <tr><td><span class="namefield">{{'all.expire_at' | translate}}</span></td><td>  {{selectedTrgData.exAt | datepipe: 'datetime'}}</td></tr>
                        <tr><td><span class="namefield">{{selectedTrgData.typ==='GTT_OCO' ? ('all.trgt_trigger_price'|translate) : ('all.trg_prc'|translate)}}</span></td><td>  {{selectedTrgData.ord[0].tv | pricepipe: 2}}</td></tr>
                        <tr *ngIf="selectedTrgData.typ==='GTT_OCO'"><td><span class="namefield">{{'all.stp_trigger_price'|translate}}</span></td><td> {{selectedTrgData.ord[1].tv |  pricepipe: 2}}</td></tr>
                        <tr><td><span class="namefield">{{selectedTrgData.typ==='GTT_OCO' ? ('all.target_price'|translate) : ('all.prc'|translate)}}</span></td><td>  {{selectedTrgData.ord[0].px | pricepipe: 2}}</td></tr>
                        <tr *ngIf="selectedTrgData.typ==='GTT_OCO'"><td><span class="namefield">{{'all.stoploss_price'|translate}}</span></td><td> {{selectedTrgData.ord[1].px | pricepipe: 2}}</td></tr>
                        <tr><td><span class="namefield">{{selectedTrgData.typ==='GTT_OCO' ? ('all.target_qty'|translate):('all.basket_qty'|translate)}}</span></td><td>  {{selectedTrgData.ord[0].qty}}</td></tr>
                        <tr *ngIf="selectedTrgData.typ==='GTT_OCO'"><td><span class="namefield">{{'all.stoploss_qty'|translate}}</span></td><td> {{selectedTrgData.ord[1].qty}}</td></tr>
                        <tr><td><span class="namefield">{{'all.producttype' | translate}}</span></td><td>  {{selectedTrgData.ord[0].prod}}</td></tr>
                        <tr><td><span class="namefield">{{'all.ordertype' | translate}}</span></td><td>  {{selectedTrgData.ord[0].otyp === 'L'? 'LIMIT' : '-'}}</td></tr>
                    </table>
                <div >
            </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedTrgData.stat === 1 || selectedTrgData.stat === 6" class="row bottomsheet-buttons">
    <div class="col-12 p-0">
        <div class="btngroup">
            <div *ngIf="selectedTrgData.stat === 1" class="imnegative" (click)="deleteTrigger(selectedTrgData)" matRipple><i class="icon-delete"></i>{{'all.delete' | translate}}</div>
            <div *ngIf="selectedTrgData.stat === 1 || selectedTrgData.stat === 6" (click)='editTrigger(selectedTrgData)' matRipple><i class="icon-edit"></i>{{'all.edit' | translate}}</div>
        </div>
    </div>
</div>