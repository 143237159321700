import { ScripModel } from './scrip.model';
import { HoldingModelChanged } from '../interface/HoldingModelChanged';
export class HoldingModel {
    scripModel: ScripModel;
    WHqty: number;
    SellableQty: number;
    CUqty: number;
    hsflag: string;
    CSEHOldingValue: number;
    Token5: any;
    Btst: number;
    Token3: any;
    Token4: any;
    Token1: any;
    Token2: any;
    BuyQty: string;
    WCqty: number;
    DaysMTM: number;
    LTnse: number;
    Ltp: number;
    Ttrind: string;
    Mcxsxcmsym: string;
    Bsetsym: string;
    Price: number;
    Haircut: string;
    Series: string;
    Usedqty: number;
    HUqty: number;
    Tprod: string;
    Scripcode: string;
    NSEHoldingValue: number;
    Series1: string;
    Nsetsym: string;
    Csetsym: string;
    Colqty: number;
    Exch1: string;
    Exch2: string;
    Exch3: string;
    Exch4: string;
    csflag: string;
    Exch5: string;
    BSEHoldingValue: number;
    Ysxtsym: string;
    LTmcxsxcm: number;
    Coltype: string;
    LTPValuation: number;
    ExchSeg4: any;
    ExchSeg3: any;
    ExchSeg2: any;
    YSXHoldingValue: number;
    ExchSeg1: any;
    ExchSeg5: any;
    LTbse: number;
    Holdqty: number;
    LTysx: number;
    LTcse: number;
    MCXHoldingValue: number;
    Pcode: string;
    isin: string;
    Symbol: any;
    ExchangeSeg: any;
    Exchange: any;
    Token: any;
    Clientid: any;
    MaturityDate: string;
    IssueDate: string;
    Settlements: string;
    DecimalPrecision: any;
    ObligationQty: any;
    TotalHoldingQty: any;
    AvailableQty: number;
    PrimaryLTP: number;
    PrimaryLTPStr: any;
    PrimaryValuation: any;
    PrimaryValuationStr: any;
    DayPNL: number;
    NetPNL: number;
    PNLPercentageChg: number;
    DayPNLStr: any;
    PriceDisplay: any;
    isSelected: boolean = false;
    tOneQty: number;
    NonPOAQty: number;
    holdingModelChanged: HoldingModelChanged;
    constructor () {
    }
    setScripModel(x) {
        this.scripModel = x;
        this.scripModel.lastTradedPriceChanged = this.onLtpChange.bind(this);
    }
    onLtpChange(sm) {
        this.holdingModelChanged(this);
    }
}
