import { Keys } from '../services/keys';
import { ScripModel } from './scrip.model';

export class SpreadOrderModel {
    ProductCode: string;
    AvgPrice: number;
    AvgPriceLeg2: number;
    Status: string;
    Action: string;
    ActionDisplay: string;
    ActionLeg2: string;
    ActionDisplayLeg2: string;
    FilledShares: number;
    FillPrice: number;
    FillPriceLeg2: number;
    ExchangeOrderNo: string;
    ExchangeOrderNoLeg2: string;
    TSym: string;
    TSymLeg2: string;
    PendingQty: number;
    PendingQtyLeg2: number;
    scripModel: ScripModel;
    FillQty: number;
    Exchange: string;
    TradeSymbol: string;
    NestOrd: string;
    Token: string;
    ExchangeSegment: string;
    DisplayStatus: string;
    nestOrderNumber: string;
    exchSeg: string;
    exchange: string;
    SymbolName: string;
    ExpiryDate: string;
    ExpiryDateLeg2: string;
    FilledSharesLeg2: number;
    FillQtyLeg2: number;
    exchOrderUpdateTime: string;
    PriceType: string;
    UserId: string;
    AccountId: string;
    Text: string;
    discQty: string;
}
