import { Component, OnInit, ViewChild, HostListener, ChangeDetectorRef, Input, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChannelType, Utility } from '../../../services/utility';
import { ScripModel } from '../../../model/scrip.model';
import { TradingRestService } from '../../../services/tradingrest.service';
import { IScripService } from '../../../services/scrip/iscrip.service';
import { Router } from '@angular/router';
import { LightWeightChartComponent } from '../lightweightchart/lightweightchart.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stockdetail',
  templateUrl: './stockdetail.component.html'
})
export class StockdetailComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('lightweightchart', { static: false }) lightweightchart: LightWeightChartComponent;
  @Input() omtoken: string;
  @Input() exchseg: string;
  @Input() isCustomDashboard: boolean;
  isngOnChange: boolean;
  graphResizedWidth;
  scrpModel: ScripModel;
  scripService: IScripService;
  graphHeight = 250;
  isHideTab;
  tabIndex;
//  circuitData;
//  lowHighData;

  graphWidth;
  isLoading: boolean;
  omtkn;
  exseg;
  dataArr = [];
  isSearch = false;
  goback = '1';
  defaultFeature = environment.DefaultFeature;
  betaFeature = environment.BetaFeature;
  advanceFeature = environment.AdvanceFeature;
  isLand;
  orientationSubscription:Subscription;
  constructor(private activatedRoute: ActivatedRoute, private tradingRestService: TradingRestService, private router: Router, private dialog: MatDialog, private cdr: ChangeDetectorRef) {
    if (!Utility.IS_MOBILE) {
      this.isSearch = true;
      this.goback = '2';
    }
    else {
      this.isLand = Utility.IS_MOBILE_LAND;
      this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
        this.isLand = Utility.IS_MOBILE_LAND;
      });
    }
  }

  ngOnChanges() {
    this.isngOnChange = true;
    this.omtkn = this.omtoken;
    this.exseg = this.exchseg;
    this.initStockDetail();
  }

  ngOnInit() {
    this.initStockDetail();
  }

  ngOnDestroy() {
    this.onDisappearing();
    this.orientationSubscription?.unsubscribe();
  }

  onDisappearing() {
      Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
  }
  
  initStockDetail() {
    this.scripService = this.tradingRestService.getScripService();
    this.activatedRoute.queryParams.subscribe(params => {
      if (!this.isngOnChange) {
        this.omtkn = params['omtkn'];
        this.exseg = params['exseg'];
      }
      if (this.exseg === 'nse_cm' || this.exseg === 'bse_cm') {
        this.isHideTab = true;
      }
      this.scrpModel = Utility.getScripModel(this.omtkn, this.exseg, this.tradingRestService, true);
    });
    this.dataArr = ['stock_detail.overview', 'stock_detail.peer', 'stock_detail.news', 'stock_detail.financials'];
    if (Utility.stockDetailIndex > 0){
      this.tabIndex = Utility.stockDetailIndex;
    }
    else{
      this.tabIndex = 0;
    }
    if (this.omtkn && this.exseg)
      Utility.addRecentlySearchedScrips(this.omtkn, this.exseg, this.scrpModel.TradingSymbol, this.scrpModel.CompanyName);
  }


  openPlaceOrder(buttontype) {
    Utility.openPlaceOrder(this.router, this.omtkn, this.exseg, buttontype, this.dialog);
  }


  ngAfterViewInit() {
    this.isngOnChange = false;
    if(Utility.stockDetailIndex === 0){
      this.graphWidth = document.getElementById('lite-weight').offsetWidth;
    }
    this.cdr.detectChanges();
  }

  chartEvent($event) {
    this.lightweightchart.plotChart(this.omtkn, this.exseg);
  }

  loaderEvent($event) {
    this.isLoading = $event;
    this.cdr.detectChanges();
  }

  onTabChanged(index) {
  Utility.stockDetailIndex = index;
   this.tabIndex = index;
    if (index === 0) {
      setTimeout(() => {
        this.graphWidth = document.getElementById('lite-weight').offsetWidth;
      }, 10);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.graphResizedWidth = document.getElementById('lite-weight').offsetWidth;
    this.lightweightchart.chartResized(this.graphResizedWidth);
  }

  openTradingViewChart() {
    this.router.navigate(['tradingview'], { queryParams: { 'exch': this.exseg, 'symbol': this.omtkn, 'type': 'stock', 'fromStockDetail': true } });
  }
}
