import { Component, OnInit } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
let privacyComponent : PrivacyComponent;
@Component({
  selector: 'app-privacy',
  templateUrl: '../../../'+environment.baseviewpath+'/pages/privacy/privacy.component.html'
})
export class PrivacyComponent implements OnInit {
  selectedLabel = 'privacyPolicy';
  selectedParagraph;
  isMobile;
  constructor() {
    privacyComponent = this;
   }

  ngOnInit(): void {
    privacyComponent.isMobile = Utility.IS_MOBILE;
  }

  onTabChange(selectedLabel){
    privacyComponent.selectedLabel = selectedLabel;
    privacyComponent.selectedParagraph = '';
  }

  AnchorClicked(selectedText){
    if (selectedText === privacyComponent.selectedParagraph){
      privacyComponent.selectedParagraph = '';
    }
    else{
      privacyComponent.selectedParagraph = selectedText;
    }
  }

}
