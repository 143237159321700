export class ScripContentDataModel {
    public SevenDayReturn: number;
    public OneMonthReturn: number;
    public ThreeMonthReturn: number;
    public SixMonthReturn: number;
    public OneYearReturn: number;
    public SevenDayVolume: number;
    public OneMonthVolume: number;
    public ThreeMonthVolume: number;
    public SixMonthVolume: number;
    public OneYearVolume: number;
    public VolumeOne: number;
    public VolumeTwo: number;
    public SevenDayReturnVolume: number;
    public OneMonthReturnVolume: number;
    public ThreeMonthReturnVolume: number;
    public SixMonthReturnVolume: number;
    public OneYearReturnVolume: number;
    public Sma20: string;
    public Sma50: string;
    public Sma100: string;
    public Sma200: string;
    public Ema20: string;
    public Ema50: string;
    public Ema100: string;
    public Ema200: string;
    public UpperBand: string;
    public MiddleBand: string;
    public LowerBand: string;
}
