<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row list-view border-b" (swipedown)="onSwipeDown()">
    <div class="col-8 pl-0">
        <div class="addwatchlistbtn" matRipple>
            <button mat-icon-button (click) = "addWLdata(tradeDetailData)" [matMenuTriggerFor]="menu" class="primetext"><i class="icon-plus"></i></button>
            <mat-menu #menu="matMenu">
                <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                <ng-container *ngFor = "let data of WlArr">
                    <button mat-menu-item (click) = "addStock(data.wlName, tradeDetailData)" [disabled] = "data.isDisable">
                        <span>{{data.wlName}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </div>
        <div class="min-w160 subcolor ts-2">
            <span class="tc-1 ts-4 textclip">{{tradeDetailData.ExchangeSeg == 'nse_fo' ? tradeDetailData.Symbol : tradeDetailData.TSym | symbolpipe}}</span><small class="exchangename">{{tradeDetailData.Exchange}}</small><br>
            <div class="texttrim" matTooltip="{{tradeDetailData.scripModel.DisplaySymbolDescription}}">{{tradeDetailData.scripModel.DisplaySymbolDescription}}</div>
        </div>
    </div>
    <div class="col-4 text-right" [class.imnegative]="tradeDetailData.scripModel.Change<0" [class.impositive]="tradeDetailData.scripModel.Change>0">
        <div>
            <div [class.ts-3] = "tradeDetailData.scripModel.DecimalPrecision == 4" [class.ts-4] = "tradeDetailData.scripModel.DecimalPrecision != 4"><span [class.imnegativebg]="tradeDetailData.scripModel.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="tradeDetailData.scripModel.TickDirection === 1 && flashType !== 'noFlash'">{{tradeDetailData.scripModel.LastTradePrice | pricepipe:tradeDetailData.scripModel.DecimalPrecision}}</span><br>
                <span class="ts-2" [class.imnegativebg]="tradeDetailData.scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="tradeDetailData.scripModel.TickDirection === 1 && flashType === 'defaultFlash'">{{tradeDetailData.scripModel.Change | pricepipe:tradeDetailData.scripModel.DecimalPrecision}} ({{tradeDetailData.scripModel.PercentageChange | percentagepipe}})</span>
            </div>
        </div>
    </div>
</div>

<div class="sheetscrollarea tradebook-area">
    <div class="row text-center ts-2 py-2 border-b border-t mb-2">
        <div class="col-3"><span class="subcolor ts-1" translate>all.ordertype</span><br> {{tradeDetailData.PrcType | valuepipe}}</div>
        <div class="col-3"><span class="subcolor ts-1" translate>all.qty</span><br> {{tradeDetailData.FilledQty | numberpipe}}</div>
        <div class="col-3"><span class="subcolor ts-1" translate>all.ordervalidity</span><br> {{tradeDetailData.OrdDuration}}</div>
        <div class="col-3"><span class="subcolor ts-1" translate>all.trade_id</span><br> {{tradeDetailData.FillId}}</div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table-bottomsheet">
                <tr>
                    <td><span class="namefield" translate>all.transaction_type</span></td><td> {{tradeDetailData.TranType | valuepipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.exch_orderno</span></td><td> {{tradeDetailData.ExchordID}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.exchange_time</span></td><td>{{tradeDetailData.ExchTime | datepipe:'datetime'}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.internal_orderno</span></td><td>{{tradeDetailData.NestOrd}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.avgprice</span></td><td> {{tradeDetailData.AvgPrice | pricepipe: tradeDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.product</span></td><td> {{tradeDetailData.Pcode | valuepipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.cust_firm</span></td><td> {{tradeDetailData.CustOFrm}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.nestorderid</span></td><td> {{tradeDetailData.NOReqID}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.fillleg</span></td><td> {{tradeDetailData.FillLeg}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.trade_time</span></td><td>{{tradeDetailData.FillTime | datepipe:'datetimeFormat2'}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>
<div class="row bottomsheet-buttons">
        <div class="col-12">
            <div class="btngroup">
                <!-- <div (click)="handleButton('PC')"><i class="icon-pcb"></i>Convert</div> -->
                <!--div [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i>Watchlist</div>
                    <mat-menu #menu="matMenu">
                        <mat-optgroup label="Select Watchlist" class="watchlist-nav"></mat-optgroup>
                        <ng-container *ngFor = "let data of WlArr">
                            <button mat-menu-item (click) = "addStock(data, tradeDetailData)">
                                <span>{{data}}</span>
                            </button>
                        </ng-container>
                    </mat-menu-->
                    <div (click)="handleButton('ADD')" class="impositive"><i class="icon-bb"></i>{{'all.buy' | translate}}</div>
                    <div (click)="handleButton('REMOVE')" class="imnegative"><i class="icon-sb"></i>{{'all.sell' | translate}}</div>
                    <div (click)="handleButton('sd')"><i class="icon-ib"></i>{{'all.detail' | translate}}</div>
                
            </div>    
        </div>
    </div>