import { Component, OnInit, Input, ElementRef, ViewChild, ChangeDetectorRef, Output, EventEmitter, OnChanges, AfterViewInit, HostListener, OnDestroy, NgZone } from '@angular/core';
import { PtPlusRestService } from '../../../../services/ptplusrest.service';
import { Location } from '@angular/common';
import { Utility } from 'src/app/services/utility';
import { ScripModel } from 'src/app/model/scrip.model';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { StockMoreOptionComponent } from '../../stockmoreoption/stockmoreoption.component';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { TableUtility } from '../../../../services/tableutility';
import { Mouse } from 'src/app/services/mouse';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { SuperPlaceholder } from 'src/app/utility/superplaceholder';

let search: SearchComponent;
@Component({
  selector: 'app-search',
  templateUrl: '../../../'+environment.baseviewpath+'/home/search/search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  tempdata= {chng: -56.6,  cnam: "TATA CONSULTANCY SERV LT",  expry: "",  exseg: "nse_cm",  last: 3759.3,  mktcap: 117550304,  omexs: "11536_nse_cm",  omtkn: "11536",  opdiff: 0,  optyp: "",  pchng: -1.48,  seris: "EQ",  stktyp: "spot",  strikprc: "-",  symdes: "TATA CONSULTANCY SERV LT",  time: "1631085787",  tsym: "TCS-EQ",  uomtkn: "",  usym: "TCS-EQ",  vol: 3346207,  wgt: "11"}  
  @Output() closeSearch  = new EventEmitter();
  @Input() callback: Function;
  @Input() formTopSearch;
  @Input() formWL;
  @Input() isOpen;
  @Input() isfromMWLSearch;
  @Input() wlnameSearch;
  @Input() fromNews;
  showheader = false;
  showNews = true;
  @Output() selectData = new EventEmitter();
  @Input() symbol: string;
  isDisable = false;
  timer;
  searchResult: Observable<ScripModel[]>;
  scripSearch = [];
  search = '';
  map = {};
  arr = [];
  searchType = 'ALL';
  searchseg;
  allowedExchange = [];
  segmentToExchange;
  getDecimal;
  keyword = '';
  isLoader = false;
  isLoadMore = false;
  wlname = '';
  isAddRemoveDisabled = false;
  WlArr = [];
  isfromWL = false;
  isfromalert = false;
  isfromnews = false;
  counter = 0;
  isfromOC = false;
  isfromSpOrder = false;
  @Input() isfromOCSearch;
  isfromMWL = false;
  wltokens = [];
  selectIndex = 0;
  tagssearch_obj = { 'start': 0, 'limit': 10 };
  filteredOptions = [];
  currentPageName = '';
  inputValue = '';
  selectedOption = 'All';
  isMobile = true;
  defaultFeature: boolean;
  exTypeArr;
  EXCHANGE_CONSTANT = {};
  recentSearchValues = [];
  isOpenLast = 0;
  isfreetrial = false;
  selectedRowIndex = 0;
  M;
  previous = '';
  createGttFlag = environment.isGTTEnable;
  sp;
  constructor(private PS: PtPlusRestService, private router: Router, private location: Location, private tradingRestService: TradingRestService, private _bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, public dialog: MatDialog, private cdr: ChangeDetectorRef, private translate: TranslateService, private zone: NgZone) {
    search = this;
    this.M = Mouse;
    search.defaultFeature = environment.DefaultFeature;
    search.searchResult = of([]);
    search.recentSearchValues = Preferences.getItem('recentlyViewScrips', ReturnType.Array);
    search.isMobile = Utility.IS_MOBILE;
    search.currentPageName = Preferences.getItem('currentpage', ReturnType.String);
    search.isfromWL = false;
    search.getDecimal = Utility.getDecimal;
    search.segmentToExchange = Utility.segmentToExchange;
    search.map['All'] = 'ALL';
    search.map['Cash'] = 'EQUITY';
    search.map['Commodity'] = 'COMMODITY';
    search.map['F&O'] = 'DERIVATIVE';
    search.map['Currency'] = 'CURRENCY';
    search.exTypeArr = Utility.getExchangeTypesForSearch();
    search.wltokens = Preferences.getItem('stocks', ReturnType.Array);
    const exarr = Utility.getTradingExchanges();
    for (let i = 0; i < exarr.length; i++) {
      search.allowedExchange.push(Utility.exchangeToSegment(exarr[i]));
    }

    const all = ['bse_cm', 'nse_cm', 'bse_fo', 'nse_fo', 'bcs_fo', 'cde_fo',  'mcx_fo', 'ncx_fo', 'nse_com', 'bse_com','nse_nie','bse_bix'];
    const cash = ['bse_cm', 'nse_cm'];
    const fno = ['bse_fo', 'nse_fo','nse_nie','bse_bix'];
    const curr = ['bcs_fo', 'cde_fo'];
    const comm = ['mcx_fo', 'ncx_fo', 'nse_com', 'bse_com'];
    search.EXCHANGE_CONSTANT = {'ALL': all.filter(value => -1 !== search.allowedExchange.indexOf(value)), 
      'EQUITY': cash.filter(value => -1 !== search.allowedExchange.indexOf(value)),
      'DERIVATIVE': fno.filter(value => -1 !== search.allowedExchange.indexOf(value)),
      'CURRENCY': curr.filter(value => -1 !== search.allowedExchange.indexOf(value)),
      'COMMODITY': comm.filter(value => -1 !== search.allowedExchange.indexOf(value)),
    };
  }

  ngOnChanges(){
    if (!search.isMobile) {
      if (search.isOpen !== search.isOpenLast){
         if (Utility.SELECTION !== 7) {
          search.isfromnews = false;
        }
        search.setSearchTabs();
        if (search.searchElement !== undefined)
          search.searchElement.nativeElement.focus();
        search.cdr.detectChanges();
      }
      search.isOpenLast = search.isOpen;
    }
    if(!this.sp && !Utility.IS_MOBILE) {
      this.zone.runOutsideAngular(() => {
        this.sp = new SuperPlaceholder({
          placeholders: [	"TCS", "TCS Fut","TCS CE", "TCS PE", "TCS "+name, "TCS Opt", "TCS option" ],
          preText: "Search: ",
          stay: 1000,
          speed: 100,
          element: '#dynamic-placeholder'
        });
        this.sp.init();
      });
    }
  }
  ngOnInit() {
    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const d = new Date();
    let n = d.getMonth();
    n = (n < 11) ? (n+1) : 0;
    let name = month[n];
    if (search.isMobile) {
      this.zone.runOutsideAngular(() => {
        this.sp = new SuperPlaceholder({
          placeholders: [	"TCS", "TCS Fut","TCS CE", "TCS PE", "TCS "+name, "TCS Opt", "TCS option" ],
          preText: "Search: ",
          stay: 1000,
          speed: 100,
          element: '#dynamic-placeholder'
        });
        this.sp.init();
      });
  

      search.isDisable = false;
      search.selectedOption = 'All';
      search.showNews = true;
      search.isfreetrial = false;
      search.activatedRoute.queryParams.subscribe(params => {
        search.wlname = params['wlname'];
        search.isfromWL = params['isfromWL'];
        search.isfromMWL = params['isfromMWL'];
        search.isfromalert = params['isfromalert'];
        search.isfromnews = params['isfromnews'];
        search.isfromOC = params['isfromOC'];
        search.isfromSpOrder = params['isfromSpOrder'];
      });
      if (search.isfromnews) {
        search.isfreetrial = true;
        search.selectedOption = 'News';
        search.isDisable = true;
      }
      if (search.isfromWL || search.isfromMWL || search.isfromOC) {
        search.showNews = false;
      }
      if (search.isfromOC) {
        search.searchType = 'DERIVATIVE';
        search.allowedExchange = ['nse_fo'];
      }
    } else {
      search.isfromalert = ((Utility.dialogData !== undefined) ? Utility.dialogData.isfromalert : false);
      if (Utility.SELECTION !== 7) {
      //  search.isfromnews = false;
      }
    }
  }

  ngOnDestroy() {
    this.sp.kill();
  }

  setSearchTabs() {
    search.wltokens = Preferences.getItem('stocks', ReturnType.Array);
    if (search.isfromnews) {
      search.selectedOption = 'News';
    } else {
      search.selectedOption = 'All';
      search.searchType = search.map[search.selectedOption];
      search.allowedExchange = search.EXCHANGE_CONSTANT[search.searchType];
    }
    search.search = '';
    search.onClearSearch();
    search.isfromWL = false;
    search.showheader = false;
    search.isfromMWL = false;
    search.showNews = false;
    search.isfromOC = false;
    search.isDisable = false;
    if (search.formTopSearch) {
      search.showNews = true;
    } else if (search.formWL) {
      search.isfromWL = true;
      search.wlname = search.wlnameSearch;
    } else if (search.isfromMWLSearch) {
      search.isfromMWL = true;
      search.wlname = search.wlnameSearch;
    } else if (search.fromNews) {
      search.showNews = true;
      search.isfromnews = true;
      search.selectedOption = 'News';
      search.isDisable = true;
    } else if (search.isfromOCSearch) {
      search.isfromOC = true;
      search.searchType = 'DERIVATIVE';
      search.allowedExchange = ['nse_fo'];
    }
  }
  ngAfterViewInit() {
    search.searchElement.nativeElement.focus();
    search.cdr.detectChanges();

  }
  openPlaceOrder(buttontype, data) {
    search.addRecentSearchData(data);
    Utility.addRecentlySearchedScrips(data.omtkn, data.exseg, data.tsym, data.cnam);
    Utility.openPlaceOrder(search.router, data.omtkn, data.exseg, buttontype, search.dialog, (environment.baseviewpath === 'fabits') ? false : true);
    if(!Utility.IS_MOBILE && environment.baseviewpath === ('investor' || 'fabits')){
      this.closeSearch.emit();
    }
   }

  openStockDetail(option) {
    search.addRecentSearchData(option);
    search.selectData.emit(option);
  }

  close() {
    if(search.isMobile) {
      search.location.back();
    } else {
      search.closeSearch.emit();
    }
  }

  onRecentKeyword(data) {
    search.inputValue = data;
    search.onSearchChange(data);
  }

  addStockPopup(wl, stockdata) {
    const seltctedStock = stockdata.exseg + '|' + stockdata.omtkn;
    WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
      if (result && msg === 'Success') {
        this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
      } else if (result && msg === 'Fail') {
        this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
      } else {
        this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
      }
    }.bind(this));
  }

  result(option, isRecentView = true) {
    search.addRecentSearchData(option);
    if (Utility.IS_MOBILE) {
      if (search.currentPageName === 'visualtrading') {
        search.router.navigate(['visualtrading'], { queryParams: { 'omtkn': option.omtkn, 'exseg': option.exseg } });
      } else if (search.isfromalert || search.isfromOC) {
          let tsym = option.exseg === 'nse_fo' ? option.usym+' '+option.symdes : option.tsym;
          Preferences.setItem('tsym', tsym);
          Preferences.setItem('exg', Utility.segmentToExchange(option.exseg));
          Preferences.setItem('token', option.omtkn);
          search.location.back();
      } else {
        let scripModel = Utility.getScripModel(option.omtkn, option.exseg, this.tradingRestService);
        scripModel.TradingSymbol = option.tsym;
        scripModel.InstrumentName = option.seris;
        if(!isRecentView)
          scripModel.DisplaySymbolDescription = option.symdes;
        this._bottomSheet.open(StockMoreOptionComponent, {
          data: scripModel, restoreFocus: false
        });
      }
    }
    else {
      if (search.formTopSearch) {
        Utility.SELECTED_SCRIP = Utility.getScripModel(option.omtkn, option.exseg)
        search.selectData.emit(option)
      } else if (search.currentPageName === 'visualtrading') {
        search.router.navigate(['visualtrading'], { queryParams: { 'omtkn': option.omtkn, 'exseg': option.exseg } });
      }
      else {
        const scripModel = Utility.getScripModel(option.omtkn, option.exseg);
        scripModel.SymbolDescription = option.symdes;
        scripModel.TradingSymbol = option.tsym;
        scripModel.ExpiryDate = option.expry;
        Preferences.setItem('decimalPrecision', scripModel.DecimalPrecision);
        if (search.isfromalert || search.isfromOC) {
          let tsym = option.exseg === 'nse_fo' ? option.usym+' '+option.symdes : option.tsym;
          Preferences.setItem('tsym', tsym);
          Preferences.setItem('exg', Utility.segmentToExchange(option.exseg));
          Preferences.setItem('token', option.omtkn);
          if (search.isMobile) {
            search.location.back();
          } else {
            search.close();
          }
        } else {
          if (!search.isMobile && (!search.isfromWL && !search.isfromMWL)) {
            Utility.dialogRef.close();
          }
          if (!search.isfromWL && !search.isfromMWL) {
            Utility.openStockDetail(search.router, option.omtkn, option.exseg, true);
          }
        }
      }
    }
  }

  onClearSearch() {
    search.inputValue = '';
    search.keyword = '';
    search.searchResult = of([]);
    search.scripSearch = [];
    search.filteredOptions = [];
    search.recentSearchValues = Preferences.getItem('recentlyViewScrips', ReturnType.Array);
  }

  highlight(row: any, i: number){
    Mouse.IS_MOUSE = false;
    if(this.selectedRowIndex !== i) {
      let element:HTMLElement;
        element = document.getElementById("search_list"+i) as HTMLElement;
        element.focus();
    }
    this.selectedRowIndex = i;
    Utility.SELECTED_SCRIP = row;
    Utility.selectedTab = TableUtility.searchBook;
  }

  @HostListener('window:keydown.arrowup', ['$event']) 
  arrowUpEvent(event: KeyboardEvent){
    event.preventDefault();
    if(((this.selectedRowIndex - 1) >= 0))
    {
        var nextrow = this.scripSearch[this.selectedRowIndex - 1];
        this.highlight(nextrow, this.selectedRowIndex - 1);
    }
    else
    {
      this.highlight(this.scripSearch[this.scripSearch.length - 1], this.scripSearch.length - 1);
    }
  }

  @HostListener('window:keydown.arrowdown', ['$event'])
  arrowDownEvent(event: KeyboardEvent){
    event.preventDefault();
    if(((this.selectedRowIndex + 1) < search.scripSearch.length))
    {
      var nextrow = search.scripSearch[this.selectedRowIndex + 1];
      this.highlight(nextrow, this.selectedRowIndex + 1);
    }
    else
    {
      this.highlight(search.scripSearch[0], 0);
    } 
  }

  addStock(data) {
    if (search.wltokens.includes(data.omtkn + '_' + data.exseg)) {
      let mWString = '';
      if (environment.isHypersync)
        mWString = data.exseg + '|' + data.omtkn ;
      else
        mWString = Utility.segmentToExchange(data.exseg) + '|' + data.omtkn + '#';
      if (search.wltokens.length === 1) {
        search.snackBar.open(search.translate.instant('all.min_scrip_msg'), '', { duration: 2000 });
      } else {
        search.isAddRemoveDisabled = true;
        search.isLoader = true;
        search.tradingRestService.getWatchListService().deleteScripToWL(mWString, search.wlname, (list, code, msg) => {
          search.isAddRemoveDisabled = false;
          search.isLoader = false;
          if (code === 200) {
            Preferences.removeItem('stocks');
            const index = search.wltokens.indexOf(data.omtkn + '_' + data.exseg);
            search.wltokens.splice(index, 1);
            Preferences.setItem('stocks', search.wltokens);
          }
        });
      }
    } else{
      if (environment.DefaultFeature ? search.wltokens.length < 50 : true)
      {
        let mWString = '';
        mWString = Utility.segmentToExchange(data.exseg) + '|' + data.omtkn;
        search.isAddRemoveDisabled = true;
        search.isLoader = true;
        search.tradingRestService.getWatchListService().addScripToWL(mWString, search.wlname, (list, code, msg) => {
          search.isAddRemoveDisabled = false;
          search.isLoader = false;
          if (code === 200) {
            Preferences.removeItem('stocks');
            search.wltokens.push(data.omtkn + '_' + data.exseg);
            Preferences.setItem('stocks', search.wltokens);
            Preferences.setItem('defaultMW', search.wlname);
          }
        });
      }
      else
        search.snackBar.open(search.translate.instant('all.max_scrips'), search.translate.instant('all.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
  }

  clear() {
    search.search = '';
    search.searchResult = of([]);
    search.scripSearch = [];
  }

  selectTab(item: string) {
    search.selectedOption = item;
    search.isfreetrial = false;
    if (search.selectedOption === 'News') {
      search.isfromnews = true;
      search.isfreetrial = true;
      if (search.inputValue.length >= 3 && search.inputValue !== ' '){
        search.PS.getSearchService().getTagsForText(search.tagssearch_obj, search.getTagData);
      }
    } else {
      search.isfromnews = false;
      search.searchType = search.map[search.selectedOption];
      search.onSearchChange(search.keyword);
    }
  }

  onSearchChange(searchValue: string) {
    let isSpecialCharcter = false;
    if(this.isMobile){
      const regex = /^[a-zA-Z0-9 & \b]+$/;
      const regex_emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
      if((this.searchElement.nativeElement.value.match(regex) === null || this.searchElement.nativeElement.value.match(regex_emoji) !== null) && searchValue !== ''){
        isSpecialCharcter = true;
        searchValue = this.previous; 
        this.searchElement.nativeElement.value = this.previous;
      }
      else{
        isSpecialCharcter = false;
      }
      this.previous = searchValue;
    }
    else{
      isSpecialCharcter = false;
    }
    search.isLoadMore = false;
    search.keyword = searchValue;
    if (searchValue.length >= 2 && searchValue !== ' ') {
      search.tagssearch_obj['text'] = searchValue;
      if (search.isfromnews) {
        if(!isSpecialCharcter)
          search.isLoader = true;
        search.PS.getSearchService().getTagsForText(search.tagssearch_obj, search.getTagData);
      } else {
        const obj = {};
        obj['keyword'] = (search.isfromSpOrder) ? search.keyword + ' spread' : search.keyword;
        obj['search_type'] = search.searchType;
        obj['allowed_exchange'] = search.EXCHANGE_CONSTANT[search.searchType];
        if(!isSpecialCharcter)
          search.isLoader = true;
        search.PS.getSearchService().fetchData(++search.counter, obj, search.setData);
      }
    } else {
      search.searchResult = of([]);
      search.scripSearch = [];
    }
  }

  getTagData(code, tags, message) {
    search.isLoader = false;
    if (code === 200 && tags.length > 0) {
      search.filteredOptions = tags;
    }
  }

  onSelect(data) {
    const search_obj = { 'id': data.id, 'text': '', 'tag': data.tag };
    Preferences.setItem('tag_item', search_obj);
    if (search.formTopSearch) {
      search.selectData.emit(data);
    }else {
      if (search.fromNews) {
        Utility.newsSearchReq.next(search_obj);
      } else {
        search.router.navigate(['/news'], { queryParams: { 'isfromspecificnews': true }, replaceUrl: true  });
        if (!search.isMobile) {
          search.close();
        }
      }
    }
  }

  onSugggestionClear() {
    search.recentSearchValues = [];
    Preferences.removeItem('recentlyViewScrips');
  }

  back() {
    search.location.back();
  }

  setData(obj, msg, counter) {
    search.isLoader = false;
    if (search.counter === counter) {
      if (search.isfromOC) {
        obj = obj.filter(element => {
          return (element.optyp === 'CE' || element.optyp === 'PE')
        })
      }
      search.searchResult = of(obj.slice(0, 50));
      search.scripSearch = obj.slice(0, 50);
      search.selectedRowIndex = 0;
      setTimeout(() => {
        // if(search.scripSearch && search.scripSearch.length > 0)
        // {
        //   let element = document.getElementById("search_list0") as HTMLElement;
        // }
        search.isLoadMore = true;
      }, 1000);
    }
  }

  displayFn(data?: any[]): string | undefined {
    return data ? data['tsym'] : undefined;
  }

  onDataClick(event: any) {
    search.search = '';
    search.searchResult = of([]);
    search.scripSearch = [];
    search.callback(event.option.value);
  }

  addRecentSearchData(data) {
    const seltctedStock = data.exseg + '|' + data.omtkn;
    this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
    if(!this.isMobile){
      Utility.addRecentlyViewScrips(search.keyword, data.tsym);
      search.recentSearchValues = Preferences.getItem('recentlyViewScrips', ReturnType.Array);
    }
  }

  validation(ev) {
    var k;  
    k = ev.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57) || k == 38 || k == 45);
  }
  openTrigger(data) {
    Utility.openCreateTrigger(this.router, data.omtkn, data.exseg, this.dialog);
  }
}
