import { Component, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import {Subject} from 'rxjs';

let dm: DerivativeMarketComponent;
@Component({
    selector: 'app-derivative',
    templateUrl: './derivativemarket.component.html',
    styleUrls: ['./derivativemarket.component.scss']
})
export class DerivativeMarketComponent {
    @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
    isLoading: boolean;
    isNoData: boolean;
    goback = '1';
    isSearch = false;
    tabIndex;
    defaultFeature = environment.DefaultFeature;
    betaFeature = environment.BetaFeature;
    advanceFeature = environment.AdvanceFeature;
    reloadDerivativeNews: Subject<any> = new Subject();
    constructor(private cdr: ChangeDetectorRef) {
        dm = this;
        if (!Utility.IS_MOBILE) {
            dm.goback = '2';
            dm.isSearch = true;
        }
        dm.tabIndex = dm.defaultFeature ? 4 : 1;
    }
    onTabChanged(i){
        dm.tabIndex = i;
    }

    reloadData()
    {
        this.reloadDerivativeNews.next();
    }
    
    loaderEvent($event) {
        dm.isLoading = $event;
        dm.cdr.detectChanges();
    }

    onTitleClick() {
        this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
}
