import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PositionModel } from 'src/app/model/position.model';
import { Utility } from 'src/app/services/utility';
@Component({
  selector: 'app-positiondetail',
  templateUrl: './positiondetail.component.html',
  styleUrls: ['./positiondetail.component.scss']
})
export class PositiondetailComponent implements OnInit, OnChanges {
  positionDetailData: PositionModel;
  isMobile = Utility.IS_MOBILE;
  @Input() count;
  @Input() isHeader = true;
  constructor() {
    this.positionDetailData = Utility.positionDetail;
  }

  ngOnInit(): void {
  }
  
  ngOnChanges() {
    this.positionDetailData = Utility.positionDetail;
  }

  getAbsoluteValue(number: any)
    {
        return Math.abs(number);
    }

}
