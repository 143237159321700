import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Utility } from '../utility';
import { Url } from '../url';
import { Keys } from '../keys';
import { IQProfileService } from './iprofile.service';

@Injectable()
export class QProfileService implements IQProfileService {
    apiService: ApiService;
    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }
    getByPhorem(mob: any, callback: Function) {
        let code = 200;
        let message = '';
        const jsonSendObj = {
            'custPhone': mob,
        };
        this.apiService.qprofilepost(Url.GET_BY_PHOREM, jsonSendObj).subscribe(res => {
            callback(res, code, message);
        }, error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
        });
    }

    fetchIBForUser(custID, callback: Function) {
        let code;
        let message = '';
        this.apiService.qprofileget(Url.FETCH_IB_FOR_USER+custID).subscribe(res => {
            callback(res, code, message);
        }, error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                Utility.print(error);
                callback('', code, message);
            });
    }
}