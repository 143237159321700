<mat-progress-bar *ngIf="isLoading" style="position: fixed;left: 0; top:0;right:0;z-index:1100" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-title="" class="mat-dialog-title" id="mat-dialog-title-2">
    <div class="row">
        <div [class.col-10] = "!utility.fromTodaySip" [class.col-6] = "utility.fromTodaySip"><h1>{{title}}</h1></div>
        <div class="text-right" [class.col-2] = "!utility.fromTodaySip" [class.col-6] = "utility.fromTodaySip">
            <span class="ts-3" *ngIf = "utility.fromTodaySip">{{'limits.avl_margin' | translate}}</span> <span *ngIf = "utility.fromTodaySip" class="primetext ts-3 pl-1">{{utility.avl_margin | pricepipe}}</span>
            <button mat-icon-button (click) = "close()"><i class="icon-close"></i></button>
        </div>
    </div>
</div>


<!-- 
<div class="mypopup">
        <div class="heading">
            <div class="row">
                <div class="col-10"><h3>{{title}}</h3></div>
                <div class="col-2 text-right">
                    <button class="nobg" mat-icon-button (click) = "close()"><i class="icon-close"></i></button>
                </div>
            </div>
        </div>
    </div>-->