import { Component, Output, EventEmitter } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Router } from '@angular/router';
import { Utility } from 'src/app/services/utility';
import { MatDialog } from '@angular/material/dialog';
import { AddAlertComponent } from './addalert/addalert.component';
import { Preferences } from 'src/app/services/Preferences';

let alertComponent: AlertComponent;
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  isLoading: boolean;
  @Output() load = new EventEmitter<boolean>();
  alertList = [];
  isnodata = false;
  isMobile = true;
  constructor(private tradingRestService: TradingRestService, private router: Router, public dialog: MatDialog) {
    alertComponent = this;
    if (Utility.IS_MOBILE)
      Preferences.removeItem('currentpage');
    alertComponent.isMobile = Utility.IS_MOBILE;
    alertComponent.isLoading = true;
    alertComponent.load.emit(alertComponent.isLoading);
    alertComponent.tradingRestService.getAlertService().GetAlertHistory(alertComponent.GetAlertHistoryRes);
  }
  GetAlertHistoryRes(data, code, msg) {
    if (data.length === 0) {
      alertComponent.isnodata = true;
    } else {
      alertComponent.isnodata = false;
      alertComponent.alertList = data;
    }
    alertComponent.isLoading = false;
    alertComponent.load.emit(alertComponent.isLoading);
  }
  addAlert() {
    Preferences.removeItem('tsym');
    Preferences.removeItem('exg');
    Preferences.removeItem('token');
    if (alertComponent.isMobile) {
      alertComponent.router.navigate(['/addalert']);
    } else {
      Utility.dialogRef.close();
      Utility.openPage(alertComponent.router, 'alert', 'addAlert', AddAlertComponent, alertComponent.dialog, 'popup', {}, '480px');
    }
  }
  result(data) {
    Utility.setNavigationData(data);
    if (alertComponent.isMobile) {
      alertComponent.router.navigate(['/addalert'], {queryParams: {isEdit: true}});
    } else {
      Utility.dialogRef.close();
      const data_obj = {isEdit: true};
      Utility.openPage(alertComponent.router, 'alert', 'addAlert', AddAlertComponent, alertComponent.dialog, 'popup', data_obj, '480px');
    }

  }
}
