import { Component, OnInit, Input, Injector, ChangeDetectorRef, Output, EventEmitter, ElementRef, ViewChild, HostListener } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Utility } from 'src/app/services/utility';
import { CustomBasket } from '../thematicbasket/customthematicbasket/custombasket/custombasket.component';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from '../../../services/tableutility';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { EventFilterOptionComponent, EventFilterOptionBottomSheetComponent, EventFilterOptionPopupComponent } from './eventFilterOption/eventFilterOption.component';
import { Subject } from 'rxjs';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
declare const moment: any;
let event : EventComponent;
@Component({
  selector: 'app-event',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventComponent extends BaseComponentDirective implements OnInit {
  @ViewChild('sectionNeedToScroll', { static: false }) sectionNeedToScroll: ElementRef;
  @Input() IsStockSpecific: boolean;
  @Input() ScripToken: string;
  @Input() ExchangeSegment: string;
  @Input() isCustomDashboard = false;
  @ViewChild('header') header;
  targetElement: Element;
  isMobile;
  isLoading: boolean;
  isDHeight = true;
  DHeight = {};
  isNoData = false;
  start = 0;
  limit = 50;
  exchange = { 'nse_cm': 'NSE', 'bse_cm': 'BSE' };
  corporateActionData = [];
  isSearch = false;
  goback = '1';
  displayedColumns = ['CompanyName', 'Purpose', 'Record Date', 'Ex Date'];
  utility: any;
  subscription: Subscription;
  Height: any;
  modesub: Subscription;
  container;
  cellView: boolean;
  isFirstNoData: boolean;
  filterObj: any;
  isFilterApplied = false;
  @Output() load = new EventEmitter<boolean>();
  @Output() loaderEvent = new EventEmitter<boolean>();
  isReload = false;
  constructor(elRef: ElementRef, private PS: PtPlusRestService, private injector: Injector, private detector: ChangeDetectorRef, private _bottomSheet: MatBottomSheet, public dialog: MatDialog) {
    super(elRef.nativeElement);
    event = this;
    if (!Utility.IS_MOBILE) {
      // this.container = <ComponentContainer>this.injector.get('Container');
    }
    this.utility = Utility;
    if (!Utility.IS_MOBILE) {
      this.goback = '2';
    }
    this.subscription = CustomBasket.BASKET_CORPORATE_EVENT.subscribe(data => {
      this.setStockSpecificEvent(data);
    });
    this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      this.changeMode();
  });
  this.filterObj = {
    'datetype': 'record_date',
    'startdate': moment(new Date()).format('Y-MM-DD'),
    'enddate': moment(new Date(new Date().getFullYear(), new Date().getMonth() + 3, new Date().getDate())).format('Y-MM-DD'),
    'type': 'ALL',
    'exchange': ['nse_cm', 'bse_cm']
};
  }

  changeMode(){
    if (this.container.width !== null){
      this.cellView =  (this.container.width < Utility.MinWidth)? true : false;
      if(!this.cellView) {
        this.Height = {
          'height': (this.container.height - TableUtility.EVHeight) + 'px',
          'overflow': 'auto'
        };
      } else {
        this.Height = {
          'height': this.container.height + 'px',
          'overflow': 'auto'
        };
      }

      this.detector.detectChanges();
    }
  }
  setStockSpecificEvent(data) {
    this.load.emit(true);
    this.PS.getEventService().getMultipleSearchEvents(data.tokenarr, data.exsegarr, this.getCorporateActiondata.bind(this), this.setPreEventsData.bind(this));
  }
  ngOnInit() {
    if(this.inputs !== undefined){
      this.IsStockSpecific = this.inputs.IsStockSpecific === undefined? false : true;
      this.ExchangeSegment = this.inputs.exch;
      this.isCustomDashboard = this.inputs.isCustomDashboard;
      this.ScripToken = this.inputs.token;
    }
    this.isMobile = Utility.IS_MOBILE;
    this.targetElement = document.querySelector('html');
    if (this.isCustomDashboard) {
      /* this.changeMode();
       this.container.on('resize', _ => {
        this.changeMode();
      });
      this.container.on('show', _ => {
        setTimeout(() => {
          this.changeMode();
        }, 50);
      });*/
    }
    this.getData(this.IsStockSpecific);

    Utility.webReloadComponent.subscribe(data =>
    {
      if(event.isReload === false && data === 'Events')
      {
        event.isReload = true;
        this.reloadData();
      }
    });
  }

  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
      this.reloadData();
      event.next();
    }, 1000);
  }
  
  alert(message: string) {
    
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
      if (Utility.IS_MOBILE_LAND) {
          if(window.scrollY > this.header?.nativeElement.scrollHeight && this.isDHeight){
          this.DHeight = {
              'height': 'calc(100vh - 48px)' ,
              'overflow': 'auto',
              'scroll-behavior': 'smooth',
          };
          this.isDHeight = false;
          }
          else if(window.scrollY < this.header?.nativeElement.scrollHeight){
          this.DHeight = {
              'height': '100%',
              'overflow': 'auto',
              'scroll-behavior': 'smooth'
          };
          this.isDHeight = true;
          }
      }
  }

  reloadData()
  {
    this.getData(this.IsStockSpecific);    
  }

  getData(isStockSpecific) {
    this.isLoading = true;
    this.load.emit(true);
    this.loaderEvent.emit(true);
    this.PS.getEventService().getAllEvents(isStockSpecific, this.start, this.limit, this.ScripToken,
      this.ExchangeSegment, this.getCorporateActiondata.bind(this), this.setPreEventsData.bind(this));
  }

  getCorporateActiondata(code, result, message) {
    this.load.emit(false);
    this.loaderEvent.emit(false);
    if (code === 200 && result.length > 0) {
      this.isFirstNoData = false;
      this.corporateActionData = result;
      if(this.isFilterApplied)
      {
        this.filterObj = {
          'datetype': 'record_date',
          'startdate': moment(new Date()).format('Y-MM-DD'),
          'enddate': moment(new Date(new Date().getFullYear(), new Date().getMonth() + 3, new Date().getDate())).format('Y-MM-DD'),
          'type': 'ALL',
          'exchange': ['nse_cm', 'bse_cm']
        };
      }
    } else {
      if (this.corporateActionData.length === 0)
        this.isFirstNoData = true;
    }
    this.isLoading = false;
    this.isReload = false;
  }
  setPreEventsData(data) {
    this.corporateActionData = data;
  }
  onEventFilter() {
    if (Utility.IS_MOBILE) {
      this.isLoading = true;
      const bottomSheetRef = this._bottomSheet.open(EventFilterOptionBottomSheetComponent, {
        data: this.filterObj, restoreFocus: false
      });
      bottomSheetRef.afterDismissed().subscribe((result) => {
        if (result !== undefined) {
          this.filterObj = result.filter;
          if (result.code === 200 && result.data.length > 0) {
            this.isFilterApplied = true;
            this.corporateActionData = [];
            this.corporateActionData.push(...result.data);
            this.isNoData = false;
          } else {
            this.corporateActionData = [];
            this.isNoData = true;
          }
        }
      });
    }
    else {
      Utility.dialogData = this.filterObj;
      Utility.dialogRef = this.dialog.open(EventFilterOptionPopupComponent, {
        restoreFocus: false
      });
      Utility.dialogRef.afterClosed().subscribe(function(result) {
        if (result !== undefined) {
          this.filterObj = result.filter;
          if (result !== undefined && result.code === 200 && result.data.length > 0) {
            this.corporateActionData = [];
            this.corporateActionData.push(...result.data);
            this.isNoData = false;
            this.isFilterApplied = true;
          } else {
            this.corporateActionData = [];
            this.isNoData = true;
          }
        }
      }.bind(this));
    }
    this.isLoading = false;
  }

  onTitleClick() {
    this.sectionNeedToScroll?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    this.cellView =  (width < Utility.MinWidth) ? true : false;
    this.changeMode();
  }
}
