import { AfterViewInit, Component,  ChangeDetectorRef, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GoldenLayoutHostComponent } from 'src/app/goldenlayout/golden-layout-host.component';
import { Preferences } from 'src/app/services/Preferences';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { ChannelType, Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import { IndexSpotComponent } from '../../indexspot/indexspot.component';
import { GoldencontrolsComponent } from '../goldencontrols/goldencontrols.component';

let customDashboardComponent: CustomDashboardComponent;
@Component({
  selector: 'app-customdashboard',
  templateUrl: '../../../'+environment.baseviewpath+'/home/customdashboard/customdashboard.component.html', 
  styleUrls: ['./customdashboard.component.scss']
})
export class CustomDashboardComponent implements AfterViewInit, OnDestroy{
  private _controlsElement: HTMLElement;
  private _windowResizeListener = () => customDashboardComponent.handleWindowResizeEvent();

  @ViewChild('controls') private _controlsComponent: GoldencontrolsComponent; 
  @ViewChild('goldenLayoutHost0') private _goldenLayoutHostComponent0: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost1') private _goldenLayoutHostComponent1: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost2') private _goldenLayoutHostComponent2: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost3') private _goldenLayoutHostComponent3: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost4') private _goldenLayoutHostComponent4: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost5') private _goldenLayoutHostComponent5: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost6') private _goldenLayoutHostComponent6: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost7') private _goldenLayoutHostComponent7: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost8') private _goldenLayoutHostComponent8: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost9') private _goldenLayoutHostComponent9: GoldenLayoutHostComponent; 
  @ViewChild('goldenLayoutHost10') private _goldenLayoutHostComponent10: GoldenLayoutHostComponent; 
  @ViewChild('middle') private _middle: ElementRef; 
  stockInput: any;
  chartInputs: any;
  isLoader: boolean;
  indexList = [];
  nameArr = [];
  utility;
  constructor(private tradingRestService: TradingRestService, private cdr: ChangeDetectorRef, private router: Router, private dialog: MatDialog){
    customDashboardComponent = this;
    customDashboardComponent.utility = Utility;
    customDashboardComponent.indexcall();
    Utility.indexCall.subscribe(() => {
      customDashboardComponent.indexcall();
    });
  }

  ngAfterViewInit() {
    customDashboardComponent._controlsElement = customDashboardComponent._controlsComponent.element;
    customDashboardComponent._controlsComponent.setGoldenLayoutHostComponent(customDashboardComponent._goldenLayoutHostComponent0,
      customDashboardComponent._goldenLayoutHostComponent1,
      customDashboardComponent._goldenLayoutHostComponent2,
      customDashboardComponent._goldenLayoutHostComponent3,
      customDashboardComponent._goldenLayoutHostComponent4,
      customDashboardComponent._goldenLayoutHostComponent5,
      customDashboardComponent._goldenLayoutHostComponent6,
      customDashboardComponent._goldenLayoutHostComponent7,
      customDashboardComponent._goldenLayoutHostComponent8,
      customDashboardComponent._goldenLayoutHostComponent9,
      customDashboardComponent._goldenLayoutHostComponent10);

    globalThis.addEventListener('resize', customDashboardComponent._windowResizeListener);
    setTimeout(() => customDashboardComponent.resizeGoldenLayout(true), 0);
  }

  opeSideMenu(){
    customDashboardComponent._controlsComponent.opeSideMenu();
  }
  ngOnDestroy() {
    globalThis.removeEventListener('resize', customDashboardComponent._windowResizeListener);
  }

  private handleWindowResizeEvent() {
    // handling of resize event is required if GoldenLayout does not use body element
    customDashboardComponent.resizeGoldenLayout(false);
  }

  private resizeGoldenLayout(flag) {
    const w = customDashboardComponent._middle.nativeElement.offsetWidth;
    const h= customDashboardComponent._middle.nativeElement.offsetHeight;
    if (Utility.selectedWorkspace === 0 || flag)
      customDashboardComponent._goldenLayoutHostComponent0.setSize(w, h);
    if (environment.baseviewpath !== 'investor') {
      if (Utility.selectedWorkspace === 1 || flag)
        customDashboardComponent._goldenLayoutHostComponent1.setSize(w, h);
      if (Utility.selectedWorkspace === 2 || flag)
        customDashboardComponent._goldenLayoutHostComponent2.setSize(w, h);
      if (Utility.selectedWorkspace === 3 || flag)
        customDashboardComponent._goldenLayoutHostComponent3.setSize(w, h);
      if (Utility.selectedWorkspace === 4 || flag)
        customDashboardComponent._goldenLayoutHostComponent4.setSize(w, h);
      if (Utility.selectedWorkspace === 5 || flag)
        customDashboardComponent._goldenLayoutHostComponent5.setSize(w, h);
      if (Utility.selectedWorkspace === 6 || flag)
        customDashboardComponent._goldenLayoutHostComponent6.setSize(w, h);
      if (Utility.selectedWorkspace === 7 || flag)
        customDashboardComponent._goldenLayoutHostComponent7.setSize(w, h);
      if (Utility.selectedWorkspace === 8 || flag)
        customDashboardComponent._goldenLayoutHostComponent8.setSize(w, h);
      if (Utility.selectedWorkspace === 9 || flag)
        customDashboardComponent._goldenLayoutHostComponent9.setSize(w, h);
      if (Utility.selectedWorkspace === 10 || flag)
        customDashboardComponent._goldenLayoutHostComponent10.setSize(w, h);
    }
  }
  setStockInputs(event){
    customDashboardComponent.stockInput = event;
  }

  setChartInputs(event)
  {
    customDashboardComponent.chartInputs = event;
  } 
  onLoading(isLoader) {
    customDashboardComponent.isLoader = isLoader;
    customDashboardComponent.cdr.detectChanges();
  }
  addstockInputs(event)
  {
    var obj = {"inputs":event, "name":"addStock"}
    Utility.InputsDataGet.next(obj);  
  }

  manageWLInputs(event)
  {
    var obj = {"inputs":event, "name":"manageWList"}
    Utility.InputsDataGet.next(obj);  
  }
  
  optionCalInputs(event)
  {
    var obj = {"inputs":event, "name":"optionCal"}
    Utility.InputsDataGet.next(obj);  
  }

  addFundsInputs(event)
  {
    var obj = {"inputs":event, "name":"addFunds"}
    Utility.InputsDataGet.next(obj);  
  }

  addOpenManageBooksInputs(event){
    var obj = {"inputs":event, "name":"managecolumn"}
    Utility.InputsDataGet.next(obj); 
  }

  newsInputs(event)
  {
    var obj = {"inputs":event, "name":"news"}
    Utility.InputsDataGet.next(obj);  
  }
  


  indexcall(){
    this.nameArr = [];
    this.indexList = [];
    let spotValue;
    let userid = Preferences.getPreference('userid');
    const allowedExchanges = Utility.getTradingExchanges();
    if(allowedExchanges.includes('BSE') && allowedExchanges.includes('NSE')){
      this.nameArr.push({'s':'Nifty 50','e':'NSE'});
      this.nameArr.push({'s':'Nifty Bank','e':'NSE'});
      this.nameArr.push({'s':'Nifty Fin Service','e':'NSE'});
      this.nameArr.push({'s':'SENSEX','e':'BSE'});
    } else if (allowedExchanges.includes('BSE') && !allowedExchanges.includes('NSE')){
      this.nameArr.push({'s':'SENSEX','e':'BSE'});
      this.nameArr.push({'s':'BSE IT','e':'BSE'});
    } else if (!allowedExchanges.includes('BSE') && allowedExchanges.includes('NSE')){
      this.nameArr.push({'s':'Nifty 50','e':'NSE'});
      this.nameArr.push({'s':'Nifty Bank','e':'NSE'});
      this.nameArr.push({'s':'Nifty Fin Service','e':'NSE'});
    }
    if(Preferences.getPreference(userid+'_spot1') !== null) {
      spotValue=Preferences.getPreference(userid+'_spot1').split('|');
      this.nameArr[0] = {'s':spotValue[0],'e':spotValue[1]};
    }
    if(Preferences.getPreference(userid+'_spot2') !== null) {
      spotValue=Preferences.getPreference(userid+'_spot2').split('|');
      this.nameArr[1] = {'s':spotValue[0],'e':spotValue[1]};
    }
    if(Preferences.getPreference(userid+'_spot3') !== null) {
      spotValue=Preferences.getPreference(userid+'_spot3').split('|');
      if(this.nameArr.length > 2){
        this.nameArr[2] = {'s':spotValue[0],'e':spotValue[1]};
      } else {
        this.nameArr.push({'s':spotValue[0],'e':spotValue[1]});
      }
    }
    if(Preferences.getPreference(userid+'_spot4') !== null) {
      spotValue=Preferences.getPreference(userid+'_spot4').split('|');
      if(this.nameArr.length > 3){
        this.nameArr[3] = {'s':spotValue[0],'e':spotValue[1]};
      } else {
        this.nameArr.push({'s':spotValue[0],'e':spotValue[1]});
      }
    }
    for (let i = 0; i < this.nameArr.length; i++) {
      const indexdata = Utility.getIndexModel(this.nameArr[i]['s'], this.nameArr[i]['e'], this.tradingRestService, true, ChannelType.Dashboard);
      this.indexList.push(indexdata);
    }
  }

  openIndexSpot(i) {
    const obj = {'index' : i};
    let userid = Preferences.getPreference('userid');
    Preferences.setPreference(userid+'_spot'+(i+1), this.indexList[i].Name +'|'+ this.indexList[i].Exchange);
    Utility.openPage(this.router, 'customdashboard', 'indexspot', IndexSpotComponent, this.dialog, 'popup', obj, '400px', '450px');  
  }
}
