import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlaceorderComponent } from './view/protradelite/placeorder/placeorder.component';
import { StockdetailComponent } from './view/protradelite/stockdetail/stockdetail.component';
import { SearchComponent } from './view/protradelite/home/search/search.component';
import { ManageWLComponent } from './view/protradelite/home/watchlist/managewatchlist/managewatchlist.component';
import { AlertComponent } from './view/protradelite/alert/alert.component';
import { AddAlertComponent } from './view/protradelite/alert/addalert/addalert.component';
import { LimitComponent } from './view/protradelite/limit/limit.component';
import { LogsComponent } from './view/protradelite/logs/logs.component';
import { LotWeightComponent } from './view/protradelite/lotweight/lotweight.component';
import { ProfileComponent } from './view/protradelite/profile/profile.component';
import { ChangePwdComponent } from './view/protradelite/changepassword/changepassword.component';
import { ChangeQuestionComponent } from './view/protradelite/changequestion/changequestion.component';
import { MarketStatusComponent } from './view/protradelite/marketstatus/marketstatus.component';
import { ExchangeMessageComponent } from './view/protradelite/exchangemessage/exchangemessage.component';
import { IndexComponent } from './view/protradelite/index/index.component';
import { NewsComponent } from './view/protradelite/news/news.component';
import { BasketComponent } from './view/protradelite/thematicbasket/basket.component';
import { BasketDetailComponent } from './view/protradelite/thematicbasket/basketdetail/basketdetail.component';
import { EventComponent } from './view/protradelite/events/events.component';
import { BasketPlaceOrderComponent } from './view/protradelite/thematicbasket/basketplaceorder/basketplaceorder.component';
import { BasketOrdersummaryComponent } from './view/protradelite/thematicbasket/basketordersummary/basketordersummary.component';
import { BasketOrderBookComponent } from './view/protradelite/thematicbasket/basketorderbook/basketorderbook.component';
import { BasketPlaceOrderSummaryComponent } from './view/protradelite/thematicbasket/basketplaceordersummary/basketplaceordersummary.component';
import { BasketHomeComponent } from './view/protradelite/thematicbasket/baskethome/baskethome.component'
import { UserBasketHomeComponent } from './view/protradelite/thematicbasket/userbaskethome/userbaskethome.component'
import { BasketConstituentsComponent } from './view/protradelite/thematicbasket/basketconstituents/basketconstituents.component';
import { VisualTradeComponent } from './view/protradelite/visualtrading/visualtrading.component';
import { BasketCompareComponent } from './view/protradelite/thematicbasket/basketcompare/basketcompare.component';
import { TradingViewComponent } from './view/protradelite/tradingview/tradingview.component';
import { ScannerComponent } from './view/protradelite/scanner/scannercomponent/scanner.component';
import { ScannerDetailComponent } from './view/protradelite/scanner/scannerdetail/scannerdetail.component';
import { CanDeactivateGuard } from './view/protradelite/header/candeactivateguard';
import { CanActivateGuard } from './view/protradelite/header/canactivateguard';
import { CanLoginGuard } from './view/protradelite/header/canloginguard';
import { EquityMarketComponent } from './view/protradelite/equitymarket/equitymarket.component';
import { OptionCalculatorComponent } from './view/protradelite/optioncalculator/optioncalculator.component';
import { CustomDashboardComponent } from './view/protradelite/home/customdashboard/customdashboard.component';
import { IndicesComponent } from './view/protradelite/equitymarket/indices/indices.component';
import { DerivativeMarketComponent } from './view/protradelite/derivativemarket/derivativemarket.component';
import { TagSpecificNewsComponent } from './view/protradelite/news/tagspecificnews/tagspecificnews.component';
import { PutCallRatioComponent } from './view/protradelite/derivativemarket/putcallratio/putcallratio.component';
import { WebScannerComponent } from './view/protradelite/webscanner/webscanner.component';
import { GeneralSettingsComponent } from './view/protradelite/generalsettings/generalsettings.component';
import { MultiViewComponent } from './view/protradelite/multiviewcomponent/multiviewcomponent.component';
import { WatchlistSettingsComponent } from './view/protradelite/home/watchlist/watchlistsettings/watchlistsettings.component';
import { AddfundsComponent } from './view/protradelite/addfunds/addfunds.component';
import { WithdrawfundsComponent } from './view/protradelite/withdrawfunds/withdrawfunds.component';
import { TransactiondetailsComponent } from './view/protradelite/transactiondetails/transactiondetails.component';
import { TopMarketComponent } from './view/protradelite/equitymarket/topmarket/topmarket.component';
import { OptionComponent } from './view/protradelite/derivativemarket/optionchain/optionchain.component';
import { OptionChainPlaceOrderComponent } from './view/protradelite/derivativemarket/optionchain/optionchainplaceorder/optionchainplaceorder.component';
import { OrderHistoryComponent } from './view/protradelite/orderhistory/orderhistory.component';
import { AboutusComponent } from './view/protradelite/pages/aboutus/aboutus.component';
import { TermsComponent } from './view/protradelite/pages/terms/terms.component';
import { PrivacyComponent } from './view/protradelite/pages/privacy/privacy.component';
import { ContactComponent } from './view/protradelite/pages/contact/contact.component';
import { FeedbackComponent } from './view/protradelite/feedback/feedback.component';
import { SpreadOrderComponent } from './view/protradelite/spreadorder/spreadorder.component';
import { SpreadPlaceorderComponent } from './view/protradelite/spreadorder/spreadplaceorder/spreadplaceorder.component';
import { SpreadOrderdetailComponent} from './view/protradelite/spreadorder/spreadorderdetail/spreadorderdetail.component';
import { SpreadOrderHistoryComponent} from './view/protradelite/spreadorder/spreadorderhistory/spreadorderhistory.component';
import { SpreadOrderMoreOptionComponent} from './view/protradelite/spreadorder/spreadordermoreoption/spreadordermoreoption.component';
import { ManagebooksComponent } from './view/protradelite/books/managebooks/managebooks.component';
import { ChangeMpinComponent } from './view/protradelite/changempin/changempin.component';
import { PartialPositionConvertionComponent } from './view/protradelite/partialpositionconvertion/partialpositionconvertion.component';
import { PositionConvertionComponent } from './view/protradelite/positionconvertion/positionconvertion.component';
import { OptionChainAnalysisComponent } from './view/protradelite/derivativemarket/optionchain/optionchainanalysis/optionchainanalysis.component';
import { SelllistComponent } from './view/protradelite/selllist/selllist.component';
import { OrderBookComponent } from './view/protradelite/orderbook/orderbook.component';
import { TradebookComponent } from './view/protradelite/tradebook/tradebook.component';
import { RetryBasketOrdersComponent } from './view/protradelite/thematicbasket/retrybasketorders/retrybasketorders.component';
import { UsertodayssipComponent } from './view/protradelite/thematicbasket/usertodayssip/usertodayssip.component';
import { SipupdateComponent } from './view/protradelite/thematicbasket/sipupdate/sipupdate.component';
import { TriggerComponent } from './view/protradelite/trigger/trigger.component';
import { CreateTriggerComponent } from './view/protradelite/createTrigger/createTrigger.component';
import { CreateEmandateComponent } from './view/protradelite/thematicbasket/createemandate/createemandate.component';
import { EmandateComponent } from './view/protradelite/thematicbasket/emandate/emandate.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'aboutus', component: AboutusComponent},
  { path: 'terms', component: TermsComponent},
  { path: 'privacy', component: PrivacyComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'topmarket', component: TopMarketComponent},
  { path: 'multiview', component: MultiViewComponent},
  { path: 'tagspecificnews', component: TagSpecificNewsComponent },
  { path: 'indices', component: IndicesComponent },
  { path: 'equitymarket', component: EquityMarketComponent },
  { path: 'events', component: EventComponent },
  { path: 'news', component: NewsComponent },
  { path: 'index', component: IndexComponent },
  { path: 'alert', component: AlertComponent },
  { path: 'addalert', component: AddAlertComponent },
  { path: 'login', loadChildren: () => import('./view/protradelite/login/login.module').then(m => m.LoginModule), canActivate: [CanLoginGuard] },
  { path: 'home', loadChildren: () => import('./home.module').then(m => m.HomeModule)},
  { path: 'placeorder', component: PlaceorderComponent },
  { path: 'search', component: SearchComponent },
  { path: 'stockdetail', component: StockdetailComponent },
  { path: 'managewatchlist', component: ManageWLComponent },
  { path: 'limit', component: LimitComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'logs', component: LogsComponent },
  { path: 'lotweight', component: LotWeightComponent },
  { path: 'changempin', component: ChangeMpinComponent},
  { path: 'changepwd', component: ChangePwdComponent },
  { path: 'changequestion', component: ChangeQuestionComponent },
  { path: 'marketstatus', component: MarketStatusComponent },
  { path: 'exchangemessage', component: ExchangeMessageComponent },
  { path: 'basket', component: BasketComponent },
  { path: 'basketdetail', component: BasketDetailComponent },
  { path: 'basketplaceorder', component: BasketPlaceOrderComponent },
  { path: 'basketordersummary', component: BasketOrdersummaryComponent },
  { path: 'basketorderbook', component: BasketOrderBookComponent },
  { path: 'basketplaceordersummary', component: BasketPlaceOrderSummaryComponent },
  { path: 'baskethome', component: BasketHomeComponent },
  { path: 'userbaskethome', component: UserBasketHomeComponent },
  { path: 'basketconstituents', component: BasketConstituentsComponent },
  { path: 'visualtrading', component: VisualTradeComponent },
  { path: 'basketcompare', component: BasketCompareComponent },
  { path: 'tradingview', component: TradingViewComponent },
  { path: 'scanners', component: ScannerComponent },
  { path: 'scannerdetail', component: ScannerDetailComponent },
  { path: 'optioncalculator', component: OptionCalculatorComponent },
  { path: 'workbook', component: CustomDashboardComponent, canDeactivate: [CanDeactivateGuard], canActivate: [CanActivateGuard] },
  { path: 'derivativemarket', component: DerivativeMarketComponent },
  { path: 'putcallratio', component: PutCallRatioComponent },
  { path: 'webscanner', component: WebScannerComponent },
  { path: 'generalsettings', component: GeneralSettingsComponent},
  { path: 'watchlistsettings', component: WatchlistSettingsComponent},
  { path: 'managebooks', component: ManagebooksComponent},
  { path: 'addfunds', component: AddfundsComponent},
  { path: 'withdrawfunds', component: WithdrawfundsComponent},
  { path: 'transactiondetails', component: TransactiondetailsComponent},
  { path: 'optionchain', component: OptionComponent},
  { path: 'optionchainplaceorder', component: OptionChainPlaceOrderComponent},
  { path: 'orderhistory', component: OrderHistoryComponent },
  { path: 'feedback', component: FeedbackComponent},
  { path: 'spreadorder', component: SpreadOrderComponent },
  { path: 'spreadplaceorder', component: SpreadPlaceorderComponent },
  { path: 'spreadorderdetail', component: SpreadOrderdetailComponent },
  { path: 'spreadorderhistory', component: SpreadOrderHistoryComponent },
  { path: 'spreadordermoreoption', component: SpreadOrderMoreOptionComponent },
  { path: 'partialpositionconvertion', component: PartialPositionConvertionComponent },
  { path: 'positionconvertion', component: PositionConvertionComponent },
  { path: 'optionchainanalysis', component: OptionChainAnalysisComponent },
  { path: 'selllist', component: SelllistComponent },
  {path: 'orderbook', component: OrderBookComponent},
  {path: 'tradebook', component: TradebookComponent},
  {path: 'retrybasketorders', component: RetryBasketOrdersComponent},
  {path: 'todayssip', component: UsertodayssipComponent},
  {path: 'sipupdate', component: SipupdateComponent},
  {path: 'trigger', component: TriggerComponent},
  {path: 'createtrigger', component: CreateTriggerComponent},
  {path: 'createemandate', component: CreateEmandateComponent},
  {path: 'emandate', component: EmandateComponent}
  
  
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation:'disabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }   
