import { OptionCalcModel, OptionCalcGridModel } from "./OptionCalc.model";

export class CoxRossFormula
{
    public CoxRossFormula()
    {
    }

    public Round6(n: number): number
    {
        var X1 = n * 10000;
        X1 = Math.round(X1);
        return X1 / 10000;
    }

    public NormDist(x: number): number 
    {
        var dv1 = 0.0498673470, dv2 = 0.0211410061, dv3 = 0.0032776263, dv4 = 0.0000380036, dv5 = 0.0000488906, dv6 = 0.0000053830;

        var a = Math.abs(x);
        var t = 1.0 + a * (dv1 + a * (dv2 + a * (dv3 + a * (dv4 + a * (dv5 + a * dv6)))));

        t *= t;
        t *= t;
        t *= t;
        t *= t;
        t = 1.0 / (t + t);
        if (x >= 0)
            t = 1 - t;
        return t;
    }

    public OptionCalc(S: number, X: number, T:number, Rf: number, sigma: number, Q: number): Array<OptionCalcGridModel>
    {
        var calcModel = new OptionCalcModel();
        calcModel.S = S; // Spot price 
        calcModel.X = X; // Strike price
        calcModel.T = T / 365; //Time
        calcModel.Rf = Rf / 100;  // intrest
        calcModel.sigma = sigma / 100;    // Volatility
        calcModel.Q = Q / 100; //div

        calcModel.d1 = (Math.log(calcModel.S / calcModel.X) + (calcModel.Rf - calcModel.Q + (Math.pow(calcModel.sigma, 2) / 2)) * calcModel.T) / (calcModel.sigma * Math.sqrt(calcModel.T));
        calcModel.d2 = calcModel.d1 - (calcModel.sigma * Math.sqrt(calcModel.T));
        var eqt = Math.exp(-(calcModel.Q * calcModel.T));

        calcModel.Call = Math.max((calcModel.S - calcModel.X), 0);
        calcModel.Put = Math.max((calcModel.X - calcModel.S), 0);
        calcModel.delta_C = this.Round6(eqt * this.NormDist(calcModel.d1));
        calcModel.delta_P = this.Round6(calcModel.delta_C - 1);
        calcModel.rho_C = this.Round6(calcModel.X * calcModel.T * Math.exp(-(calcModel.Rf * calcModel.T)) * this.NormDist(calcModel.d2) / 100);
        calcModel.rho_P = this.Round6(-calcModel.X * calcModel.T * Math.exp(-(calcModel.Rf * calcModel.T)) * this.NormDist(-calcModel.d2) / 100);

        var pdf = Math.exp(-(Math.pow(calcModel.d1, 2) / 2)) / Math.sqrt(2 * Math.PI);
        calcModel.gamma = this.Round6((pdf * eqt) / (calcModel.S * calcModel.sigma * Math.sqrt(calcModel.T)));
        calcModel.vega = this.Round6((calcModel.S * eqt * Math.sqrt(calcModel.T) * pdf) / 100);
        calcModel.theta_C = this.Round6((-((calcModel.S * pdf * eqt * calcModel.sigma) / (2 * Math.sqrt(calcModel.T))) - (calcModel.Rf * calcModel.X * Math.exp(-(calcModel.Rf * calcModel.T)) * this.NormDist(calcModel.d2)) + (calcModel.S * calcModel.Q * eqt * this.NormDist(calcModel.d1))) / 365);
        calcModel.theta_P = this.Round6((-((calcModel.S * pdf * eqt * calcModel.sigma) / (2 * Math.sqrt(calcModel.T))) + (calcModel.Rf * calcModel.X * Math.exp(-(calcModel.Rf * calcModel.T)) * this.NormDist(-calcModel.d2)) - (calcModel.S * calcModel.Q * eqt * this.NormDist(-calcModel.d1))) / 365);

        var datalist = new Array<OptionCalcGridModel>();
        var gridModel = new OptionCalcGridModel();
        gridModel.Name = "Theoritical Option Price";
        gridModel.call = calcModel.Call;
        gridModel.put = calcModel.Put;
        gridModel.straddle = calcModel.Call - calcModel.Put;
        datalist.push(gridModel);

        gridModel = new OptionCalcGridModel();
        gridModel.Name = "Delta";
        gridModel.call = calcModel.delta_C;
        gridModel.put = calcModel.delta_P;
        gridModel.straddle = calcModel.delta_C - calcModel.delta_P;
        datalist.push(gridModel);

        gridModel = new OptionCalcGridModel();
        gridModel.Name = "Gamma";
        gridModel.call = calcModel.gamma;
        gridModel.straddle = calcModel.gamma;
        datalist.push(gridModel);

        gridModel = new OptionCalcGridModel();
        gridModel.Name = "Theta";
        gridModel.call = calcModel.theta_C;
        gridModel.put = calcModel.theta_P;
        gridModel.straddle = calcModel.theta_C - calcModel.theta_P;
        datalist.push(gridModel);

        gridModel = new OptionCalcGridModel();
        gridModel.Name = "Vega";
        gridModel.call = calcModel.vega;
        gridModel.straddle = calcModel.vega;
        datalist.push(gridModel);

        gridModel = new OptionCalcGridModel();
        gridModel.Name = "Rho";
        gridModel.call = calcModel.rho_C;
        gridModel.put = calcModel.rho_P;
        gridModel.straddle = calcModel.rho_C - calcModel.rho_P;

        datalist.push(gridModel);

        return datalist;
    }
}
