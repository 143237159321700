<app-header (onTitleClick)="onTitleClick()" *ngIf="isMobile" goback="1" [title]="'dashboard.index' | translate" [isLoading]="isLoading" (reloadPage) = "reloadData()"></app-header>
    <ngx-pull-to-refresh
	spinnerColor="#ff0000"
	distanceForRefresh="80"
	[isEnable]="isMobile"
	[targetElement]="targetElement"
	(refresh)="myRefreshEvent($event, 'refresh')"
	(loadMore)="alert('loadmore')"
	class = "loader">
    <mat-card class="webpanelnew">
        <mat-card-content>
            <div #sectionNeedToScroll [class.pt-0]="!isMobile">
                <div class="row" [class.data-container]="isMobile">
                    <div class="col-12">
                        <mat-chip-list >
                            <mat-chip *ngFor="let data of indexExSegArr" [class.imactive] = "tagText ==  data" (click)="getIndexData(data)">{{data}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
                <div  *ngIf="!isLoading && !isNoData" [ngStyle]="Height">
                <ng-container *ngIf="isMobile || cellview">
                    <div [class.pl-2]="cellview" [class.pr-2]="cellview"  *ngFor="let index of indexList">
                        <div class="row">
                            <div class="col-12">
                                <div class="watchlistwrapper" [ngClass]="{'d-block d-sm-none' : isMobile}">
                                    <div class="row">
                                        <div class="col-7">
                                            <div class="firstline">{{index.Name}}</div>
                                        </div>
                                        <div [class.imnegative]="index.Change<0" [class.impositive]="index.Change>0" class="col-5 text-right">
                                            <div class="firstline">{{index.IndexValue | pricepipe:2}}</div>
                                            <div class="line2">{{index.Change | pricepipe:2}} ({{index.PerCng | percentagepipe}})</div>
                                        </div>
                                    </div>
                                    <div class="row text-center ts-3 pt-1">
                                        <div class="col-3"><span class="subcolor ts-1" translate>all.open</span><br>{{index.OpenPrice | pricepipe:2 }}</div>
                                        <div class="col-3"><span class="subcolor ts-1" translate>all.close</span><br>{{index.close | pricepipe:2 }}</div>
                                        <div class="col-3"><span class="subcolor ts-1" translate>all.low</span><br>{{index.lowPrice | pricepipe:2 }}</div>
                                        <div class="col-3"><span class="subcolor ts-1" translate>all.high</span><br>{{index.highPrice | pricepipe:2 }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!cellview">
                    <div class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                    <table mat-table [dataSource]="indexList">

                        <ng-container matColumnDef="Name" sticky>
                            <th mat-header-cell *matHeaderCellDef translate> dashboard.index </th>
                            <td mat-cell *matCellDef="let element">{{element.Name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="IndexValue">
                            <th mat-header-cell *matHeaderCellDef translate class="text-right"> alert.value </th>
                            <td mat-cell *matCellDef="let element" class="text-right"[class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">{{element.IndexValue | pricepipe:2}} </td>
                        </ng-container>
                    
                        <ng-container matColumnDef="Change">
                        <th mat-header-cell *matHeaderCellDef class="text-right" translate>stock_detail.change</th>
                        <td mat-cell *matCellDef="let element" class="text-right" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">{{element.Change | pricepipe:2}} </td>
                        </ng-container>
                        <ng-container matColumnDef="PerCng">
                            <th mat-header-cell *matHeaderCellDef class="text-right"> % {{'stock_detail.change'| translate}} </th>
                            <td mat-cell *matCellDef="let element" class="text-right" [class.imnegative]="element.Change<0" [class.impositive]="element.Change>0">{{element.PerCng | percentagepipe}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Open">
                            <th mat-header-cell *matHeaderCellDef class="text-right" translate>all.open</th>
                            <td mat-cell *matCellDef="let element" class="text-right">{{element.OpenPrice | pricepipe:2 }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Close">
                            <th mat-header-cell *matHeaderCellDef class="text-right" translate>all.close</th>
                            <td mat-cell *matCellDef="let element" class="text-right">{{element.close | pricepipe:2 }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Low">
                            <th mat-header-cell *matHeaderCellDef class="text-right" translate>all.low</th>
                            <td mat-cell *matCellDef="let element" class="text-right">{{element.lowPrice | pricepipe:2 }}</td>
                        </ng-container>
                        <ng-container matColumnDef="High">
                            <th mat-header-cell *matHeaderCellDef class="text-right" translate>all.high</th>
                            <td mat-cell *matCellDef="let element" class="text-right">{{element.highPrice | pricepipe:2 }}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    </div>
                </ng-container>
                </div>

                <ng-container *ngIf='!isLoading && isNoData'>
                    <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
                </ng-container>
            </div>
        </mat-card-content>
    </mat-card>
</ngx-pull-to-refresh>