<app-header *ngIf = "!fromMultiView" goback="1" title={{tradingSymbol}} [isLoading]="isLoader"></app-header>
<div [class.data-container]="!isCustomDashboard">
    <ng-container cdkDropListGroup *ngIf = "!noData">
        <div class="modeBar">
            <div class="row" *ngIf = "showSettings">
                <div class="col-4" [ngStyle] = "{paddingLeft: '10px'}">
                    <mat-checkbox [checked]="displayMode == 'Normal'" (change) = "onChangeMode($event)">Show All Ticks</mat-checkbox>
                </div>
                <div class="zindex1 col-5">
                    <mat-form-field *ngIf = "displayMode == 'Optimized'" [ngStyle] = "{float: 'right'}">
                        <mat-label>Tick Size</mat-label>
                        <mat-select disableRipple (selectionChange)="onTickSizeChange()" [(value)]="ticksize">
                            <mat-option *ngFor="let tick of tickSizeArray" [value]="tick">
                                {{tick}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="headerContent col-3">
                    <button mat-button (click) = "onShowSettingsToggle()"><i class="icon-close"></i></button>
                </div>
            </div>
            <div *ngIf = "!showSettings" class="row myrow">
                <div class="col-3">
                    <small class="primetext">LCL </small><br>
                    {{depthData?.LCircuitLimit | pricepipe}}
                </div>
                <div class="col-3">
                    <small class="primetext">LTP</small><br>
                    {{depthData?.ltp | pricepipe}}
                </div>
                <div class="col-3">
                    <small class="primetext">UCL</small><br>
                    {{depthData?.HCircuitLimit | pricepipe}}
                </div>
                <div class="col-3 text-right">
                    <button mat-button (click) = "onShowSettingsToggle()"><i class="icon-menu"></i></button>
                </div>
            </div>
        </div>
        <div class="container">
            <div id="headerBar" class="row headerrow">
                <div id="openBuyHeader" class="col-3 text-center" [ngStyle] = "{width: buyWidth+'px', color: themeObj['PositiveTrand']}" translate>visual_trading.open_buy</div>
                <div [ngStyle]="{width: bidWidth+'px', color: themeObj['PositiveTrand']}" id="bidHeader" class="col-2"><span id="bidColumnHeader" translate>stock_detail.bid</span></div>
                <div [ngStyle]="{width: priceWidth+'px', color: themeObj['primetext']}" id="priceHeader" class="col-2 primetext text-center"><span id="priceColumnHeader" translate>stock_detail.price</span></div>
                <div [ngStyle]="{width: askWidth+'px', color: themeObj['NegativeTrand']}" id="askHeader" class="col-2 text-right "><span id="askColumnHeader"translate>stock_detail.ask</span></div>
                <div id="openSellHeader" class="col-3 text-center" [ngStyle] = "{width: buyWidth+'px', color: themeObj['NegativeTrand']}" translate>visual_trading.open_sell</div>
            </div>
        </div>
        <div [ngStyle]="{height: containerHeight+'px'}" class="container mybox" #container [hidden] = "isLoader">
            <div *ngFor = "let ob of customrows; let i = index" class="row" #customrow [ngStyle]="{height: rowHeight+'px'}">
                <div cdkDropList (cdkDropListDropped)="showAlert('drop', $event)" [cdkDropListData]="ob.buy.orders" id="buy-{{i}}" class="col-3 text-center border-r buy-div" (cdkDropListEntered)="dragListEntered(i, 'buy')" (cdkDropListExited)="dragListExited(i, 'buy')">
                    <button *ngIf = "ob.buy.isMore" id="buy-more-{{i}}" class="menudrop" (click) = "onMore($event)"><i class="icon-menu" id="buy-more-{{i}}"></i></button>
                    <ng-container *ngFor = "let order of ob.buy.orders; let j = index">
                        <span 
                            *ngIf = "order.disable" 
                            class="openorder animate">
                                {{order.PendingQty}}
                        </span>
                        <span 
                            id="{{order.id}}"
                            (cdkDragStarted) = "drag($event)" 
                            (cdkDragEnded)="dragEnd($event)" 
                            (click) = "onEditOrder($event)" 
                            *ngIf = "!order.disable" 
                            cdkDrag 
                            cdkDragLockAxis="y"
                            class="openorder"
                            [ngStyle] = "{ background: themeObj['PositiveTrand']}">
                                {{order.PendingQty}}
                        </span>
                    </ng-container>
                    <div [hidden] = "!ob.buy.showPopup" id="morePopup">
                        <ng-container *ngFor = "let order of ob.buy.moreOrders; let j = index">
                            <span 
                                *ngIf = "order.disable" 
                                class="openorder animate">
                                    {{order.PendingQty}}
                            </span>
                            <span 
                                id="{{order.id}}"
                                (cdkDragStarted) = "drag($event)" 
                                (cdkDragEnded)="dragEnd($event)" 
                                (click) = "onEditOrder($event)" 
                                *ngIf = "!order.disable" 
                                cdkDragLockAxis="y"
                                cdkDrag 
                                class="openorder" 
                                [ngStyle] = "{ background: themeObj['Divider']}">
                                    {{order.PendingQty}}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div (click) = "onBestBuySell($event)" id="{{ob.bid.id}}" class="col-2 bid-div noselect" [ngStyle]="{color: themeObj['PositiveTrand']}" (touchstart) = "ontouchStart($event)" (touchmove) = "ontouchMove($event)" (wheel) = "scrollEvent($event)">{{ob.bid.qty}}</div>
                <div (click) = "onPriceClick($event)" id="{{ob.price}}"  class="col-2 primetext text-center price-div noselect"  [ngClass] = "(displayMode == 'Optimized' && ticksize != tickSizeArray[0] && (updatedLtp > (ob.price - ticksize) && updatedLtp < ob.price) && ob.price != updatedLtp) ? 'borderBottom tooltip': ''"  [ngStyle]="{background: ob.price == updatedLtp ? themeObj['Divider'] : themeObj['Accent']}" (touchstart) = "ontouchStart($event)" (touchmove) = "ontouchMove($event)" (wheel) = "scrollEvent($event)">
                    <!-- <span class="tooltiptext" *ngIf = "displayMode == 'Optimized' && depthData.ticksize != ticksize && (updatedLtp > (ob.price - ticksize) && updatedLtp < ob.price)">LTP: {{updatedLtp}}</span> -->
                        {{ob.price}}
                </div>
                <div (click) = "onBestBuySell($event)" id="{{ob.ask.id}}" class=" text-right col-2 column ask-div noselect" [ngStyle]="{color: themeObj['NegativeTrand']}" (touchstart) = "ontouchStart($event)" (touchmove) = "ontouchMove($event)" (wheel) = "scrollEvent($event)">{{ob.ask.qty}}</div>
                <div cdkDropList (cdkDropListDropped)="showAlert('drop', $event)" [cdkDropListData]="ob.sell.orders" id="sell-{{i}}" class="col-3 border-l text-center sell-div" (cdkDropListEntered)="dragListEntered(i, 'sell')" (cdkDropListExited)="dragListExited(i, 'sell')">
                    <ng-container *ngFor = "let order of ob.sell.orders; let j = index">
                        <span 
                            *ngIf = "order.disable" 
                            class="openorder animate">
                                {{order.PendingQty}}
                        </span>
                        <span 
                            id="{{order.id}}"
                            (cdkDragStarted) = "drag($event)" 
                            (cdkDragEnded)="dragEnd($event)" 
                            (click) = "onEditOrder($event)" 
                            *ngIf = "!order.disable" 
                            cdkDragLockAxis="y"
                            cdkDrag 
                            class="openorder" 
                            [ngStyle] = "{ background: themeObj['NegativeTrand']}">
                                {{order.PendingQty}}
                        </span>
                    </ng-container>
                    <button *ngIf = "ob.sell.isMore" id="sell-more-{{i}}" class="menudrop" (click) = "onMore($event)"><i class="icon-menu" id="sell-more-{{i}}"></i></button>
                    <div [hidden] = "!ob.sell.showPopup" id="morePopup">
                        <ng-container *ngFor = "let order of ob.sell.moreOrders; let j = index">
                            <span 
                                *ngIf = "order.disable" 
                                class="openorder animate">
                                    {{order.PendingQty}}
                            </span>
                            <span 
                                id="{{order.id}}"
                                (cdkDragStarted) = "drag($event)" 
                                (cdkDragEnded)="dragEnd($event)" 
                                (click) = "onEditOrder($event)" 
                                *ngIf = "!order.disable" 
                                cdkDragLockAxis="y"
                                cdkDrag 
                                class="openorder" 
                                [ngStyle] = "{ background: themeObj['Divider']}">
                                    {{order.PendingQty}}
                            </span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isLoader" class="loader" [ngStyle]="{height: containerHeight+'px'}">
            <div class="wrapper">
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
                <div class="comment br loaderAnimate"></div>
            </div>
        </div>
        <div *ngIf="isAlertDiv" class="fadeDiv" [ngStyle]="{height: fadeDivHeight+'px'}"></div>
        <div class="container" id="bottomDiv">
            <div class="row">
                <div class="col-3"></div>
                <div class="col-2 text-center"><button mat-icon-button (click)="onUpClick()" (touchstart) = "onLongPress('u')" (touchend) = "onLongPressRelease()"><i class="icon-godown"></i></button></div>
                <div class="col-2 text-center"><button mat-icon-button (click)="onRecenter()"><i class="icon-recenter" [hidden] = "wait"></i>
                    <svg class="svgClass" *ngIf = "wait">
                        <circle r="10" cx="15" cy="15"></circle>
                    </svg>
                </button></div>
                <div class="col-2 text-center"><button mat-icon-button (click)="onDownClick()" (touchstart) = "onLongPress('d')" (touchend) = "onLongPressRelease()"><i class="icon-goup"></i></button></div>
                <div class="col-3"></div>
            </div>
            
            <div id="placeorder" *ngIf = "isPlaceorder">
                <div class="form visualtrading placeorderfield">
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-6 col-md-3">
                                    <mat-form-field><input type="tel" #inputtext inputmode="decimal"  min="0"  matInput  [placeholder]="'stock_detail.price' | translate" id="priceField" (keydown)="validatePrice($event)" autocomplete="off" [(ngModel)] = "displayPrice" (keyup) = "keyup($event)"/></mat-form-field>
                                </div>
                                <div class="col-6 col-md-3 "><mat-form-field><input type="tel" #inputQty  matInput  [placeholder]="'basket.basket_qty' | translate" id="quantityField" (keydown)="qtyUpDown($event)" (keyup)="validateQty($event)" maxlength="10" autocomplete="off" [(ngModel)] = "displayQuantity"/></mat-form-field></div>
                            </div>
                            
                            
                        </div>
                        <div class="col-4 text-right">
                            <ng-container *ngIf = "isBuySellDiv" class="btn-cont" id="buy_sell">
                                <div class="buttonsgrp pt-1">
                                <button *ngIf = "isBuyButton" [ngClass] = "{'disableButton': !displayPrice || !displayQuantity}" mat-button  (click)="showAlert('buy')" [disabled] = "!displayPrice || !displayQuantity" class="btn-positive"><i class="icon-bb"></i></button>
                                <button *ngIf = "isSellButton" [ngClass] = "{'disableButton': !displayPrice || !displayQuantity}" mat-button (click)="showAlert('sell')" [disabled] = "!displayPrice || !displayQuantity" class="btn-negative"><i class="icon-sb"></i></button>
                                </div>
                            </ng-container>
                            <ng-container *ngIf = "isModifyCancelDiv" class="btn-cont" id="modify">
                                <div class="buttonsgrp">
                                    <button  matTooltip="{{'tooltip.edit_order' | translate}}" mattooltip="Edit Order" class="btn-primary" [ngClass] = "{'disableButton': !displayPrice || !displayQuantity}" [disabled] = "!displayPrice || !displayQuantity" mat-icon-button id='edit' (click)="showAlert('modify')"><i class="icon-edit"></i></button>
                                    <button matTooltip="{{'watchlist.cancel_order' | translate}}" mat-icon-button class="btn-negative" id='cancelorder' (click)="showAlert('cancel')"><i class="icon-close"></i></button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    
                    
                    
                </div>
            </div>
            <div [@slideInOut] = animate cdkDropList id="deleteArea" (cdkDropListDropped) = "showAlert('dropCancel', $event)" (cdkDropListEntered)="entered($event)" (cdkDropListExited)="exited($event)">
            <div style="position:relative;top:10%">
                <img alt="" *ngIf ="!cancelDropListEntered" src="assets/img/delete.png" alt="" class="deleteImg"/>
                <img alt="" *ngIf ="cancelDropListEntered" src="assets/img/deleteopen.png" alt="" class="deleteImg"/>
                <br/>Drop here to cancel order
                </div>
            </div>
            <div class="p-2 mt-2 text-center" *ngIf = "isAlertDiv">
                <div class="ts-3 mb-2">Do you want to {{displayAction}} <span class="primetext fw-3">( {{ alertQty }} @ {{ alertPrice }} )?</span> </div>
                <button class="btn-positive mr-1" mat-flat-button (click)="onAlertYes()">CONFIRM</button>
                <button class="btn-plain" mat-flat-button (click)="onAlertNo()">CLOSE</button>
            </div>
        </div>
        
        
        
        
        
    </ng-container>
</div>
<div id="nodata" *ngIf="noData" [ngStyle]="{height: containerHeight+'px'}">
    <span class="nodataLable" translate>no_data.NODATA</span>
</div>
<div class="colors"></div>