import { IGraphService } from './igraph.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Utility } from '../utility';
import { Url } from '../url';
import { Keys } from '../keys';
import { Preferences } from '../Preferences';

@Injectable()
export class HSGraphService implements IGraphService {
    apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    getChartData(data: any, callback: Function) {
        let message = '';
        let code;
        const chartArr = [];
        // const jsonSendObj = { 'exch': Utility.segmentToExchange(data['exch']), 'sym': data['symbol'], 'intvl': '1', 'graphTp': '0' };
        const param = Utility.prepareParameters(JSON.stringify(data), Preferences.getPreference('userSessionId'));
        this.apiService.hspost(Url.HS_INTRADAY_CHART, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok' && res['data']['chartData']) {
                message = 'Ok';
                code = Keys.RES_CODE_SUCCESS;
                for (let i = 0; i < res['data']['chartData'].length; i++) {
                    let chartData = {};
                    chartData['high'] = res['data']['chartData'][i]['hgPrc'];
                    chartData['volumeChange'] = res['data']['chartData'][i]['volChg'];
                    chartData['low'] = res['data']['chartData'][i]['loPrc'];
                    chartData['CumulativeVolume'] = res['data']['chartData'][i]['cumVol'];
                    chartData['ltrate'] = res['data']['chartData'][i]['ltpRt'];
                    chartData['close'] = res['data']['chartData'][i]['clPrc'];
                    chartData['date'] = res['data']['chartData'][i]['dtTm'];
                    chartData['open'] = res['data']['chartData'][i]['opPrc'];
                    chartArr.push(chartData);
                }
            }
            else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback({ 'Result': chartArr }, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback('', code, message);
            });
    }

    toDateTime(seconds) {
        const date = new Date(1970, 0, 1);
        date.setSeconds(seconds);
        return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
    
    }

}
