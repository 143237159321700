<app-titlebar *ngIf="!isMobile"  [title]="'login.t_c' | translate"></app-titlebar>
<app-header (onTitleClick)="onTitleClick()" *ngIf="isMobile" goback="1" [title]="'login.t_c' | translate" [isReloadVisible]="false"></app-header>


<mat-card  [class.mt-5]="isMobile" [class.m-0]="!isMobile">
    <mat-card-content>
        <div class = "webpanelheight mb-2">
            <div class="textdetails-popup">
                <div>
                    <h4>Scope</h4>
                    <p>The below document covers the terms governing your usage of services provided by FinSpot on behalf of your BROKER.</p>
                </div>
                <div>
                    <h4>Restrictions on usage</h4>  
                    <p>You may not:</p>
                        <ul>
                            <li>Bypass any security mechanisms put in place</li>
                            <li>Reverse engineer or decompile or otherwise try to determine the ways in which our solutions are built or copy the implementation details of any of the components of our solutions.</li>
                            <li>Tamper with or attempt to read or change the content of any files we may store locally on your system.</li>
                            <li>Try to connect to our back-end services with any client software that is not explicitly provided by us, or explicitly authorized by us.</li>
                            <li>Use the solutions provided by us or the code behind these solutions to build competing products.</li>
                            <li>Use any of our UI for humans with software robots that simulate human behaviour that send requests or orders automatically. Only explicitly authorized and approved algo strategies may use our algo SDK/API for automated requests/orders, in conformance with regulations on such algos. Your account may be barred if this behaviour is seen from regular front-ends or from SDK/API without approval.</li>
                            <li>Use any of our front-ends, SDKs or APIs in any manner that could compromise the integrity or availability of our services for other customers. This includes sending lot of valid or invalid requests, for login or for other services</li>
                        </ul>
                </div>
                <div>
                    <h4>Fair Usage Policy</h4>
                    <p>To ensure the availability of our services to all users, we set some fair usage limits in place for our services. We reserve the right to blacklist or block users who violate these Fair Usage limits. There are separate limits set in place for human users and algo systems.</p>
                    <p>Human traders can send a maximum of 2 request per second, but below 10 requests in any 60 second window.</p>
                    <p>The number of instruments in any watchlist is capped at 50, and you may create a maximum of 5 such watchlists. The total subscriptions allowed across watchlist, positions, holdings and orderbook are capped at 500. If you want to subscribe to more than 500 instruments across all screens, please contact us for our Professional offering.</p>
                    <p>For algo systems, these limits will be decided at the point of approval and authorization of the algo strategy.</p>
                </div>
                <div>
                    <h4>Market Data Policy</h4>
                    <p>The Market Data provided by us is explicitly meant for use within our solutions, and only by active registered users of our services. You may not use this market data outside our solutions, unless you have signed up for our Market Data streaming services. Users of the Market Data Streaming services may only use these services from ONE APPLICATION running on ONE COMPUTER at a time, and may not build any mechanisms to store this data, or to publish the data either within their computer, within the network, or outside the network.</p>
                    <p>Concurrent usage policy</p>
                    <p>
                        Under our regular plan, you are allowed to login from only one device – whether Mobile, Web or Desktop at one time. If you would like to login from multiple devices simultaneously, please contact us for the details of the Professional offering.
                    </p>
                </div>
                <div>
                    <h4>Account Closure</h4>
                    <p>
                        We are obliged to retain some of the data related to your activity, as well as profile and access data for a period of time even after you have closed your account with the BROKER to meet our regulatory obligations.
                    </p>
                    <p>
                        However, this maintenance of data for our purposes does not mean that we can provide this data to you. Please ensure that you have downloaded all necessary statements and transaction details prior to closing your account with the BROKER.
                    </p>
                </div>
                
            </div>
          </div>
    </mat-card-content>
  </mat-card>