import { OnInit, Component, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ScripModel } from 'src/app/model/scrip.model';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility, ChannelType } from '../../../../services/utility';
let orderHistory: SpreadOrderHistoryComponent;
@Component({
    selector: 'app-spreadorderhistory',
    templateUrl: './spreadorderhistory.component.html'
})

export class SpreadOrderHistoryComponent implements OnDestroy, OnInit {
    isNoData: boolean;
    detailedOrderData;
    fillQuantity;
    isExit: boolean;
    isCancel: boolean;
    isMobile = true;
    dialogRef;
    showtitle = true;
    @Input() formOrder: boolean;
    scripModel: ScripModel;
    orderHistoryList;
    @Output() load = new EventEmitter<boolean>();
    isLoading: boolean;
    constructor(private dialog: MatDialog, private router: Router, private tradingRestService: TradingRestService) {
        this.isMobile = Utility.IS_MOBILE;
        orderHistory = this;
    }

    ngOnInit() {
        this.showtitle = !this.formOrder;
        orderHistory.detailedOrderData = Utility.spreadOrderDetail;
        orderHistory.getSpreadOrderHistory();
        this.onAppearing();
    }

    getSpreadOrderHistory(){
        this.isLoading = true;
        this.load.emit(this.isLoading);
        orderHistory.tradingRestService.getBookService().getSpreadOrderHistory(orderHistory.detailedOrderData['NestOrd'], ((response, code, message) => {
            this.isLoading = false;
            this.load.emit(this.isLoading);
            if (code === 200 && message === 'OK'){
                orderHistory.isNoData = false;
                orderHistory.orderHistoryList = response;
                orderHistory.scripModel = Utility.getScripModel(orderHistory.orderHistoryList[0]['Token'], orderHistory.detailedOrderData['ExchangeSegment'], orderHistory.tradingRestService, true);
            }
            else{
                orderHistory.isNoData = true;
            }
        }), orderHistory.preDefinedOrderHistory);
    }

    onRefresh(){
        orderHistory.getSpreadOrderHistory();
    }

    preDefinedOrderHistory(data) {
        orderHistory.orderHistoryList = data;
        orderHistory.scripModel = Utility.getScripModel(orderHistory.orderHistoryList[0]['Token'], orderHistory.detailedOrderData['ExchangeSegment'], orderHistory.tradingRestService, true);
    }

    // cancelOpenOrder() {
    //     this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { message: orderHistory.translate.instant('books.cancel_order') } });
    //     this.dialogRef.afterClosed().subscribe(result => {
    //         if (result === 'confirm') {
    //             orderHistory.tradingRestService.getBookService().CancelOrder(orderHistory.om, (responsedata, code, message) => {
    //                 if (responsedata['stat'] === 'Ok')
    //                     orderHistory.router.navigate(['/home/orders']);
    //             });
    //         }
    //     });
    // }

    // exitOrder() {
    //     this.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { message: orderHistory.translate.instant('books.exit_order') } });
    //     this.dialogRef.afterClosed().subscribe(result => {
    //         if (result === 'confirm') {
    //             orderHistory.tradingRestService.getBookService().ExitOrder(orderHistory.om, (responsedata, code, message) => {
    //                 if (responsedata['stat'] === 'Ok')
    //                     orderHistory.router.navigate(['/home/orders']);
    //             });
    //         }
    //     });
    // }

    // onModify() {
    //     if (this.isMobile) {
    //         orderHistory.router.navigate(['placeorder'], { queryParams: { 'isEdit': true } });
    //     } else {
    //         Utility.dialogRef.close();
    //         const data_obj = { 'isEdit': true };
    //         Utility.openPage(orderHistory.router, 'orderHistory', 'placeorder', PlaceorderComponent, orderHistory.dialog, 'popup', data_obj, '500px', '1250px');
    //     }
    // }

    setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.onAppearing();
          }, 50);
        }
        else {
          this.onDisappearing();
        }
    }

    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Shared);
    }

    onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Shared);
    }

    ngOnDestroy()
    {
        this.onDisappearing();
    }
}
