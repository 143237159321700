import { Component, OnInit, Output, EventEmitter, Input, Inject, ChangeDetectorRef, Injector } from '@angular/core';
import { Utility } from '../../../../services/utility';
import { PtPlusRestService } from '../../../../services/ptplusrest.service';
import { TradingRestService } from '../../../../services/tradingrest.service';
import { Keys } from '../../../../services/keys';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { StockMoreOptionComponent } from '../../stockmoreoption/stockmoreoption.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ComponentContainer } from 'golden-layout';
import { TableUtility } from '../../../../services/tableutility';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { Preferences } from 'src/app/services/Preferences';

let marketMood: MarketMoodComponent;
@Component({
    selector: 'app-market-mood',
    templateUrl: './marketmood.component.html',
    styleUrls: ['./marketmood.component.scss']
})
export class MarketMoodComponent implements OnInit {
    displayedColumns = TableUtility.headerConfig;
    exchArr = ['NSE', 'BSE'];
    indexArr = [];
    selectedExch = 'NSE';
    selectedIndex;
    indexData;
    marketMoodData;
    isNoData;
    scripList;
    indexConstituentData;
    index;
    isIndexRes;
    isLoading: boolean;
    isMobile = true;
    moodtype = '';
    tabmap = ['greaterthenfive', 'greaterthenthree', 'greaterthenzero', 'zero', 'lessthenfive', 'lessthenthree', 'lessthenzero'];
    datamap = {};
    utility: any;
    @Output() loaderEvent = new EventEmitter<boolean>();
    @Output() openStockDetailPage = new EventEmitter<string>();
    @Input() isCustomDashboard = false;
    Height: any;
    container?: ComponentContainer;
    left: any;
    dataArray;
    modesub: Subscription;
    cellView;

    constructor(private injector: Injector, private detector: ChangeDetectorRef, private tradingRestService: TradingRestService,
        private ptPlusRestService: PtPlusRestService, private router: Router, private dialog: MatDialog,
        private translate: TranslateService, private _bottomSheet: MatBottomSheet) {
        marketMood = this;
        if (!Utility.IS_MOBILE) {
            marketMood.container = <ComponentContainer>marketMood.injector.get('Container');
        }
        marketMood.utility = Utility;
        marketMood.createDataMap();
        marketMood.isMobile = Utility.IS_MOBILE;
        marketMood.indexData = (JSON.parse(Preferences.getPreference('accessToken')));
        marketMood.indexArr = marketMood.indexData.index.NSE;
        marketMood.selectedIndex = marketMood.indexArr[0].symbol;
        marketMood.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            marketMood.changeMode();
        });
    }

    changeMode(){
        if (marketMood.container.width !== null){
            marketMood.Height = {
                'height': (marketMood.container.height - TableUtility.MMHeight) + 'px',
                'overflow': 'auto'
            };
            marketMood.left = {
                'left': (marketMood.container.width - TableUtility.MMLeft) + 'px'
            };
            marketMood.detector.detectChanges();

            if (marketMood.container.width < Utility.MinWidth)
                marketMood.cellView = true;
            else
                marketMood.cellView = false;
        }
    }

    ngOnInit() {
        if (marketMood.container) {
           /* marketMood.container.on('resize', _ => {
                marketMood.changeMode();
            });
            marketMood.container.on('show', _ => {
                setTimeout(() => {
                    marketMood.changeMode();
                }, 50);
              });*/
        }
        marketMood.getMarketMoodData();
        if (marketMood.isCustomDashboard) {
            marketMood.changeMode();
        }
    }

    sortTopMarket(event) {
        const data = Array.from(marketMood.dataArray);
        if (event.active === 'TradingSymbol' || event.active === 'CompanyName') {
          if (event.direction === 'asc')
            marketMood.indexConstituentData = data.sort((a, b) => (a[event.active] > b[event.active]) ? 1 : ((b[event.active] > a[event.active]) ? -1 : 0));
          else
            marketMood.indexConstituentData = data.sort((a, b) => (a[event.active] < b[event.active]) ? 1 : ((b[event.active] < a[event.active]) ? -1 : 0));
        }
        else {
          if (event.direction === 'asc')
            marketMood.indexConstituentData = data.sort((a, b) =>
             (Utility.getNumber(a[event.active]) > Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) > Utility.getNumber(a[event.active])) ? -1 : 0));
          else
            marketMood.indexConstituentData = data.sort((a, b) =>
             (Utility.getNumber(a[event.active]) < Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) < Utility.getNumber(a[event.active])) ? -1 : 0));
        }
    }
    onIndexSelection(index) {
        marketMood.selectedIndex = index;
        marketMood.isIndexRes = false;
        marketMood.getMarketMoodData();
        marketMood.createDataMap();
        marketMood.getIndexData(marketMood.tabmap[marketMood.index]);
    }

    onExchangeSelection(exch) {
        marketMood.selectedExch = exch;
        marketMood.isIndexRes = false;
        marketMood.indexArr = [];
        if (marketMood.selectedExch === 'NSE')
            marketMood.indexArr = marketMood.indexData.index.NSE;
        else
            marketMood.indexArr = marketMood.indexData.index.BSE;
        marketMood.selectedIndex = marketMood.indexArr[0].symbol;
        marketMood.getMarketMoodData();
        marketMood.createDataMap();
        marketMood.getIndexData(marketMood.tabmap[marketMood.index]);
    }

    createDataMap() {
        marketMood.datamap = {
            'greaterthenzero': [], 'greaterthenthree': [],
            'greaterthenfive': [], 'zero': [], 'lessthenzero': [], 'lessthenthree': [], 'lessthenfive': [],
            'isgreaterthenzero': false, 'isgreaterthenthree': false, 'isgreaterthenfive': false,
            'iszero': false, 'islessthenzero': false, 'islessthenthree': false, 'islessthenfive': false
        };
    }

    onTabChange(index) {
        marketMood.index = index;
        marketMood.indexConstituentData = [];
        marketMood.getIndexData(marketMood.tabmap[index]);
    }

    getIndexData(moodtype) {
        marketMood.moodtype = moodtype;
        marketMood.indexConstituentData = [];
        if (marketMood.datamap['is' + moodtype] === false) {
            marketMood.ptPlusRestService.getEquityMarketService().getIndicesTodayConstituentsNQ(Utility.exchangeToSegment(marketMood.selectedExch), marketMood.selectedIndex, 'mooddetails', moodtype,
                marketMood.getAllIndicesResponse, (data) => {
                    marketMood.isIndexRes = true;
                    marketMood.datamap[marketMood.moodtype] = data;
                    marketMood.datamap['is' + marketMood.moodtype] = true;
                    marketMood.indexConstituentData = data;
                    marketMood.dataArray = Array.from(marketMood.indexConstituentData);
                });
        } else {
            marketMood.indexConstituentData = marketMood.datamap[moodtype];
        }
        marketMood.isNoData = (marketMood.indexConstituentData.length === 0) ? true : false;

    }


    getMarketMoodData() {
        marketMood.isLoading = true;
        marketMood.loaderEvent.emit(marketMood.isLoading);
        marketMood.ptPlusRestService.getEquityMarketService().getMarketMood(Utility.exchangeToSegment(marketMood.selectedExch),
            marketMood.selectedIndex, marketMood.getMarketMoodResponse, (data) => {
                marketMood.marketMoodData = [{ 'name': 'greaterthenfive', 'value': data['greaterthenfive'], 'perChng': '>5%' },
                { 'name': 'greaterthenthree', 'value': data['greaterthenthree'], 'perChng': '3% to 5%' },
                { 'name': 'greaterthenzero', 'value': data['greaterthenzero'], 'perChng': '0% to 3%' },
                { 'name': 'zero', 'value': data['zero'], 'perChng': marketMood.translate.instant('equity.noChg') },
                { 'name': 'lessthenfive', 'value': data['lessthenfive'], 'perChng': '<-5%' },
                { 'name': 'lessthenthree', 'value': data['lessthenthree'], 'perChng': '-5% to -3%' },
                { 'name': 'lessthenzero', 'value': data['lessthenzero'], 'perChng': '-3% to 0%' }];
            });
    }

    getMarketMoodResponse(data, code, msg) {
        marketMood.isLoading = false;
        marketMood.loaderEvent.emit(marketMood.isLoading);
        marketMood.marketMoodData = [];
        if (code === Keys.RES_CODE_SUCCESS) {
            marketMood.isNoData = false;
            marketMood.marketMoodData = [{ 'name': 'greaterthenfive', 'value': data['greaterthenfive'], 'perChng': '>5%' },
            { 'name': 'greaterthenthree', 'value': data['greaterthenthree'], 'perChng': '3% to 5%' },
            { 'name': 'greaterthenzero', 'value': data['greaterthenzero'], 'perChng': '0% to 3%' },
            { 'name': 'zero', 'value': data['zero'], 'perChng': marketMood.translate.instant('equity.noChg') },
            { 'name': 'lessthenfive', 'value': data['lessthenfive'], 'perChng': '<-5%' },
            { 'name': 'lessthenthree', 'value': data['lessthenthree'], 'perChng': '-5% to -3%' },
            { 'name': 'lessthenzero', 'value': data['lessthenzero'], 'perChng': '-3% to 0%' }];
        }
        else
            marketMood.isNoData = true;
    }

    getAllIndicesResponse(res, code, msg) {
        if (code === Keys.RES_CODE_SUCCESS && res.length > 0) {
            marketMood.isIndexRes = true;
            marketMood.isNoData = false;
            marketMood.datamap[marketMood.moodtype] = res;
            marketMood.datamap['is' + marketMood.moodtype] = true;
            marketMood.indexConstituentData = res;
        }
        else
            marketMood.isNoData = true;
    }
    openPlaceOrder(buttontype, data) {
        Utility.openPlaceOrder(marketMood.router, data.Token, data.ExchangeSegment, buttontype, marketMood.dialog);
    }
    openScripModel(option) {
        if(marketMood.isMobile) {
            marketMood._bottomSheet.open(StockMoreOptionComponent, {
                data: option, restoreFocus: false
            });
        }
    }
    openStockDetail(data) {
        const obj = { 'token': data.Token, 'exch': data.ExchangeSegment, 'symbol': data.TradingSymbol }
        marketMood.openStockDetailPage.emit(JSON.stringify(obj));
    }

    handleMouseOver(row) {
    const TradingSymbol = row.TradingSymbol;
    marketMood.indexConstituentData.map((data: any) => {
      if (TradingSymbol && data.TradingSymbol && data.TradingSymbol === TradingSymbol) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const TradingSymbol = row.TradingSymbol;
    marketMood.indexConstituentData.map((data: any) => {
      if (data.TradingSymbol === TradingSymbol) {
        data.show = false;
      }
    });
  }
}
