<app-header goback="1" *ngIf="isMobile"  [title]="title" [isLoading]="isLoading"></app-header>
<div class="webpanelnew" [class.mt-5]="isMobile" [class.pt-0]="!isMobile && !cellview">
    <div class="row px-0">
        <div class="col-12">
            <div class="row panelsubheader">   
                <div class="col-12 sameer">
                    <mat-radio-group [(ngModel)]="selectedTriggerType" (change) = "selectType()" [disabled]="isLoading">
                        <mat-radio-button *ngFor="let val of triggerTypes let i = index" [value]="val.val" [class.imactive]="val.val === selectedTriggerType">{{val.key}}</mat-radio-button>&nbsp;
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf = "isNoData"><app-nodata (refreshEvent) = "refresh()" [btnTitle]="'all.reload' | translate"></app-nodata></div>
    <div #p2 *ngIf="!isNoData && (isMobile || cellview)" [ngStyle]="Height" (scroll)="onWindowScroll($event)">
        <div [class.pl-2]="cellview" [class.pr-2]="cellview" *ngFor="let data of triggersList; let i = index"  [class.wlweb]="cellview" (click)="highlight(data, i)" tabindex="999" [attr.id]="'wl_cell' + i">
            <div class="row">
                <div class="col-12" [ngClass]="{'highlight': selectedRowIndex == i}">
                    <div class="list-view border-b">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <div class="subcolor ts-2"><span class="fw-3 tc-3" [ngClass]="getClass(statusMap[data.stat])">{{statusMap[data.stat]}}</span></div>
                                <span class="tc-1 ts-4 texttrim" matTooltip="{{data.cond.sym}}">{{data.cond.sym | symbolpipe}}</span> <small class="exchangename">{{segmentToExchange(data.cond.ex)}}</small><br>
                            </div>
                            <div class="col-6 text-right">
                                <span [class.tag-red]="data.ord[0].ttyp=='S'" [class.tag-green]="data.ord[0].ttyp=='B'">{{data.ord[0].ttyp === 'B' ? ('all.buy' | translate) : ('all.sell' | translate)}}</span><br/>
                                <div [class.imnegative]="data.scripModel.PercentageChange<0" [class.impositive]="data.scripModel.PercentageChange>0">
                                    <div [class.imnegativebg]="data.scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="data.scripModel.TickDirection === 1 && flashType === 'defaultFlash'">
                                        <span [class.imnegativebg]="data.scripModel.TickDirection === -1 && flashType === 'ltpFlash'" [class.impositivebg]="data.scripModel.TickDirection === 1 && flashType === 'ltpFlash'">{{data.scripModel.LastTradePrice | pricepipe:data.scripModel.DecimalPrecision}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="wl-btn" *ngIf="cellview" [class.d-none]="!M.IS_MOUSE">
                                <button *ngIf="data.stat === 1" (click)='deleteTrigger(data)' class="btn-negative" matTooltip="{{'all.delete' | translate}}" mat-icon-button><i class="icon-delete"></i></button> 
                                <button *ngIf="data.stat === 1 || data.stat === 6" (click)='editTrigger(data)' class="ml-2 btn-primary" matTooltip="{{'all.edit' | translate}}" mat-icon-button ><i class="icon-edit"></i></button> 
                            </div>
                        </div>
                        <div class="row" *ngIf="data.typ == 'GTT_SINGLE'">
                            <div class="col-12 pr-0">
                                <span>{{'all.target_ltp' | translate}} <b>{{data.ord[0].tv | pricepipe: 2}}</b> {{'all.plac' | translate}} <b>{{data.ord[0].qty}}</b> x <b>{{data.ord[0].px | pricepipe: 2}}</b></span>
                            </div>
                        </div>
                        <div class="row" *ngIf="data.typ == 'GTT_OCO'">
                            <div class="col-12 pr-0">
                                <span>{{'all.stoploss_ltp' | translate}} <b>{{data.ord[1].tv | pricepipe: 2}}</b> {{'all.plac' | translate}} <b>{{data.ord[1].qty}}</b> x <b>{{data.ord[1].px | pricepipe: 2}}</b></span>
                            </div>
                            <div class="col-12 pr-0">
                                <span>{{'all.target_ltp' | translate}} <b>{{data.ord[0].tv | pricepipe: 2}}</b> {{'all.plac' | translate}} <b>{{data.ord[0].qty}}</b> x <b>{{data.ord[0].px | pricepipe: 2}}</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <ng-container *ngIf="!cellview && !isMobile">
        <div #p1 *ngIf = "!isNoData" class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
            <table mat-table [dataSource]="triggersList">
                <ng-container matColumnDef="TradingSymbol" sticky>
                    <th mat-header-cell *matHeaderCellDef> <span>{{'all.symbol' | translate}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <span class="button-row">
                            <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true" [class.d-none]="!M.IS_MOUSE">
                                <button *ngIf="element.stat === 1" (click)='deleteTrigger(element)' class="btn-negative" matTooltip="{{'all.delete' | translate}}" mat-icon-button><i class="icon-delete"></i></button> 
                                <button *ngIf="element.stat === 1 || element.stat === 6" (click)='editTrigger(element)' class="ml-2 btn-primary" matTooltip="{{'all.edit' | translate}}" mat-icon-button ><i class="icon-edit"></i></button> 
                            </div>
                        </span>
                        <div class="firstline">{{element.cond.sym | symbolpipe}}<small>{{segmentToExchange(element.cond.ex)}}</small></div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef class="text-left"> <span>{{'all.status' | translate}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index"  class="text-left" [ngClass]="getClass(statusMap[element.stat])">
                        <span>{{statusMap[element.stat]}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="TriggerType">
                    <th mat-header-cell *matHeaderCellDef class="text-left"> <span>{{'all.transaction_type' | translate}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="text-left" [class.impositive]="element.ord[0].ttyp === 'B'"  [class.imnegative]="element.ord[0].ttyp === 'S'">
                        <span>{{element.ord[0].ttyp === 'B' ? ('all.buy' | translate) : ('all.sell' | translate)}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="LastTradePrice">
                    <th mat-header-cell *matHeaderCellDef> <span>{{'all.ltp' | translate}}</span> </th>
                    <td mat-cell *matCellDef="let element" [class.imnegative]="element.scripModel.Change<0" [class.impositive]="element.scripModel.Change>0">
                        <div [class.imnegativebg]="element.scripModel.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="element.scripModel.TickDirection === 1 && flashType !== 'noFlash'">
                            {{element.scripModel.LastTradePrice| pricepipe:element.scripModel.DecimalPrecision}}            
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Quantity1">
                    <th mat-header-cell *matHeaderCellDef> <span>{{selectedTriggerType == 'GTT_SINGLE' ? ('all.qty'|translate) : ('all.target_qty'|translate)}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span>{{element.ord[0].qty}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Price1">
                    <th mat-header-cell *matHeaderCellDef> <span>{{selectedTriggerType == 'GTT_SINGLE' ? ('all.prc'|translate) : ('all.target_price'|translate)}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span>{{element.ord[0].px | pricepipe: 2}}</span>
                    </td>
                </ng-container>  
                <ng-container matColumnDef="TriggerPrice1">
                    <th mat-header-cell *matHeaderCellDef> <span>{{selectedTriggerType == 'GTT_SINGLE' ? ('all.trg_prc'|translate) : ('all.trgt_trigger_price'|translate)}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span>{{element.ord[0].tv | pricepipe: 2}}</span>
                    </td>
                </ng-container>  
                <ng-container matColumnDef="Quantity2">
                    <th mat-header-cell *matHeaderCellDef> <span>{{'all.stoploss_qty' | translate}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span>{{element?.ord[1]?.qty}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Price2">
                    <th mat-header-cell *matHeaderCellDef> <span>{{'all.stoploss_price' | translate}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span>{{element?.ord[1]?.px | pricepipe: 2}}</span>
                    </td>
                </ng-container>   
                <ng-container matColumnDef="TriggerPrice2">
                    <th mat-header-cell *matHeaderCellDef> <span>{{'all.stp_trigger_price' | translate}}</span> </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <span>{{element?.ord[1]?.tv | pricepipe: 2}}</span>
                    </td>
                </ng-container>   
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" [ngClass]="{'highlight': selectedRowIndex == i}" (click)="highlight(row, i)" tabindex="999" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)" [attr.id]="'wl_tbl' + i"></tr>
            </table>
        </div>
    </ng-container>
</div>