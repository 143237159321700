export class IndexModel {
    public PerCng: number;
    public Token: string;
    public IndexValue: number;
    public Exchange: string;
    public Name: string;
    public Change: number;
    public TSymbol: string;
    public ExchangeSegment: string;
    public OmToken: string;
    public SevenDayReturn: number;
    public OneMonthReturn: number;
    public ThreeMonthReturn: number;
    public SixMonthReturn: number;
    public OneYearReturn: number;
    public SevenDayVolume: number;
    public OneMonthVolume: number;
    public ThreeMonthVolume: number;
    public SixMonthVolume: number;
    public OneYearVolume: number;
    public VolumeOne: number;
    public VolumeTwo: number;
    public SevenDayReturnVolume: number;
    public OneMonthReturnVolume: number;
    public ThreeMonthReturnVolume: number;
    public SixMonthReturnVolume: number;
    public OneYearReturnVolume: number;
    public Advance: number;
    public Decline: number;
    public Neutral: number;
    public AdvancePer: string;
    public DeclinePer: string;
    public NeutralPer: string;
    public Date: string;
    public DateTime: string;
    public Turnover: string;
    public High: string;
    public Low: string;
    public Open: string;
    public last: string;
    public Yearhigh: string;
    public Yearlow: string;
    public Cls: string;
    public closePrice: number;
    public Tym: string;

}
