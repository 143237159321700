import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Location} from '@angular/common';

@Component({
  selector: 'app-withdrawfunds',
  templateUrl: '../../'+environment.baseviewpath+'/withdrawfunds/withdrawfunds.component.html'
})
export class WithdrawfundsComponent implements OnInit {
  @Output() load = new EventEmitter<boolean>();
  @Output() loaderEvent = new EventEmitter();
  @ViewChild('inputPrice', { static: false }) inputPrice: ElementRef;
  isMobile = true;
  accountNumber;
  bankAccountDisplay = [];
  amount;
  isLoading;
  isedit = false;
  withdrawbleAmount;
  uid = '';
  holderName = '';
  ifsc;
  accountsDetail="";
  bankName;
  withdrawalDetails;
  selectedAccount;
  @Output() close = new EventEmitter();
  @Input() awlBalance;
  @Input() modifyAmount;
  isModify;
  enabledSegments: any;
  segmentSelected: any;
  isWithdrawSuccess: boolean;
  paymentStatus = 'Payment Unsuccessful';
  disableContinue: boolean;
  constructor(private tradingRestService: TradingRestService, private translate: TranslateService, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute, private router: Router, private location: Location) { }

  ngOnInit(): void {
    this.isMobile = Utility.IS_MOBILE;
    if (this.isMobile) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.withdrawbleAmount = params['bal'];
      });
    } else {
      this.withdrawbleAmount = this.awlBalance;
    }
    if (Utility.modifyWithdrawalData.length > 0){
      this.amount = Utility.modifyWithdrawalData[0]['amount']/100;
      this.isModify = true;
    }
    Utility.fillWithdrawalData.subscribe(() => {
      this.amount = Utility.modifyWithdrawalData[0]['amount']/100;
      this.isModify = true;
    });
    Utility.closeWithdraw.subscribe(() => {
      this.transactionDone();
    })
    this.tradingRestService.getUserService().getUserProfile((data, code, msg) => {
      if (code === 200 && msg === 'OK') {
        this.uid = data.usrId;
        this.accountNumber = data.bnkActNo;
        this.holderName = this.toTitleCase(data.actName);
        this.ifsc =  data.bnkIfscCode;
        if (data['bnkDtl'] !== 'null'){
          this.accountsDetail = data['bnkDtl'];
        }
        if (this.accountsDetail.length > 0){
          for (let i = 0; i < this.accountsDetail.length; i++){
          this.bankAccountDisplay[i] = { 'value': this.accountsDetail[i]['bnkActNo'], 'viewValue': this.accountsDetail[i]['bnkName']+ '-' +Utility.getMaskedValue(this.accountsDetail[i]['bnkActNo']) };
          }
        }
        if (this.accountsDetail.length > 0){
          this.selectedAccount = this.accountsDetail[0]['bnkActNo'];
        }

        this.enabledSegments = Utility.SegmentDetails['segments'];
        this.segmentSelected = this.enabledSegments[0];
      }
      else{}
    }, this.setPreProfileData);
  }
  setPreProfileData(){}

  
  onSegmentSelection(event: any){
    this.segmentSelected = event;
  }

  validatePrice(ev) {
    const regex = new RegExp('^[0-9]{0,' + 10 + '}$|^[0-9]{1,' + 10 + '}[.]{1,1}[0-9]{0,' + 2 + '}$');
    const val = this.inputPrice.nativeElement.value;
    const index = ev.target.selectionStart;
    if (!val.match(regex)) {
      if (this.amount === '.')
      {
        this.amount = '0.';
      }
      else
      {
        this.validateKey(ev.key, 'price');
        ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
        this.inputPrice.nativeElement.dispatchEvent(new Event('input'));
        this.inputPrice.nativeElement.setSelectionRange(index - 1, index - 1);
      }
    }
  }

  validateKey(key, str)
  {
    if (/[0-9]/i.test(key))
    {
      if (str === 'price')
        this.snackBar.open(this.translate.instant('limits.maximum_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
    }
    else if (/[a-zA-Z]/i.test(key))
      this.snackBar.open(this.translate.instant('limits.only_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
  }

  numberVal(e: any) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
     return false;
    }
    if (e.which === 0) {
     return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  onSelection(event: any){
    for (let i = 0; i < this.accountsDetail.length; i++){
      if (event === this.accountsDetail[i]['bnkActNo']){
        this.accountNumber = this.accountsDetail[i]['bnkActNo'];
        this.ifsc = this.accountsDetail[i]['bnkIfscCode'];
        this.bankName = this.accountsDetail[i]['bnkName'];
      }
    }
    }

  onContinueClick(){
    if (this.isModify){
      this.modifyWithdrawal();
    }
    else{
      this.payOut();
    }
  }

  modifyWithdrawal(){
    const bill_id = Utility.modifyWithdrawalData[0]['bill_id'];
    const sendJson = {
      "bill_id": Utility.modifyWithdrawalData[0]['bill_id'],
      "user_id": this.uid,
      "amount": (this.amount * 100).toFixed(0),
      "limit_payout_amt": Utility.payoutamt
  }
  if (Number(this.amount) > Number(this.withdrawbleAmount)){
    this.snackBar.open(this.translate.instant('placeorder.amt_greater'), '', {duration: 3000, panelClass: ['errorSnackBar']});
  }
  else{
    this.isModify = false;
    Utility.modifyWithdrawalData = [];
    this.disableContinue = true;
    this.isLoading = true;
    this.load.emit(this.isLoading);
    this.loaderEvent.emit(this.isLoading);
    this.tradingRestService.getUserService().modifyWithdrawal((res,code,message) => {
    this.disableContinue = false;
    this.isLoading = false;
    this.load.emit(this.isLoading);
    this.loaderEvent.emit(this.isLoading);
    if (code === 200 && res){
        this.isWithdrawSuccess = true;
        this.withdrawalDetails = res['data'];
        this.paymentStatus = 'Payment Successful';
        //this.withdrawbleAmount = this.withdrawbleAmount - this.withdrawalDetails['amount']/100;
        Utility.reloadLimit.next();
        if(!this.isMobile){
          Utility.transactionRefresh.next();
        }
      }
      else{
        this.isWithdrawSuccess = false;
        this.snackBar.open(res['message'], '', {duration: 3000, panelClass: ['errorSnackBar']});

        // if(!this.isMobile){
        //   Utility.transactionRefresh.next();
        // }
        // this.paymentStatus = 'Payment Unsuccessful';
      }
    }, sendJson);
  }
  }

  payOut() {
    if (Number(this.amount) > Number(this.withdrawbleAmount)){
      this.snackBar.open(this.translate.instant('placeorder.amt_greater'), '', {duration: 3000, panelClass: ['errorSnackBar']});
    }
    else{
       this.disableContinue = true;
      this.isLoading = true;
    this.load.emit(this.isLoading);
    this.loaderEvent.emit(this.isLoading);
    const data = {uid: this.uid, 'amount': (this.amount * 100).toFixed(0), 'acc_num': this.accountNumber, 'accHolder_name': this.holderName, 'ifsc_code': this.ifsc};
    this.tradingRestService.getUserService().PayOut((res, code, message) => {
      this.disableContinue = false;
        if (code === 200 && res) {
          this.isWithdrawSuccess = true;
          this.withdrawalDetails = res['data'];
          this.paymentStatus = 'Payment Successful';
          Utility.reloadLimit.next();
          if(!this.isMobile){
            Utility.transactionRefresh.next();
          }
        } else {
            if(!this.isMobile){
              Utility.transactionRefresh.next();
            }
            this.paymentStatus = 'Payment Unsuccessful';
        }
        this.isLoading = false;
        this.load.emit(this.isLoading);
        this.loaderEvent.emit(this.isLoading);
    }, data);
    }
  }

  withdrawAll(event: MatCheckboxChange){
    if (event.checked){
      this.isedit = true;
      this.amount = this.withdrawbleAmount;
    } else{
      this.isedit = false;
      this.amount = '';
    }
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  cancelClick(){
    this.amount = '';
    if (this.isMobile){
      this.isWithdrawSuccess = false;
      this.location.back();
    }
    else{
      this.isWithdrawSuccess = false;
      Utility.closeAddOverLay.next();
      this.close.emit();
    }
  }

  transactionDone(){
    this.isWithdrawSuccess = false;
    this.amount = '';
  }
}
