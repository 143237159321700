import { Component, OnInit } from '@angular/core';


import { TradingRestService } from '../../../services/tradingrest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Keys } from '../../../services/keys';
import { Router } from '@angular/router';
import { Utility } from 'src/app/services/utility';
import { PtPlusRestService } from '../../../services/ptplusrest.service';
import { TranslateService } from '../../../../../node_modules/@ngx-translate/core';
import { Preferences } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';


let changePwdComponent: ChangePwdComponent;
@Component({
  selector: 'app-change-pwd',
  templateUrl: './changepassword.component.html'
})
export class ChangePwdComponent implements OnInit {
  oldPwdError;
  newPwdError;
  confirmPwdError;
  PasswordPolicy = [];
  hideOldPwd = true;
  hideNewPwd = true;
  hideConfirmPwd = true;
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';
  isLoading;
  uid;
  isMobile = true;
  constructor(private tradingRestService: TradingRestService, private ptPlusRestService: PtPlusRestService, private snackBar: MatSnackBar, private router: Router,
    private translate: TranslateService) {
    changePwdComponent = this;
    changePwdComponent.isMobile = Utility.IS_MOBILE;
    changePwdComponent.translate.get(['settings.pwdQ1', 'settings.pwdQ2', 'settings.pwdQ3', 'settings.pwdQ4', 'settings.pwdQ5', 'settings.pwdQ6']).subscribe(
      values => {
        changePwdComponent.PasswordPolicy = Object.keys(values).map(key => values[key]);
      });
    changePwdComponent.uid = Preferences.getPreference('userid');
  }

  ngOnInit() {
  }
  onEnter(id) {
    if (Utility.IS_MOBILE) {
      document.getElementById(id).focus();
    }
  }
  passwordSubmit() {
    const pwdRegex = /^(?=.*[A-z])(?=.*[0-9])(?=.*[~!@#$%^&*_])\S{6,12}$/;
    if (changePwdComponent.oldPassword === '') {
      changePwdComponent.oldPwdError = true;
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('login.enter_CuP'), '', { duration: 3000 });
    }
    else if (changePwdComponent.newPassword === '') {
      changePwdComponent.newPwdError = true;
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('login.enter_NP'), '', { duration: 3000 });
    }
    else if (changePwdComponent.newPassword.indexOf(' ') > -1)
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('login.pwd_space'), '', { duration: 3000 });
    else if (changePwdComponent.newPassword.toLowerCase().indexOf(changePwdComponent.uid.toLowerCase()) > -1)
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('settings.pwdQ1'), '', { duration: 3000 });
    else if (changePwdComponent.oldPassword === changePwdComponent.newPassword)
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('login.old_new_pwd'), '', { duration: 3000 });
    else if (changePwdComponent.confirmPassword === '') {
      changePwdComponent.confirmPwdError = true;
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('login.enter_CoP'), '', { duration: 3000 });
    }
    else if (changePwdComponent.newPassword !== changePwdComponent.confirmPassword)
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('login.new_cur_pwd'), '', { duration: 3000 });
    else if (!pwdRegex.test(changePwdComponent.newPassword))
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('settings.pwdQ4'), '', { duration: 3000 });
    else
      changePwdComponent.getChangePwdData();
  }

  getChangePwdData() {
    changePwdComponent.isLoading = true;
    changePwdComponent.tradingRestService.getLoginService().ChangePassword(changePwdComponent.uid, changePwdComponent.oldPassword.toString(),
      changePwdComponent.newPassword.toString(), changePwdComponent.ChangePwdResponse);
  }

  ChangePwdResponse(data, code, msg) {
    changePwdComponent.isLoading = false;
    if (code === 200) {
      changePwdComponent.snackBar.open(changePwdComponent.translate.instant('settings.pwd_changed'), '', { duration: 3000 });
      if (Preferences.getPreference('LoginMode') === 'quickLogin') {
        changePwdComponent.tradingRestService.getLoginService().ResetUser(function (res, code, message){
          if (code === 200) {
            changePwdComponent.tradingRestService.getLoginService().DefaultLogin(changePwdComponent.DefaultLoginResponse);
          } else {
            changePwdComponent.snackBar.open(message, '', { duration: 3000 });
          }
        }.bind(this));
      }
      else {
          if(Utility.dialogRef)
            Utility.dialogRef.close();
          Preferences.setPreference('isMPIN', 'false');
          changePwdComponent.logoutForcefully();
      }
    }
    else if(code === 101) {
        if(Utility.dialogRef)
          Utility.dialogRef.close();
        changePwdComponent.snackBar.open(changePwdComponent.translate.instant(Utility.getErrorMessage(code,msg)), '', { duration: 3000 }); 
        changePwdComponent.logoutForcefully();
    }
    else {
        changePwdComponent.snackBar.open(changePwdComponent.translate.instant(Utility.getErrorMessage(code,msg)), '', { duration: 3000 });
    }
  }

  logoutForcefully()
  {
    Utility.candeactivate = false;
    Preferences.setPreference('LoggedIn', 'false');
    Preferences.setPreference('finallogin', 'false');
    if (environment.isQuickRest) {
        changePwdComponent.tradingRestService.tradeFeeder.closeSocketConnection();
    }
        Preferences.setPreference('userSessionId', null);
        Utility.userId = '';
        if (Utility.orderDataSubscription)
            Utility.orderDataSubscription.unsubscribe();
        changePwdComponent.router.navigate(['']);
  }


  DefaultLoginResponse(data, code, msg) {
    if (code === Keys.RES_CODE_SUCCESS) {
      changePwdComponent.ptPlusRestService.getAuthenticationService().GetAccessToken(changePwdComponent.getAccessTokenResponse);
      changePwdComponent.tradingRestService.marketDataFeeder.CreateConnection();
      if (!changePwdComponent.isMobile) {
        Utility.dialogRef.close();
      }
      else
      {
        changePwdComponent.router.navigate(['/home']);
      }      
    }
  }

  getAccessTokenResponse(data, code, msg) {
    if (code === 200)
    Preferences.setPreference('accessToken', JSON.stringify(data));
  }
}
