<app-header *ngIf="isMobile" goback="1" [title]="'header.lot_weight' | translate" [isLoading]="isLoading" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'header.lot_weight' | translate" [isLoading]="isLoading"></app-titlebar>
<mat-card class="webpanelnew">
    <mat-card-content>
        <div [class.pt-0]="!isMobile" [class.data-container]="isMobile">
            <ng-container *ngIf="!isLoading && !isNoData" >
                <div *ngFor="let key of lotweight | keyvalue">
                    <div class="watchlistwrapper">
                        <div class="row" [class.px-3]="!isMobile">
                            <div class="col-7">
                                {{key.key}}
							</div>
							<div class = "col-5">
								{{key.value}}
							</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf='!isLoading && isNoData' class='nodata'>
                <app-nodata message = "N0 DATA"></app-nodata>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>