<div class="row" [ngStyle]="Height">
    <div class="col-12">
<div class="p-2">
<div class="row"  *ngIf='!isNoData'>
    <div class="col-12 white-text pb-2">{{'stock_detail.thomson_reuters' | translate}} {{'stock_detail.I/B/E/S' | translate}} {{'stock_detail.mean' | translate}}: ( {{total}} {{'stock_detail.analysts' | translate}} )</div>
    <div class="col-12 pt-md-2 p-0">
        <canvas class = "chart-color3"  *ngIf = "ibesChartData.length>0" baseChart [datasets]="ibesChartData" [labels]="chartLabel" [options]="chartOptions"
            [chartType]="chartType" [colors]="colors" [plugins]="chartPlugins"></canvas>
    </div>
</div>
<div  *ngIf='!isNoData' class="row">
    <div class="col-12 p-0">
        <div class="text-center white-text col-md-6 col-12 p-0 right-align">
            <div class="py-1">{{'stock_detail.thomson_reuters' | translate}} {{'stock_detail.mean' | translate}} {{'stock_detail.target_price' | translate}}</div>
            <div class="py-1">{{targetPrice | pricepipe}}</div>
        </div>
    </div>
   
</div>
</div></div></div>
<div style="display: none;" class="chart-color3"></div>
<div *ngIf='isNoData'>
        <app-nodata message = "No Data"></app-nodata>
</div>