<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row list-view border-b" (swipedown)="onSwipeDown()">
    <div class="col-8" [class.pl-0] = "!isInteropsEnabled">
        <div *ngIf  = "!isInteropsEnabled" class="addwatchlistbtn" matRipple>
            <button mat-icon-button (click) = "addWLdata(positionDetailData)" [matMenuTriggerFor]="menu" class="primetext"><i class="icon-plus"></i></button>
            <mat-menu #menu="matMenu">
                <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                <ng-container *ngFor = "let data of WlArr">
                    <button mat-menu-item (click) = "addStock(data.wlName, positionDetailData)" [disabled] = "data.isDisable">
                        <span>{{data.wlName}}</span>
                    </button>
                </ng-container>
            </mat-menu>
        </div>

        <div class="min-w160 subcolor ts-2">
            <span class="tc-1 ts-4 textclip">{{positionDetailData.Exchangeseg == 'nse_fo' ? positionDetailData.Symbol : positionDetailData.Tsym | symbolpipe}}</span><small *ngIf = "!isInteropsEnabled || (positionDetailData.Exchange !== 'NSE' && positionDetailData.Exchange !== 'BSE')" class="exchangename">{{positionDetailData.Exchange}}</small><br>
            <div class="texttrim" matTooltip="{{positionDetailData.scripModel.DisplaySymbolDescription}}">{{positionDetailData.scripModel.DisplaySymbolDescription}}</div>
        </div>
    </div>
    <div class="col-4 text-right" [class.imnegative]="positionDetailData.scripModel.Change<0" [class.impositive]="positionDetailData.scripModel.Change>0">
        <div>
            <div [class.ts-3] = "positionDetailData.scripModel.DecimalPrecision == 4" [class.ts-4] = "positionDetailData.scripModel.DecimalPrecision != 4"><span [class.imnegativebg]="positionDetailData.scripModel.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="positionDetailData.scripModel.TickDirection === 1 && flashType !== 'noFlash'">{{positionDetailData.scripModel.LastTradePrice | pricepipe:positionDetailData.scripModel.DecimalPrecision}}</span><br>
                <span class="ts-2" [class.imnegativebg]="positionDetailData.scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="positionDetailData.scripModel.TickDirection === 1 && flashType === 'defaultFlash'">{{positionDetailData.scripModel.Change | pricepipe:positionDetailData.scripModel.DecimalPrecision}} <small class="ts-1">({{positionDetailData.scripModel.PercentageChange | percentagepipe}})</small></span>
            </div>
        </div>
    </div>
</div>


<div *ngIf = "isInteropsEnabled && positionBreakUpDetailData.list.length>1">
    <div class="row border-b ts-2 py-1">
        <div class="col-12 primetext" translate>all.overall </div>
        <div class="col-6"><span class="subcolor" translate>all.avgprice</span><span class="subcolor">: </span>{{positionBreakUpDetailData.AvgPrc | pricepipe: positionBreakUpDetailData.list[0].scripModel.DecimalPrecision}}</div>
        <div class="col-6 text-right"><span class="subcolor" translate>all.net_qty</span><span class="subcolor">: </span>{{positionBreakUpDetailData.Netqty | numberpipe}}</div>
        
        <div class="col-6"><span class="subcolor" translate>all.avg_buy_price</span><span class="subcolor">: </span>
            <span *ngIf="positionBreakUpDetailData.list.length==1">{{positionBreakUpDetailData.list[0].NetBuyAvgPrc | pricepipe:positionBreakUpDetailData.list[0].scripModel.DecimalPrecision}}</span>
            <span *ngIf="positionBreakUpDetailData.list.length==2 && (positionBreakUpDetailData.list[0].NetBQty != 0 || positionBreakUpDetailData.list[1].NetBQty != 0)">{{((positionBreakUpDetailData.list[0].NetBuyAmt + positionBreakUpDetailData.list[0].NetBuyAmt)/(positionBreakUpDetailData.list[0].NetBQty + positionBreakUpDetailData.list[0].NetBQty)) | pricepipe:positionBreakUpDetailData.list[0].scripModel.DecimalPrecision}} </span>
            <span *ngIf="positionBreakUpDetailData.list.length==2 && positionBreakUpDetailData.list[0].NetBQty == 0 && positionBreakUpDetailData.list[1].NetBQty == 0">{{0 | pricepipe:positionBreakUpDetailData.list[0].scripModel.DecimalPrecision}} </span>
        </div>
        <div class="col-6 text-right"><span class="subcolor" translate>all.avg_sell_price</span><span class="subcolor">: </span>
            <span *ngIf="positionBreakUpDetailData.list.length==1">{{positionBreakUpDetailData.list[0].NetSellAvgPrc | pricepipe:positionBreakUpDetailData.list[0].scripModel.DecimalPrecision}}</span>
            <span *ngIf="positionBreakUpDetailData.list.length==2 && (positionBreakUpDetailData.list[0].NetSQty != 0 || positionBreakUpDetailData.list[1].NetSQty != 0)">{{((positionBreakUpDetailData.list[0].NetSellAmt + positionBreakUpDetailData.list[0].NetSellAmt)/(positionBreakUpDetailData.list[0].NetSQty + positionBreakUpDetailData.list[0].NetSQty)) | pricepipe:positionBreakUpDetailData.list[0].scripModel.DecimalPrecision}} </span>
            <span *ngIf="positionBreakUpDetailData.list.length==2 && positionBreakUpDetailData.list[0].NetSQty == 0 && positionBreakUpDetailData.list[1].NetSQty == 0">{{0 | pricepipe:positionBreakUpDetailData.list[0].scripModel.DecimalPrecision}} </span>
        </div>
    </div>
    <div class="row py-1">
        <div class="col-12 primetext ts-2" translate>all.break_up </div>
        <div class="col-12 sameer m-0">
            <mat-radio-group [(ngModel)]="selectedRadioButtonValue">
                <mat-radio-button value="NSE" (change) = "handleChange('NSE')">NSE</mat-radio-button>
                <mat-radio-button value="BSE" (change) = "handleChange('BSE')">BSE</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>
<div class="sheetscrollarea position-area" [class.position-area-interops] = "isInteropsEnabled && positionBreakUpDetailData.list.length>1">
    <div class="row">
        <div class="col-12">
            <table class="table-bottomsheet">
                <tr>
                    <td width="50%"><span class="namefield" translate>all.net_qty</span></td><td width="50%"> {{positionDetailData.Netqty | numberpipe}}</td>
                </tr>
                <tr>
                    <td width="50%"><span class="namefield" translate>all.net_buy_val</span></td><td width="50%"> {{positionDetailData.NetBuyAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td width="50%"><span class="namefield" translate>all.net_sell_val</span></td><td width="50%"> {{positionDetailData.NetSellAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td width="50%"><span class="namefield" translate>all.net_amt</span></td><td width="50%"> {{positionDetailData.Netamt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.cfqty</span></td><td> {{positionDetailData.CFQty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.prcclose</span></td><td> {{positionDetailData.scripModel.PrvClose | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.fillbuyqty</span></td><td> {{positionDetailData.FillBuyQty | numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.buyavgprc</span></td><td> {{positionDetailData.BuyAvgPrc | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.buyvalue</span></td><td> {{positionDetailData.FillBuyAmt| pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.avgprice</span></td><td> {{positionDetailData.AvgPrc | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.product</span></td><td> {{positionDetailData.Pcode | valuepipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.mtom</span></td><td [class.imnegative]="positionDetailData.MtoM<0" [class.impositive]="positionDetailData.MtoM>0">{{positionDetailData.MtoM | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.fillsellqty</span> </td><td>{{positionDetailData.FillSellQty| numberpipe}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.sellavgprc</span></td><td> {{positionDetailData.SellAvgPrc | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>all.sellvalue</span></td><td> {{positionDetailData.FillSellAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>

                <tr>
                    <td><span class="namefield" translate>CF {{'all.fillbuyqty' | translate}}</span></td><td> {{positionDetailData.CFBuyQty | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>CF {{'all.fillsellqty' | translate}} </span></td><td> {{positionDetailData.CFSellQty | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>CF {{'all.buyavgprc' | translate}}  </span></td><td> {{positionDetailData.CFBuyAvgPrice === null ? '-' : positionDetailData.CFBuyAvgPrice | pricepipe: positionDetailData.scripModel.DecimalPrecision}} </td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>CF {{'all.sellavgprc' | translate}} </span></td><td> {{positionDetailData.CFSellAvgPrice === null ? '-': positionDetailData.CFSellAvgPrice | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>CF {{'all.buyvalue' | translate}} </span></td><td> {{positionDetailData.CFBuyAmt === undefined ? '-' : positionDetailData.CFBuyAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}} </td>
                </tr>
                <tr>
                    <td><span class="namefield" translate>CF {{'all.sellvalue' | translate}} </span></td><td>{{positionDetailData.CFSellAmt === undefined? '-': positionDetailData.CFSellAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</td>
                </tr>
            </table>
        </div>
    </div>
</div>
<div class="row bottomsheet-buttons">
    <div class="col-12">
        <div class="btngroup">
            <!--div [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i>watchlist</div>
                <mat-menu #menu="matMenu">
                    <mat-optgroup label="Select Watchlist" class="watchlist-nav"></mat-optgroup>
                    <ng-container *ngFor = "let data of WlArr">
                        <button mat-menu-item (click) = "addStock(data, positionDetailData)">
                            <span>{{data}}</span>
                        </button>
                    </ng-container>
                </mat-menu-->
                <div (click)="handleButton('ADD')" [class.impositive] = "positionDetailData.Netqty>=0" [class.imnegative] = "positionDetailData.Netqty<0" ><i class="icon-addb"></i>{{'all.add' | translate}}</div>
                <div (click)="handleButton('REMOVE')" *ngIf = "positionDetailData.Pcode !== 'CO' && positionDetailData.Netqty !== 0" [class.imnegative] = "positionDetailData.Netqty>=0" [class.impositive] = "positionDetailData.Netqty<0"><i class="icon-minusb"></i>{{'all.exit' | translate}}</div>
                <div (click)="handleButton('PPC')" *ngIf = "positionDetailData.Netqty !== 0 && positionDetailData.Pcode !== 'CO' && positionDetailData.Pcode !== 'BO'" ><i class="icon-pcb "></i>{{'all.convert' | translate}}</div>
                <div  (click)="handleButton('sd')"><i class="icon-ib"></i>{{'all.detail' | translate}}</div>
        </div>    
    </div>
</div>