import { Component, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '../../../../../../node_modules/@ngx-translate/core';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { ChannelType, Utility } from 'src/app/services/utility';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-positionmoreoption',
    templateUrl: '../../../'+environment.baseviewpath+'/positionbook/positionmoreoption/positionmoreoption.component.html',
    styleUrls: []
})

export class PositionMoreOptionComponent {
    positionDetailData: any;
    netQuantity: any;
    WlArr = [];
    subscription;
    isInteropsEnabled = environment.isInteropsEnabled;
    positionBreakUpDetailData;
    selectedRadioButtonValue = '';
    flashType = 'defaultFlash';
    constructor(private dialog: MatDialog, private _bottomSheetRef: MatBottomSheetRef<PositionMoreOptionComponent>, private router: Router, private snackBar: MatSnackBar,
         private translate: TranslateService, private detector: ChangeDetectorRef, private tradingRestService: TradingRestService) {
        this.positionDetailData = Utility.positionDetail;
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
            this.flashType = Preferences.getItem('flashType');
        }
        Utility.flashType.subscribe(obj => {
            this.flashType = obj;
        });
        if(this.isInteropsEnabled) {
            this.positionBreakUpDetailData = Utility.positionBreakUpDetail;
            this.selectedRadioButtonValue = this.positionBreakUpDetailData.list[0].Exchange;
        }
        this.netQuantity = this.positionDetailData['Netqty'];
        this.subscription = this.positionDetailData.scripModel.scripUpdate.subscribe(ele =>{
            this.detector.markForCheck();
        });
    }

    openLink(): void {
        this._bottomSheetRef.dismiss();
    }
    addWLdata(stock) {
        const seltctedStock = stock.Exchangeseg + '|' + stock.Token;
        this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
    }
    addStock(wl, stockdata) {
        const seltctedStock = stockdata.Exchangeseg + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
          if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
    }
    handleButton(buttonType) {
        switch (buttonType) {
            case 'PPC':
                if (this.netQuantity === '0') {
                    this.snackBar.open(this.translate.instant('all.positionzero'), '', { duration: 2000, panelClass: ['errorSnackBar'] });
                }
                else {
                    this.router.navigate(['/partialpositionconvertion']);
                }
                break;
            case 'ADD':
                const removeobj = { 'remove': true, 'Exchange': this.positionDetailData.Exchange, 'Token': this.positionDetailData.Token, 'OrderType': 'L', 'ProductCode': this.positionDetailData.Pcode};
                const addobj = { 'add': true, 'Exchange': this.positionDetailData.Exchange, 'Token': this.positionDetailData.Token, 'OrderType': 'L', 'ProductCode': this.positionDetailData.Pcode};
                const data_obj1 = (this.positionDetailData.Netqty >= 0) ? addobj : removeobj;
                this.router.navigate(['placeorder'], { queryParams: data_obj1 });
                break;
            case 'REMOVE':
                const removeobj2 = { 'remove': true, 'Exchange': this.positionDetailData.Exchange, 'Token': this.positionDetailData.Token, 'OrderType': 'L', 'ProductCode': this.positionDetailData.Pcode, 'qty': (this.isInteropsEnabled)?this.positionBreakUpDetailData.Netqty:this.positionDetailData.Netqty };
                const addobj2 = { 'add': true, 'Exchange': this.positionDetailData.Exchange, 'Token': this.positionDetailData.Token, 'OrderType': 'L', 'ProductCode': this.positionDetailData.Pcode, 'qty': (this.isInteropsEnabled)?this.positionBreakUpDetailData.Netqty:this.positionDetailData.Netqty };
                const data_obj2 = (this.positionDetailData.Netqty < 0) ? addobj2 : removeobj2;
                this.router.navigate(['placeorder'], { queryParams: data_obj2 });
                break;
            case 'sd':
                Utility.openStockDetail(this.router, this.positionDetailData.Token, this.positionDetailData.Exchangeseg);
                break;
            case 'BUY':
                Utility.openPlaceOrder(this.router, this.positionDetailData.Token, this.positionDetailData.Exchangeseg, 'B', this.dialog);
                break;
            case 'SELL':
                Utility.openPlaceOrder(this.router, this.positionDetailData.Token, this.positionDetailData.Exchangeseg, 'S', this.dialog);
                break;
        }
        this.openLink();
    }

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onSwipeDown() {
        this._bottomSheetRef.dismiss();
    }

    handleChange(event){
        this.selectedRadioButtonValue = event;
        for(let i =0; i< this.positionBreakUpDetailData.list.length; i++){
        if(this.positionBreakUpDetailData.list[i].Exchange === this.selectedRadioButtonValue)
            Utility.positionDetail = this.positionBreakUpDetailData.list[i];
            this.positionDetailData = Utility.positionDetail;
            this.netQuantity = this.positionDetailData['Netqty'];
        }
      }
}
