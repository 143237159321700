export class BasketModel {
    id: number;
    name: string;
    description: string;
    creation_time: string;
    update_time: string;
    status: number;
    enterprise_id: number;
    percent_change: number;
    percentChange30: number;
    percentChange90: number;
    percentChange180: number;
    percentChange365: number;
    oneyrcagr: number;
    twoyrcagr: number;
    threeyrcagr: number;
    fouryrcagr: number;
    fiveyrcagr: number;
    min_investment: number;
    category: string;
    image: string;
    rationale: string;
    basket_setting: string;
    investment_close_on: string;
    investment_period: string;
    risk_factors: string;
    last_review_date: string;
    next_review_date: string;
    base_index_name: string;
    frequency: string;
    sip_enable: boolean;
    sip_types: string;
}
