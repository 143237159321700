import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DragSource, GoldenLayout, LayoutConfig, ResolvedLayoutConfig, Stack, StackItemConfig, ComponentItem } from "golden-layout";
import { GoldenLayoutHostComponent } from '../../../../goldenlayout/golden-layout-host.component';
import { GoldenLayoutComponentService } from '../../../../goldenlayout/golden-layout-component.service';
import { predefinedLayoutNames, predefinedLayouts } from 'src/app/goldenlayout/predefined-layouts';
import { Utility } from 'src/app/services/utility';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ShortcutInput } from "ng-keyboard-shortcuts";
import { TermsComponent } from '../../pages/terms/terms.component';
import { AboutusComponent } from '../../pages/aboutus/aboutus.component';
import { AlertComponent } from '../../alert/alert.component';
import { ChangePwdComponent } from '../../changepassword/changepassword.component';
import { ChangeMpinComponent } from '../../changempin/changempin.component';
import { ProfileComponent } from '../../profile/profile.component';
import { PrivacyComponent } from '../../pages/privacy/privacy.component';
import { ContactComponent } from '../../pages/contact/contact.component';
import { LogsComponent } from '../../logs/logs.component';
import { LotWeightComponent } from '../../lotweight/lotweight.component';
import { GeneralSettingsComponent } from '../../generalsettings/generalsettings.component';
import { UpdateuserinfoComponent } from '../../updateuserinfo/updateuserinfo.component';
import { environment } from 'src/environments/environment';
import { PwaService } from 'src/app/services/pwa.service';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker'; 
import { interval } from 'rxjs';
import { KeyboardShortcutsComponent } from '../../keyboardshortcuts/keyboardshortcuts.component';
import { TableUtility } from 'src/app/services/tableutility';
import { MyAlertDialogComponent } from '../watchlist/watchlist.component';
import { PlaceorderComponent } from '../../placeorder/placeorder.component';
import { OrderdetailComponent } from '../../orderdetail/orderdetail.component';
import { PartialPositionConvertionComponent } from '../../partialpositionconvertion/partialpositionconvertion.component';
import { PositiondetailComponent } from '../../positiondetail/positiondetail.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { UsertodayssipComponent } from '../../thematicbasket/usertodayssip/usertodayssip.component';
import { LinkComponent } from '../../link/link.component';


@Component({
  selector: 'app-goldencontrols',
  templateUrl: '../../../'+environment.baseviewpath+'/home/goldencontrols/goldencontrols.component.html'
})
export class GoldencontrolsComponent implements AfterViewInit, OnDestroy, OnChanges, OnInit {
  public _goldenLayoutHostComponent: GoldenLayoutHostComponent[] =[];
  private _goldenLayout: GoldenLayout[] = [];
  private deleteWorkSpace = false;
  private _selectedRegisteredComponentTypeName: string;
  private _selectedLayoutName: string;
  private _dragSources: Array<DragSource | undefined> = [];
  public registeredComponentTypeNames: readonly string[];
  public initialRegisteredComponentTypeName: string;
  public layoutNames: readonly string[];
  public initialLayoutName: string;
  public saveLayoutButtonDisabled = true;
  @Input() stockInput : any;
  @Input() chartInputs : any;
  @Input() isLoad: boolean;
  contactList : number[];
  isIAM = environment.isIAM;
  version;
  isNotificationEnabled;
  isUpdateAvailable;
  filterComponents: {};
  shortcutsMap;
  balance;
  addFundsFrom;
  basketFeature = environment.BasketFeature;
  isTriggerenable = environment.isGTTEnable;
  isSpreadOrderenable = environment.enableSpreadOrder;
  shortcuts: ShortcutInput[] = [];
  loginId = Preferences.getPreference('userid');
  accountId = Preferences.getPreference('accountId');
  actName = Preferences.getPreference('actName');
  inputValue = '';
  components = {
  "Reports":[{"cls":1,"val":"Order Book"},{"cls":1,"val":"Trade Book"},{"cls":1,"val":"Position Book"},{"cls":1,"val":"Holding Book"}],"Funds":[{"cls":1,"val":"Funds"}], "Trade":[{"cls":1,"val":"Investment Boxes"},{"cls":1, "val":"GTT"}],
  "News & Events":[{"cls":1,"val":"General News"},{"cls":1,"val":"Equity News"},{"cls":1,"val":"Derivative News"},{"cls":1,"val":"Events"},{"cls":1,"val":"Exchange Message"}],
  "Stock":[{"cls":1,"val":"Stock Depth"},{"cls":1,"val":"Chart"},{"cls":1,"val":"Stock Technicals"},{"cls":1,"val":"Stock News"}],
  "Market":[{"cls":1,"val":"WatchList"},{"cls":1,"val":"Top Indices"},{"cls":1,"val":"Advances & Declines"},{"cls":1,"val":"Top Gainer/Losers"},{"cls":1,"val":"Most Active Volume/Value"},{"cls":1,"val":"Market Status"},{"cls":1,"val":"Option Chain"}],
  "Spread Order":[{"cls":1,"val":"Spread Order Book"}],
  "Tools & Calculator":[{"cls":1,"val":"Option Calculator"}],
  "Scanner":[{"cls":1,"val":"Resistance & Support"},{"cls":1,"val":"Volume Shocker"},{"cls":1,"val":"Circuit Breakers"},{"cls":1,"val":"High/Low Breakers"},{"cls":1,"val":"Open High/Low"},{"cls":1,"val":"Technical Scanners"}],
  };
  hmap = { "Reports": true, "Funds": true, "News & Events": true, "Stock": true, "Market": true, "Tools & Calculator": true, "Scanner": true, "Trade":true, "Spread Order": true };
  componentslist = ['Order Book', 'Trade Book', 'Position Book', 'Holding Book', 'Funds', 'Investment Boxes', 'GTT', 'News', 'Equity News',
                    'Derivative News', 'Events', 'Exchange Message','Stock Depth','Chart', 'Stock Technicals','Stock News', 'WatchList', 'Top Indices',
                    'Advances & Declines', 'Top Gainer/Losers', 'Most Active Volume/Value', 'Market Status', 'Option Chain', 'Spread Order Book', 'Option Calculator',
                    'Resistance & Support', 'Volume Shocker', 'Circuit Breakers', 'High/Low Breakers', 'Open High/Low', 'Technical Scanners']; 
  cmap = {
    'terms' :{'h':'90vh','w':'40vw','comp':TermsComponent},
    'privacy' :{'h':'90vh','w':'40vw','comp':PrivacyComponent},
    'about' :{'h':'45vh','w':'30vw','comp':AboutusComponent},
    'contact' :{'h':'82vh','w':'50vw','comp':ContactComponent},
    'alert' :{'h':'70vh','w':'500px','comp':AlertComponent},
    'profile' :{'h':'580px','w':'500px','comp':ProfileComponent},
    'changepwd' :{'h':'580px','w':'500px','comp':ChangePwdComponent},
    'changempin' :{'h':'580px','w':'500px','comp':ChangeMpinComponent},
    'logs' :{'h':'580px','w':'500px','comp':LogsComponent},
    'lotweight' :{'h':'580px','w':'500px','comp':LotWeightComponent},
    'generalsettings' :{'h':'480px','w':'500px','comp':GeneralSettingsComponent},
    'keyboardshortcuts' :{'h':'80vh','w':'80vw','comp':KeyboardShortcutsComponent},
    'userinfo' :{'h':'480px','w':'500px','comp':UpdateuserinfoComponent}
    }
  objectKeys = Object.keys;
  objectVal = Object.values
  @ViewChildren('dragComponentList1') dragComponentList1: QueryList<any>;
  @ViewChildren('dragComponentList2') dragComponentList2: QueryList<any>;
  @ViewChildren('dragComponentList3') dragComponentList3: QueryList<any>;
  @ViewChildren('dragComponentList4') dragComponentList4: QueryList<any>;
  @ViewChildren('dragComponentList5') dragComponentList5: QueryList<any>;
  isfromOCSearch = false;
  fromNews = false;
  formWL: boolean;
  formTopSearch: boolean;
  overlaysearch = false;
  overlaycounter = 0;
  isfromMWLSearch: boolean;
  overlayMWL = false;
  overlayadd = false;
  overlaySelllist = false;
  overlaywithdraw = false;
  overlaytrans = false;
  overlayManageBooks = false;
  formCustomDB: boolean;
  manageBooksPageName: any;
  isManageCol = true;
  overlayNotification: boolean = false;
  isLoading: any;
  fcmNotificationList: Array<{id: String, obj: object}>;
  watchlistSettingLabelSelected = 'mangewatchlist';
  wlName = ' ';
  overlayBasketDetail = false;
  overlayUserBasket = false;
  isStockDetail = false;
  stockSelected = [];
  utility: any;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  isItemDestroyed = [false,false,false,false,false,false,false,false,false,false,false];
  backOfficeType = environment.backOfficeType;
  ticketSupport = environment.ticketSupport;
  loadArr = [true, true, true, true, true, true, true, true, true, true, true];

  get element() {
    return this._elRef.nativeElement;
  }
  
  constructor(private _elRef: ElementRef<HTMLElement>, private router: Router, private dialog: MatDialog, private _goldenLayoutComponentService: GoldenLayoutComponentService,  public Pwa: PwaService, private tradingRestService: TradingRestService, private translate: TranslateService, private snackBar: MatSnackBar, private update: SwUpdate,private appRef: ApplicationRef, private cdr: ChangeDetectorRef, private PS: PtPlusRestService) {
    this.filterComponents=this.components;
    this.contactList = environment.contactList;
    this.version = environment.version;
    this.isNotificationEnabled = environment.isNotificationEnabled;
    this.isUpdateAvailable = false;
    this.update.available.subscribe((event) => {
      this.isUpdateAvailable = this.update.isEnabled;
    });
    this.update.activated.subscribe((event) => {
      this.isUpdateAvailable = false;
    });
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(8 * 60 * 60 * 1000);
        timeInterval.subscribe(() => {
          this.update.checkForUpdate().then(() => console.log('checked'));
        });
      }
    });
    Utility.InputsDataGet.subscribe(data=>{
      this.InputsData(data);
    });
    if(environment.baseviewpath === 'investor'){
      this.PS.getThematicBasketService().setTradingRestService(tradingRestService);
      this.getUserTodaysSip();
    }
    let isReload = this.router.getCurrentNavigation().extras.state?.isReload;
    if(isReload){
      window.location.reload();
    }
  }

/* new clean code start*/
addWorkspace(action = undefined,tag = undefined) {
  Utility.saveLayoutArr[Utility.selectedWorkspace] = this._goldenLayout[Utility.selectedWorkspace].saveLayout();
  let free = Utility.addWorkSpace(false);
  if(free !== -1) {
    Utility.inputproplist[Utility.selectedWorkspace] = {};
    const selectedLayout = predefinedLayouts[1];
    if(tag) {
      this._goldenLayoutHostComponent[free].tagSymbol = tag;
    }
    this._goldenLayout[free].loadLayout(selectedLayout.config);    
    Utility.saveLayoutArr[Utility.selectedWorkspace] = this._goldenLayout[free].saveLayout();
    if(action) {
      setTimeout(()=>{
        this.addComponentWorkspace(action); 
      },10);
    }
  }
  else {
    this.snackBar.open(this.translate.instant('dashboard.workspace_add_error'), '', { duration: 3000 });
  }
  Utility.saveWorkspace();
}

deleteWorkspace(layout, isStock) {
  if(layout!==0){
    this.deleteWorkSpace = true;
    this.loadArr[layout] = true;
    let newWork = Utility.deleteWorkSpace(layout, isStock);
    Utility.inputproplist[layout] = {}; 
    Utility.workspaceMap[layout] = [];
    Utility.saveLayoutArr[layout] ={};
    Utility.saveWorkspace();  
    this._goldenLayout[layout].clear();  
    this.loadWorkSpace(newWork, (newWork>5)?true:false);
  }
  else {
    this.snackBar.open(this.translate.instant('dashboard.home_delete'), '', { duration: 3000 });
  }
}

loadWorkSpace(index, isStock = false) {
  Utility.loadWorkSpace(index,isStock);
  if(!this.loadArr[index]){
    const savelayoutarr = Preferences.getItem('w_save', ReturnType.Null);
    this.loadArr[index] = true;
    if(isStock) {
        Utility.inputproplist[Utility.selectedWorkspace] = {};
        const selectedLayout = predefinedLayouts[2];
        this._goldenLayoutHostComponent[index].setStockData = Utility.StockKeyMap[index];
        setTimeout(()=>{
          this._goldenLayout[index].loadLayout(selectedLayout.config);    
        },10);
    }
    else {
      if(index === 0){
        Utility.selectedWorkspace = 0;
        Utility.workspaceMap[0] = ['WatchList', 'Order Book', 'Position Book', 'Holding Book', 'Funds'];
        const selectedLayout = predefinedLayouts[0];
        this._goldenLayout[0].loadLayout(selectedLayout.config);
        Utility.saveLayoutArr[0] = this._goldenLayout[0].saveLayout();
      } else {
        setTimeout(()=>{
          this._goldenLayout[index].loadLayout(savelayoutarr[index]);
        },10);
      }
    }
  }
  
  Utility.saveWorkspace();
}

addComponentWorkspaceWithAdd(comName, ev?){
  this.addWorkspace(comName);
}

addComponentWorkspace(comName, ev?) {
  let compName = comName;
  if(ev != undefined)
    ev.stopPropagation();
  if(comName == "Buy Order")
    this.openPlaceOrder('B');
  else if(comName == "Sell Order")
    this.openPlaceOrder('S');  
  else{
    if(comName == "General News") {
      comName = "News";
    }
    if(Utility.selectedWorkspace === 0 || Utility.selectedWorkspace > 5) {
        if (Utility.workspaceMap[Utility.selectedWorkspace].indexOf(comName) !== -1 && Utility.selectedWorkspace <= 5) {
          this.selectTab(comName); 
        } else {
          if (Utility.selectedWorkspace <= 5)
            this.snackBar.open(this.translate.instant('dashboard.cant_com_add_one'), '', { duration: 3000 });
          else
            this.snackBar.open(this.translate.instant('dashboard.cant_add'), '', { duration: 3000 });
        }
    } else {
        if (Utility.workspaceMap[Utility.selectedWorkspace].indexOf(comName) !== -1) {
          this.snackBar.open(this.translate.instant('dashboard.cant_com_add'), '', { duration: 3000 });
        } else {
          //Utility.fromNewWorkspace = false;
          Utility.workspaceMap[Utility.selectedWorkspace].push(compName);
          this._goldenLayout[Utility.selectedWorkspace].addComponent(comName);
          Utility.saveLayoutArr[Utility.selectedWorkspace] = this._goldenLayout[Utility.selectedWorkspace].saveLayout();
        }
    }
  }
}

selectTab(tabName: string){
  let item = <Stack>this._goldenLayout[Utility.selectedWorkspace].rootItem.contentItems[1].contentItems[0];
  if ( item.type === 'stack'){
    for(let i = 0; i < item.contentItems.length; i++) {
      let ci = <ComponentItem>item.contentItems[i];
      if(ci.title === tabName) {
        item.setActiveComponentItem(ci, true); 
      }
    }
  }
}

ngOnInit(){
  this.utility = Utility;
}

setGoldenLayoutHostComponent(value0: GoldenLayoutHostComponent, value1: GoldenLayoutHostComponent, value2: GoldenLayoutHostComponent, value3: GoldenLayoutHostComponent, value4: GoldenLayoutHostComponent, value5: GoldenLayoutHostComponent, value6: GoldenLayoutHostComponent, value7: GoldenLayoutHostComponent, value8: GoldenLayoutHostComponent, value9: GoldenLayoutHostComponent, value10: GoldenLayoutHostComponent) {
  setTimeout(() => {
    this._goldenLayoutHostComponent.push(value0);
    this._goldenLayoutHostComponent.push(value1);
    this._goldenLayoutHostComponent.push(value2);
    this._goldenLayoutHostComponent.push(value3);
    this._goldenLayoutHostComponent.push(value4);
    this._goldenLayoutHostComponent.push(value5);
    this._goldenLayoutHostComponent.push(value6);
    this._goldenLayoutHostComponent.push(value7);
    this._goldenLayoutHostComponent.push(value8);
    this._goldenLayoutHostComponent.push(value9);
    this._goldenLayoutHostComponent.push(value10);
    this._goldenLayoutHostComponent[0].myWork = 0;
    this._goldenLayout.push(this._goldenLayoutHostComponent[0].goldenLayout);  
    if(environment.baseviewpath !== 'investor'){
      for(let i=1;i<=10;i++){
        this._goldenLayoutHostComponent[i].myWork = i;
        this._goldenLayout.push(this._goldenLayoutHostComponent[i].goldenLayout);
      }
    }
    this.initialiseControls();
    if(environment.baseviewpath !== 'investor'){
      this._goldenLayout[1].on('itemDestroyed', function(val) {
        this.itemDestroyed(val,1);
      }.bind(this)); 
      this._goldenLayout[2].on('itemDestroyed', function(val) {
        this.itemDestroyed(val,2);
      }.bind(this)); 
      this._goldenLayout[3].on('itemDestroyed', function(val) {
        this.itemDestroyed(val,3);
      }.bind(this)); 
      this._goldenLayout[4].on('itemDestroyed', function(val) {
        this.itemDestroyed(val,4);
      }.bind(this)); 
      this._goldenLayout[5].on('itemDestroyed', function(val) {
        this.itemDestroyed(val,5);
      }.bind(this)); 
    }
  },1);
}

private initialiseControls() {
  this.registeredComponentTypeNames = this._goldenLayoutComponentService.getRegisteredComponentTypeNames();
  this._selectedRegisteredComponentTypeName = this.registeredComponentTypeNames[0];
  this.initialRegisteredComponentTypeName = this._selectedRegisteredComponentTypeName;
  this.layoutNames = predefinedLayoutNames;
  this._selectedLayoutName = this.layoutNames[0];
  this.initialLayoutName = this._selectedLayoutName;
  if(environment.baseviewpath !== 'investor'){
    this.initialiseDragSources();
  }
  this.handleLoadLayoutButtonClick();
}

private initialiseDragSources() {
  for(let i =0; i< this.componentslist.length; i++){
    this.loadDragSource(this.componentslist[i], this.componentslist[i], this.dragComponentList1['_results'][i],1);
    this.loadDragSource(this.componentslist[i], this.componentslist[i], this.dragComponentList2['_results'][i],2);
    this.loadDragSource(this.componentslist[i], this.componentslist[i], this.dragComponentList3['_results'][i],3);
    this.loadDragSource(this.componentslist[i], this.componentslist[i], this.dragComponentList4['_results'][i],4);
    this.loadDragSource(this.componentslist[i], this.componentslist[i], this.dragComponentList5['_results'][i],5);
  }
}

private loadDragSource(title: string, componentName: string, element: ElementRef | undefined, index): void {
  const config = () => {
    const item: DragSource.ComponentItemConfig = {
      state: undefined,
      title,
      type: componentName,
    };
    return item;
  };
  
  this._dragSources.push(this._goldenLayout[index].newDragSource(element?.nativeElement, config));
}
handleLoadLayoutButtonClick() {
  const savelayoutarr = Preferences.getItem('w_save', ReturnType.Null);
  const selectedworkspace = Preferences.getItem('w_sel', ReturnType.Null);
  const stockLayout =  Preferences.getItem('w_stk', ReturnType.Null);
  const WorkspaceMap = Preferences.getItem('w_map', ReturnType.Null);
  const inputPropList = Preferences.getItem('w_iplist', ReturnType.Null);
  const WorkspaceArr = Preferences.getItem('w_warr', ReturnType.Null);
  const StockDetailArrs = Preferences.getItem('w_sarr', ReturnType.Null);
  const StockKeyMap = Preferences.getItem('w_skmap', ReturnType.Null);
  const StockListArr = Preferences.getItem('w_slarr', ReturnType.Null);
  const selectedLayoutIndex = (environment.baseviewpath === 'investor') ? 3 : 0;
  const selectedLayout = predefinedLayouts[selectedLayoutIndex];
  if ( environment.baseviewpath !== 'investor' ) {
  // need to check
    if (savelayoutarr != null){
      Utility.selectedWorkspace = selectedworkspace;
      Utility.saveLayoutArr = savelayoutarr;
      Utility.workspaceMap = WorkspaceMap;
      Utility.inputproplist = inputPropList;
      Utility.WorkspaceArr = WorkspaceArr;
      Utility.StockDetailArrs = StockDetailArrs;
      Utility.StockKeyMap = StockKeyMap;
      Utility.StockListArr = StockListArr;
      for(let i=0;i<Utility.WorkspaceArr.length;i++){
        this.loadArr[Utility.WorkspaceArr[i]] = false;
        Utility.BufferMap[Utility.WorkspaceArr[i]+''] = true;
      }
      for(let i=0;i<Utility.StockDetailArrs.length;i++){
        this.loadArr[Utility.StockDetailArrs[i]] = false;
        Utility.BufferMap[Utility.StockDetailArrs[i]+''] = true;
      }
      this.loadWorkSpace(Utility.selectedWorkspace, (Utility.selectedWorkspace>5)?true:false);
    } else {
      Utility.selectedWorkspace = 0;
      Utility.workspaceMap = {0: ['WatchList', 'Order Book', 'Position Book', 'Holding Book', 'Funds'], 1: [], 2: [], 3: [], 4: [], 5:[]};
      this._goldenLayout[0].loadLayout(selectedLayout.config);
      Utility.saveLayoutArr[0] = this._goldenLayout[0].saveLayout();          
    }
  } else {
      this._goldenLayout[0].loadLayout(selectedLayout.config);
      Utility.saveLayoutArr[0] = this._goldenLayout[0].saveLayout(); 
  }
}

// for stock detail

openStokDetail() {
  if(Utility.SELECTED_SCRIP != undefined) {
    const obj = {'symbol': Utility.SELECTED_SCRIP.TradingSymbol, 'token': Utility.SELECTED_SCRIP.Token, 'exch' : Utility.SELECTED_SCRIP.ExchangeSegment}
    this.openStockDetail(obj);
  }
}

openStockDetail(data){
  if(typeof(data) === 'string'){
    data = JSON.parse(data);
  }

  for(let i=0;i<Utility.StockDetailArrs.length;i++){
    let element =Utility.StockDetailArrs[i];
    if(Utility.StockKeyMap[element]['token'] && Utility.StockKeyMap[element]['token'] === data.token) {
      this.loadWorkSpace(element,true);
      return;
    }
  }
  Utility.saveLayoutArr[Utility.selectedWorkspace] = this._goldenLayout[Utility.selectedWorkspace].saveLayout();
  let free = Utility.addWorkSpace(true);
  if(free !== -1)
  {
    Utility.inputproplist[Utility.selectedWorkspace] = {};
    Utility.selectedWorkspace = free;
    const selectedLayout = predefinedLayouts[2];
    this._goldenLayoutHostComponent[free].setStockData = data;
    setTimeout(()=>{
      this._goldenLayout[free].loadLayout(selectedLayout.config);    
    },10);
    if(data.exch === 'bse_cm')
      data.symbol = data.symbol+'('+data.series+')';
    Utility.StockKeyMap[free] = data;
    Utility.saveLayoutArr[Utility.selectedWorkspace] = this._goldenLayout[free].saveLayout();
    Preferences.setItem('w_stk',this.stockSelected);
  }
  else{
    this.snackBar.open(this.translate.instant('dashboard.workspace_add_error'), '', { duration: 3000 });
  }  
}

openPlaceOrder(buttontype, typ = 0) {
  if(Utility.isPlaceorderOpen)
    Utility.changeTranctionType.next(buttontype);
  else
  {
    if(Utility.SELECTED_SCRIP != undefined && typ == 0) {  
      Utility.openPlaceOrder(this.router, Utility.SELECTED_SCRIP.Token, Utility.SELECTED_SCRIP.ExchangeSegment, buttontype, this.dialog);
    } else {
      Utility.openPlaceOrder(this.router, 'asdfg', 'nse_cm', buttontype, this.dialog);
    }
  }
}

syncMe(){
  Preferences.serverSync(this.tradingRestService);
}

openTicket() {
  window.open('https://thriveadvisors.freshdesk.com/support/tickets/new','_blank');
}

openBackOffice(){
  if(environment.backOfficeSecType == 0){
    this.tradingRestService.getUserService().openBackOffice(this.backOfficeCallback.bind(this));
  }
  else {
    this.dialog.open(LinkComponent, {
      data: {
        url: environment.backOfficeUrl,
      },
    });
  }
}

backOfficeCallback(code,msg,data){
  let url = environment.backOfficeUrl+'shrdbms/userlogin.ss?login=silent&token='+data;
  this.dialog.open(LinkComponent, {
    data: {
      url: url,
    },
  });
}

getUserTodaysSip(){
  this.PS.getThematicBasketService().getUserTodaySip(function(data, code, message){
    if(code === 200 && message === "SUCCESS"){
      Utility.todaysSipData = data;
    }
  }.bind(this));
}

filterData(val) {
  const asArray = Object.entries(this.components);
  this.filterComponents = this.components;
  asArray.forEach(([key, value]) => {
    let header = false;
    value.forEach((data) => {
      data.cls = (data.val.toLowerCase().includes(val.toLowerCase()) || (data.val+'s').toLowerCase().includes(val.toLowerCase()))?1:0;
      if(data.cls == 1){
        header = true;
      }
    });
    this.hmap[key] = header;
  });
}

onLogout() {
  Preferences.setPreference('checkSession', 'true');
  Utility.logoutPopup(this.router,  this.translate, this.tradingRestService, this.dialog, 'customDashboard', this.snackBar);
}

openPopupsPage(cname) {
  Utility.openPage(this.router, 'header', cname, this.cmap[cname]['comp'], this.dialog, 'popup', {}, this.cmap[cname]['h'], this.cmap[cname]['w']);
}

openPopups(cname) {
  Utility.dialogRef = this.dialog.open(this.cmap[cname]['comp'], { disableClose: false, height: this.cmap[cname]['h'], width: this.cmap[cname]['w'],  autoFocus: false});
}

checkUpdate() {
  this.update.activateUpdate().then(() => location.reload());
}

installApp() {
  Utility.installApp.next();
}

watchlistAction(arrowType) {
  Utility.watchlistAction.next(arrowType);
}

opeSideMenu() {
  this.menuTrigger.openMenu();
}

openSearch(flag, searchtype){
   this.isfromOCSearch = false;
   this.fromNews = false;
   this.formWL = false;
   this.isfromMWLSearch = false;
   this.formTopSearch = false;
   Utility.isDialogOpened = true;
   this.overlaysearch = true;
   this.overlaycounter++;
  switch(searchtype) {
    case 1:
      if (flag) {
        this.isfromMWLSearch = false;
        this.formWL = false;
      } else {
        this.formWL = true;
      }
      this.formTopSearch = flag;
      break;
    case 2:
      this.fromNews = flag;
      break;
    case 3:
      this.isfromOCSearch = true;
      break;
  }

}

closeSearch(){
  if (this.formWL) {
    Utility.watchlistData.next(true);
  } 
  else if (this.isfromMWLSearch) {
    Utility.watchlistDataFromMWL.next(true);
  } 
  else if (this.isfromOCSearch) {
    Utility.callOptionCalculator.next(true);
  } 

  this.overlaysearch = false;
  Utility.isDialogOpened = false;
}

openWL(flag){
  Utility.isDialogOpened = true;
  this.overlayMWL = true;
  this.formCustomDB = true;
  this.overlaycounter++;
}

closeSL(){
  this.overlaySelllist=false;
  Utility.isDialogOpened = false;
}

closeMWL(){
  this.overlayMWL = false;
  Utility.isDialogOpened = false;
}

closeFunds(){
  this.overlayadd = false;
  this.overlaywithdraw = false;
  this.overlaytrans = false;
  Utility.isDialogOpened = false;
  Utility.addFundsMainPage.next();
}

closeBasketDetail(){
  this.overlayBasketDetail = false;
  Utility.isDialogOpened = false;
}
closeUserBasket(){
  this.overlayUserBasket = false;
  Utility.isDialogOpened = false;
}

openManageBooks(data) {
  this.manageBooksPageName = data;
  this.overlayManageBooks = true;
  this.overlaycounter++;
}

closeManageBooks() {
  this.overlayManageBooks = false;
}

limitLoader(event: any){
  this.isLoading = event;
  this.cdr.detectChanges();
}

watchlistSelectedChip(watchlistsettingLabelSelected) {
  this.watchlistSettingLabelSelected = watchlistsettingLabelSelected;
}

closePopup() {
  Utility.getColInWL.next();
  this.closeMWL();
}

addStockToNewWL(data) {
  data = JSON.parse(data);
  this.closeMWL();
  this.formWL = false;
  this.isfromMWLSearch = true;
  this.wlName = data.wlname;
  this.openSearch(false,1);
}

InputsData(data) {
  if(data.name === 'Funds') {
    this.selectTab('Funds');     
  } 
  
  if(data.name === 'Investment Boxes' && Utility.selectedWorkspace === 0 && environment.baseviewpath === 'investor') {
    this.selectTab('Investment Boxes');     
  }

  if(data.name == "addStock" && data.inputs !== undefined) {
    this.wlName = data.inputs.wlname;
    this.formWL = data.inputs.isfromWL;
    this.openSearch(false,1); 
  }

  if(data.name == "manageWList" && data.inputs !== undefined) {
    this.isManageCol = data.inputs;
    if(!this.isManageCol)
      Utility.watchlistSettingOpen.next('mangewatchlist');
    else
      Utility.watchlistSettingOpen.next(this.watchlistSettingLabelSelected);
      this.openWL(false);
  }

  if(data.name == "optionCal" && data.inputs !== undefined) {
    this.openSearch(data.inputs, 3);
  }

  if(data.name == "news" && data.inputs !== undefined) {
    this.openSearch(data.inputs, 2);
  }

  if(data.name == "addFunds" && data.inputs !== undefined) {
    this.openAddFunds(data.inputs)
  }

  if(data.name== "buy") {
    this.openSearch(true,1);
  }
 
  if(data.name == "sell" ) {
    this.overlaySelllist=true;
    this.overlaycounter++;
  }
  if(data.name === 'basketdetail') {
    this.overlayBasketDetail = true;
    this.overlaycounter++;
  }
  if(data.name === 'userbaskethome') {
    this.overlayUserBasket = true;
    this.overlaycounter++;
  }
  if(data.name === 'managecolumn') {
    this.openManageBooks(data.inputs);
  }
}

openAddFunds(data) {
  this.balance = data.bal;
  if (data.btnindex === 1) {
    this.overlayadd = true;
    this.addFundsFrom = data.overlayFrom;
  } else if (data.btnindex === 2) {
    this.overlaywithdraw = true;
  } else {
    Utility.transactionRefresh.next();
    this.overlaytrans = true;
  }
}

openComponentUsingShortcut(component, i) {
  if (Utility.selectedWorkspace !== 0 && Utility.selectedWorkspace <= 5) {
    this.addComponentWorkspace(component);
  } else {
    if (Utility.workspaceMap[Utility.selectedWorkspace].indexOf(component) !== -1 && Utility.selectedWorkspace <= 5) {
      Utility.selectWLTab.next(component);
      this.selectTab(component);
      
    } else {
      if (Utility.selectedWorkspace <= 5)
        this.snackBar.open(this.translate.instant('dashboard.cant_com_add_one'), '', { duration: 3000 });
      else
        this.snackBar.open(this.translate.instant('dashboard.cant_add'), '', { duration: 3000 }); 
    }
  }
}

orderBookAction(buttonType, omo) {
  if(Utility.selectedTab === TableUtility.orderBook) {
    Utility.detailedOrder = omo;
    let alertMessage = '';
    switch (buttonType) {
      case 'E':
        if (omo.IsExit) {
          alertMessage = 'Do you want to exit the order?';
          Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('alert.exit_hed'), message: alertMessage } });
          Utility.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
              this.tradingRestService.getBookService().ExitOrder(omo, function(responsedata, code, message) {
                if (responsedata['stat'] === 'Ok') {
                  Utility.webReloadComponent.next('Order Book');
                }
              }.bind(this));
            }
          });
        }
        else
          this.snackBar.open(this.translate.instant('stock_detail.cant_exit_msg'), '', { duration: 3000 });
        break;
      case 'C':
        if(Utility.canCancel(omo)) {
          alertMessage = 'Do you want to cancel ' + omo.SymbolName + ' order?';
          Utility.dialogRef = this.dialog.open(MyAlertDialogComponent, { data: { heading: this.translate.instant('alert.cancel_hed'), message: alertMessage, fromOrder: true } });
          Utility.dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm') {
              this.tradingRestService.getBookService().CancelOrder(omo, function(responsedata, code, message)  {
                if (responsedata['stat'] === 'Ok') {
                  Utility.webReloadComponent.next('Order Book');
                }
              }.bind(this));
            }
          });
        }
        else {
          this.snackBar.open(this.translate.instant('stock_detail.cant_cancel_msg'), '', { duration: 3000 });
        }
        break;
      case 'M':
        if(omo.IsEdit) {
          const data_obj = { 'isEdit': true };
          Utility.openPage(this.router, 'orderhistory', 'placeorder', PlaceorderComponent, this.dialog, 'popup', data_obj, '500px', '1250px');
        }
        else {
          this.snackBar.open(this.translate.instant('stock_detail.cant_modify_msg'), '', { duration: 3000 });
        }
        break;
      case 'H':
        Utility.openPage(this.router, 'order', 'orderdetail', OrderdetailComponent, this.dialog, 'popup');
        break;
    }
  }
}

positionBookAction(buttonType, pmo) {
  if (Utility.selectedTab === TableUtility.positionBook) {
    Utility.positionDetail = pmo;
    switch (buttonType) {
        case 'I':
            Utility.openPage(this.router, 'position', 'positiondetail', PositiondetailComponent, this.dialog, 'popup');
            break;
        case 'PPC':
          if(pmo.Netqty == 0 || pmo.Pcode == 'CO' || pmo.Pcode == 'BO' ){
            this.snackBar.open(this.translate.instant('all.partial'), '', { duration: 3000 });
          }
          else{
            Utility.openPage(this.router, 'position', 'partialpositionconvertion', PartialPositionConvertionComponent, this.dialog, 'popup',{},  '200px', '500px');
          }
          break;
    }
  }
}

openSpreadPlaceOrder(buttontype, typ = 0) {
  if(Utility.isPlaceorderOpen)
    Utility.changeTranctionType.next(buttontype);
  else
  {
    if(Utility.SELECTED_SCRIP != undefined && typ == 0) {  
      Utility.openSpreadPlaceOrder(this.router, Utility.SELECTED_SCRIP.Token, Utility.SELECTED_SCRIP.ExchangeSegment, buttontype, this.dialog);
    } else {
      Utility.openSpreadPlaceOrder(this.router, 'asdfg', 'nse_cm', buttontype, this.dialog);

    }
  }
}

ngAfterViewInit() {
  this.shortcutsMap = Utility.shortcutsMap;

  this.shortcuts.push({
      key: 'ctrl + shift + s', // Search
      command: (e) => { this.openSearch(true,1); },
      preventDefault: true
    },
    {
      key: ['ctrl + f1'], // AMO Buy Order
      command: (e) => { this.openPlaceOrder('AB'); },
      preventDefault: true
    },
    {
      key: ['ctrl + f2'], // AMO Sell Order
      command: (e) => { this.openPlaceOrder('AS'); },
      preventDefault: true
    },
    {
      key: ['f1','shift + plus','plus'], // Buy Order
      command: (e) => { this.openPlaceOrder('B'); },
      preventDefault: true
    },
    {
      key: ['f2','-'], // Sell Order
      command: (e) => { this.openPlaceOrder('S'); },
      preventDefault: true
    },
    {
      key: ['ctrl + shift + F3'], // Spread Order Book
      command: (e) => { this.openComponentUsingShortcut('Spread Order Book', 3); },
      preventDefault: true
    },
    {
      key: ['f3', 'ctrl + o'], // Order Book
      command: (e) => { this.openComponentUsingShortcut('Order Book', 3); },
      preventDefault: true
    },
    {
      key: ['f4', 'ctrl + m'], // Watchlist
      command: (e) => { this.openComponentUsingShortcut('WatchList', 3); },
      preventDefault: true
    },
    {
      key: 'shift + F1', // Cancel Order
      command: (e) => { this.orderBookAction('C',Utility.SELECTED_ORDER); },
      preventDefault: true
    },
    {
      key: 'shift + F2', // Modify Order
      command: (e) => { this.orderBookAction('M',Utility.SELECTED_ORDER); },
      preventDefault: true
    },
    {
      key: 'shift + F11', // Exit Order
      command: (e) => { this.orderBookAction('E',Utility.SELECTED_ORDER); },
      preventDefault: true
    },
    {
      key: 'shift + F12', // Order Detail
      command: (e) => { this.orderBookAction('H',Utility.SELECTED_ORDER); },
      preventDefault: true
    },
    {
      key: 'shift + F5', // Product Convert
      command: (e) => { this.positionBookAction('PPC',Utility.SELECTED_POSITION); },
      preventDefault: true
    },
    {
      key: 'shift + F7', // Position Detail
      command: (e) => { this.positionBookAction('I',Utility.SELECTED_POSITION); },
      preventDefault: true
    },
    {
      key: ['f10', 'ctrl + l'], // Logout
      command: (e) => { this.onLogout(); },
      preventDefault: true
    },
    {     
      key: 'alt + F9', // Holding Book
      command: (e) => { this.openComponentUsingShortcut('Holding Book', 3); },
      preventDefault: true
    },
    {
      key: ['alt + f6', 'ctrl + p'], // Position Book
      command: (e) => { this.openComponentUsingShortcut('Position Book', 3); },
      preventDefault: true
    },
    {
      key: 'ctrl + shift + u', // Funds
      command: (e) => { this.openComponentUsingShortcut('Funds',3); },
      preventDefault: true
    },       
    {
      key: ['f6', 'ctrl + d'], // Stock Detail
      command: (e) => { this.openStokDetail(); },
      preventDefault: true
    },
    {
      key: 'shift + up', // Change Watchlist Upwards
      command: (e) => { this.watchlistAction('UP'); },
      preventDefault: true
    },
    {
      key: 'shift + down', // Change Watchlist Downwards
      command: (e) => { this.watchlistAction('DOWN'); },
      preventDefault: true
    },
    {
      key: ['ins', 'ctrl + i'], // Add Stock in watchlist
      command: (e) => { this.watchlistAction('PLUS'); },
      preventDefault: true
    },
    {
      key: ['del', 'ctrl + e'], // Delete Stock in watchlist
      command: (e) => { this.watchlistAction('DEL'); },
      preventDefault: true
    },
     {
       key: 'shift + F9', // Chart
       command: (e) => { this.openComponentUsingShortcut('Chart',2); },
       preventDefault: true
     },
    {
      key: 'ctrl + shift + F1', // Spread Buy Order
      command: (e) => { this.openSpreadPlaceOrder('B',1); },
      preventDefault: true
    },
    {
      key: 'ctrl + shift + F2', // Spread Sell Order
      command: (e) => { this.openSpreadPlaceOrder('S',1); },
      preventDefault: true
    },
    {
      key: 'shift + tab', // Next WorkSpace
      command: (e) => {
        let warr = Utility.WorkspaceArr.indexOf(Utility.selectedWorkspace);
        let sarr = Utility.StockDetailArrs.indexOf(Utility.selectedWorkspace);
        let wlen = Utility.WorkspaceArr.length;
        let slen = Utility.StockDetailArrs.length;
        if(warr !== -1 && warr !== wlen -1) {
          this.loadWorkSpace(Utility.WorkspaceArr[warr + 1], false);
        } else if(sarr !== -1 && sarr !== slen -1) {
          this.loadWorkSpace(Utility.StockDetailArrs[sarr + 1], true);
        }  else if(warr !== -1 && warr === wlen -1 && slen > 0) {
          this.loadWorkSpace(Utility.StockDetailArrs[0], true);
        }  else if(warr !== -1 && warr === wlen -1 && slen === 0) {
          this.loadWorkSpace(Utility.WorkspaceArr[0], false);
        } else if(sarr !== -1 && sarr === slen -1) {
          this.loadWorkSpace(Utility.WorkspaceArr[0], false);
        }
      },
      preventDefault: true
    },
    {
      key: 'ctrl + q', // Delete WorkSpace
      command: (e) => {
        this.deleteWorkspace(Utility.selectedWorkspace,(Utility.selectedWorkspace>5) ? true : false);
      },
      preventDefault: true
    },
    {
      key: 'ctrl + shift + F9', // Change Password
      command: (e) => { this.openPopupsPage('changepwd'); },
      preventDefault: true
    },
    {
      key: 'ctrl + shift + F8', // Change Mpin
      command: (e) => { this.openPopupsPage('changempin'); },
      preventDefault: true
    },
    {
      key: 'ctrl + shift + F7', // Profile
      command: (e) => { this.openPopupsPage('profile'); },
      preventDefault: true
    },
    {
      key: 'ctrl + shift + F6', // Manage Watchlist
      command: (e) => { this.openWL(false); },
      preventDefault: true
    },
    {
      key: 'ctrl + shift + F5', // General Setting
      command: (e) => { this.openPopupsPage('generalsettings'); },
      preventDefault: true
    },
    {
      key: 'alt + 1', // Resistance & Support
      command: (e) => { this.openComponentUsingShortcut('Resistance & Support',2); },
      preventDefault: true
    },
    {
      key: 'alt + 2', // Volume Shocker
      command: (e) => { this.openComponentUsingShortcut('Volume Shocker',2); },
      preventDefault: true
    },
    {
      key: 'alt + 3', // Circuit Breakers
      command: (e) => { this.openComponentUsingShortcut('Circuit Breakers',2); },
      preventDefault: true
    },
    {
      key: 'alt + 4', // High/Low Breakers
      command: (e) => { this.openComponentUsingShortcut('High/Low Breakers',2); },
      preventDefault: true
    },
    {
      key: 'alt + 5', // Open High/Low
      command: (e) => { this.openComponentUsingShortcut('Open High/Low',2); },
      preventDefault: true
    },
    {
      key: 'alt + 6', // Technical Scanners
      command: (e) => { this.openComponentUsingShortcut('Technical Scanners',2); },
      preventDefault: true
    },
    {
      key: 'alt + shift + G', // News
      command: (e) => { this.openComponentUsingShortcut('General News', 3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + N', // Equity News
      command: (e) => { this.openComponentUsingShortcut('Equity News', 4); },
      preventDefault: true
    },
    {
      key: 'alt + shift + D', // Derivative News
      command: (e) => { this.openComponentUsingShortcut('Derivative News',4); },
      preventDefault: true
    },
    {
      key: 'alt + shift + E', // Events
      command: (e) => { this.openComponentUsingShortcut('Events', 3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + M', // Exchange Message
      command: (e) => { this.openComponentUsingShortcut('Exchange Message', 3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + I', // Top Indices
      command: (e) => { this.openComponentUsingShortcut('Top Indices',3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + A', // Advances & Declines
      command: (e) => { this.openComponentUsingShortcut('Advances & Declines',3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + T', // Top Gainer/Losers
      command: (e) => { this.openComponentUsingShortcut('Top Gainer/Losers',3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + V', // Most Active Volume/Value
      command: (e) => { this.openComponentUsingShortcut('Most Active Volume/Value',3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + S', // Market Status
      command: (e) => { this.openComponentUsingShortcut('Market Status',3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + O', // Option Chain
      command: (e) => { this.openComponentUsingShortcut('Option Chain',3); },
      preventDefault: true
    },
    {
      key: 'alt + shift + C', // Option Calculator
      command: (e) => { this.openComponentUsingShortcut('Option Calculator', 3); },
      preventDefault: true
    },
    {
      key: 'shift + T', // Technicals
      command: (e) => { this.openComponentUsingShortcut('Technicals',1); },
      preventDefault: true
    },
    {
      key: 'shift + S', // Stock news
      command: (e) => { this.openComponentUsingShortcut('Stock News',1); },
      preventDefault: true
    },
    {
      key: 'shift + D', // Depth
      command: (e) => { this.openComponentUsingShortcut('Depth',1); },
      preventDefault: true
    },
    {
      key: 'alt + n', // Add workspace
      command: (e) => { this.addWorkspace(); },
      preventDefault: true
    } 
  );
}

selectData(value) {
  this.closeSearch();
  if (value.tsym !== undefined && value.omtkn !== undefined && value.exseg !== undefined) {
    const obj={exch:value.exseg, symbol:value.tsym, token:value.omtkn, 'series': value.seris}
    this.openStockDetail(obj);
    if (value.omtkn && value.exseg)
      Utility.addRecentlySearchedScrips(value.omtkn, value.exseg, value.tsym, value.cnam);
  } else {
    this.addWorkspace('Tag News', value.tag);
  }
}

itemDestroyed(val, n){
  if(!Utility.isLogout) {
    if(!this.itemDestroyed[n]) {
      let item = Utility.workspaceMap[n];
      if(val._target.title){
        if(val._target.title === 'News')
          item.splice(item.indexOf('General News'), 1);
        else{
          item.splice(item.indexOf(val._target.title), 1);
          delete Utility.inputproplist[n][val._target.title]
        }
      }
      if(item.length === 0){
        setTimeout(()=>{
          if(!this.itemDestroyed[n]) {
            this.itemDestroyed[n] = true;
            if(!this.deleteWorkSpace){
              this._goldenLayout[n].clear();
              const selectedLayout = predefinedLayouts[1];
              this._goldenLayout[n].loadLayout(selectedLayout.config);          
            }
            this.deleteWorkSpace = false;
          }
        },200);   
        setTimeout(()=>{
          this.itemDestroyed[n] = false;
        },300);
      }
    }
  }
} 

ngOnDestroy(): void {
  for(let i=0; i<11; i++){
    this.isItemDestroyed[i] = true;
  }
  if(this._dragSources.length>0) {
    let i=0;
    while(i< this._dragSources.length){
      this._goldenLayout[1].removeDragSource(this._dragSources[i++]);
      this._goldenLayout[2].removeDragSource(this._dragSources[i++]);
      this._goldenLayout[3].removeDragSource(this._dragSources[i++]);
      this._goldenLayout[4].removeDragSource(this._dragSources[i++]);
      this._goldenLayout[5].removeDragSource(this._dragSources[i++]);
    }
    this._dragSources =[];
  }
}

ngOnChanges(){
  if(this.stockInput !== undefined)
  {
    Utility.stockFlag = true;
    this.openStockDetail(this.stockInput);
    this.stockInput = undefined;
    Utility.stockFlag=false;
  }
  if(this.chartInputs !== undefined)
  {
    this.addWorkspace('Chart');
    if(this.chartInputs.token && this.chartInputs.exch)
    Utility.addRecentlySearchedScrips(this.chartInputs.token, this.chartInputs.exch, this.chartInputs.symbol, '-');
    this.chartInputs = undefined;
  }
}

openMenu(){
  this.inputValue = '';
  this.filterData(this.inputValue);
}
}