<div class="closebottomsheet">
    <button mat-icon-button (click) = "onSwipeDown()"><i class="icon-close"></i></button>
</div>
<div class="row" (swipedown)="onSwipeDown()">
    <div class="col-12">
        <div class="watchlistwrapper">
            <div class="row">
                <label class="col-12 tc-1 ts-4 textclip">{{sipData.basket_name}}</label>
                <div class="col-7 pr-0">
                    <label class="line2 subcolor ts-2">{{'settings.status' | translate}} :&nbsp;</label><span [class.imnegative]="sipData.sip_status===2" [class.impositive]="sipData.sip_status===1">{{statusMap[sipData.sip_status]}}</span><br/>
                    <label class="line2 subcolor ts-2">{{'all.sip' | translate}} {{'all.frequency' | translate}} :&nbsp;</label>{{sipData.sip_frequency}}
                </div>
                <div class="col-5 pl-0 text-right">
                    <label class="line2 subcolor ts-2">{{'all.date' | translate}} :</label><label>{{sipData.next_trigger_date  | datepipe: 'date'}}</label>
                    <br>
                    <label class="line2 subcolor ts-2">{{'all.sip' | translate}} {{'books.amt' | translate}} :&nbsp;</label><label>{{sipData.sip_amount}}</label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid textpapa mt-2">
    <div class="row py-2" *ngIf="isEmandateFlag" [disable]="!(sipData.sip_status === 1)">
        <div class="col-12 text-right">
            <a class="plainlink" (click)="openEmandate()">
                <span><b translate>all.emandate</b></span>
            </a>
        </div>
    </div>
    <div class="row buttonrow">
        <div *ngIf = "sipData.sip_status === 2" class="col-4">
            <button [disabled]="isSipUpdate" mat-flat-button class="btn-positive" (click)="handleButton(1)">{{'all.active' | translate}}</button>
        </div>
        <div *ngIf = "sipData.sip_status === 1" class="col-4">
            <button [disabled]="isSipUpdate" mat-flat-button class="btn-positive" (click)="handleButton(2)">{{'all.pause' | translate}}</button>
        </div>
        <div class="col-4">
            <button [disabled]="isSipUpdate" mat-flat-button class="btn-positive" (click)="handleButton(-1)">{{'all.delete' | translate}}</button>
        </div>
        <div class="col-4">
            <button [disabled]="isSipUpdate" mat-flat-button class="btn-negative" (click)="handleButton('U')">{{'all.update' | translate}}</button>
        </div>
    </div>
</div>