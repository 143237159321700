import { Component, Inject, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChannelType, Utility } from 'src/app/services/utility';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ReturnType, Preferences } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-stockmoreoption',
    templateUrl: '../../'+environment.baseviewpath+'/stockmoreoption/stockmoreoption.component.html',
    styleUrls: ['./stockmoreoption.component.scss']
})

export class StockMoreOptionComponent implements OnDestroy{
    scripModelData;
    scanner_name = "";
    scanner_type_val = "";
    tabIndex = 0;
    WlArr = [];
    showWlBtn = true;
    subscription;
    loadBaiKaiDetailList;
    flashType = 'defaultFlash';
    createGttFlag = environment.isGTTEnable;

    constructor(private router: Router, private zone: NgZone, private dialog: MatDialog, private _bottomSheetRef: MatBottomSheetRef<StockMoreOptionComponent>, private detector: ChangeDetectorRef,
         @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private translate: TranslateService) {
        const SelectedTab = Preferences.getItem('stockSelectedTab', ReturnType.Number);
        this.scanner_type_val = Utility.scannerTypeValue;
        this.tabIndex = (SelectedTab === null) ? 0 : parseInt(SelectedTab, 10);
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
            this.flashType = Preferences.getItem('flashType');
        }
        Utility.flashType.subscribe(obj => {
            this.flashType = obj;
        });
        if (data.formWL) {
            this.showWlBtn = false;
            this.scripModelData = data.data;
        } 
        else if (data.frmScanner) {
            this.scanner_name = Utility.scannerName;
            this.scripModelData = data.data;
        } else {
            this.showWlBtn = true;
            this.scripModelData = data;
        }
        this.loadBaiKaiDetailList = Utility.GetScripMarketPictureModel(this.scripModelData.Token, this.scripModelData.ExchangeSegment, this.tradingRestService);
        this.subscription = this.scripModelData.scripUpdate.subscribe(ele =>{
            this.detector.markForCheck();
        });
        this.loadBaiKaiDetailList.depthUpdate.subscribe(ele =>{
            this.detector.markForCheck();
        })
    }
    openLink(): void {
        this._bottomSheetRef.dismiss();
    }
    addWLdata(stock) {
        const seltctedStock = stock.ExchangeSegment + '|' + stock.Token;
        this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
    }
    addStock(wl, stockdata) {
        const seltctedStock = stockdata.ExchangeSegment + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
            if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('books.add_stock'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('books.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('books.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
    }
    handleButton(buttontype) {
        switch (buttontype) {
            case 'B':
            case 'S':
                Utility.openPlaceOrder(this.router, this.scripModelData.Token, this.scripModelData.ExchangeSegment, buttontype, this.dialog);
                break;
            case 'D':
                Utility.openStockDetail(this.router, this.scripModelData.Token, this.scripModelData.ExchangeSegment);
                break;
            case 'T':
                Utility.openCreateTrigger(this.router, this.scripModelData.Token, this.scripModelData.ExchangeSegment, this.dialog)
                break;
        }
        this.openLink();
    }
    onTabChanged(index) {
        Preferences.setItem('stockSelectedTab', index);
        this.zone.run(() => {
            this.tabIndex = index;
        });
    }

    openChart()
    {
        this.router.navigate(["/tradingview"], {queryParams: {'exch': this.scripModelData.ExchangeSegment, 'symbol': this.scripModelData.Token, 'type': 'stock'}})
        this.openLink();
    }

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    onSwipeDown() {
        this._bottomSheetRef.dismiss();
    }
}
