<div *ngIf = 'isMobile'>
    <app-stockheader [scripModel] = "scrpModel"></app-stockheader>
    
    <div class ="data-container pb-5" *ngIf = "!isOrderSummary">
        <div class="panelforsearchcom">
            <div class="row">
                <div  class="col-12 text-right opensearch">
                    <app-autocompletesearch [pageName]="searchId" (selectData)="selectData($event)" [frompage] = "'spreadOrder'"></app-autocompletesearch>
                </div>
            </div>
        </div>

        <div>
            <mat-card class="stockdetailstop">
                <div class="row smalltext mt-3">
                    <div class="col-6"><span class="subcolor2">{{'watchlist.open' | translate}}:</span><span> {{scrpModel.OpenPrice | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                    <div class="col-6 text-right"><span class="subcolor2">{{'watchlist.low' | translate}}:</span><span> {{scrpModel.Low | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                </div>
                <div class="row myrow smalltext">
                    <div class="col-6"><span class="subcolor2">{{'watchlist.high' | translate}}:</span><span> {{scrpModel.High | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                    <div class="col-6 text-right"><span class="subcolor2">{{'watchlist.prv_close' | translate}}:</span><span> {{scrpModel.PrvClose | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                </div>
                <div class="row btn-margin">
                    <div class="col-6">
                        <button mat-flat-button (click) = "openDepth()" class="btn-primary">Depth<i *ngIf = "!isShowDepth" class="icon-godown"></i><i *ngIf = "isShowDepth" class="icon-goup"></i></button>
                    </div>
                    <div class="col-6 text-right">
                        <button mat-flat-button [class.custom-btn]="pom.Ttranstype!=='B'" [class.btn-positive]="pom.Ttranstype=='B'" (click) = "changeTransectionType('B')" [disabled] = "isEdit  || isSquareOff || isDisableForm"><span translate>stock_detail.buy</span></button>
                        <button mat-flat-button  [class.custom-btn]="pom.Ttranstype!=='S'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = "changeTransectionType('S')" [disabled] = "isEdit  || isSquareOff || isDisableForm"><span translate>stock_detail.sell</span></button>
                    </div>
                </div>
                    
                <div [@slideInOut] = depthAnimation [hidden] = "!isShowDepth">
                    <app-depth [omtkn] = "pom.Token" isPlaceOrder="true" [exseg] = "pom.Exchangeseg" (priceEmit) = "priceFormDepth($event)"></app-depth>
                </div>
            </mat-card>
            <div [class.buy]="pom.Ttranstype=='B'" [class.sell]="pom.Ttranstype=='S'"  class="placeorderwrapper">
                <div class="row placeorderfield">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label><span translate>basket.basket_qty </span><span *ngIf = "isLot || scrpModel.BodLotQuantity > 1" class="lotLable">(1 Lot = {{scrpModel.BodLotQuantity | numberpipe}}Qty)</span></mat-label>
                            <input [disabled] = "isQtyDisable || isDisableForm" matInput type="tel" [placeholder]="'basket.basket_qty' | translate" #inputQty [(ngModel)]="pom.qty" (keyup)="validateQty($event)" (keyup.enter)="onEnter('price')" autocomplete="off">
                        </mat-form-field>
                        <br *ngIf = "isLot"/>
                        <span *ngIf = "isLot || scrpModel.BodLotQuantity > 1" class="lotLable" [class.imnegative] = "lotError">Lots = {{calculateLots(pom.qty, scrpModel.BodLotQuantity) | numberpipe}}</span>
                    </div>
                    <div class="col-6" *ngIf = "isEnablePrice">
                        <mat-form-field>
                            <mat-label translate>stock_detail.price</mat-label>
                            <input id="price" (keyup.enter)="onEnter('retention')"  [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'stock_detail.price' | translate" #inputPrice [(ngModel)]="pom.Price" (keyup)="validatePrice($event)" autocomplete="off" inputmode="decimal">
                        </mat-form-field>
                    </div>
                    <div class="col-6" *ngIf = "isMktPro">
                        <mat-form-field>
                            <mat-label translate>placeorder.mktpro</mat-label>
                            <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'placeorder.mktpro' | translate" #inputMktPro [(ngModel)]="pom.MktPro" (keyup)="validateMKTPro($event)" autocomplete="off" [disabled] = "isDisableForm || isEdit">
                        </mat-form-field>
                    </div>
                </div>
                
                    
                    <!-- <div class="row py-2">
                        <div class="col-12">
                            <div class="mb-1" ><small class="textcolor5" translate>books.ordertype</small></div>
                            <mat-radio-group [disabled] = "(isEdit && pom.Pcode == 'BO') || isDisableForm || !isOrderType" [(ngModel)]="pom.prctyp" aria-label="Select an option" (change) = "onOrderTypeChange($event)"> 
                                <ng-template ngFor let-ortype [ngForOf]="orderTypeArray">
                                    <mat-radio-button *ngIf="orarr.includes(ortype)" class = "pr-1" [value]="ortype">{{ortype | valuepipe}}</mat-radio-button>
                                </ng-template>
                            </mat-radio-group>
                        </div>
                    </div> -->
                    <!-- <div class="row" *ngIf = "isSL || checkSL()" [ngStyle]="{minHeight: '40px', maxHeight: '40px'}">
                        <div class="col-6">
                            <mat-form-field class="w-100">
                                <mat-label translate>books.trig_price</mat-label>
                                <input [disabled] = "isDisableForm || checkSL()" matInput type="tel" [placeholder]="'books.trig_price' | translate" [(ngModel)]="pom.TrigPrice" autocomplete="off" (keyup)="validateTriggerPrice($event)" #inputTriggerPrice>
                            </mat-form-field>
                        </div>
                    </div> -->
                    <!-- <div class="row py-2">
                        <div class="col-12">
                            <div class="mb-1" ><small class="textcolor5" translate>placeorder.verity</small></div>
                            <mat-radio-group aria-label="Select an option" [(ngModel)] = 'verityType' (change) = "onVerityTypeChange($event)" [disabled] = "isEdit  || isSquareOff || isDisableForm"    >
                                <mat-radio-button class="pr-1" value="RGLR">RGLR</mat-radio-button>
                                <mat-radio-button class="pr-1" value="BO" *ngIf = "advancedProductArray.includes('BO')">BO</mat-radio-button>
                                <mat-radio-button class="pr-1" value="CO" *ngIf = "advancedProductArray.includes('CO')">CO</mat-radio-button>
                                <mat-radio-button class="pr-1" value="AMO">AMO</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>   -->
                    <!-- <div class="row mt-2 placeorderfield" *ngIf = "pom.Pcode == 'CO' && (isEdit? pom.Status.toLowerCase() != 'open': true)">
                        <div class="col-6">
                            <mat-form-field>
                                <mat-label translate>books.trig_price</mat-label>
                                <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'books.trig_price' | translate" [(ngModel)]="pom.TrigPrice" autocomplete="off" (keyup)="validateTriggerPrice($event)" #inputTriggerPrice>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <div class="primetext ts-1" translate>placeorder.triggerrange</div>
                            <div class="pt-1">{{scrpModel.TriggerRangeStart | pricepipe: scrpModel.DecimalPrecision}} - {{scrpModel.TriggerRangeEnd | pricepipe: scrpModel.DecimalPrecision}} ( {{scrpModel.CoverPercentage}}% )</div>
                        </div>
                    </div> -->
                    <div class="row mt-2 placeorderfield" *ngIf = "pom.Pcode == 'BO' && !isEdit">
                        <div class="col-6">
                            <mat-form-field>
                                <mat-label translate>placeorder.squareoffvalue</mat-label>
                                <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'placeorder.squareoff' | translate" #inputSquareOffvalue [(ngModel)]="squareOffvalue" (keyup)="validateSquareOffvalue($event)" autocomplete="off">
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field>  
                                <mat-label translate>placeorder.stoplossvalue</mat-label>
                                <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'placeorder.squareoff' | translate" #inputStoplossValue [(ngModel)]="stoplossValue" (keyup)="validateStoplossValue($event)" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-6">
                            <mat-form-field [ngStyle] = "">
                                <mat-label translate>placeorder.retentiontype </mat-label>
                                <mat-select id="retention" (keyup.enter)="onSubmit()"  [disabled] = "(isEdit && (pom.Pcode == 'BO' || pom.Pcode == 'CO')) || isDisableForm" (selectionChange)="onRetentionChange($event)" [(value)]="pom.Ret">
                                    <mat-option *ngFor="let obj of retentionTypeArray" [value]="obj">
                                        {{obj}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field>
                                <mat-label translate>placeorder.discqty</mat-label>
                                <input [disabled] = "isDisableForm" matInput type="tel" (keyup)="validateDiscQty($event)" [placeholder]="'placeorder.discqty' | translate" [(ngModel)]="pom.discqty" autocomplete="off" #discQty>
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf = "pom.Ret == 'GTD'">
                            <mat-form-field [disable] = "isDisableForm">
                                <mat-label translate>placeorder.choosedate</mat-label>
                                <input matInput [min]="GTDMindate" [matDatepicker]="picker" [(ngModel)] = 'GTDVal' autocomplete="off" disabled>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf = "pom.Ret == 'GTDys'">
                            <mat-form-field>
                                <mat-label translate>placeorder.validity</mat-label>
                                <mat-select [(value)]="GTDayaVal" [disbaled] = "isDisableForm">
                                    <mat-option *ngFor="let obj of daysArr" [value]="obj">
                                        {{obj}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6" *ngIf = "pom.Ret == 'GTT'">
                            <mat-form-field [ngStyle] = "{width: '150px'}">
                                <input [disabled] = "isDisableForm" matInput type="time" [placeholder]="'placeorder.startdate' | translate" [(ngModel)] = 'GTTval' autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row py-2" *ngIf = "productArr.length > 0">
                        <div class="col-12">
                            <div class="mb-1"><small class="textcolor5" translate>placeorder.producttype</small></div>
                            <mat-radio-group [(ngModel)]="pom.Pcode" aria-label="Select an option" [disabled] = "isEdit || isSquareOff || !isProductType || isDisableForm" (change) = "onProductTypeChange($event)">
                                <ng-template ngFor let-prtype [ngForOf]="productArr">
                                    <mat-radio-button [id] = "prtype" class="pr-1" [value]="prtype">{{prtype | valuepipe}}</mat-radio-button>
                                </ng-template>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="footerbtn" *ngIf="!isDisableForm">
                    <button *ngIf = '!isEdit' mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" class="w-100" (click) = 'onSubmit()' [disabled] = "!isPlaceorder"><span translate class="text-uppercase">basket.place</span></button>
                    <button *ngIf = 'isEdit' mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" class="w-100" (click) = 'onSubmit()' [disabled] = "!isPlaceorder"><span translate>placeorder.modify_order</span></button>
                </div>

                <div class="footerbtn" *ngIf="isDisableForm">
                    <span *ngIf="isDisableForm"><button mat-flat-button class="btn-primary w-25" (click) = 'onEdit()'><span translate>placeorder.edit</span></button></span>
                    <span *ngIf="isDisableForm"><button class="w-75" mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = 'onConfirm()'><span translate class="text-uppercase">watchlist.confirm</span></button></span>
                </div>
                
            </div>
            
    </div>

    <div *ngIf = "isOrderSummary" [class.data-container] = 'isMobile'>
        <div class="row mt-3">
            <div class="col-12">{{'stock_detail.place_msg' | translate}}
            </div>
        </div>
        <div *ngFor = "let orderObj of orderObjArr" class="watchlistwrapper">
            <div *ngIf = "orderObj">
                <div>{{orderObj.TradeSymbol}} <small>{{orderObj.Exchange}}</small></div>
                <div class="row">
                    <div class="col-6">
                        <div class="line2 subcolor">{{'basket.orderid' | translate}}<br/>{{orderObj.NestOrd}}</div>
                    </div>
                    <div class="col-6">
                        <div class="line2 subcolor text-right">{{'basket.orderstatus' | translate}}<br/><span class="primetext textuppercase">{{orderObj.Status}}</span></div>
                    </div>
                </div><br/>
                <div class="line2 subcolor" *ngIf = "orderObj.Status == 'rejected'">{{'placeorder.reject_reason' | translate}}: {{orderObj.RejectionReason}}</div>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-center btn-margin">
                            <button mat-flat-button class = "btn-primary" (click) = 'onModify(orderObj)' *ngIf = "orderObj && orderObj.IsEdit"><span translate>placeorder.modify_order</span></button>
                            <button mat-flat-button class = "btn-negative" (click) = 'onCancel(orderObj)' *ngIf = "orderObj && orderObj.IsCancel"><span translate>watchlist.cancel_order</span></button>
                            <div class="d-inline" *ngIf="orderObj && orderObj.IsExit">
                                <button mat-flat-button class="btn-primary" (click)="onExit(orderObj)"><span translate>books.exit</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-dialog-actions class="mat-dialog-actions d-block m-2 text-right">   
            <button [class.col]="isMobile" mat-flat-button class = "btn-primary" (click) = 'onPlaceAnother()'>
                <span translate class="text-uppercase">placeorder.place_another</span></button>
                <!-- <button [class.col]="isMobile" mat-flat-button class = "btn-primary mt-2" (click) = 'onOpenOrderBook()'>
                        <span translate class="text-uppercase">placeorder.open_order_book</span></button> -->
        </mat-dialog-actions>
    </div>
</div>

<div *ngIf = '!isMobile'>
    <div mat-dialog-title="" class="mat-dialog-title" id="mat-dialog-title-2">
        <div class="row">
            <div class="col-3"><h1>Spread Place Order</h1></div>
            <div class="col-7 border-l mt-1"><div class="expand-search"><app-autocompletesearch pageName="placeorder" (selectData)="selectData($event)" [frompage] = "'spreadOrder'"></app-autocompletesearch></div></div>
            <div class="col-2 text-right">
                <button tabindex="-1" mat-icon-button (click) = "close()"><i class="icon-close"></i></button>
            </div>
        </div>
    </div>
    <div *ngIf = "!isOrderSummary">
        <div class="row">
            <div [class.col-12]="nodepth" [class.col-8]="!nodepth" >
                <mat-dialog-content class="h-auto narrowpadding">
                <div [class.p-50]="isPopup">
                    <div class="row stockdetailstop pb-2">
                        <div class="col-xl-6 col-sm-4">
                            <div class="firstline"><span class="symbolname"> {{scrpModel.TradingSymbol}}<small>{{scrpModel.ExchangeName}}</small></span></div>
                            <div class="line2">{{scrpModel.CompanyName}}</div>
                        </div>
                        <div class="col-xl-3 col-sm-4 text-right" [class.imnegative]="scrpModel.Change<0" [class.impositive]="scrpModel.Change>0" >
                            <div [class.imnegativebg]="scrpModel.TickDirection === -1" [class.impositivebg]="scrpModel.TickDirection === 1">
                                <div class="firstline">{{scrpModel.LastTradePrice | pricepipe: scrpModel.DecimalPrecision}}</div>
                                <div class="line2">{{scrpModel.Change | pricepipe: scrpModel.DecimalPrecision}} ({{scrpModel.PercentageChange | percentagepipe}})</div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-4 text-right">
                            <button mat-flat-button tabindex="-1" [class.custom-btn]="pom.Ttranstype!=='B'" [class.btn-positive]="pom.Ttranstype=='B'" (click) = "changeTransectionType('B')" [disabled] = "isEdit  || isSquareOff || isDisableForm"><span translate>stock_detail.buy</span></button> &nbsp;
                            <button mat-flat-button tabindex="-1" [class.custom-btn]="pom.Ttranstype!=='S'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = "changeTransectionType('S')" [disabled] = "isEdit  || isSquareOff || isDisableForm"><span translate>stock_detail.sell</span></button>
                        </div>
                    </div>
                    <div class="row myrow smalltext">
                        <div class="col-3"><span class="subcolor2">{{'watchlist.open' | translate}}:</span><span> {{scrpModel.OpenPrice | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                        <div class="col-3 text-center"><span class="subcolor2">{{'watchlist.high' | translate}}:</span><span> {{scrpModel.High | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                        <div class="col-3 text-center"><span class="subcolor2">{{'watchlist.low' | translate}}:</span><span> {{scrpModel.Low | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                        <div class="col-3 text-right"><span class="subcolor2">{{'watchlist.prv_close' | translate}}:</span><span> {{scrpModel.PrvClose | pricepipe: scrpModel.DecimalPrecision}}</span></div>
                    </div>
                    <div [class.buy]="pom.Ttranstype=='B'" [class.sell]="pom.Ttranstype=='S'" class="">
                        <div class="row myrow">
                            <div class="col-12 col-md-6">
                                <div class="mb-1"><small class="textcolor5" translate>placeorder.producttype</small></div>
                                <mat-radio-group [(ngModel)]="pom.Pcode" aria-label="Select an option" [disabled] = "isEdit || isSquareOff || isDisableForm" (change) = "onProductTypeChange($event)">
                                    <div class="row">
                                        <div class="col-12 mb-2">
                                        <ng-template ngFor let-prtype [ngForOf]="productArr">
                                            <mat-radio-button class = "pr-1" [value]="prtype">{{prtype | valuepipe}}</mat-radio-button>
                                        </ng-template></div>
                                    </div>
                                </mat-radio-group>
                            </div>
                            <!-- <div class="col-12 col-md-6">
                                <div class="mb-1"><small class="textcolor5" translate>books.ordertype</small> </div>
                                <mat-radio-group [disabled] = "(isEdit && pom.Pcode == 'BO') || isDisableForm || !isOrderType" [(ngModel)]="pom.prctyp" aria-label="Select an option" (change) = "onOrderTypeChange($event)"> 
                                    <div class="row"><div class="col-12 mb-2">
                                        <ng-template ngFor let-ortype [ngForOf]="orderTypeArray">
                                            <mat-radio-button *ngIf="orarr.includes(ortype)" class = "pr-1" [value]="ortype">{{ortype | valuepipe}}</mat-radio-button>
                                        </ng-template></div>
                                    </div>
                                </mat-radio-group>
                            </div> -->
                            
                        </div>
                        <div class="row myrow placeorderfield pt-3">
                            <div class="col-2">
                                <mat-form-field>
                                    <mat-label><span translate>basket.basket_qty </span><span *ngIf = "isLot || scrpModel.BodLotQuantity > 1" class="lotLable">(1 Lot = {{scrpModel.BodLotQuantity | numberpipe}}Qty)</span></mat-label>
                                    <input [disabled] = "isQtyDisable || isDisableForm" matInput type="tel" [placeholder]="'basket.basket_qty' | translate" #inputQty [(ngModel)]="pom.qty" (keyup)="validateQty($event)" autocomplete="off">
                                </mat-form-field><br/>
                                <span *ngIf = "isLot || scrpModel.BodLotQuantity > 1" class="lotLable" [class.imnegative] = "lotError">Lots = {{calculateLots(pom.qty, scrpModel.BodLotQuantity) | numberpipe}}</span>
                            </div>
                            <div class="col-2" *ngIf = "isEnablePrice">
                                <mat-form-field>
                                    <mat-label translate>stock_detail.price</mat-label>
                                    <input  [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'stock_detail.price' | translate" #inputPrice [(ngModel)]="pom.Price" (keyup)="validatePrice($event)" autocomplete="off" inputmode="decimal">
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "isMktPro">
                                <mat-form-field>
                                    <mat-label translate>placeorder.mktpro</mat-label>
                                    <input [disabled] = "isDisableForm || isEdit" matInput type="tel" [placeholder]="'placeorder.mktpro' | translate" #inputMktPro [(ngModel)]="pom.MktPro" (keyup)="validateMKTPro($event)" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = 'isDiscQty'>
                                <mat-form-field>
                                    <mat-label translate>placeorder.discqty</mat-label>
                                    <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'placeorder.discqty' | translate" (keyup)="validateDiscQty($event)" [(ngModel)]="pom.discqty" autocomplete="off" #discQty>
                                </mat-form-field>
                            </div>
                            <!-- <div class="col-2" *ngIf = "isSL || (pom.Pcode == 'CO' && (isEdit? pom.Status.toLowerCase() != 'open': true)) || checkSL()">
                                <mat-form-field>
                                    <mat-label><span translate>books.trig_price</span></mat-label>
                                    <input [disabled] = "isDisableForm || (pom.prctyp === 'L' && isEdit && checkSL())" matInput type="tel" [placeholder]="'books.trig_price' | translate" [(ngModel)]="pom.TrigPrice" autocomplete="off" (keyup)="validateTriggerPrice($event)" #inputTriggerPrice>
                                </mat-form-field>
                            </div> -->
                            
                            <div class="col-2" *ngIf = "pom.Pcode == 'BO' && !isEdit">
                                <mat-form-field>
                                    <mat-label translate>placeorder.squareoffvalue</mat-label>
                                    <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'placeorder.squareoff' | translate" #inputSquareOffvalue [(ngModel)]="squareOffvalue" (keyup)="validateSquareOffvalue($event)" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "pom.Pcode == 'BO' && !isEdit">
                                <mat-form-field>
                                    <mat-label translate>placeorder.stoplossvalue</mat-label>
                                    <input [disabled] = "isDisableForm" matInput type="tel" [placeholder]="'placeorder.squareoff' | translate" #inputStoplossValue [(ngModel)]="stoplossValue" (keyup)="validateStoplossValue($event)" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-2">
                                <mat-form-field>
                                    <mat-label translate>placeorder.retentiontype </mat-label>
                                    <mat-select [disabled] = "(isEdit && (pom.Pcode == 'BO' || pom.Pcode == 'CO')) || isDisableForm" (selectionChange)="onRetentionChange($event)" [(value)]="pom.Ret">
                                        <mat-option *ngFor="let obj of retentionTypeArray" [value]="obj">
                                            {{obj}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "pom.Ret == 'GTD'">
                                    <mat-form-field [disabled] = "isDisableForm" [ngStyle] = "{width: '150px'}">
                                        <mat-label translate>placeorder.choosedate</mat-label>
                                        <input matInput [min]="GTDMindate" [matDatepicker]="picker" [(ngModel)] = 'GTDVal' autocomplete="off" disabled>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            <div class="col-2" *ngIf = "pom.Ret == 'GTDys'">
                                <mat-form-field>
                                    <mat-label translate>placeorder.validity</mat-label>
                                    <mat-select [disabled] = "isDisableForm" [(value)]="GTDayaVal">
                                        <mat-option *ngFor="let obj of daysArr" [value]="obj">
                                            {{obj}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2" *ngIf = "pom.Ret == 'GTT'">
                                <mat-form-field [ngStyle] = "{width: '150px'}">
                                    <input [disabled] = "isDisableForm" matInput type="time" [placeholder]="'placeorder.startdate' | translate" [(ngModel)] = 'GTTval' autocomplete="off">
                                </mat-form-field>
                            </div>
                            <!-- <div class="col-2 amoweb" *ngIf = "(pom.Pcode != 'CO') && (pom.Pcode != 'BO')">
                                <mat-checkbox (change) = "onAMO($event)" [disabled] = "isEdit || isDisableForm">AMO</mat-checkbox>
                            </div> -->
                            <!-- <div class="col-12 ts-1" *ngIf = "pom.Pcode == 'CO' && (isEdit? pom.Status.toLowerCase() != 'open': true)">
                                <small class="subcolor2" translate>placeorder.triggerrange</small>: {{scrpModel.TriggerRangeStart | pricepipe: scrpModel.DecimalPrecision}} - {{scrpModel.TriggerRangeEnd | pricepipe: scrpModel.DecimalPrecision}} ( {{scrpModel.CoverPercentage}}% )
                            </div> -->
                        </div>
                        
                    </div>
                </div>
                </mat-dialog-content>
            <mat-dialog-actions class="mat-dialog-actions d-block text-right">
                <div *ngIf = "!isDisableForm || scrpModel.Token === 'asdfg'">
                    <div class="row">
                        <div class="col-12">
                            <button *ngIf = '!isEdit' mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = 'onSubmit()' [disabled] = "!isPlaceorder || scrpModel.Token === 'asdfg'"><span translate class="text-uppercase">basket.place</span></button>
                            <button *ngIf = 'isEdit' mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = 'onSubmit()' [disabled] = "!isPlaceorder || scrpModel.Token === 'asdfg'"><span translate>placeorder.modify_order</span></button>
                        </div>
                    </div>
                    
                </div>
                <div *ngIf="isDisableForm && scrpModel.Token !== 'asdfg'">
                    <button mat-flat-button class="btn-primary" (click) = 'onEdit()'><span translate>placeorder.edit</span></button>
                    <button mat-flat-button [class.btn-positive]="pom.Ttranstype=='B'" [class.btn-negative]="pom.Ttranstype=='S'" (click) = 'onConfirm()'><span translate class="text-uppercase">watchlist.confirm</span></button>
                </div>
            
            </mat-dialog-actions>
        </div>
        <div *ngIf="!nodepth" class="col-4 border-l  pr-3">
            <mat-card>
                <app-depth isPlaceOrder="true" [omtkn] = "pom.Token" [exseg] = "pom.Exchangeseg" (priceEmit) = "priceFormDepth($event)"></app-depth>
            </mat-card>
        </div>
    </div>
    </div>
    <div *ngIf = "isOrderSummary">
        <div class="row m-2">
            <div class="col-12">
                <div class="impositivebg">{{'stock_detail.place_msg' | translate}}</div>
                <div *ngFor = "let orderObj of orderObjArr" class="watchlistwrapper mb-1">
                    <div *ngIf = "orderObj">
                        <div class="row">
                            <dv class="col-8">
                                <div class="row mb-1">
                                    <div class="col-4">
                                        <div class="firstline">{{orderObj.TradeSymbol}} <small>{{orderObj.Exchange}}</small></div>
                                        <div><small class="line2 subcolor2">Qty:</small> {{orderObj.TotalQty}} @ <small class="line2 subcolor2"> Price:</small> {{orderObj.Price}}</div>
                                    </div>
                                    <div class="col-4">
                                        <div class="line2 subcolor2">{{'basket.orderid' | translate}}</div>
                                        <div class="">{{orderObj.NestOrd}}</div>
                                    </div>
                                    <div class="col-4">
                                        <div class="line2 subcolor2">{{'basket.orderstatus' | translate}}</div>
                                        <div class="primetext mecaps">{{orderObj.Status}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="line2 subcolor2" *ngIf = "orderObj.Status == 'rejected'">{{'placeorder.reject_reason' | translate}}:</div>
                                        <div class="" *ngIf = "orderObj.Status == 'rejected'">{{orderObj.RejectionReason}}</div>
                                    </div>
                                </div>
                            </dv>
                            
                            <div class="col-4 text-right">
                                <button mat-flat-button class = "ml-1 btn-primary" (click) = 'onModify(orderObj)' *ngIf = "orderObj && orderObj.IsEdit"><span translate>placeorder.modify_order</span></button>
                                <button mat-flat-button class = "ml-1 btn-negative" (click) = 'onCancel(orderObj)' *ngIf = "orderObj && orderObj.IsCancel"><span translate>alert.cancel</span></button>
                                <button mat-flat-button class="ml-1 btn-primary" (click)="onExit(orderObj)" *ngIf="orderObj && orderObj.IsExit"><span translate>books.exit</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-dialog-actions class="mat-dialog-actions d-block pt-0">   
                    <div class="row">
                        <div class="col-12 text-right">
                            <button [class.col]="isMobile" mat-flat-button class = "btn-primary" (click) = 'onPlaceAnother()'><span translate class="text-uppercase">placeorder.place_another</span></button>
                        </div>
                    </div>
                </mat-dialog-actions>
            </div>
        </div>
    </div>
</div>
