import { Injectable, ɵConsole } from '@angular/core';
import { IIndexService } from './iindex.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import { Utility } from '../utility';
import { TradingRestService } from '../tradingrest.service';
import { Preferences } from '../Preferences';

let ind: IndexService;

@Injectable()
export class IndexService implements IIndexService {
    apiService: ApiService;
    tradingRestService: TradingRestService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
        ind = this;
    }
    getIndexExchange(callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        let returndata = {};
        const jsonSendObj = { 'exarry': JSON.stringify(JSON.parse(Preferences.getPreference('userParameter'))['exarr']) };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.IndexExchange, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok') {
                returndata = res;
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(returndata, code, message);
            });
    }

    getIndexDetails(Exchange: string, callback: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const returndata = [];
        const jsonSendObj = { 'Exchange': Exchange };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.IndexDetails, param).subscribe(res => {
            const stat = res['stat'];
            if (stat === 'Ok') {
                for (let i = 0; i < res['IndexDetail'].length; i++) {
                    const im = Utility.getIndexModel(res['IndexDetail'][i]['IndexName'], Exchange, ind.tradingRestService, true);
                    im.Name = res['IndexDetail'][i]['IndexName'];
                    im.Change = res['IndexDetail'][i]['IndexChange'];
                    im.IndexValue = res['IndexDetail'][i]['IndexValue'];
                    const indexVal = parseFloat(im.IndexValue.toString());
                    const change = parseFloat(im.Change.toString());
                    const oldVal = indexVal - change;
                    const perChangeVal = (change / oldVal) * 100;
                    im.PerCng = ((isFinite(perChangeVal) && perChangeVal.toString() !== 'NaN') ? perChangeVal : 0.00);
                    returndata.push(im);
                }
                message = 'OK';
            } else {
                message = res['Emsg'];
                code = Keys.RES_CODE_FAILURE;
                if (message === Keys.NO_DATA) {
                    code = Keys.RES_CODE_SUCCESS;
                }
            }
            callback(returndata, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback(returndata, code, message);
            });
    }

    setTradingRestService(tradingRest: TradingRestService) {
        ind.tradingRestService = tradingRest;
    }
}
