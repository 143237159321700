import { Injectable, ɵConsole } from '@angular/core';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import { Utility } from '../utility';
import { IMarketService } from './imarket.service';
import { MarketStatusModel } from '../../model/marketstatus.model';
import { ExchangeMessageModel } from '../../model/exchangemessage.model';
import { Preferences } from '../Preferences';

let ms: MarketService;
@Injectable()
export class MarketService implements IMarketService {
    apiService: ApiService;
    constructor(apiService: ApiService) {
        ms = this;
        ms.apiService = apiService;
    }

    getMarketStatus(exchange: string, callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'Exchange': exchange };
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        const key = Url.marketStatus + '_' + 'exchange' + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        this.apiService.post(Url.marketStatus, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            const MarketModelList = new Array<MarketStatusModel>();
            if (res['stat'] === 'Ok') {
                for (let i = 0; i < res['Marketstaus'].length; i++) {
                    const jdata = res['Marketstaus'][i];
                    const model = new MarketStatusModel();
                    model.MarketStatus = jdata['MktStatus'];
                    model.MarketMode = jdata['MarketMode'];
                    if (model.MarketStatus.toLowerCase().endsWith('preclose'))
                        model.Status = 'Pre Close';
                    else if (model.MarketStatus.toLowerCase().endsWith('preopen end'))
                        model.Status = 'Pre Open End';
                    else if (model.MarketStatus.toLowerCase().endsWith('preopen'))
                        model.Status = 'Pre Open';
                    else if (model.MarketStatus.toLowerCase().endsWith('open'))
                        model.Status = 'Open';
                    else if (model.MarketStatus.toLowerCase().includes('close'))
                        model.Status = 'Close';
                    else if (model.MarketStatus.toLowerCase().includes(' start'))
                        model.Status = 'Start';
                    else if (model.MarketStatus.toLowerCase().includes(' end'))
                        model.Status = 'End';
                    else
                        model.Status = '---';
                    MarketModelList.push(model);
                }
                Utility.CACHE_STORE.setCacheData(key, MarketModelList);
                message = Keys.RES_MSG_OK;
            }
            else {
                code = Keys.RES_CODE_FAILURE;
                message = res['Emsg'];
            }
            callback(MarketModelList, code, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }

    getExchangeMessage(exchange: string, callback: Function, precallback?: Function) {
        let message = '';
        let code = Keys.RES_CODE_SUCCESS;
        const jsonSendObj = { 'Exch': exchange };
        const key = Url.exchangeMessage + '_' + exchange + Preferences.getPreference('userid');
        const predata = Utility.CACHE_STORE.getCacheData(key);
        if (predata !== undefined) {
            precallback(predata);
        }
        const param = Utility.prepareParameters(JSON.stringify(jsonSendObj), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.exchangeMessage, param).subscribe(res => {
            res = JSON.parse(JSON.stringify(res));
            const ModelList = new Array<ExchangeMessageModel>();
            let arr = [];
            let msgArr = [];
            if (res['stat'] === 'Ok') {
                if (res['Exchmsg'] !== 'No Data') {
                    arr = res['Exchmsg'].split('/n');
                    for (let i = 0; i < arr.length; i++) {
                        const model = new ExchangeMessageModel();
                        const msg = arr[i];
                        if (msg) {
                            msgArr = msg.split('::');
                            model.Message = msgArr[2].substring(21, msgArr[2].length - 1);
                            model.Exchange = msgArr[1];
                            model.DateTime = msgArr[2].substring(0, 20);
                            ModelList.push(model);
                        }
                    }
                    message = Keys.RES_MSG_OK;
                    Utility.CACHE_STORE.setCacheData(key, ModelList);
                }
                else
                    message = res['Exchmsg'];
            }
            else {
                message = Response['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback(ModelList, code, message);
        }, error => {
            message = error;
            code = Keys.RES_CODE_FAILURE;
            callback('', code, message);
        });
    }
}

