import { Component, Output, EventEmitter, Input, Inject, ChangeDetectorRef, Injector, OnInit, HostListener, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { HoldingModel } from 'src/app/model/holding.model';
import { Router } from '@angular/router';
import { ChannelType, Utility } from 'src/app/services/utility';
import CryptoJS from 'crypto-js';
import { MatDialog } from '@angular/material/dialog';
import { PortfolioMoreOptionComponent } from '../portfoliomoreoption/portfoliomoreoption.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PlaceorderComponent } from '../../../placeorder/placeorder.component';
import { ComponentContainer } from 'golden-layout';
import * as $ from 'jquery';
import { TableUtility } from 'src/app/services/tableutility';
import { GeneralSettingsComponent } from '../../../generalsettings/generalsettings.component';
import { Subscription, Subject } from 'rxjs';
import { Holdings } from 'src/app/model/holdingsummary.model';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { filter } from 'rxjs/operators';
import { CSVUtility } from 'src/app/services/CSVUtiity';
import { MyAlertDialogComponent } from '../../watchlist/watchlist.component';
import { data } from 'jquery';
import { Mouse } from 'src/app/services/mouse';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { environment } from 'src/environments/environment';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { IvyParser } from '@angular/compiler';

let autoGenId = 0;
let holding : HoldingComponent;
declare const moment: any;
@Component({
  selector: 'app-holding',
  templateUrl: '../../../../'+environment.baseviewpath+'/home/portfolio/holding/holding.component.html',
  styleUrls: ['./holding.component.scss']
})
export class HoldingComponent  extends BaseComponentDirective implements OnInit, OnDestroy {
  targetElement: Element;
  @Input() isMobile: boolean;
  @Output() load = new EventEmitter<boolean>();
  @Output() openStockDetailPage = new EventEmitter<string>();
  @Input() isCustomDashboard: boolean;
  @Input() workspaceName: string;
  @Input() selectedTabIndex: any;
  @ViewChild('menuTrigger') matMenuTriggerClose;
  @Output() totalScripCount = new EventEmitter<number>();
  expandText = 'More';
  hideDownload = false;
  holdingList: Array<HoldingModel>;
  @ViewChild('sortMenu') sortMenu;
  @ViewChild('pnlHeader') pnlHeader;
  filteredHoldingList: Array<HoldingModel> = [];
  dataArr = [];
  isLoader: boolean;
  noData: boolean;
  isExpandVisible: boolean;
  isExpand = false;
  dataArrLen = 0;
  widthArr = [];
  utility: any;
  selectedLabel = 'ALL';
  indexArray = [];
  searchId;
  isMobileLand;
  edisCheck: boolean;
  holdings: any = {};
  isAvgPriceEditable: boolean;
  uniqueMap = new Map();
  authorizedScripMap = new Map();
  isFilterNoData: boolean;
  selectedFilter = { 'type': 'All', 'data': 'ALL' };
  displayedColumns;
  Height: any;
  DHeight: {};
  isDHeight = true;
  container;
  left: any;
  modesub: Subscription;
  sortIconSubscription: Subscription;
  bookColumnSubscription: Subscription;
  webReloadSubscription: Subscription;
  orientationSubscription : Subscription;
  holdingPriceSubscription : Subscription;
  cellView: boolean;
  WlArr = [];
  selectedFilterOption = '';
  tempFilteredHoldingList: Array<HoldingModel> = [];
  dataArray : Array<HoldingModel> = [];
  filteredKey : any;
  filteredValue : any;
  isReloadData = false;
  isReloadClick = false;
  selectedRowIndex : any;
  totalheight : any;
  M;
  matChipItems = ['Asc', 'Desc'];
  sortItemList = ['Unsorted', 'Symbol', 'Net PNL', 'PNL %Change', 'LTP', '%Change'];
  chipSelected = this.matChipItems[0];
  selectedSortedItem = this.sortItemList[0];
  filteObject : any;
  sortingDictionary = new Map<any, any>();
  hostName;
  edisTransArray = [];
  isEdisEnable = environment.isEdis;
  flashType = 'defaultFlash';
  @Output() openManageBooks = new EventEmitter<string>();
  subscription: any;

  constructor(elRef: ElementRef, private injector: Injector, private tradingRestService: TradingRestService, private router: Router, public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet, private detector: ChangeDetectorRef,  private snackBar: MatSnackBar, private translate: TranslateService) {
      super(elRef.nativeElement, 'HoldingBook');
      holding = this;
      this.M = Mouse;
    this.searchId = 'HoldingBookView-' + (autoGenId++);
    this.isAvgPriceEditable = environment.isHoldingPriceEditable;
    this.utility = Utility;
    this.isMobileLand = Utility.IS_MOBILE_LAND;
    this.sortingDictionary.set(this.selectedSortedItem, this.chipSelected);
    this.isLoader = true;
    this.load.emit(this.isLoader);
    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      this.flashType = Preferences.getItem('flashType');
    }
    Utility.flashType.subscribe(obj => {
      this.flashType = obj;
    });
    this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
      this.changeMode();
    });

    this.getColumns()
    this.bookColumnSubscription = TableUtility.updateHoldingBookColumns.subscribe(data =>{
      this.updateColumns();
    })
    this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
      this.isMobileLand = Utility.IS_MOBILE_LAND;
    });

    this.subscription = Utility.ORDER_PLACED_EVENT.subscribe(data => {
      this.callHoldingBookService();
    });

    this.holdingPriceSubscription = Utility.holdingAvgPriceChanged.subscribe(data => {
      this.changeAvgPrice(data.model, data.price);
    });

    this.hostName = window.location.hostname;
  }
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
    this.modesub.unsubscribe();
    this.webReloadSubscription.unsubscribe();
    this.sortIconSubscription.unsubscribe();
    this.bookColumnSubscription.unsubscribe();
    this.orientationSubscription.unsubscribe();
    this.holdingPriceSubscription.unsubscribe();
  }
 
  setPositionAndSize(left: number, top: number, width: number, height: number) {
    this.container={'width': width, 'height':height}
    this.setBasePositionAndSize(left, top, width, height);
    if (width < Utility.MinWidth)
    this.cellView = true;
    else
    this.cellView = false;
    this.changeMode();
  } 
  onAppearing() {
    Utility.resumeChannel(this.tradingRestService, ChannelType.Holding);
  }

  onDisappearing() {
    Utility.pauseChannel(this.tradingRestService, ChannelType.Holding);
  }

  onPriceInputFocus(model: HoldingModel, inputField: any, id: any) {
    model.isSelected = true;
    setTimeout(() => {
        inputField.select(model.Price);
    }, 0);
  }

  onPriceDivClick(id: any, model: HoldingModel){ 
    model.isSelected = true;
    setTimeout(() => {
        document.getElementById(id).focus();
    }, 0);
  }

  changeAvgPrice(model: HoldingModel, price: any) {
      model.isSelected = false;
      price = price.replace(',', '');
      price = parseFloat(parseFloat(price).toFixed(model.DecimalPrecision));
      this.holdings.TotalInvestment -= (model.Price * model.AvailableQty);
      this.holdings.TotalInvestment += (price * model.AvailableQty);
      model.Price = price;
      const avgPriceObj = Preferences.getItem('holdingAvgPrice', ReturnType.Object);
      avgPriceObj[model.scripModel.Token + '_' + model.scripModel.ExchangeSegment] = model.Price;
      Preferences.setItem('holdingAvgPrice', avgPriceObj);
      this.tradingRestService.getBookService().calculateHoldingFields(model, this.holdings);
  }

  authorize() {
    Utility.edisAuthorize(this.holdingList,this.hostName, this.tradingRestService);
  }

  

  _keyPress(e: any) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
     return false;
    }
    if (e.which === 0) {
     return true;
    }
    if (e.which < 33) {
      return true;
    }
    if(e.which  === 46) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  sortingItemSelected(sortingItem)
  {
    if(holding.sortingDictionary === undefined || !holding.sortingDictionary.has(sortingItem))
    {
      holding.sortingDictionary.set(sortingItem, holding.matChipItems[1]);
    }
    
    if(holding.selectedSortedItem !== sortingItem && holding.sortingDictionary.has(holding.selectedSortedItem))
    {
      holding.sortingDictionary.set(holding.selectedSortedItem, holding.matChipItems[1]);
      holding.selectedSortedItem = '';
    }
  
    if(holding.sortingDictionary.get(sortingItem) as string === holding.matChipItems[0])
    {
      holding.chipSelected = holding.matChipItems[1]; 
    }
    else
    {
      holding.chipSelected = holding.matChipItems[0]; 
    }
    holding.selectedSortedItem = sortingItem;
    holding.sortingDictionary.set(sortingItem, holding.chipSelected);
    holding.filteObject = {[holding.chipSelected] : holding.selectedSortedItem};
    this.selectedFilterOption = holding.filteObject;
    holding.SortHoldingBookInMobile(holding.filteObject);
  }

  resetSortingFilter() {
    this.chipSelected = this.matChipItems[0]; 
    this.selectedFilterOption = "";
    this.selectedSortedItem = this.sortItemList[0];
    this.sortingDictionary.clear();
  }

  matMenuClosed()
  {
  }

  changeMode() {
    if (this.container?.width !== null){
      if (this.holdingList !== undefined && this.holdingList.length === 1) {
        this.totalheight = this.container?.height;
        this.Height = {
          'height': this.container?.height + 'px',
          'overflow': 'auto'
        };
      } else {
        this.totalheight = (this.container?.height - TableUtility.HBHeight);
        this.Height = {
          'height': (this.container?.height - TableUtility.HBHeight) + 'px',
          'overflow': 'auto'
        };
      }
      this.left = {
        'left': (this.container?.width - TableUtility.HBLeft) + 'px'
      };
      this.detector.detectChanges();
      if (Utility.IS_MOBILE) {
        this.dataArrLen = this.dataArr.length;
      } else {
        this.getChipWidth();
      }

      if (this.container?.width < Utility.MinWidth){
        this.Height = {
          'height': (this.container?.height - TableUtility.HBHeight - 45) + 'px',
          'overflow': 'auto'
        };
        this.cellView = true;
        if(this.dataArray) this.SortHoldingBookInMobile(this.selectedFilterOption);
      }
      else {
        this.cellView = false;
        this.filteredHoldingList.map((data: any) => {
          data.show = false;
        });
      }
    }
  }

  sortList() {
    this.filteredHoldingList = this.filteredHoldingList.sort((a, b) => (a.Symbol > b.Symbol) ? 1 : ((b.Symbol > a.Symbol) ? -1 : 0));
  }
  myRefreshEvent(event: Subject<any>, message: string) {
    setTimeout(() => {
        this.onRefresh();
        event.next();
    }, 1000);
  }
  
  alert(message: string) {
    //alert(message);
  }

  sortHoldingBook(event)
  {
      const data = Array.from(this.filteredHoldingList);
      if(event.active === 'Symbol' || event.active === 'AvailableQty')
      {
        if (event.direction === 'asc')
          this.filteredHoldingList = data.sort((a, b) => (a[event.active] > b[event.active]) ? 1 : ((b[event.active] > a[event.active]) ? -1 : 0));
        else
          this.filteredHoldingList = data.sort((a, b) => (a[event.active] < b[event.active]) ? 1 : ((b[event.active] < a[event.active]) ? -1 : 0));
      }
      else if(event.active === 'LastTradePrice')
      {
        if (event.direction === 'asc')
          this.filteredHoldingList = data.sort((a, b) =>
          (Utility.getNumber(a.scripModel[event.active]) > Utility.getNumber(b.scripModel[event.active])) ? 1 : ((Utility.getNumber(b.scripModel[event.active]) > Utility.getNumber(a.scripModel[event.active])) ? -1 : 0));
        else
          this.filteredHoldingList = data.sort((a, b) =>
          (Utility.getNumber(a.scripModel[event.active]) < Utility.getNumber(b.scripModel[event.active])) ? 1 : ((Utility.getNumber(b.scripModel[event.active]) < Utility.getNumber(a.scripModel[event.active])) ? -1 : 0));
      }
      else
      {
        if (event.direction === 'asc')
          this.filteredHoldingList = data.sort((a, b) =>
          (Utility.getNumber(a[event.active]) > Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) > Utility.getNumber(a[event.active])) ? -1 : 0));
        else
          this.filteredHoldingList = data.sort((a, b) =>
          (Utility.getNumber(a[event.active]) < Utility.getNumber(b[event.active])) ? 1 : ((Utility.getNumber(b[event.active]) < Utility.getNumber(a[event.active])) ? -1 : 0));        
      }
  }

  ngOnInit() {
    this.targetElement = document.querySelector('html');
    if (this.container) {
      /* this.container.on('resize', _ => {
        this.changeMode();
      });
      this.container.on('show', _ => {
        this.callHoldingBookService();
        setTimeout(() => {
          this.changeMode();
        }, 50);
      });*/
    }
    this.isExpandVisible = false;
    // if (Preferences.getItem('holdingColumns', ReturnType.Undefined) !== undefined && Preferences.getItem('holdingColumns') !== null)
    //   this.displayedColumns = Preferences.getItem('holdingColumns', ReturnType.Array);
    // else
    //   this.displayedColumns = ['Symbol', 'AvailableQty', 'Price', 'LastTradePrice', 'PrimaryValuation', 'NetPNL', 'DayPNL'];
    if(this.isMobile){
      this.callHoldingBookService();
    }
    this.sortIconSubscription = Utility.holdingBookSortIconClicked.subscribe(data =>
    {
        this.selectedFilterOption = data;
        this.SortHoldingBookInMobile(data);
    })
    this.webReloadSubscription = Utility.webReloadComponent.subscribe(data =>
    {
      if(holding.isReloadClick === false && data === 'Holding Book')
      {
        holding.isReloadClick = true;
        this.onRefresh();
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== 0 && event.currentIndex !== 1) {
      moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
      Preferences.setItem('holdingColumns', this.displayedColumns);
    }
  }

  SortHoldingBookInMobile(selectedFilter)
  {
    this.filteredKey = Object.keys(selectedFilter)[0];
    this.filteredValue = Object.values(selectedFilter)[0];

    if(this.filteredKey === 'Asc')
    {
        if(this.filteredValue === 'Symbol')
        {      
          this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a,b) =>
          (a['Symbol'] > b['Symbol']) ? 1 : ((b['Symbol'] > a['Symbol']) ? -1 : 0));
        }
        else if(this.filteredValue === 'Net PNL')
        {
            this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a, b) =>
            (Utility.getNumber(a['NetPNL']) > Utility.getNumber(b['NetPNL'])) ? 1 : ((Utility.getNumber(b['NetPNL']) > Utility.getNumber(a['NetPNL'])) ? -1 : 0));      
        }
        else if(this.filteredValue === 'PNL %Change')
        {
            this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a, b) =>
            (Utility.getNumber(a['PNLPercentageChg']) > Utility.getNumber(b['PNLPercentageChg'])) ? 1 : ((Utility.getNumber(b['PNLPercentageChg']) > Utility.getNumber(a['PNLPercentageChg'])) ? -1 : 0));      
        }
        else if(this.filteredValue === 'LTP')
        {
          this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a, b) =>
          (Utility.getNumber(a.scripModel['LastTradePrice']) > Utility.getNumber(b.scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.scripModel['LastTradePrice']) > Utility.getNumber(a.scripModel['LastTradePrice'])) ? -1 : 0));
        }
        else if(this.filteredValue === '%Change')
        {
          this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a, b) =>
          (Utility.getNumber(a.scripModel['PercentageChange']) > Utility.getNumber(b.scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.scripModel['PercentageChange']) > Utility.getNumber(a.scripModel['PercentageChange'])) ? -1 : 0));      
        }
        else if (this.filteredValue === 'Unsorted')
        {
          this.filteredHoldingList = this.tempFilteredHoldingList;
        }
    }
    else if(this.filteredKey === 'Desc')
    {
        if(this.filteredValue === 'Symbol')
        {      
          this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a,b) =>
          (a['Symbol'] < b['Symbol']) ? 1 : ((b['Symbol'] < a['Symbol']) ? -1 : 0));
        }
        else if(this.filteredValue === 'Net PNL')
        {
            this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a, b) =>
            (Utility.getNumber(a['NetPNL']) < Utility.getNumber(b['NetPNL'])) ? 1 : ((Utility.getNumber(b['NetPNL']) < Utility.getNumber(a['NetPNL'])) ? -1 : 0));      
        }
        else if(this.filteredValue === 'PNL %Change')
        {
            this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a, b) =>
            (Utility.getNumber(a['PNLPercentageChg']) < Utility.getNumber(b['PNLPercentageChg'])) ? 1 : ((Utility.getNumber(b['PNLPercentageChg']) < Utility.getNumber(a['PNLPercentageChg'])) ? -1 : 0));      
        }
        else if(this.filteredValue === 'LTP')
        {
          this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a, b) =>
          (Utility.getNumber(a.scripModel['LastTradePrice']) < Utility.getNumber(b.scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.scripModel['LastTradePrice']) < Utility.getNumber(a.scripModel['LastTradePrice'])) ? -1 : 0));
        }
        else if(this.filteredValue === '%Change')
        {
          this.filteredHoldingList = this.tempFilteredHoldingList.slice().sort((a, b) =>
          (Utility.getNumber(a.scripModel['PercentageChange']) < Utility.getNumber(b.scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.scripModel['PercentageChange']) < Utility.getNumber(a.scripModel['PercentageChange'])) ? -1 : 0));      
        }
        else if (this.filteredValue === 'Unsorted')
        {
          this.filteredHoldingList.reverse();
        }
    }
    else
    {
        this.filteredHoldingList = this.tempFilteredHoldingList;
    }

    if(this.filteredHoldingList.length === 0)
        this.noData = true;
    else
        this.noData = false;
  }

  addWLdata(stock) {
    const seltctedStock = stock.ExchangeSeg + '|' + stock.Token;
    this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
  }
  addStock(wl, stockdata) {
    const seltctedStock = stockdata.ExchangeSeg + '|' + stockdata.Token;
    WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
      if (result && msg === 'Success') {
        this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
      } else if (result && msg === 'Fail') {
        this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
      } else {
        this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
      }
    }.bind(this));
  }
  callHoldingBookService() {
    this.load.emit(true);
    this.resetSortingFilter();
    this.tradingRestService.getBookService().getAllHoldings(function(holdings, code, message) {
      this.isLoader = false;
      holding.isReloadClick = false;
      this.load.emit(this.isLoader);
      if (code === 200 && message === 'Ok') {
        this.noData = false;
        this.holdings = holdings;
        this.holdingList = holdings.HoldingsModel;
        this.filteredHoldingList = this.holdingList;
        this.totalScripCount.emit(this.holdingList.length);
        this.tempFilteredHoldingList = this.holdingList;
        this.dataArray = Array.from(this.filteredHoldingList);
        this.ProcessTags();
        if (this.isCustomDashboard) {
          this.changeMode();
        }
        if(this.isReloadData)
        {
          this.isReloadData = false;
          if (this.currentTabData)
            this.onTabChange(this.currentTabData['tabData'], this.currentTabData['type'], this.currentTabData['index']);
          else
            this.onTabChange('ALL', 'All', null);
        }
        if (this.filteredHoldingList.length > 0 && !Utility.IS_MOBILE) {
          this.highlight(this.filteredHoldingList[0], 0)
        }
      }
      else {
        this.noData = true;
      }
    }.bind(this), null);
  }

  onRefresh() {
    this.isReloadData = true;
    this.isLoader = true;
    this.load.emit(this.isLoader);
    this.detector.detectChanges();
    this.callHoldingBookService();
  }
  ProcessTags() {
    const resultMap = new Map();
    let obj = {};
    this.dataArr = [];
    const symbolArr = [];
    const exchangeArr = [];
    this.dataArr = [];

    if (this.holdingList.length > 1) {
      for (let i = 0; i < this.holdingList.length; i++) {
        if (this.holdingList[i].Exchange != null) {
          if (!resultMap.has(this.holdingList[i].Exchange)) {
            resultMap.set(this.holdingList[i].Exchange, true);
            obj = {};
            obj['data'] = this.holdingList[i].Exchange;
            obj['type'] = 'Exchange';
            obj['count'] = 1;
            obj['visibility'] = false;

            exchangeArr.push(obj);
          }

          else {
            for (let j = 0; j < exchangeArr.length; j++) {
              if (exchangeArr[j]['data'] === this.holdingList[i].Exchange) {
                exchangeArr[j]['count'] = exchangeArr[j]['count'] + 1;
              }
            }
          }
        }

        if (this.holdingList[i].Symbol != null) {
          if (!resultMap.has(this.holdingList[i].Symbol)) {
            resultMap.set(this.holdingList[i].Symbol, true);
            obj = {};
            obj['data'] = this.holdingList[i].Symbol;
            obj['type'] = 'Symbol';
            obj['count'] = 1;
            obj['visibility'] = false;

            symbolArr.push(obj);
          }

          else {
            for (let j = 0; j < symbolArr.length; j++) {
              if (symbolArr[j]['data'] === this.holdingList[i].Symbol) {
                symbolArr[j]['count'] = symbolArr[j]['count'] + 1;
              }
            }
          }
        }
      }
      if (exchangeArr.length > 1)
        this.processChipData(exchangeArr.sort(this.compare.bind(this)));
      if (symbolArr.length > 1)
        this.processChipData(symbolArr.sort(this.compare.bind(this)));

      for (let i = 0; i < this.holdingList.length; i++) {
        if (this.holdingList[i].Btst > 0) {
            const dataObj = {};
            dataObj['data'] = 'T + 1';
            dataObj['type'] = 'Btst';
            dataObj['count'] = 1;
            dataObj['visibility'] = false;
            this.dataArr.splice(0, 0, dataObj);
            break;
        }
      }

      if (Utility.IS_MOBILE) {
        this.dataArrLen = this.dataArr.length;
      } else {
        this.getChipWidth();
      }
      this.sortList();
      this.dataArray = Array.from(this.filteredHoldingList);
    }
  }
  exportCSV(){
    if (this.selectedLabel === 'ALL')
      CSVUtility.ExportDataToCSV(this.holdingList, 'HoldingBook Report', 'Holding Book','Holding Book Data');
    else {
      const dialogRef = this.dialog.open(MyAlertDialogComponent,
        { data: { heading: this.translate.instant('all.export_hed'), message: this.translate.instant('all.export_msg'),
         disableClose: true,  fromCSV: true}});
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm') 
          CSVUtility.ExportDataToCSV(this.filteredHoldingList, 'HoldingBook Report', 'Holding Book','Holding Book Data');
        else if (result === 'cancel') 
          CSVUtility.ExportDataToCSV(this.holdingList, 'HoldingBook Report', 'Holding Book','Holding Book Data');
      })
    }
  }
  processChipData(dataArr) {
    for (let i = 0; i < dataArr.length; i++) {
      this.dataArr.push(dataArr[i]);
    }
  }

  getChipWidth() {
    setTimeout(() => {
      if (this.sortMenu !== undefined) {
        this.expandText  = 'More';
        const wrapperWidth = this.container?.width - Utility.getChipLetterWidth('ALL') - this.sortMenu.nativeElement.scrollWidth;
        let WidCount = 0;
        let width = 0;
        this.widthArr = [];
        for (let i = 0; i < this.dataArr.length; i++) {
          width = Utility.getChipLetterWidth(this.dataArr[i].data);
          WidCount = WidCount + width;
          if (WidCount < wrapperWidth - 75 ) {
            this.widthArr.push(this.dataArr[i]);
            this.isExpandVisible = false;
            this.dataArrLen = this.widthArr.length;
          }
          else {
            this.dataArrLen = this.widthArr.length;
            this.isExpandVisible = true;
            break;
          }
        }
      }
    }, 200);
  }

  compare(a, b) {
    const count1 = a.count;
    const count2 = b.count;
    let comparison = 0;
    if (count1 < count2) {
      comparison = 1;
    } else if (count1 > count2) {
      comparison = -1;
    }
    return comparison;
  }

  onExpanClick() {
    this.isExpand = !this.isExpand;
    if (this.isExpand) {
      this.expandText = 'Less';
      this.hideDownload = true;  
    } else {
      this.expandText = 'More';
      this.hideDownload = false;
    }
    this.dataArrLen = (this.isExpand && this.dataArr) ? this.dataArr.length : this.widthArr.length - 1;
  }


  onClickPortfolioData(data) {
    Utility.holdingDetails = data;
    Utility.holdings = this.holdingList;
    
    if (this.isMobile) {
      this._bottomSheet.open(PortfolioMoreOptionComponent, {
        restoreFocus: false
      });
    }
  }

  onTabChange(tabData, type, index) {
    const tabType = type;
    const data = tabData;
    this.selectedLabel = '';
    this.filteredHoldingList = [];
    let filterArr = [];
    this.uniqueMap.clear();
    if (data !== 'ALL') {
      if (this.dataArr[index]['visibility'] === true) {
        this.dataArr[index]['visibility'] = false;
      }
      else {
        this.dataArr[index]['visibility'] = true;
        this.indexArray.push(index);
      }
    }
    else {
      this.indexArray.forEach(arrData => {
        if (this.dataArr[arrData]['visibility'] === true)
          this.dataArr[arrData]['visibility'] = false;
      });
    }

    if (this.dataArr && data !== 'ALL') {
      filterArr = this.dataArr.filter(obj => {
        return obj['visibility'] === true;
      });
    }

    if (filterArr.length > 0) {

      filterArr.forEach(arrData => {
        this.uniqueMap.set(arrData['type'], true);
      });

      for (let i = 0; i < this.holdingList.length; i++) {
        let count = 0;
        filterArr.forEach(arrData => {
          if (arrData['data'] === this.holdingList[i][arrData['type']] || (arrData['data'] === 'T + 1' && this.holdingList[i][arrData['type']] > 0)) {
            count++;
          }
        });

        if (count === this.uniqueMap.size) {
          this.filteredHoldingList.push(this.holdingList[i]);
        }
      }
    }
    else {
      this.selectedLabel = 'ALL';
      this.filteredHoldingList = this.holdingList;
    }
    this.tempFilteredHoldingList = this.filteredHoldingList;
    this.noDataCheck();
    this.sortList();
    if((this.selectedSortedItem !== this.sortItemList[0] || (this.selectedSortedItem === this.sortItemList[0] && this.chipSelected === this.matChipItems[1])) && (this.cellView || this.isMobile))
      this.SortHoldingBookInMobile(this.selectedFilterOption);
    if (this.filteredHoldingList.length > 0 && !Utility.IS_MOBILE)
      this.highlight(this.filteredHoldingList[0], 0);
  }

  noDataCheck() {
    if (this.filteredHoldingList.length === 0) {
      this.noData = true;
    }
    else {
      this.noData = false;
      this.isFilterNoData = true;
    }
  }

  handleButton(buttonType, data) {
    Utility.holdingDetails = data;
    this.filteredHoldingList.map((data: any) => {
      data.show = false;
    })
    switch (buttonType) {
      case 'NSE':
      case 'BSE':
        let exseg;
        if (buttonType === 'NSE') {
          exseg = 'nse_cm';
        } else {
          exseg = 'bse_cm';
        }
        const dataobj = { 'isSquareOff': true, 'exseg': exseg, 'action': 'S' };
        Utility.openPage(this.router, 'portfolio', 'placeorder', PlaceorderComponent, this.dialog, 'popup', dataobj, '500px', '1250px');
        break;
      case 'ADD':
        const data_obj1 = { 'add': true, 'Exchange': data.Exchange, 'Token': data.Token, 'OrderType': 'L', 'ProductCode': data.Pcode };
        Utility.openPage(this.router, 'portfolio', 'placeorder', PlaceorderComponent, this.dialog, 'popup', data_obj1, '500px', '1250px');
        break;
      case 'REMOVE':
        this.edisCheck = this.isEdisEnable ? Utility.edisAuthorizationCheck(data.Pcode, (data.TotalHoldingQty - data.Usedqty), 'S', data.Token, data.ExchangeSeg, data.Usedqty) : true;
        if (this.edisCheck){
          const data_obj2 = { 'remove': true, 'Exchange': data.Exchange, 'Token': data.Token, 'OrderType': 'L', 'ProductCode': data.Pcode, 'qty': (data.Holdqty - data.Usedqty) };
          Utility.openPage(this.router, 'orderDetail', 'placeorder', PlaceorderComponent, this.dialog, 'popup', data_obj2, '500px', '1250px');
        }
        else{
          this.authorize();
        }
        break;
      case 'sd':
        // const obj = { 'token': data.Token, 'exch': data.ExchangeSeg, 'symbol': data.Symbol };
        // this.openStockDetailPage.emit(JSON.stringify(obj));
        const object = { 'isCustomDashboard': true, 'fromMultiView': true, 'fromStockDetail': true, 'isHeader': true, 'MVtype': 'stock', 'backbtnEnable': false, 'IsStockSpecific': false, 'c_name': 'holdingbook', 'token': data.Token, 'exch': data.ExchangeSeg, 'symbol': data.Symbol, 'series': data.Series1 };
                Utility.SELECTED_SCRIP = Utility.getScripModel(data.Token, data.ExchangeSeg);
                this.outputs.emit(object);
        break;
      case 'BUY':
          Utility.openPlaceOrder(this.router, data.Token, data.ExchangeSeg, 'B', this.dialog);
        break;

      case 'SELL':
        this.edisCheck = this.isEdisEnable ? Utility.edisAuthorizationCheck(data.Pcode, (data.TotalHoldingQty - data.Usedqty), 'S', data.Token, data.ExchangeSeg, data.Usedqty) : true;
        if (this.edisCheck){
          Utility.openPlaceOrder(this.router, data.Token, data.ExchangeSeg, 'S', this.dialog);
        }
        else{
          this.authorize();
        } 
        break;
    }
  }

  handleMouseOver(row) {
    const Symbolname = row.Symbol;
    this.filteredHoldingList.map((data: any) => {
      if (Symbolname && data.Symbol && data.Symbol === Symbolname) {
        data.show = true;
      }
    });
  }

  handleMouseLeave(row) {
    const Symbolname = row.Symbol;
    this.filteredHoldingList.map((data: any) => {
      if (data.Symbol === Symbolname) {
        data.show = false;
      }
    });
  }

  highlight(row: any, i: number){
    Mouse.IS_MOUSE = false;
    if(this.selectedRowIndex !== i && !Utility.IS_MOBILE) {
      let element:HTMLElement;
      if(this.cellView) {
        element = document.getElementById("hb_cell"+i) as HTMLElement;
      }
      else {
        element = document.getElementById("hb_tbl"+i) as HTMLElement;
      }
      element?.focus();
    }
    this.selectedRowIndex = i;
    Utility.SELECTED_HOLDING = row;
    Utility.SELECTED_SCRIP = row.scripModel;
    Utility.selectedTab = TableUtility.holdingBook;
    if(this.isMobile) {
      this.onClickPortfolioData(this.filteredHoldingList[i]);
    }
  }

  openManageBook()
  {
    if (this.isMobile) {
      Utility.selectedPortfolioTab = 1;
      this.router.navigate(['/managebooks'], { queryParams: {'name': 'holdingbook'}});
    } else {
      this.openManageBooks.emit('holdingbook');
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (Utility.IS_MOBILE_LAND && this.selectedTabIndex === 1) {
      if(window.scrollY > (TableUtility.BOOK_HEADER_HEIGHT + this.sortMenu?.nativeElement.scrollHeight + 
        this.pnlHeader?.nativeElement.scrollHeight) && this.isDHeight){
        this.DHeight = {
          'height': '100vh',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };
        let element:HTMLElement;
        element = document.getElementById("hb_tbl1") as HTMLElement;
        element?.focus();
        this.isDHeight = false;
      }
      else if(window.scrollY < (TableUtility.BOOK_HEADER_HEIGHT + this.sortMenu?.nativeElement.scrollHeight + 
        this.pnlHeader?.nativeElement.scrollHeight) ){
        this.DHeight = {
          'height': '100%',
          'overflow': 'auto',
          'scroll-behavior': 'smooth'
        };
        this.isDHeight = true;
      }
    }
  }

  getColumns()
  {
    let tempColumns = Preferences.getItem('holdingBookColumns', ReturnType.Undefined);
    var found = false;
    if(tempColumns){
      for(var i = 0; i < tempColumns.length; i++) {
        if (tempColumns[i].key == 'Invest') {
            found = true;
            break;
        }
      }
    }
    if(!found){
      tempColumns = undefined;
    }
    if (!tempColumns)
    {
      tempColumns = JSON.parse(JSON.stringify(TableUtility.holdingBookColumns));
      Preferences.setItem('holdingBookColumns', tempColumns);
    }
    this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
  }

  updateColumns()
  {
    let tempColumns = Preferences.getItem('holdingBookColumns', ReturnType.Undefined);
    this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
  }

  @HostListener('window:keydown.arrowdown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    //if (Utility.selectedTab === TableUtility.holdingBook && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Holding Book', this.workspaceName)) {
    if (Utility.selectedTab === TableUtility.holdingBook && !Utility.isDialogOpened && !this.isMobile) {
      event.preventDefault();
      if(((this.selectedRowIndex + 1) < this.filteredHoldingList.length)) {
        var nextrow = this.filteredHoldingList[this.selectedRowIndex + 1];
        this.highlight(nextrow, this.selectedRowIndex + 1);
      } else {
      this.highlight(this.filteredHoldingList[0], 0);
    }
    }
  }

  @HostListener('window:keydown.arrowup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    //if (Utility.selectedTab === TableUtility.holdingBook && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Holding Book', this.workspaceName)) {
    if (Utility.selectedTab === TableUtility.holdingBook && !Utility.isDialogOpened && !this.isMobile) {
      event.preventDefault();
      if(((this.selectedRowIndex - 1) >= 0)) {
        var nextrow = this.filteredHoldingList[this.selectedRowIndex - 1];
        this.highlight(nextrow, this.selectedRowIndex - 1);
      }  else {
        this.highlight(this.filteredHoldingList[this.filteredHoldingList.length - 1], this.filteredHoldingList.length - 1);
      }
    }
  }
  setBaseVisibility(visible: boolean){
    if(visible){
      this.callHoldingBookService();
      setTimeout(() => {
        this.changeMode();
        this.onAppearing();
      }, 50);
    }
    else{
        this.onDisappearing();
    }
  }
}
function getTransactionData() {
  throw new Error('Function not implemented.');
}

