import { Injectable } from '@angular/core';
import { Utility } from '../utility';
import { Url } from '../url';
import { environment } from 'src/environments/environment';
declare const HSWebSocket: any;
let websocketService: WebsocketService;
@Injectable()
export class WebsocketService {

    private webSocket: any;
    isOpenConn = false;
    constructor() {
        websocketService = this;
        try {
            if (environment.isHypersync)
                websocketService.webSocket = HSWebSocket.getInstance();    
            else
                websocketService.webSocket = new WebSocket(environment.tradewss);
        } catch ( error ) {
            Utility.print('======socket Exception======');
        }
    }

    public Connect(ConnectionCallback) {
            websocketService.isOpenConn = false;
            if (environment.isHypersync)
                websocketService.webSocket.connect(environment.tradewss);    
            websocketService.webSocket.onopen = function() {
                websocketService.isOpenConn = true;
                ConnectionCallback('open');
            };

            websocketService.webSocket.onmessage = function(event) {
                ConnectionCallback('message', event);
            };

            websocketService.webSocket.onclose = function() {
                ConnectionCallback('close');
            };
            websocketService.webSocket.onerror = function(event) {
                ConnectionCallback('error');
            };
    }

    public sendMessage(data) {
        if (websocketService.webSocket !== null && data !== undefined) {
            if (websocketService.webSocket.readyState === 1) {                
                const str = JSON.stringify(data);
                try {
                    websocketService.webSocket.send(str);
                } catch (err) {
                    Utility.print('exception in sendMessage');
                }
            }
        }
    }

    public closeSocketConnection()
    {
        try {
            if (websocketService.webSocket != null) {
                websocketService.webSocket.close();
                websocketService.webSocket = null;
            }
        } catch (err) {
            Utility.print(err);
        }
    }

    public SocketStatus() {
        if (websocketService.webSocket !== null) {
            return websocketService.webSocket.OPEN;
        } else {
          return false;
        }
    }
}
