import { OnInit, Component, Inject, Input, ChangeDetectorRef, Injector, OnDestroy, Output, EventEmitter, HostListener, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { TradingRestService } from '../../../services/tradingrest.service';
import { PositionModel } from 'src/app/model/position.model';
import { CombinePositionModel } from 'src/app/model/combineposition.model';
import { Router } from '@angular/router';
import { ChannelType, Utility } from 'src/app/services/utility';
import { IScripService } from '../../../services/scrip/iscrip.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PositionMoreOptionComponent } from './positionmoreoption/positionmoreoption.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PartialPositionConvertionComponent } from '../partialpositionconvertion/partialpositionconvertion.component';
import { PlaceorderComponent } from '../placeorder/placeorder.component';
import { ComponentContainer } from 'golden-layout';
import { Subscription, Subject } from '../../../../../node_modules/rxjs';
import * as $ from 'jquery';
import { TableUtility } from 'src/app/services/tableutility';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { Positions } from 'src/app/model/positionsummary.model';
import { PositiondetailComponent } from 'src/app/view/protradelite/positiondetail/positiondetail.component';
import { WatchListUtility } from 'src/app/services/watchlistUtility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ReturnType, Preferences } from 'src/app/services/Preferences';
import { CSVUtility } from 'src/app/services/CSVUtiity';
import { MyAlertDialogComponent } from '../home/watchlist/watchlist.component';
import { Mouse } from 'src/app/services/mouse';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { environment } from 'src/environments/environment';
import { PositionBreakupPopupComponent } from './positionbreakuppopup/positionbreakuppopup.component';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { PlaceOrderModel } from 'src/app/model/place.order.model';

let autoGenId = 0;
let positionBook : PositionBookComponent;
@Component({
    selector: 'app-positionbook',
    templateUrl: (environment.isInteropsEnabled)?'../../'+environment.baseviewpath+'/positionbook/interops.positionbook.component.html':'../../'+environment.baseviewpath+'/positionbook/positionbook.component.html',
    styleUrls: ['./positionbook.component.scss']
})

export class PositionBookComponent  extends BaseComponentDirective implements OnInit, OnDestroy, OnChanges {
    targetElement: Element;
    @Input() isCustomDashboard: boolean;
    @Output() load = new EventEmitter<boolean>();
    @Output() openStockDetailPage = new EventEmitter<string>();
    @ViewChild('menuTrigger') matMenuTriggerClose;
    @ViewChild('sortMenu') sortMenu;
    @ViewChild('pnlHeader') pnlHeader;
    @Input() workspaceName: string;
    @Input() selectedTabIndex: any;
    @Output() totalScripCount = new EventEmitter<number>();
    isLoading: boolean;
    expandText = 'More';
    hideDownload = false;
    isMobile = true;
    combinePositionModelList: Array<CombinePositionModel> = [];
    searchId;
    isNoData: boolean;
    noDataMessage = '';
    positions: Positions = new Positions;
    positionList: Array<PositionModel>;
    interopsPositionList : Array<CombinePositionModel> = [];
    dataArray : Array<PositionModel> = [];
    dataArrayIntrops : Array<CombinePositionModel> = [];
    filteredPositionList: Array<PositionModel> = [];
    dataArr = [];
    combineDataArr = [];
    combineDataArrLen;
    selectedLabel = 'ALL';
    selectedInteropsLabel = 'ALL';
    selectedType = 'Overall';
    jsonSendObj = {};
    dayTab = { 'data': 'Today', 'type': '', 'visibility': false };
    netTab = { 'data': 'Overall', 'type': '', 'visibility': true };
    isFirstTabChange = true;
    isExpandVisible: boolean;
    isExpand = false;
    dataArrLen = 0;
    widthArr = [];
    utility: any;
    indexArray = [];
    indexCombineArray = [];
    uniqueMap = new Map();
    uniqueCombineMap = new Map();
    isFilterNoData: boolean;
    selectedFilter = 'ALL';
    subscription: Subscription;
    bookDataSubscription: Subscription;
    webReloadSubscription: Subscription;
    sortIconSubscription: Subscription;
    bookColumnSubscription: Subscription;
    orientationSubscription : Subscription;
    displayedColumns;
    scripService: IScripService;
    Height: any;
    DHeight: {};
    isDHeight = true;
    container;
    left: any;
    modesub: Subscription;
    currentTabData;
    currentCombineTabData;
    cellView: boolean;
    isMobileLand;
    WlArr = [];
    selectedFilterOption = '';
    tempFilteredPositionList: Array<PositionModel> = [];
    isReloadData = false;
    filteredKey : any;
    filteredValue : any;
    isReload = false;
    selectedRowIndex : any;
    totalheight : any;
    M;
    combinePositionMap;
    checkCombineCalc;
    matChipItems = ['Asc', 'Desc'];
    sortItemList = ['Unsorted', 'Symbol','LTP', '%Change'];
    chipSelected = this.matChipItems[0];
    selectedSortedItem = this.sortItemList[0];
    filteObject : any;
    flashType = 'defaultFlash';
    sortingDictionary = new Map<any, any>();
    tempFilterCombinePositionList;
    @Output() openManageBooks = new EventEmitter<string>();
    checkedPositionArr = [];
    checkedPositionCount = 0;
    positionCount;
    isPositionSquaerOff = environment.isPositionSquaerOff;
    isVisible = true;

    constructor(elRef: ElementRef, private injector: Injector, private tradingRestService: TradingRestService, private router: Router, public dialog: MatDialog,
        private _bottomSheet: MatBottomSheet, private detector: ChangeDetectorRef,  private snackBar: MatSnackBar, private translate: TranslateService) {
        super(elRef.nativeElement, 'PositionBook');
        positionBook = this;
        this.M = Mouse;
        this.searchId = 'PositionBookView-' + (autoGenId++);
        this.isLoading = true;
        this.utility = Utility;
        this.isMobile = Utility.IS_MOBILE;
        this.isMobileLand = Utility.IS_MOBILE_LAND;
        this.sortingDictionary.set(this.selectedSortedItem, this.chipSelected);
        if (Preferences.getItem('flashType', ReturnType.Undefined)) {
            this.flashType = Preferences.getItem('flashType');
          }
        this.subscription = Utility.ORDER_PLACED_EVENT.subscribe(data => {
            this.callPositionBookService();
        });
        this.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            this.changeMode();
        });
        Utility.flashType.subscribe(obj => {
            this.flashType = obj;
          });
        this.bookDataSubscription = Utility.positionBook.subscribe(data =>{
            if(data == undefined)
                this.isReloadData = true;
            this.callPositionBookService();
        })
        this.orientationSubscription = Utility.orientationChanged.subscribe(() => {
            this.isMobileLand = Utility.IS_MOBILE_LAND;
        });
        this.webReloadSubscription = Utility.webReloadComponent.subscribe(data =>
        {
            if(positionBook.isReload === false && data === 'Position Book')
            {
                positionBook.isReload = true;
                this.isReloadData = true;
                this.callPositionBookService();
            }
        });

        Utility.positionBookData.subscribe(obj => {
            const foundIndex = this.positionList.findIndex(x => x.Token === obj.Token && x.Exchangeseg === obj.Exchangeseg && x.Pcode === obj.Pcode);
            if (foundIndex !== -1)
                this.positionList.splice(foundIndex, 1);
            this.positionList.push(obj);
            if (this.currentTabData)
                this.onTabChange(this.currentTabData.tabData, this.currentTabData.type, this.currentTabData.index);
            else
                this.onTabChange('ALL', 'All', null);
        });

        this.sortIconSubscription = Utility.positionBookSortIconClicked.subscribe(data =>
        {
            this.selectedFilterOption = data;
            this.SortPositionBookInMobile(data);
        })

        this.getColumns()
        this.bookColumnSubscription = TableUtility.updatePositionBookColumns.subscribe(data =>{
            this.updateColumns();
        })

    }
    ngOnChanges(changes: SimpleChanges): void {
        if(this.isMobile && this.selectedTabIndex === 0){
            this.callPositionBookService();
        }
    }
    exportCSV(){
        if (this.selectedLabel === 'ALL')
            CSVUtility.ExportDataToCSV(this.positionList, 'PositionBook Report', 'Position Book','Position Book Data');
        else {
            const dialogRef = this.dialog.open(MyAlertDialogComponent,
                { data: {heading: this.translate.instant('all.export_hed'), message: this.translate.instant('all.export_msg'),
                 disableClose: true,  fromCSV: true}});
            dialogRef.afterClosed().subscribe(result => {
                if (result === 'confirm')
                    CSVUtility.ExportDataToCSV(this.filteredPositionList, 'PositionBook Report', 'Position Book','Position Book Data');
                else if (result === 'cancel')
                    CSVUtility.ExportDataToCSV(this.positionList, 'PositionBook Report', 'Position Book','Position Book Data');
            })
        }
    }

   
    setPositionAndSize(left: number, top: number, width: number, height: number) {
        this.container={'width': width, 'height':height}
        this.setBasePositionAndSize(left, top, width, height);
        if (width < Utility.MinWidth)
        this.cellView = true;
        else
        this.cellView = false;
        this.changeMode();
      }

    exportCombineCSV(){
        let exportList = []; 
        if (this.selectedInteropsLabel === 'ALL'){
            this.interopsPositionList.forEach((data) => {
                exportList = data['list'];
            });
            CSVUtility.ExportDataToCSV(exportList, 'PositionBook Report', 'Position Book','Position Book Data');
        }
    else {
        const dialogRef = this.dialog.open(MyAlertDialogComponent,
            { data: { heading: this.translate.instant('all.export_hed'), message: this.translate.instant('all.export_msg'),
             disableClose: true,  fromCSV: true}});
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirm'){
                this.combinePositionModelList.forEach((data) => {
                    exportList = data['list'];
                });
                CSVUtility.ExportDataToCSV(exportList, 'PositionBook Report', 'Position Book','Position Book Data');
            }
            else if (result === 'cancel'){
                this.interopsPositionList.forEach((data) => {
                    exportList = data['list'];
                });
                CSVUtility.ExportDataToCSV(exportList, 'PositionBook Report', 'Position Book','Position Book Data');
            }
        });
    }
    }

    sortingItemSelected(sortingItem)
    {
        if (positionBook.sortingDictionary === undefined || !positionBook.sortingDictionary.has(sortingItem))
        {
            positionBook.sortingDictionary.set(sortingItem, positionBook.matChipItems[1]);
        }

        if (positionBook.selectedSortedItem !== sortingItem && positionBook.sortingDictionary.has(positionBook.selectedSortedItem))
        {
            positionBook.sortingDictionary.set(positionBook.selectedSortedItem, positionBook.matChipItems[1]);
            positionBook.selectedSortedItem = '';
        }

        if (positionBook.sortingDictionary.get(sortingItem) as string === positionBook.matChipItems[0])
        {
            positionBook.chipSelected = positionBook.matChipItems[1]; 
        }
        else
        {
            positionBook.chipSelected = positionBook.matChipItems[0]; 
        }
        positionBook.selectedSortedItem = sortingItem;
        positionBook.sortingDictionary.set(sortingItem, positionBook.chipSelected);
        positionBook.filteObject = {[positionBook.chipSelected] : positionBook.selectedSortedItem};
        this.selectedFilterOption = positionBook.filteObject;
        if (environment.isInteropsEnabled){
            positionBook.SortCombinePositionBookInMobile(positionBook.filteObject);
        }
        else{
            positionBook.SortPositionBookInMobile(positionBook.filteObject);
        }
    }

    matMenuClosed()
    {
    }

    sortPositionBook(event)
    {
        const data = Array.from(this.filteredPositionList);
        if(event.active === 'LastTradePrice')
        {
            if (event.direction === 'asc')
            this.filteredPositionList = data.sort((a, b) =>
            (Utility.getNumber(a.scripModel[event.active]) > Utility.getNumber(b.scripModel[event.active])) ? 1 : ((Utility.getNumber(b.scripModel[event.active]) > Utility.getNumber(a.scripModel[event.active])) ? -1 : 0));
            else
            this.filteredPositionList = data.sort((a, b) =>
            (Utility.getNumber(a.scripModel[event.active]) < Utility.getNumber(b.scripModel[event.active])) ? 1 : ((Utility.getNumber(b.scripModel[event.active]) < Utility.getNumber(a.scripModel[event.active])) ? -1 : 0));
        }
        else 
        {
          if (event.direction === 'asc')
            this.filteredPositionList = data.sort((a, b) => (a[event.active] > b[event.active]) ? 1 : ((b[event.active] > a[event.active]) ? -1 : 0));
          else
            this.filteredPositionList = data.sort((a, b) => (a[event.active] < b[event.active]) ? 1 : ((b[event.active] < a[event.active]) ? -1 : 0));
        }
    }

    sortCombinePositionBook(event)
    {
        let filteredList = [];
        let tempFilteredList = [];

        tempFilteredList = this.combinePositionModelList;
        filteredList = this.combinePositionModelList;

        if(event.active === 'LastTradePrice')
        {
            if (event.direction === 'asc'){
                filteredList = tempFilteredList.slice().sort((a, b) =>
               (Utility.getNumber(a.list[0].scripModel[event.active]) > Utility.getNumber(b.list[0].scripModel[event.active])) ? 1 : ((Utility.getNumber(b.list[0].scripModel[event.active]) > Utility.getNumber(a.list[0].scripModel[event.active])) ? -1 : 0));
            }
            else{
                filteredList = tempFilteredList.slice().sort((a, b) =>
              (Utility.getNumber(a.list[0].scripModel[event.active]) < Utility.getNumber(b.list[0].scripModel[event.active])) ? 1 : ((Utility.getNumber(b.list[0].scripModel[event.active]) < Utility.getNumber(a.list[0].scripModel[event.active])) ? -1 : 0));
            }
        }
        else
        {
          if (event.direction === 'asc'){
            filteredList = tempFilteredList.slice().sort((a, b) =>
            (Utility.getNumber(a.list[0][event.active]) > Utility.getNumber(b.list[0][event.active])) ? 1 : ((Utility.getNumber(b.list[0][event.active]) > Utility.getNumber(a.list[0][event.active])) ? -1 : 0));
          }
          
          else{
            filteredList = tempFilteredList.slice().sort((a, b) =>
            (Utility.getNumber(a.list[0][event.active]) < Utility.getNumber(b.list[0][event.active])) ? 1 : ((Utility.getNumber(b.list[0][event.active]) < Utility.getNumber(a.list[0][event.active])) ? -1 : 0));
          }
        }
        this.combinePositionModelList = filteredList;
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
      if (Utility.IS_MOBILE_LAND && this.selectedTabIndex === 0) {
        if(window.scrollY > (TableUtility.BOOK_HEADER_HEIGHT + this.sortMenu?.nativeElement.scrollHeight + 
            this.pnlHeader?.nativeElement.scrollHeight)  && this.isDHeight){
          this.DHeight = {
            'height': '100vh',
            'overflow': 'auto',
            'scroll-behavior': 'smooth'
          };
          let element:HTMLElement;
          element = document.getElementById("pb_tbl1") as HTMLElement;
          element?.focus();
          this.isDHeight = false;
        }
        else if(window.scrollY < (TableUtility.BOOK_HEADER_HEIGHT + this.sortMenu?.nativeElement.scrollHeight + 
            this.pnlHeader?.nativeElement.scrollHeight)){
          this.DHeight = {
            'height': '100%',
            'overflow': 'auto',
            'scroll-behavior': 'smooth'
          };
          this.isDHeight = true;
        }
      }
    }

    SortPositionBookInMobile(selectedFilter)
    {
        this.filteredKey = Object.keys(selectedFilter)[0];
        this.filteredValue = Object.values(selectedFilter)[0];

        if(this.filteredKey === 'Asc')
        {
            if(this.filteredValue === 'Symbol')
            {
              this.filteredPositionList = this.tempFilteredPositionList.slice().sort((a,b) =>
              (a['Tsym'] > b['Tsym']) ? 1 : ((b['Tsym'] > a['Tsym']) ? -1 : 0));
            }
            else if (this.filteredValue === 'MtoM')
            {
                this.filteredPositionList = this.tempFilteredPositionList.slice().sort((a, b) =>
                (Utility.getNumber(a['MtoM']) > Utility.getNumber(b['MtoM'])) ? 1 : ((Utility.getNumber(b['MtoM']) > Utility.getNumber(a['MtoM'])) ? -1 : 0));      
            }
            else if (this.filteredValue === 'LTP')
            {
              this.filteredPositionList = this.tempFilteredPositionList.slice().sort((a, b) =>
              (Utility.getNumber(a.scripModel['LastTradePrice']) > Utility.getNumber(b.scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.scripModel['LastTradePrice']) > Utility.getNumber(a.scripModel['LastTradePrice'])) ? -1 : 0));
            }
            else if (this.filteredValue === '%Change')
            {
              this.filteredPositionList = this.tempFilteredPositionList.slice().sort((a, b) =>
              (Utility.getNumber(a.scripModel['PercentageChange']) > Utility.getNumber(b.scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.scripModel['PercentageChange']) > Utility.getNumber(a.scripModel['PercentageChange'])) ? -1 : 0));      
            }
            else if (this.filteredValue === 'Unsorted')
            {
              this.filteredPositionList = this.tempFilteredPositionList;
            }
        }
        else if(this.filteredKey === 'Desc')
        {
            if(this.filteredValue === 'Symbol')
            {      
              this.filteredPositionList = this.tempFilteredPositionList.slice().sort((a,b) =>
              (a['Tsym'] < b['Tsym']) ? 1 : ((b['Tsym'] < a['Tsym']) ? -1 : 0));
            }
            else if(this.filteredValue === 'MtoM')
            {
                this.filteredPositionList = this.tempFilteredPositionList.slice().sort((a, b) =>
                (Utility.getNumber(a['MtoM']) < Utility.getNumber(b['MtoM'])) ? 1 : ((Utility.getNumber(b['MtoM']) < Utility.getNumber(a['MtoM'])) ? -1 : 0));      
            }
            else if(this.filteredValue === 'LTP')
            {
              this.filteredPositionList = this.tempFilteredPositionList.slice().sort((a, b) =>
              (Utility.getNumber(a.scripModel['LastTradePrice']) < Utility.getNumber(b.scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.scripModel['LastTradePrice']) < Utility.getNumber(a.scripModel['LastTradePrice'])) ? -1 : 0));
            }
            else if(this.filteredValue === '%Change')
            {
              this.filteredPositionList = this.tempFilteredPositionList.slice().sort((a, b) =>
              (Utility.getNumber(a.scripModel['PercentageChange']) < Utility.getNumber(b.scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.scripModel['PercentageChange']) < Utility.getNumber(a.scripModel['PercentageChange'])) ? -1 : 0));      
            }
            else if (this.filteredValue === 'Unsorted')
            {
              this.filteredPositionList.reverse();
            }
        }
        else
        {
            this.filteredPositionList = this.tempFilteredPositionList;
        }

        if(this.filteredPositionList.length === 0)
            this.isNoData = true;
        else
            this.isNoData = false;
    }

    SortCombinePositionBookInMobile(selectedFilter)
    {
        this.filteredKey = Object.keys(selectedFilter)[0];
        this.filteredValue = Object.values(selectedFilter)[0];
        // const originalInteropsList = Array.from(this.combinePositionModelList);
            
        if (this.filteredKey === 'Asc')
        {
            if(this.filteredValue === 'Symbol')
            {
                this.combinePositionModelList = this.tempFilterCombinePositionList.slice().sort((a,b) =>
                (a['Tsym'] > b['Tsym']) ? 1 : ((b['Tsym'] > a['Tsym']) ? -1 : 0));
            }
            else if (this.filteredValue === 'LTP')
            {
                this.combinePositionModelList = this.tempFilterCombinePositionList.slice().sort((a, b) =>
                (Utility.getNumber(a.list[0].scripModel['LastTradePrice']) > Utility.getNumber(b.list[0].scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.list[0].scripModel['LastTradePrice']) > Utility.getNumber(a.list[0].scripModel['LastTradePrice'])) ? -1 : 0));
            }
            else if (this.filteredValue === '%Change')
            {
                this.combinePositionModelList = this.tempFilterCombinePositionList.slice().sort((a, b) =>
                (Utility.getNumber(a.list[0].scripModel['PercentageChange']) > Utility.getNumber(b.list[0].scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.list[0].scripModel['PercentageChange']) > Utility.getNumber(a.list[0].scripModel['PercentageChange'])) ? -1 : 0));
            }
            else if (this.filteredValue === 'Unsorted')
            {
                this.combinePositionModelList = this.tempFilterCombinePositionList;
            }
        }
        else if(this.filteredKey === 'Desc')
        {
            if(this.filteredValue === 'Symbol')
            {      
                this.combinePositionModelList = this.tempFilterCombinePositionList.slice().sort((a,b) =>
                (a['Tsym'] < b['Tsym']) ? 1 : ((b['Tsym'] < a['Tsym']) ? -1 : 0));
            }
            else if(this.filteredValue === 'LTP')
            {
                this.combinePositionModelList = this.tempFilterCombinePositionList.slice().sort((a, b) =>
                (Utility.getNumber(a.list[0].scripModel['LastTradePrice']) < Utility.getNumber(b.list[0].scripModel['LastTradePrice'])) ? 1 : ((Utility.getNumber(b.list[0].scripModel['LastTradePrice']) < Utility.getNumber(a.list[0].scripModel['LastTradePrice'])) ? -1 : 0));
            }
            else if(this.filteredValue === '%Change')
            {
                this.combinePositionModelList = this.tempFilterCombinePositionList.slice().sort((a, b) =>
                (Utility.getNumber(a.list[0].scripModel['PercentageChange']) < Utility.getNumber(b.list[0].scripModel['PercentageChange'])) ? 1 : ((Utility.getNumber(b.list[0].scripModel['PercentageChange']) < Utility.getNumber(a.list[0].scripModel['PercentageChange'])) ? -1 : 0));      
            }
            else if (this.filteredValue === 'Unsorted')
            {
                this.combinePositionModelList.reverse();
            }
        }

        if(this.combinePositionModelList.length === 0)
            this.isNoData = true;
        else
            this.isNoData = false;
    }

    changeMode() {
        if (this.container?.width !== null){
            if (this.positionList && this.positionList.length === 1) {
                this.totalheight = this.container?.height;
                this.Height = {
                    'height': this.container?.height + 'px',
                    'overflow': 'auto'
                };
            } else {
                this.totalheight = (this.container?.height - TableUtility.PBHeight);
                this.Height = {
                    'height': (this.container?.height - TableUtility.PBHeight) + 'px',
                    'overflow': 'auto'
                };
            }
            this.left = {
                'left': (this.container?.width - TableUtility.PBLeft) + 'px'
            };
            this.detector.detectChanges();

            if (Utility.IS_MOBILE) {
                this.dataArrLen = this.dataArr.length;
            }
            else {
                this.getChipWidth();
            }

            if (this.container?.width < Utility.MinWidth)
            {
                this.cellView = true;
                if (environment.isInteropsEnabled){
                    if(this.dataArrayIntrops) this.SortCombinePositionBookInMobile(this.selectedFilterOption)
                }
                else{
                    if(this.dataArray) this.SortPositionBookInMobile(this.selectedFilterOption)   
                }
            }
            else {
                this.cellView = false;
                this.filteredPositionList.map((data: any) => {
                    data.show = false;
                });
            }
        }
    }
    myRefreshEvent(event: Subject<any>, message: string) {
        setTimeout(() => {
            this.onRefresh();
            event.next();
        }, 1000);
      }
      
      alert(message: string) {
        //alert(message);
      }
    ngOnInit() {
        this.targetElement = document.querySelector('html');
        if (this.container) {
            /* this.container.on('resize', _ => {
                this.changeMode();
            });
            this.container.on('show', _ => {
                this.positions.totalMtoM = 0;
                this.positions.totalURPL = 0;
                this.positions.totalRPL = 0;
                this.positions.totalDayMtom = 0;
                this.callPositionBookService();
                setTimeout(() => {
                    this.changeMode();
                }, 50);
              });*/
        }
        // if (Preferences.getItem('positionColumns', ReturnType.Undefined) !== undefined && Preferences.getItem('positionColumns') !== null)
        //     this.displayedColumns = Preferences.getItem('positionColumns', ReturnType.Array);
        // else
        //     this.displayedColumns = ['Tsym', 'Pcode', 'Netqty', 'AvgPrc', 'LastTradePrice', 'MtoM', 'PNLPercentageChg'];
        // if(this.isMobile){
        //     this.callPositionBookService();
        // }
        setTimeout(()=>{
            this.isVisible = false;
        },(1000*60));
    }
    addWLdata(stock) {
        const seltctedStock = stock.Exchangeseg + '|' + stock.Token;
        this.WlArr = WatchListUtility.checkScripInWl(seltctedStock);
      }
    addStock(wl, stockdata) {
        const seltctedStock = stockdata.Exchangeseg + '|' + stockdata.Token;
        WatchListUtility.addStockInWatchList(wl, seltctedStock, this.tradingRestService, function(result, msg) {
          if (result && msg === 'Success') {
            this.snackBar.open(this.translate.instant('all.add_stockmsg'), '', { duration: 3000 });
          } else if (result && msg === 'Fail') {
            this.snackBar.open(this.translate.instant('all.server_fail'), '', { duration: 3000 });
          } else {
            this.snackBar.open(this.translate.instant('all.cancel_stock'), '', { duration: 3000 });
          }
        }.bind(this));
      }
    callPositionBookService() {
        this.positionCount = 0;
        this.checkedPositionCount = 0;
        this.isExpandVisible = false;
        this.resetSortingFilter();
        this.scripService = this.tradingRestService.getScripService();
        this.scripService.getLotWeight(this.setLotWeight.bind(this), this.setPredinedLotWeight.bind(this));
        const userParameter = JSON.parse(Preferences.getPreference('userParameter'));
        this.jsonSendObj = {
            'uid': Preferences.getPreference('userid'),
            's_prdt_ali': userParameter['s_prdt_ali'],
            'actid': userParameter['sAccountId'],
            'type': this.selectedType,
            'forceLoad': true
        };
        this.load.emit(true);
        this.tradingRestService.getBookService().getAllPositionList(this.jsonSendObj, this.getAllPositionListResponse.bind(this));
        if (this.isCustomDashboard) {
            this.changeMode();
        }
    }
    setLotWeight() {}
    setPredinedLotWeight() { }

    getEventData(tabData) {
        if (this.isFirstTabChange) {
            this.dayTab.visibility = false;
            this.isFirstTabChange = false;
        }
        if (tabData === 'Today') {
            this.dayTab.visibility = true;
            this.netTab.visibility = false;
        }
        else {
            this.netTab.visibility = true;
            this.dayTab.visibility = false;
        }
        this.selectedType = tabData;
        this.jsonSendObj['type'] = this.selectedType;
        this.jsonSendObj['forceLoad'] = false;
        this.tradingRestService.getBookService().getAllPositionList(this.jsonSendObj, this.getAllPositionListResponse.bind(this));
    }

    getAllPositionListResponse(data, code, message) {
        this.isLoading = false;
        positionBook.isReload = false;
        this.load.emit(this.isLoading);
        this.positionList = [];
        if (code === 200 && message === 'Ok') {
            this.positions = data;
            this.positionList = data.PositionsModel;
            this.filteredPositionList = this.positionList;
            this.tempFilteredPositionList = this.positionList;
            if (this.selectedType === 'Today') {
                this.filteredPositionList = this.positionList.filter(x => {
                    if ((x.FillBuyAmt > 0 || x.FillSellAmt > 0)) {
                        return x;
                    }
                });
            }
            if (!environment.isInteropsEnabled){
                this.totalScripCount.emit(this.positionList.length);
                this.ProcessTags();
            }
            this.dataArray = Array.from(this.positionList)
            this.isNoData = false;
            if(this.isReloadData)
            {
              this.isReloadData = false;
              if (this.currentTabData)
                  this.onTabChange(this.currentTabData['tabData'], this.currentTabData['type'], this.currentTabData['index']);
              else
                    this.onTabChange('ALL', 'All', null);
            }
            if (this.filteredPositionList.length > 0 && !Utility.IS_MOBILE) {
                this.highlight(this.filteredPositionList[0], 0);
            }
            if(environment.isInteropsEnabled){
                this.combinePositionData();
                this.processCombinedTags();
                this.dataArrayIntrops = Array.from(this.combinePositionModelList);
                this.tempFilterCombinePositionList = this.dataArrayIntrops;
            }
        }
        else {
            this.isNoData = true;
            this.noDataMessage = 'NO DATA';
        }
    }

    processCombinedTags(){
        const dataMap = new Map();
        let obj = {};
        this.combineDataArr = [];
        const exchangeArr = [];
        const symbolArr = [];
        this.combineDataArr = [];

        if (this.combinePositionModelList?.length > 1) {
            for (let i = 0; i < this.combinePositionModelList.length; i++) {
                if (this.combinePositionModelList[i].Tsym != null) {
                    if (!dataMap.has(this.combinePositionModelList[i].Tsym)) {
                        dataMap.set(this.combinePositionModelList[i].Tsym, true);
                        obj = {};
                        obj['data'] = this.combinePositionModelList[i].Tsym;
                        obj['type'] = 'Tsym';
                        obj['count'] = 1;
                        obj['visibility'] = false;
                        symbolArr.push(obj);
                    }
                    else {
                        for (let j = 0; j < symbolArr.length; j++) {
                            if (symbolArr[j]['data'] === this.combinePositionModelList[i].Tsym) {
                                symbolArr[j]['count'] = symbolArr[j]['count'] + 1;
                            }
                        }
                    }
                }
            }
            if (symbolArr.length > 1)
                this.processCombineChipData(symbolArr.sort(this.compare.bind(this)));

            if (Utility.IS_MOBILE) {
                this.combineDataArrLen = this.combineDataArrLen?.length;
            } else {
                this.getChipWidth();
            }

            this.selectedInteropsLabel = 'ALL';
            this.sortCombineList();
        }
    }

    combinePositionData(){
        this.combinePositionMap ={};
        this.interopsPositionList = [];
        this.checkCombineCalc = {};
        this.combinePositionModelList = [];
            for(let i=0; i < this.filteredPositionList.length; i++){
            let combineMapKey = '';
            if(this.filteredPositionList[i].Exchangeseg==='nse_cm' || this.filteredPositionList[i].Exchangeseg==='bse_cm')
            {
                combineMapKey = this.filteredPositionList[i].scripModel.Isin+'_'+this.filteredPositionList[i].Pcode;
            }
            else{
                combineMapKey = this.filteredPositionList[i].Tsym+'_'+this.filteredPositionList[i].Pcode;
            }
            if(this.combinePositionMap[combineMapKey] === undefined){
                this.combinePositionMap[combineMapKey]=[];
            }

            if(environment.preferredExchange[0] === this.filteredPositionList[i].Exchange)
                this.combinePositionMap[combineMapKey].splice(0, 0, this.filteredPositionList[i]);
            else {
                this.combinePositionMap[combineMapKey].push(this.filteredPositionList[i]);
            }
        }   

        for(let i=0; i < this.filteredPositionList.length; i++){
            let combineMapKey = '';
            if(this.filteredPositionList[i].Exchangeseg==='nse_cm' || this.filteredPositionList[i].Exchangeseg==='bse_cm')
            {
                combineMapKey = this.filteredPositionList[i].scripModel.Isin+'_'+this.filteredPositionList[i].Pcode;
            }
            else{
                combineMapKey = this.filteredPositionList[i].Tsym+'_'+this.filteredPositionList[i].Pcode;
            }

            let tempModel = new CombinePositionModel();
            if(this.combinePositionMap[combineMapKey] && this.checkCombineCalc[combineMapKey]===undefined)
            {
                this.checkCombineCalc[combineMapKey]=true;
                let list = this.combinePositionMap[combineMapKey];
                tempModel.AvgPrc = 0;
                tempModel.Netqty = 0;
                tempModel.Pcode = this.filteredPositionList[i].Pcode;
                tempModel.Tsym = this.filteredPositionList[i].Tsym;
                let wap =0;
                for(let j=0;j < list.length;j++) {
                    tempModel.list.push(list[j]);
                    wap += (tempModel.list[j].AvgPrc*tempModel.list[j].Netqty);
                    tempModel.Netqty+=tempModel.list[j].Netqty;
                }
                tempModel.AvgPrc = (tempModel.Netqty!==0)?(wap/tempModel.Netqty):0;
                this.combinePositionModelList.push(tempModel);
                this.interopsPositionList.push(tempModel);
            }
            else if(this.checkCombineCalc[combineMapKey]){
                continue;
            }
        }
        this.totalScripCount.emit(this.combinePositionModelList.length);
    }
    sortList() {
        this.filteredPositionList = this.filteredPositionList.sort((a, b) => (a.Tsym > b.Tsym) ? 1 : ((b.Tsym > a.Tsym) ? -1 : 0));
      }
    
      sortCombineList(){
        this.combinePositionModelList = this.combinePositionModelList.sort((a, b) => (a.Tsym > b.Tsym) ? 1 : ((b.Tsym > a.Tsym) ? -1 : 0));
      }

    ProcessTags() {
        const dataMap = new Map();
        let obj = {};
        this.dataArr = [];
        const exchangeArr = [];
        const symbolArr = [];
        this.dataArr = [];

        if (this.filteredPositionList.length > 1) {
            for (let i = 0; i < this.filteredPositionList.length; i++) {
                if (this.filteredPositionList[i].Exchange != null) {
                    if (!dataMap.has(this.filteredPositionList[i].Exchange)) {
                        dataMap.set(this.filteredPositionList[i].Exchange, true);
                        obj = {};
                        obj['data'] = this.filteredPositionList[i].Exchange;
                        obj['type'] = 'Exchange';
                        obj['count'] = 1;
                        obj['visibility'] = false;
                        exchangeArr.push(obj);
                    }
                    else {
                        for (let j = 0; j < exchangeArr.length; j++) {
                            if (exchangeArr[j]['data'] === this.filteredPositionList[i].Exchange) {
                                exchangeArr[j]['count'] = exchangeArr[j]['count'] + 1;
                            }
                        }
                    }
                }
                if (this.filteredPositionList[i].Tsym != null) {
                    if (!dataMap.has(this.filteredPositionList[i].Tsym)) {
                        dataMap.set(this.filteredPositionList[i].Tsym, true);
                        obj = {};
                        obj['data'] = this.filteredPositionList[i].Tsym;
                        obj['type'] = 'Tsym';
                        obj['count'] = 1;
                        obj['visibility'] = false;
                        symbolArr.push(obj);
                    }
                    else {
                        for (let j = 0; j < symbolArr.length; j++) {
                            if (symbolArr[j]['data'] === this.filteredPositionList[i].Tsym) {
                                symbolArr[j]['count'] = symbolArr[j]['count'] + 1;
                            }
                        }
                    }
                }
            }
            if (exchangeArr.length > 1)
                this.processChipData(exchangeArr.sort(this.compare.bind(this)));
            if (symbolArr.length > 1)
                this.processChipData(symbolArr.sort(this.compare.bind(this)));

            if (Utility.IS_MOBILE) {
                this.dataArrLen = this.dataArr.length;
            } else {
                this.getChipWidth();
            }

            this.selectedLabel = 'ALL';
            this.sortList();
        }
    }


    processChipData(dataArr) {
        for (let i = 0; i < dataArr.length; i++) {
            this.dataArr.push(dataArr[i]);
        }
    }

    processCombineChipData(combineDataArr){
        for (let i = 0; i < combineDataArr.length; i++) {
            this.combineDataArr.push(combineDataArr[i]);
        }
    }

    getChipWidth() {
        setTimeout(() => {
            if (this.sortMenu !== undefined) {
                this.expandText  = 'More';
                let wrapperWidth = this.container?.width - this.sortMenu.nativeElement.scrollWidth;
                const widthAll = Utility.getChipLetterWidth('All');
                if (this.dayTab.visibility === true)
                    wrapperWidth = wrapperWidth - Utility.getChipLetterWidth(this.dayTab.data);
                if (this.netTab.visibility === true)
                    wrapperWidth = wrapperWidth - Utility.getChipLetterWidth(this.netTab.data);

                let WidCount = 0;
                let width = 0;
                this.widthArr = [];
                if (environment.isInteropsEnabled){
                    this.dataArr = this.combineDataArr;
                }
                for (let i = 0; i < this.dataArr.length; i++) {
                    width = Utility.getChipLetterWidth(this.dataArr[i].data);
                    WidCount = WidCount + width;
                    if (WidCount < (wrapperWidth - widthAll - 50)) {
                        this.widthArr.push(this.dataArr[i]);
                        this.isExpandVisible = false;
                        if (environment.isInteropsEnabled){
                            this.combineDataArrLen = this.widthArr.length;
                        }
                        else{
                            this.dataArrLen = this.widthArr.length;
                        }
                    }
                    else {
                        if (environment.isInteropsEnabled){
                            this.combineDataArrLen = this.widthArr.length - 1;
                        }
                        else{
                            this.dataArrLen = this.widthArr.length - 1;
                        }
                        this.isExpandVisible = true;
                        break;
                    }
                }
            }
        }, 200);
    }

    compare(a, b) {
        const count1 = a.count;
        const count2 = b.count;
        let comparison = 0;
        if (count1 < count2) {
            comparison = 1;
        } else if (count1 > count2) {
            comparison = -1;
        }
        return comparison;
    }

    onFilterPositionListChanged() {
        this.positionList.forEach(element => {
            element.IsVisible = false;
        });

        this.positions.totalMtoM = 0;
        this.filteredPositionList.forEach(element => {
            element.IsVisible = true;
            this.positions.totalMtoM += element.MtoM;
        });
    }
    drop(event: CdkDragDrop<string[]>) {
        if (event.currentIndex !== 0 && event.currentIndex !== 1) {
          moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
          Preferences.setItem('positionColumns', this.displayedColumns);
        }
    }

    onCombineTabChange(tabData, type, index) {
        this.currentCombineTabData = { 'tabData': tabData, 'type': type, 'index': index };
        const data = tabData;
        this.selectedInteropsLabel = '';
        this.combinePositionModelList = [];
        let filterArr = [];
        this.uniqueCombineMap.clear();
        if (data !== 'ALL') {
            if (this.combineDataArr[index]['visibility'] === true) {
                this.combineDataArr[index]['visibility'] = false;
            }
            else {
                this.combineDataArr[index]['visibility'] = true;
                this.indexCombineArray.push(index);
            }
        }
        else {
            this.indexCombineArray.forEach(arrData => {
                if (this.combineDataArr.length > arrData && this.combineDataArr[arrData]['visibility'] === true)
                    this.combineDataArr[arrData]['visibility'] = false;
            });
        }
        if (this.combineDataArr && data !== 'ALL') {
            filterArr = this.combineDataArr.filter(obj => {
                return obj['visibility'] === true;
            });
        }

        if (filterArr.length > 0) {

            filterArr.forEach(arrData => {
                this.uniqueCombineMap.set(arrData['type'], true);
            });

            for (let i = 0; i < this.interopsPositionList.length; i++) {
                let count = 0;
                filterArr.forEach(arrData => {
                    if (arrData['data'] === this.interopsPositionList[i][arrData['type']]) {
                        count++;
                    }
                });

                if (count === this.uniqueCombineMap.size) {
                    this.combinePositionModelList.push(this.interopsPositionList[i]);
                }
            }
        }
        else {
            this.selectedInteropsLabel = 'ALL';
            this.combinePositionModelList = this.interopsPositionList;
        }
        this.tempFilterCombinePositionList = Array.from(this.combinePositionModelList);
        this.noDataCheck();
        this.sortCombineList();
    }

    onTabChange(tabData, type, index) {
        this.currentTabData = { 'tabData': tabData, 'type': type, 'index': index };
        const data = tabData;
        this.selectedLabel = '';
        this.filteredPositionList = [];
        let filterArr = [];
        this.uniqueMap.clear();
        if (data !== 'ALL') {
            if (this.dataArr[index]['visibility'] === true) {
                this.dataArr[index]['visibility'] = false;
            }
            else {
                this.dataArr[index]['visibility'] = true;
                this.indexArray.push(index);
            }
        }
        else {
            this.indexArray.forEach(arrData => {
                if (this.dataArr.length > arrData && this.dataArr[arrData]['visibility'] === true)
                    this.dataArr[arrData]['visibility'] = false;
            });
        }
        if (this.dataArr && data !== 'ALL') {
            filterArr = this.dataArr.filter(obj => {
                return obj['visibility'] === true;
            });
        }

        if (filterArr.length > 0) {

            filterArr.forEach(arrData => {
                this.uniqueMap.set(arrData['type'], true);
            });

            for (let i = 0; i < this.positionList.length; i++) {
                let count = 0;
                filterArr.forEach(arrData => {
                    if (arrData['data'] === this.positionList[i][arrData['type']]) {
                        count++;
                    }
                });

                if (count === this.uniqueMap.size) {
                    this.filteredPositionList.push(this.positionList[i]);
                }
            }
        }
        else {
            this.selectedLabel = 'ALL';
            this.filteredPositionList = this.positionList;
        }

        if (this.selectedType === 'Today') {
            this.filteredPositionList = this.filteredPositionList.filter(x => {
                if ((x.FillBuyAmt > 0 || x.FillSellAmt > 0)) {
                    return x;
                }
            });
        }
        this.tempFilteredPositionList = this.filteredPositionList;
        this.noDataCheck();
        this.sortList();
        if((this.selectedSortedItem !== this.sortItemList[0] || (this.selectedSortedItem === this.sortItemList[0] && this.chipSelected === this.matChipItems[1]))  && (this.cellView || this.isMobile))
            this.SortPositionBookInMobile(this.selectedFilterOption);

        this.onFilterPositionListChanged();
        if (this.filteredPositionList.length > 0 && !Utility.IS_MOBILE)
            this.highlight(this.filteredPositionList[0], 0);
    }

    noDataCheck() {
        if (this.filteredPositionList.length === 0) {
            this.isNoData = true;
        }
        else {
            this.isFilterNoData = true;
            this.isNoData = false;
        }
    }
    onRefresh() {
        this.isReloadData = true;
        this.isLoading = true;
        this.load.emit(this.isLoading);
        this.detector.detectChanges();
        this.callPositionBookService();
    }

    onReset(){
        this.currentTabData = undefined;
        this.callPositionBookService();
        this.onTabChange('ALL', 'All', null);
      }
    
    handleButton(buttonType, pmo, cpmo=undefined) {
        this.filteredPositionList.map((data: any) => {
            data.show = false;
        })
        this.combinePositionModelList.map((data: any) => {
            data.show = false;
        })
        if(buttonType !== 'breakup' || buttonType !== 'PPC')
            Utility.positionDetail = pmo;
        switch (buttonType) {
            case 'I':
                Utility.openPage(this.router, 'position', 'positiondetail', PositiondetailComponent, this.dialog, 'popup');
                break;
            case 'PPC':
                if(environment.isInteropsEnabled){
                    Utility.positionDetail = pmo.list[0];
                    Utility.positionBreakUpDetail = pmo;
                } else {
                    Utility.positionDetail = pmo;
                }
                Utility.openPage(this.router, 'position', 'partialpositionconvertion', PartialPositionConvertionComponent, this.dialog, 'popup',{},  '200px', '500px');
                break;
            case 'ADD':
                const addobj = { 'add': true, 'Exchange': pmo.Exchange, 'Token': pmo.Token, 'OrderType': 'L', 'ProductCode': pmo.Pcode};
                const removeobj = { 'remove': true, 'Exchange': pmo.Exchange, 'Token': pmo.Token, 'OrderType': 'L', 'ProductCode': pmo.Pcode};
                let data_obj1;
                if(environment.isInteropsEnabled){
                    data_obj1 = (cpmo.Netqty >= 0) ? addobj : removeobj;
                } else {
                    data_obj1 = (pmo.Netqty >= 0) ? addobj : removeobj;
                }
                Utility.openPage(this.router, 'positionDetail', 'placeorder', PlaceorderComponent, this.dialog, 'popup', data_obj1, '500px', '1250px');
                break;
            case 'REMOVE':
                const addobj2 = { 'add': true, 'Exchange': pmo.Exchange, 'Token': pmo.Token, 'OrderType': 'L', 'ProductCode': pmo.Pcode, 'qty': (environment.isInteropsEnabled) ? cpmo.Netqty : pmo.Netqty };
                const removeobj2 = { 'remove': true, 'Exchange': pmo.Exchange, 'Token': pmo.Token, 'OrderType': 'L', 'ProductCode': pmo.Pcode, 'qty': (environment.isInteropsEnabled) ? cpmo.Netqty : pmo.Netqty };
                let data_obj2;
                if(environment.isInteropsEnabled){
                    data_obj2 = (cpmo.Netqty >= 0) ? removeobj2 : addobj2;
                } else {
                    data_obj2 = (pmo.Netqty >= 0) ? removeobj2 : addobj2;
                }
                Utility.openPage(this.router, 'positionDetail', 'placeorder', PlaceorderComponent, this.dialog, 'popup', data_obj2, '500px', '1250px');
                break;
            case 'sd':
                // const obj = { 'token': pmo.Token, 'exch': pmo.Exchangeseg, 'symbol': pmo.Tsym };
                // this.openStockDetailPage.emit(JSON.stringify(obj));
                const object = { 'isCustomDashboard': true, 'fromMultiView': true, 'fromStockDetail': true, 'isHeader': true, 'MVtype': 'stock', 'backbtnEnable': false, 'IsStockSpecific': false, 'c_name': 'positionbook', 'token': pmo.Token, 'exch': pmo.Exchangeseg, 'symbol': pmo.Tsym, 'series': pmo.Series };
                Utility.SELECTED_SCRIP = Utility.getScripModel(pmo.Token, pmo.Exchangeseg);
                this.outputs.emit(object);
                break;
            case 'breakup':
                if(environment.isInteropsEnabled){
                    Utility.positionDetail = pmo.list[0];
                    Utility.positionBreakUpDetail = pmo;
                } else {
                    Utility.positionDetail = pmo;
                }
                Utility.openPage(this.router, 'position', 'PositionBreakupPopupComponent', PositionBreakupPopupComponent, this.dialog, 'popup',{},  '200px', '500px');
                break;
            case 'BUY':
                Utility.openPlaceOrder(this.router, pmo.Token, pmo.Exchangeseg, 'B', this.dialog);
            break;

            case 'SELL':
                Utility.openPlaceOrder(this.router, pmo.Token, pmo.Exchangeseg, 'S', this.dialog);
            break;
        }
    }

    onClickPositionBookData(data) {
        if(environment.isInteropsEnabled){
            Utility.positionBreakUpDetail = data;
            Utility.positionDetail = data.list[0];
        } else{

            Utility.positionDetail = data;
        }
        if (this.isMobile) {
            this._bottomSheet.open(PositionMoreOptionComponent, {
                restoreFocus: false
            });
        }
    }

    onExpanClick() {
        this.isExpand = !this.isExpand;
        if (this.isExpand) {
            this.expandText = 'Less';
            this.hideDownload = true;  
        } else {
            this.expandText = 'More';
            this.hideDownload = false;
        }
        this.dataArrLen = (this.isExpand && this.dataArr) ? this.dataArr.length : this.widthArr.length - 1;
    }

    onCombineExpanClick(){
        this.isExpand = !this.isExpand;
        if (this.isExpand) {
            this.expandText = 'Less';
            this.hideDownload = true;
        } else {
            this.expandText = 'More';
            this.hideDownload = false;
        }
        this.combineDataArrLen = (this.isExpand && this.combineDataArr) ? this.combineDataArr.length : this.widthArr.length - 1;
    }

    openManageBook()
    {
        if (this.isMobile) {
            this.router.navigate(['/managebooks'], { queryParams: {'name': 'positionbook'}});
        } else {
            this.openManageBooks.emit('positionbook');
        }
    }

    getColumns()
    {
        let tempColumns = Preferences.getItem('positionBookColumns', ReturnType.Undefined);
        if (!tempColumns)
        {
            tempColumns  = JSON.parse(JSON.stringify(TableUtility.positionBookColumns));
            Preferences.setItem('positionBookColumns', tempColumns)
        }
        this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
    }

    updateColumns()
    {
        let tempColumns = Preferences.getItem('positionBookColumns', ReturnType.Undefined);
        this.displayedColumns = tempColumns.filter(ele => ele.isChecked).map(ele => ele.key);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.modesub.unsubscribe();
        this.webReloadSubscription.unsubscribe();
        this.bookDataSubscription.unsubscribe();
        this.sortIconSubscription.unsubscribe();
        this.bookColumnSubscription.unsubscribe();
        this.orientationSubscription.unsubscribe();
    }
    
    onAppearing() {
        Utility.resumeChannel(this.tradingRestService, ChannelType.Position);
    }

    onDisappearing() {
        Utility.pauseChannel(this.tradingRestService, ChannelType.Position);
    }

    resetSortingFilter() {
        this.chipSelected = this.matChipItems[0]; 
        this.selectedFilterOption = "";
        this.selectedSortedItem = this.sortItemList[0];
        this.sortingDictionary.clear();
    }

    handleMouseOver(row) {
        if(environment.isInteropsEnabled) {
            const uniquekey = row.Token + '' + row.Exchangeseg + '' + row.Pcode;
            this.combinePositionModelList.map((data: any) => {
                const val = data.list[0].Token + '' + data.list[0].Exchangeseg + '' + data.list[0].Pcode;
                if (uniquekey && val && (val === uniquekey)) {
                    data.show = true;
                }
            });
        } else {
            const uniquekey = row.Token + '' + row.Exchangeseg + '' + row.Pcode;
            this.filteredPositionList.map((data: any) => {
                const val = data.Token + '' + data.Exchangeseg + '' + data.Pcode;
                if (uniquekey && val && (val === uniquekey)) {
                    data.show = true;
                }
            });
        }
    }

    handleMouseLeave(row) {
        if(environment.isInteropsEnabled) {
            const uniquekey = row.Token + '' + row.Exchangeseg + '' + row.Pcode;
            this.combinePositionModelList.map((data: any) => {
                if (data.list[0].Token + '' + data.list[0].Exchangeseg + '' + data.list[0].Pcode === uniquekey) {
                    data.show = false;
                }
            });
        } else {
            const uniquekey = row.Token + '' + row.Exchangeseg + '' + row.Pcode;
            this.filteredPositionList.map((data: any) => {
                if (data.Token + '' + data.Exchangeseg + '' + data.Pcode === uniquekey) {
                    data.show = false;
                }
            });
        }
    }

    highlight(row: any, i: number){
        Mouse.IS_MOUSE = false;
        if(this.selectedRowIndex !== i && !Utility.IS_MOBILE) {
            let element:HTMLElement;
            if(this.cellView) {
              element = document.getElementById("pb_cell"+i) as HTMLElement;
            }
            else {
              element = document.getElementById("pb_tbl"+i) as HTMLElement;
            }
            element?.focus();
        }
        this.selectedRowIndex = i;
        Utility.SELECTED_POSITION = row;
        Utility.SELECTED_SCRIP = row.scripModel;
        Utility.selectedTab = TableUtility.positionBook;
        if(this.isMobile && !environment.isInteropsEnabled) {
            this.onClickPositionBookData(this.filteredPositionList[i]);
        } else if(this.isMobile && environment.isInteropsEnabled) {
            this.onClickPositionBookData(this.combinePositionModelList[i]);
        }
    }
    
    @HostListener('window:keydown.arrowdown', ['$event'])
    keyDownEvent(event: KeyboardEvent) {
      //if (Utility.selectedTab === TableUtility.positionBook && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Position Book', this.workspaceName)) {
      if (Utility.selectedTab === TableUtility.positionBook && !Utility.isDialogOpened && !this.isMobile) {
        event.preventDefault();
        if(((this.selectedRowIndex + 1) < this.filteredPositionList.length)) {
          var nextrow = this.filteredPositionList[this.selectedRowIndex + 1];
          this.highlight(nextrow, this.selectedRowIndex + 1);
        } else {
        this.highlight(this.filteredPositionList[0], 0);
      }
      }
    }
  
    @HostListener('window:keydown.arrowup', ['$event'])
    keyUpEvent(event: KeyboardEvent) {
      //if (Utility.selectedTab === TableUtility.positionBook && !Utility.isDialogOpened && !this.isMobile && Utility.isCompInCurrentWorkspace('Position Book', this.workspaceName)) {
      if (Utility.selectedTab === TableUtility.positionBook && !Utility.isDialogOpened && !this.isMobile) {
        event.preventDefault();
        if(((this.selectedRowIndex - 1) >= 0)) {
          var nextrow = this.filteredPositionList[this.selectedRowIndex - 1];
          this.highlight(nextrow, this.selectedRowIndex - 1);
        }  else {
          this.highlight(this.filteredPositionList[this.filteredPositionList.length - 1], this.filteredPositionList.length - 1);
        }
      }
    }
    setBaseVisibility(visible: boolean){
        if(visible){
          this.callPositionBookService();
          setTimeout(() => {
            this.onAppearing();
            this.changeMode();
          }, 50);
        }
        else {
            this.onDisappearing();
          }
      
      }
    onCheckBoxClicked(data, event, i){
        if(data === 'All'){
            if(event.checked){
                this.combinePositionModelList.forEach((arrData, i) => {
                    this.combinePositionModelList[i]['CheckBoxValue'] = true;
                });
                this.checkedPositionCount = this.combinePositionModelList.length;
                this.positionCount = 1;
            } else {
                this.combinePositionModelList.forEach((arrData, i) => {
                    this.combinePositionModelList[i]['CheckBoxValue'] = false;
                });
                this.checkedPositionCount = 0;
            }
        } else {
            this.positionCount = 0;
            if(event.checked){
                this.checkedPositionCount = this.checkedPositionCount + 1;
                this.combinePositionModelList[i]['CheckBoxValue'] = true;
            } else {
                this.checkedPositionCount = this.checkedPositionCount - 1;
                this.combinePositionModelList[i]['CheckBoxValue'] = false;
            }
        }
    }
    allSquareOffCellView(){
        this.combinePositionModelList.forEach((arrData, i) => {
            this.combinePositionModelList[i]['CheckBoxValue'] = true;
        });
       this.allSquareOff();
    }
    allSquareOff(){
        this.checkedPositionArr = [];
        this.combinePositionModelList.forEach(element => {
            if(element.CheckBoxValue)
                this.checkedPositionArr.push(element);
        });
        if(this.checkedPositionArr.length > 0){
            let message = this.checkedPositionArr.length + " " + this.translate.instant('all.position');
            Utility.dialogRef = this.dialog.open(SquareOffDialogComponent, { data: { heading: this.translate.instant('all.want_square_position'), message: message, data:  this.checkedPositionArr}, disableClose: true }); 
        }
    }   
}

@Component({
    selector: 'app-squareoff-dialog',
    templateUrl: './squareoff-dialog.component.html',
  })

export class SquareOffDialogComponent{
    scripService: IScripService;
    checkedPositionArr = [];
    selectedPosition = 0;
    stopCallPlaceOrder = true;
    constructor(public dialogRef: MatDialogRef<SquareOffDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, private tradingRestService: TradingRestService, private translate: TranslateService){
            this.scripService = tradingRestService.getScripService();
            this.checkedPositionArr = data.data;
        }
    
    continue(){
        this.callPlaceOrder(this.checkedPositionArr[this.selectedPosition]);
    }

    callPlaceOrder(data){
        let obj: PlaceOrderModel;
        obj = new PlaceOrderModel();
        obj.Exchangeseg = data.list[0].Exchangeseg;
        obj.Pcode = data.list[0].Pcode;
        obj.prctyp = 'MKT';
        obj.qty = Math.abs(data.list[0].Netqty).toString();
        obj.Token = data.list[0].Token;
        obj.Tsym = data.list[0].Tsym.replace('&', '%26');
        obj.Price =  '0';
        obj.MktPro = '0';
        obj.Ret = 'DAY';
        obj.TrigPrice = '0';
        obj.Ttranstype = 'S';
        obj.AMO = 'NO';
        obj.discqty = '0';
        this.scripService.placeOrder(obj, this.placeOrderRes.bind(this));
    }

    placeOrderRes(data, code, message) {
        if(this.selectedPosition < this.checkedPositionArr.length && this.stopCallPlaceOrder){
            this.selectedPosition = this.selectedPosition + 1;
            if(this.checkedPositionArr[this.selectedPosition] !== undefined)
                this.callPlaceOrder(this.checkedPositionArr[this.selectedPosition]);
        } else {
            Utility.positionBook.next('Squareoff');
        }
    }
    onCancel(){
        this.stopCallPlaceOrder = false;
        Utility.positionBook.next('Squareoff');
    }
}
