import { Component, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { BasketOrdersummaryComponent } from '../../../thematicbasket/basketordersummary/basketordersummary.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

let optionchainplaceorder: OptionChainPlaceOrderComponent;
@Component({
    selector: 'app-optionchainplaceorder',
    templateUrl: './optionchainplaceorder.component.html'
})
export class OptionChainPlaceOrderComponent {
    optiondata = [];
    uniqueKeyArr = [];
    filterData = [];
    isLoading = false;
    isNodata = false;
    isMobile = false;
    totalAmount: any;
    orderSummary = [];
    detailedOrderSummary = [];
    isLot = false;
    isLotLable = false;
    lotError = true;
    ticksize;
    @Output() load = new EventEmitter<boolean>();
    isOrderPlaced: boolean;
    @ViewChildren('callInput') callInput: QueryList<OptionChainPlaceOrderComponent>;
    @ViewChildren('putInput') putInput: QueryList<OptionChainPlaceOrderComponent>;
    @ViewChildren('callInputPrice') callInputPrice: QueryList<OptionChainPlaceOrderComponent>;
    @ViewChildren('putInputPrice') putInputPrice: QueryList<OptionChainPlaceOrderComponent>;
    constructor(private tradingRestService: TradingRestService, private translate: TranslateService,  private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar) {
        optionchainplaceorder = this;
        optionchainplaceorder.isMobile = Utility.IS_MOBILE;
        optionchainplaceorder.optiondata = Utility.dialogData.map(ele => Object.assign({}, ele));
        this.totalAmount = 0;
        this.isLot = Utility.IsLot(Utility.segmentToExchange(optionchainplaceorder.optiondata[0].callscrip['ExchangeSegment']));
        if (Utility.getNumber(optionchainplaceorder.optiondata[0].callscrip.BodLotQuantity) > 1)
            this.isLotLable = true;
        for(let i = 0; i < optionchainplaceorder.optiondata.length; i++)
        {
            let ele = optionchainplaceorder.optiondata[i];
            ele.calltransactionType = ele.callqtycount < 0 ? 'S' : 'B';
            ele.puttransactionType = ele.putqtycount < 0 ? 'S' : 'B';
            ele.callPrice = ele.callqtycount < 0 ? (Utility.getNumber(ele.callscrip.BestBuyPrice) ? Utility.getNumber(ele.callscrip.BestBuyPrice): ele.callscrip.LastTradePrice) : (Utility.getNumber(ele.callscrip.BestSellPrice) ? Utility.getNumber(ele.callscrip.BestSellPrice) : ele.callscrip.LastTradePrice);
            ele.putPrice = ele.putqtycount < 0 ? (Utility.getNumber(ele.putscrip.BestBuyPrice) ? Utility.getNumber(ele.putscrip.BestBuyPrice): ele.putscrip.LastTradePrice) : (Utility.getNumber(ele.putscrip.BestSellPrice) ? Utility.getNumber(ele.putscrip.BestSellPrice) : ele.putscrip.LastTradePrice);
            ele.callscrip.LastTradePrice = ele.callqtycount < 0 ? (Utility.getNumber(ele.callscrip.BestBuyPrice) ? Utility.getNumber(ele.callscrip.BestBuyPrice): ele.callscrip.LastTradePrice) : (Utility.getNumber(ele.callscrip.BestSellPrice) ? Utility.getNumber(ele.callscrip.BestSellPrice) : ele.callscrip.LastTradePrice);
            ele.putscrip.LastTradePrice = ele.putqtycount < 0 ? (Utility.getNumber(ele.putscrip.BestBuyPrice) ? Utility.getNumber(ele.putscrip.BestBuyPrice): ele.putscrip.LastTradePrice) : (Utility.getNumber(ele.putscrip.BestSellPrice) ? Utility.getNumber(ele.putscrip.BestSellPrice) : ele.putscrip.LastTradePrice);
            if (ele.futurescrip !== undefined)
                ele.futurescrip.LastTradePrice = ele.futqtycount < 0 ? (Utility.getNumber(ele.futurescrip.BestBuyPrice) ? Utility.getNumber(ele.futurescrip.BestBuyPrice): ele.futurescrip.LastTradePrice) : (Utility.getNumber(ele.futurescrip.BestSellPrice) ? Utility.getNumber(ele.futurescrip.BestSellPrice) : ele.futurescrip.LastTradePrice);
        }

        optionchainplaceorder.ticksize = Utility.getTickSize(optionchainplaceorder.optiondata[0].callscrip.TickSize, optionchainplaceorder.optiondata[0].callscrip.Multiplier, optionchainplaceorder.optiondata[0].callscrip.DecimalPrecision);
    }
    changeVal(type, index, data) {
        switch (type) {
            case 'futltp':
                optionchainplaceorder.optiondata[index].futurescrip['LastTradePrice'] = data;
                break;
            case 'futqty':
                if (data !== '' && data !== '0') {
                    optionchainplaceorder.optiondata[index].futqtycount = data;
                }
                break;
            case 'callltp':
                optionchainplaceorder.optiondata[index].callPrice = data;
                break;
            case 'callqty':
                if (data !== '' && data !== '0'){
                    if (optionchainplaceorder.optiondata[index].calltransactionType === 'S' ){
                        optionchainplaceorder.optiondata[index].callqtycount = data * -1;
                    }
                    else{
                        optionchainplaceorder.optiondata[index].callqtycount = data;
                    }
                }
                break;
            case 'putltp':
                optionchainplaceorder.optiondata[index].putPrice = data;
                break;
            case 'putqty':
                if (data !== '' && data !== '0'){
                    if (optionchainplaceorder.optiondata[index].puttransactionType === 'S' ){
                        optionchainplaceorder.optiondata[index].putqtycount = data * -1;
                    }
                    else{
                        optionchainplaceorder.optiondata[index].putqtycount = data;
                    }
                }
                break;
        }
    }

    placeBasketOrder() {
        const reqArr = [];
        optionchainplaceorder.isOrderPlaced = true;
        this.isLoading = true;
        this.load.emit(this.isLoading);
        let message = '';
        for (let i = 0; i < optionchainplaceorder.optiondata.length ; i++) {
            if (optionchainplaceorder.optiondata[i].callqtycount !== 0) {
                if (this.isLot ? false : (this.floatSafeModulus(optionchainplaceorder.optiondata[i].callqtycount,  optionchainplaceorder.optiondata[i].callscrip['BodLotQuantity'])) !== 0)
                {
                    message = this.translate.instant('placeorder.qtymultiple') + optionchainplaceorder.optiondata[i].callscrip['BodLotQuantity'];
                    break;
                }
                if ((this.floatSafeModulus(optionchainplaceorder.optiondata[i].callPrice, optionchainplaceorder.ticksize) !== 0)) {
                    message = this.translate.instant('placeorder.pricemultiple') + optionchainplaceorder.ticksize;
                    break;
                }
                else if (optionchainplaceorder.optiondata[i].callPrice == '' || !Utility.checkPriceLength(optionchainplaceorder.optiondata[i].callPrice, optionchainplaceorder.optiondata[i].callscrip['DecimalPrecision'])){
                    message = this.translate.instant('placeorder.invalid_price');
                    break;
                }
                else if (optionchainplaceorder.optiondata[i].callqtycount == '' || !Utility.checkQty((optionchainplaceorder.optiondata[i].callqtycount) < 0 ? (-1 * optionchainplaceorder.optiondata[i].callqtycount) : (optionchainplaceorder.optiondata[i].callqtycount)  , optionchainplaceorder.optiondata[i].callscrip['DecimalPrecision'])){
                    message =  this.translate.instant('placeorder.invalid_qty');
                    break;
                }
                const obj = {};
                obj['exchange'] = optionchainplaceorder.optiondata[i].callscrip['ExchangeSegment']; // 'NSE'
                obj['symbol'] = optionchainplaceorder.optiondata[i].callscrip['TradingSymbol']; // 'HDFC-EQ'
                obj['ltp'] = optionchainplaceorder.optiondata[i].callPrice; // 1793.45
                obj['tk'] = optionchainplaceorder.optiondata[i].callscrip['Token'];
                if (optionchainplaceorder.optiondata[i].callqtycount < 0) {
                    obj['qty'] = -1 * (optionchainplaceorder.optiondata[i].callqtycount); // 2
                    optionchainplaceorder.optiondata[i].callqtycount = -1 * (optionchainplaceorder.optiondata[i].callqtycount);
                    obj['tt'] = 'S';
                } else {
                    obj['qty'] = optionchainplaceorder.optiondata[i].callqtycount; // 2
                    obj['tt'] = 'B';
                }

                obj['orderType'] = 'L'; // 'L'
                obj['pc'] = 'NRML';
                obj['rt'] = 'DAY';
                obj['tp'] = '0';
                obj['AMO'] = 'NO';
                reqArr.push(obj);
            }
            if (optionchainplaceorder.optiondata[i].putqtycount !== 0) {
                if (this.isLot ? false : (this.floatSafeModulus(optionchainplaceorder.optiondata[i].putqtycount,  optionchainplaceorder.optiondata[i].putscrip['BodLotQuantity'])) !== 0)
                {
                    message = this.translate.instant('placeorder.qtymultiple') + optionchainplaceorder.optiondata[i].putscrip['BodLotQuantity'];
                    break;
                }
                if ((this.floatSafeModulus(optionchainplaceorder.optiondata[i].putPrice, optionchainplaceorder.ticksize) !== 0)) {
                    message = this.translate.instant('placeorder.pricemultiple') + optionchainplaceorder.ticksize;
                    break;
                }
                else if (optionchainplaceorder.optiondata[i].putPrice == '' || !Utility.checkPriceLength(optionchainplaceorder.optiondata[i].putPrice, optionchainplaceorder.optiondata[i].putscrip['DecimalPrecision'])){
                    message = this.translate.instant('placeorder.invalid_price');
                    break;
                }
                else if (optionchainplaceorder.optiondata[i].putqtycount == '' || !Utility.checkQty((optionchainplaceorder.optiondata[i].putqtycount) < 0 ? (-1 * optionchainplaceorder.optiondata[i].putqtycount) : (optionchainplaceorder.optiondata[i].putqtycount), optionchainplaceorder.optiondata[i].putscrip['DecimalPrecision'])){
                    message =  this.translate.instant('placeorder.invalid_qty');
                    break;
                  }
                const obj = {};
                obj['exchange'] = optionchainplaceorder.optiondata[i].putscrip['ExchangeSegment']; // 'NSE'
                obj['symbol'] = optionchainplaceorder.optiondata[i].putscrip['TradingSymbol']; // 'HDFC-EQ'
                obj['ltp'] = optionchainplaceorder.optiondata[i].putPrice; // 1793.45
                obj['tk'] = optionchainplaceorder.optiondata[i].putscrip['Token'];
                if (optionchainplaceorder.optiondata[i].putqtycount < 0) {
                    obj['qty'] = -1 * (optionchainplaceorder.optiondata[i].putqtycount); // 2
                    optionchainplaceorder.optiondata[i].putqtycount = -1 * (optionchainplaceorder.optiondata[i].putqtycount);
                    obj['tt'] = 'S';
                } else {
                    obj['qty'] = optionchainplaceorder.optiondata[i].putqtycount; // 2
                    obj['tt'] = 'B';
                }
                obj['orderType'] = 'L'; // 'L'
                obj['pc'] = 'NRML';
                obj['rt'] = 'DAY';
                obj['tp'] = '0';
                obj['AMO'] = 'NO';
                reqArr.push(obj);
            }
        }
        if (optionchainplaceorder.optiondata[0].futqtycount !== 0) {
            if (this.isLot ? false : (this.floatSafeModulus(optionchainplaceorder.optiondata[0].futqtycount,  optionchainplaceorder.optiondata[0].futurescrip['BodLotQuantity'])) !== 0)
            {
                message = this.translate.instant('placeorder.qtymultiple') + optionchainplaceorder.optiondata[0].futurescrip['BodLotQuantity'];
            }
            if ((this.floatSafeModulus(optionchainplaceorder.optiondata[0].futurescrip['LastTradePrice'], optionchainplaceorder.ticksize) !== 0)) {
                message = this.translate.instant('placeorder.pricemultiple') + optionchainplaceorder.ticksize;
            }
            const obj = {};
            obj['exchange'] = optionchainplaceorder.optiondata[0].futurescrip['ExchangeSegment']; // 'NSE'
            obj['symbol'] = optionchainplaceorder.optiondata[0].futurescrip['TradingSymbol']; // 'HDFC-EQ'
            obj['ltp'] = optionchainplaceorder.optiondata[0].futurescrip['LastTradePrice']; // 1793.45
            obj['tk'] = optionchainplaceorder.optiondata[0].futurescrip['Token'];
            if (optionchainplaceorder.optiondata[0].futqtycount < 0) {
                obj['qty'] = -1 * (optionchainplaceorder.optiondata[0].futqtycount); // 2
                obj['tt'] = 'S';
            } else {
                obj['qty'] = optionchainplaceorder.optiondata[0].futqtycount; // 2
                obj['tt'] = 'B';
            }
            obj['orderType'] = 'L'; // 'L'
            obj['pc'] = 'NRML';
            obj['rt'] = 'DAY';
            obj['tp'] = '0';
            obj['AMO'] = 'NO';
            reqArr.push(obj);
        }
        if (message === '')
        {
            optionchainplaceorder.tradingRestService.getScripService().placeMultipleReqOrder(reqArr, (res) => {
                optionchainplaceorder.isOrderPlaced = false;
                optionchainplaceorder.orderSummary = res;
                this.isLoading = false;
                this.load.emit(this.isLoading);
                optionchainplaceorder.tradingRestService.getBookService().getAllOrders(optionchainplaceorder.getOrderBookDataResponse, optionchainplaceorder.setPreDefineData);
            });
        }
        else{
            this.snackBar.open(message, this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            optionchainplaceorder.isOrderPlaced = false;
            this.isLoading = false;
            this.load.emit(this.isLoading);
        }
    }
    // getQty(qty, bodltqty) {
    //     return this.isLot? Utility.getNumber(qty < 0 ? -(qty) : qty) : Utility.getNumber(qty < 0 ? -(qty) : qty) * Utility.getNumber(bodltqty);
    // }
    setPreDefineData(data) {
    }

    getOrderBookDataResponse(data, code, message) {
        if (code === 200 && message === 'OK') {
            Utility.ORDER_PLACED_EVENT.next('true');
            optionchainplaceorder.orderSummary.forEach(element => {
                if (element['status'] === 'Ok') {
                    const temp = data.find(ordermodel => ordermodel['NestOrd'] === element['orderId']);
                    element['orderData'] = temp;
                }
                optionchainplaceorder.detailedOrderSummary.push(element);
            });
            Utility.basketOrderSummary = optionchainplaceorder.detailedOrderSummary;
            if (!Utility.IS_MOBILE) {
                Utility.ocData = optionchainplaceorder.optiondata;
                Utility.dialogRef.close();
                Utility.openPage(optionchainplaceorder.router, 'optionchainplaceorder', 'basketordersummary', BasketOrdersummaryComponent, optionchainplaceorder.dialog, 'popup', {}, '480px');
            }
            else{
                Utility.ocData = optionchainplaceorder.optiondata;
                optionchainplaceorder.router.navigate(['/basketordersummary'], { replaceUrl: true}); 
            }
        }
    }

    floatSafeModulus(val, step) {
        val = Utility.getNumber(val);
        step = Utility.getNumber(step);
        if (val && step)
        {
          const valDecCount = (val.toString().split('.')[1] || '').length;
          const stepDecCount = (step.toString().split('.')[1] || '').length;
          const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
          let valInt;
          if (val !== undefined && val !== null && val !== NaN)
            valInt = parseInt(Utility.getNumber(val).toFixed(decCount).replace(/\./g, ''), 10);
          const stepInt = parseInt(step.toFixed(decCount).replace(/\./g, ''), 10);
          return (valInt % stepInt) / Math.pow(10, decCount);
        }
        else
          return 0;
      }

    calculateLots(qty, lotSize)
    {
        this.lotError = false;
        if (qty && lotSize && this.floatSafeModulus(Utility.getNumber(qty), Utility.getNumber(lotSize)) === 0)
        return Math.floor(Utility.getNumber(qty)/Utility.getNumber(lotSize));
        this.lotError = true;
        return 'NA';
    }

    validateKey(key, str) {
        if (/[0-9]/i.test(key)) {
            if (str === 'price')
                this.snackBar.open(this.translate.instant('limits.max_bfr_digits')+ 2 + this.translate.instant('limits.max_aft_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            else if (str === 'mkt')
                this.snackBar.open(this.translate.instant('placeorder.max_two_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
            else
                this.snackBar.open(this.translate.instant('placeorder.max_sevn_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }
        else if (/[a-zA-Z]/i.test(key)) {
            this.snackBar.open(this.translate.instant('limits.only_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }
        else {
            this.snackBar.open(this.translate.instant('limits.only_digits'), this.translate.instant('watchlist.close'), { duration: 2000, panelClass: ['errorSnackBar'] });
        }
    }

    validateQty(ev, type, ind) {
        const regex = new RegExp('^[0-9]{0,7}$|^[0-9]{1,' + 2 + '}$');
        const index = ev.target.selectionStart;
        let element;
        if (type === 'CALL') {
            element = this.callInput['_results'][ind].nativeElement;
        }
        else {
            element = this.putInput['_results'][ind].nativeElement;
        }
        const val = element.value;
        if (!val.match(regex)) {
            this.validateKey(ev.key, 'qty')
            ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
            element.dispatchEvent(new Event('input'));
            element.setSelectionRange(index - 1, index - 1);
        }
    }

    validatePrice(ev, type, ind, dp) {
        const regex = new RegExp('^[0-9]{0,' + 7 + '}$|^[0-9]{1,' + 7 + '}[.]{1,1}[0-9]{0,' + dp + '}$');
        let element;
        if (type === 'CALL') {
            element = this.callInputPrice['_results'][ind].nativeElement;
        }
        else {
            element = this.putInputPrice['_results'][ind].nativeElement;
        }
        const val = element.value;
        const index = ev.target.selectionStart;
        if (!val.match(regex)) {
            this.validateKey(ev.key, 'price');
            ev.srcElement.value = val.substring(0, index - 1) + val.substring(index, val.length);
            element.dispatchEvent(new Event('input'));
            element.setSelectionRange(index - 1, index - 1);
        }
    }
}
