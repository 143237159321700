import { Component, OnInit } from '@angular/core';
import { Utility } from '../../../../services/utility';
@Component({
  selector: 'app-spreadorderdetail',
  templateUrl: './spreadorderdetail.component.html'
})
export class SpreadOrderdetailComponent implements OnInit {
  selectedLabel = 'orderdetail';
  orderDetailData;
  constructor() {
    this.orderDetailData = Utility.spreadOrderDetail;
  }

  ngOnInit(): void {
  }

  onTabChange(type) {
    this.selectedLabel = type;
  }
}
