<app-header (onTitleClick)="onTitleClick()" *ngIf="isMobile" goback="1" [title]="'more.limits' | translate" [isLoading]="isLoading" [isFunds]="isMobile" (reloadPage) = "reloadData()"></app-header>
<mat-card class="webpanelnew overlaywrapper">
    <mat-card-content class="smalllwidth pt-2">
        <div #sectionNeedToScroll class="row" [class.data-container]="isMobile" [class.pt-0]="!isMobile" *ngIf="!isLoading">
            <div class="col-12">
                <div class="row p-2" *ngIf="!isMobile && !isNoData">
                    <div class="col-9">
                        <button [disabled] = "!segmentsData || !segmentsData.payin_enable || isNoFundData"   (click)="openoverlay(1, limitList?.AvailableMargin)" class="btn-positive fundbutton mr-2" [class.fundbuttonwidth]="cellview" mat-flat-button>{{'limits.add_funds' | translate}}</button>&nbsp;
                        <button [disabled] = "!segmentsData || !segmentsData.payout_enable || isNoFundData" (click)="openoverlay(2, limitList?.AvailableCash)" class="btn-primary fundbutton" [class.fundbuttonwidth]="cellview" mat-flat-button>{{'limits.withdraw' | translate}}</button>
                    </div>
                    <div (click)="openoverlay(3, 0)" matTooltip="{{'limits.transaction_details' | translate}}" class="col-3 text-right ts-2 primetext mouse-p">
                        <span *ngIf="!cellview" translate>limits.statement</span>
                        <button   class="" mat-icon-button><i class="icon-history subcolor2"></i></button>
                    </div>
                </div>

                <div class="row px-2" *ngIf="isMobile && !isNoData">
                    <div class="col-12 text-center" >
                        <button [disabled] = "!segmentsData || !segmentsData.payin_enable || isNoFundData"  (click)="openoverlay(1, limitList.AvailableMargin)" class="btn-positive mr-2 fundbutton" mat-flat-button>{{'limits.add_funds' | translate}}</button>
                        <button  [disabled] =  "!segmentsData || !segmentsData.payout_enable || isNoFundData" (click)="openoverlay(2, limitList.AvailableCash)" class="btn-primary mr-1 fundbutton" mat-flat-button >{{'limits.withdraw' | translate}}</button>
                    </div>
                </div>
                
               
                <div class="row ">
                    <div class="col-12">
                        <mat-chip-list class = "nofilter d-none">
                            <mat-chip *ngFor="let obj of displayArray; let i = index" (click)="onTabChange(obj)" [class.imactive]="obj === selectedTab">
                                {{obj}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
                <div *ngIf="!isNoData && !isMobile && !cellview" class="row px-1 py-4 border-b">
                        <div class="col-4 pl-3 subcolor2" matTooltip="{{'limits.open_balance' | translate}}" matTooltipPosition="below" translate>
                            limits.avl_cash <i class="icon-help infoicon2"></i> <div class="ts-5 pt-1 tc-1"> {{limitList?.AvailableCash | pricepipe:2}}</div>
                        </div>
                        <div class="col-4 text-center subcolor2" matTooltip="{{'limits.avlcash_totalcol' | translate}}" matTooltipPosition="below" translate>
                            limits.avl_margin <i class="icon-help infoicon2"></i><div class="ts-6 primetext pt-1 tc-1"> {{limitList?.AvailableMargin | pricepipe:2}}</div>
                         </div>
                        <div  *ngIf="!isNoData" class="col-4 text-right subcolor2" matTooltip="{{'limits.span_expose' | translate}}" matTooltipPosition="below" translate>
                            limits.used_margin <i class="icon-help infoicon2"></i><div class="ts-5 pt-1 tc-1">{{limitList?.UsedMargin | pricepipe:2}}</div>
                        </div>
                </div>

                <div  *ngIf="!isNoData && (isMobile || cellview )" class="row pt-4 pb-2 text-center">
                    <div class="col-12 subcolor2" matTooltip="{{'limits.avlcash_totalcol' | translate}}" translate>
                        limits.avl_margin <i class="icon-help infoicon2"></i><div class="ts-6 primetext pt-1"> {{limitList?.AvailableMargin | pricepipe:2}}</div>
                    </div>
                </div>

                <div  *ngIf="!isNoData && (isMobile || cellview )" class="row px-2 py-2 border-b">
                    <div class="col-6  text-left subcolor2" matTooltip="{{'limits.open_balance' | translate}}" translate>
                        limits.avl_cash <i class="icon-help infoicon2"></i> <div class="ts-5 pt-1 tc-1"> {{limitList?.AvailableCash | pricepipe:2}}</div>
                    </div>
                    <div  *ngIf="!isNoData && (isMobile || cellview )" class="col-6 text-right  subcolor2" matTooltip="{{'limits.used_margin' | translate}}" translate>
                        limits.used_margin <i class="icon-help infoicon2"></i><div class="ts-5 pt-1 tc-1">{{limitList?.UsedMargin | pricepipe:2}}</div>
                    </div>
                </div>
                <ng-container *ngIf='isNoData'>
                    <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'utility.reload' | translate"></app-nodata>
                </ng-container>
                <ngx-pull-to-refresh
            spinnerColor="#ff0000"
            distanceForRefresh="80"
            [isEnable]="isMobile"
            [targetElement]="targetElement"
            (refresh)="myRefreshEvent($event, 'refresh')"
            (loadMore)="alert('loadmore')"
            class = "loader">
            <div *ngIf="!isNoData" class="row" [ngStyle]="Height">
                <div class="col-12 pt-2">
                    <div class="table-global fundstable">
                        <table mat-table [dataSource]="limitData" >
                            <ng-container matColumnDef="key">
                                <td class="text-left subcolor2" mat-cell *matCellDef="let element" translate>{{element.key}}</td>
                            </ng-container>
                            <ng-container matColumnDef="values">
                                <td class="text-right" mat-cell *matCellDef="let element"> {{element.values | pricepipe: 2}} </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        </div>
                        <div class="pl-2 pr-2 pb-1 pt-4 primetext ts-2" translate>
                            limits.used_margin_breakup
                        </div>
                        <div class="table-global fundstable">
                            <table mat-table [dataSource]="breakupData" >
                                <ng-container matColumnDef="key">
                                    <td class="text-left subcolor2" mat-cell *matCellDef="let element" translate>{{element.key}}</td>
                                </ng-container>
                                <ng-container matColumnDef="values">
                                    <td class="text-right" mat-cell *matCellDef="let element"> {{element.values | pricepipe: 2}} </td>
                                </ng-container>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            </div>
                            <div class="row" *ngIf="!isNoData && iseMandate">
                                <div class="col-12 auth text-center p-2">
                                        <a (click)="openeMandate()">
                                            <span>{{'all.reg_eMan' | translate}}</span>
                                        </a>
                                </div>
                            </div>

                </div>
            </div> 
            </ngx-pull-to-refresh>
            </div>
        </div>
    </mat-card-content>
</mat-card>
