import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'splitpipe' })

export class SplitPipe implements PipeTransform {
    /**
     *
     * @param value is a string which needs to be split.
     * @param separator is on what basis you need to split. eg: '-', '/', ''.
     * @param index after splitting it returns an array, so index is the what index element of array you need to return back. eg: 0,1,2.
     */
    transform(value: string, separator: string, index: number) {
        return value.split(separator)[index];
    }
}
