<div *ngIf = "!isMobile && !hideNotification" class="messageclose" (click)="messageopen()"  [class.messageshow]="messagecls===true" ><i class="icon-noti"></i></div>
<div  class="impmessage" [class.goingmessages]="messagecls===true" [class.mobnotiicon] = "isMobile && isCloseClicked ">
    <div  class="impmessage" [class.goingmessages]="messagecls===true">
    <div class="titlebartext" *ngIf = "isVisible">
        <span class="heading"><i class="icon-noti"></i> UPDATE</span>
        <button mat-icon-button  (click)="messageclose()" class="mat-icon-button float-right" mat-button ><i class="icon-cancel"></i></button>
    </div>
    <div *ngIf = "isVisible">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ul>
                        <li class="itemtile">
                            <p [innerHTML] = "messageDetail.content"></p></li>
                    </ul>
                </div>
            </div>
            <div *ngIf = "this.messgaeArray.length > 1" class="row">
                <div class="col-4">
                    <p class="m-1 mt-2">{{arrayIndex + 1}}<span class="subcolor2">/{{messgaeArray.length}} </span></p>
                </div>
                <div class="col-8">
                    <div class="float-right">
                        <button  mat-icon-button  [disabled] = "arrayIndex === 0" (click)="onleftClick()"  class="nextprev" mat-button ><i class="icon-goback"></i></button>
                        <button mat-icon-button  [disabled] = "arrayIndex === messgaeArray.length - 1" (click)="onrightClick()"  class="nextprev" mat-button ><i class="icon-gonext"></i></button>
                    </div>
                </div>
            </div>
        </div>
        
        
        
    </div>   
</div>