import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from 'src/app/services/utility';
let titlebar: TitlebarComponent;

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html'
})

export class TitlebarComponent {
  @Input() isMobile: boolean;
  @Input() title: string;
  @Input() isLoading: boolean;
  @Output() closePlaceOrderSettings = new EventEmitter();
  utility = Utility;
  constructor(private router: Router) {
    titlebar = this;
  }
  close() {
    Utility.fromTodaySip = false;
    if(Utility.isFromSettingPO) {
      this.closePlaceOrderSettings.emit();
    } else {
      Utility.dialogRef.close();
    }
  }
}
