<app-header *ngIf="isMobile" goback="1" [title]="'all.position_conversion' | translate"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'all.position_conversion' | translate"></app-titlebar>
<mat-dialog-content class="mat-dialog-content-small">
	<div [class.data-container]="isMobile" class="row">
		<div class="col-12">
			<div class="p-2">
				<div class="row pt-md-2 pb-md-3">
					<div class="col-6 pt-4">
						<span class="disabletext" translate>all.convertto</span>
					</div>
					<div class="col-6 text-right">
						<mat-form-field class="w-100 white-text">
							<mat-select (selectionChange)="onSendSelection($event)" [(value)]="selectedProduct">
								<mat-option *ngFor="let data of productList" [value]="data">
									{{data}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions d-block mt-3 postion-convertion">
	<div class="row">
		<div *ngIf="!isMobile" class="col-12 text-right">
			<button class="m-2 custom-btn" mat-flat-button (click)="onCancel()"><span translate>all.cancel</span></button>
			<button class="m-2 custom-btn btn-primary" mat-flat-button (click)="onSubmit()"><span translate>all.submit</span></button>
		</div>
		<div *ngIf="isMobile" class="col-6 p-action-l">
			<button class="w-100 custom-btn" mat-flat-button (click)="onCancel()"><span translate>all.cancel</span></button>
    </div>
		<div *ngIf="isMobile" class="col-6 p-action-r">
			<button class="w-100 custom-btn btn-primary" mat-flat-button (click)="onSubmit()"><span translate>all.submit</span></button>
    </div>
	</div>
</mat-dialog-actions>