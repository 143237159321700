import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Utility } from 'src/app/services/utility';
import { BasketPlaceOrderComponent } from '../../basketplaceorder/basketplaceorder.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BasketDetailModel } from 'src/app/model/basketdetail.model';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Subject } from 'rxjs';
import { Preferences } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-custombasket',
    templateUrl: '../../../../'+environment.baseviewpath+'/thematicbasket/customthematicbasket/custombasket/custombasket.component.html',
    styleUrls: ['./custombasket.component.scss']
})

export class CustomBasket implements OnChanges, OnInit {
    @Input() basketdata: any;
    @Input() isCustomDashboard: boolean;
    basketDataObj: any;
    investmentPeriodMap = {};
    riskMap = {};
    shortdesc = '';
    longdesc = '';
    length;
    text='more';
    isMore = false;
    static BASKET_NEWS_DATA_EVENT = new Subject<string>();
    static BASKET_CORPORATE_EVENT = new Subject<any>();
    static CHART_DATA_EVENT = new Subject<any>();
    constructor(private router: Router, private dialog: MatDialog, private PS: PtPlusRestService) {
        this.createMap();
    }

    ngOnChanges() {
        this.basketDataObj = this.basketdata;
        this.longdesc = this.basketDataObj.description;
        this.length = this.longdesc.length;
        this.shortdesc = this.longdesc.substring(0,(this.length>=200)?200:this.length);
    }
    ngOnInit() {
        this.basketDataObj = this.basketdata;
        this.longdesc = this.basketDataObj.description;
    }
    moreclick(){
        this.isMore = !this.isMore;
        this.text = (this.isMore)?'less':'more';
    }
    createMap() {
        this.investmentPeriodMap['s'] = 'Short';
        this.investmentPeriodMap['m'] = 'Medium';
        this.investmentPeriodMap['l'] = 'Long';

        this.riskMap['l'] = 'Low';
        this.riskMap['m'] = 'Medium';
        this.riskMap['h'] = 'High';
        this.riskMap['mh'] = 'Moderate High';

    }
}
