import { Component, ViewEncapsulation, Input, OnChanges, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { PtPlusRestService } from '../../../services/ptplusrest.service';
import { Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { TableUtility } from 'src/app/services/tableutility';
import { environment } from 'src/environments/environment';

let aboutcomponent: AboutComponent;

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    encapsulation: ViewEncapsulation.None
})

export class AboutComponent implements OnChanges, OnInit {
    @Input() omtkn: string;
    @Input() exseg: string;
    @Input() isCustomDashboard: boolean;
    isNoData: boolean;
    isHoldingData: boolean;
    shareholder;
    cbdata = [];
    dataArr = [];
    tabIndex;
    res = [];
    yterm;
    xterm;
    chartbgcolor;
    chartfgcolor;
    cfdata = [];
    adata = [];
    emaildata = [];
    element;
    style;
    generalinfo = {};
    public Xaxis: Object;
    public Yaxis: Object;
    public chartArea: Object;
    public tooltip: Object;
    public marker: Object;
    public animation: Object;
    public chartData = [];
    public holdingData = [];
    public title: string;
    officer = [];
    Height: any;
    modesub: Subscription;
    container?: ComponentContainer;
    constructor(private injector: Injector, private detector: ChangeDetectorRef, private ptPlusRestService: PtPlusRestService, private decimalPipe: DecimalPipe) {
        aboutcomponent = this;
        if (!Utility.IS_MOBILE) {
            aboutcomponent.container = <ComponentContainer>aboutcomponent.injector.get('Container');
        }
        aboutcomponent.dataArr = ['stock_detail.info', 'stock_detail.holdings'];
        aboutcomponent.tabIndex = 0;
        aboutcomponent.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            aboutcomponent.changeMode();
        });
    }
    changeMode(){
        aboutcomponent.Height = {
          'height': (aboutcomponent.container.height - TableUtility.ABHeight) + 'px',
          'overflow': 'auto'
          };
          aboutcomponent.detector.detectChanges();
      }

    ngOnInit(){
        if (aboutcomponent.isCustomDashboard) {
            aboutcomponent.changeMode();
           /* aboutcomponent.container.on('resize', _ => {
              aboutcomponent.changeMode();
            });*/
          }
        if (aboutcomponent.isCustomDashboard){
            aboutcomponent.ptPlusRestService.getFundamentalsService().getCompayData(aboutcomponent.omtkn, aboutcomponent.exseg, 'omnisys', aboutcomponent.GetCompanyDataResponse);
        }
    }
    ngOnChanges() {
        aboutcomponent.ptPlusRestService.getFundamentalsService().getCompayData(aboutcomponent.omtkn, aboutcomponent.exseg, 'omnisys', aboutcomponent.GetCompanyDataResponse);
    }

    ngAfterViewInit() {
        aboutcomponent.loadinfochart();
    }

    loadinfochart() {
        aboutcomponent.element = document.querySelector('.chart-about');
        aboutcomponent.style = getComputedStyle(aboutcomponent.element);
        aboutcomponent.chartbgcolor = aboutcomponent.style.backgroundColor;
        aboutcomponent.chartfgcolor = [aboutcomponent.style.fill];
        aboutcomponent.Xaxis = {
            valueType: 'Category',
            majorGridLines: { width: 0 },
            minorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
            labelStyle: {
                color: aboutcomponent.style.color,
            }
        };
        aboutcomponent.Yaxis = {
            labelFormat: '{value}%',
            majorGridLines: { width: 0 },
            minorGridLines: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
            lineStyle: { width: 0 },
            labelStyle: { color: 'transparent' }
        };
        aboutcomponent.chartArea = {
            border: {
                width: 0
            }
        };
        aboutcomponent.tooltip = {
            enable: true,
            format: '${point.y}',
        };
        aboutcomponent.marker = { dataLabel: { visible: true, font: { color: aboutcomponent.style.color } } };
        aboutcomponent.animation = { enable: true, duration: 200 };
    }

    GetCompanyDataResponse(code, resData, message) {
        if (code === 200 && Object.keys(resData).length > 0) {
            aboutcomponent.isNoData = false;
            aboutcomponent.isHoldingData = false;
            aboutcomponent.cbdata = resData.business_summary;
            aboutcomponent.adata = resData.address;
            aboutcomponent.officer = resData.officers;
            aboutcomponent.emaildata = resData.email;
            aboutcomponent.cfdata = resData.financial_summary;
            aboutcomponent.generalinfo = resData.companygeneralinfo;
            if (Object.keys(resData.shareholder).length > 0) {
                aboutcomponent.shareholder = resData.shareholder;
                aboutcomponent.holdingData = aboutcomponent.loadshareholdingchart();
            }
            else {
                aboutcomponent.isHoldingData = true;
            }
        }
        else
            aboutcomponent.isNoData = true;
    }

    onTabChanged(index){
        aboutcomponent.tabIndex = index;
    }

    loadshareholdingchart() {
        aboutcomponent.chartData = [];
        const res = [], roundoffres = [];
        let shareholding = [], totalshareholder = 0;
        shareholding = Object.values(aboutcomponent.shareholder);
        shareholding.sort(function (a, b) { return a - b; });
        aboutcomponent.xterm = Object.keys(aboutcomponent.shareholder);
        for (let i = 0; i < shareholding.length; i++) {
            totalshareholder = totalshareholder + shareholding[i];
        }
        for (let i = 0; i < shareholding.length; i++) {
            res.push((shareholding[i] * 100) / totalshareholder);
        }
        for (let i = 0; i < res.length; i++) {
            roundoffres.push(aboutcomponent.transformDecimal(res[i]));
        }
        const yterm = roundoffres.map(function (v) {
            return parseFloat(v);
        });
        aboutcomponent.chartData = aboutcomponent.mergeArrays(yterm, aboutcomponent.xterm);
        return aboutcomponent.chartData;
    }

    mergeArrays(arr1, arr2) {
        return arr1.map(function (item, index) {
            return {
                yterm: arr1[index],
                xterm: arr2[index].replace('', '<br>')
            };

        });
    }

    transformDecimal(num) {
        return aboutcomponent.decimalPipe.transform(num, '1.0-2');
    }

}

