import { Component, Input, OnChanges, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { PtPlusRestService } from '../../../services/ptplusrest.service';
import { Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { Subscription } from 'rxjs';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';

let keyratio: KeyRatioComponent;

@Component({
    selector: 'app-keyratio',
    templateUrl: './keyratio.component.html',
    styleUrls: ['./keyratio.component.scss']
})

export class KeyRatioComponent implements OnChanges, OnInit {
    @Input() omtkn: string;
    @Input() exseg: string;
    @Input() isCustomDashboard: boolean;
    data;
    displayedColumns: string[] = ['DisplayName', 'Value'];
    dataSource = [];
    dataSource1 = [];
    selectedVal;
    isNoData;
    keys = [];
    utility: any;
    scripToken;
    exchangeSegment;
    Height = {};
    container?: ComponentContainer;
    modesub: Subscription;

    constructor(private injector: Injector, private detector: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private ptPlusRestService: PtPlusRestService) {
        keyratio = this;
        keyratio.utility = Utility;
        if (!Utility.IS_MOBILE) {
            keyratio.container = <ComponentContainer>keyratio.injector.get('Container');
        }
        keyratio.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            keyratio.changeMode();
        });
    }

    changeMode(){
        keyratio.Height = {
            'height': (keyratio.container.height) + 'px',
            'overflow': 'auto'
        };

        keyratio.detector.detectChanges();
    }

    ngOnInit() {
        if (keyratio.container) {
           /* keyratio.container.on('resize', _ => {
                keyratio.changeMode();
            });*/
        }
        if (keyratio.isCustomDashboard) {
            keyratio.scripToken = keyratio.omtkn;
            keyratio.exchangeSegment = keyratio.exseg;
            keyratio.fetchKeyRatioData();
        }
        if (keyratio.isCustomDashboard) {
            keyratio.changeMode();
        }
    }
    ngOnChanges() {
        keyratio.scripToken = keyratio.omtkn;
        keyratio.exchangeSegment = keyratio.exseg;
        keyratio.fetchKeyRatioData();
    }

    fetchKeyRatioData() {
        keyratio.ptPlusRestService.getFundamentalsService().getKeyratioData(keyratio.scripToken, keyratio.exchangeSegment, 'omnisys', (code, resData, message) => {
            if (code === 200 && resData && Object.keys(resData).length > 0) {
                keyratio.isNoData = false;
                keyratio.data = resData;
                for (let key in keyratio.data) {
                    keyratio.keys.push(key);
                }
                keyratio.selectedVal = Object.keys(keyratio.data)[0];
                keyratio.loadtable(keyratio.data[keyratio.selectedVal]);
            }
            else
                keyratio.isNoData = true;
        });
    }

    loadtable(type) {
        keyratio.dataSource = [];
        keyratio.dataSource1 = [];
        const len = Object.keys(type).length;
        const value = Object.values(type);
        for (let i = 0; i < len; i++) {
            if (i % 2 === 0)
                keyratio.dataSource.push(value[i]);
            else
                keyratio.dataSource1.push(value[i]);
        }
    }

    onSelection(event: MatSelectChange) {
        keyratio.loadtable(keyratio.data[event.value]);
    }
}
