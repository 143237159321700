<app-titlebar *ngIf = "isHeader"  [title]="'all.position_detail' | translate"></app-titlebar>
<mat-card class="webpanelnew mx-2">
    <mat-card-content>
      <div class="myrow smalltext" [class.container] = "!isMobile">
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext" translate>all.trade_symbol</span><span class="graytext">:</span>
                <span class="float-right">{{positionDetailData.Tsym}} ({{positionDetailData.Exchange}})</span>
            </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.net_qty</span><span class="graytext float-left">:</span>
                {{positionDetailData.Netqty === undefined ? '-' : positionDetailData.Netqty}}
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext" translate>all.cfqty</span><span class="graytext">:</span>
                <span class="float-right">{{positionDetailData.CFQty=== undefined ? '-' : positionDetailData.CFQty}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.day_qty</span><span class="graytext float-left">:</span>
                {{positionDetailData.DayQty}}
             </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext" translate>all.change</span><span class="graytext">:</span>
                <span class="float-right" [class.imnegative]="positionDetailData.scripModel.Change<0" [class.impositive]="positionDetailData.scripModel.Change>0">{{positionDetailData.scripModel.Change | pricepipe: positionDetailData.scripModel.DecimalPrecision }}({{positionDetailData.scripModel.PercentageChange | percentagepipe}})</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left"  translate>all.avgprice</span><span class="graytext float-left">:</span>
                {{positionDetailData.AvgPrc | pricepipe: positionDetailData.scripModel.DecimalPrecision}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext" translate>all.prcclose</span><span class="graytext">:</span>
                <span class="float-right">{{positionDetailData.scripModel.PrvClose | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.lasttradeprice</span><span class="graytext float-left">:</span>
                 <span [class.imnegative]="positionDetailData.scripModel.Change<0" [class.impositive]="positionDetailData.scripModel.Change>0">
                   <span [class.imnegativebg]="positionDetailData.scripModel.TickDirection === -1" [class.impositivebg]="positionDetailData.scripModel.TickDirection === 1">
                      {{positionDetailData.scripModel.LastTradePrice  | pricepipe: positionDetailData.scripModel.DecimalPrecision}}
                   </span>
                  </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext"  translate>all.fillbuyqty</span><span class="graytext">:</span>
                <span class="float-right">{{positionDetailData.FillBuyQty}}</span>
            </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.fillsellqty</span><span class="graytext float-left">:</span>
                {{positionDetailData.FillSellQty}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext" translate>all.buyavgprc</span><span class="graytext">:</span>
                <span class="float-right">{{positionDetailData.BuyAvgPrc === null ? '-' : positionDetailData.BuyAvgPrc | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.sellavgprc</span><span class="graytext float-left">:</span>
                {{positionDetailData.SellAvgPrc === null ? '-': positionDetailData.SellAvgPrc | pricepipe: positionDetailData.scripModel.DecimalPrecision}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext" translate>all.buyvalue</span><span class="graytext">:</span>
                <span class="float-right">{{positionDetailData.FillBuyAmt === undefined ? '-' : positionDetailData.FillBuyAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.sellvalue</span><span class="graytext float-left">:</span>
                {{positionDetailData.FillSellAmt === undefined? '-': positionDetailData.FillSellAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext">CF {{'all.fillbuyqty' | translate}}</span><span class="graytext">:</span>
                 <span class="float-right">{{positionDetailData.CFBuyQty}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left">CF {{'all.fillsellqty' | translate}} </span><span class="graytext float-left">:</span>
                  {{positionDetailData.CFSellQty}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext">CF {{'all.buyavgprc' | translate}}</span><span class="graytext">:</span>
                <span class="float-right">{{positionDetailData.CFBuyAvgPrice === null ? '-' : positionDetailData.CFBuyAvgPrice | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left">CF {{'all.sellavgprc' | translate}}</span><span class="graytext float-left">:</span>
                {{positionDetailData.CFSellAvgPrice === null ? '-': positionDetailData.CFSellAvgPrice | pricepipe: positionDetailData.scripModel.DecimalPrecision}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="line-height">
                <span class="graytext">CF {{'all.buyvalue' | translate}}</span><span class="graytext">:</span>
                 <span class = "float-right">{{positionDetailData.CFBuyAmt === undefined ? '-' : positionDetailData.CFBuyAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left">CF {{'all.sellvalue' | translate}}</span><span class="graytext float-left">:</span>
                {{positionDetailData.CFSellAmt === undefined? '-': positionDetailData.CFSellAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.mtom</span><span class="graytext float-left">:</span>
                 <span [class.imnegative]="positionDetailData.MtoM<0" [class.impositive]="positionDetailData.MtoM>0">{{positionDetailData.MtoM === undefined ? '-' : positionDetailData.MtoM | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.product</span><span class="graytext float-left">:</span>
                  {{positionDetailData.Pcode === undefined ? '-' : positionDetailData.Pcode | valuepipe}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.net_buy_val</span><span class="graytext float-left">:</span>
                 <span>{{positionDetailData.NetBuyAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.net_sell_val</span><span class="graytext float-left">:</span>
                  {{positionDetailData.NetSellAmt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-right">
              <div class="line-height">
                <span class="graytext float-left" translate>all.net_amt</span><span class="graytext float-left">:</span>
                <span>{{positionDetailData.Netamt | pricepipe: positionDetailData.scripModel.DecimalPrecision}}</span>
              </div>
            </div>
            <div class="col-6 text-right">
            </div>
          </div>
          
      </div>
    </mat-card-content>
</mat-card>
