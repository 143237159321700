import { Component, Input, OnInit } from '@angular/core';
import { Preferences } from 'src/app/services/Preferences';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { Utility } from 'src/app/services/utility';

let bannerComponent: BannerComponent;
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})

export class BannerComponent implements OnInit {
  @Input() isBannerClose: boolean;
  @Input() isLogin : boolean;
  isMobile: boolean;
  isCloseClicked: boolean;
  messagecls = true;
  messageDetail;
  arrayIndex = 0;
  messgaeArray ;
  maxBannerId;
  isloginBanner: boolean;
  arr = [];
  hideNotification;
  isVisible : boolean;

  constructor(private tradingRestService: TradingRestService) {
    bannerComponent = this;

    this.isMobile = Utility.IS_MOBILE;
    Utility.isBannerHidden = true;
    Utility.showBannerMessage.subscribe(data => {
      if (data === true && this.messgaeArray.length > 0 ){
        this.messageclose();
      }
      else if (data !== true && this.messgaeArray.length > 0 ){
        this.messageopen();
      }
    });
   }

  ngOnInit(): void {
    this.getData();
  }

  onrightClick(){
    if (this.arrayIndex !== this.messgaeArray.length - 1 && this.messgaeArray.length > 0){
      this.arrayIndex = this.arrayIndex + 1;
      this.messageDetail = this.messgaeArray[this.arrayIndex];
    }
  }

  onleftClick(){
    if (this.arrayIndex !== 0){
      this.arrayIndex = this.arrayIndex - 1;
      this.messageDetail = this.messgaeArray[this.arrayIndex];
    }
  }

  getData()
  {
    let data = [];
    bannerComponent.tradingRestService.getUserService().getBannerData((data, code, status) =>
    {
    this.messgaeArray = data;
      if (code === 200 && status === 'SUCCESS' && data.length > 0) {
      this.messgaeArray = data;
      Utility.hideNotification = false;
      this.hideNotification = false;
      data.sort((a, b) => (a.bannerId < b.bannerId) ? 1 : ((b.bannerId < a.bannerId) ? -1 : 0));
      data.forEach(element => {
        this.arr.push(element['bannerId']);
      });
      this.maxBannerId = Math.max(...this.arr);
      if (this.isLogin || Preferences.getPreference('bannerKey') < this.maxBannerId || Preferences.getPreference('bannerKey') === null){
        this.messageopen();
      }
      this.messageDetail = [];
      this.messageDetail = this.messgaeArray[0];
      }
      else{
        Utility.hideNotification = true;
        this.hideNotification = true;
        this.isVisible = false;
      }
    });
  }
  messageclose(){
    this.isCloseClicked = true;
    Utility.isBannerHidden = true;
    this.messagecls = Utility.isBannerHidden;
    this.isVisible = false;
    if (this.isLogin && this.isMobile){
      Utility.hideBellIcon = false;
    }
    if (!this.isLogin){
      Preferences.setPreference('bannerKey', this.maxBannerId);
    }
  }
  messageopen(){
    this.isCloseClicked = false;
    Utility.isBannerHidden = false;
   this.isVisible = true;
    this.messagecls = Utility.isBannerHidden;
    if (this.isLogin && this.isMobile){
      Utility.hideBellIcon = true;
    }
  }

}
