<div class="logoutpopup alertalign">
  <div class="mat-dialog-title">
    <span>{{'all.confirm_btn' | translate}} ?</span>
  </div>
  <mat-dialog-content class="text-center">
    <svg class="infoIconPopup" viewBox="0 0 24 24">
      <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm5.86,15.86A8.28,8.28,0,1,1,20.29,12,8.26,8.26,0,0,1,17.86,17.86Z"/>
      <path d="M12,10.51a1.14,1.14,0,0,0-1.14,1.14v4.92a1.14,1.14,0,0,0,2.28,0V11.65A1.14,1.14,0,0,0,12,10.51Z"/>
      <circle cx="12" cy="7.43" r="1.14"/>
  </svg>
    <h1>{{data.heading}}</h1>
    <span class="subtext"><span *ngIf = "selectedPosition > 0">{{selectedPosition}} {{'all.out_of' | translate}} </span> {{data.message}}</span>
  </mat-dialog-content>
  <div class="mat-dialog-actions">
    <div class="row">
      <div class="col-12 text-right pl-3 pr-3">
        <button mat-flat-button matDialogClose="cancel" [class.btn-primary] = "selectedPosition == checkedPositionArr.length" (click) = "onCancel()">{{selectedPosition == checkedPositionArr.length ? ('all.done' | translate) : ('all.cancel' | translate)}}</button>
        <button *ngIf = "selectedPosition == 0" mat-flat-button class="btn-primary" (click) = "continue()">{{'all.continue' | translate}}</button>
      </div>
    </div>
  </div>
</div>