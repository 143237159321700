<app-header *ngIf="isMobile" goback="1" [title]="'header.my_profile' | translate" [isLoading]="isLoading" [isReloadVisible]="false"></app-header>
<app-titlebar *ngIf="!isMobile" [title]="'header.my_profile' | translate" [isLoading]="isLoading"></app-titlebar>
<mat-dialog-content [class.mh-none] = "isMobile" class="narrowpadding toplandscape">
   <div [class.data-container]="isMobile">
      <div [class.p-0]="!isMobile">
         <div *ngIf="!isNoData && !isLoading">
            <div class="row">
               <div class="col-12">
                  <div class="float-left mr-2">
                     <img alt="user" src="assets/img/user.svg" width="60px" />
                  </div>
                  <div class="float-left tc-1">
                     <div><small class="tc-4" translate>settings.client_name</small> {{clientName}}</div>
                     <div><small class="tc-4" translate>settings.login_id</small> {{loginId}} </div>
                     <div><small class="tc-4" translate>settings.acc_id</small> {{accountId}} </div>
                  </div>
               </div>
            </div>
            <div class="pl-0 nofilter" [class.col-12] = "isMobile" [class.col-12] = "!isMobile" *ngIf = "displayArray.length > 1">
               <mat-chip-list >
                   <mat-chip *ngFor="let obj of displayArray; let i = index" [class.imactive] = "tabIndex ==  i" (click)="onTabChanged(i)" translate>
                   {{obj}}</mat-chip>
                   <mat-chip  [class.imactive] = "tabIndex ==  3" (click)="onTabChanged(3)">
                     <span translate>settings.session_details</span><span class="badge-sm"> {{sessionData.length}}</span>
                  </mat-chip>
                   
               </mat-chip-list>
           </div>
            <div class="row border-t pt-2">
               <div class="col-12">
                  <mat-card class="profilecard" *ngIf = "tabIndex === 0">
                     <h3 translate>settings.account_details</h3>
                     <table class="table-other">
                        <tr *ngFor="let acc of accountDetails">
                           <td class="subcolor2">{{acc.Display}}</td>
                           <td>{{acc.Value}}</td>
                        </tr>
                     </table>
                  </mat-card>

                  <mat-card class="profilecard" *ngIf = "tabIndex === 1 && !isBankDetail">
                     <div class = "row">
                        <div class = "col-12">
                           <h3  translate>settings.bank_details</h3>
                        </div>
                     </div>
                     <table class="table-other">
                        <tr>
                           <td class="profilebank ">
                              <mat-form-field>
                                 <mat-label><span class="subcolor2">Select Bank Account</span></mat-label>
                             <mat-select [(value)]="selected" (selectionChange)="onSelection($event.value)">
                                 <mat-option *ngFor="let data of bankAccountDisplay" [value]="data.value">
                                     {{data.viewValue}}
                                 </mat-option>
                             </mat-select>
                           </mat-form-field></td>
                           <td class="subcolor2"></td>
                           
                        </tr>
                        <tr *ngFor="let acc of bankDetails">
                           <td class="subcolor2">{{acc.Display}}</td>
                           <td>{{acc.Value}}</td>
                        </tr>
                     </table>
                  </mat-card>

                  <mat-card class="profilecard" *ngIf = "tabIndex === 2">
                     <h3 translate>settings.dp_details</h3>
                     <table class="table-other">
                        <tr *ngFor="let acc of dpDetails">
                           <td class="subcolor2">{{acc.Display}}</td>
                           <td>{{acc.Value}}</td>
                        </tr>
                     </table>
                  </mat-card>

                  <mat-card class="profilecard" *ngIf = "tabIndex === 3">
                     <div class = "row">
                        <div class = "col-6">
                           <h3 translate>settings.session_details
                              <small class="subheading-h3">{{'portfolio.total' | translate}} {{sessionData.length}} {{'settings.logged_in' | translate}}</small>
                           </h3>
                           </div>
                        <div class="col-6 text-right" *ngIf ="!isNoSessionData">
                           <a class="logoutme" matTooltip="{{'tooltip.logout_froma_all' | translate}}" (click)="clearSession()"><span translate>header.logout</span></a>
                        </div>     
                    </div>
                    <table class="table-other" *ngIf ="!isNoSessionData">
                        <tr >
                           <th class = "col-6 subcolor" translate>settings.source</th>
                           <th class = "col-6 subcolor" translate>settings.time</th>
                        </tr>
                        <tr *ngFor= "let data of sessionData">
                           <td class="impositive" >{{data.source}}</td>
                           <td class="subcolor2 ts-1">{{data.time}}</td>
                        </tr>
                     </table>
                  </mat-card>
               </div>
            </div>
         </div>
         <app-nodata *ngIf="tabIndex === 0 && isAccountDetail" class="nodata" translate>no_data.NODATA</app-nodata>
         <app-nodata *ngIf="tabIndex === 1 && isBankDetail" class="nodata" translate>no_data.NODATA</app-nodata>
         <app-nodata *ngIf="tabIndex === 2 && isDpDetail" class="nodata" translate>no_data.NODATA</app-nodata>
         <app-nodata *ngIf="tabIndex === 3 && isNoSessionData" class="nodata" translate>no_data.NODATA</app-nodata>
      </div>
   </div>
</mat-dialog-content>