import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Preferences } from 'src/app/services/Preferences';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RetryBasketOrdersComponent } from '../retrybasketorders/retrybasketorders.component';

let basketOrdersCmp: BasketOrdersComponent;
@Component({
    selector: 'app-basketorders',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketorders/basketorders.component.html'
    //styleUrls: ['./basketorders.component.scss']
})
export class BasketOrdersComponent implements OnInit, OnChanges {
    @Output() load = new EventEmitter<boolean>();
    @Input() isOpen;
    isLoading;
    isNoData;
    isMobile;
    batchMap;
    lastBatchData = [];
    retryData = [];
    repairDisable = true;
    @Output() closeUserBasket = new EventEmitter<any>();

    constructor(private PS: PtPlusRestService, private router: Router, public dialog: MatDialog) {
        basketOrdersCmp = this;
        basketOrdersCmp.isMobile = Utility.IS_MOBILE;
    }

    ngOnInit() {
        if (this.isMobile) {
            this.setData();
        }
    }

    ngOnChanges(){
        if (!this.isMobile) {
            this.setData();
        }
    }

    setData(){
        this.batchMap = [];
        basketOrdersCmp.isLoading = true;
        this.PS.getThematicBasketService().getBasketConstituentOrders(Preferences.getPreference('userid'), this.basketConstituentResponse);
    }
    
    basketConstituentResponse(data, code, message)
    {
        basketOrdersCmp.isLoading = false;
        if (code === 200 && message === 'OK') {
            basketOrdersCmp.batchMap = data;
            basketOrdersCmp.lastBatchData = basketOrdersCmp.batchMap[basketOrdersCmp.batchMap.bch[0]];
            basketOrdersCmp.retryData = [];

            for(let i = 0; i <  basketOrdersCmp.lastBatchData.length; i++) {
                if(!( basketOrdersCmp.lastBatchData[i].ordSt === 'complete' || 
                      basketOrdersCmp.lastBatchData[i].ordSt === 'open' ||  
                      basketOrdersCmp.lastBatchData[i].ordSt === 'open pending')) {

                    basketOrdersCmp.retryData.push(basketOrdersCmp.lastBatchData[i]);
                }
            }
            basketOrdersCmp.repairDisable = false;     
        }
        else {
            basketOrdersCmp.isNoData = true;
        }
    }

    RepairOrders(data){
        Preferences.setItem('retryData',data);
        if (Utility.IS_MOBILE) {
            this.router.navigate(['/retrybasketorders']);
        } else {
            this.closeUserBasket.emit(); 
            Utility.openPage(basketOrdersCmp.router, 'basketorders', 'retrybasketorders', RetryBasketOrdersComponent, basketOrdersCmp.dialog, 'popup');                             
        }
    }

}

