import { Component, HostListener, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ScripModel } from 'src/app/model/scrip.model';
import { Utility } from 'src/app/services/utility';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stockheader',
  templateUrl: '../../../'+environment.baseviewpath+'/header/stockheader/stockheader.component.html',
  styleUrls: ['./stockheader.component.scss']
})

export class StockHeaderComponent {
  @Input() isLoading: boolean;
  @Input() isStock: boolean;
  @Input() isPlaceOrder: boolean;
  @Input() isLand: boolean;
  @Input() scripModel: ScripModel;
  @Input() type;
  flashType = 'defaultFlash';
  constructor(private location: Location, private router: Router, private dialog: MatDialog) {
    if (Preferences.getItem('flashType', ReturnType.Undefined)) {
      this.flashType = Preferences.getItem('flashType');
    }
    Utility.flashType.subscribe(obj => {
      this.flashType = obj;
    });   
  }

  back() {
      this.location.back();
      if (this.isStock && !this.isPlaceOrder){
        Utility.stockDetailIndex = 0;
      }
      if (this.isPlaceOrder)
        this.isPlaceOrder = false;
  }
  placeorder(buttontype){
    Utility.openPlaceOrder(this.router, this.scripModel.Token, this.scripModel.ExchangeSegment, buttontype, this.dialog);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    Utility.stockDetailIndex = 0;
  }
}
