import {CanActivate, Router} from '@angular/router';
import { Injectable } from '@angular/core';
import { Utility } from '../../../services/utility';
import { Preferences } from 'src/app/services/Preferences';

@Injectable()
export class CanLoginGuard implements CanActivate {
    constructor(private router: Router) {}
    canActivate() {
        const islogin = Preferences.getPreference('finallogin');
        const tkn = (Preferences.getPreference('userKey') && Preferences.getPreference('userKey') !== 'undefined') ? Preferences.getPreference('userKey') : Preferences.getPreference('userSessionId');
        if (tkn !== undefined && tkn !== '' && tkn !== 'undefined' && tkn !== null && tkn !== 'null' && islogin === 'true') {
            Preferences.setPreference('LoggedIn', 'true');
            if (Utility.IS_MOBILE) {
                this.router.navigate(['/home']);
            } else {
                this.router.navigate(['/workbook']);
            }
            return false;
        }
        return true;
    }
}
