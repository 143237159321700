<div class="rightcon pr-0">
    <div class="row tab123">
        <div class="col-12">
            <div [class.pt-0]="!isMobile">
                <ng-container *ngIf = "!isNoData">
                    <div *ngFor="let recipient of batchMap?.bch">
                        <label class="traded-status2">{{'basket.batch_orders' | translate}} #{{recipient}}</label>
                        <div *ngFor="let data of batchMap[recipient]">
                            <div class="row">
                                <div class="col-12">
                                    <div class="watchlistwrapper">
                                        <div class="row mt-1">
                                            <div class="col-6">
                                                <div class="subcolor ts-2">
                                                    <div><span [class.tag-red]="data.trans_type=='S'" [class.tag-green]="data.trans_type=='B'">{{(data.trans_type==='B'?'BUY':'SELL')}}</span> | {{'portfolio.qty' | translate}}: <span class="fw-4 tc-3">{{data.fill_qty | numberpipe}}</span></div>
                                                    <span [ngClass]="{'tc-1' : true, 'ts-4' : true, 'textclip' : true}">{{data.trdSym | symbolpipe}}</span><small class="exchangename">{{data.exch_seg | valuepipe}}</small><br>
                                                    {{data.product_type | valuepipe}} | {{data.order_type | valuepipe}}
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="subcolor ts-2 text-right">
                                                    <div>{{(data.order_time === '--') ? '--' : (data.order_time | datepipe:'datetime')}}</div>
                                                    <div><span class="subcolor2">{{'books.prc' | translate}}: </span><span class="ts-4 tc-1">{{data.avg_price  | pricepipe:2}}</span></div>
                                                    <div><span [class.rejected-status] = "data.ordSt === 'rejected'">{{data.ordSt | valuepipe}}</span></div>
                                                </div>
                                            </div>
                                        </div>            
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                
                </ng-container>
                
            </div>
        </div>
    </div>
</div>
<div class="footerbtn fixatbottom" *ngIf = "isMobile">
    <button [class.d-none]="retryData.length <= 0" class="w-100 btn-primary" mat-flat-button (click)="RepairOrders(retryData)"><span translate>{{'basket.repair_orders' | translate}}</span></button>
</div>
<div  class="footerbtn fixatbottom" *ngIf = "!isMobile">
    <div class="row m-0">
        <div class="col-12 px-0">
            <button  [class.d-none]="retryData.length <= 0" class="w-100 btn-primary" mat-flat-button (click)="RepairOrders(retryData)"><span translate>{{'basket.repair_orders' | translate}}</span></button>
        </div>
    </div>
</div>
<ng-container *ngIf='!isLoading && isNoData' class='nodata'>
    <app-nodata message = "N0 DATA"></app-nodata>
</ng-container>