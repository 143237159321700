import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'maskedvaluepipe'})

export class MaskedValuePipe implements PipeTransform {
    transform(value: string, formate = 'normaltext') {
        const size = 3;
        if (value && value !== '-' && value.length >= size) {
            let maskedValue;
            if (formate === 'normaltext') {
                maskedValue = new Array(value.length - (size - 1)).join('X') + value.substr(value.length - size, size);            
            }
            if(formate === 'mail') {
                let subVal = value.split('@')[0];
                maskedValue = (new Array(subVal.length - (size - 1)).join('X') + subVal.substr(subVal.length - size, size)) + '@' + value.split('@')[1];
            }
            return maskedValue;
        }
        else {
            return value;
        }
    }
}