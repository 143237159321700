import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nodata',
  templateUrl: '../../'+environment.baseviewpath+'/nodata/nodata.component.html',
  styleUrls: ['./nodata.component.scss']
})
export class NodataComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() componentname: string;
  @Input() imgType = 1;
  @Input() link: string;
  @Input() action: string;
  @Output() refreshEvent = new EventEmitter();
  @Input() btnTitle: string;
  constructor(private translate: TranslateService) {
    this.title = this.translate.instant('all.short_message');
    this.message = this.translate.instant('all.long_message')+'<br/>'+this.translate.instant('all.right_now');
  }

  ngOnInit() {
  }
  actionRequired(value){
  }

  onRefresh() {
    this.refreshEvent.emit();
  }

}