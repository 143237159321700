import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TableUtility } from 'src/app/services/tableutility';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
import { BasketPlaceOrderComponent } from '../basketplaceorder/basketplaceorder.component';

@Component({
  selector: 'app-usertodayssip',
  templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/usertodayssip/usertodayssip.component.html'
})
export class UsertodayssipComponent implements OnInit {
  sipList;
  statusMap = {};
  isMobile;
  displayedColumns;
  constructor(private router: Router, private dialog: MatDialog) { 
    this.sipList = Utility.todaysSipData;
    this.isMobile = Utility.IS_MOBILE;
    this.displayedColumns = TableUtility.qProfileConfiq;
  }

  ngOnInit(): void {
    this.setStatusMap();
  }

  setStatusMap(){
    this.statusMap[1] = 'Active';
    this.statusMap[2] = 'Pause';
    this.statusMap[-1] = 'Delete';
  }

  placeOrder(obj) {
    Utility.selectedSipData = obj;
    Utility.fromTodaySip = true;
    Utility.isTodaySipOpen = false;
    if (Utility.IS_MOBILE) {
      Utility.dialogRef.close();
      this.router.navigate(['/basketplaceorder'], { queryParams: { 'basketOrderType': 'FirstPurchase' } });
    }
    else {
      Utility.dialogRef.close();
      Utility.openPage(this.router, 'basketdetail', 'basketplaceorder', BasketPlaceOrderComponent, this.dialog, 'popup', { 'basketOrderType': 'FirstPurchase' });
    }
}

}
