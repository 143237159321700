import { Injectable } from '@angular/core';
import { IGraphService } from './igraph.service';
import { ApiService } from '../api.service';
import { Keys } from '../keys';
import { Url } from '../url';
import { Utility } from '../utility';
import { Preferences } from '../Preferences';

@Injectable()
export class GraphService implements IGraphService {
    apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    getChartData(data, callback: Function) {
        let message = '';
        let code;
        const param = Utility.prepareParameters(JSON.stringify(data), Preferences.getPreference('publicKey4'));
        this.apiService.post(Url.GRAPH_URL, param).subscribe(responseData => {
            const stat = responseData['stat'];
            if (stat === 'Ok') {
                message = 'Ok';
                code = Keys.RES_CODE_SUCCESS;
            }
            else {
                message = responseData['Emsg'];
                code = Keys.RES_CODE_FAILURE;
            }
            callback(responseData, code, message);
        },
            error => {
                code = Keys.RES_CODE_FAILURE;
                message = error;
                callback('', code, message);
            });
    }

}
