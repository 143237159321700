<mat-card class="">
    <mat-card-content>
        <div class="row stickychips">
        <div class="col-12" *ngIf="holdingList"><div class="row">
            <div class="col-10">
                <div class="row">
                    <div class="col-12" *ngIf = "holdingList.length > 1">
                        <mat-chip-list *ngIf="!(noData && !isLoader) || isFilterNoData">
                            <mat-chip *ngIf = "!isLoader"  [class.imactive]="selectedLabel === 'ALL'" id="tab-header" (click) = "onTabChange('ALL','All')">ALL</mat-chip>
                            <mat-chip *ngFor="let obj of dataArr | slice:0: dataArrLen; let i = index" (click)="onTabChange(obj.data, obj.type, i)" [class.imactive]="obj.visibility === true">
                                {{obj.data}}</mat-chip>
                            <mat-chip *ngIf = "isExpandVisible" id="tab-header" (click) = "onExpanClick()" title="Expand">{{expandText}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
            <div #sortMenu class="col-2 text-right pl-0 my-auto" *ngIf="holdingList.length > 0">
                <button disabled="false" #menuTrigger="matMenuTrigger" class="btn-icon mr-1" mat-icon-button [matMenuTriggerFor]="sortnav" (menuClosed)="matMenuClosed()">
                    <i class="icon-menu"></i>
                    <em *ngIf="(selectedSortedItem !== sortItemList[0] || (selectedSortedItem === sortItemList[0] && chipSelected === matChipItems[1])) && (cellView || isMobile)" class="wl-bubblesmall"></em>
                </button>
                <mat-menu #sortnav="matMenu" xPosition="before" [class.col-12] = "true" class="panelnav">
                    <div *ngIf="cellView || isMobile" [ngClass]="{'d-sm-none' : isMobile}" class="primetext p-2 ts-2">{{'all.sorting' | translate}}</div>
                    <div *ngIf="cellView || isMobile" [ngClass]="{'d-sm-none' : isMobile}" (click)="$event.stopPropagation();$event.preventDefault();"><button (click)="sortingItemSelected(sortingItem)" mat-menu-item *ngFor="let sortingItem of sortItemList" >{{sortingItem}} <span class="rightitem impositive"><i [class.icon-godown]= "chipSelected === 'Asc' && selectedSortedItem === sortingItem" [class.icon-goup]= "chipSelected === 'Desc' && selectedSortedItem === sortingItem"></i></span></button></div>
                    <div class="border-t pt-2">
                        <button *ngIf = "!isLoading && !isNoData" mat-menu-item matTooltip="{{'all.export' | translate}}" (click)="exportCSV()">{{'all.download' | translate}}<span class="rightitem"><i class="icon-refresh"></i></span></button>
                        <button *ngIf = "!isLoading && !isNoData && ((!cellView && !isMobile) || isMobileLand)" matTooltip="{{'all.manage_columns' | translate}}" mat-menu-item  (click)="openManageBook()">{{'all.manage_columns' | translate}}<span class="rightitem"><i class="icon-setting"></i></span></button>
                        <button *ngIf="isEdisEnable" mat-menu-item (click)="authorize()">{{'all.authorization' | translate}}</button>

                    </div>
                </mat-menu>        
            </div>
            </div>
        </div>
    </div>

        <div *ngIf="noData && !isLoader">
            <app-nodata (refreshEvent) = "onRefresh()" [btnTitle]="'all.reload' | translate"></app-nodata>
        </div>
        <div *ngIf="!noData" class="" [class.mt-0]="!isMobile" #pnlHeader>
            <div *ngIf="(!cellView && !isMobile) || isMobileLand" class="row hightlight-box ts-1">
                <div class="col-3">
                    <div class="pl-3">
                        <small class="subcolor" translate>all.total_investment</small><br>
                    <span class="ts-4">{{holdings.TotalInvestment | pricepipe:2}}</span>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <small class="subcolor" translate>all.market_value</small><br>
                    <span class="ts-4">{{holdings.TotalValue | pricepipe:2}}</span>
                </div>
                <div class="col-3  text-center">
                    <small class="subcolor" translate>all.dayPL</small><br>
                    <span class="ts-4" [class.imnegative]="holdings.TotalDayPNL<0" [class.impositive]="holdings.TotalDayPNL>0">{{(holdings.TotalDayPNL === undefined) ? '0.00' : (holdings.TotalDayPNL | pricepipe:2)}}</span>
                </div>
                <div class="col-3 text-right">
                    <small class="subcolor" translate>all.p_and_l</small><br>
                    <span class="ts-4" [class.imnegative]="holdings.TotalPNL<0" [class.impositive]="holdings.TotalPNL>0">{{(holdings.TotalPNL === undefined) ? '0.00' : (holdings.TotalPNL | pricepipe:2)}}</span>
                </div> 
            </div>
            <div *ngIf="cellView || (isMobile && !isMobileLand)" class="row hightlight-box">
                <div class="col-12 subcolor ts-1">
                    <div class="row pb-1">
                        <div class="col-6">
                            <small translate>all.total_investment</small><br><span class="ts-4">{{holdings.TotalInvestment | pricepipe:2}}</span>
                        </div>
                        <div class="col-6 text-right">
                            <small translate>all.dayPL</small><br><span class="ts-4" [class.imnegative]="holdings.TotalDayPNL<0" [class.impositive]="holdings.TotalDayPNL>0">{{(holdings.TotalDayPNL === undefined) ? '0.00' : (holdings.TotalDayPNL | pricepipe:2)}}</span>
                        </div>
                    </div>

                    <div class="row border-t pt-1">
                        <div class="col-6">
                            <small  translate>all.market_value</small><br><span class="ts-4">{{holdings.TotalValue | pricepipe:2}}</span>
                        </div>
                        <div class="col-6 text-right">
                            <small  translate>all.p_and_l</small><br><span class="ts-4" [class.imnegative]="holdings.TotalPNL<0" [class.impositive]="holdings.TotalPNL>0">{{(holdings.TotalPNL === undefined) ? '0.00' : (holdings.TotalPNL | pricepipe:2)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ngx-pull-to-refresh
        spinnerColor="#ff0000"
        distanceForRefresh="80"
        [isEnable]="isMobile"
        [targetElement]="targetElement"
        (refresh)="myRefreshEvent($event, 'refresh')"
        (loadMore)="alert('loadmore')"
        class = "loader">
        <div class="row">
            <div class="col-12">
                <div #p2 *ngIf="!noData && (isMobile || cellView)"  [ngStyle]="Height">
                    <div *ngFor="let list of filteredHoldingList; let i = index" [attr.id]="'hb_cell' + i" [class.wlweb]="cellView" [class.pl-2]="cellView" [class.pr-2]="cellView" (click)="highlight(list, i)" tabindex="999">
                        <div class="row">
                            <div class="col-12" [ngClass]="{'highlight': selectedRowIndex == i}">
                                <div class="list-view border-b" [ngClass]="{'d-block d-sm-none' : isMobile}">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="subcolor ts-2">
                                                <div>{{'all.qty' | translate}}:<span class="tc-1"> {{list.AvailableQty | numberpipe}}</span> | Avg: 
                                                    <span class="tc-1"> {{list.Price | pricepipe:list.DecimalPrecision}}</span>
                                                </div>
                                                <span class="tc-1 ts-4 texttrim" matTooltip="{{list.Symbol | symbolpipe}}">{{list.Symbol | symbolpipe}}</span><br>
                                                {{'all.current_valuation' | translate}}: <span class="tc-1"> {{list.PrimaryValuation | pricepipe:list.DecimalPrecision}}</span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="subcolor ts-2 text-right">
                                                <div> {{'all.dayPL' | translate}} : <span [class.imnegative]="list.DayPNL<0" [class.impositive]="list.DayPNL>0"> {{(list.DayPNL === undefined) ? '0.00' : list.DayPNL | pricepipe: list.DecimalPrecision}}</span></div>
                                                <div class="ts-4 tc-1" [class.imnegative]="list.PNLPercentageChg<0" [class.impositive]="list.PNLPercentageChg>0">{{(list.Price == 0)? 'NA' :((list.NetPNL === undefined) ? '0.00' : (list.NetPNL | pricepipe:list.DecimalPrecision))}}
                                                    <small *ngIf="list.Price !== 0" class="ts-1">({{list.PNLPercentageChg | percentagepipe: '0.00%'}})</small>
                                                </div>
                                                <div [class.imnegative]="list.scripModel.PercentageChange<0" [class.impositive]="list.scripModel.PercentageChange>0">
                                                    <div [class.imnegativebg]="list.scripModel.TickDirection === -1 && flashType === 'defaultFlash'" [class.impositivebg]="list.scripModel.TickDirection === 1 && flashType === 'defaultFlash'">
                                                        <span [class.imnegativebg]="list.scripModel.TickDirection === -1 && flashType === 'ltpFlash'" [class.impositivebg]="list.scripModel.TickDirection === 1 && flashType === 'ltpFlash'">{{list.scripModel.LastTradePrice | pricepipe:list.DecimalPrecision}}</span>&nbsp;
                                                        <small class="ts-1">({{list.scripModel.PercentageChange | percentagepipe}})</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="wl-btn" *ngxCacheIf="!isMobile && cellView" [class.d-none]="!M.IS_MOUSE">
                                        <button mat-button matTooltip="{{'all.add' | translate}}"  class = "btn-positive ml-2 text-uppercase" (click)="handleButton('ADD', list)" >{{'all.add' | translate}}</button>
                                        <button *ngIf = "list.AvailableQty > 0" mat-button matTooltip="{{'all.exit' | translate}}"  class="ml-2 text-uppercase" class = "btn-negative ml-2" (click)="handleButton('REMOVE', list)" >{{'all.exit' | translate}}</button>
                                        <!-- <button [disabled]="!list.Exch1" mat-icon-button matTooltip="{{'search.square_off_nse' | translate}}" class="btn-primary ml-2" (click)="handleButton('NSE', list)"><i class="icon-nsb"></i></button>
                                        <button [disabled]="!list.Exch2" mat-icon-button matTooltip="{{'search.square_off_bse' | translate}}" class="btn-primary ml-2" (click)="handleButton('BSE', list)"><i class="icon-bsb"></i></button> -->
                                        <button mat-icon-button (click) = "addWLdata(list)" matTooltip="{{'all.addto_watchlist' | translate}}"  class="btn-primary ml-2" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                        <mat-menu #menu="matMenu">
                                            <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                            <ng-container *ngFor = "let data of WlArr">
                                                <button mat-menu-item (click) = "addStock(data.wlName, list)" [disabled] = "data.isDisable">
                                                    <span>{{data.wlName}}</span>
                                                </button>
                                            </ng-container>
                                        </mat-menu>
                                        <button mat-icon-button matTooltip="{{'all.stock_detail' | translate}}" class="btn-primary ml-2"  (click)="handleButton('sd', list)"><i class="icon-ib"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="!noData && !cellView">
                    <div #p1 class="table-global" [ngClass]="{'d-none d-sm-block contentwrapper' : isMobile}" [ngStyle]="isMobile ? DHeight: Height" [class.ispowermode]="utility.TOUCH_TYPE == 'C'" [class.ismousemode]="utility.TOUCH_TYPE == 'M'">
                        <table mat-table [dataSource]="filteredHoldingList" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" matSort (matSortChange)="sortHoldingBook($event)">
                            <ng-container matColumnDef="Symbol" sticky>
                            <th mat-header-cell *matHeaderCellDef cdkDrag [cdkDragDisabled]="true" mat-sort-header> <span translate>all.instrument</span> </th>
                            <td mat-cell *matCellDef="let element"> 
                                <span class="button-row">
                                    <div class="float-button-container" [ngStyle]="left" [class.active]="element.show == true" [class.d-none]="!M.IS_MOUSE">
                                        <button mat-icon-button matTooltip="{{'all.add' | translate}}" class="btn-positive normalbtn text-uppercase" (click)="handleButton('ADD', element)">{{'all.add' | translate}}</button>
                                        <button [disabled] = "element.AvailableQty <= 0" mat-icon-button matTooltip="{{'all.exit' | translate}}" class="btn-negative normalbtn text-uppercase" (click)="handleButton('REMOVE', element)" >{{'all.exit' | translate}}</button>
                                        <!-- <button [disabled]="!element.Exch1" mat-icon-button matTooltip="{{'search.square_off_nse' | translate}}" class="btn-primary" (click)="handleButton('NSE', element)"><i class="icon-nsb"></i></button>
                                        <button [disabled]="!element.Exch2" mat-icon-button matTooltip="{{'search.square_off_bse' | translate}}" class="btn-primary" (click)="handleButton('BSE', element)"><i class="icon-bsb"></i></button> -->
                                        <button mat-icon-button (click) = "addWLdata(element)" matTooltip="{{'all.addto_watchlist' | translate}}"  class="btn-primary" [matMenuTriggerFor]="menu"><i class="icon-add-wlist"></i></button>
                                        <mat-menu #menu="matMenu">
                                            <mat-optgroup label="{{'all.slct_watchlist' | translate}}" class="watchlist-nav"></mat-optgroup>
                                            <ng-container *ngFor = "let data of WlArr">
                                                <button mat-menu-item (click) = "addStock(data.wlName, element)" [disabled] = "data.isDisable">
                                                    <span>{{data.wlName}}</span>
                                                </button>
                                            </ng-container>
                                        </mat-menu>
                                        <button mat-icon-button matTooltip="{{'all.stock_detail' | translate}}" class="btn-primary"  (click)="handleButton('sd', element)"><i class="icon-ib"></i></button>
                                    </div>
                                </span>
                                <span [matTooltip]="element.scripModel.CompanyName" class="txtsmall">{{element.Symbol | symbolpipe}}</span></td>
                            </ng-container>
                            <ng-container matColumnDef="AvailableQty">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag> {{'all.available' | translate}} {{'all.qty' | translate}} </th>
                                <td mat-cell *matCellDef="let element" class="text-right txtsmall"><small *ngIf="element.Btst>0">T1:{{element.Btst | numberpipe}}</small> {{element.AvailableQty | numberpipe}} </td>
                            </ng-container>
                            <ng-container matColumnDef="Price">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag> {{'all.avgprice' | translate}}<i *ngIf="isAvgPriceEditable" matTooltip="{{'all.avgPriceEdit_info' | translate}}" class="icon-ib"></i></th>
                                <td mat-cell *matCellDef="let element" class="text-right w-90"> 
                                    <input id="priceInput" class="onFocus w100" [value]="element.Price" *ngIf="isAvgPriceEditable && element.isSelected" (keypress)="_keyPress($event)"
                                    (focusout)="changeAvgPrice(element, $event.target.value)" matInput type="number" placeholder="0.00" inputmode="decimal" (focusin)="onPriceInputFocus(element, $event.target, 'priceInput')">
                                    <span *ngIf="isAvgPriceEditable && !element.isSelected" (click)="onPriceDivClick('priceInput', element); $event.stopPropagation();" matTooltip="{{'all.click_edit' | translate}}">
                                        {{element.Price | pricepipe:element.DecimalPrecision}} 
                                    </span>
                                    <span *ngIf="!isAvgPriceEditable">
                                        {{element.Price | pricepipe:element.DecimalPrecision}} 
                                    </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="LastTradePrice">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" cdkDrag> <span translate>all.ltp</span> </th>
                                <td mat-cell *matCellDef="let element" class="text-right" [class.imnegative]="element.scripModel.Change<0" [class.impositive]="element.scripModel.Change>0"> 
                                    <div [class.imnegativebg]="element.scripModel.TickDirection === -1 && flashType !== 'noFlash'" [class.impositivebg]="element.scripModel.TickDirection === 1 && flashType !== 'noFlash'">
                                        {{element.scripModel.LastTradePrice | pricepipe:element.DecimalPrecision}}
                                    </div>
                                 </td>
                            </ng-container>
                            <ng-container matColumnDef="Invest">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"  cdkDrag><span translate> basket.invst_val </span></th>
                                <td mat-cell *matCellDef="let element"  class="text-right"> {{(element.Price * element.AvailableQty) | pricepipe:element.DecimalPrecision}} </td>
                            </ng-container>
                            <ng-container matColumnDef="PrimaryValuation">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"  cdkDrag><span translate> all.current_valuation </span></th>
                                <td mat-cell *matCellDef="let element"  class="text-right"> {{element.PrimaryValuation | pricepipe:element.DecimalPrecision}} </td>
                            </ng-container>
                            <ng-container matColumnDef="NetPNL">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"  cdkDrag> <span translate> all.p_and_l</span> </th>
                                <td mat-cell *matCellDef="let element"  class="text-right" [class.imnegative]="element.NetPNL<0" [class.impositive]="element.NetPNL>0">
                                    {{(element.Price == 0)? 'NA' :((element.NetPNL === undefined) ? '0.00' : (element.NetPNL | pricepipe:element.DecimalPrecision))}} {{(element.Price === 0) ? '' :'('}}{{(element.Price === 0) ? '' : (element.PNLPercentageChg | percentagepipe: '0.00%')}}{{(element.Price === 0) ? '' :')'}}
                                </td>
                            </ng-container> 
                            <ng-container matColumnDef="DayPNL">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right"  cdkDrag><span translate> all.dayPL </span> </th>
                                <td mat-cell *matCellDef="let element"  class="text-right" [class.imnegative]="element.scripModel.PercentageChange<0 && element.AvailableQty !== 0" [class.impositive]="element.scripModel.PercentageChange>0 && element.AvailableQty !== 0">
                                    {{(element.DayPNL === undefined) ? '0.00' : element.DayPNL | pricepipe: element.DecimalPrecision}} ({{(element.AvailableQty !== 0) ? (element.scripModel.PercentageChange | percentagepipe: '0.00%') : '0.00%'}})
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" [ngClass]="{'highlight': selectedRowIndex == i}" (click)="highlight(row, i)" tabindex="999" (mouseover)="handleMouseOver(row)" (mouseleave)="handleMouseLeave(row)" [attr.id]="'hb_tbl' + i"></tr>
                        </table>
                        </div>
                </ng-container>
            </div>
        </div>
        </ngx-pull-to-refresh>
    </mat-card-content>
</mat-card>
