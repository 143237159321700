import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseComponentDirective } from "src/app/goldenlayout/base-component.directive";
import { TableUtility } from "src/app/services/tableutility";
import { TradingRestService } from "src/app/services/tradingrest.service";
import { Utility } from "src/app/services/utility";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-emandate',
    templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/emandate/emandate.component.html',
    styleUrls: ['./emandate.component.scss']
})

export class EmandateComponent extends BaseComponentDirective implements OnInit {

    isMobile: boolean;
    transactionsList;
    displayedColumns = [];
    frequencyMap = {'DAIL':'daily', 'WEEK':'Weekly', 'MNTH':'Monthly', 'BINM':'Bi-Monthly', 'QURT':'Quarterly', 'MIAN':'Half Yearly', 'YEAR':'Yearly'};
    isLoading: boolean;
    container: any;
    totalheight: number;
    Height: {};
    left: {};
    DHeight: {};
    isDHeight = true;
    utility: any;
    isNodata = false;

    constructor(elRef: ElementRef, private tradingRestService: TradingRestService, private snackBar: MatSnackBar, private detector: ChangeDetectorRef) {
        super(elRef.nativeElement);   
        this.isMobile = Utility.IS_MOBILE;
        this.utility = Utility;
        this.displayedColumns = TableUtility.EmandtTransactionConfiq;
    }

    ngOnInit() {
        this.getAllTransaction();
    }
    getAllTransaction(){
      this.isLoading = true;
      this.tradingRestService.getUserService().getAllTransactions(this.getAllTransactionsResponse.bind(this));
    }
    getAllTransactionsResponse(data, code, msg) {
      this.isLoading = false;
        if(code === 200) {
            this.transactionsList = data;
        } else {
            this.snackBar.open(msg, '', {duration: 2000});
        }
        this.isNodata = (this.transactionsList.length === 0) ? true: false;
    }

    changeMode() {
        if (this.container?.width !== null){
          this.totalheight = (this.container.height - TableUtility.EHeight);
          this.Height = {
            'height': this.totalheight + 'px',
            'overflow': 'auto',
            'scroll-behavior': 'smooth'
          };
          this.left = {
            'left': (this.container.width - TableUtility.WLeft) + 'px'
          };
          this.detector.detectChanges();
        }
    }

    setBaseVisibility(visible: boolean){
        if(visible){
          setTimeout(() => {
            this.changeMode();
          }, 50);
        }
    }
}