import { Component, OnInit } from '@angular/core';
import { Utility } from 'src/app/services/utility';

@Component({
  selector: 'app-positionbreakuppopup',
  templateUrl: './positionbreakuppopup.component.html',
  styleUrls: ['./positionbreakuppopup.component.scss']
})
export class PositionBreakupPopupComponent implements OnInit {
  positionBreakUpDetailData: any;
  goback = '1';
  isLoading = false;
  selectedRadioButtonValue = '';
  count = 0;
  constructor() { 
    this.positionBreakUpDetailData = Utility.positionBreakUpDetail;
    this.selectedRadioButtonValue=this.positionBreakUpDetailData.list[0].Exchange;
  }

  ngOnInit(): void {
  }
  handleChange(event) {
    this.count = this.count + 1;
    this.selectedRadioButtonValue = event;
    for(let i =0; i< this.positionBreakUpDetailData.list.length; i++){
      if(this.positionBreakUpDetailData.list[i].Exchange === this.selectedRadioButtonValue)
        Utility.positionDetail = this.positionBreakUpDetailData.list[i];
    }
  }
}
