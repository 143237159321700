import { LastTradedPriceChanged } from '../interface/LastTradedPriceChanged';
import { Utility } from '../services/utility';
import { ScripContentDataModel } from './scripcontentdata.model';
import { Subject } from 'rxjs';

let scripModel: ScripModel;
export class ScripModel {
    public Token: string;
    public GDGNPDPN: number;
    public ExchangeSegment: string;
    public scripContentDataModel: ScripContentDataModel;
    public Isin: string;
    public DecimalPrecision;
    public BodLotQuantity;
    public OptionType;
    public StrikePrice;
    public TradingSymbol;
    public MaxQty;
    public ExpiryDate;
    public ExpiryDateDisplayValue;
    public BestSellSize;
    public MarketProtection;
    public BestBuyPrice = '0';
    public ExchangeName;
    public BestBuySize;
    public TickSize;
    public TradeVolume;
    public DiscQty;
    public Change;
    public MinQty;
    public PercentageChange;
    public Multiplier;
    public NoMarketProtectionFlag;
    public DefaultMarketProtectionValue;
    public UniqueKey;
    public High;
    public Low;
    public OpenPrice;
    public PrvClose;
    public InstrumentName;
    public BestSellPrice = '0';
    public Lasttradedquantity;
    public LastTradePrice;
    public CompanyName;
    public SymbolDescription;
    public DisplaySymbolDescription = '-';
    public DisplaySymbol;
    public CombinedSymbol;
    public Openinterest;
    public TotalBuyQty;
    public TotalSellQty;
    public SellQty;
    public TotalSell;
    public YearlyLowPrice;
    public VwapAveragePrice;
    public YearlyHighPrice;
    public ExchangeFeedTime;
    public LastTradeQuantity;
    public TotalBuy;
    public LastTradedTime;
    public BuyQuantity;
    public Series;
    public CoverPercentage;
    public TriggerPriceBuy;
    public TriggerPriceSell;
    public Symbolname;
    public SymbolDescriptionTopN;
    public Name;
    public IndexValue;
    public PerCng;
    public underlyingScripToken;
    public underlyingExchange;
    public Icon;
    public Corporateaction;
    public isShowQuotesLoaded = false;
    public LCircuitLimit;
    public HCircuitLimit;
    public COLTP;
    public TriggerRangeStart;
    public TriggerRangeEnd;
    public tracsactionType;
    public RollScrip;
    public RollOver;
    public ResistanceOne
    public ResistanceTwo;
    public ResistanceThree;
    public Pivot;
    public SupportOne;
    public SupportTwo;
    public SupportThree;
    public wkhigh;
    public wklow;
    public dmaFifty;
    public dmaTwoHundred;
    public macd;
    public signalLine;
    public rsi;
    public isUpdateTriggerRange = false;
    public exchTokens: Array<any>;
    public pltp1;
    public pltp2;
    public pvol1;
    public pvol2;
    IsLTPColorTimeOut = true;
    LTPColorTimeOutRef: any;
    TIME_OUT = 500;
    isResearchcall = false;
    bodlot: any;
    SpotPrice: any;
    public segment;
    public SubscribedCn = 0;
    public TickDirection = 0;
    public isInfoCalled = false;

    lastTradedPriceChanged: LastTradedPriceChanged;
    lTPChangedForPosition: LastTradedPriceChanged;
    public onLTPChange = new Subject<LastTradedPriceChanged>();
    scripUpdate = new Subject<any>();

    constructor() {
        scripModel = this;
        scripModel.setDefaultValues();
    }

    setDefaultValues() {
        scripModel.SymbolDescription = '-';
        scripModel.TradeVolume = '0';
        scripModel.Change = parseFloat('0');
        scripModel.PercentageChange = parseFloat('0');
        scripModel.PrvClose = parseFloat('0');
        scripModel.LastTradePrice = parseFloat('0');
    }

    setTimeOut(jObj) {
        if (jObj.ltp && this.LastTradePrice !== jObj.ltp) {
            this.TickDirection = (this.LastTradePrice < jObj.ltp) ? 1 : -1;
            this.LastTradePrice = jObj.ltp;
            setTimeout(() => {
                this.TickDirection = 0;
            }, 250);
        }
        this.BestBuyPrice = jObj.bp ? jObj.bp : this.BestBuyPrice;
        this.BestBuySize = jObj.bq ? jObj.bq : this.BestBuySize;
        this.BestSellPrice = jObj.sp ? jObj.sp : this.BestSellPrice;
        this.BestSellSize = jObj.bs ? jObj.bs : this.BestSellSize;
        if (this.isUpdateTriggerRange) {
            this.UpdateTriggerPriceRange(this.tracsactionType);
        }
        if (jObj.ltp !== undefined) {
            if (this.lastTradedPriceChanged !== undefined) {
                this.lastTradedPriceChanged(this);
            }
            if (this.lTPChangedForPosition !== undefined) {
                this.lTPChangedForPosition(this);
            }
            if (this.onLTPChange !== undefined) {
                this.onLTPChange.next();
            }
            if (this.scripUpdate !== undefined) {
                this.scripUpdate.next(jObj);
            }
            
        }
    }

    CalculateTriggerPriceBuy() {
        let difpc;
        const MultiplierVal = this.Multiplier * (Math.pow(10, this.DecimalPrecision));
        let difpci;
        difpc = Utility.getNumber(this.BestSellPrice) - ((Utility.getNumber(this.BestSellPrice) * this.CoverPercentage) / 100);
        const addSub = ((Math.round(difpc * MultiplierVal)) % this.TickSize);
        if (addSub !== 0) {
            difpci = difpc * MultiplierVal;
            difpci = difpci - addSub;
            difpci = Utility.getNumber(difpci) + Utility.getNumber(this.TickSize);
            difpci = difpci / MultiplierVal;
        } else {
            difpci = difpc;
        }
        this.TriggerPriceBuy = difpci;
    }

    CalculateTriggerPriceSell() {
        let difpc;
        const MultiplierVal = this.Multiplier * (Math.pow(10, this.DecimalPrecision));
        let difpci;
        difpc = ((Utility.getNumber(this.BestBuyPrice) * this.CoverPercentage) / 100) + Utility.getNumber(this.BestBuyPrice);
        const addSub = Utility.getNumber(((Math.round(difpc * MultiplierVal)) % Utility.getNumber(this.TickSize)));
        if (addSub !== 0) {
            difpci = ((difpc * MultiplierVal) - addSub) / MultiplierVal;
        } else {
            difpci = difpc;
        }
        this.TriggerPriceSell = difpci;
    }

    CalculateTriggerPriceRange(transactionType: string, isCOPriceFlagEnabled: boolean) {
        if (isCOPriceFlagEnabled) {
            if (transactionType === 'B')
                this.CalculateTriggerRange(transactionType, this.BestBuyPrice);
            else
                this.CalculateTriggerRange(transactionType, this.BestSellPrice);
        }
        else {
            transactionType = transactionType === 'S' ? 'B' : 'S';
            this.CalculateTriggerRange(transactionType, this.COLTP);
        }
    }

    UpdateTriggerPriceRange(transactionType: string) {
        this.CalculateTriggerPriceRange(transactionType, true);
    }

    CalculateTriggerRange(transactionType: string, refPrice) {
        const MultiplierVal = Utility.getNumber(this.Multiplier) * (Math.pow(10, Utility.getNumber(this.DecimalPrecision)));
        let difpci = 0;
        const coverageAmount = ((Utility.getNumber(refPrice) * Utility.getNumber(this.CoverPercentage)) / 100);
        if (transactionType === 'B') {
            difpci = Utility.getNumber(refPrice) - Utility.getNumber(coverageAmount);
        }
        else {
            difpci = Utility.getNumber(refPrice) + Utility.getNumber(coverageAmount);
        }
        const addSub = ((Math.round(Utility.getNumber(difpci) * Utility.getNumber(MultiplierVal))) % Utility.getNumber(this.TickSize));
        if (Utility.getNumber(addSub) !== 0) {
            if (transactionType === 'B')
                difpci = ((Utility.getNumber(difpci) * Utility.getNumber(MultiplierVal)) - Utility.getNumber(addSub) + Utility.getNumber(this.TickSize)) / Utility.getNumber(MultiplierVal);
            // note check is required for adding this.TickSize
            else
                difpci = ((Utility.getNumber(difpci) * Utility.getNumber(MultiplierVal)) - Utility.getNumber(addSub)) / Utility.getNumber(MultiplierVal);
        }
        this.TriggerRangeStart = (transactionType === 'B' ? Utility.getNumber(difpci) : Utility.getNumber(refPrice)).toFixed(this.DecimalPrecision);
        this.TriggerRangeEnd = (transactionType === 'B' ? Utility.getNumber(refPrice) : Utility.getNumber(difpci)).toFixed(this.DecimalPrecision);
    }
}
