import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { Utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-basketsipmore',
  templateUrl: '../../../'+environment.baseviewpath+'/thematicbasket/basketsipmore/basketsipmore.component.html'
})
export class BasketsipmoreComponent implements OnInit {
  sipData;
  statusMap = {};
  isSipUpdate = false;
  isEmandateFlag = environment.emandate.enable;

  constructor(private _bottomSheetRef: MatBottomSheetRef<BasketsipmoreComponent>, private PS: PtPlusRestService, private snackBar: MatSnackBar, private router: Router) {
    this.sipData = Utility.selectedSipData;
   }

  ngOnInit(): void {
    this.setStatusMap()
  }

  setStatusMap(){
    this.statusMap[1] = 'Active';
    this.statusMap[2] = 'Pause';
    this.statusMap[-1] = 'Delete';
  }

  handleButton(buttonType){
    if(buttonType === 'U'){
      this.router.navigate(['sipupdate']);
      this._bottomSheetRef.dismiss();
    } else {
      this.isSipUpdate = true;
      let obj = {'id': this.sipData.id, 'sip_status': buttonType};
      this.PS.getThematicBasketService().changeSipStatus(obj, this.changeStatusResponse.bind(this));
    }
  }

  onSwipeDown() {
    this._bottomSheetRef.dismiss();
  }

  changeStatusResponse(code, status, message){
    this.isSipUpdate = false;
    if(code === 200 && status === 'SUCCESS'){
      this.snackBar.open(message, 'Close', { duration: 3000, panelClass: ['sucessSnackBar'] });
      this._bottomSheetRef.dismiss();
      Utility.callBasketSip.next();
    } else {
      this.snackBar.open(message, 'Close', { duration: 2000, panelClass: ['errorSnackBar']});
    }
  }

  openEmandate() {
    const obj = {'emandateFrm': 'basketSip'};
    this._bottomSheetRef.dismiss();
    this.router.navigate(['/createemandate'],{queryParams:obj});
  }

}
