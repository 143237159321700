import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { Utility } from '../../../services/utility';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../login/login.component.scss']
})
export class FeedbackComponent implements OnInit{
  isMobile = false;
  version;
  dis_version;
  SEBIRNO;
  @Input() isCustomDashboard;

  constructor(private snackBar: MatSnackBar, private router: Router, private location: Location) {
    this.isMobile = Utility.IS_MOBILE;
    this.dis_version = environment.display_version;
    this.version = environment.version;
    this.SEBIRNO = environment.SEBIRNO;
  }

  ngOnInit() {
    console.log(this.isCustomDashboard)
  }

  onLoad()
  {
    
  }

  back()
  {
    this.location.back();
  }
}
