<app-titlebar *ngIf = "showheader && !isMobile" [title]="'all.watchlist_setting' | translate"></app-titlebar>
<app-header *ngIf="isMobile" goback="1" [title]="'all.watchlist_setting' | translate" ></app-header>
<!-- <mat-progress-bar *ngIf="isLoader" style="position: fixed;left: 0; top:0;right:0;z-index:1100" mode="indeterminate"></mat-progress-bar> -->

<mat-card class="webpanelnew mx-2" [class.m-40]="isMobileLand">
  <mat-card-content>
    <div class="container nofilter">
        <span *ngIf="!isMobile" translate>all.watchlist_setting</span>
        <mat-chip-list *ngIf="showManageColumns">
            <mat-chip  [class.imactive]="selectedLabel === 'mangewatchlist'" id="tab-header" (click) = "onTabChange('mangewatchlist')" translate>all.manage_watchlist</mat-chip>
            <mat-chip  [class.imactive]="selectedLabel === 'managecolumn'" id="tab-header" (click) = "onTabChange('managecolumn')" translate>all.manage_column</mat-chip>
        </mat-chip-list>
    </div>
    <div *ngIf = "selectedLabel === 'mangewatchlist'">
      <app-manage-watchlist [isMobileLand]="isMobileLand" [isOpen] = "isOpen" [formCustomDB]="formCustomDB" (addStockToNewWL) = "addStockToNewWL1($event)"></app-manage-watchlist>
    </div>
    <div *ngIf = "selectedLabel === 'managecolumn'">
      <app-watchlist-popup [isPopupOpen]="isOpen" (closeWatchlistPopup) = "closeWatchlistPopup1()"></app-watchlist-popup>
    </div>

  </mat-card-content>
</mat-card>
