import { Component, OnInit, EventEmitter, Output, Input, Inject, ChangeDetectorRef, Injector, ElementRef } from '@angular/core';
import { PtPlusRestService } from 'src/app/services/ptplusrest.service';
import { BasketModel } from 'src/app/model/basket.model';
import { Router } from '@angular/router';
import { ChannelType, Utility } from 'src/app/services/utility';
import { ComponentContainer } from 'golden-layout';
import { GeneralSettingsComponent } from '../generalsettings/generalsettings.component';
import { Subscription } from 'rxjs';
import { Preferences, ReturnType } from 'src/app/services/Preferences';
import { environment } from 'src/environments/environment';
import { TradingRestService } from 'src/app/services/tradingrest.service';
import { BaseComponentDirective } from 'src/app/goldenlayout/base-component.directive';
import { Url } from 'src/app/services/url';
import { QProfileComponent } from '../qprofile/qprofile.component';
import { MatDialog } from '@angular/material/dialog';
import { combineQProfileTodaySip } from '../combineqprofiletodaysip/combineqprofiletodaysip.component';

let basket: BasketComponent;
@Component({
    selector: 'app-basket',
    templateUrl: '../../'+environment.baseviewpath+'/thematicbasket/basket.component.html',
    styleUrls:['./basket.component.scss']
})
export class BasketComponent extends BaseComponentDirective implements OnInit {
    @Output() load = new EventEmitter<boolean>();
    @Input() isCustomDashboard: boolean;
    @Output() openBasketDetailPage = new EventEmitter<string>();
    isLoading: boolean;
    isNoData: boolean;
    basketList: Array<BasketModel>;
    investmentPeriodMap = {};
    riskMap = {};
    compareBasketStatus: boolean;
    selectedBasket = [];
    doCompare = false;
    goback = '1';
    isSearch = true;
    checked;
    isMobile;
    container;
    modesub: Subscription;
    @Input() Height;
    showButtonInBasket = false;
    qprofileData;

    constructor (elRef: ElementRef, private injector: Injector,private tradingRestService: TradingRestService, private detector: ChangeDetectorRef, private PS: PtPlusRestService, private router: Router, public dialog: MatDialog) {
        super(elRef.nativeElement);
        basket = this;
        if (!Utility.IS_MOBILE) {
            basket.goback = '2';
            // basket.container = <ComponentContainer>basket.injector.get('Container');
        }
        if(environment.baseviewpath == 'investor'){
            basket.getByPhorem();
        }
        basket.isMobile = Utility.IS_MOBILE;
        basket.PS.getThematicBasketService().setTradingRestService(tradingRestService);
        basket.createMap();
        if (Preferences.getItem('selectedBasket', ReturnType.Undefined)) {
            basket.selectedBasket = Preferences.getItem('selectedBasket', ReturnType.Array);
            if (basket.selectedBasket.length >= 2) {
                basket.checked = true;
                basket.compareBasketStatus = true;
                basket.doCompare = true;
            }
        }
        basket.modesub = GeneralSettingsComponent.MODE.subscribe(data => {
            // basket.changeMode();
        });
    }


    createMap() {
        basket.investmentPeriodMap['s'] = 'Short';
        basket.investmentPeriodMap['m'] = 'Medium';
        basket.investmentPeriodMap['l'] = 'Long';

        basket.riskMap['l'] = 'Low';
        basket.riskMap['m'] = 'Medium';
        basket.riskMap['h'] = 'High';
        basket.riskMap['mh'] = 'Moderate High';

    }

    ngOnInit() {
        if (basket.isCustomDashboard) {
            // basket.changeMode();
        }
        basket.getData();
        if (basket.container) {
         /*   basket.container.on('resize', _ => {
                basket.changeMode();
            });*/
        }
    }

    onToggle(event) {
        basket.checked = event.checked;
        if (basket.checked === true) {
            basket.compareBasketStatus = true;
        }
        else {
            basket.compareBasketStatus = false;
        }
    }

    allBasketDataResponse(result, code, message) {
        basket.isLoading = false;
        basket.isNoData = false;
        basket.load.emit(basket.isLoading);
        if (code === 200 && message === 'SUCCESS') {
            basket.basketList = result;
            Preferences.setItem('basketList', basket.basketList);
        }
        else {
            basket.isNoData = true;
            basket.doCompare = false;
        }
    }

    onClickBasket(data) {
        Preferences.setItem('basketData', data);
        if (!this.isMobile) {
            var obj = {"name":"basketdetail"}
            Utility.InputsDataGet.next(obj);
        } else {
            basket.router.navigate(['/basketdetail']);
        }
    }

    userSelectClick(eve, data) {
        const id = data['id'];
        if (eve.checked) {
            basket.selectedBasket.push(id);
            Preferences.setItem('selectedBasket', basket.selectedBasket);
        }
        else {
            basket.selectedBasket.splice(basket.selectedBasket.indexOf(id), 1);
            Preferences.setItem('selectedBasket', basket.selectedBasket);
        }
        if (basket.selectedBasket.length >= 2) {
            basket.doCompare = true;
        }
        else {
            basket.doCompare = false;
        }
    }

    compareButtonClick()
    {
        basket.router.navigate(['/basketcompare']);
    }

    getData(){
        basket.isLoading = true;
        basket.PS.getThematicBasketService().getAllBasketData(basket.allBasketDataResponse);
    }

    callQprofile(){
      
        if(environment.baseviewpath === 'investor'){
            Utility.fromBasket = true;
            if(this.qprofileData.length > 0)
                Utility.dialogData = this.qprofileData;
            Utility.isTodaySipOpen = true;
            let w = '500px';
            let h = '410px';
            if(Utility.IS_MOBILE){
              w = '90vw';
              h = '60vh';  
            }
            Utility.dialogRef = this.dialog.open(combineQProfileTodaySip, { disableClose: false, width: w, panelClass:'p-relative', autoFocus: false});
        }
    }  
    getByPhorem(){
        let key = Url.HS_USER_DETAILS + '_' + Preferences.getPreference('userid');
        let predata = Utility.CACHE_STORE.getCacheData(key);
        if(predata){
            this.tradingRestService.getQProfileService().getByPhorem(predata['cellAddr'], (data, code, msg) => {
                if(code == 200 && data.hasOwnProperty('custId')){
                  this.getService(data['custId']);
                }
                else if(Utility.todaysSipData.length > 0) {
                   this.callQprofile(); 
                }
              });
        } else {
           this.callProfileService();
        }
    }
    getService(custId){
        this.tradingRestService.getQProfileService().fetchIBForUser(custId, (data, code, msg) => {
          if(data && (data.constructor === [].constructor) && data.length > 0){
            this.showButtonInBasket = true;
            this.qprofileData = data;
          }
        });
    }
    callProfileService(){
        this.tradingRestService.getUserService().getUserProfile(function(data, code, msg) {
            if (code === 200 && msg === 'OK') {
               this.getByPhorem();
            }
          }.bind(this), function(data, code, msg) {});
    }
}

