<mat-card class="webpanelnew" [class.rightcon2]="isMobile" [ngStyle]="Height">
    <mat-card-content>
        <div [class.pt-0]="!isMobile">
            <ng-container *ngIf = "!isNoData && !isMobile">
                <div class="table-global ismousemode">
                    <table class="mat-table cdk-table" role="grid">
                        <thead role="rowgroup">
                            <tr role="row" class="mat-header-row cdk-header-row">
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left" translate>{{'settings.name'}}</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left" translate>{{'settings.status'}}</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left" translate>{{'all.date'}}</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left" translate>{{'all.sip' | translate}} {{'all.frequency' | translate}}</th>
                                <th role="columnheader" class="mat-header-cell cdk-header-cell text-left" translate>{{'all.sip' | translate}} {{'books.amt' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            <tr role="row" class="mat-row cdk-row cursor-pointer"  *ngFor='let data of basketList' (mouseover)="handleMouseOver(data)" (mouseleave)="handleMouseLeave(data)">
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.basket_name}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left" [class.imnegative]="data.sip_status===2" [class.impositive]="data.sip_status===1">{{statusMap[data.sip_status]}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.next_trigger_date  | datepipe: 'date'}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.sip_frequency}}</td>
                                <td role="gridcell" class="mat-cell cdk-cell text-left">{{data.sip_amount}}</td>
                                <span class="button-row-new">
                                    <div class="float-button-container" [ngStyle]="right" [class.active]="data.show == true">
                                        <button [disabled]="isSipUpdate" *ngIf = "data.sip_status !== 1"  (click)='changeStatus(data.id, 1)' class="btn-positive mr-1" mat-icon-button matTooltip="Resume"><i class="icon-play"></i></button>
                                        <button [disabled]="isSipUpdate" *ngIf = "data.sip_status !== 2"  (click)='changeStatus(data.id, 2)' class="btn-negative mr-1" mat-icon-button matTooltip="Pause"><i class="icon-pause"></i></button>
                                        <button [disabled]="isSipUpdate" (click)='sipUpdate(data)' class="btn-primary" mat-icon-button matTooltip="Update"><i class="icon-edit"></i></button>
                                        <button [disabled]="isSipUpdate" (click)='changeStatus(data.id, -1)' class="btn-primary mr-1" mat-icon-button matTooltip="{{'all.delete' | translate}}"><i class="icon-delete"></i></button> 
                                        <button [disabled]="!(data.sip_status === 1)" *ngIf = "isEmandateFlag"  (click)='openEmandate(data)' class="btn-primary mr-1" mat-icon-button matTooltip="{{'all.emandate' | translate}}"><i class="icon-emandate"></i></button>
                                    </div>
                                </span>
                            </tr>
                        </tbody>
                    </table>
                </div>                                
            </ng-container>

            <ng-container *ngIf = "!isNoData && isMobile">
                <div *ngFor='let data of basketList'>
                    <div class="row" (click) = "openSipMore(data)">
                        <div class="col-12">
                            <div class="watchlistwrapper">
                                <div class="row">
                                    <label class="col-12 tc-1 ts-4 textclip">{{data.basket_name}}</label>
                                    <div class="col-7 pr-0">
                                        <label class="line2 subcolor ts-2">{{'settings.status' | translate}}:&nbsp;</label><span [class.imnegative]="data.sip_status===2" [class.impositive]="data.sip_status===1">{{statusMap[data.sip_status]}}</span><br/>
                                        <label class="line2 subcolor ts-2">{{'all.sip' | translate}} {{'all.frequency' | translate}}:&nbsp;</label>{{data.sip_frequency}}
                                    </div>
                                    <div class="col-5 pl-0 text-right">
                                        <label class="line2 subcolor ts-2">{{'all.date' | translate}}: </label><label>{{data.next_trigger_date  | datepipe: 'date'}}</label>
                                        <br>
                                        <label class="line2 subcolor ts-2">{{'all.sip' | translate}} {{'books.amt' | translate}}:&nbsp;</label><label>{{data.sip_amount}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row border-b">
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf='!isLoading && isNoData' class='nodata'>
                <app-nodata message = "N0 DATA" (refreshEvent) = "getData()" [btnTitle]="'utility.reload' | translate"></app-nodata>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>